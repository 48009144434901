import { ReactNode } from "react";
import { useTranslation } from "../../../../hooks/useTranslation";

type KycFormSectionProps = {
  label: string;
  children?: ReactNode;
};

export const KycFormSection = ({ label, children }: KycFormSectionProps): JSX.Element => {
  const { fm } = useTranslation();

  return (
    <>
      <div className="d-flex flex-wrap flex-sm-wrap flex-md-wrap flex-lg-nowrap justify-content-center">
        {label && (
          <label className="col-lg-12 col-form-label text-left pl-lg-40 ">{fm(label)}:</label>
        )}
      </div>
      <div>{children}</div>
    </>
  );
};
