import { FormattedMessage, useIntl } from "react-intl";
import * as React from "react";
import { useEffect } from "react";
import BootstrapTable, { ColumnDescription, ExpandRowProps } from "react-bootstrap-table-next";
import { NoRecordsFoundMessage, PleaseWaitMessage } from "../../../_metronic/_helpers";

import { defaultSorted } from "../../modules/Settings/components/SettingsTabs/PropCoTab/PropCoList/definitions";
import { AuditTrailDateTimeColumnFormatter } from "./AuditTrailDateTimeColumnFormatter";
import { AuditTrailDetailColumnFormatter } from "./AuditTrailDetailColumnFormatter";
import { Button } from "react-bootstrap";
import { EventNames, eventTypes, getEntries } from "./utilsAuditTrail";
import "./AuditTrail.scss";
import cn from "clsx";
import { useAuditTrail } from "../../hooks/useAuditTrail";
import { formatDisplayNameIntl } from "../../_utils/userUtils";
import { LOADING_FETCH_USER_EVENTS } from "../../contexts/AuditTrailContext";
import { IDiffEntry, IEvent } from "./models";
import { LoaderSpinner } from "../LoaderSpinner";
import { IGNORE_FIELDS } from "./utilsAuditTrailTranslations";
import { isArray, isEmpty } from "lodash-es";
import { AuditTrailNavbar } from "./AuditTrailNavbar";

interface AuditTrailViewerProps {
  showNavbar?: boolean;
  enableEventPrefixFormatting?: boolean;
}

export const AuditTrailViewer = ({
  showNavbar = true,
  enableEventPrefixFormatting = true,
}: AuditTrailViewerProps): JSX.Element => {
  const intl = useIntl();

  const { events, loadings, currentEntityObject } = useAuditTrail();

  const [eventsToDisplay, setEventsToDisplay] = React.useState([]);
  useEffect(() => {
    const newEventsToDisplay: any = [];

    if (isArray(events)) {
      for (const ev of [...events].sort(
        // @ts-ignore
        (e1: any, e2: any) => new Date(e1.createdAt) - new Date(e2.createdAt)
      )) {
        if (currentEntityObject && !ev.objectNameRelatedTo) {
          if (currentEntityObject.firstName) {
            ev.objectNameRelatedTo = formatDisplayNameIntl(intl, currentEntityObject, false);
          } else {
            ev.objectNameRelatedTo = currentEntityObject.name;
          }
        }

        /*
        Special case - "file request":

        1. User A creates file request in User B's details (in file tab)
        2. Now, User B received the request, but for some reason he is not able to upload the file and/or
           the requested file has been sent e.g. via e-mail (manually).
        3. User A (or any other User with sufficient rights) has the possibility to upload the request on its own (for User B).
        4. In this case, audit trail requires two events (because of current implementation and dynamoDB keys/queries):
            i. first event will only contain relation to file (no indication of relation to user itself)
            ii. second event will contain relation to file & user

            Having these two events will assure that the upload is traced in the audit trail of
            the location of the file (= User B's details where the request has been created)

            and

            in the audit trail of the User which uploaded the file (User A)

        The issue caused by this is that there can occur two different events which are basically the
        same on UI level (again, this is caused by current implementation/the way dynamoDB table is designed

        To avoid to show these duplicates, we the following if-condition ignores 1 of the events

        Note: backend implementation of upload of requested file: createUsersFilesMeta.ts
      */

        if (
          ev.eventName === EventNames.FILE_UPLOADED_REQUESTED_FILE &&
          !newEventsToDisplay?.find((e: IEvent) => {
            const xMinBefore = new Date(ev.createdAt);
            xMinBefore.setMinutes(xMinBefore.getMinutes() - 5);
            return (
              e.eventName === ev.eventName &&
              e.objectIdRelatedTo === ev.objectIdRelatedTo &&
              e.objectTypeRelatedTo === ev.objectTypeRelatedTo &&
              xMinBefore < new Date(e.createdAt)
            );
          })
        ) {
          newEventsToDisplay.push(ev);
        }
        if (
          Object.keys(eventTypes).includes(ev.eventName) &&
          ev.eventName !== EventNames.FILE_UPLOADED_REQUESTED_FILE &&
          (!eventTypes[ev.eventName].hideDuplicated ||
            !newEventsToDisplay?.find((e: IEvent) => {
              const xMinBefore = new Date(ev.createdAt);
              xMinBefore.setMinutes(xMinBefore.getMinutes() - 5);
              return (
                e.eventName === ev.eventName &&
                e.objectIdRelatedTo === ev.objectIdRelatedTo &&
                e.objectTypeRelatedTo === ev.objectTypeRelatedTo &&
                e.rootObjectIdRelatedTo === ev.rootObjectIdRelatedTo &&
                e.rootObjectTypeRelatedTo === ev.rootObjectTypeRelatedTo &&
                xMinBefore < new Date(e.createdAt)
              );
            }))
        ) {
          newEventsToDisplay.push(ev);
        }
      }

      setEventsToDisplay(newEventsToDisplay.reverse());
    }
  }, [events]);

  const headerStyle = {
    border: "none",
    classes: "text-left align-text-middle",
  };

  const cellStyle = {
    borderBottom: "none",
    borderTop: "none",
    fontSize: "12px",
    padding: "0",
    margin: "0",
  };

  const columns: ColumnDescription[] = [
    {
      dataField: "createdAt",
      text: "",
      style: { ...cellStyle },
      classes: "text-right align-middle",
      formatter: AuditTrailDateTimeColumnFormatter,
      formatExtraData: { intl, showTime: true },
      headerStyle: { ...headerStyle, width: "150px" },
    },
    {
      dataField: "id",
      // classes: "text-left align-middle",
      headerStyle: { ...headerStyle },
      style: { ...cellStyle },
      text: "",
      sort: false,
      formatter: AuditTrailDetailColumnFormatter,
      formatExtraData: {
        intl: intl,
        enableEventPrefixFormatting: enableEventPrefixFormatting,
      },
    },
  ];

  const expandRow: ExpandRowProps<any, any> = {
    renderer: (row: any) => {
      return (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {getEntries(intl, row)}
        </div>
      );
    },
    showExpandColumn: true,
    expandByColumnOnly: true,
    expandColumnPosition: "right",
    // onlyOneExpanding: true,
    expandHeaderColumnRenderer: () => <></>,
    expandColumnRenderer: ({ expanded, rowKey }) => {
      const event = events?.find((event) => event.id === rowKey);

      if (!event) return <></>;

      let showDetailsButton = false;

      let entryDetails: any = [];

      if (["MODIFY", "INSERT", "SIGNATURE_REQUEST_CREATED"].includes(event.eventName)) {
        event?.diff?.forEach((entry: IDiffEntry, index) => {
          const propertyKey = entry.path[entry.path.length - 1];
          if (propertyKey && !IGNORE_FIELDS.includes(propertyKey)) {
            entryDetails.push(propertyKey);
          }
        });
      }

      if (!isEmpty(entryDetails)) {
        showDetailsButton = true;
      }

      return (
        <>
          {showDetailsButton && (
            <div className={"text-right"}>
              <Button variant="none" size="sm" className="p-0">
                <FormattedMessage
                  id={expanded ? "COMMON.ACTION.HIDE_DETAILS" : "COMMON.ACTION.SHOW_DETAILS"}
                />
                <i className={cn("fas icon-md", expanded ? "fa-angle-up" : "fa-angle-down")} />
              </Button>
            </div>
          )}
        </>
      );
    },
  };

  return (
    <div>
      {loadings.has(LOADING_FETCH_USER_EVENTS) ? (
        <LoaderSpinner
          style={{ height: "20vh" }}
          className={"justify-content-center align-items-center"}
        />
      ) : (
        <div>
          {showNavbar && <AuditTrailNavbar />}
          {/*?.slice(0, 5)*/}
          {/*"-25px"*/}
          <div style={{ marginTop: 0 }}>
            <BootstrapTable
              id="audit-trail-table"
              wrapperClasses="table-responsive"
              bootstrap4
              bordered={false}
              keyField="id"
              data={eventsToDisplay ?? []}
              columns={columns}
              defaultSorted={defaultSorted}
              expandRow={expandRow}
              hover
              condensed
              rowStyle={{ height: 40, borderTop: "0px" }}
            >
              <PleaseWaitMessage entities={events} />
              <NoRecordsFoundMessage entities={events} />
            </BootstrapTable>
          </div>
        </div>
      )}
    </div>
  );
};
