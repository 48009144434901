import { all } from "redux-saga/effects";
import { combineReducers } from "redux";

import * as auth from "../app/modules/Auth/_redux/authRedux";
import { productsSlice } from "../app/modules/PropertiesManagement/_redux/products/productsSlice";
import { leadsSlice } from "../app/modules/PropertiesManagement/_redux/leads/leadsSlice";
import { snackBarNotificationsSlice } from "../app/modules/Notifications/_redux/snackBarNotificationsSlice";
import { projectsSlice } from "../app/modules/PropertiesManagement/_redux/projects/projectsSlice";
import { inAppNotificationsSlice } from "../app/modules/Notifications/_redux/inAppNotificationsSlice";
import { usersSlice } from "../app/modules/UsersManagement/_redux/usersSlice";
import { settingsSlice } from "../app/modules/Settings/_redux/settingsSlice";
import { dashboardSlice } from "../app/modules/Dashboard/_redux/dashboardSlice";
import { formsSlice } from "../app/modules/PropertiesManagement/_redux/forms/formsSlice";
import { tasksSlice } from "../app/modules/PropertiesManagement/_redux/tasks/tasksSlice";
import { budgetsSlice } from "../app/modules/Budget/_redux/budgetsSlice";
import { globalSlice } from "./global/globalSlice";
import { chatsSlice } from "../app/modules/Chat/_redux/chatSlice";
import { uiReducer } from "./ui/uiReducer";

export const rootReducer = combineReducers({
  auth: auth.reducer,
  global: globalSlice.reducer,
  products: productsSlice("products").reducer,
  projectProducts: productsSlice("projectProducts").reducer,
  leads: leadsSlice("leads").reducer,
  projectLeads: leadsSlice("projectLeads").reducer,
  productLeads: leadsSlice("productLeads").reducer,
  clientLeads: leadsSlice("clientLeads").reducer,
  leadClients: usersSlice("leadClients").reducer,
  snackBarNotifications: snackBarNotificationsSlice.reducer,
  inAppNotifications: inAppNotificationsSlice.reducer,
  projects: projectsSlice.reducer,
  users: usersSlice("users").reducer,
  settings: settingsSlice.reducer,
  dashboard: dashboardSlice("dashboard").reducer,
  forms: formsSlice.reducer,
  tasks: tasksSlice("tasks").reducer,
  budgets: budgetsSlice("budgets").reducer,
  chats: chatsSlice.reducer,
  ui: uiReducer,
});

export function* rootSaga() {
  yield all([auth.saga()]);
}
