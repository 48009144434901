import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import SVG from "react-inlinesvg";
import { useTranslation } from "../../../hooks/useTranslation";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import * as React from "react";

type InfoBoxBigProps = {
  label: string;
  link?: string;
};

export const KycInfoBoxBig = ({ label, link }: InfoBoxBigProps): JSX.Element => {
  const { fm } = useTranslation();

  return (
    <>
      {!link ? (
        <OverlayTrigger
          placement="right"
          overlay={
            <Tooltip id={"tooltip-" + label} className="kyc-tooltip">
              {fm(label)}
            </Tooltip>
          }
        >
          <div className="ml-2">
            <span className="svg-icon svg-icon-2x svg-icon-info mr-2">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Code/Info-circle.svg")} />
            </span>
          </div>
        </OverlayTrigger>
      ) : (
        <>
          {label && (
            <div
              className="d-flex flex-wrap flex-sm-wrap flex-md-wrap flex-lg-nowrap justify-content-start pb-8 pt-4 pl-10  "
              style={{ alignItems: "center", paddingRight: 16 }}
            >
              <span className="svg-icon svg-icon-2x svg-icon-info mr-2">
                <SVG src={toAbsoluteUrl("/media/svg/icons/Code/Info-circle.svg")} />
              </span>
              <span
                style={{ fontWeight: 400, textAlign: "justify" }}
                className="text-info text-justify"
              >
                <a href={link} rel="noreferrer" target="_blank" className="text-info text-justify">
                  {fm(label)}
                </a>
              </span>
            </div>
          )}
        </>
      )}
    </>
  );
};
