import { KycTranslation } from "../../KycTranslation";
import * as React from "react";
import { ChangeEvent } from "react";
import { useKycFlow } from "../../../../hooks/useKycFlow";
import { KycFormTitle } from "../layout/KycFormTitle";
import { useTranslation } from "../../../../hooks/useTranslation";
import { Button } from "react-bootstrap";
import { KycCenteredStepBodyLayout } from "./KycCenteredStepBodyLayout";
import { Field } from "formik";
import { KycCheckbox } from "../controls/kyc-checkbox/KycCheckbox";
import { KycDialogConfig } from "../KycConfirmationDialogContainer";
import { useKycConfirmation } from "../../../../hooks/useKycConfirmation";
import { canSendForm } from "../../KycUtils";

export const KycStepClientPage4 = (): JSX.Element => {
  const { values, setFlowValues, isClientViewer } = useKycFlow();
  const { setConfig, setShowModal } = useKycConfirmation();

  const { fm } = useTranslation();

  const handleClick = (e: any, t: any) => {
    e.preventDefault();
    setConfig({
      type: t,
      kyc: values,
      kycId: values?.id,
    });
    setShowModal(true);
  };

  return (
    <>
      <KycFormTitle label={KycTranslation.STEPS_CLIENT_PAGE_4_TITLE} />
      <KycCenteredStepBodyLayout>
        <div className="mt-8 mx-auto align-items-center " style={{ maxWidth: "80%" }}>
          <div>
            <Field
              inline={false}
              name="form.client.disclaimerCorrectness.isAccepted"
              component={KycCheckbox}
              disabled={!canSendForm(values) || !isClientViewer}
              text={KycTranslation.DISCLAIMER_INFORMATION_CORRECTNESS}
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                if (e.target.checked) {
                  setFlowValues({
                    "form.client.disclaimerCorrectness": {
                      isAccepted: true,
                      acceptedDate: new Date().toUTCString(),
                    },
                  });
                } else {
                  setFlowValues({
                    "form.client.disclaimerCorrectness": {
                      isAccepted: false,
                      acceptedDate: "",
                    },
                  });
                }
              }}
              data-cy="checkbox-recto-verso"
            />
          </div>
        </div>

        {isClientViewer && (
          <div className="d-flex justify-content-center align-items-center mt-5">
            <div className="mt-8 mx-auto">
              <Button
                data-cy="button-disclaimer-correctness-isAccepted"
                disabled={!values?.form?.client?.disclaimerCorrectness?.isAccepted}
                onClick={(e: any) => handleClick(e, KycDialogConfig.KYC_FLOW_SEND_FORM_COMPLETION)}
                className="btn-primary"
              >
                {fm(KycTranslation.ACTION_SUBMIT_COMPLETED_FORM)}
              </Button>
            </div>
          </div>
        )}
      </KycCenteredStepBodyLayout>
    </>
  );
};
