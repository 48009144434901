import { createSlice } from "@reduxjs/toolkit";
import { set } from "lodash-es";

import { sliceSignaturesRequestFileHelper } from "app/_utils/signatureUtils";

import { FilesSliceUtils } from "app/modules/File/filesSliceUtils";

const initialProductsState = {
  listLoading: false,
  actionsLoading: false,
  entities: [],
  productForEdit: {
    saved: undefined,
    current: undefined,
  },
};

export const callTypes = {
  list: "list",
  action: "action",
};

export const productsSlice = (name) =>
  createSlice({
    name: name,
    initialState: initialProductsState,
    reducers: {
      catchError: (state, action) => {
        if (action.payload.callType === callTypes.list) {
          state.listLoading = false;
        } else {
          state.actionsLoading = false;
        }
      },

      startCall: (state, action) => {
        state.error = null;
        if (action.payload.callType === callTypes.list) {
          state.listLoading = true;
        } else {
          state.actionsLoading = true;
        }
      },

      productFetched: (state, action) => {
        const { productForEdit } = action.payload;
        state.actionsLoading = false;
        state.productForEdit.saved = productForEdit;
        state.productForEdit.current = productForEdit;
        state.error = null;
      },

      productsFetched: (state, action) => {
        const { entities } = action.payload;
        state.listLoading = false;
        state.error = null;
        state.entities = entities;
        state.productForEdit = initialProductsState.productForEdit;
      },

      productCreated: (state, action) => {
        state.actionsLoading = false;
        state.error = null;
        state.entities.push(action.payload.product);
        state.productForEdit = {
          saved: action.payload.product,
          current: action.payload.product,
        };
      },

      productFieldUpdatedLocally: (state, action) => {
        state.error = null;
        state.actionsLoading = false;

        if (!state.productForEdit.current) {
          state.productForEdit.current = {};
        }

        const { key, value } = action.payload;
        set(state.productForEdit.current, key, value);
      },

      productUpdated: (state, action) => {
        state.error = null;
        state.actionsLoading = false;
        state.productForEdit.saved = action.payload.product;
        state.entities = state.entities.map((entity) => {
          if (entity.id === action.payload.product.id) {
            return action.payload.product;
          }
          return entity;
        });
      },

      productDeleted: (state, action) => {
        state.error = null;
        state.actionsLoading = false;
        state.entities = state.entities.filter((el) => el.id !== action.payload.id);
      },

      productLeadInvoicesUpdatedLocally: (state, action) => {
        state.error = null;
        state.actionsLoading = false;

        // Ensure that productForEdit and current exist
        if (state.productForEdit && state.productForEdit.current) {
          state.productForEdit.current.leadInvoices -= action.payload;
        }
      },

      fileCreated: (state, action) => {
        state.error = null;
        state.actionsLoading = false;
        const { file } = action.payload;
        state.productForEdit.current.files.push(file);
        state.productForEdit.saved.files.push(file);

        const { id, friendlyName, relatedEntity } = file;
        for (const linkedFile of file.linkedFiles ?? []) {
          FilesSliceUtils.linkedFilesAdded({
            entityForEdit: state.productForEdit,
            fileId: linkedFile.id,
            linkedFiles: [
              {
                id,
                friendlyName,
                relatedEntity,
              },
            ],
          });
        }
      },

      fileUpdated: (state, action) => {
        state.error = null;
        state.actionsLoading = false;

        const { file } = action.payload;
        const fileId = file.id;
        const fileFindIndex = (file) => file.id === fileId;

        const currentFileIndex = state.productForEdit.current.files.findIndex(fileFindIndex);
        const currentFile = state.productForEdit.current.files[currentFileIndex];

        if (file.friendlyName !== currentFile.friendlyName) {
          FilesSliceUtils.linkedFileRenamed({
            updatedFile: file,
            entityForEdit: state.productForEdit,
          });
        }

        state.productForEdit.current.files[currentFileIndex] = file;

        const savedFileIndex = state.productForEdit.current.files.findIndex(fileFindIndex);
        state.productForEdit.saved.files[savedFileIndex] = file;
      },
      linkedFilesAdded: (state, { payload: { fileId, linkedFiles } }) =>
        FilesSliceUtils.linkedFilesAdded({
          entityForEdit: state.productForEdit,
          fileId,
          linkedFiles,
        }),
      linkedFileRemoved: (state, { payload: { fileId, linkedFileToRemove } }) =>
        FilesSliceUtils.linkedFileRemoved({
          entityForEdit: state.productForEdit,
          fileId,
          linkedFileToRemove,
        }),
      fileDeleted: (state, action) => {
        state.error = null;
        state.actionsLoading = false;
        const { id } = action.payload.file;
        const currentFile = state.productForEdit.current?.files?.find((file) => file.id === id);
        for (const linkedFile of currentFile?.linkedFiles ?? []) {
          FilesSliceUtils.linkedFileRemoved({
            entityForEdit: state.productForEdit,
            fileId: linkedFile.id,
            linkedFileToRemove: currentFile.id,
          });
        }
        state.productForEdit.current.files = state.productForEdit.current.files.filter(
          (file) => file.id !== id
        );
        state.productForEdit.saved.files = state.productForEdit.saved.files.filter(
          (file) => file.id !== id
        );
      },

      photosFetched: (state, action) => {
        const photos = action.payload;
        state.error = null;
        state.actionsLoading = false;
        state.productForEdit.current.photos = photos;
        state.productForEdit.saved.photos = photos;
      },

      photoCreated: (state, action) => {
        state.error = null;
        state.actionsLoading = false;
        const { newPhoto } = action.payload;

        state.productForEdit.current.photos.push(newPhoto);
        state.productForEdit.saved.photos.push(newPhoto);

        state.productForEdit.current.photosOrder.push(newPhoto.id);
        state.productForEdit.saved.photosOrder.push(newPhoto.id);
      },

      photoUpdated: (state, action) => {
        state.actionsLoading = false;
        state.error = null;
        const { photo: updatedPhoto } = action.payload;
        const updatedPhotoId = updatedPhoto.id;

        const photoIndex =
          state.productForEdit.current?.photos?.findIndex((photo) => photo.id === updatedPhotoId) ??
          -1;

        updatedPhoto.localPhoto = state.productForEdit.current?.photos?.[photoIndex]?.localPhoto;

        if (state.productForEdit?.current?.photos) {
          state.productForEdit.current.photos[photoIndex] = updatedPhoto;
        }
        if (state.productForEdit?.saved?.photos) {
          state.productForEdit.saved.photos[photoIndex] = updatedPhoto;
        }
      },

      photoDeleted: (state, action) => {
        const { photoId } = action.payload;
        state.error = null;
        state.actionsLoading = false;
        state.productForEdit.current.photos = state.productForEdit.current?.photos?.filter(
          (photo) => photo.id !== photoId
        );
        state.productForEdit.saved.photos = state.productForEdit.saved?.photos?.filter(
          (photo) => photo.id !== photoId
        );
        state.productForEdit.current.photosOrder =
          state.productForEdit.current?.photosOrder?.filter((id) => id !== photoId);
        state.productForEdit.saved.photosOrder = state.productForEdit.saved?.photosOrder?.filter(
          (id) => id !== photoId
        );
      },

      photosOrderUpdate: (state, action) => {
        const { photosOrder } = action.payload;
        state.error = null;
        state.actionsLoading = false;
        state.productForEdit.current.photosOrder = photosOrder;
        state.productForEdit.saved.photosOrder = photosOrder;
      },

      signaturesRequestCreated: (state, action) => {
        state.error = null;
        state.actionsLoading = false;

        const { file } = action.payload;

        const { current, saved } = state.productForEdit;

        sliceSignaturesRequestFileHelper({ file, current, saved });

        state.productForEdit.current = current;
        state.productForEdit.saved = saved;
      },

      signaturesRequestCancelled: (state, action) => {
        state.error = null;
        state.actionsLoading = false;

        const { file } = action.payload;

        const { current, saved } = state.productForEdit;

        sliceSignaturesRequestFileHelper({ file, current, saved });

        state.productForEdit.current = current;
        state.productForEdit.saved = saved;
      },

      photoSharingCreated: (state, action) => {
        const photoSharing = action.payload.photoSharing;
        state.error = null;
        state.actionsLoading = false;
        const photoSharingId = photoSharing.photoId;

        const photosMap = (photo) => {
          if (photo.id === photoSharingId) {
            photo.photoSharings.push(photoSharing);
          }
          return photo;
        };

        state.productForEdit.current.photos = state.productForEdit.current?.photos?.map(photosMap);

        if (state.productForEdit.saved) {
          state.productForEdit.saved.photos = state.productForEdit.saved?.photos?.map(photosMap);
        }
      },

      photoSharingRemoved: (state, action) => {
        const photoSharing = action.payload.photoSharing;
        state.error = null;
        state.actionsLoading = false;
        state.productForEdit.current.photos = state.productForEdit.current.photos.map((photo) => {
          if (photo.id === photoSharing.photoId) {
            photo.photoSharings = photo.photoSharings.filter(
              (ps) => ps.userId !== photoSharing.userId
            );
          }
          return photo;
        });
        state.productForEdit.saved.photos = state.productForEdit.saved.photos.map((photo) => {
          if (photo.id === photoSharing.photoId) {
            photo.photoSharings = photo.photoSharings.filter(
              (ps) => ps.userId !== photoSharing.userId
            );
          }
          return photo;
        });
      },
    },
  });
