import { TInvoiceStatus } from "data/schemas";

import { CardTableItem } from "app/_components/CardTable/context/CardTableContext";

//----------------------------------------------------------------------------//

export interface IRevenueColumns {
  label: string;
  instalmentPercentage?: number;
  expected?: number;
  indexedAmount?: number;
  sold?: number;
  invoiced?: number;
  invoicedPercentage?: number;
  paymentStatus?: TInvoiceStatus;
  supplementMargin?: number;
  supplementMarginPercentage?: number;
}

export const percentageColumns = [
  "instalmentPercentage",
  "invoicedPercentage",
  "supplementMarginPercentage",
];

export const currencyColumns = [
  "expected",
  "indexedAmount",
  "sold",
  "invoiced",
  "supplementMargin",
];
export type TCurrencyColumns = keyof Pick<
  IRevenueColumns,
  "expected" | "indexedAmount" | "sold" | "invoiced" | "supplementMargin"
>;

export interface IInstalmentRevenue extends Partial<IRevenueColumns> {
  id: string;
  label: string;
  paymentStatusCount: Record<string, number>;
  invoicedLeads: string[];
}

export interface ISupplementRevenue extends Partial<IRevenueColumns> {
  id: string;
  label: string;
  budgetInstalmentId?: string;
  paymentStatusCount?: Record<string, number>;
}

export interface RevenueTableRow extends IRevenueColumns, CardTableItem {}

export const BUDGET_REVENUE_CALCULATED_ID = {
  SHARE_OF_LAND: "SHARE_OF_LAND",
  ARCHITECT_ENGINEERING_FEES: "ARCHITECT_ENGINEERING_FEES",
  TOTAL_SUPPLEMENT: "TOTAL_SUPPLEMENT",
};
