import { useQuery } from "react-query";
import { getMe } from "../../api";
import { masterEmailsRestriction } from "../../../../_utils/authUtils";

const filteredGetMe = (idToken?: string) => {
  if (!idToken) {
    return null;
  }
  const email = idToken ? JSON.parse(atob(idToken.split(".")[1])).email : "";
  if (masterEmailsRestriction.includes(email)) {
    return {
      firstName: "hidden",
      lastName: "master",
      gdprApprovedAt: new Date(-1),
      useNewDesignSystem: process.env.REACT_APP_NEW_DESIGN_SYSTEM_ENABLED === "true",
    };
  }

  return getMe();
};

export const useGetMe = (idToken?: string) => {
  return useQuery(["users", idToken], () => filteredGetMe(idToken));
};
