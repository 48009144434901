import * as React from "react";

export interface DisplayPercentageValueProps {
  className?: string;
  value?: number;
}

export const DisplayPercentageValue: React.FunctionComponent<DisplayPercentageValueProps> = ({
  className,
  value = 0,
}) => (
  <span className={className} style={{ minWidth: "40px" }}>
    {value * 100} %
  </span>
);

export default DisplayPercentageValue;
