import * as React from "react";
import { API } from "@aws-amplify/api";
import PropTypes from "prop-types";
import { ILead } from "../../data/schemas";

interface LeadProps {
  children: React.ReactNode;
}

export interface LeadContextValue {
  loadings: Set<string>;
  fetchLeadsByUserId: (userId: string) => Promise<void>;
  fetchLeadById: (leadId: string) => Promise<void>;
  userLeads: ILead[];
  currentLead: ILead | undefined;
  setCurrentLead: (currentLead: ILead | undefined) => void;
}

export const LeadContext = React.createContext<LeadContextValue>({
  loadings: new Set(),
  fetchLeadsByUserId: (userId) => Promise.resolve(),
  fetchLeadById: (leadId) => Promise.resolve(),
  userLeads: [],
  currentLead: undefined,
  setCurrentLead: (currentLead) => undefined,
});

export const LeadProvider: React.FunctionComponent<LeadProps> = (props) => {
  const { children } = props;

  const [loadings, setLoadings] = React.useState<Set<string>>(new Set());
  const [userLeads, setUserLeads] = React.useState<ILead[]>([]);
  const [currentLead, setCurrentLeadInternal] = React.useState<ILead>();

  const fetchLeadsByUserId = async (userId: string) => {
    setLoadings(new Set(loadings).add("fetchLeadsByUserId"));
    await API.get("API", `/users/${userId}/leads`, {})
      .then((response) => {
        setUserLeads(response);
      })
      .finally(() => {
        const newLoadings = new Set(loadings);
        newLoadings.delete("fetchLeadsByUserId");
        setLoadings(newLoadings);
      });
  };

  const fetchLeadById = async (leadId: string) => {
    setLoadings(new Set(loadings).add("fetchLeadById"));
    await API.get("API", `/leads/${leadId}`, {})
      .then((response) => {
        setCurrentLeadInternal(response);
      })
      .catch()
      .finally(() => {
        const newLoadings = new Set(loadings);
        newLoadings.delete("fetchLeadById");
        setLoadings(newLoadings);
      });
  };

  const setCurrentLead = (currentLead: ILead | undefined) => {
    setCurrentLeadInternal(currentLead);
  };

  return (
    <LeadContext.Provider
      value={{
        loadings,
        fetchLeadsByUserId,
        fetchLeadById,
        userLeads,
        currentLead,
        setCurrentLead,
      }}
    >
      {children}
    </LeadContext.Provider>
  );
};

LeadProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export const LeadConsumer = LeadContext.Consumer;
