import * as React from "react";
import { IProject } from "../../../../../../../data/schemas";
import { useBudgetsUIContext } from "../../../../BudgetsUIContext";
import {
  CurrencyMetricCard,
  MetricCard,
  MetricCardProps,
} from "../../../../../../_components/MetricCard";

export interface InvoicedSupplementaryAgreementsCardProps {
  canSeeHiddenLine: boolean;
  project: Partial<IProject>;
}

export const InvoicedSupplementaryAgreementsCard: React.FC<
  InvoicedSupplementaryAgreementsCardProps & MetricCardProps
> = ({ canSeeHiddenLine, project, ...props }) => {
  const {
    budgetTotals: {
      invoicedSupplementaryAgreements = 0,
      supplementary_agreements,
      discountsSupplementaryAgreements = 0,
    },
  } = useBudgetsUIContext();

  const totalInvoicedSupplAgreementsPercentage =
    invoicedSupplementaryAgreements && supplementary_agreements
      ? (invoicedSupplementaryAgreements /
          (supplementary_agreements - discountsSupplementaryAgreements)) *
        100
      : 0;
  return (
    <CurrencyMetricCard
      dataCy="input-budget-invoiced-sa"
      value={invoicedSupplementaryAgreements}
      label="BUDGET.SUPPLEMENTARY_AGREEMENT_INVOICED"
      {...props}
    >
      <MetricCard.PercentageSubMetric
        dataCy="input-budget-invoiced-sa-percentage"
        value={totalInvoicedSupplAgreementsPercentage}
      />
    </CurrencyMetricCard>
  );
};
