import * as React from "react";
import { CustomCard, CustomCardLine } from "../../../../../../../_components/CustomCard";
import { FormattedMessage, useIntl } from "react-intl";
import { NumberInput } from "../../../../../../../_utils/formUtils";
import { cloneDeep, get, sumBy } from "lodash-es";
import { SUFFIX_EURO_CURRENCY, SUFFIX_PERCENTAGE } from "../../../../../../../_utils/suffixUtils";
import { Field, FieldArray, FieldArrayRenderProps, useFormikContext } from "formik";
import { ReactSortable } from "react-sortablejs";
import { Input } from "../../../../../../../../_metronic/_partials/controls";
import CreatableSelect from "react-select/creatable";
import { v4 as uuid } from "uuid";
import { accurateFloatOperation } from "../../../../../../../_utils/mathUtils";
import {
  IBudget,
  ILead,
  ISettings,
  isLeadFinancialDocumentPriceLine,
  LeadFinancialDocumentLine,
  LeadFinancialDocumentLineType,
  LeadFinancialDocumentPriceLine,
} from "../../../../../../../../data/schemas";
import { isBudgetServiceAvailable } from "../../../../../../../_utils/configUtils";
import { NumberFormatValues } from "react-number-format";
import { GroupBase, OnChangeValue } from "react-select";
import { ILeadFileInvoiceForm } from "./LeadFileInvoiceForm";
import cn from "clsx";
import { LeadInvoiceSA } from "./LeadInvoiceSA";
import { MixVATTooltip, WarningVATTooltip } from "./LeadInvoiceTooltips";
import { useLeadContext } from "../../../lead-edit/LeadContext";
import { flattenInvoiceLines } from "./LeadInvoiceUtils";
import TextareaAutosize from "@material-ui/core/TextareaAutosize/TextareaAutosize";
import WarningMessage from "app/_components/WarningMessage";

enum LeadOptionType {
  INSTALMENT = "INSTALMENT",
  FINANCIAL_DOCUMENT = "FINANCIAL_DOCUMENT",
}

interface SelectOption {
  id: string;
  label: string;
  isDisabled?: boolean;
}

interface LeadInvoiceLinesProps {
  lead: ILead;
  settings: ISettings;
  budget?: IBudget;
  currentConstructionPriceWithReducedVat?: number;
  setCurrentConstructionPriceWithReducedVat: React.Dispatch<React.SetStateAction<number>>;
  reducedVatAvailable: boolean;
}

export const LeadInvoiceLines: React.FC<LeadInvoiceLinesProps> = ({
  lead,
  budget,
  settings,
  currentConstructionPriceWithReducedVat,
  setCurrentConstructionPriceWithReducedVat,
  reducedVatAvailable,
}) => {
  const intl = useIntl();
  const { usedConstructionPriceWithReducedVat } = useLeadContext();
  const { values, setFieldValue, setFieldTouched, touched, errors } =
    useFormikContext<ILeadFileInvoiceForm>();

  React.useEffect(() => {
    const total = sumBy(flattenInvoiceLines(values.content), "indexedAmount");
    setFieldValue("amount", total);
    handleLineChange(values.content);
  }, [values.content]);

  const [priceIndexCoef, setPriceIndexCoef] = React.useState(0);
  React.useEffect(() => {
    const priceIndexCoef =
      values?.priceIndex?.value && values?.basePriceIndex?.value
        ? (values.priceIndex.value - values.basePriceIndex.value) / values.basePriceIndex.value
        : 0;
    setPriceIndexCoef(priceIndexCoef);
    const res = cloneDeep(values.content);
    for (const line of res) {
      if (isLeadFinancialDocumentPriceLine(line)) {
        line.indexedAmount = line.amount * (1 + priceIndexCoef);
        if (line.relatedFinancialDocuments) {
          for (const rfd of line.relatedFinancialDocuments) {
            for (const rfdLine of rfd.content) {
              if (isLeadFinancialDocumentPriceLine(rfdLine)) {
                rfdLine.indexedAmount = rfdLine.amount * (1 + priceIndexCoef);
              }
            }
          }
        }
      }
    }
    setFieldValue("content", res);
    // Update indexed amount when selected price index change
  }, [values.priceIndex]);

  const isBudgetAvailable = React.useMemo(() => isBudgetServiceAvailable(), []);

  const leadSupplementaryAgreements = React.useMemo(
    () =>
      lead?.financialDocuments?.filter(
        (f) => f.fileType === "SUPPLEMENTARY_AGREEMENT" && f.selectedForFinance
      ),
    [lead]
  );
  const leadInvoices = React.useMemo(
    () =>
      lead?.financialDocuments?.filter((f) => f.fileType === "INVOICE" && f.selectedForFinance) ??
      [],
    [lead]
  );

  const invoicedOptions = React.useMemo(() => {
    const invoicedOptions = new Set();
    for (const invoice of leadInvoices) {
      for (const line of invoice.content) {
        if (line.budgetInstalmentId) {
          invoicedOptions.add(line.budgetInstalmentId);
        } else if (line.financialDocumentId) {
          invoicedOptions.add(line.financialDocumentId);
        }
      }
    }
    return invoicedOptions;
  }, [leadInvoices]);

  const lineAlreadySelected = (optionId: string, lines: LeadFinancialDocumentLine[]) =>
    !!lines.find(
      (line) => line.budgetInstalmentId === optionId || line.financialDocumentId === optionId
    );

  const leadInvoiceOptions = React.useMemo((): GroupBase<SelectOption>[] => {
    const instalmentOptions = {
      label: intl.formatMessage({ id: "BUDGET.INSTALMENT" }),
      options: [
        {
          id: "SHARE_OF_LAND",
          label: intl.formatMessage({ id: "PRODUCT.INFO.SHARE_OF_LAND" }),
          optionType: LeadOptionType.INSTALMENT,
          isDisabled:
            invoicedOptions.has("SHARE_OF_LAND") ||
            lineAlreadySelected("SHARE_OF_LAND", values.content),
        },
        {
          id: "ARCHITECT_ENGINEERING_FEES",
          label: intl.formatMessage({ id: "PRODUCT.INFO.ARCHITECT_ENGINEERING_FEES" }),
          optionType: LeadOptionType.INSTALMENT,
          isDisabled:
            invoicedOptions.has("ARCHITECT_ENGINEERING_FEES") ||
            lineAlreadySelected("ARCHITECT_ENGINEERING_FEES", values.content),
        },
        ...(budget?.budgetInstalments?.map((instalment) => ({
          ...instalment,
          label:
            "instalment" in instalment
              ? `${instalment.label} - ${accurateFloatOperation(instalment.instalment * 100, 2)} %`
              : instalment.label,
          optionType: LeadOptionType.INSTALMENT,
        })) ?? []),
      ],
    };
    const supplementaryAgreementOptions = {
      label: intl.formatMessage({ id: "LEAD.FILE.TYPES.SUPPLEMENTARY_AGREEMENTS" }),
      options:
        leadSupplementaryAgreements
          ?.filter((sa) => !sa.content?.[0]?.budgetInstalmentId)
          ?.map((sa) => ({
            ...sa,
            label: sa.title,
            isDisabled: invoicedOptions.has(sa.id) || lineAlreadySelected(sa.id, values.content),
            optionType: LeadOptionType.FINANCIAL_DOCUMENT,
          })) ?? [],
    };
    return [instalmentOptions, supplementaryAgreementOptions];
  }, [budget, invoicedOptions, values]);

  const alreadyInvoicedWarning = React.useMemo(
    () =>
      budget?.budgetInstalments.some(
        (instalment) =>
          (invoicedOptions.has(instalment.id) &&
            lineAlreadySelected(instalment.id, values.content)) ||
          values.content.filter((line) => line.budgetInstalmentId === instalment.id).length >= 2
      ),
    [budget, invoicedOptions, values]
  );

  const changeLine = (selected: OnChangeValue<any, false>, lineIndex: number) => {
    const res = cloneDeep(values.content);

    const oldValue = res[lineIndex] as LeadFinancialDocumentPriceLine;

    // Default values of line when select or unselect an option
    res[lineIndex] = {
      id: res[lineIndex].id,
      label: selected?.label ?? "",
      vat: settings.defaultVat ?? 0,
      amount: 0,
      indexedAmount: 0,
      lineType: LeadFinancialDocumentLineType.PRICING,
    } as LeadFinancialDocumentPriceLine;
    const selectedLine = res[lineIndex] as LeadFinancialDocumentPriceLine;

    // If custom option or unselected option, no need to do the rest
    if (!selected?.id) {
      setFieldValue("content", res);
      return;
    }

    if (selected.optionType === LeadOptionType.INSTALMENT) {
      selectedLine.budgetInstalmentId = selected.id ?? "";
    } else if (selected.optionType === LeadOptionType.FINANCIAL_DOCUMENT) {
      selectedLine.financialDocumentId = selected.id ?? "";
    }
    if (selected.id === "SHARE_OF_LAND") {
      selectedLine.amount = lead.shareOfLandSellingPrice;
      selectedLine.vat = 0;
    } else if (selected?.id === "ARCHITECT_ENGINEERING_FEES") {
      selectedLine.amount = lead.architectEngineeringFees;
    } else if (selected.optionType === LeadOptionType.INSTALMENT) {
      selectedLine.amount = accurateFloatOperation(
        (selected?.instalment ?? 0) *
          (lead.sellingPrice - (lead.shareOfLandSellingPrice + lead.architectEngineeringFees)),
        2
      );
      const newConstructionPriceWithReducedVat =
        (currentConstructionPriceWithReducedVat ?? 0) -
        (oldValue.vat === settings?.reducedVat ? oldValue.indexedAmount : 0);
      if (
        reducedVatAvailable &&
        newConstructionPriceWithReducedVat < lead.constructionPriceWithReducedVat!
      ) {
        selectedLine.vat = settings.reducedVat ?? 0;
      }

      const invoicedSAForSelectedInstalment: string[] = [];
      for (const invoice of [...leadInvoices, values]) {
        for (const line of invoice.content) {
          if (line.budgetInstalmentId === selected.id) {
            invoicedSAForSelectedInstalment.push(
              ...(line.relatedFinancialDocuments?.map(
                (relatedFD) => relatedFD.financialDocumentId!
              ) ?? [])
            );
          }
        }
      }

      const filteredSA = leadSupplementaryAgreements.filter(
        (sa) =>
          sa.content?.[0]?.budgetInstalmentId === selected.id &&
          !invoicedSAForSelectedInstalment.includes(sa.id)
      );

      if (filteredSA.length) {
        selectedLine.relatedFinancialDocuments = filteredSA.map(({ id, content, title }) => ({
          financialDocumentId: id,
          title,
          content: content.map((line) => ({
            ...line,
            ...(isLeadFinancialDocumentPriceLine(line)
              ? {
                  vat: line.vat ?? settings?.defaultVat ?? 0,
                  indexedAmount: accurateFloatOperation(line.amount * (1 + priceIndexCoef), 2),
                }
              : {}),
          })),
        }));
      }
    } else if (selected.optionType === LeadOptionType.FINANCIAL_DOCUMENT) {
      selectedLine.amount = sumBy(selected.content, "amount");
      selectedLine.relatedFinancialDocuments = [
        {
          financialDocumentId: selected.id,
          title: selected.title,
          content: selected.content.map((line: any) => ({
            ...line,
            ...(isLeadFinancialDocumentPriceLine(line)
              ? {
                  vat: line.vat ?? settings?.defaultVat ?? 0,
                  indexedAmount: accurateFloatOperation(line.amount * (1 + priceIndexCoef), 2),
                }
              : {}),
          })),
        },
      ];
    }
    selectedLine.indexedAmount = accurateFloatOperation(
      selectedLine.amount * (1 + priceIndexCoef),
      2
    );
    setFieldValue("content", res);
  };

  const moveLine = (sortedLines: LeadFinancialDocumentLine[]) => {
    if (sortedLines.length) {
      setFieldValue("content", sortedLines);
    }
  };

  const removeLine = (linesArrayHelpers: FieldArrayRenderProps, lineIndex: number) => {
    linesArrayHelpers.remove(lineIndex);
    values.content.splice(lineIndex, 1);
  };

  const handleLineValueChange = <T extends keyof LeadFinancialDocumentPriceLine>(
    field: T,
    value: LeadFinancialDocumentPriceLine[T],
    lineIndex: number
  ) => {
    setFieldValue(`content.${lineIndex}.${field}`, value);
  };

  const hasRemainingCredit = (price: number) =>
    price + usedConstructionPriceWithReducedVat! < lead.constructionPriceWithReducedVat!;

  const removeProperty = (
    property: "hasMixVat" | "hasWarningVat",
    line: LeadFinancialDocumentPriceLine,
    setShouldUpdate: () => void
  ) => {
    if (line[property]) {
      delete line[property];
      setShouldUpdate();
    }
  };

  const handleLineChange = (lines: LeadFinancialDocumentLine[] = []) => {
    if (lead.constructionPriceWithReducedVat && reducedVatAvailable) {
      let price = 0;
      const res = cloneDeep(lines);
      const reducedVat = settings.reducedVat ?? 0;

      // Content is flattened, so it's easier to handle related financial documents but the references are kept so res variable is updated
      const flattenContent = flattenInvoiceLines(res);

      let shouldUpdate = false;
      const setShouldUpdate = () => (shouldUpdate = true);
      let applyWarningVat = false;
      for (const line of flattenContent) {
        if (line.vat === reducedVat) {
          // Handle Warning message when no remaining credit
          if (applyWarningVat || !hasRemainingCredit(price)) {
            removeProperty("hasMixVat", line, setShouldUpdate);
            if (!line.hasWarningVat) {
              line.hasWarningVat = true;
              shouldUpdate = true;
            }
            continue;
          } else {
            removeProperty("hasWarningVat", line, setShouldUpdate);
          }

          // Handle line with mix VAT
          price += line.indexedAmount;
          if (!hasRemainingCredit(price)) {
            if (!line.hasMixVat) {
              line.hasMixVat = true;
              applyWarningVat = true;
              shouldUpdate = true;
            }
          } else {
            removeProperty("hasMixVat", line, setShouldUpdate);
          }
        } else {
          removeProperty("hasWarningVat", line, setShouldUpdate);
          removeProperty("hasMixVat", line, setShouldUpdate);
        }
      }
      if (shouldUpdate) {
        setFieldValue("content", res);
      }
      setCurrentConstructionPriceWithReducedVat(price + usedConstructionPriceWithReducedVat);
    }
  };

  return (
    <>
      <CustomCard
        parentClassName={"border-light-dark"}
        header={
          <div className="form-row flex-grow-1 mr-12">
            <div className="col-8 d-flex">
              <h4>
                <FormattedMessage id="INVOICE.TITLE.LINES" />
              </h4>
            </div>
            <div className="col-1" />
            <div className="col-3 text-right d-flex align-items-center justify-content-end">
              <FormattedMessage id="INVOICE.TOTAL" />:
              <NumberInput
                value={values.amount}
                suffix={SUFFIX_EURO_CURRENCY}
                decimalScale={2}
                displayType="text"
                className={"font-weight-bold ml-2"}
              />
            </div>
          </div>
        }
      >
        <div className="d-flex">
          <div className="w-15px mr-2" />
          <div
            className="form-row flex-grow-1 px-2 pt-2 font-size-xs font-weight-bold line-height-sm"
            style={{ color: "#6a6a6a" }}
          >
            <div className="col">
              <FormattedMessage id="COMMON.DESCRIPTION" />
            </div>
            <div className="col max-w-70px">
              <FormattedMessage id="COMMON.VAT" />
            </div>
            <div className="col max-w-130px">
              <FormattedMessage id="COMMON.INDEXED_AMOUNT" />
            </div>
            <div className="col max-w-130px">
              <FormattedMessage id="COMMON.AMOUNT" />
            </div>
          </div>
          <div className="w-30px ml-4" />
        </div>
        <FieldArray
          name="content"
          render={(linesArrayHelpers) => (
            <>
              <ReactSortable
                list={values?.content ?? []}
                setList={(sortedLines) => moveLine(sortedLines)}
                swapThreshold={0.65}
                animation={150}
                fallbackOnBody={true}
                handle=".line-handle"
                scroll={true}
                bubbleScroll={true}
              >
                {values.content?.map((line, lineIndex) => {
                  const lineTouched = touched?.content?.[lineIndex]?.budgetInstalmentId;
                  const lineError = get(errors, `content.${lineIndex}`) as any;
                  const budgetLineLabelError =
                    lineTouched && lineError?.budgetInstalmentId && lineError?.label;
                  return (
                    <CustomCardLine
                      removable={values.content.length !== 1}
                      draggable={true}
                      remove={() => removeLine(linesArrayHelpers, lineIndex)}
                      key={line.id}
                      customClasses={
                        !!line.relatedFinancialDocuments
                          ? {
                              dragButton: "align-self-start mt-1",
                              actionsButton: "align-self-start",
                            }
                          : undefined
                      }
                    >
                      <div className="d-flex flex-column flex-grow-1">
                        <div className="form-row flex-grow-1">
                          {isLeadFinancialDocumentPriceLine(line) ? (
                            <>
                              <div className="col d-flex align-items-center">
                                <div className="flex-grow-1">
                                  {!isBudgetAvailable ? (
                                    <Field
                                      className="form-control form-control-sm"
                                      name={`content.${lineIndex}.label`}
                                      data-cy={`input-lead-invoice-${lineIndex}-label`}
                                      component={Input}
                                      value={line.label}
                                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                        handleLineValueChange("label", e.target.value, lineIndex)
                                      }
                                    />
                                  ) : (
                                    <div
                                      data-cy={`input-lead-invoice-${lineIndex}-budgetInstalmentId`}
                                    >
                                      <CreatableSelect<SelectOption>
                                        name={`content.${lineIndex}.budgetInstalmentId`}
                                        isSearchable
                                        isClearable
                                        onChange={(selected) => changeLine(selected, lineIndex)}
                                        options={leadInvoiceOptions}
                                        getOptionValue={(option) => option.id}
                                        getOptionLabel={(option) => option.label}
                                        placeholder={intl.formatMessage({
                                          id: "INVOICE.SELECT.LINE",
                                        })}
                                        classNamePrefix="creatable-select-sm"
                                        className={cn(
                                          "creatable-select-container",
                                          budgetLineLabelError && "is-invalid"
                                        )}
                                        menuPosition="absolute"
                                        styles={{
                                          container: (provided, state) => ({
                                            ...provided,
                                            width: "100%",
                                          }),
                                          menu: (provided) => ({ ...provided, zIndex: 9999 }),
                                        }}
                                        onBlur={() => {
                                          setFieldTouched(
                                            `content.${lineIndex}.budgetInstalmentId`
                                          );
                                        }}
                                      />
                                    </div>
                                  )}
                                </div>
                              </div>
                              <div className="col max-w-70px d-flex">
                                {!line.financialDocumentId && (
                                  <NumberInput
                                    name={`content.${lineIndex}.vat`}
                                    data-cy={`input-lead-invoice-${lineIndex}-vat`}
                                    className="form-control form-control-sm text-right"
                                    suffix={SUFFIX_PERCENTAGE}
                                    decimalScale={0}
                                    value={line.vat * 100}
                                    onValueChange={(e: NumberFormatValues) =>
                                      handleLineValueChange(
                                        "vat",
                                        (e.floatValue ?? 0) / 100,
                                        lineIndex
                                      )
                                    }
                                  />
                                )}
                              </div>
                              <div className="col max-w-130px d-flex align-items-center">
                                <NumberInput
                                  suffix={SUFFIX_EURO_CURRENCY}
                                  className={cn(
                                    "form-control form-control-sm text-right",
                                    line.financialDocumentId && "border-0 font-weight-bold"
                                  )}
                                  decimalScale={2}
                                  value={line.indexedAmount}
                                  data-cy={`input-lead-invoice-${lineIndex}-indexed-amount`}
                                  onValueChange={(e: NumberFormatValues) => {
                                    handleLineValueChange(
                                      "indexedAmount",
                                      e.floatValue ?? 0,
                                      lineIndex
                                    );
                                    handleLineValueChange(
                                      "amount",
                                      accurateFloatOperation(
                                        (e.floatValue ?? 0) / (1 + priceIndexCoef),
                                        2
                                      ),
                                      lineIndex
                                    );
                                  }}
                                  displayType={line.financialDocumentId ? "text" : "input"}
                                />
                              </div>
                              <div className="col max-w-130px d-flex align-items-center">
                                <NumberInput
                                  suffix={SUFFIX_EURO_CURRENCY}
                                  className={cn(
                                    "form-control form-control-sm text-right border-0",
                                    line.financialDocumentId && "font-weight-bold"
                                  )}
                                  decimalScale={2}
                                  value={line.amount}
                                  data-cy={`input-lead-invoice-${lineIndex}-amount`}
                                  displayType="text"
                                />
                                {line.hasMixVat && (
                                  <MixVATTooltip
                                    line={line}
                                    constructionPriceWithReducedVat={
                                      lead.constructionPriceWithReducedVat
                                    }
                                    currentConstructionPriceWithReducedVat={
                                      currentConstructionPriceWithReducedVat
                                    }
                                    defaultVat={settings.defaultVat}
                                    reducedVat={settings.reducedVat}
                                  />
                                )}
                                {line.hasWarningVat && <WarningVATTooltip />}
                              </div>
                            </>
                          ) : (
                            <div className={"d-flex col"}>
                              <Field
                                as={TextareaAutosize}
                                name={`content.${lineIndex}.label`}
                                className={cn(
                                  "form-control form-control-sm",
                                  get(touched, `content.${lineIndex}.label`) &&
                                    get(errors, `content.${lineIndex}.label`) &&
                                    "border-danger"
                                )}
                                value={line.label}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                  handleLineValueChange("label", e.target.value, lineIndex)
                                }
                              />
                            </div>
                          )}
                        </div>
                        {line.relatedFinancialDocuments?.map(
                          (financialDocument, financialDocumentIndex) => (
                            <LeadInvoiceSA
                              key={financialDocumentIndex}
                              financialDocument={financialDocument}
                              financialDocumentIndex={financialDocumentIndex}
                              lineIndex={lineIndex}
                              constructionPriceWithReducedVat={lead.constructionPriceWithReducedVat}
                              currentConstructionPriceWithReducedVat={
                                currentConstructionPriceWithReducedVat
                              }
                              settings={settings}
                              isLineFinancialDocument={!!line.financialDocumentId}
                            />
                          )
                        )}
                      </div>
                    </CustomCardLine>
                  );
                })}
              </ReactSortable>
              <div className="d-flex rounded rounded-top-0 overflow-hidden">
                <button
                  type="button"
                  className="btn btn-sm btn-light w-50 rounded-0 d-flex align-items-center  justify-content-center border-top-light-dark"
                  onClick={(e) =>
                    linesArrayHelpers.push({
                      id: uuid(),
                      budgetInstalmentId: undefined,
                      label: "",
                      amount: 0,
                      indexedAmount: 0,
                      vat: settings.defaultVat ?? 0,
                      lineType: LeadFinancialDocumentLineType.PRICING,
                    })
                  }
                >
                  <i className="ki ki-plus icon-nm" />
                  <FormattedMessage id="LEAD.ACTION.ADD.LINE" />
                </button>
                <button
                  type="button"
                  className="btn btn-sm btn-light w-50 border-left-1 border-left-light-dark flex-shrink-0 rounded-0 d-flex align-items-center  justify-content-center border-top-light-dark"
                  onClick={(e) =>
                    linesArrayHelpers.push({
                      id: uuid(),
                      label: "",
                      lineType: LeadFinancialDocumentLineType.DESCRIPTION,
                    })
                  }
                >
                  <i className="ki ki-plus icon-nm" />
                  <FormattedMessage id="FILE.ADD_DESCRIPTION" />
                </button>
              </div>
            </>
          )}
        />
      </CustomCard>
      {alreadyInvoicedWarning && (
        <WarningMessage className="my-4" messageId="LEAD.INVOICE.WARNING.INSTALMENT" />
      )}
    </>
  );
};
