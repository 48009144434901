import type { CustomSelectOption } from "./definitions";
import { ReactNode } from "react";
import { GroupBase } from "react-select";

export const formatGroupLabel = (group: GroupBase<unknown>): ReactNode => {
  const { label, icon, color } = group as any as CustomSelectOption;

  return (
    <>
      {icon && <i style={{ color: `${color}` }} className={`fas fa-${icon} icon-md`} />}
      <span className="pl-3">{label}</span>
    </>
  );
};

export default formatGroupLabel;
