import * as React from "react";
import { useIntl } from "react-intl";

import { TooltipHandler } from "app/_components/TooltipHandler";

import { getTaskText } from "app/_utils/tasks";

import { TaskItem, TaskItemProps } from "../TaskItem";

import "./TaskLaneItem.scss";

/** the item attribute represents the parent task */
export interface TaskLaneItemProps extends TaskItemProps {
  disableDrop?: boolean;
  renderItem?: boolean;
  subitems: TaskItemProps[];
  style?: any;
}

export const TaskLaneItem: React.FunctionComponent<TaskLaneItemProps> = ({
  disableDrop = false,
  renderItem,
  item,
  itemIndex = 0,
  subitems,
  style,
}) => {
  const intl = useIntl();

  const hasSubitems = subitems.length > 0;

  if (!hasSubitems) return <TaskItem item={item} itemIndex={itemIndex} style={style} />;

  const taskName = getTaskText(intl, item.name, item.attachments);
  const displayTooltipTaskName = taskName.length > 32;
  const key = (item.id ?? "") + subitems.map((i) => "_" + i.item.id)?.join("");

  return (
    <div className="task-lane-item" style={style} key={key}>
      {renderItem ? (
        <TaskItem item={item} itemIndex={itemIndex} disableDrop={disableDrop} />
      ) : (
        <TooltipHandler
          id={`tooltip:task-id:${item.id}`}
          message={taskName}
          disabled={!displayTooltipTaskName}
        >
          <div className="task-lane-item---header ellipsis">{taskName}</div>
        </TooltipHandler>
      )}
      <div className="task-lane-item--subitems">
        {subitems.map((subitem) => (
          <TaskItem key={subitem.item.id} {...subitem} disableDrop={disableDrop} />
        ))}
      </div>
    </div>
  );
};

export default TaskLaneItem;
