import React from "react";
import { ColumnFormatter } from "react-bootstrap-table-next";
import { IntlShape } from "react-intl";
import { eventTypes } from "./utilsAuditTrail";
import { formatDisplayNameIntl } from "../../_utils/userUtils";
import { Icon } from "../Icon";
import { IEvent } from "./models";
import { TRANSLATIONS_WITH_PARAM_VALUES } from "./utilsAuditTrailTranslations";

interface FormatExtraDataProps {
  intl: IntlShape;
  events: IEvent[];
}

interface RowProps {
  id: string;
}

export const AuditTrailDetailColumnFormatter: ColumnFormatter<RowProps, FormatExtraDataProps> = (
  cell,
  row,
  rowIndex,
  formatExtraData: any
) => {
  const event: any = row;
  const intl: IntlShape = formatExtraData?.intl;

  const getLabelEventType = (event: IEvent): React.ReactNode => {
    return (
      <>
        <div className={"mr-1"} />
        <div>
          {intl.formatMessage(
            {
              id: eventTypes[event.eventName]?.labelId,
            },
            {
              objectNameRelatedTo: event.objectNameRelatedTo,
              textPrimary: (chunks: any) => <span className="text-primary">{chunks}</span>,
            }
          )}
        </div>
      </>
    );
  };

  const formatDisplayedObject = (event: IEvent, root: boolean) => {
    // const oIdRelatedTo = root ? event.rootObjectIdRelatedTo : event.objectIdRelatedTo;
    const oTypeRelatedTo = root ? event.objectTypeRelatedTo : event.rootObjectTypeRelatedTo;

    const oIdDisplay =
      event.objectNameRelatedTo || oTypeRelatedTo?.toLowerCase().split("_").join(" ") || "";

    return (
      <>
        {/* skip adding related object's name if translations already contains it  */}
        {TRANSLATIONS_WITH_PARAM_VALUES.includes(event.eventName) ? (
          //   TODO fix urls - temporarily disabled -> needs to be checked based on entity type
          //   {oIdRelatedTo && oTypeRelatedTo ? (
          //   <a href={`/${oTypeRelatedTo}s/${oIdRelatedTo}`}>{oIdDisplay}</a>
          // ) : (          )}
          <></>
        ) : (
          <>
            <div className={"mr-1"} />
            <div className="text-primary">{oIdDisplay}</div>
          </>
        )}
      </>
    );
  };

  return (
    <>
      {/* event icon */}
      <div className={"d-flex align-items-center"}>
        <div className={"mr-4"}>
          {eventTypes[event.eventName]?.iconId && (
            <Icon name={eventTypes[event.eventName]?.iconId} />
          )}
        </div>
        {/* event content*/}
        <div className={"d-flex flex-column"}>
          <div className={"d-flex"}>
            {formatExtraData?.enableEventPrefixFormatting && (
              <>
                <div className={"font-weight-bold mr-1"}>
                  {event.user?.id ? (
                    <a href={`/users/${event.user?.id}`}>
                      {formatDisplayNameIntl(intl, event.user)}
                    </a>
                  ) : (
                    <div>{formatDisplayNameIntl(intl, event.user)}</div>
                  )}
                </div>

                {getLabelEventType(event)}
              </>
            )}
            {formatDisplayedObject(event, false)}
          </div>
        </div>
      </div>
    </>
  );
};
