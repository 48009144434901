import * as React from "react";
import { FormattedMessage } from "react-intl";

import { useRequestSignatureDialogContext } from "../RequestSignatureDialogContext";

export const CancelSignaturesRequest: React.FunctionComponent = () => {
  const { cancellationReason, setCancellationReason } = useRequestSignatureDialogContext();

  const changeHandler = (event: React.SyntheticEvent<HTMLTextAreaElement>) => {
    const { currentTarget } = event;
    setCancellationReason(currentTarget.value);
  };

  return (
    <div className="d-flex flex-column">
      <div>
        <FormattedMessage id="COMMON.ACTION.CANCEL.ARE_YOU_SURE.SIGNATURES_REQUEST.DESCRIPTION" />
      </div>

      <div className="d-flex flex-column mt-6">
        <label>
          <FormattedMessage id="COMMON.ACTION.CANCEL.ARE_YOU_SURE.SIGNATURES_REQUEST.REASON_LABEL" />
        </label>
        <textarea className="form-control" value={cancellationReason} onChange={changeHandler} />
      </div>
    </div>
  );
};

export default CancelSignaturesRequest;
