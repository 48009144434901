/**
 * Entry application component used to compose providers and render Routes.
 * */

import React, { useEffect } from "react";
import * as Sentry from "@sentry/react";
import { Provider } from "react-redux";
import { BrowserRouter, useHistory } from "react-router-dom";
import { PersistGate } from "redux-persist/integration/react";

import { intlPolyfills } from "../polyfills";

import { I18nProvider } from "_metronic/i18n";
import { LayoutSplashScreen, MaterialThemeProvider } from "_metronic/layout";

import { SetupAmplifyAxiosProvider } from "app/_components/SetupAmplifyAxios";
import { SnackBarNotificationsDialog } from "app/modules/Notifications/component/SnackBarNotificationsDialog";
import { Routes } from "app/Routes";
import { Intercom } from "./_utils/intercomUtils";
import { TranslationProvider } from "./contexts/TranslationContext";
import { AuthProvider } from "./v2/contexts";

(async () => await intlPolyfills())();

function App({ store, persistor, basename }) {
  useEffect(() => {
    Intercom.load();
  }, []);

  const history = useHistory();
  useEffect(() => {
    if (history) {
      history.listen((location) => {
        Intercom.update();
      });
    }
  }, [history]);

  return (
    /* Provide Redux store */
    <Provider store={store}>
      {/* Asynchronously persist redux stores and show `SplashScreen` while it's loading. */}
      <PersistGate persistor={persistor} loading={<LayoutSplashScreen />}>
        {/* Add high level `Suspense` in case if was not handled inside the React tree. */}
        <React.Suspense fallback={<LayoutSplashScreen />}>
          {/* Override `basename` (e.g: `homepage` in `package.json`) */}
          <BrowserRouter basename={basename}>
            {/*This library only returns the location that has been active before the recent location change in the current window lifetime.*/}
            <MaterialThemeProvider>
              {/* Provide `react-intl` context synchronized with Redux state.  */}
              <I18nProvider>
                <TranslationProvider>
                  <AuthProvider>
                    <SetupAmplifyAxiosProvider>
                      <SnackBarNotificationsDialog />
                      {/* Render routes with provided `Layout`. */}
                      <Routes />
                    </SetupAmplifyAxiosProvider>
                  </AuthProvider>
                </TranslationProvider>
              </I18nProvider>
            </MaterialThemeProvider>
          </BrowserRouter>
        </React.Suspense>
      </PersistGate>
    </Provider>
  );
}

export default Sentry.withProfiler(App);
