import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../../../_metronic/_helpers";
import * as React from "react";
import { useCardTableContext } from "../../../context/CardTableContext";

export const ExpandAll = () => {
  const { table } = useCardTableContext();

  return (
    <OverlayTrigger
      overlay={
        <Tooltip id="budgets-expand-tooltip">
          <FormattedMessage id="TOOLTIP.EXPAND_ALL" />
        </Tooltip>
      }
    >
      <button
        type="button"
        className="btn btn-icon btn-light-secondary mr-3"
        onClick={() => table.toggleAllRowsExpanded()}
      >
        <span className="svg-icon svg-icon-md svg-icon-primary">
          <SVG src={toAbsoluteUrl("/media/svg/icons/General/Size.svg")} />
        </span>
      </button>
    </OverlayTrigger>
  );
};
