import { Route, Switch } from "react-router-dom";
import * as React from "react";
import { SettingsPage } from "./pages";

export const Routes = () => {
  return (
    <Switch>
      <Route path="/settings" exact render={() => <SettingsPage />} />
    </Switch>
  );
};
