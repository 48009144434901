import { Button, Spinner } from "react-bootstrap";
import * as React from "react";
import { useTranslation } from "../../../../hooks/useTranslation";

interface KycButtonProps {
  isLoading?: boolean;
  loadingMessage?: string;
  children?: React.ReactNode;
  label: string;
  disabled?: boolean;
  [rest: string]: any;
}

export const KycButton = ({
  label,
  isLoading = false,
  loadingMessage = "",
  disabled = false,
  ...rest
}: KycButtonProps): JSX.Element => {
  const { fm } = useTranslation();

  return (
    <Button className="form-control-reduce-space" disabled={disabled || isLoading} {...rest}>
      {isLoading ? (
        <>
          <span className="visually-hidden">{fm(loadingMessage)}...</span>
          <Spinner
            as="span"
            animation="border"
            size="sm"
            role="status"
            aria-hidden="true"
            className="ml-4"
          />
        </>
      ) : (
        fm(label)
      )}
    </Button>
  );
};
