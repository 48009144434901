import TimeAgo from "react-timeago";

import { getTaskText, getTimeAgoStringForIntl, TIME_MODES } from "app/_utils/tasks";

export const UserNotificationsDropdownTabPaneItem = ({ notification, intl }) => {
  return (
    <>
      {notification && (
        <a
          href={notification.url}
          target={notification.blank ? "_blank" : "_self"}
          // Using target="_blank" without rel="noreferrer" is a security risk: see https://html.spec.whatwg.org/multipage/links.html#link-type-noopener  react/jsx-no-target-blank
          rel="noreferrer"
          className="navi-item"
          key={notification.id}
          style={{ background: notification.readAt ? "" : "#F3F6F9" }}
        >
          <div className="navi-link">
            <div className="navi-icon mr-2">
              <i className={`${notification.icon} ${notification.colorClass}`} />
            </div>
            <div className="navi-text">
              <div className="font-weight-normal">{notification.label}</div>
              <div className="font-weight-normal">
                {notification.taskRelatedTo && (
                  <i
                    style={{
                      color: notification.taskRelatedTo?.color || "f2f2f2",
                      margin: "4px",
                    }}
                    className={`fa fas fa-${notification.taskRelatedTo.icon} mr-2`}
                  />
                )}
                {notification?.tabKey === "tasks" && (
                  <span>
                    {getTaskText(intl, notification.parameters.taskName, notification.parameters)}
                  </span>
                )}
              </div>
              <div className="text-muted">
                <TimeAgo
                  date={notification.createdAt}
                  formatter={(value, unit, suffix) => {
                    return getTimeAgoStringForIntl(
                      undefined,
                      value,
                      unit,
                      intl,
                      TIME_MODES.NOTIFICATION
                    );
                  }}
                />
              </div>
            </div>
          </div>
        </a>
      )}
    </>
  );
};
