// React bootstrap table next =>
// DOCS: https://react-bootstrap-table.github.io/react-bootstrap-table2/docs/
// STORYBOOK: https://react-bootstrap-table.github.io/react-bootstrap-table2/storybook/index.html
import React, { useEffect, useMemo } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, { PaginationProvider } from "react-bootstrap-table2-paginator";
import * as uiHelpers from "./LeadFilesUIHelper";
import { Pagination } from "../../../../../../_metronic/_partials/controls";
import {
  NoRecordsFoundMessage,
  PleaseWaitMessage,
  sortCaret,
} from "../../../../../../_metronic/_helpers";
import { useLeadFilesUIContext } from "./LeadFilesUIContext";
import { DocumentTypeColumnFormatter } from "../../../../Common/DocumentTypeColumnFormatter";
import {
  ActionsColumnFormatter,
  InvoiceStatusColumnFormatter,
  TotalColumnFormatter,
  UploadedDateTimeColumnFormatter,
} from "../../../../../_components/column-formatters";
import { useQueryState } from "react-router-use-location-state";
import * as actions from "../../../_redux/leads/leadsActions";
import { useIntl } from "react-intl";
import { useEntityDeleteDialogContext } from "../../entity-delete-dialog/EntityDeleteDialogContext";
import { INVOICE_STATUS, LEAD_FILE_TYPES } from "../../../../../_utils/listUtils";
import { canEdit, canReadAll, isAdmin } from "../../../../../_utils/authUtils";
import { USER } from "../../../../../_utils/userTypes";
import { useSearch } from "../../../../../hooks/useSearch";
import {
  displayColumnIsConfidential,
  displayColumnRowHighlightIndicator,
  sortDateFunc,
} from "../../../../../_utils/columnUtils";
import { useConfirmationDialogContext } from "../../../../../_components/ConfirmationDialog";
import { columnTags } from "../../../../../_utils/tagsUtils";
import { useFileViewer } from "../../../../../hooks/useFileViewer";

export function LeadFilesTable() {
  const intl = useIntl();
  const leadFilesUIContext = useLeadFilesUIContext();
  const dispatch = useDispatch();
  const { setDeleteEntityDialog } = useEntityDeleteDialogContext();
  const { setEntities, entitiesFiltered } = useSearch();
  const { setConfirmationDialog } = useConfirmationDialogContext();
  const { downloadAndOpenFilesInViewer } = useFileViewer();

  const leadFilesUIProps = useMemo(() => {
    return {
      ids: leadFilesUIContext.ids,
      setIds: leadFilesUIContext.setIds,
      filters: leadFilesUIContext.filters,
      setFilters: leadFilesUIContext.setFilters,
      leadId: leadFilesUIContext.leadId,
      openRequestSignatureDialog: leadFilesUIContext.openRequestSignatureDialog,
      openFileDialog: leadFilesUIContext.openFileDialog,
      setIsInvoice: leadFilesUIContext.setIsInvoice,
      openEditFileDialog: leadFilesUIContext.openEditFileDialog,
      readOnly: leadFilesUIContext.readOnly,
      filterList: leadFilesUIContext.filterList,
    };
  }, [leadFilesUIContext]);

  const [highlightId] = useQueryState("h", "");

  const { lead, totalCount, entities, userId, session, groups } = useSelector(
    (state) => ({
      lead: state.leads?.leadForEdit?.current,
      totalCount: state.leads?.leadForEdit?.current?.files?.length || 0,
      entities: state.leads?.leadForEdit?.current?.files || null,
      userId: state.auth.session.id,
      session: state.auth.session,
      groups: state.auth.groups,
    }),
    shallowEqual
  );

  useEffect(() => {
    if (!entities) {
      dispatch(actions.fetchLead(leadFilesUIProps.leadId));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [leadFilesUIProps.filters, leadFilesUIProps.leadId]);

  useEffect(() => {
    setEntities(entities);
  }, [entities]);

  const openDeleteFileDialog = (id, leadFinancialDocumentId) => {
    setDeleteEntityDialog({
      action: {
        fn: actions.deleteFile,
        props: { id, leadId: leadFilesUIProps.leadId, leadFinancialDocumentId },
      },
      entityType: "LEAD.FILE",
    });
  };

  const supplementAlreadyInvoice = (financialDocumentId) =>
    lead.financialDocuments.some(
      (f) =>
        f.fileType === "INVOICE" &&
        f.content?.some((invoiceLine) =>
          invoiceLine.relatedFinancialDocuments?.some(
            (rfd) => rfd.financialDocumentId === financialDocumentId
          )
        )
    );

  const columns = [
    {
      dataField: "id",
      text: "",
      sort: false,
      formatter: DocumentTypeColumnFormatter,
    },
    {
      dataField: "friendlyName",
      text: intl.formatMessage({
        id: "COMMON.NAME",
      }),
      sort: true,
      sortCaret: sortCaret,
      headerClasses: "text-left text-nowrap",
      classes: "text-left",
    },
    {
      dataField: "fileType",
      text: intl.formatMessage({
        id: "COMMON.TYPE",
      }),
      sort: true,
      sortCaret: sortCaret,
      headerClasses: "text-left text-nowrap",
      classes: "text-left",
      formatter: (cell) => <span>{intl.formatMessage({ id: LEAD_FILE_TYPES[cell] })}</span>,
      sortValue: (cell) => intl.formatMessage({ id: LEAD_FILE_TYPES[cell] }),
    },
    {
      dataField: "uploadedAt",
      text: intl.formatMessage({
        id: "COMMON.UPLOADED.DATE",
      }),
      sort: true,
      sortCaret: sortCaret,
      formatter: UploadedDateTimeColumnFormatter,
      formatExtraData: {
        readOnly: leadFilesUIProps.readOnly,
        userId,
        intl,
      },
      sortFunc: sortDateFunc,
      headerClasses: "text-left text-nowrap",
      classes: "text-left",
    },
    {
      dataField: "invoiceStatus",
      text: intl.formatMessage({
        id: "COMMON.STATUS",
      }),
      sort: true,
      sortCaret: sortCaret,
      headerClasses: "text-left text-nowrap",
      classes: "text-left",
      formatter: InvoiceStatusColumnFormatter,
      formatExtraData: {
        intl: intl,
      },
      sortValue: (cell) => intl.formatMessage({ id: INVOICE_STATUS[cell] }),
    },
    {
      dataField: "amount",
      text: intl.formatMessage({
        id: "COMMON.AMOUNT",
      }),
      sort: true,
      sortCaret: sortCaret,
      headerClasses: "text-left text-nowrap",
      formatter: TotalColumnFormatter,
    },
    {
      dataField: "amountDue",
      text: intl.formatMessage({
        id: "COMMON.INVOICE.AMOUNT_DUE",
      }),
      sort: true,
      sortCaret: sortCaret,
      headerClasses: "text-left text-nowrap",
      formatter: (cell, row) =>
        TotalColumnFormatter(
          row?.invoiceStatus === "PARTIALLY_PAID"
            ? (row?.amount ?? 0) - (row?.amountPaidToDate ?? 0)
            : "",
          row
        ),
    },
    columnTags(groups, session, intl),
    {
      dataField: "action",
      text: intl.formatMessage({
        id: "ACTION.TITLE",
      }),
      formatter: ActionsColumnFormatter,
      formatExtraData: {
        openRequestSignatureDialog: leadFilesUIProps.openRequestSignatureDialog,
        openEditDialog: (id, invoiceStatus) => {
          leadFilesUIProps.openEditFileDialog(id);
        },
        openDeleteDialog: (id, entityType, file) => {
          if (
            !file.leadFinancialDocumentId ||
            file.fileType === "INVOICE" ||
            !supplementAlreadyInvoice(file.leadFinancialDocumentId)
          ) {
            openDeleteFileDialog(id, file.leadFinancialDocumentId);
          } else {
            setConfirmationDialog({
              title: intl.formatMessage({ id: "FILE.TITLE.DELETE" }),
              body: intl.formatMessage({
                id: "LEAD.FILE.SUPPLEMENT_INVOICED_CANT_DELETE",
              }),
              hideButtonLabel: intl.formatMessage({
                id: "COMMON.ACTION.OK",
              }),
              hideButtonClassName: "btn-primary",
            });
          }
        },
        actionList: ["SIGNATURE", "WRITE", "TRASH"],
        canRequestSignature: () => {
          return isAdmin(groups, session) || session?.userTypes?.includes(USER);
        },
        canDelete: (row) => {
          return canEdit(groups, session, "LEAD");
        },
        canEdit: (row) => {
          return (
            (canEdit(groups, session, "LEAD") && !row.invoiceStatus) ||
            canReadAll(groups, session, "BUDGET")
          );
        },
        entityType: "FILE",
      },
      classes: "text-right",
      headerClasses: "text-right",
      style: {
        minWidth: "100px",
      },
      hidden: leadFilesUIProps.readOnly,
    },
  ];

  displayColumnIsConfidential({ entitiesFiltered, columns });

  displayColumnRowHighlightIndicator({
    entitiesFiltered,
    columns,
    id: highlightId,
  });

  const paginationOptions = {
    custom: true,
    totalSize: totalCount,
    sizePerPageList: uiHelpers.sizePerPageList,
    sizePerPage: leadFilesUIProps.filters.pageSize,
    page: leadFilesUIProps.filters.pageNumber,
  };

  return (
    <PaginationProvider pagination={paginationFactory(paginationOptions)}>
      {({ paginationProps, paginationTableProps }) => {
        return (
          <Pagination paginationProps={paginationProps}>
            <BootstrapTable
              wrapperClasses="table-responsive"
              classes="table table-head-custom table-vertical-center"
              bordered={false}
              bootstrap4
              keyField="id"
              data={entitiesFiltered || []}
              columns={columns}
              defaultSorted={uiHelpers.defaultSorted}
              hover
              rowEvents={{
                onClick: (e, fileRowItem, rowIndex) => {
                  downloadAndOpenFilesInViewer([fileRowItem]);
                },
              }}
              rowStyle={{ cursor: "pointer" }}
              {...paginationTableProps}
            >
              <PleaseWaitMessage entities={entities} />
              <NoRecordsFoundMessage entities={entities} />
            </BootstrapTable>
          </Pagination>
        );
      }}
    </PaginationProvider>
  );
}
