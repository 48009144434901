import * as React from "react";
import { useIntl } from "react-intl";
import cn from "clsx";

import { DateUtils } from "app/_utils/DateUtils";

import { ISignaturesRequest } from "data/schemas/signatures";

import {
  RequestSignatureDialogUiState,
  TRequestSignatureDialogUiState,
} from "../RequestSignatureDialogContext";

import "./ViewSignaturesRequest.scss";

import { DisplayInfo } from "./DisplayInfo";
import { ViewSignatory } from "./ViewSignatory";

interface ViewSignaturesRequestProps {
  className?: string;
  uiState: TRequestSignatureDialogUiState;
  signaturesRequest: ISignaturesRequest;
}

export const ViewSignaturesRequest: React.FunctionComponent<ViewSignaturesRequestProps> = ({
  className,
  uiState,
  signaturesRequest,
}) => {
  const intl = useIntl();

  if (!signaturesRequest) return null;

  const {
    signatories = [],
    createdAt,
    sealedAt,
    updatedAt,
    notifications,
    cancelledReason,
  } = signaturesRequest;

  const signatoriesLength = signatories.length;
  const signatoriesLastIndex = signatoriesLength === 0 ? 0 : signatoriesLength - 1;

  const isPreviousSignaturesRequest = [
    `${RequestSignatureDialogUiState.EDIT}`,
    `${RequestSignatureDialogUiState.CREATE}`,
  ].includes(uiState);

  const title = intl.formatMessage({
    id: isPreviousSignaturesRequest ? "COMMON.SIGNATURES.LAST" : "COMMON.SIGNATURES",
  });

  const notificationsLabel = notifications
    .map((key) => intl.formatMessage({ id: `COMMON.${key}` }))
    .join(" + ");

  return (
    <div className={cn("view-signatures-request", className)}>
      <header>
        <div className="title">{title}</div>

        <div className="infos-container">
          <DisplayInfo
            label={intl.formatMessage({ id: "COMMON.ACTION.REQUESTED" })}
            value={
              <span className="date requested">
                {DateUtils.format(new Date(createdAt!), intl, false)}
              </span>
            }
          />

          {uiState === RequestSignatureDialogUiState.EDIT ? (
            <DisplayInfo
              label={intl.formatMessage({ id: "COMMON.ACTION.CANCELLED" })}
              value={
                <span className="date cancelled">
                  {DateUtils.format(new Date(updatedAt!), intl, false)}
                </span>
              }
            />
          ) : null}

          {uiState === RequestSignatureDialogUiState.DONE ? (
            <DisplayInfo
              label={intl.formatMessage({ id: "COMMON.SEALED" })}
              value={
                <span className="date sealed">
                  {DateUtils.format(new Date(sealedAt!), intl, false)}
                </span>
              }
            />
          ) : null}

          <DisplayInfo
            label={intl.formatMessage({ id: "COMMON.NOTIFICATION" })}
            value={notificationsLabel}
          />
        </div>
      </header>

      <main className="collection">
        <div className="item item-header">
          <div className="attribute avatar"></div>
          <div className="attribute name"></div>
          <div className="attribute email"></div>
          <div className="attribute phone"></div>
          <div className="attribute status"></div>
        </div>

        <div className="gradient gradient-before"></div>

        {signatories.map((signatory, index) => (
          <ViewSignatory
            key={index}
            className={cn({ last: index === signatoriesLastIndex })}
            isPreviousSignaturesRequest={isPreviousSignaturesRequest}
            signatory={signatory}
          />
        ))}

        <div className="gradient gradient-after"></div>
      </main>

      {cancelledReason ? (
        <footer>
          <span className="font-weight-bold footer-label">
            {intl.formatMessage({ id: "COMMON.CANCELLATION_REASON" })}:
          </span>
          <span className="font-weight-light">{cancelledReason}</span>
        </footer>
      ) : null}
    </div>
  );
};

export default ViewSignaturesRequest;
