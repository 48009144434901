import * as React from "react";

export interface TaskLaneHeaderProps {
  title: React.ReactNode;
  index: number;
}

export const TaskLaneHeader: React.FunctionComponent<TaskLaneHeaderProps> = ({ title, index }) => (
  <div className="position-sticky top-0 zindex-1">
    <h6
      className="user-select-none text-weight-bold pt-2 pb-2 m-0 w-100 text-white bg-primary rounded text-center"
      key={`lane-header-${index}`}
    >
      {title}
    </h6>
  </div>
);

export default TaskLaneHeader;
