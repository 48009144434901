import React, { useEffect, useMemo } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../../../_metronic/_partials/controls";
import { ProjectsTable } from "./projects-table/ProjectsTable";
import { useProjectsUIContext } from "./ProjectsUIContext";
import { canCreate } from "../../../../_utils/authUtils";
import { shallowEqual, useSelector } from "react-redux";
import { useIntl } from "react-intl";
import { useSubheader } from "../../../../../_metronic/layout";
import { SearchLocal } from "../../../../_components/Search/SearchLocal";
import ActionNewButton from "app/_components/ActionNewButton";

export const ProjectsCard = () => {
  const intl = useIntl();
  const { groups, session } = useSelector(
    (state) => ({
      groups: state.auth.groups,
      session: state.auth.session,
    }),
    shallowEqual
  );

  const projectsUIContext = useProjectsUIContext();
  const projectsUIProps = useMemo(() => {
    return {
      ids: projectsUIContext.ids,
      queryParams: projectsUIContext.queryParams,
      setQueryParams: projectsUIContext.setQueryParams,
      newProjectButtonClick: projectsUIContext.newProjectButtonClick,
    };
  }, [projectsUIContext]);

  const subheader = useSubheader();
  useEffect(() => {
    subheader.setTitle(
      intl.formatMessage({
        id: "PROJECT.TITLE",
      })
    );
  }, [subheader]);

  return (
    <Card>
      <CardHeader
        title={intl.formatMessage({
          id: "PROJECT.TITLE.LIST",
        })}
      >
        <CardHeaderToolbar>
          {canCreate(groups, session, "PROJECT") && (
            <ActionNewButton
              dataCy="button-projects-new"
              onClick={projectsUIProps.newProjectButtonClick}
              messageId="PROJECT.ACTION.NEW"
            />
          )}
        </CardHeaderToolbar>
      </CardHeader>
      <CardBody>
        {/*<Filter useEntityUIContext={useProjectsUIContext} />*/}
        <div className={"margin-bottom-10-mobile w-100 mb-2 mw-100"}>
          <SearchLocal useEntityUIContext={useProjectsUIContext} />
        </div>
        <ProjectsTable />
      </CardBody>
    </Card>
  );
};
