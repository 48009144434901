import {
  processSnackbarNotification,
  SNACKBAR_MESSAGE,
} from "app/modules/Common/SnackbarNotificationsHandler";

import { createSignaturesRequest, cancelSignaturesRequest } from "data/api/signatures";

import { ISignaturesRequestBody, ISignaturesRequest, IFile } from "data/schemas";

//----------------------------------------------------------------------------//

export const requestFileSignature = (
  actions: any,
  signatureRequest: ISignaturesRequestBody,
  dispatch: Function,
  callTypes: any
) => {
  dispatch(actions.startCall({ callType: callTypes.action }));

  return createSignaturesRequest(signatureRequest)
    .then((response) => {
      const { file, signaturesRequest } = response;

      processSnackbarNotification(SNACKBAR_MESSAGE.SUCCESS.REQUEST_FILE_SIGNATURE, dispatch);

      dispatch(actions.signaturesRequestCreated({ file, signaturesRequest }));

      return signaturesRequest;
    })
    .catch((error) => {
      dispatch(actions.catchError({ error, callType: callTypes.action }));

      processSnackbarNotification(SNACKBAR_MESSAGE.DANGER.REQUEST_FILE_SIGNATURE, dispatch);
    });
};

export const cancelFileSignaturesRequest = (
  actions: any,
  signaturesRequest: ISignaturesRequest,
  dispatch: Function,
  callTypes: any
) => {
  dispatch(actions.startCall({ callType: callTypes.action }));

  return cancelSignaturesRequest(signaturesRequest)
    .then((response) => {
      const { file, signaturesRequest } = response;

      dispatch(actions.signaturesRequestCancelled({ file, signaturesRequest }));

      return signaturesRequest;
    })
    .catch((error) => {
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

//----------------------------------------------------------------------------//

interface IForEdit {
  files?: IFile[];
}

interface ISliceSignaturesRequestFileHelperOptions {
  file: IFile;
  current?: IForEdit;
  saved?: IForEdit;
}

export const sliceSignaturesRequestFileHelper = ({
  file,
  current,
  saved,
}: ISliceSignaturesRequestFileHelperOptions) => {
  const { id: fileId } = file;

  const filesMapFn = (previousFile: IFile) => (previousFile.id === fileId ? file : previousFile);

  if (current && current.files && current.files.length > 0) {
    current.files = current.files.map(filesMapFn);
  }

  if (saved && saved.files && saved.files?.length > 0) {
    saved.files = saved.files.map(filesMapFn);
  }
};

//----------------------------------------------------------------------------//
