import React from "react";
import SVG from "react-inlinesvg";
import { ColumnFormatter } from "react-bootstrap-table-next";
import { getIconFilePath } from "../../../../../_utils/fileUtils";

interface RowProps {
  mimeType: string;
}

export const KycDocumentTypeColumnFormatter: ColumnFormatter<RowProps> = (cell, row) => {
  return (
    <span className="svg-icon svg-icon-2x">
      <SVG src={getIconFilePath(row?.mimeType)} />
    </span>
  );
};
