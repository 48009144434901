import { useKycFlow } from "../../../hooks/useKycFlow";
import { KycUploadComponent } from "./controls/kyc-upload/KycUploadComponent";
import { KycTranslation } from "../KycTranslation";
import { getKycAdditionalDocumentsRelatedEntityType } from "../KycUtils";

export const KycStepKycAdditionalDocumentsLuOrForeignCompany = (): JSX.Element => {
  const { isClientViewer, values } = useKycFlow();

  const getKycRelatedEntity = () => {
    return getKycAdditionalDocumentsRelatedEntityType(values);
  };

  return (
    <>
      <KycUploadComponent
        name="evidenceRegisterOfShareHolders"
        subLabel={KycTranslation.EVIDENCE_DOCUMENT_NAME_REGISTER_OF_SHAREHOLDERS}
        label={KycTranslation.EVIDENCE_DOCUMENT_NAME_REGISTER_OF_SHAREHOLDERS_SHORT}
        kycRelatedToEntity={getKycRelatedEntity()}
        multiple={true}
      />
      <KycUploadComponent
        name="evidenceCertificateIncumbency2"
        subLabel={KycTranslation.EVIDENCE_DOCUMENT_NAME_CERTIFICATE_INCUMBENCY_2}
        label={KycTranslation.EVIDENCE_DOCUMENT_NAME_CERTIFICATE_INCUMBENCY_2_SHORT}
        kycRelatedToEntity={getKycRelatedEntity()}
        multiple={true}
      />
      <KycUploadComponent
        name="evidenceAccountsYearlyPublic"
        subLabel={KycTranslation.EVIDENCE_DOCUMENT_NAME_ACCOUNTS_YEARLY_PUBLIC}
        label={KycTranslation.EVIDENCE_DOCUMENT_NAME_ACCOUNTS_YEARLY_PUBLIC_SHORT}
        kycRelatedToEntity={getKycRelatedEntity()}
        multiple={true}
      />
      <KycUploadComponent
        name="evidenceCopyAuthorisation"
        subLabel={KycTranslation.EVIDENCE_DOCUMENT_NAME_COPY_AUTHORISATION}
        label={KycTranslation.EVIDENCE_DOCUMENT_NAME_COPY_AUTHORISATION_SHORT}
        kycRelatedToEntity={getKycRelatedEntity()}
        multiple={true}
      />
      <KycUploadComponent
        name="evidenceAgreementCSSF"
        subLabel={KycTranslation.EVIDENCE_DOCUMENT_NAME_AGREEMENT_CSSF}
        label={KycTranslation.EVIDENCE_DOCUMENT_NAME_AGREEMENT_CSSF_SHORT}
        kycRelatedToEntity={getKycRelatedEntity()}
        multiple={true}
      />
      <KycUploadComponent
        name="evidencePrintScreenControlInstit"
        subLabel={KycTranslation.EVIDENCE_DOCUMENT_NAME_PRINT_SCREEN_CONTROL_INSTIT}
        label={KycTranslation.EVIDENCE_DOCUMENT_NAME_PRINT_SCREEN_CONTROL_INSTIT_SHORT}
        kycRelatedToEntity={getKycRelatedEntity()}
        multiple={true}
      />
      <KycUploadComponent
        name="evidenceSocialDocsShareholders"
        subLabel={KycTranslation.EVIDENCE_DOCUMENT_NAME_SHAREHOLDER_SOCIAL_DOCS}
        label={KycTranslation.EVIDENCE_DOCUMENT_NAME_SHAREHOLDER_SOCIAL_DOCS_SHORT}
        classNameLabel="text-justify"
        kycRelatedToEntity={getKycRelatedEntity()}
        multiple={true}
      />
      {!isClientViewer && (
        <>
          <KycUploadComponent
            name="evidenceLFCLCWorldCheck"
            subLabel={KycTranslation.EVIDENCE_DOCUMENT_L_FC_LC_WORLD_CHECK}
            label={KycTranslation.EVIDENCE_DOCUMENT_L_FC_LC_WORLD_CHECK_SHORT}
            kycRelatedToEntity={getKycRelatedEntity()}
            multiple={true}
          />
          <KycUploadComponent
            name="evidenceLFCLCInternetCheck"
            subLabel={KycTranslation.EVIDENCE_DOCUMENT_L_FC_LC_INTERNET_CHECK}
            label={KycTranslation.EVIDENCE_DOCUMENT_L_FC_LC_INTERNET_CHECK_SHORT}
            kycRelatedToEntity={getKycRelatedEntity()}
            multiple={true}
          />
        </>
      )}
    </>
  );
};
