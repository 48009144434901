import * as React from "react";
import { SUFFIX_PERCENTAGE } from "../../../../../../_utils/suffixUtils";
import { useBudgetRevenueContext } from "../../../context/BudgetRevenueContext";
import {
  PercentageMetricCard,
  MetricCard,
  MetricCardProps,
} from "../../../../../../_components/MetricCard";

export const InstalmentInvoiceShare: React.FC<MetricCardProps> = (props) => {
  const { instalmentRevenue, soldNotInvoicedInstalments, soldInvoicedInstalments } =
    useBudgetRevenueContext();

  const invoicedPercentage =
    (soldInvoicedInstalments / (soldNotInvoicedInstalments + soldInvoicedInstalments)) * 100;

  let total = instalmentRevenue?.length ?? 0;
  let fullyInvoiced = 0;
  let partiallyInvoiced = 0;
  let notInvoiced = 0;

  for (const mappedInstalment of instalmentRevenue) {
    if (mappedInstalment.expected! <= mappedInstalment.invoiced!) {
      fullyInvoiced += 1;
    } else if (!mappedInstalment.invoiced) {
      notInvoiced += 1;
    } else {
      partiallyInvoiced += 1;
    }
  }

  return (
    <PercentageMetricCard
      dataCy="input-budget-revenues-instalment-invoice-share"
      value={invoicedPercentage}
      label="BUDGET.REVENUE.INSTALMENT_INVOICE_SHARE"
      {...props}
    >
      <MetricCard.NumberSubMetric
        dataCy="input-budget-revenues-instalment-invoice-share-total-instalments"
        label="BUDGET.INSTALMENT"
        value={total}
        decimalScale={0}
      />
      <MetricCard.NumberSubMetric
        dataCy="input-budget-revenues-instalment-invoice-share-invoiced"
        label="BUDGET.REVENUE.FULLY_INVOICED_INSTALMENTS"
        value={fullyInvoiced}
        secondValue={{
          value: (fullyInvoiced / total) * 100,
          suffix: SUFFIX_PERCENTAGE,
        }}
      />
      <MetricCard.NumberSubMetric
        dataCy="input-budget-revenues-instalment-invoice-share-partially-invoiced"
        label="BUDGET.REVENUE.PARTIALLY_INVOICED_INSTALMENTS"
        value={partiallyInvoiced}
        secondValue={{
          value: (partiallyInvoiced / total) * 100,
          suffix: SUFFIX_PERCENTAGE,
        }}
      />
      <MetricCard.NumberSubMetric
        dataCy="input-budget-revenues-instalment-invoice-share-not-invoiced"
        label="BUDGET.REVENUE.NOT_INVOICED_INSTALMENTS"
        value={notInvoiced}
        secondValue={{
          value: (notInvoiced / total) * 100,
          suffix: SUFFIX_PERCENTAGE,
        }}
      />
    </PercentageMetricCard>
  );
};
