import * as React from "react";
import { ChangeEvent, useEffect, useState } from "react";
import { useKycFlow } from "../../../../hooks/useKycFlow";
import { useTranslation } from "../../../../hooks/useTranslation";
import { KycTranslation } from "../../KycTranslation";
import { useSession } from "../../../../hooks/useSession";
import { KycCenteredStepBodyLayout } from "./KycCenteredStepBodyLayout";
import { Field } from "formik";
import { KycDropdownSelect } from "../controls/kyc-dropdown-select/KycDropdownSelect";
import { useKyc } from "../../../../hooks/useKyc";
import { sortBy } from "lodash-es";
import { useAppSelector } from "../../../../../redux/hooks";
import { shallowEqual } from "react-redux";
import { KycButton } from "./KycButton";
import { useLoading } from "../../../../hooks/useLoading";
import { KycLoading } from "../../Kyc";
import { mapCompany, mapKycClient } from "../../KycMappingUtils";

export const KycStepStartFlow = (): JSX.Element => {
  const { isCurrentlyLoading } = useLoading();
  const { values, setFlowValues } = useKycFlow();
  const { fDisplayName } = useTranslation();
  const { session } = useSession();
  const { getKycClients, currentKycClients, createKycFlow } = useKyc();
  const [selectedUser, setSelectedUser] = useState<any>({});
  const [optionsUser, setOptionsUser] = useState<any[]>([]);
  const [optionsLeads, setOptionsLeads] = useState<any[]>([]);

  const { settings } = useAppSelector((state) => {
    const { saved: settings } = state.settings.settingsForEdit;
    return {
      settings,
    };
  }, shallowEqual);

  useEffect(() => {
    if (currentKycClients.length === 0) {
      getKycClients();
    }
  }, [currentKycClients]);

  useEffect(() => {
    if (selectedUser?.id && values?.form?.client?.id !== selectedUser?.id) {
      const kycClient = mapKycClient(selectedUser, fDisplayName);
      setFlowValues({
        "form.client": kycClient,
        propDevCompany: mapCompany(settings),
        clientId: kycClient?.id,
      });
    }
  }, [selectedUser]);

  const handleChangeUser = (e: ChangeEvent<any>) => {
    setSelectedUser(e?.target?.value);
  };

  const handleChangeLead = (e: ChangeEvent<any>) => {
    const kycClient = mapKycClient(selectedUser, fDisplayName, e?.target?.value);

    setFlowValues({
      clientId: kycClient?.id,
      "form.client": kycClient,
      "form.reference": kycClient?.lead?.name,
      "form.transactionAmount": kycClient?.lead?.sellingPrice,
      propDevCompany: mapCompany(settings),
    });
  };

  useEffect(() => {
    setOptionsUser(
      sortBy(
        currentKycClients
          ?.filter((user: any) => user.id !== session.id)
          ?.map((user: any) => ({
            label: fDisplayName(user, true),
            value: user,
          })),
        "label"
      )
    );
  }, [currentKycClients]);

  useEffect(() => {
    setOptionsLeads(
      currentKycClients
        .find((user: any) => user.id === selectedUser?.id)
        ?.leads?.map((lead: any) => ({
          label: lead.name,
          value: lead,
        }))
    );
  }, [selectedUser, currentKycClients]);

  const handleStartFlow = (e: any) => {
    e.preventDefault();
    createKycFlow(values);
  };

  return (
    <KycCenteredStepBodyLayout>
      <Field
        key={"select-user-" + values?.id}
        component={KycDropdownSelect}
        name="form.client.id"
        options={optionsUser}
        incremental={false}
        onChange={handleChangeUser}
        label={KycTranslation.SELECT_USER}
        isLoading={isCurrentlyLoading(KycLoading.GET_KYC_CLIENTS)}
      />
      <Field
        key={"select-lead-" + values?.form?.client?.lead?.id}
        component={KycDropdownSelect}
        name="form.client.lead.id"
        incremental={false}
        options={optionsLeads}
        onChange={handleChangeLead}
        disabled={!values?.form?.client?.id}
        label={KycTranslation.SELECT_LEAD}
        noEntriesLabel={KycTranslation.LEAD_NO_OPTION_ENTRIES}
      />
      <div className="mt-8 mx-auto">
        <KycButton
          onClick={handleStartFlow}
          isLoading={isCurrentlyLoading(KycLoading.CREATE_KYC_FLOW)}
          loadingMessage="COMMON.ACTION.CREATE.LOADING_MESSAGE"
          className="btn-primary"
          disabled={!values?.form?.client?.lead?.id}
          label="COMMON.ACTION.CREATE"
        />
      </div>
    </KycCenteredStepBodyLayout>
  );
};
