/*
  https://react-select.com/typescript
  https://react-select.com/components
*/
import * as React from "react";
import { StylesConfig } from "react-select";

import {
  baseControlStyles,
  baseStyles,
  CustomSelectBase,
  CustomSelectBaseProps,
  CustomSingleValue as CustomBaseSingleValue,
  mergeIntoStylesConfig
} from "../../CustomSelectBase";

import { CustomSelectStatusRenderProps } from "../definitions";

import { smallStatusStyles, statusSelectStyles } from "./StylesConfig";
import { CustomOption } from "./CustomOption";
import { CustomStatusSingleValue } from "./CustomStatusSingleValue";

export const InnerCustomSelectStatusRender: React.FunctionComponent<
  CustomSelectStatusRenderProps
> = ({ small, styles, ...props }) => {
  const stylesConfig: StylesConfig = !small
    ? mergeIntoStylesConfig(styles, baseStyles, baseControlStyles, statusSelectStyles)
    : mergeIntoStylesConfig(styles, statusSelectStyles, smallStatusStyles);

  const innerProps: CustomSelectBaseProps = {
    ...props,
    styles: stylesConfig,
    components: {
      Option: CustomOption,
      SingleValue: small ? CustomStatusSingleValue : CustomBaseSingleValue,
    },
  };

  return <CustomSelectBase {...innerProps} />;
};

export const CustomSelectStatusRender = React.memo(InnerCustomSelectStatusRender);

export default CustomSelectStatusRender;
