import * as React from "react";
import { useHistory, useParams } from "react-router-dom";
import { shallowEqual, useDispatch } from "react-redux";
import { KycAmlFilloutWizard } from "./form-kyc-aml/KycAmlFilloutWizard";
import { PersonalInfoFilloutWizard } from "./form-personal-information/PersonalInfoFilloutWizard";
import { CardLoaderSpinner } from "../../../../_components/CardLoaderSpinner";
import * as actions from "../../_redux/forms/formsActions";
import { useAppSelector } from "../../../../../redux/hooks";
import { FormsUIProvider } from "./FormsUIProvider";

export const WizardPage = () => {
  const { id } = useParams();

  let history = useHistory();
  const dispatch = useDispatch();

  const { session, action, isLoading } = useAppSelector(
    (state) => ({
      action: state.forms.action?.current,
      session: state.auth.session,
      isLoading: state.forms.action.isLoading,
    }),
    shallowEqual
  );

  React.useEffect(() => {
    dispatch(actions.fetchAction(session.id, id));
  }, [id]);

  React.useEffect(() => {
    if (!action && session?.id && id) {
      dispatch(actions.fetchAction(session.id, id));
    }
  }, [session, history]);

  return (
    <>
      {(isLoading || action?.id !== id) && <CardLoaderSpinner />}

      {!isLoading && action?.actionType === "FILLOUT_AML_KYC_FORM" && action?.id === id && (
        <FormsUIProvider
          pFormType={action?.actionType}
          pTitle="KYC.TITLE"
          pBreadcrumps={["KYC.TITLE"]}
          pFormId={id}
        >
          <KycAmlFilloutWizard />
        </FormsUIProvider>
      )}

      {!isLoading && action?.actionType === "FILLOUT_PERSONAL_INFORMATION" && action?.id === id && (
        <FormsUIProvider
          pFormType={action?.actionType}
          pTitle={"FORM.TITLE.PERSONAL_INFORMATION"}
          pBreadcrumps={["KYC.TITLE"]}
          pFormId={id}
        >
          <PersonalInfoFilloutWizard />
        </FormsUIProvider>
      )}
    </>
  );
};
