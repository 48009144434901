import * as React from "react";
import { Spinner } from "react-bootstrap";

import { KycCenteredStepBodyLayout } from "./KycCenteredStepBodyLayout";

export const KycStepLoadingPage = (): JSX.Element => {
  return (
    <KycCenteredStepBodyLayout>
      <div className="text-justify mx-auto" style={{ maxWidth: "80%" }}>
        <Spinner animation="border" variant="primary" style={{ top: "30px", left: "20px" }} />
      </div>
    </KycCenteredStepBodyLayout>
  );
};
