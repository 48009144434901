import { Button, Modal } from "react-bootstrap";
import cn from "clsx";
import { useTranslation } from "../../../hooks/useTranslation";
import { KycButton } from "./steps/KycButton";
import React from "react";
import { useKycConfirmation } from "../../../hooks/useKycConfirmation";
import { isArray } from "lodash-es";
import { useLoading } from "../../../hooks/useLoading";
import { KycConfirmationDialogConfiguration } from "../Kyc";

export interface KycConfirmationModalProps {
  config?: KycConfirmationDialogConfiguration;
  [rest: string]: any;
}

export const KycConfirmationDialog = ({ config, size }: KycConfirmationModalProps): JSX.Element => {
  const { fm } = useTranslation();

  const { showModal, setShowModal } = useKycConfirmation();
  const { isCurrentlyLoading } = useLoading();

  const handleCancel = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    setShowModal(false);
  };

  const handleFunc = () => {
    if (config?.func && config?.funcParams) {
      if (isArray(config?.funcParams)) {
        config?.func && config?.func(...config?.funcParams);
      } else {
        config?.func(config?.funcParams);
      }
    }
  };

  const handleFnAction = (e: any) => {
    e.preventDefault();

    const cParams = config?.fnParams;
    const cIsAsync = !config?.isFnAsync;
    const cFnAction = config?.fnAction;

    const params = cParams && isArray(cParams) ? [...cParams] : [config?.fnParams];

    if (cIsAsync) {
      return cFnAction(...params)
        ?.then(() => {
          handleFunc();
          setShowModal(false);
        })
        .catch(() => {
          setShowModal(false);
        });
    } else {
      cFnAction(...params);
      handleFunc();
      setShowModal(false);
    }
  };

  const isLoading = isCurrentlyLoading(config?.isLoading, config?.isLoadingId ?? "");

  return (
    <Modal show={showModal} centered backdrop="static" size={size ?? "md"}>
      <Modal.Header closeButton>
        <Modal.Title id="example-modal-sizes-title-lg">
          {config?.labelTitle && fm(config?.labelTitle)}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <span>
          {config?.labelContent &&
            fm(config?.labelContent, config?.labelValues ? config?.labelValues : {})}
        </span>
        {config?.children}
      </Modal.Body>
      <Modal.Footer>
        {!isLoading && showModal ? (
          <div>
            <Button
              onClick={handleCancel}
              className="btn btn-light"
              disabled={isLoading || config?.disabled}
            >
              {fm("COMMON.ACTION.CANCEL")}
            </Button>
            <KycButton
              data-cy="button-kyc-confirm"
              onClick={handleFnAction}
              className={cn(
                `btn btn-${config?.colorActionButton ?? "light"} ml-2`,
                config?.isLoading && "hidden"
              )}
              disabled={isLoading}
              loadingMessage={
                config?.labelLoading ? config?.labelLoading : config?.labelActionButton
              }
              isLoading={isCurrentlyLoading(config?.isLoading)}
              label={config?.labelActionButton ?? ""}
            />
          </div>
        ) : (
          <>
            <div className="d-flex align-items-center">
              <div className="mr-2 text-muted">{fm("COMMON.LOADING")}</div>
              <div className="spinner spinner-primary mr-10" />
            </div>
          </>
        )}
      </Modal.Footer>
    </Modal>
  );
};
