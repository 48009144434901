import { TagsFormatterComponent } from "../_components/column-formatters";
import { canReadAll } from "./authUtils";
import { FILE, TAG } from "./dataTypes";
import { sortCaret } from "../../_metronic/_helpers";

const MAX_TAGS_DISPLAYED = 5;
export const calculateMaxDisplayedTags = (options, maxLength) => {
  const emptySize = 26;
  const charSize = 7.5;
  const maxSize = 130;
  let calculateMaxDisplayed = MAX_TAGS_DISPLAYED;
  let tempo = 0;
  if (options.length) {
    let i = 0;
    let isDone = false;
    while (options.length > 0 && i < options.length && !isDone) {
      const opt = options[i];
      const size = emptySize + opt.label.length * charSize;
      tempo += size > maxSize ? maxSize : size;
      if (tempo + (emptySize + charSize * (options.length - i)) >= maxLength) {
        calculateMaxDisplayed = i === 0 ? 1 : i;
        isDone = true;
      }
      i++;
    }
    // Loop over but still space left to display values
    if (!isDone) {
      calculateMaxDisplayed = options.length;
    }
  }
  return calculateMaxDisplayed;
};

export const columnTags = (groups, session, intl, tagType = FILE) =>
  canReadAll(groups, session, TAG) && {
    dataField: "tags",
    text: intl.formatMessage({
      id: "TAGS.TITLE.LABEL",
    }),
    headerClasses: "text-left text-nowrap",
    classes: "text-left",
    sort: true,
    sortCaret: sortCaret,
    formatter: (cell, row, index) => (
      <TagsFormatterComponent
        cell={cell}
        row={row}
        rowIndex={index}
        formatExtraData={{
          tagType: tagType,
          noState: false,
        }}
      />
    ),
  };
