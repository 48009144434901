import { IUser } from "../../../../../../data/schemas";
import * as Yup from "yup";
import { IntlShape } from "react-intl";
import { SUBCONTRACTOR, CLIENT } from "../../../../../_utils/userTypes";
import { merge } from "lodash";

export const userEditFormYup = (user: IUser, intl: IntlShape) => {
  let res: Record<string, Yup.AnySchema> = {
    firstName: Yup.string()
      .required(intl.formatMessage({ id: "AUTH.VALIDATION.FIRST_NAME.REQUIRED" }))
      .min(2, `${intl.formatMessage({ id: "AUTH.VALIDATION.MIN_CHARACTER" })} 2`)
      .max(50, `${intl.formatMessage({ id: "AUTH.VALIDATION.MAX_CHARACTER" })} 50`),
    lastName: Yup.string()
      .required(intl.formatMessage({ id: "AUTH.VALIDATION.LAST_NAME.REQUIRED" }))
      .min(2, `${intl.formatMessage({ id: "AUTH.VALIDATION.MIN_CHARACTER" })} 2`)
      .max(50, `${intl.formatMessage({ id: "AUTH.VALIDATION.MAX_CHARACTER" })} 50`),
    email: Yup.string()
      .required(intl.formatMessage({ id: "AUTH.VALIDATION.EMAIL.REQUIRED" }))
      .email(intl.formatMessage({ id: "AUTH.VALIDATION.EMAIL" }))
      .min(2, `${intl.formatMessage({ id: "AUTH.VALIDATION.MIN_CHARACTER" })} 2`)
      .max(50, `${intl.formatMessage({ id: "AUTH.VALIDATION.MAX_CHARACTER" })} 50`),
    mobile: Yup.string()
      .required(intl.formatMessage({ id: "AUTH.VALIDATION.PHONE.REQUIRED" }))
      .min(6, `${intl.formatMessage({ id: "AUTH.VALIDATION.MIN_CHARACTER" })} 6`)
      .max(50, `${intl.formatMessage({ id: "AUTH.VALIDATION.MAX_CHARACTER" })} 50`),
    language: Yup.string().required(
      intl.formatMessage({ id: "AUTH.VALIDATION.LANGUAGE.REQUIRED" })
    ),
  };
  if (
    user.userTypes.includes(SUBCONTRACTOR) ||
    (user.userTypes.includes(CLIENT) && user.legalEntityType === "LEGAL")
  ) {
    res = merge(res, {
      company: Yup.object().shape({
        name: Yup.string()
          .required(intl.formatMessage({ id: "AUTH.VALIDATION.COMPANY_NAME.REQUIRED" }))
          .min(2, `${intl.formatMessage({ id: "AUTH.VALIDATION.MIN_CHARACTER" })} 2`)
          .max(50, `${intl.formatMessage({ id: "AUTH.VALIDATION.MAX_CHARACTER" })} 50`),
      }),
    });
  }
  return Yup.object().shape(res);
};
