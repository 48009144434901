import * as React from "react";
import * as Yup from "yup";
import { Field, Form, Formik } from "formik";
import { forgotPassword } from "../_redux/authCrud";
import { Input } from "../../../../_metronic/_partials/controls";
import { Link, useHistory } from "react-router-dom";
import { FormattedMessage, useIntl } from "react-intl";
import { useState } from "react";
import { useAuth } from "../../../v2/contexts";
import moment from "moment";

const initialValuesRequestCode = {
  email: "",
};
export const ForgotPasswordEmailForm = () => {
  const [errorMessage, setErrorMessage] = React.useState<string | undefined>(undefined);
  const [isSendingCode, setIsSendingCode] = useState<boolean>(false);

  const intl = useIntl();
  const history = useHistory();
  const { resetApplicationInitialized } = useAuth();

  const RequestPasswordSchema = Yup.object().shape({
    email: Yup.string()
      .email(intl.formatMessage({ id: "AUTH.VALIDATION.EMAIL" }))
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD",
        })
      ),
  });
  return (
    <div className="login-form login-forgot" style={{ display: "block" }}>
      <div className="text-center mb-10 mb-lg-20">
        <h3 className="font-size-h1">
          <FormattedMessage id="USER.EMAIL.FORGOT.PASSWORD" />
        </h3>
        <div className="text-muted font-weight-bold">
          <FormattedMessage id="USER.EMAIL.RESET.ENTER.EMAIL" />
        </div>
      </div>
      <Formik
        enableReinitialize={true}
        initialValues={initialValuesRequestCode}
        validationSchema={RequestPasswordSchema}
        onSubmit={(values) => {
          setErrorMessage(undefined);
          setIsSendingCode(true);
          forgotPassword(values.email)
            .then((response) => {
              localStorage.setItem(
                "forgot-password",
                JSON.stringify({
                  value: { ...response.CodeDeliveryDetails, email: values.email },
                  expDate: moment(new Date()).add(10, "m").toDate(), // current date + 10 min
                })
              );

              history.push("/auth/forgot-password-confirm");
            })
            .catch((error) => {
              console.log("Error:", error);
              if (error.code === "LimitExceededException") {
                setErrorMessage("AUTH.FORGOT_PASSWORD.ERROR.LIMIT_EXCEEDED");
              }
            })
            .finally(() => {
              setIsSendingCode(false);
              resetApplicationInitialized();
            });
        }}
      >
        {() => (
          <Form className="form">
            {errorMessage && (
              <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
                <div className="alert-text font-weight-bold">
                  <FormattedMessage id={errorMessage} />
                </div>
              </div>
            )}
            <div className="form-group fv-plugins-icon-container">
              <Field
                name="email"
                component={Input}
                label={intl.formatMessage({
                  id: "COMMON.EMAIL",
                })}
                className="form-control-lg form-control-solid h-auto py-5 px-6"
              />
            </div>
            <div className="form-group d-flex flex-wrap flex-center">
              <Link to="/auth">
                <button
                  type="button"
                  id="kt_login_forgot_cancel"
                  className="btn btn-light-primary font-weight-bold px-9 py-4 my-3 mx-4"
                >
                  <FormattedMessage id="COMMON.ACTION.CANCEL" />
                </button>
              </Link>
              <button
                id="kt_login_forgot_submit"
                type="submit"
                className="btn btn-primary font-weight-bold px-9 py-4 my-3 mx-4"
                disabled={isSendingCode}
              >
                <span>
                  <FormattedMessage id="COMMON.ACTION.SUBMIT" />
                </span>
                {isSendingCode && <span className="ml-3 spinner spinner-white" />}
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};
