import * as React from "react";
import { IProject } from "../../../../../../../data/schemas";
import {
  CurrencyMetricCard,
  MetricCard,
  MetricCardProps,
} from "../../../../../../_components/MetricCard";

export interface RealBudgetCardProps {
  canSeeHiddenLine: boolean;
  actualBudget: number;
  project: Partial<IProject>;
}

export const RealBudgetCard: React.FC<RealBudgetCardProps & MetricCardProps> = ({
  canSeeHiddenLine,
  project,
  actualBudget,
  ...props
}) => {
  const publicBudget = project.subcontractorsFinanceFiles
    ? project.subcontractorsFinanceFiles.orders.total.public +
      project.subcontractorsFinanceFiles.supplementary_agreements.total.public
    : 0;

  const hiddenBudget = actualBudget - publicBudget;

  return (
    <CurrencyMetricCard
      dataCy="input-budget-actual-total"
      value={actualBudget}
      label="BUDGET.REAL"
      {...props}
    >
      {canSeeHiddenLine && (
        <>
          <MetricCard.CurrencySubMetric
            dataCy="input-budget-public-actual-total"
            label="COMMON.PUBLIC"
            value={publicBudget}
          />
          <MetricCard.CurrencySubMetric
            dataCy="input-budget-hidden-actual-total"
            label="COMMON.HIDDEN"
            value={hiddenBudget}
          />
        </>
      )}
    </CurrencyMetricCard>
  );
};
