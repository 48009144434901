import { ColumnFormatter } from "react-bootstrap-table-next";
import { FormattedMessage, IntlShape } from "react-intl";

import { DateUtils } from "app/_utils/DateUtils";

import { SignatureStatus, IFileSignature } from "data/schemas/signatures";

//----------------------------------------------------------------------------//

interface FormatExtraDataProps {
  openUploadFileDialog: Function;
  readOnly: boolean;
  userId: string;
  intl: IntlShape;
}

/**
 * On the files table the properties listed here relates to the data/schemas/File
 */
interface RowProps {
  userId: string;
  status: string;

  currentSignaturesRequestId?: string;
  signatures?: IFileSignature[];
  sealedAt?: string;
}

export const UploadedDateTimeColumnFormatter: ColumnFormatter<
  RowProps,
  FormatExtraDataProps,
  string
> = (cell, row, rowIndex, formatExtraData) => {
  if (formatExtraData) {
    const { openUploadFileDialog, readOnly, userId, intl } = formatExtraData;

    const renderSignButton = () => {
      let signature: any;
      const { signatures = [], currentSignaturesRequestId = "" } = row;

      if (!currentSignaturesRequestId || signatures.length === 0) return null;
      signature = signatures
        .filter(
          (s) =>
            s.id === currentSignaturesRequestId &&
            s.assigneeId === userId &&
            s.status !== SignatureStatus.SIGNED
        )
        .sort((s1, s2) => new Date(s2.createdAt).getTime() - new Date(s1.createdAt).getTime());

      const signId = signature && signature.length > 0 && signature[0].urlParamId;

      return signId ? (
        <span className="ml-4" onClick={(e) => e.stopPropagation()}>
          <button
            onClick={() => window.open(`/sign/${signId}`, "_blank")}
            type={"button"}
            className={"btn btn-sm btn-primary"}
          >
            <FormattedMessage id="COMMON.ACTION.SIGN_NOW" />
          </button>
        </span>
      ) : null;
    };

    const renderSignaturesInfo = () => {
      const { sealedAt, currentSignaturesRequestId = "" } = row;

      if (sealedAt) {
        const sealedAtStr = DateUtils.format(new Date(sealedAt), intl, false);

        return (
          <span className="font-weight-bold">
            <FormattedMessage id="COMMON.SIGNATURES.DONE_AT" values={{ date: sealedAtStr }} />
          </span>
        );
      }

      let { signatures = [] } = row;

      if (!currentSignaturesRequestId || signatures.length === 0) return null;

      signatures = signatures.filter((s) => s.id === currentSignaturesRequestId);
      const signaturesLength = signatures.length;
      const signaturesSignedLength = signatures.filter(
        (s) => s.status === SignatureStatus.SIGNED
      ).length;

      if (signaturesSignedLength > 0) {
        return (
          <span className="font-weight-bold">
            <FormattedMessage
              id="COMMON.SIGNATURES.IN_PROGRESS"
              values={{ progress: `${signaturesSignedLength}/${signaturesLength}` }}
            />
          </span>
        );
      }

      return (
        <span className="font-weight-bold">
          <FormattedMessage id="COMMON.SIGNATURES.NEEDED" values={{ length: signaturesLength }} />
        </span>
      );
    };

    switch (row?.status) {
      case "REQUESTED_BY_CLIENT":
        return (
          <span>
            <FormattedMessage id="FILE.TITLE.UPLOAD_COMPLETION" />
          </span>
        );

      case "REQUESTED":
        return (
          <span onClick={(e) => e.stopPropagation()}>
            {row.userId !== userId && readOnly ? (
              <span>
                <FormattedMessage id="FILE.ACTION.UPLOAD_AWAITING" />
              </span>
            ) : (
              <button
                data-cy="button-file-upload-now"
                onClick={() => openUploadFileDialog(row)}
                type={"button"}
                className={"btn btn-sm btn-primary"}
              >
                <FormattedMessage id="FILE.ACTION.UPLOAD_NOW" />
              </button>
            )}
          </span>
        );

      default:
        return (
          <div className="d-flex flex-column">
            <div className="d-flex w-full mb-2 align-items-center">
              {cell ? DateUtils.format(new Date(cell), intl) : "-"}

              {renderSignButton()}
            </div>

            {renderSignaturesInfo()}
          </div>
        );
    }
  }
};
