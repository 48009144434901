import { createSlice } from "@reduxjs/toolkit";

const initialGlobalsState = {
  saving: 0,
  fromUrls: [],
};

export const globalSlice = createSlice({
  name: "global",
  initialState: initialGlobalsState,
  reducers: {
    saving: (state) => {
      state.saving++;
    },
    saved: (state) => {
      state.saving--;
    },
    setFromUrl: (state, action) => {
      state.fromUrls.push(action.payload.fromUrl);
    },
    backToUrl: (state, action) => {
      state.fromUrls.splice(-action.payload.urlsToBack, action.payload.urlsToBack);
    },
    resetFromUrl: (state) => {
      state.fromUrls = initialGlobalsState.fromUrls;
    },
  },
});
