import React from "react";
import { ColumnFormatter } from "react-bootstrap-table-next";

interface RowProps {
  isDisabled: boolean;
  id: string;
}

export const PlatformAccessColumnFormatter: ColumnFormatter<RowProps, any, boolean> = (
  cell,
  row
) => {
  if (!cell || !row.id) {
    return (
      <div className="text-center pr-6">
        <div className="symbol symbol-35 bg-white-o-30 flex-shrink-0">
          <i className="fas fa-check-square text-success icon-lg" />
        </div>
      </div>
    );
  }
};
