import * as React from "react";
import { NumberInput } from "../../../../../../_utils/formUtils";
import { SUFFIX_PERCENTAGE } from "../../../../../../_utils/suffixUtils";
import { FormattedMessage } from "react-intl";
import { IProject } from "../../../../../../../data/schemas";
import {
  CurrencyMetricCard,
  MetricCard,
  MetricCardProps,
} from "../../../../../../_components/MetricCard";

export interface SupplementaryAgreementCardProps {
  canSeeHiddenLine: boolean;
  actualBudget: number;
  project: Partial<IProject>;
}

export const SupplementaryAgreementCard: React.FC<
  SupplementaryAgreementCardProps & MetricCardProps
> = ({ canSeeHiddenLine, project, actualBudget, ...props }) => {
  const totalKey = canSeeHiddenLine ? "general" : "public";
  const totalSupplAgreements =
    project.subcontractorsFinanceFiles?.supplementary_agreements?.total?.[totalKey] ?? 0;

  const totalSupplAgreementsPercentage =
    totalSupplAgreements && actualBudget ? (totalSupplAgreements / actualBudget) * 100 : 0;
  return (
    <CurrencyMetricCard
      dataCy="input-budget-sa-total"
      value={totalSupplAgreements}
      label="BUDGET.SUPPLEMENTARY_AGREEMENTS"
      {...props}
    >
      <MetricCard.SubMetric
        value={
          <FormattedMessage
            id={"BUDGET.PERCENTAGE_OF_ACTUAL_BUDGET"}
            values={{
              amount: (
                <NumberInput
                  data-cy="input-budget-sa-total-percentage"
                  displayType="text"
                  value={totalSupplAgreementsPercentage}
                  decimalScale={2}
                  fixedDecimalScale={true}
                  suffix={SUFFIX_PERCENTAGE}
                  className="font-weight-bold text-right"
                />
              ),
            }}
          />
        }
      />
    </CurrencyMetricCard>
  );
};
