import * as snackBarNotificationsActions from "../Notifications/_redux/snackBarNotificationsActions";

export const SNACKBAR_MESSAGE = {
  DANGER: {
    FIND_USERS: {
      type: "danger",
      title: "SNACKBAR.DANGER.USERS.FIND",
    },
    FIND_TASKS: {
      type: "danger",
      title: "SNACKBAR.DANGER.TASK.FIND",
    },
    GET_USERS: {
      type: "danger",
      title: "SNACKBAR.DANGER.USERS.GET",
    },
    CREATE_SUBTASK: {
      type: "danger",
      title: "SNACKBAR.DANGER.SUBTASK.CREATE",
    },
    CREATE_TASK: {
      type: "danger",
      title: "SNACKBAR.DANGER.TASK.CREATE",
    },
    UPDATE_SUBTASK: {
      type: "danger",
      title: "SNACKBAR.DANGER.SUBTASK.UPDATE",
    },
    UPDATE_TASK: {
      type: "danger",
      title: "SNACKBAR.DANGER.TASK.UPDATE",
    },
    UPDATE_COMMENT: {
      type: "danger",
      title: "SNACKBAR.DANGER.COMMENT.UPDATE",
    },
    CREATE_COMMENT: {
      type: "danger",
      title: "SNACKBAR.DANGER.COMMENT.CREATE",
    },
    DELETE_COMMENT: {
      type: "danger",
      title: "SNACKBAR.DANGER.COMMENT.DELETE",
    },
    DELETE_SUBTASK: {
      type: "danger",
      title: "SNACKBAR.DANGER.SUBTASK.DELETE",
    },
    DELETE_TASK: {
      type: "danger",
      title: "SNACKBAR.DANGER.TASK.DELETE",
    },
    QUERY_TASKS: {
      type: "danger",
      title: "SNACKBAR.DANGER.TASK.QUERY",
    },
    UPDATE_USER: {
      type: "danger",
      title: "SNACKBAR.DANGER.USER.UPDATE",
    },
    EMAIL_ALREADY_USED: {
      type: "danger",
      title: "SNACKBAR.DANGER.USER.EMAIL.EXISTS",
    },
    UPDATE_ADDITIONAL_INFO: {
      type: "danger",
      title: "SNACKBAR.DANGER.USER.INFO.ADDITIONAL.UPDATE",
    },
    FIND_USER_INFORMATION: {
      type: "danger",
      title: "SNACKBAR.DANGER.USER.INFO.FIND",
    },
    USER_CREATED: {
      type: "danger",
      title: "SNACKBAR.DANGER.USER.CREATE",
    },
    PERFORM_REQUESTED_ACTION: {
      type: "danger",
      title: "SNACKBAR.DANGER.ACTION.EXECUTE",
    },
    ENABLE_USER_OR_RESET_PASSWORD: {
      type: "danger",
      title: "SNACKBAR.DANGER.USER.RESET",
    },
    REMOVE_USER_ACCESS: {
      type: "danger",
      title: "SNACKBAR.DANGER.USER.REMOVE",
    },
    UPDATE_FILE: {
      type: "danger",
      title: "SNACKBAR.DANGER.FILE.UPDATE",
    },
    REQUEST_FILE_CREATE: {
      type: "danger",
      title: "SNACKBAR.DANGER.FILE.CREATE",
    },
    UPLOAD_FILE: {
      type: "danger",
      title: "SNACKBAR.DANGER.FILE.UPLOAD",
    },
    ADD_CLIENT_TO_LEAD: {
      type: "danger",
      title: "SNACKBAR.DANGER.CLIENT.ADD",
    },
    REMOVE_CLIENT_FROM_LEAD: {
      type: "danger",
      title: "SNACKBAR.DANGER.LEAD.CLIENT.REMOVE",
    },
    OPEN_FILE: {
      type: "danger",
      title: "SNACKBAR.DANGER.FILE.OPEN",
    },
    FILE_DELETE: {
      type: "danger",
      title: "SNACKBAR.DANGER.FILE.DELETE",
    },
    UPDATE_USER_RIGHTS: {
      type: "danger",
      title: "SNACKBAR.DANGER.RIGHT.UPDATE",
    },
    UPDATE_BUDGET: {
      type: "danger",
      title: "SNACKBAR.DANGER.BUDGET.UPDATE",
    },
    FIND_BUDGET: {
      type: "danger",
      title: "SNACKBAR.DANGER.BUDGETS.FIND",
    },
    DELETE_BUDGET: {
      type: "danger",
      title: "SNACKBAR.DANGER.BUDGET.DELETE",
    },
    CREATE_BUDGET: {
      type: "danger",
      title: "SNACKBAR.DANGER.BUDGET.CREATE",
    },
    UPDATE_FORM: {
      type: "danger",
      title: "SNACKBAR.DANGER.FORM.UPDATE",
    },
    FIND_FORM: {
      type: "danger",
      title: "SNACKBAR.DANGER.FORM.FIND",
    },
    FIND_HISTORY: {
      type: "danger",
      title: "SNACKBAR.DANGER.HISTORY.FIND",
    },
    DELETE_PHOTO: {
      type: "danger",
      title: "SNACKBAR.DANGER.PHOTO.DELETE",
    },
    CREATE_PHOTO: {
      type: "danger",
      title: "SNACKBAR.DANGER.PHOTO.CREATE",
    },
    UPDATE_PHOTO: {
      type: "danger",
      title: "SNACKBAR.SUCCESS.PHOTO.UPDATE",
    },
    FETCH_PHOTOS: {
      type: "danger",
      title: "SNACKBAR.DANGER.PHOTOS.FETCH",
    },
    CREATE_PHOTO_SHARING: {
      type: "danger",
      title: "SNACKBAR.DANGER.SHARING_PHOTO.CREATE",
    },
    DELETE_PHOTO_SHARING: {
      type: "danger",
      title: "SNACKBAR.DANGER.SHARING_PHOTO.DELETE",
    },
    DELETE_FILE: {
      type: "danger",
      title: "SNACKBAR.DANGER.FILE.DELETE",
    },
    ADD_FILE: {
      type: "danger",
      title: "SNACKBAR.DANGER.FILE.ADD",
    },
    UPDATE_PRODUCT: {
      type: "danger",
      title: "SNACKBAR.DANGER.PRODUCT.UPDATE",
    },
    CREATE_PRODUCT: {
      type: "danger",
      title: "SNACKBAR.DANGER.PRODUCT.CREATE",
    },
    DELETE_PRODUCT: {
      type: "danger",
      title: "SNACKBAR.DANGER.PRODUCT.DELETE",
    },
    FIND_PRODUCT: {
      type: "danger",
      title: "SNACKBAR.DANGER.PRODUCTS.FIND",
    },
    LOAD_AUDIT_TRAIL: {
      type: "danger",
      title: "SNACKBAR.DANGER.HISTORY.LOAD",
    },
    FIND_PRODUCTS: {
      type: "danger",
      title: "SNACKBAR.DANGER.PRODUCTS.FIND",
    },
    CREATE_INVOICE: {
      type: "danger",
      title: "SNACKBAR.DANGER.INVOICE.CREATE",
    },
    SEND_MESSAGE: {
      type: "danger",
      title: "SNACKBAR.DANGER.NOTIFICATION.SEND",
    },
    LOAD_CHAT: {
      type: "danger",
      title: "SNACKBAR.DANGER.CHAT.LOAD",
    },
    FIND_CLIENTS: {
      type: "danger",
      title: "SNACKBAR.DANGER.CLIENTS.FIND",
    },
    UPDATE_LEAD: {
      type: "danger",
      title: "SNACKBAR.DANGER.LEAD.UPDATE",
    },
    CREATE_LEAD: {
      type: "danger",
      title: "SNACKBAR.DANGER.LEAD.CREATE",
    },
    DELETE_LEAD: {
      type: "danger",
      title: "SNACKBAR.DANGER.LEAD.DELETE",
    },
    FIND_LEAD: {
      type: "danger",
      title: "SNACKBAR.DANGER.LEAD.FIND",
    },
    UPDATE_PROJECT: {
      type: "danger",
      title: "SNACKBAR.DANGER.PROJECT.UPDATE",
    },
    CREATE_PROJECT: {
      type: "danger",
      title: "SNACKBAR.DANGER.PROJECT.CREATE",
    },
    DELETE_PROJECT: {
      type: "danger",
      title: "SNACKBAR.DANGER.PROJECT.DELETE",
    },
    FIND_PROJECT: {
      type: "danger",
      title: "SNACKBAR.DANGER.PROJECT.FIND",
    },
    FIND_PROJECTS: {
      type: "danger",
      title: "SNACKBAR.DANGER.PROJECTS.FIND",
    },
    UPDATE_SETTINGS: {
      type: "danger",
      title: "SNACKBAR.DANGER.SETTINGS.UPDATE",
    },
    FETCH_SETTINGS: {
      type: "danger",
      title: "SNACKBAR.DANGER.SETTINGS.FETCH",
    },
    FIND_PROPCOS: {
      type: "danger",
      title: "SNACKBAR.DANGER.PROPCOS.FIND",
    },
    FIND_PROPCO: {
      type: "danger",
      title: "SNACKBAR.DANGER.PROPCO.FIND",
    },
    CREATE_PROPCO: {
      type: "danger",
      title: "SNACKBAR.DANGER.PROPCO.CREATE",
    },
    UPDATE_PROPCO: {
      type: "danger",
      title: "SNACKBAR.DANGER.PROPCO.UPDATE",
    },
    DELETE_PROPCO: {
      type: "danger",
      title: "SNACKBAR.DANGER.PROPCO.DELETE",
    },
    REQUEST_FILE_SIGNATURE: {
      type: "danger",
      title: "SNACKBAR.DANGER.FILE.REQUEST.SIGNATURE",
    },
    CREATE_PROJECT_SHARING: {
      type: "danger",
      title: "SNACKBAR.DANGER.CREATE_PROJECT_SHARING",
    },
    CREATE_KYC_AML: {
      type: "danger",
      title: "SNACKBAR.DANGER.CREATE_AML_KYC_V2",
    },
    DELETE_PROJECT_SHARING: {
      type: "danger",
      title: "SNACKBAR.DANGER.DELETE_PROJECT_SHARING",
    },
    ORDER_PHOTO: { type: "danger", title: "SNACKBAR.DANGER.ORDER_PHOTO" },
    FETCH_TAGS: {
      type: "danger",
      title: "SNACKBAR.DANGER.TAGS.FETCH",
    },
    UPDATE_TAGS: {
      type: "danger",
      title: "SNACKBAR.DANGER.TAGS.UPDATE",
    },
    DELETE_TAGS: {
      type: "danger",
      title: "SNACKBAR.DANGER.TAGS.DELETE",
    },
  },
  SUCCESS: {
    DELETE_PROJECT: {
      type: "success",
      title: "SNACKBAR.SUCCESS.PROJECT.DELETE",
    },
    UPDATE_PROJECT: {
      type: "success",
      title: "SNACKBAR.SUCCESS.PROJECT.UPDATE",
    },
    CREATE_SUBTASK: {
      type: "success",
      title: "SNACKBAR.SUCCESS.SUBTASK.CREATE",
    },
    CREATE_TASK: {
      type: "success",
      title: "SNACKBAR.SUCCESS.TASK.CREATE",
    },
    DELETE_LEAD: {
      type: "success",
      title: "SNACKBAR.SUCCESS.LEAD.DELETE",
    },
    CREATE_INVOICE: {
      type: "success",
      title: "SNACKBAR.SUCCESS.INVOICE.CREATE",
    },
    UPDATE_PRODUCT: {
      type: "success",
      title: "SNACKBAR.SUCCESS.PRODUCT.UPDATE",
    },
    UPDATE_SUBTASK: {
      type: "success",
      title: "SNACKBAR.SUCCESS.SUBTASK.UPDATE",
    },
    UPDATE_TASK: {
      type: "success",
      title: "SNACKBAR.SUCCESS.TASK.UPDATE",
    },
    USER_CREATED: {
      type: "success",
      title: "SNACKBAR.SUCCESS.USER.CREATE",
    },
    UPDATE_PROFILE: {
      type: "success",
      title: "SNACKBAR.SUCCESS.PROFILE.UPDATE",
    },
    UPDATE_PROFILE_PICTURE: {
      type: "success",
      title: "SNACKBAR.SUCCESS.PROFILE.PICTURE.UPDATE",
    },
    SEND_COMMUNICATION: {
      type: "success",
      title: "SNACKBAR.SUCCESS.COMMUNICATION.SEND",
    },
    REMOVE_USER_ACCESS: {
      type: "success",
      title: "SNACKBAR.SUCCESS.USER.ACCESS.REMOVE",
    },
    UPDATE_FILE: {
      type: "success",
      title: "SNACKBAR.SUCCESS.FILE.UPDATE",
    },
    REQUEST_FILE_CREATE: {
      type: "success",
      title: "SNACKBAR.SUCCESS.FILE.REQUEST.CREATE",
    },
    UPLOAD_FILE_REQUEST: {
      type: "success",
      title: "SNACKBAR.SUCCESS.FILE.REQUEST.UPLOAD",
    },
    UPLOAD_FILE: {
      type: "success",
      title: "SNACKBAR.SUCCESS.FILE.UPLOAD",
    },
    REMOVE_CLIENT_FROM_LEAD: {
      type: "success",
      title: "SNACKBAR.SUCCESS.LEAD.CLIENT.REMOVE",
    },
    DELETE_BUDGET: {
      type: "success",
      title: "SNACKBAR.SUCCESS.BUDGET.DELETE",
    },
    CREATE_BUDGET: {
      type: "success",
      title: "SNACKBAR.SUCCESS.BUDGET.CREATE",
    },
    DELETE_SUBTASK: {
      type: "success",
      title: "SNACKBAR.SUCCESS.SUBTASK.DELETE",
    },
    DELETE_TASK: {
      type: "success",
      title: "SNACKBAR.SUCCESS.TASK.DELETE",
    },
    UPDATE_FORM: {
      type: "success",
      title: "SNACKBAR.SUCCESS.FORM.UPDATE",
    },
    DELETE_PHOTO: {
      type: "success",
      title: "SNACKBAR.SUCCESS.PHOTO.DELETE",
    },
    CREATE_PHOTO: {
      type: "success",
      title: "SNACKBAR.SUCCESS.PHOTO.CREATE",
    },
    DELETE_FILE: {
      type: "success",
      title: "SNACKBAR.SUCCESS.FILE.DELETE",
    },
    CREATE_PRODUCT: {
      type: "success",
      title: "SNACKBAR.SUCCESS.PRODUCT.CREATE",
    },
    DELETE_PRODUCT: {
      type: "success",
      title: "SNACKBAR.SUCCESS.PRODUCT.DELETE",
    },
    CREATE_LEAD: {
      type: "success",
      title: "SNACKBAR.SUCCESS.LEAD.CREATE",
    },
    DELETE_USER: {
      type: "success",
      title: "SNACKBAR.SUCCESS.USER.DELETE",
    },
    CREATE_PROJECT: {
      type: "success",
      title: "SNACKBAR.SUCCESS.PROJECT.CREATE",
    },
    FILE_DELETE: {
      type: "success",
      title: "SNACKBAR.SUCCESS.FILE.DELETE",
    },
    UPDATE_LEAD: {
      type: "success",
      title: "SNACKBAR.SUCCESS.LEAD.UPDATE",
    },
    REQUEST_FILE_SIGNATURE: {
      type: "success",
      title: "SNACKBAR.SUCCESS.FILE.REQUEST.SIGNATURE",
    },
    CREATE_PROJECT_SHARING: {
      type: "success",
      title: "SNACKBAR.SUCCESS.CREATE_PROJECT_SHARING",
    },
    DELETE_PROJECT_SHARING: {
      type: "success",
      title: "SNACKBAR.SUCCESS.DELETE_PROJECT_SHARING",
    },
    UPDATE_SETTINGS: {
      type: "success",
      title: "SNACKBAR.SUCCESS.SETTINGS.UPDATE",
    },
    CREATE_KYC_AML: {
      type: "success",
      title: "SNACKBAR.SUCCESS.CREATE_AML_KYC_V2",
    },
    COPY_TO_CLIPBOARD: {
      type: "success",
      title: "SNACKBAR.SUCCESS.COPY_TO_CLIPBOARD",
    },
  },
};

export const processSnackbarNotification = (message, dispatch) => {
  dispatch(snackBarNotificationsActions.createNotification(message));
};
