/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import { useLocation } from "react-router";
import { NavLink } from "react-router-dom";
import { checkIsActive } from "../../../../_helpers";
import { FormattedMessage } from "react-intl";

export function HeaderMenuAdmin({ layoutProps }) {
  const location = useLocation();
  const getMenuItemActive = (url) => {
    return checkIsActive(location, url) ? "menu-item-active" : "";
  };


  return (
    <div
      id="kt_header_menu"
      className={`header-menu header-menu-left header-menu-mobile ${layoutProps.ktMenuClasses}`}
      {...layoutProps.headerMenuAttributes}
    >
      <ul className={`menu-nav ${layoutProps.ulClasses}`}>
        <li className={`menu-item ${getMenuItemActive("/dashboard")}`}>
          <NavLink className="menu-link" to="/dashboard" data-cy="link-dashboard">
            <span className="menu-text"><FormattedMessage id="DASHBOARD.TITLE" /></span>
          </NavLink>
        </li>
          <li className={`menu-item ${getMenuItemActive("/projects")}`}>
            <NavLink className="menu-link" to="/projects" data-cy="link-projects">
              <span className="menu-text"><FormattedMessage id="PROJECT.TITLE" /></span>
            </NavLink>
          </li>
        <li className={`menu-item ${getMenuItemActive("/products")}`}>
          <NavLink className="menu-link" to="/products" data-cy="link-products">
            <span className="menu-text"><FormattedMessage id="PRODUCT.TITLE" /></span>
          </NavLink>
        </li>
        <li
          data-menu-toggle={layoutProps.menuDesktopToggle}
          className={`menu-item menu-item-submenu menu-item-rel ${getMenuItemActive("/users") || getMenuItemActive("/clients") || getMenuItemActive("/subcontractors")}`}
        >
          <div className="menu-link menu-toggle" data-cy="link-parent-users">
            <span className="menu-text"><FormattedMessage id="USER.TITLE" /></span>
          </div>
          <div className="menu-submenu menu-submenu-classic menu-submenu-left">
            <ul className="menu-subnav">
              <li className={`menu-item ${getMenuItemActive("/users")}`}>
                <NavLink className="menu-link" to="/users" data-cy="link-users">
                  <span className="menu-text"><FormattedMessage id="USER.TITLE" /></span>
                </NavLink>
              </li>
                <li className={`menu-item ${getMenuItemActive("/clients")}`}>
                  <NavLink className="menu-link" to="/clients" data-cy="link-clients">
                    <span className="menu-text"><FormattedMessage id="CLIENT.AND.LEAD.TITLE" /></span>
                  </NavLink>
                </li>
              <li className={`menu-item ${getMenuItemActive("/subcontractors")}`}>
                <NavLink className="menu-link" to="/subcontractors" data-cy="link-subcontractors">
                  <span className="menu-text"><FormattedMessage id="SUBCONTRACTOR.TITLE" /></span>
                </NavLink>
              </li>
            </ul>
          </div>
        </li>
        <li className={`menu-item menu-item-rel ${getMenuItemActive("/leads")}`}>
          <NavLink className="menu-link" to="/leads" data-cy="link-leads">
            <span className="menu-text"><FormattedMessage id="LEAD.TITLE" /></span>
          </NavLink>
        </li>
        <li className={`menu-item menu-item-rel ${getMenuItemActive("/tasks")}`}>
          <NavLink className="menu-link" to="/tasks" data-cy="link-tasks">
            <span className="menu-text"><FormattedMessage id="TASK.TITLE" /></span>
          </NavLink>
        </li>
      </ul>
    </div>
  );
}
