import ReactHtmlParser from "react-html-parser";

import { TASK_STATES } from "./tasks";

import { canReadAll } from "app/_utils/authUtils";

// const formatUrl = (link) => {
//   const linkWithoutHttp = link?.split("//")?.[1];
//   if (!linkWithoutHttp) {
//     return "";
//   }
//   const linkSplit = linkWithoutHttp.split("/");
//   linkSplit.shift();
//   return linkSplit.join("/");
// };

const iconMap = {
  FILE_SHARED: "fa fa-info-circle",
  FILE_UPDATED: "fa fa-info-circle",
  PROJECT_SHARED: "fas fa-layer-group",
  BO_SIGN_PDF_BO_KYC_FORM_V2: "fas fa-layer-group",
};

export const formatNotification = ({ notification, intl, userId, groups, session }) => {
  try {
    const { notificationType, parameters } = notification;

    if (notificationType === "TEST") {
      return { ...notification };
    }

    const labelValue = formatLabel("IN_APP_NOTIFICATION." + notificationType, parameters, intl);

    if ((notificationType ?? "").startsWith("TASK_")) {
      const tabKey = "tasks";
      const label = labelValue;
      const url = parameters.taskId ? `/tasks/${parameters.taskId}/edit` : parameters.url;

      switch (notificationType) {
        case "TASK_ASSIGNED_TO_USER":
        case "TASK_UNASSIGNED_TO_USER":
        case "TASK_STATUS_CHANGED_WITH_USER_EXEC":
          return {
            ...notification,

            tabKey,
            label,
            colorClass: "text-info",
            icon: "fa fa-info-circle",
            url,
          };
        case "TASK_DELETE":
          return {
            ...notification,

            tabKey,
            label,
            colorClass: "text-info",
            icon: "fa fa-info-circle",
          };
        case "TASK_DEADLINE_REMINDER":
          return {
            ...notification,

            tabKey,
            label,
            colorClass: "text-warning",
            icon: "fas fa-exclamation-triangle",
            url,
          };
        case "TASK_DONE_SIMPLE":
          return {
            ...notification,

            tabKey,
            label,
            colorClass: "text-success",
            icon: "flaticon2-check-mark",
            url,
          };
        default:
          return { ...notification };
      }
    }

    if (notificationType === "BO_SIGN_PDF_BO_KYC_FORM_V2") {
      return {
        ...notification,

        tabKey: "notifications",
        label: labelValue,
        colorClass: "text-info",
        icon: iconMap[notification] ?? "fa fa-info-circle",
        url: `/clients/${parameters.clientId}/kyc/${parameters?.kycId}`,
      };
    }

    if (["FILE_SHARED", "FILE_UPDATED", "PROJECT_SHARED"].includes(notificationType)) {
      return {
        ...notification,

        tabKey: "notifications",
        label: labelValue,
        colorClass: "text-info",
        icon: iconMap[notification] ?? "fa fa-info-circle",
        url: parameters.url,
      };
    }

    //---===---//

    // request tab
    const tabKey = "requests",
      label = labelValue;

    let url = parameters.url;

    switch (notificationType) {
      case "SIGN_DOCUMENT": {
        return {
          ...notification,

          tabKey,
          label,
          colorClass: "text-info",
          icon: "flaticon2-writing",
          url,
        };
      }

      case "SIGN_DOCUMENT_CANCELLED":
      case "SIGN_DOCUMENT_DONE": {
        const { attachments = [] } = parameters;
        const [attachement = {}] = attachments;
        const {
          id: attachementId = "",
          relatedEntity: { type: relatedEntityType, id: relatedEntityId } = { type: "", id: "" },
        } = attachement;

        if (
          relatedEntityType &&
          relatedEntityId &&
          canReadAll(groups, session, relatedEntityType)
        ) {
          const isCurrentUserFile = relatedEntityType === "USER" && relatedEntityId === userId;

          if (isCurrentUserFile) {
            url = `/profile/me`;
          } else {
            url = `/${relatedEntityType.toLowerCase()}s/${relatedEntityId}`;
          }

          url += "?t=files";
          if (relatedEntityId) {
            url += `&h=${attachementId}`;
          }
        }

        let colorClass = "text-success",
          icon = "flaticon2-check-mark";

        if (notificationType === "SIGN_DOCUMENT_CANCELLED") {
          colorClass = "text-warning";
          icon = "flaticon2-warning";
        }

        return {
          ...notification,

          tabKey,
          label,
          colorClass,
          icon,
          url,
        };
      }

      //---===---//

      case "FILE_UPLOAD_REQUEST_DONE": {
        const { userTargetId = "", attachments = [] } = parameters;
        const [attachement = {}] = attachments;
        const { id, userType } = attachement;

        if (userType && userTargetId) {
          url = `/${userType ? `${userType.toLowerCase()}s` : "clients"}/${userTargetId}?t=files`;
          if (id) {
            url += `&h=${id}`;
          }
        }

        return {
          ...notification,

          tabKey,
          label,
          colorClass: "text-success",
          icon: "flaticon2-check-mark",
          url,
        };
      }

      case "FILLOUT_AML_KYC_FORM_DONE": {
        url = `/clients/${parameters.clientId}/kyc/${parameters?.kycId}`;

        return {
          ...notification,

          tabKey,
          label,
          colorClass: "text-success",
          icon: "flaticon2-check-mark",
          url,
        };
      }

      case "FILLOUT_PERSONAL_INFORMATION_DONE": {
        url = `/clients/${parameters.userTargetId}`;

        return {
          ...notification,

          tabKey,
          label,
          colorClass: "text-success",
          icon: "flaticon2-check-mark",
          url,
        };
      }

      case "FILE_UPLOAD_REQUEST": {
        const { attachments = [] } = parameters;
        const [attachement = {}] = attachments;
        const { id = "" } = attachement;

        return {
          ...notification,

          tabKey,
          label,
          colorClass: "text-info",
          icon: "fa fa-info-circle",
          url: `/profile/me?t=files&h=${id}`,
        };
      }

      case "KYC_V2_VIEW_UPDATE":
      case "FILLOUT_AML_KYC_FORM":
      case "FILL_AGAIN_AML_KYC_FORM_V2":
      case "FILLOUT_PERSONAL_INFORMATION": {
        return {
          ...notification,

          tabKey,
          label,
          colorClass: "text-info",
          icon: "fa fa-info-circle",
          url,
        };
      }

      //---===---//

      default:
        return { ...notification };
    }
  } catch (e) {
    console.error(e);
  }
};

export function formatLabel(messageTemplateId, params, intl) {
  const preprocessedParams = { ...params };
  Object.keys(preprocessedParams).forEach((param) => {
    switch (param) {
      case "taskName":
        preprocessedParams.taskName = preprocessedParams?.taskName?.includes("_")
          ? intl.formatMessage({ id: preprocessedParams?.taskName })
          : preprocessedParams?.taskName;
        break;
      case "taskStatusOld":
        preprocessedParams.taskStatusOld = intl.formatMessage({
          id: TASK_STATES[preprocessedParams.taskStatusOld],
        });
        break;
      case "taskStatusNew":
        preprocessedParams.taskStatusNew = intl.formatMessage({
          id: TASK_STATES[preprocessedParams.taskStatusNew],
        });
        break;
      case "attachments":
        preprocessedParams.friendlyName = preprocessedParams?.attachments[0]?.name;
        break;
      default:
        break;
    }
  });
  return ReactHtmlParser(intl.formatMessage({ id: messageTemplateId }, preprocessedParams));
}
