import * as React from "react";
import { FormattedMessage } from "react-intl";
import cn from "clsx";

import {
  TOnConfirmClick,
  SelectAvailableUser,
} from "app/_components/RequestSignatureDialog/SelectAvailableUser";
import { useRequestSignatureDialogContext } from "app/_components/RequestSignatureDialog/RequestSignatureDialogContext";

enum AddSignatoryUIState {
  BUTTON = "BUTTON",
  ADDING = "ADDING",
}

export interface AddSignatoryProps {
  className?: string;
}

export const AddSignatory: React.FunctionComponent<AddSignatoryProps> = ({ className }) => {
  const { isLoading, selectableUsers, addSignatories } = useRequestSignatureDialogContext();

  const [uiState, setUiState] = React.useState(AddSignatoryUIState.BUTTON);
  const switchToAddingUIState = () => setUiState(AddSignatoryUIState.ADDING);
  const switchToButtonUIState = () => setUiState(AddSignatoryUIState.BUTTON);

  const confirmClickHandler: TOnConfirmClick = (selectedUsers) => {
    addSignatories(selectedUsers);
    switchToButtonUIState();
  };

  return (
    <div className={cn("actions", className)}>
      {uiState === AddSignatoryUIState.BUTTON ? (
        <button
          type="button"
          data-cy="add-request"
          className="btn btn-light w-100 mt-2 d-flex align-items-center justify-content-center"
          onClick={switchToAddingUIState}
        >
          <i className="ki ki-plus icon-nm"></i>
          <FormattedMessage id="COMMON.ACTION.ADD" />
        </button>
      ) : (
        <SelectAvailableUser
          className="add-row"
          isLoading={isLoading}
          users={selectableUsers}
          onConfirmClick={confirmClickHandler}
          onCancelClick={switchToButtonUIState}
        />
      )}
    </div>
  );
};

export default AddSignatory;
