import ApexCharts from "apexcharts";
import React, { useEffect } from "react";
import { SUFFIX_PERCENTAGE } from "../../../../../_utils/suffixUtils";

export function RadialBarChart({
  layoutProps,
  series,
  valueLabel,
  insideLabel,
  id,
  color = layoutProps.colorsThemeBasePrimary,
  height = 220,
}) {
  useEffect(() => {
    const element = document.getElementById(id);
    const options = {
      series: series,
      chart: {
        height: height,
        type: "radialBar",
        offsetY: 0,
      },
      plotOptions: {
        radialBar: {
          hollow: {
            margin: 0,
            size: "70%",
          },
          dataLabels: {
            showOn: "always",
            name: {
              show: true,
              fontSize: "13px",
              fontWeight: "400",
              offsetY: -5,
              color: layoutProps.colorsGrayGray500,
              formatter: (value) => insideLabel,
            },
            value: {
              color: color,
              fontSize: "22px",
              fontWeight: "bold",
              offsetY: -40,
              show: true,
              formatter: (value) => valueLabel || value + SUFFIX_PERCENTAGE,
            },
          },
          track: {
            background: layoutProps.colorsGrayGray300,
            strokeWidth: "100%",
          },
        },
      },
      colors: [color],
      stroke: {
        lineCap: "round",
      },
    };
    const chart = new ApexCharts(element, options);
    chart.render();
    return function cleanUp() {
      chart?.destroy();
    };
  }, [layoutProps, series, insideLabel]);

  return <div id={id}></div>;
}
