/*
  Bootstrap 4.5 input group
  https://getbootstrap.com/docs/4.5/components/input-group/
*/

import * as React from "react";
import SVG from "react-inlinesvg";

import { toAbsoluteUrl } from "_metronic/_helpers";

import { AvatarPlaceholderClassName, SmallAvatarImage } from "app/_components/AvatarImage";

import { useProjectOwnerSwitchContext } from "app/modules/PropertiesManagement/pages/projects/ProjectDashboard/ProjectOwnerSwitch/ProjectOwnerSwitchContext";

import ProjectOwnerDisplaySelectedTooltip from "./ProjectOwnerDisplaySelectedTooltip";

export const ProjectOwnerDisplaySelected: React.FunctionComponent = () => {
  const { isLoading, assigneeName, avatarPictureUrl, showSelectModal, toogleDisplaySelectModal } =
    useProjectOwnerSwitchContext();

  const editButtonClickHandler = React.useCallback(() => {
    if (!showSelectModal) toogleDisplaySelectModal();
  }, [showSelectModal]);

  return (
    <ProjectOwnerDisplaySelectedTooltip>
      <div className="projectOwnerDisplay">
        <div className="control" onClick={editButtonClickHandler}>
          <SmallAvatarImage
            className="avatar"
            isLoading={isLoading}
            placeholderClassName={AvatarPlaceholderClassName.COMPANY}
            url={avatarPictureUrl}
          />

          <span className="text">{assigneeName}</span>
          <span className="separator" />
          <span className="svg-icon svg-icon-md">
            <SVG src={toAbsoluteUrl("/media/svg/icons/Communication/Write.svg")} />
          </span>
        </div>
      </div>
    </ProjectOwnerDisplaySelectedTooltip>
  );
};

export default ProjectOwnerDisplaySelected;
