import * as React from "react";
import { Modal } from "react-bootstrap";
import { FormattedMessage, useIntl } from "react-intl";
import { Menu, MenuItem, Typeahead } from "react-bootstrap-typeahead";

import { ModalProgressBar } from "_metronic/_partials/controls";

import { ICompany } from "data/schemas";

import { useProjectOwnerSwitchContext } from "app/modules/PropertiesManagement/pages/projects/ProjectDashboard/ProjectOwnerSwitch/ProjectOwnerSwitchContext";

import ProjectOwnerInfoIcon from "./ProjectOwnerInfoIcon";
import ProjectOwnerDropdownItem from "./ProjectOwnerDropdownItem";

const MODAL_TITLE_ID = "switch-project-owner-modal";

export const ProjectOwnerSelectModal: React.FunctionComponent = () => {
  const intl = useIntl();
  const typeaheadRef = React.useRef<Typeahead<ICompany>>(null);

  const { isLoading, assignee, assignees, setAssignee, showSelectModal, toogleDisplaySelectModal } =
    useProjectOwnerSwitchContext();

  const [selectedProjectOwner, setSelectedProjectOwner] = React.useState<ICompany | undefined>(
    undefined
  );

  const projectOwnerSelectionChangeHandler = React.useCallback(
    ([newProjectOwner]) => {
      if (newProjectOwner === selectedProjectOwner) return;

      setSelectedProjectOwner(newProjectOwner);
    },
    [selectedProjectOwner]
  );

  const triggerModalHide = () => {
    setSelectedProjectOwner(undefined);
    toogleDisplaySelectModal(false);
  };

  const triggerSetAssignee = React.useCallback(() => {
    setAssignee(selectedProjectOwner);
    setSelectedProjectOwner(undefined);
  }, [selectedProjectOwner]);

  const assigneeId = assignee?.id;
  const options = assignees.filter(({ id }) => id !== assigneeId);

  return (
    <Modal
      className="modal-overflow-visible"
      show={showSelectModal}
      onHide={triggerModalHide}
      aria-labelledby={MODAL_TITLE_ID}
      backdrop="static"
      centered
    >
      {isLoading && <ModalProgressBar variant="query" />}

      <Modal.Header id={MODAL_TITLE_ID}>
        <Modal.Title>
          <FormattedMessage id="PROJECT.OWNER" />
        </Modal.Title>
      </Modal.Header>

      <Modal.Body className="pt-3 pb-6">
        <div className="alert d-flex px-3 py-0">
          <ProjectOwnerInfoIcon className="mt-3 mr-3" />

          <div className="d-flex flex-column">
            <FormattedMessage id="PROJECT.OWNER.MESSAGE.WARNING" />
          </div>
        </div>

        <hr />

        <ul>
          <li className="d-flex align-items-center mb-2">
            <i className="fas fa-ghost icon-md" />
            <span className="ml-2">
              <FormattedMessage id="COMMON.NOT_SPECIFIED" />
            </span>
          </li>
          <li className="d-flex align-items-center">
            <i className="fas fa-compress-alt icon-md" />
            <span className="ml-2">
              <FormattedMessage id="COMPANY.REDUCED_VAT" />
            </span>
          </li>
        </ul>

        <Typeahead
          id="project-owner-selection"
          className="projectOwnerSelection"
          ref={typeaheadRef}
          placeholder={intl.formatMessage({ id: "PROJECT.SELECT.OWNER" })}
          clearButton
          options={options}
          labelKey={(option) => option.name || ""}
          onChange={projectOwnerSelectionChangeHandler}
          renderMenu={(options, menuProps) => (
            <Menu {...menuProps}>
              {options.map((company, index) => (
                <MenuItem {...{ key: index, position: index, option: company }}>
                  <ProjectOwnerDropdownItem
                    {...{ company, searchText: (typeaheadRef?.current?.state as any)?.text }}
                  />
                </MenuItem>
              ))}
            </Menu>
          )}
          disabled={isLoading}
        />
      </Modal.Body>

      <Modal.Footer>
        <button type="button" className="btn btn-light mr-4" onClick={triggerModalHide}>
          <FormattedMessage id="COMMON.ACTION.CANCEL" />
        </button>

        <button
          type="button"
          className="btn btn-primary"
          data-cy="button-save-project-owner"
          onClick={triggerSetAssignee}
          disabled={selectedProjectOwner === undefined}
        >
          <FormattedMessage id="COMMON.ACTION.SAVE" />
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default ProjectOwnerSelectModal;
