import { createContext, useState } from "react";
import PropTypes from "prop-types";

export interface KycComplianceContextValue {
  activeIndex: number;
  setActiveIndex: (activeIndex: number) => void;
}

export const KycComplianceContext = createContext<KycComplianceContextValue>({
  activeIndex: 0,
  setActiveIndex: (activeIndex) => undefined,
});

export const KycComplianceProvider = (props: { children: any }): JSX.Element => {
  const { children } = props;

  const [activeIndex, setActiveIndexInternal] = useState<number>(0);

  const setActiveIndex = (activeIndex: number): void => {
    setActiveIndexInternal(activeIndex);
  };

  return (
    <KycComplianceContext.Provider
      value={{
        activeIndex,
        setActiveIndex,
      }}
    >
      {children}
    </KycComplianceContext.Provider>
  );
};

KycComplianceProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export const KycComplianceConsumer = KycComplianceContext.Consumer;
