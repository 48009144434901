import { ColumnFormatter } from "react-bootstrap-table-next";
import * as React from "react";
import { useTag } from "../../hooks/useTag";
import { TagLink } from "../../../data/schemas";
import { calculateMaxDisplayedTags } from "../../_utils/tagsUtils";

const MAX_WIDTH = 192;
export const TagsFormatter: ColumnFormatter<any, any, string> = (
  cell,
  row,
  cellIndex,
  formatExtraData
) => {
  const containerWidth =
    formatExtraData.containerWidth > MAX_WIDTH ? MAX_WIDTH : formatExtraData.containerWidth;
  let values: string[] = [];
  const tags: TagLink[] = formatExtraData.tagLinks ?? [];
  const sortedValues = [...tags].sort((a, b) => {
    return a.tagName.localeCompare(b.tagName);
  });
  values = (
    !formatExtraData.displayMore
      ? sortedValues.splice(
          0,
          calculateMaxDisplayedTags(
            sortedValues.map((t) => ({ label: t.tagName })),
            containerWidth
          )
        )
      : sortedValues
  ).map((t) => t.tagName);
  if (sortedValues.length > 0 && !formatExtraData.displayMore) {
    values.push(sortedValues.length + "+");
  }

  const onClickMore: React.MouseEventHandler = (e) => {
    e.stopPropagation();
    formatExtraData.setDisplayMore(true);
  };

  const isLoading = row === undefined ? true : formatExtraData.isLoading;

  return (
    <div data-cy={`tags-column-${formatExtraData.relatedTo}`} className="tags-column">
      {!isLoading &&
        values.map((v) => (
          <div
            data-cy={`tag-${v.indexOf("+") !== -1 ? "display-more" : v}`}
            key={row.id + "-" + v}
            className="tag"
            onClick={
              v.indexOf("+") !== -1 && formatExtraData.displayMore !== undefined
                ? onClickMore
                : undefined
            }
          >
            {v}
          </div>
        ))}
      {/*{isLoading && <div className={"spinner spinner-lg spinner-primary"} />}*/}
    </div>
  );
};

interface TagsFormatterComponentProps {
  cell: string;
  row: any;
  rowIndex?: number;
  formatExtraData?: { tagType: string; noState: boolean };
}
export const TagsFormatterComponent: React.FC<TagsFormatterComponentProps> = ({
  cell,
  row,
  rowIndex,
  formatExtraData = { noState: false },
}) => {
  const containerRef = React.useRef<HTMLDivElement>(null);
  const [displayMore, setDisplayMore] = React.useState<boolean | undefined>(
    formatExtraData.noState ? undefined : false
  );
  const relatedTo = formatExtraData.tagType + "#" + row.id;
  const { tagLinks, isTagLinksLoading, getTagLinksByKey } = useTag();
  const myTagLinks = tagLinks[relatedTo];
  const isLoading = isTagLinksLoading(relatedTo);
  // Loading the tagLinks for the specified key
  React.useEffect(() => {
    if (tagLinks[relatedTo] === undefined) getTagLinksByKey(relatedTo);
  }, []);
  return (
    <div ref={containerRef}>
      {TagsFormatter(cell, row, rowIndex ?? -1, {
        displayMore,
        setDisplayMore,
        tagLinks: myTagLinks,
        isLoading: isLoading,
        relatedTo,
        containerWidth: containerRef.current?.offsetWidth ?? 0,
      })}
    </div>
  );
};
