import { useQuery, UseQueryResult } from "react-query";
import { API } from "@aws-amplify/api";
import { InvoiceDashboardProjectsData } from "../../../../data/schemas/Dashboard";

export const useGetInvoiceManagement = (
  enabled: boolean
): UseQueryResult<InvoiceDashboardProjectsData> => {
  return useQuery("dashboards", () => API.get("API", `/dashboards`, {}), {
    retry: false,
    enabled,
  });
};
