import React, { FC, MouseEventHandler, useMemo } from "react";
import { useParams } from "react-router-dom";

import { usePublicRequests } from "../../hooks/usePublicRequests";
import { KycAmlFilloutAcceptTerms } from "../../../../_components/kyc-aml/KycAmlFilloutAcceptTerms";

interface KycAmlFilloutAcceptTermsPublicWrapperProps {
  previousStep: MouseEventHandler;
  nextStep: Function;
  breadcrumbs: string[];
}

type HistoryParams = {
  requestId: string;
};

export const KycAmlFilloutAcceptTermsPublicWrapper: FC<
  KycAmlFilloutAcceptTermsPublicWrapperProps
> = ({ previousStep, nextStep, breadcrumbs }) => {
  const { requestId } = useParams<HistoryParams>();

  const { action, settings, postPublicRequestsKycAml, isLoading } = usePublicRequests();

  const unAuthRequestUIProps = usePublicRequests();
  const { formData } = useMemo(
    () => ({
      formData: unAuthRequestUIProps.formData,
    }),
    [unAuthRequestUIProps]
  );

  const submit = (formDataUploadBody: Object) => {
    postPublicRequestsKycAml(requestId, formDataUploadBody).then();
    nextStep();
  };

  return (
    <KycAmlFilloutAcceptTerms
      submit={submit}
      breadcrumbs={breadcrumbs}
      companyName={settings?.companyName}
      formData={formData}
      action={action}
      previousStep={previousStep}
      actionsLoading={isLoading}
    />
  );
};
