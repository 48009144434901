/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import { useSelector } from "react-redux";
import { FormattedMessage } from "react-intl";
import { useHistory } from "react-router-dom";

export function PropertiesCounterWidget({
  className,
  baseColor = "primary",
  widgetHeight = "150px",
}) {
  const { isLoading, counter } = useSelector((state) => ({
    isLoading: state.dashboard.countersLoading,
    counter:
      state.dashboard?.counters?.propertyCounters?.find((c) => c.counterType === "TOTAL")?.value ||
      0,
  }));
  const history = useHistory();

  return (
    <div
      role="button"
      onClick={() => history.push("/products")}
      className={`card card-custom bg-${baseColor} ${className}`}
      style={{ height: widgetHeight }}
    >
      <div className="card-body">
        <span className="svg-icon svg-icon-3x svg-icon-white ml-n2">
          <SVG src={toAbsoluteUrl("/media/svg/icons/Layout/Layout-4-blocks.svg")} />
        </span>
        <div className={`text-inverse-${baseColor} font-weight-bolder font-size-h2 mt-3`}>
          {isLoading ? (
            <span className="spinner spinner-lg spinner-success mr-12" />
          ) : (
            <span>{counter}</span>
          )}
        </div>
        <div className={`text-inverse-${baseColor} font-weight-bold font-size-lg mt-1`}>
          <FormattedMessage id="PROPERTY.TITLE" />
        </div>
      </div>
    </div>
  );
}
