import * as React from "react";
import { ChangeEvent } from "react";
import { ReactSortable } from "react-sortablejs";
import { cloneDeep } from "lodash-es";
import { CustomCard } from "../../../../../../../_components/CustomCard";
import { Field, FieldArray, FieldArrayRenderProps, FormikErrors, useFormikContext } from "formik";
import { Input } from "../../../../../../../../_metronic/_partials/controls";
import { FormattedMessage, useIntl } from "react-intl";
import {
  IBudget,
  IBudgetSortedCategory,
  ISubcontractorFileCategory,
  TSubcontractorFile,
} from "../../../../../../../../data/schemas";
import CreatableSelect from "react-select/creatable";
import { UserFileFinanceLines } from "./UserFileFinanceLines";
import { useUserFilesUIContext } from "../../UserFilesUIContext";
import cn from "clsx";

export interface UserFileFinanceCategoriesProps {
  budget: IBudget;
  isBudgetAvailable: boolean;
  file: TSubcontractorFile;
}

export const UserFileFinanceCategories: React.FC<UserFileFinanceCategoriesProps> = ({
  budget,
  isBudgetAvailable,
  file,
}) => {
  const intl = useIntl();
  const { values, setFieldValue, errors, touched, setFieldTouched } =
    useFormikContext<TSubcontractorFile>();
  const { financeFileDefaultCategory } = useUserFilesUIContext();

  React.useEffect(() => {
    if (file.id && !file.categories) {
      setFieldValue("categories", [financeFileDefaultCategory()]);
    }
  }, [file]);

  const changeBudgetCategory = (
    selected: Partial<IBudgetSortedCategory> | null,
    categoryIndex: number
  ) => {
    setFieldValue(`categories.${categoryIndex}.budgetLineCategoryId`, selected?.id ?? "");
    setFieldValue(`categories.${categoryIndex}.budgetLineCategoryLabel`, selected?.label);
    if (selected?.id) {
      const lines = values.categories[categoryIndex].lines;
      setFieldValue(
        `categories.${categoryIndex}.lines`,
        lines.map(({ budgetLineId, budgetLineLabel, ...line }) => line)
      );
      if (!selected.visible) {
        setFieldValue("visible", false);
      }
    } else {
      for (const lineIndex in values.categories[categoryIndex].lines) {
        setFieldValue(`categories.${categoryIndex}.lines.${lineIndex}.budgetLineId`, "");
      }
    }
  };

  const addCategory =
    (categoryArrayHelpers: FieldArrayRenderProps) => (e: React.MouseEvent<HTMLButtonElement>) => {
      e.preventDefault();
      categoryArrayHelpers.push(financeFileDefaultCategory());
    };

  return (
    <FieldArray
      name="categories"
      validateOnChange={false}
      render={(categoryArrayHelpers) => (
        <>
          <ReactSortable
            list={cloneDeep(values.categories || []).map((category) => ({
              ...category,
              id: category.budgetLineCategoryId,
            }))}
            setList={(sortedCategories) =>
              setFieldValue(
                "categories",
                sortedCategories.map(({ id, ...category }) => category)
              )
            }
            animation={150}
            handle=".card-handle"
            scroll={true}
            bubbleScroll={true}
          >
            {values.categories?.map((category, categoryIndex) => {
              const categoryTouchedId = touched?.categories?.[categoryIndex]?.budgetLineCategoryId;
              const categoryError = errors?.categories?.[
                categoryIndex
              ] as FormikErrors<ISubcontractorFileCategory>;
              const budgetLineCategoryLabelError =
                categoryTouchedId &&
                categoryError?.budgetLineCategoryId &&
                categoryError?.budgetLineCategoryLabel;
              return (
                <CustomCard
                  draggable={true}
                  parentClassName={"mb-4"}
                  isDeleteEnabled={values?.categories?.length > 1}
                  remove={() => categoryArrayHelpers.remove(categoryIndex)}
                  key={categoryIndex}
                  header={
                    <div className="form-row flex-grow-1 ">
                      <div className="col-8 d-flex">
                        {!isBudgetAvailable ? (
                          <Field
                            className="form-control form-control-sm"
                            name="budgetLineCategoryLabel"
                            component={Input}
                            value={category.budgetLineCategoryLabel}
                            onChange={(e: ChangeEvent<HTMLInputElement>) =>
                              setFieldValue(
                                `categories.${categoryIndex}.budgetLineCategoryLabel`,
                                e.target.value
                              )
                            }
                          />
                        ) : (
                          <CreatableSelect
                            name={`categories.${categoryIndex}.budgetLineCategoryId`}
                            onChange={(selected) => changeBudgetCategory(selected, categoryIndex)}
                            isSearchable
                            isClearable
                            options={budget?.sortedLines || []}
                            onBlur={() => {
                              setFieldTouched(`categories.${categoryIndex}.budgetLineCategoryId`);
                            }}
                            value={
                              category.budgetLineCategoryLabel
                                ? {
                                    id: category.budgetLineCategoryId,
                                    label: category.budgetLineCategoryLabel,
                                  }
                                : null
                            }
                            getOptionValue={(option) => option.id}
                            getOptionLabel={(option) => option.label}
                            placeholder={intl.formatMessage({
                              id: "BUDGET.SELECT_BUDGET_CATEGORY",
                            })}
                            classNamePrefix="creatable-select-sm"
                            className={cn(
                              "creatable-select-container",
                              budgetLineCategoryLabelError && "is-invalid"
                            )}
                            menuPosition="absolute"
                            styles={{
                              container: (provided, state) => ({
                                ...provided,
                                width: "100%",
                              }),
                              menu: (provided) => ({ ...provided, zIndex: 9999 }),
                            }}
                          />
                        )}
                      </div>
                    </div>
                  }
                >
                  <div>
                    <div
                      className="form-row pl-9 pr-18 pt-2 font-size-xs font-weight-bold line-height-sm"
                      style={{ color: "#6a6a6a" }}
                    >
                      <div className="col-5 col-sm-5">
                        <FormattedMessage id="COMMON.DESCRIPTION" />
                      </div>
                      <div className="col-5 col-sm-2">
                        <FormattedMessage id="COMMON.AMOUNT" />
                      </div>
                      <div className="col-2 col-sm-2">
                        <FormattedMessage id="COMMON.DISCOUNT" />
                      </div>
                      <div className="col-5 col-sm-2">
                        <FormattedMessage id="COMMON.NET_AMOUNT" />
                      </div>
                      <div className="col-2 col-sm-1">
                        <FormattedMessage id="COMMON.VAT" />
                      </div>
                    </div>
                  </div>
                  <UserFileFinanceLines
                    {...{ category, categoryIndex, isBudgetAvailable, budget }}
                  />
                </CustomCard>
              );
            })}
          </ReactSortable>
          <button
            type="button"
            className="btn btn-light w-100 mt-2 mb-10 d-flex align-items-center justify-content-center"
            onClick={addCategory(categoryArrayHelpers)}
          >
            <i className="ki ki-plus icon-nm" />
            <FormattedMessage id="BUDGET.ACTION.CATEGORY.ADD" />
          </button>
        </>
      )}
    />
  );
};
