import React, { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { connect, shallowEqual, useSelector } from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl";
import * as auth from "../_redux/authRedux";
import { changePassword, getUserInformation } from "../_redux/authCrud";
import { useAuth } from "../../../v2/contexts";

const initialValues = {
  password: "",
  passwordConfirmation: "",
};

function ChangePassword(props) {
  const { user } = useSelector(({ auth }) => {
    return {
      user: auth.user,
    };
  }, shallowEqual);
  const { resetApplicationInitialized } = useAuth();
  const { intl } = props;
  const [loading, setLoading] = useState(false);

  Yup.addMethod(Yup.string, "lowercase", function () {
    return this.test("test-lowercase", function (value) {
      const { path, createError } = this;
      const test = /[a-z]/.test(value);
      if (!test) {
        return createError({
          path,
          message: intl.formatMessage({ id: "AUTH.VALIDATION.PASSWORD.LOWERCASE" }, { value: 1 }),
        });
      }
      return true;
    });
  });
  Yup.addMethod(Yup.string, "uppercase", function () {
    return this.test("test-uppercase", function (value) {
      const { path, createError } = this;
      const test = /[A-Z]/.test(value);
      if (!test) {
        return createError({
          path,
          message: intl.formatMessage({ id: "AUTH.VALIDATION.PASSWORD.UPPERCASE" }, { value: 1 }),
        });
      }
      return true;
    });
  });
  Yup.addMethod(Yup.string, "number", function () {
    return this.test("test-number", function (value) {
      const { path, createError } = this;
      const test = /\d/.test(value);
      if (!test) {
        return createError({
          path,
          message: intl.formatMessage({ id: "AUTH.VALIDATION.PASSWORD.NUMBER" }, { value: 1 }),
        });
      }
      return true;
    });
  });

  const ChangePasswordSchema = Yup.object().shape({
    password: Yup.string()
      .min(8, `${intl.formatMessage({ id: "AUTH.VALIDATION.MIN_CHARACTER" })} 8`)
      .max(100, `${intl.formatMessage({ id: "AUTH.VALIDATION.MAX_CHARACTER" })} 100`)
      .required(intl.formatMessage({ id: "AUTH.VALIDATION.REQUIRED_FIELD" }))
      .lowercase()
      .uppercase()
      .number(),
    passwordConfirmation: Yup.string()
      .required(intl.formatMessage({ id: "AUTH.VALIDATION.REQUIRED_FIELD" }))
      .oneOf(
        [Yup.ref("password"), null],
        intl.formatMessage({ id: "AUTH.VALIDATION.PASSWORD.MATCH" })
      ),
  });

  const enableLoading = () => {
    setLoading(true);
  };

  const disableLoading = () => {
    setLoading(false);
  };

  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }

    return "";
  };

  const formik = useFormik({
    initialValues,
    validationSchema: ChangePasswordSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      enableLoading();
      setTimeout(() => {
        changePassword(user, values.password)
          .then(async (response) => {
            console.log("changePassword Response: ", response);
            const tokens = response.signInUserSession;
            const groups = tokens.accessToken.payload["cognito:groups"] || [];
            getUserInformation(
              tokens.accessToken.payload.username,
              tokens.idToken.payload.email
            ).then((session) => {
              disableLoading();
              props.login(response, session, tokens, groups);
            });
          })
          .catch((err) => {
            console.log("err: ", err);
            disableLoading();
            setSubmitting(false);
            setStatus(
              intl.formatMessage({
                id: "AUTH.VALIDATION.INVALID_LOGIN",
              })
            );
          })
          .finally(() => {
            resetApplicationInitialized();
          });
      }, 1000);
    },
  });

  return (
    <div className="login-form login-signin" id="kt_login_signin_form">
      {/* begin::Head */}
      <div className="text-center mb-10 mb-lg-20">
        <h3 className="font-size-h1">
          <FormattedMessage id="PROFILE.PASSWORD.NEW" />
        </h3>
        <p className="text-muted font-weight-bold">
          <FormattedMessage id="PROFILE.PASSWORD.NEW.ENTER" />
        </p>
      </div>
      {/* end::Head */}

      {/*begin::Form*/}
      <form
        onSubmit={formik.handleSubmit}
        className="form fv-plugins-bootstrap fv-plugins-framework"
      >
        {formik.status && (
          <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
            <div className="alert-text font-weight-bold">{formik.status}</div>
          </div>
        )}

        <div className="form-group fv-plugins-icon-container">
          <label className="form-label">
            <FormattedMessage id={"AUTH.PASSWORD"} />
          </label>
          <input
            type="password"
            data-cy="input-change-password"
            className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
              "password"
            )}`}
            name="password"
            {...formik.getFieldProps("password")}
          />
          {formik.touched.password && formik.errors.password ? (
            <div className="fv-plugins-message-container invalid-feedback">
              <div className="fv-help-block">{formik.errors.password}</div>
            </div>
          ) : null}
        </div>
        <div className="form-group fv-plugins-icon-container">
          <label className="form-label">
            <FormattedMessage id={"AUTH.PASSWORD.REPEAT"} />
          </label>
          <input
            type="password"
            data-cy="input-change-password-repeat"
            className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
              "passwordConfirmation"
            )}`}
            name="passwordConfirmation"
            {...formik.getFieldProps("passwordConfirmation")}
          />
          {formik.touched.passwordConfirmation && formik.errors.passwordConfirmation ? (
            <div className="fv-plugins-message-container invalid-feedback">
              <div className="fv-help-block">{formik.errors.passwordConfirmation}</div>
            </div>
          ) : null}
        </div>
        <div className="form-group d-flex flex-wrap justify-content-end align-items-center">
          <button
            id="kt_change_password_submit"
            data-cy="button-change-password-submit"
            type="submit"
            disabled={formik.isSubmitting || loading}
            className={`btn btn-primary font-weight-bold px-9 py-4 my-3`}
          >
            <span>
              <FormattedMessage id="AUTH.ACTION.CHANGE_PASSWORD" />
            </span>
            {loading && <span className="ml-3 spinner spinner-white" />}
          </button>
        </div>
      </form>
      {/*end::Form*/}
    </div>
  );
}

export default injectIntl(connect(null, auth.actions)(ChangePassword));
