import React, { createContext, useCallback, useContext, useState } from "react";
import { EntityDeleteDialog } from "./EntityDeleteDialog";

const EntityDeleteDialogContext = createContext();

export function useEntityDeleteDialogContext() {
  return useContext(EntityDeleteDialogContext);
}

export function EntityDeleteDialogProvider(props) {
  const [deleteEntityDialog, setDeleteEntityDialog] = useState();
  const unSetDeleteEntityDialog = useCallback(() => {
    setDeleteEntityDialog();
  }, [setDeleteEntityDialog]);

  return (
    <EntityDeleteDialogContext.Provider
      value={{ unSetDeleteEntityDialog, setDeleteEntityDialog }}
      {...props}
    >
      {props.children}
      {deleteEntityDialog && (
        <EntityDeleteDialog
          deleteEntityDialog={deleteEntityDialog}
          unSetDeleteEntityDialog={unSetDeleteEntityDialog}
        />
      )}
    </EntityDeleteDialogContext.Provider>
  );
}
