import * as React from "react";
import PropTypes from "prop-types";
import { useIntl } from "react-intl";
import { KycChoiceOption } from "../modules/kyc-v2/Kyc";
import { IUser } from "../../data/schemas";
import { formatDisplayNameIntl } from "../_utils/userUtils";
import { DateUtils } from "../_utils/DateUtils";
import { Suffix } from "../_utils/suffixUtils";

interface TranslationProps {
  children: React.ReactNode;
}

export interface TranslationContextValue {
  // format message
  fm: (messageId: string, values?: any) => string;

  // format display name (e.g. regions: LU => Luxembourg)
  fdn: (value: string) => string;

  // format options (using format message)
  fmo: (options: KycChoiceOption[]) => KycChoiceOption[];

  // format options (using format display name)
  fmodn: (options: KycChoiceOption[]) => KycChoiceOption[];

  // format displayName
  fDisplayName: (user: IUser, showTitle: boolean) => string;

  //format date
  fDate: (d: Date, showTime?: boolean) => string;

  //format number
  fNumber: (value: number, suffix?: Suffix | string, options?: any) => string;
}

export const TranslationContext = React.createContext<TranslationContextValue>({
  fm: (messageId, values) => "",
  fdn: (value) => "",
  fmo: (options) => [],
  fmodn: (options) => [],
  fDisplayName: (user, showTitle) => "",
  fDate: (d, showTime) => "",
  fNumber: (value, suffix, options) => "",
});

export const TranslationProvider: React.FunctionComponent<TranslationProps> = (props) => {
  const intl = useIntl();

  const { children } = props;

  const fm = (messageId: string, values?: any): string => {
    if (messageId === " ") {
      return " ";
    }
    if (messageId) {
      return intl.formatMessage({ id: messageId }, values);
    }
    return messageId;
  };

  const fdn = (value: string): string => {
    return intl.formatDisplayName(value, { type: "region" }) as string;
  };

  const fmo = (options: KycChoiceOption[]): KycChoiceOption[] => {
    return options.map((option: KycChoiceOption) => ({
      label: fm(option.label),
      value: option.value,
    }));
  };

  const fmodn = (options: KycChoiceOption[]): KycChoiceOption[] => {
    return options.map((option: KycChoiceOption) => ({
      label: fdn(option.label),
      value: option.value,
    }));
  };

  const fDisplayName = (user: IUser, showTitle: boolean = false) => {
    const returnedDisplayName = formatDisplayNameIntl(intl, user, showTitle);
    return returnedDisplayName === fm("USER.UNKNOWN") ? "" : returnedDisplayName;
  };

  const fDate = (d: Date, showTime: boolean = false) => {
    return DateUtils.format(d, intl, showTime);
  };

  const fNumber = (
    value: number,
    suffix?: Suffix | string,
    options: any = {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }
  ): string => {
    return `${intl.formatNumber(value, options)} ${suffix}`;
  };

  return (
    <TranslationContext.Provider
      value={{
        fm,
        fdn,
        fmo,
        fmodn,
        fDisplayName,
        fDate,
        fNumber,
      }}
    >
      {children}
    </TranslationContext.Provider>
  );
};

TranslationProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export const TranslationConsumer = TranslationContext.Consumer;
