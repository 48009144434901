import React, { createContext, useCallback, useContext, useState } from "react";
import { isEqual, isFunction } from "lodash-es";
import { initialFilters } from "./UsersUIHelpers";
import { SUBCONTRACTOR } from "../../../../_utils/userTypes";

const UsersUIContext = createContext();

export function useUsersUIContext() {
  return useContext(UsersUIContext);
}

export const UsersUIConsumer = UsersUIContext.Consumer;

export function UsersUIProvider({ uiEvents, children, readOnly, context, action, userType }) {
  const [filters, setFiltersBase] = useState(initialFilters);
  const [ids, setIds] = useState([]);
  const filterList = [
    { value: "fullName", label: "COMMON.NAME" },
    { value: "mobile", label: "COMMON.MOBILE" },
    { value: "email", label: "COMMON.EMAIL" },
  ];
  if (userType === SUBCONTRACTOR) {
    filterList.unshift({
      value: "company.name",
      label: "COMMON.COMPANY.NAME",
    });
  }
  const setFilters = useCallback((nextFilters) => {
    setFiltersBase((prevFilters) => {
      if (isFunction(nextFilters)) {
        nextFilters = nextFilters(prevFilters);
      }

      if (isEqual(prevFilters, nextFilters)) {
        return prevFilters;
      }

      return nextFilters;
    });
  }, []);

  const value = {
    ids,
    setIds,
    filters,
    setFilters,
    newEntityButtonClick: uiEvents?.newEntityButtonClick,
    openEntityDetailsPage: uiEvents?.openEntityDetailsPage,
    openRemoveUser: uiEvents?.openRemoveUser,
    canOpenRemoveUser: uiEvents?.canOpenRemoveUser,
    openCreateLeadPage: uiEvents?.openCreateLeadPage,
    openTasksPage: uiEvents?.openTasksPage,
    readOnly,
    context,
    action,
    userType,
    filterList,
  };

  return <UsersUIContext.Provider value={value}>{children}</UsersUIContext.Provider>;
}
