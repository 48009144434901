import React from "react";
import ContentLoader from "react-content-loader";

const SkeletonTaskLane = (props: any) => {
  return (
    <ContentLoader height={1000} width={100} {...props}>
      <rect x="0" y="0" rx="5" ry="5" width="100%" height="100%" />
    </ContentLoader>
  );
};

export default SkeletonTaskLane;
