import * as React from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../../../../../_metronic/_helpers";
import { NumberInput } from "../../../../../../../_utils/formUtils";
import { SUFFIX_EURO_CURRENCY } from "../../../../../../../_utils/suffixUtils";

export interface MixVatTooltipProps {
  line: any;
  constructionPriceWithReducedVat?: number;
  currentConstructionPriceWithReducedVat?: number;
  reducedVat?: number;
  defaultVat?: number;
}

export const MixVATTooltip: React.FunctionComponent<MixVatTooltipProps> = ({
  line,
  constructionPriceWithReducedVat = 0,
  currentConstructionPriceWithReducedVat = 0,
  reducedVat = 0,
  defaultVat = 0,
}) => {
  const defaultVatPart = Math.abs(
    constructionPriceWithReducedVat - currentConstructionPriceWithReducedVat
  );
  const reducedVatPart = line.indexedAmount - defaultVatPart;
  return (
    <OverlayTrigger
      placement="top"
      overlay={
        <Tooltip id="layout-tooltip" className={"tooltip-auto-width "}>
          <div className="d-flex flex-column align-items-start">
            <span className="mb-2">
              <FormattedMessage id={"LEAD.LINE_SPLIT_DIFFERENT_VAT"} />
            </span>
            <div className="d-flex">
              <div className="d-flex flex-column mr-2 text-left">
                <span>
                  <FormattedMessage
                    id="LEAD.INVOICE.AMOUNT_WITH"
                    values={{ amount: reducedVat * 100 }}
                  />
                </span>
                <span>
                  <FormattedMessage
                    id="LEAD.INVOICE.AMOUNT_WITH"
                    values={{ amount: defaultVat * 100 }}
                  />
                </span>
              </div>
              <div className="d-flex flex-column text-right font-weight-bold">
                <NumberInput
                  displayType="text"
                  suffix={SUFFIX_EURO_CURRENCY}
                  value={reducedVatPart}
                  decimalScale={2}
                  fixedDecimalScale={true}
                />
                <NumberInput
                  displayType="text"
                  suffix={SUFFIX_EURO_CURRENCY}
                  value={defaultVatPart}
                  decimalScale={2}
                  fixedDecimalScale={true}
                />
              </div>
            </div>
          </div>
        </Tooltip>
      }
    >
      <span className="svg-icon svg-icon-lg svg-icon-warning">
        <SVG src={toAbsoluteUrl("/media/svg/icons/Code/Info-circle.svg")} />
      </span>
    </OverlayTrigger>
  );
};

export const WarningVATTooltip: React.FunctionComponent = () => (
  <OverlayTrigger
    placement="top"
    overlay={
      <Tooltip id="layout-tooltip" className={"tooltip-auto-width"}>
        <FormattedMessage id={"LEAD.LINE_NO_CREDIT_LEFT"} />
      </Tooltip>
    }
  >
    <span className="svg-icon svg-icon-lg svg-icon-danger">
      <SVG src={toAbsoluteUrl("/media/svg/icons/Code/Warning-2.svg")} />
    </span>
  </OverlayTrigger>
);
