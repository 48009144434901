import { FC, KeyboardEventHandler, useEffect, useState } from "react";
import * as React from "react";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import CreatableSelect from "react-select/creatable";
import "./TagManager.scss";
import {
  ActionMeta,
  components,
  DropdownIndicatorProps,
  MultiValue,
  MultiValueRemoveProps,
} from "react-select";
import { FilterOptionOption } from "react-select/dist/declarations/src/filters";
import { cloneDeep, findKey, sortBy } from "lodash";
import cn from "clsx";
import { isEqual } from "lodash-es";
import { useIntl } from "react-intl";
import { useTag } from "../../hooks/useTag";
import { IOption } from "../../_utils/interfacesUtils";
import { calculateMaxDisplayedTags } from "../../_utils/tagsUtils";
import { TagManagerSelected } from "./TagManagerSelected";
import { canCreate, canDelete } from "../../_utils/authUtils";
import { useAppSelector } from "../../../redux/hooks";
import { shallowEqual } from "react-redux";
import { TAG } from "../../_utils/dataTypes";

const MAX_TAGS_SELECTED = 20;
const MAX_CHARS = 40;
export interface TagManagerProps {
  displayLogo?: true;
  readOnly?: boolean;
  className?: string;
  fullWidth?: true;
  relatedTo: string;
}

export const TagManager: FC<TagManagerProps> = ({
  displayLogo,
  readOnly,
  className = "",
  fullWidth,
  relatedTo,
}) => {
  const [containerCreatableWidth, setContainerCreatableWidth] = React.useState<number>(0);
  //const containerCreatableWidth = 0; //containerCreatableRef?.current?.controlRef?.offsetWidth ?? 0;
  const intl = useIntl();
  const [error, setError] = useState("");
  const [displayMore, setDisplayMore] = useState<boolean>(false);
  const [inputValue, setInputValue] = useState<string | undefined>();
  const { groups, session } = useAppSelector((state) => {
    const { auth } = state;
    const { groups, session } = auth;
    return {
      groups,
      session,
    };
  }, shallowEqual);
  // Can't use input if readonly or can't create new tags
  const isDisabled = readOnly || !canCreate(groups, session, TAG);
  // Remove override should be possible if not readonly and cannot create tag
  const overrideRemove =
    !readOnly && !canCreate(groups, session, TAG) && canDelete(groups, session, TAG);
  const [displayRemove, setDisplayRemove] = useState<boolean>(false);
  const {
    tagList: entities,
    tagLinks,
    isUpdating,
    loadTags,
    isTagLinksLoading,
    getTagLinksByKey,
    sendUpdatedTagLinks,
  } = useTag();
  const listLoading = isTagLinksLoading(relatedTo);
  const selectedTags = tagLinks[relatedTo] ?? [];
  // Loading the tagLinks for the specified key
  useEffect(() => {
    if (tagLinks[relatedTo] === undefined) getTagLinksByKey(relatedTo);
  }, []);

  React.useEffect(() => {
    if (entities === undefined) {
      loadTags();
    }
  }, []);

  const [options, setOptions] = useState<IOption[]>(
    !listLoading && (entities?.length ?? 0) > 0
      ? selectedTags.map((x) => ({
          value: x.tagName,
          label: x.tagName,
          id: x.id,
        }))
      : []
  );

  // This use effect is used to update the selected option list if the component is update by the tagProvider for exemple
  React.useEffect(() => {
    if (!listLoading && !isUpdating) {
      // 1st we set the existings tags
      const sortedValuesByName: IOption[] = selectedTags
        .filter((tag) => options.some((option) => option.value === tag.tagName))
        .map((x) => ({ value: x.tagName, label: x.tagName, id: x.id }));

      // Then we get the new in option (so not created yet)
      const remainingTags: IOption[] = options
        .filter((o) => !selectedTags.some((tl) => tl.tagName === o.value) && o.id === undefined)
        .map((o) => ({ value: o.value, label: o.value, id: o?.id }));
      // Then we get those who where not loaded yet from memory
      const newTags = selectedTags
        .filter((tag) => !options.some((option) => option.value === tag.tagName))
        .map((x) => ({ value: x.tagName, label: x.tagName, id: x.id }));

      const sortedByLabelSelectedTags: IOption[] = sortedValuesByName
        .concat(remainingTags, newTags)
        .sort((a, b) => a.value.localeCompare(b.value));

      if (!isEqual(sortedByLabelSelectedTags, options)) setOptions(sortedByLabelSelectedTags);
    }
  }, [listLoading, isUpdating, selectedTags]);

  const triggerUpdate = () => {
    setDisplayRemove(false);
    if (displayMore) setDisplayMore(false);

    // We send the updated items to the provider which will return the updated information
    if (!readOnly || overrideRemove) sendUpdatedTagLinks(options, relatedTo);
  };

  const dropdownItemNoMatch = (inputValue: string) => {
    return (
      <div className="cursor-pointer" data-cy={"tags-input-add"}>
        <span className="mr-2">
          <i className="flaticon-add icon-1x text-muted font-weight-bold"></i>
        </span>
        {intl.formatMessage({ id: "TAGS.ACTIONS.ADD.LABEL" })} "{inputValue.toUpperCase()}"
      </div>
    );
  };

  // Content for the right end part of the tag containing the x (to remove). We only display it when focused
  const MultiValueRemove = (props: MultiValueRemoveProps<IOption, true>) => {
    return (displayRemove || overrideRemove) && !props.data.isDisabled ? (
      <components.MultiValueRemove {...props} />
    ) : (
      <></>
    );
  };

  // We actually use it as the place to render the warning icon on errors. If no errors, no DrodownIndicator
  const DropdownIndicator = (props: DropdownIndicatorProps<IOption, true>) => {
    return (
      <span className="svg-icon svg-icon-danger svg-icon-2x mr-2 ">
        <SVG src={toAbsoluteUrl("/media/svg/icons/Code/Warning-1-circle.svg")} />
      </span>
    );
  };

  // Avoid usage of space and unbreakable space
  const onKeyDown: KeyboardEventHandler = (e) => {
    if (e.key === " " || e.key === " ") {
      // If we ever decide to prevent paste
      // || (e.ctrlKey && e.key === "v")) {
      e.preventDefault();
    }
  };

  const onChange = (newValue: MultiValue<IOption>, actionMeta: ActionMeta<IOption>) => {
    const newVal = actionMeta.option || actionMeta.removedValue;
    let shouldAdd = true;
    if (newVal) {
      // If typing a new option or selecting an existing option
      if (["create-option", "select-option"].includes(actionMeta.action)) {
        // Only checking the current tag value if creating a new option
        if (
          "create-option" === actionMeta.action &&
          // No issue w/ this line see : https://youtrack.jetbrains.com/issue/IDEA-269827/Unknown-character-category-on-ExtendedPictographic-RegExp-Unicode-property-escape
          (newVal.label.match(/\p{Extended_Pictographic}/gu) !== null ||
            newVal.label.length > MAX_CHARS ||
            newVal.label.indexOf(" ") !== -1)
        ) {
          shouldAdd = false;
          setError(intl.formatMessage({ id: "TAGS.INPUT.ERROR" }));
        } else {
          newVal.value = newVal.value.toUpperCase();
          newVal.label = newVal.label.toUpperCase();
        }
        if (shouldAdd) {
          setOptions((prev) => {
            const id = selectedTags.find((x) => x.tagName === newVal.label)?.id;
            return [...prev, { ...newVal, id }];
          });
        } else {
          setInputValue(newVal.label);
        }
      } else if (["deselect-option", "remove-value"].includes(actionMeta.action)) {
        // On unselecting or clicking the remove button on a tag
        setOptions((prev) => [...prev.filter((v) => v.value !== newVal.value)]);
        // This is a specific case : user can remove but can't add new
        if (readOnly && overrideRemove) {
          triggerUpdate();
        }
      }
      if (shouldAdd && error.length > 0) setError("");
    }
  };

  const onInputChange = (value: string) => {
    // In order to clean error and keep focus in the input
    if (error.length > 0 && value === "") {
      setError("");
    }
    setInputValue(value);
  };

  // filterOption is used to dertermine if we either display the "new tag" option and how we filter
  const filterOption = (option: FilterOptionOption<IOption>, inputValue: string) => {
    const isNotFoundKey = findKey(options, (o: IOption) => o.label === option.value) === undefined;
    const isFiltered =
      typeof option.label === "object"
        ? findKey(fetchedTags, (o: IOption) => o.label === inputValue.toUpperCase()) === undefined
        : option.label.toString().indexOf(inputValue.toUpperCase()) > -1;
    return isNotFoundKey && isFiltered;
  };

  // Max tags calculation
  let calculateMaxDisplayed = calculateMaxDisplayedTags(options, containerCreatableWidth);
  const nbFiltered = options.length - calculateMaxDisplayed;
  const displayedValues =
    options.length > calculateMaxDisplayed && !displayMore
      ? [
          ...options.slice(0, calculateMaxDisplayed),
          { value: `${nbFiltered}+`, label: `${nbFiltered}+`, isDisabled: true },
        ]
      : options;
  const sortedValues = sortBy(cloneDeep(entities ?? []), (x) => x.lastUsedAt);
  // Storing the recently used value and all others
  const recentlyUsedOption = !inputValue?.length
    ? sortedValues
        .reverse()
        .splice(0, 5)
        .map((t) => ({
          value: t.name,
          label: t.name,
        }))
    : [];
  const fetchedTags = sortBy(sortedValues, (t) => t.name).map((x) => ({
    label: x.name,
    value: x.name,
  }));

  return (
    <div
      className={cn("align-items-start tags-container", className, "w-100")}
      data-cy={`tags-container-${relatedTo}`}
    >
      {displayLogo && (
        <span className="svg-icon svg-icon-2x mr-4 iconTags">
          <SVG src={toAbsoluteUrl("/media/svg/tags/tags.svg")} />
        </span>
      )}
      <div className={fullWidth ? "w-100" : "max-w-255px"}>
        <div>
          <span
            className={cn("text-dark-75 font-size-sm", displayLogo ? "font-weight-bolder" : "")}
          >
            {intl.formatMessage({ id: "TAGS.TITLE.LABEL" })}
          </span>
        </div>
        <div>
          <CreatableSelect
            ref={(ref) => {
              const value = ref?.controlRef?.offsetWidth ?? 0;
              if (value > 0 && containerCreatableWidth !== value) {
                setContainerCreatableWidth(value);
              }
            }}
            className="tags"
            tabSelectsValue={false}
            isMulti
            isClearable={false}
            isDisabled={isDisabled || listLoading}
            isLoading={listLoading}
            onKeyDown={onKeyDown}
            onChange={onChange}
            onInputChange={onInputChange}
            inputValue={inputValue}
            value={displayedValues}
            onFocus={() => {
              if (canDelete(groups, session, TAG)) {
                setDisplayRemove(true);
              }
            }}
            onBlur={() => {
              triggerUpdate();
            }}
            isOptionDisabled={() => options.length >= MAX_TAGS_SELECTED}
            filterOption={filterOption}
            formatCreateLabel={dropdownItemNoMatch}
            options={[
              {
                label: intl.formatMessage({
                  id: "TAGS.LIST.RECENTLY",
                }),
                options: recentlyUsedOption,
              },
              {
                label: intl.formatMessage({
                  id: "TAGS.LIST.EXISTING",
                }),
                options: fetchedTags,
              },
            ]}
            components={{
              DropdownIndicator: error.length > 0 ? DropdownIndicator : null,
              MultiValue: (props) => (
                <TagManagerSelected
                  tagManagerProp={props}
                  displayMore={displayMore}
                  setDisplayMore={setDisplayMore}
                  calculatedMaxDisplayedTags={calculateMaxDisplayedTags(
                    options,
                    containerCreatableWidth
                  )}
                />
              ),
              MultiValueRemove: MultiValueRemove,
              IndicatorSeparator: null,
              Group: (props) => {
                const dataCy =
                  props.label === intl.formatMessage({ id: "TAGS.LIST.RECENTLY" })
                    ? "tags-container-recently"
                    : "tags-container-existing";
                return (
                  <div data-cy={dataCy}>
                    <components.Group {...props}></components.Group>
                  </div>
                );
              },
            }}
            styles={{
              container: (base) => ({
                ...base,
                minWidth: "200px",
              }),
              multiValueLabel: (base) => ({
                ...base,
                backgroundColor: "#d7dbf1",
                color: "black",
                fontSize: "13px",
                fontWeight: "400",
                minWidth: "8.164px",
                padding: "0.3em 0.5em",
                paddingLeft: "6.5px",
                borderRadius: "3px",
                // if + 1 options (or input focused?) lower to 130
                maxWidth: options.length > 1 ? "130px" : "175px",
              }),
              multiValueRemove: (base) => ({
                ...base,
                backgroundColor: "#d7dbf1 !important",
                paddingLeft: "0px",
                ">svg": {
                  fill: "black",
                },
              }),
              control: (base) => ({
                ...base,
                "&:hover": {
                  borderColor: "none",
                },
                border: `1px solid var(--color-gray-300)`,
                borderRadius: "0.42rem",
                borderColor: error.length === 0 ? "var(--color-primary)" : "red !important",
                boxShadow:
                  error.length === 0
                    ? base.boxShadow !== undefined
                      ? "0 0 0 1px var(--color-primary)"
                      : base.boxShadow
                    : "0 0 0 1px red",
                minHeight: "40px",
                overflow: displayMore ? base.overflow : "hidden",
              }),
              valueContainer: (base) => ({
                ...base,
                "& > div:not([class$='Input'])": {
                  pointerEvents: "initial",
                },
              }),
              input: (base) => ({
                ...base,
                color: error.length === 0 ? base.color : "red",
              }),
              menuList: (base) => ({
                ...base,
                maxHeight: "240px",
              }),
            }}
          />
          {error !== "" && (
            <div className="invalid-feedback d-inline-flex" data-cy="error-tags">
              <span>{error}</span>
            </div>
          )}
          {options.length === MAX_TAGS_SELECTED && (
            <div className="text-warning d-inline-flex" data-cy={"max-reached-tags"}>
              <span>{intl.formatMessage({ id: "TAGS.INPUT.MAX_REACHED" })}</span>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
