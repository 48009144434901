import * as React from "react";
import cn from "clsx";

import { CardBaseProps } from "./definitions";

export interface CardBodyProps extends CardBaseProps {
  fit?: boolean;
  fluid?: boolean;
}

export const CardBody = React.forwardRef<HTMLDivElement, CardBodyProps>(
  ({ className, fit = false, fluid = false, ...props }, ref) => (
    <div
      {...{
        ref,
        className: cn(
          "card-body",
          {
            "card-body-fit": fit,
            "card-body-fluid": fluid,
          },
          className
        ),
        ...props,
      }}
    />
  )
);

export default CardBody;

// Set display name for debugging.
if (process.env.NODE_ENV !== "production") {
  CardBody.displayName = "CardBody";
}
