export const isAddressValid = (address) => {
  return address?.addressLine1 && address?.postalCode && address?.city && address.countryCode;
};

export const formatAddressInline = (address, intl) => {
  if (!isAddressValid(address)) {
    return "Incomplete Address";
  }

  let inlineAddress = address.addressLine1 + ", ";
  if (address.addressLine2) {
    inlineAddress += address.addressLine2 + ", ";
  }
  const country = address.countryCode
    ? intl.formatDisplayName(address.countryCode, { type: "region" })
    : "";
  inlineAddress += `${address.postalCode} ${address.city}, ${country}`;
  return inlineAddress;
};
