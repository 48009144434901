export enum KycTranslation {
  REFERENCE_NAME = "KYC.V2.REFERENCE_NAME.TITLE",
  PROPERTY_NAME = "KYC.V2.PROPERTY_NAME.TITLE",
  CLIENT_NAME = "KYC.V2.CLIENT_NAME.TITLE",
  BO_NAME = "KYC.V2.BO_NAME.TITLE",
  CLIENT_IS = "KYC.V2.CLIENT_IS.TITLE",
  COUNTERPARTY = "KYC.V2.COUNTERPARTY.TITLE",
  DATE_OF_TRANSACTION = "KYC.V2.DATE_OF_TRANSACTION.TITLE",
  DATE_OF_ACQUISITION = "KYC.V2.DATE_OF_ACQUISITION.TITLE",
  TRANSACTION_AMOUNT = "KYC.V2.TRANSACTION_AMOUNT.TITLE",
  PURPOSE_AND_NATURE = "KYC.V2.PURPOSE_AND_NATURE.TITLE",
  FILE_OWNER = "KYC.V2.FILE_OWNER",
  SELECT_USER = "KYC.V2.SELECT_USER",
  IS_PEP = "KYC.V2.IS_PEP",
  TITLE = "KYC.V2.TITLE",
  FIRST_NAME = "KYC.V2.FIRST_NAME",
  FAMILY_NAME = "KYC.V2.FAMILY_NAME",
  NATIONALITY = "KYC.V2.NATIONALITY",
  DATE_OF_BIRTH = "KYC.V2.DATE_OF_BIRTH",
  PLACE_OF_BIRTH = "KYC.V2.PLACE_OF_BIRTH",
  COUNTRY_OF_BIRTH = "KYC.V2.COUNTRY_OF_BIRTH",
  PRIVATE_ADDRESS = "KYC.V2.PRIVATE_ADDRESS",
  PROFESSION = "KYC.V2.PROFESSION",
  PROFESSIONAL_ADDRESS = "KYC.V2.PROFESSIONAL_ADDRESS",
  PASSPORT_ID_NUMBER = "KYC.V2.PASSPORT_ID_NUMBER",
  ID_VALIDITY_DATE = "KYC.V2.ID_VALIDITY_DATE",
  USER_CAN_UPDATE = "KYC.V2.USER_CAN_UPDATE",
  CLIENT_PRESENT = "KYC.V2.CLIENT_PRESENT",
  FORM_COMPLETE_WHO = "KYC.V2.FORM_COMPLETE_WHO",
  LEGAL_TYPE = "KYC.V2.LEGAL_TYPE",
  DEADLINE = "KYC.V2.DEADLINE",
  COMPANY_NAME = "KYC.V2.COMPANY_NAME",
  LEGAL_FORM = "KYC.V2.LEGAL_FORM",
  COMPANY_ADDRESS = "KYC.V2.COMPANY_ADDRESS",
  TRADE_REGISTRY_NUMBER = "KYC.V2.TRADE_REGISTRY_NUMBER",
  WEBSITE = "KYC.V2.WEBSITE",
  CREATE_NOTES = "KYC.V2.CREATE_NOTES",
  DISCLAIMER_ACCEPT_TERMS = "KYC.V2.DISCLAIMER_ACCEPT_TERMS",
  DISCLAIMER_CONTENT = "KYC.V2.DISCLAIMER_CONTENT",
  PDF_GENERATION = "KYC.V2.PDF_GENERATION",
  FLOW_TYPE = "KYC.V2.COMPLIANCE.FLOW_TYPE.LABEL",

  SELECT_LEAD = "KYC.V2.SELECT_LEAD",
  COMPANY_REPRESENTATIVE = "KYC.V2.COMPANY_REPRESENTATIVE",
  BENEFICIAL_OWNER_OF_LEGAL_PERSON = "KYC.V2.BENEFICIAL_OWNER_OF_LEGAL_PERSON",
  CLIENT_IS_VALUE = "KYC.V2.CLIENT_IS.VALUE",
  COUNTERPARTY_VALUE = "KYC.V2.COUNTERPARTY.VALUE",
  WORKFLOW_COMPLETE_BY_CLIENT = "KYC.V2.WORKFLOW.COMPLETE_BY_CLIENT",
  WORKFLOW_COMPLETE_BY_ADMIN = "KYC.V2.WORKFLOW.COMPLETE_BY_ADMIN",
  LEAD_NO_OPTION_ENTRIES = "KYC.V2.LEAD.NO_OPTION_ENTRIES",

  //STEPS
  STEPS_DISCLAIMER_TITLE = "KYC.V2.STEPS.DISCLAIMER.TITLE",
  STEPS_ADMIN_PAGE_1_TITLE = "KYC.V2.STEPS.ADMIN_PAGE_1.TITLE",
  STEPS_ADMIN_PAGE_1_TITLE_CLIENT = "KYC.V2.STEPS.ADMIN_PAGE_1.TITLE.CLIENT",
  STEPS_ADMIN_PAGE_2_TITLE_COMPLETE_BY_CLIENT = "KYC.V2.STEPS.ADMIN_PAGE_2.TITLE_COMPLETE_BY_CLIENT",
  STEPS_ADMIN_PAGE_2_TITLE_COMPLETE_BY_ADMIN = "KYC.V2.STEPS.ADMIN_PAGE_2.TITLE_COMPLETE_BY_ADMIN",
  STEPS_ADMIN_PAGE_2_TITLE_REJECT_RESEND_FORM_COMPLETION = "KYC.V2.STEPS.ADMIN_PAGE_2.TITLE_REJECT_RESEND_FORM_COMPLETION",

  STEPS_CLIENT_PAGE_1_TITLE = "KYC.V2.STEPS.CLIENT_PAGE_1.TITLE",
  STEPS_CLIENT_PAGE_1_SELLER_TITLE = "KYC.V2.STEPS.CLIENT_PAGE_1.SELLER.TITLE",
  STEPS_CLIENT_PAGE_2_TITLE = "KYC.V2.STEPS.CLIENT_PAGE_2.TITLE",
  STEPS_CLIENT_PAGE_3_TITLE = "KYC.V2.STEPS.CLIENT_PAGE_3.TITLE",
  STEPS_CLIENT_PAGE_4_TITLE = "KYC.V2.STEPS.CLIENT_PAGE_4.TITLE",

  STEPS_REJECT_AND_REQUEST_FORM_COMPLETION_TITLE = "KYC.V2.STEPS.REJECT_AND_REQUEST_FORM_COMPLETION.TITLE",

  // SELECT/DROPDOWN OPTIONS
  PROVIDED_SERVICE = "KYC.V2.PROVIDED_SERVICE.TITLE",
  PROVIDED_SERVICE_INTERMEDIARY = "KYC.V2.PROVIDED_SERVICE.INTERMEDIARY",
  PROVIDED_SERVICE_OWNER = "KYC.V2.PROVIDED_SERVICE.OWNER",
  PROVIDED_SERVICE_RENTING = "KYC.V2.PROVIDED_SERVICE.RENTING",
  PROVIDED_SERVICE_DEVELOPMENT = "KYC.V2.PROVIDED_SERVICE.DEVELOPMENT",
  PROVIDED_SERVICE_OTHER = "KYC.V2.PROVIDED_SERVICE.OTHER",
  //
  LEGAL_TYPE_LU_OR_FOREIGN_COMPANY = "KYC.V2.LEGAL_TYPE.LU_OR_FOREIGN_COMPANY",
  LEGAL_TYPE_LISTED_COMPANY = "KYC.V2.LEGAL_TYPE.LISTED_COMPANY",
  LEGAL_TYPE_INVESTMENT_FUND = "KYC.V2.LEGAL_TYPE.INVESTMENT_FUND",
  LEGAL_TYPE_TRUST = "KYC.V2.LEGAL_TYPE.TRUST",
  //
  PURPOSE_OF_ACQUISITION_TITLE = "KYC.V2.PURPOSE_OF_ACQUISITION.TITLE",
  PURPOSE_OF_ACQUISITION_USE_AS_RESIDENCE_ACQUISITION = "KYC.V2.PURPOSE_OF_ACQUISITION.USE_AS_RESIDENCE_ACQUISITION",
  PURPOSE_OF_ACQUISITION_LEASE = "KYC.V2.PURPOSE_OF_ACQUISITION.LEASE",
  PURPOSE_OF_ACQUISITION_RESALE = "KYC.V2.PURPOSE_OF_ACQUISITION.RESALE",
  PURPOSE_OF_ACQUISITION_OTHER = "KYC.V2.PURPOSE_OF_ACQUISITION.OTHER",
  //
  ORIGIN_OF_ASSETS_TITLE = "KYC.V2.ORIGIN_OF_ASSETS.TITLE",
  ORIGIN_OF_ASSETS_PROFESSIONAL_INCOME = "KYC.V2.ORIGIN_OF_ASSETS.PROFESSIONAL_INCOME",
  ORIGIN_OF_ASSETS_RENTAL_INCOME = "KYC.V2.ORIGIN_OF_ASSETS.RENTAL_INCOME",
  ORIGIN_OF_ASSETS_SAVINGS = "KYC.V2.ORIGIN_OF_ASSETS.SAVINGS",
  ORIGIN_OF_ASSETS_SUCCESSION = "KYC.V2.ORIGIN_OF_ASSETS.SUCCESSION",
  ORIGIN_OF_ASSETS_PREVIOUS_PROPERTY_SALE_PROCEEDS = "KYC.V2.ORIGIN_OF_ASSETS.PREVIOUS_PROPERTY_SALE_PROCEEDS",
  ORIGIN_OF_ASSETS_DONATION = "KYC.V2.ORIGIN_OF_ASSETS.DONATION",
  ORIGIN_OF_ASSETS_BANK_LOAN = "KYC.V2.ORIGIN_OF_ASSETS.BANK_LOAN",
  ORIGIN_OF_ASSETS_OTHER = "KYC.V2.ORIGIN_OF_ASSETS.OTHER",
  //
  REPAYMENT_METHOD_TITLE = "KYC.V2.REPAYMENT_METHOD.TITLE",
  REPAYMENT_METHOD_PROFESSIONAL_INCOME = "KYC.V2.REPAYMENT_METHOD.PROFESSIONAL_INCOME",
  REPAYMENT_METHOD_RENTAL_INCOME = "KYC.V2.REPAYMENT_METHOD.RENTAL_INCOME",
  REPAYMENT_METHOD_PREVIOUS_PROPERTY_SALE_PROCEEDS = "KYC.V2.REPAYMENT_METHOD.PREVIOUS_PROPERTY_SALE_PROCEEDS",
  REPAYMENT_METHOD_OTHER = "KYC.V2.REPAYMENT_METHOD.OTHER",
  //
  MEANS_OF_ACQUISITION_BANK_LOAN = "KYC.V2.MEANS_OF_ACQUISITION.BANK_LOAN",
  MEANS_OF_ACQUISITION_PROF_ACTIVITY = "KYC.V2.MEANS_OF_ACQUISITION.PROF_ACTIVITY",
  MEANS_OF_ACQUISITION_SUCCESSION = "KYC.V2.MEANS_OF_ACQUISITION.SUCCESSION",
  MEANS_OF_ACQUISITION_OTHER = "KYC.V2.MEANS_OF_ACQUISITION_OTHER",
  MEANS_OF_ACQUISITION_TITLE = "KYC.V2.MEANS_OF_ACQUISITION.TITLE",
  //
  ORIGIN_OF_FUNDS_TITLE = "KYC.V2.ORIGIN_OF_FUNDS.TITLE",
  //
  LEGAL_ENTITY_TYPES_NATURAL = "CLIENT.TYPES.NATURAL",
  LEGAL_ENTITY_TYPES_LEGAL = "CLIENT.TYPES.LEGAL",

  // INFO subtexts
  EVIDENCE_DOCUMENT_NAME_PROVIDED_SERVICE = "KYC.V2.EVIDENCE.DOCUMENT_NAME.PROVIDED_SERVICE",
  EVIDENCE_DOCUMENT_NAME_CERTIFICATE_INCUMBENCY = "KYC.V2.EVIDENCE.DOCUMENT_NAME.CERTIFICATE_INCUMBENCY",
  EVIDENCE_DOCUMENT_NAME_CONFIRMATION_NOT_BIG_OWNER = "KYC.V2.EVIDENCE.DOCUMENT_NAME.CONFIRMATION_NOT_BIG_OWNER",
  EVIDENCE_DOCUMENT_NAME_PASSPORT_ID_COPY = "KYC.V2.EVIDENCE.DOCUMENT_NAME.PASSPORT_ID_COPY",
  EVIDENCE_DOCUMENT_NAME_COMPANY_STATUTES_LUC_FC_LC = "KYC.V2.EVIDENCE_DOCUMENT_NAME_COMPANY_STATUTES_LUC_FC_LC",
  EVIDENCE_DOCUMENT_NAME_COMPANY_STATUTES_INVESTMENT_FUND = "KYC.V2.EVIDENCE_DOCUMENT_NAME_COMPANY_STATUTES_INVESTMENT_FUND",
  EVIDENCE_DOCUMENT_NAME_COMPANY_STATUTES_TRUST = "KYC.V2.EVIDENCE_DOCUMENT_NAME_COMPANY_STATUTES_TRUST",
  EVIDENCE_DOCUMENT_NAME_TRADE_REGISTER_1 = "KYC.V2.EVIDENCE_DOCUMENT_NAME_TRADE_REGISTER_1",
  EVIDENCE_DOCUMENT_NAME_TRADE_REGISTER_2 = "KYC.V2.EVIDENCE_DOCUMENT_NAME_TRADE_REGISTER_2",
  EVIDENCE_DOCUMENT_NAME_TRADE_REGISTER_NO_DOCS = "KYC.V2.EVIDENCE_DOCUMENT_NAME_TRADE_REGISTER_NO_DOCS",
  EVIDENCE_DOCUMENT_NAME_PURPOSE_OF_ACQUISITION = "KYC.V2.EVIDENCE.DOCUMENT_NAME.PURPOSE_OF_ACQUISITION",
  EVIDENCE_DOCUMENT_NAME_PASSPORT_ID_COPY_LEGAL = "KYC.V2.EVIDENCE.DOCUMENT_NAME.PASSPORT_ID_COPY_LEGAL",
  EVIDENCE_DOCUMENT_NAME_ORIGIN_OF_ASSETS = "KYC.V2.EVIDENCE.DOCUMENT_NAME.ORIGIN_OF_ASSETS",
  EVIDENCE_DOCUMENT_NAME_REPAYMENT_METHOD = "KYC.V2.EVIDENCE.DOCUMENT_NAME.REPAYMENT_METHOD",
  EVIDENCE_DOCUMENT_NAME_POWER_OF_ATTORNEY = "KYC.V2.EVIDENCE.DOCUMENT_NAME.POWER_OF_ATTORNEY",
  EVIDENCE_DOCUMENT_NAME_UTILITY_BILL = "KYC.V2.EVIDENCE.DOCUMENT_NAME.UTILITY_BILL",
  EVIDENCE_DOCUMENT_NAME_ORGANISATION_CHART = "KYC.V2.EVIDENCE.DOCUMENT_NAME.ORGANISATION_CHART",
  EVIDENCE_DOCUMENT_NAME_EXTRACT_RCS_RBE = "KYC.V2.EVIDENCE.DOCUMENT_NAME.EXTRACT_RCS_RBE",
  EVIDENCE_DOCUMENT_ADDITIONAL = "KYC.V2.EVIDENCE.DOCUMENT_NAME.ADDITIONAL",

  EVIDENCE_DOCUMENT_NAME_REGISTER_OF_SHAREHOLDERS = "KYC.V2.EVIDENCE.DOCUMENT_NAME.REGISTER_OF_SHAREHOLDERS",
  EVIDENCE_DOCUMENT_NAME_CERTIFICATE_INCUMBENCY_2 = "KYC.V2.EVIDENCE.DOCUMENT_NAME.CERTIFICATE_INCUMBENCY_2",
  EVIDENCE_DOCUMENT_NAME_ACCOUNTS_YEARLY_PUBLIC = "KYC.V2.EVIDENCE.DOCUMENT_NAME.ACCOUNTS_YEARLY_PUBLIC",
  EVIDENCE_DOCUMENT_NAME_COPY_AUTHORISATION = "KYC.V2.EVIDENCE.DOCUMENT_NAME.COPY_AUTHORISATION",
  EVIDENCE_DOCUMENT_NAME_AGREEMENT_CSSF = "KYC.V2.EVIDENCE.DOCUMENT_NAME.AGREEMENT_CSSF",
  EVIDENCE_DOCUMENT_NAME_PRINT_SCREEN_CONTROL_INSTIT = "KYC.V2.EVIDENCE.DOCUMENT_NAME.PRINT_SCREEN_CONTROL_INSTIT",
  EVIDENCE_DOCUMENT_NAME_PROVE_OF_CONTRIBUTIONS = "KYC.V2.EVIDENCE.DOCUMENT_NAME.PROVE_OF_CONTRIBUTIONS",
  EVIDENCE_DOCUMENT_NAME_SHAREHOLDER_SOCIAL_DOCS = "KYC.V2.EVIDENCE.DOCUMENT_NAME.SHAREHOLDER_SOCIAL_DOCS",
  EVIDENCE_DOCUMENT_NAME_PROVE_REGULATED_FUND = "KYC.V2.EVIDENCE.DOCUMENT_NAME.PROVE_REGULATED_FUND",
  EVIDENCE_DOCUMENT_NAME_COPY_PROSPECTS = "KYC.V2.EVIDENCE.DOCUMENT_NAME.COPY_PROSPECTS",
  EVIDENCE_DOCUMENT_NAME_PRINT_SCREEN_CSSF_MANCO_AIFM = "KYC.V2.EVIDENCE.DOCUMENT_NAME.PRINT_SCREEN_CSSF_MANCO_AIFM",
  EVIDENCE_DOCUMENT_NAME_PASSPORT_ID_UTILITY_BILL_2 = "KYC.V2.EVIDENCE.DOCUMENT_NAME.PASSPORT_ID_UTILITY_BILL_2",
  EVIDENCE_DOCUMENT_NP_WORLD_CHECK = "KYC.V2.EVIDENCE_DOCUMENT_NP_WORLD_CHECK",
  EVIDENCE_DOCUMENT_NP_INTERNET_CHECK = "KYC.V2.EVIDENCE_DOCUMENT_NP_INTERNET_CHECK",
  EVIDENCE_DOCUMENT_L_FC_LC_WORLD_CHECK = "KYC.V2.EVIDENCE_DOCUMENT_L_FC_LC_WORLD_CHECK",
  EVIDENCE_DOCUMENT_L_FC_LC_INTERNET_CHECK = "KYC.V2.EVIDENCE_DOCUMENT_L_FC_LC_INTERNET_CHECK",
  EVIDENCE_DOCUMENT_INVESTMENT_FUND_WORLD_CHECK = "KYC.V2.EVIDENCE_DOCUMENT_INVESTMENT_FUND_WORLD_CHECK",
  EVIDENCE_DOCUMENT_INVESTMENT_FUND_INTERNET_CHECK = "KYC.V2.EVIDENCE_DOCUMENT_INVESTMENT_FUND_INTERNET_CHECK",

  EVIDENCE_DOCUMENT_NAME_REGISTER_OF_SHAREHOLDERS_SHORT = "KYC.V2.EVIDENCE.DOCUMENT_NAME.REGISTER_OF_SHAREHOLDERS_SHORT",
  EVIDENCE_DOCUMENT_NAME_CERTIFICATE_INCUMBENCY_2_SHORT = "KYC.V2.EVIDENCE.DOCUMENT_NAME.CERTIFICATE_INCUMBENCY_2_SHORT",
  EVIDENCE_DOCUMENT_NAME_ACCOUNTS_YEARLY_PUBLIC_SHORT = "KYC.V2.EVIDENCE.DOCUMENT_NAME.ACCOUNTS_YEARLY_PUBLIC_SHORT",
  EVIDENCE_DOCUMENT_NAME_COPY_AUTHORISATION_SHORT = "KYC.V2.EVIDENCE.DOCUMENT_NAME.COPY_AUTHORISATION_SHORT",
  EVIDENCE_DOCUMENT_NAME_AGREEMENT_CSSF_SHORT = "KYC.V2.EVIDENCE.DOCUMENT_NAME.AGREEMENT_CSSF_SHORT",
  EVIDENCE_DOCUMENT_NAME_PRINT_SCREEN_CONTROL_INSTIT_SHORT = "KYC.V2.EVIDENCE.DOCUMENT_NAME.PRINT_SCREEN_CONTROL_INSTIT_SHORT",
  EVIDENCE_DOCUMENT_NAME_PROVE_OF_CONTRIBUTIONS_SHORT = "KYC.V2.EVIDENCE.DOCUMENT_NAME.PROVE_OF_CONTRIBUTIONS_SHORT",
  EVIDENCE_DOCUMENT_NAME_SHAREHOLDER_SOCIAL_DOCS_SHORT = "KYC.V2.EVIDENCE.DOCUMENT_NAME.SHAREHOLDER_SOCIAL_DOCS_SHORT",
  EVIDENCE_DOCUMENT_NAME_PROVE_REGULATED_FUND_SHORT = "KYC.V2.EVIDENCE.DOCUMENT_NAME.PROVE_REGULATED_FUND_SHORT",
  EVIDENCE_DOCUMENT_NAME_COPY_PROSPECTS_SHORT = "KYC.V2.EVIDENCE.DOCUMENT_NAME.COPY_PROSPECTS_SHORT",
  EVIDENCE_DOCUMENT_NAME_PRINT_SCREEN_CSSF_MANCO_AIFM_SHORT = "KYC.V2.EVIDENCE.DOCUMENT_NAME.PRINT_SCREEN_CSSF_MANCO_AIFM_SHORT",
  EVIDENCE_DOCUMENT_NAME_PASSPORT_ID_UTILITY_BILL_2_SHORT = "KYC.V2.EVIDENCE.DOCUMENT_NAME.PASSPORT_ID_UTILITY_BILL_2_SHORT",
  EVIDENCE_DOCUMENT_NP_WORLD_CHECK_SHORT = "KYC.V2.EVIDENCE_DOCUMENT_NP_WORLD_CHECK_SHORT",
  EVIDENCE_DOCUMENT_NP_INTERNET_CHECK_SHORT = "KYC.V2.EVIDENCE_DOCUMENT_NP_INTERNET_CHECK_SHORT",
  EVIDENCE_DOCUMENT_L_FC_LC_WORLD_CHECK_SHORT = "KYC.V2.EVIDENCE_DOCUMENT_L_FC_LC_WORLD_CHECK_SHORT",
  EVIDENCE_DOCUMENT_L_FC_LC_INTERNET_CHECK_SHORT = "KYC.V2.EVIDENCE_DOCUMENT_L_FC_LC_INTERNET_CHECK_SHORT",
  EVIDENCE_DOCUMENT_INVESTMENT_FUND_WORLD_CHECK_SHORT = "KYC.V2.EVIDENCE_DOCUMENT_INVESTMENT_FUND_WORLD_CHECK_SHORT",
  EVIDENCE_DOCUMENT_INVESTMENT_FUND_INTERNET_CHECK_SHORT = "KYC.V2.EVIDENCE_DOCUMENT_INVESTMENT_FUND_INTERNET_CHECK_SHORT",

  EXTRACT_RCS_OR_FOREIGN = "KYC.V2.EXTRACT_RCS_OR_FOREIGN",
  EXTRACT_RCS_OR_FOREIGN_EU = "KYC.V2.EXTRACT_RCS_OR_FOREIGN_EU",
  EXTRACT_RCS_NUMBER_NOT_AVAILABLE = "KYC.V2.EXTRACT_RCS_NUMBER_NOT_AVAILABLE",

  IS_BIG_OWNER = "KYC.V2.BENEFICIAL_OWNER.IS_BIG_OWNER",
  IS_CLIENT_REPRESENTED_BY_AGENT = "KYC.V2.IS_CLIENT_REPRESENTED_BY_AGENT",
  REPRESENTATIVE_IS_MAIN_EXECUTIVE = "KYC.V2.REPRESENTATIVE_IS_MAIN_EXECUTIVE",

  REPRESENTATIVE_ADD = "KYC.V2.REPRESENTATIVE_ADD",
  REPRESENTATIVE = "KYC.V2.REPRESENTATIVE",
  REPRESENTATIVE_SHORT = "KYC.V2.REPRESENTATIVE_SHORT",

  BENEFICIAL_OWNER = "KYC.V2.BENEFICIAL_OWNER",
  BENEFICIAL_OWNER_SHORT = "KYC.V2.BENEFICIAL_OWNER_SHORT",

  BENEFICIAL_OWNER_TITLE = "KYC.V2.BENEFICIAL_OWNER_TITLE",
  BENEFICIAL_OWNER_INFO_SECTION = "KYC.V2.BENEFICIAL_OWNER_INFO_SECTION",
  BENEFICIAL_OWNER_JOB_TITLE = "KYC.V2.BENEFICIAL_OWNER_JOB_TITLE",
  BENEFICIAL_OWNER_NUMBER_OF_SHARES = "KYC.V2.BENEFICIAL_OWNER_NUMBER_OF_SHARES",
  BENEFICIAL_OWNER_NOMINAL_VALUE = "KYC.V2.BENEFICIAL_OWNER_NOMINAL_VALUE",
  BENEFICIAL_OWNER_NOMINAL_VALUE_CURRENCY = "KYC.V2.BENEFICIAL_OWNER_NOMINAL_VALUE_CURRENCY",
  BENEFICIAL_OWNER_PERCENTAGE_SHARE_CAPITAL = "KYC.V2.BENEFICIAL_OWNER_PERCENTAGE_SHARE_CAPITAL",
  BENEFICIAL_OWNER_REPRESENTATIVE = "KYC.V2.BENEFICIAL_OWNER_REPRESENTATIVE",

  IS_MARRIED = "KYC.V2.IS_MARRIED",
  IS_MARRIED_YES_MATRIMONIAL_REGIME = "KYC.V2.IS_MARRIED_YES_MATRIMONIAL_REGIME",
  IS_MARRIED_YES_ACQUISITION_MARTIAL_HOME = "KYC.V2.IS_MARRIED_YES_ACQUISITION_MARTIAL_HOME",
  IS_MARRIED_YES_SALE_MARTIAL_HOME = "KYC.V2.IS_MARRIED_YES_SALE_MARTIAL_HOME",

  REGISTRATION_NUMBER = "KYC.V2.REGISTRATION_NUMBER",
  AGENT = "KYC.V2.AGENT",
  AGENTS = "KYC.V2.AGENTS",

  //
  SUCCESS_CARD_CREATE = "KYC.V2.COMPLETED.SUCCESS",
  ADDRESS_PERSONAL = "KYC.V2.ADDRESS_PRIVATE",
  ADDRESS_COMPANY = "KYC.V2.ADDRESS_COMPANY",
  INFO_NOTIFICATION_IN_APP = "KYC.V2.INFO_NOTIFICATION_IN_APP",
  UPLOAD_CHOOSE_FILE = "KYC.V2.UPLOAD_CHOOSE_FILE",
  NOT_SPECIFIED = "KYC.V2.NOT_SPECIFIED",
  ORIGIN_OF_FUNDS_ACCURATE_DESCRIPTION_BUYER = "KYC.V2.ORIGIN_OF_FUNDS_ACCURATE_DESCRIPTION",
  ORIGIN_OF_FUNDS_ACCURATE_DESCRIPTION_SELLER = "KYC.V2.ORIGIN_OF_FUNDS_ACCURATE_DESCRIPTION_SELLER",

  PLACEHOLDER_FILENAME = "KYC.V2.PLACEHOLDER_FILENAME",
  PREVIOUSLY_REQUESTED_DOCUMENTS = "KYC.V2.PREVIOUSLY_REQUESTED_DOCUMENTS",
  ADDITIONAL_REQUESTED_DOCUMENTS = "KYC.V2.ADDITIONAL_REQUESTED_DOCUMENTS",
  STEPS_RISK_EVALUATION_TITLE = "KYC.V2.STEPS.RISK_EVALUATION.TITLE",
  STEPS_BO_SIGNATURE_REQUEST_TITLE = "KYC.V2.STEPS.BO_SIGNATURE_REQUEST.TITLE",

  SUCCESS_SCREEN_KYC_FLOW_COMPLETED = "KYC.V2.SUCCESS_SCREEN.KYC_FLOW_COMPLETED",
  SUCCESS_SCREEN_KYC_CLIENT_FORM_COMPLETED = "KYC.V2.SUCCESS_SCREEN.KYC_CLIENT_FORM_COMPLETED",
  SUCCESS_SCREEN_KYC_REQUEST_SENT = "KYC.V2.SUCCESS_SCREEN.KYC_REQUEST_SENT",
  SUCCESS_SCREEN_AWAITING_BO_SIGNATURE = "KYC.V2.SUCCESS_SCREEN_AWAITING_BO_SIGNATURE",
  SUCCESS_SCREEN_BO_SIGNATURE_DONE = "KYC.V2.SUCCESS_SCREEN_BO_SIGNATURE_DONE",

  FLOW_TITLE = "KYC.V2.FLOW.TITLE",
  DISCLAIMER_INFORMATION_CORRECTNESS = "KYC.V2.DISCLAIMER_INFORMATION_CORRECTNESS",

  //RISK EVALUATION
  // client pep
  RISK_EVALUATION_CLIENT_RISK_TITLE = "KYC.V2.RISK_EVALUATION.CLIENT_RISK.TITLE",
  RISK_EVALUATION_CLIENT_RISK_IS_PEP_TITLE = "KYC.V2.RISK_EVALUATION.CLIENT_RISK.IS_PEP.TITLE",
  RISK_EVALUATION_CLIENT_RISK_IS_PEP = "KYC.V2.RISK_EVALUATION.CLIENT_RISK.IS_PEP",
  RISK_EVALUATION_CLIENT_RISK_IS_PEP_INFO = "KYC.V2.RISK_EVALUATION.CLIENT_RISK.IS_PEP.INFO",
  RISK_EVALUATION_CLIENT_RISK_NEGATIVE_INFO_INTERNET = "KYC.V2.RISK_EVALUATION.CLIENT_RISK.NEGATIVE_INFO_INTERNET",
  RISK_EVALUATION_CLIENT_RISK_NEGATIVE_INFO_INTERNET_INFO = "KYC.V2.RISK_EVALUATION.CLIENT_RISK.NEGATIVE_INFO_INTERNET.INFO",
  RISK_EVALUATION_CLIENT_RISK_KYC_DONE_BY_OTHER_TOOL = "KYC.V2.RISK_EVALUATION.CLIENT_RISK.KYC_DONE_BY_OTHER_TOOL",
  RISK_EVALUATION_CLIENT_RISK_KYC_DONE_BY_OTHER_TOOL_NAME_OF_TOOL = "KYC.V2.RISK_EVALUATION.CLIENT_RISK.KYC_DONE_BY_OTHER_TOOL.NAME_OF_TOOL",

  // client risks
  RISK_EVALUATION_CLIENT_RISK_CLIENT_POTENTIAL_HIGHER_RISK_TITLE = "KYC.V2.RISK_EVALUATION.CLIENT_RISK.POTENTIAL_HIGHER_RISK.TITLE",
  RISK_EVALUATION_CLIENT_RISK_CLIENT_POTENTIAL_LOWER_RISK_TITLE = "KYC.V2.RISK_EVALUATION.CLIENT_RISK.POTENTIAL_LOWER_RISK.TITLE",
  RISK_EVALUATION_CLIENT_RISK_CLIENT_NOT_PRESENT = "KYC.V2.RISK_EVALUATION.CLIENT_RISK.CLIENT_NOT_PRESENT",
  RISK_EVALUATION_CLIENT_RISK_BELOW_MARKET_ADDITIONAL_CASH = "KYC.V2.RISK_EVALUATION.CLIENT_RISK.BELOW_MARKET_ADDITIONAL_CASH",
  RISK_EVALUATION_CLIENT_RISK_NO_INSPECTION = "KYC.V2.RISK_EVALUATION.CLIENT_RISK.NO_INSPECTION",
  RISK_EVALUATION_CLIENT_RISK_QUICK_BUY_SELL_DUDE = "KYC.V2.RISK_EVALUATION.CLIENT_RISK.QUICK_BUY_SELL_DUDE",
  RISK_EVALUATION_CLIENT_RISK_PAYMENT_PARTIALLY_IN_CASH = "KYC.V2.RISK_EVALUATION.CLIENT_RISK.PAYMENT_PARTIALLY_IN_CASH",
  RISK_EVALUATION_CLIENT_RISK_FREQUENT_CHANGES_SHAREHOLDING = "KYC.V2.RISK_EVALUATION.CLIENT_RISK.FREQUENT_CHANGES_SHAREHOLDING",
  RISK_EVALUATION_CLIENT_RISK_SUSPICION_NOT_TRUSTWORTHY = "KYC.V2.RISK_EVALUATION.CLIENT_RISK.SUSPICION_NOT_TRUSTWORTHY",
  RISK_EVALUATION_CLIENT_RISK_BAD_STRUCTURE = "KYC.V2.RISK_EVALUATION.CLIENT_RISK.BAD_STRUCTURE",
  RISK_EVALUATION_CLIENT_RISK_DIFFICULT_TRANSACTION_STRUCTURE = "KYC.V2.RISK_EVALUATION.CLIENT_RISK.DIFFICULT_TRANSACTION_STRUCTURE",
  RISK_EVALUATION_CLIENT_RISK_ABNORMAL_CIRCUMSTANCES = "KYC.V2.RISK_EVALUATION.CLIENT_RISK.ABNORMAL_CIRCUMSTANCES",
  RISK_EVALUATION_CLIENT_RISK_DETAINED_BY_DESIGNATED_SHAREHOLDERS = "KYC.V2.RISK_EVALUATION.CLIENT_RISK.DETAINED_BY_DESIGNATED_SHAREHOLDERS",
  RISK_EVALUATION_CLIENT_RISK_EX_TRUST_FOUNDATION = "KYC.V2.RISK_EVALUATION.CLIENT_RISK.EX_TRUST_FOUNDATION",
  RISK_EVALUATION_CLIENT_RISK_COUNTRY_NON_EU_ADDITIONAL_REQUESTS = "KYC.V2.RISK_EVALUATION.CLIENT_RISK.COUNTRY_NON_EU_ADDITIONAL_REQUESTS",
  RISK_EVALUATION_CLIENT_RISK_OTHER = "KYC.V2.RISK_EVALUATION.CLIENT_RISK.OTHER",

  // client risks additional
  RISK_EVALUATION_CLIENT_RISK_ADDITIONAL_LONG_TIME_CUSTOMER = "KYC.V2.RISK_EVALUATION.CLIENT_RISK.ADDITIONAL.LONG_TIME_CUSTOMER",
  RISK_EVALUATION_CLIENT_RISK_ADDITIONAL_BANK_RIGHTS = "KYC.V2.RISK_EVALUATION.CLIENT_RISK.ADDITIONAL.BANK_RIGHTS",
  RISK_EVALUATION_CLIENT_RISK_ADDITIONAL_PSF = "KYC.V2.RISK_EVALUATION.CLIENT_RISK.ADDITIONAL.PSF",
  RISK_EVALUATION_CLIENT_RISK_ADDITIONAL_PUBLICLY_TRADED_COMPANY = "KYC.V2.RISK_EVALUATION.CLIENT_RISK.ADDITIONAL.PUBLICLY_TRADED_COMPANY",
  RISK_EVALUATION_CLIENT_RISK_ADDITIONAL_WEAK_CORRUPTION_ADMINISTRATION = "KYC.V2.RISK_EVALUATION.CLIENT_RISK.ADDITIONAL.WEAK_CORRUPTION_ADMINISTRATION",
  RISK_EVALUATION_CLIENT_RISK_ADDITIONAL_OTHER = "KYC.V2.RISK_EVALUATION.CLIENT_RISK.ADDITIONAL.OTHER",

  // geo risk
  RISK_EVALUATION_GEO_TITLE = "KYC.V2.RISK_EVALUATION.GEO.TITLE",
  RISK_EVALUATION_GEO_CLIENT_TITLE = "KYC.V2.RISK_EVALUATION.GEO.CLIENT.TITLE",
  RISK_EVALUATION_GEO_BO_TITLE = "KYC.V2.RISK_EVALUATION.GEO_BO.TITLE",
  RISK_EVALUATION_GEO_CLIENT_BUSINESS_ACTIVITY_TITLE = "KYC.V2.RISK_EVALUATION.GEO.CLIENT_BUSINESS_ACTIVITY.TITLE",
  RISK_EVALUATION_GEO_EFFECTIVE_BENEFICIARY_ACTIVITY_TITLE = "KYC.V2.RISK_EVALUATION.GEO_EFFECTIVE_BENEFICIARY_ACTIVITY.TITLE",

  RISK_EVALUATION_GEO_LOC_CLIENT_RO = "KYC.V2.RISK_EVALUATION.GEO.LOC_CLIENT_RO",
  RISK_EVALUATION_GEO_LOC_BO_RO = "KYC.V2.RISK_EVALUATION.GEO.LOC_BO_RO",
  RISK_EVALUATION_GEO_LOC_CLIENT_POB = "KYC.V2.RISK_EVALUATION.GEO.LOC_CLIENT_POB",
  RISK_EVALUATION_GEO_LOC_BO_POB = "KYC.V2.RISK_EVALUATION.GEO.LOC_BO_POB",

  // geo risk client activity
  RISK_EVALUATION_CLIENT_ACTIVITY_TITLE = "KYC.V2.RISK_EVALUATION.CLIENT_ACTIVITY.TITLE",
  RISK_EVALUATION_CLIENT_ACTIVITY_FIELD_OF_ACTIVITY = "KYC.V2.RISK_EVALUATION.CLIENT_ACTIVITY.FIELD_OF_ACTIVITY",
  RISK_EVALUATION_CLIENT_ACTIVITY_CLIENT_RISK = "KYC.V2.RISK_EVALUATION.CLIENT_ACTIVITY.CLIENT_RISK",
  RISK_EVALUATION_CLIENT_ACTIVITY_SEVERITY_ML_FR = "KYC.V2.RISK_EVALUATION.CLIENT_ACTIVITY.SEVERITY_ML_FR",

  // geo country risk options
  RISK_EVALUATION_GEO_COUNTRY_GAFI_MEMBER = "KYC.V2.RISK_EVALUATION.GEO.COUNTRY.GAFI_MEMBER",
  RISK_EVALUATION_GEO_COUNTRY_GAFI_NOT_MEMBER = "KYC.V2.RISK_EVALUATION.GEO.COUNTRY.GAFI_NOT_MEMBER",
  RISK_EVALUATION_GEO_COUNTRY_GAFI_COOP = "KYC.V2.RISK_EVALUATION.GEO.COUNTRY.GAFI_COOP",
  RISK_EVALUATION_GEO_COUNTRY_GAFI_NOT_COOP = "KYC.V2.RISK_EVALUATION.GEO.COUNTRY.GAFI_NOT_COOP",
  RISK_EVALUATION_GEO_COUNTRY_NOT_EU_ML_TR = "KYC.V2.RISK_EVALUATION.GEO.COUNTRY.NOT_EU.ML_TR",
  RISK_EVALUATION_GEO_COUNTRY_NOT_EU_NOT_ML_TR = "KYC.V2.RISK_EVALUATION.GEO.COUNTRY.NOT_EU.NOT_ML_TR",
  RISK_EVALUATION_GEO_COUNTRY_NOT_EU_WEAK_CORRUPTION = "KYC.V2.RISK_EVALUATION.GEO.COUNTRY.NOT_EU.WEAK_CORRUPTION",
  RISK_EVALUATION_GEO_COUNTRY_NOT_EU_HIGH_CORRUPTION = "KYC.V2.RISK_EVALUATION.GEO.COUNTRY.NOT_EU.HIGH_CORRUPTION",
  RISK_EVALUATION_GEO_COUNTRY_NOT_EU_SANCTION_FREE = "KYC.V2.RISK_EVALUATION.GEO.COUNTRY.NOT_EU.SANCTION_FREE",
  RISK_EVALUATION_GEO_COUNTRY_NOT_EU_SANCTION = "KYC.V2.RISK_EVALUATION.GEO.COUNTRY.NOT_EU.SANCTION",

  // client activities risk option
  RISK_EVALUATION_CLIENT_ACTIVITIES_PRIVATE_BANK = "KYC.V2.RISK_EVALUATION.CLIENT_ACTIVITIES.PRIVATE_BANK",
  RISK_EVALUATION_CLIENT_ACTIVITIES_FAV_ANONYMOUS = "KYC.V2.RISK_EVALUATION.CLIENT_ACTIVITIES.FAV_ANONYMOUS",
  RISK_EVALUATION_CLIENT_ACTIVITIES_SPECIAL_ACTIVITIES = "KYC.V2.RISK_EVALUATION.CLIENT_ACTIVITIES.SPECIAL_ACTIVITIES",
  RISK_EVALUATION_CLIENT_ACTIVITIES_GASTRO = "KYC.V2.RISK_EVALUATION.CLIENT_ACTIVITIES.GASTRO",
  RISK_EVALUATION_CLIENT_ACTIVITIES_GAMBLING = "KYC.V2.RISK_EVALUATION.CLIENT_ACTIVITIES.GAMBLING",
  RISK_EVALUATION_CLIENT_ACTIVITIES_ONG = "KYC.V2.RISK_EVALUATION.CLIENT_ACTIVITIES.ONG",
  RISK_EVALUATION_CLIENT_ACTIVITIES_INNOVATION = "KYC.V2.RISK_EVALUATION.CLIENT_ACTIVITIES.INNOVATION",
  RISK_EVALUATION_CLIENT_ACTIVITIES_NON_PHYSICAL = "KYC.V2.RISK_EVALUATION.CLIENT_ACTIVITIES.NON_PHYSICAL",
  RISK_EVALUATION_CLIENT_ACTIVITIES_OTHER = "KYC.V2.RISK_EVALUATION.CLIENT_ACTIVITIES.OTHER",

  ACTION_SEND_FORM_COMPLETION_REQUEST_TITLE = "KYC.V2.ACTION.SEND_FORM_COMPLETION_REQUEST.TITLE",
  ACTION_SEND_SIGNATURE_REQUEST_TITLE = "KYC.V2.ACTION.SEND_SIGNATURE_REQUEST.TITLE",
  COMPLIANCE_TITLE = "KYC.V2.COMPLIANCE.TITLE",
  ACTION_START_EVALUATION = "KYC.V2.ACTION.START_EVALUATION",
  ACTION_START_CLIENT_REVIEW = "KYC.V2.ACTION.START_CLIENT_REVIEW",
  ACTION_OPEN_KYC_FLOW_DETAILS = "KYC.V2.ACTION.OPEN_KYC_FLOW_DETAILS",
  ACTION_RISK_EVALUATION_ACCEPT = "KYC.V2.ACTION.RISK_EVALUATION.ACCEPT",
  ACTION_RISK_EVALUATION_DECLINE = "KYC.V2.ACTION.RISK_EVALUATION.DECLINE",
  RISK_EVALUATION_GEO_COUNTRY_LOWER_RISK_TITLE = "KYC.V2.RISK_EVALUATION.GEO.COUNTRY.LOWER_RISK.TITLE",
  RISK_EVALUATION_GEO_COUNTRY_HIGHER_RISK_TITLE = "KYC.V2.RISK_EVALUATION.GEO.COUNTRY.HIGHER_RISK.TITLE",
  RISK_EVALUATION_GEO_COUNTRY_INFO_FATF = "KYC.V2.RISK_EVALUATION.GEO.COUNTRY.INFO_FATF",

  RISK_EVALUATION_RISK_LEVEL_CLIENT = "KYC.V2.RISK_EVALUATION.RISK_LEVEL.CLIENT",
  RISK_EVALUATION_RISK_LEVEL_GEO = "KYC.V2.RISK_EVALUATION.RISK_LEVEL.GEO",
  RISK_EVALUATION_RISK_LEVEL_CLIENT_ACTIVITY = "KYC.V2.RISK_EVALUATION.RISK_LEVEL.CLIENT_ACTIVITY",
  RISK_EVALUATION_RISK_LEVEL_WEAK = "KYC.V2.RISK_EVALUATION.RISK_LEVEL.WEAK",
  RISK_EVALUATION_RISK_LEVEL_MEDIUM = "KYC.V2.RISK_EVALUATION.RISK_LEVEL.MEDIUM",
  RISK_EVALUATION_RISK_LEVEL_HIGH = "KYC.V2.RISK_EVALUATION.RISK_LEVEL.HIGH",

  RISK_EVALUATION_REMARKS = "KYC.V2.RISK_EVALUATION.REMARKS",

  CLIENT_BO_IS_DIRECT_SHAREHOLDER_OF_COMPANY = "KYC.V2.CLIENT.BO.IS_DIRECT_SHAREHOLDER_OF_COMPANY",
  CLIENT_BO_IS_INDIRECT_SHAREHOLDER_OF_COMPANY = "KYC.V2.CLIENT.BO.IS_INDIRECT_SHAREHOLDER_OF_COMPANY",

  CLIENT_BO_ADD_SHAREHOLDER_COMPANY = "KYC.V2.CLIENT.BO.ADD_SHAREHOLDER_COMPANY",
  COUNTRY_OF_ESTABLISHMENT = "KYC.V2.COUNTRY_OF_ESTABLISHMENT",
  TRADE_REGISTER_NAME = "KYC.V2.TRADE_REGISTER.NAME",
  TRADE_REGISTER_NAME_TOOLTIP = "KYC.V2.TRADE_REGISTER.NAME.TOOLTIP",
  TRADE_REGISTER_NUMBER = "KYC.V2.TRADE_REGISTER.NUMBER",
  RISK_EVALUATION_GEO_COUNTRY_INFO_FATF_LINK = "KYC.V2.RISK_EVALUATION.GEO.COUNTRY.INFO_FATF_LINK",
  PUBLIC_MANDATE = "KYC.V2.PUBLIC_MANDATE",
  SIGNATURE_SIGNATORY_OFFLINE_SIGN_CONFIRMATION = "KYC.V2.SIGNATURE.SIGNATORY.OFFLINE_SIGN_CONFIRMATION",
  ACTION_OPEN_BO_DECLARATION = "KYC.V2.ACTION.OPEN_BO_DECLARATION",
  ACTION_DOWNLOAD_FILE = "COMMON.ACTION.FILE.DOWNLOAD",
  FILE_NAME_BO_DECLARATION = "KYC.V2.FILE_NAME.BO_DECLARATION",
  ARE_YOU_SURE_SEND_MESSAGE = "COMMON.ACTION.CREATE.ARE_YOU_SURE.KYC_V2",
  ACTION_GENERATING_BO_DECLARATION_LOADING_MESSAGE = "KYC.V2.ACTION.GENERATING_BO_DECLARATION.LOADING_MESSAGE",
  SELECT_PROPDEV_COMPANY = "KYC.V2.SELECT_PROPDEV_COMPANY",
  BO_NOT_DEFINED = "KYC.V2.BO.NOT_DEFINED",
  ACTION_CREATE_KYC_FLOW = "KYC.V2.ACTION.CREATE_KYC_FLOW",
  ACTION_SELECT_ACTIVE_FILE = "KYC.V2.ACTION.SELECT.ACTIVE_FILE",
  ARE_YOU_SURE_SEND_KYC_REQUEST = "KYC.V2.ARE_YOU_SURE_SEND_KYC_REQUEST",
  ACTION_SUBMIT_COMPLETED_FORM = "KYC.V2.ACTION.SUBMIT_COMPLETED_FORM",
  AWAITING_SIGNATURE = "KYC.V2.MESSAGE.AWAITING_SIGNATURE",
  ACTION_KYC_FLOW_APPROVE = "KYC.V2.ACTION.KYC_FLOW.APPROVE",
  ACTION_KYC_FLOW_REJECT = "KYC.V2.ACTION.KYC_FLOW.REJECT",
  ACTION_KYC_FLOW_ABORT = "KYC.V2.ACTION.KYC_FLOW.ABORT",

  SUCCESS_SCREEN_KYC_APPROVED = "KYC.V2.SUCCESS_SCREEN.KYC_APPROVED",
  SUCCESS_SCREEN_KYC_REJECTED = "KYC.V2.SUCCESS_SCREEN.KYC_REJECTED",
  MESSAGE_NOTE_FROM_PROPDEV = "KYC.V2.MESSAGE.NOTE_FROM_PROPDEV",
  MESSAGE_REASON_FOR_REJECTION = "KYC.V2.MESSAGE.REASON_FOR_REJECTION",
  MESSAGE_REASON_FOR_ADDITIONAL_REQUEST = "KYC.V2.MESSAGE.REASON_FURTHER_INFO",

  ACTION_DELETE_KYC_FLOW_ARE_YOU_SURE = "KYC.V2.ACTION.DELETE_KYC_FLOW.ARE_YOU_SURE",
  ACTION_ABORT_KYC_FLOW_ARE_YOU_SURE = "KYC.V2.ACTION.ABORT_KYC_FLOW.ARE_YOU_SURE",
  ACTION_REJECT_KYC_FLOW_ARE_YOU_SURE = "KYC.V2.ACTION.REJECT_KYC_FLOW.ARE_YOU_SURE",
  ACTION_APPROVE_KYC_FLOW_ARE_YOU_SURE = "KYC.V2.ACTION.APPROVE_KYC_FLOW.ARE_YOU_SURE",
  ACTION_DOWNLOAD_PACKAGE = "KYC.V2.ACTION.DOWNLOAD_PACKAGE",
  ACTION_OPEN_FORM = "KYC.V2.ACTION.OPEN_FORM",
  ACTION_OPEN_TASK = "KYC.V2.ACTION.OPEN_TASK",
  FILE_CATEGORY_INTERNAL_DOCUMENT = "KYC.V2.FILE.CATEGORY.INTERNAL_DOCUMENT",
  FILENAME_KYC_DOCUMENT = "KYC.V2.FILENAME.KYC_DOCUMENT",
  FILENAME_BO_DECLARATION = "KYC.V2.FILENAME.BO_DECLARATION",
  FILE_CATEGORY_KYC_DOCUMENT = "KYC.V2.FILE.CATEGORY.KYC_DOCUMENT",
  FILE_CATEGORY_BO_DECLARATION = "KYC.V2.FILE.CATEGORY.BO_DECLARATION",
  AUDIT_LAST_ACTION = "KYC.V2.AUDIT.LAST_ACTION",
  AUDIT_STARTED_ON = "KYC.V2.AUDIT.STARTED_ON",

  FILE_CATEGORY_PASSPORT_IDENTITY = "KYC.V2.FILE.CATEGORY.PASSPORT_IDENTITY_CARD",
  FILE_CATEGORY_ORGANIGRAM = "KYC.V2.FILE.CATEGORY.ORGANIGRAM",
  FILE_CATEGORY_UTILITY_BILL = "KYC.V2.FILE.CATEGORY.UTILITY_BILL",
  FILE_CATEGORY_PROOF_OF_RESIDENCE = "KYC.V2.FILE.CATEGORY.PROOF_OF_RESIDENCE",
  FILE_CATEGORY_TRADE_REGISTER = "KYC.V2.FILE.CATEGORY.TRADE_REGISTER",

  COMPLIANCE_STATUS_BO_DECLARATION_TITLE = "KYC.V2.COMPLIANCE_STATUS_BO_DECLARATION_TITLE",
  BO_TYPE_NATURAL_PERSON = "KYC.V2.BO.TYPE.NATURAL_PERSON",
  BO_TYPE_LEGAL_PERSON = "KYC.V2.BO.TYPE.LEGAL_PERSON",
  BO_TYPE_LEGAL_PERSON_MORE_25 = "KYC.V2.BO.TYPE.LEGAL_PERSON_MORE_25",
  INFO_REPRESENTATIVES_ARE_BOS = "KYC.V2.INFO.REPRESENTATIVES_ARE_BOS",
  INFO_NATURAL_PERSON_OCCUPIES_EXECUTIVE_POSITION = "KYC.V2.INFO.NATURAL_PERSON_OCCUPIES_EXECUTIVE_POSITION",
  SHAREHOLDER_COMPANY = "KYC.V2.SHAREHOLDER_COMPANY",
  SHAREHOLDER_COMPANIES = "KYC.V2.SHAREHOLDER_COMPANIES",
  EVIDENCE_TITLE = "KYC.V2.UPLOAD_LABEL",
  EVIDENCE_PROVIDED = "KYC.V2.PROVIDED_DOCS",

  NO_SIGNATORIES_AVAILABLE = "KYC.V2.INFO.NO_DATA_INDICATOR.SIGNATORIES",
  NO_EVENTS_AVAILABLE = "KYC.V2.INFO.NO_DATA_INDICATOR.EVENTS",
  NO_FILES_AVAILABLE = "KYC.V2.INFO.NO_DATA_INDICATOR.FILES",
  NO_KYC_FLOWS_AVAILABLE = "KYC.V2.INFO.NO_DATA_INDICATOR.KYC_FLOWS_LIST",

  OVERVIEW = "KYC.V2.OVERVIEW",
  ACTION_START = "KYC.V2.ACTION_START_KYC_FLOW",
  MARK_AS_DATA_VALIDATED = "KYC.V2.MARK_AS_VALID_DATA",
  ARE_YOU_SURE_SEND_BO_DECLARATIONS_SIGNATURE_REQUEST = "KYC.V2.ACTION.SEND_BO_DECLARATIONS",
  ACTION_SEND_BO_DECLARATIONS_SIGNATURE_REQUEST_TITLE = "KYC.V2.ACTION.SEND_BO_DECLARATIONS.TITLE",
  ACTION_VALIDATE = "KYC.V2.ACTION_VALIDATE",
  ACTION_VALIDATE_RISK_ASSESSMENT_ARE_YOU_SURE = "KYC.V2.ACTION.VALIDATE_RISK_ASSESSMENT_ARE_YOU_SURE",
  ACTION_VALIDATE_RISK_ASSESSMENT_TITLE = "KYC.V2.ACTION.VALIDATE_RISK_ASSESSMENT.TITLE",
  ARE_YOU_SURE_SUBMIT_COMPLETED_KYC_FORM = "KYC.V2.ARE_YOU_SURE_SUBMIT_COMPLETED_FORM",
  ACTION_SUBMIT_COMPLETED_KYC_FORM_TITLE = "KYC.V2.ARE_YOU_SURE_SUBMIT_COMPLETED_FORM.TITLE",

  STATUS_LABEL_INIT = "KYC.V2.STATUS_LABEL.INIT",
  STATUS_LABEL_CREATED = "KYC.V2.STATUS_LABEL.CREATED",
  STATUS_LABEL_ADMIN_FILLING_IN = "KYC.V2.STATUS_LABEL.ADMIN_FILLING_IN",
  STATUS_LABEL_CLIENT_REVIEW_PENDING = "KYC.V2.STATUS_LABEL.CLIENT_REVIEW_PENDING",
  STATUS_LABEL_ADMIN_REVIEW = "KYC.V2.STATUS_LABEL.ADMIN_REVIEW",
  STATUS_LABEL_CLIENT_REJECT_AND_REVIEW_PENDING = "KYC.V2.STATUS_LABEL.CLIENT_REJECT_AND_REVIEW_PENDING",
  STATUS_LABEL_CLIENT_REVIEW_DONE = "KYC.V2.STATUS_LABEL.CLIENT_REVIEW_DONE",
  STATUS_LABEL_ADMIN_RISK_ASSESSMENT_PENDING = "KYC.V2.STATUS_LABEL.ADMIN_RISK_ASSESSMENT_PENDING",
  STATUS_LABEL_ADMIN_RISK_ASSESSMENT_VALIDATED = "KYC.V2.STATUS_LABEL.ADMIN_RISK_ASSESSMENT_VALIDATED",
  STATUS_LABEL_ADMIN_RISK_ASSESSMENT_DECLINED = "KYC.V2.STATUS_LABEL.ADMIN_RISK_ASSESSMENT_DECLINED",
  STATUS_LABEL_ADMIN_RISK_ASSESSMENT_DONE = "KYC.V2.STATUS_LABEL.ADMIN_RISK_ASSESSMENT_DONE",
  STATUS_LABEL_BO_DECLARATIONS_SIGNATURE_PENDING = "KYC.V2.STATUS_LABEL.BO_DECLARATIONS_SIGNATURE_PENDING",
  STATUS_LABEL_BO_DECLARATIONS_SIGNATURE_DONE = "KYC.V2.STATUS_LABEL.BO_DECLARATIONS_SIGNATURE_DONE",
  STATUS_LABEL_KYC_APPROVED = "KYC.V2.STATUS_LABEL.KYC_APPROVED",
  STATUS_LABEL_KYC_REJECTED = "KYC.V2.STATUS_LABEL.KYC_REJECTED",
  STATUS_LABEL_INIT_NOT_STARTED = "KYC.V2.STATUS_LABEL_INIT_NOT_STARTED",
  STATUS_LABEL_PENDING = "KYC.V2.STATUS.PENDING",
  STATUS_LABEL_VALIDATED = "KYC.V2.STATUS.VALIDATED",
  STATUS_LABEL_CANCELED = "KYC.V2.STATUS_LABEL.CANCELED",
  STATUS_LABEL_IN_PROGRESS = "KYC.V2.STATUS_LABEL.IN_PROGRESS",

  BO_OVERALL_DRAFT = "KYC.V2.STATUS_BO_OVERALL_DRAFT",
  BO_OVERALL_IN_PROGRESS = "KYC.V2.STATUS_BO_OVERALL_IN_PROGRESS",
  BO_OVERALL_DONE = "KYC.V2.STATUS_BO_OVERALL_DONE",
  BO_DECLARATION_DOWNLOAD_REQUIRED_INFO = "KYC.V2.BO_DECLARATION_DOWNLOAD_REQUIRED_INFO",
  OPEN_FORM_DEFAULT = "KYC.V2.OPEN_FORM_DEFAULT",
  FORM = "KYC.V2.FORM",
  OPEN_FORM_CONTINUE = "KYC.V2.OPEN_FORM_CONTINUE",
  OPEN_FORM_REVIEW_FORM_DATA = "KYC.V2.OPEN_FORM_REVIEW_FORM_DATA",
  STATUS_SIGNED = "KYC.V2.BO_DECLARATION.STATUS.SIGNED",
  STEPS_CLIENT_PAGE_3_MISSING_DOCUMENTS = "KYC.V2.STEPS.CLIENT_PAGE_3.MISSING_DOCUMENTS",
  STEPS_CLIENT_PAGE_3_PROVIDED_DOCUMENTS = "KYC.V2.STEPS.CLIENT_PAGE_3.PROVIDED_DOCUMENTS",
  EVIDENCE_DOCUMENT_NAME_NO_BIG_OWNER_SHORT = "KYC.V2.EVIDENCE.DOCUMENT_NAME.NO_BIG_OWNER_SHORT",
  BO_DECLARATION_NOTIFICATION = "KYC.V2.BO_DECLARATION_NOTIFICATION",
  INFO_PURPOSE_OF_THE_ACQUISITION = "KYC.V2.INFO_PURPOSE_OF_THE_ACQUISITION",
  MISSION_LETTER = "KYC.V2.MISSION_LETTER",

  ERROR_MANDATORY_FIELD_DROPDOWN = "KYC.V2.ERROR.MANDATORY_FIELD.DROPDOWN",
  COLUMN_NAMES_ENTITY = "KYC.V2.COLUMN_NAMES.ENTITY",

  KYC_PACKAGE_MODAL_TITLE = "KYC.V2.KYC_PACKAGE.MODAL.TITLE",
  KYC_PACKAGE_MODAL_CONTENT = "KYC.V2.KYC_PACKAGE.MODAL.CONTENT",
  KYC_PACKAGE_MODAL_LOADING = "KYC.V2.KYC_PACKAGE.MODAL.LOADING",
  PLACEHOLDER_NO_VALUE_INDICATOR = "KYC.V2.PLACEHOLDER.NO_VALUE_INDICATOR",
  KYC_PACKAGE_MODAL_PROGRESS_MESSAGE_CONTENT = "KYC.V2.KYC_PACKAGE.MODAL.PROGRESS_MESSAGE_CONTENT",
  ACTION_DELETE_ARE_YOU_SURE_ENTITY = "KYC.V2.ACTION.DELETE.ARE_YOU_SURE.ENTITY",
  SELECT_FLOW_TYPE = "KYC.V2.COMPLIANCE.SELECT_FLOW_TYPE",
  FLOW_TYPE_BUYER = "KYC.V2.COMPLIANCE.FLOW_TYPE.BUYER",
  FLOW_TYPE_SELLER = "KYC.V2.COMPLIANCE.FLOW_TYPE.SELLER",
}
