import { ENABLE_KYC_MODULE, ENABLED_KYC_FLOWS, SERVICES } from "../../configuration";
import { KycFlowType } from "../modules/kyc-v2/Kyc";

export const isBudgetServiceAvailable = (): boolean => {
  return SERVICES.filter((s: any) => s.includes("ServiceBudget")).length > 0;
};

export const isKycAvailable = (): boolean => {
  return (
    SERVICES.filter((s: any) => s.includes("ServiceKyc")).length > 0 && ENABLE_KYC_MODULE === "true"
  );
};

export const kycAvailableFlowTypes = (): KycFlowType[] => {
  return ENABLED_KYC_FLOWS?.split(",") as KycFlowType[];
};

export const isKycMultipleFlowTypesAvailable = (): boolean => {
  return isKycAvailable() && kycAvailableFlowTypes()?.length > 1;
};
