import React, { createContext, useCallback, useContext, useState } from "react";
import { isEqual, isFunction } from "lodash-es";
import { initialFilters } from "./ProductsUIHelpers";

const ProductsUIContext = createContext();

export function useProductsUIContext() {
  return useContext(ProductsUIContext);
}

export const ProductsUIConsumer = ProductsUIContext.Consumer;

export function ProductsUIProvider({
  productsUIEvents,
  children,
  queryParamsInit,
  readOnly,
  context,
}) {
  const [queryParams, setQueryParams] = useState(queryParamsInit);
  const [filters, setFiltersBase] = useState(initialFilters);
  const [ids, setIds] = useState([]);
  const filterList = [
    { value: "name", label: "COMMON.NAME" },
    { value: "address.city", label: "ADDRESS.CITY" },
    { value: "address.countryCode", label: "ADDRESS.COUNTRY" },
    { value: "updatedAt", label: "COMMON.UPDATED.AT" },
    { value: "leadStatus", label: "LEAD.TITLE.SINGLE" },
  ];
  const setFilters = useCallback((nextFilters) => {
    setFiltersBase((prevFilters) => {
      if (isFunction(nextFilters)) {
        nextFilters = nextFilters(prevFilters);
      }

      if (isEqual(prevFilters, nextFilters)) {
        return prevFilters;
      }

      return nextFilters;
    });
  }, []);

  const value = {
    filters,
    setFilters,
    queryParams,
    setQueryParams,
    ids,
    setIds,
    newProductButtonClick: productsUIEvents.newProductButtonClick,
    openProductPage: productsUIEvents.openProductPage,
    openFetchProductsDialog: productsUIEvents.openFetchProductsDialog,
    openCreateLeadPage: productsUIEvents.openCreateLeadPage,
    status: productsUIEvents.status,
    readOnly,
    context,
    filterList,
  };

  return <ProductsUIContext.Provider value={value}>{children}</ProductsUIContext.Provider>;
}
