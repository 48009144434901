import { useQuery, UseQueryResult } from "react-query";
import { API } from "@aws-amplify/api";
import { ILead, ILeadFinancialDocument, IProduct } from "../../../../../data/schemas";

export const useGetRevenue = ({
  projectId,
}: {
  projectId?: string;
}): UseQueryResult<{
  leadFinancialDocuments: ILeadFinancialDocument[];
  leads: ILead[];
  products: IProduct[];
}> => {
  return useQuery("revenue", () =>
    !projectId ? {} : API.get("API", `/projects/${projectId}/revenue`, {})
  );
};
