import * as React from "react";
import { FormattedMessage } from "react-intl";
import { isEqual, isFunction } from "lodash-es";
import { useDidUpdate } from "rooks";
import cn from "clsx";

import { ImageCropper } from "app/_components/ImageCropper";
import { TooltipHandler } from "app/_components/TooltipHandler";

import {
  AvatarImageProps,
  AvatarPlaceholderClassName,
  TSaveAvatarFn,
  TRemoveAvatarFn,
  errorImageHandler,
} from "./definitions";

export interface EditAvatarImageProps extends AvatarImageProps {
  containerClassName?: string;
  symbolContainerClassName?: string;
  isEditable?: boolean;

  imageAltText?: string;
  editTooltipMessage?: React.ReactNode;
  removeTooltipMessage?: React.ReactNode;
  tooltipMessage?: React.ReactNode;

  showAllowedFileTypesLabel?: boolean;

  /** if defined it has priority over the placeholderClassName (it covers the case for the user profile avatar)  */
  placeholderStyleBackgroundImage?: string;

  /** to be used on the input file, for example: .png, .jpg, .jpeg */
  allowedFileTypes?: string;

  onSaveImage: TSaveAvatarFn;
  onRemoveImage?: TRemoveAvatarFn;
}

export const EditAvatarImage: React.FunctionComponent<EditAvatarImageProps> = ({
  containerClassName = "d-flex flex-column align-items-center",
  symbolContainerClassName = "symbol-60 symbol-md-80 symbol-lg-120",

  placeholderClassName = AvatarPlaceholderClassName.LOADING,
  placeholderStyleBackgroundImage,

  editTooltipMessage,
  removeTooltipMessage,
  tooltipMessage,
  imageAltText = "avatar image",

  isLoading = false,
  isEditable = false,

  url,

  showAllowedFileTypesLabel = true,
  allowedFileTypes = ".png, .jpg, .jpeg",

  onSaveImage,
  onRemoveImage,
}) => {
  const [showCropper, setShowCropper] = React.useState(false);
  const [selectedFile, setSelectedFile] = React.useState<File | null>(null);
  const [imageUrl, setImageUrl] = React.useState<string | undefined>(url);

  const canRemove = isEditable && isFunction(onRemoveImage) && imageUrl;

  const allowedFileTypesLabel = showAllowedFileTypesLabel
    ? allowedFileTypes.replaceAll(".", "")
    : "";

  useDidUpdate(() => {
    setImageUrl(url);
  }, [url]);

  const inputFileHandler = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const target = event.currentTarget;
      const files = target.files;
      const newFile: File | null = files ? files[0] : null;
      target.value = "";

      if (isEqual(newFile, selectedFile)) return;

      setSelectedFile(newFile);

      if (!!newFile) {
        setShowCropper(true);
      }
    },
    [selectedFile]
  );

  const closeImageCropper = () => setShowCropper(false);

  const triggerSaveImage = React.useCallback(
    (newImage?: Blob | null) => {
      if (!newImage || !selectedFile) return;

      closeImageCropper();

      onSaveImage(selectedFile.name, newImage, () => {
        setImageUrl(URL.createObjectURL(newImage));
      });
    },
    [selectedFile]
  );

  const triggerRemoveImage = React.useCallback(() => {
    if (!onRemoveImage) return;

    onRemoveImage(() => {
      setImageUrl(undefined);
    });
  }, [onRemoveImage]);

  return (
    <>
      {!!selectedFile && (
        <ImageCropper
          isOpen={showCropper}
          image={URL.createObjectURL(selectedFile)}
          onCancel={closeImageCropper}
          saveCroppedImage={triggerSaveImage}
        />
      )}

      <div className={containerClassName}>
        <div className={cn("symbol", symbolContainerClassName)}>
          <div className="image-input image-input-outline">
            <TooltipHandler
              disabled={!tooltipMessage}
              message={tooltipMessage}
              id="avatar-image-tooltip"
            >
              {!!imageUrl ? (
                <img
                  className="image-input-wrapper"
                  src={imageUrl}
                  alt={imageAltText}
                  onError={errorImageHandler()}
                />
              ) : (
                <>
                  {placeholderStyleBackgroundImage ? (
                    <div
                      className="image-input-wrapper"
                      style={{ backgroundImage: placeholderStyleBackgroundImage }}
                    />
                  ) : (
                    <span className="symbol symbol-label font-weight-bold image-input-wrapper">
                      <i
                        className={cn(
                          "fas text-info icon-xl",
                          isLoading ? AvatarPlaceholderClassName.LOADING : placeholderClassName
                        )}
                      />
                    </span>
                  )}
                </>
              )}
            </TooltipHandler>

            {isEditable && (
              <TooltipHandler
                placement="top"
                id="change-avatar-tooltip"
                message={editTooltipMessage || <FormattedMessage id="PICTURE.CHANGE" />}
              >
                <label
                  className="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow"
                  data-action="change"
                  data-toggle="tooltip"
                >
                  <i className="fa fa-pen icon-sm text-muted" />
                  <input
                    type="file"
                    className="d-none"
                    accept={allowedFileTypes}
                    onChange={inputFileHandler}
                  />
                </label>
              </TooltipHandler>
            )}

            {canRemove && (
              <TooltipHandler
                placement="top"
                id="remove-avatar-tooltip"
                message={removeTooltipMessage || <FormattedMessage id="COMMON.ACTION.REMOVE" />}
              >
                <label
                  className="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow"
                  data-action="remove"
                  data-toggle="tooltip"
                  onClick={triggerRemoveImage}
                >
                  <i className="fa fa-times icon-sm text-muted" />
                </label>
              </TooltipHandler>
            )}
          </div>
        </div>

        {showAllowedFileTypesLabel && (
          <span className="mt-4 form-text text-muted">
            {isEditable ? (
              <>
                <FormattedMessage id="PHOTOS.ALLOWED_FILE_TYPES" />: {allowedFileTypesLabel}.
              </>
            ) : (
              <>&nbsp;</>
            )}
          </span>
        )}
      </div>
    </>
  );
};

export default EditAvatarImage;
