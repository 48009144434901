export enum SignatureStatus {
  CREATED = "CREATED",
  IN_PROGRESS = "IN_PROGRESS",
  SIGNED = "SIGNED",

  /** to support doc signature rejection by the user */
  REJECTED = "REJECTED",

  /** to support doc signatures request cancelation  */
  CANCELLED = "CANCELLED",
}

export type TSignatureStatus = `${SignatureStatus}`;

//----------------------------------------------------------------------------//

export enum SignatureMode {
  DEFAULT = "DEFAULT",
  SIGNATURE_ONLY = "SIGNATURE_ONLY",
}

export type TSignatureMode = `${SignatureMode}`;

//----------------------------------------------------------------------------//

export enum SignatureFlow {
  CONCURRENT = "CONCURRENT",
  SEQUENCE = "SEQUENCE",
}

export type TSignatureFlow = `${SignatureFlow}`;

//----------------------------------------------------------------------------//

export enum SignaturesRequestKind {
  STANDARD = "STANDARD",
  KYC = "KYC",
  BO = "BO",
}

export type TSignaturesRequestKind = `${SignaturesRequestKind}`;

//----------------------------------------------------------------------------//
