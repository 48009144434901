/*
  https://react-select.com/typescript
*/

import * as React from "react";
import { components, GroupBase, StylesConfig } from "react-select";

import {
  baseControlStyles,
  baseStyles,
  ComponentsStyles,
  CustomSelectBase,
  CustomSelectBaseProps,
  CustomSingleValue,
  formatGroupLabel,
  mergeIntoStylesConfig,
} from "../CustomSelectBase";

export interface CustomSelectDefaultProps<
  Option,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>
> extends Omit<CustomSelectBaseProps<Option, IsMulti, Group>, "styles"> {
  styles?: ComponentsStyles;
}

export const CustomSelectDefault = <
  Option,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>
>({
  styles,
  ...props
}: CustomSelectDefaultProps<Option, IsMulti, Group>) => (
  <CustomSelectBase
    styles={mergeIntoStylesConfig<Option, IsMulti, Group>(
      styles,
      baseStyles as any as StylesConfig<Option, IsMulti, Group>,
      baseControlStyles as any as StylesConfig<Option, IsMulti, Group>
    )}
    formatGroupLabel={formatGroupLabel}
    components={{ ...components, SingleValue: CustomSingleValue }}
    {...props}
  />
);

export default CustomSelectDefault;
