import { CSSObjectWithLabel, GroupBase, StylesConfig } from "react-select";

import { ComponentsStyles, StylesConfigFunction } from "./definitions";

//----------------------------------------------------------------------------//

const checkKeysOverlaps = <T = StylesConfig>(stylesConfigs: T[]): boolean => {
  const keys = stylesConfigs.reduce(
    (acc, stylesConfig) => acc.concat(...Object.keys(stylesConfig)),
    [] as string[]
  );
  const uniqueKeys = new Set(keys);
  return keys.length !== uniqueKeys.size;
};

export const mergeStylesConfigsArray = <T = StylesConfig>(stylesConfigs: T[]): T => {
  const { length } = stylesConfigs;

  if (length === 0) return {} as T;
  if (length === 1) return stylesConfigs[0];

  if (!checkKeysOverlaps(stylesConfigs)) {
    // simple merge elements from stylesConfigs
    return stylesConfigs.reduce((config, item) => ({ ...config, ...item }), {} as T);
  }

  // complex merge that will process the current StylesConfigFunctions and build a new unique StylesConfigFunction

  // build a map of the functions of the same key
  const map = stylesConfigs.reduce((acc, item) => {
    Object.entries(item).forEach((item) => {
      const [key, fn] = item as [string, StylesConfigFunction];
      if (acc.hasOwnProperty(key)) {
        acc[key].push(fn);
      } else {
        acc[key] = [fn];
      }
    });
    return acc;
  }, {} as Record<string, StylesConfigFunction[]>);

  // define a new StylesConfigFunction to each key merging the previous functions
  const result = Object.entries(map).reduce((acc, item) => {
    const [key, fns] = item as [string, StylesConfigFunction[]];

    const fn: StylesConfigFunction = (base, props) =>
      fns.reduce(
        (acc, fn) => {
          return {
            ...acc,
            ...fn(base, props),
          };
        },
        { ...base } as CSSObjectWithLabel
      );

    acc[key] = fn;
    return acc;
  }, {} as Record<string, StylesConfigFunction>);

  return result as any as T;
};

export const mergeStylesConfigs = <T = StylesConfig>(...stylesConfigs: T[]): T =>
  mergeStylesConfigsArray(stylesConfigs);

/**
 * from ComponentsStyles into StylesConfig
 */
export const mergeIntoStylesConfig = <
  Option,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>
>(
  styles?: ComponentsStyles,
  ...stylesConfigs: StylesConfig<Option, IsMulti, Group>[]
): StylesConfig<Option, IsMulti, Group> => {
  const baseStyles = mergeStylesConfigsArray(stylesConfigs);

  if (!styles) {
    return baseStyles;
  }

  const redefineStyles = baseStyles as any as Record<string, StylesConfigFunction>;
  Object.entries(styles).forEach(([key, styles]) => {
    if (redefineStyles.hasOwnProperty(key)) {
      const styleFn = redefineStyles[key];
      redefineStyles[key] = ((defaultStyles, props) => ({
        ...styleFn(defaultStyles, props),
        ...styles,
      })) as StylesConfigFunction;
    } else {
      redefineStyles[key] = ((defaultStyles) => ({
        ...defaultStyles,
        ...styles,
      })) as StylesConfigFunction;
    }
  });

  return baseStyles;
};
