import * as React from "react";
import { useBudgetRevenueContext } from "../../../context/BudgetRevenueContext";
import {
  CurrencyMetricCard,
  MetricCard,
  MetricCardProps,
} from "../../../../../../_components/MetricCard";

export const ExpectedInstalmentRevenues: React.FC<MetricCardProps> = (props) => {
  const { unsoldTotal, soldInvoicedInstalments, soldNotInvoicedInstalments } =
    useBudgetRevenueContext();

  const total = unsoldTotal + soldInvoicedInstalments + soldNotInvoicedInstalments;

  return (
    <CurrencyMetricCard
      dataCy="input-budget-revenues-expected"
      value={total}
      label="BUDGET.REVENUE.EXPECTED_INSTALMENT_REVENUE"
      {...props}
    >
      <MetricCard.CurrencySubMetric
        dataCy="input-budget-revenues-expected-sold-invoiced"
        label="BUDGET.REVENUE.SOLD_AND_INVOICED"
        value={soldInvoicedInstalments}
        secondValue={(soldInvoicedInstalments / total) * 100}
      />
      <MetricCard.CurrencySubMetric
        dataCy="input-budget-revenues-expected-sold-not-invoiced"
        label="BUDGET.REVENUE.SOLD_NOT_INVOICED"
        value={soldNotInvoicedInstalments}
        secondValue={(soldNotInvoicedInstalments / total) * 100}
      />
      <MetricCard.CurrencySubMetric
        dataCy="input-budget-revenues-expected-unsold"
        label="BUDGET.REVENUE.UNSOLD"
        value={unsoldTotal}
        secondValue={(unsoldTotal / total) * 100}
      />
    </CurrencyMetricCard>
  );
};
