import React, { FC } from "react";
import { FormattedMessage } from "react-intl";
import { Locker } from "./Locker";

type Props = {
  name: string;
  tab: any;
  setTab: Function;
  translationId: string;
  showLocker?: boolean;
  edit?: boolean;
  data_cy?: string;
};

export const TabNavHeader: FC<Props> = ({
  name,
  tab,
  setTab,
  translationId,
  showLocker = false,
  edit = false,
  data_cy,
}) => {
  return (
    <li className="nav-item" onClick={() => setTab(name)}>
      <span
        className={`nav-link ${tab === name && "active"}`}
        data-cy={data_cy}
        data-toggle="tab"
        role="button"
      >
        {showLocker && <Locker />}
        <FormattedMessage id={translationId} />
        {edit && "*"}
      </span>
    </li>
  );
};
