import { SearchTypeValues } from "../../contexts/SearchContext";

export const filterUITag = (key, tagLinks) => {
  const values = [];
  for (const key of Object.keys(tagLinks)) {
    if (key.indexOf(key) === 0) {
      values.push(...tagLinks[key]);
    }
  }

  return {
    name: "TAGS.TITLE.LABEL",
    value: "tags",
    type: SearchTypeValues.SEARCH_DROPDOWN,
    labelKey: "",
    isMultiple: true,
    values: values,
    isSpecificValues: true,
    formatter: (tag) => {
      return tag.tagName;
    },
    groupBy: (options, entities) => {
      // filter the exisiting options to only take those in entity
      const tags = [];
      const filtered = [];

      if (values.length > 0 && entities?.length > 0)
        values
          .sort((a, b) => a.tagName.localeCompare(b.tagName))
          .forEach((v) => {
            const seekId = v.relatedTo.split("#")[1];
            if (filtered.indexOf(v.tagName) === -1 && entities.some((e) => e.id === seekId)) {
              filtered.push(v.tagName);
              tags.push({ value: v.tagName, label: v.tagName });
            }
          });
      return tags;
    },
    validator: (entity, value) => {
      let isFoundAll = false;
      const foundTagLinks = tagLinks[key + "#" + entity.id] ?? [];
      value.every((v) => {
        isFoundAll = isFoundAll || foundTagLinks.find((t) => t.tagName === v.label);
        return !isFoundAll;
      });
      return value.length > 0 ? isFoundAll : true;
    },
  };
};
