import React, { FC, Fragment, useRef, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { FieldArray, useFormikContext } from "formik";
import { CustomCard, CustomCardLine } from "./CustomCard";
import { ENTITY_ICON } from "../_utils/listUtils";
import { AsyncTypeahead, Menu, MenuItem } from "react-bootstrap-typeahead";
import { FieldArrayRenderProps } from "formik/dist/FieldArray";
import { IFile, ILinkedFile } from "../../data/schemas";
import { useFilesContext } from "../modules/File/FilesProvider";
import { groupBy, sortBy } from "lodash-es";
import { DropdownItem } from "./actions-menu/DropdownItem";
import { searchFiles } from "../modules/File/filesCrud";

export interface LinkedFilesProps {
  activeFile: IFile;
}

const relatedEntitiesOrder = ["PROJECT", "PRODUCT", "LEAD", "USER"];

export const LinkedFiles: FC<LinkedFilesProps> = ({ activeFile }) => {
  const intl = useIntl();
  const { values } = useFormikContext<any>();
  const {
    openEditLinkedFile,
    openLinkedFile,
    addLinkedFiles,
    removeLinkedFile: removeLinkedFileContext,
  } = useFilesContext();

  const [showSearchFileInput, setShowSearchFileInput] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState<IFile[]>([]);
  const [filesOptions, setFilesOptions] = useState<IFile[]>([]);
  const [filesOptionsLoading, setFilesOptionsLoading] = useState(false);
  const typeaheadRef = useRef(null);

  const handleFileSearch = (fileToSearch: string) => {
    setFilesOptionsLoading(true);
    searchFiles(fileToSearch)
      .then((response) => {
        setFilesOptions(
          response.filter(
            (fileToLink: IFile) =>
              !activeFile.linkedFiles?.find(
                (linkedFile: ILinkedFile) => linkedFile.id === fileToLink.id
              ) &&
              !selectedFiles.includes(fileToLink) &&
              activeFile.id !== fileToLink.id
          )
        );
      })
      .finally(() => setFilesOptionsLoading(false));
  };

  const cancelLinkFiles = () => {
    setSelectedFiles([]);
    setShowSearchFileInput(false);
  };

  const linkSelectedFiles = (push: FieldArrayRenderProps["push"]) => {
    const newLinkedFiles = [];
    for (const selectedFile of selectedFiles) {
      const { id, friendlyName, relatedEntity } = selectedFile;
      const linkedFile = {
        id: id!,
        friendlyName,
        relatedEntity,
      };
      newLinkedFiles.push(linkedFile);
      push(linkedFile);
    }
    if (activeFile.id) {
      addLinkedFiles(activeFile, newLinkedFiles);
    }
    setSelectedFiles([]);
    setShowSearchFileInput(false);
  };

  const removeLinkedFile = (
    remove: FieldArrayRenderProps["remove"],
    linkedFile: ILinkedFile,
    index: number
  ) => {
    remove(index);
    if (activeFile.id) {
      removeLinkedFileContext(activeFile, linkedFile);
    }
  };

  return (
    <div className="form-group">
      <div className="d-flex align-items-center justify-content-between">
        <span className="font-size-lg font-weight-bold">
          <FormattedMessage id="FILE.LINKED_FILES" />
        </span>
        <button
          className="btn btn-icon btn-icon-primary"
          type={"button"}
          onClick={() => setShowSearchFileInput(true)}
        >
          <i className="ki ki-plus icon-md"></i>
        </button>
      </div>
      <FieldArray name="linkedFiles">
        {({ remove, push }) => (
          <>
            <CustomCard>
              {values.linkedFiles?.map((linkedFile: ILinkedFile, index: number) => {
                const entityIcon = ENTITY_ICON[linkedFile.relatedEntity.type];
                return (
                  <CustomCardLine
                    key={linkedFile.id}
                    removable={true}
                    remove={() => removeLinkedFile(remove, linkedFile, index)}
                    actions={
                      <DropdownItem
                        onClick={() => openEditLinkedFile(linkedFile)}
                        icon={"/media/svg/icons/Communication/Write.svg"}
                        title={"COMMON.ACTION.EDIT"}
                      />
                    }
                  >
                    <div className="d-flex align-items-center flex-grow-1">
                      <i
                        style={{
                          fontSize: 16,
                          color: entityIcon?.color,
                        }}
                        className={`fas fa-${entityIcon?.icon} icon-md mr-2`}
                      />
                      <span
                        className={"font-weight-bold cursor-pointer"}
                        onClick={() => openLinkedFile(linkedFile)}
                      >
                        <span className={"text-muted"}>{linkedFile.relatedEntity.name}</span>
                        {" | "}
                        <span>{linkedFile.friendlyName}</span>
                      </span>
                    </div>
                  </CustomCardLine>
                );
              })}
            </CustomCard>
            {showSearchFileInput && (
              <div className={"d-flex flex-column"}>
                <AsyncTypeahead
                  id="search-file-typeahead"
                  ref={typeaheadRef}
                  paginate={false}
                  multiple
                  positionFixed
                  labelKey={"friendlyName"}
                  filterBy={() => true}
                  selected={selectedFiles}
                  autoFocus={true}
                  onChange={(selected) => {
                    setSelectedFiles(selected);
                  }}
                  onSearch={handleFileSearch}
                  options={filesOptions}
                  isLoading={filesOptionsLoading}
                  placeholder={intl.formatMessage({ id: "FILE.ACTION.BROWSE" })}
                  className={"flex-grow-1 mb-2"}
                  renderMenu={(files, menuProps) => {
                    let index = 0;
                    const relatedEntities = groupBy(files, "relatedEntity.type");
                    const items = sortBy(Object.entries(relatedEntities), ([relatedEntityType]) =>
                      relatedEntitiesOrder.indexOf(relatedEntityType)
                    ).map(([relatedEntityType, files]) => {
                      const entityIcon = ENTITY_ICON[relatedEntityType];
                      return (
                        <Fragment key={relatedEntityType}>
                          {index !== 0 && <Menu.Divider />}
                          <Menu.Header>
                            <i
                              style={{
                                fontSize: 16,
                                color: entityIcon?.color,
                              }}
                              className={`fas fa-${entityIcon?.icon} icon-md mr-2`}
                            />
                            {relatedEntityType}
                          </Menu.Header>
                          {files.map((file) => {
                            const item = (
                              <MenuItem option={file} position={index} key={index}>
                                <span className={"font-weight-bold"}>
                                  <span className={"text-muted"}>{file.relatedEntity.name}</span>
                                  {" | "}
                                  <span>{file.friendlyName}</span>
                                </span>
                              </MenuItem>
                            );

                            index += 1;
                            return item;
                          })}
                        </Fragment>
                      );
                    });
                    return <Menu {...menuProps}>{items}</Menu>;
                  }}
                />
                <div className={"align-self-end"}>
                  <button
                    type="button"
                    className="btn btn-text-dark btn-hover-light-dark mr-4"
                    onClick={cancelLinkFiles}
                  >
                    <FormattedMessage id="COMMON.ACTION.CANCEL" />
                  </button>
                  <button
                    type="button"
                    className="btn btn-text-primary btn-hover-light-primary font-weight-bold m-0"
                    disabled={!selectedFiles?.length}
                    onClick={() => linkSelectedFiles(push)}
                  >
                    <FormattedMessage id="COMMON.ACTION.LINK" />
                  </button>
                </div>
              </div>
            )}
          </>
        )}
      </FieldArray>
    </div>
  );
};
