import { useTranslation } from "../../../../hooks/useTranslation";
import cn from "clsx";
import { KycValidationElement } from "../KycValidationElement";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import React from "react";
import { FormattedMessage } from "react-intl";
import { toAbsoluteUrl } from "../../../../../_metronic/_helpers";
import SVG from "react-inlinesvg";

export type KycControlsBaseLayoutProps = {
  field?: any;
  inline?: boolean;
  content: any;
  label?: string;
  disableLabel?: boolean;
  disabled: boolean;
  isSectionChild?: boolean;
  translateLabel?: boolean;
  renderAsLabel?: boolean;
  className?: string;
  labelInfoLabel?: string;
};
export const KycFormElementBaseLayout = ({
  className = "",
  field,
  inline = true,
  content,
  label,
  disableLabel = false,
  disabled,
  isSectionChild = false,
  translateLabel = true,
  renderAsLabel = false,
  labelInfoLabel = "",
  ...props
}: KycControlsBaseLayoutProps) => {
  const { fm } = useTranslation();

  const labelInfoLabelElem = (
    <OverlayTrigger
      placement="top"
      overlay={
        <Tooltip id="layout-tooltip" className={"tooltip-auto-width"}>
          <FormattedMessage id={labelInfoLabel} />
        </Tooltip>
      }
    >
      <span className="svg-icon svg-icon-md svg-icon-info ml-2">
        <SVG src={toAbsoluteUrl("/media/svg/icons/Code/Info-circle.svg")} />
      </span>
    </OverlayTrigger>
  );

  return (
    <KycValidationElement field={field}>
      {renderAsLabel ? (
        <>
          {label && (
            <div className="row align-items-center py-1">
              <div className={cn("col-lg-4 col-md-4 col-sm-12", className)}>{fm(label)}:</div>
              <div className="col-lg-8 col-md-8 col-sm-12 p-lg-0">{content}</div>
            </div>
          )}
        </>
      ) : (
        <>
          {inline ? (
            <div className="d-flex flex-wrap flex-sm-wrap flex-md-wrap flex-lg-nowrap justify-content-center pb-1 align-items-center">
              {isSectionChild ? (
                <>
                  <div className="col-lg-6" />
                  <div className="col-lg-6">{content}</div>
                </>
              ) : (
                <>
                  {label && (
                    <label
                      className={cn(
                        className ? className : "text-lg-right",
                        label
                          ? "col-lg-6 col-form-label pt-0 pb-0 py-auto pl-lg-4 pr-lg-4 pl-sm-0 pr-sm-0 pb-sm-1"
                          : "col-lg-12",
                        disableLabel && "text-muted",
                        label === " " && "p-0"
                      )}
                    >
                      {translateLabel ? fm(label) : label}
                      {labelInfoLabel && labelInfoLabelElem}
                    </label>
                  )}
                  <div
                    className={cn(
                      label
                        ? "col-lg-6 col-md-12 pl-lg-4 pr-lg-4 pl-sm-0 pr-sm-0"
                        : "col-lg-12 pl-0 pr-0",
                      className
                    )}
                  >
                    {content}
                  </div>
                </>
              )}
            </div>
          ) : (
            <>
              {label && (
                <label className={cn("text-left", className)}>
                  {!disabled && translateLabel && fm(label)}
                  {renderAsLabel && ":"}
                </label>
              )}
              {content}
            </>
          )}
        </>
      )}
    </KycValidationElement>
  );
};
