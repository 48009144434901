import * as Yup from "yup";
import { IntlShape } from "react-intl";
import { BasicAddressFormValidationSchema } from "./BasicAddressFormValidationSchema";
import { getTranslation } from "./utilsValidation";

export const PersonalInfoFilloutFormValidationSchema = (intl: IntlShape) =>
  BasicAddressFormValidationSchema(intl).shape({
    firstName: Yup.string().required(getTranslation(intl, "VALIDATION.FIRST_NAME")),
    lastName: Yup.string().required(getTranslation(intl, "VALIDATION.LAST_NAME")),
  });
