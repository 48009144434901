/*
  How to use React Context effectively
  https://kentcdodds.com/blog/how-to-use-react-context-effectively
*/

import * as React from "react";
import { shallowEqual } from "react-redux";
import { useDidMount } from "rooks";

import { useAppDispatch, useAppSelector } from "redux/hooks";

import { ICompany } from "data/schemas";

import * as actions from "app/modules/PropertiesManagement/_redux/projects/projectsActions";

//----------------------------------------------------------------------------//

export interface IProjectOwnerSwitchContext {
  readonly?: boolean;

  isLoading: boolean;

  /** available companies that could be assigned as project owner */
  assignees: ICompany[];

  /** selected project owner */
  assignee?: ICompany;

  /** selected project owner name */
  assigneeName: string;

  assigneeDefaultVat?: number;
  assigneeReducedVat?: number;
  assigneeCountryCode?: string;

  /** selected project owner avatar image url */
  avatarPictureUrl?: string;

  /** trigger when user clicked on the “save change”-button inside of the select modal */
  setAssignee: (selectedAssignee?: ICompany) => void;

  /** display modal state */
  showSelectModal: boolean;
  /** change the display modal state */
  toogleDisplaySelectModal: (display?: boolean) => void;
}

export const ProjectOwnerSwitchContext = React.createContext<
  IProjectOwnerSwitchContext | undefined
>(undefined);

export interface ProjectOwnerSwitchProviderProps {
  readonly?: boolean;
}

/** centralize all the code logic in one place */
export const ProjectOwnerSwitchProvider: React.FunctionComponent<ProjectOwnerSwitchProviderProps> =
  ({ children, readonly = false }) => {
    const dispatch = useAppDispatch();

    const {
      isLoading,
      assignee,
      assigneeName,
      assigneeDefaultVat,
      assigneeReducedVat,
      assigneeCountryCode,
      avatarPictureUrl,
      assignees,
      currentProject,
    } = useAppSelector((state) => {
      const { projects } = state;
      const { actionsLoading, projectForEdit, projectOwnerAssignees = [] } = projects;

      const currentProject = projectForEdit?.current;

      const assignee = currentProject?.projectOwner;
      const assigneeName = assignee?.name || "-";
      const assigneeDefaultVat = assignee?.defaultVat;
      const assigneeReducedVat = assignee?.reducedVat;
      const assigneeCountryCode = assignee?.address?.countryCode;
      const avatarPictureUrl = assignee?.avatarPictureUrl;

      return {
        isLoading: actionsLoading,

        assignee,
        assigneeName,
        assigneeDefaultVat,
        assigneeReducedVat,
        assigneeCountryCode,
        avatarPictureUrl,
        assignees: projectOwnerAssignees,

        currentProject: currentProject || {},
      };
    }, shallowEqual);

    const triggerFetchProjectOwnerAssignees = () => actions.fetchProjectOwnerAssignees(dispatch);

    const [showSelectModal, setShowDisplaySelectModal] = React.useState(false);

    const toogleDisplaySelectModal = (display?: boolean) => {
      if (display === undefined) {
        setShowDisplaySelectModal((previousValue) => {
          const newValue = !previousValue;
          if (newValue) {
            triggerFetchProjectOwnerAssignees();
          }
          return newValue;
        });
        return;
      }

      if (display) {
        triggerFetchProjectOwnerAssignees();
      }

      setShowDisplaySelectModal(display);
    };

    const setAssignee = React.useCallback(
      (selectedAssignee?: ICompany) => {
        const projectToUpdate = {
          ...currentProject,
          projectOwnerId: selectedAssignee ? selectedAssignee.id : "",
          projectOwner: selectedAssignee,
        };

        actions
          .updateProject(projectToUpdate)(dispatch)
          .then(() => {
            setShowDisplaySelectModal(false);
          });
      },
      [currentProject]
    );

    //--------------------------------------------------------------------------//

    useDidMount(() => {
      if (!readonly) triggerFetchProjectOwnerAssignees();
    });

    //--------------------------------------------------------------------------//

    const contextValue: IProjectOwnerSwitchContext = {
      readonly: readonly || assignees.length <= 1,
      isLoading,

      assignees,
      assignee,
      assigneeName,
      assigneeDefaultVat,
      assigneeReducedVat,
      assigneeCountryCode,
      avatarPictureUrl,

      setAssignee,

      showSelectModal,
      toogleDisplaySelectModal,
    };

    return (
      <ProjectOwnerSwitchContext.Provider value={contextValue}>
        {children}
      </ProjectOwnerSwitchContext.Provider>
    );
  };

export const useProjectOwnerSwitchContext = () => {
  const context = React.useContext(ProjectOwnerSwitchContext);

  if (context === undefined) {
    throw new Error(
      "useProjectOwnerSwitchContext must be used within a ProjectOwnerSwitchProvider"
    );
  }

  return context;
};

export default ProjectOwnerSwitchProvider;
