import { FieldInputProps } from "formik";
import React, { FC } from "react";
import cn from "clsx";
import { KycFormElementBaseLayout } from "../KycControlsBaseLayout";

export interface KycTextAreaProps {
  field: any;
  inline: boolean;
  name: string;
  label?: string;
  customClasses?: string;
  text: string;
  disabled: boolean;
  checked?: boolean;
  onChange: any;
  children: any;
  translateLabel: boolean;
  disableLabel: boolean;
}
export const KycTextarea: FC<KycTextAreaProps & Partial<FieldInputProps<boolean>>> = ({
  field,
  inline = true,
  name,
  label,
  customClasses,
  text,
  disabled = false,
  children,
  checked,
  onChange,
  translateLabel = true,
  disableLabel = false,
  ...props
}) => {
  const content = (
    <div className={cn("row", customClasses, "mb-1")}>
      <textarea
        name={field.name}
        className="form-control mx-4 form-control-reduce-space"
        id="exampleFormControlTextarea1"
        rows={20}
        disabled={disabled}
        value={field?.value}
        onChange={onChange}
      />
    </div>
  );
  return (
    <>
      <KycFormElementBaseLayout
        field={field}
        content={content}
        disabled={disabled}
        disableLabel={disableLabel}
        label={label}
        inline={inline}
      />
    </>
  );
};
