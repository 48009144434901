import * as React from "react";
import { CardTable } from "../../../_components/CardTable/CardTable";
import { BudgetInstalmentsHeader } from "./components/BudgetInstalmentsHeader";
import { FormattedMessage, useIntl } from "react-intl";
import { EditableCardTableItem } from "../../../_components/CardTable/context/CardTableContext";
import { createColumnHelper } from "@tanstack/react-table";
import cn from "clsx";
import { BudgetInstalmentsCell } from "./components/BudgetInstalmentsCell";
import { useDebounce } from "rooks";
import { useFormikContext } from "formik";
import { IBudget } from "../../../../data/schemas";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import { accurateFloatOperation } from "../../../_utils/mathUtils";
import { exportCSV } from "../../../_utils/fileUtils";
import { cloneDeep, sumBy } from "lodash-es";
import { useAppSelector } from "../../../../redux/hooks";
import { shallowEqual } from "react-redux";
import { BudgetInstalmentActions } from "./components/BudgetInstalmentActions";
import { v4 as uuid } from "uuid";

export interface IInstalmentsColumns {
  label: string;
  instalment?: number;
  amountPreview: number;
}

export interface InstalmentsTableRow extends IInstalmentsColumns, EditableCardTableItem {
  rowType?: string;
}

const headers: Record<keyof IInstalmentsColumns, string> = {
  label: "COMMON.DESCRIPTION",
  instalment: "BUDGET.INSTALMENT.SINGLE",
  amountPreview: "BUDGET.AMOUNT_PREVIEW",
};

export interface BudgetInstalmentsProps {
  disabled: boolean;
  saveBudgetFields: (key: string | string[], value: any) => void;
}

export const BudgetInstalments: React.FC<BudgetInstalmentsProps> = ({
  disabled,
  saveBudgetFields,
}) => {
  const { values, handleChange } = useFormikContext<IBudget>();

  const intl = useIntl();

  const { products } = useAppSelector(
    (state) => ({
      products: state.projects.projectForEdit.current?.products,
    }),
    shallowEqual
  );

  const { totalPrice, expectedConstructionPrice, architectEngineeringFeesPrice, shareOfLandPrice } =
    React.useMemo(() => {
      const res = {
        totalPrice: 0,
        expectedConstructionPrice: 0,
        architectEngineeringFeesPrice: 0,
        shareOfLandPrice: 0,
      };
      for (const product of products ?? []) {
        const { price, architectEngineeringFees, shareOfLand } = product.financialDetails;
        res.totalPrice += price ?? 0;
        res.architectEngineeringFeesPrice += architectEngineeringFees ?? 0;
        res.shareOfLandPrice += shareOfLand ?? 0;
        res.expectedConstructionPrice +=
          (price ?? 0) - (architectEngineeringFees ?? 0) - (shareOfLand ?? 0);
      }
      return res;
    }, [products]);

  const customHandleChange = React.useCallback(
    useDebounce((event: any) => {
      handleChange(event);
      saveBudgetFields(event.target.name, event.target.value);
    }, 500),
    []
  );

  const addInstalment = (e: React.MouseEvent<HTMLButtonElement>) => {
    const resLines = cloneDeep(values.budgetInstalments);
    resLines.push({
      id: uuid(),
      label: "",
      instalment: 0,
      sequence: resLines.length,
    });
    saveBudgetFields("budgetInstalments", resLines);
  };

  const columnHelper = createColumnHelper<InstalmentsTableRow>();

  const tableData: InstalmentsTableRow[] | undefined = React.useMemo(() => {
    const totalInstalmentPercentage = sumBy(values.budgetInstalments, "instalment");
    return [
      {
        id: "TOTAL_INSTALMENT_ROW",
        label: intl.formatMessage({ id: "COMMON.TOTAL" }),
        instalment: totalInstalmentPercentage,
        amountPreview:
          totalInstalmentPercentage * expectedConstructionPrice +
          shareOfLandPrice +
          architectEngineeringFeesPrice,
        children: [
          {
            id: "SHARE_OF_LAND",
            label: intl.formatMessage({ id: "COMMON.EXPECTED.TOTAL.SHARE_OF_LAND" }),
            amountPreview: accurateFloatOperation(shareOfLandPrice, 2),
          },
          {
            id: "ARCHITECT_ENGINEERING_FEES",
            label: intl.formatMessage({ id: "COMMON.EXPECTED.TOTAL.ARCHITECT_ENGINEERING" }),
            amountPreview: accurateFloatOperation(architectEngineeringFeesPrice, 2),
          },
          ...values.budgetInstalments.map((instalment) => ({
            ...instalment,
            amountPreview: accurateFloatOperation(
              instalment.instalment * expectedConstructionPrice,
              2
            ),
          })),
        ],
        defaultExpanded: true,
      },
    ];
  }, [values.budgetInstalments, expectedConstructionPrice]);

  const columns = React.useMemo(
    () => [
      ...(disabled
        ? []
        : [
            columnHelper.display({
              id: "ACTIONS",
              cell: ({ row }) => (
                <>
                  {![
                    "TOTAL_INSTALMENT_ROW",
                    "SHARE_OF_LAND",
                    "ARCHITECT_ENGINEERING_FEES",
                  ].includes(row.id) && <BudgetInstalmentActions row={row} disabled={disabled} />}
                </>
              ),
              meta: {
                minWidth: 0,
                fixed: true,
                customWidth: "w-45px",
              },
            }),
          ]),
      ...Object.entries(headers).map(([key, value]) => {
        const typedKey = key as keyof IInstalmentsColumns;
        return columnHelper.accessor(typedKey, {
          header: () => (
            <div className={cn(typedKey !== "label" && "ml-auto text-right")}>
              <FormattedMessage id={value} />
            </div>
          ),
          cell: (props) => (
            <BudgetInstalmentsCell
              {...props}
              typedKey={typedKey}
              customHandleChange={customHandleChange}
              disabled={disabled}
            />
          ),
          meta: {
            customWidth:
              typedKey === "label" ? "flex-shrink-0 flex-grow-1" : "w-100px w-sm-150px w-md-200px",
            label: intl.formatMessage({ id: value }),
          },
          enableHiding: false,
        });
      }),
    ],
    [intl.locale]
  );

  const downloadCSV = () => {
    const headers = [
      intl.formatMessage({ id: "COMMON.DESCRIPTION" }),
      intl.formatMessage({ id: "BUDGET.INSTALMENT.SINGLE" }),
      intl.formatMessage({ id: "BUDGET.AMOUNT_PREVIEW" }),
    ];
    const firstRow = [
      intl.formatMessage({ id: "COMMON.EXPECTED.TOTAL.SHARE_OF_LAND" }),
      " ",
      accurateFloatOperation(shareOfLandPrice, 2),
    ];
    const secondRow = [
      intl.formatMessage({ id: "COMMON.EXPECTED.TOTAL.ARCHITECT_ENGINEERING" }),
      " ",
      accurateFloatOperation(architectEngineeringFeesPrice, 2),
    ];
    const content = [headers, firstRow, secondRow];

    values.budgetInstalments.forEach((data) => {
      const row = [
        '"' + data.label + '"', // Doing this to force the string content
        accurateFloatOperation(data.instalment * 100, 2) + "%",
        accurateFloatOperation(data.instalment * expectedConstructionPrice, 2),
      ];
      content.push(row);
    });
    exportCSV(values.projectName + "_" + values.name, content);
  };

  return (
    <>
      <BudgetInstalmentsHeader
        {...{
          totalPrice,
          expectedConstructionPrice,
          architectEngineeringFeesPrice,
          shareOfLandPrice,
        }}
      />
      <div>
        <CardTable
          data={tableData}
          columns={columns}
          multiCardLayout={false}
          id="BUDGET_INSTALMENTS"
          canToggleColumns={false}
          canCollapse={false}
          canExpand={false}
          customActions={
            <OverlayTrigger
              overlay={
                <Tooltip id="budgets-download-tooltip">
                  <FormattedMessage id="TOOLTIP.EXPORT_CSV" />
                </Tooltip>
              }
            >
              <button
                type="button"
                className="btn btn-icon btn-light-secondary"
                onClick={downloadCSV}
              >
                <span className="svg-icon svg-icon-md svg-icon-primary">
                  <SVG src={toAbsoluteUrl("/media/svg/icons/Files/Download.svg")} />
                </span>
              </button>
            </OverlayTrigger>
          }
        />
        {!disabled && (
          <button
            type="button"
            className="btn btn-sm btn-light rounded-0 w-100 mt-6"
            onClick={addInstalment}
          >
            <i className="ki ki-plus icon-nm" />
            <FormattedMessage id="BUDGET.INSTALMENT.ADD" />
          </button>
        )}
      </div>
    </>
  );
};
