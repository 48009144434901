import * as React from "react";
import { ContextType, useState } from "react";
import { ScrollMenu, VisibilityContext } from "react-horizontal-scrolling-menu";
import { LeftArrow, RightArrow } from "./Arrows";
import "./Arrows.scss";
import { Button } from "react-bootstrap";
import cn from "clsx";
import { useTranslation } from "../../../../../hooks/useTranslation";
import { useWizard } from "../../../../../hooks/useWizard";
import { formatDataCy } from "../../../KycUtils";
import { DataCyCompPrefix } from "../../../Kyc";
import { useKycFlow } from "../../../../../hooks/useKycFlow";
import { KycDialogConfig } from "../../KycConfirmationDialogContainer";
import { useKycConfirmation } from "../../../../../hooks/useKycConfirmation";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../../../_metronic/_helpers";
import { useFormikContext } from "formik";
import { get } from "lodash-es";

type ScrollVisibilityApiType = ContextType<typeof VisibilityContext>;

type KycHorizontalScrollMenuProps = {
  field: any;
  defaultTitle: string;
  activeIndex: number;
  setActiveIndex: any;
  disabled?: boolean;
  remove: any;
  items: any[];
};

export const KycHorizontalScrollMenu = ({
  field,
  defaultTitle,
  activeIndex,
  setActiveIndex,
  disabled,
  remove,
  items,
}: KycHorizontalScrollMenuProps): JSX.Element => {
  const { fm, fDisplayName } = useTranslation();
  const { isReadOnly } = useWizard();
  const [hovered, setHovered] = useState<number>(-1);
  const { isSubFieldLocked, values } = useKycFlow();
  const { setConfig, setShowModal } = useKycConfirmation();

  const handleActiveIndex = (e: React.MouseEvent<HTMLDivElement>, index: number) => {
    e.preventDefault();
    setActiveIndex(index);
  };

  const removeEntity = (remove: Function, index: number) => {
    remove(index);
    // setActiveIndex(index - 1 > 0 ? index - 1 : index);
  };

  const handleRemoveEntity = async (e: any, remove: Function, index: number, entity: any) => {
    e.preventDefault();
    if (index > -1) {
      const entityField = {
        name: `${field?.name}[${index}]`,
        value: undefined,
      };

      setConfig({
        type: KycDialogConfig.KYC_FLOW_DELETE_ENTITY,
        labelValues: { entityName: fDisplayName(entity, true) },
        func: removeEntity,
        funcParams: [remove, index],
        kycId: values?.id,
        e: e,
        entity: { ...entity, field: entityField },
      });
      setShowModal(true);
    }
  };
  const { errors } = useFormikContext();

  return (
    <>
      {items?.length > 0 && (
        <div className="mt-2 ml-4" style={{ paddingRight: 12 }}>
          <div>
            <ScrollMenu LeftArrow={LeftArrow} RightArrow={RightArrow} onWheel={onWheel}>
              {items?.map((entity: any, index: number) => (
                <div
                  key={`${field?.name}-header-scroll-item-${index}`}
                  role="button"
                  style={{
                    borderLeft: "white",
                    marginLeft: 4,
                    marginRight: 4,
                    display: "inline-block",
                    margin: "10px 10px",
                    minWidth: "160px",
                    userSelect: "none",
                  }}
                  tabIndex={0}
                  // className="card"
                >
                  <div
                    className={cn(
                      "mb-2",
                      "d-flex justify-content-between align-items-center",
                      "border border-top-white border-left-white border-right-white",
                      activeIndex === index ? "border-bottom-primary text-primary" : "border-light"
                    )}
                    onMouseEnter={() => setHovered(index)}
                    onMouseLeave={() => setHovered(-1)}
                  >
                    <div
                      style={{ width: "100%" }}
                      data-cy={formatDataCy(
                        DataCyCompPrefix.BUTTON + "-header-menu-item",
                        field?.name,
                        index
                      )}
                      className="w-100"
                      onClick={(e: React.MouseEvent<HTMLDivElement>) => handleActiveIndex(e, index)}
                    >
                      {get(errors, `${field?.name}[${index}]`) && (
                        <span className={cn("svg-icon svg-icon-md mr-1", "svg-icon-danger")}>
                          <SVG src={toAbsoluteUrl("/media/svg/icons/Code/Warning-1-circle.svg")} />
                        </span>
                      )}
                      {fDisplayName(
                        entity.name
                          ? { ...entity, firstName: "", company: { name: entity.name } }
                          : entity,
                        true
                      ) || `${fm(defaultTitle)} ${index + 1}`}
                    </div>
                    {!disabled && !isReadOnly && !isSubFieldLocked(`${field?.name}[${index}]`) && (
                      <Button
                        className={cn(
                          "ml-1",
                          hovered === index ? "visible" : "invisible",
                          "border-0 bg-transparent user-select-none p-1"
                        )}
                        onClick={(e: any) => handleRemoveEntity(e, remove, index, entity)}
                      >
                        <i className="fas fa-times text-danger" />
                      </Button>
                    )}
                  </div>
                </div>
              ))}
            </ScrollMenu>
          </div>
        </div>
      )}
    </>
  );
};

const onWheel = (apiObj: ScrollVisibilityApiType, ev: React.WheelEvent): void => {
  const isTouchpad = Math.abs(ev.deltaX) !== 0 || Math.abs(ev.deltaY) < 15;

  if (isTouchpad) {
    ev.stopPropagation();
    return;
  }

  if (ev.deltaY < 0) {
    apiObj.scrollNext();
  } else if (ev.deltaY > 0) {
    apiObj.scrollPrev();
  }
};
