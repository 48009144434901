import { Form, Formik } from "formik";
import { KycFlowV2Provider } from "../../../../contexts/KycFlowContext";
import { FORM_INIT } from "../../KycUtils";
import { useTranslation } from "../../../../hooks/useTranslation";
import { useSession } from "../../../../hooks/useSession";
import { KycFlow } from "../../Kyc";
import * as React from "react";
import { useIntl } from "react-intl";
import { kycFormSchema } from "../yup/KycYupForm";
import { useWizard } from "../../../../hooks/useWizard";
import * as Yup from "yup";

interface KycFormBaseProps {
  initValues?: KycFlow;
  children?: React.ReactNode;
}

const defaultSchema = Yup.object({});

export const KycFlowFormBase: React.FC<KycFormBaseProps> = ({ initValues, children }) => {
  const intl = useIntl();

  const { activeStep, steps } = useWizard();

  const [schema, setSchema] = React.useState({});

  React.useEffect(() => {
    setSchema(defaultSchema.concat(kycFormSchema(intl)[steps?.[activeStep]?.id]));
  }, [activeStep, steps]);

  const { fm, fDisplayName } = useTranslation();

  const { session } = useSession();

  return (
    <Formik
      validationSchema={schema}
      enableReinitialize={false}
      //TODO disabled, because field array & formik with large amount
      //causes input lag in (at least in dev)
      //to be reevaluated depending if and how validation needs to be applied
      validateOnBlur={true}
      validateOnChange={false}
      validateOnMount={false}
      initialValues={initValues ?? FORM_INIT(fDisplayName, fm, session)}
      onSubmit={(data) => {}}
    >
      <Form className="form form-label-right" style={{ width: "100%" }}>
        <KycFlowV2Provider>{children}</KycFlowV2Provider>
        {/*<PersistFormikValues name="kyc-v2-flow" />*/}
      </Form>
    </Formik>
  );
};
