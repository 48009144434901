// React bootstrap table next =>
// DOCS: https://react-bootstrap-table.github.io/react-bootstrap-table2/docs/
// STORYBOOK: https://react-bootstrap-table.github.io/react-bootstrap-table2/storybook/index.html
import React, { useEffect, useMemo, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, { PaginationProvider } from "react-bootstrap-table2-paginator";
import { shallowEqual, useDispatch } from "react-redux";
import * as actions from "../../../_redux/leads/leadsActions";
import * as uiHelpers from "../LeadsUIHelpers";
import { NoRecordsFoundMessage, sortCaret } from "../../../../../../_metronic/_helpers";
import {
  ActionsColumnFormatter,
  CreatedByColumnFormatter,
  DateTimeColumnFormatter,
  PhotoColumnFormatter,
  StatusColumnFormatter,
  UserListAccessFormatter,
} from "../../../../../_components/column-formatters";
import { Pagination } from "../../../../../../_metronic/_partials/controls";
import { useLeadsUIContext } from "../LeadsUIContext";
import { useIntl } from "react-intl";
import { useEntityDeleteDialogContext } from "../../entity-delete-dialog/EntityDeleteDialogContext";
import { searchUtils, selectField } from "../../../../../_utils/searchUtils";
import { canDelete, canReadAll } from "../../../../../_utils/authUtils";
import { formatCurrency } from "../../../../../_utils/mathUtils";
import { useAppSelector } from "../../../../../../redux/hooks";
import { selectedLeadStatus } from "../../../../../_utils/formUtils";
import { formatDisplayName } from "../../../../../_utils/userUtils";
import { cloneDeep } from "lodash-es";
import { sortDateFunc } from "../../../../../_utils/columnUtils";
import EmptyState from "app/_components/EmptyState/EmptyState";

export function LeadsTable() {
  const intl = useIntl();
  const [isLoading, setLoading] = useState(false);
  const [entitiesFiltered, setEntitiesFiltered] = useState([]);

  const leadsUIContext = useLeadsUIContext();

  const leadsUIProps = useMemo(() => {
    return {
      ids: leadsUIContext.ids,
      setIds: leadsUIContext.setIds,
      queryParams: leadsUIContext.queryParams,
      filters: leadsUIContext.filters,
      setFilters: leadsUIContext.setFilters,
      openLeadPage: leadsUIContext.openLeadPage,
      openDeleteLeadDialog: leadsUIContext.openDeleteLeadDialog,
      newLeadButtonClick: leadsUIContext.newLeadButtonClick,
      readOnly: leadsUIContext.readOnly,
      context: leadsUIContext.context,
      filterList: leadsUIContext.filterList,
    };
  }, [leadsUIContext]);

  const { currentState, session, groups } = useAppSelector(
    (state) => ({
      currentState: leadsUIContext.context ? state[leadsUIContext.context] : state.leads,
      session: state.auth.session,
      groups: state.auth.groups,
    }),
    shallowEqual
  );
  const { entities, listLoading } = currentState;

  const dispatch = useDispatch();
  useEffect(() => {
    // clear selections list
    leadsUIProps.setIds([]);
    // server call by queryParams
    const queryParamsToUse = { ...leadsUIProps.queryParams };
    if (groups?.length === 0 && session?.client?.id && !leadsUIProps?.queryParams?.clientId) {
      queryParamsToUse.clientId = session?.client?.id;
    }

    setLoading(true);
    dispatch(actions.fetchLeads(queryParamsToUse, leadsUIProps.context)).then(() =>
      setLoading(false)
    );
  }, [leadsUIProps.queryParams]);

  const { setDeleteEntityDialog } = useEntityDeleteDialogContext();
  const openDeleteLeadDialog = (id) => {
    setDeleteEntityDialog({
      action: { fn: actions.deleteLead, props: { context: leadsUIProps.context, id } },
      entityType: "LEAD",
    });
  };

  // Table columns
  const columns = [
    {
      dataField: "id",
      text: "",
      sort: false,
      formatter: PhotoColumnFormatter,
      formatExtraData: {
        icon: "fas fa-fire text-info icon-md",
      },
    },
    {
      dataField: "name",
      text: intl.formatMessage({
        id: "COMMON.NAME",
      }),
      headerClasses: "text-left text-nowrap",
      classes: "text-left",
      sort: true,
      sortCaret: sortCaret,
    },
    {
      dataField: "status",
      text: intl.formatMessage({
        id: "COMMON.STATUS",
      }),
      sort: true,
      headerClasses: "text-left text-nowrap",
      classes: "text-left",
      sortCaret: sortCaret,
      formatter: StatusColumnFormatter,
      formatExtraData: {
        intl,
      },
      sortValue: (cell) => selectedLeadStatus(cell ?? {}).priority,
    },
    {
      dataField: "sellingPrice",
      text: intl.formatMessage({
        id: "INVOICE.PRICE.EXCL_TAX",
      }),
      sort: true,
      headerClasses: "text-left text-nowrap",
      classes: "text-left",
      sortCaret: sortCaret,
      formatter: (cell, row) => formatCurrency(cell, 2, intl),
      hidden: !canReadAll(groups, session, "LEAD"),
    },
    {
      dataField: "createdAt",
      text: intl.formatMessage({
        id: "COMMON.CREATED.AT",
      }),
      sort: true,
      headerClasses: "text-left text-nowrap",
      classes: "text-left",
      sortCaret: sortCaret,
      formatter: DateTimeColumnFormatter,
      formatExtraData: { intl, showTime: false },
      sortFunc: sortDateFunc,
    },
    {
      dataField: "createdByUserId",
      text: intl.formatMessage({
        id: "COMMON.CREATED.BY",
      }),
      sort: true,
      headerClasses: "text-left text-nowrap",
      classes: "text-left",
      sortCaret: sortCaret,
      formatter: CreatedByColumnFormatter,
      sortValue: (cell) => (cell ? formatDisplayName(cell, false) : "-"),
    },
    {
      dataField: "productCity",
      text: intl.formatMessage({
        id: "ADDRESS.CITY",
      }),
    },
    {
      dataField: "clientsNames",
      text: intl.formatMessage({
        id: "CLIENT.TITLE",
      }),
      formatter: UserListAccessFormatter,
      headerClasses: "text-center",
      formatExtraData: { modalTitle: intl.formatMessage({ id: "CLIENT.TITLE" }) },
    },
    {
      dataField: "action",
      text: intl.formatMessage({
        id: "COMMON.ACTIONS",
      }),
      formatter: ActionsColumnFormatter,
      formatExtraData: {
        openDeleteDialog: openDeleteLeadDialog,
        actionList: ["TRASH"],
        canDelete: (row) => {
          return canDelete(groups, session, "LEAD");
        },
        entityType: "LEAD",
      },
      classes: "text-right",
      headerClasses: "text-right",
      style: {
        minWidth: "100px",
      },
      hidden: leadsUIProps.readOnly,
    },
  ];

  useEffect(() => {
    const entitiesClone = cloneDeep(entities).map((entity) => {
      const clientsNames = [];
      (entity?.users ?? []).forEach((user) => clientsNames.push(formatDisplayName(user)));
      entity.clientsNames = clientsNames.join(", ");
      return entity;
    });

    setEntitiesFiltered(
      searchUtils(
        leadsUIContext?.filters?.freeText,
        entitiesClone,
        selectField(leadsUIContext),
        intl
      )
    );
  }, [entities, leadsUIContext?.filters?.freeText, leadsUIContext?.filters?.filterSelected]);

  // Table pagination properties
  const paginationOptions = {
    custom: true,
    totalSize: entitiesFiltered.length,
    sizePerPageList: uiHelpers.sizePerPageList,
    sizePerPage: leadsUIProps.filters.pageSize,
    page: leadsUIProps.filters.pageNumber,
  };
  return (
    <div data-cy="table-list">
      <EmptyState
        entitiesLength={entities.length}
        entityUIContextAction={leadsUIProps.newLeadButtonClick}
        listLoading={listLoading}
        stateLocation={"LEAD"}
      >
        <PaginationProvider pagination={paginationFactory(paginationOptions)}>
          {({ paginationProps, paginationTableProps }) => {
            return (
              <Pagination
                isLoading={listLoading}
                paginationProps={{ ...paginationProps, intl: intl }}
              >
                <BootstrapTable
                  id="leads-table"
                  wrapperClasses="table-responsive"
                  classes="table table-head-custom table-vertical-center"
                  bootstrap4
                  bordered={false}
                  keyField="id"
                  data={isLoading ? [] : entitiesFiltered || []}
                  columns={columns}
                  defaultSorted={uiHelpers.defaultSorted}
                  noDataIndication={() =>
                    listLoading ? "" : <NoRecordsFoundMessage entities={entitiesFiltered} />
                  }
                  hover
                  rowEvents={{
                    onClick: (e, row, rowIndex) => {
                      if (
                        canReadAll(groups, session, "LEAD") ||
                        !!row.users.filter((user) => user.userId === session.id).length
                      ) {
                        leadsUIProps.openLeadPage(row);
                      }
                    },
                  }}
                  rowStyle={{ cursor: "pointer" }}
                  {...paginationTableProps}
                />
              </Pagination>
            );
          }}
        </PaginationProvider>
      </EmptyState>
    </div>
  );
}
