import { IntlShape } from "react-intl";
import * as Yup from "yup";
import { getTranslation } from "./utilsValidation";

export const BasicAddressFormValidationSchema = (intl: IntlShape) =>
  Yup.object().shape({
    address: Yup.object().shape({
      addressLine1: Yup.string().required(
        getTranslation(intl, "VALIDATION.ADDRESS.ADDRESS_LINE_1")
      ),
      // addressLine2: "",
      city: Yup.string().required(getTranslation(intl, "VALIDATION.ADDRESS.CITY")),
      // stateOrRegion: "",
      postalCode: Yup.string().required(getTranslation(intl, "VALIDATION.ADDRESS.POSTAL_CODE")),
      countryCode: Yup.string()
        .notOneOf(["EMPTY.STRING"], getTranslation(intl, "VALIDATION.ADDRESS.COUNTRY_CODE"))
        .required(getTranslation(intl, "VALIDATION.ADDRESS.COUNTRY_CODE")),
    }),
  });
