import * as requestFromServer from "./formsCrud";
import { callTypes, formsSlice } from "./formsSlice";
import { isEmpty } from "lodash-es";
import {
  processSnackbarNotification,
  SNACKBAR_MESSAGE,
} from "../../../Common/SnackbarNotificationsHandler";

const { actions } = formsSlice;
export const fetchAction = (userId, actionId) => (dispatch) => {
  if (!actionId) {
    return dispatch(actions.formFetched({ formForEdit: undefined }));
  }

  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .getActionById(userId, actionId)
    .then((response) => {
      if (isEmpty(response)) {
        dispatch(actions.actionNotFound({}));
      } else {
        const action = response;
        const formForEdit = { fields: response.content } || {};
        dispatch(actions.actionFetched({ action, formForEdit }));
      }
    })
    .catch((error) => {
      dispatch(actions.catchError({ error, callType: callTypes.action }));
      processSnackbarNotification(SNACKBAR_MESSAGE.DANGER.FIND_FORM, dispatch);
    });
};

export const updateFormFieldLocally = (key, value) => (dispatch) => {
  dispatch(actions.formFieldUpdatedLocally({ key, value }));
};

export const uploadForm = (form, userId, actionId) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .updateForm(form, userId, actionId)
    .then((response) => {
      // disabled because not required atm (UI shows confirmation message instead
      // processSnackbarNotification(SNACKBAR_MESSAGE.SUCCESS.UPDATE_FORM, dispatch);
      const formForEdit = response.action;

      if (response?.action?.content) {
        try {
          formForEdit.fields = formForEdit.content;
        } catch (e) {}
      }
      dispatch(actions.formUpdated({ action: response.action, formForEdit: formForEdit }));
    })
    .catch((error) => {
      console.log("error", error);
      dispatch(actions.catchError({ error, callType: callTypes.action }));
      processSnackbarNotification(SNACKBAR_MESSAGE.DANGER.UPDATE_FORM, dispatch);
    });
};
