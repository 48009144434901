/*
  https://react-select.com/components
  https://react-select.com/styles
*/

import type { CSSObjectWithLabel, StylesConfig } from "react-select";

/*
  https://github.com/gka/chroma.js
  https://vis4.net/chromajs/
*/
import chroma from "chroma-js";

import { ETaskStatus } from "data/schemas";

import { CustomSelectOption } from "../../CustomSelectBase";

//----------------------------------------------------------------------------//

const CSS_VAR_TEXT_COLOR = "--textColor";

const CSS_VAR_ICON_COLOR = "--iconOptionColor";

const CSS_VAR_DROPDOWN_ICON_COLOR = "--iconDropDownColor";
const CSS_VAR_DROPDOWN_ICON_HOVER_COLOR = "--iconDropDownHoverColor";

//----------------------------------------------------------------------------//

export const widthViewport = 50;

export const statusSelectStyles: StylesConfig = {
  input: (previousStyles) => ({
    ...previousStyles,
    height: "100%",
    opacity: 0,
    margin: 0,
    padding: 0,
  }),

  menuList: (previousStyles) => ({
    ...previousStyles,
    paddingTop: 0,
    paddingBottom: 0,
  }),

  option: (styles, { isDisabled, isFocused, isSelected, ...others }) => {
    const data = others.data as CustomSelectOption;
    const colorChroma = chroma(data.color!);
    const value = data.value;

    const baseStyles: CSSObjectWithLabel = {
      ...styles,
      display: "flex",
      gap: "0.5rem",
      alignItems: "center",
    };

    if (isDisabled) {
      return {
        ...baseStyles,
        cursor: "not-allowed",
        backgroundColor: undefined,
        textShadow: "none",
        color: "#ccc",
        [CSS_VAR_ICON_COLOR]: "#ccc",
      };
    }

    const isPlanned = value === ETaskStatus.PLANNED;
    const isInProgress = value === ETaskStatus.IN_PROGRESS;

    let color = colorChroma.darken(isPlanned ? 2 : 1).css();
    let backgroundColor: string | undefined = undefined;

    const newStyles: CSSObjectWithLabel = {
      ...baseStyles,
      cursor: "default",
      backgroundColor,
      textShadow: "none",
      color,
      [CSS_VAR_ICON_COLOR]: isPlanned ? colorChroma.darken(2).alpha(0.5).css() : data.color,
    };

    if (isSelected) {
      const shouldUseBrightFontColor = chroma.contrast(colorChroma, "white") > 2;
      color = shouldUseBrightFontColor
        ? colorChroma.brighten(4).css()
        : colorChroma.darken(3).css();
      const iconColor = shouldUseBrightFontColor
        ? colorChroma.brighten(2).css()
        : colorChroma
            .darken()
            .alpha(isInProgress ? 0.5 : 1)
            .css();
      const textShadowColor = shouldUseBrightFontColor
        ? colorChroma.brighten(4).alpha(0.6).css()
        : colorChroma.darken().alpha(0.2).css();

      backgroundColor = colorChroma.alpha(isPlanned ? 1 : isInProgress ? 0.5 : 0.8).css();

      return {
        ...newStyles,
        color,
        [CSS_VAR_ICON_COLOR]: iconColor,
        textShadow: `0 1px 1px ${textShadowColor}`,
        backgroundColor,
      };
    }

    if (isFocused) {
      backgroundColor = colorChroma.alpha(isPlanned ? 0.6 : 0.2).css();
      return {
        ...newStyles,
        color: colorChroma.darken(isPlanned ? 3 : 2).css(),
        backgroundColor,
      };
    }

    return newStyles;
  },
};

export const smallStatusStyles: StylesConfig = {
  singleValue: (defaultStyles) => ({
    ...defaultStyles,
    display: "flex",
    alignItems: "center",
    gap: "0.5rem",
  }),

  valueContainer: (defaultStyles, { isDisabled }) => ({
    ...defaultStyles,
    /* top | right | bottom | left */
    padding: `0 ${isDisabled ? "6px" : 0} 0 4px`,
  }),

  control: (previousStyles, { isFocused, isDisabled, getValue }) => {
    const controlValue = getValue()[0] as CustomSelectOption;

    const baseStyles: CSSObjectWithLabel = {
      ...previousStyles,

      minHeight: "32px",
      boxShadow: "none",
      borderRadius: ".42rem",
      outline: "none",

      cursor: isDisabled ? "not-allowed" : "pointer",
    };

    if (!controlValue) {
      if (isDisabled) {
        const color = "#ccc";
        return {
          ...baseStyles,
          textShadow: "none",
          color: color,
          backgroundColor: undefined,
          [CSS_VAR_ICON_COLOR]: color,
        };
      }

      return baseStyles;
    }

    const baseColorChroma = chroma(controlValue.color!);
    const { value } = controlValue;

    const isPlanned = value === ETaskStatus.PLANNED;

    const colorChroma = baseColorChroma.darken(isPlanned ? 3 : 1);
    const backgroundColorChroma = baseColorChroma.alpha(isPlanned ? 0.6 : 0.2);
    const iconColorChroma = isPlanned
      ? baseColorChroma.darken(2).alpha(0.6)
      : baseColorChroma.darken().alpha(0.6);

    const color = colorChroma.css();
    const backgroundColor = backgroundColorChroma.css();
    const iconColor = iconColorChroma.css();

    return {
      ...baseStyles,

      color,
      backgroundColor,
      [CSS_VAR_ICON_COLOR]: iconColor,
      [CSS_VAR_DROPDOWN_ICON_COLOR]: isFocused ? color : iconColor,
      [CSS_VAR_DROPDOWN_ICON_HOVER_COLOR]: color,
      [CSS_VAR_TEXT_COLOR]: color,

      ":hover": { border: `1px solid ${color}` },
      border: isFocused
        ? `1px solid ${color}`
        : isDisabled
        ? `1px solid ${backgroundColorChroma.darken().css()}`
        : `1px solid transparent`,
    };
  },

  indicatorsContainer: (previousStyles, { isDisabled }) => ({
    ...previousStyles,
    display: isDisabled ? "none" : "flex",
  }),

  indicatorSeparator: (previousStyles) => ({
    ...previousStyles,
    display: "none",
  }),

  dropdownIndicator: (previousStyles) => ({
    ...previousStyles,
    /* top | right | bottom | left */
    padding: "0 2px 2px 0",
    color: `var(${CSS_VAR_DROPDOWN_ICON_COLOR}, ${previousStyles.color})`,
    ":hover": {
      color: `var(${CSS_VAR_DROPDOWN_ICON_HOVER_COLOR}, ${
        (previousStyles as any)[":hover"].color
      })`,
    },
  }),

  menu: (previousStyles) => ({
    ...previousStyles,
    minWidth: `120px`,
  }),
};
