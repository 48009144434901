/*
  check if 2 given objects they're equals
  also with the possibility to ignore some attributes on the check
*/
import { isEqual } from "lodash-es";

import { removeObjectProperties, defaultIgnoredProps } from "./removeObjectProperties";

export const isEqualObjects = <T>(
  editedEntity?: T,
  originalEntity?: T,
  ignoredProps: string[] = []
): boolean => {
  if (editedEntity === undefined && originalEntity === undefined) return true;

  if (editedEntity && originalEntity === undefined) return false;

  const propsToIgnore = [...ignoredProps, ...defaultIgnoredProps];

  const filteredEditedEntity = removeObjectProperties(editedEntity, propsToIgnore);
  const filteredOriginalEntity = removeObjectProperties(originalEntity, propsToIgnore);

  return isEqual(filteredEditedEntity, filteredOriginalEntity);
};

export default isEqualObjects;
