import * as React from "react";
import { CurrencyMetricCard } from "../../../../../../../_components/MetricCard";

export interface BudgetInstalmentsHeaderProps {
  sellingPrice: number;
  constructionPrice: number;
  architectEngineeringFeesPrice: number;
  shareOfLandPrice: number;
}

export const LeadProvisionalPlanHeader: React.FC<BudgetInstalmentsHeaderProps> = ({
  sellingPrice,
  constructionPrice,
  architectEngineeringFeesPrice,
  shareOfLandPrice,
}) => {
  return (
    <div className={"row"}>
      <CurrencyMetricCard
        dataCy="input-lead-provisional-plan-selling-price"
        value={sellingPrice}
        label="COMMON.SELLING.PRICE"
        className={"gutter-b"}
      />
      <CurrencyMetricCard
        dataCy="input-lead-provisional-plan-construction-price"
        value={constructionPrice}
        label="PRODUCT.INFO.CONSTRUCTION_PRICE"
        className={"gutter-b"}
      />
      <CurrencyMetricCard
        dataCy="input-lead-provisional-plan-share-of-land"
        value={shareOfLandPrice}
        label="PRODUCT.INFO.SHARE_OF_LAND"
        className={"gutter-b"}
      />
      <CurrencyMetricCard
        dataCy="input-lead-provisional-plan-architect-engineering-fees"
        value={architectEngineeringFeesPrice}
        label="PRODUCT.INFO.ARCHITECT_ENGINEERING_FEES"
        className={"gutter-b"}
      />
    </div>
  );
};
