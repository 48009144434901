import * as React from "react";
import { ModalProps, Modal } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { useDidUpdate, useTimeoutWhen } from "rooks";
import cn from "clsx";

import "./ValidateIdentityModal.scss";

//----------------------------------------------------------------------------//

const TIMEOUT_DELAY_2MIN = 1000 * 60 * 2;

//----------------------------------------------------------------------------//

export interface ValidateIdentityModalProps extends ModalProps {
  triggerModalHide: (reloadDocInfo?: boolean) => void;
  requestCode: () => Promise<void>;
  requestDocSign: (code: string) => Promise<any>;
}

export const ValidateIdentityModal: React.FunctionComponent<ValidateIdentityModalProps> = ({
  triggerModalHide,
  requestCode,
  requestDocSign,

  className,

  ...props
}) => {
  const [code, setCode] = React.useState("");

  const [isLoading, setLoading] = React.useState(false);
  const [isInvalid, setInvalid] = React.useState(false);
  const [isTimeoutRunning, setTimeoutRunning] = React.useState(false);

  const resetUI = () => {
    setLoading(false);
    setInvalid(false);
    setCode("");
  };

  const requestOtpCodeHandler = React.useCallback(() => {
    if (isTimeoutRunning) return;

    setLoading(true);
    setTimeoutRunning(true);

    requestCode()
      .catch((reason) => {
        console.warn(reason.response.data);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [isTimeoutRunning]);

  const cancelClickHandler = () => {
    resetUI();
    triggerModalHide();
  };

  const signClickHandler = (code: string) => () => {
    setInvalid(false);
    setLoading(true);

    requestDocSign(code)
      .then((response) => {
        console.info(response);

        triggerModalHide(true);
      })
      .catch((error) => {
        const { status, data } = error.response;

        if (status === 403) {
          setInvalid(true);

          console.error(`${status} - ${data.message}`);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  //--------------------------------------------------------------------------//

  useDidUpdate(() => {
    if (!props.show) return;

    resetUI();

    requestOtpCodeHandler();
  }, [props.show]);

  // https://rooks.vercel.app/docs/useTimeoutWhen
  useTimeoutWhen(() => setTimeoutRunning(false), TIMEOUT_DELAY_2MIN, isTimeoutRunning);

  //--------------------------------------------------------------------------//

  return (
    <Modal
      {...props}
      className={cn("validate-identity-modal", className)}
      size={"lg"}
      aria-labelledby="validate-identity"
      centered
    >
      <Modal.Header>
        <Modal.Title>
          <FormattedMessage id="SIGN.VALIDATE_IDENTITY.TITLE" />
        </Modal.Title>
      </Modal.Header>

      <Modal.Body className="f-flex flex-column">
        <div>
          <FormattedMessage id="SIGN.VALIDATE_IDENTITY" />
        </div>

        <div className="mt-6">
          <label>
            <FormattedMessage id="SIGN.CODE" />

            {isInvalid && (
              <span className="text-danger font-italic">
                <FormattedMessage id="AUTH.VALIDATION.INVALID" values={{ name: "" }} />
              </span>
            )}
          </label>

          <input
            data-cy="input-signature-otp"
            type="text"
            className="form-control"
            value={code}
            onChange={(e) => setCode(e.target.value.toUpperCase())}
          />
        </div>

        <div className="d-flex justify-content-between align-items-center mt-6">
          <span className="font-weight-bold">
            <FormattedMessage id="SIGN.RESEND_CODE_LABEL" />
          </span>

          <button
            type="button"
            className="btn btn-outline-primary"
            onClick={requestOtpCodeHandler}
            disabled={isTimeoutRunning || isLoading}
          >
            <FormattedMessage id="SIGN.RESEND_CODE" />
          </button>
        </div>
      </Modal.Body>

      <Modal.Footer className="text-right">
        <button
          type="button"
          className="btn btn-outline-secondary mr-4"
          onClick={cancelClickHandler}
        >
          <FormattedMessage id="COMMON.ACTION.CANCEL" />
        </button>

        <button
          data-cy="button-signature-confirm"
          type="button"
          onClick={signClickHandler(code)}
          disabled={isLoading || code.length < 2}
          className={cn("btn btn-primary btn-elevate btn-confirm", {
            "spinner spinner-white spinner-right": isLoading,
          })}
        >
          <FormattedMessage id="COMMON.ACTION.CONFIRM" />
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default ValidateIdentityModal;
