export const TimeUnitTypes = {
  SECOND: { label: "Seconds", value: 1000 },
  MINUTE: { label: "Minutes", value: 60 * 1000 },
  HOUR: { label: "Hours", value: 60 * 60 * 1000 },
  DAY: { label: "Days", value: 24 * 60 * 60 * 1000 },
  MONTH: { label: "Months", value: 30 * 24 * 60 * 60 * 1000 },
  YEAR: { label: "Years", value: 365 * 60 * 60 * 1000 },
};

export const TimeUnit = {
  SECOND: "SECOND",
  MINUTE: "MINUTE",
  HOUR: "HOUR",
  DAY: "DAY",
  MONTH: "MONTH",
  YEAR: "YEAR",
};
