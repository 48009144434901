import * as React from "react";
import { DropdownItem } from "../../../../../actions-menu/DropdownItem";
import { Row } from "@tanstack/react-table";
import { DeletePopover } from "../../../../../CustomCard";
import { useCardTableDropdownActionsContext } from "../context/CardTableDropdownActionsContext";
import { EditableCardTableItem } from "../../../../context/CardTableContext";
import { cloneDeep } from "lodash-es";

export interface CardTableDropdownActionsDeleteProps<T> {
  row: Row<T>;
  arrayToUpdate: any[];
  removeCallback: (array: any[]) => void;
  confirmDelete?: boolean;
  deleteTitle?: string;
}

export const CardTableDropdownActionsDelete = <T extends EditableCardTableItem>({
  row,
  arrayToUpdate,
  removeCallback,
  deleteTitle,
  confirmDelete,
}: CardTableDropdownActionsDeleteProps<T>) => {
  const { buttonRef } = useCardTableDropdownActionsContext();
  const [showRemove, setShowRemove] = React.useState(false);

  const handleClickDelete = () => {
    if (confirmDelete) {
      setShowRemove(true);
    } else {
      removeItem();
    }
  };
  const removeItem = () => {
    const updatedArr = cloneDeep(arrayToUpdate);
    updatedArr.splice(row.original.sequence!, 1);
    // update sequence of modified elements
    for (let i = row.original.sequence!; i < updatedArr.length; i++) {
      updatedArr[i].sequence = i;
    }

    removeCallback(updatedArr);
  };

  return (
    <>
      {!!confirmDelete && !!deleteTitle && buttonRef && (
        <DeletePopover
          showRemove={showRemove}
          setShowRemove={setShowRemove}
          targetRemove={buttonRef}
          remove={removeItem}
          title={deleteTitle}
        />
      )}
      <DropdownItem
        onClick={handleClickDelete}
        icon={"/media/svg/icons/General/Trash.svg"}
        title={"COMMON.DELETE"}
        classes={{ container: "text-danger", svg: "svg-icon-danger" }}
      />
    </>
  );
};
