import * as React from "react";
import { Row } from "@tanstack/react-table";
import { EditableCardTableItem } from "../../../../context/CardTableContext";
import { DropdownItem } from "../../../../../actions-menu/DropdownItem";
import { cloneDeep } from "lodash-es";

export interface CardTableDropdownActionsMoveProps<T> {
  row: Row<T>;
  arrayToUpdate: any[];
  moveCallback: (updatedArr: any[]) => void;
}

export const CardTableDropdownActionsMove = <T extends EditableCardTableItem>({
  row,
  arrayToUpdate,
  moveCallback,
}: CardTableDropdownActionsMoveProps<T>) => {
  const moveItem = (fromIndex: number, toIndex: number) => {
    const updatedArr = cloneDeep(arrayToUpdate);

    const movedElement = updatedArr[fromIndex];
    updatedArr.splice(fromIndex, 1);
    updatedArr.splice(toIndex, 0, movedElement);

    // update sequence of modified elements
    const start = Math.min(fromIndex, toIndex);
    const end = Math.max(fromIndex, toIndex);
    for (let i = start; i <= end; i++) {
      updatedArr[i].sequence = i;
    }

    moveCallback(updatedArr);
  };

  return (
    <>
      <DropdownItem
        onClick={() => moveItem(row.original.sequence!, 0)}
        icon={"/media/svg/icons/Navigation/Arrow-to-up.svg"}
        title={"BUDGET.TABLE.ACTIONS.MOVE_ON_TOP"}
        disabled={row.original.sequence === 0}
      />
      <DropdownItem
        onClick={() => moveItem(row.original.sequence!, row.original.sequence! - 1)}
        icon={"/media/svg/icons/Navigation/Arrow-up.svg"}
        title={"BUDGET.TABLE.ACTIONS.MOVE_ONE_POSITION_UP"}
        disabled={row.original.sequence === 0}
      />
      <DropdownItem
        onClick={() => moveItem(row.original.sequence!, row.original.sequence! + 1)}
        icon={"/media/svg/icons/Navigation/Arrow-down.svg"}
        title={"BUDGET.TABLE.ACTIONS.MOVE_ONE_POSITION_DOWN"}
        disabled={row.original.sequence! === arrayToUpdate.length - 1}
      />
      <DropdownItem
        onClick={() => moveItem(row.original.sequence!, arrayToUpdate.length - 1)}
        icon={"/media/svg/icons/Navigation/Arrow-to-bottom.svg"}
        title={"BUDGET.TABLE.ACTIONS.MOVE_TO_BOTTOM"}
        disabled={row.original.sequence! === arrayToUpdate.length - 1}
      />
    </>
  );
};
