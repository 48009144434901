import {toAbsoluteUrl} from "../../_helpers";
import {customTheme} from "./_customTheme";

export function getInitLayoutConfig() {
  return {
    demo: "demo2",
    // Javascript Settings
    js: {
      breakpoints: {
        sm: 576,
        md: 768,
        lg: 992,
        xl: 1200,
        xxl: 1200,
      },
      colors: {
        theme: {
          base: {
            white: "#ffffff",
            primary: "#cea87b",
            secondary: "#E5EAEE",
            success: "#50ab9f",
            info: "#6e6db2",
            warning: "#FFA800",
            danger: "#e8534f",
            light: "#F5F5F5",
            dark: "#1B1B1B",
          },
          light: {
            white: "#ffffff",
            primary: "#f7f5f0",
            secondary: "#ECF0F3",
            success: "#F1F9F7",
            info: "#EEE5FF",
            warning: "#FFF4DE",
            danger: "#F9DDDC",
            light: "#F5F5F5",
            dark: "#D6D6D6",
          },
          inverse: {
            white: "#ffffff",
            primary: "#ffffff",
            secondary: "#1B1B1B",
            success: "#ffffff",
            info: "#ffffff",
            warning: "#ffffff",
            danger: "#ffffff",
            light: "#464E5F",
            dark: "#ffffff",
          },
        },
        gray: {
          gray100: "#F5F5F5",
          gray200: "#F5F5F5",
          gray300: "#E0E0E0",
          gray400: "#D6D6D6",
          gray500: "#B8B8B8",
          gray600: "#7A7A7A",
          gray700: "#5C5C5C",
          gray800: "#333333",
          gray900: "#1B1B1B",
        },
      },
      ...customTheme,
      fontFamily: "Poppins",
    },
    // Base Settins
    self: {
      layout: "default", // blank/default page mode
      body: {
        backgroundImage: "header_desktop.png",
      },
    },
    // == Page Splash Screen loading
    loader: {
      enabled: true,
      type: "", // default|spinner-message|spinner-logo
      logo: toAbsoluteUrl("/media/logos/logo-dark-sm.png"),
      message: "Please wait...",
    },
    header: {
      self: {
        width: "fixed", // fixed|fluid
        fixed: {
          desktop: true,
          mobile: false,
        },
      },
      menu: {
        self: {
          display: true,
          layout: "default", // tab/default
          "root-arrow": false,
          "icon-style": "duotone", // duotone, line, bold, solid
        },
        desktop: {
          arrow: true,
          toggle: "click",
          submenu: {
            theme: "light", // light|dark
            arrow: true,
          },
        },
        mobile: {
          submenu: {
            theme: "dark",
            accordion: true,
          },
        },
      },
    },
    subheader: {
      display: true,
      displayDesc: false,
      displayDaterangepicker: true,
      breadcrumb: {
        display: true,
      },
      layout: "subheader-v6",
      fixed: false,
      width: "fixed", // fixed/fluid,
      clear: false,
      style: "transparent", // solid/transparent
    },
    content: {
      width: "fixed", // fluid|fixed
    },
    aside: {
      self: {
        display: false,
      },
      menu: {
        dropdown: false, // ok
        scroll: false, // ok
        "icon-style": "duotone", // duotone, line, bold, solid
        submenu: {
          accordion: true,
          dropdown: {
            arrow: true,
            "hover-timeout": 500, // in milliseconds
          },
        },
      },
    },
    footer: {
      width: "fixed", // fixed|fluid
      layout: "compact", // compact|extended
    },
    extras: {
      search: {
        display: false,
        layout: "dropdown", // offcanvas, dropdown
        offcanvas: {
          direction: "right",
        },
      },
      messageDestinationTypes: {
        display: true,
        layout: "dropdown", // offcanvas, dropdown
        dropdown: {
          style: "dark", // light, dark
        },
        offcanvas: {
          direction: "right",
        },
      },
      "quick-actions": {
        display: false,
        layout: "dropdown", // offcanvas, dropdown,
        dropdown: {
          style: "dark", // light, dark
        },
        offcanvas: {
          direction: "right",
        },
      },
      cart: {
        display: false,
        layout: "dropdown", // offcanvas, dropdown
        offcanvas: {
          direction: "dark", // ligth, dark
        },
      },
      chat: {
        display: false,
      },
      user: {
        display: true,
        layout: "dropdown", // offcanvas, dropdown
        dropdown: {
          style: "light", // light|dark
        },
        offcanvas: {
          direction: "right",
        },
      },
      languages: {
        display: true,
      },
      "quick-panel": {
        display: false,
        offcanvas: {
          direction: "right",
        },
      },

      toolbar: {
        display: true,
      },
      scrolltop: {
        display: true,
      },
    },
  };
}
