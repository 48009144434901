import type { IImmotoolEntity } from "./ImmotoolEntity";

import type { IFileSignature } from "./signatures/FileSignature";

import type { TUserTypes } from "./User";

//----------------------------------------------------------------------------//

export const FileStatus = {
  REQUESTED: "REQUESTED",
  UPLOADED: "UPLOADED",
  UPLOADING: "UPLOADING",
  REQUESTED_BY_CLIENT: "REQUESTED_BY_CLIENT",
  UPLOADED_TO_S3: "UPLOADED_TO_S3",
} as const;

export type TFileStatus = keyof typeof FileStatus;

//----------------------------------------------------------------------------//

export interface IRelatedEntity {
  id: string;
  type: string;
  name: string;

  userType?: TUserTypes;
}

export interface ILinkedFile {
  id: string;
  friendlyName: string;
  relatedEntity: IRelatedEntity;
}

export interface IFile extends IImmotoolEntity {
  uploadedAt: string;
  uploadedByUserId: string;
  uploadedByUser: any;
  friendlyName: string;
  fileType: string;
  isConfidential: boolean;
  fileExtension: string;
  mimeType: string;
  taskId: string;
  status: TFileStatus;

  previousSignaturesRequestId?: string;
  currentSignaturesRequestId?: string;
  signatures: IFileSignature[];

  sealedAt?: string;
  sealedBySignaturesRequestId?: string;

  relatedEntity: IRelatedEntity;
  linkedFiles: ILinkedFile[];

  url?: string;
  name?: string;
  lang?: string;
}
