import * as React from "react";
import { shallowEqual, useSelector } from "react-redux";

import { Draggable } from "@react-forked/dnd";

import { ITask } from "data/schemas";

import { canEdit } from "app/_utils/authUtils";

import "./TaskItem.scss";
import TaskItemContent from "./TaskItemContent";

export interface TaskItemProps {
  item: ITask;
  itemIndex?: number;
  disableDrop?: boolean;
  style?: any;
}

export const TaskItem: React.FunctionComponent<TaskItemProps> = ({
  item,
  itemIndex = 0,
  disableDrop = false,
  style: styleProps,
}) => {
  const placeholderDivRef = React.useRef<HTMLDivElement>(null);

  const { session, groups } = useSelector(
    (state: any) => ({
      session: state.auth.session,
      groups: state.auth.groups,
    }),
    shallowEqual
  );

  const { itemId, placeholderClassName } = React.useMemo(() => {
    const itemId = item.id as string;
    return {
      itemId,
      placeholderClassName: `placeholder__task-item__${itemId}`,
    };
  }, [item]);

  return (
    <div ref={placeholderDivRef} className={placeholderClassName} style={styleProps}>
      <Draggable
        draggableId={itemId}
        index={itemIndex}
        isDragDisabled={!canEdit(groups, session, "TASK")}
      >
        {(provided, snapshot) => (
          <TaskItemContent
            item={item}
            style={{}}
            snapshot={snapshot}
            provided={provided}
            disableDrop={disableDrop}
            key={itemId}
          />
        )}
      </Draggable>
    </div>
  );
};

export default TaskItem;
