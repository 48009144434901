import { KycFieldLock } from "../KycFieldLock";
import Select from "react-select";
import * as React from "react";
import { useEffect, useMemo, useState } from "react";
import { KycChoiceOption, KycFile } from "../../../Kyc";
import { findAndReplaceFile } from "../../../KycFileUtils";
import { useHtmlClassService } from "../../../../../../_metronic/layout";
import objectPath from "object-path";
import { isEmpty } from "lodash-es";
import { useTranslation } from "../../../../../hooks/useTranslation";
import { useKycFile } from "../../../../../hooks/useKycFile";
import { useWizard } from "../../../../../hooks/useWizard";

interface KycUploadDropdownProps {
  file: KycFile;
  options: KycChoiceOption[];
  setIsDropdownRequired: Function;
  disabled?: boolean;
}

export const KycUploadDropdown = ({
  options,
  setIsDropdownRequired,
  disabled,
  file,
}: KycUploadDropdownProps): JSX.Element => {
  const { fmo } = useTranslation();
  const { currentFiles, setCurrentFiles, updateFile } = useKycFile();
  const { isReadOnly } = useWizard();

  const [preprocessedOptions, setPreprocessedOptions] = useState<KycChoiceOption[]>([]);

  useEffect(() => {
    if (isEmpty(options)) {
      setIsDropdownRequired(false);
    } else {
      const pOptions = fmo(options);
      setPreprocessedOptions(pOptions);
      setIsDropdownRequired(true);
    }
  }, [options]);

  const uiService = useHtmlClassService();

  const layoutProps = useMemo(() => {
    return {
      colorsThemeLightPrimary: objectPath.get(uiService.config, "js.colors.theme.light.primary"),
      colorsThemeBasePrimary: objectPath.get(uiService.config, "js.colors.theme.base.primary"),
      colorsGrayGray400: objectPath.get(uiService.config, "js.colors.gray.gray400"),
    };
  }, [uiService]);

  const handleSelect = (optionSelected: KycChoiceOption, file: any) => {
    file.option = optionSelected?.value;
    setCurrentFiles(findAndReplaceFile(currentFiles, file));
    updateFile(file);
  };

  return (
    <div className={"w-100"}>
      <KycFieldLock
        field={{
          name: file?.fieldPath,
          value: preprocessedOptions.find((option) => option?.value === file?.option)?.label,
        }}
        disabled={disabled}
      >
        <Select
          menuPortalTarget={document.body}
          key={"upload-file-dropdown-" + file?.id}
          isClearable={false}
          theme={(theme) => ({
            ...theme,
            colors: {
              ...theme.colors,
              primary25: layoutProps.colorsThemeLightPrimary,
              primary: layoutProps.colorsThemeBasePrimary,
            },
          })}
          placeholder=""
          hideSelectedOptions={false}
          isDisabled={disabled || isReadOnly}
          value={preprocessedOptions.find((op) => op?.value === file?.option)}
          options={preprocessedOptions}
          classNamePrefix="kyc-select"
          onChange={(op: any) => handleSelect(op, file)}
        />
      </KycFieldLock>
    </div>
  );
};
