import { API } from "@aws-amplify/api";

export const getMe = async () => {
  try {
    const user = await API.get("API", "/users/me", {});

    return {
      ...user,
      useNewDesignSystem:
        process.env.REACT_APP_NEW_DESIGN_SYSTEM_ENABLED === "true" && user.useNewDesignSystem,
    };
  } catch (e) {
    return undefined;
  }
};
