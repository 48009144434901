import React, { createContext, useContext } from "react";

const ProfileUIContext = createContext();

export function useProfileUIContext() {
  return useContext(ProfileUIContext);
}

export const ProfileUIConsumer = ProfileUIContext.Consumer;

export function ProfileUIProvider({ profileUIEvents, children, pathId }) {
  const value = {
    pathId,
    backToUsersList: profileUIEvents.backToUsersList,
  };

  return <ProfileUIContext.Provider value={value}>{children}</ProfileUIContext.Provider>;
}
