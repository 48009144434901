import React from "react";
import { ModalProgressBar } from "../../../../../../../_metronic/_partials/controls";
import { useDispatch } from "react-redux";
import { Modal } from "react-bootstrap";
import * as actions from "../../../../_redux/usersActions";
import { FormattedMessage } from "react-intl";

export function RemoveUserAccessModal({
  user,
  actionsLoading,
  showRemoveUserAccessModal,
  setShowRemoveUserAccessModal,
}) {
  const dispatch = useDispatch();

  const removeUserAccess = () => {
    dispatch(actions.removeUserAccess(user.id));
    setShowRemoveUserAccessModal(false);
  };

  return (
    <Modal
      show={showRemoveUserAccessModal}
      onHide={() => setShowRemoveUserAccessModal(false)}
      aria-labelledby="user-credentials-modal"
      centered
      backdrop="static"
    >
      {actionsLoading && <ModalProgressBar variant="query" />}
      <Modal.Header closeButton>
        <Modal.Title id="user-credentials-modal-title">
          <FormattedMessage id="USER.TITLE.ACCESS" />
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {!actionsLoading && !user?.isDisabled && (
          <>
            <span>
              <FormattedMessage id="USER.ACTION.ACCESS.REMOVE.ARE_YOU_SURE" />
            </span>
            <div>
              <i>
                <small className="text-primary">
                  <FormattedMessage id="COMMON.ACTION.ACCESS.CANNOT_BE_UNDONE" />
                </small>
              </i>
            </div>
          </>
        )}
        {actionsLoading && (
          <span>
            <FormattedMessage id="COMMON.ACTION.REMOVING" />
          </span>
        )}
      </Modal.Body>
      <Modal.Footer>
        <div>
          <button
            type="button"
            onClick={() => setShowRemoveUserAccessModal(false)}
            className="btn btn-light btn-elevate"
          >
            <FormattedMessage id="COMMON.ACTION.CANCEL" />
          </button>
          <> </>
          <button
            type="button"
            onClick={removeUserAccess}
            className="btn btn-primary font-weight-bold"
            data-cy="button-user-remove-access-confirm"
            disabled={actionsLoading}
          >
            <FormattedMessage id="USER.ACTION.ACCESS.REMOVE" />{" "}
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
}
