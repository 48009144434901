import * as React from "react";
import { CardTableCollapseIndicator } from "../../../../../_components/CardTable/components/buttons/CardTableCollapseIndicator";
import { Field } from "formik";
import { Input } from "../../../../../../_metronic/_partials/controls";
import { Row } from "@tanstack/react-table";
import { BudgetTableRow } from "../../BudgetEditExpenses";
import { BudgetLineActions } from "../BudgetLineActions/BudgetLineActions";
import { IBudgetSortedCategory } from "../../../../../../data/schemas";
import { useAppSelector } from "../../../../../../redux/hooks";
import { DebouncedFunc } from "lodash-es";

export interface BudgetLabelColumnFormatterProps {
  row: Row<BudgetTableRow>;
  value: any;
  disabled: boolean;
  setValue: React.Dispatch<React.SetStateAction<string>>;
  customHandleChange: DebouncedFunc<(event: any) => void>;
}

export const BudgetLabel = ({
  row,
  value,
  disabled,
  setValue,
  customHandleChange,
}: BudgetLabelColumnFormatterProps) => {
  const { budget } = useAppSelector((state) => ({ budget: state.budgets.budgetForEdit.current }));

  const { sequence, categoryId, rowType } = row.original;

  let relatedBudgetCategory: IBudgetSortedCategory | undefined;
  if (categoryId) {
    relatedBudgetCategory = budget?.sortedLines?.find((cat) => cat.id === categoryId);
  }
  let fieldName: string | undefined = undefined;
  if (rowType === "BUDGET_CATEGORY") {
    fieldName = `sortedLines.${sequence}.label`;
  } else if (rowType === "BUDGET_LINE") {
    fieldName = `sortedLines.${relatedBudgetCategory?.sequence}.lines.${sequence}.label`;
  }

  const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value);
    customHandleChange(event);
  };

  return (
    <div
      className="d-flex align-items-center flex-grow-1"
      style={{
        paddingLeft: `${row.depth > 0 ? row.depth * 2 : 0}rem`,
      }}
    >
      <>
        {row.id !== "BUDGET_EXPENSE_TOTAL" && row.getCanExpand() ? (
          <CardTableCollapseIndicator row={row} />
        ) : (
          <div className="btn btn-icon btn-link-primary btn-xs flex-shrink-0"></div>
        )}

        {!["BUDGET_EXPENSE_TOTAL", "unspecifiedCategory"].includes(row.id) && (
          <BudgetLineActions
            row={row}
            relatedBudgetCategory={relatedBudgetCategory}
            disabled={disabled}
          />
        )}
        {!!fieldName ? (
          <Field
            component={Input}
            withFeedbackLabel={false}
            name={fieldName}
            value={value}
            onChange={handleNameChange}
            disabled={disabled}
            onClick={(e: React.MouseEvent<HTMLInputElement>) => e.stopPropagation()}
            style={{ height: "auto" }}
          />
        ) : (
          value
        )}
      </>
    </div>
  );
};
