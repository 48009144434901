import * as React from "react";
import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useFormikContext } from "formik";
import { cloneDeep, get, isEmpty, set } from "lodash-es";
import { useWizard } from "../hooks/useWizard";
import { KycFlow, KycFlowStatus, KycLoadingParams } from "../modules/kyc-v2/Kyc";
import {
  getFlowDefinition,
  isKycFlowInTerminalState,
  statusAsArray,
} from "../modules/kyc-v2/KycUtils";
import { v4 as uuid } from "uuid";
import { useKyc } from "../hooks/useKyc";
import { useSession } from "../hooks/useSession";
import { diff } from "deep-object-diff";
import { useKycFile } from "../hooks/useKycFile";
import { useAppSelector } from "../../redux/hooks";
import { useDebounce } from "rooks";

interface KycFlowV2Props {
  children: React.ReactNode;
}

interface SaveFieldOptions {
  preventDefault?: boolean;
  loading?: KycLoadingParams;
  field?: { name: string; value: any };
  isDateValue?: boolean;
  kycId?: string;
}

export interface KycFlowV2ContextValue {
  customHandleChange: (
    e: { target: { name: string; value: any } },
    doUpdate?: boolean,
    incremental?: boolean
  ) => void;
  saveField: (e: any, options?: SaveFieldOptions) => void;
  deleteEntityField: (e: any, entity: any) => void;

  saveFieldDelayed: (e: any) => void;

  flow?: KycFlow;

  resetFlowValues: () => void;

  values: any;
  setFlowValues: (values: any, doUpdate?: boolean) => void;

  isFieldLocked: (field: any) => boolean;
  isSubFieldLocked: (fieldName: string) => boolean;

  setFlowId: (id: string) => void;

  setFlowValue: (id: string, value: any, forceUpdate?: boolean) => void;

  isHovered?: string | undefined;
  setIsHovered: (id: string) => void;

  isClientViewer: boolean;
  isFileOwnerViewer: boolean;

  isStatus: (status: KycFlowStatus) => boolean;

  isStatusOneOf: (status: KycFlowStatus[]) => boolean;
}

export const KycFlowContext = React.createContext<KycFlowV2ContextValue>({
  customHandleChange: (e, doUpdate, incremental) => undefined,
  saveField: (e, options) => undefined,
  saveFieldDelayed: (e) => undefined,

  deleteEntityField: (e, entity) => undefined,

  flow: undefined,

  resetFlowValues: () => undefined,

  values: undefined,
  setFlowValues: (values, doUpdate) => undefined,

  isFieldLocked: (field) => true,
  isSubFieldLocked: (fieldName) => true,

  setFlowId: (id) => undefined,

  setFlowValue: (id: string, value: any) => undefined,

  isHovered: undefined,
  setIsHovered: (id: string) => undefined,

  isClientViewer: false,
  isFileOwnerViewer: false,

  isStatus: (status) => false,
  isStatusOneOf: (status) => false,
});

export const KycFlowV2Provider: React.FunctionComponent<KycFlowV2Props> = (props) => {
  const { children } = props;
  const { setFlowDefinition, isReadOnly, setIsReadOnly, setActiveStep, setShowControls } =
    useWizard();
  const { values, setValues, resetForm } = useFormikContext<KycFlow>();
  const { session } = useSession();
  const { updateKycFlow, updateKycFlowField, currentKycFlow } = useKyc();
  const { saveFile, deleteFile, currentFiles } = useKycFile();

  const [isHovered, setIsHoveredInternal] = useState<string>();
  const [isClientViewer, setIsClientViewerInternal] = useState<boolean>(false);
  const [isFileOwnerViewer, setIsFileOwnerViewerInternal] = useState<boolean>(false);

  const { groups } = useAppSelector((state) => ({
    groups: state.auth.groups,
  }));

  useEffect(() => {
    setIsClientViewerInternal(session?.id === values?.form?.client?.id);
  }, [session, values]);

  useEffect(() => {
    setIsFileOwnerViewerInternal(session?.id === values?.fileOwner?.id);
  }, [values?.fileOwner?.id]);

  const customHandleChange = (
    e: { target: { name: string; value: any } },
    doUpdate = false,
    incremental = false
  ) => {
    if (incremental) {
      saveField(e);
    } else {
      setValues((current) => {
        const res: any = cloneDeep(current);

        set(res, e.target.name, e.target.value);

        if (e?.target?.value?.body) {
          e.target.value.categoryId = e.target.name;
          saveFile(e.target.value);
        }

        if (doUpdate) {
          updateKycFlow(res, doUpdate);
        }
        return res;
      });
    }
  };

  const saveField = (e: any, options?: SaveFieldOptions) => {
    if (e && options?.preventDefault) {
      e?.preventDefault();
    }

    const f = options?.field || { name: e?.target?.name, value: e?.target?.value };

    if (f?.name === "" || f?.name === undefined) {
      return;
    }
    setValues((current) => {
      const res: any = cloneDeep(current);
      set(res, f.name, options?.isDateValue ? f?.value?.toString() : f?.value);

      if (f && f.value !== get(currentKycFlow, f.name)) {
        updateKycFlowField(options?.kycId || values?.id, f, { loading: options?.loading });
      }
      return res;
    });
  };

  const saveFieldDelayed = React.useCallback(
    useDebounce((event: any) => {
      saveField(undefined, { field: event?.field, kycId: event?.kycId });
    }, 100),
    []
  );

  const deleteEntityField = (e: any, data: any) => {
    saveField(e, { field: data?.entity?.field });
    const files = currentFiles.filter((f) => f.kycRelatedToEntity?.id === data?.entity?.id);
    for (const f of files) {
      deleteFile(f);
    }
  };

  useEffect(() => {
    if (!currentKycFlow) {
      return;
    }

    if (
      values?.status === KycFlowStatus.INIT ||
      statusAsArray.indexOf(values?.status) < statusAsArray.indexOf(currentKycFlow?.status)
    ) {
      setValues(currentKycFlow);
    }
    if (isKycFlowInTerminalState(values)) {
      setIsReadOnly(true);
    }
  }, [currentKycFlow, values]);

  useEffect(() => {
    if (!currentKycFlow) {
      return;
    }

    if (
      isStatusOneOf([
        KycFlowStatus.CLIENT_REVIEW_PENDING,
        KycFlowStatus.CLIENT_REJECT_AND_REVIEW_PENDING,
      ]) &&
      isFileOwnerViewer &&
      diff(values?.signatories, currentKycFlow?.signatories)
    ) {
      setValues(currentKycFlow);
    }
  }, [currentKycFlow]);

  useEffect(() => {
    setFlowDefinition(
      getFlowDefinition(
        values,
        isClientViewer,
        isFileOwnerViewer,
        setIsReadOnly,
        setActiveStep,
        setShowControls,
        groups,
        session
      )
    );
  }, [values?.form?.workflowChoice, values?.status, isClientViewer]);

  const setFlowValues = (flowValues: any, doUpdate = false) => {
    if (flowValues) {
      const res = cloneDeep(values);
      Object?.entries(flowValues)?.forEach((entry) => {
        set(res, entry[0], entry[1]);
      });
      if (values?.id !== "0" && !isEmpty(diff(res, values))) {
        if (res.form.client) {
          setValues(res);
        }
        if (doUpdate) {
          updateKycFlow(res);
        }
      }
    }
  };

  const setFlowValue = (id: string, value: any, forceUpdate = false): void => {
    const res = cloneDeep(values);
    set(res, id, value);
    if (id === "beneficialOwners" || forceUpdate) {
      updateKycFlow(res, forceUpdate);
    }

    setValues(res);
  };

  const resetFlowValues = () => {
    resetForm();
    setFlowDefinition(
      getFlowDefinition(
        values,
        isClientViewer,
        isFileOwnerViewer,
        setIsReadOnly,
        setActiveStep,
        setShowControls,
        groups,
        session
      )
    );
  };

  useEffect(() => {
    if (values?.id === "0") {
      setFlowId(uuid());
    }
  }, [values?.id]);

  const setFlowId = (id: string): void => {
    setFlowValue("id", id);
  };

  const isFieldLocked = (field: { name: string; value: any }): boolean => {
    if (!field) {
      return false;
    }
    //lock fields if:
    // 1. client is viewing the form
    // 2. field is not empty
    // 3. admin already filled out the field
    const fieldName = field.name;

    if (
      isClientViewer &&
      currentKycFlow?.fieldsUpdateHistory?.hasOwnProperty(fieldName) &&
      currentKycFlow?.fieldsUpdateHistory?.[fieldName]?.updatedByUserId !== session?.id
    ) {
      return true;
    }
    if (
      field?.name === "propDevCompany.id" &&
      (isStatus(KycFlowStatus.ADMIN_RISK_ASSESSMENT_DONE) ||
        isStatus(KycFlowStatus.ADMIN_RISK_ASSESSMENT_VALIDATED))
    ) {
      return false;
    }

    if (isReadOnly || isKycFlowInTerminalState(values)) {
      return true;
    }

    const ALWAYS_LOCKED_FIELDS = [
      "form.client.displayName",
      "form.client.email",
      "form.client.legalEntityType",
      "form.client.mobile",
      "form.clientIs",
      "form.counterparty",
      "form.client.lead.product.name",
    ];

    const TRANSACTION_DATA_FIELDS = [
      ...ALWAYS_LOCKED_FIELDS,
      "form.client.lead.id",
      "form.client.legalEntityType",
      "form.client.legalType",
      "form.client.lead.name",
      "form.reference",
      "form.transactionDate",
      "form.transactionAmount",
      "fileOwner.id",
      "form.purposeAndNature",
      "form.workflowChoice",
      "form.isClientCanUpdate",
      "form.isClientPresent",
    ];

    // lock fields that must not be modified (= no user, neither admin or client can modify, as defined in https://kodehyve.atlassian.net/browse/IMT-987)
    if (ALWAYS_LOCKED_FIELDS.includes(field?.name)) {
      return true;
    }

    if (isClientViewer && TRANSACTION_DATA_FIELDS.includes(field?.name)) {
      return true;
    }

    return !values?.form?.isClientCanUpdate && !isEmpty(get(values, field?.name));
  };

  const setIsHovered = (id: string) => {
    setIsHoveredInternal(id);
  };

  const isStatus = (status: KycFlowStatus) => {
    return values?.status === status;
  };

  const isStatusOneOf = (status: KycFlowStatus[]) => {
    if (!currentKycFlow?.status) {
      return false;
    }
    return status.includes(currentKycFlow.status);
  };

  const isSubFieldLocked = (fieldName: string): boolean => {
    return (
      (isClientViewer &&
        currentKycFlow?.fieldsUpdateHistory &&
        !isEmpty(
          Object.keys(currentKycFlow?.fieldsUpdateHistory).filter(
            (modifiedFieldEntry) =>
              modifiedFieldEntry.includes(fieldName) &&
              currentKycFlow.fieldsUpdateHistory[modifiedFieldEntry] !== session?.id
          )
        )) ||
      false
    );
  };
  return (
    <KycFlowContext.Provider
      value={{
        customHandleChange,
        saveField,
        saveFieldDelayed,
        deleteEntityField,
        values,
        resetFlowValues,
        setFlowValues,
        isFieldLocked,
        isSubFieldLocked,
        setFlowId,
        setFlowValue,
        isHovered,
        setIsHovered,
        isClientViewer,
        isFileOwnerViewer,
        isStatus,
        isStatusOneOf,
      }}
    >
      {children}
    </KycFlowContext.Provider>
  );
};

KycFlowV2Provider.propTypes = {
  children: PropTypes.node.isRequired,
};

export const KycFlowV2Consumer = KycFlowContext.Consumer;
