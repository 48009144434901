import {
  ConfidentialColumnFormatter,
  RowHighlightIndicatorColumnFormatter,
} from "../_components/column-formatters";
import { sortCaret } from "../../_metronic/_helpers";
import { ColumnDescription } from "react-bootstrap-table-next";

export const displayColumnIsConfidential = ({
  entitiesFiltered,
  columns,
}: {
  entitiesFiltered: { isConfidential: boolean }[];
  columns: ColumnDescription[];
}) => {
  if (entitiesFiltered.filter((file) => file?.isConfidential).length > 0) {
    columns.unshift({
      dataField: "isConfidential",
      text: "",
      sort: true,
      sortCaret: sortCaret,
      formatter: ConfidentialColumnFormatter,
    });
  }
};

export const displayColumnRowHighlightIndicator = ({
  entitiesFiltered,
  columns,
  id,
}: {
  entitiesFiltered: { id: string }[];
  columns: ColumnDescription[];
  id: string;
}) => {
  if (id && entitiesFiltered.find((data) => data.id === id)) {
    columns.unshift({
      dataField: "",
      text: "",
      sort: false,
      formatter: RowHighlightIndicatorColumnFormatter,
      formatExtraData: {
        highlightId: id,
      },
    });
  }
};

export const sortDateFunc = (a: any, b: any, order: "asc" | "desc") => {
  const dates = {
    a: new Date(a).getTime() || 0,
    b: new Date(b).getTime() || 0,
  };
  if (order === "asc") {
    return dates.a - dates.b;
  } else {
    //desc
    return dates.b - dates.a;
  }
};
