import { TInvoiceStatus } from "data/schemas";

export interface ILeadInstalmentsColumns {
  label: string;
  instalmentPercentage?: number;
  amount?: number;
  amountInclPriceIndex?: number;
  priceIndex?: number;
  amount0VAT?: number;
  amountReducedVAT?: number;
  amountDefaultVAT?: number;
  taxes?: number;
  amountInclVAT?: number;
  credit?: number;
  invoiced?: number;
  invoicedInclVAT?: number;
  supplementMargin?: number;
  supplementMarginPercentage?: number;
  paymentStatus?: TInvoiceStatus;
}

export const percentageColumns = [
  "instalmentPercentage",
  "priceIndex",
  "supplementMarginPercentage",
];
export const currencyColumns = [
  "amount",
  "amountInclPriceIndex",
  "amount0VAT",
  "amountReducedVAT",
  "amountDefaultVAT",
  "taxes",
  "amountInclVAT",
  "credit",
  "invoiced",
  "invoicedInclVAT",
  "supplementMargin",
];
export type TCurrencyColumns = keyof Pick<
  ILeadInstalmentsColumns,
  | "amount"
  | "amountInclPriceIndex"
  | "amount0VAT"
  | "amountReducedVAT"
  | "amountDefaultVAT"
  | "taxes"
  | "amountInclVAT"
  | "credit"
  | "invoiced"
  | "invoicedInclVAT"
  | "supplementMargin"
>;

export interface ILeadInstalment extends Partial<ILeadInstalmentsColumns> {
  id: string;
  label: string;
  paymentStatusCount: Record<string, number>;
  paid?: number;
  paidInclVAT?: number;
  indexedAmount?: number;
}

export interface ILeadSupplement extends Partial<ILeadInstalmentsColumns> {
  id: string;
  label: string;
  budgetInstalmentId?: string;
  paymentStatusCount: Record<string, number>;
  paid?: number;
  paidInclVAT?: number;
  indexedAmount?: number;
}

export const LEAD_CALCULATED_ID = {
  SHARE_OF_LAND: "SHARE_OF_LAND",
  ARCHITECT_ENGINEERING_FEES: "ARCHITECT_ENGINEERING_FEES",
  TOTAL_SUPPLEMENT: "TOTAL_SUPPLEMENT",
};
