import React, { FC } from "react";
import { Overlay, Popover } from "react-bootstrap";
import { FormattedMessage } from "react-intl";

export interface DeletePopoverProps {
  showRemove: boolean;
  setShowRemove: React.Dispatch<React.SetStateAction<boolean>>;
  remove: Function;
  targetRemove: HTMLButtonElement;
  title: string;
}
export const DeletePopover: FC<DeletePopoverProps> = ({
  showRemove,
  setShowRemove,
  remove,
  targetRemove,
  title,
}) => (
  <Overlay
    show={showRemove}
    target={targetRemove}
    placement="top"
    rootClose
    onHide={() => setShowRemove(false)}
  >
    <Popover id="popover-contained">
      <Popover.Title as="h3">
        <FormattedMessage id={title} />
      </Popover.Title>
      <Popover.Content>
        <div className={"d-flex justify-content-end"}>
          <button
            type="button"
            onClick={(e) => {
              e.stopPropagation();
              setShowRemove(false);
            }}
            className="btn btn-light btn-elevate"
          >
            <FormattedMessage id="COMMON.ACTION.CANCEL" />
          </button>
          <button
            type="button"
            onClick={(e) => {
              e.stopPropagation();
              setShowRemove(false);
              remove(e);
            }}
            className="btn btn-delete btn-danger btn-elevate ml-4"
          >
            <FormattedMessage id="COMMON.ACTION.DELETE" />
          </button>
        </div>
      </Popover.Content>
    </Popover>
  </Overlay>
);
