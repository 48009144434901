import { Field, FieldInputProps } from "formik";
import React, { FC } from "react";
import cn from "clsx";
import { useTranslation } from "../../../../../hooks/useTranslation";
import { KycInput } from "../kyc-input/KycInput";
import { useWizard } from "../../../../../hooks/useWizard";
import { formatDataCy } from "../../../KycUtils";
import { DataCyCompPrefix } from "../../../Kyc";
import { useKycFlow } from "../../../../../hooks/useKycFlow";

export interface KycCheckboxProps {
  field: any;
  inline: boolean;
  label?: string;
  customClasses?: string;
  text: string;
  disabled: boolean;
  onChange: any;
  children: any;
  translateLabel: boolean;
  translateText: boolean;
  disableLabel: boolean;
}
export const KycCheckbox: FC<KycCheckboxProps & Partial<FieldInputProps<boolean>>> = ({
  field,
  inline = true,
  label,
  customClasses,
  text,
  disabled,
  onChange,
  translateText = true,
  translateLabel = true,
  disableLabel = false,
}) => {
  const { fm } = useTranslation();
  const { isReadOnly } = useWizard();
  const { isFieldLocked } = useKycFlow();

  const isCheckboxDisabled = disabled || isReadOnly || isFieldLocked(field);

  const fieldName = field?.name;
  const fieldValue = field?.value;
  const isFieldOptionOther = field?.value?.option?.value === "other";
  const isChecked = fieldValue?.option ? fieldValue?.isChecked : fieldValue;
  text = fieldValue?.option?.label || text;

  const content = (
    <div className={cn("row", customClasses, "mb-1")}>
      <div className="col-lg-12 d-flex">
        <label
          data-cy={formatDataCy(DataCyCompPrefix.CHECKBOX, fieldName)}
          className="checkbox checkbox-lg checkbox-single mr-2 form-control-reduce-space user-select-none"
          style={{ cursor: isCheckboxDisabled && isChecked ? "default" : "pointer" }}
        >
          <input
            type="checkbox"
            name={fieldName}
            disabled={isCheckboxDisabled}
            checked={isChecked}
            onChange={onChange}
          />
          <span className="mr-2" style={{ minWidth: 24, minHeight: 24 }} />
          {translateText && text
            ? fm(text, {
                inputTextField: (
                  <div style={{ marginLeft: 5, marginRight: 5 }}>
                    <Field
                      style={{ minWidth: isFieldOptionOther && "50ch" }}
                      name={`${field?.name}.freeText`}
                      component={KycInput}
                      disableOnBlur={true}
                      disabled={isCheckboxDisabled || !isChecked}
                      locked={isCheckboxDisabled || !isChecked}
                      onBlur={(e: any) => {
                        onChange({
                          source: "freeText",
                          target: {
                            name: fieldName,
                            value: e.target.value,
                          },
                        });
                      }}
                    />
                  </div>
                ),
              })
            : text}
        </label>
      </div>
    </div>
  );
  return (
    <>
      {inline ? (
        <div className="d-flex flex-wrap flex-sm-wrap flex-md-wrap flex-lg-nowrap justify-content-center pb-2 ">
          {
            <label
              className={cn("col-lg-6 col-form-label text-left", disableLabel && "text-muted")}
            >
              {label && fm(label)}
            </label>
          }
          <div className={cn("col-lg-6", disableLabel && "text-muted")}>{content}</div>
        </div>
      ) : (
        <>
          {label && <label>{translateLabel ? fm(label) : label}</label>}
          {content}
        </>
      )}
    </>
  );
};
