import * as React from "react";
import { useDashboardContext } from "../context/DashboardContext";
import { useSetupAmplifyAxios } from "../../../_components/SetupAmplifyAxios";
import { useDidMount } from "rooks";
import { useSubheader } from "../../../../_metronic/layout";
import { FormattedMessage, useIntl } from "react-intl";
import { sumBy } from "lodash-es";
import { CustomSelect } from "../../../_components/CustomSelect";
import { InvoiceManagementMetrics } from "./components/InvoiceManagementMetrics";
import {
  InvoiceDashboardMetrics,
  InvoiceDashboardProjectsData,
} from "../../../../data/schemas/Dashboard";
import { InvoiceManagementInvoices } from "./components/InvoiceManagementInvoices";

export const InvoiceManagement: React.FC = () => {
  const intl = useIntl();

  const subheader = useSubheader();

  React.useEffect(() => {
    subheader.setTitle(
      intl.formatMessage({
        id: "DASHBOARD.INVOICE_MANAGEMENT_DASHBOARD",
      })
    );
  }, [subheader]);

  const { shouldRedirectOnStatus404 } = useSetupAmplifyAxios();
  useDidMount(() => {
    shouldRedirectOnStatus404(false);
  });

  const { invoiceManagementLoading, invoiceManagementData } = useDashboardContext();

  const [selectedProjects, setSelectedProjects] = React.useState<any[]>([]);

  const computeProjectData = (
    res: InvoiceDashboardMetrics,
    {
      projects,
      leadsInvoices,
      subcontractorsInvoices,
      subcontractors,
      clients,
    }: Omit<InvoiceDashboardProjectsData, "createdAt">
  ) => {
    for (const project of Object.values(projects)) {
      res.lead.expectedRevenue += project.expectedRevenue;
      res.subcontractors.realBudget += project.realBudget;
    }
    res.lead.invoiced += sumBy(leadsInvoices, "amount");
    res.subcontractors.invoiced += sumBy(subcontractorsInvoices, "amount");
    res.lead.open = res.lead.expectedRevenue - res.lead.invoiced;
    if (res.lead.open < 0) {
      res.lead.open = 0;
    }
    res.subcontractors.open = res.subcontractors.realBudget - res.subcontractors.invoiced;
    if (res.subcontractors.open < 0) {
      res.subcontractors.open = 0;
    }
    res.invoicesCount += leadsInvoices.length + subcontractorsInvoices.length;
    const paidSubcontractorsInvoices = sumBy(subcontractorsInvoices, (invoice) =>
      invoice.invoiceStatus === "PAID" ? 1 : 0
    );
    const paidLeadInvoices = sumBy(leadsInvoices, (invoice) =>
      invoice.invoiceStatus === "PAID" ? 1 : 0
    );
    res.invoicesPaidCount += paidSubcontractorsInvoices + paidLeadInvoices;
    res.subcontractorsCount += Object.keys(subcontractors).length;
    res.clientsCount += Object.keys(clients).length;
    return res;
  };

  const shownData = React.useMemo(() => {
    let res: InvoiceDashboardMetrics = {
      lead: {
        expectedRevenue: 0,
        invoiced: 0,
        open: 0,
      },
      subcontractors: {
        realBudget: 0,
        invoiced: 0,
        open: 0,
      },
      invoicesCount: 0,
      invoicesPaidCount: 0,
      subcontractorsCount: 0,
      clientsCount: 0,
    };

    if (invoiceManagementData) {
      if (selectedProjects.length) {
        const projects = selectedProjects.reduce(
          (acc, projectId) => ({ ...acc, [projectId]: invoiceManagementData.projects[projectId] }),
          {}
        );
        const leadsInvoices = invoiceManagementData.leadsInvoices.filter((invoice) =>
          selectedProjects.includes(invoice.projectId)
        );
        const leadIds = new Set(leadsInvoices.map((invoice) => invoice.leadId));
        const clients: any = {};
        for (const leadId of Array.from(leadIds)) {
          for (const client of Object.values(invoiceManagementData.clients)) {
            if (client.leads.includes(leadId)) {
              clients[client.id] = client;
            }
          }
        }

        const subcontractorsInvoices = invoiceManagementData.subcontractorsInvoices.filter(
          (invoice) => selectedProjects.includes(invoice.projectId)
        );

        const subcontractors: any = {};
        const subcontractorsIds = new Set(subcontractorsInvoices.map((invoice) => invoice.userId));
        for (const subcontractorId of Array.from(subcontractorsIds)) {
          subcontractors[subcontractorId] = invoiceManagementData.subcontractors[subcontractorId];
        }

        res = computeProjectData(res, {
          projects,
          leadsInvoices,
          subcontractorsInvoices,
          subcontractors,
          clients,
        });
      } else {
        res = computeProjectData(res, invoiceManagementData);
      }
    }

    return res;
  }, [selectedProjects, invoiceManagementData]);

  return invoiceManagementLoading ? (
    <div className="d-flex align-items-center justify-content-center">
      <div className="spinner spinner-lg spinner-primary h-30px w-30px" />
    </div>
  ) : invoiceManagementData ? (
    <>
      <div className="row">
        <div className="col-12">
          <div className={"card card-custom gutter-b"}>
            <div className="card-body d-flex flex-row justify-content-between align-items-center">
              <h2 className="mb-0">
                <FormattedMessage id="DASHBOARD.INVOICE_MANAGEMENT" />
              </h2>
              <div className="d-flex align-items-center">
                <div className="mr-4">
                  {invoiceManagementData && (
                    <FormattedMessage
                      id="COMMON.LAST_REFRESH_ON"
                      values={{
                        date:
                          intl.formatDate(invoiceManagementData?.createdAt) +
                          ", " +
                          intl.formatTime(invoiceManagementData?.createdAt),
                      }}
                    />
                  )}
                </div>
                <div>
                  <CustomSelect
                    onChange={(selected) => setSelectedProjects(selected.map((p) => p.value))}
                    isMulti={true}
                    clearable={true}
                    getOptionValue={(o: any) => o.value}
                    options={Object.values(invoiceManagementData?.projects ?? {}).map(
                      (project) => ({
                        label: project.name!,
                        value: project.id!,
                      })
                    )}
                    styles={{ control: { width: "300px" } }}
                    placeholder={intl.formatMessage({ id: "INVOICE_MANAGEMENT.ALL_PROJECTS" })}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <InvoiceManagementMetrics data={shownData} />
      <InvoiceManagementInvoices data={invoiceManagementData} selectedProjects={selectedProjects} />
    </>
  ) : (
    <div className="card card-custom min-h-300px">
      <div className="card-body d-flex flex-row justify-content-center align-items-center">
        <h3>
          <FormattedMessage id="DASHBOARD.NO_DATA" />
        </h3>
      </div>
    </div>
  );
};
