import * as React from "react";
import cn from "clsx";
import { FormattedMessage } from "react-intl";
import {
  CurrencyMetricCardSubMetric,
  MetricCardSubMetric,
  NumberMetricCardSubMetric,
  PercentageMetricCardSubMetric,
} from "./MetricCardSubValue";
import { HTMLAttributes } from "react";

type MetricCardComponents = {
  SubMetric: typeof MetricCardSubMetric;
  NumberSubMetric: typeof NumberMetricCardSubMetric;
  CurrencySubMetric: typeof CurrencyMetricCardSubMetric;
  PercentageSubMetric: typeof PercentageMetricCardSubMetric;
};

export interface MetricCardBase {
  mainContent: React.ReactNode;
  label: string;
  title?: string;
}

export interface MetricCardProps extends Pick<HTMLAttributes<HTMLDivElement>, "className"> {}

export const MetricCard: React.FC<MetricCardProps & MetricCardBase> & MetricCardComponents = ({
  className,
  mainContent,
  label,
  title,
  children,
}) => {
  return (
    <div className={cn("col-12 col-sm-6 col-md-4 col-xl-3 d-flex flex-column", className)}>
      <div className="card card-custom bg-light flex-grow-1">
        <div className="card-body d-flex flex-column px-8 py-7">
          {!!title && (
            <div className="font-size-h3 font-weight-bold">
              <FormattedMessage id={title} />
            </div>
          )}
          <div className="text-inverse-light font-weight-bold font-size-md">
            <FormattedMessage id={label} />
          </div>
          <div className="text-inverse-light font-weight-bolder font-size-h3">{mainContent}</div>
          {children}
        </div>
      </div>
    </div>
  );
};

MetricCard.SubMetric = MetricCardSubMetric;
MetricCard.NumberSubMetric = NumberMetricCardSubMetric;
MetricCard.CurrencySubMetric = CurrencyMetricCardSubMetric;
MetricCard.PercentageSubMetric = PercentageMetricCardSubMetric;
