import React from "react";
import { Button } from "react-bootstrap";
import { formatDisplayLang } from "../../../KycFileUtils";
import { KycFile } from "../../../Kyc";
import { handleDownloadFile } from "../../../../../_utils/fileUtils";

interface KycFileItemProps {
  file: KycFile;
  index?: number;
}

export const KycFileItemLang = ({ file, index }: KycFileItemProps): JSX.Element => {
  return (
    <div className="d-flex align-items-center">
      <span className="px-1 text-muted">{index && index > 0 ? "|" : ""}</span>
      <Button
        style={{ paddingLeft: 4, paddingRight: 0 }}
        size="lg"
        variant="link"
        onClick={(e) => handleDownloadFile(e, file)}
      >
        {formatDisplayLang(file?.lang ?? "")}
      </Button>
    </div>
  );
};
