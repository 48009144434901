import { Carousel } from "react-bootstrap";
import "../../../KycWizard.scss";
import { KycClientPage2LegalEntityPersonalInfo } from "../../steps/KycClientPage2LegalEntityPersonalInfo";

import "../../RepresentativeCarousel.scss";
import { KycItemType } from "../../../Kyc";
import { KycBoShareholderCompany } from "../../steps/KycBoShareholderCompany";

type RepresentativeCarouselProps = {
  name: string;
  activeIndex: number;
  items: any[];
  itemType: KycItemType;
};

export const KycEntityCarousel = ({
  name,
  activeIndex,
  items,
  itemType,
}: RepresentativeCarouselProps): JSX.Element => {
  const getChildrenForItemType = (name: string, index: number) => {
    switch (itemType) {
      case KycItemType.PERSONAL_INFO:
        return <KycClientPage2LegalEntityPersonalInfo name={name} index={index} />;
      case KycItemType.SHAREHOLDER_COMPANY:
        return <KycBoShareholderCompany name={name} index={index} />;
    }
  };

  return (
    <div className="mb-8">
      <Carousel indicators={false} controls={false} interval={null} activeIndex={activeIndex}>
        {items?.map((entity: any, index: number) => (
          <Carousel.Item key={`${name}-carousel-item-${index}`}>
            {/*performance boost:
             When having 8+ items (non-production build), ui starts to lag. This is caused by formik
             as performance drastically decreases after 30+ fields or so (TODO replace shitty discontinued formik)
             Underneath the condition loads max 3 items at a time, to support carousel slide animation
              This only works if user clicks the previous or next item. When active index is 1 and we click e.g. the 8th entry,
              animation still works but looks a bit odd since white space is shown instead of previous tab
              -> Can be improved by replacing carousel/improve code, but very low priority
            */}
            {(activeIndex === index || index === activeIndex - 1 || index === activeIndex + 1) &&
              getChildrenForItemType(name, index)}
          </Carousel.Item>
        ))}
      </Carousel>
    </div>
  );
};
