import * as React from "react";
import { useKycFile } from "../../../../../hooks/useKycFile";
import { useWizard } from "../../../../../hooks/useWizard";
import { isFileUploaded } from "../../../KycFileUtils";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../../../_metronic/_helpers";
import { KycFile } from "../../../Kyc";
import { KycDialogConfig } from "../../KycConfirmationDialogContainer";
import { useKycConfirmation } from "../../../../../hooks/useKycConfirmation";
import { useSession } from "app/hooks/useSession";

type KycUploadComponentProps = {
  disabled?: boolean;
  file?: KycFile;
};

export const KycUploadTrashButton = ({
  disabled = false,
  file,
}: KycUploadComponentProps): JSX.Element => {
  const { isReadOnly } = useWizard();
  const { setConfig, setShowModal } = useKycConfirmation();
  const { session } = useSession();
  const { deleteFile } = useKycFile();

  const handleDeleteFile = (e: any, dialogConfig: KycDialogConfig) => {
    e.preventDefault();

    if (file?.createdByUserId === session?.id || file?.status === "DRAFT") {
      deleteFile(file);
    } else {
      setConfig({
        type: dialogConfig,
        kycId: file?.kycId,
        file: file,
      });
      setShowModal(true);
    }
  };

  return (
    <>
      {((file && isFileUploaded(file)) || file?.option) && !disabled && !isReadOnly && (
        <div className="px-0">
          <button
            className="btn btn-icon btn-light btn-hover-danger btn-md ml-1 form-control-reduce-space h-100"
            onClick={(e: any) => handleDeleteFile(e, KycDialogConfig.KYC_FLOW_FILE_DELETE)}
          >
            <span className="svg-icon svg-icon-md svg-icon-danger">
              <SVG src={toAbsoluteUrl("/media/svg/icons/General/Trash.svg")} />
            </span>
          </button>
        </div>
      )}
    </>
  );
};
