/*
  How to use React Context effectively | Kent C. Dodds
  https://kentcdodds.com/blog/how-to-use-react-context-effectively
*/

import * as React from "react";

import SetupAmplifyAxios from "./SetupAmplifyAxios";

export interface IShouldRedirectOnError {
  status401: boolean;
  status404: boolean;
  status5xx: boolean;
  network: boolean;
}

export interface ISetupAmplifyAxiosContext {
  shouldRedirectOnError: Readonly<IShouldRedirectOnError>;

  shouldRedirectOnStatus401: React.Dispatch<React.SetStateAction<boolean>>;
  shouldRedirectOnStatus404: React.Dispatch<React.SetStateAction<boolean>>;
  shouldRedirectOnStatus5xx: React.Dispatch<React.SetStateAction<boolean>>;
  shouldRedirectOnNetworkError: React.Dispatch<React.SetStateAction<boolean>>;
}

export const SetupAmplifyAxiosContext = React.createContext<ISetupAmplifyAxiosContext | undefined>(
  undefined
);

export const useSetupAmplifyAxios = () => {
  const context = React.useContext(SetupAmplifyAxiosContext);

  if (context === undefined) {
    throw new Error("useSetupAmplifyAxios must be used within a SetupAmplifyAxiosProvider");
  }

  return context;
};

export const SetupAmplifyAxiosProvider: React.FunctionComponent = ({ children }) => {
  const [status401, shouldRedirectOnStatus401] = React.useState(true);
  const [status404, shouldRedirectOnStatus404] = React.useState(true);
  const [status5xx, shouldRedirectOnStatus5xx] = React.useState(true);
  const [network, shouldRedirectOnNetworkError] = React.useState(true);

  const contextValue: ISetupAmplifyAxiosContext = {
    shouldRedirectOnError: {
      status401,
      status404,
      status5xx,
      network,
    },

    shouldRedirectOnStatus401,
    shouldRedirectOnStatus404,
    shouldRedirectOnStatus5xx,
    shouldRedirectOnNetworkError,
  };

  return (
    <SetupAmplifyAxiosContext.Provider value={contextValue}>
      <SetupAmplifyAxios />

      {children}
    </SetupAmplifyAxiosContext.Provider>
  );
};

export default SetupAmplifyAxiosProvider;
