import { inAppNotificationsSlice } from "./inAppNotificationsSlice";
import * as requestFromServer from "./inAppNotificationsCrud";

const { actions } = inAppNotificationsSlice;

export const fetchLatestNotifications =
  ({ intl, userId, groups, session }) =>
  (dispatch) => {
    return requestFromServer
      .getLatest({ intl, userId, groups, session })
      .then((response) => {
        dispatch(actions.updateNotifications({ notifications: response }));
      })
      .catch((error) => {
        console.log("getLatest error:", error);

        //TODO: temporarily disabled to avoid snackbar notification that appear all the time
        // dispatch(
        //   snackBarNotificationsActions.addNotification({
        //     type: "danger",
        //     body: "Failed to retrieve notifications",
        //   })
        // );
      });
  };

export const markNotificationAsRead = (notification) => (dispatch) => {
  return requestFromServer
    .updateNotification(notification)
    .then((response) => {
      dispatch(actions.markNotificationAsRead({ notification: response }));
    })
    .catch((error) => {
      console.log("getLatest error:", error);
    });
};
