import { IntlShape } from "react-intl";

import { TASK_ATTRIBUTES_TO_FILTER_WITH, sortedTasksUtils, getTaskText } from "app/_utils/tasks";
import { lookupForValueFilterFn } from "app/_utils/searchUtils";

import { TasksSorterFilter, TasksSorterFilterFunction } from "../definitions";

export interface TasksSortFilterParam extends TasksSorterFilter {
  intl: IntlShape;
}

/**
 * logic to sort and filter the tasks in the way needed for the gantt chart
 */
export const tasksSorterFilter: TasksSorterFilterFunction<TasksSortFilterParam> = ({
  intl,
  filterText = "",
  selectedSort,
  tasks,
}) => {
  if (tasks.length === 0) return tasks;

  const searchByText = (filterText && filterText.length > 0 && filterText.trim()) || "";
  const shouldFilter = searchByText.length > 1;

  /*
    1 - sort the tasks level
    2 - sort the subtasks level
    3 - if the filterText is defined filter the subtasks level
  */
  tasks = sortedTasksUtils(tasks, selectedSort).map((task) => {
    // In order to avoid an assign to read only property issue
    const clonedTask = { ...task };
    const subtasks = clonedTask.subtasks || [];
    const hasSubtasks = subtasks && subtasks.length > 0;

    const { name, attachments } = clonedTask;
    clonedTask.name = getTaskText(intl, name, attachments);

    if (hasSubtasks) {
      clonedTask.subtasks = sortedTasksUtils(subtasks, selectedSort).map((subtask) => {
        const { name, attachments } = subtask;
        subtask.name = getTaskText(intl, name, attachments);

        return subtask;
      });

      if (shouldFilter) {
        clonedTask.subtasks = clonedTask.subtasks.filter(
          lookupForValueFilterFn(searchByText, TASK_ATTRIBUTES_TO_FILTER_WITH, intl)
        );
      }
    }

    return clonedTask;
  });

  // 4 - if the filterText is defined filter the tasks level
  // (it will keep the task in case of it contains any subtask)
  if (shouldFilter) {
    tasks = tasks.filter(
      (task) =>
        task.subtasks.length > 0 ||
        lookupForValueFilterFn(searchByText, TASK_ATTRIBUTES_TO_FILTER_WITH, intl)(task)
    );
  }

  return tasks;
};

export default tasksSorterFilter;
