import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import * as globalActions from "../../redux/global/globalActions";

// This method is used for back button and for opening an entity from another entity (ex: Projects/<id> -> Products/<id>) and for breadcrumb
export const useCustomLocationState = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const { fromUrls } = useSelector((state) => ({
    fromUrls: state.global.fromUrls,
  }));

  useEffect(
    () => () => {
      if (!history.location?.state?.fromUrl) {
        dispatch(globalActions.resetFromUrl());
      }
    },
    [history]
  );

  const setFromUrlTo = (fromUrlTo) => {
    dispatch(
      globalActions.setFromUrl({
        url: history.location.pathname + history.location.search,
        name: fromUrlTo.name,
      })
    );
    history.push(fromUrlTo.url, { fromUrl: true });
  };

  const goBack = (defaultUrl = "/", numberOfUrlsToBack = 1) => {
    if (fromUrls.length && numberOfUrlsToBack <= fromUrls.length) {
      dispatch(globalActions.backToUrl(numberOfUrlsToBack));
      const fromUrl = fromUrls[fromUrls.length - numberOfUrlsToBack];
      history.push(fromUrl.url, { fromUrl: true });
    } else {
      dispatch(globalActions.resetFromUrl());
      history.push(defaultUrl);
    }
  };
  return { setFromUrlTo, goBack };
};
