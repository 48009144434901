import {
  ETaskStatus,
  TASK_STATUS_COLOR,
  TASK_STATUS_I18N_TITLE,
  TASK_STATUS_ICON_NAME,
} from "data/schemas/Task";

export const PROPERTY_TYPES = {
  "-": "EMPTY.STRING",
  STUDIO: "PROPERTY.TYPES.STUDIO",
  APARTMENT: "PROPERTY.TYPES.APARTMENT",
  PENTHOUSE: "PROPERTY.TYPES.PENTHOUSE",
  DUPLEX: "PROPERTY.TYPES.DUPLEX",
  TRIPLEX: "PROPERTY.TYPES.TRIPLEX",
  LOFT: "PROPERTY.TYPES.LOFT",
  HOUSE: "PROPERTY.TYPES.HOUSE",
  DETACHED_HOUSE: "PROPERTY.TYPES.DETACHED_HOUSE",
  SEMI_DETACHED_HOUSE: "PROPERTY.TYPES.SEMI_DETACHED_HOUSE",
  VILLA: "PROPERTY.TYPES.VILLA",
  OFFICE: "PROPERTY.TYPES.OFFICE",
  LAND: "PROPERTY.TYPES.LAND",
  INDOOR_PARKING: "PROPERTY.TYPES.INDOOR_PARKING",
  OUTDOOR_PARKING: "PROPERTY.TYPES.OUTDOOR_PARKING",
  CELLAR: "PROPERTY.TYPES.CELLAR",
};

export const LEAD_FILE_TYPES = {
  "-": "EMPTY.STRING",
  PLAN_CHANGES: "LEAD.FILE.TYPES.PLAN_CHANGES",
  EXTRA_REQUESTS: "LEAD.FILE.TYPES.EXTRA_REQUESTS",
  LEGAL: "LEAD.FILE.TYPES.LEGAL",
  INVOICE: "LEAD.FILE.TYPES.INVOICE",
  QUOTE: "LEAD.FILE.TYPES.QUOTE",
  CONTRACT: "LEAD.FILE.TYPES.CONTRACT",
  SALES_AGREEMENT: "LEAD.FILE.TYPES.SALES_AGREEMENT",
  SUPPLEMENTARY_AGREEMENT: "LEAD.FILE.TYPES.SUPPLEMENTARY_AGREEMENT",
  STATUS_REPORT: "LEAD.FILE.TYPES.STATUS_REPORT",
  ORDER: "LEAD.FILE.TYPES.ORDER",
  VARIOUS: "LEAD.FILE.TYPES.VARIOUS",
};

export const PROJECT_PRODUCT_FILE_TYPES = {
  "-": "EMPTY.STRING",
  ARCHITECT_PLANS: "PROJECT.PRODUCT.FILE.TYPES.ARCHITECT_PLANS",
  BUSINESS_PLANS: "PROJECT.PRODUCT.FILE.TYPES.BUSINESS_PLANS",
  INSTALMENTS: "PROJECT.PRODUCT.FILE.TYPES.INSTALMENTS",
  SALES_BOOKLET: "PROJECT.PRODUCT.FILE.TYPES.SALES_BOOKLET",
  VISUALISATIONS: "PROJECT.PRODUCT.FILE.TYPES.VISUALISATIONS",
  TECHNICAL_SPECIFICATIONS: "PROJECT.PRODUCT.FILE.TYPES.TECHNICAL_SPECIFICATIONS",
  ANALYSIS: "PROJECT.PRODUCT.FILE.TYPES.ANALYSIS",
  BUILDING_PERMIT: "PROJECT.PRODUCT.FILE.TYPES.BUILDING_PERMIT",
  AUTHORISATION: "PROJECT.PRODUCT.FILE.TYPES.AUTHORISATION",
  CADASTRE: "PROJECT.PRODUCT.FILE.TYPES.CADASTRE",
  FINANCE: "PROJECT.PRODUCT.FILE.TYPES.FINANCE",
  NOTARIAL_DEED: "PROJECT.PRODUCT.FILE.TYPES.NOTARIAL_DEED",
  MINUTES: "PROJECT.PRODUCT.FILE.TYPES.MINUTES",
  QUOTE: "PROJECT.PRODUCT.FILE.TYPES.QUOTE",
  INVOICE: "PROJECT.PRODUCT.FILE.TYPES.INVOICE",
  CONTRACT: "PROJECT.PRODUCT.FILE.TYPES.CONTRACT",
  SALES_AGREEMENT: "PROJECT.PRODUCT.FILE.TYPES.SALES_AGREEMENT",
  SUPPLEMENTARY_AGREEMENT: "PROJECT.PRODUCT.FILE.TYPES.SUPPLEMENTARY_AGREEMENT",
  PROGRESS: "PROJECT.PRODUCT.FILE.TYPES.PROGRESS",
  TECHNICAL_DATA_SHEET: "PROJECT.PRODUCT.FILE.TYPES.TECHNICAL_DATA_SHEET",
  CORRESPONDENCE: "PROJECT.PRODUCT.FILE.TYPES.CORRESPONDENCE",
  DOCUMENTATION: "PROJECT.PRODUCT.FILE.TYPES.DOCUMENTATION",
  STATUS_REPORT: "PROJECT.PRODUCT.FILE.TYPES.STATUS_REPORT",
  ORDER: "PROJECT.PRODUCT.FILE.TYPES.ORDER",
  PLANS: "PROJECT.PRODUCT.FILE.TYPES.PLANS",
  VARIOUS: "PROJECT.PRODUCT.FILE.TYPES.VARIOUS",
  OTHER: "PROJECT.PRODUCT.FILE.TYPES.OTHER",
};

export const USER_FILE_TYPES = {
  CLIENT: {
    "-": "EMPTY.STRING",
    ID: "USER.FILE.TYPES.CLIENT.ID",
    RESIDENCE_CERTIFICATE: "USER.FILE.TYPES.CLIENT.RESIDENCE_CERTIFICATE",
    BANK_GUARANTEE: "USER.FILE.TYPES.CLIENT.BANK_GUARANTEE",
    SOCIAL_SECURITY_CARD: "USER.FILE.TYPES.CLIENT.SOCIAL_SECURITY_CARD",
    PAYSLIP: "USER.FILE.TYPES.CLIENT.PAYSLIP",
    EMPLOYMENT_CONTRACT: "USER.FILE.TYPES.CLIENT.EMPLOYMENT_CONTRACT",
    PROOF_OF_FUNDS: "USER.FILE.TYPES.CLIENT.PROOF_OF_FUNDS",
    AML_KYC_FORM: "USER.FILE.TYPES.CLIENT.AML_KYC_FORM",
    FILLOUT_AML_KYC_FORM: "USER.FILE.TYPES.CLIENT.FILLOUT_AML_KYC_FORM",
    BANK: "USER.FILE.TYPES.CLIENT.BANK",
    CONTRACT: "USER.FILE.TYPES.CLIENT.CONTRACT",
    CRIMINAL_RECORD: "USER.FILE.TYPES.CLIENT.CRIMINAL_RECORD",
    DECLARATION_OF_BENEFICIAL_OWNER: "USER.FILE.TYPES.CLIENT.DECLARATION_OF_BENEFICIAL_OWNER",
    VARIOUS: "USER.FILE.TYPES.CLIENT.VARIOUS",
  },
  SUBCONTRACTOR: {
    "-": "EMPTY.STRING",
    QUOTE: "USER.FILE.TYPES.SUBCONTRACTOR.QUOTE",
    INVOICE: "USER.FILE.TYPES.SUBCONTRACTOR.INVOICE",
    SUPPLEMENTARY_AGREEMENT: "USER.FILE.TYPES.SUBCONTRACTOR.SUPPLEMENTARY_AGREEMENT",
    ARCHITECT_PLANS: "USER.FILE.TYPES.SUBCONTRACTOR.ARCHITECT_PLANS",
    BUSINESS_PLANS: "PROJECT.PRODUCT.FILE.TYPES.BUSINESS_PLANS",
    MINUTES: "USER.FILE.TYPES.SUBCONTRACTOR.MINUTES",
    PROGRESS: "USER.FILE.TYPES.SUBCONTRACTOR.PROGRESS",
    LEGAL: "USER.FILE.TYPES.SUBCONTRACTOR.LEGAL",
    CONTRACT: "USER.FILE.TYPES.SUBCONTRACTOR.CONTRACT",
    VISUALISATIONS: "USER.FILE.TYPES.SUBCONTRACTOR.VISUALISATIONS",
    STATUS_REPORT: "USER.FILE.TYPES.SUBCONTRACTOR.STATUS_REPORT",
    TECHNICAL_DATA_SHEET: "USER.FILE.TYPES.SUBCONTRACTOR.TECHNICAL_DATA_SHEET",
    CORRESPONDENCE: "USER.FILE.TYPES.SUBCONTRACTOR.CORRESPONDENCE",
    DOCUMENTATION: "USER.FILE.TYPES.SUBCONTRACTOR.DOCUMENTATION",
    ORDER: "USER.FILE.TYPES.SUBCONTRACTOR.ORDER",
    PLANS: "USER.FILE.TYPES.SUBCONTRACTOR.PLANS",
    VARIOUS: "USER.FILE.TYPES.SUBCONTRACTOR.VARIOUS",
  },
  USER: {
    "-": "EMPTY.STRING",
    CONTRACT: "USER.FILE.TYPES.USER.CONTRACT",
    ID: "USER.FILE.TYPES.USER.ID",
    VARIOUS: "USER.FILE.TYPES.USER.VARIOUS",
  },
};

export const LEGAL_ENTITY_TYPES = [
  {
    value: "NATURAL",
    label: "CLIENT.TYPES.NATURAL",
  },
  {
    value: "LEGAL",
    label: "CLIENT.TYPES.LEGAL",
  },
];

export const TITLES = [
  {
    value: "-",
    label: "EMPTY.STRING",
  },
  {
    value: "Mr",
    label: "TITLES.MR",
  },
  {
    value: "Mrs",
    label: "TITLES.MRS",
  },
];

export const HEATING_TYPES = {
  "-": "EMPTY.STRING",
  ELECTRICITY: "HEATING.TYPES.ELECTRICITY",
  FUEL_OIL: "HEATING.TYPES.FUEL_OIL",
  GAS: "HEATING.TYPES.GAS",
  HEAT_PUMP: "HEATING.TYPES.HEAT_PUMP",
  OTHER: "HEATING.TYPES.OTHER",
};

export const PRODUCT_TYPES = {
  "-": "EMPTY.STRING",
  RENTAL: "PRODUCT.TYPES.RENTAL",
  SALE: "PRODUCT.TYPES.SALE",
  OTHER: "PRODUCT.TYPES.OTHER",
};

export const INVOICE_STATUS = {
  TO_BE_PAID: "INVOICE.STATUS.TO_BE_PAID",
  PARTIALLY_PAID: "INVOICE.STATUS.PARTIALLY_PAID",
  PAID: "INVOICE.STATUS.PAID",
  OVERDUE: "INVOICE.STATUS.OVERDUE",
};

export const PRODUCT_STATUS = ["PRODUCT.STATUS.SELLING", "PRODUCT.STATUS.SOLD"];

export const SOURCE_OF_FUNDS = [
  "EMPTY.STRING",
  "SOURCE.OF.FUNDS.SELLING_HERITAGE",
  "SOURCE.OF.FUNDS.PROFESSIONAL_INCOME",
  "SOURCE.OF.FUNDS.REALISATION_OF_ASSETS",
  "SOURCE.OF.FUNDS.OTHER_SOURCE_OF_FUNDS",
];

export const EMPLOYMENT_STATUS = [
  "EMPTY.STRING",
  "EMPLOYMENT.STATUS.EMPLOYEE",
  "EMPLOYMENT.STATUS.SELF_EMPLOYED",
  "EMPLOYMENT.STATUS.LIBERAL_PROFESSION",
  "EMPLOYMENT.STATUS.EUROPEAN_CIVIL_SERVANT",
  "EMPLOYMENT.STATUS.LIFE_ANNUITANT",
  "EMPLOYMENT.STATUS.RETIRED",
  "EMPLOYMENT.STATUS.UNEMPLOYED",
  "EMPLOYMENT.STATUS.STUDENT",
  "EMPLOYMENT.STATUS.OTHER_ACTIVITY",
];

export const EMPLOYER_SECTOR = [
  "EMPTY.STRING",
  "EMPLOYER.SECTOR.ADULT_INDUSTRY",
  "EMPLOYER.SECTOR.AGRICULTURE",
  "EMPLOYER.SECTOR.BANK_INSURANCE",
  "EMPLOYER.SECTOR.BUSINESS_SERVICES",
  "EMPLOYER.SECTOR.CAR_TRADE",
  "EMPLOYER.SECTOR.CASINOS_AND_GAMBLING",
  "EMPLOYER.SECTOR.CATERING",
  "EMPLOYER.SECTOR.CHARITY",
  "EMPLOYER.SECTOR.CHEMISTRY_PARACHEMISTRY",
  "EMPLOYER.SECTOR.CONSTRUCTION",
  "EMPLOYER.SECTOR.ELECTRONICS",
  "EMPLOYER.SECTOR.EXPRESS_SHIPPING_COMPANIES",
  "EMPLOYER.SECTOR.FAIRGROUND",
  "EMPLOYER.SECTOR.FOOD",
  "EMPLOYER.SECTOR.IT_TELECOM",
  "EMPLOYER.SECTOR.IMPORT_EXPORT_OF_GOODS",
  "EMPLOYER.SECTOR.LAWYERS_OFFICE",
  "EMPLOYER.SECTOR.LIVESTOCK_TRADE",
  "EMPLOYER.SECTOR.MACHINERY_AND_EQUIPMENT_AUTOMOTIVE",
  "EMPLOYER.SECTOR.METALLURGY",
  "EMPLOYER.SECTOR.NOTARY_OFFICE",
  "EMPLOYER.SECTOR.PHARMACEUTICAL_INDUSTRY",
  "EMPLOYER.SECTOR.PLASTIC_RUBBER",
  "EMPLOYER.SECTOR.POLITICAL_PARTIES",
  "EMPLOYER.SECTOR.PROFESSIONAL_SPORT",
  "EMPLOYER.SECTOR.PUBLIC_ADMINISTRATION",
  "EMPLOYER.SECTOR.PUBLISHING_COMMUNICATION_MULTIMEDIA",
  "EMPLOYER.SECTOR.STOCKBROKER",
  "EMPLOYER.SECTOR.STUDIES_AND_ADVISORY",
  "EMPLOYER.SECTOR.TEXTILE_CLOTHING_SHOES",
  "EMPLOYER.SECTOR.TOBACCO",
  "EMPLOYER.SECTOR.TRADE_COMMERCE_DISTRIBUTION",
  "EMPLOYER.SECTOR.TRADE_ANTIQUES_OR_WORKS_OF_ART",
  "EMPLOYER.SECTOR.TRADE_MILITARY_EQUIPMENT_OIL",
  "EMPLOYER.SECTOR.TRADE_ELECTRONIC_AND_COMPUTER_EQUIPMENT",
  "EMPLOYER.SECTOR.TRANSPORT_LOGISTICS",
  "EMPLOYER.SECTOR.WASTE_AND_METALS_TREATMENT",
  "EMPLOYER.SECTOR.WHOLESALE_TRADE",
  "EMPLOYER.SECTOR.WOOD_PAPER_CARDBOARD_PRINTING",
  "EMPLOYER.SECTOR.OTHER_SECTOR",
];

export const LEAD_STATUS = {
  "-": {
    name: "LEAD.STATUS.N_A_NAME",
    className: "warning",
    priority: 1,
  },
  LOST: {
    name: "LEAD.STATUS.LOST_NAME",
    description: "LEAD.STATUS.LOST_DESCRIPTION",
    className: "red",
    priority: 2,
  },
  OPEN: {
    name: "LEAD.STATUS.OPEN_NAME",
    description: "LEAD.STATUS.OPEN_DESCRIPTION",
    className: "info",
    priority: 3,
  },
  QUALIFIED: {
    name: "LEAD.STATUS.QUALIFIED_NAME",
    description: "LEAD.STATUS.QUALIFIED_DESCRIPTION",
    className: "warning",
    priority: 4,
  },
  WON: {
    name: "LEAD.STATUS.WON_NAME",
    description: "LEAD.STATUS.WON_DESCRIPTION",
    className: "success",
    priority: 5,
  },
};

/*
  https://www.countrycode.org/
  https://en.wikipedia.org/wiki/List_of_ISO_3166_country_codes
*/
export const COUNTRIES_INFO = [
  { code: "LU", flag: "/media/svg/flags/035-luxembourg.svg" },
  { code: "FR", flag: "/media/svg/flags/195-france.svg" },
  { code: "BE", flag: "/media/svg/flags/165-belgium.svg" },
  { code: "DE", flag: "/media/svg/flags/162-germany.svg" },
  { code: "GB", flag: "/media/svg/flags/260-united-kingdom.svg" },
  { code: "PT", flag: "/media/svg/flags/224-portugal.svg" },
  { code: "IT", flag: "/media/svg/flags/013-italy.svg" },
  { code: "ES", flag: "/media/svg/flags/128-spain.svg" },
  { code: "CH", flag: "/media/svg/flags/205-switzerland.svg" },
  { code: "LI", flag: "/media/svg/flags/134-liechtenstein.svg" },
  { code: "RU", flag: "/media/svg/flags/248-russia.svg" },
  { code: "AF", flag: "/media/svg/flags/111-afghanistan.svg" },
  { code: "AL", flag: "/media/svg/flags/099-albania.svg" },
  { code: "AX", flag: "/media/svg/flags/087-aland-islands.svg" },
  { code: "DZ", flag: "/media/svg/flags/144-algeria.svg" },
  { code: "AS", flag: "/media/svg/flags/027-american-samoa.svg" },
  { code: "AD", flag: "/media/svg/flags/045-andorra.svg" },
  { code: "AO", flag: "/media/svg/flags/117-angola.svg" },
  { code: "AI", flag: "/media/svg/flags/025-anguilla.svg" },
  { code: "AQ", flag: "/media/svg/flags/000-antarctic.svg" },
  { code: "AG", flag: "/media/svg/flags/075-antigua-and-barbuda.svg" },
  { code: "AR", flag: "/media/svg/flags/198-argentina.svg" },
  { code: "AM", flag: "/media/svg/flags/108-armenia.svg" },
  { code: "AW", flag: "/media/svg/flags/042-aruba.svg" },
  { code: "AU", flag: "/media/svg/flags/234-australia.svg" },
  { code: "AT", flag: "/media/svg/flags/003-austria.svg" },
  { code: "AZ", flag: "/media/svg/flags/141-azerbaijan.svg" },
  { code: "BH", flag: "/media/svg/flags/138-bahrain.svg" },
  { code: "BS", flag: "/media/svg/flags/120-bahamas.svg" },
  { code: "BD", flag: "/media/svg/flags/147-bangladesh.svg" },
  { code: "BB", flag: "/media/svg/flags/084-barbados.svg" },
  { code: "BY", flag: "/media/svg/flags/135-belarus.svg" },
  { code: "BZ", flag: "/media/svg/flags/078-belize.svg" },
  { code: "BJ", flag: "/media/svg/flags/060-benin.svg" },
  { code: "BM", flag: "/media/svg/flags/081-bermuda.svg" },
  { code: "BT", flag: "/media/svg/flags/040-bhutan.svg" },
  { code: "BO", flag: "/media/svg/flags/150-bolivia.svg" },
  { code: "BQ", flag: "/media/svg/flags/072-bonaire.svg" },
  { code: "BA", flag: "/media/svg/flags/132-bosnia-and-herzegovina.svg" },
  { code: "BW", flag: "/media/svg/flags/263-botswana.svg" },
  { code: "BV", flag: "/media/svg/flags/143-norway.svg" },
  { code: "BR", flag: "/media/svg/flags/255-brazil.svg" },
  { code: "IO", flag: "/media/svg/flags/069-british-indian-ocean-territory.svg" },
  { code: "VG", flag: "/media/svg/flags/114-british-virgin-islands.svg" },
  { code: "BN", flag: "/media/svg/flags/119-brunei.svg" },
  { code: "BG", flag: "/media/svg/flags/168-bulgaria.svg" },
  { code: "BF", flag: "/media/svg/flags/090-burkina-faso.svg" },
  { code: "BI", flag: "/media/svg/flags/057-burundi.svg" },
  { code: "CV", flag: "/media/svg/flags/038-cape-verde.svg" },
  { code: "KH", flag: "/media/svg/flags/159-cambodia.svg" },
  { code: "CM", flag: "/media/svg/flags/105-cameroon.svg" },
  { code: "CA", flag: "/media/svg/flags/243-canada.svg" },
  { code: "KY", flag: "/media/svg/flags/051-cayman-islands.svg" },
  { code: "CF", flag: "/media/svg/flags/036-central-african-republic.svg" },
  { code: "TD", flag: "/media/svg/flags/066-chad.svg" },
  { code: "CL", flag: "/media/svg/flags/131-chile.svg" },
  { code: "CN", flag: "/media/svg/flags/034-china.svg" },
  { code: "HK", flag: "/media/svg/flags/183-hong-kong.svg" },
  { code: "MO", flag: "/media/svg/flags/167-macao.svg" },
  { code: "CX", flag: "/media/svg/flags/017-christmas-island.svg" },
  { code: "CC", flag: "/media/svg/flags/023-cocos-island.svg" },
  { code: "CO", flag: "/media/svg/flags/177-colombia.svg" },
  { code: "KM", flag: "/media/svg/flags/029-comoros.svg" },
  { code: "CG", flag: "/media/svg/flags/157-republic-of-the-congo.svg" },
  { code: "CK", flag: "/media/svg/flags/021-cook-islands.svg" },
  { code: "CR", flag: "/media/svg/flags/156-costa-rica.svg" },
  { code: "HR", flag: "/media/svg/flags/164-croatia.svg" },
  { code: "CU", flag: "/media/svg/flags/153-cuba.svg" },
  { code: "CW", flag: "/media/svg/flags/116-curacao.svg" },
  { code: "CY", flag: "/media/svg/flags/264-cyprus" },
  { code: "CZ", flag: "/media/svg/flags/149-czech-republic.svg" },
  { code: "CI", flag: "/media/svg/flags/265-cote-d-ivoire.svg" },
  { code: "KP", flag: "/media/svg/flags/030-north-korea.svg" },
  { code: "CD", flag: "/media/svg/flags/249-democratic-republic-of-congo.svg" },
  { code: "DK", flag: "/media/svg/flags/174-denmark.svg" },
  { code: "DJ", flag: "/media/svg/flags/068-djibouti.svg" },
  { code: "DM", flag: "/media/svg/flags/186-dominica.svg" },
  { code: "DO", flag: "/media/svg/flags/047-dominican-republic.svg" },
  { code: "EC", flag: "/media/svg/flags/104-ecuador.svg" },
  { code: "EG", flag: "/media/svg/flags/158-egypt.svg" },
  { code: "SV", flag: "/media/svg/flags/015-el-salvador.svg" },
  { code: "GQ", flag: "/media/svg/flags/189-equatorial-guinea.svg" },
  { code: "ER", flag: "/media/svg/flags/065-eritrea.svg" },
  { code: "EE", flag: "/media/svg/flags/008-estonia.svg" },
  { code: "SZ", flag: "/media/svg/flags/266-eswatini.svg" },
  { code: "ET", flag: "/media/svg/flags/005-ethiopia.svg" },
  { code: "FK", flag: "/media/svg/flags/215-falkland-islands.svg" },
  { code: "FO", flag: "/media/svg/flags/267-faroe-islands" },
  { code: "FJ", flag: "/media/svg/flags/137-fiji.svg" },
  { code: "FI", flag: "/media/svg/flags/125-finland.svg" },
  { code: "GF", flag: "/media/svg/flags/268-french-guiana" },
  { code: "PF", flag: "/media/svg/flags/180-french-polynesia.svg" },
  { code: "TF", flag: "/media/svg/flags/269-french-southern-and-antarctic-lands" },
  { code: "GA", flag: "/media/svg/flags/059-gabon.svg" },
  { code: "GM", flag: "/media/svg/flags/146-gambia.svg" },
  { code: "GE", flag: "/media/svg/flags/256-georgia.svg" },
  { code: "GH", flag: "/media/svg/flags/053-ghana.svg" },
  { code: "GI", flag: "/media/svg/flags/213-gibraltar.svg" },
  { code: "GR", flag: "/media/svg/flags/170-greece.svg" },
  { code: "GL", flag: "/media/svg/flags/113-greenland.svg" },
  { code: "GD", flag: "/media/svg/flags/210-grenada.svg" },
  { code: "GP", flag: "/media/svg/flags/270-guadeloupe.svg" },
  { code: "GU", flag: "/media/svg/flags/207-guam.svg" },
  { code: "GT", flag: "/media/svg/flags/098-guatemala.svg" },
  { code: "GG", flag: "/media/svg/flags/204-guernsey.svg" },
  { code: "GN", flag: "/media/svg/flags/110-guinea.svg" },
  { code: "GW", flag: "/media/svg/flags/056-guinea-bissau.svg" },
  { code: "GY", flag: "/media/svg/flags/271-guyana" },
  { code: "HT", flag: "/media/svg/flags/185-haiti.svg" },
  { code: "HM", flag: "/media/svg/flags/234-australia.svg" },
  { code: "VA", flag: "/media/svg/flags/272-vatican.svg" },
  { code: "HN", flag: "/media/svg/flags/024-honduras.svg" },
  { code: "HU", flag: "/media/svg/flags/115-hungary.svg" },
  { code: "IS", flag: "/media/svg/flags/080-iceland.svg" },
  { code: "IN", flag: "/media/svg/flags/246-india.svg" },
  { code: "ID", flag: "/media/svg/flags/209-indonesia.svg" },
  { code: "IR", flag: "/media/svg/flags/136-iran.svg" },
  { code: "IQ", flag: "/media/svg/flags/020-iraq.svg" },
  { code: "IE", flag: "/media/svg/flags/179-ireland.svg" },
  { code: "IM", flag: "/media/svg/flags/219-isle-of-man.svg" },
  { code: "IL", flag: "/media/svg/flags/155-israel.svg" },
  { code: "JM", flag: "/media/svg/flags/037-jamaica.svg" },
  { code: "JP", flag: "/media/svg/flags/063-japan.svg" },
  { code: "JE", flag: "/media/svg/flags/245-jersey.svg" },
  { code: "JO", flag: "/media/svg/flags/077-jordan.svg" },
  { code: "KZ", flag: "/media/svg/flags/074-kazakhstan.svg" },
  { code: "KE", flag: "/media/svg/flags/067-kenya.svg" },
  { code: "KI", flag: "/media/svg/flags/261-kiribati.svg" },
  { code: "KW", flag: "/media/svg/flags/273-kuwait.svg" },
  { code: "KG", flag: "/media/svg/flags/152-kyrgyzstan.svg" },
  { code: "LA", flag: "/media/svg/flags/112-laos.svg" },
  { code: "LV", flag: "/media/svg/flags/044-latvia.svg" },
  { code: "LB", flag: "/media/svg/flags/018-lebanon.svg" },
  { code: "LS", flag: "/media/svg/flags/176-lesotho.svg" },
  { code: "LR", flag: "/media/svg/flags/169-liberia.svg" },
  { code: "LY", flag: "/media/svg/flags/231-libya.svg" },
  { code: "LT", flag: "/media/svg/flags/064-lithuania.svg" },
  { code: "MG", flag: "/media/svg/flags/242-madagascar.svg" },
  { code: "MW", flag: "/media/svg/flags/214-malawi.svg" },
  { code: "MY", flag: "/media/svg/flags/118-malasya.svg" },
  { code: "MV", flag: "/media/svg/flags/225-maldives.svg" },
  { code: "ML", flag: "/media/svg/flags/173-mali.svg" },
  { code: "MT", flag: "/media/svg/flags/194-malta.svg" },
  { code: "MH", flag: "/media/svg/flags/103-marshall-island.svg" },
  { code: "MQ", flag: "/media/svg/flags/201-martinique.svg" },
  { code: "MR", flag: "/media/svg/flags/050-mauritania.svg" },
  { code: "MU", flag: "/media/svg/flags/001-mauritius.svg" },
  { code: "YT", flag: "/media/svg/flags/274-mayotte.svg" },
  { code: "MX", flag: "/media/svg/flags/252-mexico.svg" },
  { code: "FM", flag: "/media/svg/flags/046-micronesia.svg" },
  { code: "MC", flag: "/media/svg/flags/039-monaco.svg" },
  { code: "MN", flag: "/media/svg/flags/258-mongolia.svg" },
  { code: "ME", flag: "/media/svg/flags/239-montenegro.svg" },
  { code: "MS", flag: "/media/svg/flags/043-montserrat.svg" },
  { code: "MA", flag: "/media/svg/flags/166-morocco.svg" },
  { code: "MZ", flag: "/media/svg/flags/096-mozambique.svg" },
  { code: "MM", flag: "/media/svg/flags/058-myanmar.svg" },
  { code: "NA", flag: "/media/svg/flags/062-namibia.svg" },
  { code: "NR", flag: "/media/svg/flags/228-nauru.svg" },
  { code: "NP", flag: "/media/svg/flags/016-nepal.svg" },
  { code: "NL", flag: "/media/svg/flags/237-netherlands.svg" },
  { code: "NC", flag: "/media/svg/flags/275-new-caledonia.svg" },
  { code: "NZ", flag: "/media/svg/flags/121-new-zealand.svg" },
  { code: "NI", flag: "/media/svg/flags/007-nicaragua.svg" },
  { code: "NE", flag: "/media/svg/flags/222-niger.svg" },
  { code: "NG", flag: "/media/svg/flags/086-nigeria.svg" },
  { code: "NU", flag: "/media/svg/flags/182-niue.svg" },
  { code: "NF", flag: "/media/svg/flags/193-norfolk-island.svg" },
  { code: "MP", flag: "/media/svg/flags/160-northern-marianas-islands.svg" },
  { code: "NO", flag: "/media/svg/flags/143-norway.svg" },
  { code: "OM", flag: "/media/svg/flags/004-oman.svg" },
  { code: "PK", flag: "/media/svg/flags/100-pakistan.svg" },
  { code: "PW", flag: "/media/svg/flags/178-palau.svg" },
  { code: "PA", flag: "/media/svg/flags/106-panama.svg" },
  { code: "PG", flag: "/media/svg/flags/163-papua-new-guinea.svg" },
  { code: "PY", flag: "/media/svg/flags/041-paraguay.svg" },
  { code: "PE", flag: "/media/svg/flags/188-peru.svg" },
  { code: "PH", flag: "/media/svg/flags/192-philippines.svg" },
  { code: "PN", flag: "/media/svg/flags/095-pitcairn-islands.svg" },
  { code: "PL", flag: "/media/svg/flags/211-poland.svg" },
  { code: "PR", flag: "/media/svg/flags/028-puerto-rico.svg" },
  { code: "QA", flag: "/media/svg/flags/026-qatar.svg" },
  { code: "KR", flag: "/media/svg/flags/094-south-korea.svg" },
  { code: "MD", flag: "/media/svg/flags/212-moldova.svg" },
  { code: "RO", flag: "/media/svg/flags/109-romania.svg" },
  { code: "RW", flag: "/media/svg/flags/206-rwanda.svg" },
  { code: "RE", flag: "/media/svg/flags/276-reunion.svg" },
  { code: "BL", flag: "/media/svg/flags/277-saint-barthelemy.svg" },
  { code: "SH", flag: "/media/svg/flags/260-united-kingdom.svg" },
  { code: "KN", flag: "/media/svg/flags/033-saint-kitts-and-nevis.svg" },
  { code: "LC", flag: "/media/svg/flags/172-st-lucia.svg" },
  { code: "MF", flag: "/media/svg/flags/195-france.svg" },
  { code: "PM", flag: "/media/svg/flags/278-saint-pierre-and-miquelon" },
  { code: "VC", flag: "/media/svg/flags/279-saint-vincent-and-the-grenadines" },
  { code: "WS", flag: "/media/svg/flags/251-samoa.svg" },
  { code: "SM", flag: "/media/svg/flags/097-san-marino.svg" },
  { code: "ST", flag: "/media/svg/flags/012-sao-tome-and-prince.svg" },
  { code: "SA", flag: "/media/svg/flags/133-saudi-arabia.svg" },
  { code: "SN", flag: "/media/svg/flags/227-senegal.svg" },
  { code: "RS", flag: "/media/svg/flags/071-serbia.svg" },
  { code: "SC", flag: "/media/svg/flags/253-seychelles.svg" },
  { code: "SL", flag: "/media/svg/flags/092-sierra-leone.svg" },
  { code: "SG", flag: "/media/svg/flags/230-singapore.svg" },
  { code: "SX", flag: "/media/svg/flags/089-sint-maarten.svg" },
  { code: "SK", flag: "/media/svg/flags/091-slovakia.svg" },
  { code: "SI", flag: "/media/svg/flags/010-slovenia.svg" },
  { code: "SB", flag: "/media/svg/flags/085-solomon-islands.svg" },
  { code: "SO", flag: "/media/svg/flags/083-somalia.svg" },
  { code: "ZA", flag: "/media/svg/flags/200-south-africa.svg" },
  { code: "GS", flag: "/media/svg/flags/280-south-georgia-and-the-south-sandwich-islands.svg" },
  { code: "SS", flag: "/media/svg/flags/244-south-sudan.svg" },
  { code: "LK", flag: "/media/svg/flags/127-sri-lanka.svg" },
  { code: "PS", flag: "/media/svg/flags/208-palestine.svg" },
  { code: "SD", flag: "/media/svg/flags/199-sudan.svg" },
  { code: "SR", flag: "/media/svg/flags/076-suriname.svg" },
  { code: "SJ", flag: "/media/svg/flags/143-norway.svg" },
  { code: "SE", flag: "/media/svg/flags/184-sweden.svg" },
  { code: "SY", flag: "/media/svg/flags/022-syria.svg" },
  { code: "TJ", flag: "/media/svg/flags/196-tajikistan.svg" },
  { code: "TH", flag: "/media/svg/flags/238-thailand.svg" },
  { code: "MK", flag: "/media/svg/flags/236-republic-of-macedonia.svg" },
  { code: "TL", flag: "/media/svg/flags/140-east-timor.svg" },
  { code: "TG", flag: "/media/svg/flags/073-togo.svg" },
  { code: "TK", flag: "/media/svg/flags/235-tokelau.svg" },
  { code: "TO", flag: "/media/svg/flags/191-tonga.svg" },
  { code: "TT", flag: "/media/svg/flags/181-trinidad-and-tobago.svg" },
  { code: "TN", flag: "/media/svg/flags/049-tunisia.svg" },
  { code: "TR", flag: "/media/svg/flags/218-turkey.svg" },
  { code: "TM", flag: "/media/svg/flags/229-turkmenistan.svg" },
  { code: "TC", flag: "/media/svg/flags/223-turks-and-caicos.svg" },
  { code: "TV", flag: "/media/svg/flags/221-tuvalu.svg" },
  { code: "UG", flag: "/media/svg/flags/009-uganda.svg" },
  { code: "UA", flag: "/media/svg/flags/145-ukraine.svg" },
  { code: "AE", flag: "/media/svg/flags/151-united-arab-emirates.svg" },
  { code: "TZ", flag: "/media/svg/flags/006-tanzania.svg" },
  { code: "TW", flag: "/media/svg/flags/202-taiwan.svg" },
  { code: "UM", flag: "/media/svg/flags/226-united-states.svg" },
  { code: "US", flag: "/media/svg/flags/226-united-states.svg" },
  { code: "UY", flag: "/media/svg/flags/088-uruguay.svg" },
  { code: "UZ", flag: "/media/svg/flags/190-uzbekistn.svg" },
  { code: "VU", flag: "/media/svg/flags/187-vanuatu.svg" },
  { code: "VE", flag: "/media/svg/flags/139-venezuela.svg" },
  { code: "VN", flag: "/media/svg/flags/220-vietnam.svg" },
  { code: "VI", flag: "/media/svg/flags/217-virgin-islands.svg" },
  { code: "WF", flag: "/media/svg/flags/195-france.svg" },
  { code: "EH", flag: "/media/svg/flags/203-sahrawi-arab-democratic-republic.svg" },
  { code: "YE", flag: "/media/svg/flags/232-yemen.svg" },
  { code: "ZM", flag: "/media/svg/flags/032-zambia.svg" },
  { code: "ZW", flag: "/media/svg/flags/011-zimbabwe.svg" },
];

export const { COUNTRY_CODES, countryFlagByCodeMap } = COUNTRIES_INFO.reduce(
  (acc, { code, flag }) => {
    const flagValue = flag.endsWith(".svg") ? flag : undefined;

    acc.COUNTRY_CODES.push(code);
    acc.countryFlagByCodeMap.set(code, flagValue);

    return acc;
  },
  { COUNTRY_CODES: [], countryFlagByCodeMap: new Map() } as {
    COUNTRY_CODES: string[];
    countryFlagByCodeMap: Map<string, string | undefined>;
  }
);

export interface ILaneDefinition {
  id: string;
  title: string;
  color: string;
  faIconName: string;
}

export const LANES_DEFINITION: ILaneDefinition[] = [
  {
    id: ETaskStatus.PLANNED,
    title: TASK_STATUS_I18N_TITLE.PLANNED,
    color: TASK_STATUS_COLOR.PLANNED,
    faIconName: TASK_STATUS_ICON_NAME.PLANNED,
  },
  {
    id: ETaskStatus.IN_PROGRESS,
    title: TASK_STATUS_I18N_TITLE.IN_PROGRESS,
    color: TASK_STATUS_COLOR.IN_PROGRESS,
    faIconName: TASK_STATUS_ICON_NAME.IN_PROGRESS,
  },
  {
    id: ETaskStatus.STUCK,
    title: TASK_STATUS_I18N_TITLE.STUCK,
    color: TASK_STATUS_COLOR.STUCK,
    faIconName: TASK_STATUS_ICON_NAME.STUCK,
  },
  {
    id: ETaskStatus.DONE,
    title: TASK_STATUS_I18N_TITLE.DONE,
    color: TASK_STATUS_COLOR.DONE,
    faIconName: TASK_STATUS_ICON_NAME.DONE,
  },
];

export const TASK_SORTER_OPTION = [
  { type: "DUE_DATE", label: "TASK.LABEL.DATE.DUE" },
  { type: "UPDATED_AT", label: "COMMON.DATE.UPDATED" },
];

export const URL_LABEL = {
  youtube: {
    logo: "/media/logos/black/youtube.png",
  },
  facebook: {
    logo: "/media/logos/black/facebook.png",
  },
  instagram: {
    logo: "/media/logos/black/instagram.svg",
  },
  linkedin: {
    logo: "/media/logos/black/linkedin.png",
  },
  twitter: {
    logo: "/media/logos/black/twitter.svg",
    label: "Twitter",
  },
  crunchbase: {
    logo: "/media/logos/black/crunchbase.svg",
  },
  "y.at": {
    logo: "/media/logos/black/y.at.png",
  },
  neon: {
    logo: "/media/logos/black/neon.png",
  },
  medium: {
    logo: "/media/logos/black/medium.png",
  },
};

export const ENTITY_ICON: Record<string, { icon: string; color: string }> = {
  PROJECT: { icon: "layer-group", color: "#4BADE8" },
  PRODUCT: { icon: "home", color: "#64BA3C" },
  LEAD: { icon: "fire", color: "#904EE2" },
  USER: { icon: "user", color: "#865439" },
};

export const ENTITY_TYPE_PATH: Record<string, string> = {
  PROJECT: "projects",
  PRODUCT: "products",
  LEAD: "leads",
  USER: "users",
  SUBCONTRACTOR: "subcontractors",
  CLIENT: "clients",
  TASK: "tasks",
  BUDGET: "budgets",
};

export const PARKING_TYPE = [
  { label: "PROPERTY.TYPES.INDOOR_PARKING", key: "INDOOR" },
  { label: "PROPERTY.TYPES.OUTDOOR_PARKING", key: "OUTDOOR" },
];

export const OUTDOOR_STATUS = [
  { label: "COMMON.PRIVATE", key: "PRIVATE" },
  { label: "COMMON.COMMON", key: "COMMON" },
];
