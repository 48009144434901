import React from "react";
import { formatDisplayName } from "../../_utils/userUtils";
import { ColumnFormatter } from "react-bootstrap-table-next";

interface RowProps {
  createdByUser: string;
}

export const CreatedByColumnFormatter: ColumnFormatter<RowProps, any, string> = (cell, row) => (
  <span>{!!row.createdByUser ? formatDisplayName(row.createdByUser, false) : "-"}</span>
);
