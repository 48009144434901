import * as React from "react";
import { useEffect, useMemo, useState } from "react";
import { useFormikContext } from "formik";
import { useTranslation } from "../../../../../hooks/useTranslation";
import { KycFormElementBaseLayout } from "../KycControlsBaseLayout";
import { DataCyCompPrefix, KycChoiceOption } from "../../../Kyc";
import { KycFieldLock } from "../KycFieldLock";
import Select from "react-select";
import objectPath from "object-path";
import { useHtmlClassService } from "../../../../../../_metronic/layout";
import { KycInfoBox, KycInfoLevel } from "../../KycInfoBox";
import { isEqual } from "lodash";
import { formatDataCy } from "../../../KycUtils";
import { KycValidationMessage } from "../../KycValidationMessage";
import { KycTranslation } from "../../../KycTranslation";
import { useKycFlow } from "../../../../../hooks/useKycFlow";
import { Spinner } from "react-bootstrap";

type KycDropdownSelectFormFieldProps = {
  field: any;
  className?: string;
  isLoading?: boolean;
  name: string;
  options: any[];
  onChange: any;
  label?: string;
  disabled: boolean;
  locked?: boolean;
  noEntriesLabel?: string;
  translateLabels?: boolean;
  translateRegion?: boolean;
  inline?: boolean;
  isSectionChild?: boolean;
  isUploadMode?: boolean;
  placeholder?: string;
  clearable?: boolean;
  indent?: boolean;
  customStyle?: any;
  infoLabel?: string;
  infoLabelTextAlign?: string;
  disableLabel?: boolean;
  isRequired?: boolean;
  incremental?: boolean;
  classNameLabel?: string;
  renderAsLabel?: boolean;
};
export const KycDropdownSelect = ({
  field,
  options,
  onChange,
  label = "",
  disabled,
  noEntriesLabel = "COMMON.NO_OPTION_ENTRIES",
  isLoading = false,
  translateLabels = false,
  translateRegion = false,
  inline = true,
  isSectionChild = false,
  isUploadMode = false,
  placeholder,
  clearable = true,
  indent = true,
  customStyle,
  infoLabel,
  infoLabelTextAlign,
  disableLabel = false,
  locked = false,
  isRequired = false,
  incremental = true,
  classNameLabel = "",
  renderAsLabel = false,
  ...props
}: KycDropdownSelectFormFieldProps): JSX.Element => {
  const { values } = useFormikContext();
  const { customHandleChange } = useKycFlow();

  const [selectedOption, setSelectedOption] = useState<KycChoiceOption | null>(null);
  const { fmo, fmodn } = useTranslation();
  const [preprocessedOptions, setPreprocessedOptions] = useState<KycChoiceOption[]>([]);

  const uiService = useHtmlClassService();
  const layoutProps = useMemo(() => {
    return {
      colorsThemeLightPrimary: objectPath.get(uiService.config, "js.colors.theme.light.primary"),
      colorsThemeBasePrimary: objectPath.get(uiService.config, "js.colors.theme.base.primary"),
      colorsGrayGray400: objectPath.get(uiService.config, "js.colors.gray.gray400"),
    };
  }, [uiService]);

  useEffect(() => {
    const propertyValue = field.value;
    if (propertyValue) {
      const preSetOption = preprocessedOptions?.find((option) => {
        return !!(
          (option?.value?.id && isEqual(option?.value?.id, propertyValue)) ||
          isEqual(option?.value, propertyValue)
        );
      });

      if (preSetOption && !isEqual(selectedOption, preSetOption)) {
        setSelectedOption(preSetOption);
      }
    } else if (preprocessedOptions?.length === 1) {
      // handleChange({
      //   target: {
      //     name: field.name,
      //     value: preprocessedOptions[0]?.value,
      //   },
      // });
    } else {
      setSelectedOption(null);
    }
  }, [values, preprocessedOptions]);

  useEffect(() => {
    if (translateRegion) {
      setPreprocessedOptions(fmodn(options));
    } else if (translateLabels) {
      setPreprocessedOptions(fmo(options));
    } else {
      setPreprocessedOptions(options);
    }
  }, [options]);

  const handleChange = (e: any) => {
    const targetField = { target: { name: field.name, value: e?.value || null } };
    if (incremental) {
      customHandleChange(targetField, true, true);
    } else {
      onChange(targetField);
    }
  };

  const customStyles: any = {
    menu: (provided: any, state: any) => ({
      ...provided,
      color: state.selectProps.menuColor,
      zIndex: 9999,
    }),

    menuPortal: (provided: any) => ({ ...provided, zIndex: 9999 }),
    control: (provided: any, state: any) => ({
      ...provided,
      border: "1px solid #E1DFE0 !important",
      ...customStyle,
    }),

    singleValue: (provided: any, state: any) => {
      const opacity = state.isDisabled ? 0.5 : 1;
      const transition = "opacity 300ms";
      return { ...provided, opacity, transition };
    },
  };

  const dropdown = (
    <div data-cy={formatDataCy(DataCyCompPrefix.SELECT, field.name)}>
      <Select
        menuPortalTarget={document.body}
        isClearable={clearable}
        styles={customStyles}
        theme={(theme) => ({
          ...theme,
          colors: {
            ...theme.colors,
            primary25: layoutProps.colorsThemeLightPrimary,
            primary: layoutProps.colorsThemeBasePrimary,
          },
        })}
        placeholder=""
        hideSelectedOptions={false}
        isDisabled={disabled || isLoading}
        value={selectedOption}
        name={field.name}
        options={preprocessedOptions}
        classNamePrefix="kyc-select"
        onChange={handleChange}
      />
    </div>
  );

  const content = (
    <>
      {renderAsLabel ? (
        <KycFieldLock field={{ name: field.name, value: selectedOption?.label }} locked={locked}>
          <div className="d-flex align-items-center">
            <div data-cy={formatDataCy(DataCyCompPrefix.SELECT, field.name)}>{dropdown}</div>
            {isLoading && <Spinner animation="border" variant="primary" className="ml-3" />}
          </div>
        </KycFieldLock>
      ) : (
        <KycFieldLock field={{ name: field.name, value: selectedOption?.label }} locked={locked}>
          <div data-cy={formatDataCy(DataCyCompPrefix.SELECT, field.name)}>{dropdown}</div>
        </KycFieldLock>
      )}
    </>
  );

  return (
    <>
      <KycFormElementBaseLayout
        disableLabel={disableLabel}
        field={field}
        content={content}
        disabled={disabled}
        label={label}
        inline={inline}
        isSectionChild={isSectionChild}
        className={classNameLabel}
        renderAsLabel={renderAsLabel}
      />
      <KycValidationMessage
        isRequired={isRequired}
        field={field}
        level={KycInfoLevel.WARN}
        message={KycTranslation.ERROR_MANDATORY_FIELD_DROPDOWN}
      />
      {infoLabel && (
        <KycInfoBox label={infoLabel} className="mb-4" textAlign={infoLabelTextAlign} />
      )}
    </>
  );
};
