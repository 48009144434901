import { API } from "@aws-amplify/api";

import { uploadFile } from "app/_utils/fileUtils";
import { FileStatus } from "../../../../../data/schemas";

export async function createProduct(product) {
  const body = { ...product };
  return await API.post("API", "/products", { body });
}

export async function updateProduct(product) {
  const body = { ...product };
  return await API.post("API", `/products/${product.id}`, { body });
}

export async function getProductById(id) {
  return await API.get("API", `/products/${id}`, {});
}

export async function findProducts(queryParams) {
  const response = {
    data: {
      items: [],
    },
  };
  if (!!queryParams?.projectId) {
    const apiResponse = await API.get("API", "/products", {
      queryStringParameters: {
        projectId: queryParams.projectId,
      },
    });
    console.log("apiResponse:", apiResponse);
    response.data.items = apiResponse;
  } else {
    const apiResponse = await API.get("API", "/products", {});
    console.log("apiResponse:", apiResponse);
    response.data.items = apiResponse;
  }
  return response;
}

export async function deleteProduct(id) {
  return await API.del("API", `/products/${id}`, {});
}

export const createProductFile = async (file) => {
  return await API.post("API", `/products/${file.productId}/files`, {
    body: { ...file, status: FileStatus.UPLOADED },
  });
};

export const uploadProductFile = async (file, sessionId, setProgress) => {
  return await uploadFile(
    file,
    sessionId,
    {
      type: "PRODUCT",
      id: file.productId,
    },
    setProgress
  );
};

export const updateFile = async (file) => {
  return await API.post("API", `/products/${file.productId}/files/${file.id}`, {
    body: file,
  });
};

export const getFile = async (file) => {
  return await API.get("API", `/products/${file.productId}/files/${file.id}`, {});
};

export const deleteFile = async (file) => {
  return await API.del("API", `/products/${file.productId}/files/${file.id}`, {});
};

export const getPhotosByProductId = async (productId) => {
  return await API.get("API", `/products/${productId}/photos`, {});
};

export const createPhoto = async (photo) => {
  const { productId, typePhoto, file } = photo;
  const response = await API.post("API", `/products/${productId}/photos`, {
    body: { typePhoto, isPrivate: false },
  });
  try {
    const fetchResponse = await fetch(response.url, {
      method: "PUT",
      body: file,
      headers: {
        "Content-Type": "",
      },
    });
    console.log("fetchResponse:", fetchResponse);
    return response;
  } catch (e) {
    console.log("axios error:");
    console.log(e);
  }
};

export const updatePhoto = async (photo) => {
  return await API.post("API", `/products/${photo.productId}/photos/${photo.id}`, {
    body: { ...photo },
  });
};

export async function updatePhotosOrder(productId, photosOrder) {
  return await API.post("API", `/products/${productId}/photos/ordering`, {
    body: { photosOrder },
  });
}

export const deletePhoto = async ({ productId, photoId }) => {
  return await API.del("API", `/products/${productId}/photos/${photoId}`, {});
};

export const createPhotoSharing = async (photoSharing) => {
  return await API.post(
    "API",
    `/products/${photoSharing.productId}/photos/${photoSharing.photoId}/sharing`,
    {
      body: photoSharing,
    }
  );
};

export const deletePhotoSharing = async ({ productId, photoId, userId }) => {
  return await API.del("API", `/products/${productId}/photos/${photoId}/sharing/${userId}`, {});
};
