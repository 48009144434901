import { FORM_INIT, KYC_BENEFICIAL_OWNER, KYC_CLIENT_LEGAL, KYC_CLIENT_NATURAL } from "./KycUtils";
import { KycClientLead, KycFile, KycLegalEntityType } from "./Kyc";
import { IDocument } from "@cyntler/react-doc-viewer";
import { IFile, IUser } from "../../../data/schemas";

export const mapKycClient = (client: IUser, fDisplayName: Function, lead?: KycClientLead) => {
  return {
    ...KYC_CLIENT_LEGAL,
    ...KYC_CLIENT_NATURAL,
    id: client?.id,
    firstName: client?.firstName,
    lastName: client?.lastName,
    title: client?.title,
    displayName: fDisplayName(client, true),
    legalEntityType: client?.legalEntityType,
    lead: lead ? { ...lead } : FORM_INIT().form?.client?.lead,
    company: client?.company,
    privateAddress: client?.legalEntityType === KycLegalEntityType.NATURAL ? client?.address : "",
    address: client?.legalEntityType === KycLegalEntityType.LEGAL ? client?.address : "",
    email: client?.email,
    mobile: client?.mobile,
  };
};

export const mapCompany = (c: any) => {
  return { label: c?.companyName || c?.name, value: c };
};

export const mapBo = (user: IUser, fDisplayName: Function) => {
  return {
    ...KYC_BENEFICIAL_OWNER,
    id: user?.id,
    firstName: user?.firstName,
    lastName: user?.lastName,
    title: user?.title,
    displayName: fDisplayName(user, true),
    email: user?.email,
    mobile: user?.mobile,
    privateAddress: user?.address,
    //TODO: currently no distinction between private and professional address for client-user in kodehyve os
    // -> default address from client-user is mapped to private address
    // professionalAddress: user?.address,
    address: user?.address,
  };
};

export const mapFileOwner = (user: any, fDisplayName: Function) => {
  return {
    id: user.id,
    firstName: user.firstName,
    lastName: user.lastName,
    title: user.title,
    displayName: fDisplayName(user, true),
    legalEntityType: user.legalEntityType,
    companyName: user?.company?.name,
    mobile: user.mobile,
    email: user.email,
  };
};

const mapFileToDoc = (file?: KycFile | IFile): any => {
  return {
    uri: file?.url?.includes("PutObject") ? "" : file?.url,
    fileType: file?.mimeType,
    fileName: file?.friendlyName || file?.name,
    lang: file?.lang,
  };
};

export const mapFilesToDoc = (files?: any[]): IDocument[] | undefined => {
  if (!files) {
    return;
  }

  const docs = [];

  for (const f of files) {
    if (f?.url) {
      docs.push(mapFileToDoc(f));
    }
  }
  return docs;
};
