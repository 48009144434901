import * as React from "react";
import { Card, CardBody } from "_metronic/_partials/controls";

interface SignatureContentInfoCardProps {
  children: React.ReactNode;
}

export const SignatureContentInfoCard: React.FunctionComponent<SignatureContentInfoCardProps> = ({
  children,
}) => (
  <Card>
    <CardBody
      style={{ minHeight: "40vh", minWidth: "40vw" }}
      className={"d-flex align-items-center justify-content-center"}
    >
      {children}
    </CardBody>
  </Card>
);

export default SignatureContentInfoCard;
