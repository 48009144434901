import { KycFile } from "./Kyc";
import { filter, groupBy, head, isArray, mapValues, orderBy, tail } from "lodash-es";
import { IntlShape } from "react-intl";
import { FileStatus } from "../../../data/schemas";

export const handleDownload = (fileUrl: string, fileName: string) => {
  const downloadUrl = fileUrl;
  const link = document.createElement("a");
  link.href = downloadUrl;
  link.setAttribute("download", fileName);
  document.body.appendChild(link);
  link.click();
};

export const getFileInFilesByBucketFileId = (files: any[], fileId: string) => {
  const indexOfFileUrl = files?.findIndex((file: KycFile) => file?.id === fileId);
  return files?.[indexOfFileUrl];
};

export const formatDisplayLang = (langCode: string): string => {
  if (!langCode) {
    return "";
  }
  return langCode?.split("-")?.[0]?.toUpperCase();
};

export const getFilesForKycFile = (f: KycFile, files?: KycFile[]) => {
  if (!files) {
    return;
  }
  const processedFiles = [];
  if (isArray(f)) {
    processedFiles.push(...getLanguageFilesOfKycFileItem(files, f));
  } else if (f?.url) {
    processedFiles.push(f);
  } else if (f?.de || f?.fr || f?.en) {
    processedFiles.push(...getLanguageFilesOfKycFileItem(files, Object.values(f)));
  }
  return processedFiles;
};

export const getLanguageFilesOfKycFileItem = (files: any[], kycFileItem: any[]) => {
  return kycFileItem?.map((file: any) => {
    if (file?.url) {
      return file;
    } else if (file?.id) {
      return getFileInFilesByBucketFileId(files, file.id);
    }
    return undefined;
  });
};

export const findAndReplaceFile = (files: KycFile[], file: KycFile) => {
  const indexOfItem = files?.findIndex((item) => item?.id === file?.id);
  const updatedItems = [...files];
  if (indexOfItem === -1) {
    updatedItems.push(file);
    return updatedItems;
  }
  updatedItems[indexOfItem] = file;
  return updatedItems;
};

export const findAndDeleteFile = (files: any[], file: KycFile) => {
  const indexOfItem = files?.findIndex((item) => item?.id === file?.id);
  if (indexOfItem === -1) {
    return files;
  }
  const updatedItems = [...files];
  updatedItems.splice(indexOfItem, 1);
  return updatedItems;
};

export const findAndDeleteFileById = (files: any[], fileId: string) => {
  const indexOfItem = files?.findIndex((item) => item?.id === fileId);
  if (indexOfItem === -1) {
    return files;
  }
  const updatedItems = [...files];
  updatedItems.splice(indexOfItem, 1);
  return updatedItems;
};

const UNSUPPORTED_FILE_EXTENSIONS = ["docx"];

export const isPreviewPossible = (filesToDisplay: any) => {
  let isFileExtensionSupported = true;

  if (isArray(filesToDisplay)) {
    for (const f of filesToDisplay) {
      if (UNSUPPORTED_FILE_EXTENSIONS.includes(f?.fileExtension)) {
        isFileExtensionSupported = false;
        return;
      }
    }
    return isFileExtensionSupported;
  } else if (filesToDisplay?.fileExtension) {
    isFileExtensionSupported = !UNSUPPORTED_FILE_EXTENSIONS.includes(filesToDisplay.fileExtension);
  } else {
    isFileExtensionSupported = false;
  }

  return isFileExtensionSupported;
};

export const isEvidenceOptionSet = (files: KycFile[], option: string): boolean => {
  if (!files || !isArray(files)) {
    return false;
  }
  return files.find((file) => file?.option === option) !== undefined;
};

export const isFileUploaded = (file: KycFile): boolean => {
  return [FileStatus.UPLOADED_TO_S3, FileStatus.UPLOADED].includes(file?.status);
};

export const isGeneratedFile = (file: KycFile) => {
  return file?.categoryId && ["boDeclaration", "kycDocument"].includes(file?.categoryId ?? "");
};

const groupByCategoryAndKycRelatedToEntity = (files: any[]) => {
  const groupedByCategory = files.reduce((acc: Record<string, any[]>, file: any) => {
    const categoryId = file.categoryId;

    if (categoryId === "boDeclaration") {
      const kycRelatedToEntityId = file.kycRelatedToEntity.id;

      if (!acc[kycRelatedToEntityId]) {
        acc[kycRelatedToEntityId] = [];
      }

      acc[kycRelatedToEntityId].push(file);
    } else {
      if (!acc[categoryId]) {
        acc[categoryId] = [];
      }

      acc[categoryId].push(file);
    }

    return acc;
  }, {});

  return groupedByCategory;
};

export const getGroupedFiles = (files: any[], intl: IntlShape): any => {
  if (!files || !isArray(files)) {
    return [];
  }

  //Note: the kyc package (=zip file) is not shown in the file list on purpose.
  //the package can be downloaded using 3-dots menu instead
  const filesWithoutLang = files?.filter(
    (file: KycFile) => !file?.lang && file?.id !== "kycPackage"
  );

  const groupedByCategory = groupByCategoryAndKycRelatedToEntity(filter(files, (f) => f.lang));
  const multiFiles = [...filesWithoutLang];

  const getFilesLogicallyOrderedByLang = (result: any) => {
    const primaryLang = intl?.locale;

    switch (primaryLang) {
      case "en-GB":
        return [result[primaryLang], result["fr-FR"], result["de-DE"]];
      case "de-DE":
        return [result[primaryLang], result["en-GB"], result["fr-FR"]];
      case "fr-FR":
        return [result[primaryLang], result["en-GB"], result["de-DE"]];
      default:
        return [result[primaryLang], result["en-GB"], result["fr-FR"]];
    }
  };

  const olderFilesToDelete: any[] = [];
  Object.values(groupedByCategory).forEach((groupedFile: any) => {
    const sourceFile = groupedFile[0];
    const filesPerLang = groupBy(groupedFile, "lang");

    // Iterate over each key in the object
    // Iterate over each key in the object
    const result: any = mapValues(filesPerLang, (array) => {
      // Sort the array in descending order based on updatedAt
      const sortedArray = orderBy(array, ["updatedAt"], ["desc"]);
      // Get the first object (most recent) from the sorted array
      const mostRecent = head(sortedArray);
      // Get the older objects by excluding the most recent one
      olderFilesToDelete.push(...tail(sortedArray));
      return mostRecent;
    });

    const multiFile: any = {
      ...sourceFile,
      files: getFilesLogicallyOrderedByLang(result),
    };
    multiFiles.push(multiFile);
  });

  return { multiFiles, olderFilesToDelete };
};
