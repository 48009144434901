import React from "react";
import { ColumnFormatter } from "react-bootstrap-table-next";
import { IntlShape } from "react-intl";
import { IEvent } from "../../../../../_components/AuditTrail/models";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../../../_metronic/_helpers";
import cn from "clsx";
import { getColorForKycEvent, KYC_EVENT_TYPES } from "../../../KycUtils";

interface FormatExtraDataProps {
  intl: IntlShape;
  events: IEvent[];
}

interface RowProps {
  id: string;
}

export const KycAuditTrailDetailColumnFormatter: ColumnFormatter<RowProps, FormatExtraDataProps> = (
  cell,
  row,
  rowIndex,
  formatExtraData: any
) => {
  const event: any = row;
  const intl: IntlShape = formatExtraData?.intl;

  return (
    <>
      {/* event icon */}
      <div className="d-flex align-items-center">
        <div className="mr-4">
          {event?.eventName && KYC_EVENT_TYPES?.[event?.eventName] && (
            <span className={cn("svg-icon svg-icon-2x", getColorForKycEvent(event?.eventName))}>
              <SVG src={toAbsoluteUrl(KYC_EVENT_TYPES[event.eventName])} />
            </span>
          )}
        </div>
        {/* event content*/}
        {event?.content?.[intl.locale]}
      </div>
    </>
  );
};
