// Form is based on Formik
// Data validation is based on Yup
// Please, be familiar with article first:
// https://hackernoon.com/react-form-validation-with-formik-and-yup-8b76bda62e10
import * as React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import * as Yup from "yup";
import { cloneDeep } from "lodash";
import { Field, FieldArray, Form, Formik, FormikHandlers } from "formik";
import { Button, ButtonGroup } from "react-bootstrap";
import { NumberFormatValues } from "react-number-format";

import {
  ICombinePlotDetails,
  IProduct,
  IPropertyDetails,
  IPropertyDetailsBase,
  IPropertyParkingDetails,
  IPropertySurfaceDetails,
} from "data/schemas";

import { Input, Select } from "_metronic/_partials/controls";

import { NumberInput } from "app/_utils/formUtils";
import { HEATING_TYPES, PROPERTY_TYPES } from "app/_utils/listUtils";

import { TextEditor } from "app/_components/TextEditor/TextEditor";
import { commentToolbar } from "app/_components/TextEditor/config";

import { useEntityDeleteDialogContext } from "app/modules/PropertiesManagement/pages/entity-delete-dialog/EntityDeleteDialogContext";

import {
  FormSection,
  FormSectionField,
  FormSectionRegistryRow,
  FormSectionRow,
} from "./FormSection";
import { CombinesPlotsFields, OutdoorSurfaceFields, ParkingFields } from "./CustomFields";
import { CustomCard } from "../../../../../../_components/CustomCard";
import {
  ParkingType,
  SurfaceStatus,
  SurfaceType,
} from "../../../../../../_utils/propertyDetailsUtils";
import { sum } from "lodash-es";

//----------------------------------------------------------------------------//

const defaultItem: IPropertyDetailsBase = {
  parcel: "",
  landRegistryNumber: "",
  landArchitectNumber: "",
  thousands: 0,
  surface: 0,
  numberBlockStaircaseLevelCadastreCellar: "",
};

const defaultParking: IPropertyParkingDetails = {
  ...defaultItem,
  type: ParkingType.INDOOR,
  price: 0,
};

const defaultSurface: IPropertySurfaceDetails = {
  ...defaultItem,
  status: SurfaceStatus.COMMON,
};

const defaultCustomItem: ICombinePlotDetails = {
  ...defaultItem,
  surfaceTypes: [],
};

const MAX_SQM = 1000000000;
const YUP_MAX_SQM = Yup.number().max(MAX_SQM);

//----------------------------------------------------------------------------//

interface IInputField extends HTMLElement {
  name: string;
  value: any;
}

interface INumberFieldChangeHandlerParam {
  name: string;
  handleChange: FormikHandlers["handleChange"];
  defaultValue?: number;
  disabled?: boolean;
}

interface IBaseEntityButtonClickHandlerParam {
  values: IProduct;
  entityType: SurfaceType;
}

interface IRemoveLineButtonClickHandlerParam extends IBaseEntityButtonClickHandlerParam {
  index: number;
  remove<T>(index: number): T | undefined;
  entityTypeDialog?: string;
}

interface IAddEntityButtonClickHandlerParam extends IBaseEntityButtonClickHandlerParam {
  push: (obj: any) => void;
}

interface IButtonClickFieldChangeHandlerParam {
  name: string;
  value?: string;
}

interface AddLineParam {
  addLine: Function;
  values: IProduct;
  push: Function;
  entityType: SurfaceType;
}

//----------------------------------------------------------------------------//

export interface ProductEditInformationFormProps {
  submitProduct: Function;
  saveProductFields: (name: string, value: string) => void;
  product: IProduct;
  disabled: boolean;
  isLoading?: boolean;
}

export const ProductEditInformationForm: React.FunctionComponent<ProductEditInformationFormProps> =
  ({ submitProduct, saveProductFields, product, disabled }) => {
    const intl = useIntl();

    const { setDeleteEntityDialog } = useEntityDeleteDialogContext();

    const { SUFFIX_UNIT_AREA, ProductEditSchema } = React.useMemo(() => {
      const YUP_MIN_1_MAX_150_CHARS = Yup.string()
        .min(1, `${intl.formatMessage({ id: "AUTH.VALIDATION.MIN_CHARACTER" })} 1`)
        .max(150, `${intl.formatMessage({ id: "AUTH.VALIDATION.MAX_CHARACTER" })} 150`);

      return {
        SUFFIX_UNIT_AREA: intl.formatMessage({ id: "COMMON.AREA.UNIT" }),
        // Validation schema
        ProductEditSchema: Yup.object().shape({
          propertyDetails: Yup.object().shape({
            rooms: YUP_MAX_SQM,
            bedrooms: YUP_MAX_SQM,
            bathrooms: YUP_MAX_SQM,

            livingSurface: YUP_MAX_SQM,
            acre: YUP_MAX_SQM,

            gardenSurface: YUP_MAX_SQM,
            balconySurface: YUP_MAX_SQM,
            terraceSurface: YUP_MAX_SQM,

            floor: YUP_MIN_1_MAX_150_CHARS,

            energyPerformanceClass: YUP_MIN_1_MAX_150_CHARS,
            environmentalPerformanceClass: YUP_MIN_1_MAX_150_CHARS,
            thermalInsulationClass: YUP_MIN_1_MAX_150_CHARS,
          }),
        }),
      };
    }, []);

    //--------------------------------------------------------------------------//

    const customFieldChangeHandler = (fieldName: string, fieldValue: any) => {
      if (saveProductFields !== undefined && fieldName) {
        saveProductFields(fieldName, fieldValue);
      }
    };

    const fieldChangeHandler =
      <T extends IInputField>(handleChange: FormikHandlers["handleChange"]) =>
      (e: React.ChangeEvent<T>) => {
        handleChange(e);
        customFieldChangeHandler(e.target.name, e.target.value);
      };

    const numberFieldChangeHandler =
      ({
        name,
        defaultValue = undefined,
        handleChange,
        disabled = false,
      }: INumberFieldChangeHandlerParam) =>
      ({ floatValue: fieldValue = defaultValue }: NumberFormatValues) => {
        handleChange({
          target: {
            name,
            value: fieldValue,
          },
        });

        if (disabled) {
          debugger;
          return;
        }

        customFieldChangeHandler(name, fieldValue);
      };

    //--------------------------------------------------------------------------//

    const removeLine =
      ({ values, index, remove, entityType }: IRemoveLineButtonClickHandlerParam) =>
      () => {
        remove(index);

        const clonedList = cloneDeep(values?.propertyDetails?.[entityType]);
        Array.isArray(clonedList) && clonedList.length && clonedList.splice(index, 1);

        customFieldChangeHandler(`propertyDetails.${entityType}`, clonedList);
      };

    const removeLineButtonClickHandler =
      ({
        values,
        index,
        remove,
        entityType,
        entityTypeDialog,
      }: IRemoveLineButtonClickHandlerParam) =>
      (event: React.MouseEvent<HTMLButtonElement>) => {
        event.stopPropagation();

        setDeleteEntityDialog({
          entityType: entityTypeDialog,
          onDelete: removeLine({ values, index, remove, entityType }),
        });
      };

    const addEntityButtonClickHandler =
      ({ values, push, entityType }: IAddEntityButtonClickHandlerParam) =>
      (_: React.MouseEvent<HTMLButtonElement>) => {
        let defaultItem: any = defaultSurface;
        if (entityType === SurfaceType.PARKINGS) {
          defaultItem = defaultParking;
        }
        if (entityType === SurfaceType.COMBINE_PLOTS) {
          defaultItem = defaultCustomItem;
        }
        push(defaultItem);
        const clonedList = cloneDeep(values?.propertyDetails?.[entityType])!;
        clonedList.push(defaultItem as any);

        customFieldChangeHandler(`propertyDetails.${entityType}`, clonedList);
      };

    const buttonClickFieldChangeHandler =
      ({ name, value = "" }: IButtonClickFieldChangeHandlerParam) =>
      (_: React.MouseEvent<HTMLButtonElement>) => {
        customFieldChangeHandler(name, value);
      };

    //--------------------------------------------------------------------------//

    const onSubmitHandler = () => {
      if (!disabled && !!submitProduct) {
        submitProduct();
      }
    };

    //--------------------------------------------------------------------------//
    const AddLine = ({ addLine, values, push, entityType }: AddLineParam) => (
      <>
        {!disabled && (
          <div className="d-flex justify-content-end">
            <button
              type="button"
              className="btn btn-sm btn-light flex-grow-1 rounded-0 d-flex align-items-center  justify-content-center"
              onClick={addLine({ values, push, entityType })}
            >
              <i className="ki ki-plus icon-nm" />
              <FormattedMessage id="LEAD.ACTION.ADD.LINE" />
            </button>
          </div>
        )}
      </>
    );

    const [totalThousands, setTotalThousands] = React.useState(0);

    React.useEffect(() => {
      if (product?.propertyDetails) {
        const { thousands, parkings, cellars, gardens, terraces, balconies, combinePlots } =
          product.propertyDetails;
        setTotalThousands(
          (thousands ?? 0) +
            sum(
              [parkings, cellars, gardens, terraces, balconies, combinePlots].map((data) => {
                let res = 0;
                for (const element of data ?? []) {
                  res += element.thousands ?? 0;
                }
                return res;
              })
            )
        );
      }
    }, [
      product?.propertyDetails?.thousands,
      product?.propertyDetails?.parkings,
      product?.propertyDetails?.cellars,
      product?.propertyDetails?.gardens,
      product?.propertyDetails?.terraces,
      product?.propertyDetails?.balconies,
      product?.propertyDetails?.combinePlots,
    ]);

    return !product ? (
      <div className="d-flex align-items-center justify-content-center">
        <div className="spinner spinner-lg spinner-primary h-30px w-30px" />
      </div>
    ) : (
      <Formik
        enableReinitialize={true}
        initialValues={product}
        validationSchema={ProductEditSchema}
        onSubmit={onSubmitHandler}
      >
        {({ handleChange, values }) => (
          <Form className="form form-label-right">
            {/* @begin: info */}
            <FormSection>
              <FormSectionRow>
                {/* @begin: propertyType */}
                <FormSectionField>
                  <Select
                    name="propertyDetails.propertyType"
                    label={intl.formatMessage({
                      id: "PRODUCT.TYPE",
                    })}
                    onChange={fieldChangeHandler(handleChange)}
                    disabled={disabled}
                    customFeedbackLabel={true}
                  >
                    {Object.keys(PROPERTY_TYPES).map((value) => (
                      <option key={value} value={value}>
                        {intl.formatMessage({ id: (PROPERTY_TYPES as any)?.[value] })}
                      </option>
                    ))}
                  </Select>
                </FormSectionField>
                {/* @end: propertyType */}

                {/* @begin: heatingType */}
                <FormSectionField>
                  <Select
                    name="propertyDetails.heatingType"
                    label={intl.formatMessage({
                      id: "PRODUCT.INFO.HEATING_TYPE",
                    })}
                    onChange={fieldChangeHandler(handleChange)}
                    disabled={disabled}
                    customFeedbackLabel={true}
                  >
                    {Object.keys(HEATING_TYPES).map((value) => (
                      <option key={value} value={value}>
                        {intl.formatMessage({ id: (HEATING_TYPES as any)[value] })}
                      </option>
                    ))}
                  </Select>
                </FormSectionField>
                {/* @end: heatingType */}

                {/* @begin: acre */}
                <FormSectionField label={<FormattedMessage id="PRODUCT.INFO.ACRE" />}>
                  <NumberInput
                    className="form-control text-right"
                    value={values?.propertyDetails?.acre}
                    onValueChange={numberFieldChangeHandler({
                      name: "propertyDetails.acre",
                      defaultValue: 0,
                      handleChange,
                    })}
                    decimalScale={2}
                    disabled={disabled}
                    placeholder=""
                  />
                </FormSectionField>
                {/* @end: acre */}
              </FormSectionRow>

              {/* @begin: livingSurface | thousands | landArchitectNumber | landRegistryNumber */}
              <FormSectionRegistryRow
                surface={{
                  label: "PRODUCT.INFO.LIVING_SURFACE",
                  value: values?.propertyDetails?.livingSurface,
                  onValueChange: numberFieldChangeHandler({
                    name: "propertyDetails.livingSurface",
                    defaultValue: 0,
                    handleChange,
                  }),
                }}
                thousands={{
                  value: values?.propertyDetails?.thousands,
                  onValueChange: numberFieldChangeHandler({
                    name: "propertyDetails.thousands",
                    defaultValue: 0,
                    handleChange,
                  }),
                }}
                landArchitectNumber={"propertyDetails.landArchitectNumber"}
                landRegistryNumber={"propertyDetails.landRegistryNumber"}
                onFieldChange={fieldChangeHandler(handleChange)}
                suffix={{ surface: SUFFIX_UNIT_AREA }}
                disabled={disabled}
              />
              {/* @end: livingSurface | thousands | landArchitectNumber | landRegistryNumber */}

              <FormSectionRow>
                {/* @begin: numberBlockStaircaseLevelCadastreCellar */}
                <FormSectionField className={"col-sm-6 col-md-5 mt-3"}>
                  <Field
                    name={"propertyDetails.numberBlockStaircaseLevelCadastreCellar"}
                    onChange={fieldChangeHandler(handleChange)}
                    label={intl.formatMessage({
                      id: "PRODUCT.INFO.NUMBER_BLOCK_STAIRCASE",
                    })}
                    component={Input}
                    disabled={disabled}
                  />
                </FormSectionField>
                {/* @end: numberBlockStaircaseLevelCadastreCellar */}

                {/* @begin: parcel */}
                <FormSectionField>
                  <Field
                    name={"propertyDetails.parcel"}
                    component={Input}
                    onChange={fieldChangeHandler(handleChange)}
                    label={intl.formatMessage({
                      id: "PRODUCT.INFO.LOT",
                    })}
                    disabled={disabled}
                  />
                </FormSectionField>
                {/* @end: parcel */}

                {/* @begin: total totalThousands */}

                <FormSectionField
                  label={<FormattedMessage id="PRODUCT.INFO.TOTAL_THOUSANDS" />}
                  className={"col-sm-6 col-md-3 mt-3"}
                >
                  <NumberInput
                    className="form-control text-right"
                    value={totalThousands}
                    disabled={true}
                  />
                </FormSectionField>

                {/* @end: totalThousands */}
              </FormSectionRow>
            </FormSection>
            {/* @end: info */}

            {/* @begin: floor and rooms */}
            <FormSection title={<FormattedMessage id={"PROPERTY.INFO.FLOOR_AND_ROOMS"} />}>
              <FormSectionRow>
                {[
                  { label: "PRODUCT.INFO.ROOMS", key: "rooms" },
                  { label: "PRODUCT.INFO.BEDROOMS", key: "bedrooms" },
                  { label: "PRODUCT.INFO.BATHROOMS", key: "bathrooms" },
                  { label: "PRODUCT.INFO.FLOOR", key: "floor" },
                ].map((data, index) => (
                  <FormSectionField key={index} label={<FormattedMessage id={data.label} />}>
                    <NumberInput
                      className={"form-control text-right"}
                      value={values?.propertyDetails?.[data.key as keyof IPropertyDetails] ?? 0}
                      onValueChange={numberFieldChangeHandler({
                        name: `propertyDetails.${data.key}`,
                        handleChange,
                      })}
                      decimalScale={0}
                      disabled={disabled}
                      placeholder=""
                    />
                  </FormSectionField>
                ))}
              </FormSectionRow>
            </FormSection>
            {/* @end: floor and rooms */}

            {/* @begin: parking */}
            <>
              <CustomCard
                header={
                  <h4>
                    <FormattedMessage id={"PROPERTY.INFO.PARKING"} />
                    {` (${values?.propertyDetails?.parkings?.length ?? 0})`}
                  </h4>
                }
                collapsable={true}
                isByDefaultCollapse={true}
                headerClassName={"bg-light-o-5 border-0"}
                parentClassName={"border-0 w-100"}
              >
                <FieldArray name={"propertyDetails.parkings"}>
                  {({ remove, push }) => (
                    <div>
                      {values?.propertyDetails?.parkings?.map((parking, index) => (
                        <div key={index}>
                          <ParkingFields
                            {...{
                              parking,
                              parentName: "propertyDetails",
                              disabled,
                              index,
                              handle: {
                                handleChange,
                                fieldChangeHandler,
                                numberFieldChangeHandler,
                              },
                              removeParking: (e: React.MouseEvent<HTMLButtonElement>) =>
                                removeLineButtonClickHandler({
                                  values,
                                  index,
                                  remove,
                                  entityType: SurfaceType.PARKINGS,
                                  entityTypeDialog: `${SurfaceType.PARKINGS.toUpperCase()}.SINGULAR`,
                                })(e),
                            }}
                          />
                        </div>
                      ))}
                      <AddLine
                        {...{
                          addLine: addEntityButtonClickHandler,
                          values,
                          push,
                          entityType: SurfaceType.PARKINGS,
                        }}
                      />
                    </div>
                  )}
                </FieldArray>
              </CustomCard>
              <hr className={"my-8"} />
            </>
            {/* @end: parking */}

            {/* @begin: cellar|garden|terrace|balcony */}
            {[
              SurfaceType.CELLARS,
              SurfaceType.GARDENS,
              SurfaceType.TERRACES,
              SurfaceType.BALCONIES,
            ].map((surfaceType, index2) => (
              <div key={index2}>
                <CustomCard
                  header={
                    <h4>
                      <FormattedMessage id={`PRODUCT.INFO.${surfaceType.toUpperCase()}.SINGULAR`} />
                      {` (${values?.propertyDetails?.[surfaceType]?.length ?? 0})`}
                    </h4>
                  }
                  collapsable={true}
                  isByDefaultCollapse={true}
                  headerClassName={"bg-light-o-5 border-0"}
                  parentClassName={"border-0 w-100"}
                >
                  <FieldArray name={`propertyDetails.${surfaceType}`}>
                    {({ remove, push }) => (
                      <div>
                        {(
                          (values?.propertyDetails?.[surfaceType] ??
                            []) as IPropertySurfaceDetails[]
                        ).map((entity, index) => (
                          <div key={index}>
                            <OutdoorSurfaceFields
                              {...{
                                entity,
                                parentName: `propertyDetails.${surfaceType}`,
                                disabled,
                                index,
                                handle: {
                                  handleChange,
                                  fieldChangeHandler,
                                  numberFieldChangeHandler,
                                },
                                removeEntity: (e: React.MouseEvent<HTMLButtonElement>) =>
                                  removeLineButtonClickHandler({
                                    values,
                                    index,
                                    remove,
                                    entityType: surfaceType,
                                    entityTypeDialog: `${surfaceType.toUpperCase()}.SINGULAR`,
                                  })(e),
                              }}
                            />
                          </div>
                        ))}

                        <AddLine
                          {...{
                            addLine: addEntityButtonClickHandler,
                            values,
                            push,
                            entityType: surfaceType,
                          }}
                        />
                      </div>
                    )}
                  </FieldArray>
                </CustomCard>
                <hr className={"my-8"} />
              </div>
            ))}
            {/* @end: cellar|garden|terrace|balcony */}

            {/* @begin: combinePlots */}
            <>
              <CustomCard
                header={
                  <h4>
                    <FormattedMessage id={`PRODUCT.INFO.COMBINE_PLOTS.SINGULAR`} />
                    {` (${values?.propertyDetails?.combinePlots?.length ?? 0})`}
                  </h4>
                }
                collapsable={true}
                isByDefaultCollapse={true}
                headerClassName={"bg-light-o-5 border-0"}
                parentClassName={"border-0 w-100"}
              >
                <FieldArray name={`propertyDetails.combinePlots`}>
                  {({ remove, push }) => (
                    <div>
                      {values?.propertyDetails?.combinePlots?.map((entity, index) => (
                        <div key={index}>
                          <CombinesPlotsFields
                            {...{
                              disabled,
                              parentName: "propertyDetails",
                              index,
                              entity,
                              handle: {
                                handleChange,
                                fieldChangeHandler,
                                numberFieldChangeHandler,
                              },
                              removeEntity: (e: React.MouseEvent<HTMLButtonElement>) =>
                                removeLineButtonClickHandler({
                                  values,
                                  index,
                                  remove,
                                  entityType: SurfaceType.COMBINE_PLOTS,
                                  entityTypeDialog: "COMBINES_PLOTS.SINGULAR",
                                })(e),
                            }}
                          />
                        </div>
                      ))}

                      <AddLine
                        {...{
                          addLine: addEntityButtonClickHandler,
                          values,
                          push,
                          entityType: SurfaceType.COMBINE_PLOTS,
                        }}
                      />
                    </div>
                  )}
                </FieldArray>
              </CustomCard>
              <hr className={"my-8"} />
            </>
            {/* @end: combinePlots */}

            <FormSection
              title={<FormattedMessage id={"PROPERTY.INFO.SPECIFICATION"} />}
              renderDivider={false}
            >
              {/* @begin: specification */}
              <FormSectionRow className="form-group mt-3">
                <TextEditor
                  name={"propertyDetails.specification"}
                  data={values?.propertyDetails?.specification!}
                  saveMethod={saveProductFields}
                  disabled={disabled}
                  toolbarOption={commentToolbar}
                  data-cy={"input-product-details-specification"}
                />
              </FormSectionRow>
              {/* @end: specification */}

              {/* @begin: performance classes */}
              <FormSectionRow className="form-group form-row d-flex justify-content-around">
                {[
                  {
                    label: "PRODUCT.INFO.ENERGY_PERFORMANCE_CLASS",
                    key: "energyPerformanceClass",
                  },
                  {
                    label: "PRODUCT.INFO.ENVIRONMENTAL_PERFORMANCE_CLASS",
                    key: "environmentalPerformanceClass",
                  },
                  {
                    label: "PRODUCT.INFO.THERMAL_INSULATION_CLASS",
                    key: "thermalInsulationClass",
                  },
                ].map((data, index) => (
                  <FormSectionField
                    label={<FormattedMessage id={data.label} />}
                    className={"my-2"}
                    key={index}
                  >
                    <div className={"d-flex"}>
                      <ButtonGroup>
                        {["A+", "A", "B", "C", "D", "E", "F", "G", "H", "I"].map((value) => (
                          <Button
                            className={"p-3"}
                            variant={
                              values?.propertyDetails?.[data.key as keyof IPropertyDetails] ===
                              value
                                ? "primary"
                                : "outline-secondary"
                            }
                            key={value}
                            disabled={disabled}
                            onClick={buttonClickFieldChangeHandler({
                              name: `propertyDetails.${data.key}`,
                              value,
                            })}
                          >
                            {value}
                          </Button>
                        ))}
                      </ButtonGroup>

                      <ButtonGroup className={"ml-2"}>
                        <Button
                          className={"p-3"}
                          variant={
                            !values?.propertyDetails?.[data.key as keyof IPropertyDetails]
                              ? "primary"
                              : "outline-secondary"
                          }
                          disabled={disabled}
                          onClick={buttonClickFieldChangeHandler({
                            name: `propertyDetails.${data.key}`,
                          })}
                        >
                          N/A
                        </Button>
                      </ButtonGroup>
                    </div>
                  </FormSectionField>
                ))}
              </FormSectionRow>
              {/* @end: performance classes */}
            </FormSection>
          </Form>
        )}
      </Formik>
    );
  };

export default ProductEditInformationForm;
