import {
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Input,
  Select,
} from "../../../../../../_metronic/_partials/controls";
import { FormattedMessage, useIntl } from "react-intl";
import React, { useEffect, useMemo } from "react";
import { Field, Form, Formik } from "formik";
import { TITLES } from "../../../../../_utils/listUtils";
import { AddressEditForm } from "../../../../../_components/AddressEditForm";
import { cloneDeep, get, mergeWith } from "lodash-es";
import { PersonalInfoFilloutFormValidationSchema } from "../../../../../_validation/PersonalInfoFilloutFormValidationSchema";
import { useFormsUIContext } from "../FormsUIProvider";

const initForm = {
  fields: {
    id: undefined,
    firstName: "",
    lastName: "",
    address: {
      addressLine1: "",
      addressLine2: "",
      city: "",
      stateOrRegion: "",
      postalCode: "",
      countryCode: "",
    },
  },
};

const mergeWithInitForm = (obj) => {
  return mergeWith(cloneDeep(initForm?.fields), obj, (dest, src) =>
    src === null ? dest : undefined
  );
};

export const PersonalInfoFilloutCard = ({
  session,
  submitForm,
  saveFormFields,
  disabled,
  title,
  showOnly,
}) => {
  const intl = useIntl();

  const formsUIContext = useFormsUIContext();
  const formsUiProps = useMemo(() => {
    return {
      formDataFields: formsUIContext.formData?.fields,
    };
  }, [formsUIContext]);

  const { formDataFields } = formsUiProps;

  const customHandleChange = (e) => (handleChange) => {
    if (!!saveFormFields) {
      handleChange(e);
      saveFormFields(e.target.name, e.target.value);
    }
  };

  useEffect(() => {
    if (!showOnly) {
      if (!!session) {
        const userFields = ["title", "firstName", "lastName"];
        const clientFields = [
          "address.addressLine1",
          "address.addressLine2",
          "address.city",
          "address.stateOrRegion",
          "address.postalCode",
          "address.countryCode",
        ];
        const fieldsToUpdate = [];
        const values = [];
        for (const field of userFields) {
          if (
            !get(formDataFields, field) &&
            !!get(session, field) &&
            get(formDataFields, field) !== get(session, field)
          ) {
            fieldsToUpdate.push(field);
            values.push(get(session, field));
          }
        }
        if (!!session) {
          for (const field of clientFields) {
            if (!get(formDataFields, field) && !!get(session, field)) {
              fieldsToUpdate.push(field);
              values.push(get(session, field));
            }
          }
        }
        saveFormFields(fieldsToUpdate, values);
      }
    }
  }, [session]);

  return (
    <Formik
      enableReinitialize={true}
      initialValues={mergeWithInitForm(formDataFields)}
      validationSchema={PersonalInfoFilloutFormValidationSchema(intl)}
      onSubmit={() => {
        if (!disabled) {
          submitForm();
        }
      }}
    >
      {({ handleChange, validateForm }) => (
        <>
          <Form className="form form-label-right" data-cy="form-personal-information">
            <Card>
              <CardHeader title={title ? intl.formatMessage({ id: title }) : ""} />
              <CardBody>
                <div className="form-group row">
                  <div className="col-sm-2">
                    <Select
                      name="title"
                      data-cy="select-user-title"
                      label={intl.formatMessage({
                        id: "USER.INFO.TITLE",
                      })}
                      onChange={(e) => customHandleChange(e)(handleChange)}
                      customFeedbackLabel={" "}
                      disabled={disabled}
                    >
                      {TITLES.map((userTitle) => {
                        const titleTranslated = intl.formatMessage({ id: userTitle.label });
                        return (
                          <option key={userTitle.value} value={userTitle.value}>
                            {titleTranslated}
                          </option>
                        );
                      })}
                    </Select>
                  </div>
                  <div className="col-sm-5">
                    <Field
                      data-cy="input-first-name"
                      name="firstName"
                      component={Input}
                      placeholder={intl.formatMessage({
                        id: "COMMON.NAME.FIRST",
                      })}
                      label={intl.formatMessage({
                        id: "COMMON.NAME.FIRST",
                      })}
                      onChange={(e) => customHandleChange(e)(handleChange)}
                      disabled={disabled}
                    />
                  </div>
                  <div className="col-sm-5">
                    <Field
                      data-cy="input-last-name"
                      name="lastName"
                      component={Input}
                      placeholder={intl.formatMessage({
                        id: "COMMON.NAME.LAST",
                      })}
                      label={intl.formatMessage({
                        id: "COMMON.NAME.LAST",
                      })}
                      onChange={(e) => customHandleChange(e)(handleChange)}
                      disabled={disabled}
                    />
                  </div>
                </div>

                <hr />
                <AddressEditForm
                  customHandleChange={customHandleChange}
                  handleChange={handleChange}
                  disabled={disabled}
                />
              </CardBody>
              <CardFooter className="d-flex justify-content-end">
                <div className="text-right">
                  <button
                    type="submit"
                    className="btn btn-primary ml-2"
                    onClick={() =>
                      validateForm().then((values) => {
                        if (!values) submitForm();
                      })
                    }
                    data-cy="button-next"
                  >
                    <FormattedMessage id="COMMON.ACTION.SUBMIT" />
                  </button>
                </div>
              </CardFooter>
            </Card>
          </Form>
        </>
      )}
    </Formik>
  );
};
