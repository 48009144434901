import { Field } from "formik";
import { KycDropdownSelect } from "../../controls/kyc-dropdown-select/KycDropdownSelect";
import { KycChoiceOption, KycLoading } from "../../../Kyc";
import { KycTranslation } from "../../../KycTranslation";
import React, { ChangeEvent, useEffect, useState } from "react";
import { useKycFlow } from "../../../../../hooks/useKycFlow";
import { useLoading } from "../../../../../hooks/useLoading";
import { useKyc } from "../../../../../hooks/useKyc";
import { get } from "lodash-es";

export const KycPropDevCompanyChooser = (): JSX.Element => {
  const { isCurrentlyLoading, addLoading } = useLoading();
  const { currentKycFlow } = useKyc();
  const { values, setFlowValue } = useKycFlow();

  const handleChangeKycCreationCompany = (e: ChangeEvent<any>) => {
    if (get(currentKycFlow, "propDevCompany")?.id !== e?.target?.value?.id) {
      setFlowValue("propDevCompany", e?.target?.value, true);
      addLoading(KycLoading.GENERATE_BO);
      addLoading(KycLoading.GENERATE_KYC_FLOW);
    }
  };

  const [optionsKycCreationCompany, setOptionsKycCreationCompany] = useState<KycChoiceOption[]>([]);

  const mapCompany = (c: any) => {
    return { label: c?.companyName || c?.name, value: c };
  };

  useEffect(() => {
    setOptionsKycCreationCompany(
      currentKycFlow?.kycFlowCompanies?.map((kycFlowCompany: any) => mapCompany(kycFlowCompany)) ??
        []
    );
  }, [currentKycFlow?.kycFlowCompanies]);

  return (
    <Field
      key={"select-kyc-creation-company-" + values?.form?.client?.lead?.id}
      component={KycDropdownSelect}
      name="propDevCompany.id"
      options={optionsKycCreationCompany}
      incremental={false}
      clearable={false}
      onChange={handleChangeKycCreationCompany}
      isLoading={
        isCurrentlyLoading(KycLoading.GENERATE_BO) &&
        isCurrentlyLoading(KycLoading.GENERATE_KYC_FLOW)
      }
      disabled={false}
      locked={false}
      label={KycTranslation.SELECT_PROPDEV_COMPANY}
      noEntriesLabel={KycTranslation.LEAD_NO_OPTION_ENTRIES}
      renderAsLabel={true}
      customStyle={{ minWidth: 250 }}
    />
  );
};
