import React from "react";
import { PaginationLinks } from "./PaginationLinks";
import { PaginationToolbar } from "./PaginationToolbar";
import cn from "clsx";

export function Pagination(props) {
  const { children, isLoading, paginationProps } = props;

  return (
    <>
      {children}
      <div className={cn("d-flex", "align-items-center", "flex-wrap", (!isLoading ? "justify-content-between" : "justify-content-center"))}>
        {!isLoading && 
          <PaginationLinks paginationProps={paginationProps} />
        }
        <PaginationToolbar isLoading={isLoading} paginationProps={paginationProps} />
      </div>
    </>
  );
}
