import { IntlShape } from "react-intl";
import { formatDisplayNameMeIntl } from "./userUtils";
import { sortBy } from "lodash";
import { ENTITY_ICON } from "./listUtils";
import { ILead, IProduct, IProject, IUser } from "../../data/schemas";
import { CLIENT, SUBCONTRACTOR, USER } from "./userTypes";
import { groupBy, isEmpty, keyBy } from "lodash-es";
import { LEAD, PRODUCT, PROJECT } from "./dataTypes";

interface Options {
  id: string;
  label: string;
  icon: string;
  color: string;
  options: any[];
}

interface Option {
  id: string;
  name: string;
  value?: any;
}

export interface DataSelectFields {
  projects: (IProject | Option)[];
  products: (IProduct | Option)[];
  leads: (ILead | Option)[];
  users: (IUser | Option)[];
  clients: (IUser | Option)[];
  subcontractors: (IUser | Option)[];
}

export const formatEntityNames = (intl: IntlShape, entityNames: any[]) => {
  return [
    ...entityNames.map((entity) => {
      const processedEntity = entity;

      if ([CLIENT, USER, SUBCONTRACTOR]?.includes(processedEntity?.userType)) {
        entity.type = USER;
      }

      if (entity.type === USER) {
        processedEntity.name = formatDisplayNameMeIntl(intl, entity);
      }
      processedEntity.typeIdTuple = `${entity.type}#${entity.id}`;
      return processedEntity;
    }),
  ];
};

export function formatDataSelect(
  data: DataSelectFields,
  intl: IntlShape,
  shouldTranslate: boolean = true
) {
  const response: { items: Options[] } = {
    items: [],
  };

  if (data.projects?.length > 0) {
    response.items = [
      {
        id: "projects",
        label: intl.formatMessage({ id: "PROJECT.TITLE" }),
        ...ENTITY_ICON.PROJECT,
        options: sortBy(
          [
            ...data.projects.map((i: any) => ({
              label: i.name,
              value: i.value ?? i,
              type: "PROJECT",
              ...ENTITY_ICON.PROJECT,
            })),
          ],
          "label"
        ),
      },
    ];
  }
  if (data.products?.length > 0) {
    response.items = [
      ...response.items,
      {
        id: "products",
        label: intl.formatMessage({ id: "PRODUCT.TITLE" }),
        ...ENTITY_ICON.PRODUCT,
        options: sortBy(
          [
            ...data.products?.map((i: any) => ({
              label: i.name,
              value: i.value ?? i,
              type: "PRODUCT",
              ...ENTITY_ICON.PRODUCT,
            })),
          ],
          "label"
        ),
      },
    ];
  }
  if (data.leads?.length > 0) {
    response.items = [
      ...response.items,
      {
        id: "leads",
        label: intl.formatMessage({ id: "LEAD.TITLE" }),
        ...ENTITY_ICON.LEAD,
        options: sortBy(
          [
            ...data.leads.map((i: any) => ({
              label: i.name,
              value: i.value ?? i,
              type: "LEAD",
              ...ENTITY_ICON.LEAD,
            })),
          ],
          "label"
        ),
      },
    ];
  }
  if (data.clients?.length > 0) {
    response.items = [
      ...response.items,
      {
        id: "clients",
        label: intl.formatMessage({ id: "CLIENT.TITLE" }),
        ...ENTITY_ICON.USER,
        options: sortBy(
          [
            ...data.clients.map((i: any) => ({
              label: shouldTranslate ? formatDisplayNameMeIntl(intl, i) : i.name,
              value: i.value ?? i,
              type: "CLIENT",
              ...ENTITY_ICON.USER,
            })),
          ],
          "label"
        ),
      },
    ];
  }
  if (data.users?.length > 0) {
    response.items = [
      ...response.items,
      {
        id: "users",
        label: intl.formatMessage({ id: "USER.TITLE" }),
        ...ENTITY_ICON.USER,
        options: sortBy(
          [
            ...data.users.map((i: any) => ({
              label: shouldTranslate ? formatDisplayNameMeIntl(intl, i) : i.name,
              value: i.value ?? i,
              type: "USER",
              ...ENTITY_ICON.USER,
            })),
          ],
          "label"
        ),
      },
    ];
  }
  if (data.subcontractors?.length > 0) {
    response.items = [
      ...response.items,
      {
        id: "subcontractors",
        label: intl.formatMessage({ id: "SUBCONTRACTOR.TITLE" }),
        ...ENTITY_ICON.USER,
        options: sortBy(
          [
            ...data.subcontractors.map((i: any) => ({
              label: shouldTranslate ? formatDisplayNameMeIntl(intl, i) : i.name,
              value: i.value ?? i,
              type: "SUBCONTRACTOR",
              ...ENTITY_ICON.USER,
            })),
          ],
          "label"
        ),
      },
    ];
  }

  return response;
}
interface Item {
  label: string;
  value: any;
  type: string;
}

interface ResponseItem {
  id: string;
  label: string;
  options: Item[];
}

export const formatDropdownOptions = (
  intl: IntlShape,
  options: any[],
  entityNames: any,
  key: string
) => {
  if (isEmpty(options) || isEmpty(entityNames)) {
    return [];
  }

  const tupleToEntity = keyBy(entityNames, key);
  const formattedOps = options.map((o) => {
    const formattedEntity = tupleToEntity[o.value];
    return {
      ...o,
      label: formattedEntity?.name ?? formattedEntity?.id,
      type: formattedEntity?.userTypes?.[0] ?? formattedEntity?.type,
    };
  });
  const formattedData = formatDataSelect2(formattedOps, intl);
  return formattedData.items;
};

export function formatDataSelect2(data: any[], intl: IntlShape): { items: ResponseItem[] } {
  const response: { items: ResponseItem[] } = { items: [] };

  const addGroup = (id: string, labelId: string, type: string, icon: any, dataList: any) => {
    const items = dataList?.[type] ?? [];
    if (items.length > 0) {
      response.items.push({
        id,
        label: intl.formatMessage({ id: labelId }),
        ...icon,
        options: items
          ?.map((i: any) => ({
            label: i.label,
            value: i.value,
            type,
            ...icon,
          }))
          .sort((a: any, b: any) => a.label.localeCompare(b.label)),
      });
    }
  };

  const dataGroupedByEntityType: any = groupBy(data, "type");

  addGroup("projects", "PROJECT.TITLE", PROJECT, ENTITY_ICON.PROJECT, dataGroupedByEntityType);
  addGroup("products", "PRODUCT.TITLE", PRODUCT, ENTITY_ICON.PRODUCT, dataGroupedByEntityType);
  addGroup("leads", "LEAD.TITLE", LEAD, ENTITY_ICON.LEAD, dataGroupedByEntityType);
  addGroup("clients", "CLIENT.TITLE", CLIENT, ENTITY_ICON.USER, dataGroupedByEntityType);
  addGroup("users", "USER.TITLE", USER, ENTITY_ICON.USER, dataGroupedByEntityType);
  addGroup(
    "subcontractors",
    "SUBCONTRACTOR.TITLE",
    SUBCONTRACTOR,
    ENTITY_ICON.USER,
    dataGroupedByEntityType
  );
  return response;
}
