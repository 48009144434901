/* eslint-disable jsx-a11y/anchor-is-valid */
import { ContentRoute } from "_metronic/layout";
import "_metronic/_assets/sass/pages/login/classic/login-1.scss";
import { BRAND_URL } from "configuration";

import { PublicRequestsProvider } from "../contexts/PublicRequestsContext";
import { KycFlowContainer } from "../../kyc-v2/KycFlowContainer";

import { KycAmlPage } from "./KycAmlPage";
import { SignaturePage } from "./SignaturePage";

export const RequestsPage = () => (
  <PublicRequestsProvider>
    <div className="d-flex flex-column flex-root">
      {/*begin::Login*/}
      <div
        className="login login-1 login-signin-on d-flex flex-column flex-lg-row flex-row-fluid bg-white"
        id="kt_login"
      >
        {/*begin::Content*/}
        <div className="flex-row-fluid d-flex flex-column position-relative p-7 overflow-hidden">
          {/* begin::Content body */}
          <div className="d-flex flex-column flex-column-fluid flex-center mb-10 mb-sm-20">
            <img
              src={`${BRAND_URL}/logo_dark.png`}
              alt="Logo"
              className="max-w-180px max-w-lg-200px max-h-120px mb-10 mb-sm-20"
            />

            <ContentRoute
              path="/requests/:requestId/signature"
              component={SignaturePage}
              children={undefined}
              render={undefined}
            />

            <ContentRoute
              path="/requests/:requestId/kyc-aml"
              component={KycAmlPage}
              children={undefined}
              render={undefined}
            />

            {/*TODO: remove when we manage the case to complete kyc with an client disabled*/}
            <ContentRoute
              path="/requests/v2/:requestId/kyc-aml"
              component={KycFlowContainer}
              children={undefined}
              render={undefined}
            />
          </div>
          {/*end::Content body*/}

          {/* begin::Mobile footer */}
          <div className="d-flex flex-column-auto align-items-center mt-5">
            <div className="text-dark-75 d-flex">
              <span className="text-muted font-weight-bold mr-2">
                {new Date().getFullYear()} &copy;
              </span>
              {` `}
              <a
                href="https://www.kodehyve.com/"
                rel="noopener noreferrer"
                target="_blank"
                className="text-dark-75 text-hover-primary pr-3 font-weight-bold"
              >
                kodehyve
              </a>
            </div>
          </div>
          {/* end::Mobile footer */}
        </div>
        {/*end::Content*/}
      </div>
      {/*end::Login*/}
    </div>
  </PublicRequestsProvider>
);
