import * as React from "react";

import {
  LeadsUIProvider,
  useLeadsUIContext,
} from "../../PropertiesManagement/pages/leads/LeadsUIContext";
import { canCreate, canReadAll } from "app/_utils/authUtils";

import { Filter } from "../../../_components/Filter";
import { LeadsTable } from "../../PropertiesManagement/pages/leads/leads-table/LeadsTable";
import { shallowEqual } from "react-redux";
import { useAppSelector } from "../../../../redux/hooks";
import { useCustomLocationState } from "../../../_utils/useCustomLocationState";
import { useParams } from "react-router-dom";
import { useSubheader } from "../../../../_metronic/layout";
import { useHistory } from "react-router-dom";
import ActionNewButton from "app/_components/ActionNewButton";

interface HistoryParams {
  id: string;
}

interface LeadTabProps {
  name: string;
  queryParam: Record<string, any>;
  context: string;
  readOnly: boolean;
}
export const LeadTab: React.FC<LeadTabProps> = ({ name, queryParam, context, readOnly = true }) => {
  const { setFromUrlTo } = useCustomLocationState();
  const subheader = useSubheader();

  const { id } = useParams<HistoryParams>();

  const { groups, session } = useAppSelector(
    (state) => ({
      groups: state.auth.groups,
      session: state.auth.session,
    }),
    shallowEqual
  );
  const history = useHistory();

  const leadsUIEvents = {
    openLeadPage: (lead: any) => {
      subheader.setTitle(lead.name);
      setFromUrlTo({
        url: `/leads/${lead.id}`,
        name: name,
      });
    },
    newLeadButtonClick: () => {
      if (context === "projectLeads") {
        history.push({
          pathname: "/leads/new/products",
          state: { projectId: queryParam.projectId },
        });
      } else if (context === "productLeads") {
        setFromUrlTo({ url: `/leads/new/clients?p=${queryParam.productId}` });
      } else if (context === "clientLeads") {
        setFromUrlTo({ url: `/leads/new/products?c=${queryParam.userId}` });
      }
    },
  };

  return (
    <LeadsUIProvider
      queryParamsInit={queryParam}
      leadsUIEvents={leadsUIEvents}
      readOnly={readOnly}
      context={context}
    >
      <div className="form-filtration">
        <div className="row align-items-center">
          <div className="col-md-6 margin-bottom-10-mobile">
            <Filter useEntityUIContext={useLeadsUIContext} />
          </div>
          <div className="col-md-2 margin-bottom-10-mobile" />
          <div className="col-md-4 text-right margin-bottom-10-mobile ">
            {id && canCreate(groups, session, "LEAD") && canReadAll(groups, session, "CLIENT") && (
              <ActionNewButton
                dataCy="new-lead-btn"
                className="mb-4 ml-2"
                onClick={leadsUIEvents.newLeadButtonClick}
                messageId="LEAD.ACTION.NEW"
              />
            )}
          </div>
        </div>
        <LeadsTable />
      </div>
    </LeadsUIProvider>
  );
};
