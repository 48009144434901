import * as React from "react";
import { useLeadContext } from "../../../../LeadContext";
import {
  CurrencyMetricCard,
  MetricCard,
  MetricCardProps,
} from "../../../../../../../../../_components/MetricCard";

export interface TaxesBreakdownProps {}

export const TaxesBreakdown: React.FC<TaxesBreakdownProps & MetricCardProps> = (props) => {
  const { leadInstalments, leadSupplements, settings } = useLeadContext();

  let totalTaxes = 0;
  let totalTaxesReducedVAT = 0;
  let totalTaxesDefaultVAT = 0;
  for (const item of [...leadInstalments, ...leadSupplements]) {
    totalTaxes += item.taxes ?? 0;
    totalTaxesReducedVAT += (item.amountReducedVAT ?? 0) * (settings?.reducedVat ?? 0);
    totalTaxesDefaultVAT += (item.amountDefaultVAT ?? 0) * (settings?.defaultVat ?? 0);
  }

  return (
    <CurrencyMetricCard
      title="LEAD.INSTALMENTS.TAXES_BREAKDOWN"
      dataCy="input-lead-instalments-total-taxes"
      value={totalTaxes}
      label="LEAD.INSTALMENTS.TOTAL_EXPECTED_TAXES"
      {...props}
    >
      <MetricCard.CurrencySubMetric
        dataCy="input-lead-instalments-total-taxes-reduced-vat"
        label="LEAD.INSTALMENTS.TOTAL_TAXES_AT_VAT"
        labelValues={{ vat: (settings?.reducedVat ?? 0) * 100 }}
        value={totalTaxesReducedVAT}
        secondValue={(totalTaxesReducedVAT / totalTaxes) * 100}
      />
      <MetricCard.CurrencySubMetric
        dataCy="input-lead-instalments-total-taxes-default-vat"
        label="LEAD.INSTALMENTS.TOTAL_TAXES_AT_VAT"
        labelValues={{ vat: (settings?.defaultVat ?? 0) * 100 }}
        value={totalTaxesDefaultVAT}
        secondValue={(totalTaxesDefaultVAT / totalTaxes) * 100}
      />
    </CurrencyMetricCard>
  );
};
