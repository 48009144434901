import React from "react";
import { WidgetUserList } from "../WidgetUserList";
import { ColumnFormatter } from "react-bootstrap-table-next";
import { IUser } from "../../../data/schemas";

interface FormatExtraDataProps {
  modalTitle: string;
}

interface RowProps {
  users: IUser[];
}

export const UserListAccessFormatter: ColumnFormatter<RowProps, FormatExtraDataProps> = (
  cell,
  row,
  rowIndex,
  formatExtraData
) =>
  formatExtraData && (
    <div onClick={(e) => e.stopPropagation()}>
      <WidgetUserList
        modalTitle={formatExtraData.modalTitle}
        users={row.users ?? []}
        displayLogo={false}
      />
    </div>
  );
