/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useEffect, useMemo } from "react";
import objectPath from "object-path";
import { useLocation } from "react-router-dom";
import { BreadCrumbs } from "./components/BreadCrumbs";
import { useSubheader } from "../../_core/MetronicSubheader";
import { useHtmlClassService } from "../../_core/MetronicLayout";
import { useSelector } from "react-redux";
import { useIntl } from "react-intl";
import { getRouteBreadcrumb } from "../../../../app/_utils/routeUtils";

export function SubHeader() {
  const uiService = useHtmlClassService();
  const location = useLocation();
  const subheader = useSubheader();
  const intl = useIntl();

  const layoutProps = useMemo(() => {
    return {
      config: uiService.config,
      subheaderMobileToggle: objectPath.get(uiService.config, "subheader.mobile-toggle"),
      subheaderCssClasses: uiService.getClasses("subheader", true),
      subheaderContainerCssClasses: uiService.getClasses("subheader_container", true),
    };
  }, [uiService]);

  const { fromUrls } = useSelector((state) => ({
    fromUrls: state.global.fromUrls,
  }));

  useEffect(() => {
    const breadcrumbs = [];
    if (fromUrls.length === 0) {
      const routeBreadcrumb = getRouteBreadcrumb(intl, location.pathname, subheader.title, true);
      breadcrumbs.push(...routeBreadcrumb);
    } else {
      fromUrls.forEach((fromUrl, index) => {
        if (index === 0) {
          const routeBreadcrumb = getRouteBreadcrumb(intl, fromUrl.url, fromUrl.name, false);
          breadcrumbs.push(...routeBreadcrumb);
        } else {
          breadcrumbs.push({ pathname: fromUrl.url, title: fromUrl.name });
        }
      });
      breadcrumbs.push({ title: subheader.title });
    }
    subheader.setBreadcrumbs(breadcrumbs);
  }, [location.pathname, fromUrls, subheader.title, location.search]);

  // Do not remove this useEffect, need from update title/breadcrumbs outside (from the page)
  useEffect(() => {}, [subheader]);

  return (
    <div
      id="kt_subheader"
      className={`subheader py-2 ${layoutProps.subheaderCssClasses}`}
    >
      <div
        className={`${layoutProps.subheaderContainerCssClasses} d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap`}
        style={{ minHeight: 40 }}
      >
        {/* Info */}
        <div className="d-flex align-items-center flex-wrap w-100">
          {/* begin::Mobile Toggle */}
          {layoutProps.subheaderMobileToggle && (
            <button
              className="burger-icon burger-icon-left mr-4 d-inline-block d-lg-none"
              id="kt_subheader_mobile_toggle"
            >
              <span />
            </button>
          )}
          {/* end::Mobile Toggle */}

          {/* begin::Heading */}
          <div className="d-flex">
            {/* begin::Title */}
            {/*<h2 className="text-white font-weight-bold my-2 mr-5">{subheader.title}</h2>*/}
            {/* end::Title */}

            <BreadCrumbs subheader={subheader} />
          </div>
          {/* end::Heading */}
        </div>

        {/* Toolbar */}
        {/*<div className="d-flex align-items-center">*/}
        {/*  <button*/}
        {/*    type="button"*/}
        {/*    className="btn btn-transparent-white font-weight-bold  py-3 px-6 mr-4"*/}
        {/*  >*/}
        {/*    Reports*/}
        {/*  </button>*/}
        {/*  <QuickActions />*/}
        {/*</div>*/}
      </div>
    </div>
  );
}
