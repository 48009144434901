import * as React from "react";
import { isEqual, isFunction } from "lodash-es";
import { initialFilters } from "./BudgetsUIHelpers";
import { BudgetLineBasePropsType, BudgetLineColumnsTypes, IBudget } from "../../../data/schemas";
import * as actions from "./_redux/budgetsActions";
import { useDispatch } from "react-redux";

export interface IBudgetsUIContext extends IBudgetsUIEvents {
  filters: IBudgetFilters;
  setFilters: React.Dispatch<React.SetStateAction<IBudgetFilters>>;
  queryParams: Record<string, string>;
  setQueryParams: React.Dispatch<React.SetStateAction<Record<string, string>>>;
  ids: string[];
  setIds: React.Dispatch<React.SetStateAction<string[]>>;
  readOnly?: boolean;
  context?: string;
  columnWidth: Record<BudgetLineColumnsTypes, number>;
  setColumnWidth: React.Dispatch<React.SetStateAction<Record<BudgetLineColumnsTypes, number>>>;
  triggerExpandCategories: boolean;
  triggerCollapseCategories: boolean;
  expandCategories: () => void;
  collapseCategories: () => void;
  filterList: Object[];
  budgetTotals: Record<BudgetLineBasePropsType, number>;
  setBudgetTotals: React.Dispatch<React.SetStateAction<Record<BudgetLineBasePropsType, number>>>;
  computedCategories?: any[];
  setComputedCategories: React.Dispatch<React.SetStateAction<any[] | undefined>>;
  saveBudgetFields: (key: string | string[], value: any) => void;
}

export interface IBudgetsUIEvents {
  newBudgetButtonClick?: () => void;
  openBudgetPage?: (budget: IBudget) => void;
}

export interface IBudgetFilters {
  pageNumber: number;
  pageSize: number;
  filterSelected: Object[];
  freeText?: string;
}

const BudgetsUIContext = React.createContext<IBudgetsUIContext>({} as IBudgetsUIContext);

export function useBudgetsUIContext() {
  return React.useContext(BudgetsUIContext);
}

export const BudgetsUIConsumer = BudgetsUIContext.Consumer;

export interface BudgetsUIProviderProps {
  budgetsUIEvents?: IBudgetsUIEvents;
  queryParamsInit?: any;
  readOnly?: boolean;
  context?: string;
}

export const BudgetsUIProvider: React.FC<BudgetsUIProviderProps> = ({
  budgetsUIEvents,
  children,
  queryParamsInit,
  readOnly,
  context,
}) => {
  const dispatch = useDispatch();
  const [queryParams, setQueryParams] = React.useState<Record<string, string>>(queryParamsInit);
  const [filters, setFiltersBase] = React.useState<IBudgetFilters>(initialFilters);
  const [ids, setIds] = React.useState<string[]>([]);
  const filterList = [
    { value: "name", label: "COMMON.NAME" },
    { value: "budgetStatus", label: "COMMON.STATUS" },
  ];
  const setFilters = React.useCallback((nextFilters) => {
    setFiltersBase((prevFilters) => {
      if (isFunction(nextFilters)) {
        nextFilters = nextFilters(prevFilters);
      }

      if (isEqual(prevFilters, nextFilters)) {
        return prevFilters;
      }

      return nextFilters;
    });
  }, []);

  const [budgetTotals, setBudgetTotals] = React.useState<Record<BudgetLineBasePropsType, number>>(
    {} as Record<BudgetLineBasePropsType, number>
  );
  const [computedCategories, setComputedCategories] = React.useState<any[]>();
  const [columnWidth, setColumnWidth] = React.useState<Record<BudgetLineColumnsTypes, number>>(
    {} as Record<BudgetLineColumnsTypes, number>
  );
  const [triggerExpandCategories, setTriggerExpandCategories] = React.useState(false);
  const [triggerCollapseCategories, setTriggerCollapseCategories] = React.useState(false);
  const expandCategories = () => {
    setTriggerExpandCategories(!triggerExpandCategories);
  };
  const collapseCategories = () => {
    setTriggerCollapseCategories(!triggerCollapseCategories);
  };

  const saveBudgetFields = (key: string | string[], value: any) => {
    if (Array.isArray(key) && key.length === value.length) {
      for (let i = 0; i < key.length; i++) {
        dispatch(actions.updateBudgetFieldLocally(key[i], value[i]));
      }
    } else {
      dispatch(actions.updateBudgetFieldLocally(key, value));
    }
  };

  const value: IBudgetsUIContext = {
    filters,
    setFilters,
    queryParams,
    setQueryParams,
    ids,
    setIds,
    newBudgetButtonClick: budgetsUIEvents?.newBudgetButtonClick,
    openBudgetPage: budgetsUIEvents?.openBudgetPage,
    readOnly,
    context,
    columnWidth,
    setColumnWidth,
    triggerExpandCategories,
    triggerCollapseCategories,
    expandCategories,
    collapseCategories,
    filterList,
    budgetTotals,
    setBudgetTotals,
    computedCategories,
    setComputedCategories,
    saveBudgetFields,
  };

  return <BudgetsUIContext.Provider value={value}>{children}</BudgetsUIContext.Provider>;
};
