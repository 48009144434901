/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import SVG from "react-inlinesvg";
import {toAbsoluteUrl} from "../../../_helpers";

export function TilesWidget11({className, baseColor = "primary", widgetHeight = "100px", title, subtitle, icon}) {
  return (
    <>
      <div
        className={`card card-custom bg-${baseColor} ${className}`}
        style={{height: widgetHeight}}
      >
        <div className="card-body d-flex flex-column justify-content-center px-8">
          {icon &&
          <span className="svg-icon svg-icon-3x svg-icon-white ml-n2 mb-3">
            <SVG src={toAbsoluteUrl(icon)}/>
          </span>
          }
          <div className={`text-inverse-${baseColor} font-weight-bolder font-size-h2`}>
            {title}
          </div>

          <div className={`text-inverse-${baseColor} font-weight-bold font-size-lg mt-1`}>
            {subtitle}
          </div>
        </div>
      </div>
    </>
  );
}
