import React from "react";
import { ColumnFormatter } from "react-bootstrap-table-next";

interface FormatExtraDataProps {
  highlightId: string;
}

interface RowProps {
  id: string;
}

export const RowHighlightIndicatorColumnFormatter: ColumnFormatter<RowProps, FormatExtraDataProps> =
  (cell, row, rowIndex, formatExtraData) => {
    if (row && row.id === formatExtraData?.highlightId) {
      return <i className="fa fa-circle text-danger icon-nm" data-cy="indicator-row" />;
    }
  };
