import * as React from "react";
import { NumberInput } from "../../../../../_utils/formUtils";
import { SUFFIX_EURO_CURRENCY } from "../../../../../_utils/suffixUtils";
import { NumberFormatValues } from "react-number-format";
import { DebouncedFunc } from "lodash-es";
import { BudgetTableRow } from "../../BudgetEditExpenses";
import { Row } from "@tanstack/react-table";
import { useFormikContext } from "formik";
import { IBudget } from "../../../../../../data/schemas";

export interface BudgetPlannedProps {
  row: Row<BudgetTableRow>;
  value: any;
  disabled: boolean;
  setValue: React.Dispatch<React.SetStateAction<number>>;
  customHandleChange: DebouncedFunc<(event: any) => void>;
}

export const BudgetPlanned: React.FC<BudgetPlannedProps> = ({
  row,
  value,
  disabled,
  setValue,
  customHandleChange,
}) => {
  const { values } = useFormikContext<IBudget>();
  const { categoryId, sequence } = row.original;
  const handlePlannedChange = (event: NumberFormatValues) => {
    setValue(event.floatValue || 0);
    const relatedBudgetCategory = values?.sortedLines?.find((cat) => cat.id === categoryId);
    customHandleChange({
      target: {
        name: `sortedLines.${relatedBudgetCategory!.sequence}.lines.${sequence}.plannedBudget`,
        value: event.floatValue || 0,
      },
    });
  };

  return (
    <NumberInput
      className="form-control text-right"
      value={value}
      suffix={SUFFIX_EURO_CURRENCY}
      decimalScale={2}
      onValueChange={handlePlannedChange}
      disabled={disabled}
      size={5}
      onClick={(e: React.MouseEvent<HTMLInputElement>) => e.stopPropagation()}
    />
  );
};
