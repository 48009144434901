import * as React from "react";
import { Tag, TagLink } from "../../data/schemas";
import { cloneDeep } from "lodash";
import { useWebsocket } from "../hooks/useWebsocket";
import { IOption } from "../_utils/interfacesUtils";
import { API } from "@aws-amplify/api";
import { useDispatch } from "react-redux";
import {
  processSnackbarNotification,
  SNACKBAR_MESSAGE,
} from "../modules/Common/SnackbarNotificationsHandler";
import { useQueryClient } from "react-query";
import { TAGLINK } from "../_utils/dataTypes";
import { useGetTagLinks } from "../hooks/useGetTagLinks";

export interface TagLinkMap {
  [key: string]: TagLink[];
}
interface TagContextValue {
  // List of Tags
  tagList?: Tag[];
  // List of TagLinks stored in array with key => values (key is relatedTo and values array of tagLinks)
  tagLinks: TagLinkMap;
  // boolean to indicate if the provider is updating tagLinks
  isUpdating: boolean;
  // Function to load the Tags
  loadTags: () => void;
  // Get the loading state of a provided relatedTo key for TagLinks
  isTagLinksLoading: (key: string) => boolean;
  // Return the stored TagLinks for provided keys or initialize the fetch call
  getTagLinksByKey: (key: string) => TagLink[];
  // Send the provided TagLinks to update or delete to the backend
  sendUpdatedTagLinks: (tagLinksToHandle: IOption[], relatedTo: string) => Promise<void>; //Promise<ITagLink[]>;
  addTagLink: (tagLink: TagLink) => void;
}

interface TagProviderProps {
  children: React.ReactNode;
}

export const TagContext = React.createContext<TagContextValue>({
  tagList: undefined,
  tagLinks: {},
  isUpdating: false,
  loadTags: () => undefined,
  isTagLinksLoading: (key: string) => false,
  getTagLinksByKey: (key: string) => [],
  sendUpdatedTagLinks: async (tagLinksToHandle: IOption[], relatedTo: string) => undefined, //[],
  addTagLink: (tagLink: TagLink) => undefined,
});

export const TagProvider: React.FC<TagProviderProps> = (props) => {
  const { children } = props;
  const [tagList, setTagList] = React.useState<Tag[] | undefined>(undefined);
  const { latestMessage } = useWebsocket();
  const [isUpdating, setIsUpdating] = React.useState<boolean>(false);
  // This variable is set in order to avoid fetching the same item at once
  const [relatedToEntitiesList, setRelatedToEntitiesList] = React.useState<string[]>([]);
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const { tagLinks } = useGetTagLinks(relatedToEntitiesList);

  React.useEffect(() => {
    switch (latestMessage?.type) {
      case "ADD_TAG":
      case "UPDATE_TAG":
        // No need to update as it hasn't been loaded first
        if (tagList) updateTag(latestMessage.parameters?.tag);
        break;
      case "REMOVE_TAG":
        removeTag(latestMessage.parameters?.tag);
        break;
      case "ADD_TAG_LINK":
        const tagLinkToAdd = latestMessage.parameters?.tagLink;
        // If the item is not loaded, we don't have to manage it
        const addQuery = queryClient.getQueryState([TAGLINK, tagLinkToAdd.relatedTo]);
        if (tagLinkToAdd && addQuery) addTagLink(tagLinkToAdd);
        break;
      case "REMOVE_TAG_LINK":
        const tagLinkToRemove = latestMessage.parameters?.tagLink;
        // If the item is not loaded, we don't have to manage it
        const delQuery = queryClient.getQueryState([TAGLINK, tagLinkToRemove.relatedTo]);
        if (tagLinkToRemove && delQuery) removeTagLink(tagLinkToRemove);
        break;
    }
  }, [latestMessage]);

  const loadTags = () => {
    API.get("API", "/tags", {})
      .then((entities) => {
        setTagList(entities);
      })
      .catch((error) => {
        processSnackbarNotification(SNACKBAR_MESSAGE.DANGER.FETCH_TAGS, dispatch);
      });
  };

  // We fetch the TagLinks escaping the tagId and providing the relatedTo key used in backend

  const getTagLinksByKey = (key: string) => {
    // If key not fetched nor in loading list, we add it to expand queue list
    // We check if already loading, if yes, we remove it from the list to add it at the end to fetch it first
    const query = queryClient.getQueriesData<TagLink[]>([TAGLINK, key]);
    if (query.length === 0 || relatedToEntitiesList.indexOf(key) !== -1) {
      setRelatedToEntitiesList((prev) => [...prev.filter((p) => p !== key), key]);
    }
    return query.length > 0 ? query[0][1] : [];
  };

  const isTagLinksLoading = (key: string) => {
    const queryStatus = queryClient.getQueryState([TAGLINK, key]);
    const queryDefault = queryClient.getQueryDefaults([TAGLINK, key]);
    return queryDefault ? false : queryStatus === undefined || queryStatus?.status !== "success";
  };

  // Update or add the tag if not found in list
  const updateTag = (tag: Tag) => {
    if (tagList) {
      const index = tagList.findIndex((t) => t.id === tag.id || t.name === tag.name);
      if (index > -1) {
        tagList[index] = tag;
      } else {
        tagList.push(tag);
      }
      setTagList(tagList);
    }
  };

  const removeTag = (tag: Tag) => {
    // Remove from tags
    if (tagList) {
      setTagList((prev) => {
        return prev ? prev.filter((t) => t.id !== tag.id) : prev;
      });
    }
  };

  const addTagLink = (tagLink: TagLink) => {
    const queryKey = [TAGLINK, tagLink.relatedTo];

    const rqData = queryClient.getQueriesData<TagLink[]>(queryKey);
    if (rqData && rqData?.[0]?.[1]) {
      const tagLinksData = rqData[0][1];
      if (tagLinksData.find((tl) => tl.id === tagLink.id) === undefined)
        queryClient.setQueriesData(queryKey, [...tagLinksData, tagLink]);
    } else {
      let values = [tagLink];
      const defaultQuery = queryClient.getQueryDefaults(queryKey);
      if (defaultQuery && defaultQuery.queryFn) {
        const defaultValue = defaultQuery.queryFn({
          queryKey,
          meta: {},
        });
        values = Array.from(new Set([...defaultValue, ...values]));
      }
      queryClient.setQueryDefaults(queryKey, { queryFn: () => values });
      setRelatedToEntitiesList((prev) => [
        ...prev.filter((p) => p !== tagLink.relatedTo),
        tagLink.relatedTo,
      ]);
    }
  };

  const removeTagLink = (tagLink: TagLink) => {
    const queryKey = [TAGLINK, tagLink.relatedTo];
    const tagLinksData = queryClient.getQueriesData<TagLink[]>(queryKey)[0][1];
    queryClient.setQueriesData(
      queryKey,
      tagLinksData.filter((tl) => tl.id !== tagLink.id)
    );
  };

  const sendUpdatedTagLinks = async (tagLinksToHandle: IOption[], relatedTo: string) => {
    if (tagList) {
      setIsUpdating(true);
      const data = queryClient.getQueriesData<TagLink[]>([TAGLINK, relatedTo]);
      const currentTagLinks: TagLink[] = data ? cloneDeep(data[0][1]) : [];
      // Compare the stored taglinks with those sent one
      // 1. New tagLinks
      for (const o of tagLinksToHandle) {
        const index = currentTagLinks.findIndex((tl) => tl.tagName === o.value);
        if (index === -1) {
          const tag = tagList.find((t) => t.name === o.value);
          const tagLink = { tagName: o.value, tagId: tag?.id, relatedTo };
          // check if not found in taglist
          if (tag === undefined) {
            const newTag = { name: o.value, tagLink };
            // create taglink + tag
            await API.post("API", `/tags`, { body: newTag })
              .then((t) => {
                if (t.tagLink) {
                  addTagLink(t.tagLink);
                  delete t.tagLink;
                }
                updateTag(t);
              })
              .catch((error) => {
                processSnackbarNotification(SNACKBAR_MESSAGE.DANGER.UPDATE_TAGS, dispatch);
              });
          } else {
            // create taglink
            await API.post("API", `/tags/${tagLink.tagId}/links/`, { body: tagLink })
              .then((tl) => {
                addTagLink(tl);
              })
              .catch((error) => {
                processSnackbarNotification(SNACKBAR_MESSAGE.DANGER.UPDATE_TAGS, dispatch);
              });
          }
        }
      }
      // 2. taglinks to delete
      for (const tl of currentTagLinks) {
        const index = tagLinksToHandle.findIndex((o) => o.value === tl.tagName);
        if (index === -1) {
          // Delete
          await API.del("API", `/tags/${tl.tagId}/links/${tl.id}`, {})
            .then(() => {
              removeTagLink(tl);
            })
            .catch((error) => {
              processSnackbarNotification(SNACKBAR_MESSAGE.DANGER.DELETE_TAGS, dispatch);
            });
        }
      }
      // 3. update state
      setIsUpdating(false);
    }
  };

  return (
    <TagContext.Provider
      value={{
        tagList,
        tagLinks,
        isUpdating,
        loadTags,
        isTagLinksLoading,
        getTagLinksByKey,
        sendUpdatedTagLinks,
        addTagLink,
      }}
    >
      {children}
    </TagContext.Provider>
  );
};
