import { useRef } from "react";
import { useTranslation } from "../../../../../hooks/useTranslation";
import { useKycFile } from "../../../../../hooks/useKycFile";
import { KycTranslation } from "../../../KycTranslation";
import { Button } from "react-bootstrap";
import { KycFile } from "../../../Kyc";

interface UploadButtonProps {
  file?: KycFile;
  setProgress: Function;
}

export const KycUploadButton = ({ file, setProgress }: UploadButtonProps): JSX.Element => {
  const { fm } = useTranslation();
  const { saveFile } = useKycFile();

  const hiddenFileInput: any = useRef(null);
  const handleClick = (event: any) => {
    hiddenFileInput?.current?.click();
  };

  const handleFileUpload = (e: any) => {
    if (e?.target?.files?.[0]) {
      const sourceFileRaw = e?.target?.files?.[0];
      if (sourceFileRaw && sourceFileRaw?.size) {
        const f: any = {
          ...file,
          friendlyName: sourceFileRaw?.name,
          fileExtension: sourceFileRaw?.name?.split(".")?.pop(),
          mimeType: sourceFileRaw.type,
          name: sourceFileRaw.name,
          body: sourceFileRaw,
          size: sourceFileRaw?.size ?? -1,
        };

        saveFile(f, setProgress);
      }
    }
  };

  return (
    <>
      <Button
        size="sm"
        onClick={handleClick}
        className=" btn-primary form-control-reduce-space ml-1 text-nowrap h-100"
      >
        {fm(KycTranslation.UPLOAD_CHOOSE_FILE)}
      </Button>
      <input
        type="file"
        ref={hiddenFileInput}
        accept=".jpg, .jpeg, .png, .bmp, .pdf, .txt, .docx, .doc, .xls, .xlsx"
        onChange={handleFileUpload}
        style={{ display: "none" }}
      />
    </>
  );
};
