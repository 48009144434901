import type { FC, ReactNode } from "react";
import { createContext } from "react";
import PropTypes from "prop-types";
import { API } from "@aws-amplify/api";
import { useAuditTrail } from "../hooks/useAuditTrail";
import { KycEventBody, KycStepEvent } from "../modules/kyc-v2/Kyc";

interface KycEventProviderProps {
  children?: ReactNode;
}

interface KycEventContextValue {
  createKycEventMessage: (kycId: string, eventName: KycStepEvent, options?: any) => Promise<void>;
}

export const KycEventContext = createContext<KycEventContextValue>({
  createKycEventMessage: (kycId, eventName, options) => Promise.resolve(),
});

export const KycEventProvider: FC<KycEventProviderProps> = (props) => {
  const { children } = props;
  const { setEventsManually } = useAuditTrail();

  const createKycEventMessage = async (kycId: string, eventName: KycStepEvent, options?: any) => {
    const body: KycEventBody = { status: eventName, options };
    await API.post("API", `/kyc/${kycId}/audit`, { body }).then((response) => {
      response?.items && setEventsManually(response?.items);
    });
  };

  return (
    <KycEventContext.Provider
      value={{
        createKycEventMessage,
      }}
    >
      {children}
    </KycEventContext.Provider>
  );
};

KycEventProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export const KycEventsConsumer = KycEventContext.Consumer;
