import { HeaderRender } from "./HeaderRender";
import * as React from "react";
import { useMutationObserver } from "rooks";
import { useCardTableContext } from "../context/CardTableContext";

export interface CardTableHeadersProps {
  headersRef: React.RefObject<HTMLDivElement>;
}

export const CardTableHeaders = ({ headersRef }: CardTableHeadersProps) => {
  const { table } = useCardTableContext();

  const [headersOffsetTop, setHeadersOffsetTop] = React.useState(0);

  const bodyRef = React.useRef(document.body);
  useMutationObserver(bodyRef, (mutations) => {
    mutations.forEach(function (mutation) {
      if (mutation.type === "attributes" && mutation.attributeName === "data-header-scroll") {
        if ((mutation.target as Element).getAttribute("data-header-scroll")) {
          setHeadersOffsetTop(80);
        } else {
          setHeadersOffsetTop(0);
        }
      }
    });
  });
  return (
    <div
      className="d-flex overflow-hidden position-sticky zindex-2 bg-white border-bottom"
      style={{
        top: headersOffsetTop,
      }}
      ref={headersRef}
    >
      {table.getHeaderGroups().map((headerGroup) => (
        <div key={headerGroup.id} className="d-flex flex-grow-1 py-2">
          {headerGroup.headers.map((header, index) => (
            <HeaderRender header={header} index={index} key={header.id} />
          ))}
        </div>
      ))}
    </div>
  );
};
