/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import cn from "clsx";
import { Dropdown, OverlayTrigger, Tooltip } from "react-bootstrap";
import { useLanguage } from "../../../../i18n";
import { DropdownTopbarItemToggler } from "../../../../_partials/dropdowns";
import { FormattedMessage } from "react-intl";
import { AVAILABLE_LANGUAGES } from "../../../../../app/_utils/languages";

export const LanguageSelectorDropdown = () => {

  const {selectedLanguage:lang, setI18nLanguage} = useLanguage();
  
  const currentLanguage = AVAILABLE_LANGUAGES.find((x) => x.lang === lang);
  return (
    <Dropdown drop="down" alignRight>
      <Dropdown.Toggle as={DropdownTopbarItemToggler} id="dropdown-toggle-my-cart">
        <OverlayTrigger
          placement="bottom"
          overlay={<Tooltip id="language-panel-tooltip"><FormattedMessage id="LANGUAGE.SELECT" /></Tooltip>}
        >
          <div className="btn btn-icon btn-hover-transparent-white btn-dropdown btn-lg mr-1">
            <img
              className="h-20px w-20px rounded-sm"
              src={currentLanguage?.flag}
              alt={currentLanguage?.name}
            />
          </div>
        </OverlayTrigger>
      </Dropdown.Toggle>
      <Dropdown.Menu className="dropdown-menu p-0 m-0 dropdown-menu-anim-up dropdown-menu-sm dropdown-menu-right">
        <ul className="navi navi-hover py-4">
          {AVAILABLE_LANGUAGES.map((language) => (
            <li
              key={language.lang}
              className={cn("navi-item", {
                active: language.lang === currentLanguage?.lang
              })}
            >
              <a href="#" onClick={() => setI18nLanguage(language.lang)} className="navi-link">
                <span className="symbol symbol-20 mr-3">
                  <img src={language.flag} alt={language.name} />
                </span>
                <span className="navi-text">{language.name}</span>
              </a>
            </li>
          ))}
        </ul>
      </Dropdown.Menu>
    </Dropdown>
  );
}
