import { useAppSelector } from "redux/hooks";
import { shallowEqual } from "react-redux";
import { canCreate, canReadAll } from "app/_utils/authUtils";
import cn from "clsx";

import "./EmptyState.scss";
import ActionNewButton from "../ActionNewButton";
import { FormattedMessage } from "react-intl";

interface EmptyStateProps {
  entityUIContextAction?: any;
  stateLocation: string;
  entitiesLength?: number;
  listLoading?: boolean;
  userType?: string;
  children?: JSX.Element;
  disabled?: boolean;
}

const EmptyState = ({
  entityUIContextAction,
  stateLocation,
  userType,
  entitiesLength,
  listLoading,
  children,
  disabled = false,
}: EmptyStateProps) => {
  const { groups, session } = useAppSelector(
    (state) => ({
      groups: state.auth.groups,
      session: state.auth.session,
    }),
    shallowEqual
  );

  const canCreateEntity = canCreate(groups, session, stateLocation);
  const canRead = canReadAll(groups, session, stateLocation);

  const translationText = (messageId: string) => {
    return `${messageId}.${userType ?? stateLocation}`;
  };

  return (
    <div>
      {entitiesLength === 0 && !listLoading && canRead ? (
        <div
          className={cn(
            "emptyStateContainer",
            stateLocation === "TASK" ? "emptyStateTaskPage" : "emptyStatePages"
          )}
        >
          <h3 data-cy="empty-state-title">
            <FormattedMessage
              id={translationText(
                canCreateEntity ? "EMPTY.STATE.TITLE" : "EMPTY.STATE.TITLE.NOT.ALLOWED"
              )}
            />
          </h3>
          <p className="lg mt-2">
            {<FormattedMessage id={canCreateEntity ? "EMPTY.STATE.DESCRIPTION" : "EMPTY.STATE.DESCRIPTION.NOT.ALLOWED"} />}
          </p>
          {canCreateEntity && (
            <div className="mt-2 w-sm-100">
              <ActionNewButton
                dataCy="button-empty-state-new"
                className="text-nowrap w-100 w-md-auto min-h-40px"
                onClick={entityUIContextAction}
                messageId={translationText("EMPTY.STATE.ACTION")}
                disabled={disabled}
              />
            </div>
          )}
        </div>
      ) : (
        children
      )}
    </div>
  );
};

export default EmptyState;
