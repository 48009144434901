import * as React from "react";
import { useDebounce } from "rooks";
import { Field, FieldAttributes } from "formik";

const INPUT_DELAY = 200;

export const DebouncedField = (props: FieldAttributes<any>) => {
  const [innerValue, setInnerValue] = React.useState("");

  React.useEffect(() => {
    if (props.value) {
      setInnerValue(props.value as string);
    } else {
      setInnerValue("");
    }
  }, [props.value]);

  const debouncedHandleOnChange = React.useCallback(
    useDebounce((event: React.ChangeEvent<HTMLInputElement>) => {
      if (props.onChange) {
        props.onChange(event);
      }
    }, INPUT_DELAY),
    []
  );

  const handleOnChange = React.useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    event.persist();

    const newValue = event.currentTarget.value;
    setInnerValue(newValue);
    debouncedHandleOnChange(event);
  }, []);

  return <Field {...props} value={innerValue} onChange={handleOnChange} />;
};
