/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import { FormattedMessage } from "react-intl";
import { ColumnFormatter } from "react-bootstrap-table-next";

interface FormatExtraDataProps {
  openDeleteDialog?: Function;
  openEditDialog?: Function;
  openRequestSignatureDialog?: Function;
  openShareProjectDialog?: Function;
  editDialog?: any;
  actionList: string[];
  isAdmin?: boolean;
  copy?: Function;
  canDelete?: Function;
  canSelectBudget?: Function;
  canShare?: Function;
  canDuplicate?: Function;
  canEdit?: Function;
  canRequestSignature?: Function;
  entityType: string;
}

export const ActionsColumnFormatter: ColumnFormatter<any, FormatExtraDataProps> = (
  cell,
  row,
  rowIndex,
  formatExtraData
) =>
  formatExtraData && (
    <span onClick={(e) => e.stopPropagation()}>
      {/*STAR*/}
      <>
        {formatExtraData.actionList.includes("STAR") &&
          !!formatExtraData.canSelectBudget &&
          formatExtraData.canSelectBudget(row) && (
            <>
              {formatExtraData.editDialog && formatExtraData.editDialog !== row.id ? (
                <OverlayTrigger
                  overlay={
                    <Tooltip id="budgets-select-tooltip">
                      <FormattedMessage id="BUDGET.ACTION.SELECT_FOR_FINANCE" />
                    </Tooltip>
                  }
                >
                  <button
                    className="btn btn-icon btn-light btn-hover-primary btn-sm mr-3"
                    onClick={() => formatExtraData.openEditDialog?.(row.id, row.invoiceStatus)}
                  >
                    <span className="svg-icon svg-icon-md svg-icon-primary">
                      <SVG src={toAbsoluteUrl("/media/svg/icons/General/Star.svg")} />
                    </span>
                  </button>
                </OverlayTrigger>
              ) : (
                <button className="btn btn-icon btn-light btn-hover-primary btn-sm mr-3" disabled>
                  <span className="svg-icon svg-icon-md svg-icon-primary">
                    <SVG src={toAbsoluteUrl("/media/svg/icons/General/Star.svg")} />
                  </span>
                </button>
              )}
            </>
          )}
      </>
      {/*DUPLICATE*/}
      <>
        {formatExtraData.actionList.includes("DUPLICATE") && formatExtraData.canDuplicate?.(row) && (
          <OverlayTrigger
            overlay={
              <Tooltip id="budgets-copy-tooltip">
                <FormattedMessage id={`${formatExtraData.entityType.split("_")[0]}.ACTION.COPY`} />
              </Tooltip>
            }
          >
            <button
              className="btn btn-icon btn-light btn-hover-info btn-sm mr-3"
              onClick={() => formatExtraData.copy?.(row.id, row)}
            >
              <span className="svg-icon svg-icon-md svg-icon-info">
                <SVG src={toAbsoluteUrl("/media/svg/icons/General/Duplicate.svg")} />
              </span>
            </button>
          </OverlayTrigger>
        )}
      </>
      {/*SIGNATURE*/}
      <>
        {formatExtraData.actionList.includes("SIGNATURE") &&
          row.mimeType === "application/pdf" &&
          formatExtraData.canRequestSignature?.(row) && (
            <OverlayTrigger
              overlay={
                <Tooltip id="customers-edit-tooltip">
                  <FormattedMessage id="COMMON.ACTION.SIGNATURES_REQUEST" />
                </Tooltip>
              }
            >
              <a
                data-cy="button-request-signature"
                className="btn btn-icon btn-light btn-hover-primary btn-sm mr-3"
                onClick={() => formatExtraData.openRequestSignatureDialog?.(row.id)}
              >
                <span className="svg-icon svg-icon-md svg-icon-primary">
                  <SVG src={toAbsoluteUrl("/media/svg/icons/Text/Underline.svg")} />
                </span>
              </a>
            </OverlayTrigger>
          )}
      </>
      {/*SHARE*/}
      <>
        {formatExtraData.actionList.includes("SHARE") && formatExtraData.canShare?.(row) && (
          <OverlayTrigger
            overlay={
              <Tooltip id="project-share-tooltip">
                <FormattedMessage id={`${formatExtraData.entityType.split("_")[0]}.ACTION.SHARE`} />
              </Tooltip>
            }
          >
            <a
              className="btn btn-icon btn-light btn-hover-info btn-sm mr-3"
              data-cy="button-share"
              onClick={() => formatExtraData.openShareProjectDialog?.(row.id, row)}
            >
              <span className="svg-icon svg-icon-md svg-icon-info">
                <SVG src={toAbsoluteUrl("/media/svg/icons/Communication/Share.svg")} />
              </span>
            </a>
          </OverlayTrigger>
        )}
      </>
      {/*WRITE*/}
      <>
        {formatExtraData.actionList.includes("WRITE") && formatExtraData.canEdit?.(row) && (
          <OverlayTrigger
            overlay={
              <Tooltip id="customers-edit-tooltip">
                <FormattedMessage id={`${formatExtraData.entityType.split("_")[0]}.ACTION.EDIT`} />
              </Tooltip>
            }
          >
            <a
              data-cy="button-edit-row"
              className="btn btn-icon btn-light btn-hover-primary btn-sm mr-3"
              onClick={() => formatExtraData.openEditDialog?.(row.id, row.invoiceStatus)}
            >
              <span className="svg-icon svg-icon-md svg-icon-primary">
                <SVG src={toAbsoluteUrl("/media/svg/icons/Communication/Write.svg")} />
              </span>
            </a>
          </OverlayTrigger>
        )}
      </>
      {/*TRASH*/}
      <>
        {formatExtraData.actionList.includes("TRASH") && formatExtraData.canDelete?.(row) && (
          <>
            {formatExtraData.editDialog !== row.id ? (
              <OverlayTrigger
                overlay={
                  <Tooltip id="budgets-delete-tooltip">
                    <FormattedMessage
                      id={`${formatExtraData.entityType.split("_")[0]}.ACTION.DELETE`}
                    />
                  </Tooltip>
                }
              >
                <a
                  className="btn btn-icon btn-light btn-hover-danger btn-sm"
                  onClick={() =>
                    formatExtraData.openDeleteDialog?.(
                      row.id,
                      formatExtraData.entityType.split("_")[1],
                      row
                    )
                  }
                  data-cy="button-delete-row"
                >
                  <span className="svg-icon svg-icon-md svg-icon-danger">
                    <SVG src={toAbsoluteUrl("/media/svg/icons/General/Trash.svg")} />
                  </span>
                </a>
              </OverlayTrigger>
            ) : (
              <button className="btn btn-icon btn-light btn-hover-danger btn-sm" disabled>
                <span className="svg-icon svg-icon-md svg-icon-danger">
                  <SVG src={toAbsoluteUrl("/media/svg/icons/General/Trash.svg")} />
                </span>
              </button>
            )}
          </>
        )}
      </>
    </span>
  );

interface ActionsColumnFormatterComponentProps {
  row: any;
  rowIndex?: number;
  formatExtraData: FormatExtraDataProps;
}

export const ActionsColumnFormatterComponent: React.FC<ActionsColumnFormatterComponentProps> = ({
  row,
  rowIndex,
  formatExtraData,
}) => <>{ActionsColumnFormatter(null, row, rowIndex ?? -1, formatExtraData)}</>;
