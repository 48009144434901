import { defaultSelectedSort } from "app/_utils/tasks";

import { TaskItemProps } from "../TaskItem";

export const sortedByDateFn =
  (key: string, isAscending: boolean) => (itemA: TaskItemProps, itemB: TaskItemProps) => {
    const a = itemA.item as any;
    const b = itemB.item as any;
    const res = Number(new Date(a[key])) - Number(new Date(b[key]));
    return isAscending ? res : res * -1;
  };

export const sortedTasksLaneItems = <T extends TaskItemProps>(
  items: T[] = [],
  selectedSort = defaultSelectedSort
): T[] => {
  if (items.length === 0) return items;

  if (selectedSort?.selected && selectedSort.selected.type && selectedSort.selected.label) {
    if (selectedSort.selected.type === "DUE_DATE") {
      let itemsWithDueDate = items.filter(({ item: task }) => !!task.dueDate);
      let itemsWithoutDueDate = items.filter(({ item: task }) => !task.dueDate);

      itemsWithDueDate = itemsWithDueDate.sort(sortedByDateFn("dueDate", selectedSort.isAscending));
      itemsWithoutDueDate = itemsWithoutDueDate.sort(
        sortedByDateFn("createdAt", selectedSort.isAscending)
      );
      return itemsWithDueDate.concat(itemsWithoutDueDate);
    } else if (selectedSort.selected.type === "UPDATED_AT") {
      return items.sort(sortedByDateFn("updatedAt", selectedSort.isAscending));
    }
  } else {
    return items.sort(sortedByDateFn("createdAt", selectedSort.isAscending));
  }

  return [];
};
