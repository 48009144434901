import { Dispatch } from "@reduxjs/toolkit";
import { IntlShape } from "react-intl";
import { cloneDeep } from "lodash-es";

import { IAvatar, ISettings, ICompany } from "data/schemas";

import {
  processSnackbarNotification,
  SNACKBAR_MESSAGE,
} from "app/modules/Common/SnackbarNotificationsHandler";

import { removeObjectProperties, defaultIgnoredProps } from "app/_utils/removeObjectProperties";

import * as settingsCrud from "./settingsCrud";
import { settingsSlice } from "./settingsSlice";
import { CallType } from "./definitions";

const { actions } = settingsSlice;

export const updateSettingsFieldLocally = (key: string, value: any) => (dispatch: Dispatch) => {
  dispatch(actions.settingsFieldUpdatedLocally({ key, value }));
};

export const fetchSettings = (dispatch: Dispatch) => {
  dispatch(actions.startCall({ callType: CallType.GET }));

  return settingsCrud
    .fetchSettings()
    .then((settings) => {
      dispatch(actions.settingsFetched({ settings }));
    })
    .catch((error) => {
      dispatch(actions.catchError({ error }));
      processSnackbarNotification(SNACKBAR_MESSAGE.DANGER.FETCH_SETTINGS, dispatch);

      throw error;
    });
};

export const submitSettings = (settings: ISettings) => (dispatch: Dispatch) => {
  //Remove IBAN formatting for database
  const settingsForDB = cloneDeep(settings);

  settingsForDB.bankAccounts = settingsForDB.bankAccounts?.map((bankAccount) => ({
    ...bankAccount,
    IBAN: bankAccount.IBAN.replace(/[^A-Z0-9]/g, ""),
  }));

  dispatch(actions.startCall({ callType: CallType.ACTION }));
  return settingsCrud
    .updateSettings(settingsForDB)
    .then((settings) => {
      console.log("updateSettings response: ", settings);
      processSnackbarNotification(SNACKBAR_MESSAGE.SUCCESS.UPDATE_SETTINGS, dispatch);

      dispatch(actions.settingsUpdated({ settings }));
    })
    .catch((error) => {
      dispatch(actions.catchError({ error }));
      processSnackbarNotification(SNACKBAR_MESSAGE.DANGER.UPDATE_SETTINGS, dispatch);
    });
};

export const setSettingsAvatar = (avatar: IAvatar) => (dispatch: Dispatch) => {
  dispatch(actions.startCall({ callType: CallType.ACTION }));

  return settingsCrud
    .setSettingsAvatar(avatar)
    .then((response) => {
      dispatch(actions.avatarCreated({}));
      return response;
    })
    .catch((error) => {
      dispatch(actions.catchError({ error }));
      throw error;
    });
};

export const removeSettingsAvatar = (dispatch: Dispatch) => {
  dispatch(actions.settingsRemoveAvatar({}));
};

//----------------------------------------------------------------------------//

export const resetPropCo = (dispatch: Dispatch) => {
  dispatch(actions.propCoResetForEdit({}));
};

export const updatePropCoFieldLocally = (key: string, value: any) => (dispatch: Dispatch) => {
  dispatch(actions.propCoFieldUpdatedLocally({ key, value }));
};

export const fetchPropCos = (dispatch: Dispatch) => {
  dispatch(actions.propCoEntititesPreFetched({}));

  return settingsCrud
    .listPropCos()
    .then((propCos) => {
      dispatch(actions.propCoEntititesFetched({ propCos }));
      return propCos;
    })
    .catch((error) => {
      dispatch(actions.catchError({ error }));
      processSnackbarNotification(SNACKBAR_MESSAGE.DANGER.FIND_PROPCOS, dispatch);
    });
};

export const getPropCosById = (propCoId?: string) => (dispatch: Dispatch) => {
  if (!propCoId) {
    dispatch(actions.propCoFetched({ propCo: undefined }));
    return Promise.resolve(undefined);
  }

  dispatch(actions.propCoPreFetched({ propCoId }));

  return settingsCrud
    .getPropCoById(propCoId)
    .then((propCo) => {
      dispatch(actions.propCoFetched({ propCo }));
      return propCo;
    })
    .catch((error) => {
      dispatch(actions.catchError({ error }));
      processSnackbarNotification(SNACKBAR_MESSAGE.DANGER.FIND_PROPCO, dispatch);
    });
};

export const createPropCo = (propCo: ICompany) => (dispatch: Dispatch) => {
  dispatch(actions.startCall({ callType: CallType.ACTION }));

  return settingsCrud
    .createPropCo(propCo)
    .then((propCo) => {
      dispatch(actions.propCoCreated({ propCo }));
      return propCo;
    })
    .catch((error) => {
      dispatch(actions.catchError({ error }));
      processSnackbarNotification(SNACKBAR_MESSAGE.DANGER.CREATE_PROPCO, dispatch);
      return error;
    });
};

export const updatePropCo = (propCo: ICompany) => (dispatch: Dispatch) => {
  dispatch(actions.startCall({ callType: CallType.ACTION }));

  return settingsCrud
    .updatePropCo(propCo)
    .then((propCo) => {
      dispatch(actions.propCoUpdated({ propCo }));
      return propCo;
    })
    .catch((error) => {
      dispatch(actions.catchError({ error }));
      processSnackbarNotification(SNACKBAR_MESSAGE.DANGER.UPDATE_PROPCO, dispatch);
    });
};

export const deletePropCo = (propCoId: string) => (dispatch: Dispatch) => {
  dispatch(actions.startCall({ callType: CallType.ACTION }));

  return settingsCrud
    .deletePropCo(propCoId)
    .then(() => {
      dispatch(actions.propCoDeleted({ propCoId }));
    })
    .catch((error) => {
      dispatch(actions.catchError({ error }));
      processSnackbarNotification(SNACKBAR_MESSAGE.DANGER.DELETE_PROPCO, dispatch);
    });
};

export const setPropCoAvatar = (avatar: IAvatar) => (dispatch: Dispatch) => {
  dispatch(actions.startCall({ callType: CallType.ACTION }));

  return settingsCrud
    .setPropCoAvatar(avatar)
    .then((response) => {
      dispatch(actions.avatarCreated({}));
      return response;
    })
    .catch((error) => {
      dispatch(actions.catchError({ error }));
      throw error;
    });
};

export const removePropCoAvatar = (dispatch: Dispatch) => {
  dispatch(actions.propCoRemoveAvatar({}));
};

//----------------------------------------------------------------------------//

const commonIgnoredProps = [
  ...defaultIgnoredProps,
  "id",
  "avatarPictureSetup",
  "avatarPictureKey",
  "avatarPictureUrl",
  "avatarPictureUrlExpires",
];

const TRANSLATION_COPY_ENTRY = "COMMON.COPY";

const triggerPropCoDuplicagted = (dispatch: Dispatch, propCo: Partial<ICompany>, name: string) => {
  dispatch(actions.propCoDuplicated({ propCo: { ...propCo, name } }));
  return Promise.resolve("done");
};

const ignorePropCoProps = ["name", ...commonIgnoredProps];

export const duplicatePropCo = (fromPropCo: ICompany, intl: IntlShape) => (dispatch: Dispatch) => {
  const propCo: Partial<ICompany> = removeObjectProperties(fromPropCo, ignorePropCoProps);
  const name = `${fromPropCo.name} - ${intl.formatMessage({ id: TRANSLATION_COPY_ENTRY })}`;
  return triggerPropCoDuplicagted(dispatch, propCo, name);
};

const ignoreSettingsProps = ["companyName", ...commonIgnoredProps];

export const duplicateSettings =
  (fromSettings: ISettings, intl: IntlShape) => (dispatch: Dispatch) => {
    let propCo: Partial<ICompany> = removeObjectProperties(fromSettings, ignoreSettingsProps);
    const name = `${fromSettings.companyName} - ${intl.formatMessage({
      id: TRANSLATION_COPY_ENTRY,
    })}`;
    return triggerPropCoDuplicagted(dispatch, propCo, name);
  };
