import React, { useEffect } from "react";
import { Button } from "react-bootstrap";
import { useTranslation } from "../../../../../hooks/useTranslation";
import { KycTranslation } from "../../../KycTranslation";
import { KycClientLead, KycLoading } from "../../../Kyc";
import { useKyc } from "../../../../../hooks/useKyc";
import { useSession } from "../../../../../hooks/useSession";
import { isFlowUnfinished } from "../../../KycUtils";
import { canCreate } from "../../../../../_utils/authUtils";
import { useAppSelector } from "../../../../../../redux/hooks";
import { shallowEqual } from "react-redux";
import { KycCreateDialog } from "./KycCreateDialog";
import { isEmpty } from "lodash-es";
import { useKycUIContext } from "../KycUIContext";

type KycStartFlowActionProps = {
  cId: string;
};

export const KycStartFlowAction = ({ cId }: KycStartFlowActionProps): JSX.Element => {
  const { session } = useSession();
  const { fm } = useTranslation();
  const { currentKycClientLeads, currentKycFlows, getKycClientLeads } = useKyc();
  const { newKycModalButtonClick, optionsLeads, setOptionsLeads } = useKycUIContext();

  useEffect(() => {
    if (isEmpty(optionsLeads)) {
      getKycClientLeads(cId);
    }
  }, [optionsLeads]);

  const handleClick = (e: any) => {
    e.preventDefault();
    newKycModalButtonClick?.();
  };

  useEffect(() => {
    if (!isEmpty(currentKycClientLeads)) {
      const leadOptions = currentKycClientLeads?.map((lead: KycClientLead) => ({
        label: lead?.name,
        value: lead,
        isDisabled: isFlowUnfinished(currentKycFlows, lead?.id),
      }));
      setOptionsLeads(leadOptions);
    }
  }, [currentKycFlows, currentKycClientLeads]);

  const { groups } = useAppSelector(
    (state) => ({
      groups: state.auth.groups,
    }),
    shallowEqual
  );

  return (
    <>
      {canCreate(groups, session, "KYC_FLOW") && (
        <>
          <KycCreateDialog
            config={{
              isLoading: KycLoading.CREATE_KYC_FLOW,
              isLoadingData: KycLoading.GET_KYC_CLIENTS,
              labelActionButton: KycTranslation.ACTION_START,
              colorActionButton: "primary",
              labelContent: KycTranslation.ACTION_SELECT_ACTIVE_FILE,
              labelTitle: KycTranslation.ACTION_START,
            }}
            cId={cId}
            optionsLeads={optionsLeads}
          />

          <Button
            data-cy="button-kyc-create-kyc-flow"
            onClick={handleClick}
            className="btn-primary w-sm-100 text-nowrap mt-2 ml-2"
            size="sm"
            disabled={
              !optionsLeads ||
              isEmpty(optionsLeads) ||
              isEmpty(optionsLeads?.filter((option) => !option.isDisabled))
            }
          >
            {fm(KycTranslation.ACTION_CREATE_KYC_FLOW)}
          </Button>
        </>
      )}
    </>
  );
};
