import * as React from "react";
import cn from "clsx";

import { CardBaseProps } from "./definitions";

export interface CardProps extends CardBaseProps {
  fluidHeight?: boolean;
}

export const Card = React.forwardRef<HTMLDivElement, CardProps>(
  ({ fluidHeight, className, ...props }, ref) => (
    <div
      {...{
        ref,
        className: cn(
          "card card-custom gutter-b m-2",
          { "card-height-fluid": fluidHeight },
          className
        ),
        ...props,
      }}
    />
  )
);

export default Card;

// Set display name for debugging.
if (process.env.NODE_ENV !== "production") {
  Card.displayName = "Card";
}
