import { KycFileProvider } from "../../../contexts/KycFileContext";
import { KycComplianceProvider } from "../../../contexts/KycComplianceContext";
import { KycComplianceContainer } from "./compliance/KycComplianceContainer";
import { KycConfirmationProvider } from "../../../contexts/KycConfirmationContext";
import * as React from "react";
import { KycV2Provider } from "../../../contexts/KycContext";
import KycUIProvider from "./compliance/KycUIContext";

interface KycComplianceTabProps {
  userId: string;
}

export const KycComplianceTab = ({ userId }: KycComplianceTabProps): JSX.Element => {
  return (
    <KycV2Provider>
      <KycFileProvider>
        <KycConfirmationProvider>
          <KycComplianceProvider>
            <KycUIProvider>
              <KycComplianceContainer userId={userId} />
            </KycUIProvider>
          </KycComplianceProvider>
        </KycConfirmationProvider>
      </KycFileProvider>
    </KycV2Provider>
  );
};
