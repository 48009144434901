import React, { useEffect, useMemo } from "react";
import { shallowEqual, useDispatch } from "react-redux";
import * as actions from "../../../_redux/forms/formsActions";
import { useSubheader } from "../../../../../../_metronic/layout";
import { useFormsUIContext } from "../FormsUIProvider";
import { useIntl } from "react-intl";
import { useHistory, useParams } from "react-router-dom";
import { PersonalInfoFilloutCard } from "../form-personal-information/PersonalInfoFilloutCard";
import { AuthRequestKycAmlFilloutCard } from "../form-kyc-aml/AuthRequestKycAmlFilloutCard";
import { useAppSelector } from "../../../../../../redux/hooks";
import { translateBreadcrumbs } from "../../../../../_utils/routeUtils";
import { set } from "lodash-es";

export function FormEdit({ nextStep, showOnly = false }) {
  let history = useHistory();
  let { id } = useParams();
  const intl = useIntl();
  const formsUIContext = useFormsUIContext();
  const formsUiProps = useMemo(() => {
    return {
      title: formsUIContext.title,
      breadcrumbs: formsUIContext.breadcrumbs,
      formType: formsUIContext.formType,
      formData: formsUIContext.formData,
      setFormData: formsUIContext.setFormData,
    };
  }, [formsUIContext]);

  const { title, breadcrumbs, formType, formData } = formsUiProps;

  // Subheader
  const dispatch = useDispatch();
  const subheader = useSubheader();

  useEffect(() => {
    if (breadcrumbs) {
      subheader.setBreadcrumbs(translateBreadcrumbs(intl, breadcrumbs));
    }
  }, []);

  const { actionNotFound, originalFormForEdit, session, action, isFormCompleted } = useAppSelector(
    (state) => ({
      actionNotFound: state.forms.actionNotFound,
      originalFormForEdit: state.forms.formForEdit?.saved,
      formForEdit: state.forms.formForEdit?.current,
      session: state.auth.session,
      action: state.forms.action?.current,
      isFormCompleted: state.forms.action?.current?.status === "DONE",
    }),
    shallowEqual
  );

  useEffect(() => {
    if (!formData && !action) {
      dispatch(actions.fetchAction(session.id, id));
    }
  }, [session.id]);

  useEffect(() => {
    if (actionNotFound) {
      history.push(`/unavailable`);
    }
  }, [actionNotFound]);

  useEffect(() => {
    subheader.setTitle(title ? intl.formatMessage({ id: title }) : "");
  }, [originalFormForEdit, id]);

  const saveFormFields = (key, value) => {
    if (Array.isArray(key) && key.length === value.length) {
      for (const k of Object.keys(key)) {
        set(formData, `fields.${key[k]}`, value[k]);
      }
    } else {
      set(formData, `fields.${key}`, value);
    }
  };

  const submitForm = () => {
    const form = {
      ...formData,
      actionId: id,
      userId: action.userId,
    };
    formsUiProps.setFormData(form);
    nextStep();
  };

  switch (formType) {
    case "FILLOUT_AML_KYC_FORM":
      return (
        <AuthRequestKycAmlFilloutCard
          title={title}
          session={session}
          submitForm={nextStep}
          saveFormFields={saveFormFields}
          disabled={isFormCompleted}
        />
      );
    case "FILLOUT_PERSONAL_INFORMATION":
      return (
        <PersonalInfoFilloutCard
          showOnly={showOnly}
          title={title}
          session={session}
          submitForm={submitForm}
          saveFormFields={saveFormFields}
          disabled={isFormCompleted}
        />
      );
    default:
      return <></>;
  }
}
