import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import SVG from "react-inlinesvg";
import { useTranslation } from "../../../hooks/useTranslation";
import * as React from "react";
import cn from "clsx";

export enum KycInfoLevel {
  INFO = "INFO",
  WARN = "WARN",
  ERROR = "ERROR",
}

interface KycInfoLevelProps {
  icon: string;
  iconColor: string;
  textColor: string;
}

const mInfoLevels: Record<KycInfoLevel, KycInfoLevelProps> = {
  [KycInfoLevel.INFO]: {
    icon: "/media/svg/icons/Code/Info-circle.svg",
    iconColor: "svg-icon-info",
    textColor: "text-info",
  },
  [KycInfoLevel.WARN]: {
    icon: "/media/svg/icons/Code/Warning-2.svg",
    iconColor: "svg-icon-warning",
    textColor: "text-warning",
  },
  [KycInfoLevel.ERROR]: {
    icon: "/media/svg/icons/Code/Warning-1-circle.svg",
    iconColor: "svg-icon-danger",
    textColor: "text-danger",
  },
};

type InfoBoxProps = {
  className?: string;
  label?: string;
  infoLabel?: string;
  textAlign?: string;
  level?: KycInfoLevel;
};

export const KycInfoBox = ({
  label,
  className,
  infoLabel,
  textAlign,
  level = KycInfoLevel.INFO,
}: InfoBoxProps): JSX.Element => {
  const { fm } = useTranslation();

  const levelInfo = mInfoLevels[level];
  // e.g. "/media/svg/icons/Code/Warning-1-circle.svg"
  const levelIcon = levelInfo.icon;
  // e.g. "svg-icon-danger"
  const levelIconColor = levelInfo.iconColor;
  // e.g. "text-danger"
  const levelTextColor = levelInfo.textColor;

  return (
    <>
      {label && (
        <div
          className={cn(
            "d-flex flex-wrap flex-sm-wrap flex-md-wrap flex-lg-nowrap justify-content-center pb-lg-1 pl-lg-4 pr-lg-2 pl-sm-0 pr-sm-0",
            className
          )}
        >
          <label className="col-lg-6 col-form-label text-left h-0" />
          <div
            className={cn(
              "col-lg-6",
              textAlign ? `text-${textAlign}` : "text-justify",
              "pl-lg-4 pr-lg-4 pl-sm-0 pr-sm-0 pt-lg-1 pb-2"
            )}
          >
            <div>
              <span className={cn("svg-icon svg-icon-md mr-1", levelIconColor)}>
                <SVG src={toAbsoluteUrl(levelIcon)} />
              </span>
              <span className={levelTextColor}>{fm(label)}</span>
            </div>
          </div>
        </div>
      )}
      {infoLabel && <KycInfoBox label={infoLabel} className="mb-4" />}
    </>
  );
};
