import * as React from "react";
import { Row } from "@tanstack/react-table";
import { InstalmentsTableRow } from "../BudgetInstalments";
import { CardTableDropdownActions } from "../../../../_components/CardTable/components/buttons/CardTableDropdownActions/CardTableDropdownActions";
import { useFormikContext } from "formik";
import { useConfirmationDialogContext } from "../../../../_components/ConfirmationDialog";
import { useBudgetsUIContext } from "../../BudgetsUIContext";
import { IBudget } from "../../../../../data/schemas";
import { useIntl } from "react-intl";
import { useAppSelector } from "../../../../../redux/hooks";

export interface BudgetInstalmentActionsProps {
  row: Row<InstalmentsTableRow>;
  disabled: boolean;
}

export const BudgetInstalmentActions: React.FC<BudgetInstalmentActionsProps> = ({
  row,
  disabled,
}) => {
  const { setConfirmationDialog } = useConfirmationDialogContext();
  const { saveBudgetFields } = useBudgetsUIContext();
  const { handleChange, values } = useFormikContext<IBudget>();
  const intl = useIntl();

  const { financialDocuments } = useAppSelector((state) => ({
    financialDocuments: state.projects?.projectForEdit?.current?.leadsFinancialDocuments,
  }));

  const instalmentAlreadyInvoiced = React.useMemo(
    () =>
      financialDocuments?.some(
        (f) =>
          f.fileType === "INVOICE" &&
          f.content?.some(
            (invoiceLine: any) =>
              invoiceLine.budgetInstalmentId === values.budgetInstalments[row.original.sequence!].id
          )
      ),
    [financialDocuments, row.original.sequence]
  );

  const removeInstalment = (updatedArray: any[]) => {
    if (instalmentAlreadyInvoiced) {
      setConfirmationDialog({
        title: intl.formatMessage({ id: "BUDGET.INSTALMENTS.DELETE" }),
        body: intl.formatMessage({
          id: "BUDGET.INSTALMENTS.REMOVE_INSTALMENT_WARNING",
        }),
        hideButtonLabel: intl.formatMessage({
          id: "COMMON.ACTION.OK",
        }),
        hideButtonClassName: "btn-primary",
      });
    } else {
      saveBudgetFields("budgetInstalments", updatedArray);
    }
  };

  const updateInstalmentOrder = (updatedArray: any[]) => {
    saveBudgetFields("budgetInstalments", updatedArray);
    handleChange({ target: { name: "budgetInstalments", value: updatedArray } });
  };

  return (
    <CardTableDropdownActions disabled={disabled}>
      <CardTableDropdownActions.Move
        row={row}
        arrayToUpdate={values.budgetInstalments}
        moveCallback={updateInstalmentOrder}
      />
      <CardTableDropdownActions.Delete
        confirmDelete={!instalmentAlreadyInvoiced}
        deleteTitle={"BUDGET.LINE.DELETE"}
        row={row}
        arrayToUpdate={values.budgetInstalments}
        removeCallback={removeInstalment}
      />
    </CardTableDropdownActions>
  );
};
