import React from "react";

function SelectionCheckbox({ isSelected, rest }) {
  return (
    <span onClick={(e) => e.preventDefault()}>
      <input type="checkbox" style={{ display: "none" }} readOnly />
      <label className="checkbox checkbox-single">
        <input type="checkbox" {...rest} onChange={() => {}} />
        <span />
      </label>
    </span>
  );
}

function groupingItemOnSelect(props) {
  const { ids, setIds, customerId } = props;
  if (ids.some((id) => id === customerId)) {
    setIds(ids.filter((id) => id !== customerId));
  } else {
    const newIds = [...ids];
    newIds.push(customerId);
    setIds(newIds);
  }
}

// check official documentations: https://react-bootstrap-table.github.io/react-bootstrap-table2/storybook/index.html?selectedKind=Row%20Selection&selectedStory=Custom%20Selection%20Column%20Header%20Style&full=0&addons=1&stories=1&panelRight=0&addonPanel=storybook%2Factions%2Factions-panel
export function getSelectRow(props) {
  const { ids, setIds } = props;
  return {
    mode: "checkbox",
    clickToSelect: true,
    hideSelectAll: true,
    selectionRenderer: ({ rowIndex, ...rest }) => {
      const restToUse = { ...rest, rowkey: rest.rowkey };
      delete restToUse.rowKey;
      return <SelectionCheckbox rest={restToUse} />;
    },
    onSelect: (row, isSelect, rowIndex, e) => {
      const props = { ids, setIds, customerId: row.id };
      return groupingItemOnSelect(props);
    },
  };
}
