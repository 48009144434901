import * as React from "react";
import { useBudgetRevenueContext } from "../../../context/BudgetRevenueContext";
import { sumBy } from "lodash-es";
import {
  CurrencyMetricCard,
  MetricCard,
  MetricCardProps,
} from "../../../../../../_components/MetricCard";

export const ExpectedTotalRevenue: React.FC<MetricCardProps> = (props) => {
  const { soldTotal, unsoldTotal, supplementRevenue } = useBudgetRevenueContext();

  const supplementsTotal = sumBy(supplementRevenue, "sold");
  const total = soldTotal + unsoldTotal + supplementsTotal;
  return (
    <CurrencyMetricCard
      dataCy="input-budget-revenues-expected-total"
      value={total}
      label="BUDGET.REVENUE.EXPECTED_TOTAL"
      {...props}
    >
      <MetricCard.CurrencySubMetric
        dataCy="input-budget-revenues-expected-total-sold-units"
        label="BUDGET.REVENUE.SOLD_UNITS"
        value={soldTotal}
      />
      <MetricCard.CurrencySubMetric
        dataCy="input-budget-revenues-expected-total-unsold-units"
        label="BUDGET.REVENUE.UNSOLD_UNITS"
        value={unsoldTotal}
      />
      <MetricCard.CurrencySubMetric
        dataCy="input-budget-revenues-expected-total-supplements"
        label="BUDGET.REVENUE.SUPPLEMENTS"
        value={supplementsTotal}
      />
    </CurrencyMetricCard>
  );
};
