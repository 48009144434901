import * as React from "react";
import PropTypes from "prop-types";
import { cloneDeep, flatten, isArray, isEqual, isObject, unionBy, uniqWith } from "lodash-es";
import { searchUtils } from "../_utils/searchUtils";
import { useIntl } from "react-intl";
import moment from "moment";
import { useTag } from "../hooks/useTag";
import Fuse from "fuse.js";
import { DateRange } from "../_components/DateRangePicker/DateRangePicker";

export enum SearchTypeValues {
  SEARCH_TEXT_COLUMNS = "text_columns",
  SEARCH_DROPDOWN = "dropdown",
  SEARCH_DATE = "date",
  SEARCH_DATE_RANGE = "date_range",
  SEARCH_CHECKBOX = "checkbox",
}

export type IFilterSearchType =
  | IFilterSearchTypeDateRange
  | IFilterSearchTypeDate
  | IFilterSearchTypeDropdown
  | IFilterSearchTypeCheckBox
  | IFilterSearchTypeTextColumns;

export interface IFilterSearchBase {
  name: string;
  value: string;
  type: SearchTypeValues;
  // current value
  selected?: any;
  // specify if the filter should be show by an "advanced button"
  isAdvanced?: boolean;
}

export interface IFilterSearchTypeTextColumns extends IFilterSearchBase {
  type: SearchTypeValues.SEARCH_TEXT_COLUMNS;
  columns?: { value: string; label: string }[];
  checkTag?: boolean;
}

export interface IFilterSearchTypeDate extends IFilterSearchBase {
  type: SearchTypeValues.SEARCH_DATE;
  isFuture?: boolean;
}

export interface IFilterSearchTypeCheckBox extends IFilterSearchBase {
  type: SearchTypeValues.SEARCH_CHECKBOX;
  isChecked?: boolean;
  // custom validator
  validator: (entity: any, value: any) => boolean;
}

export interface IFilterSearchTypeDropdown extends IFilterSearchBase {
  type: SearchTypeValues.SEARCH_DROPDOWN;
  labelKey?: string;
  values: any[];
  filteredValues?: any[];
  isMultiple?: boolean;
  // To format the value to be displayed
  formatter?: (value: any) => any;
  // To group the values in an option
  groupBy?: (values: any, entities?: any) => any;
  // custom validator instead of basic isEqual
  validator?: (entity: any, value: any) => boolean;
  isSpecificValues?: boolean;
}

export interface IFilterSearchTypeDateRange extends IFilterSearchBase {
  type: SearchTypeValues.SEARCH_DATE_RANGE;
  selected?: DateRange | Partial<DateRange>;
}

interface SearchProviderProps {
  children: React.ReactNode;
  entities?: any[];
}

export interface SearchContextValue {
  setEntities: React.Dispatch<React.SetStateAction<any[]>>;
  entitiesFiltered: any[];
  entities: any[];

  setSearchValues: (value: any, type: SearchTypeValues, key?: string) => void;
  filters: IFilterSearchType[];
  setFilters: (values: IFilterSearchType[]) => void;
}

export const SearchContext = React.createContext<SearchContextValue>({
  setEntities: (values) => undefined,
  entitiesFiltered: [],
  entities: [],
  setSearchValues: (value, type, key) => undefined,
  filters: [],
  setFilters: (values) => undefined,
});

export const SearchProvider: React.FC<SearchProviderProps> = ({
  children,
  entities: defaultEntities,
}) => {
  const intl = useIntl();
  const [filters, setFilters] = React.useState<IFilterSearchType[]>([]);
  const [entities, setEntities] = React.useState<any[]>([]);
  const { tagLinks } = useTag();
  const [entitiesFiltered, setEntitiesFiltered] = React.useState<any[]>(entities);

  React.useEffect(() => {
    if (defaultEntities) {
      setEntities(defaultEntities);
    }
  }, [defaultEntities]);
  const applySearch = (filters: IFilterSearchType[]) => {
    let values = entities ?? [];
    filters?.forEach((filter) => {
      switch (filter.type) {
        case SearchTypeValues.SEARCH_TEXT_COLUMNS:
          const text = filter.selected?.toString();
          values = searchUtils(text, values, filter.columns, intl);
          if (filter.checkTag && text) {
            // for each entity, use the id / type and return those with the text. It has to be joined to the previous result
            const tempo: any[] = [];
            (entities ?? []).forEach((e) => {
              const val = (Object.keys(tagLinks) ?? []).find((key) => key.includes("#" + e.id));
              if (val && tagLinks[val] && tagLinks[val].some((tl) => tl.tagName.includes(text))) {
                tempo.push(e);
              }
            });
            if (tempo.length > 0) values = unionBy(values, tempo, "id");
          }
          // Spec case if tags ?
          break;
        case SearchTypeValues.SEARCH_DROPDOWN:
          if (filter.selected !== undefined && filter.selected !== "*") {
            if (filter.validator !== undefined) {
              values = values.filter((v: any) => filter.validator!(v, filter.selected));
            } else {
              let keys: any = [filter.value];
              let searchedValue = filter.selected;
              // if the value is an object we search differently
              if (filter.selected?.id) {
                keys = [[keys[0], "id"]];
                searchedValue = filter.selected.id;
              }
              const options = {
                includeScore: true,
                keys,
                useExtendedSearch: true,
              };
              values = new Fuse(values, options).search("=" + searchedValue).map((f) => f.item);
            }
          }
          break;
        case SearchTypeValues.SEARCH_DATE:
          if (filter.selected !== undefined && filter.selected !== "*") {
            const splitDateSel = filter.selected.split(",");
            const dateToCompare = moment().add(
              splitDateSel[0] as string,
              splitDateSel[1] as "M" | "y"
            );
            if (filter.isFuture) {
              const currentDt = moment().set("hour", 0).set("minute", 0);
              values = values.filter((v: any) => {
                const filterDt = moment(v[filter.value]);
                return !!v[filter.value] && filterDt > currentDt && filterDt <= dateToCompare;
              });
            } else
              values = values.filter(
                (v: any) => !!v[filter.value] && moment(v[filter.value]) > dateToCompare
              );
          }
          break;
        case SearchTypeValues.SEARCH_CHECKBOX:
          if (!!filter.selected && filter.validator) {
            values = values.filter((v: any) => filter.validator!(v, filter.selected));
          }
          break;
        case SearchTypeValues.SEARCH_DATE_RANGE:
          if (filter.selected !== undefined) {
            values = values.filter(
              (value) =>
                (!filter.selected?.from ||
                  value[filter.value] >= filter.selected.from.toISOString()) &&
                (!filter.selected?.to || value[filter.value] <= filter.selected.to.toISOString())
            );
          }
      }
    });

    return values;
  };

  React.useEffect(() => {
    const updatedFilters = cloneDeep(filters)?.map((filter) => {
      if (filter.type === SearchTypeValues.SEARCH_DROPDOWN) {
        if (!filter.isSpecificValues) {
          const entityValue =
            (entities ?? []).find((e) => e[filter.value] !== undefined)?.[filter.value] ?? "";
          const allFiltersExceptCurrent = filters.filter((f) => f.value !== filter.value);
          const entitiesFilteredExceptCurrent = applySearch(allFiltersExceptCurrent);
          const filteredEntities = entitiesFilteredExceptCurrent
            ?.filter((e) => e[filter.value] !== undefined)
            .map((e) => e[filter.value]);
          filter.filteredValues = isArray(entityValue)
            ? uniqWith(flatten(filteredEntities), isEqual)
            : isObject(entityValue)
            ? uniqWith(filteredEntities, (a, b) => {
                return a.id === b.id;
              })
            : uniqWith(filteredEntities, isEqual);
        }
      }
      return filter;
    });
    if (!!filters && !isEqual(filters, updatedFilters)) {
      setFilters(updatedFilters);
    }
  }, [filters, entities]);

  const setSearchValues = (value: any, type: SearchTypeValues, key: string = "") => {
    setFilters((prevState) => {
      return prevState.map((filter) => {
        if (filter.type === type) {
          switch (filter.type) {
            case SearchTypeValues.SEARCH_TEXT_COLUMNS:
              if (filter.name === key) {
                filter.selected = value;
              }
              break;
            case SearchTypeValues.SEARCH_DROPDOWN:
            case SearchTypeValues.SEARCH_DATE_RANGE:
            case SearchTypeValues.SEARCH_DATE:
              if (filter.value === key) {
                filter.selected = value;
              }
              break;
            case SearchTypeValues.SEARCH_CHECKBOX:
              if (filter.value === key) {
                filter.selected = value;
                filter.isChecked = value === "true";
              }
              break;
          }
        }
        return filter;
      });
    });
  };

  React.useEffect(() => {
    setEntitiesFiltered(applySearch(filters));
  }, [filters, intl.locale, entities]);

  return (
    <SearchContext.Provider
      value={{
        setEntities,
        entitiesFiltered,
        entities,
        setSearchValues,
        filters,
        setFilters,
      }}
    >
      {children}
    </SearchContext.Provider>
  );
};

SearchProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export const SearchConsumer = SearchContext.Consumer;
