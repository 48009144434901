import * as React from "react";
import { useState } from "react";
import { KycComplianceDetailsSection } from "../KycComplianceDetailsSection";
import { KycTranslation } from "../../../KycTranslation";
import { getStatusProperties, KycStatusMode } from "../../../KycTranslationUtils";
import { KycBeneficialOwnerTable } from "./KycBeneficialOwnerTable";
import { KycBoTableMode, KycFlowStatus, KycLoading, KycSignatureType } from "../../../Kyc";
import { useKyc } from "../../../../../hooks/useKyc";
import { Button } from "react-bootstrap";
import { getSignatureByBoId, statusAsArray } from "../../../KycUtils";
import { KycDialogConfig } from "../../KycConfirmationDialogContainer";
import { KycButton } from "../../steps/KycButton";
import { isEmpty } from "lodash-es";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../../../_metronic/_helpers";
import { useKycFlow } from "../../../../../hooks/useKycFlow";
import { useLoading } from "../../../../../hooks/useLoading";
import { useKycConfirmation } from "../../../../../hooks/useKycConfirmation";
import { useTranslation } from "../../../../../hooks/useTranslation";

export const KycBeneficialOwnerSection = (): JSX.Element => {
  const { currentKycFlow } = useKyc();
  const [currentMode, setCurrentMode] = useState<KycBoTableMode>(KycBoTableMode.COMPLIANCE);
  const { values } = useKycFlow();

  const handleCurrentMode = (e: any) => {
    e.preventDefault();
    setCurrentMode(KycBoTableMode.COMPLIANCE);
  };

  const handleCurrentModeSetup = (e: any) => {
    e.preventDefault();
    setCurrentMode(KycBoTableMode.SIGNATURE_REQUEST);
  };

  const createSignatureRequestButton = (
    <>
      {!isEmpty(values?.signatories) && (
        <Button onClick={handleCurrentModeSetup} type="button" className="mr-2 btn-light" size="sm">
          <span className={"svg-icon svg-icon-black "}>
            <SVG src={toAbsoluteUrl("/media/svg/icons/Shopping/Settings.svg")} />
          </span>
        </Button>
      )}
    </>
  );

  const saveButton = (
    <Button size="sm" onClick={handleCurrentMode} className="mx-1 btn-light">
      <i className="fa fa-arrow-left" />
    </Button>
  );

  const getSignatureRequestControls = () => {
    if (currentKycFlow?.status && currentKycFlow?.status !== KycFlowStatus.KYC_REJECTED) {
      if (
        statusAsArray.indexOf(currentKycFlow?.status) >=
          statusAsArray.indexOf(KycFlowStatus.ADMIN_RISK_ASSESSMENT_VALIDATED) &&
        statusAsArray.indexOf(currentKycFlow?.status) <
          statusAsArray.indexOf(KycFlowStatus.BO_DECLARATIONS_SIGNATURE_PENDING)
      ) {
        if (currentMode === KycBoTableMode.COMPLIANCE) {
          return (
            <div className="d-flex">
              {createSignatureRequestButton}
              {launchButton()}
            </div>
          );
        } else if (currentMode === KycBoTableMode.SIGNATURE_REQUEST) {
          return <div className="d-flex">{saveButton}</div>;
        }
      } else if (
        statusAsArray.indexOf(currentKycFlow?.status) ===
        statusAsArray.indexOf(KycFlowStatus.BO_DECLARATIONS_SIGNATURE_PENDING)
      ) {
        return <div className="d-flex">{confirmSignaturesCompleteButton()}</div>;
      }
    }
  };

  //used to enable/disable "Signatures complete" button.
  //the button should only be enabled if user downloaded offline bo declarations
  const isOfflineSignatureNotDownloaded = () => {
    const signatories = currentKycFlow?.signatories;
    const offlineSignatures =
      signatories?.map((s) => {
        return { ...s, signature: getSignatureByBoId(currentKycFlow, s?.id) };
      }) ?? [];
    const offlineNotDownloaded = offlineSignatures?.filter(
      (s) =>
        s?.boDeclaration?.downloadedAt === "" && s?.signature?.type === KycSignatureType.OFFLINE
    );
    return !isEmpty(offlineNotDownloaded);
  };

  const confirmSignaturesCompleteButton = () => {
    return (
      <div className="d-flex justify-content-end">
        <KycButton
          disabled={
            isCurrentlyLoading(KycLoading.SIGNATURES_COMPLETE) || isOfflineSignatureNotDownloaded()
          }
          onClick={(e: any) => handleClick(e, KycDialogConfig.KYC_FLOW_CONFIRM_SIGNATURES_COMPLETE)}
          className="btn-primary"
          label="COMMON.ACTION.SIGNATURES_COMPLETE"
        />
      </div>
    );
  };

  const launchButton = () => {
    return (
      <div className="d-flex justify-content-end">
        <KycButton
          disabled={
            isCurrentlyLoading(KycLoading.SEND_SIGNATURE_REQUEST) ||
            isCurrentlyLoading(KycLoading.GENERATE_KYC_FLOW) ||
            isEmpty(values?.signatories)
          }
          onClick={(e: any) => handleClick(e, KycDialogConfig.KYC_FLOW_SEND_SIGNATURE_REQUEST)}
          className="btn-primary"
          label="COMMON.ACTION.LAUNCH"
        />
      </div>
    );
  };

  const { isCurrentlyLoading } = useLoading();
  const { fm } = useTranslation();
  const { setShowModal, setConfig } = useKycConfirmation();

  const handleClick = (e: any, t: any) => {
    e.preventDefault();
    setConfig({
      type: t,
      kyc: { ...values },
      kycId: values?.id,
    });
    setShowModal(true);
  };

  return (
    <>
      <KycComplianceDetailsSection
        title={KycTranslation.COMPLIANCE_STATUS_BO_DECLARATION_TITLE}
        status={
          !isEmpty(currentKycFlow?.signatories)
            ? getStatusProperties(
                currentKycFlow?.status,
                KycStatusMode.BO_OVERALL,
                fm,
                currentKycFlow
              )
            : { label: "", color: "" }
        }
        controls={<div className="d-flex">{getSignatureRequestControls()}</div>}
      >
        <KycBeneficialOwnerTable mode={currentMode} />
      </KycComplianceDetailsSection>
    </>
  );
};
