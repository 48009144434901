import cn from "clsx";
import { KycContentContainer } from "./KycContentContainer";
import { WizardNavbar } from "../../_components/wizard/WizardNavbar";
import { useWizard } from "../../hooks/useWizard";
import { KycLoading, KycStep } from "./Kyc";
import { Card, CardBody, CardFooter } from "../../../_metronic/_partials/controls";
import * as React from "react";
import { useLoading } from "../../hooks/useLoading";
import { useParams } from "react-router-dom";

export const KycFlowContainerContent = (): JSX.Element => {
  let { id } = useParams<any>();
  const { isCurrentlyLoading } = useLoading();
  const { isExpanded, showControls, currentStepId } = useWizard();
  const isFooterHidden = () => {
    if (currentStepId() === KycStep.STEP_START_FLOW) {
      return true;
    }
  };

  return (
    <Card className={cn(isExpanded && "card-maximized")}>
      <div className="kyc-card-header">
        <h2 className="kyc-card-title">KYC/AML/BO</h2>
      </div>
      <CardBody style={{ padding: 0 }}>
        <KycContentContainer />
      </CardBody>
      {showControls &&
        !(
          isCurrentlyLoading(KycLoading.GET_KYC_FORM) ||
          isCurrentlyLoading(KycLoading.LIST_FILES, id)
        ) && (
          <CardFooter
            style={{
              borderTop: !isFooterHidden() ? "1px solid #F5F5F5" : "none",
              padding: "1rem",
              minHeight: 66,
            }}
          >
            {!isFooterHidden() && <WizardNavbar />}
          </CardFooter>
        )}
    </Card>
  );
};
