/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import { Redirect, Route, Switch, useLocation } from "react-router-dom";

import { Layout } from "../_metronic/layout";

import { ErrorsPage } from "./modules/ErrorsExamples/ErrorsPage";
import { ResourceUnavailablePage } from "./modules/ExceptionPages/ResourceUnavailablePage";
import { AuthPage, Logout } from "./modules/Auth";

import { masterEmailsRestriction } from "./_utils/authUtils";

import { RequestsPage } from "./modules/Requests/pages/RequestsPage";

import { VersionPage } from "./pages/VersionPage";
import BasePage from "./BasePage";

import { Routes as RoutesV2 } from "./v2/Routes";
import { useAuth } from "./v2/contexts";
import { useGetMe } from "./v2/data";

export const Routes = () => {
  const { tokens, user: cognitoUser } = useAuth();
  const isAuthorized = tokens?.accessToken != null;
  const userEmail = cognitoUser?.attributes?.email;

  const { data: user } = useGetMe(tokens?.idToken?.getJwtToken());

  const location = useLocation();

  const redirectQueryParam = new URLSearchParams(location?.search).get("redirect_uri");

  return (
    <Switch>
      <Route path="/version" component={VersionPage} />
      <Route path="/logout" component={Logout} />

      <Route path={"/requests"} component={RequestsPage} />

      {!user ||
      !isAuthorized ||
      (!user.gdprApprovedAt && !masterEmailsRestriction.includes(userEmail)) ? (
        /* Render auth page when user at `/auth` and not authorized. */
        <Route>
          {/* TODO: https://kodehyve.atlassian.net/browse/KHOS-2809 */}
          <AuthPage />
        </Route>
      ) : (
        /* Otherwise redirect to root page (`/`) */
        <Redirect
          strict
          from="/auth"
          to={redirectQueryParam ? decodeURIComponent(redirectQueryParam) : "/"}
        />
      )}

      <Route path="/error" component={ErrorsPage} />

      <Route path="/unavailable" component={ResourceUnavailablePage} />

      {isAuthorized && (
        // TODO: https://kodehyve.atlassian.net/browse/KHOS-2808
        <Layout>
          {user?.useNewDesignSystem && <RoutesV2 />}
          <BasePage />
        </Layout>
      )}
    </Switch>
  );
};
