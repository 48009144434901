import * as React from "react";
import { IProject } from "../../../../../../../data/schemas";
import {
  CurrencyMetricCard,
  MetricCard,
  MetricCardProps,
} from "../../../../../../_components/MetricCard";
import { useBudgetsUIContext } from "../../../../BudgetsUIContext";

export interface RemainingToInvoiceBudgetCardProps {
  canSeeHiddenLine: boolean;
  actualBudget: number;
  project: Partial<IProject>;
}

export const RemainingToInvoiceBudgetCard: React.FC<
  RemainingToInvoiceBudgetCardProps & MetricCardProps
> = ({ canSeeHiddenLine, project, actualBudget, ...props }) => {
  const {
    budgetTotals: { invoiced = 0, discounts = 0 },
  } = useBudgetsUIContext();

  const remainingToInvoice = actualBudget - invoiced - discounts;

  const remainingToInvoicePercentage =
    remainingToInvoice && actualBudget
      ? (remainingToInvoice / (actualBudget - discounts)) * 100
      : 0;
  return (
    <CurrencyMetricCard
      dataCy="input-budget-remaining-to-invoice"
      value={remainingToInvoice}
      label="BUDGET.REMAINING_TO_INVOICE"
      {...props}
    >
      <MetricCard.PercentageSubMetric
        dataCy="input-budget-remaining-to-invoice-percentage"
        value={remainingToInvoicePercentage}
      />
    </CurrencyMetricCard>
  );
};
