import { components, MultiValueProps } from "react-select";
import * as React from "react";
import { IOption } from "../../_utils/interfacesUtils";
import { FC } from "react";

interface TagManagerSelectedProps {
  tagManagerProp: MultiValueProps<IOption, true>;
  displayMore: boolean;
  setDisplayMore: React.Dispatch<boolean>;
  calculatedMaxDisplayedTags: number;
}

export const TagManagerSelected: FC<TagManagerSelectedProps> = ({
  tagManagerProp,
  displayMore,
  setDisplayMore,
  calculatedMaxDisplayedTags,
}) => {
  const isCount = !displayMore && tagManagerProp.index === calculatedMaxDisplayedTags;
  const component = (
    <components.MultiValue {...tagManagerProp}>{tagManagerProp.children}</components.MultiValue>
  );
  // In order to handle the X+ tags we have to add specific code around
  if (isCount) {
    return (
      <div
        className={"cursor-pointer"}
        onMouseDown={() => {
          setDisplayMore(true);
        }}
        data-cy={`tag-selected-display-more`}
      >
        {component}
      </div>
    );
  }
  return (
    <div title={tagManagerProp.data.label} data-cy={`tag-selected-${tagManagerProp.data.label}`}>
      {component}
    </div>
  );
};
