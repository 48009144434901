import { IImmotoolEntity } from "./ImmotoolEntity";
import { IAddress } from "./Address";
import { IBudget } from "./Budget";
import { IUser } from "./User";
import { IPhoto } from "./Photo";
import { IFile, ILinkedFile } from "./File";
import { ICompany } from "./Company";
import { IProduct } from "./Product";
import { TInvoiceStatus } from "./Lead";

export interface IProject extends IImmotoolEntity {
  name?: string;
  description?: string;
  notes?: string;
  selectedBudget?: string;
  address?: IAddress;
  startDate?: Date | null;
  availability?: Date | null;
  constructionYear?: string;
  avatarPictureSetup: boolean;
  avatarPictureUrl?: string;
  avatarPictureUrlExpires?: Date;
  avatarPictureKey?: string;
  products?: IProduct[];
  budgets?: IBudget[];
  leadsFinancialDocuments?: any[];
  subcontractorsFinanceFiles?: SubcontractorsFinanceFiles;
  projectSharings?: IProjectSharing[];
  projectSubcontractors?: any[];
  usersCanAccess?: IUser[];
  files?: IProjectFile[];
  photos?: IProjectPhoto[];
  photosOrder?: string[];
  tasksCount: number;
  tasksDoneCount: number;
  projectOwnerId?: string;
  projectOwner?: ICompany;
}

export enum FinanceFileType {
  QUOTE = "QUOTE",
  SUPPLEMENTARY_AGREEMENT = "SUPPLEMENTARY_AGREEMENT",
  ORDER = "ORDER",
  INVOICE = "INVOICE",
}

export enum SubcontractorFinanceType {
  QUOTE = "quotes",
  SUPPLEMENTARY_AGREEMENT = "supplementary_agreements",
  ORDER = "orders",
  INVOICE = "invoices",
}

export type FinanceFileTypes = `${FinanceFileType}`;
export type SubcontractorFinanceTypes = `${SubcontractorFinanceType}`;

export const FinanceFileTypeToSubcontractorFinance = Object.values(FinanceFileType).reduce<
  Record<FinanceFileType, SubcontractorFinanceType>
>(
  (acc, value) => ({ ...acc, [value]: SubcontractorFinanceType[value] }),
  {} as Record<FinanceFileType, SubcontractorFinanceType>
);

export const isFinanceFile = (x: any): x is FinanceFileType =>
  Object.values(FinanceFileType).includes(x);

export type SubcontractorsFinanceFiles = Record<SubcontractorFinanceTypes, ISFFType>;

export interface ISFFType {
  total: { general: number; public: number };
  data: SSFData;
}

type SSFData = {
  unspecifiedCategory?: ISFFCategory;
} & Record<string, ISFFCategory>;

export interface ISFFCategory {
  sum: number;
  lines: Record<string, ISFFLine>;
  unspecifiedLines?: ISFFLine[];
}

export interface ISFFLine {
  id: string;
  budgetLineId?: string;
  label: string;
  amount: number;
  files: ISFFFile[];
}

export interface ISFFFile {
  id: string;
  friendlyName: string;
  fileType: string;
  amount: number;
  grossAmount: number;
  userId: string;
  userName: string;
  linkedFiles?: ILinkedFile[];
  vat: number;
  invoiceStatus: TInvoiceStatus;
}

export interface IProjectPhoto extends IPhoto {
  projectId: string;
}

export interface IProjectFile extends IFile {
  projectId: string;
}

export interface IProjectSharing extends IImmotoolEntity {
  userId: string;
  projectId: string;
}
