import { API } from "@aws-amplify/api";

import { IAvatar, IAvatarResponse, ISettings, ICompany } from "data/schemas";

//----------------------------------------------------------------------------//

const uploadAvatarImage = async (url: string, image: Blob) => {
  try {
    const fetchResponse = await fetch(url, {
      method: "PUT",
      body: image,
      headers: {
        "Content-Type": "",
      },
    });

    console.log("fetch response:", fetchResponse);
  } catch (e) {
    console.error("fetch error:", e);
  }
};

//----------------------------------------------------------------------------//

export const fetchSettings = async (): Promise<ISettings> => await API.get("API", "/settings", {});

export const updateSettings = async (settings: ISettings): Promise<ISettings> =>
  await API.post("API", "/settings", { body: settings });

export const setSettingsAvatar = async (avatar: IAvatar): Promise<IAvatarResponse> => {
  const { id, fileName, image } = avatar;

  const response = await API.post("API", "/settings/avatar", { body: { id, fileName } });

  await uploadAvatarImage(response.url, image);

  return response;
};

//---//

export const listPropCos = async (): Promise<ICompany[]> =>
  await API.get("API", "/settings/propcos", {});

export const getPropCoById = async (propCoId: string): Promise<ICompany> =>
  await API.get("API", `/settings/propcos/${propCoId}`, {});

export const createPropCo = async (propCo: ICompany): Promise<ICompany> => {
  const body = { ...propCo };
  return await API.post("API", "/settings/propcos", { body });
};

export const updatePropCo = async (propCo: ICompany): Promise<ICompany> => {
  const { id } = propCo;
  const body = { ...propCo };
  return await API.post("API", `/settings/propcos/${id}`, { body });
};

export const deletePropCo = async (propCoId: string): Promise<undefined> =>
  await API.del("API", `/settings/propcos/${propCoId}`, {});

export const setPropCoAvatar = async (avatar: IAvatar): Promise<IAvatarResponse> => {
  const { id, fileName, image } = avatar;

  const response = await API.post("API", `/settings/propcos/${id}}/avatar`, {
    body: { id, fileName },
  });

  await uploadAvatarImage(response.url, image);

  return response;
};
