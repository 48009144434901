import * as React from "react";
import { useDebounce, useDidMount, useInput, useKey } from "rooks";
import { FormattedMessage, useIntl } from "react-intl";

import "./AddNewSubtask.scss";

export interface AddNewSubtaskProps {
  disabled?: boolean;
  onCreate: (subtaskTitle: string) => void;
  onCancel: (action?: any) => void;
}

export const AddNewSubtask: React.FunctionComponent<AddNewSubtaskProps> = ({
  disabled = false,
  onCancel,
  onCreate,
}) => {
  const intl = useIntl();

  const inputRef = React.useRef<HTMLInputElement>(null);
  const createButtonRef = React.useRef<HTMLButtonElement>(null);

  const subtaskTitleInput = useInput();

  const { value: subtasksTitle } = subtaskTitleInput;

  const disableCreateButton = !(subtasksTitle && subtasksTitle.trim().length > 0);

  useDidMount(() => {
    const { current: subtaskTitleInput } = inputRef;
    if (!subtaskTitleInput) return;
    subtaskTitleInput.focus();
  });

  const createHandler = React.useCallback(() => {
    onCreate(subtasksTitle);
  }, [subtasksTitle]);

  const enterPressedHandler = (_: KeyboardEvent) => {
    const { current: subtaskCreateButton } = createButtonRef;
    if (!subtaskCreateButton) return;
    subtaskCreateButton.focus();

    window.setTimeout(() => {
      subtaskCreateButton.dispatchEvent(new Event("click", { bubbles: true, cancelable: true }));
    }, 100);
  };

  const escPressedHandler = (_: KeyboardEvent) => {
    const { current: subtaskTitleInput } = inputRef;
    if (!subtaskTitleInput) return;
    onCancel(false);
  };

  // https://react-hooks.org/docs/useDebounce
  const enterPressedHandlerDebounced = useDebounce(enterPressedHandler, 100);
  const escPressedHandlerDebounced = useDebounce(escPressedHandler, 100);

  // https://react-hooks.org/docs/useKey
  useKey(["Enter"], enterPressedHandlerDebounced, {
    target: inputRef,
    when: !(disableCreateButton || disabled),
  });
  useKey([27 /* esc */], escPressedHandlerDebounced, {
    target: inputRef,
  });

  return (
    <div className="add-new-subtask d-flex flex-column">
      <div className="field">
        <input
          data-cy="input-subtask-create"
          disabled={disabled}
          ref={inputRef}
          type="text"
          className="form-control no-validation"
          placeholder={intl.formatMessage({
            id: "TASK.SUBTASKS.INPUT_PLACEHOLDER",
          })}
          {...subtaskTitleInput}
        />
      </div>
      <div className="actions d-flex flex-row justify-content-end">
        <button
          data-cy="button-subtask-action-cancel"
          type="button"
          className="btn btn-light btn-elevate"
          onClick={onCancel}
        >
          <FormattedMessage id="COMMON.ACTION.CANCEL" />
        </button>
        <button
          data-cy="button-subtask-action-create"
          ref={createButtonRef}
          type="button"
          className="btn btn-primary btn-elevate"
          onClick={createHandler}
          disabled={disableCreateButton || disabled}
        >
          <FormattedMessage id="COMMON.ACTION.CREATE" />
        </button>
      </div>
    </div>
  );
};

export default AddNewSubtask;
