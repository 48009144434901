import React, { useMemo } from "react";
import { useUserFilesUIContext } from "./UserFilesUIContext";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import { isSameUser } from "app/_utils/userUtils";
import { SearchLocal } from "../../../../../_components/Search/SearchLocal";

export function UserFilesFilter() {
  const userFilesUIContext = useUserFilesUIContext();
  const { entityForEdit, session } = useSelector((state) => ({
    entityForEdit: state.users.entityForEdit.current,
    session: state.auth.session,
  }));
  const userFilesUIProps = useMemo(() => {
    return {
      setQueryParams: userFilesUIContext.setQueryParams,
      openNewFileDialog: userFilesUIContext.openNewFileDialog,
      openRequestFileDialog: userFilesUIContext.openRequestFileDialog,
      queryParams: userFilesUIContext.queryParams,
      readOnly: userFilesUIContext.readOnly,
      canUpload: userFilesUIContext.canUpload,
    };
  }, [userFilesUIContext]);

  return (
    <>
      <div className="form-filtration d-md-flex">
        <div className={"margin-bottom-10-mobile w-100"} style={{ maxWidth: "100%" }}>
          <SearchLocal useEntityUIContext={useUserFilesUIContext} />
        </div>
        <div className="text-right margin-bottom-10-mobile d-flex align-items-start mx-md-2 mt-2">
          {!userFilesUIProps.readOnly && !isSameUser(session, entityForEdit) && (
            <button
              type="button"
              className="btn btn-light w-100 w-md-auto mx-1"
              data-cy="button-file-request-document"
              onClick={() => userFilesUIProps.openRequestFileDialog()}
            >
              <FormattedMessage id="FILE.ACTIONS.UPLOAD.REQUEST" />
            </button>
          )}
          {userFilesUIProps.canUpload && (
            <button
              type="button"
              className="btn btn-primary w-100 w-md-auto mx-1"
              data-cy="button-document-upload-new-file"
              onClick={() => userFilesUIProps.openNewFileDialog()}
            >
              <FormattedMessage id="FILE.ACTIONS.UPLOAD.NEW" />
            </button>
          )}
        </div>
      </div>
    </>
  );
}
