export const SUFFIX_EURO_CURRENCY = " €";
export const SUFFIX_EURO_CURRENCY_NO_WHITESPACE = "€";

export const SUFFIX_PERCENTAGE = " %";
export const SUFFIX_PERCENTAGE_NO_WHITESPACE = "%";

export enum Suffix {
  PERCENTAGE = " %",
  EURO = " €",
}
