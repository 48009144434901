import { KycTranslation } from "../../KycTranslation";
import { KycFormTitle } from "../layout/KycFormTitle";
import { KycCenteredStepBodyLayout } from "./KycCenteredStepBodyLayout";
import { Field } from "formik";
import { KycTextarea } from "../controls/kyc-textarea/KycTextarea";

export const KycStepStartWithNote = (): JSX.Element => {
  return (
    <>
      <KycFormTitle label={KycTranslation.MESSAGE_NOTE_FROM_PROPDEV} />
      <KycCenteredStepBodyLayout>
        <Field disabled={true} inline={false} name="clientNotes[0]" component={KycTextarea} />
      </KycCenteredStepBodyLayout>
    </>
  );
};
