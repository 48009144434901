import * as React from "react";
import cn from "clsx";

import { CardBaseProps } from "./definitions";

export interface CardHeaderToolbarProps extends CardBaseProps {}

export const CardHeaderToolbar = React.forwardRef<HTMLDivElement, CardHeaderToolbarProps>(
  ({ className, ...props }, ref) => (
    <div {...{ ref, className: cn("card-toolbar", className), ...props }} />
  )
);

export default CardHeaderToolbar;

// Set display name for debugging.
if (process.env.NODE_ENV !== "production") {
  CardHeaderToolbar.displayName = "CardHeaderToolbar";
}
