import React from "react";
import { KycBoTableMode } from "../../../Kyc";
import "./KycFileItemLangChoose.scss";
import { getLangShort, getSignatoryIndex } from "../../../KycUtils";
import { useKycFlow } from "../../../../../hooks/useKycFlow";
import { LanguageType } from "../../../../../../_metronic/i18n";
import { KycButtonGroup, KycButtonGroupMode } from "./KycButtonGroup";

interface KycFileItemLangChooseProps {
  cell: any;
  signatory: any;
  mode: KycBoTableMode;
}

export const KycFileItemLangChoose = ({
  cell,
  signatory,
  mode,
}: KycFileItemLangChooseProps): JSX.Element => {
  const { values, saveField } = useKycFlow();
  const selectedLang = cell;

  let filesToDisplay = signatory?.files;

  const signatories = values?.signatories;

  const getFileForLang = (filesToDisplay: any[], lang: string) => {
    return filesToDisplay?.find((f) => f.lang === lang || getLangShort(f.lang) === lang);
  };

  const handleSelectLang = (e: any) => {
    const index = getSignatoryIndex(signatories, signatory?.id);
    const lang = e.target.value;
    const fileForLang: any = getFileForLang(filesToDisplay, lang);
    if (index > -1) {
      saveField(e, {
        field: {
          name: `signatories[${index}].boDeclaration`,
          value: {
            ...values?.signatories[index].boDeclaration,
            lang: lang,
            fileId: fileForLang?.id,
          },
        },
        preventDefault: false,
      });
    } else {
      console.warn("no index for signatory");
    }
  };

  const languagesShort = Object.values(LanguageType).map((language) => getLangShort(language));

  return (
    <div className="d-flex align-items-center justify-content-center">
      <KycButtonGroup
        items={languagesShort}
        mode={
          mode === KycBoTableMode.SIGNATURE_REQUEST
            ? KycButtonGroupMode.EDIT
            : KycButtonGroupMode.VIEW
        }
        selectedValue={getLangShort(selectedLang)}
        onClick={(e: any) => handleSelectLang(e)}
      />
    </div>
  );
};
