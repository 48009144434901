import { createContext, FC, ReactNode, useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { CognitoAccessToken, CognitoIdToken, CognitoUser } from "amazon-cognito-identity-js";
import { Analytics, API, Auth } from "aws-amplify";
import { masterEmailsRestriction } from "../../_utils/authUtils";
import { formatDisplayName } from "../../_utils/userUtils";
import { Intercom } from "../../_utils/intercomUtils";
import * as Sentry from "@sentry/react";
import { LayoutSplashScreen } from "../../../_metronic/layout";
import { useGetMe } from "../data";

type CognitoUserGroups = string[];

interface State {
  applicationInitialized: boolean;
  challengeName?: string;
  tokens: { accessToken: CognitoAccessToken; idToken: CognitoIdToken } | null;
  groups: CognitoUserGroups;
  user: CognitoUser | null;
}

// TODO: https://kodehyve.atlassian.net/browse/KHOS-2809
interface AuthContextProps extends State {
  // login: (username: string, password: string) => Promise<void>;
  // logout: () => Promise<void>;
  // register: (email: string, password: string) => Promise<void>;
  // verifyCode: (username: string, code: string) => Promise<void>;
  // resendCode: (username: string) => Promise<void>;
  // forgotPassword: (username: string) => Promise<void>;
  // passwordReset: (username: string, code: string, newPassword: string) => Promise<void>;
  // completeNewPassword: (user: CognitoUser | any, newPassword: string) => Promise<void>;
  resetApplicationInitialized: () => void;
}

export interface AuthProviderProps {
  children: ReactNode;
}

const initialState: State = {
  applicationInitialized: false,
  tokens: null,
  groups: [],
  user: null,
};

export const AuthContext = createContext<AuthContextProps>({
  ...initialState,
  // login: () => Promise.resolve(),
  // logout: () => Promise.resolve(),
  // register: () => Promise.resolve(),
  // verifyCode: () => Promise.resolve(),
  // resendCode: () => Promise.resolve(),
  // forgotPassword: () => Promise.resolve(),
  // passwordReset: () => Promise.resolve(),
  // completeNewPassword: () => Promise.resolve(),
  resetApplicationInitialized: () => {},
});

export const AuthProvider: FC<AuthProviderProps> = (props) => {
  const { children } = props;
  const [state, setState] = useState<State>(initialState);
  const { isLoading: isUserLoading } = useGetMe(state.tokens?.idToken?.getJwtToken());

  useEffect(() => {
    const initialize = async (): Promise<void> => {
      const session = await Auth.currentSession();

      const currentAuthenticatedUser = await Auth.currentAuthenticatedUser();

      const accessToken = session.getAccessToken();
      const idToken = session.getIdToken();

      const email = idToken.payload.email;

      const groups = accessToken.payload["cognito:groups"] || [];

      let user: any = { userTypes: ["MASTER"] };
      if (!masterEmailsRestriction.includes(email)) {
        user = await API.get("API", "/users/me", {});
        // user.lastSeenAt = new Date().toISOString();

        if (user.id) {
          const hostname = window?.location?.hostname || "";
          if (
            user.intercom &&
            !hostname.includes("localhost") &&
            !hostname.includes("testpr") &&
            !hostname.includes(".d.kodehyve.com")
          ) {
            const formattedName = formatDisplayName(user);
            // https://developers.intercom.com/installing-intercom/docs/javascript-api-attributes-objects#section-company-object
            // https://github.com/intercom/example-single-page-app-install/tree/logged-in-react-router
            Intercom.boot({
              user_id: user.id,
              user_hash: user.intercom.hash,
              name: formattedName,
              email: user.email,
              Platform: hostname,
              "User Type": user.userTypes?.length ? user.userTypes[0] : "",
              company: {
                id: user.intercom.companyId,
                name: user.intercom.companyName,
                website: user.intercom.companyWebsite,
              },
            });
          }

          // TODO
          // await usersCrud.update(user);
        }
      }
      await Analytics.updateEndpoint({
        address: email,
        demographic: {
          timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        },
        optOut: "ALL",
        userId: `COGNITO-${accessToken.payload.username}`,
        userAttributes: {
          userTypes: [...user.userTypes],
        },
      });

      if (process.env.REACT_APP_SENTRY_ENABLED === "true") {
        Sentry.setTag("user_id", user.id);
      }

      setState({
        applicationInitialized: true,
        // challengeName?: string;
        tokens: { accessToken, idToken },
        groups,
        user: currentAuthenticatedUser,
      });
    };

    if (!state.applicationInitialized) {
      initialize()
        .then()
        .catch((error) => {
          setState({ ...initialState, applicationInitialized: true });
          return;
        });
    }
  }, [state.applicationInitialized]);

  const resetApplicationInitialized = () => {
    setState({ ...initialState, applicationInitialized: false });
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        // login,
        // logout,
        // register,
        // verifyCode,
        // resendCode,
        // forgotPassword,
        // passwordReset,
        // completeNewPassword,
        resetApplicationInitialized,
      }}
    >
      {state.applicationInitialized && !isUserLoading ? <>{children}</> : <LayoutSplashScreen />}
    </AuthContext.Provider>
  );
};

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export const AuthConsumer = AuthContext.Consumer;

export const useAuth = () => useContext(AuthContext);
