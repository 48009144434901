import * as React from "react";
import { useEffect } from "react";
import { Checkbox } from "../../../../../../../_components/Checkbox";
import { ErrorMessage, Field, useFormikContext } from "formik";
import { IBudgetInstalment, ILead, TSubcontractorFile } from "../../../../../../../../data/schemas";
import { FormattedMessage, useIntl } from "react-intl";
import { CustomSelect } from "../../../../../../../_components/CustomSelect";
import { useDidUpdate } from "rooks";
import { findLeads } from "../../../../../../PropertiesManagement/_redux/leads/leadsCrud";
import { SingleValue } from "react-select";
import { Input } from "../../../../../../../../_metronic/_partials/controls";
import { get, sumBy } from "lodash-es";
import cn from "clsx";
import { ReInvoiceLines } from "./ReInvoiceLines";
import { useUserFilesUIContext } from "../../UserFilesUIContext";
import { v4 as uuid } from "uuid";

export interface UserFileFinanceMarginsProps {
  projectId: string;
  budgetInstalments: IBudgetInstalment[];
  file: TSubcontractorFile;
}

export const UserFileFinanceReInvoice: React.FC<UserFileFinanceMarginsProps> = ({
  projectId,
  budgetInstalments,
  file,
}) => {
  const intl = useIntl();
  const { values, errors, touched, handleChange, setFieldValue, setFieldTouched } =
    useFormikContext<TSubcontractorFile>();
  const [leads, setLeads] = React.useState<ILead[]>([]);
  const [leadsLoading, setLeadsLoading] = React.useState(false);
  const { reInvoicePriceLine } = useUserFilesUIContext();

  useEffect(() => {
    if (projectId) {
      setLeadsLoading(true);
      findLeads({ projectId })
        .then((response) => {
          setLeads(response);
        })
        .finally(() => setLeadsLoading(false));
    }
  }, [projectId]);

  const total = sumBy(values.categories, ({ lines }) => sumBy(lines, "amount"));

  useDidUpdate(() => {
    if (values.enableReInvoicing && !values.reInvoiceFile) {
      const detailLine = reInvoicePriceLine();
      detailLine.unitPrice = total;
      setFieldValue("reInvoiceFile", {
        leadFileId: uuid(),
        budgetInstalmentId: undefined,
        leadId: undefined,
        fileName: undefined,
        details: [detailLine],
        includeFileToLeadFile: undefined,
        useQuantities: false,
      });
    }
  }, [values.enableReInvoicing]);

  const selectLead = (selected: SingleValue<{ label: string; value: string }>) => {
    handleChange({ target: { name: "reInvoiceFile.leadId", value: selected?.value } });
  };

  const selectInstalment = (selected: SingleValue<{ label: string; value: string }>) => {
    handleChange({ target: { name: "reInvoiceFile.budgetInstalmentId", value: selected?.value } });
  };

  return (
    <>
      <Checkbox
        name={"enableReInvoicing"}
        label={"FILE.ACTION.CREATE_SUPPLEMENTARY_AGREEMENT_ACTIVE_FILE"}
        customClasses={"mb-4"}
      />
      {values.enableReInvoicing && values.reInvoiceFile && (
        <div
          className={"bg-info-o-20 mb-4 py-4"}
          style={{ margin: "0 -1.75rem", padding: "0 1.75rem" }}
        >
          <div className="form-group row">
            <div className="col-12">
              <Field
                name="reInvoiceFile.fileName"
                component={Input}
                label={intl.formatMessage({
                  id: "FILE.GENERATED_FILE_NAME_FOR_ACTIVE_FILE",
                })}
              />
            </div>
          </div>
          <div className="form-group row">
            <div className="col-12">
              <ReInvoiceLines totalSubcontractorLines={total} />
            </div>
          </div>
          <div className="form-group row">
            <div className="col-lg-6">
              <label>
                <FormattedMessage id={"LEAD.TITLE.SINGLE"} />
              </label>
              <CustomSelect
                name={"reInvoiceFile.leadId"}
                onChange={selectLead}
                selected={
                  values.reInvoiceFile.leadId
                    ? {
                        label: leads?.find((l) => l.id === values.reInvoiceFile.leadId)?.name ?? "",
                        value: values.reInvoiceFile.leadId,
                      }
                    : null
                }
                isLoading={leadsLoading}
                loadingMessage={() => intl.formatMessage({ id: "COMMON.LOADING" })}
                options={leads?.map((lead) => ({
                  label: lead.name!,
                  value: lead.id!,
                }))}
                styles={{ control: { width: "auto", maxWidth: "none" } }}
                onBlur={() => {
                  setFieldTouched("reInvoiceFile.leadId");
                }}
                classNamePrefix="react-select"
                className={cn(
                  "react-select-container",
                  get(errors, "reInvoiceFile.leadId") &&
                    get(touched, "reInvoiceFile.leadId") &&
                    "is-invalid"
                )}
                disabled={!!file.enableReInvoicing && !!file.reInvoiceFile?.leadFileId}
              />
              <div className="text-danger mt-2">
                <ErrorMessage name={"reInvoiceFile.leadId"} />
              </div>
            </div>
            <div className="col-lg-6">
              <label>
                <FormattedMessage id={"BUDGET.INSTALMENT.SINGLE"} />
              </label>
              <Field
                component={CustomSelect}
                name={"reInvoiceFile.budgetInstalmentId"}
                onChange={selectInstalment}
                selected={
                  values.reInvoiceFile.budgetInstalmentId
                    ? {
                        label:
                          budgetInstalments?.find(
                            (p) => p.id === values.reInvoiceFile.budgetInstalmentId
                          )?.label ?? "",
                        value: values.reInvoiceFile.budgetInstalmentId,
                      }
                    : null
                }
                loadingMessage={() => intl.formatMessage({ id: "COMMON.LOADING" })}
                options={[
                  {
                    label: "",
                    value: "",
                  },
                  ...budgetInstalments?.map((instalment) => ({
                    label: instalment.label,
                    value: instalment.id,
                  })),
                ]}
                styles={{ control: { width: "auto", maxWidth: "none" } }}
                onBlur={() => {
                  setFieldTouched("reInvoiceFile.budgetInstalmentId");
                }}
              />
            </div>
          </div>
          <Checkbox
            name={"reInvoiceFile.includeFileToLeadFile"}
            label={"FILE.ACTION.INCLUDE_UPLOADED_FILE_TO_ACTIVE_FILE"}
            checkboxStyle={"checkbox-light-white"}
          />
        </div>
      )}
    </>
  );
};
