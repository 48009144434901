/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import {getPagesCount} from "../../../_helpers";
import {FormattedMessage} from "react-intl";

export function PaginationLinks({paginationProps}) {
  const {totalSize, sizePerPage, page} = paginationProps;
  const pagesCount = getPagesCount(totalSize, sizePerPage);
  const handlePrevPage = ({page, onPageChange}) => {
    onPageChange(page - 1 <= 0 ? 1 : page - 1);
  };

  const handleNextPage = ({page, onPageChange}) => {
    if (page < pagesCount) {
      onPageChange(page + 1);
    }
  };

  const disabledClass = pagesCount > 1 ? "" : "disabled";
  return (
    <>
      <div className={`d-flex align-items-center flex-wrap py-2 mr-3 ${disabledClass}`}>
        <div className={"mr-2"}>
          {totalSize === 0 ? 0 : 1 + (page - 1) * sizePerPage} -{" "}
          {page * sizePerPage > totalSize ? totalSize : page * sizePerPage}{" "}
          <FormattedMessage id="COMMON.OF" /> {totalSize}
        </div>
        {pagesCount > 1 && (
          <>
            <a
              onClick={() => handlePrevPage(paginationProps)}
              className={"btn btn-icon btn-sm btn-light btn-hover-primary mr-2 my-1 " + (page === 1 && "disabled")}
            >
              <i className="ki ki-bold-arrow-back icon-xs" />
            </a>
            <a
              onClick={() => handleNextPage(paginationProps)}
              className={"btn btn-icon btn-sm btn-light btn-hover-primary mr-2 my-1 " + (page === pagesCount && "disabled")}
            >
              <i className="ki ki-bold-arrow-next icon-xs"></i>
            </a>
          </>
        )}
      </div>
    </>
  );
}
