import * as React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import { useCustomLocationState } from "../../../_utils/useCustomLocationState";

export const DashboardCTAWidget = () => {
  const intl = useIntl();
  const { setFromUrlTo } = useCustomLocationState();

  return (
    <div className={`card card-custom gutter-b`}>
      <div className="card-body d-flex align-items-center justify-content-between flex-wrap">
        <div className="mr-2">
          <div className="svg-icon svg-icon-3x svg-icon-dark mb-2 ml-n2">
            <SVG src={toAbsoluteUrl("/media/svg/icons/Shopping/Chart-line1.svg")} />
          </div>
          <h3 className="font-weight-bolder">
            <FormattedMessage id="DASHBOARD.CTA.TITLE" />
          </h3>
          <div className="text-dark-50 font-size-lg mt-2">
            <FormattedMessage id="DASHBOARD.CTA.SUBTITLE" />
          </div>
        </div>
        <button
          onClick={() =>
            setFromUrlTo({
              url: `/dashboard/invoice-management`,
              name: intl.formatMessage({
                id: "DASHBOARD.TITLE",
              }),
            })
          }
          className="btn btn-primary font-weight-bold py-3 px-6"
        >
          <FormattedMessage id="COMMON.SEE_MORE" />
        </button>
      </div>
    </div>
  );
};
