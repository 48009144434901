import { flexRender, Header } from "@tanstack/react-table";
import cn from "clsx";
import * as React from "react";
import { BaseCellRender } from "./BaseCellRender";
import { CardTableItem } from "../context/CardTableContext";

export interface HeaderRenderProps<T> {
  header: Header<T, unknown>;
  index: number;
}
export const HeaderRender = <T extends CardTableItem>({ header, index }: HeaderRenderProps<T>) => (
  <BaseCellRender
    index={index}
    cell={header}
    customClasses={cn(
      "p-2 font-size-xs font-weight-bold line-height-sm d-flex align-items-end",
      header.column.columnDef.meta?.fixed && "bg-white"
    )}
    render={
      header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())
    }
  />
);
