import { DefaultLayoutPluginProps } from "@react-pdf-viewer/default-layout";
import React from "react";
import { SpecialZoomLevel } from "@react-pdf-viewer/core";
import { renderToolbar } from "../../../Common/PdfViewer/CustomToolbar";
import { useFileViewer } from "../../../../hooks/useFileViewer";
import { PdfViewer } from "../../../Common/PdfViewer/PdfViewer";

interface FileViewerPdfViewerProps {
  defaultZoomLevel?: number | SpecialZoomLevel;
  toolbar?: any;
}

export const FileViewerPdfViewer = ({
  //1.7 = 170% zoom -> good value DIN A4
  defaultZoomLevel = 1.4,
  //set default toolbar to avoid issues with existing parts of the application
  toolbar = renderToolbar as DefaultLayoutPluginProps,
}: FileViewerPdfViewerProps): JSX.Element => {
  const { currentFileShown } = useFileViewer();
  return (
    <PdfViewer
      fileUrl={currentFileShown?.url}
      defaultZoomLevel={defaultZoomLevel}
      toolbar={toolbar}
    />
  );
};
