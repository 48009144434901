export function UserAvatarImg({ user }) {
  const errorHandler = (event) => {
    const imageElement = event.target;
    imageElement.src = "/media/users/blank.png";
    imageElement.alt = "default";
  };

  return (
    <img
      src={user.profilePictureUrl}
      alt="Profile"
      onError={errorHandler}
      tooltip="not available"
    />
  );
}
