import * as React from "react";
import { CardTable } from "../../../../../../_components/CardTable/CardTable";
import { FormattedMessage, useIntl } from "react-intl";
import cn from "clsx";
import { createColumnHelper } from "@tanstack/react-table";
import { CardTableItem } from "../../../../../../_components/CardTable/context/CardTableContext";
import { useLeadContext } from "../LeadContext";
import { useAppSelector } from "../../../../../../../redux/hooks";
import { isUndefined, sumBy } from "lodash-es";
import { ILeadInstalmentsColumns } from "../definitions";
import { NumberInput } from "../../../../../../_utils/formUtils";
import { SUFFIX_EURO_CURRENCY, SUFFIX_PERCENTAGE } from "../../../../../../_utils/suffixUtils";
import { LeadProvisionalPlanHeader } from "./components/LeadProvisionalPlanHeader";

export interface LeadInstalmentsTableRow extends ILeadInstalmentsColumns, CardTableItem {}

const headers = {
  label: "LEAD.PROVISIONAL_PLAN.TABLE_COLUMN.DESCRIPTION",
  instalmentPercentage: "LEAD.PROVISIONAL_PLAN.TABLE_COLUMN.INSTALMENT",
  amount0VAT: "LEAD.PROVISIONAL_PLAN.TABLE_COLUMN.AMOUNT_EXCL_TAX",
  amountDefaultVAT: "LEAD.PROVISIONAL_PLAN.TABLE_COLUMN.AMOUNT_INCL_DEFAULT_VAT",
};

export interface LeadProvisionalPlanProps {}

export const LeadProvisionalPlan: React.FC<LeadProvisionalPlanProps> = (props) => {
  const intl = useIntl();
  const { leadInstalments } = useLeadContext();
  const { leadForEdit, generalSettings } = useAppSelector((state) => ({
    leadForEdit: state.leads.leadForEdit.current,
    generalSettings: state.settings.settingsForEdit.saved,
  }));

  const { sellingPrice, shareOfLandSellingPrice, architectEngineeringFees } = leadForEdit ?? {};
  const constructionPrice =
    (sellingPrice ?? 0) - ((shareOfLandSellingPrice ?? 0) + (architectEngineeringFees ?? 0));

  const settings = leadForEdit?.project?.projectOwner ?? generalSettings;
  const defaultVat = settings?.defaultVat ?? 0;

  const amountDefaultVAT = React.useMemo(() => {
    const shareOfLand = (shareOfLandSellingPrice?? 0);
    return (
      (sumBy(leadInstalments, "amount") - shareOfLand) * (1 + defaultVat) +
      shareOfLand
    );
  }, [leadInstalments, defaultVat, shareOfLandSellingPrice]);

  const tableData: LeadInstalmentsTableRow[] | undefined = React.useMemo(
    () => [
      {
        id: "TOTAL",
        label: intl.formatMessage({ id: "COMMON.TOTAL" }),
        defaultExpanded: true,
        instalmentPercentage: sumBy(leadInstalments, "instalmentPercentage"),
        amount0VAT: sumBy(leadInstalments, "amount"),
        amountDefaultVAT: amountDefaultVAT,
        children: leadInstalments.map((instalment) => ({
          id: instalment.id,
          label: instalment.label,
          instalmentPercentage: instalment.instalmentPercentage,
          amount0VAT: instalment.amount ?? 0,
          amountDefaultVAT:
            instalment.id === "SHARE_OF_LAND"
              ? instalment.amount
              : (instalment.amount ?? 0) * (1 + defaultVat),
        })),
      },
    ],
    []
  );

  const columnHelper = createColumnHelper<LeadInstalmentsTableRow>();

  const columns = React.useMemo(
    () =>
      Object.entries(headers).map(([key, translationKey]) => {
        const typedKey = key as keyof ILeadInstalmentsColumns;
        const column = columnHelper.accessor(typedKey, {
          header: () => (
            <span className={cn(typedKey !== "label" && "ml-auto text-right")}>
              <FormattedMessage id={translationKey} />
            </span>
          ),
          cell: ({ getValue }) => {
            let value = getValue();
            // Empty column render
            if (isUndefined(value)) {
              return "-";
            }
            // Specific column render
            switch (typedKey) {
              case "label":
                return <div className="d-flex align-items-center flex-grow-1">{value}</div>;
              case "instalmentPercentage":
                return (
                  <NumberInput
                    displayType="text"
                    fixedDecimalScale={true}
                    className="font-weight-bold text-right"
                    value={value}
                    decimalScale={2}
                    suffix={SUFFIX_PERCENTAGE}
                  />
                );
              case "amount0VAT":
              case "amountDefaultVAT":
                return (
                  <NumberInput
                    displayType="text"
                    fixedDecimalScale={true}
                    className="font-weight-bold text-right"
                    value={value}
                    decimalScale={2}
                    suffix={SUFFIX_EURO_CURRENCY}
                  />
                );
            }
          },
          meta: {
            minWidth: 110,
            label: intl.formatMessage({ id: translationKey }, { vat: defaultVat * 100 }),
          },
          enableHiding: true,
        });
        switch (typedKey) {
          case "label":
            column.meta!.customWidth = "flex-shrink-0 w-200px w-sm-250px w-md-300px w-lg-350px";
            column.meta!.fixed = true;
            column.enableHiding = false;
            break;
        }
        return column;
      }),
    [intl.locale]
  );

  return (
    <>
      <LeadProvisionalPlanHeader
        sellingPrice={sellingPrice ?? 0}
        constructionPrice={constructionPrice}
        architectEngineeringFeesPrice={architectEngineeringFees ?? 0}
        shareOfLandPrice={shareOfLandSellingPrice ?? 0}
      />
      <div style={{ marginLeft: "-21px", marginRight: "-21px" }}>
        <CardTable
          data={tableData}
          columns={columns}
          multiCardLayout={false}
          canCollapse={false}
          canToggleColumns={false}
          canExpand={false}
          id="LEAD_PROVISIONAL_PLAN"
        />
      </div>
    </>
  );
};
