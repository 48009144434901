/* eslint-disable no-unused-vars */

import { ReactNode } from "react";

export enum KycWorkflow {
  FLOW_KYC_WORKFLOW_COMPLETE_BY_CLIENT = "FLOW_KYC_WORKFLOW_COMPLETE_BY_CLIENT",
  FLOW_KYC_WORKFLOW_COMPLETE_BY_ADMIN = "FLOW_KYC_WORKFLOW_COMPLETE_BY_ADMIN",
}

export enum KycLegalEntityType {
  LEGAL = "LEGAL",
  NATURAL = "NATURAL",
}

export enum KycLegalType {
  LEGAL_TYPE_LU_OR_FOREIGN_COMPANY = "LEGAL_TYPE_LU_OR_FOREIGN_COMPANY",
  LEGAL_TYPE_LISTED_COMPANY = "LEGAL_TYPE_LISTED_COMPANY",
  LEGAL_TYPE_INVESTMENT_FUND = "LEGAL_TYPE_INVESTMENT_FUND",
  LEGAL_TYPE_TRUST = "LEGAL_TYPE_TRUST",
}

export enum KycItemType {
  PERSONAL_INFO = "PERSONAL_INFO",
  SHAREHOLDER_COMPANY = "SHAREHOLDER_COMPANY",
}

export enum KycFlowStatus {
  INIT = "INIT",
  CREATED = "CREATED",
  CLIENT_REVIEW_PENDING = "CLIENT_REVIEW_PENDING",
  CLIENT_REJECT_AND_REVIEW_PENDING = "CLIENT_REJECT_AND_REVIEW_PENDING",
  CLIENT_REVIEW_DONE = "CLIENT_REVIEW_DONE",
  ADMIN_RISK_ASSESSMENT_PENDING = "ADMIN_RISK_ASSESSMENT_PENDING",
  ADMIN_RISK_ASSESSMENT_DECLINED = "ADMIN_RISK_ASSESSMENT_DECLINED",
  ADMIN_RISK_ASSESSMENT_VALIDATED = "ADMIN_RISK_ASSESSMENT_VALIDATED",
  ADMIN_RISK_ASSESSMENT_DONE = "ADMIN_RISK_ASSESSMENT_DONE",
  BO_DECLARATIONS_SIGNATURE_PENDING = "BO_DECLARATIONS_SIGNATURE_PENDING",
  BO_DECLARATIONS_SIGNATURE_DONE = "BO_DECLARATIONS_SIGNATURE_DONE",
  KYC_APPROVED = "KYC_APPROVED",
  KYC_REJECTED = "KYC_REJECTED",
  CANCELED = "CANCELED",
  ADMIN_FILLING_IN = "ADMIN_FILLING_IN",
}

export enum KycSignatureDocumentType {
  BO = "BO",
  KYC = "KYC",
}

export enum KycBoTableMode {
  SIGNATURE_REQUEST = "SIGNATURE_REQUEST",
  COMPLIANCE = "COMPLIANCE",
}

export enum KycNotificationDestination {
  SMS = "SMS",
  EMAIL = "EMAIL",
  OFFLINE = "OFFLINE",
}

export enum KycSignatureType {
  ONLINE = "ONLINE",
  OFFLINE = "OFFLINE",
}

export enum KycComplianceViewMode {
  CREATED = "CREATED",
  COMPLIANCE_DETAILS = "COMPLIANCE_DETAILS",
  FORM = "FORM",
  RISK_ASSESSMENT = "RISK_ASSESSMENT",
}

export interface KycConfirmationDialogConfiguration {
  fnAction?: any;
  fnParams?: any;
  isFnAsync?: boolean;
  labelTitle: string;
  labelValues?: any;
  labelContent: string;
  labelLoading?: string;
  labelActionButton: string;
  colorActionButton?: "primary" | "danger" | "success" | "warning" | "info" | "light";
  isLoading: KycLoading;
  isLoadingId?: string;
  isLoadingData?: KycLoading;
  disabled?: boolean;
  children?: ReactNode;
  func?: Function;
  funcParams?: any;
}

export interface Address {
  addressLine1?: string;
  addressLine2?: string;
  city?: string;
  stateOrRegion?: string;
  postalCode?: string;
  countryCode?: string;
  country?: string;
}

export interface KycUser {
  id: string;
  title?: string;
  firstName: string;
  lastName: string;
  displayName?: string;
  companyName?: string;
  mobile?: string;
  email?: string;
}

export interface KycFileOwner extends KycUser {}

export interface KycClientLeadProduct {
  id: string;
  name: string;
}

export interface KycClientLeadProjectOwner {
  id: string;
  name: string;
  companyType: string;
}

export interface KycClientLeadProject {
  id: string;
  projectOwner: KycClientLeadProjectOwner;
  name: string;
}

export interface KycClientLead {
  id: string;
  name: string;
  sellingPrice: number;
  project: KycClientLeadProject;
  product: KycClientLeadProduct;
}

export interface KycPerson {
  id?: string;
  title?: string;
  firstName: string;
  lastName: string;
  displayName?: string;
  mobile?: string;
  email?: string;
  nationality?: string;
  dateOfBirth?: string;
  placeOfBirth: string;
  registrationNumber: string;
  profession?: string;
  countryOfBirth: string;
  passportOrIdNumber: string;
  idValidityDate?: string;
  isPep: string;
  publicMandate: string;
  privateAddress: Address;
  disclaimerCorrectness: {
    isAccepted: boolean;
    acceptedDate: string;
  };
}

export interface Representative extends KycPerson {}

export interface Agent extends KycPerson {
  companyName: string;
  isRepresentingClient: boolean;
}

export interface BigBeneficialOwner {
  numberOfSharesInEntity?: number;
  nominalValue: number;
  nominalValueCurrency: string;
  percentageOfShareCapital: number;
}

export interface SmallBeneficialOwner {
  jobTitle: string;
}

export interface ShareholderCompany {
  id: string;
  name: string;
  legalForm: string;
  countryCodeEstablishment: string;
  address: Address;
  nameOfTradeRegister: string;
  numberInTradeRegister: string;
}

export interface BeneficialOwner extends Representative, SmallBeneficialOwner, BigBeneficialOwner {
  profession: string;
  professionalAddress: Address;
  isBigOwner: string;
  isShareholderOfClientCompany: boolean;
  externalCompanies: ShareholderCompany[];
  id: string;
}

export interface KycCompany {
  name: string;
  vatNumber?: string;
  //misleading variable name, but can't be changed without migration script etc.
  //registrationNumber is the name of the trade register (e.g. RCS Luxembourg)
  registrationNumber?: string;
  //what is referred to nationalRegistrationNumber, is basically the number in the trade register
  //for the specific company.
  nationalRegistrationNumber?: string;
}

export interface KycNotification {
  deadline: string;
  methodsUsed: any[];
  isRequestSent: boolean;
  sentAt?: string;
}

export interface KycClient extends KycPerson {
  lead: KycClientLead | null;
  isRepresentedByAgent: string;
  agents: Agent[];
  privateAddress: Address;
}

//Note: a "LEGAL person" => the entity is a company
export interface KycClientLegal extends Partial<KycClient> {
  legalEntityType?: KycLegalEntityType.LEGAL | null;
  legalType: KycLegalType | null;
  name: string;
  legalForm: string;
  company: KycCompany;
  website: string;
  representatives: Representative[];
  beneficialOwners: BeneficialOwner[];
  isBigOwner: string;
  lead: KycClientLead | null;
}

//Note: a "NATURAL person" => the entity is an individual (a single person)
export interface KycClientNatural extends KycClient {
  legalEntityType: KycLegalEntityType.NATURAL | null;
  isMarried: string;
  isMarriedMatrimonialRegime: string;
  isMarriedAcquisitionMartialHome: string;
  profession: string;
  professionalAddress: Address;
}

export interface KycForm {
  acceptTerms: {
    isAccepted: boolean;
    isNextClicked: boolean;
    acceptedAt: string;
  };
  client?: any;
  referenceId: string;
  reference: string;
  transactionDate?: string;
  transactionAmount?: number;
  dateOfAcquisition?: string;
  purposeAndNature: string;
  clientIs: string;
  counterparty: string;
  workflowChoice: KycWorkflow;
  isClientPresent: string;
  isClientCanUpdate: string;
  originOfFunds: string;
  accurateDescriptionOfOriginOfFunds: string;
  sellingPrice: number;
  notes: string;
}

export enum KycRiskLevel {
  WEAK = "WEAK",
  MEDIUM = "MEDIUM",
  HIGH = "HIGH",
  NONE = "NONE",
}

export interface KycGeoRisk {
  countryCode: string | null;
  lowerRisk: KycRiskCheckItem[];
  higherRisk: KycRiskCheckItem[];
}

export enum KycBoType {
  NATURAL_PERSON = "NATURAL_PERSON",
  LEGAL_PERSON = "LEGAL_PERSON",
  LEGAL_PERSON_MORE_25 = "LEGAL_PERSON_MORE_25",
}

export interface KycBoDeclaration {
  sentAt: string;
  isOfflineSignatory?: boolean;
  fileId: string;
  downloadedAt: string;
  uploadedAt: string;
  lang: string;
  notificationMethods: any[];
}

export interface KycSignatory {
  id: string;
  fullName: string;
  mobile?: string;
  email?: string;
  type?: KycBoType;
  files?: any;
  boDeclaration: KycBoDeclaration;
}

interface KycRiskEvaluation {
  isPep: string;
  negativeWebInfo: string;
  amlKycAdditionalTool: {
    isUsed: string;
    nameOfTool?: string;
  };
  clientRisks: KycRiskCheckItem[];
  clientRisksAdditional: KycRiskCheckItem[];
  clientRiskDetermination: {
    riskLevel: KycRiskLevel;
  };
  geographicRiskDetermination: {
    registeredOfficeClient: KycGeoRisk;
    registeredOfficeBo: KycGeoRisk;
    placeOfBusinessClient: KycGeoRisk;
    placeOfBusinessBo: KycGeoRisk;
    riskLevel: KycRiskLevel;
  };
  clientRisksActivity: KycRiskCheckItem[];
  clientRisksActivityDomain: string;
  clientActivityRiskDetermination: {
    riskLevel: KycRiskLevel;
  };
  riskLevel: KycRiskLevel;
  remarks: string;
}

export interface KycFlow {
  eventId: string;
  beneficialOwners: any[];
  createdAt?: string;
  createdByUserId?: string;
  createdByUser?: any;
  clientId: string;
  id: string;
  fileOwner: KycFileOwner;
  form: KycForm;
  notification: KycNotification;
  taskId: string;
  link: string;
  events?: any;
  status: KycFlowStatus;
  updatedAt?: string;
  internalNotes: [];
  clientNotes: [];
  signatures: [];
  signatories: KycSignatory[];
  propDevCompany: any;
  riskEvaluation: KycRiskEvaluation;
  isDirty: boolean;
  files: any[];
  kycDocument: any;
  lang: string;
  riskAssessmentStatus: KycRiskAssessmentStatus;
  fieldsUpdateHistory: Record<string, any>;
  kycFlowCompanies: any[];
  flowType: KycFlowType | undefined;
}

export enum KycStep {
  STEP_START_FLOW = "STEP_START_FLOW",
  STEP_DISCLAIMER = "STEP_DISCLAIMER",
  STEP_ADMIN_PAGE_1 = "STEP_ADMIN_PAGE_1",
  STEP_ADMIN_PAGE_2 = "STEP_ADMIN_PAGE_2",
  STEP_CLIENT_PAGE_1 = "STEP_CLIENT_PAGE_1",
  STEP_CLIENT_PAGE_2 = "STEP_CLIENT_PAGE_2",
  STEP_CLIENT_PAGE_3 = "STEP_CLIENT_PAGE_3",
  STEP_CLIENT_PAGE_4 = "STEP_CLIENT_PAGE_4",
  STEP_SUCCESS = "STEP_SUCCESS",
  STEP_RISK_EVALUATION = "STEP_RISK_EVALUATION",
  STEP_BO_SIGNATURE_REQUEST = "STEP_BO_SIGNATURE_REQUEST",
  STEP_REJECT_AND_REQUEST_FORM_COMPLETION = "STEP_REJECT_AND_REQUEST_FORM_COMPLETION",
  STEP_START_WITH_NOTE = "STEP_START_WITH_NOTE",
}

export interface KycChoiceOption {
  label: string;
  value: any;
  isDisabled?: boolean;
}

export enum KycLoading {
  CREATE_KYC_FLOW = "CREATE_KYC_FLOW",
  CREATE_KYC_FLOW_REQUEST = "CREATE_KYC_FLOW_REQUEST",
  COMPLETE_KYC_FLOW_PHASE = "COMPLETE_KYC_FLOW_PHASE",
  UPDATE_KYC_FLOW = "UPDATE_KYC_FLOW",
  SIGNATURES_COMPLETE = "SIGNATURES_COMPLETE",
  GET_KYC_FORM = "GET_KYC_FORM",
  GET_KYC_CLIENTS = "GET_KYC_CLIENTS",
  GET_KYC_CLIENT_LEADS = "GET_KYC_CLIENT_LEADS",
  DOWNLOAD_KYC_PACKAGE = "DOWNLOAD_KYC_PACKAGE",
  SAVE_FILE = "SAVE_FILE",
  DELETE_FILE = "DELETE_FILE",
  UPDATE_FILE = "UPDATE_FILE",
  GENERATE_BO = "GENERATE_BO",
  SEND_SIGNATURE_REQUEST = "SEND_SIGNATURE_REQUEST",
  LIST_KYC_FOR_USER_ID = "LIST_KYC_FOR_USER_ID",
  DELETE_KYC_FORM = "DELETE_KYC_FORM",
  UPDATE_KYC_FLOW_FIELD = "UPDATE_KYC_FLOW_FIELD",
  LIST_FILES = "LIST_FILES",
  GET_FILE = "GET_FILE",
  DELETE_KYC_FLOW_ENTITY = "DELETE_KYC_FLOW_ENTITY",
  SAVE_KYC_ENTITY = "SAVE_KYC_ENTITY",
  GENERATE_KYC_FLOW = "GENERATE_KYC_FLOW",
}

export enum KycRiskAssessmentStatus {
  INIT = "INIT",
  IN_PROGRESS = "IN_PROGRESS",
  VALIDATED = "VALIDATED",
  REJECTED = "REJECTED",
}

export enum KycFlowType {
  BUYER = "BUYER",
  SELLER = "SELLER",
}

export enum KycEvent {
  ADMIN_CREATE_KYC = "ADMIN_CREATE_KYC",
  ADMIN_ACCEPT_DISCLAIMER = "ADMIN_ACCEPT_DISCLAIMER",
  ADMIN_UPDATE = "ADMIN_UPDATE",
  CLIENT_UPDATE = "CLIENT_UPDATE",
  ADMIN_SEND_TO_CLIENT_FOR_COMPLETION = "ADMIN_SEND_TO_CLIENT_FOR_COMPLETION",
  OPEN_KYC_FORM = "OPEN_KYC_FORM",
  CLIENT_SEND_TO_ADMIN = "CLIENT_SEND_TO_ADMIN",
  ADMIN_REFUSE_DATA_ADDED_BY_CLIENT_AND_SEND_BACK_FOR_REVISION = "ADMIN_REFUSE_DATA_ADDED_BY_CLIENT_AND_SEND_BACK_FOR_REVISION",
  ADMIN_ACCEPT_DATA_ADDED_BY_CLIENT = "ADMIN_ACCEPT_DATA_ADDED_BY_CLIENT",
  ADMIN_START_RISK_ASSESSMENT = "ADMIN_START_RISK_ASSESSMENT",
  ADMIN_UPDATE_RISK_ASSESSMENT = "ADMIN_UPDATE_RISK_ASSESSMENT",
  ADMIN_ACCEPT_RISK_ASSESSMENT = "ADMIN_ACCEPT_RISK_ASSESSMENT",
  ADMIN_REJECT_RISK_ASSESSMENT = "ADMIN_REJECT_RISK_ASSESSMENT",
  ADMIN_SEND_PDF_FOR_BO = "ADMIN_SEND_PDF_FOR_BO",
  ADMIN_DOWNLOAD_PDF_FOR_BO = "ADMIN_DOWNLOAD_PDF_FOR_BO",
  BO_SIGN_PDF_BO = "BO_SIGN_PDF_BO",
  ADMIN_UPLOAD_PDF_BO_SIGNED = "ADMIN_UPLOAD_PDF_BO_SIGNED",
  ADMIN_DOWNLOAD_FILE = "ADMIN_DOWNLOAD_FILE",
  ADMIN_APPROVE_KYC = "ADMIN_APPROVE_KYC",
  ADMIN_CANCEL_KYC = "ADMIN_CANCEL_KYC",
  ADMIN_REJECT_KYC = "ADMIN_REJECT_KYC",
}

export enum KycStepEvent {
  ADMIN_CREATE_KYC = "ADMIN_CREATE_KYC",
  ADMIN_ACCEPT_DISCLAIMER = "ADMIN_ACCEPT_DISCLAIMER",
  ADMIN_UPDATE = "ADMIN_UPDATE",
  CLIENT_UPDATE = "CLIENT_UPDATE",
  ADMIN_SEND_TO_CLIENT_FOR_COMPLETION = "ADMIN_SEND_TO_CLIENT_FOR_COMPLETION",
  OPEN_KYC_FORM = "OPEN_KYC_FORM",
  CLIENT_SEND_TO_ADMIN = "CLIENT_SEND_TO_ADMIN",
  ADMIN_REFUSE_DATA_ADDED_BY_CLIENT_AND_SEND_BACK_FOR_REVISION = "ADMIN_REFUSE_DATA_ADDED_BY_CLIENT_AND_SEND_BACK_FOR_REVISION",
  ADMIN_ACCEPT_DATA_ADDED_BY_CLIENT = "ADMIN_ACCEPT_DATA_ADDED_BY_CLIENT",
  ADMIN_START_RISK_ASSESSMENT = "ADMIN_START_RISK_ASSESSMENT",
  ADMIN_UPDATE_RISK_ASSESSMENT = "ADMIN_UPDATE_RISK_ASSESSMENT",
  ADMIN_ACCEPT_RISK_ASSESSMENT = "ADMIN_ACCEPT_RISK_ASSESSMENT",
  ADMIN_REJECT_RISK_ASSESSMENT = "ADMIN_REJECT_RISK_ASSESSMENT",
  ADMIN_ACCEPT_SIGNATURES_COMPLETE = "ADMIN_ACCEPT_SIGNATURES_COMPLETE",
  ADMIN_SEND_PDF_FOR_BO = "ADMIN_SEND_PDF_FOR_BO",
  ADMIN_DOWNLOAD_PDF_FOR_BO = "ADMIN_DOWNLOAD_PDF_FOR_BO",
  BO_SIGN_PDF_BO = "BO_SIGN_PDF_BO",
  ADMIN_UPLOAD_PDF_BO_SIGNED = "ADMIN_UPLOAD_PDF_BO_SIGNED",
  ADMIN_DOWNLOAD_FILE = "ADMIN_DOWNLOAD_FILE",
  ADMIN_APPROVE_KYC = "ADMIN_APPROVE_KYC",
  ADMIN_CANCEL_KYC = "ADMIN_CANCEL_KYC",
  ADMIN_REJECT_KYC = "ADMIN_REJECT_KYC",
  STATUS_CHANGED = "STATUS_CHANGED",
}

export interface KycFile {
  [key: string]: any;
  id: string;
  kycId?: string;
  categoryId?: string;
  friendlyName: string;
  fileExtension?: string;
  mimeType?: string;
  lang?: string;
  url?: string;
  fieldPath?: string;
  freeText?: string;
  option?: string;
  index: number;
  status?: any;
  body?: any;
  name?: string;
  uploadedAt?: string;
  size?: number;
  files?: { en?: string; fr?: string; de?: string };
}

export interface KycEventBody {
  status: KycStepEvent;
  options: any;
}

export interface KycFlowListItem {
  id: string;
  clientId: string;
  createdAt: string;
  updatedAt: string;
  createdByUserId: string;
  lead: KycClientLead;
  status: KycFlowStatus;
  flowType: KycFlowType;
}

export enum DataCyCompPrefix {
  BUTTON = "button",
  RADIO = "radio",
  CHECKBOX = "checkbox",
  DATEPICKER = "datepicker",
  SELECT = "select",
  INPUT = "input",
}

export enum KycEntityType {
  KYC = "KYC",
  REPRESENTATIVE = "REPRESENTATIVE",
  BENEFICIAL_OWNER = "BENEFICIAL_OWNER",
  AGENT = "AGENT",
  ADDITIONAL_DOCUMENTS_LU_OR_FOREIGN_COMPANY = "ADDITIONAL_DOCUMENTS_LU_OR_FOREIGN_COMPANY",
  ADDITIONAL_DOCUMENTS_INVESTMENT_FUND = "ADDITIONAL_DOCUMENTS_INVESTMENT_FUND",
  ADDITIONAL_DOCUMENTS_LISTED_COMPANY = "ADDITIONAL_DOCUMENTS_LISTED_COMPANY",
  ADDITIONAL_DOCUMENTS_TRUST = "ADDITIONAL_DOCUMENTS_TRUST",
  ADDITIONAL_DOCUMENTS_NATURAL_PERSON = "ADDITIONAL_DOCUMENTS_NATURAL_PERSON",
  CLIENT_LEGAL = "CLIENT_LEGAL",
  CLIENT_NATURAL = "CLIENT_NATURAL",
  SHAREHOLDER_COMPANY = "SHAREHOLDER_COMPANY",
}

export interface KycRelatedToEntity {
  id: string;
  type: KycEntityType;
}

export interface KycRiskCheckItem {
  option: KycChoiceOption;
  isChecked: boolean;
  freeText?: string;
}

export interface KycLoadingParams {
  type: KycLoading;
  id?: string;
}

export interface FieldNameValue {
  name: string;
  value: any;
}
