import * as React from "react";
import { Highlighter } from "react-bootstrap-typeahead";
import { FormattedMessage } from "react-intl";

import { ICompany } from "data/schemas";

import { AvatarPlaceholderClassName, SmallAvatarImage } from "app/_components/AvatarImage";

import DisplayPercentageValue from "./DisplayPercentageValue";
import DisplayCountry from "./DisplayCountry";

export interface ProjectOwnerDropdownItemProps {
  isLoading?: boolean;
  company: ICompany;
  searchText?: string;
}

export const ProjectOwnerDropdownItem: React.FunctionComponent<ProjectOwnerDropdownItemProps> = ({
  isLoading = false,
  company,
  searchText: search = "",
}) => {
  const highlightClassName = "bg-light-primary p-0";
  const {
    avatarPictureUrl,
    name,
    defaultVat,
    reducedVat,
    address: { countryCode } = { countryCode: undefined },
  } = company;

  return (
    <div className="d-flex align-items-center flex-grow-1">
      <SmallAvatarImage
        className="avatar"
        isLoading={isLoading}
        placeholderClassName={AvatarPlaceholderClassName.COMPANY}
        url={avatarPictureUrl}
      />

      <div className="d-flex justify-content-center flex-column flex-grow-1 ml-3">
        <div className="font-weight-bold">
          <Highlighter
            {...{
              highlightClassName,
              search,
              children: name ?? "",
            }}
          />
        </div>

        <div className="text-muted d-flex align-items-center justify-content-between flex-grow-1">
          <div className="d-flex align-items-center">
            <FormattedMessage id="COMMON.VAT" />
            <div className="ml-2 d-flex align-items-center">
              {defaultVat ? (
                <>
                  <DisplayPercentageValue value={defaultVat} />
                  {reducedVat && (
                    <div className="ml-2 d-flex align-items-center">
                      <i className="fas fa-compress-alt icon-md" />

                      <DisplayPercentageValue className="ml-1" value={reducedVat} />
                    </div>
                  )}
                </>
              ) : (
                <i className="fas fa-ghost icon-md" />
              )}
            </div>
          </div>

          <DisplayCountry className="ml-3" countryCode={countryCode} />
        </div>
      </div>
    </div>
  );
};

export default ProjectOwnerDropdownItem;
