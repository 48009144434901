import ApexCharts, { ApexOptions } from "apexcharts";
import React, { useEffect } from "react";
import { formatCurrency, roundByTo100 } from "../../../../_utils/mathUtils";
import { IntlShape } from "react-intl";
import { SUFFIX_PERCENTAGE } from "../../../../_utils/suffixUtils";
import { sum } from "lodash-es";

export interface RadialBarChartProps {
  layoutProps: any;
  series: number[];
  labels: string[];
  totalLabel: string;
  totalValue: string;
  id: string;
  intl: IntlShape;
}

export const DonutChart = ({
  layoutProps,
  series,
  labels,
  id,
  totalValue,
  totalLabel,
  intl,
}: RadialBarChartProps) => {
  useEffect(() => {
    const element = document.getElementById(id);
    const roundTo100 = roundByTo100(series, sum(series));
    const mappedRoundTo100: Record<number, number> = series.reduce(
      (acc, val, index) => ({ ...acc, [val]: roundTo100[index] }),
      {}
    );
    const isEmpty = sum(series) === 0;
    const options: ApexOptions = {
      series: isEmpty ? [1] : series,
      chart: {
        height: "290px",
        type: "donut",
      },
      labels,
      dataLabels: {
        enabled: false,
      },
      legend: {
        show: true,
        position: "bottom",
        fontSize: "inherit",
        fontFamily: "inherit",
        fontWeight: "inherit",
        onItemClick: {
          toggleDataSeries: false,
        },
        onItemHover: {
          highlightDataSeries: false,
        },
        markers: {
          radius: 0,
          fillColors: isEmpty
            ? ["transparent"]
            : [layoutProps.colorsThemeBaseInfo, layoutProps.colorsThemeLightInfo],
        },
        labels: {
          colors: isEmpty ? "transparent" : undefined,
        },
      },
      markers: {
        radius: 0,
      },
      tooltip: {
        enabled: !isEmpty,
        fillSeriesColor: false,
        theme: "light",
        y: {
          formatter: (val) => {
            return `${formatCurrency(val, 2, intl)} (${mappedRoundTo100[val] + SUFFIX_PERCENTAGE})`;
          },
        },
      },
      plotOptions: {
        pie: {
          expandOnClick: false,
          donut: {
            size: "80%",
            labels: {
              show: true,
              name: {
                show: true,
                fontSize: "14px",
                fontWeight: 400,
                offsetY: 20,
              },
              value: {
                show: true,
                fontSize: "22px",
                fontWeight: 600,
                offsetY: -20,
              },
              total: {
                show: true,
                showAlways: true,
                fontFamily: layoutProps.fontFamily,
                label: totalLabel,
                formatter: () => totalValue,
              },
            },
          },
        },
      },
      states: {
        hover: {
          filter: {
            type: "none",
          },
        },
        active: {
          filter: {
            type: "none",
          },
        },
      },
      colors: isEmpty
        ? [layoutProps.colorsGrayGray300]
        : [layoutProps.colorsThemeBaseInfo, layoutProps.colorsThemeLightInfo],
    };
    const chart = new ApexCharts(element, options);
    chart.render();
    return function cleanUp() {
      chart?.destroy();
    };
  }, [layoutProps, series]);

  return <div id={id}></div>;
};
