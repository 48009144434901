import * as React from "react";
import { useEffect } from "react";

import { ITask } from "data/schemas";

import { useAppSelector } from "redux/hooks";

import { ISelectedSort, tasksWithSubtasksUtils } from "app/_utils/tasks";

import { TasksContentViewMode, TTasksContentViewMode } from "./TasksSwitchContentView";
import { TasksBoard } from "./TasksBoard";
import { TasksGanttChart } from "./TasksGanttChart";
import { useSearch } from "../../../../../../hooks/useSearch";
import { useTag } from "../../../../../../hooks/useTag";

export interface TasksCardContentProps {
  isTab?: boolean;
  filterText?: string;
  selectedSort: ISelectedSort;
  contentMode: TTasksContentViewMode;
  relatedTo?: string;
}

export const InnerTasksCardContent: React.FunctionComponent<TasksCardContentProps> = ({
  isTab = false,
  filterText,
  selectedSort,
  contentMode,
  relatedTo,
}) => {
  const { addTagLink } = useTag();
  const { setEntities, entitiesFiltered } = useSearch();
  const { isLoading, rawTasks = [] } = useAppSelector((state) => ({
    isLoading: state.tasks.listLoading as boolean,
    rawTasks: state.tasks.entities as ITask[],
  }));
  const refinedTasks = entitiesFiltered;
  const isTasksLoading = isLoading && !rawTasks?.length;

  useEffect(() => {
    setEntities(rawTasks);

    // browsing tasks to store tags
    for (const task of rawTasks ?? []) {
      if (task.tags) {
        for (const tag of task.tags) {
          addTagLink(tag);
        }
      }
    }
  }, [rawTasks]);

  const tasks = React.useMemo(
    () => (refinedTasks.length > 1 ? tasksWithSubtasksUtils(refinedTasks) : refinedTasks),
    [refinedTasks]
  );

  return contentMode === TasksContentViewMode.COLUMNS ? (
    <TasksBoard
      {...{
        isTab,
        filterText,
        selectedSort,
        isTasksLoading,
        tasks,
        rawTasks: refinedTasks,
        relatedTo,
      }}
    />
  ) : (
    <TasksGanttChart {...{ filterText, selectedSort, isTasksLoading, tasks }} />
  );
};

export const TasksCardContent = React.memo(InnerTasksCardContent);

export default TasksCardContent;
