import React, { useMemo } from "react";
import { ProductFilesFilter } from "./ProductFilesFilter";
import { ProductFilesTable } from "./ProductFilesTable";
import { ProductFileEditDialog } from "./product-file-edit-dialog/ProductFileEditDialog";
import { useProductFilesUIContext } from "./ProductFilesUIContext";
import { ProductRequestSignature } from "./product-file-edit-dialog/ProductRequestSignature";
import { SearchProvider } from "../../../../../contexts/SearchContext";

export function ProductFiles() {
  const productFilesUIContext = useProductFilesUIContext();
  const productFilesUIProps = useMemo(() => {
    return { readOnly: productFilesUIContext.readOnly };
  }, [productFilesUIContext]);

  return (
    <SearchProvider>
      {!productFilesUIProps.readOnly && (
        <>
          <ProductFileEditDialog />
          <ProductRequestSignature />
        </>
      )}
      <div className="form margin-b-30">
        <ProductFilesFilter />
      </div>
      <ProductFilesTable />
    </SearchProvider>
  );
}
