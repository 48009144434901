import * as React from "react";
import { ICombinePlotDetails } from "../../../../../../../../data/schemas";
import { FormattedMessage, useIntl } from "react-intl";
import { FormSectionField, FormSectionRegistryRow } from "../FormSection";
import { NumberInput } from "../../../../../../../_utils/formUtils";
import { Field } from "formik";
import { Input, Select } from "_metronic/_partials/controls";
import { MultiSelect } from "../../../../../../../_components/MultiSelect";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../../../../../_metronic/_helpers";
import { IOption } from "../../../../../../../_utils/interfacesUtils";
import { SUFFIX_EURO_CURRENCY } from "../../../../../../../_utils/suffixUtils";
import { OUTDOOR_STATUS, PARKING_TYPE } from "../../../../../../../_utils/listUtils";
import { intersection } from "lodash-es";
import {
  ParkingType,
  SurfaceStatus,
  SurfaceType,
} from "../../../../../../../_utils/propertyDetailsUtils";

interface CombinesPlotsFieldsProps {
  parentName: string;
  disabled: boolean;
  index: number;
  entity: ICombinePlotDetails;
  handle: any;
  removeEntity: Function;
}

export const CombinesPlotsFields: React.FC<CombinesPlotsFieldsProps> = ({
  parentName,
  disabled,
  index,
  entity,
  handle,
  removeEntity,
}) => {
  const { fieldChangeHandler, handleChange, numberFieldChangeHandler } = handle;
  const intl = useIntl();

  const { options, SUFFIX_UNIT_AREA } = React.useMemo(() => {
    return {
      options: [
        SurfaceType.CELLARS,
        SurfaceType.GARDENS,
        SurfaceType.TERRACES,
        SurfaceType.BALCONIES,
        SurfaceType.PARKINGS,
      ].map((key) => {
        return {
          value: key,
          label: intl.formatMessage({ id: `PRODUCT.INFO.${key.toUpperCase()}.SINGULAR` }),
        };
      }),
      SUFFIX_UNIT_AREA: intl.formatMessage({ id: "COMMON.AREA.UNIT" }),
    };
  }, []);

  const updateSurfaceTypes = (options: IOption[]) => {
    const optionFormatted = options.map((option) => option.value);
    const res: any = { ...entity, surfaceTypes: optionFormatted };

    if (optionFormatted.includes(SurfaceType.PARKINGS)) {
      for (const object of [
        { key: "parkingType", defaultData: ParkingType.INDOOR },
        { key: "parkingPrice", defaultData: 0 },
      ]) {
        if (!res.hasOwnProperty(object.key)) {
          res[object.key] = object.defaultData;
        }
      }
    } else {
      for (const key of ["parkingType", "parkingPrice"]) {
        delete res[key];
      }
    }
    if (
      intersection(optionFormatted, [
        SurfaceType.CELLARS,
        SurfaceType.GARDENS,
        SurfaceType.TERRACES,
        SurfaceType.BALCONIES,
      ]).length
    ) {
      if (!res.hasOwnProperty("status")) {
        res.status = SurfaceStatus.COMMON;
      }
    } else {
      delete res.status;
    }
    fieldChangeHandler(handleChange)({
      target: {
        name: `${parentName}.combinePlots.${index}`,
        value: res,
      },
    });
  };

  return (
    <div className={"d-flex justify-content-between my-4 bg-gray-100 h-100"}>
      <div className={"w-100 h-100 pb-4"}>
        <div className={"w-100 mx-2 form-row justify-content-between justify-content-lg-start"}>
          <div className={"p-2"}>
            <label>
              <FormattedMessage id={"PRODUCT.INFO.COMBINE_PLOTS.SINGULAR"} />
            </label>
            <MultiSelect
              options={options}
              value={(entity?.surfaceTypes ?? []).map((key) => ({
                value: key,
                label: intl.formatMessage({ id: `PRODUCT.INFO.${key.toUpperCase()}.SINGULAR` }),
              }))}
              onChange={(values: IOption[]) => updateSurfaceTypes(values)}
            />
          </div>
          {entity.surfaceTypes.find((key) => key !== SurfaceType.PARKINGS) && (
            <FormSectionField className={"col-sm-6 col-md-2 mt-3"}>
              <Select
                name={`${parentName}.combinePlots.${index}.status`}
                value={entity?.status}
                onChange={fieldChangeHandler(handleChange)}
                disabled={disabled}
                customFeedbackLabel={true}
                label={<FormattedMessage id={"COMMON.STATUS"} />}
              >
                {OUTDOOR_STATUS.map((value, index2) => (
                  <option key={index2} value={value.key}>
                    {intl.formatMessage({ id: value.label })}
                  </option>
                ))}
              </Select>
            </FormSectionField>
          )}
        </div>
        <div className={"w-100 mx-2 form-row justify-content-between justify-content-lg-start"}>
          <FormSectionRegistryRow
            surface={{
              value: entity?.surface ?? 0,
              onValueChange: numberFieldChangeHandler({
                name: `${parentName}.combinePlots.${index}.surface`,
                defaultValue: 0,
                handleChange,
              }),
            }}
            thousands={{
              value: entity?.thousands ?? 0,
              onValueChange: numberFieldChangeHandler({
                name: `${parentName}.combinePlots.${index}.thousands`,
                defaultValue: 0,
                handleChange,
              }),
            }}
            landArchitectNumber={`${parentName}.combinePlots.${index}.landArchitectNumber`}
            landRegistryNumber={`${parentName}.combinePlots.${index}.landRegistryNumber`}
            onFieldChange={fieldChangeHandler(handleChange)}
            suffix={{ surface: SUFFIX_UNIT_AREA }}
          />
        </div>

        <div className={"w-100 mx-2 form-row justify-content-lg-start"}>
          <FormSectionField className={"col-sm-6 col-md-5 mt-3"}>
            <Field
              name={`${parentName}.combinePlots.${index}.numberBlockStaircaseLevelCadastreCellar`}
              onChange={fieldChangeHandler(handleChange)}
              label={intl.formatMessage({
                id: "PRODUCT.INFO.NUMBER_BLOCK_STAIRCASE",
              })}
              component={Input}
              disabled={disabled}
            />
          </FormSectionField>
          {entity.surfaceTypes.includes(SurfaceType.PARKINGS) && (
            <FormSectionField
              label={<FormattedMessage id={"COMMON.PRICE"} />}
              className={"col-sm-6 col-md-2 mt-3"}
            >
              <NumberInput
                className="form-control text-right"
                suffix={SUFFIX_EURO_CURRENCY}
                decimalScale={2}
                value={entity?.parkingPrice ?? 0.0}
                onValueChange={numberFieldChangeHandler({
                  name: `${parentName}.combinePlots.${index}.parkingPrice`,
                  handleChange,
                })}
              />
            </FormSectionField>
          )}

          <FormSectionField>
            <Field
              name={`${parentName}.combinePlots.${index}.parcel`}
              onChange={fieldChangeHandler(handleChange)}
              label={intl.formatMessage({
                id: "PRODUCT.INFO.LOT",
              })}
              component={Input}
              disabled={disabled}
            />
          </FormSectionField>
          {entity.surfaceTypes.includes(SurfaceType.PARKINGS) && (
            <FormSectionField className={"col-sm-6 col-md-2 mt-3"}>
              <Select
                name={`${parentName}.combinePlots.${index}.parkingType`}
                value={entity?.parkingType}
                onChange={fieldChangeHandler(handleChange)}
                disabled={disabled}
                customFeedbackLabel={true}
                label={<FormattedMessage id={"COMMON.TYPE"} />}
              >
                {PARKING_TYPE.map((value, index2) => (
                  <option key={index2} value={value.key}>
                    {intl.formatMessage({ id: value.label })}
                  </option>
                ))}
              </Select>
            </FormSectionField>
          )}
        </div>
      </div>
      <div
        className="pb-4 ml-2 d-flex flex-column justify-content-end btn px-0 btn-hover-danger"
        onClick={(e) => removeEntity(e)}
      >
        {!disabled && (
          <button style={{ minHeight: "36px" }} className={"btn btn-icon btn btn-sm my-auto"}>
            <span className="svg-icon svg-icon-md svg-icon-danger">
              <SVG src={toAbsoluteUrl("/media/svg/icons/General/Trash.svg")} />
            </span>
          </button>
        )}
      </div>
    </div>
  );
};
