import React from "react";

import { CommentItem } from "./CommentItem.js";
import { CommentItemCreate } from "./CommentItemCreate";
import { isOwner } from "../../_utils/authUtils";
import { FormattedMessage } from "react-intl";

export const CommentsContainer = ({ task, session, groups }) => {
  return (
    <>
      <div className="title h5">
        <FormattedMessage id="TASK.LABEL.COMMENTS" />
      </div>
      <CommentItemCreate
        task={task}
        session={session}
        draft={task.comments.find((comment) => comment.isDraft && isOwner(session, comment))}
      />
      {task?.comments
        .filter((comment) => !comment.isDraft)
        ?.sort((c1, c2) => new Date(c2.createdAt) - new Date(c1.createdAt))
        .map((comment) => {
          return (
            <CommentItem
              key={`comment-${comment.id}`}
              comment={comment}
              task={task}
              session={session}
              groups={groups}
            />
          );
        })}
    </>
  );
};
