import * as React from "react";
import { CustomCard, CustomCardLine } from "../../../../../../../_components/CustomCard";
import { FormattedMessage } from "react-intl";
import { NumberInput } from "../../../../../../../_utils/formUtils";
import { cloneDeep, get, sumBy } from "lodash-es";
import { SUFFIX_EURO_CURRENCY, SUFFIX_PERCENTAGE } from "../../../../../../../_utils/suffixUtils";
import { ErrorMessage, Field, FieldArray, FieldArrayRenderProps, useFormikContext } from "formik";
import { ReactSortable } from "react-sortablejs";
import { NumberFormatValues } from "react-number-format";
import { UnitSwitchInput } from "../../../../../../../_components/UnitSwitchInput/UnitSwitchInput";
import cn from "clsx";
import TextareaAutosize from "@material-ui/core/TextareaAutosize/TextareaAutosize";
import { DropdownItem } from "../../../../../../../_components/actions-menu/DropdownItem";
import { DropdownButton, InputGroup } from "react-bootstrap";
import {
  isReInvoiceDetailPriceLine,
  TSubcontractorFile,
  ReInvoiceDetail,
  ReInvoiceDetailPriceLine,
  UnitQuantity,
} from "../../../../../../../../data/schemas";
import { useUserFilesUIContext } from "../../UserFilesUIContext";
import { Checkbox } from "../../../../../../../_components/Checkbox";

interface ReInvoiceLinesProps {
  totalSubcontractorLines: number;
}

export const ReInvoiceLines: React.FC<ReInvoiceLinesProps> = ({ totalSubcontractorLines }) => {
  const { values, errors, touched, setFieldValue } = useFormikContext<TSubcontractorFile>();
  const details = values.reInvoiceFile?.details ?? [];
  const { reInvoicePriceLine, reInvoiceDescriptionLine } = useUserFilesUIContext();

  const moveLine = (sortedLines: ReInvoiceDetail[]) => {
    if (sortedLines.length) {
      setFieldValue("reInvoiceFile.details", sortedLines);
    }
  };

  const removeLine = (linesArrayHelpers: FieldArrayRenderProps, lineIndex: number) => {
    linesArrayHelpers.remove(lineIndex);
    details.splice(lineIndex, 1);
  };

  const handleLineValueChange = <
    K extends ReInvoiceDetail = ReInvoiceDetail,
    T extends keyof K = keyof K
  >(
    field: T,
    value: K[T],
    lineIndex: number
  ) => {
    setFieldValue(`reInvoiceFile.details.${lineIndex}.${field}`, value);
  };

  const total = sumBy(details, (detail) =>
    isReInvoiceDetailPriceLine(detail)
      ? (detail.unitPrice ?? 0) * (values.reInvoiceFile?.useQuantities ? detail.quantity : 1)
      : 0
  );

  const totalMargins = sumBy(details, (detail) =>
    isReInvoiceDetailPriceLine(detail)
      ? detail.marginType === "absolute"
        ? detail.margin ?? 0
        : ((values.reInvoiceFile?.useQuantities ? detail.quantity ?? 0 : 1) *
            (detail.unitPrice ?? 0) *
            (detail.margin ?? 0)) /
          100
      : 0
  );

  return (
    <>
      <CustomCard
        parentClassName={"border-light-dark"}
        headerClassName={"bg-info-o-40"}
        header={
          <div className="d-flex form-row flex-grow-1 mr-12">
            <h4 className="pl-4">
              <FormattedMessage id="FILE.DETAILS_SUPPL_AGREEMENT" />
            </h4>
            <div className="col text-right d-flex align-items-center justify-content-end">
              <div>
                <FormattedMessage id="COMMON.MARGIN" />:
                <NumberInput
                  value={totalMargins}
                  suffix={SUFFIX_EURO_CURRENCY}
                  decimalScale={2}
                  displayType="text"
                  className={"font-weight-bold ml-2"}
                />
                <span className="font-size-sm ml-2">
                  (
                  <NumberInput
                    value={total ? (totalMargins / total) * 100 : 0}
                    suffix={SUFFIX_PERCENTAGE}
                    decimalScale={2}
                    fixedDecimalScale={true}
                    displayType="text"
                    className={"font-weight-bold font-size-sm"}
                  />
                  )
                </span>
              </div>
            </div>
          </div>
        }
      >
        <div className="form-row pl-8 pt-2">
          <Checkbox
            name={"reInvoiceFile.useQuantities"}
            label={"FILE.USE_QUANTITIES"}
            checkboxStyle={"checkbox-info"}
          />
        </div>
        <div
          className="form-row pl-8 pr-16 pt-2 font-size-xs font-weight-bold line-height-sm"
          style={{ color: "#6a6a6a" }}
        >
          <div className="col">
            <FormattedMessage id="COMMON.DESCRIPTION" />
          </div>
          {values.reInvoiceFile!.useQuantities && (
            <div className="col max-w-130px">
              <FormattedMessage id="COMMON.QTY" />
            </div>
          )}
          <div className="col max-w-120px">
            <FormattedMessage
              id={values.reInvoiceFile!.useQuantities ? "COMMON.UNIT_PRICE" : "COMMON.AMOUNT"}
            />
          </div>
          {values.reInvoiceFile!.useQuantities && (
            <div className="col max-w-110px">
              <FormattedMessage id="COMMON.AMOUNT" />
            </div>
          )}
          <div className="col max-w-150px">
            <FormattedMessage id="COMMON.MARGIN" />
          </div>
          {values.reInvoiceFile!.useQuantities && (
            <div className="col max-w-110px">
              <FormattedMessage id="COMMON.UNIT_PRICE_INCL_MARGIN" />
            </div>
          )}
          <div className="col max-w-110px">
            <FormattedMessage id="COMMON.NET_AMOUNT" />
          </div>
          <div className="col max-w-55px">
            <FormattedMessage id="COMMON.VAT" />
          </div>
        </div>
        <FieldArray
          name="reInvoiceFile.details"
          render={(linesArrayHelpers) => (
            <>
              <ReactSortable
                list={cloneDeep(details)}
                setList={(sortedLines) => moveLine(sortedLines)}
                swapThreshold={0.65}
                animation={150}
                fallbackOnBody={true}
                handle=".line-handle"
                scroll={true}
                bubbleScroll={true}
              >
                {details?.map((line, lineIndex) => {
                  return (
                    <CustomCardLine
                      removable={details?.length !== 1}
                      draggable={true}
                      remove={() => removeLine(linesArrayHelpers, lineIndex)}
                      key={lineIndex}
                    >
                      <div className="form-row flex-grow-1 align-items-start">
                        <div className={"d-flex col"}>
                          <Field
                            as={TextareaAutosize}
                            name={`reInvoiceFile.details.${lineIndex}.label`}
                            className={cn(
                              "form-control form-control-sm",
                              get(touched, `reInvoiceFile.details.${lineIndex}.label`) &&
                                get(errors, `reInvoiceFile.details.${lineIndex}.label`) &&
                                "border-danger"
                            )}
                            value={line.label}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                              handleLineValueChange("label", e.target.value, lineIndex)
                            }
                          />
                        </div>
                        {isReInvoiceDetailPriceLine(line) &&
                          (() => {
                            const amount =
                              (line.unitPrice ?? 0) *
                              (values.reInvoiceFile?.useQuantities ? line.quantity : 1);
                            const lineTotal =
                              amount +
                              (line.marginType === "absolute"
                                ? line.margin
                                : (amount * line.margin) / 100);
                            return (
                              <>
                                {values.reInvoiceFile!.useQuantities && (
                                  <div className="col max-w-130px d-flex align-items-center">
                                    <InputGroup>
                                      <NumberInput
                                        className="form-control form-control-sm text-right"
                                        value={line.quantity}
                                        onValueChange={(e: NumberFormatValues) =>
                                          handleLineValueChange<ReInvoiceDetailPriceLine>(
                                            "quantity",
                                            e.floatValue ?? 1,
                                            lineIndex
                                          )
                                        }
                                      />
                                      <DropdownButton
                                        as={InputGroup.Append}
                                        title={line.unit}
                                        variant={"outline-secondary"}
                                        size={"sm"}
                                      >
                                        {Object.values(UnitQuantity).map((unit) => (
                                          <DropdownItem
                                            key={unit}
                                            onClick={() =>
                                              handleLineValueChange<ReInvoiceDetailPriceLine>(
                                                "unit",
                                                unit,
                                                lineIndex
                                              )
                                            }
                                            title={unit}
                                          />
                                        ))}
                                      </DropdownButton>
                                    </InputGroup>
                                  </div>
                                )}
                                <div className="col max-w-120px d-flex align-items-center">
                                  <NumberInput
                                    suffix={SUFFIX_EURO_CURRENCY}
                                    className="form-control form-control-sm text-right"
                                    value={line.unitPrice}
                                    onValueChange={(e: NumberFormatValues) =>
                                      handleLineValueChange<ReInvoiceDetailPriceLine>(
                                        "unitPrice",
                                        e.floatValue,
                                        lineIndex
                                      )
                                    }
                                  />
                                </div>
                                {values.reInvoiceFile!.useQuantities && (
                                  <div className="col max-w-110px d-flex align-items-center">
                                    <NumberInput
                                      displayType={"text"}
                                      value={
                                        (line.unitPrice ?? 0) *
                                        (values.reInvoiceFile?.useQuantities ? line.quantity : 1)
                                      }
                                      suffix={SUFFIX_EURO_CURRENCY}
                                      className={"form-control form-control-sm text-right border-0"}
                                      decimalScale={2}
                                    />
                                  </div>
                                )}
                                <div className="col max-w-150px">
                                  <UnitSwitchInput
                                    value={line.margin ?? 0}
                                    unitValue={line.marginType}
                                    onValueChange={(e) =>
                                      handleLineValueChange<ReInvoiceDetailPriceLine>(
                                        "margin",
                                        e.floatValue ?? 0,
                                        lineIndex
                                      )
                                    }
                                    onUnitSwitch={(marginType) =>
                                      handleLineValueChange<ReInvoiceDetailPriceLine>(
                                        "marginType",
                                        marginType,
                                        lineIndex
                                      )
                                    }
                                    units={[
                                      { id: "absolute", label: SUFFIX_EURO_CURRENCY },
                                      { id: "percentage", label: SUFFIX_PERCENTAGE },
                                    ]}
                                  />
                                </div>
                                {values.reInvoiceFile!.useQuantities && (
                                  <div className="col max-w-110px d-flex align-items-center">
                                    <NumberInput
                                      displayType={"text"}
                                      value={lineTotal / line.quantity}
                                      suffix={SUFFIX_EURO_CURRENCY}
                                      className={"form-control form-control-sm text-right border-0"}
                                      decimalScale={2}
                                    />
                                  </div>
                                )}
                                <div className="col max-w-110px d-flex align-items-center">
                                  <NumberInput
                                    displayType={"text"}
                                    value={lineTotal}
                                    suffix={SUFFIX_EURO_CURRENCY}
                                    className={"form-control form-control-sm text-right border-0"}
                                    decimalScale={2}
                                  />
                                </div>
                                <div className="col max-w-55px d-flex align-items-center">
                                  <NumberInput
                                    className="form-control form-control-sm text-right"
                                    suffix={SUFFIX_PERCENTAGE}
                                    decimalScale={0}
                                    value={(line.vat ?? 0) * 100}
                                    onValueChange={(e: NumberFormatValues) =>
                                      handleLineValueChange<ReInvoiceDetailPriceLine>(
                                        "vat",
                                        (e.floatValue ?? 0) / 100 || 0,
                                        lineIndex
                                      )
                                    }
                                  />
                                </div>
                              </>
                            );
                          })()}
                      </div>
                    </CustomCardLine>
                  );
                })}
              </ReactSortable>
              <div className="d-flex rounded rounded-top-0 overflow-hidden">
                <button
                  type="button"
                  className="btn btn-sm btn-light w-50 rounded-0 d-flex align-items-center  justify-content-center border-top-light-dark"
                  onClick={(e) => linesArrayHelpers.push(reInvoicePriceLine())}
                >
                  <i className="ki ki-plus icon-nm" />
                  <FormattedMessage id="LEAD.ACTION.ADD.LINE" />
                </button>
                <button
                  type="button"
                  className="btn btn-sm btn-light w-50 border-left-1 border-left-light-dark flex-shrink-0 rounded-0 d-flex align-items-center  justify-content-center border-top-light-dark"
                  onClick={(e) => linesArrayHelpers.push(reInvoiceDescriptionLine())}
                >
                  <i className="ki ki-plus icon-nm" />
                  <FormattedMessage id="FILE.ADD_DESCRIPTION" />
                </button>
              </div>
            </>
          )}
        />
      </CustomCard>
      <div className="text-danger mt-2">
        <ErrorMessage name={"reInvoiceFile.leadFileDescription"} />
      </div>
    </>
  );
};
