import * as React from "react";
import { FormattedMessage } from "react-intl";
import SVG from "react-inlinesvg";
import cn from "clsx";
import { useMediaMatch } from "rooks";

import { IRequestSignatory } from "data/schemas";

import { toAbsoluteUrl } from "_metronic/_helpers";

import { TooltipHandler } from "app/_components/TooltipHandler";

import { SignatoryForm } from "./SignatoryForm";

//----------------------------------------------------------------------------//

export interface SignatoryProps {
  className?: string;
  signatory: IRequestSignatory;
  onRemoveClick: () => void;
  onUpdate?: (signatory: IRequestSignatory) => void;
}

export const Signatory: React.FunctionComponent<SignatoryProps> = ({
  className,
  signatory,
  onRemoveClick,
  onUpdate,
}) => {
  const isSmallScreen = useMediaMatch("(max-width: 1199.98px)");

  const { signatoryHelperId, avatar, fullName, email, mobile, id } = signatory;

  const isImage = avatar?.startsWith("http");

  return (
    <div className={cn("item", className)} data-cy={`item__${signatoryHelperId}`}>
      <div className="attribute avatar">
        <div
          className={cn("symbol symbol-35 min-w-35px min-h-35px flex-shrink-0", {
            "bg-white-o-30": isImage,
            "symbol-light-primary": !isImage,
          })}
        >
          {isImage ? (
            <img src={avatar} alt="avatar" />
          ) : (
            <span className="symbol-label font-size-h5 font-weight-bold symbol-label">
              {avatar}
            </span>
          )}
        </div>
      </div>

      {id ? (
        <>
          <div className="attribute name">{fullName}</div>

          <div className="attribute email">{email}</div>

          <div className="attribute phone">{mobile ?? "-"}</div>
        </>
      ) : (
        <SignatoryForm signatory={signatory} onUpdate={onUpdate} />
      )}

      <div className="attribute actions">
        <TooltipHandler
          id="remove-signatory-tooltip"
          message={<FormattedMessage id="COMMON.ACTION.REMOVE" />}
        >
          <button
            data-cy={`remove-subtask-name__${signatoryHelperId}`}
            className={cn("btn btn-icon", {
              "btn-light btn-hover-danger btn-sm": !isSmallScreen,
              "btn-remove": isSmallScreen,
            })}
            type="button"
            onClick={onRemoveClick}
          >
            {isSmallScreen ? (
              <i className="button-icon remove fas fa-times"></i>
            ) : (
              <span className="svg-icon svg-icon-md svg-icon-danger">
                <SVG src={toAbsoluteUrl("/media/svg/icons/General/Trash.svg")} />
              </span>
            )}
          </button>
        </TooltipHandler>
      </div>
    </div>
  );
};

export default Signatory;
