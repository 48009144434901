import React from "react";
import { usePublicRequests } from "../hooks/usePublicRequests";
import { useParams } from "react-router-dom";
import { GDPR } from "../../../_components/GDPR";

type HistoryParams = {
  requestId: string;
};

export const PublicAcceptGDPR = () => {
  const { requestId } = useParams<HistoryParams>();
  const { updateGDPR, settings, user } = usePublicRequests();

  const acceptGDPR = () => {
    updateGDPR(requestId).then();
  };

  return <GDPR settings={settings} session={user} acceptGDPR={acceptGDPR} />;
};
