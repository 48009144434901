import { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import { isEqualObjects } from "./isEqualObjects";
import { defaultIgnoredProps } from "./removeObjectProperties";

export const useAutoSave = (entityForEdit, originalEntity, actionToCall, ignoredProps = []) => {
  const [totalIgnoredProps, setTotalIgnoredProps] = useState(defaultIgnoredProps);
  const [updateTimeout, setUpdateTimeout] = useState();
  const action = useCallback((values) => actionToCall(values), [actionToCall]);
  const dispatch = useDispatch();

  useEffect(() => {
    if (entityForEdit && entityForEdit.id && originalEntity) {
      if (!isEqualObjects(entityForEdit, originalEntity, totalIgnoredProps)) {
        clearTimeout(updateTimeout);
        setUpdateTimeout(
          setTimeout(async () => {
            await dispatch(action(entityForEdit));
          }, 1000)
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [action, entityForEdit, originalEntity, totalIgnoredProps]);

  useEffect(() => {
    setTotalIgnoredProps([...defaultIgnoredProps, ...ignoredProps]);
  }, []);
};
