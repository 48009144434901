import * as React from "react";
import cn from "clsx";
import { FormattedMessage, useIntl } from "react-intl";
import { shallowEqual } from "react-redux";

import { useSubheader } from "_metronic/layout";
import { Card, CardBody, CardHeader, CardHeaderToolbar } from "_metronic/_partials/controls";

import { useAppDispatch, useAppSelector } from "redux/hooks";

import { Sorter } from "app/_components/Sorter";

import { defaultSelectedSort, ISelectedSort } from "app/_utils/tasks";
import { canCreate, canReadAll } from "app/_utils/authUtils";
import { TASK_SORTER_OPTION } from "app/_utils/listUtils";

import * as actions from "app/modules/PropertiesManagement/_redux/tasks/tasksActions";

import { useTasksUIContext } from "app/modules/PropertiesManagement/pages/tasks/TasksUIContext";

import { TasksCardContent, TasksContentViewMode, TasksSwitchContentView } from "./TasksCardContent";
import { SearchLocal } from "app/_components/Search/SearchLocal";
import EmptyState from "app/_components/EmptyState/EmptyState";
import ActionNewButton from "app/_components/ActionNewButton";

export enum TasksCardDisplayMode {
  PAGE = "page",
  TAB = "tab",
}

export type TTasksCardDisplayMode = `${TasksCardDisplayMode}`;

export interface TasksCardProps {
  relatedTo?: string;
  displayMode?: TTasksCardDisplayMode;
}

export const TasksCard: React.FunctionComponent<TasksCardProps> = ({
  relatedTo,
  displayMode = TasksCardDisplayMode.PAGE,
}) => {
  const intl = useIntl();
  const dispatch = useAppDispatch();

  const [selectedSort, setSelectedSort] = React.useState<ISelectedSort>(defaultSelectedSort);

  const {
    contentView,
    toggleContentView = () => undefined,
    newTaskButtonClick,
  } = useTasksUIContext();

  const { groups, session, isTasksLoading, tasksLength } = useAppSelector(
    (state) => ({
      groups: state.auth.groups,
      session: state.auth.session,
      isTasksLoading: state.tasks.listLoading,
      tasksLength: state.tasks.entities?.length ?? 0,
    }),
    shallowEqual
  );

  const subheader = useSubheader();

  React.useEffect(() => {
    if (!displayMode) {
      subheader.setTitle(
        intl.formatMessage({
          id: "TASK.TITLE",
        })
      );
    }
  }, [subheader]);

  React.useEffect(() => {
    dispatch(
      actions.fetchTasks({
        intl,
        queryParams: {
          relatedTo: relatedTo,
          deeplyRelated: relatedTo?.startsWith("PROJECT"),
        },
      })
    );
  }, [relatedTo]);

  React.useEffect(() => {
    if (displayMode === TasksCardDisplayMode.TAB) return;
    //fetch options for project filter (only available in global tasks view)
    dispatch(actions.fetchRelatedToItems(intl));
    openRelatedToSelect();
  }, []);

  const openRelatedToSelect = () => {
    actions.fetchRelatedToItems(intl)(dispatch);
  };

  const isTab = displayMode === TasksCardDisplayMode.TAB;

  const cardStyles: React.CSSProperties = {
    ...(isTab ? { boxShadow: "none", minHeight: "60vh", marginTop: "0px" } : {}),
  };

  return (
    <Card className="h-100" style={cardStyles}>
      <CardHeader
        cardHeaderClassName={cn(displayMode === "tab" ? "p-0 mt-0" : "pt-2", "border-0")}
        title={
          <div className="d-flex align-items-center">
            <span>
              <FormattedMessage id="TASK.TITLE.DASHBOARD" />
            </span>
            {isTasksLoading ? (
              <div className="d-flex align-items-center ml-2">
                <span>({tasksLength}+)</span>
                <div className="spinner spinner-track spinner-info spinner-md h-20px" />
              </div>
            ) : (
              <span>({tasksLength})</span>
            )}
          </div>
        }
      >
        <div className="d-flex align-items-end flex-wrap py-1">
          <div className="flex-grow-1 flex-basis-0">
            <Sorter
              disabled={contentView === TasksContentViewMode.GANTT_CHART}
              selectedSort={selectedSort}
              setSelectedSort={setSelectedSort}
              option={TASK_SORTER_OPTION}
              style={{ width: "100%", maxWidth: "100%" }}
              parentStyle={{ width: "100%", maxWidth: "100%" }}
              containerStyle={{ width: "200px" }}
            />
          </div>
          {tasksLength > 0 && (
            <TasksSwitchContentView
              {...{
                mode: contentView,
                onChange: toggleContentView,
                className: "w-sm-100 mt-xs-0 mt-2 min-h-40px ml-2",
              }}
            />
          )}
          {canCreate(groups, session, "TASK") && canReadAll(groups, session, "TASK") && (
            <div className="align-self-end mt-2 ml-2">
              <ActionNewButton
                dataCy="button-tasks-new"
                className="text-nowrap w-100 w-md-auto min-h-40px"
                onClick={newTaskButtonClick}
                messageId="TASK.ACTION.NEW"
              />
            </div>
          )}
        </div>
        <CardHeaderToolbar className="pb-3 pt-0 w-100" style={{ minHeight: 65 }}>
          <div className="d-md-flex justify-content-end w-100">
            <div className="mr-md-3 mt-2">
              <EmptyState
                entitiesLength={tasksLength}
                entityUIContextAction={newTaskButtonClick}
                listLoading={isTasksLoading}
                stateLocation={"TASK"}
              />
            </div>
          </div>
          <div className="w-100">
            <div
              className="d-md-flex flex-wrap flex-md-shrink-1 flex-md-basis-0"
              style={{ flexGrow: "6" }}
            >
              <SearchLocal useEntityUIContext={useTasksUIContext} />
            </div>
          </div>
        </CardHeaderToolbar>
      </CardHeader>
      <div className="position-relative flex-grow-1">
        <CardBody
          className={cn("w-100 h-100 position-absolute d-flex flex-column", {
            "p-0": isTab,
          })}
        >
          <TasksCardContent
            {...{
              isTab,
              relatedTo,
              contentMode: contentView,
              selectedSort: selectedSort,
            }}
          />
        </CardBody>
      </div>
    </Card>
  );
};

export default TasksCard;
