import React, { useEffect, useRef, useState } from "react";
import { Field, Form, Formik } from "formik";
import * as Yup from "yup";
import { DatePickerField, Input } from "../../../../../../_metronic/_partials/controls";
import { FormattedMessage, useIntl } from "react-intl";
import { AddressEditForm } from "../../../../../_components/AddressEditForm";
import { TextEditor } from "../../../../../_components/TextEditor/TextEditor";
import { Urls } from "../../../../../_components/Urls";
import { commentToolbar } from "../../../../../_components/TextEditor/config";
import { useAppSelector } from "redux/hooks";
import {
  filterPriceIndicesProperDate,
  getBasePriceIndex,
  getPriceIndexOptionLabel,
} from "app/_utils/priceIndexUtils";
import TitleWithGuideLink from "app/_components/TitleWithGuideLink";
import { useTranslation } from "app/hooks/useTranslation";
import WarningMessage from "app/_components/WarningMessage";
import WarningModal from "app/_components/WarningModal";

export function ProjectEditForm({
  project,
  submitProject,
  saveProjectFields,
  disabled,
  onSubmitRef,
}) {
  const intl = useIntl();
  const { fm } = useTranslation();

  const [basePriceIndex, setBasePriceIndex] = useState("");
  const [showWarningModal, setShowWarningModal] = useState(false);

  const { generalSettings } = useAppSelector((state) => ({
    generalSettings: state.settings.settingsForEdit.saved,
  }));

  const settings = project?.projectOwner ?? generalSettings;

  const calculateBasePriceIndexLabel = (settings, project, intl) => {
    if (settings && settings.priceIndices && project) {
      const priceIndicesInRange = filterPriceIndicesProperDate(settings.priceIndices, project);
      const basePriceIndexProject = getBasePriceIndex(project);
      if (basePriceIndexProject) {
        return getPriceIndexOptionLabel(
          basePriceIndexProject,
          priceIndicesInRange,
          basePriceIndexProject,
          intl
        );
      }
    }
    return "";
  };

  React.useEffect(() => {
    setBasePriceIndex(calculateBasePriceIndexLabel(settings, project, intl));
  }, [settings, project]);

  const ProjectEditSchema = Yup.object().shape({
    name: Yup.string()
      .required(intl.formatMessage({ id: "AUTH.VALIDATION.PROJECT.NAME.REQUIRED" }))
      .min(2, `${intl.formatMessage({ id: "AUTH.VALIDATION.MIN_CHARACTER" })} 2`)
      .max(250, `${intl.formatMessage({ id: "AUTH.VALIDATION.MAX_CHARACTER" })} 250`),
    description: Yup.string(),
    address: Yup.object().shape({
      addressLine1: Yup.string(),
      addressLine2: Yup.string(),
      city: Yup.string(),
      stateOrRegion: Yup.string(),
      postalCode: Yup.string(),
      countryCode: Yup.string(),
    }),
    startDate: Yup.date().required(intl.formatMessage({ id: "AUTH.VALIDATION.START_DATE" })),
    availability: Yup.date(),
    constructionYear: Yup.number(),
  });

  const customHandleChange = (e) => (handleChange) => {
    if (!!saveProjectFields && !disabled) {
      handleChange(e);
      saveProjectFields(e.target.name, e.target.value);
    }
  };
  const formRef = useRef();

  useEffect(() => {
    if (onSubmitRef) {
      onSubmitRef(() => formRef.current.submitForm());
    }
  }, []);

  return (
    <>
      <Formik
        innerRef={formRef}
        enableReinitialize={true}
        initialValues={{ ...project, basePriceIndex }}
        validationSchema={ProjectEditSchema}
        onSubmit={() => {
          if (!disabled && !!submitProject) {
            submitProject();
          }
        }}
      >
        {({ handleChange, values }) => (
          <Form className="form form-label-right" data-cy="form-project">
            <div className="form-group row">
              <div className="col-lg-12">
                <Field
                  name="name"
                  data-cy="input-project-name"
                  component={Input}
                  onChange={(e) => customHandleChange(e)(handleChange)}
                  placeholder={
                    disabled
                      ? ""
                      : intl.formatMessage({
                          id: "PROJECT.TITLE.NAME",
                        })
                  }
                  label={intl.formatMessage({
                    id: "PROJECT.TITLE.NAME",
                  })}
                  disabled={disabled}
                />
              </div>
            </div>
            <div className="form-group form-row">
              <div className="col-4 d-flex flex-column" data-cy="datepicker-start-date">
                <Field
                  className="form-control react-datepicker-ignore-onclickoutside"
                  name="startDate"
                  component={DatePickerField}
                  onChange={(val) => {
                    if (!val) return;
                    if (project?.products?.some((e) => e.leadInvoices > 0)) {
                      setShowWarningModal(!showWarningModal);
                      return;
                    }
                    customHandleChange({
                      target: { name: "startDate", value: val?.toISOString() ?? null },
                    })(handleChange);
                  }}
                  label={intl.formatMessage({
                    id: "COMMON.START_DATE",
                  })}
                  disabled={disabled}
                  showTimeSelect={false}
                  dateFormat="P"
                  popperPlacement="bottom-start"
                  autoComplete="off"
                />
              </div>
              <WarningModal
                displayModal={showWarningModal}
                modalTitle="PROJECT.START_DATE_CHANGE.WARNING_MODAL.TITLE"
                modalDescription="PROJECT.START_DATE_CHANGE.WARNING_MODAL.DESCRIPTION"
                setShowModal={setShowWarningModal}
              />
              <div className="col-4 d-flex flex-column">
                <Field
                  className="form-control react-datepicker-ignore-onclickoutside no-validation"
                  name="availability"
                  component={DatePickerField}
                  onChange={(val) =>
                    customHandleChange({
                      target: { name: "availability", value: val?.toISOString() ?? null },
                    })(handleChange)
                  }
                  label={intl.formatMessage({
                    id: "PROJECT.INFO.AVAILABILITY_DATE",
                  })}
                  withFeedbackLabel={false}
                  disabled={disabled}
                  showTimeSelect={false}
                  dateFormat="P"
                  popperPlacement="bottom-start"
                  autoComplete="off"
                />
              </div>
              <div className="col-4">
                <Field
                  name="constructionYear"
                  component={Input}
                  type="number"
                  onChange={(e) => customHandleChange(e)(handleChange)}
                  label={intl.formatMessage({
                    id: "PROJECT.INFO.CONSTRUCTION_YEAR",
                  })}
                  disabled={disabled}
                />
              </div>
            </div>
            <div className="mb-4">
              {project.id && (
                <>
                  <TitleWithGuideLink
                    descriptionMessageId="PRICE_INDEX.BASE_INDEX.DESCRIPTION"
                    guideLink={fm("INVOICE.PRICE_INDEX_INFO_MESSAGE.LINK")}
                    titleMessageId="PRICE_INDEX.TITLE.SINGULAR"
                    mainTitle={true}
                  />
                  {basePriceIndex ? (
                    <>
                      <Field
                        name="basePriceIndex"
                        className="mt-1 mb-2"
                        component={Input}
                        disabled={true}
                      />
                    </>
                  ) : (
                    <WarningMessage messageId="PRICE_INDEX.BASE_INDEX.LEAD.WARNING" />
                  )}
                  <div className="separator separator-solid separator-border-2 my-4" />
                </>
              )}
            </div>
            <AddressEditForm
              customHandleChange={customHandleChange}
              handleChange={handleChange}
              disabled={disabled}
              hideGPSCoordinates={false}
            />
            <div className="form-group">
              <label>
                <FormattedMessage id="COMMON.DESCRIPTION" />
              </label>
              <TextEditor
                data-cy="input-project-description"
                name="description"
                data={values?.description}
                saveMethod={saveProjectFields}
                disabled={disabled}
              />
            </div>
            <Urls disabled={disabled} values={values} saveFields={saveProjectFields} />

            <div className="my-8">
              <h4>
                <FormattedMessage id="PROPERTY.INFO.SPECIFICATION" />
              </h4>
              <TextEditor
                name="specification"
                data={values?.specification}
                saveMethod={saveProjectFields}
                disabled={disabled}
                toolbarOption={commentToolbar}
                data-cy="input-project-details-specification"
              />
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
}
