import * as React from "react";
import cn from "clsx";
import type { SingleValueProps } from "react-select";
import { components } from "react-select";

import { CustomSelectOption } from "../../../CustomSelectBase";

import "./CustomStatusSingleValue.scss";

export const CustomStatusSingleValue: React.ComponentType<SingleValueProps<unknown, false>> = ({
  children,
  className,
  ...props
}) => {
  const { icon, color } = props.data as CustomSelectOption;

  const iconStyles = {
    "--iconLocalColor": color,
  } as any;

  return (
    <components.SingleValue
      {...{ ...props, className: cn("custom-status-single-value", className) }}
    >
      {icon && <i style={iconStyles} className={`fas fa-${icon}`} />}
      <span data-cy="select-custom-status-single-value">{children}</span>
    </components.SingleValue>
  );
};

export default CustomStatusSingleValue;
