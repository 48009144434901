import * as React from "react";
import {
  CurrencyMetricCard,
  MetricCard,
  MetricCardProps,
} from "../../../../../../_components/MetricCard";

export interface PlannedBudgetCardProps {
  canSeeHiddenLine: boolean;
  plannedTotal: number;
  publicPlannedTotal?: number;
  hiddenPlannedTotal?: number;
}

export const PlannedBudgetCard: React.FC<PlannedBudgetCardProps & MetricCardProps> = ({
  canSeeHiddenLine,
  plannedTotal,
  publicPlannedTotal,
  hiddenPlannedTotal,
  ...props
}) => {
  return (
    <CurrencyMetricCard
      dataCy="input-budget-planned-total"
      value={plannedTotal}
      label="BUDGET.PLANNED"
      {...props}
    >
      {canSeeHiddenLine && (
        <>
          <MetricCard.CurrencySubMetric
            dataCy="input-budget-public-planned-total"
            label="COMMON.PUBLIC"
            value={publicPlannedTotal}
          />
          <MetricCard.CurrencySubMetric
            dataCy="input-budget-hidden-planned-total"
            label="COMMON.HIDDEN"
            value={hiddenPlannedTotal}
          />
        </>
      )}
    </CurrencyMetricCard>
  );
};
