import * as React from "react";

import ProjectOwnerDisplaySelected from "./ProjectOwnerDisplaySelected";
import ProjectOwnerSelectModal from "./ProjectOwnerSelectModal";

export const ProjectOwnerSwitchEdit: React.FunctionComponent = () => (
  <>
    <ProjectOwnerSelectModal />
    <ProjectOwnerDisplaySelected />
  </>
);

export default ProjectOwnerSwitchEdit;
