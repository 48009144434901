import { BudgetsUIProvider, useBudgetsUIContext } from "../BudgetsUIContext";
import { canCreate, canEdit } from "../../../_utils/authUtils";
import { shallowEqual, useDispatch } from "react-redux";

import { BudgetsTable } from "../BudgetsTable";
import { Filter } from "../../../_components/Filter";
import { IBudget } from "../../../../data/schemas";
import { fetchBudget } from "../_redux/budgetsActions";
import { useAppSelector } from "../../../../redux/hooks";
import { useCustomLocationState } from "../../../_utils/useCustomLocationState";
import { useParams } from "react-router-dom";
import { useSubheader } from "../../../../_metronic/layout";
import ActionNewButton from "app/_components/ActionNewButton";

type HistoryParams = {
  id: string;
};

export const BudgetTab = () => {
  const { setFromUrlTo } = useCustomLocationState();

  const { id } = useParams<HistoryParams>();

  const { projectForEdit, groups, session } = useAppSelector(
    (state) => ({
      projectForEdit: state.projects.projectForEdit.current
        ? state.projects.projectForEdit.current
        : state.projects.entities?.find((entity) => entity.id === id),
      groups: state.auth.groups,
      session: state.auth.session,
    }),
    shallowEqual
  );

  const subheader = useSubheader();
  const dispatch = useDispatch();
  const budgetsUIEvents = {
    openBudgetPage: (budget: IBudget) => {
      subheader.setTitle(budget.name);
      setFromUrlTo({ url: `/budgets/${budget.id}`, name: projectForEdit?.name });
    },
    newBudgetButtonClick: () => {
      // To unload budgetForEdit if already selected
      dispatch(fetchBudget(undefined));
      setFromUrlTo({ url: `/budgets/new?projectId=${id}`, name: projectForEdit?.name });
    },
  };
  return (
    <BudgetsUIProvider
      queryParamsInit={{ projectId: id }}
      budgetsUIEvents={budgetsUIEvents}
      readOnly={!(canCreate(groups, session, "BUDGET") || canEdit(groups, session, "BUDGET"))}
      context={"projectBudgets"}
    >
      <div className="form-filtration">
        <div className="row align-items-center">
          <div className="col-md-6 margin-bottom-10-mobile">
            <Filter useEntityUIContext={useBudgetsUIContext} />
          </div>
          <div className="col-md-2 margin-bottom-10-mobile" />
          <div className="col-md-4 text-right margin-bottom-10-mobile ">
            {id && canCreate(groups, session, "BUDGET") && (
              <ActionNewButton
                dataCy="new-budget-btn"
                className="mb-4 ml-2"
                onClick={budgetsUIEvents.newBudgetButtonClick}
                messageId="BUDGET.ACTION.NEW"
              />
            )}
          </div>
          <BudgetsTable />
        </div>
      </div>
    </BudgetsUIProvider>
  );
};
