import * as React from "react";
import { Modal } from "react-bootstrap";
import { useIntl } from "react-intl";
import objectPath from "object-path";

import { ModalProgressBar } from "_metronic/_partials/controls";

import { useHtmlClassService } from "_metronic/layout";

import {
  useRequestSignatureDialogContext,
  RequestSignatureDialogUiState,
} from "./RequestSignatureDialogContext";

import { RequestSignatureDialogTitle } from "./RequestSignatureDialogTitle/RequestSignatureDialogTitle";
import { RequestSignatureDialogBody } from "./RequestSignatureDialogBody";
import { RequestSignatureDialogFooter } from "./RequestSignatureDialogFooter";

export const RequestSignatureDialogContent: React.FunctionComponent = () => {
  const intl = useIntl();
  const uiService = useHtmlClassService();
  const { uiState, isLoading, shouldShowDialog, closeDialog } = useRequestSignatureDialogContext();

  const layoutProps = React.useMemo(() => {
    const { config } = uiService;

    return {
      colorsGrayGray100: objectPath.get(config, "js.colors.gray.gray100"),
      colorsGrayGray300: objectPath.get(config, "js.colors.gray.gray300"),
      colorsGrayGray500: objectPath.get(config, "js.colors.gray.gray500"),
      colorsGrayGray600: objectPath.get(config, "js.colors.gray.gray600"),
      colorsGrayGray700: objectPath.get(config, "js.colors.gray.gray700"),
      colorsGrayGray800: objectPath.get(config, "js.colors.gray.gray800"),
      colorsWhite: "#ffffff",
      colorsThemeBaseDanger: objectPath.get(config, "js.colors.theme.base.danger"),
    };
  }, [uiService]);

  const modalStyle = {
    "--label-avatar": `"${intl.formatMessage({ id: "PROFILE.AVATAR" })}"`,
    "--label-name": `"${intl.formatMessage({ id: "COMMON.NAME" })}"`,
    "--label-email": `"${intl.formatMessage({ id: "COMMON.EMAIL" })}"`,
    "--label-phone": `"${intl.formatMessage({ id: "COMMON.MOBILE" })}"`,
    "--label-status": `"${intl.formatMessage({ id: "COMMON.STATUS" })}"`,
    "--label-actions": `"${intl.formatMessage({ id: "ACTION.TITLE" })}"`,

    "--color-bg": layoutProps.colorsWhite,
    "--color-danger": layoutProps.colorsThemeBaseDanger,
    "--color-border": layoutProps.colorsGrayGray100,
    "--color-header-bg": layoutProps.colorsWhite,
    "--color-header-text": layoutProps.colorsGrayGray500,
    "--color-hover-bg": layoutProps.colorsGrayGray300,
    "--color-hover-text": layoutProps.colorsGrayGray800,

    "--color-display-info-label": layoutProps.colorsGrayGray700,
    "--color-display-info-value": layoutProps.colorsGrayGray600,

    "--color-text-status": layoutProps.colorsWhite,
    "--color-status-needed": "#808080",
    "--color-status-in-progress": "#FA6D10",
    "--color-status-done": "#0CA789",

    "--collection-gradient-height": "0.5rem",
    "--collection-header-min-height": "25px",
    "--collection-item-min-height": "48px",
  } as any;

  return (
    <Modal
      show={shouldShowDialog}
      onHide={closeDialog}
      keyboard={false}
      aria-labelledby="request-signature-modal"
      backdrop="static"
      centered
      size={uiState === RequestSignatureDialogUiState.CANCELLATION ? "lg" : "xl"}
      style={modalStyle}
    >
      {isLoading && <ModalProgressBar variant="query" />}

      <Modal.Header closeButton>
        <Modal.Title id="request-signature-modal">
          <RequestSignatureDialogTitle />
        </Modal.Title>
      </Modal.Header>

      <Modal.Body className="pb-6 request-signature-dialog-body">
        <RequestSignatureDialogBody />
      </Modal.Body>

      <Modal.Footer>
        <RequestSignatureDialogFooter />
      </Modal.Footer>
    </Modal>
  );
};

export default RequestSignatureDialogContent;
