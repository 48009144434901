import { useKycFlow } from "../../../hooks/useKycFlow";
import { KycUploadComponent } from "./controls/kyc-upload/KycUploadComponent";
import { KycTranslation } from "../KycTranslation";
import { getKycAdditionalDocumentsRelatedEntityType } from "../KycUtils";

export const KycAdditionalDocumentsInvestmentFund = (): JSX.Element => {
  const { isClientViewer, values } = useKycFlow();

  const getKycRelatedEntity = () => {
    return getKycAdditionalDocumentsRelatedEntityType(values);
  };

  return (
    <>
      <KycUploadComponent
        name="evidenceProveRegulatedFund"
        subLabel={KycTranslation.EVIDENCE_DOCUMENT_NAME_PROVE_REGULATED_FUND}
        label={KycTranslation.EVIDENCE_DOCUMENT_NAME_PROVE_REGULATED_FUND_SHORT}
        kycRelatedToEntity={getKycRelatedEntity()}
        multiple={true}
      />
      <KycUploadComponent
        name="evidenceCopyProspects"
        subLabel={KycTranslation.EVIDENCE_DOCUMENT_NAME_COPY_PROSPECTS}
        label={KycTranslation.EVIDENCE_DOCUMENT_NAME_COPY_PROSPECTS_SHORT}
        kycRelatedToEntity={getKycRelatedEntity()}
        multiple={true}
      />
      <KycUploadComponent
        name="evidencePrintScreenCssfMancoAifm"
        subLabel={KycTranslation.EVIDENCE_DOCUMENT_NAME_PRINT_SCREEN_CSSF_MANCO_AIFM_SHORT}
        label={KycTranslation.EVIDENCE_DOCUMENT_NAME_PRINT_SCREEN_CSSF_MANCO_AIFM_SHORT}
        kycRelatedToEntity={getKycRelatedEntity()}
        multiple={true}
      />
      {!isClientViewer && (
        <>
          <KycUploadComponent
            name="evidenceInvestmentFundWorldCheck"
            subLabel={KycTranslation.EVIDENCE_DOCUMENT_INVESTMENT_FUND_WORLD_CHECK}
            label={KycTranslation.EVIDENCE_DOCUMENT_INVESTMENT_FUND_WORLD_CHECK_SHORT}
            kycRelatedToEntity={getKycRelatedEntity()}
            multiple={true}
          />
          <KycUploadComponent
            name="evidenceInvestmentFundInternetCheck"
            subLabel={KycTranslation.EVIDENCE_DOCUMENT_INVESTMENT_FUND_INTERNET_CHECK}
            kycRelatedToEntity={getKycRelatedEntity()}
            label={KycTranslation.EVIDENCE_DOCUMENT_INVESTMENT_FUND_INTERNET_CHECK_SHORT}
            multiple={true}
          />
        </>
      )}
    </>
  );
};
