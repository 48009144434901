/* eslint-disable no-restricted-imports */
import * as React from "react";
import { shallowEqual } from "react-redux";
import { Modal } from "react-bootstrap";
import { ModalProgressBar } from "../../../../../../../_metronic/_partials/controls";
import { useIntl } from "react-intl";
import { IUserFile } from "../../../../../../../data/schemas";
import { useAppSelector } from "../../../../../../../redux/hooks";

export interface UserFileEditDialogHeaderProps {
  userFileForEdit: IUserFile;
  isUploadingFile: boolean;
  showEditFileDialog: boolean;
}

export const UserFileEditDialogHeader: React.FC<UserFileEditDialogHeaderProps> = ({
  userFileForEdit,
  isUploadingFile,
  showEditFileDialog,
}) => {
  const intl = useIntl();
  const [title, setTitle] = React.useState("");

  const { actionsLoading } = useAppSelector(
    (state) => ({
      actionsLoading: state.users?.actionsLoading,
    }),
    shallowEqual
  );

  React.useEffect(() => {
    let filename = "";
    let prefixId;
    if (!userFileForEdit?.id) {
      prefixId = isUploadingFile ? "FILE.ACTIONS.UPLOAD.NEW" : "FILE.ACTIONS.UPLOAD.REQUEST";
    } else {
      prefixId = "COMMON.ACTION.EDIT";
      filename = userFileForEdit?.friendlyName;
    }

    setTitle(`${intl.formatMessage({ id: prefixId })} ${filename}`);
  }, [userFileForEdit, actionsLoading]);

  return (
    <>
      {actionsLoading && <ModalProgressBar variant="query" />}
      <Modal.Header closeButton>
        <Modal.Title id="example-modal-sizes-title-lg">{showEditFileDialog && title}</Modal.Title>
      </Modal.Header>
    </>
  );
};
