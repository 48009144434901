// the code data logic of the modal is centralized here

import * as React from "react";
import { useIntl } from "react-intl";
import { useDidUpdate } from "rooks";
import { useDispatch, useSelector, shallowEqual } from "react-redux";

import {
  NotificationDestination,
  IRequestSignatory,
  ISignaturesRequest,
  IFile,
  IUserOption,
  UserType,
} from "data/schemas";

import { formatDisplayNameIntl } from "app/_utils/userUtils";

import { getSignaturesRequest } from "data/api/signatures";
import { createOrResetAccess } from "app/modules/UsersManagement/_redux/usersCrud";

//----------------------------------------------------------------------------//

export const RequestSignatureDialogUiState = {
  /** state while loading the data */
  LOADING: "LOADING",
  /** when the file is sealed */
  DONE: "DONE",
  /** when it has signatures on going */
  VIEW: "VIEW",
  /** when the file doesn't has any signatures requested yet */
  CREATE: "CREATE",
  /** when the file has a previous signatures requested */
  EDIT: "EDIT",
  /** display the UI to confirm the operation */
  CANCELLATION: "CANCELLATION",
} as const;

export type TRequestSignatureDialogUiState = keyof typeof RequestSignatureDialogUiState;

//---===---//

interface ISignaturesRequests {
  previousSignaturesRequest?: ISignaturesRequest;
  currentSignaturesRequest?: ISignaturesRequest;
  sealedAt?: string;
}

export interface IRequestSignatureDialogContext extends ISignaturesRequests {
  uiState: TRequestSignatureDialogUiState;
  isLoading: boolean;

  isSendButtonDisabled: boolean;
  shouldShowDialog: boolean;

  selectableUsers: IUserOption[];

  /** list of persons to sign the requested document */
  signatories: IRequestSignatory[];

  toggleCancellationUiState: () => void;

  closeDialog: () => void;

  addSignatory: (user: IUserOption) => void;
  addSignatories: (users: IUserOption[]) => void;

  updateSignatory: (signatory: IRequestSignatory) => void;
  removeSignatory: (signatory: IRequestSignatory) => void;

  sendEmail: boolean;
  setSendEmail: React.Dispatch<React.SetStateAction<boolean>>;
  sendSms: boolean;
  setSendSms: React.Dispatch<React.SetStateAction<boolean>>;

  submit: () => void;

  cancellationReason?: string;
  setCancellationReason: React.Dispatch<React.SetStateAction<string | undefined>>;

  cancelPrevious: (
    signaturesRequest?: ISignaturesRequest,
    cancellationReason?: string
  ) => () => void;

  toggleGrantAccess: () => void;
}

const RequestSignatureDialogContext = React.createContext<
  IRequestSignatureDialogContext | undefined
>(undefined);

export const useRequestSignatureDialogContext = () => {
  const context = React.useContext(RequestSignatureDialogContext);

  if (context === undefined) {
    throw new Error(
      "useRequestSignatureDialogContext must be used within a RequestSignatureDialogProvider"
    );
  }

  return context;
};

export interface RequestSignatureDialogProviderProps {
  fileId?: string;
  file?: IFile;
  showDialog?: boolean;
  closeDialog: () => void;
  requestFileSignature: Function;
  requestCancelPrevious: Function;
}

export const RequestSignatureDialogProvider: React.FunctionComponent<RequestSignatureDialogProviderProps> =
  ({
    children,
    fileId,
    file,
    showDialog = false,
    closeDialog,
    requestFileSignature,
    requestCancelPrevious,
  }) => {
    const intl = useIntl();
    const dispatch = useDispatch();

    const [uiState, setUiState] = React.useState<TRequestSignatureDialogUiState>(
      RequestSignatureDialogUiState.LOADING
    );

    const [cancellationReason, setCancellationReason] = React.useState<string | undefined>(
      undefined
    );
    const [signaturesRequests, setSignaturesRequests] = React.useState<ISignaturesRequests>({
      previousSignaturesRequest: undefined,
      currentSignaturesRequest: undefined,
      sealedAt: undefined,
    });

    const [availableUsers, setAvailableUsers] = React.useState<IUserOption[]>([]);
    const [selectableUsers, setSelectableUsers] = React.useState<IUserOption[]>([]);

    const [signatories, setSignatories] = React.useState<IRequestSignatory[]>([]);

    const [sendEmail, setSendEmail] = React.useState(true);
    const [sendSms, setSendSms] = React.useState(false);

    const [isSubmitting, setSubmitting] = React.useState(false);
    const [isSendButtonDisabled, setSendButtonDisabled] = React.useState(false);

    const [isGrantAccess, setGrantAccess] = React.useState(false);

    //------------------------------------------------------------------------//

    const { actionsLoading: isUsersLoading, entities: users } = useSelector(
      (state: any) => state.users,
      shallowEqual
    );

    React.useEffect(() => {
      if (isUsersLoading) {
        return;
      }

      if (!showDialog) {
        setCancellationReason(undefined);

        setSignaturesRequests({
          previousSignaturesRequest: undefined,
          currentSignaturesRequest: undefined,
          sealedAt: undefined,
        });

        setSignatories([]);

        setTimeout(() => {
          setUiState(RequestSignatureDialogUiState.LOADING);
        }, 300);

        return;
      }

      setAvailableUsers(
        (users as IUserOption[]).map((user) => ({
          ...user,
          displayName: formatDisplayNameIntl(intl, user, false),
          isExternal: user.userTypes.includes(UserType.EXTERNAL) ?? false,

          uuid: crypto.randomUUID(),
        }))
      );
    }, [showDialog, users, isUsersLoading]);

    useDidUpdate(() => {
      const isSignatoriesEmpty = signatories.length === 0;
      const isAnyInvalid =
        signatories.filter((signatory) => signatory.isValid === false).length > 0;

      setSendButtonDisabled(isSignatoriesEmpty || isAnyInvalid);

      if (isSignatoriesEmpty) {
        setSelectableUsers([...availableUsers]);
        return;
      }

      const ignoreIds = signatories.reduce((acc, { id }) => {
        if (id) {
          acc.push(id);
        }
        return acc;
      }, [] as string[]);

      if (ignoreIds.length > 0) {
        setSelectableUsers(availableUsers.filter(({ id = "" }) => !ignoreIds.includes(id)));
      }
    }, [availableUsers, signatories]);

    React.useMemo(async () => {
      if (!file) return;

      const { previousSignaturesRequestId, currentSignaturesRequestId, sealedAt } = file;

      if (!previousSignaturesRequestId && !currentSignaturesRequestId) {
        setSignaturesRequests({
          previousSignaturesRequest: undefined,
          currentSignaturesRequest: undefined,
          sealedAt: undefined,
        });

        setUiState(RequestSignatureDialogUiState.CREATE);

        return;
      }

      if (previousSignaturesRequestId && !currentSignaturesRequestId) {
        const previousSignaturesRequest = await getSignaturesRequest(previousSignaturesRequestId);

        setSignaturesRequests({
          previousSignaturesRequest,
          currentSignaturesRequest: undefined,
          sealedAt: undefined,
        });

        setUiState(RequestSignatureDialogUiState.EDIT);

        return;
      }

      const currentSignaturesRequest = currentSignaturesRequestId
        ? await getSignaturesRequest(currentSignaturesRequestId)
        : undefined;

      setSignaturesRequests({
        previousSignaturesRequest: undefined,
        currentSignaturesRequest,
        sealedAt,
      });

      setUiState(
        sealedAt ? RequestSignatureDialogUiState.DONE : RequestSignatureDialogUiState.VIEW
      );
    }, [file]);

    //------------------------------------------------------------------------//

    const toggleCancellationUiState = () => {
      setUiState((previousUiState) => {
        switch (previousUiState) {
          case RequestSignatureDialogUiState.VIEW:
            setCancellationReason(undefined);
            return RequestSignatureDialogUiState.CANCELLATION;
          case RequestSignatureDialogUiState.CANCELLATION:
            return RequestSignatureDialogUiState.VIEW;
          default:
            return previousUiState;
        }
      });
    };

    const cancelPrevious =
      (signaturesRequest?: ISignaturesRequest, cancellationReason?: string) => () => {
        if (!signaturesRequest) return;

        signaturesRequest.cancelledReason = cancellationReason;

        setSubmitting(true);

        dispatch(requestCancelPrevious(signaturesRequest))
          .then((signaturesRequestResponse: ISignaturesRequest) => {
            setSignaturesRequests({
              previousSignaturesRequest: signaturesRequestResponse,
              currentSignaturesRequest: undefined,
              sealedAt: undefined,
            });

            setUiState(RequestSignatureDialogUiState.EDIT);
          })
          .finally(() => setSubmitting(false));
      };

    //------------------------------------------------------------------------//

    const addSignatory = (user: IUserOption) => {
      const {
        id,
        email,
        mobile,
        displayName: fullName,
        profilePictureUrl,
        uuid = crypto.randomUUID(),
      } = user;

      const names = fullName.split(" ");
      const namesLength = names.length;

      const firstName = user.firstName || names[0] || "U";
      const lastName = namesLength > 1 ? names[names.length - 1] || "" : "";

      const avatar =
        profilePictureUrl ||
        `${firstName[0].toUpperCase()}${lastName ? lastName[0].toUpperCase() : ""}`;

      const isValid = !!user.email;

      const hasUserPlatformAccess = !user.isDisabled;

      const newSignatory: IRequestSignatory = {
        id,
        avatar,
        email,
        mobile,
        fullName,

        isValid,
        signatoryHelperId: uuid,
        hasUserPlatformAccess,
      };

      setSignatories((previous) => [...previous, newSignatory]);
    };

    const addSignatories = (users: IUserOption[]) => users?.forEach(addSignatory);

    const updateSignatory = (signatory: IRequestSignatory) => {
      const { signatoryHelperId, fullName, email, mobile, isValid, hasUserPlatformAccess } =
        signatory;

      setSignatories((signatories) =>
        signatories.map((previousSignatory) => {
          if (previousSignatory.signatoryHelperId === signatoryHelperId) {
            const names = fullName.split(" ");
            const namesLength = names.length;

            const firstName = names[0] || "U";
            const lastName = namesLength > 1 ? names[names.length - 1] || "" : "";

            const avatar = `${firstName[0].toUpperCase()}${
              lastName ? lastName[0].toUpperCase() : ""
            }`;

            return {
              ...previousSignatory,
              avatar,
              fullName,
              email,
              mobile,
              isValid,
              hasUserPlatformAccess,
            };
          }
          return previousSignatory;
        })
      );
    };

    const removeSignatory = (signatory: IRequestSignatory) => {
      const filterByKey = signatory.signatoryHelperId;
      setSignatories((previous) =>
        previous.filter((signatory) => signatory.signatoryHelperId !== filterByKey)
      );
    };

    const submit = React.useCallback(() => {
      const notifications: NotificationDestination[] = [];
      sendEmail && notifications.push(NotificationDestination.EMAIL);
      sendSms && notifications.push(NotificationDestination.SMS);

      const body = {
        file,
        fileId,
        signatories,
        notifications,
      };

      setSubmitting(true);

      if (isGrantAccess) {
        signatories.forEach((signatory: IRequestSignatory) => {
          if (!signatory.hasUserPlatformAccess) {
            createOrResetAccess(signatory.id, notifications);
          }
        });
      }
      dispatch(requestFileSignature(body))
        .then(() => closeDialog())
        .finally(() => setSubmitting(false));
    }, [sendEmail, sendSms, signatories, fileId, file, requestFileSignature, isGrantAccess]);

    //------------------------------------------------------------------------//

    const toggleGrantAccess = () => {
      setGrantAccess((prevState) => !prevState);
    };

    //------------------------------------------------------------------------//
    const contextValue: IRequestSignatureDialogContext = {
      uiState,
      isLoading: isUsersLoading || isSubmitting,

      isSendButtonDisabled:
        isSubmitting || isSendButtonDisabled || (!isSendButtonDisabled && !sendEmail && !sendSms),
      shouldShowDialog: showDialog,

      ...signaturesRequests,

      selectableUsers,
      signatories,

      toggleCancellationUiState,

      closeDialog,

      addSignatory,
      addSignatories,

      updateSignatory,
      removeSignatory,

      sendEmail,
      setSendEmail,
      sendSms,
      setSendSms,

      submit,

      cancellationReason,
      setCancellationReason,

      cancelPrevious,

      toggleGrantAccess,
    };

    return (
      <RequestSignatureDialogContext.Provider value={contextValue}>
        {children}
      </RequestSignatureDialogContext.Provider>
    );
  };

export default RequestSignatureDialogProvider;
