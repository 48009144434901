export interface ISelectedSort {
  isAscending: boolean;
  selected: { type: string; label: string };
}

export const defaultSelectedSort: ISelectedSort = {
  selected: { type: "", label: "" },
  isAscending: false,
};

// to be used only when the filterText value is defined
export const TASK_ATTRIBUTES_TO_FILTER_WITH = [
  "name",
  "description",
  "assignee.firstName",
  "assignee.lastName",
];
