import * as React from "react";
import cn from "clsx";

import { CardBaseProps } from "./definitions";

export const CardFooter = React.forwardRef<HTMLDivElement, CardBaseProps>(
  ({ className, ...props }, ref) => (
    <div
      {...{
        ref,
        className: cn("card-footer", className),
        ...props,
      }}
    />
  )
);

export default CardFooter;

// Set display name for debugging.
if (process.env.NODE_ENV !== "production") {
  CardFooter.displayName = "CardBody";
}
