import * as React from "react";
import { useDidUpdate } from "rooks";

import { TasksContentViewMode, TTasksContentViewMode } from "./definitions";

export const useTasksSwitchContentView = ({
  contentView: externalContentView = TasksContentViewMode.COLUMNS,
} = {}) => {
  const [innerContentView, setContentView] = React.useState(externalContentView);

  useDidUpdate(() => {
    setContentView(externalContentView);
  }, [externalContentView]);

  const toggleContentView = (contentView?: TTasksContentViewMode) => {
    setContentView(
      contentView === TasksContentViewMode.GANTT_CHART
        ? TasksContentViewMode.GANTT_CHART
        : TasksContentViewMode.COLUMNS
    );
  };

  return { contentView: innerContentView, toggleContentView };
};

export default useTasksSwitchContentView;
