import { Button } from "react-bootstrap";
import * as React from "react";
import { useWizard } from "../../../../hooks/useWizard";
import { useKyc } from "../../../../hooks/useKyc";
import { useKycFlow } from "../../../../hooks/useKycFlow";
import { useTranslation } from "../../../../hooks/useTranslation";
import { KycComplianceViewMode } from "../../Kyc";
import { useKycFile } from "../../../../hooks/useKycFile";

interface KycComplianceBackButtonProps {
  setViewMode: Function;
}

export const KycComplianceBackButton = ({
  setViewMode,
}: KycComplianceBackButtonProps): JSX.Element => {
  const { fm } = useTranslation();
  const { isReadOnly } = useWizard();
  const { updateKycFlow } = useKyc();
  const { values } = useKycFlow();

  const { getKycFlow, currentKycFlow } = useKyc();
  const { listFilesByKycId } = useKycFile();

  const backButtonClick = (e: any) => {
    e.preventDefault();
    if (currentKycFlow) {
      if (!isReadOnly) {
        updateKycFlow({ ...values });
      }
      getKycFlow(currentKycFlow?.id);
      listFilesByKycId(currentKycFlow?.id);
    }

    setViewMode(KycComplianceViewMode.COMPLIANCE_DETAILS);
  };

  return (
    <Button
      data-cy="button-kyc-toolbar-end"
      size="sm"
      onClick={backButtonClick}
      className="btn-light"
    >
      <i className="fa fa-arrow-left" />
      {fm("COMMON.ACTION.BACK")}
    </Button>
  );
};
