import * as React from "react";
import { useDispatch } from "react-redux";
import { TasksWidget } from "./widgets/task/TasksWidget";
import { EmptyWidget } from "./widgets/EmptyWidget";
import { ProfileWidget } from "./widgets/ProfileWidget";
import {
  canSeeProjectInsights,
  isAdmin,
  isStaffMember,
  isSubcontractor,
} from "../../_utils/authUtils";
import { ActiveFilesWidget } from "./widgets/ActiveFilesWidget";
import * as actionsTasks from "../../modules/PropertiesManagement/_redux/tasks/tasksActions";
import { useIntl } from "react-intl";
import * as dashboardActions from "./_redux/dashboardActions";
import { useSubheader } from "../../../_metronic/layout";
import { useAppSelector } from "../../../redux/hooks";
import { ClientsCounterWidget } from "./widgets/ClientsCounterWidget";
import { PropertiesCounterWidget } from "./widgets/PropertiesCounterWidget";
import { LeadsCounterWidget } from "./widgets/LeadsCounterWidget";
import { DashboardCTAWidget } from "./widgets/DashboardCTAWidget";

export function DashboardPage() {
  const intl = useIntl();
  const { session, groups } = useAppSelector((state) => ({
    session: state.auth.session,
    groups: state.auth.groups,
    // isLoading: state.dashboard.countersLoading,
    // counterProject:
    //   state.dashboard?.counters?.projectCounters?.find((c: any) => c.counterType === "TOTAL")
    //     ?.value || 0,
    // counterLead:
    //   state.dashboard?.counters?.leadCounters?.find((c: any) => c.counterType === "TOTAL")?.value ||
    //   0,
  }));

  const dispatch = useDispatch();

  React.useEffect(() => {
    if (!groups?.map((g: any) => g.toLowerCase()).includes("admin")) {
      dispatch(actionsTasks.fetchTasks({ intl, queryParams: { userId: session?.id, groups } }));
    }
    dispatch(dashboardActions.loadCounters());
  }, []);

  const subheader = useSubheader();

  React.useEffect(() => {
    subheader.setTitle(
      intl.formatMessage({
        id: "DASHBOARD.TITLE",
      })
    );
  }, [subheader]);

  return (
    <>
      <div className="row">
        <div className="col-lg-6">
          <div className="row">
            <div className="col-lg-12">
              <ProfileWidget className="gutter-b" widgetHeight="150px" />
            </div>
          </div>
          {isStaffMember(groups, session) || isAdmin(groups, session) ? (
            <>
              <div className="row">
                <div className="col-lg-12">
                  {canSeeProjectInsights(groups, session) && <DashboardCTAWidget />}
                  <div className="row">
                    <div className="col-xl-4">
                      <PropertiesCounterWidget
                        className="gutter-b"
                        baseColor="primary"
                        widgetHeight="150px"
                      />
                    </div>
                    <div className="col-xl-4">
                      <LeadsCounterWidget
                        className="gutter-b"
                        iconColor="success"
                        widgetHeight="150px"
                      />
                    </div>
                    <div className="col-xl-4">
                      <ClientsCounterWidget className="gutter-b" />
                    </div>
                  </div>
                </div>
              </div>
            </>
          ) : (
            !isSubcontractor && (
              <>
                <div className="row">
                  <div className="col-lg-8">
                    <ActiveFilesWidget className="gutter-b" />
                  </div>
                  <div className="col-lg-4">
                    <EmptyWidget className="gutter-b card-stretch" />
                  </div>
                </div>
              </>
            )
          )}
        </div>
        <div className="col-lg-6">
          <TasksWidget className="card-stretch gutter-b" />
        </div>
      </div>
    </>
  );
}
