import "./KycWizard.scss";
import * as React from "react";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { KycLoading } from "./Kyc";
import { WizardStepper } from "../../_components/wizard/WizardStepper";
import { useKyc } from "../../hooks/useKyc";
import { useLoading } from "../../hooks/useLoading";
import { KycStepLoadingPage } from "./components/steps/KycStepLoadingPage";
import { useKycFile } from "../../hooks/useKycFile";

export const KycContentContainer = (): JSX.Element => {
  const { isCurrentlyLoading } = useLoading();
  let { id } = useParams<any>();

  const { getKycFlow } = useKyc();
  const { listFilesByKycId } = useKycFile();

  useEffect(() => {
    if (id && id !== "new") {
      getKycFlow(id, { createAudit: true });
      listFilesByKycId(id);
    }
  }, [id]);

  return (
    <>
      {isCurrentlyLoading(KycLoading.GET_KYC_FORM) ||
      isCurrentlyLoading(KycLoading.LIST_FILES, id) ? (
        <KycStepLoadingPage />
      ) : (
        <WizardStepper />
      )}
    </>
  );
};
