import { FormattedMessage } from "react-intl";

import { save, saved } from "redux/global/globalActions";

import { CLIENT } from "app/_utils/userTypes";
import { cancelFileSignaturesRequest, requestFileSignature } from "app/_utils/signatureUtils";

import { usersSlice } from "app/modules/UsersManagement/_redux/usersSlice";
import { getById } from "app/modules/UsersManagement/_redux/usersCrud";

import {
  processSnackbarNotification,
  SNACKBAR_MESSAGE,
} from "app/modules/Common/SnackbarNotificationsHandler";

import { getProductById } from "../products/productsCrud";
import { productsSlice } from "../products/productsSlice";
import { projectsSlice } from "../projects/projectsSlice";

import * as requestFromServer from "./leadsCrud";
import { callTypes, leadsSlice } from "./leadsSlice";

//----------------------------------------------------------------------------//

const contextualActions = {
  default: leadsSlice("leads").actions,
  productLeads: leadsSlice("productLeads").actions,
  projectLeads: leadsSlice("projectLeads").actions,
  clientLeads: leadsSlice("clientLeads").actions,
  leadClients: usersSlice("leadClients").actions,
};

const contextualActionProduct = {
  default: productsSlice("products").actions,
};

const { actions: actionProject } = projectsSlice;

const actions = contextualActions.default;
const actionClientLeads = contextualActions.leadClients;
const actionProduct = contextualActionProduct.default;

export const fetchLeads =
  (queryParams = {}, context = "default") =>
  (dispatch) => {
    dispatch(contextualActions[context].startCall({ callType: callTypes.list }));
    return requestFromServer
      .findLeads(queryParams)
      .then((response) => {
        dispatch(contextualActions[context].leadsFetched({ entities: response }));
      })
      .catch((error) => {
        // error.displayMessage = "Can't find leads";
        dispatch(contextualActions[context].catchError({ error, callType: callTypes.list }));
        processSnackbarNotification(SNACKBAR_MESSAGE.DANGER.FIND_LEAD, dispatch);
      });
  };

export const fetchLead = (id) => (dispatch) => {
  if (!id) {
    return dispatch(actions.leadFetched({ leadForEdit: undefined }));
  }

  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .getLeadById(id)
    .then((response) => {
      const leadForEdit = response;
      console.log("fetchLead leadForEdit: ", leadForEdit);
      dispatch(actions.leadFetched({ leadForEdit }));
      dispatch(actionClientLeads.usersFetched({ entities: response.users }));
    })
    .catch((error) => {
      // error.displayMessage = "Can't find lead";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
      processSnackbarNotification(SNACKBAR_MESSAGE.DANGER.FIND_LEAD, dispatch);
    });
};

export const fetchLeadDetails = (id) => (dispatch) => {
  if (!id) {
    dispatch(actions.leadFetched({ leadForEdit: undefined }));
    return Promise.resolve(undefined);
  }

  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .getLeadComplete(id)
    .then((response) => {
      const leadForEdit = response;
      dispatch(actions.leadFetched({ leadForEdit }));
      dispatch(actionClientLeads.usersFetched({ entities: response.users }));
      dispatch(actionProduct.productFetched({ productForEdit: response.product }));
      dispatch(actionProject.projectFetched({ projectForEdit: response.project }));
    })
    .catch((error) => {
      // error.displayMessage = "Can't find lead";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
      processSnackbarNotification(SNACKBAR_MESSAGE.DANGER.FIND_LEAD, dispatch);
    });
};

export const deleteLead =
  ({ context, id }) =>
  (dispatch) => {
    dispatch(actions.startCall({ callType: callTypes.action }));
    return requestFromServer
      .deleteLead(id)
      .then((response) => {
        processSnackbarNotification(SNACKBAR_MESSAGE.SUCCESS.DELETE_LEAD, dispatch);
        dispatch(actions.leadDeleted({ id }));
        dispatch(actionProduct.productLeadInvoicesUpdatedLocally(response.deletedInvoices));
        if (context) {
          dispatch(contextualActions[context].leadDeleted({ id }));
        }
      })
      .catch((error) => {
        // error.displayMessage = "Can't delete lead";
        dispatch(actions.catchError({ error, callType: callTypes.action }));
        processSnackbarNotification(SNACKBAR_MESSAGE.DANGER.DELETE_LEAD, dispatch);
      });
  };

export const createLead = (leadForCreation) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .createLead(leadForCreation)
    .then((response) => {
      console.log("createLead response: ", response);
      const lead = { ...leadForCreation, ...response };
      processSnackbarNotification(SNACKBAR_MESSAGE.SUCCESS.CREATE_LEAD, dispatch);

      dispatch(actions.leadCreated({ lead }));
      return lead;
    })
    .catch((error) => {
      // error.displayMessage = "Can't create lead";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
      processSnackbarNotification(SNACKBAR_MESSAGE.DANGER.CREATE_LEAD, dispatch);
    });
};

export const updateLeadFieldLocally = (key, value) => (dispatch) => {
  dispatch(actions.leadFieldUpdatedLocally({ key, value }));
};

export const updateLeadField = (key, value) => (dispatch) => {
  dispatch(actions.leadFieldUpdated({ key, value }));
};

export const updateLead = (leadForEdit) => (dispatch) => {
  const requestBody = { ...leadForEdit };
  delete requestBody.product;
  dispatch(save());
  return requestFromServer
    .updateLead(requestBody)
    .then((response) => {
      console.log("updateLead response: ", response);
      const lead = { ...leadForEdit, ...response };
      dispatch(actions.leadUpdated({ lead }));
      dispatch(saved());
    })
    .catch((error) => {
      dispatch(saved());
      dispatch(actions.catchError({ error, callType: callTypes.action }));
      processSnackbarNotification(SNACKBAR_MESSAGE.DANGER.UPDATE_LEAD, dispatch);
    });
};

export const fetchLeadProduct = (id) => (dispatch) => {
  if (!id) {
    return dispatch(actions.leadProductFetched({ product: undefined }));
  }

  dispatch(actions.startCall({ callType: callTypes.action }));
  return getProductById(id)
    .then((response) => {
      const product = response;
      console.log("fetchLeadProduct product: ", product);
      dispatch(actions.leadProductFetched({ product }));
      return product;
    })
    .catch((error) => {
      // error.displayMessage = "Can't find product";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
      processSnackbarNotification(SNACKBAR_MESSAGE.DANGER.FIND_PRODUCT, dispatch);
    });
};

export const fetchLeadClients = (ids, catchEmpty) => (dispatch) => {
  if (!ids) {
    return dispatch(actions.leadClientsFetched({ users: undefined }));
  }

  dispatch(actions.startCall({ callType: callTypes.action }));
  return Promise.all(ids.map((id) => getById(id, CLIENT)))
    .then((responses) => {
      const clientsFromDb = responses.filter(
        (client) => !!client.id && client.userTypes?.includes(CLIENT)
      );
      if (clientsFromDb.length === 0) {
        catchEmpty();
        throw new Error("Failed to find clients");
      } else {
        dispatch(actions.leadClientsFetched({ users: clientsFromDb }));
        dispatch(actionClientLeads.usersFetched({ entities: clientsFromDb }));
      }
    })
    .catch((error) => {
      // error.displayMessage = "Can't find product";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
      processSnackbarNotification(SNACKBAR_MESSAGE.DANGER.FIND_CLIENTS, dispatch);
    });
};

export const uploadLeadFile = (file, sessionId, setProgress) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer.uploadLeadFile(file, sessionId, setProgress).catch((error) => {
    setProgress(0);
    dispatch(actions.catchError({ error, callType: callTypes.action }));
    return Promise.reject({ errorCode: "FILE_UPLOAD_ERROR" });
  });
};

export const createLeadFile = (fileForCreation) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .createLeadFile(fileForCreation)
    .then((response) => {
      const file = { ...response };
      processSnackbarNotification(SNACKBAR_MESSAGE.SUCCESS.UPLOAD_FILE, dispatch);
      dispatch(actions.fileCreated({ file }));
    })
    .catch((error) => {
      // error.displayMessage = "Can't create file";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
      processSnackbarNotification(SNACKBAR_MESSAGE.DANGER.ADD_FILE, dispatch);
    });
};

export const updateFile = (fileForEdit) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .updateFile(fileForEdit)
    .then((response) => {
      const file = { ...response };
      processSnackbarNotification(SNACKBAR_MESSAGE.SUCCESS.UPDATE_FILE, dispatch);
      dispatch(actions.fileUpdated({ file }));
    })
    .catch((error) => {
      dispatch(actions.catchError({ error, callType: callTypes.action }));
      processSnackbarNotification(SNACKBAR_MESSAGE.DANGER.UPDATE_FILE, dispatch);
    });
};

//TODO review and delete, copy pasta madness/nonsense
export const openFileViewer = (file, openFileViewerDialog) => (dispatch) =>
  requestFromServer
    .getFile(file)
    .then((response) => {
      file = file.friendlyName ? file : response;

      openFileViewerDialog({
        file,
        fileUrl: response.url,
      });
    })
    .catch((error) => {
      dispatch(actions.catchError({ error, callType: callTypes.action }));
      processSnackbarNotification(SNACKBAR_MESSAGE.DANGER.OPEN_FILE, dispatch);
    });

export const addLinkedFiles = (props) => (dispatch) => dispatch(actions.linkedFilesAdded(props));
export const removeLinkedFile = (props) => (dispatch) => dispatch(actions.linkedFileRemoved(props));

export const deleteFile = (file) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .deleteFile(file)
    .then(() => {
      processSnackbarNotification(SNACKBAR_MESSAGE.SUCCESS.DELETE_FILE, dispatch);
      dispatch(actions.fileDeleted({ file }));
    })
    .catch((error) => {
      dispatch(actions.catchError({ error, callType: callTypes.action }));
      processSnackbarNotification(SNACKBAR_MESSAGE.DANGER.DELETE_FILE, dispatch);
    });
};

export const createFinancialDocument =
  (financialDocument, viewFileHandler = undefined) =>
  (dispatch) => {
    dispatch(actions.startCall({ callTypes: callTypes.action }));
    return requestFromServer
      .createFinancialDocument(financialDocument)
      .then((file) => {
        processSnackbarNotification(
          {
            type: "success",
            title: `SNACKBAR.SUCCESS.${file.fileType}.CREATE`,
            body: !!viewFileHandler && (
              <div className={"d-flex"}>
                <button
                  type="button"
                  className="btn btn-sm btn-link btn-link-info p-0 font-weight-bold"
                  onClick={viewFileHandler}
                >
                  <FormattedMessage id={"FILE.ACTION.VIEW_FILE"} />
                </button>
              </div>
            ),
          },
          dispatch
        );

        dispatch(actions.fileCreated({ file }));
        return file;
      })
      .catch((error) => {
        dispatch(actions.catchError({ error, callType: callTypes.action }));
        processSnackbarNotification(SNACKBAR_MESSAGE.DANGER.CREATE_INVOICE, dispatch);
      });
  };

export const updateFinancialDocument = (financialDocument) => (dispatch) => {
  dispatch(actions.startCall({ callTypes: callTypes.action }));
  return requestFromServer
    .updateFinancialDocument(financialDocument)
    .then((file) => {
      processSnackbarNotification(SNACKBAR_MESSAGE.SUCCESS.CREATE_INVOICE, dispatch);
      return file;
    })
    .catch((error) => {
      dispatch(actions.catchError({ error, callType: callTypes.action }));
      processSnackbarNotification(SNACKBAR_MESSAGE.DANGER.CREATE_INVOICE, dispatch);
    });
};

//----------------------------------------------------------------------------//

export const requestLeadFileSignature = (signatureRequest) => (dispatch) =>
  requestFileSignature(actions, signatureRequest, dispatch, callTypes);

export const cancelLeadFileSignaturesRequest = (signaturesRequest) => (dispatch) =>
  cancelFileSignaturesRequest(actions, signaturesRequest, dispatch, callTypes);
