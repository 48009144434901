import type { IHistoryParams } from "./definitions";

import { useParams } from "react-router-dom";

export const useRequestIdUrlParam = () => {
  const { requestId } = useParams<IHistoryParams>();

  return requestId;
};

export default useRequestIdUrlParam;
