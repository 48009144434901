import { KycStepClientPage2Legal } from "./KycStepClientPage2Legal";
import { KycStepClientPage2Natural } from "./KycStepClientPage2Natural";
import { useKycFlow } from "../../../../hooks/useKycFlow";
import { KycTranslation } from "../../KycTranslation";
import { KycFormTitle } from "../layout/KycFormTitle";
import { isLegalClient } from "../../KycUtils";

export const KycStepClientPage2 = (): JSX.Element => {
  const { values } = useKycFlow();

  return (
    <>
      <KycFormTitle label={KycTranslation.STEPS_CLIENT_PAGE_2_TITLE} />
      {values?.form?.client?.legalEntityType ? (
        isLegalClient(values) ? (
          <KycStepClientPage2Legal />
        ) : (
          <KycStepClientPage2Natural />
        )
      ) : (
        <>No legal type set</>
      )}
    </>
  );
};
