export const defaultIgnoredProps = ["updatedAt", "updatedByUserId", "eventId"];

export const removeObjectProperties = <T>(obj: T, ignoredProps: string[] = []): Partial<T> =>
  ignoredProps.length > 0
    ? Object.keys(obj).reduce((accumulator, key) => {
        if (!ignoredProps.includes(key)) {
          accumulator[key] = (obj as any)[key];
        }
        return accumulator;
      }, {} as any)
    : obj;

export default removeObjectProperties;
