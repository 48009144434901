import { ReactNode } from "react";
import { useTranslation } from "../../../../hooks/useTranslation";
import cn from "clsx";

interface KycFormSectionSubProps {
  label: string;
  children?: ReactNode;
  translateLabel?: boolean;
  indent?: boolean;
}

export const KycFormSectionSub = ({
  label,
  translateLabel = true,
  children,
  indent = true,
}: KycFormSectionSubProps): JSX.Element => {
  const { fm } = useTranslation();

  return (
    <>
      <div className={cn("row", indent ? "pl-lg-40" : "pl-lg-10")}>
        {label && (
          <label className={cn("col-lg-12", "col-form-label text-left")}>
            {translateLabel ? fm(label) : label}:
          </label>
        )}
      </div>
      <div className="row">
        <div className={cn("col-lg-12")}>{children}</div>
      </div>
    </>
  );
};
