import * as Yup from "yup";
import { IntlShape } from "react-intl";
import { KycStep } from "../../Kyc";
import { get } from "lodash";

type UserSchemaKey = "firstName" | "lastName" | "email" | "mobile";

const userSchema = (intl: IntlShape, ignoredProps: UserSchemaKey[] = []) => {
  const schema: Partial<Record<UserSchemaKey, any>> = {
    firstName: Yup.string()
      .required(intl.formatMessage({ id: "AUTH.VALIDATION.REQUIRED_FIELD" }))
      .min(2, `${intl.formatMessage({ id: "AUTH.VALIDATION.MIN_CHARACTER" })} 2`)
      .max(100, `${intl.formatMessage({ id: "AUTH.VALIDATION.MAX_CHARACTER" })} 100`),
    lastName: Yup.string()
      .required(intl.formatMessage({ id: "AUTH.VALIDATION.REQUIRED_FIELD" }))
      .min(2, `${intl.formatMessage({ id: "AUTH.VALIDATION.MIN_CHARACTER" })} 2`)
      .max(100, `${intl.formatMessage({ id: "AUTH.VALIDATION.MAX_CHARACTER" })} 100`),
    email: Yup.string()
      .required(intl.formatMessage({ id: "AUTH.VALIDATION.REQUIRED_FIELD" }))
      .min(2, `${intl.formatMessage({ id: "AUTH.VALIDATION.MIN_CHARACTER" })} 2`)
      .max(100, `${intl.formatMessage({ id: "AUTH.VALIDATION.MAX_CHARACTER" })} 100`)
      .email(intl.formatMessage({ id: "AUTH.VALIDATION.EMAIL" })),
    mobile: Yup.string()
      .required(intl.formatMessage({ id: "AUTH.VALIDATION.REQUIRED_FIELD" }))
      .min(6, `${intl.formatMessage({ id: "AUTH.VALIDATION.MIN_CHARACTER" })} 6`)
      .max(50, `${intl.formatMessage({ id: "AUTH.VALIDATION.MAX_CHARACTER" })} 50`),
  };
  for (const key of ignoredProps) {
    delete schema[key];
  }
  return schema;
};

export const kycFormSchema = (intl: IntlShape): Record<KycStep, any> => ({
  [KycStep.STEP_START_FLOW]: Yup.object(),
  [KycStep.STEP_DISCLAIMER]: Yup.object(),
  [KycStep.STEP_ADMIN_PAGE_1]: Yup.object(),
  [KycStep.STEP_ADMIN_PAGE_2]: Yup.object(),
  [KycStep.STEP_CLIENT_PAGE_1]: Yup.object(),
  [KycStep.STEP_CLIENT_PAGE_2]: Yup.object({
    form: Yup.object().shape({
      client: Yup.object().shape({
        beneficialOwners: Yup.array().of(Yup.object().shape(userSchema(intl))),
        agents: Yup.array().of(Yup.object().shape(userSchema(intl, ["email", "mobile"]))),
        representatives: Yup.array().of(Yup.object().shape(userSchema(intl))),
      }),
    }),
  }),
  [KycStep.STEP_CLIENT_PAGE_3]: Yup.object(),
  [KycStep.STEP_CLIENT_PAGE_4]: Yup.object(),
  [KycStep.STEP_SUCCESS]: Yup.object(),
  [KycStep.STEP_RISK_EVALUATION]: Yup.object(),
  [KycStep.STEP_BO_SIGNATURE_REQUEST]: Yup.object(),
  [KycStep.STEP_REJECT_AND_REQUEST_FORM_COMPLETION]: Yup.object(),
  [KycStep.STEP_START_WITH_NOTE]: Yup.object(),
});

type TNameFieldByPage = string | { startName: string; endName: TNameFieldByPage[] };

export const namesFieldByPage: Record<KycStep, TNameFieldByPage[]> = {
  [KycStep.STEP_START_FLOW]: [],
  [KycStep.STEP_DISCLAIMER]: [],
  [KycStep.STEP_ADMIN_PAGE_1]: [],
  [KycStep.STEP_ADMIN_PAGE_2]: [],
  [KycStep.STEP_CLIENT_PAGE_1]: [],
  [KycStep.STEP_CLIENT_PAGE_2]: [
    {
      startName: "form.client.beneficialOwners",
      endName: ["firstName", "lastName", "email", "mobile"],
    },
    {
      startName: "form.client.agents",
      endName: ["firstName", "lastName"],
    },
    {
      startName: "form.client.representatives",
      endName: ["firstName", "lastName", "email", "mobile"],
    },
  ],
  [KycStep.STEP_CLIENT_PAGE_3]: [],
  [KycStep.STEP_CLIENT_PAGE_4]: [],
  [KycStep.STEP_SUCCESS]: [],
  [KycStep.STEP_RISK_EVALUATION]: [],
  [KycStep.STEP_BO_SIGNATURE_REQUEST]: [],
  [KycStep.STEP_REJECT_AND_REQUEST_FORM_COMPLETION]: [],
  [KycStep.STEP_START_WITH_NOTE]: [],
};

export const checkFieldByPage = (
  sName: string,
  data: TNameFieldByPage,
  formikErrorValue: any,
  isValid: boolean
) => {
  if (typeof data === "string") {
    if (get(formikErrorValue, sName ? sName + "." + data : data)) {
      isValid = false;
    }
  } else {
    for (const i in get(formikErrorValue, data.startName)) {
      for (const v of data.endName) {
        isValid = checkFieldByPage(`${data.startName}[${i}]`, v, formikErrorValue, isValid);
      }
    }
  }
  return isValid;
};
