import * as React from "react";
import { useIntl } from "react-intl";
import cn from "clsx";

import { DateUtils } from "app/_utils/DateUtils";

import { ISignatory, SignatureStatus } from "data/schemas/signatures";

import "./ViewSignatory.scss";

interface ViewSignatoryProps {
  className?: string;
  isPreviousSignaturesRequest?: boolean;
  signatory: ISignatory;
}

export const ViewSignatory: React.FunctionComponent<ViewSignatoryProps> = ({
  className,
  isPreviousSignaturesRequest = false,
  signatory,
}) => {
  const intl = useIntl();

  const { status, updatedAt, signedDate, displayName, email = "-", mobile = "-" } = signatory;

  let statusClassname = "";
  let statusIntlId = "COMMON.PENDING";
  let statusDate: Date | undefined = undefined;

  switch (status) {
    case SignatureStatus.IN_PROGRESS:
      statusClassname = "viewed";
      statusIntlId = "COMMON.SIGNATURES.STATUS_VIEWED";
      statusDate = new Date(updatedAt!);
      break;
    case SignatureStatus.REJECTED:
      statusClassname = "rejected";
      statusIntlId = "COMMON.SIGNATURES.STATUS_REJECTED";
      statusDate = new Date(updatedAt!);
      break;
    case SignatureStatus.SIGNED:
      statusClassname = "signed";
      statusIntlId = "COMMON.SIGNED.AT";
      statusDate = new Date(signedDate!);
      break;
    default:
      statusClassname = "pending";
      statusIntlId = isPreviousSignaturesRequest
        ? "COMMON.SIGNATURES.STATUS_NOT_VIEWED"
        : "COMMON.PENDING";
      break;
  }

  return (
    <div className={cn("view-signatory item", className)}>
      <div className="attribute avatar">
        <div
          className={cn("symbol symbol-35 min-w-35px min-h-35px flex-shrink-0", {
            "bg-white-o-30": false,
            "symbol-light-primary": true,
          })}
        >
          <span className="symbol-label font-size-h5 font-weight-bold symbol-label">AV</span>
        </div>
      </div>
      <div className="attribute name">{displayName}</div>
      <div className="attribute email">{email}</div>
      <div className="attribute phone">{mobile}</div>
      <div className={cn("attribute status", statusClassname)}>
        {statusIntlId && (
          <span>
            {intl.formatMessage(
              { id: statusIntlId },
              statusDate
                ? {
                    date: <span className="date">{DateUtils.format(statusDate, intl, true)}</span>,
                  }
                : undefined
            )}
          </span>
        )}
      </div>
    </div>
  );
};

export default ViewSignatory;
