// Form is based on Formik
// Data validation is based on Yup
// Please, be familiar with article first:
// https://hackernoon.com/react-form-validation-with-formik-and-yup-8b76bda62e10
import React from "react";
import { Modal } from "react-bootstrap";
import { Field, Form, Formik } from "formik";
import { Input, Select } from "../../../../../../../_metronic/_partials/controls";
import { FormattedMessage, useIntl } from "react-intl";
import { PROJECT_PRODUCT_FILE_TYPES } from "../../../../../../_utils/listUtils";
import { canReadAll, canReadAllDocument } from "../../../../../../_utils/authUtils";
import { shallowEqual, useSelector } from "react-redux";
import { Checkbox } from "../../../../../../_components/Checkbox";
import { FileDropzone } from "app/_components/FileDropzone";
import { LinkedFiles } from "../../../../../../_components/LinkedFiles";
import { TagManager } from "../../../../../../_components/TagManager";
import { FILE, TAG } from "../../../../../../_utils/dataTypes";
import TitleWithGuideLink from "../../../../../../_components/TitleWithGuideLink";
import { useTranslation } from "app/hooks/useTranslation";
import { UploadFileFormValidationSchema } from "../../../forms/UploadFileFormValidationSchema";

export function ProjectFileEditForm({ saveFile, file, actionsLoading, onHide, newFile, progress }) {
  const intl = useIntl();
  const { fm } = useTranslation();

  const { session, groups, project } = useSelector(
    (state) => ({
      session: state.auth.session,
      groups: state.auth.groups,
      project: state.projects.projectForEdit.saved,
    }),
    shallowEqual
  );

  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={file}
        validationSchema={UploadFileFormValidationSchema({ newFile })}
        onSubmit={(values, formikHelpers) =>
          saveFile({ ...values, relatedEntity: { name: project.name } }).catch((e) => {
            formikHelpers.setFieldError("file", "FILE_UPLOAD_ERROR");
          })
        }
      >
        {({ handleSubmit, setFieldValue, values, errors }) => (
          <>
            <Modal.Body className="overlay">
              <Form className="form form-label-right">
                <div className="form-group row">
                  <div className="col-lg-12">
                    <Field
                      name="friendlyName"
                      component={Input}
                      placeholder={intl.formatMessage({
                        id: "COMMON.NAME",
                      })}
                      label={intl.formatMessage({
                        id: "COMMON.NAME",
                      })}
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <div className="col-lg-12">
                    <Select
                      name="fileType"
                      label={intl.formatMessage({
                        id: "COMMON.TYPE",
                      })}
                    >
                      {Object.keys(PROJECT_PRODUCT_FILE_TYPES).map((value) => (
                        <option key={value} value={value}>
                          {intl.formatMessage({ id: PROJECT_PRODUCT_FILE_TYPES[value] })}
                        </option>
                      ))}
                    </Select>
                  </div>
                </div>

                {newFile && (
                  <div className="form-group row">
                    <div className="col-lg-12">
                      {actionsLoading && !!values.file ? (
                        <div className="upload-file-container upload-file-container--disabled">
                          <div className="d-flex justify-content-center align-middle">
                            <div className="spinner-grow text-primary mr-4" />
                            <div>
                              <FormattedMessage id="FILE.ACTION.UPLOADING" /> {progress}%
                            </div>
                          </div>
                        </div>
                      ) : (
                        <FileDropzone values={values} setFieldValue={setFieldValue} />
                      )}
                    </div>
                    {errors?.file === "FILE_UPLOAD_ERROR" && (
                      <div className="col col-12 text-danger mt-2">
                        <FormattedMessage id="FILE.UPLOAD_FAIL" />
                      </div>
                    )}
                  </div>
                )}
                {canReadAll(groups, session, TAG) && !newFile && (
                  <div className="form-group row">
                    <div className="col-lg-12" data-cy="select-custom-tags">
                      <TagManager displayLogo={false} fullWidth relatedTo={`${FILE}#${file.id}`} />
                    </div>
                  </div>
                )}
                {canReadAllDocument(groups, session, "PROJECT") && (
                  <>
                    <TitleWithGuideLink
                      titleMessageId="COMMON.CONFIDENTIAL.DOCUMENT"
                      descriptionMessageId="FILE.CONFIDENTIAL.DOCUMENT.DESCRIPTION"
                      guideLink={fm("FILE.CONFIDENTIAL.DOCUMENT.LINK")}
                    />
                    <Checkbox name="isConfidential" label="COMMON.CONFIDENTIAL.DOCUMENT" />
                  </>
                )}
                <div className="separator separator-solid separator-border-2 my-4"></div>
                <LinkedFiles activeFile={file} />
              </Form>
            </Modal.Body>
            <Modal.Footer>
              <button
                type="button"
                onClick={onHide}
                disabled={actionsLoading}
                className="btn btn-light btn-elevate"
              >
                <FormattedMessage id="COMMON.ACTION.CANCEL" />
              </button>
              <> </>
              <button
                disabled={actionsLoading}
                type="submit"
                onClick={() => handleSubmit()}
                className="btn btn-primary btn-elevate"
              >
                <FormattedMessage id={newFile ? "FILE.ACTIONS.UPLOAD.NEW" : "COMMON.ACTION.SAVE"} />
              </button>
            </Modal.Footer>
          </>
        )}
      </Formik>
    </>
  );
}
