import React from "react";

export interface FormSectionProps {
  baseContentClassName?: string;
  title?: React.ReactNode;
  renderDivider?: boolean;
}

export const FormSection: React.FunctionComponent<FormSectionProps> = ({
  title,
  children,
  renderDivider = true,
}) => (
  <>
    {title && <h4>{title}</h4>}
    {children}
    {renderDivider && <hr className="my-8" />}
  </>
);

export default FormSection;
