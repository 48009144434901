import * as React from "react";
import { SearchTypeValues } from "../../contexts/SearchContext";
import { CustomSelect } from "../CustomSelect";
import { useIntl } from "react-intl";
import { useSearch } from "../../hooks/useSearch";
import cn from "clsx";

interface SearchDateProps {
  name: string;
  label: string;
  isFuture?: boolean;
  isFlexGrow?: boolean;
}

interface Option {
  label: string;
  value: string;
}

export const SearchDate: React.FC<SearchDateProps> = ({
  name,
  label,
  isFuture,
  isFlexGrow,
}): JSX.Element => {
  const intl = useIntl();
  const { setSearchValues } = useSearch();

  const getOption = (value: string, labelValue: string): Option => {
    return {
      value: `${isFuture ? "" : "-"}${value}`,
      label: intl.formatMessage({ id: `SEARCH.DATE.${isFuture ? "NEXT" : "LAST"}${labelValue}` }),
    };
  };
  const options: Option[] = [
    getOption("7,d", "_7_DAYS"),
    getOption("1,M", "_MONTH"),
    getOption("6,M", "_6_MONTHS"),
    getOption(`${isFuture ? "100" : "1"},Y`, "_YEAR"),
  ];

  return (
    <div
      className={cn("mr-sm-2 mr-0 mt-2", { "flex-grow-1": isFlexGrow })}
      data-cy={"filter-date-" + name.toLowerCase()}
    >
      <CustomSelect
        placeholder={intl.formatMessage({ id: label })}
        styles={{
          control: { width: "100%", maxWidth: "100%", minHeight: "40px", minWidth: "240px" },
        }}
        options={options}
        clearable={true}
        openMenuOnClick={true}
        onChange={(selected: any) => {
          if (selected === null) {
            setSearchValues("*", SearchTypeValues.SEARCH_DATE, name);
            return;
          }
          setSearchValues(selected.value, SearchTypeValues.SEARCH_DATE, name);
        }}
        getOptionValue={(o: any) => o.value}
      />
    </div>
  );
};
