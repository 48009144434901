import * as React from "react";
import { Layout } from "../../../_metronic/layout";
import { Card, CardBody } from "../../../_metronic/_partials/controls";
import { FormattedMessage } from "react-intl";
import { Link, useHistory } from "react-router-dom";

export const NetworkError = () => {
  const history = useHistory();
  const retryUrl = localStorage.getItem("retryUrl");
  const retry = () => {
    if (retryUrl) {
      history.push(retryUrl);
      localStorage.removeItem("retryUrl");
    }
  };
  return (
    <Layout>
      <Card className={"h-100"}>
        <CardBody className={"p-0"}>
          <div className="d-flex flex-column h-100">
            <div className="d-flex flex-row-fluid bgi-size-cover bgi-position-center">
              <div className="d-flex flex-row-fluid flex-column justify-content-center align-items-center text-center">
                <h1 className="display-1 font-weight-bold">
                  <FormattedMessage id={"ERROR.CONNECTION_LOST"} />
                </h1>
                <span className="display-4 font-weight-bold mb-8">
                  <FormattedMessage id={"ERROR.CHECK_CONNECTION"} />
                </span>
                {retryUrl ? (
                  <button type="button" className="btn btn-primary btn-lg" onClick={retry}>
                    <FormattedMessage id={"ERROR.TRY_AGAIN"} />
                  </button>
                ) : (
                  <Link to={"/"}>
                    <button type="button" className="btn btn-primary btn-lg">
                      <FormattedMessage id={"ERROR.GO_TO_HOMEPAGE"} />
                    </button>
                  </Link>
                )}
              </div>
            </div>
          </div>
        </CardBody>
      </Card>
    </Layout>
  );
};
