import React, { useState } from "react";
import { ModalProgressBar } from "../../../../../../../_metronic/_partials/controls";
import { useDispatch } from "react-redux";
import { Modal } from "react-bootstrap";
import * as actions from "../../../../_redux/usersActions";
import { FormattedMessage } from "react-intl";
import { Notification } from "../../../../../../_components/Notification";
import { IUser, UserType } from "../../../../../../../data/schemas";
import { isEmpty } from "lodash-es";

interface SendUserCredentialsModalProps {
  user: IUser;
  actionsLoading: boolean;
  showSendUserCredentialsModal: boolean;
  setShowSendUserCredentialsModal: Function;
}

export const SendUserCredentialsModal: React.FC<SendUserCredentialsModalProps> = ({
  user,
  actionsLoading,
  showSendUserCredentialsModal,
  setShowSendUserCredentialsModal,
}) => {
  const dispatch = useDispatch();
  const [notifyMethods, setNotifyMethods] = useState<string[]>([]);
  const sendUserCredentials = () => {
    dispatch(actions.createOrResetUserAccess(user.id, notifyMethods));
    setShowSendUserCredentialsModal(false);
    setNotifyMethods([]);
  };
  return (
    <Modal
      show={showSendUserCredentialsModal}
      onHide={() => setShowSendUserCredentialsModal(false)}
      aria-labelledby="user-credentials-modal"
      centered
      backdrop="static"
    >
      {actionsLoading && <ModalProgressBar variant="query" />}
      <Modal.Header closeButton>
        <Modal.Title id="user-credentials-modal-title">
          <FormattedMessage id="COMMON.ACTION.ACCESS.CREDENTIALS.USER" />
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {!actionsLoading && (
          <>
            <span>
              <FormattedMessage
                id={
                  user?.isDisabled
                    ? "USER.ACTION.ACCESS.CREATE.CONFIRMATION.USER"
                    : "USER.ACTION.ACCESS.RESET.CONFIRMATION.USER"
                }
              />
            </span>
            <div>
              <i>
                <small className="text-primary">
                  <FormattedMessage id="USER.ACTION.ACCESS.CANNOT_BE_UNDONE" />
                </small>
              </i>
            </div>
            <div className={"mt-8"}>
              <Notification
                userType={
                  user.userTypes.length === 0 || user.userTypes.includes(UserType.USER)
                    ? UserType.USER
                    : user.userTypes[0]
                }
                setNotifyMethods={setNotifyMethods}
                notifyMethods={notifyMethods}
              />
            </div>
          </>
        )}
        {actionsLoading && (
          <span>
            <FormattedMessage id="USER.ACTION.SENDING" />
          </span>
        )}
      </Modal.Body>
      <Modal.Footer>
        <div>
          <button
            type="button"
            onClick={() => setShowSendUserCredentialsModal(false)}
            className="btn btn-light btn-elevate"
          >
            <FormattedMessage id="COMMON.ACTION.CANCEL" />
          </button>
          <> </>
          <button
            type="button"
            onClick={() => {
              if (!actionsLoading && !isEmpty(notifyMethods)) {
                sendUserCredentials();
              }
            }}
            className="btn btn-primary font-weight-bold"
            data-cy="button-user-create-access-confirm"
            disabled={actionsLoading || isEmpty(notifyMethods)}
          >
            {user?.isDisabled ? (
              <FormattedMessage id="COMMON.ACTION.CREATE.AND.SEND" />
            ) : (
              <FormattedMessage id="COMMON.ACTION.RESET.AND.SEND" />
            )}
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};
