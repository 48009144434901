import { Modal } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import * as React from "react";
import { BudgetLineFileTable } from "./BudgetLineFileTable";
import { useQueryState } from "react-router-use-location-state";
import { shallowEqual } from "react-redux";
import { useAppSelector } from "../../../../../redux/hooks";
import { useSubheader } from "../../../../../_metronic/layout";
import {
  ISFFFile,
  SubcontractorFinanceType,
  SubcontractorFinanceTypes,
} from "../../../../../data/schemas";
import { useBudgetsUIContext } from "../../BudgetsUIContext";
import { useLocation } from "react-router-dom";
import cn from "clsx";

export interface BudgetLineDialogProps {
  show: boolean;
  lineId: string;
  categoryId: string;
  budgetId: string;
  onHide: Function;
  history: any;
}

export const BudgetLineDialog: React.FC<BudgetLineDialogProps> = ({
  show,
  categoryId,
  lineId,
  onHide,
}) => {
  const [tab, setTab] = useQueryState<SubcontractorFinanceTypes>(
    "tf",
    SubcontractorFinanceType.QUOTE
  );
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const subcontractorId = searchParams.get("subcontractorId");
  const fileId = searchParams.get("fileId");

  const subheader = useSubheader();
  const { computedCategories } = useBudgetsUIContext();

  const { budget, project } = useAppSelector(
    (state) => ({
      budget: state.budgets?.budgetForEdit?.current,
      project: state.projects?.projectForEdit?.current,
    }),
    shallowEqual
  );

  const displayedLine = computedCategories
    ?.find((cat) => cat.id === categoryId)
    ?.lines?.find((line: any) => line.id === lineId);
  let displayedSubcontractor;
  let displayedFile: any;
  if (displayedLine && subcontractorId) {
    displayedSubcontractor = displayedLine.subcontractors.find(
      (s: any) => s.userId === subcontractorId
    );
    if (fileId && displayedSubcontractor) {
      displayedFile = displayedSubcontractor.files.find((f: any) => f.fileId === fileId);
    }
  }

  let modalTitle = displayedLine?.label;
  if (displayedSubcontractor) {
    modalTitle += " - " + displayedSubcontractor.label;
    if (displayedFile) {
      modalTitle += " - " + displayedFile.label;
    }
  }
  React.useEffect(() => {
    if (budget && displayedLine && subheader && subheader.title === budget.name) {
      subheader.setTitle(modalTitle);
    }
  }, [budget, displayedLine]);

  const [files, setFiles] = React.useState<Record<SubcontractorFinanceType, ISFFFile[]>>(
    {} as Record<SubcontractorFinanceType, ISFFFile[]>
  );
  React.useEffect(() => {
    const res = {} as Record<SubcontractorFinanceType, ISFFFile[]>;
    if (subcontractorId && fileId && displayedFile) {
      // Handle single file + potential invoices that are linked to it
      // Use of the file type so we don't have to search for each finance type to find it
      const sffType =
        SubcontractorFinanceType[displayedFile.fileType as keyof typeof SubcontractorFinanceType];
      const lineFiles =
        project?.subcontractorsFinanceFiles?.[sffType]?.data?.[categoryId]?.lines?.[lineId]?.files;
      const file = lineFiles?.find((file) => file.id === fileId);
      if (file) {
        res[sffType] = [file];
        if (sffType !== SubcontractorFinanceType.INVOICE) {
          // List the invoices that are linked to the file
          const invoices = project?.subcontractorsFinanceFiles?.invoices?.data?.[
            categoryId
          ]?.lines?.[lineId]?.files?.filter((f) =>
            file.linkedFiles?.map((linkedFile) => linkedFile.id)?.includes(f.id)
          );
          res[SubcontractorFinanceType.INVOICE] = invoices ?? [];
        }
      }
    } else {
      // List every files related to the budget line
      for (const sffType of Object.values(SubcontractorFinanceType)) {
        const line =
          project?.subcontractorsFinanceFiles?.[sffType]?.data?.[categoryId]?.lines?.[lineId];
        let lineFiles = line?.files ?? [];
        if (subcontractorId) {
          // List only files that are uploaded by the given subcontractor
          lineFiles = lineFiles.filter((file) => file.userId === subcontractorId);
        }
        res[sffType] = lineFiles;
      }
    }
    setFiles(res);

    // Set the default tab
    const firstNotEmptyTab = (Object.keys(res) as (keyof typeof res)[]).find(
      (key) => res[key]?.length
    );
    if (firstNotEmptyTab) {
      setTab(firstNotEmptyTab);
    }
  }, [project]);

  const changeTab = (tab: SubcontractorFinanceType) => {
    if (files?.[tab]?.length) {
      setTab(tab);
    }
  };

  return (
    <Modal show={show} onHide={() => onHide()} centered backdrop="static" size={"lg"}>
      <Modal.Header closeButton>
        <Modal.Title id="example-modal-sizes-title-lg">{modalTitle}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <ul className="nav nav-tabs nav-tabs-line " role="tablist">
          <li className="nav-item" onClick={() => changeTab(SubcontractorFinanceType.QUOTE)}>
            <div
              className={cn(
                "nav-link cursor-pointer",
                tab === SubcontractorFinanceType.QUOTE && "active",
                !files?.[SubcontractorFinanceType.QUOTE]?.length && "disabled"
              )}
              data-toggle="tab"
              role="tab"
            >
              <FormattedMessage id="FINANCE.QUOTES" />
            </div>
          </li>
          <li
            className="nav-item"
            onClick={() => changeTab(SubcontractorFinanceType.SUPPLEMENTARY_AGREEMENT)}
          >
            <div
              className={cn(
                "nav-link cursor-pointer",
                tab === SubcontractorFinanceType.SUPPLEMENTARY_AGREEMENT && "active",
                !files?.[SubcontractorFinanceType.SUPPLEMENTARY_AGREEMENT]?.length && "disabled"
              )}
              data-toggle="tab"
              role="tab"
            >
              <FormattedMessage id="BUDGET.SUPPLEMENTARY_AGREEMENTS" />
            </div>
          </li>
          <li className="nav-item" onClick={() => changeTab(SubcontractorFinanceType.ORDER)}>
            <div
              className={cn(
                "nav-link cursor-pointer",
                tab === SubcontractorFinanceType.ORDER && "active",
                !files?.[SubcontractorFinanceType.ORDER]?.length && "disabled"
              )}
              data-toggle="tab"
              role="tab"
            >
              <FormattedMessage id="FINANCE.ORDERS" />
            </div>
          </li>
          <li className="nav-item" onClick={() => changeTab(SubcontractorFinanceType.INVOICE)}>
            <div
              className={cn(
                "nav-link cursor-pointer",
                tab === SubcontractorFinanceType.INVOICE && "active",
                !files?.[SubcontractorFinanceType.INVOICE]?.length && "disabled"
              )}
              data-toggle="tab"
              role="tab"
            >
              <FormattedMessage id="FINANCE.INVOICES" />
            </div>
          </li>
        </ul>
        <div className={"mt-5"}>
          <BudgetLineFileTable files={files[tab]} budgetLineLabel={displayedLine?.label ?? ""} />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div>
          <button type="button" onClick={() => onHide()} className="btn btn-primary">
            <FormattedMessage id="COMMON.ACTION.CLOSE" />
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};
