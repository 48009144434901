import * as React from "react";

import "./DisplayInfo.scss";

interface DisplayInfoProps {
  label: React.ReactNode;
  value: React.ReactNode;
}

export const DisplayInfo: React.FunctionComponent<DisplayInfoProps> = ({ label, value }) => (
  <div className="info">
    <div className="info-label">{label}</div>
    <div className="info-value">{value}</div>
  </div>
);

export default DisplayInfo;
