import * as React from "react";
import { DropdownItem } from "../../../../../../_components/actions-menu/DropdownItem";
import { Row } from "@tanstack/react-table";
import { BudgetTableRow } from "../../../BudgetEditExpenses";
import { useFormikContext } from "formik";
import { cloneDeep } from "lodash-es";
import * as projectActions from "../../../../../PropertiesManagement/_redux/projects/projectsActions";
import { useAppSelector } from "../../../../../../../redux/hooks";
import { useDispatch } from "react-redux";
import { useBudgetsUIContext } from "../../../../BudgetsUIContext";

export interface SetConfidentialCategoryProps {
  row: Row<BudgetTableRow>;
}

export const SetConfidentialCategory: React.FC<SetConfidentialCategoryProps> = ({ row }) => {
  const dispatch = useDispatch();
  const { saveBudgetFields } = useBudgetsUIContext();
  const { handleChange } = useFormikContext();
  const { project } = useAppSelector((state) => ({
    project: state.projects.projectForEdit.current,
  }));

  const { sequence, visible, id } = row.original;
  const setCategoryVisibility = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    e.stopPropagation();
    saveBudgetFields(`sortedLines.${sequence}.visible`, !visible);
    handleChange({
      target: {
        name: `sortedLines.${sequence}.visible`,
        value: !visible,
      },
    });
    updateRealBudget(!visible);
  };

  const updateRealBudget = (visible: boolean) => {
    if (project?.subcontractorsFinanceFiles) {
      const newSubcontractorsFinanceFiles = cloneDeep(project.subcontractorsFinanceFiles);
      const catRealBudget =
        (project.subcontractorsFinanceFiles.orders?.data?.[id]?.sum ?? 0) +
        (project.subcontractorsFinanceFiles.supplementary_agreements?.data?.[id]?.sum ?? 0);
      newSubcontractorsFinanceFiles.orders.total.public += visible ? catRealBudget : -catRealBudget;
      dispatch(
        projectActions.updateProjectFieldLocally(
          "subcontractorsFinanceFiles",
          newSubcontractorsFinanceFiles
        )
      );
    }
  };

  return (
    <DropdownItem
      onClick={setCategoryVisibility}
      icon={visible ? "/media/svg/icons/General/Lock.svg" : "/media/svg/icons/General/UnLock.svg"}
      title={
        visible
          ? "BUDGET.TABLE.ACTIONS.MAKE_THIS_GROUP_CONFIDENTIAL"
          : "BUDGET.TABLE.ACTIONS.MAKE_THIS_GROUP_PUBLIC"
      }
    />
  );
};
