import { useCardTableContext } from "./context/CardTableContext";
import * as React from "react";
import { CardTableActions } from "./components/CardTableActions/CardTableActions";
import { CardTableHeaders } from "./components/CardTableHeaders";
import ScrollContainer from "react-indiana-drag-scroll";
import { Accordion } from "react-bootstrap";
import cn from "clsx";
import { getRowTheme } from "./CardTableThemes";
import { CellRender } from "./components/CellRender";
import { RowRender } from "./components/RowRender";
import { CardTablePropsBase } from "./CardTable";
import { useWindowSize } from "rooks";

const scrollDelta = 5;

export const CardTableComponent = ({
  multiCardLayout,
  rowTheme,
  innerRef,
  ...props
}: CardTablePropsBase) => {
  const { setIsScrolled, setIsScrolledEnd, isScrolledEnd, table } = useCardTableContext();

  const scrollRef = React.useRef<HTMLDivElement>(null);
  const headersRef = React.useRef<HTMLDivElement>(null);
  const { innerWidth } = useWindowSize();

  const checkScrolledEnd = () => {
    if (scrollRef.current) {
      setIsScrolledEnd(
        Math.abs(
          scrollRef.current.scrollWidth -
            scrollRef.current.scrollLeft -
            scrollRef.current.clientWidth
        ) <= scrollDelta
      );
    }
  };

  React.useEffect(() => {
    if (scrollRef.current) {
      checkScrolledEnd();
    }
  }, [innerWidth, table.getVisibleLeafColumns()]);

  const onScrollTable = () => {
    if (scrollRef.current) {
      if (headersRef.current) {
        headersRef.current.scrollLeft = scrollRef.current.scrollLeft ?? 0;
      }
      setIsScrolled((scrollRef.current.scrollLeft ?? 0) > scrollDelta);
      checkScrolledEnd();
    }
  };

  const rows = table.getCoreRowModel().rows;

  return (
    <div className="card-table" ref={innerRef}>
      <CardTableActions {...props} />
      <div className={cn("position-relative", !isScrolledEnd && "overflow-shadow-end")}>
        <CardTableHeaders headersRef={headersRef} />
        <ScrollContainer
          hideScrollbars={false}
          ignoreElements={"input, .card-handle, .line-handle"}
          className={"d-flex"}
          style={{ fontFamily: "Roboto" }}
          innerRef={scrollRef}
          onScroll={onScrollTable}
          onEndScroll={onScrollTable}
        >
          <div className="flex-grow-1">
            {rows.map((row, index) => {
              const theme = row.original.rowTheme ?? rowTheme;
              return (
                <Accordion
                  onSelect={() => row.toggleExpanded()}
                  key={row.id}
                  activeKey={!multiCardLayout || row.getIsExpanded() ? "0" : undefined}
                >
                  <div
                    className={cn(
                      "card card-custom shadow-none",
                      index < rows.length - 1 && "mb-6"
                    )}
                  >
                    <Accordion.Toggle
                      eventKey={"0"}
                      as="div"
                      className={cn(
                        "card-header flex-nowrap mt-0 p-0 min-h-50px",
                        getRowTheme(theme, 0)
                      )}
                    >
                      {row.getVisibleCells().map((cell, cellIndex) => (
                        <CellRender cell={cell} rowTheme={theme} index={cellIndex} key={cell.id} />
                      ))}
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey={"0"}>
                      <>
                        <div className="card-body p-0">
                          {row.subRows.map((subRow) => (
                            <RowRender
                              row={subRow}
                              rowTheme={subRow.original.rowTheme ?? theme}
                              key={subRow.id}
                            />
                          ))}
                        </div>
                        {!!row.original.footer && <div>{row.original.footer}</div>}
                      </>
                    </Accordion.Collapse>
                  </div>
                </Accordion>
              );
            })}
          </div>
        </ScrollContainer>
      </div>
    </div>
  );
};
