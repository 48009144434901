import * as React from "react";
import { useGetInvoiceManagement } from "../hooks/useGetInvoiceManagement";
import { InvoiceDashboardProjectsData } from "../../../../data/schemas/Dashboard";
import { useAppSelector } from "../../../../redux/hooks";
import { canSeeInvoiceManagementDashboard } from "../../../_utils/authUtils";

export interface DashboardContextValue {
  invoiceManagementData?: InvoiceDashboardProjectsData;
  invoiceManagementLoading?: boolean;
}

export const DashboardContext = React.createContext<DashboardContextValue>(
  {} as DashboardContextValue
);

export function useDashboardContext() {
  return React.useContext<DashboardContextValue>(
    DashboardContext as React.Context<DashboardContextValue>
  );
}

export const DashboardProvider: React.FC = ({ children }) => {
  const { session, groups } = useAppSelector((state) => ({
    session: state.auth.session,
    groups: state.auth.groups,
  }));
  const { isLoading: invoiceManagementLoading, data: invoiceManagementData } =
    useGetInvoiceManagement(canSeeInvoiceManagementDashboard(groups, session));

  const value: DashboardContextValue = {
    invoiceManagementData: invoiceManagementData,
    invoiceManagementLoading,
  };

  return <DashboardContext.Provider value={value}>{children}</DashboardContext.Provider>;
};
