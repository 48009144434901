import { defaultLayoutPlugin, DefaultLayoutPluginProps } from "@react-pdf-viewer/default-layout";
import { useLanguage } from "../../../../_metronic/i18n";
import React, { useEffect, useState } from "react";
import { LocalizationMap, SpecialZoomLevel, Viewer, Worker } from "@react-pdf-viewer/core";
import en_US from "@react-pdf-viewer/locales/lib/en_US.json";
import { renderToolbar } from "./CustomToolbar";

interface PdfViewerProps {
  fileUrl: any;
  defaultZoomLevel?: number | SpecialZoomLevel;
  toolbar?: any;
}

export const PdfViewer = ({
  fileUrl,
  //1.7 = 170% zoom -> good value DIN A4
  defaultZoomLevel = 1.4,
  //set default toolbar to avoid issues with existing parts of the application
  toolbar = renderToolbar as DefaultLayoutPluginProps,
}: PdfViewerProps): JSX.Element => {
  const { selectedLanguage: lang } = useLanguage();
  const localeMapping = {
    "en-GB": "en_US",
    "fr-FR": "fr_FR",
    "de-DE": "de_DE",
    "es-ES": "es_ES",
    "it-IT": "it_IT",
  };
  const [pdfTexts, setPdfTexts] = useState(en_US);
  useEffect(() => {
    (async () => {
      const response = await import(
        "@react-pdf-viewer/locales/lib/" + localeMapping[lang] + ".json"
      );
      setPdfTexts(response);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lang]);

  const defaultLayoutPluginInstance = defaultLayoutPlugin({
    renderToolbar: toolbar,
    sidebarTabs: (defaultTabs) => [
      // Remove sidebar tabs -> empty array = no sidebar.
    ],
  });

  return (
    <Worker workerUrl="/pdf.worker.min.js">
      <Viewer
        defaultScale={defaultZoomLevel}
        fileUrl={fileUrl}
        plugins={[defaultLayoutPluginInstance]}
        localization={pdfTexts as unknown as LocalizationMap}
        renderLoader={(percentages) => <div className={"spinner spinner-primary spinner-lg"} />}
      />
    </Worker>
  );
};
