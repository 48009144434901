// React bootstrap table next =>
// DOCS: https://react-bootstrap-table.github.io/react-bootstrap-table2/docs/
// STORYBOOK: https://react-bootstrap-table.github.io/react-bootstrap-table2/storybook/index.html
import React, { useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import { useIntl } from "react-intl";
import { shallowEqual, useDispatch } from "react-redux";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, { PaginationProvider } from "react-bootstrap-table2-paginator";
import { useDidMount, useDidUpdate } from "rooks";

import { useAppSelector } from "redux/hooks";

import { getSelectRow, NoRecordsFoundMessage, sortCaret } from "_metronic/_helpers";
import { Pagination } from "_metronic/_partials/controls";

import { searchUtils, selectField } from "app/_utils/searchUtils";
import { CLIENT, SUBCONTRACTOR } from "app/_utils/userTypes";

import {
  ActionsColumnFormatter,
  FullNameFormatter,
  PhoneNumberFormatter,
  PlatformAccessColumnFormatter,
  ProfilePictureColumnFormatter,
} from "app/_components/column-formatters";

import * as actions from "../../../../UsersManagement/_redux/usersActions";
import * as uiHelpers from "../UsersUIHelpers";
import { useUsersUIContext } from "../UsersUIContext";
import { formatDisplayNameColumn } from "app/_utils/userUtils";
import EmptyState from "app/_components/EmptyState/EmptyState";
import { isKycMultipleFlowTypesAvailable } from "../../../../../_utils/configUtils";
import { canReadAll } from "../../../../../_utils/authUtils";
import { KycTranslation } from "../../../../kyc-v2/KycTranslation";
import { KycLeadColumnFormatter } from "../../../../kyc-v2/components/KycLeadColumnFormatter";
import { useTranslation } from "../../../../../hooks/useTranslation";
import { KycFlowStatus } from "../../../../kyc-v2/Kyc";
import { cloneDeep, isEmpty } from "lodash-es";

export function UsersTable(lead = undefined) {
  const intl = useIntl();
  const history = useHistory();
  const dispatch = useDispatch();

  const ctx = useUsersUIContext();

  const { fm } = useTranslation();

  const uiProps = useMemo(() => {
    return {
      ids: ctx.ids,
      setIds: ctx.setIds,
      queryParams: ctx.queryParams,
      setQueryParams: ctx.setQueryParams,
      filters: ctx.filters,
      setFilters: ctx.setFilters,
      newUserClick: ctx.newEntityButtonClick,
      readOnly: ctx.readOnly,
      context: ctx.context,
      action: ctx.action,
      openEntityDetailsPage: ctx.openEntityDetailsPage,
      openRemoveUser: ctx.openRemoveUser,
      canOpenRemoveUser: ctx.canOpenRemoveUser,
      userType: ctx.userType,
      filterList: ctx.filterList,
    };
  }, [ctx]);

  const { entities, listLoading, session, groups } = useAppSelector(
    (state) => ({
      entities: uiProps.context ? state[uiProps.context].entities : state.users.entities,
      listLoading: uiProps.context ? state[uiProps.context].listLoading : state.users.listLoading,
      session: state.auth.session,
      groups: state.auth.groups,
    }),
    shallowEqual
  );

  const fetchUsersByType = () => {
    if (uiProps !== "leadClients") {
      uiProps.setIds([]);
      dispatch(
        actions.fetchUsersByUserType(
          { ...uiProps.queryParams, userTypes: [uiProps.userType] },
          uiProps.context
        )
      );
    }
  };
  useDidMount(() => fetchUsersByType());
  useDidUpdate(() => fetchUsersByType(), [uiProps.userType]);

  const [entitiesFiltered, setEntitiesFiltered] = useState([]);

  useEffect(() => {
    let entitiesMapped = cloneDeep(entities) ?? [];
    entitiesMapped = entitiesMapped?.filter((user) => user?.userTypes?.includes(ctx.userType));

    if (entitiesMapped) {
      for (let i = 0; i < entitiesMapped.length; i++) {
        let entity = entitiesMapped[i];
        const kycFlows = lead?.lead?.kycs.filter(
          (item) => item?.status !== KycFlowStatus.CANCELED && entity?.id === item?.clientId
        );

        entity["mostRecentKycFlow"] = kycFlows?.reduce((maxItem, currentItem) => {
          if (!maxItem) return currentItem;
          if (
            currentItem.updatedAt &&
            new Date(currentItem.updatedAt) > new Date(maxItem.updatedAt)
          ) {
            return currentItem;
          }
          return maxItem;
        }, null);
      }
    }

    setEntitiesFiltered(
      searchUtils(uiProps?.filters?.freeText, entitiesMapped, selectField(uiProps), intl)
    );
  }, [entities, uiProps?.filters?.freeText, uiProps?.filters?.filterSelected]);

  const isLeadWithKycAvailable =
    entitiesFiltered?.filter((entity) => entity?.mostRecentKycFlow)?.length > 0;

  const columns = [
    {
      dataField: "user",
      text: "",
      sort: false,
      formatter: ProfilePictureColumnFormatter,
    },
    {
      dataField: "fullName",
      headerClasses: "text-left text-nowrap",
      classes: "text-left",
      text: intl.formatMessage({
        id: "COMMON.NAME",
      }),
      sort: true,
      formatter: FullNameFormatter,
      sortValue: (cell, row) => formatDisplayNameColumn(row),
      sortCaret: sortCaret,
    },
    {
      dataField: "leadsCount",
      headerClasses: "text-left text-nowrap",
      classes: "text-left",
      text: intl.formatMessage({
        id: "LEAD.TITLE",
      }),
      sort: true,
      sortCaret: sortCaret,
      hidden: uiProps.userType !== CLIENT || !isEmpty(lead),
    },
    {
      dataField: "projectsCount",
      headerClasses: "text-left text-nowrap",
      classes: "text-left",
      text: intl.formatMessage({
        id: "PROJECT.TITLE",
      }),
      sort: true,
      sortCaret: sortCaret,
      hidden: uiProps.userType !== SUBCONTRACTOR,
    },
    {
      dataField: "mobile",
      headerClasses: "text-left text-nowrap",
      classes: "text-left",
      text: intl.formatMessage({
        id: "COMMON.MOBILE",
      }),
      sort: true,
      formatter: PhoneNumberFormatter,
      sortCaret: sortCaret,
    },
    {
      dataField: "email",
      headerClasses: "text-left text-nowrap",
      classes: "text-left",
      text: intl.formatMessage({
        id: "COMMON.EMAIL",
      }),
      sort: true,
      sortCaret: sortCaret,
    },

    {
      dataField: "mostRecentKycFlow",
      headerClasses: "text-left text-nowrap",
      text: fm(KycTranslation.COMPLIANCE_TITLE),
      sort: true,
      headerStyle: { minWidth: 200 },
      style: { minWidth: 200 },
      formatter: (cell) => <KycLeadColumnFormatter kycFlow={cell} />,
      hidden:
        !isKycMultipleFlowTypesAvailable() ||
        !isLeadWithKycAvailable ||
        !canReadAll(groups, session, "KYC_FLOW"),
    },
    {
      dataField: "isDisabled",
      headerClasses: "text-center text-nowrap",
      classes: "text-center",
      text: intl.formatMessage({
        id: "COMMON.HAS_PLATFORM_ACCESS",
      }),
      sort: true,
      sortCaret: sortCaret,
      formatter: PlatformAccessColumnFormatter,
      sortValue: (cell, row) => !row.isDisabled,
    },
    {
      dataField: "action",
      text: intl.formatMessage({
        id: "COMMON.ACTION",
      }),
      formatter: ActionsColumnFormatter,
      formatExtraData: {
        openDeleteDialog: (id) => uiProps.openRemoveUser(id, uiProps),
        actionList: ["TRASH"],
        canDelete: (row) => {
          return uiProps.canOpenRemoveUser(row);
        },
        entityType: uiProps.userType,
      },
      classes: "text-right",
      headerClasses: "text-right",
      style: {
        minWidth: "100px",
      },
      hidden: uiProps.readOnly || !!uiProps?.action,
    },
  ];

  // Table pagination properties
  const paginationOptions = {
    custom: true,
    totalSize: entitiesFiltered?.length,
    sizePerPageList: uiHelpers.sizePerPageList,
    sizePerPage: uiProps.filters.pageSize,
    page: uiProps.filters.pageNumber,
  };

  const selectRow =
    uiProps.action === "LEAD_CREATION" || uiProps.action === "MULTI_TASK_CREATION"
      ? getSelectRow({
          ids: uiProps.ids,
          setIds: uiProps.setIds,
        })
      : {
          mode: "radio",
          selected: entitiesFiltered?.id !== "" ? [entitiesFiltered?.id] : [],
          hideSelectColumn: true,
        };

  return (
    <div data-cy="table-list">
      <EmptyState
        entitiesLength={entities.length}
        entityUIContextAction={ctx.newEntityButtonClick}
        listLoading={listLoading}
        stateLocation={"USER"}
        userType={ctx.userType}
      >
        <PaginationProvider pagination={paginationFactory(paginationOptions)}>
          {({ paginationProps, paginationTableProps }) => {
            return (
              <Pagination isLoading={listLoading} paginationProps={paginationProps}>
                <BootstrapTable
                  wrapperClasses="table-responsive"
                  classes="table table-head-custom table-vertical-center"
                  bootstrap4
                  bordered={false}
                  keyField="id"
                  data={entitiesFiltered || []}
                  columns={columns}
                  defaultSorted={uiHelpers.defaultSorted}
                  noDataIndication={() =>
                    listLoading ? "" : <NoRecordsFoundMessage entities={entitiesFiltered} />
                  }
                  selectRow={selectRow}
                  hover
                  //TODO nasty fix for client not clickable in leads
                  rowEvents={{
                    onClick: (e, row, rowIndex) => {
                      if (
                        uiProps.action !== "LEAD_CREATION" &&
                        uiProps.action !== "MULTI_TASK_CREATION"
                      ) {
                        uiProps.openEntityDetailsPage
                          ? uiProps.openEntityDetailsPage(row)
                          : history.push(`/clients/${row.id}`);
                      }
                    },
                  }}
                  rowStyle={{ cursor: "pointer" }}
                  {...paginationTableProps}
                />
              </Pagination>
            );
          }}
        </PaginationProvider>
      </EmptyState>
    </div>
  );
}
