export const ckeConfig = (language: string, toolbar: Array<string> | undefined) => {
  //TODO proper configure plugins in future for additstringional editor features

  if (toolbar) {
    return {
      toolbar,
      language,
    };
  }

  return {
    toolbar: [
      "heading",
      "|",
      "bold",
      "italic",
      "blockQuote",
      // doesn't do anything, disabled for now
      // "link",
      "numberedList",
      "bulletedList",
      //imageupload doesn't work, requires config
      // "imageUpload",
      "insertTable",
      "tableColumn",
      "tableRow",
      "mergeTableCells",
      //media embed works somewhat, not relevant for now, maybe in future
      // "mediaEmbed",
      "|",
      "undo",
      "redo",
    ],
    language: language,
  };
};

export const commentToolbar = [
  "heading",
  "|",
  "bold",
  "italic",
  "blockQuote",
  "numberedList",
  "bulletedList",
  "|",
  "undo",
  "redo",
];

export const basicToolbar = ["bold", "italic", "|", "numberedList", "bulletedList"];
