import * as React from "react";
import { useEffect, useState } from "react";
import { IDocument } from "@cyntler/react-doc-viewer";
import { useFileViewer } from "../../../../hooks/useFileViewer";
import { mapFilesToDoc } from "../../../kyc-v2/KycMappingUtils";
import { useWebsocket } from "../../../../hooks/useWebsocket";
import { isEmpty } from "lodash-es";
import { useKycFile } from "../../../../hooks/useKycFile";
import { diff } from "deep-object-diff";
import { FileViewerHeader } from "./FileViewerHeader";
import { Modal } from "react-bootstrap";
import "./FileViewer.scss";
import { useTranslation } from "../../../../hooks/useTranslation";
import FileViewerBody from "./FileViewerBody";
import { v4 as uuid } from "uuid";

/**
 * Component to visualise files such as pdf, png, jpeg,...
 */
export const FileViewer = (): JSX.Element => {
  const { latestMessage } = useWebsocket();

  const {
    showFileViewer,
    currentFiles,
    closeFileViewer,
    setCurrentFiles,
    currentFileShown,
    setCurrentFileShown,
  } = useFileViewer();

  const { fm } = useTranslation();
  const { currentFilesIdToFileMap } = useKycFile();

  const [key, setKey] = useState<string>();

  const [mappedFiles, setMappedFiles] = useState<IDocument[]>([]);

  useEffect(() => {
    return () => {
      setMappedFiles([]);
      setCurrentFiles([]);
    };
  }, []);

  useEffect(() => {
    if (isEmpty(currentFiles) || !latestMessage) {
      return;
    }

    if (latestMessage?.type === "REFRESH_GENERATED_FILES") {
      const updatedFiles = currentFiles?.map((currentFile) =>
        currentFilesIdToFileMap.hasOwnProperty(currentFile?.id)
          ? currentFilesIdToFileMap[currentFile?.id]
          : currentFile || []
      );
      setCurrentFiles(updatedFiles || []);
    }
  }, [latestMessage]);

  useEffect(() => {
    const mappedFilesNew: IDocument[] = mapFilesToDoc(currentFiles) ?? [];
    if (!isEmpty(diff(mappedFiles, mappedFilesNew))) setMappedFiles(mappedFilesNew);
  }, [currentFiles]);

  useEffect(() => {
    if (!currentFileShown && !isEmpty(currentFiles)) {
      setCurrentFileShown(currentFiles?.[0]);
    }
    setKey(uuid());
  }, [currentFiles]);

  const closeModalHandler = () => {
    closeFileViewer();
  };

  return (
    <Modal
      show={showFileViewer}
      dialogClassName="modal-dialog-file-viewer"
      aria-labelledby="validate-identity"
      data-cy="file-viewer-modal"
    >
      <Modal.Header className="d-block">
        <FileViewerHeader />
      </Modal.Header>
      <Modal.Body className="modal-body-file-viewer">
        <FileViewerBody key={key} documents={mappedFiles} />
      </Modal.Body>

      <Modal.Footer className="text-right">
        <button
          type="button"
          className="btn btn-primary mr-4"
          onClick={closeModalHandler}
          data-cy="file-viewer-close-btn"
        >
          {fm("COMMON.ACTION.CLOSE")}
        </button>
      </Modal.Footer>
    </Modal>
  );
};
