/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import { FormattedMessage } from "react-intl";
import { ColumnFormatter } from "react-bootstrap-table-next";

interface FormatExtraDataProps {
  openCreateLeadPage: Function;
}

interface RowProps {
  id: string;
}

export const LeadCreationActionsColumnFormatter: ColumnFormatter<RowProps, FormatExtraDataProps> = (
  cell,
  row,
  rowIndex,
  formatExtraData
) => (
  <>
    {formatExtraData && (
      <span onClick={(e) => e.stopPropagation()}>
        <a
          className="btn btn-primary btn-sm font-weight-bold px-6"
          onClick={() => formatExtraData?.openCreateLeadPage(row.id)}
        >
          <FormattedMessage id="COMMON.ACTION.SELECT" />
        </a>
      </span>
    )}
  </>
);
