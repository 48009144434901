import { KycConfirmationDialogConfiguration, KycFlow, KycFlowStatus, KycLoading } from "../Kyc";
import { KycTranslation } from "../KycTranslation";
import { useKyc } from "../../../hooks/useKyc";
import { KycConfirmationDialog } from "./KycConfirmationDialog";
import React, { ReactNode } from "react";
import { useKycConfirmation } from "../../../hooks/useKycConfirmation";
import { useKycFile } from "../../../hooks/useKycFile";
import { useKycFlow } from "../../../hooks/useKycFlow";

interface KycConfirmationDialogConfigProps {
  children?: ReactNode;
}

export enum KycDialogConfig {
  KYC_FLOW_CREATE = "KYC_FLOW_CREATE",
  KYC_FLOW_SEND_COMPLETION_REQUEST = "KYC_FLOW_SEND_COMPLETION_REQUEST",
  KYC_FLOW_SEND_SIGNATURE_REQUEST = "KYC_FLOW_SEND_SIGNATURE_REQUEST",
  KYC_FLOW_CONFIRM_SIGNATURES_COMPLETE = "KYC_FLOW_CONFIRM_SIGNATURES_COMPLETE",
  KYC_FLOW_ABORT = "KYC_FLOW_ABORT",
  KYC_FLOW_DELETE = "KYC_FLOW_DELETE",
  KYC_FLOW_APPROVED = "KYC_FLOW_APPROVED",
  KYC_FLOW_REJECT = "KYC_FLOW_REJECT",
  KYC_FLOW_RESEND = "KYC_FLOW_RESEND",
  KYC_FLOW_SEND_FORM_COMPLETION = "KYC_FLOW_SEND_FORM_COMPLETION",
  KYC_FLOW_RISK_ASSESSMENT_ACCEPT = "KYC_FLOW_RISK_ASSESSMENT_ACCEPT",
  KYC_FLOW_FILE_DELETE = "KYC_FLOW_FILE_DELETE",
  KYC_FLOW_DELETE_ENTITY = "KYC_FLOW_DELETE_ENTITY",
}

export const KycConfirmationDialogContainer = ({
  children,
}: KycConfirmationDialogConfigProps): JSX.Element => {
  const {
    sendKycRequest,
    sendSignatureRequest,
    deleteKycFlow,
    createKycFlow,
    rejectAndSendKycRequest,
    completeKycFlowPhase,
    updateKycFlowStatus,
    updateKycFlow,
    signaturesComplete,
  } = useKyc();

  const { deleteFile } = useKycFile();
  const { config } = useKycConfirmation();
  const { deleteEntityField } = useKycFlow();

  const getDialogPropsForConfig = (
    e?: any,
    field?: any,
    type?: KycDialogConfig,
    kycId?: string,
    file?: any,
    kyc?: KycFlow,
    func?: Function,
    funcParams?: any,
    labelValues?: any,
    entity?: any
  ): KycConfirmationDialogConfiguration | undefined => {
    switch (type) {
      case KycDialogConfig.KYC_FLOW_CREATE:
        return {
          fnAction: createKycFlow,
          fnParams: kyc,
          isLoading: KycLoading.CREATE_KYC_FLOW,
          isLoadingData: KycLoading.GET_KYC_CLIENTS,
          labelActionButton: KycTranslation.ACTION_START,
          colorActionButton: "primary",
          labelContent: KycTranslation.ACTION_SELECT_ACTIVE_FILE,
          labelTitle: KycTranslation.ACTION_START,
          children: config?.children,
        };
      case KycDialogConfig.KYC_FLOW_SEND_FORM_COMPLETION:
        return {
          fnAction: completeKycFlowPhase,
          fnParams: kycId,
          isLoading: KycLoading.COMPLETE_KYC_FLOW_PHASE,
          labelActionButton: "COMMON.ACTION.SUBMIT",
          colorActionButton: "primary",
          labelContent: KycTranslation.ARE_YOU_SURE_SUBMIT_COMPLETED_KYC_FORM,
          labelTitle: KycTranslation.ACTION_SUBMIT_COMPLETED_KYC_FORM_TITLE,
        };
      case KycDialogConfig.KYC_FLOW_RISK_ASSESSMENT_ACCEPT:
        return {
          fnAction: updateKycFlow,
          fnParams: [{ ...kyc, status: KycFlowStatus.ADMIN_RISK_ASSESSMENT_VALIDATED }, true],
          isLoading: KycLoading.UPDATE_KYC_FLOW,
          labelActionButton: KycTranslation.ACTION_VALIDATE,
          colorActionButton: "success",
          labelContent: KycTranslation.ACTION_VALIDATE_RISK_ASSESSMENT_ARE_YOU_SURE,
          labelTitle: KycTranslation.ACTION_VALIDATE_RISK_ASSESSMENT_TITLE,
        };
      case KycDialogConfig.KYC_FLOW_SEND_COMPLETION_REQUEST:
        return {
          fnAction: sendKycRequest,
          fnParams: [kyc?.id, kyc?.notification],
          isLoading: KycLoading.CREATE_KYC_FLOW_REQUEST,
          labelActionButton: "COMMON.ACTION.SEND",
          colorActionButton: "primary",
          labelContent: KycTranslation.ARE_YOU_SURE_SEND_KYC_REQUEST,
          labelTitle: KycTranslation.ACTION_SEND_FORM_COMPLETION_REQUEST_TITLE,
        };
      case KycDialogConfig.KYC_FLOW_RESEND:
        return {
          labelActionButton: "COMMON.ACTION.SEND",
          fnAction: rejectAndSendKycRequest,
          // @ts-ignore
          fnParams: [kyc?.id, kyc?.notification, kyc?.clientNotes?.[0] ?? ""],
          isLoading: KycLoading.CREATE_KYC_FLOW_REQUEST,
          colorActionButton: "primary",
          labelContent: KycTranslation.ARE_YOU_SURE_SEND_KYC_REQUEST,
          labelTitle: KycTranslation.ACTION_SEND_FORM_COMPLETION_REQUEST_TITLE,
        };
      case KycDialogConfig.KYC_FLOW_SEND_SIGNATURE_REQUEST:
        return {
          fnAction: sendSignatureRequest,
          fnParams: [kycId || kyc?.id, kyc?.signatories],
          isLoading: KycLoading.SEND_SIGNATURE_REQUEST,
          labelActionButton: "COMMON.ACTION.LAUNCH",
          colorActionButton: "primary",
          labelContent: KycTranslation.ARE_YOU_SURE_SEND_BO_DECLARATIONS_SIGNATURE_REQUEST,
          labelTitle: KycTranslation.ACTION_SEND_BO_DECLARATIONS_SIGNATURE_REQUEST_TITLE,
        };
      case KycDialogConfig.KYC_FLOW_CONFIRM_SIGNATURES_COMPLETE:
        return {
          isLoading: KycLoading.SIGNATURES_COMPLETE,
          labelActionButton: "COMMON.ACTION.SIGNATURES_COMPLETE",
          colorActionButton: "primary",
          labelTitle: "COMMON.ACTION.CONFIRM_SIGNATURES_COMPLETE.TITLE",
          labelContent: "COMMON.ACTION.CONFIRM_SIGNATURES_COMPLETE",
          fnAction: signaturesComplete,
          fnParams: kycId,
        };
      case KycDialogConfig.KYC_FLOW_ABORT:
        return {
          fnAction: updateKycFlowStatus,
          fnParams: [kyc, KycFlowStatus.CANCELED],
          isLoading: KycLoading.UPDATE_KYC_FLOW,
          labelActionButton: KycTranslation.ACTION_KYC_FLOW_ABORT,
          colorActionButton: "warning",
          labelContent: KycTranslation.ACTION_ABORT_KYC_FLOW_ARE_YOU_SURE,
          labelTitle: KycTranslation.ACTION_KYC_FLOW_ABORT,
        };
      case KycDialogConfig.KYC_FLOW_DELETE:
        return {
          fnAction: deleteKycFlow,
          fnParams: kycId,
          isLoading: KycLoading.DELETE_KYC_FORM,
          labelActionButton: "COMMON.ACTION.DELETE",
          colorActionButton: "danger",
          labelContent: KycTranslation.ACTION_DELETE_KYC_FLOW_ARE_YOU_SURE,
          labelTitle: "COMMON.ACTION.DELETE",
        };
      case KycDialogConfig.KYC_FLOW_FILE_DELETE:
        return {
          fnAction: deleteFile,
          fnParams: file,
          isLoading: KycLoading.DELETE_FILE,
          isLoadingId: file?.id,
          labelActionButton: "COMMON.ACTION.DELETE",
          colorActionButton: "danger",
          labelContent: "COMMON.ACTION.DELETE.ARE_YOU_SURE.USER.FILE",
          labelTitle: "COMMON.ACTION.DELETE",
        };
      case KycDialogConfig.KYC_FLOW_DELETE_ENTITY:
        return {
          fnAction: deleteEntityField,
          isFnAsync: true,
          fnParams: [e, { field: field, entity: entity }],
          func: func,
          funcParams: funcParams,
          isLoading: KycLoading.DELETE_KYC_FLOW_ENTITY,
          isLoadingId: file?.id,
          labelActionButton: "COMMON.ACTION.DELETE",
          colorActionButton: "danger",
          labelValues: labelValues,
          labelContent: KycTranslation.ACTION_DELETE_ARE_YOU_SURE_ENTITY,
          labelTitle: "COMMON.ACTION.DELETE",
        };

      case KycDialogConfig.KYC_FLOW_APPROVED:
        return {
          fnAction: updateKycFlowStatus,
          fnParams: [kyc, KycFlowStatus.KYC_APPROVED],
          isLoading: KycLoading.UPDATE_KYC_FLOW,
          labelActionButton: KycTranslation.ACTION_KYC_FLOW_APPROVE,
          colorActionButton: "success",
          labelContent: KycTranslation.ACTION_APPROVE_KYC_FLOW_ARE_YOU_SURE,
          labelTitle: KycTranslation.ACTION_KYC_FLOW_APPROVE,
        };
      case KycDialogConfig.KYC_FLOW_REJECT:
        return {
          fnAction: updateKycFlowStatus,
          fnParams: [kyc, KycFlowStatus.KYC_REJECTED],
          isLoading: KycLoading.UPDATE_KYC_FLOW,
          labelActionButton: KycTranslation.ACTION_KYC_FLOW_REJECT,
          colorActionButton: "danger",
          labelContent: KycTranslation.ACTION_REJECT_KYC_FLOW_ARE_YOU_SURE,
          labelTitle: KycTranslation.ACTION_KYC_FLOW_REJECT,
        };
    }

    return undefined;
  };

  return (
    <KycConfirmationDialog
      config={getDialogPropsForConfig(
        config?.e,
        config?.field,
        config?.type,
        config?.kycId,
        config?.file,
        config?.kyc,
        config?.func,
        config?.funcParams,
        config?.labelValues,
        config?.entity
      )}
    >
      {children}
    </KycConfirmationDialog>
  );
};
