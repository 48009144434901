import * as React from "react";
import { ConfirmationDialog, FunctionType } from "./ConfirmationDialog";

export interface IConfirmationDialogProps {
  action?: { fn: any; props?: any; fnType?: FunctionType };

  title: string;
  body: string;
  extraContent?: React.ReactNode;
  loading?: string;
  actionButtonLabel?: string;
  actionButtonClassName?: string;
  hideButtonLabel?: string;
  hideButtonClassName?: string;
}
export interface IConfirmationDialogUIContext {
  confirmationDialog?: IConfirmationDialogProps;
  unSetConfirmationDialog: () => void;
  setConfirmationDialog: React.Dispatch<React.SetStateAction<IConfirmationDialogProps | undefined>>;
}

const ConfirmationDialogContext = React.createContext<IConfirmationDialogUIContext>(
  {} as IConfirmationDialogUIContext
);

export function useConfirmationDialogContext() {
  return React.useContext(ConfirmationDialogContext);
}

export function ConfirmationDialogProvider(props: any) {
  const [confirmationDialog, setConfirmationDialog] = React.useState<IConfirmationDialogProps>();
  const unSetConfirmationDialog = React.useCallback(() => {
    setConfirmationDialog(undefined);
  }, [setConfirmationDialog]);

  return (
    <ConfirmationDialogContext.Provider
      value={{ confirmationDialog, unSetConfirmationDialog, setConfirmationDialog }}
      {...props}
    >
      {props.children}
      {confirmationDialog && (
        <ConfirmationDialog
          confirmationDialog={confirmationDialog}
          unSetConfirmationDialog={unSetConfirmationDialog}
        />
      )}
    </ConfirmationDialogContext.Provider>
  );
}

export default ConfirmationDialogProvider;
