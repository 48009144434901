/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid,jsx-a11y/role-supports-aria-props */
import * as React from "react";
import { shallowEqual } from "react-redux";
import * as actions from "./_redux/budgetsActions";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
  ModalProgressBar,
} from "../../../_metronic/_partials/controls";
import { BudgetEditForm } from "./BudgetEditForm";
import { useSubheader } from "../../../_metronic/layout";
import { useQueryState } from "react-router-use-location-state";
import { v4 as uuid } from "uuid";
import { FormattedMessage, useIntl } from "react-intl";
import { canCreate, canEdit } from "../../_utils/authUtils";
import { useAutoSave } from "../../_utils/useAutoSave";
import { useCustomLocationState } from "../../_utils/useCustomLocationState";
import { BudgetsUIProvider } from "./BudgetsUIContext";
import { Route, RouteComponentProps } from "react-router-dom";
import { BudgetLineDialog } from "./Expenses/components/BudgetLineDialog";
import * as projectActions from "../PropertiesManagement/_redux/projects/projectsActions";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { useWillUnmount } from "rooks";
import { IBudget } from "../../../data/schemas";

const initBudget = () => ({
  id: undefined,
  name: "",
  projectId: "",
  projectName: "",
  sortedLines: [
    {
      id: uuid(),
      label: "",
      visible: true,
      sequence: 0,
      lines: [{ id: uuid(), label: "", plannedBudget: 0, sequence: 0 }],
    },
  ],
  budgetInstalments: [
    {
      id: uuid(),
      label: "",
      instalment: 0,
      sequence: 0,
    },
  ],
});

interface IBudgetEditMatchParams {
  id: string;
}

export const BudgetEdit: React.FC<RouteComponentProps<IBudgetEditMatchParams>> = ({
  history,
  match: {
    params: { id },
  },
  location,
}) => {
  const intl = useIntl();
  // Subheader
  const subheader = useSubheader();

  const [title, setTitle] = React.useState("");
  // const layoutDispatch = useContext(LayoutContext.Dispatch);
  const { actionsLoading, budgetForEdit, originalBudgetForEdit, session, groups, project } =
    useAppSelector(
      (state) => ({
        actionsLoading: state.budgets.actionsLoading,
        originalBudgetForEdit:
          state?.budgets?.budgetForEdit?.saved ??
          state?.budgets?.entities?.find((entity) => entity.id === id),
        budgetForEdit:
          state?.budgets?.budgetForEdit?.current ??
          state?.budgets?.entities?.find((entity) => entity.id === id),
        project: state?.projects?.projectForEdit?.saved,
        groups: state.auth.groups,
        session: state.auth.session,
      }),
      shallowEqual
    );

  useAutoSave(budgetForEdit, originalBudgetForEdit, actions.updateBudget);

  const readOnly = id ? !canEdit(groups, session, "BUDGET") : !canCreate(groups, session, "BUDGET");

  const budgetEditFormCallbackRef = React.useRef<() => void>();
  const [projectId] = useQueryState("projectId", "");

  const dispatch = useAppDispatch();

  // Clean up active budget on unmount
  useWillUnmount(() => {
    dispatch(actions.fetchBudget());
  });

  // Fetch existing budget
  React.useEffect(() => {
    if (id) {
      dispatch(actions.fetchBudget(id));
    } else {
      dispatch(actions.updateBudgetLocally(initBudget()));
    }
  }, [id]);

  const [firstLoadBudget, setFirstLoadBudget] = React.useState(false);
  // Fetch project from existing budget or for new budget with projectId query param
  React.useEffect(() => {
    if (
      originalBudgetForEdit &&
      ((!firstLoadBudget && originalBudgetForEdit.id === id) ||
        project?.id !== originalBudgetForEdit.projectId)
    ) {
      setFirstLoadBudget(true);
      dispatch(projectActions.fetchProject(originalBudgetForEdit.projectId));
    } else if (!originalBudgetForEdit && projectId) {
      dispatch(projectActions.fetchProject(projectId));
    }
  }, [originalBudgetForEdit, projectId, project?.id]);

  // Set default value for new budget from projectId
  React.useEffect(() => {
    if (project && project.id === projectId && !budgetForEdit?.projectName) {
      saveBudgetFields(["projectId", "projectName"], [project.id, project.name]);
    }
  }, [project, projectId]);

  // Set card title
  React.useEffect(() => {
    if (!id || budgetForEdit?.id === id) {
      const _title = !id
        ? intl.formatMessage({ id: "BUDGET.TITLE.NEW" })
        : budgetForEdit?.name ?? "";
      setTitle(_title);
      subheader.setTitle(_title);
    }
  }, [budgetForEdit, id]);

  const saveBudgetFields = (key: string | string[], value: any) => {
    if (Array.isArray(key) && key.length === value.length) {
      for (let i = 0; i < key.length; i++) {
        dispatch(actions.updateBudgetFieldLocally(key[i], value[i]));
      }
    } else {
      dispatch(actions.updateBudgetFieldLocally(key, value));
    }
  };

  const submitBudget = () => {
    if (!id) {
      dispatch(actions.createBudget(budgetForEdit)).then((budget) => {
        if (budget?.id) {
          history.push(`/budgets/${budget.id}`);
        }
      });
    }
  };

  const { goBack } = useCustomLocationState();
  const backButtonClick = () => {
    goBack(`/projects/${budgetForEdit?.projectId || projectId}?t=budgets`);
  };

  return (
    <>
      <Card>
        {actionsLoading && <ModalProgressBar />}
        <CardHeader title={title}>
          <CardHeaderToolbar>
            <button type="button" onClick={backButtonClick} className="btn btn-light">
              <i className="fa fa-arrow-left" />
              {id ? (
                <FormattedMessage id="COMMON.ACTION.BACK" />
              ) : (
                <FormattedMessage id="COMMON.ACTION.CANCEL" />
              )}
            </button>
            {!id && !readOnly && (
              <button
                type="submit"
                className="btn btn-primary ml-2"
                onClick={budgetEditFormCallbackRef?.current}
                data-cy="save-budget-btn"
                disabled={actionsLoading}
              >
                <FormattedMessage id="COMMON.ACTION.CREATE" />
              </button>
            )}
          </CardHeaderToolbar>
        </CardHeader>
        <CardBody>
          <div className="mt-5">
            <BudgetsUIProvider>
              {!!budgetForEdit ? (
                <>
                  <BudgetEditForm
                    submitBudget={submitBudget}
                    budget={budgetForEdit as IBudget}
                    disabled={readOnly}
                    onSubmitRef={(callback) => {
                      budgetEditFormCallbackRef.current = callback;
                    }}
                  />
                </>
              ) : (
                <div className="d-flex align-items-center justify-content-center">
                  <div className="spinner spinner-lg spinner-primary h-30px w-30px" />
                </div>
              )}
              {!!originalBudgetForEdit && !!project && (
                <>
                  <Route
                    path="/budgets/:id/:categoryId/:lineId"
                    exact
                    render={({ history, match }) => (
                      <BudgetLineDialog
                        show={match != null}
                        budgetId={match?.params?.id}
                        categoryId={match?.params?.categoryId}
                        lineId={match?.params?.lineId}
                        onHide={() => {
                          goBack(`/budgets/${originalBudgetForEdit.id}`);
                          subheader.setTitle(budgetForEdit!.name);
                        }}
                        history={history}
                      />
                    )}
                  />
                </>
              )}
            </BudgetsUIProvider>
          </div>
        </CardBody>
      </Card>
    </>
  );
};
