import { PayloadAction, Draft, SliceCaseReducers, createSlice } from "@reduxjs/toolkit";
import { set } from "lodash-es";

import { ICompany, ISettings } from "data/schemas";

import {
  IState,
  CallType,
  IFieldUpdatedLocallyPayload,
  ICallTypePayload,
  IsettingsPayload,
  IPropCosPayload,
  IPropCoIdPayload,
  IPropCoPayload,
  stateName as name,
  initialState,
} from "./definitions";
import { cloneDeep } from "lodash";

const resetLoadingStates = (state: Draft<IState>) => {
  state.getLoading = false;
  state.listLoading = false;
  state.actionLoading = false;
};

export const settingsSlice = createSlice<IState, SliceCaseReducers<IState>>({
  name,
  initialState,

  reducers: {
    resetLoadingStates,

    catchError: (state) => resetLoadingStates(state),

    startCall: (state, action: PayloadAction<ICallTypePayload>) => {
      const { callType } = action.payload;

      switch (callType) {
        case CallType.GET:
          state.getLoading = true;
          break;
        case CallType.LIST:
          state.listLoading = true;
          break;
        case CallType.ACTION:
          state.actionLoading = true;
          break;
      }
    },

    avatarCreated: (state) => {
      state.actionLoading = false;
    },

    settingsFetched: (state, action: PayloadAction<IsettingsPayload>) => {
      state.getLoading = false;

      const { settings } = action.payload;

      state.settingsForEdit = {
        saved: settings,
        current: settings,
      };
    },

    settingsFieldUpdatedLocally: (state, action: PayloadAction<IFieldUpdatedLocallyPayload>) => {
      if (!state.settingsForEdit.current) {
        state.settingsForEdit.current = {
          email: "",
          mobile: "",
        };
      }

      const { key, value } = action.payload;

      set(state.settingsForEdit.current, key, value);
    },

    settingsUpdated: (state, action: PayloadAction<IsettingsPayload>) => {
      state.actionLoading = false;

      const { settings } = action.payload;

      state.settingsForEdit = {
        saved: settings,
        current: settings,
      };
    },

    settingsRemoveAvatar: (state) => {
      const { current: previousSettings = {} as ISettings, saved } = state.settingsForEdit;

      const newSettings: ISettings = {
        ...previousSettings,
        avatarPictureSetup: false,
        avatarPictureUrl: undefined,
      };

      state.settingsForEdit = {
        saved,
        current: newSettings,
      };
    },

    //---//

    propCoEntititesPreFetched: (state) => {
      state.listLoading = state.propCo.entities.length <= 0;
    },

    propCoEntititesFetched: (state, action: PayloadAction<IPropCosPayload>) => {
      state.listLoading = false;

      const { propCos } = action.payload;

      state.propCo.entities = propCos as ICompany[];
    },

    propCoPreFetched: (state, action: PayloadAction<IPropCoIdPayload>) => {
      const { propCoId } = action.payload;

      if (!propCoId) return;

      state.getLoading = true;

      const { entities } = state.propCo;
      const index = entities.findIndex(({ id }) => id === propCoId);
      if (index < 0) return;

      const propCo = entities[index];

      state.propCo.forEdit = {
        saved: propCo,
        current: propCo,
      };

      state.getLoading = false;
    },

    propCoFetched: (state, action: PayloadAction<IPropCoPayload>) => {
      state.getLoading = false;

      const { propCo } = action.payload;

      state.propCo.forEdit = {
        saved: propCo,
        current: propCo,
      };
    },

    propCoFieldUpdatedLocally: (state, action: PayloadAction<IFieldUpdatedLocallyPayload>) => {
      if (!state.propCo.forEdit.current) {
        state.propCo.forEdit.current = {
          email: "",
          mobile: "",
        };
      }

      const { key, value } = action.payload;

      set(state.propCo.forEdit.current, key, value);
    },

    propCoCreated: (state, action: PayloadAction<IPropCoPayload>) => {
      state.actionLoading = false;

      const { propCo } = action.payload;

      state.propCo.entities.push(propCo);
      state.propCo.forEdit = {
        saved: propCo,
        current: propCo,
      };
    },

    propCoUpdated: (state, action: PayloadAction<IPropCoPayload>) => {
      state.actionLoading = false;

      const { propCo } = action.payload;
      const propCoId = propCo.id;

      state.propCo.entities = state.propCo.entities.map((entity) => {
        if (entity.id === propCoId) {
          return propCo;
        }
        return entity;
      });

      state.propCo.forEdit = {
        saved: propCo,
        current: propCo,
      };
    },

    propCoRemoveAvatar: (state) => {
      const { current: previousPropCo = {} as ICompany, saved } = state.propCo.forEdit;

      const newPropCo: ICompany = {
        ...previousPropCo,
        avatarPictureSetup: false,
        avatarPictureUrl: undefined,
      };

      state.propCo.forEdit = {
        saved,
        current: newPropCo,
      };
    },

    propCoDeleted: (state, action: PayloadAction<IPropCoIdPayload>) => {
      state.actionLoading = false;

      const { propCoId } = action.payload;

      state.propCo.entities = state.propCo.entities.filter(({ id }) => id !== propCoId);
    },

    propCoResetForEdit: (state) => {
      state.propCo.forEdit = {
        saved: undefined,
        current: undefined,
      };
    },

    propCoDuplicated: (state, action: PayloadAction<IPropCoPayload>) => {
      const { propCo } = action.payload;

      state.propCo.forEdit = {
        saved: propCo,
        current: propCo,
      };
    },

    resetLocalSettings: (state, action) => {
      state.settingsForEdit.current = cloneDeep(state.settingsForEdit.saved);
    },
  },
});
