import { Auth } from "aws-amplify";
import { SUBCONTRACTOR } from "./userTypes";

export const masterEmailsRestriction = ["master@immotool.lu"];

export async function getUserGroups() {
  try {
    const currentSession = await Auth.currentSession();
    if (
      currentSession?.accessToken?.payload &&
      currentSession?.accessToken?.payload["cognito:groups"]
    ) {
      return currentSession.accessToken.payload["cognito:groups"];
    }
  } catch (e) {
    // Do nothing, session may not be initialized
  }
  return [];
}

/**
 *
 * @param {string|stringp[]} searchEntityType
 * @param {string|string[]|undefined} searchRightType default value READ
 * @returns boolean
 */
const findRightFn = (searchEntityType, searchRightType = "READ") => {
  if (!searchEntityType) return (r) => false;

  const checkEntityType = Array.isArray(searchEntityType)
    ? ({ entityType }) => searchEntityType.includes(entityType)
    : ({ entityType }) => searchEntityType === entityType;

  const checkRightType = Array.isArray(searchRightType)
    ? ({ rightType }) => searchRightType.includes(rightType)
    : ({ rightType }) => searchRightType === rightType;

  return (r) => checkEntityType(r) && checkRightType(r);
};

/**
 *
 * @param {object} session
 * @param {string|string[]} searchEntityType
 * @param {string|string[]|undefined} searchRightType default value READ
 * @returns boolean
 */
export const hasSessionRight = (session, searchEntityType, searchRightType) =>
  !!session?.rights?.find(findRightFn(searchEntityType, searchRightType));

export const isAdmin = (groups = [], session = {}) =>
  groups?.map((g) => g.toLowerCase()).includes("admin") || hasSessionRight(session, "*", "*");

/**
 *
 * @param {string[]} groups
 * @param {object} session
 * @param {string|string[]} searchEntityType
 * @param {string|string[]|undefined} searchRightType default value READ
 * @returns boolean
 */
export const isAdminOrHasSessionRight = (groups, session, searchEntityType, searchRightType) =>
  isAdmin(groups, session) || hasSessionRight(session, searchEntityType, searchRightType);

export async function isAdminUser() {
  return isAdmin(await getUserGroups());
}

export const canEditUser = (groups, session, user) => {
  let res = false;
  user?.userTypes?.forEach((userType) => {
    res = res || canEdit(groups, session, userType);
  });
  return res;
};

export const canDeleteUser = (groups, session, user) => {
  let res = true;
  user?.userTypes?.forEach((userType) => {
    res = res && canDelete(groups, session, userType);
  });
  return res || isAdmin(groups, session);
};

export const isStaffMember = (groups, session) => (session?.userTypes ?? []).includes("USER");

export const isSubcontractor = (session) =>
  session?.userTypes?.includes(SUBCONTRACTOR) && session?.userTypes?.length === 1;

export const canAddOrRequestClientFilesOnLeadView = (groups, session) =>
  isAdminOrHasSessionRight(groups, session, "USER", "UPDATE");

export const canCreate = (groups, session, entityType) =>
  isAdminOrHasSessionRight(groups, session, entityType, "CREATE");

export const canReadAll = (groups, session, entityType) =>
  isAdminOrHasSessionRight(groups, session, entityType, "READ");

export const canEdit = (groups, session, entityType) =>
  isAdminOrHasSessionRight(groups, session, entityType, "UPDATE");

export const canDelete = (groups, session, entityType) =>
  isAdminOrHasSessionRight(groups, session, entityType, "DELETE");

export const canShare = (groups, session, entityType) =>
  isAdminOrHasSessionRight(groups, session, entityType, "SHARE");

export const canSeeSettings = (groups, session) => canReadAll(groups, session, "PROPCO");

export const canEditPropCoSettingsTab = (groups, session) => canEdit(groups, session, "PROPCO");

export const canDeletePropCoSettingsTab = (groups, session) => canDelete(groups, session, "PROPCO");

export const canGoOnTabAnalysis = (groups, session) =>
  isAdminOrHasSessionRight(groups, session, "PROJECT", "ANALYSIS");

export const canSeeAuditTrail = (groups, session, entityType) =>
  isAdminOrHasSessionRight(groups, session, entityType, "AUDIT_TRAIL");

export const canSeeNote = (groups, session, entityType) =>
  isAdminOrHasSessionRight(groups, session, entityType, "NOTE");

export const canDeletePhoto = (groups, session, entityType) =>
  isAdminOrHasSessionRight(groups, session, entityType, "DELETE_PHOTO");

export const canUploadPhoto = (groups, session, entityType) =>
  isAdminOrHasSessionRight(groups, session, entityType, "UPLOAD_PHOTO");

export const canUpdatePhoto = (groups, session, entityType) =>
  isAdminOrHasSessionRight(groups, session, entityType, "UPDATE_PHOTO");

export const canSeeHiddenLine = (groups, session) =>
  isAdminOrHasSessionRight(groups, session, "BUDGET", "HIDDEN_LINE");

export const canReadAllDocument = (groups, session, entityType) =>
  isAdminOrHasSessionRight(groups, session, entityType, "CONFIDENTIAL_DOCUMENT");

export const canSeeInvoiceManagementDashboard = (groups, session) =>
  isAdminOrHasSessionRight(groups, session, "PROJECT_INSIGHTS", "INVOICE_MANAGEMENT_DASHBOARD");
export const canSeeProjectInsights = (groups, session) =>
  canSeeInvoiceManagementDashboard(groups, session);

export const canReadAllUserDocument = (groups, session, user) => {
  let res = false;
  user?.userTypes.forEach((userType) => {
    res = res || canReadAllDocument(groups, session, userType);
  });
  return res;
};

export const canSeeClientsTabInLead = (groups, session) =>
  isAdmin(groups, session) || (session?.userTypes && session.userTypes.includes("USER"));

export const isOwner = (session, entity) => {
  return entity?.createdByUserId === session.id;
};
