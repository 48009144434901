import ActionNewButton from "app/_components/ActionNewButton";
import React from "react";

export function ClientsActions({ openAddClientToLead }) {
  return (
    <>
      <div className="form-filtration">
        <div className="row align-items-center">
          <div className="col-md-6 margin-bottom-10-mobile" />
          <div className="col-md-4 margin-bottom-10-mobile" />
          <div className="col-md-2 text-right margin-bottom-10-mobile">
            <ActionNewButton
              className="my-3"
              onClick={openAddClientToLead}
              messageId="CLIENT.ACTION.NEW"
            />
          </div>
        </div>
      </div>
    </>
  );
}
