const getTranslationFromRoute = (intl, pathname) => {
  switch (pathname) {
    case "projects":
      return intl.formatMessage({ id: "PROJECT.TITLE" });
    case "products":
      return intl.formatMessage({ id: "PRODUCT.TITLE" });
    case "users":
      return intl.formatMessage({ id: "USER.TITLE" });
    case "clients":
      return intl.formatMessage({ id: "CLIENT.TITLE" });
    case "subcontractors":
      return intl.formatMessage({ id: "SUBCONTRACTOR.TITLE" });
    case "leads":
      return intl.formatMessage({ id: "LEAD.TITLE" });
    case "tasks":
      return intl.formatMessage({ id: "TASK.TITLE" });
    case "settings":
      return intl.formatMessage({ id: "SETTING.TITLE" });
    case "propcos":
      return intl.formatMessage({ id: "PROPCOS.TITLE" });
    case "kyc":
      return intl.formatMessage({ id: "RIGHT.USER.KYC_FLOW" });
    default:
      return "";
  }
};

export const getRouteBreadcrumb = (intl, pathname, title, active) => {
  const paths = pathname
    .split("/")
    .filter((path) => !!path)
    .slice(0, -1);

  const routeBreadcrumb = [];

  // initial support to handle sub paths, for example: Settings / PropCos
  let breasdcrumbPathname = "";
  for (const path of paths) {
    if (
      [
        "projects",
        "products",
        "users",
        "clients",
        "subcontractors",
        "leads",
        "tasks",
        "settings",
        "propcos",
        "kyc",
      ].includes(path)
    ) {
      breasdcrumbPathname += `/${path}`;
      routeBreadcrumb.push({
        title: getTranslationFromRoute(intl, path),
        pathname: breasdcrumbPathname,
      });
    }
  }

  const route = { title };
  if (!active) {
    route.pathname = pathname;
  }
  routeBreadcrumb.push(route);
  return routeBreadcrumb;
};

export const translateBreadcrumbs = (intl, breadcrumbs) => {
  return breadcrumbs
    ? breadcrumbs.map((title) => {
        return { title: intl.formatMessage({ id: title }) };
      })
    : "";
};
