// https://github.com/cyntler/react-doc-viewer

import type { IDocument } from "@cyntler/react-doc-viewer";
import DocViewer from "@cyntler/react-doc-viewer";

import * as React from "react";
import PNGRenderer from "@cyntler/react-doc-viewer/dist/esm/renderers/png";
import JPGRenderer from "@cyntler/react-doc-viewer/dist/esm/renderers/jpg";
import BMPRenderer from "@cyntler/react-doc-viewer/dist/esm/renderers/bmp";
import HTMLRenderer from "@cyntler/react-doc-viewer/dist/esm/renderers/html";
import TXTRenderer from "@cyntler/react-doc-viewer/dist/esm/renderers/txt";
import CSVRenderer from "@cyntler/react-doc-viewer/dist/esm/renderers/csv";

import {
  CustomPdfRenderer,
  CustomSvgRenderer,
  CustomVideoRenderer,
  LoadingRenderer,
  NoRenderer,
} from "./custom-renderer";

interface FileViewerModalBodyProps {
  documents: IDocument[];
}

const FileViewerBody = ({ documents }: FileViewerModalBodyProps) => {
  return (
    <>
      {documents?.[0]?.uri && (
        <DocViewer
          config={{
            header: {
              disableHeader: true,
            },
            loadingRenderer: {
              overrideComponent: LoadingRenderer,
              showLoadingTimeout: 500,
            },
            noRenderer: {
              overrideComponent: NoRenderer,
            },
          }}
          documents={documents}
          pluginRenderers={[
            CustomPdfRenderer,
            CustomVideoRenderer,
            CustomSvgRenderer,
            PNGRenderer,
            JPGRenderer,
            BMPRenderer,
            HTMLRenderer,
            TXTRenderer,
            CSVRenderer,
          ]}
        />
      )}
    </>
  );
};

export default FileViewerBody;
