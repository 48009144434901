import React from "react";
import { IntlShape } from "react-intl";
import { selectedLeadStatus } from "../../_utils/formUtils";
import { ColumnFormatter } from "react-bootstrap-table-next";

interface FormatExtraDataProps {
  intl: IntlShape;
}

export const StatusColumnFormatter: ColumnFormatter<any, FormatExtraDataProps, string> = (
  cell,
  row,
  rowIndex,
  formatExtraData
) => {
  const leadStatus = selectedLeadStatus(cell ?? {});

  return (
    <span
      className={`label label-lg label-light-${leadStatus.className || "primary"} label-inline`}
    >
      {formatExtraData?.intl.formatMessage({ id: leadStatus.name })}
    </span>
  );
};
