import DatePicker from "react-datepicker";
import { useIntl } from "react-intl";
import { createPortal } from "react-dom";
import { KycFormElementBaseLayout } from "../KycControlsBaseLayout";
import { KycFieldLock } from "../KycFieldLock";
import "./KycDatePickerStyles.scss";

import PropTypes from "prop-types";
import React from "react";
import cn from "clsx";
import { formatDataCy } from "../../../KycUtils";
import { DataCyCompPrefix } from "../../../Kyc";
import { useKycFlow } from "../../../../../hooks/useKycFlow";

type KycDatePickerFieldProps = {
  field: any;
  withFeedbackLabel: boolean;
  inline: boolean;
  label: string;
  disabled: boolean;
  props: any;
  disableLabel?: boolean;
};

const CalendarContainer = ({ className, children, showPopperArrow, arrowProps = {} }: any) => {
  return (
    <div className={cn(className, "myCustomDate-1", "rounded")}>
      {showPopperArrow && <div className="react-datepicker__triangle " {...arrowProps} />}
      {children}
    </div>
  );
};

CalendarContainer.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  arrowProps: PropTypes.object,
  showPopperArrow: PropTypes.bool,
};

export const KycDatePicker = ({
  field,
  withFeedbackLabel = true,
  inline = true,
  label,
  disabled,
  disableLabel = false,
  ...props
}: KycDatePickerFieldProps): JSX.Element => {
  const intl = useIntl();
  if (field.value) {
    field.value = new Date(field.value).getTime();
  }
  const { saveField } = useKycFlow();

  const content = (
    <>
      <KycFieldLock field={field} disabled={disabled} type="date">
        <div data-cy={formatDataCy(DataCyCompPrefix.DATEPICKER, field?.name)}>
          <DatePicker
            {...field}
            calendarContainer={CalendarContainer}
            locale={intl.locale}
            showPopperArrow={false}
            showYearDropdown
            showMonthDropdown
            yearDropdownItemNumber={10}
            showTimeSelect
            isClearable
            disabled={disabled}
            dropdownMode="select"
            popperContainer={({ children }) => createPortal(children, document.body)}
            popperPlacement="top"
            popperClassName="custom-popper-zindex"
            timeIntervals={10}
            dateFormat="Pp"
            selected={(field.value && new Date(field.value)) || undefined}
            onChange={(val: any) => {
              saveField(undefined, {
                field: { name: field?.name, value: val?.toISOString() ?? null },
                isDateValue: true,
              });
            }}
            {...props}
          />
        </div>
      </KycFieldLock>
    </>
  );

  return (
    <KycFormElementBaseLayout
      disableLabel={disableLabel}
      field={field}
      content={content}
      disabled={disabled}
      label={label}
      inline={inline}
    />
  );
};
