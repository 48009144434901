import * as React from "react";
import { useState } from "react";
import { NumberInput } from "../../_utils/formUtils";
import cn from "clsx";
import { NumberFormatValues } from "react-number-format";

export interface IUnit {
  id: string;
  label: string;
}

interface UnitSwitchInputBaseProps {
  units: IUnit[];
}

export interface UnitSwitchInputProps {
  onValueChange: (values: NumberFormatValues) => void;
  value: number;
  unitValue: string | undefined;
  onUnitSwitch: (unit: string) => void;
}

export const UnitSwitchInput: React.FC<UnitSwitchInputBaseProps & UnitSwitchInputProps> = ({
  units,
  onValueChange,
  value,
  unitValue,
  onUnitSwitch,
}) => {
  const [activeUnit, setActiveUnit] = useState<IUnit>(units[0]);

  React.useEffect(() => {
    const unit = units.find((unit) => unit.id === unitValue);
    if (unit) {
      setActiveUnit(unit);
    }
  }, [unitValue]);

  const toggleUnitSwitch = (unit: IUnit) => () => {
    onUnitSwitch(unit.id);
  };
  return (
    <div className="input-group">
      <NumberInput
        className="form-control form-control-sm text-right"
        decimalScale={2}
        suffix={activeUnit.label}
        value={value}
        onValueChange={onValueChange}
      />
      <div className="input-group-append btn-group">
        {units.map((unit) => (
          <button
            key={unit.id}
            type="button"
            className={cn(
              "btn btn-sm btn-outline-secondary",
              activeUnit.id === unit.id && "active"
            )}
            onClick={toggleUnitSwitch(unit)}
          >
            {unit.label}
          </button>
        ))}
      </div>
    </div>
  );
};
