import * as React from "react";
import { useRef } from "react";
import { KycLoading, KycStepEvent } from "../../../Kyc";
import { useKycFile } from "../../../../../hooks/useKycFile";
import { useKyc } from "../../../../../hooks/useKyc";
import { useKycEvent } from "../../../../../hooks/useKycEvent";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../../../_metronic/_helpers";
import cn from "clsx";
import { useLoading } from "../../../../../hooks/useLoading";
import { Spinner } from "react-bootstrap";

type KycUploadInputProps = {
  signatory: any;
  disabled?: boolean;
  show?: boolean;
};

export const KycUploadInputSimple = ({
  signatory,
  disabled = true,
  show = false,
}: KycUploadInputProps): JSX.Element => {
  const { replaceFile } = useKycFile();
  const { currentKycFlow } = useKyc();
  const { createKycEventMessage } = useKycEvent();
  const { isCurrentlyLoading } = useLoading();

  const hiddenFileInput: any = useRef(null);

  const handleClick = (event: any) => {
    hiddenFileInput?.current?.click();
  };

  const handleFileUpload = (e: any) => {
    if (e?.target?.files?.[0]) {
      const sourceFileRaw = e?.target?.files?.[0];
      if (sourceFileRaw && sourceFileRaw?.size) {
        const f: any = {
          ...signatory?.selectedFile,
          friendlyName: sourceFileRaw?.name,
          fileExtension: sourceFileRaw?.name?.split(".")?.pop(),
          mimeType: sourceFileRaw.type,
          name: sourceFileRaw.name,
          freeText: "",
          body: sourceFileRaw,
        };
        replaceFile(f);
        currentKycFlow &&
          createKycEventMessage(currentKycFlow?.id, KycStepEvent.ADMIN_UPLOAD_PDF_BO_SIGNED, {
            boName: signatory?.name,
            boEmail: signatory?.email,
          });
      }
    }
  };

  return (
    <>
      {show && (
        <>
          {isCurrentlyLoading(KycLoading.SAVE_FILE, signatory?.selectedFile?.id) ? (
            <button disabled={true} className="btn btn-icon btn-light btn-hover-info btn-md ml-1">
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
                variant="primary"
              />
            </button>
          ) : (
            <>
              <button
                className={cn("btn btn-icon btn-light btn-hover-primary btn-sm ml-1")}
                onClick={handleClick}
              >
                <span
                  className={cn(
                    "svg-icon svg-icon-sm",
                    signatory?.signature?.status === "SIGNED" && "svg-icon-success",
                    signatory?.signature?.status === "CREATED" && "svg-icon-warning"
                  )}
                >
                  <SVG
                    src={toAbsoluteUrl(
                      signatory?.signature?.status === "SIGNED"
                        ? "/media/svg/icons/Navigation/Exchange.svg"
                        : "/media/svg/icons/Files/Upload.svg"
                    )}
                  />
                </span>
              </button>
              <input
                type="file"
                ref={hiddenFileInput}
                accept=".jpg, .jpeg, .png, .bmp, .pdf, .txt, .docx, .doc, .xls, .xlsx"
                onChange={handleFileUpload}
                style={{ display: "none" }}
              />
            </>
          )}
        </>
      )}
    </>
  );
};
