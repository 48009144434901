/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid,jsx-a11y/role-supports-aria-props */
import * as React from "react";
import { useEffect, useRef, useState } from "react";
import cn from "clsx";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { FormattedMessage, useIntl } from "react-intl";
import { isEqual } from "lodash-es";

import * as actions from "../../../../UsersManagement/_redux/usersActions";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
  ModalProgressBar,
} from "../../../../../../_metronic/_partials/controls";
import { UserEditForm } from "../../../../UsersManagement/pages/profile/profile-edit/UserEditForm";
import { useSubheader } from "../../../../../../_metronic/layout";
import { useQueryState } from "react-router-use-location-state";
import {
  formatDisplayNameIntl,
  isClientOrSubcontractor,
  isSameUser,
} from "../../../../../_utils/userUtils";
import { ActionsTab } from "../../../../UsersManagement/pages/profile/tabs/ActionsTab";
import { UserFiles } from "../../../../UsersManagement/pages/profile/user-files/UserFiles";
import { UserFilesUIProvider } from "../../../../UsersManagement/pages/profile/user-files/UserFilesUIContext";

import {
  canCreate,
  canEdit,
  canEditUser,
  canReadAll,
  canSeeAuditTrail,
  canSeeNote,
  isAdmin,
} from "../../../../../_utils/authUtils";
import { useCustomLocationState } from "../../../../../_utils/useCustomLocationState";
import { useAutoSave } from "../../../../../_utils/useAutoSave";
import { CLIENT, SUBCONTRACTOR, USER } from "../../../../../_utils/userTypes";
import { SubcontractorProjectsTable } from "../../../../UsersManagement/pages/profile/subcontractor-projects/SubcontractorProjectsTable";
import { UsersUIProvider } from "../UsersUIContext";
import { ENTITY_TYPE_PATH } from "../../../../../_utils/listUtils";
import { LeadTab } from "../../../../Lead/components/LeadTab";
import { TabNavHeader } from "../../../../../_components/TabNavHeader";
import { TextEditor } from "../../../../../_components/TextEditor/TextEditor";

import { TasksPageTab } from "app/modules/PropertiesManagement/pages/tasks/TasksPageTab";
import { useTasksSwitchContentView } from "app/modules/PropertiesManagement/pages/tasks/TasksCard/TasksCardContent/TasksSwitchContentView";

import UserRights from "./UserRights";
import { AuditTrailViewer } from "../../../../../_components/AuditTrail/AuditTrailViewer";
import { useAuditTrail } from "../../../../../hooks/useAuditTrail";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../../../_metronic/_helpers";
import { DateUtils } from "../../../../../_utils/DateUtils";
import { KycTranslation } from "../../../../kyc-v2/KycTranslation";
import { isKycAvailable } from "../../../../../_utils/configUtils";
import { KycComplianceTab } from "../../../../kyc-v2/components/KycComplianceTab";
import {
  mergeWithInitUser,
  saveUserFields,
  submitUserUtils,
} from "../../../../../_utils/userFormUtils";

export function UserEdit({
  history,
  match: {
    params: { id },
  },
  userType = USER,
}) {
  const intl = useIntl();
  const dispatch = useDispatch();

  // Subheader
  const subheader = useSubheader();

  // Tabs
  const [tab, setTab] = useQueryState("t", "user");
  const [, setHighlightId] = useQueryState("h", "");
  const [title, setTitle] = useState("the title");

  const { contentView, toggleContentView } = useTasksSwitchContentView();

  const { actionsLoading, entityForEdit, originalEntityForEdit, session, groups } = useSelector(
    (state) => ({
      actionsLoading: state.users.actionsLoading,
      originalEntityForEdit: state?.users?.entityForEdit.saved
        ? state.users.entityForEdit.saved
        : state?.users?.entities?.find((entity) => entity?.id === id),
      entityForEdit: state.users.entityForEdit.current
        ? state.users.entityForEdit.current
        : state?.users?.entities?.find((entity) => entity?.id === id),
      session: state.auth.session,
      groups: state.auth.groups,
    }),
    shallowEqual
  );
  const [isEmailAlreadyExists, setIsEmailAlreadyExists] = useState(false);

  useAutoSave(entityForEdit, originalEntityForEdit, actions.updateUser, [
    "rights",
    "isDisabled",
    "profilePictureUrl",
    "profilePictureUrlExpires",
    "lastSeenAt",
    "projects",
    "actions",
  ]);
  const readOnly = id
    ? !canEditUser(groups, session, entityForEdit) || isSameUser(session, entityForEdit)
    : !canCreate(groups, session, userType);
  const { goBack } = useCustomLocationState();

  const [userTypePath, setUserTypePath] = useState();
  useEffect(() => {
    setUserTypePath(ENTITY_TYPE_PATH[userType]);
  }, [userType]);

  useEffect(() => {
    dispatch(actions.fetchUserById(id, [userType]));
  }, [id]);

  const { fetchAuditTrail, setCurrentEntityObject } = useAuditTrail();

  React.useEffect(() => {
    if (tab === "history") {
      fetchAuditTrail(id, USER);
    }
    if (tab !== "files") {
      setHighlightId("");
    }
  }, [tab]);

  React.useEffect(() => {
    if (entityForEdit) {
      setCurrentEntityObject(entityForEdit);
    }
  }, [entityForEdit]);

  useEffect(() => {
    let _title = "";
    if (!id || originalEntityForEdit?.id === id) {
      _title = !id
        ? intl.formatMessage({
            id: `${userType}.ACTION.NEW`,
          })
        : formatDisplayNameIntl(intl, originalEntityForEdit);
      subheader.setTitle(_title);
    }
    setTitle(_title);
  }, [originalEntityForEdit, id]);

  useEffect(() => {
    if (isEmailAlreadyExists) {
      setIsEmailAlreadyExists(false);
    }
  }, [entityForEdit]);

  const userEditFormCallbackRef = useRef(null);

  const submitUser = () =>
    submitUserUtils({
      intl,
      user: entityForEdit,
      setIsEmailAlreadyExists,
      isEmailAlreadyExists,
      actionFn: (notes) =>
        dispatch(
          actions.createUser(
            mergeWithInitUser(
              {
                ...entityForEdit,
                userTypes: [userType],
                notes,
                username: entityForEdit.email,
              },
              userType
            )
          )
        ).then((res) => {
          if (res?.response?.status && res?.response?.status === 409) {
            setIsEmailAlreadyExists(true);
          } else if (res?.id) {
            history.push(`/${userTypePath}/${res.id}${history.location.search}`);
          }
        }),
    });

  const cancelButtonClick = () => goBack(`/${userTypePath}`);

  return (
    <Card style={{ height: tab === "tasks" ? "calc(100% - 1rem)" : "auto" }}>
      {actionsLoading && <ModalProgressBar />}

      <CardHeader
        title={
          <div className={"d-flex align-items-center"}>
            <span>{title}</span>
            {entityForEdit?.gdprApprovedAt && (
              <OverlayTrigger
                placement="top"
                overlay={
                  <Tooltip id="layout-tooltip" className={"tooltip-auto-width"}>
                    <FormattedMessage
                      id="USER.GDPR_APPROVED_AT"
                      values={{ date: DateUtils.format(entityForEdit.gdprApprovedAt, intl, true) }}
                    />
                  </Tooltip>
                }
              >
                <span className="svg-icon svg-icon-xl svg-icon-success ml-2 cursor-pointer">
                  <SVG
                    className={"pb-1"}
                    src={toAbsoluteUrl("/media/svg/icons/Navigation/Check.svg")}
                  />
                </span>
              </OverlayTrigger>
            )}
          </div>
        }
      >
        <CardHeaderToolbar>
          {!id && (
            <button
              type="button"
              data-cy="button-back"
              onClick={cancelButtonClick}
              className="btn btn-light"
            >
              <i className="fa fa-arrow-left" />
              <FormattedMessage id="COMMON.ACTION.CANCEL" />
            </button>
          )}
          {!id && !readOnly && (
            <button
              type="submit"
              className="btn btn-primary ml-2"
              onClick={userEditFormCallbackRef?.current}
              data-cy="button-user-create"
              disabled={actionsLoading}
            >
              <FormattedMessage id="COMMON.ACTION.CREATE" />
            </button>
          )}
        </CardHeaderToolbar>
      </CardHeader>

      <CardBody className={cn(tab === "tasks" && "d-flex flex-column")}>
        {id && (
          <ul className="nav nav-tabs nav-tabs-line " role="tablist">
            <TabNavHeader
              name={"user"}
              tab={tab}
              setTab={setTab}
              translationId={`${userType}.TITLE.SINGLE`}
              data_cy={"main-user-tab"}
            />
            <TabNavHeader
              name={"files"}
              tab={tab}
              setTab={setTab}
              translationId={"FILE.TITLE"}
              data_cy={"tab-files"}
            />
            <TabNavHeader
              name={"tasks"}
              tab={tab}
              setTab={setTab}
              translationId={"TASK.TITLE"}
              data_cy={"tab-tasks"}
            />
            {entityForEdit?.userTypes?.includes(SUBCONTRACTOR) && (
              <TabNavHeader
                name={"projects"}
                tab={tab}
                setTab={setTab}
                data_cy={"project-user-tab"}
                translationId={"PROJECT.TITLE"}
              />
            )}
            {entityForEdit?.userTypes?.includes(CLIENT) && canReadAll(groups, session, "LEAD") && (
              <TabNavHeader
                name={"leads"}
                tab={tab}
                setTab={setTab}
                translationId={"COMMON.TAB.TITLE.LEAD"}
                showLocker={true}
              />
            )}
            {isClientOrSubcontractor(entityForEdit) && canSeeNote(groups, session, userType) && (
              <TabNavHeader
                name={"notes"}
                tab={tab}
                setTab={setTab}
                translationId={"NOTE.TITLE"}
                showLocker={true}
                edit={!isEqual(entityForEdit?.notes || "", originalEntityForEdit?.notes || "")}
              />
            )}
            {!isSameUser(session, entityForEdit) &&
              (canReadAll(groups, session, "USER_RIGHTS") ||
                canCreate(groups, session, "USER_ACTION")) && (
                <TabNavHeader
                  name={"rights"}
                  tab={tab}
                  setTab={setTab}
                  translationId={"RIGHT.TITLE"}
                  showLocker={true}
                  data_cy={"tab-user-rights"}
                />
              )}
            {canSeeAuditTrail(groups, session, userType) && (
              <TabNavHeader
                name={"history"}
                tab={tab}
                setTab={setTab}
                translationId={"HISTORY.TITLE"}
                showLocker={true}
                data_cy={"tab-audit-trail"}
              />
            )}
            {isKycAvailable() &&
              entityForEdit?.userTypes?.includes(CLIENT) &&
              canReadAll(groups, session, "KYC_FLOW") && (
                <TabNavHeader
                  name="compliance"
                  tab={tab}
                  setTab={setTab}
                  showLocker={true}
                  data_cy={"tab-user-compliance"}
                  translationId={KycTranslation.COMPLIANCE_TITLE}
                />
              )}
          </ul>
        )}

        <div className={cn("mt-5", tab === "tasks" && "flex-grow-1")}>
          {(tab === "user" || !id) && (
            <div className={"pt-8"}>
              <UserEditForm
                user={
                  readOnly
                    ? mergeWithInitUser(originalEntityForEdit, userType)
                    : mergeWithInitUser(entityForEdit, userType)
                }
                originalUser={mergeWithInitUser(originalEntityForEdit, userType)}
                saveUserFields={(key, value) => saveUserFields(`${key}`, value)(dispatch)}
                disabled={readOnly}
                hideUserAvatar={true}
                isEmailAlreadyExists={isEmailAlreadyExists}
                submitUser={submitUser}
                onSubmitRef={(callback) => {
                  userEditFormCallbackRef.current = callback;
                }}
                isSameUser={isSameUser(session, entityForEdit)}
              />
            </div>
          )}
          {tab === "files" && entityForEdit && id && (
            <UserFilesUIProvider
              canUpload
              userTypes={entityForEdit.userTypes}
              currentUserId={id}
              readOnly={readOnly}
            >
              <UserFiles />
            </UserFilesUIProvider>
          )}
          {tab === "tasks" && entityForEdit && id && (
            <TasksPageTab
              {...{
                contentView,
                toggleContentView,
                history,
                relatedToName: formatDisplayNameIntl(intl, entityForEdit),
                relatedTo: `USER#${entityForEdit.id}`,
              }}
            />
          )}
          {tab === "projects" && entityForEdit && id && (
            <UsersUIProvider readOnly={readOnly}>
              <SubcontractorProjectsTable />
            </UsersUIProvider>
          )}
          {tab === "notes" &&
            isClientOrSubcontractor(entityForEdit) &&
            canSeeNote(groups, session, userType) && (
              <TextEditor
                name={"notes"}
                data={entityForEdit?.notes}
                saveMethod={(key, value) => saveUserFields(`${key}`, value)(dispatch)}
                disabled={readOnly}
              />
            )}
          {tab === "rights" && entityForEdit && id && (
            <>
              {!isSameUser(session, entityForEdit) && canCreate(groups, session, "USER_ACTION") && (
                <ActionsTab
                  afterDeleteAction={() => history.push(`/${userTypePath}/`)}
                  user={entityForEdit}
                  actionLoading={actionsLoading}
                />
              )}
              {entityForEdit?.userTypes?.includes(USER) &&
                canReadAll(groups, session, "USER_RIGHTS") && (
                  <UserRights
                    {...{
                      userId: id,
                      readOnly:
                        isSameUser(session, entityForEdit) ||
                        !canEdit(groups, session, "USER_RIGHTS") ||
                        (isAdmin([], entityForEdit) && !isAdmin(groups, session)),
                    }}
                  />
                )}
            </>
          )}
          {tab === "history" && canSeeAuditTrail(groups, session, userType) && <AuditTrailViewer />}
          {tab === "leads" && id && canReadAll(groups, session, "LEAD") && (
            <LeadTab
              context={"clientLeads"}
              name={formatDisplayNameIntl(intl, entityForEdit)}
              queryParam={{ userId: id }}
              readOnly={readOnly}
            />
          )}

          {isKycAvailable() &&
            id &&
            tab === "compliance" &&
            canReadAll(groups, session, "KYC_FLOW") && <KycComplianceTab userId={id} />}
        </div>
      </CardBody>
    </Card>
  );
}
