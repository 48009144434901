import {
  HEATING_TYPES,
  INVOICE_STATUS,
  LEAD_FILE_TYPES,
  PRODUCT_TYPES,
  PROJECT_PRODUCT_FILE_TYPES,
  PROPERTY_TYPES,
  USER_FILE_TYPES,
} from "../../_utils/listUtils";

export const IGNORE_FIELDS_COMMON = [
  "PK",
  "SK",
  "GSI1PK",
  "GSI1SK",
  "GSI2PK",
  "GSI2SK",
  "GSI3PK",
  "GSI3SK",
  "GSI4PK",
  "GSI4SK",
  "createdAt",
  "createdByUserId",
  "uploadedByUserId",
  "eventId",
  "updatedAt",
  "updatedBy",
  "updatedByUserId",
  "notes",
  "id",
  "userId",
  "taskId",
  "leadId",
  "projectId",
  "fileId",
  "clientId",
  "subcontractorId",
  "productId",
  "associatedTasks",
  "url",
];

export const IGNORE_FILE = [
  "signatures",
  "mimeType",
  "type",
  "bucketKey",
  "leadFinancialDocumentId",
  "leadId",
  "status",
  "userType",
  "attachments",
  "taskType",
  "descriptionShort",
  "name",
  "relatedTo",
  "assigneeId",
];

export const IGNORE_KYC = ["url"];

export const IGNORE_SIGNATURE = ["signaturePlaceholder", "file", "otpList", "signedDate"];

export const IGNORE_FIELDS = [
  ...IGNORE_FIELDS_COMMON,
  ...IGNORE_FILE,
  ...IGNORE_SIGNATURE,
  ...IGNORE_KYC,
];

export const PRODUCT_INFO_FIELDS = {
  acre: "PRODUCT.INFO.ACRE",
  architectEngineeringFees: "PRODUCT.INFO.ARCHITECT_ENGINEERING_FEES",
  balcony: "PRODUCT.INFO.BALCONIES.SINGULAR",
  balconies: "PRODUCT.INFO.BALCONY",
  balconySurface: "PRODUCT.INFO.BALCONY_SURFACE",
  bathrooms: "PRODUCT.INFO.BATHROOMS",
  bedrooms: "PRODUCT.INFO.BEDROOMS",
  cellars: "PRODUCT.INFO.CELLAR",
  cellar: "PRODUCT.INFO.CELLARS.SINGULAR",
  combinePlot: "PRODUCT.INFO.COMBINE_PLOTS.SINGULAR",
  constructionPrice: "PRODUCT.INFO.CONSTRUCTION_PRICE",
  energyPerformanceClass: "PRODUCT.INFO.ENERGY_PERFORMANCE_CLASS",
  environmentalPerformanceClass: "PRODUCT.INFO.ENVIRONMENTAL_PERFORMANCE_CLASS",
  floor: "PRODUCT.INFO.FLOOR",
  gardens: "PRODUCT.INFO.GARDEN",
  garden: "PRODUCT.INFO.GARDENS.SINGULAR",
  heatingType: "PRODUCT.INFO.HEATING_TYPE",
  housingProject: "PRODUCT.INFO.HOUSING_PROJECT",
  landArchitectNumber: "PRODUCT.INFO.LAND_ARCHITECT_NUMBER",
  landRegistryNumber: "PRODUCT.INFO.LAND_REGISTRY_NUMBER",
  livingSurface: "PRODUCT.INFO.LIVING_SURFACE",
  lot: "PRODUCT.INFO.LOT",
  numberBlockStaircase: "PRODUCT.INFO.NUMBER_BLOCK_STAIRCASE",
  parcel: "PRODUCT.INFO.PARCEL",
  parking: "PRODUCT.INFO.PARKINGS.SINGULAR",
  rooms: "PRODUCT.INFO.ROOMS",
  shareOfLand: "PRODUCT.INFO.SHARE_OF_LAND",
  slotIndoor: "PRODUCT.INFO.SLOT_INDOOR",
  slotOutdoor: "PRODUCT.INFO.SLOT_OUTDOOR",
  surface: "PRODUCT.INFO.SURFACE",
  terraces: "PRODUCT.INFO.TERRACE",
  terrace: "PRODUCT.INFO.TERRACES.SINGULAR",
  thermalInsulationClass: "PRODUCT.INFO.THERMAL_INSULATION_CLASS",
  thousands: "PRODUCT.INFO.THOUSANDS",
  totalOuterSurface: "PRODUCT.INFO.TOTAL_OUTER_SURFACE",
  totalThousands: "PRODUCT.INFO.TOTAL_THOUSANDS",
  numberBlockStaircaseLevelCadastreCellar: "PRODUCT.INFO.NUMBER_BLOCK_STAIRCASE",
  propertyType: "PRODUCT.TYPE",
  EMAIL: "USER.INFO.EMAIL",
  SMS: "COMMON.SMS",
  notifyUser: "COMMON.ACTION.NOTIFY_USER_BY",
};

export const PROPERTY_TO_MESSAGE_ID: any = {
  true: "COMMON.ACTION.YES",
  false: "COMMON.ACTION.NO",
  dueDate: "TASK.LABEL.DATE.DUE",
  isConfidential: "COMMON.CONFIDENTIAL.DOCUMENT",
  amountPaidToDate: "TASK.LABEL.PAID_TO_DATE",
  amount: "COMMON.AMOUNT",
  authorisationCode: "LEAD.AUTHORISATION_CODE",
  invoiceStatus: "COMMON.INVOICE.STATUS",
  relatedEntityName: "TASK.LABEL.RELATED_TO",
  fileType: "COMMON.TYPE",
  friendlyName: "COMMON.NAME",
  title: "TASK.LABEL.TITLE",
  constructionPriceWithReducedVat: "LEAD.CONSTRUCTION_PRICE_WITH_REDUCED_VAT",
  uploadedAt: "COMMON.UPLOADED.DATE.TITLE",
  fileExtension: "COMMON.FILE.FILE_EXTENSION",
  ...PROPERTY_TYPES,
  ...LEAD_FILE_TYPES,
  ...PROJECT_PRODUCT_FILE_TYPES,
  // ...LEGAL_ENTITY_TYPES,
  // ...TITLES,
  ...HEATING_TYPES,
  ...PRODUCT_TYPES,
  ...INVOICE_STATUS,
  // ...PRODUCT_STATUS,
  ...PRODUCT_INFO_FIELDS,
  // ...SOURCE_OF_FUNDS,
  //   ...EMPLOYMENT_STATUS,
  //   ...EMPLOYER_SECTOR,
  //   ...LEAD_STATUS,
  ...USER_FILE_TYPES.USER,
  ...USER_FILE_TYPES.CLIENT,
  ...USER_FILE_TYPES.SUBCONTRACTOR,
};

export const TRANSLATIONS_WITH_PARAM_VALUES = [
  "FILE_OPEN_DOCUMENT",
  "FILE_UPLOADED_REQUESTED_FILE",
  "SIGNATURE_REQUEST_CREATED",
  "SIGNATURE_DOCUMENT_SIGNED",
];
