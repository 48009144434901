import * as React from "react";
import { Portal } from "react-portal";
import { useIntl } from "react-intl";
import { useDidUpdate } from "rooks";
import cn from "clsx";

export interface LoadingDialogProps {
  isLoading?: boolean;
}

const buildCssClasses = (isLoading = false) =>
  cn(
    "dialog dialog-default dialog-loader dialog-top-center",
    isLoading ? "dialog-shown" : "dialog-hidden"
  );

export const LoadingDialog: React.FunctionComponent<LoadingDialogProps> = ({
  isLoading = false,
}) => {
  const intl = useIntl();

  const [cssClasses, setCssClasses] = React.useState(buildCssClasses(isLoading));

  useDidUpdate(() => {
    setCssClasses(buildCssClasses(isLoading));
  }, [isLoading]);

  return (
    <Portal node={document && document.getElementById("layout-portal")}>
      <div className={cssClasses}>{intl.formatMessage({ id: "COMMON.LOADING" })}</div>
    </Portal>
  );
};

export default LoadingDialog;
