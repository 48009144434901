import { SortOrder } from "react-bootstrap-table-next";
import { IBudgetFilters } from "./BudgetsUIContext";

export const defaultSorted: [{ dataField: any; order: SortOrder }] = [
  { dataField: "status", order: "asc" },
];
export const sizePerPageList = [
  { text: "5", value: 5 },
  { text: "10", value: 10 },
  { text: "25", value: 25 },
  { text: "50", value: 50 },
];
export const initialFilters: IBudgetFilters = {
  filterSelected: [],
  pageNumber: 1,
  pageSize: 25,
};
