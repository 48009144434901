import { Spinner } from "react-bootstrap";
import * as React from "react";
import { KycFile } from "../../../Kyc";

interface KycUploadProgressProps {
  file?: KycFile;
  progress: number;
}

export const KycUploadProgress = ({ file, progress }: KycUploadProgressProps): JSX.Element => {
  return (
    <>
      {file && file.size && file?.size > 2000000 ? (
        <div className="position-relative">
          <span
            className="position-absolute m-auto"
            style={{ left: 48, transform: "translateY(50%)" }}
          >
            <Spinner
              as="span"
              animation="border"
              size="sm"
              role="status"
              aria-hidden="true"
              variant="primary"
            />
          </span>
          <button disabled={true} className="btn btn-icon btn-light btn-hover-info btn-md ml-1">
            <div className="d-relative align-content-center">
              <div>{progress}%</div>
            </div>
          </button>
        </div>
      ) : (
        <button disabled={true} className="btn btn-icon btn-light btn-hover-info btn-md ml-1">
          <Spinner
            as="span"
            animation="border"
            size="sm"
            role="status"
            aria-hidden="true"
            variant="primary"
          />
        </button>
      )}
    </>
  );
};
