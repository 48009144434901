import * as React from "react";
import { useLeadContext } from "../../../../LeadContext";
import {
  CurrencyMetricCard,
  MetricCard,
  MetricCardProps,
} from "../../../../../../../../../_components/MetricCard";
import { roundByTo100 } from "../../../../../../../../../_utils/mathUtils";

export interface PricingBreakdownProps {}

export const PricingBreakdown: React.FC<PricingBreakdownProps & MetricCardProps> = (props) => {
  const { leadInstalments, leadSupplements } = useLeadContext();

  let totalInclVAT = 0;
  let instalmentsTotal = 0;
  let supplementsTotal = 0;
  let totalTaxes = 0;
  for (const item of leadInstalments) {
    totalInclVAT += item.amountInclVAT ?? 0;
    totalTaxes += item.taxes ?? 0;
    instalmentsTotal += item.amountInclPriceIndex ?? 0;
  }
  for (const item of leadSupplements) {
    totalInclVAT += item.amountInclVAT ?? 0;
    totalTaxes += item.taxes ?? 0;
    supplementsTotal += item.amountInclPriceIndex ?? 0;
  }

  const roundedPercents = roundByTo100(
    [instalmentsTotal, supplementsTotal, totalTaxes],
    totalInclVAT
  );

  return (
    <CurrencyMetricCard
      title="LEAD.INSTALMENTS.PRICING_BREAKDOWN"
      dataCy="input-lead-instalments-expected-price-incl-vat"
      value={totalInclVAT}
      label="LEAD.INSTALMENTS.TOTAL_EXPECTED_PRICE_INCL_VAT"
      {...props}
    >
      <MetricCard.CurrencySubMetric
        dataCy="input-lead-instalments-total-instalments"
        label="LEAD.INSTALMENTS.TOTAL_PRICE_INSTALMENTS"
        value={instalmentsTotal}
        secondValue={roundedPercents[0]}
      />
      <MetricCard.CurrencySubMetric
        dataCy="input-lead-instalments-total-supplements"
        label="LEAD.INSTALMENTS.TOTAL_PRICE_SUPPLEMENTS"
        value={supplementsTotal}
        secondValue={roundedPercents[1]}
      />
      <MetricCard.CurrencySubMetric
        dataCy="input-lead-instalments-total-taxes"
        label="LEAD.INSTALMENTS.TOTAL_TAXES"
        value={totalTaxes}
        secondValue={roundedPercents[2]}
      />
    </CurrencyMetricCard>
  );
};
