import React, { FC, useMemo, useState } from "react";
import {
  ActionsColumnFormatterComponent,
  ProgressBarFormatterComponent,
} from "../../../../../_components/column-formatters";
import { canDelete, canReadAll, canSeeHiddenLine, canShare } from "../../../../../_utils/authUtils";
import { useAppSelector } from "../../../../../../redux/hooks";
import { shallowEqual } from "react-redux";
import { useProjectsUIContext } from "../ProjectsUIContext";
import { ProjectWidget } from "../project-widget/ProjectWidget";
import { WidgetUserList } from "../../../../../_components/WidgetUserList";
import { cloneDeep } from "lodash-es";
import { FormattedMessage, useIntl } from "react-intl";
import { DateUtils } from "../../../../../_utils/DateUtils";
import { IBudget, IProject } from "../../../../../../data/schemas";
import { SUFFIX_EURO_CURRENCY } from "app/_utils/suffixUtils";

interface ProjectsMacroViewProps {
  projects: IProject[];
  shareProjectDialog: [boolean, Function];
  openDeleteProjectDialog: Function;
}

const maxSizeDescriptionCollapsed = 150;
const regExTagHtml = /(<([^>]+)>)/gi;

export const ProjectsMacroView: FC<ProjectsMacroViewProps> = ({
  projects,
  shareProjectDialog: [openShareProjectDialog, setOpenShareProjectDialog],
  openDeleteProjectDialog,
}) => {
  return (
    <div className={"d-flex flex-wrap align-items-stretch bg-gray-100 p-4"}>
      {projects.map((project, index) => (
        <ProjectCard
          key={index}
          project={project}
          index={index}
          budget={(project?.budgets ?? []).find((budget) => budget.id === project.selectedBudget)}
          shareProjectDialog={[openShareProjectDialog, setOpenShareProjectDialog]}
          openDeleteProjectDialog={openDeleteProjectDialog}
        />
      ))}
    </div>
  );
};

interface ProjectCardProps {
  index: number;
  project: IProject;
  budget: IBudget | undefined;
  shareProjectDialog: [boolean, Function];
  openDeleteProjectDialog: Function;
}

const ProjectCard: FC<ProjectCardProps> = ({
  index,
  project,
  budget,
  shareProjectDialog: [, setOpenShareProjectDialog],
  openDeleteProjectDialog,
}) => {
  const [isCollapsedDescriptionShown, setIsCollapsedDescriptionShown] = useState<boolean>(true);
  const isLongDescription =
    (project?.description?.replace(regExTagHtml, "")?.length ?? 0) > maxSizeDescriptionCollapsed;
  const { groups, session } = useAppSelector(
    (state) => ({
      groups: state.auth.groups,
      session: state.auth.session,
    }),
    shallowEqual
  );
  const intl = useIntl();
  const projectsUIContext = useProjectsUIContext();
  const projectsUIProps = useMemo(() => {
    return {
      openProjectPage: projectsUIContext.openProjectPage,
    };
  }, [projectsUIContext]);
  return (
    <>
      <div className={"col-12 col-lg-6 my-2"}>
        <div className={"card m-2 h-100"}>
          <div className={"card-body p-0"}>
            <div className={"px-6 pt-6"}>
              <div className={"d-flex flex-shrink-0 mt-lg-0"}>
                <div
                  style={{ cursor: "pointer" }}
                  className={"symbol symbol-40 symbol-md-60 symbol-lg-70"}
                  onClick={() => {
                    projectsUIProps.openProjectPage(project);
                  }}
                >
                  {!!project?.avatarPictureUrl ? (
                    <img
                      className="image-input-wrapper rounded-circle"
                      src={project?.avatarPictureUrl}
                      alt="Project avatar"
                    />
                  ) : (
                    <span className="symbol symbol-label font-weight-bold image-input-wrapper rounded-circle bg-gray-100">
                      <i className={"fas fa-layer-group text-info icon-xl"} />
                    </span>
                  )}
                </div>
                <div className={"ml-3"}>
                  <div
                    style={{ cursor: "pointer" }}
                    className="d-flex align-items-center text-dark font-size-h5 font-weight-bold text-hover-primary"
                    onClick={() => {
                      projectsUIProps.openProjectPage(project);
                    }}
                  >
                    {project?.name ?? "-"}
                  </div>
                  {project?.address?.city && (
                    <div className="d-flex flex-wrap my-2">
                      <div className="text-muted font-weight-bold">
                        <span className="svg-icon svg-icon-md svg-icon-gray-500 mr-1">
                          <i className={"fas fa-map-marker-alt icon-md"} />
                        </span>
                        {project?.address?.city}
                      </div>
                    </div>
                  )}
                </div>
                <div className={"ml-auto"}>
                  <ActionsColumnFormatterComponent
                    row={project}
                    rowIndex={index}
                    formatExtraData={{
                      openShareProjectDialog: setOpenShareProjectDialog,
                      openDeleteDialog: openDeleteProjectDialog,
                      actionList: ["SHARE", "TRASH"],
                      canDelete: (row: { budgetsCount: number; hiddenLineExist: boolean }) => {
                        if (row.budgetsCount) {
                          if (row.hiddenLineExist) {
                            return (
                              canDelete(groups, session, "PROJECT") &&
                              canDelete(groups, session, "BUDGET") &&
                              canSeeHiddenLine(groups, session)
                            );
                          }
                          return (
                            canDelete(groups, session, "PROJECT") &&
                            canDelete(groups, session, "BUDGET")
                          );
                        }
                        return canDelete(groups, session, "PROJECT");
                      },
                      canShare: () => {
                        return canShare(groups, session, "PROJECT");
                      },
                      entityType: "PROJECT",
                    }}
                  />
                </div>
              </div>
              <div className="d-flex align-items-center justify-content-between">
                <div className="m-2">
                  <div className="font-weight-bold mb-2">
                    <FormattedMessage id={"COMMON.START_DATE"} />
                  </div>
                  <span
                    style={{ cursor: "auto" }}
                    className="btn btn-sm btn-text btn-light-primary text-uppercase font-weight-bold"
                  >
                    {!!project?.startDate
                      ? DateUtils.format(new Date(project?.startDate), intl, false)
                      : "-"}
                  </span>
                </div>
                <div className="m-2">
                  <div className="font-weight-bold mb-2">
                    <FormattedMessage id={"PROJECT.INFO.AVAILABILITY_DATE"} />
                  </div>
                  <span
                    style={{ cursor: "auto" }}
                    className="btn btn-sm btn-text btn-light-danger text-uppercase font-weight-bold"
                  >
                    {!!project?.availability
                      ? DateUtils.format(project?.availability, intl, false)
                      : "-"}
                  </span>
                </div>
              </div>
            </div>
            <hr className={"mx-0 my-1"} />
            <div className={"d-flex align-items-center flex-wrap my-2 px-6"}>
              {budget?.plannedTotal !== undefined &&
                canReadAll(groups, session, "BUDGET") &&
                ProjectWidget({
                  data: budget?.plannedTotal,
                  icon: "flaticon-piggy-bank",
                  label: "COMMON.PLANNED.EXPENSES",
                  suffix: SUFFIX_EURO_CURRENCY,
                })}
              {project?.subcontractorsFinanceFiles !== undefined &&
                canReadAll(groups, session, "BUDGET") &&
                ProjectWidget({
                  data:
                    (project?.subcontractorsFinanceFiles?.orders?.total?.general ?? 0) +
                    (project?.subcontractorsFinanceFiles?.supplementary_agreements?.total
                      ?.general ?? 0),
                  icon: "flaticon-statistics",
                  label: "COMMON.REAL.EXPENSES",
                  suffix: SUFFIX_EURO_CURRENCY,
                })}
              {project?.tasksCount !== undefined &&
                ProjectWidget({
                  data: project?.tasksCount,
                  icon: "flaticon-file-2",
                  label: project?.tasksCount > 1 ? "TASKS.COUNT" : "TASK.COUNT",
                })}
              {project?.products &&
                ProjectWidget({
                  data: project?.products?.length,
                  icon: "flaticon-home-2",
                  label: "COMMON.PROPERTIES",
                })}
            </div>
            <hr className={"mx-0 my-1"} />
            <div className={"px-6"}>
              <div className={"d-flex align-items-center flex-wrap mt-5"}>
                <div className={"d-flex justify-content-between w-100"}>
                  <div>
                    {((project && project?.usersCanAccess?.length) ?? 0) > 0 && (
                      <WidgetUserList
                        displayLogo={false}
                        modalTitle={intl.formatMessage(
                          { id: "COMMON.USERS.ACCESS.ENTITY" },
                          { entityName: project?.name ?? "" }
                        )}
                        users={(cloneDeep(project?.usersCanAccess) as []).sort(
                          (a: { profilePictureUrl: string }, b: { profilePictureUrl: string }) => {
                            return (b.profilePictureUrl ?? "").localeCompare(
                              a.profilePictureUrl ?? ""
                            );
                          }
                        )}
                      />
                    )}
                  </div>
                  <div className={"ml-2 w-150px"}>
                    {project?.tasksCount > 0 && (
                      <ProgressBarFormatterComponent
                        rowIndex={index}
                        formatExtraData={{
                          numerator: project?.tasksDoneCount,
                          denominator: project?.tasksCount,
                        }}
                      />
                    )}
                  </div>
                </div>
              </div>
              <div className={"my-3"}>
                <p
                  style={{ textAlign: "justify" }}
                  className={"m-0"}
                  dangerouslySetInnerHTML={{
                    __html:
                      isLongDescription && isCollapsedDescriptionShown
                        ? project?.description?.substring(0, maxSizeDescriptionCollapsed) + "..."
                        : project?.description ?? "",
                  }}
                />
                {isLongDescription && (
                  <div className={"d-flex justify-content-end"}>
                    <button
                      onClick={() => {
                        setIsCollapsedDescriptionShown(!isCollapsedDescriptionShown);
                      }}
                      className={"btn m-0 p-0"}
                    >
                      <span className={"text-dark-50 text-hover-primary font-weight-bold"}>
                        <u>
                          <FormattedMessage
                            id={isCollapsedDescriptionShown ? "COMMON.SEE_MORE" : "COMMON.SEE_LESS"}
                          />
                        </u>
                      </span>
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className={"card-footer p-6"}>
            <div className={"d-flex justify-content-end"}>
              <button
                type={"button"}
                className={"btn font-weight-bold btn-primary"}
                onClick={() => {
                  projectsUIProps.openProjectPage(project);
                }}
              >
                <FormattedMessage id={"COMMON.DETAILS"} />
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
