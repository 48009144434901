import { INVOICE_STATUS } from "app/_utils/listUtils";
import { ColumnFormatter } from "react-bootstrap-table-next";
import { IntlShape } from "@formatjs/intl";

interface RowProps {
  fileType: string;
}

interface FormatExtraDataProps {
  intl: IntlShape;
}

export const InvoiceStatusColumnFormatter: ColumnFormatter<RowProps, FormatExtraDataProps, string> =
  (cell, row, rowIndex, formatExtraData) => {
    let color = "text-info";
    switch (cell) {
      case "PAID": {
        color = "text-success";
        break;
      }
      case "PARTIALLY_PAID": {
        color = "text-info";
        break;
      }
      case "TO_BE_PAID": {
        color = "text-warning";
        break;
      }
      case "OVERDUE": {
        color = "text-danger";
        break;
      }
    }
    return (
      <span className={color}>
        {cell &&
          formatExtraData &&
          formatExtraData.intl.formatMessage({
            id: (INVOICE_STATUS as any)[cell] || "-",
          })}
      </span>
    );
  };
