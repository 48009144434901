import * as React from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";

import {
  requestProductFileSignature,
  cancelProductFileSignaturesRequest,
} from "app/modules/PropertiesManagement/_redux/products/productsActions";
import { fetchUsersByUserType } from "app/modules/UsersManagement/_redux/usersActions";

import { CLIENT, SUBCONTRACTOR, USER } from "app/_utils/userTypes";

import { RequestSignatureDialog } from "app/_components/RequestSignatureDialog";

import { useProductFilesUIContext } from "../ProductFilesUIContext";

export const ProductRequestSignature: React.FunctionComponent = () => {
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(fetchUsersByUserType({ userTypes: [USER, CLIENT, SUBCONTRACTOR] }));
  }, []);

  const { productFile } = useSelector(
    (state: any) => ({
      productFile: state.products?.productForEdit?.current?.files,
    }),
    shallowEqual
  );

  return (
    <RequestSignatureDialog
      useFilesUIContext={useProductFilesUIContext}
      fileList={productFile}
      requestFileSignature={requestProductFileSignature}
      requestCancelPrevious={cancelProductFileSignaturesRequest}
    />
  );
};

export default ProductRequestSignature;
