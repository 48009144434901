import { InvoiceStatus, TInvoiceStatus } from "../../../../data/schemas";
import { sum } from "lodash-es";

export const getTotalInvoiceStatus = (invoiceStatusCount: Record<TInvoiceStatus, number>) => {
  const totalCount = sum(Object.values(invoiceStatusCount));

  if (!totalCount) {
    return;
  }
  if (invoiceStatusCount.PAID === totalCount) {
    return InvoiceStatus.PAID;
  } else if (
    (invoiceStatusCount.TO_BE_PAID ?? 0) + (invoiceStatusCount.OVERDUE ?? 0) ===
    totalCount
  ) {
    return InvoiceStatus.TO_BE_PAID;
  } else {
    return InvoiceStatus.PARTIALLY_PAID;
  }
};
