import { Controller } from "react-hook-form";
import Select from "react-select";
import React, { useMemo } from "react";
import { useHtmlClassService } from "../../../../../../_metronic/layout";
import objectPath from "object-path";
import { useTranslation } from "../../../../../hooks/useTranslation";

interface KycDropDownSelectV2Props {
  name: string;
  isDisabled?: boolean;
  options: any[];
  control: any;
  label: string;
  className?: string;
}

export const KycDropDownSelectRHF = ({
  name,
  isDisabled = false,
  options,
  control,
  label,
  className,
}: KycDropDownSelectV2Props): JSX.Element => {
  const uiService = useHtmlClassService();
  const { fm } = useTranslation();

  const layoutProps = useMemo(() => {
    return {
      colorsThemeLightPrimary: objectPath.get(uiService.config, "js.colors.theme.light.primary"),
      colorsThemeBasePrimary: objectPath.get(uiService.config, "js.colors.theme.base.primary"),
      colorsGrayGray400: objectPath.get(uiService.config, "js.colors.gray.gray400"),
    };
  }, [uiService]);

  return (
    <Controller
      control={control}
      name={name}
      render={({ field }) => (
        <div className={className}>
          <label>{fm(label)}</label>
          <Select
            {...field}
            menuPortalTarget={document.body}
            menuPosition="fixed"
            styles={{
              menuPortal: (provided: any) => ({ ...provided, zIndex: 9999 }),
              menu: (provided: any) => ({ ...provided, zIndex: 9999 }),
            }}
            placeholder=""
            theme={(theme) => ({
              ...theme,
              colors: {
                ...theme.colors,
                primary25: layoutProps.colorsThemeLightPrimary,
                primary: layoutProps.colorsThemeBasePrimary,
              },
            })}
            isDisabled={isDisabled}
            options={options}
            classNamePrefix="kyc-select"
          />
        </div>
      )}
    />
  );
};
