import { FormattedMessage, useIntl } from "react-intl";
import * as React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import { sortCaret } from "../../../../../_metronic/_helpers";
import {
  DateTimeColumnFormatter,
  InvoiceStatusColumnFormatter,
} from "../../../../_components/column-formatters";
import { sortDateFunc } from "../../../../_utils/columnUtils";
import { InvoiceStatus, TInvoiceStatus } from "../../../../../data/schemas";
import { SUFFIX_EURO_CURRENCY } from "../../../../_utils/suffixUtils";
import { NumberInput } from "../../../../_utils/formUtils";
import { sumBy } from "lodash-es";
import { SearchLocal } from "../../../../_components/Search/SearchLocal";
import { IFilterSearchType, SearchTypeValues } from "../../../../contexts/SearchContext";
import { useSearch } from "../../../../hooks/useSearch";
import { INVOICE_STATUS } from "../../../../_utils/listUtils";
import paginationFactory, { PaginationProvider } from "react-bootstrap-table2-paginator";
import { Pagination } from "../../../../../_metronic/_partials/controls";
import { useFileViewer } from "../../../../hooks/useFileViewer";
import { getFileItemWithRelatedEntity } from "../../../../_utils/fileUtils";

interface AccountsReceivableInvoicesProps {
  users: Record<string, { id: string; userName: string }>;
}

export const AccountsReceivableInvoicesTable = ({ users }: AccountsReceivableInvoicesProps) => {
  const intl = useIntl();
  const { entitiesFiltered } = useSearch();
  const { downloadAndOpenFilesInViewer } = useFileViewer();

  const totalFilteredInvoices = sumBy(entitiesFiltered, "amount");

  const columns = [
    {
      dataField: "id",
      text: "",
      sort: false,
      formatExtraData: {
        icon: "fas fa-layer-group text-info icon-md",
      },
      hidden: true,
    },
    {
      dataField: "name",
      headerClasses: "text-left text-nowrap",
      classes: "text-left",
      text: intl.formatMessage({
        id: "DASHBOARD.INVOICE_MANAGEMENT.TABLE_COLUMN.NAME",
      }),
      sort: true,
      sortCaret: sortCaret,
    },
    {
      dataField: "createdAt",
      headerClasses: "text-left text-nowrap",
      classes: "text-left",
      text: intl.formatMessage({
        id: "DASHBOARD.INVOICE_MANAGEMENT.TABLE_COLUMN.DATE",
      }),
      sort: true,
      formatter: DateTimeColumnFormatter,
      formatExtraData: { intl, showTime: false },
      sortFunc: sortDateFunc,
      sortCaret: sortCaret,
    },
    {
      dataField: "assignee",
      headerClasses: "text-left text-nowrap",
      classes: "text-left",
      text: intl.formatMessage({
        id: "DASHBOARD.INVOICE_MANAGEMENT.TABLE_COLUMN.ASSIGNEE",
      }),
      sort: true,
      sortCaret: sortCaret,
      formatter: (cell: string) => (
        <div
          className="max-w-200px overflow-hidden"
          style={{
            textOverflow: "ellipsis",
          }}
        >
          <span className="text-left text-nowrap mr-auto">{cell}</span>
        </div>
      ),
    },
    {
      dataField: "projectName",
      headerClasses: "text-left text-nowrap",
      classes: "text-left",
      text: intl.formatMessage({
        id: "DASHBOARD.INVOICE_MANAGEMENT.TABLE_COLUMN.RELATED_TO",
      }),
      sort: true,
      sortCaret: sortCaret,
    },
    {
      dataField: "invoiceStatus",
      headerClasses: "text-left text-nowrap",
      classes: "text-left",
      text: intl.formatMessage({
        id: "DASHBOARD.INVOICE_MANAGEMENT.TABLE_COLUMN.PAYMENT_STATUS",
      }),
      sort: true,
      sortCaret: sortCaret,
      formatter: InvoiceStatusColumnFormatter,
      formatExtraData: {
        intl: intl,
      },
      sortValue: (cell: TInvoiceStatus) =>
        cell ? intl.formatMessage({ id: InvoiceStatus[cell] }) : "-",
    },
    {
      dataField: "amount",
      headerClasses: "text-right text-nowrap",
      classes: "text-right",
      text: intl.formatMessage({
        id: "DASHBOARD.INVOICE_MANAGEMENT.TABLE_COLUMN.AMOUNT",
      }),
      sort: true,
      sortCaret: sortCaret,
      formatter: (cell: number) => (
        <NumberInput
          value={cell}
          suffix={SUFFIX_EURO_CURRENCY}
          fixedDecimalScale={true}
          decimalScale={2}
          displayType="text"
        />
      ),
    },
  ];

  const filters: IFilterSearchType[] = React.useMemo(
    () => [
      {
        name: "DASHBOARD.INVOICE_MANAGEMENT.ALL_PAYMENT_STATUS",
        value: "invoiceStatus",
        labelKey: "INVOICE.STATUS",
        type: SearchTypeValues.SEARCH_DROPDOWN,
        values: Object.keys(INVOICE_STATUS),
        isSpecificValues: true,
      },
      {
        name: "DASHBOARD.INVOICE_MANAGEMENT.ALL_USERS",
        value: "userIds",
        type: SearchTypeValues.SEARCH_DROPDOWN,
        values: [],
        formatter: (value) => {
          return users[value]?.userName ?? "Undefined user";
        },
        validator: (entity, value) => {
          return entity.userIds.includes(value);
        },
      },
      {
        name: "DATEPICKER.ALL_TIME",
        value: "createdAt",
        type: SearchTypeValues.SEARCH_DATE_RANGE,
      },
    ],
    [users]
  );

  const paginationOptions = {
    custom: true,
    totalSize: entitiesFiltered.length ?? 0,
    sizePerPageList: [
      { text: "5", value: 5 },
      { text: "10", value: 10 },
      { text: "25", value: 25 },
      { text: "50", value: 50 },
    ],
    sizePerPage: 25,
  };

  return (
    <div className="mt-5">
      <div className="d-flex justify-content-between align-items-center flex-wrap">
        <h3 className="mt-0">
          <FormattedMessage
            id="DASHBOARD.INVOICE_MANAGEMENT.AVAILABLE_INVOICES"
            values={{ count: entitiesFiltered.length ?? 0 }}
          />
          <span className="text-muted ml-2">
            (
            <NumberInput
              value={totalFilteredInvoices}
              suffix={SUFFIX_EURO_CURRENCY}
              decimalScale={2}
              displayType="text"
            />
            )
          </span>
        </h3>
        <div className="d-flex">
          <SearchLocal filters={filters} />
        </div>
      </div>
      <div>
        <PaginationProvider pagination={paginationFactory(paginationOptions)}>
          {({ paginationProps, paginationTableProps }) => {
            return (
              <Pagination paginationProps={paginationProps}>
                <BootstrapTable
                  {...paginationTableProps}
                  wrapperClasses="table-responsive"
                  classes="table table-head-custom table-vertical-center text-nowrap "
                  bootstrap4
                  bordered={false}
                  keyField="id"
                  data={entitiesFiltered}
                  columns={columns}
                  defaultSorted={[{ dataField: "createdAt", order: "desc" }]}
                  noDataIndication={() => ""}
                  hover
                  rowEvents={{
                    onClick: (e, fileRowItem, rowIndex) => {
                      downloadAndOpenFilesInViewer([getFileItemWithRelatedEntity(fileRowItem)]);
                    },
                  }}
                  rowStyle={{ cursor: "pointer" }}
                />
              </Pagination>
            );
          }}
        </PaginationProvider>
      </div>
    </div>
  );
};
