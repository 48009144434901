import * as React from "react";

import ProjectOwnerSwitchProvider from "./ProjectOwnerSwitchContext";
import ProjectOwnerSwitchContent from "./ProjectOwnerSwitchContent";

import "./ProjectOwnerSwitch.scss";

export interface ProjectOwnerSwitchProps {
  readonly?: boolean;
}

export const ProjectOwnerSwitch: React.FunctionComponent<ProjectOwnerSwitchProps> = ({
  readonly = false,
}) => (
  <ProjectOwnerSwitchProvider readonly={readonly}>
    <ProjectOwnerSwitchContent />
  </ProjectOwnerSwitchProvider>
);

export default ProjectOwnerSwitch;
