import { createSlice } from "@reduxjs/toolkit";
import { set } from "lodash-es";

import { ILead, ILeadFile, IUser } from "data/schemas";

import { formatDisplayName } from "app/_utils/userUtils";
import { sliceSignaturesRequestFileHelper } from "app/_utils/signatureUtils";

import { FilesSliceUtils } from "app/modules/File/filesSliceUtils";

interface ILeadState {
  listLoading: boolean;
  actionsLoading: boolean;

  entities: ILead[];

  leadForEdit: {
    saved?: ILead;
    current?: Partial<ILead>;
  };

  error: any;
}

const initialLeadsState: ILeadState = {
  listLoading: false,
  actionsLoading: false,
  entities: [],
  leadForEdit: {
    saved: undefined,
    current: undefined,
  },
  error: null,
};

export const callTypes = {
  list: "list",
  action: "action",
};

export const leadsSlice = (name: string) =>
  createSlice({
    name: name,
    initialState: initialLeadsState,
    reducers: {
      catchError: (state, action) => {
        if (action.payload.callType === callTypes.list) {
          state.listLoading = false;
        } else {
          state.actionsLoading = false;
        }
      },
      startCall: (state, action) => {
        if (action.payload.callType === callTypes.list) {
          state.listLoading = true;
        } else {
          state.actionsLoading = true;
        }
      },
      leadFetched: (state, action) => {
        state.actionsLoading = false;
        state.leadForEdit = {
          saved: action.payload.leadForEdit,
          current: action.payload.leadForEdit,
        };
      },
      leadsFetched: (state, action) => {
        const { entities } = action.payload;
        state.listLoading = false;
        state.entities = entities;
        state.leadForEdit = initialLeadsState.leadForEdit;
      },
      leadCreated: (state, action) => {
        state.actionsLoading = false;
        state.entities.push(action.payload.lead);
        state.leadForEdit = {
          saved: action.payload.lead,
          current: action.payload.lead,
        };
      },
      leadFieldUpdatedLocally: (state, action) => {
        state.error = null;
        state.actionsLoading = false;
        if (!state.leadForEdit.current) {
          state.leadForEdit.current = {};
        }
        set(state.leadForEdit.current, action.payload.key, action.payload.value);
      },
      leadFieldUpdated: (state, action) => {
        state.error = null;
        state.actionsLoading = false;
        if (state.leadForEdit.current) {
          set(state.leadForEdit.current, action.payload.key, action.payload.value);
        }
        if (state.leadForEdit.saved) {
          set(state.leadForEdit.saved, action.payload.key, action.payload.value);
        }
      },
      leadUpdated: (state, action) => {
        state.actionsLoading = false;
        state.leadForEdit.saved = action.payload.lead;
        state.entities = state.entities.map((entity) => {
          if (entity.id === action.payload.lead.id) {
            return action.payload.lead;
          }
          return entity;
        });
      },
      leadDeleted: (state, action) => {
        state.actionsLoading = false;
        state.entities = state.entities.filter((el) => el.id !== action.payload.id);
      },
      leadProductFetched: (state, action) => {
        console.log("leadProductFetched: ", action);
        state.actionsLoading = false;
        const { product } = action.payload;
        const newState = {
          ...state.leadForEdit.current,
          product,
          sellingPrice: product.financialDetails.price,
          shareOfLandSellingPrice: product.financialDetails.shareOfLand,
          architectEngineeringFees: product.financialDetails.architectEngineeringFees,
        };
        if (!state.leadForEdit.current?.name) {
          newState.name = product.name;
        }
        state.leadForEdit.current = newState;
      },
      leadClientsFetched: (state, action) => {
        console.log("leadClientsFetched: ", action);
        state.actionsLoading = false;
        state.leadForEdit.current = {
          ...state.leadForEdit.current,
          users: action.payload.users,
          name:
            state.leadForEdit.current?.name?.split("-")[0].trim() +
            " - " +
            action.payload.users.map((user: IUser) => formatDisplayName(user)).join(", "),
        };
      },
      leadClientCreated: (state, action) => {
        state.actionsLoading = false;
        state.leadForEdit.current!.users =
          state.leadForEdit.current?.users && state.leadForEdit.current.users.length > 0
            ? [...state.leadForEdit.current.users, action.payload.user]
            : [action.payload.user];
        state.leadForEdit.saved!.users =
          state.leadForEdit.saved?.users && state.leadForEdit.saved.users.length > 0
            ? [...state.leadForEdit.saved.users, action.payload.user]
            : [action.payload.user];
      },
      leadClientDeleted: (state, action) => {
        state.actionsLoading = false;
        state.leadForEdit.current!.users =
          state.leadForEdit.current?.users && state.leadForEdit.current.users.length > 0
            ? [...state.leadForEdit.current.users].filter((user) => user.id !== action.payload.id)
            : [];
        state.leadForEdit.saved!.users =
          state.leadForEdit.saved?.users && state.leadForEdit.saved.users.length > 0
            ? [...state.leadForEdit.saved.users].filter((user) => user.id !== action.payload.id)
            : [];
      },
      fileCreated: (state, action) => {
        state.error = null;
        state.actionsLoading = false;
        const { file } = action.payload;
        state.leadForEdit.current?.files?.push(file);
        state.leadForEdit.saved?.files?.push(file);

        const { id, friendlyName, relatedEntity } = file;
        for (const linkedFile of file.linkedFiles ?? []) {
          FilesSliceUtils.linkedFilesAdded({
            entityForEdit: state.leadForEdit,
            fileId: linkedFile.id,
            linkedFiles: [
              {
                id,
                friendlyName,
                relatedEntity,
              },
            ],
          });
        }
        if (file.financialDocument) {
          state.leadForEdit.current?.financialDocuments?.push(file.financialDocument);
          state.leadForEdit.saved?.financialDocuments?.push(file.financialDocument);
        }
      },
      fileUpdated: (state, action) => {
        state.error = null;
        state.actionsLoading = false;

        const { file } = action.payload;
        const fileId = file.id;
        const fileFindIndex = (file: ILeadFile) => file.id === fileId;

        const currentFileIndex = state.leadForEdit.current?.files?.findIndex(fileFindIndex) ?? -1;
        const currentFile = state.leadForEdit.current?.files?.[currentFileIndex];

        if (file.friendlyName !== currentFile?.friendlyName) {
          FilesSliceUtils.linkedFileRenamed({
            updatedFile: file,
            entityForEdit: state.leadForEdit,
          });
        }

        if (file.selectedForFinance !== currentFile?.selectedForFinance) {
          const financialDocument = state.leadForEdit.current?.financialDocuments?.find(
            (fd) => fd.id === file.leadFinancialDocumentId
          );
          if (financialDocument) {
            financialDocument.selectedForFinance = file.selectedForFinance;
          }
          const savedFinancialDocument = state.leadForEdit.saved?.financialDocuments?.find(
            (fd) => fd.id === file.leadFinancialDocumentId
          );
          if (savedFinancialDocument) {
            savedFinancialDocument.selectedForFinance = file.selectedForFinance;
          }
        }

        if (state.leadForEdit.current?.files) {
          state.leadForEdit.current.files[currentFileIndex] = file;
        }

        const savedFileIndex = state.leadForEdit.current?.files?.findIndex(fileFindIndex) ?? -1;
        if (state.leadForEdit.saved?.files) {
          state.leadForEdit.saved.files[savedFileIndex] = file;
        }
      },
      linkedFilesAdded: (state, { payload: { fileId, linkedFiles } }) =>
        FilesSliceUtils.linkedFilesAdded({
          entityForEdit: state.leadForEdit,
          fileId,
          linkedFiles,
        }),
      linkedFileRemoved: (state, { payload: { fileId, linkedFileToRemove } }) =>
        FilesSliceUtils.linkedFileRemoved({
          entityForEdit: state.leadForEdit,
          fileId,
          linkedFileToRemove,
        }),
      fileDeleted: (state, action) => {
        state.error = null;
        state.actionsLoading = false;
        const { id, leadFinancialDocumentId } = action.payload.file;
        const currentFile = state.leadForEdit.current?.files?.find((file) => file.id === id);
        for (const linkedFile of currentFile?.linkedFiles ?? []) {
          FilesSliceUtils.linkedFileRemoved({
            entityForEdit: state.leadForEdit,
            fileId: linkedFile.id,
            linkedFileToRemove: currentFile!.id,
          });
        }
        state.leadForEdit.current!.files = state.leadForEdit.current?.files?.filter(
          (file) => file.id !== id
        );
        state.leadForEdit.saved!.files = state.leadForEdit.saved?.files?.filter(
          (file) => file.id !== id
        );
        if (leadFinancialDocumentId) {
          state.leadForEdit.current!.financialDocuments =
            state.leadForEdit.current?.financialDocuments?.filter(
              (file) => file.id !== leadFinancialDocumentId
            );
          state.leadForEdit.saved!.financialDocuments =
            state.leadForEdit.saved?.financialDocuments?.filter(
              (file) => file.id !== leadFinancialDocumentId
            ) ?? [];
        }
      },

      signaturesRequestCreated: (state, action) => {
        state.error = null;
        state.actionsLoading = false;

        const { file } = action.payload;

        const { current, saved } = state.leadForEdit;

        sliceSignaturesRequestFileHelper({ file, current, saved });

        state.leadForEdit.current = current;
        state.leadForEdit.saved = saved;
      },

      signaturesRequestCancelled: (state, action) => {
        state.error = null;
        state.actionsLoading = false;

        const { file } = action.payload;

        const { current, saved } = state.leadForEdit;

        sliceSignaturesRequestFileHelper({ file, current, saved });

        state.leadForEdit.current = current;
        state.leadForEdit.saved = saved;
      },
    },
  });
