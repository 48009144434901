import { createEntityAdapter, createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  addNewChat,
  addNewChatMessage,
  fetchChatById,
  fetchChats,
  readMessages,
  setActiveChat,
  updateChat,
} from "./chatActions";
import { Chat, ChatMessage, ChatUser } from "./models";

const chatsAdapter = createEntityAdapter<Chat>({
  sortComparer: (a, b) => b?.lastMessageSentAt?.localeCompare(a?.lastMessageSentAt),
});

export const chatsSelectors = chatsAdapter.getSelectors((state: any) => state.chats);

const initialState = chatsAdapter.getInitialState({
  activeChat: null,
});

export const chatsSlice = createSlice({
  name: "chats",
  initialState,
  reducers: {},
  extraReducers: {
    [fetchChats.fulfilled.type]: (state, action: PayloadAction<Chat[]>) => {
      chatsAdapter.setAll(state, action.payload);
    },
    [fetchChatById.fulfilled.type]: (state, action: PayloadAction<Chat>) => {
      action.payload.messages.sort((msg1, msg2) => {
        return new Date(msg1.createdAt).valueOf() - new Date(msg2.createdAt).valueOf();
      });
      chatsAdapter.upsertOne(state, action.payload);
    },
    [readMessages.fulfilled.type]: (state, action) => {
      const entity = state.entities[action.payload.id];
      if (!entity) {
        return;
      }

      const activeUserIndex = entity.users.findIndex(
        (u: ChatUser) => u.userId === action.payload.userId
      );
      if (typeof activeUserIndex !== "undefined" && activeUserIndex !== -1) {
        entity.users[activeUserIndex].lastMessageSeenAt = action.payload.lastMessageSeenAt;
      }
    },
    [updateChat.fulfilled.type]: (state, action) => {
      chatsAdapter.updateOne(state, { id: action.payload.id, changes: action.payload });
    },
    [setActiveChat.type]: (state, action) => {
      state.activeChat = action.payload.chatId;
    },
    [addNewChat.type]: (state, action: PayloadAction<{ chat: Chat }>) => {
      chatsAdapter.addOne(state, action.payload.chat);
    },
    [addNewChatMessage.type]: (
      state,
      action: PayloadAction<{ chat: Chat; message: ChatMessage }>
    ) => {
      chatsAdapter.updateOne(state, { id: action.payload.chat.id, changes: action.payload.chat });
      if (state.entities && state.activeChat === action.payload.chat.id) {
        state.entities[action.payload.chat.id]?.messages?.push(action.payload.message);
      }
    },
  },
});
