export enum UserTypes {
  CLIENT = "CLIENT",
  USER = "USER",
  SUBCONTRACTOR = "SUBCONTRACTOR",
}

export const CLIENT = "CLIENT";
export const USER = "USER";
export const SUBCONTRACTOR = "SUBCONTRACTOR";
export const userTypes = [CLIENT, USER, SUBCONTRACTOR];

export const LEGAL = "LEGAL";
export const NATURAL = "NATURAL";
export const legalEntityTypes = [LEGAL, NATURAL];
