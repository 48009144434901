import "vis-timeline/styles/vis-timeline-graph2d.min.css";
import "@szhsin/react-menu/dist/index.css";
import "./TasksGanttChart.scss";

import * as React from "react";
import cn from "clsx";
import { useDidMount, useDidUpdate, useWillUnmount } from "rooks";
import { Overlay, Popover } from "react-bootstrap";

/*
  there an issue open on the vis-timeline lib to replace the momentjs
  https://github.com/visjs/vis-timeline/issues/672

  until that's solved we still need to use it...
*/
import moment from "moment";
import "moment/locale/fr";
import "moment/locale/de";

import { useIntl } from "react-intl";
import {
  ClickEvent,
  ControlledMenu,
  MenuDivider,
  MenuItem,
  SubMenu,
  useMenuState,
} from "@szhsin/react-menu";
import type { DateType, TimelineEventPropertiesResult } from "vis-timeline/types";
import { Timeline as VisTimeline } from "vis-timeline/esnext";

import { ETaskStatus, ITask, TASK_STATUS_I18N_TITLE, TASK_STATUS_ICON_NAME } from "data/schemas";

import { DateUtils } from "app/_utils/DateUtils";

import { useTasksUIContext } from "app/modules/PropertiesManagement/pages/tasks/TasksUIContext";

import {
  emptyGanttChartGroupsAndItems,
  IAnchorPoint,
  IDisplayExpandCollapseMenuItems,
  initialAnchorPoint,
  IVisTimeline,
  TASK_BG_ITEM_REGEX,
  TasksGanttChartProps,
  TIME_FORMAT,
  TUpdateVisTimelineWindowCommand,
  updateVisTimelineWindowAvailableCommands,
  UpdateVisTimelineWindowCommand,
  updateVisTimelineWindowFromDateAvailableCommands,
  VIS_TIMELINE_MENU_WIDTH,
  VisTimelineGroupsCommand,
  visTimelineGroupsCommands,
  visTimelineOptions,
} from "./definitions";

import VisTimelineSkeleton from "./VisTimelineSkeleton";

import { extractTaskId, ganttChartGroupsAndItems, tasksSorterFilter } from "./utils";

// https://visjs.github.io/vis-timeline/docs/timeline/#Localization
const adjustLocaleToVisTimeline = (locale: string) => locale.split("-")[0];

//TASK.GANTT_CHART.VIEW.FROM.${suffix}
const contextMenuCommandToIntlKeySuffix = {
  [`${UpdateVisTimelineWindowCommand.DAY}`]: "DAY",
  [`${UpdateVisTimelineWindowCommand.WEEK}`]: "WEEK",
  [`${UpdateVisTimelineWindowCommand.MONTH}`]: "MONTH",
  [`${UpdateVisTimelineWindowCommand.QUARTER}`]: "QUARTER",
  [`${UpdateVisTimelineWindowCommand.YEAR}`]: "YEAR",
};

const NOOP = () => undefined;

export const TasksGanttChart: React.FunctionComponent<TasksGanttChartProps> = ({
  isTasksLoading = false,
  filterText = "",
  selectedSort,
  tasks,
}) => {
  const intl = useIntl();
  const { locale: intlLocale } = intl;
  const [locale, setLocale] = React.useState(() => adjustLocaleToVisTimeline(intlLocale));

  const [isLoadingTimeline, setLoadingTimeline] = React.useState(true);

  const {
    ganttShowDetails,
    toggleGanttChartDetails = NOOP,
    openEditTaskPage = NOOP,
  } = useTasksUIContext();

  const [fromDate, setFromDate] = React.useState<string | undefined>(undefined);
  const [fromDateLabel, setFromDateLabel] = React.useState("");
  const [anchorPoint, setAnchorPoint] = React.useState<IAnchorPoint>(initialAnchorPoint);
  const [isOnTopOfLabels, setOnTopOfLabels] = React.useState<
    IDisplayExpandCollapseMenuItems | undefined
  >(undefined);

  const [contextMenuProps, toggleContextMenu] = useMenuState();

  const visTimelineInitRef = React.useRef(false);
  const visTimelineRef = React.useRef<IVisTimeline | undefined>(undefined);
  const ids2tasksRef = React.useRef<Map<string, ITask> | undefined>(undefined);

  const { groups, items } = React.useMemo(() => {
    if (isTasksLoading) return emptyGanttChartGroupsAndItems;

    const { groups, items, ids2tasks } = ganttChartGroupsAndItems(
      tasksSorterFilter({
        intl,
        filterText,
        selectedSort,
        tasks,
      })
    );

    ids2tasksRef.current = ids2tasks;

    return { groups, items };
  }, [isTasksLoading, filterText, selectedSort, tasks, locale]);

  const visTimelineContainerRef = React.useRef<HTMLDivElement>(null);

  const [overlayTarget, setOverlayTarget] = React.useState<HTMLDivElement | null>(null);
  const [overlayTask, setOverlayTask] = React.useState<ITask | undefined>(undefined);
  const [showOverlay, setShowOverlay] = React.useState(false);

  //--------------------------------------------------------------------------//

  const updateCSSVarVisItemBackgroundOpacity = (show: boolean) => {
    const { current: timelineEl } = visTimelineContainerRef;
    if (!timelineEl) return;
    timelineEl.style.setProperty("--visItemBackgroundOpacity", show ? "1" : "0");
  };

  //--------------------------------------------------------------------------//
  // @begin: context menu logic

  const closeControllerMenu = () => {
    toggleContextMenu(false);
  };

  const checkForExpandCollapseMenuItems = () => {
    const { current: visTimeline } = visTimelineRef;
    if (!visTimeline) return;

    const groups = visTimeline.itemSet.groups;

    const groupsWithNested = Object.values(groups).filter(
      ({ nestedGroups }) => nestedGroups && nestedGroups.length > 0
    );
    const groupsWithNestedLength = groupsWithNested.length;

    const { expanded, collapsed } = groupsWithNested.reduce(
      (acc, group) => {
        const { showNested } = group;

        acc[
          showNested
            ? `${VisTimelineGroupsCommand.EXPAND}ed`
            : `${VisTimelineGroupsCommand.COLLAPSE}d`
        ] += 1;

        return acc;
      },
      { expanded: 0, collapsed: 0 }
    );

    return {
      expand: expanded < groupsWithNestedLength,
      collapse: collapsed < groupsWithNestedLength,
    };
  };

  //--- events handler

  const menuItemClickHandler = (command: string, fromDate?: string) => (_: ClickEvent) => {
    if (visTimelineGroupsCommands.includes(command)) {
      if (command === VisTimelineGroupsCommand.TOGGLE_DETAILS) {
        toggleGanttChartDetails();
        return;
      }
      toggleGroupsWithNestedGroups(command === `${VisTimelineGroupsCommand.EXPAND}`);
      return;
    }

    if (!updateVisTimelineWindowAvailableCommands.includes(command)) return;
    updateVisTimelineWindow(command as TUpdateVisTimelineWindowCommand, fromDate);
  };

  // @end: context menu logic
  //--------------------------------------------------------------------------//
  // @begin: visTimeline logic

  const toggleGroupsWithNestedGroups = (force = false) => {
    const { current: visTimeline } = visTimelineRef;
    if (!visTimeline) return;

    const groups = visTimeline.itemSet.groups;

    const groupsWithNested = Object.values(groups).filter(
      ({ nestedGroups, showNested }) =>
        nestedGroups &&
        nestedGroups.length > 0 &&
        (force !== undefined ? force !== showNested : true)
    );

    groupsWithNested.forEach((group) => visTimeline.itemSet.toggleGroupShowNested(group, force));
  };

  const updateVisTimelineWindow = (command: TUpdateVisTimelineWindowCommand, date?: DateType) => {
    const { current: visTimeline } = visTimelineRef;
    if (!visTimeline) return;

    if (command === UpdateVisTimelineWindowCommand.FIT) {
      visTimeline.fit();
      visTimeline.redraw();
      return;
    }

    const momentDate = moment(date || new Date());
    const start = momentDate.startOf(`${command}`).toISOString();
    const end = momentDate.endOf(`${command}`).toISOString();

    visTimeline.setWindow(start, end);
    visTimeline.redraw();
  };

  //--- events handler

  const contextMenuHandler = (props: TimelineEventPropertiesResult) => {
    props.event.preventDefault();

    // needed to correct the y postion on the tasks gantt chart rendered as a tab
    const pageTop = document.body.getBoundingClientRect().top;

    const { what, pageX, pageY, time } = props;
    setAnchorPoint({ x: pageX, y: pageY + pageTop });

    const isOnTopOfLabels = what === "group-label" || pageX < VIS_TIMELINE_MENU_WIDTH;

    setOnTopOfLabels(!isOnTopOfLabels ? undefined : checkForExpandCollapseMenuItems());
    setFromDate(isOnTopOfLabels ? undefined : moment(time).format(TIME_FORMAT));

    toggleContextMenu(true);
  };

  const clickHandler = (props: TimelineEventPropertiesResult) => {
    props.event.preventDefault();

    const { time } = props;
    if (time) {
      setFromDate(moment(time).format(TIME_FORMAT));
    }

    closeControllerMenu();
  };

  const doubleClickHandler = (props: TimelineEventPropertiesResult) => {
    const { item, event } = props;

    if (!item) return;

    event.preventDefault();

    const itemStr = `${item}`;

    if (TASK_BG_ITEM_REGEX.test(itemStr)) {
      const { current: timelineEl } = visTimelineContainerRef;

      if (!timelineEl) return;

      const styles = getComputedStyle(timelineEl);
      const visItemBackgroundOpacity = styles.getPropertyValue("--visItemBackgroundOpacity");

      if (visItemBackgroundOpacity !== "1") return;
    }

    const taskId = extractTaskId(itemStr);
    taskId && openEditTaskPage(taskId);
  };

  const resetOverlay = () => {
    setShowOverlay(false);
    setOverlayTarget(null);
    setOverlayTask(undefined);
  };

  const itemOutHandler = (_: TimelineEventPropertiesResult) => {
    resetOverlay();
  };

  const itemHoverHandler = (props: TimelineEventPropertiesResult) => {
    resetOverlay();

    const {
      item,
      event: { target },
    } = props;

    if (!item) return;

    const itemStr = `${item}`;

    if (TASK_BG_ITEM_REGEX.test(itemStr)) {
      const { current: timelineEl } = visTimelineContainerRef;

      if (!timelineEl) return;

      const styles = getComputedStyle(timelineEl);
      const visItemBackgroundOpacity = styles.getPropertyValue("--visItemBackgroundOpacity");

      if (visItemBackgroundOpacity !== "1") return;
    }

    const { current: ids2tasks } = ids2tasksRef;
    if (!ids2tasks) return;

    const taskId = extractTaskId(itemStr);
    const task = ids2tasks.get(`${taskId}`);

    if (!task) return;

    setOverlayTarget(target as HTMLDivElement);
    setOverlayTask(task);
    setShowOverlay(true);
  };

  // @end: visTimeline logic
  //--------------------------------------------------------------------------//

  useDidUpdate(() => {
    setLocale(adjustLocaleToVisTimeline(intlLocale));
  }, [intlLocale]);

  useDidUpdate(() => {
    const { current: visTimeline } = visTimelineRef;
    if (!visTimeline) return;

    visTimeline.setData({
      groups,
      items,
    });

    if (!visTimelineInitRef.current) {
      visTimeline.redraw();

      const changedHandler = () => {
        visTimeline.off("changed", changedHandler);
        setLoadingTimeline(false);
        visTimelineInitRef.current = true;
        updateCSSVarVisItemBackgroundOpacity(ganttShowDetails);
      };

      visTimeline.on("changed", changedHandler);
      visTimeline.fit({ animation: false });

      return;
    }

    visTimeline.fit();
  }, [groups, items]);

  useDidUpdate(() => {
    updateCSSVarVisItemBackgroundOpacity(ganttShowDetails);
  }, [ganttShowDetails]);

  useDidUpdate(() => {
    const options: Intl.DateTimeFormatOptions = {
      year: "numeric",
      day: "numeric",
      month: "long",
    };
    setFromDateLabel(
      fromDate ? new Intl.DateTimeFormat(intlLocale, options).format(new Date(fromDate)) : ""
    );
  }, [fromDate, intlLocale]);

  useDidMount(() => {
    const { current: visTimelinePrevious } = visTimelineRef;
    if (!visTimelinePrevious) {
      const { current: timelineEl } = visTimelineContainerRef;
      if (!timelineEl) return;

      visTimelineOptions.onInitialDrawComplete = () => {
        const visTimeline = visTimelineRef.current;
        if (!visTimeline) return;
        setLoadingTimeline(true);

        if (items.length === 0) {
          setLoadingTimeline(false);
          return;
        }

        const changedHandler = () => {
          visTimeline.off("changed", changedHandler);
          setLoadingTimeline(false);
          visTimelineInitRef.current = true;

          updateCSSVarVisItemBackgroundOpacity(ganttShowDetails);
        };

        const rangeChangedHandler = () => {
          visTimeline.off("rangechanged", rangeChangedHandler);
          visTimeline.on("changed", changedHandler);
        };
        visTimeline.on("rangechanged", rangeChangedHandler);

        // Without this, the loader stay displayed with high amount of tasks
        visTimeline.setData({ groups, items });
        setLoadingTimeline(false);
      };

      const visTimeline = new VisTimeline(timelineEl, [], [], {
        ...visTimelineOptions,
        locale,
      });
      visTimelineRef.current = visTimeline as IVisTimeline;

      visTimeline.on("itemover", itemHoverHandler);
      visTimeline.on("itemout", itemOutHandler);
      visTimeline.on("contextmenu", contextMenuHandler);
      visTimeline.on("doubleClick", doubleClickHandler);
      visTimeline.on("click", clickHandler);
    }
  });

  useWillUnmount(() => {
    const { current: visTimeline } = visTimelineRef;
    if (!visTimeline) return;

    visTimeline.off("itemover", itemHoverHandler);
    visTimeline.off("itemout", itemOutHandler);
    visTimeline.off("contextmenu", contextMenuHandler);
    visTimeline.off("doubleClick", doubleClickHandler);
    visTimeline.off("click", clickHandler);
    visTimeline.destroy();
    visTimelineRef.current = undefined;
  });

  return (
    <>
      <div className="tasks-gantt-chart">
        <div className="tasks-gantt-chart__container">
          <VisTimelineSkeleton className={cn({ loading: isLoadingTimeline })} />

          <div
            ref={visTimelineContainerRef}
            className={cn("vis-timeline-container", { loading: isLoadingTimeline })}
          />
        </div>
      </div>

      <Overlay
        {...{
          show: showOverlay,
          target: overlayTarget,
          container: visTimelineContainerRef,
          containerPadding: 20,
        }}
      >
        <Popover id="task-info-overlay_popover-contained">
          <Popover.Content>
            {overlayTask && (
              <div className="task-overlay">
                <div className={cn("task-overlay__status", overlayTask.status)}>
                  <i className={`fas fa-${TASK_STATUS_ICON_NAME[overlayTask.status]}`} />
                  <span className="text">
                    {intl.formatMessage({ id: TASK_STATUS_I18N_TITLE[overlayTask.status] })}
                  </span>
                </div>
                <div className="task-overlay__name">{overlayTask.name}</div>
                <div className="task-overlay__info">
                  {overlayTask.createdAt && (
                    <>
                      <div className="info-label">{intl.formatMessage({ id: "CREATED" })}</div>
                      <div>
                        {`${DateUtils.format(new Date(overlayTask.createdAt), intl, true)}`}
                      </div>
                    </>
                  )}
                  {overlayTask.updatedAt && (
                    <>
                      <div className="info-label">{intl.formatMessage({ id: "UPDATED" })}</div>
                      <div>
                        {`${DateUtils.format(new Date(overlayTask.updatedAt), intl, true)}`}
                      </div>
                    </>
                  )}

                  {overlayTask.status === ETaskStatus.DONE && overlayTask.doneDate && (
                    <>
                      <div className="info-label">{intl.formatMessage({ id: "RESOLVED" })}</div>
                      <div>{`${DateUtils.format(new Date(overlayTask.doneDate), intl, true)}`}</div>
                    </>
                  )}

                  {(overlayTask.plannedStartDate || overlayTask.dueDate) && (
                    <>
                      <div className="info-label"></div>
                      <div className="planned-label">
                        {intl.formatMessage({ id: TASK_STATUS_I18N_TITLE.PLANNED })}
                      </div>
                    </>
                  )}

                  {overlayTask.plannedStartDate && (
                    <>
                      <div className="info-label">
                        {intl.formatMessage({ id: "TASK.LABEL.DATE.START" })}
                      </div>
                      <div>
                        {`${DateUtils.format(new Date(overlayTask.plannedStartDate), intl, true)}`}
                      </div>
                    </>
                  )}

                  {overlayTask.dueDate && (
                    <>
                      <div className="info-label">
                        {intl.formatMessage({ id: "TASK.LABEL.DATE.DUE" })}
                      </div>
                      <div>{`${DateUtils.format(new Date(overlayTask.dueDate), intl, true)}`}</div>
                    </>
                  )}
                </div>
              </div>
            )}
          </Popover.Content>
        </Popover>
      </Overlay>

      <ControlledMenu
        {...contextMenuProps}
        anchorPoint={anchorPoint}
        onClose={() => closeControllerMenu()}
      >
        {isOnTopOfLabels && (
          <>
            {isOnTopOfLabels.expand && (
              <MenuItem onClick={menuItemClickHandler(VisTimelineGroupsCommand.EXPAND)}>
                {intl.formatMessage({ id: "TASK.GANTT_CHART.VIEW.GROUPS.EXPAND" })}
              </MenuItem>
            )}
            {isOnTopOfLabels.collapse && (
              <MenuItem onClick={menuItemClickHandler(VisTimelineGroupsCommand.COLLAPSE)}>
                {intl.formatMessage({ id: "TASK.GANTT_CHART.VIEW.GROUPS.COLLAPSE" })}
              </MenuItem>
            )}
            {(isOnTopOfLabels.expand || isOnTopOfLabels.collapse) && <MenuDivider />}
          </>
        )}
        <MenuItem onClick={menuItemClickHandler(VisTimelineGroupsCommand.TOGGLE_DETAILS)}>
          {intl.formatMessage({
            id: `TASK.GANTT_CHART.VIEW.DETAILS.${ganttShowDetails ? "HIDE" : "SHOW"}`,
          })}
        </MenuItem>
        <MenuDivider />
        <MenuItem onClick={menuItemClickHandler(UpdateVisTimelineWindowCommand.FIT)}>
          {intl.formatMessage({ id: "TASK.GANTT_CHART.VIEW.FIT_ALL" })}
        </MenuItem>
        <SubMenu label={intl.formatMessage({ id: "TASK.GANTT_CHART.VIEW.FROM_TODAY" })}>
          {updateVisTimelineWindowFromDateAvailableCommands.map((from) => (
            <MenuItem key={from} onClick={menuItemClickHandler(from)}>
              {intl.formatMessage({
                id: `TASK.GANTT_CHART.VIEW.FROM.${contextMenuCommandToIntlKeySuffix[from]}`,
              })}
            </MenuItem>
          ))}
        </SubMenu>
        {fromDate && (
          <>
            <SubMenu
              label={intl.formatMessage(
                { id: "TASK.GANTT_CHART.VIEW.FROM" },
                { date: fromDateLabel }
              )}
            >
              {updateVisTimelineWindowFromDateAvailableCommands.map((from) => (
                <MenuItem key={from} onClick={menuItemClickHandler(from, fromDate)}>
                  {intl.formatMessage({
                    id: `TASK.GANTT_CHART.VIEW.FROM.${contextMenuCommandToIntlKeySuffix[from]}`,
                  })}
                </MenuItem>
              ))}
            </SubMenu>
          </>
        )}
      </ControlledMenu>
    </>
  );
};

export default TasksGanttChart;
