import { Tab } from "react-bootstrap";
import React from "react";
import { UserNotificationsDropdownTabPaneItem } from "./UserNotificationsDropdownTabPaneItem";
import { FormattedMessage } from "react-intl";

export const UserNotificationsDropdownTabPane = ({ notifications, tabKey, intl }) => {
  return (
    <Tab.Pane eventKey={tabKey} data-cy={`tab-${tabKey}`}>
      {notifications?.length > 0 ? (
        notifications.map((notification, index) => (
          <UserNotificationsDropdownTabPaneItem
            key={index}
            notification={notification}
            intl={intl}
          />
        ))
      ) : (
        <div className="d-flex flex-center text-center text-muted min-h-200px">
          <FormattedMessage id="NO_NOTIFICATION" />
        </div>
      )}
    </Tab.Pane>
  );
};
