import * as React from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import cn from "clsx";

import { AvatarPlaceholderClassName, SmallAvatarImage } from "app/_components/AvatarImage";

import { ProjectOwnerSwitchEdit, ProjectOwnerInfoIcon } from "./ProjectOwnerSwitchEdit";

import ProjectOwnerDisplaySelectedTooltip from "./ProjectOwnerSwitchEdit/ProjectOwnerDisplaySelectedTooltip";

import { useProjectOwnerSwitchContext } from "./ProjectOwnerSwitchContext";

export const ProjectOwnerSwitchContent: React.FunctionComponent = () => {
  const { readonly, isLoading, assigneeName, avatarPictureUrl } = useProjectOwnerSwitchContext();

  return (
    <>
      <div className="m-2 d-flex">
        <span className="font-weight-bold">
          <FormattedMessage id="PROJECT.OWNER" />
        </span>

        {!readonly && (
          <OverlayTrigger
            placement="bottom"
            overlay={
              <Tooltip id="product-owner-switch-tooltip">
                <FormattedMessage id="PROJECT.OWNER.MESSAGE.WARNING" />
              </Tooltip>
            }
          >
            <div className="ml-2">
              <ProjectOwnerInfoIcon />
            </div>
          </OverlayTrigger>
        )}
      </div>

      {!readonly ? (
        <ProjectOwnerSwitchEdit />
      ) : (
        <ProjectOwnerDisplaySelectedTooltip>
          <div className="projectOwnerDisplay">
            <div className={cn("control", { readonly: readonly })}>
              <SmallAvatarImage
                className="avatar"
                isLoading={isLoading}
                placeholderClassName={AvatarPlaceholderClassName.COMPANY}
                url={avatarPictureUrl}
              />

              <span className="text">{assigneeName}</span>
            </div>
          </div>
        </ProjectOwnerDisplaySelectedTooltip>
      )}
    </>
  );
};

export default ProjectOwnerSwitchContent;
