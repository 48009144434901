import * as React from "react";
import { useDidUpdate } from "rooks";

import { ITask } from "data/schemas";

import { SubtaskItem, SubtaskItemSharedProps } from "./SubtaskItem";

import "./SubtasksList.scss";

export interface SubtasksListSharedProps extends SubtaskItemSharedProps {
  disabled?: boolean;
  list: ITask[];
  toogleEditing: (action: any) => any;
}

export const SubtasksList: React.FunctionComponent<SubtasksListSharedProps> = ({
  disabled = false,
  list,
  onUpdate,
  onDelete,
  toogleEditing,
}) => {
  const [editingSubTaskId, setEditingSubtaskId] = React.useState("");

  useDidUpdate(() => {
    toogleEditing(editingSubTaskId && editingSubTaskId.length > 0);
  }, [editingSubTaskId]);

  return (
    <div className="subtasks-list d-flex flex-column">
      {/* index is used when we are holding subtask at creation time */}
      {list.map((subtask, index) => (
        <SubtaskItem
          {...{
            key: `subtask:${subtask.id}`,
            disabled: disabled || (editingSubTaskId !== "" && editingSubTaskId !== subtask.id),
            subtask,
            onUpdate,
            onDelete,
            setEditingSubtaskId,
          }}
        />
      ))}
    </div>
  );
};

export default SubtasksList;
