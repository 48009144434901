import React, { useEffect, useState } from "react";
import { useFileViewer } from "../../../../../hooks/useFileViewer";

import "./KycFileExplorerStyles.scss";
import {
  NoRecordsFoundMessage,
  PleaseWaitMessage,
  sortCaret,
  toAbsoluteUrl,
} from "../../../../../../_metronic/_helpers";
import paginationFactory, { PaginationProvider } from "react-bootstrap-table2-paginator";
import { Pagination } from "../../../../../../_metronic/_partials/controls";
import BootstrapTable, { ColumnDescription } from "react-bootstrap-table-next";
import { useTranslation } from "../../../../../hooks/useTranslation";
import { useIntl } from "react-intl";
import { KycDocumentTypeColumnFormatter } from "../column-formatters/KycDocumentTypeColumnFormatter";
import { KycFileDownloadColumnFormatter } from "../column-formatters/KycFileDownloadColumnFormatter";
import { useKyc } from "../../../../../hooks/useKyc";
import {
  defaultBootstrapTableOptions,
  fileExplorerPaginationOptions,
  KYC_FILE_EXPLORER_TEXT_SEARCH_FILTER,
} from "../definitions";
import { useKycFile } from "../../../../../hooks/useKycFile";
import { KycTranslation } from "../../../KycTranslation";
import { getFilesForKycFile, getGroupedFiles, isGeneratedFile } from "../../../KycFileUtils";
import { KycBoTableMode } from "../../../Kyc";
import { concat, isArray } from "lodash-es";
import { formatGroupedFiles, getFilesMissingPlaceholderTableRows } from "../../../KycUtils";
import { useSearch } from "../../../../../hooks/useSearch";
import { SearchLocal } from "../../../../../_components/Search/SearchLocal";
import { useKycFilesUIContext } from "../../../../../hooks/useKycFilesUIContext";
import { KycFileExplorerDropdownMenu } from "./KycFileExplorerDropdownMenu";
import { KycComplianceDetailsSection } from "../KycComplianceDetailsSection";
import { isEmpty } from "lodash";
import cn from "clsx";
import SVG from "react-inlinesvg";
import { useLoading } from "../../../../../hooks/useLoading";

interface KycFileExplorerProps {
  mode?: KycBoTableMode;
  setSourceEntities: Function;
  selectedEntity: { id: string; searchText: string };
  kycEntities: any;
}

export const KycFileExplorer = ({
  mode = KycBoTableMode.COMPLIANCE,
  setSourceEntities,
  selectedEntity,
  kycEntities,
}: KycFileExplorerProps): JSX.Element => {
  const intl = useIntl();

  const { fm, fDisplayName } = useTranslation();
  const { setCurrentFiles, openFileViewer } = useFileViewer();
  const { currentKycFlow } = useKyc();
  const { deleteFile } = useKycFile();
  const { entitiesFiltered, setEntities, filters, setFilters } = useSearch();
  const { currentFiles } = useKycFile();
  const { isCurrentlyLoading } = useLoading();

  const [countFiles, setCountFiles] = useState<number>(-1);
  const [processedEntities, setProcessedEntities] = useState<any[]>([]);

  enum KycFileExplorerDisplayMode {
    EVIDENCE_PROVIDED_MISSING = "EVIDENCE_PROVIDED_MISSING",
    DEFAULT = "DEFAULT",
  }

  const [displayMode, setDisplayMode] = useState<KycFileExplorerDisplayMode>(
    KycFileExplorerDisplayMode.DEFAULT
  );

  useEffect(() => {
    if (isEmpty(filters)) {
      setFilters([{ ...KYC_FILE_EXPLORER_TEXT_SEARCH_FILTER }]);
    }
  }, [filters]);

  useEffect(() => {
    if (currentKycFlow) {
      if (selectedEntity?.searchText !== "") {
        const selectedEntityGrouped = kycEntities[selectedEntity?.id];
        const missingFilesRows = getFilesMissingPlaceholderTableRows(selectedEntityGrouped, fm);
        const allFiles = concat(
          entitiesFiltered?.filter((f) => f.kycRelatedToEntity?.id === selectedEntity?.id),
          missingFilesRows
        );

        setProcessedEntities(allFiles);
        setCountFiles(allFiles?.length);
        setDisplayMode(KycFileExplorerDisplayMode.EVIDENCE_PROVIDED_MISSING);
      } else {
        setProcessedEntities(entitiesFiltered);
        setCountFiles(entitiesFiltered?.length);
        setDisplayMode(KycFileExplorerDisplayMode.DEFAULT);
      }
    }
  }, [selectedEntity, entitiesFiltered, currentFiles]);

  useEffect(() => {
    if (currentKycFlow && currentFiles && isArray(currentFiles)) {
      const files = getGroupedFiles(currentFiles, intl);
      const groupedFiles = files?.multiFiles?.filter((file: any) => file.mimeType);
      const oldFiles = files?.olderFilesToDelete;
      for (const f of oldFiles) {
        deleteFile(f);
      }

      const groupedFilesFormatted = formatGroupedFiles(
        groupedFiles,
        currentKycFlow,
        intl,
        fDisplayName,
        fm
      );

      setSourceEntities(groupedFilesFormatted);
      setEntities(groupedFilesFormatted);
      setCountFiles(groupedFilesFormatted?.length);
    }
  }, [currentKycFlow, currentFiles, intl]);

  const openFileInViewer = (e: any, row: any) => {
    e.preventDefault();
    const filesToShow = getFilesForKycFile(row.files || row, currentFiles);
    if (filesToShow) {
      setCurrentFiles(filesToShow);
      openFileViewer();
    }
  };

  const columnsProvidedMissingEvidence: ColumnDescription[] = [
    {
      dataField: "isAvailable",
      classes: "text-center",
      text: "",
      sort: true,
      sortCaret: sortCaret,
      headerStyle: { minWidth: 24, maxWidth: 24 },
      style: { minWidth: 24, maxWidth: 24 },
      formatter: (cell: any, row: any) => (
        <>
          {!isGeneratedFile(row) && (
            <div className="mr-2 text-warning font-size-lg">
              <span
                className={cn(
                  "svg-icon svg-icon-lg",
                  cell ? "svg-icon-success" : "svg-icon-warning"
                )}
              >
                <SVG
                  src={toAbsoluteUrl(
                    cell
                      ? "/media/svg/icons/Code/Done-circle.svg"
                      : "/media/svg/icons/Code/Warning-2.svg"
                  )}
                />
              </span>
            </div>
          )}
        </>
      ),
    },
    {
      dataField: "id",
      text: "",
      sort: false,
      headerStyle: { minWidth: 24, maxWidth: 24 },
      style: { minWidth: 24, maxWidth: 24 },
      formatter: KycDocumentTypeColumnFormatter,
    },
    {
      dataField: "labels.evidenceCategory",
      headerClasses: "text-left text-nowrap",
      classes: "text-left",
      headerStyle: { minWidth: 100, maxWidth: 100 },
      style: { minWidth: 100, maxWidth: 100 },
      text: fm(KycTranslation.EVIDENCE_TITLE),
      sort: true,
      sortCaret: sortCaret,
    },
    {
      dataField: "labels.filename",
      headerClasses: "text-left",
      classes: "text-left text-wrap",
      text: fm(KycTranslation.PLACEHOLDER_FILENAME),
      sort: true,
      headerStyle: { minWidth: 200, maxWidth: 200 },
      style: { minWidth: 200, maxWidth: 200 },
      sortCaret: sortCaret,
    },

    {
      dataField: "labels.evidenceDocumentType",
      headerClasses: "text-left text-nowrap",
      headerStyle: { minWidth: 100, maxWidth: 100 },
      style: { minWidth: 100, maxWidth: 100 },
      classes: "text-left",
      text: fm("COMMON.TYPE"),
      sort: true,
      sortCaret: sortCaret,
    },
  ];
  const columnsDefault: ColumnDescription[] = [
    {
      dataField: "labels.entityName",
      headerClasses: "text-left",
      classes: "text-left",
      text: fm("COMMON.NAME"),
      headerStyle: { minWidth: 150, maxWidth: 150 },
      style: { minWidth: 150, maxWidth: 150 },
      sort: true,
      sortCaret: sortCaret,
    },
    {
      dataField: "labels.entity",
      headerClasses: "text-left",
      headerStyle: { minWidth: 80, maxWidth: 80 },
      style: { minWidth: 80, maxWidth: 80 },
      classes: "text-left",
      text: fm(KycTranslation.COLUMN_NAMES_ENTITY),
      sort: true,
      sortCaret: sortCaret,
    },
    {
      dataField: "labels.evidenceCategory",
      headerClasses: "text-left text-nowrap",
      classes: "text-left",
      text: fm(KycTranslation.EVIDENCE_TITLE),
      sort: true,
      sortCaret: sortCaret,
      headerStyle: { minWidth: 180, maxWidth: 180 },
      style: { minWidth: 180, maxWidth: 180 },
    },

    {
      dataField: "labels.evidenceDocumentType",
      headerClasses: "text-left text-nowrap",
      classes: "text-left",
      text: fm("COMMON.TYPE"),
      sort: true,
      sortCaret: sortCaret,
      headerStyle: { minWidth: 100, maxWidth: 100 },
      style: { minWidth: 100, maxWidth: 100 },
    },
    {
      dataField: "id",
      text: "",
      sort: false,
      headerStyle: { width: 30 },
      formatter: KycDocumentTypeColumnFormatter,
    },

    {
      dataField: "labels.filename",
      headerClasses: "text-left",
      headerStyle: { minWidth: 150, maxWidth: 150 },
      style: { minWidth: 150, maxWidth: 150 },
      classes: "text-left",
      text: fm(KycTranslation.PLACEHOLDER_FILENAME),
      sort: true,
      sortCaret: sortCaret,
    },
  ];

  const fileDownloadColumn = {
    dataField: "files",
    headerClasses: "text-left text-nowrap",
    classes: "text-right",
    text: "",
    headerStyle: { minWidth: 80, maxWidth: 80 },
    style: { minWidth: 80, maxWidth: 80, height: 55 },
    formatter: KycFileDownloadColumnFormatter,
    formatExtraData: {
      intl,
      files: currentFiles,
      deleteFile,
      mode: mode,
      isCurrentlyLoading,
    },
  };

  if (
    processedEntities.filter(
      (f: any) => f?.url || (f?.files && f?.files?.filter((file: any) => file?.url).length)
    ).length
  ) {
    columnsProvidedMissingEvidence.push(fileDownloadColumn);
    columnsDefault.push(fileDownloadColumn);
  }

  const rowStyle = (row: any) => {
    if (row?.url || (row?.files && (row?.files || [])?.filter((f: any) => f?.url)?.length)) {
      return { cursor: "pointer" };
    }
    return {};
  };

  return (
    <>
      {currentKycFlow && (
        <KycComplianceDetailsSection title="FILE.TITLE" controls={<KycFileExplorerDropdownMenu />}>
          <div className="form-filtration d-md-flex">
            <div className={"margin-bottom-10-mobile w-100"} style={{ maxWidth: "100%" }}>
              <div
                style={{
                  minHeight:
                    displayMode !== KycFileExplorerDisplayMode.EVIDENCE_PROVIDED_MISSING ? 50 : 0,
                }}
              >
                {displayMode !== KycFileExplorerDisplayMode.EVIDENCE_PROVIDED_MISSING && (
                  <SearchLocal useEntityUIContext={useKycFilesUIContext} />
                )}
              </div>
            </div>
          </div>
          <PaginationProvider
            pagination={paginationFactory({
              ...fileExplorerPaginationOptions,
              totalSize: countFiles || 0,
            })}
          >
            {({ paginationProps, paginationTableProps }) => {
              return (
                <Pagination paginationProps={paginationProps}>
                  <BootstrapTable
                    {...paginationTableProps}
                    {...defaultBootstrapTableOptions}
                    keyField="id"
                    data={processedEntities}
                    columns={
                      displayMode === KycFileExplorerDisplayMode.EVIDENCE_PROVIDED_MISSING
                        ? columnsProvidedMissingEvidence
                        : columnsDefault
                    }
                    noDataIndication={() => (
                      <NoRecordsFoundMessage
                        entities={processedEntities}
                        messageId={KycTranslation.NO_FILES_AVAILABLE}
                      />
                    )}
                    defaultSorted={[
                      displayMode === KycFileExplorerDisplayMode.EVIDENCE_PROVIDED_MISSING
                        ? { dataField: "uploadedAt", order: "desc" }
                        : { dataField: "labels.entityName", order: "asc" },
                    ]}
                    rowEvents={{
                      onClick: (e, row) => {
                        if (
                          row?.isAvailable &&
                          (row?.url ||
                            (row?.files && (row?.files || [])?.filter((f: any) => f?.url)?.length))
                        ) {
                          openFileInViewer(e, row);
                        }
                      },
                    }}
                    rowStyle={rowStyle}
                  >
                    <PleaseWaitMessage entities={processedEntities} />
                  </BootstrapTable>
                </Pagination>
              );
            }}
          </PaginationProvider>
        </KycComplianceDetailsSection>
      )}
    </>
  );
};
