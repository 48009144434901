import { IPriceIndex, IProject, ISettings } from "data/schemas";
import { isEmpty } from "lodash-es";
import { DateUtils } from "./DateUtils";
import { IntlShape } from "react-intl";

export const getAvailablePriceIndices = (
  priceIndices: IPriceIndex[],
  targetDate: Date = new Date(),
  enableFuturePriceIndexes = true
) => {
  let availablePriceIndices = getPreprocessedPriceIndices(priceIndices);
  if (!enableFuturePriceIndexes) {
    availablePriceIndices = availablePriceIndices.filter(
      (priceIndex: IPriceIndex) =>
        priceIndex.startAt && new Date(priceIndex.startAt) <= new Date(targetDate)
    );
  }
  return availablePriceIndices;
};

export const getBasePriceIndex = (project: IProject) => {
  const projectStartDate = project.startDate || project.createdAt;
  if (!projectStartDate || !project?.projectOwner) return;

  const availablePriceIndices = getCompanyAvailablePricesIndices(
    project?.projectOwner,
    false,
    projectStartDate
  );
  if (availablePriceIndices.length < 1) return;
  return availablePriceIndices[0];
};

export const getPriceIndexById = (priceIndices: IPriceIndex[], id: string) => {
  return priceIndices.find((i) => i.id === id);
};

export const getCompanyAvailablePricesIndices = (
  company?: ISettings,
  enableFuturePriceIndexes = false,
  targetDate: Date = new Date()
): any => {
  if (!company || !company?.priceIndices) return [];
  return getAvailablePriceIndices(company.priceIndices, targetDate, enableFuturePriceIndexes);
};

export const sortPriceIndicesByDateDesc = (priceIndices?: IPriceIndex[]) => {
  if (!priceIndices) return [];

  const arrayForSort = [...priceIndices];

  return arrayForSort?.sort(
    (a: any, b: any) => new Date(b.startAt).getTime() - new Date(a.startAt).getTime()
  );
};

export const filterPricesIndicesByStatus = (
  priceIndices: IPriceIndex[] = [],
  ignoredStatus: string
) => {
  return priceIndices?.filter((priceIndex: IPriceIndex) => priceIndex?.status !== ignoredStatus);
};

// this function will filter indecies from one before project start until Today
export const filterPriceIndicesProperDate = (priceIndices: IPriceIndex[], project: IProject) => {
  const filteredIndices: IPriceIndex[] = [];

  const startDate = project.startDate ?? project.createdAt;

  if (!startDate) return filteredIndices;

  let foundBeforeStartDate = false;

  const today = new Date();

  const projectStartDate = new Date(startDate);

  for (const priceIndex of priceIndices) {
    if (priceIndex.startAt) {
      const indexStartDate = new Date(priceIndex.startAt);

      if (!foundBeforeStartDate && indexStartDate < projectStartDate) {
        filteredIndices.push(priceIndex);
        foundBeforeStartDate = true;
      } else if (projectStartDate < indexStartDate && indexStartDate < today) {
        filteredIndices.push(priceIndex);
      }
    }
  }

  if (filteredIndices.length <= 1) return [];
  return filteredIndices;
};

export const getPreprocessedPriceIndices = (
  priceIndices: IPriceIndex[] | undefined,
  sortedByDateDesc: boolean = true,
  filterDeleted: boolean = true
) => {
  if (!priceIndices) return [];

  let pIndices = [...priceIndices];

  if (filterDeleted) {
    pIndices = filterPricesIndicesByStatus(pIndices, "DELETED");
  }

  if (sortedByDateDesc) {
    pIndices = sortPriceIndicesByDateDesc(pIndices);
  }

  return pIndices;
};

export const getActivePriceIndexForDate = (
  priceIndices: IPriceIndex[] | undefined,
  currentDate: Date = new Date()
) => {
  if (!priceIndices || isEmpty(priceIndices)) return;

  const eligiblePriceIndices: IPriceIndex[] = filterPricesIndicesByStatus(
    priceIndices,
    "DELETED"
  ).filter(
    (priceIndex: IPriceIndex) => priceIndex.startAt && new Date(priceIndex.startAt) <= currentDate
  );

  return sortPriceIndicesByDateDesc(eligiblePriceIndices)[0];
};

export const getActivePriceIndexIdForDate = (
  priceIndices: IPriceIndex[] | undefined,
  currentDate: Date = new Date()
) => {
  const findActivePriceIndex = getActivePriceIndexForDate(priceIndices, currentDate);
  if (findActivePriceIndex) {
    return findActivePriceIndex.id;
  }
};

export const getPriceIndexOptionLabel = (
  priceIndex: IPriceIndex,
  priceIndices: IPriceIndex[],
  basePriceIndex: IPriceIndex | undefined,
  intl: IntlShape
): string => {
  let optionContent: string = "";

  if (getActivePriceIndexIdForDate(priceIndices) === priceIndex.id) {
    optionContent +=
      intl.formatMessage({ id: "PRICE_INDEX.OPTION.CURRENT" })?.toUpperCase() + " | ";
  } else if (priceIndex.startAt && new Date(priceIndex.startAt).getTime() > new Date().getTime()) {
    optionContent += intl.formatMessage({ id: "PRICE_INDEX.OPTION.FUTURE" })?.toUpperCase() + " | ";
  } else if (basePriceIndex?.id === priceIndex.id) {
    optionContent += intl.formatMessage({ id: "PRICE_INDEX.OPTION.BASE" })?.toUpperCase() + " | ";
  }

  optionContent += `${priceIndex?.value?.toFixed(2)} | ${priceIndex?.source} | ${
    priceIndex.startAt &&
    DateUtils.format(priceIndex.startAt, intl, false, {
      year: "numeric",
      month: "numeric",
    })
  }`;

  return optionContent;
};
