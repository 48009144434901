import { Button } from "react-bootstrap";
import { LOADING_FETCH_USER_EVENTS } from "../../contexts/AuditTrailContext";
import cn from "clsx";
import * as React from "react";
import { useAuditTrail } from "../../hooks/useAuditTrail";

export const AuditTrailNavbar = (): JSX.Element => {
  const { events, getNextUserEvents, getPreviousUserEvents, currentPageIndex, loadings } =
    useAuditTrail();

  return (
    <div className="d-flex flex-row-reverse ">
      <Button
        className="ml-1"
        variant="light"
        size="sm"
        disabled={events?.length === 0 || loadings.has(LOADING_FETCH_USER_EVENTS)}
        onClick={() => getNextUserEvents()}
      >
        <i className={cn("fas icon-md ml-2", "fa-angle-right")} />
      </Button>
      <Button
        variant="light"
        size="sm"
        disabled={currentPageIndex === 0 || loadings.has(LOADING_FETCH_USER_EVENTS)}
        onClick={async () => getPreviousUserEvents()}
      >
        <i className={cn("fas icon-md ml-2", "fa-angle-left")} />
      </Button>
    </div>
  );
};
