import { useFormikContext } from "formik";
import * as React from "react";
import { v4 as uuid } from "uuid";
import { FormattedMessage, useIntl } from "react-intl";
import { useAppSelector } from "../../../../redux/hooks";
import { cloneDeep } from "lodash-es";
import { useBudgetsUIContext } from "../BudgetsUIContext";
import { shallowEqual } from "react-redux";
import { computeCategory, computeRelatedProps, sumBudgetLineBaseProps } from "../BudgetsUtils";
import {
  BudgetLineColumnsTypes,
  IBudget,
  SubcontractorFinanceType,
} from "../../../../data/schemas";
import { BudgetExpensesHeader } from "./components/BudgetExpensesHeader/BudgetExpensesHeader";
import { ColumnDef, createColumnHelper } from "@tanstack/react-table";
import { EditableCardTableItem } from "../../../_components/CardTable/context/CardTableContext";
import { RowTheme } from "../../../_components/CardTable/CardTableThemes";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import { downloadCSV } from "./components/BudgetExport";
import { CardTable } from "../../../_components/CardTable/CardTable";
import cn from "clsx";
import { useDebounce, useResizeObserverRef } from "rooks";
import { BudgetExpensesCell } from "./components/BudgetExpensesCell";

type BudgetColumns = {
  [key in BudgetLineColumnsTypes]: any;
};

export interface BudgetTableRow extends BudgetColumns, EditableCardTableItem {
  readOnly?: boolean;
  categoryId?: string;
  rowType?: string;
  visible?: boolean;
  userId?: string;
  fileId?: string;
  lineId?: string;
}

const headers: Partial<Record<BudgetLineColumnsTypes, string>> = {
  label: "COMMON.DESCRIPTION",
  planned: "BUDGET.PLANNED",
  quotes: "FINANCE.QUOTES",
  orders: "FINANCE.ORDERS",
  supplementary_agreements: "BUDGET.SUPPLEMENTARY_AGREEMENTS",
  ordersAndSupplAgreements: "BUDGET.ORDERS_AND_SUPPLEMENTARY_AGREEMENTS",
  realDifference: "BUDGET.DIFFERENCE",
  realDifferencePercentage: "BUDGET.DIFFERENCE_PERCENT",
  invoiced: "BUDGET.INVOICED",
  discounts: "BUDGET.DISCOUNTS",
  invoicedPercentage: "BUDGET.INVOICED_PERCENT",
  remainingToInvoice: "BUDGET.REMAINING_TO_INVOICE",
  invoicedOrders: "BUDGET.INVOICED_ORDERS",
  remainingOrdersToInvoice: "BUDGET.REMAINING_ORDERS_TO_INVOICE",
  invoicedOrdersPercentage: "BUDGET.INVOICED_ORDERS_PERCENT",
  invoicedSupplementaryAgreements: "BUDGET.INVOICED_SUPPLEMENTARY_AGREEMENT",
  remainingSupplementaryAgreementsToInvoice: "BUDGET.REMAINING_SUPPLEMENTARY_AGREEMENT_TO_INVOICE",
  invoicedSupplementaryAgreementsPercentage: "BUDGET.INVOICED_SUPPLEMENTARY_AGREEMENT_PERCENT",
  invoiceStatus: "BUDGET.REVENUE.TABLE_COLUMN.PAYMENT_STATUS",
};

export interface IBudgetEditExpensesProps {
  budget: IBudget;
  disabled: boolean;
}

export const BudgetEditExpenses: React.FC<IBudgetEditExpensesProps> = ({ budget, disabled }) => {
  const intl = useIntl();
  const { values, handleChange } = useFormikContext<IBudget>();
  const {
    setBudgetTotals,
    budgetTotals,
    saveBudgetFields,
    setComputedCategories,
    computedCategories,
  } = useBudgetsUIContext();

  const { project } = useAppSelector(
    (state) => ({
      project: state.projects.projectForEdit.current,
      budgetLoading: state.budgets.actionsLoading,
    }),
    shallowEqual
  );

  const isSelectedBudget = project && budget.id === project?.selectedBudget;

  const [tableWidth, setTableWidth] = React.useState<number>();

  const resize = (entries: ResizeObserverEntry[]) => {
    setTableWidth(entries[0].contentRect.width);
  };
  const [tableRef] = useResizeObserverRef(resize);

  const customHandleChange = React.useCallback(
    useDebounce((event: any) => {
      handleChange(event);
      saveBudgetFields(event.target.name, event.target.value);
    }, 500),
    []
  );

  const addCategory = () => {
    const resLines = cloneDeep(budget.sortedLines);
    resLines.push({
      id: uuid(),
      label: "",
      visible: true,
      sequence: resLines.length,
      lines: [{ id: uuid(), label: "", plannedBudget: 0, sequence: 0 }],
    });
    saveBudgetFields("sortedLines", resLines);
  };

  const addLine = (id: string) => () => {
    const category = budget.sortedLines.find((cat) => cat.id === id)!;
    const resLines = cloneDeep(category.lines);
    resLines.push({
      id: uuid(),
      label: "",
      plannedBudget: 0,
      sequence: resLines.length,
    });
    saveBudgetFields(`sortedLines.${category.sequence}.lines`, resLines);
  };

  React.useEffect(() => {
    if (project && budget) {
      const computedCategoriesTmp = budget.sortedLines?.map((category) =>
        computeCategory(category.id, project, category)
      );
      if (isSelectedBudget) {
        const hasUnspecifiedCategory = Object.values(SubcontractorFinanceType).some(
          (financeType) =>
            !!project?.subcontractorsFinanceFiles?.[financeType]?.data?.unspecifiedCategory
        );
        if (hasUnspecifiedCategory) {
          computedCategoriesTmp.push(computeCategory("unspecifiedCategory", project));
        }
      }
      setComputedCategories(computedCategoriesTmp);
    }
  }, [project, budget]);

  const data: BudgetTableRow[] | undefined = React.useMemo(() => {
    if (!computedCategories) return;
    return [
      {
        ...budgetTotals,
        id: "BUDGET_EXPENSE_TOTAL",
        label: intl.formatMessage({ id: "COMMON.TOTAL" }),
        readOnly: true,
        rowTheme: RowTheme.GREY,
      },
      ...computedCategories.map(({ lines, unspecifiedLines, ...category }) => {
        const isUnspecifiedCategory = category.id === "unspecifiedCategory";
        return {
          ...category,
          label: isUnspecifiedCategory
            ? intl.formatMessage({ id: "BUDGET.NOT_SPECIFIED" })
            : category.label,
          children: [
            ...lines.map(({ subcontractors, ...line }: any) => {
              return {
                ...line,
                children: subcontractors.map((sub: any) => {
                  return {
                    ...sub,
                    children: sub.files,
                  };
                }),
                rowType: "BUDGET_LINE",
              };
            }),
            ...(unspecifiedLines?.map((l: any) => ({ ...l, readOnly: true })) ?? []),
          ],
          rowType: !isUnspecifiedCategory ? "BUDGET_CATEGORY" : undefined,
          rowTheme: isUnspecifiedCategory || !category.visible ? RowTheme.INFO : undefined,
          defaultExpanded: !isUnspecifiedCategory,
          footer:
            !isUnspecifiedCategory && !disabled ? (
              <div className="position-sticky left-0" style={{ width: tableWidth + "px" }}>
                <button
                  type="button"
                  className="btn btn-sm btn-light rounded-0 w-100 mt-2"
                  onClick={addLine(category.id)}
                >
                  <i className="ki ki-plus icon-nm" />
                  <FormattedMessage id="BUDGET.ACTION.ADD.LINE" />
                </button>
              </div>
            ) : undefined,
        };
      }),
    ];
  }, [computedCategories, budgetTotals, tableWidth]);

  React.useEffect(() => {
    if (computedCategories) {
      const total = sumBudgetLineBaseProps(computedCategories);
      setBudgetTotals({ ...total, ...computeRelatedProps(total) });
    }
  }, [computedCategories]);

  const columnHelper = createColumnHelper<BudgetTableRow>();

  const columns: ColumnDef<BudgetTableRow>[] = React.useMemo(() => {
    let displayedHeaders: Partial<Record<BudgetLineColumnsTypes, string>> = headers;
    if (!isSelectedBudget) {
      const { label, planned } = headers;
      displayedHeaders = { label, planned };
    }
    return Object.entries(displayedHeaders).map(([key, value]) => {
      const typedKey = key as BudgetLineColumnsTypes;
      const column = columnHelper.accessor(typedKey, {
        header: () => (
          <div className={cn(typedKey !== "label" && "ml-auto text-right")}>
            <FormattedMessage id={value} />
          </div>
        ),
        cell: (props) => (
          <BudgetExpensesCell
            {...props}
            typedKey={typedKey}
            customHandleChange={customHandleChange}
            disabled={disabled}
          />
        ),
        meta: {
          minWidth: 120,
          label: intl.formatMessage({ id: value }),
        },
        enableHiding: true,
      });

      switch (typedKey) {
        case "label":
          column.enableHiding = false;
          column.meta!.customWidth = cn(
            "flex-shrink-0 w-250px w-sm-250px w-md-300px w-lg-350px",
            !isSelectedBudget && "flex-grow-1"
          );
          column.meta!.fixed = isSelectedBudget;
          break;
        case "planned":
          column.enableHiding = false;
          column.meta!.customWidth = !isSelectedBudget ? "flex-shrink-0 w-150px" : undefined;
          break;
        case "invoiceStatus":
          column.meta!.minWidth = 135;
          break;
      }

      return column;
    });
  }, [headers, isSelectedBudget]);

  return (
    <>
      <BudgetExpensesHeader budget={budget} />
      {!!data ? (
        <div style={{ marginLeft: "-21px", marginRight: "-21px" }}>
          <CardTable
            data={data}
            columns={columns}
            multiCardLayout={true}
            id="BUDGET_EXPENSES"
            innerRef={tableRef}
            customActions={
              <OverlayTrigger
                overlay={
                  <Tooltip id="budgets-download-tooltip">
                    <FormattedMessage id="TOOLTIP.EXPORT_CSV" />
                  </Tooltip>
                }
              >
                <button
                  type="button"
                  className="btn btn-icon btn-light-secondary"
                  onClick={() => {
                    downloadCSV(budget, project, values, intl);
                  }}
                >
                  <span className="svg-icon svg-icon-md svg-icon-primary">
                    <SVG src={toAbsoluteUrl("/media/svg/icons/Files/Download.svg")} />
                  </span>
                </button>
              </OverlayTrigger>
            }
          />
          {!disabled && (
            <button
              type="button"
              className="btn btn-sm btn-light rounded-0 w-100 mt-6"
              onClick={addCategory}
            >
              <i className="ki ki-plus icon-nm" />
              <FormattedMessage id="BUDGET.ACTION.CATEGORY.ADD" />
            </button>
          )}
        </div>
      ) : (
        <div className="d-flex align-items-center justify-content-center">
          <div className="spinner spinner-lg spinner-primary h-30px w-30px" />
        </div>
      )}
    </>
  );
};
