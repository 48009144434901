import { TagLink } from "../../data/schemas";
import { useQueries, QueriesResults, useQueryClient } from "react-query";
import { TAGLINK } from "../_utils/dataTypes";
import { API } from "@aws-amplify/api";
import { useMemo } from "react";
import { TagLinkMap } from "../contexts/TagContext";

const fetchTagLinksByKey = (key: string): Promise<TagLink[]> => {
  const queryParameters = { queryStringParameters: { relatedTo: key } };
  return API.get("API", "/tags/*/links", queryParameters).then((tagLinksFetched) => {
    return tagLinksFetched;
  });
};
export const useGetTagLinks = (
  relatedToEntitiesList: string[],
  options?: QueriesResults<TagLink[]>
) => {
  const queryClient = useQueryClient();
  const tagLinks = useQueries<TagLink[]>(
    relatedToEntitiesList.map((key) => {
      const queryKey = [TAGLINK, key];
      const defaultResult = queryClient.getQueryDefaults(queryKey);
      return defaultResult
        ? { queryFn: defaultResult.queryFn, queryKey, ...options }
        : {
            queryKey,
            queryFn: () => fetchTagLinksByKey(key),
            ...options,
          };
    })
  );

  const tagLinkMap: TagLinkMap = useMemo(() => {
    const result: TagLinkMap = {};
    tagLinks.forEach((tagLink, index) => {
      const key = relatedToEntitiesList[index];
      if (tagLink.isSuccess) {
        result[key] = (tagLink.data as TagLink[]) || [];
      } else {
        result[key] = [];
      }
    });
    return result;
  }, [tagLinks, relatedToEntitiesList]);

  return { tagLinks: tagLinkMap };
};
