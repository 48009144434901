import * as React from "react";
import cn from "clsx";
import type { OptionProps } from "react-select";
import { components } from "react-select";

import type { CustomSelectOption } from "../../../CustomSelectBase";

import "./CustomOption.scss";

export interface CustomOptionProps {}

export const CustomOption: React.FunctionComponent<OptionProps<unknown, false>> = ({
  children,
  className,
  ...props
}) => {
  const { icon, color } = props.data as CustomSelectOption;

  const iconStyles = {
    "--iconLocalColor": color,
  } as any;

  return (
    <components.Option {...{ ...props, className: cn("custom-select-status-option", className) }}>
      {icon && <i style={iconStyles} className={`fas fa-${icon} icon-md`} />}
      <span>{children}</span>
    </components.Option>
  );
};

export default CustomOption;
