/**
 * Here is a method to minimize (prevent?) decimal issue when working with float
 * In JS floats aren't handled well 0.1 + 0.2 = 0.30000000000000004 / 0.101 * 100 = 10.100000000000001
 * A more friendly solution exists but is less reliable : parseFloat(<value>.toFixed(<decimals>)
 * ex: parseFloat((0.155).toFixed(2)) => 0.15 instead of 0.16
 */
import { SUFFIX_EURO_CURRENCY } from "./suffixUtils";
import { floor, sortBy, sum } from "lodash-es";

export const accurateFloatOperation = (value, maxDecimals) =>
  parseFloat(Math.round(`${value}e${maxDecimals}`) + "e-" + maxDecimals);

export const formatCurrency = (value, decimals, intl) =>
  intl.formatNumber(value, {
    minimumFractionDigits: decimals,
    maximumFractionDigits: decimals,
  }) + SUFFIX_EURO_CURRENCY;

//https://stackoverflow.com/questions/13483430/how-to-make-rounded-percentages-add-up-to-100
const error_gen = (actual, rounded) => {
  const divisor = Math.sqrt(actual < 1.0 ? 1.0 : actual);
  return Math.pow(Math.abs(rounded - actual), 2) / divisor;
};

export const roundTo100 = (percents) => {
  const rounded = percents.map((x) => floor(x));
  const upCount = 100 - sum(rounded);
  const errors = percents.map((x, i) => [
    error_gen(x, rounded[i] + 1) - error_gen(x, rounded[i]),
    i,
  ]);
  const rank = sortBy(errors, [0]);

  for (let i = 0; i < upCount; i++) {
    rounded[rank[i][1]] += 1;
  }

  return rounded;
};

export const roundByTo100 = (values, denominator) =>
  roundTo100(values.map((v) => (v / denominator) * 100));
