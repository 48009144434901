import * as React from "react";
import { useDebounce, useDidMount, useInput, useKey } from "rooks";
import { useIntl } from "react-intl";

import "./UpdateSubtaskNameField.scss";

type InputHandler = ReturnType<typeof useInput>;

export interface UpdateSubtaskNameFieldProps extends InputHandler {
  onEnterPressed?: () => void;
  onEscPressed?: () => void;
}

export const UpdateSubtaskNameField: React.FunctionComponent<UpdateSubtaskNameFieldProps> = ({
  onEnterPressed,
  onEscPressed,
  ...inputHandlerProps
}) => {
  const intl = useIntl();
  const inputRef = React.useRef<HTMLInputElement>(null);

  useDidMount(() => {
    const { current: subtaskTitleInput } = inputRef;
    if (!subtaskTitleInput) return;
    subtaskTitleInput.focus();
  });

  const enterPressedHandler = React.useCallback(
    (_: KeyboardEvent) => {
      const { current: subtaskTitleInput } = inputRef;
      if (!subtaskTitleInput) return;
      onEnterPressed && onEnterPressed();
    },
    [onEnterPressed]
  );

  const escPressedHandler = React.useCallback(
    (_: KeyboardEvent) => {
      const { current: subtaskTitleInput } = inputRef;
      if (!subtaskTitleInput) return;
      onEscPressed && onEscPressed();
    },
    [onEscPressed]
  );

  // https://react-hooks.org/docs/useDebounce
  const enterPressedHandlerDebounced = useDebounce(enterPressedHandler, 100);
  const escPressedHandlerDebounced = useDebounce(escPressedHandler, 100);

  // https://react-hooks.org/docs/useKey
  useKey(["Enter"], enterPressedHandlerDebounced, {
    target: inputRef,
    when: onEnterPressed !== undefined,
  });
  useKey([27 /* esc */], escPressedHandlerDebounced, {
    target: inputRef,
    when: onEscPressed !== undefined,
  });

  return (
    <input
      data-cy="input-subtask-create"
      ref={inputRef}
      type="text"
      className="update-subtask-name form-control no-validation"
      placeholder={intl.formatMessage({
        id: "TASK.LABEL.TITLE",
      })}
      {...inputHandlerProps}
    />
  );
};

export default UpdateSubtaskNameField;
