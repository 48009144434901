import * as React from "react";
import { useEffect } from "react";
import { useKyc } from "../../../../hooks/useKyc";
import { useIntl } from "react-intl";
import BootstrapTable, { ColumnDescription } from "react-bootstrap-table-next";
import { useTranslation } from "../../../../hooks/useTranslation";
import { KycStatusFormatter } from "./column-formatters/KycStatusFormatter";
import { KycDateTimeFormatter } from "./column-formatters/KycDateTimeFormatter";
import {
  NoRecordsFoundMessage,
  PleaseWaitMessage,
  sortCaret,
} from "../../../../../_metronic/_helpers";
import "./KycComplianceTableStyles.scss";
import paginationFactory, { PaginationProvider } from "react-bootstrap-table2-paginator";
import { Pagination } from "_metronic/_partials/controls";
import { KycFlow, KycFlowListItem, KycLoading, KycStepEvent } from "../../Kyc";
import { useLoading } from "../../../../hooks/useLoading";
import {
  defaultBootstrapTableOptions,
  defaultPaginationOptions,
  KYC_LIST_TEXT_SEARCH_FILTER,
} from "./definitions";
import { KycComplianceDropdownMenu } from "./KycComplianceDropdownMenu";
import EmptyState from "app/_components/EmptyState/EmptyState";
import { useHistory } from "react-router-dom";
import { KycTranslation } from "../../KycTranslation";
import { useSession } from "../../../../hooks/useSession";
import { useAppSelector } from "../../../../../redux/hooks";
import { canDelete, canEdit, isOwner } from "../../../../_utils/authUtils";
import { KycBreadcrumbs } from "../../KycBreadcrumbs";
import { useKycEvent } from "../../../../hooks/useKycEvent";
import { isEmpty } from "lodash";
import { insertIf } from "../../KycUtils";
import {
  isKycMultipleFlowTypesAvailable,
  kycAvailableFlowTypes,
} from "../../../../_utils/configUtils";
import { useSearch } from "../../../../hooks/useSearch";
import { getFlowTypeLabel, getStatusProperties, KycStatusMode } from "../../KycTranslationUtils";
import { useKycUIContext } from "./KycUIContext";

interface KycComplianceTableProps {
  userId: string;
}

export const KycComplianceTable = ({ userId }: KycComplianceTableProps): JSX.Element => {
  const { currentKycFlows } = useKyc();
  const { createKycEventMessage } = useKycEvent();
  const { session } = useSession();
  const { isCurrentlyLoading } = useLoading();
  const { fm } = useTranslation();
  const intl = useIntl();

  const { entitiesFiltered, setEntities, filters, setFilters } = useSearch();
  const { newKycModalButtonClick, optionsLeads } = useKycUIContext();

  const { groups } = useAppSelector((state) => ({
    groups: state.auth.groups,
  }));

  const columns: ColumnDescription[] = [
    {
      dataField: "leadName",
      text: fm("LEAD.TITLE.SINGLE"),
      classes: "text-left align-middle",
      sort: true,
      style: { minWidth: 250, maxWidth: 250 },
    },
    ...insertIf(isKycMultipleFlowTypesAvailable(), {
      dataField: "flowTypeLabel",
      text: fm(KycTranslation.FLOW_TYPE),
      sort: true,
      style: { minWidth: 80, maxWidth: 80 },
    }),
    {
      dataField: "statusLabel",
      text: fm("COMMON.STATUS"),
      formatter: KycStatusFormatter,
      sort: true,
      style: { minWidth: 160, maxWidth: 160 },
      formatExtraData: {
        fm: fm,
      },
    },
    {
      dataField: "createdAt",
      text: fm("COMMON.CREATED.AT"),
      sort: true,

      formatter: KycDateTimeFormatter,
      formatExtraData: { intl, showTime: true },
      style: { minWidth: 100, maxWidth: 100 },
    },
    {
      dataField: "updatedAt",
      text: fm("COMMON.UPDATED.AT"),
      sort: true,
      sortCaret: sortCaret,
      formatter: KycDateTimeFormatter,
      formatExtraData: { intl, showTime: true },
      style: { minWidth: 100, maxWidth: 100 },
    },
  ];

  if (
    canEdit(groups, session, "KYC_FLOW") ||
    canDelete(groups, session, "KYC_FLOW") ||
    currentKycFlows.filter((k) => k?.fileOwner?.id === session.id || isOwner(session, k)).length > 0
  ) {
    columns.push({
      dataField: "id",
      text: "",
      sort: true,
      sortCaret: sortCaret,
      classes: "text-right",
      formatter: (cell: string, row: KycFlow) => (
        <KycComplianceDropdownMenu kycId={cell} status={row.status} kycFlow={row} />
      ),
      headerStyle: { width: "auto" },
    });
  }

  const history = useHistory();

  useEffect(() => {
    if (isEmpty(filters)) {
      setFilters([{ ...KYC_LIST_TEXT_SEARCH_FILTER }]);
    }
  }, [filters]);

  const formatColumns = (entities: KycFlowListItem[]) => {
    return entities.map((entity) => {
      return {
        id: entity.id,
        leadName: entity.lead.name,
        flowType: entity.flowType,
        flowTypeLabel: getFlowTypeLabel(entity.flowType, fm),
        status: entity.status,
        statusLabel: getStatusProperties(entity.status, KycStatusMode.KYC_FLOW, fm)?.label,
        createdAt: entity.createdAt,
        updatedAt: entity.updatedAt,
      };
    });
  };

  useEffect(() => {
    setEntities(
      formatColumns(
        currentKycFlows.filter((flow) => kycAvailableFlowTypes()?.includes(flow.flowType))
      )
    );
  }, [currentKycFlows, intl?.locale]);

  return (
    <>
      <KycBreadcrumbs userId={userId} />
      <div style={{ marginTop: 0 }}>
        <PaginationProvider
          pagination={paginationFactory({
            ...defaultPaginationOptions,
            totalSize: entitiesFiltered?.length,
          })}
        >
          {({ paginationProps, paginationTableProps }) => {
            return (
              <Pagination
                isLoading={isCurrentlyLoading(KycLoading.CREATE_KYC_FLOW)}
                paginationProps={paginationProps}
              >
                <BootstrapTable
                  {...paginationTableProps}
                  {...defaultBootstrapTableOptions}
                  keyField="id"
                  data={entitiesFiltered}
                  columns={columns}
                  sort={{
                    dataField: "updatedAt",
                    order: "desc",
                    sortCaret: sortCaret,
                  }}
                  hover
                  rowEvents={{
                    onClick: (e, kyc) => {
                      createKycEventMessage(kyc?.id, KycStepEvent.OPEN_KYC_FORM, {});
                      history.push(`/clients/${userId}/kyc/${kyc?.id}`);
                    },
                  }}
                  noDataIndication={() => (
                    <EmptyState
                      entitiesLength={currentKycFlows.length}
                      entityUIContextAction={newKycModalButtonClick}
                      listLoading={isCurrentlyLoading(KycLoading.CREATE_KYC_FLOW)}
                      stateLocation="KYC"
                      disabled={
                        !optionsLeads ||
                        isEmpty(optionsLeads) ||
                        isEmpty(optionsLeads?.filter((option) => !option.isDisabled))
                      }
                    />
                  )}
                >
                  <PleaseWaitMessage entities={entitiesFiltered} />
                  <NoRecordsFoundMessage entities={entitiesFiltered} />
                </BootstrapTable>
              </Pagination>
            );
          }}
        </PaginationProvider>
      </div>
    </>
  );
};
