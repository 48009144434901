import { KycChoiceOption, KycRiskCheckItem } from "../../Kyc";
import { Field, FieldConfig } from "formik";
import { useTranslation } from "../../../../hooks/useTranslation";
import React from "react";
import { KycCheckbox } from "./kyc-checkbox/KycCheckbox";
import { useKycFlow } from "app/hooks/useKycFlow";

interface KycCheckboxListProps {
  field: FieldConfig;
  title?: string;
  options: KycChoiceOption[];
  disabled?: boolean;
}

export const KycCheckboxList = ({ field, title }: KycCheckboxListProps): JSX.Element => {
  const { fm } = useTranslation();
  const { saveField } = useKycFlow();

  return (
    <>
      <span style={{ fontSize: "1.1em" }}>{title && fm(title)}</span>
      {field?.value?.map((risk: KycRiskCheckItem, index: number) => {
        return (
          <Field
            key={"kyc-checkbox-" + risk.option + "-" + index}
            inline={false}
            name={`${field?.name}[${index}]`}
            component={KycCheckbox}
            onChange={(e: any) => {
              const objectToSave = { ...risk };
              if (e.source === "freeText") {
                objectToSave.freeText = e.target?.value;
              } else {
                objectToSave.isChecked = e.target?.checked;
              }
              saveField(e, {
                field: {
                  name: `${field?.name}[${index}]`,
                  value: objectToSave,
                },
                preventDefault: false,
              });
            }}
          />
        );
      })}
    </>
  );
};
