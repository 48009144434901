/* eslint-disable strict */

export enum NotificationTypes {
  EMAIL = "EMAIL",
  SMS = "SMS",
}

export const EMAIL = NotificationTypes.EMAIL;
export const SMS = NotificationTypes.SMS;

export const notificationTypes = [EMAIL, SMS];
