import React, { useMemo } from "react";
import { shallowEqual, useDispatch } from "react-redux";

import { useFormsUIContext } from "../FormsUIProvider";
import * as actions from "../../../_redux/forms/formsActions";
import { useAppSelector } from "../../../../../../redux/hooks";
import { KycAmlFilloutAcceptTerms } from "../../../../../_components/kyc-aml/KycAmlFilloutAcceptTerms";

export function AuthRequestKycAmlFilloutAcceptTerms({ previousStep, nextStep, breadcrumbs }) {
  const dispatch = useDispatch();

  const { action, actionsLoading } = useAppSelector(
    (state) => ({
      actionsLoading: state.forms.actionsLoading,
      action: state.forms.action?.current,
    }),
    shallowEqual
  );

  const formsUIContext = useFormsUIContext();
  const { formData, companyName } = useMemo(() => {
    return {
      formData: formsUIContext.formData,
      companyName: formsUIContext.companyName,
    };
  }, [formsUIContext]);

  const submit = (formDataUploadBody) => {
    dispatch(actions.uploadForm(formDataUploadBody, action.id, action.userId));
    nextStep();
  };

  return (
    <KycAmlFilloutAcceptTerms
      submit={submit}
      breadcrumbs={breadcrumbs}
      companyName={companyName}
      formData={formData}
      action={action}
      previousStep={previousStep}
      actionsLoading={actionsLoading}
    />
  );
}
