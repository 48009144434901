import { useIntl } from "react-intl";
import { shallowEqual } from "react-redux";

import { useSubheader } from "_metronic/layout";
import { LoadingDialog } from "_metronic/_partials/controls";

import { useAppSelector } from "redux/hooks";
import { formatDisplayNameIntl } from "app/_utils/userUtils";

import { UsersUIProvider } from "./UsersUIContext";
import { UsersCard } from "./users-table/UsersCard";
import { ENTITY_TYPE_PATH } from "../../../../_utils/listUtils";
import { canDeleteUser } from "../../../../_utils/authUtils";
import { useEntityDeleteDialogContext } from "../entity-delete-dialog/EntityDeleteDialogContext";
import * as actions from "../../../UsersManagement/_redux/usersActions";

export function UsersPage({ history, action, userType }) {
  const userTypePath = ENTITY_TYPE_PATH[userType];
  const { groups, session, isLoading } = useAppSelector(
    (state) => ({
      isLoading: state.users.listLoading,
      groups: state.auth.groups,
      session: state.auth.session,
    }),
    shallowEqual
  );
  const subheader = useSubheader();
  const intl = useIntl();

  const { setDeleteEntityDialog } = useEntityDeleteDialogContext();
  const uiEvents = {
    newEntityButtonClick: () => {
      history.push(`/${userTypePath}/new`);
    },
    openEntityDetailsPage: (user) => {
      subheader.setTitle(formatDisplayNameIntl(intl, user));
      history.push(`/${userTypePath}/${user.id}`);
    },
    openCreateLeadPage: (ids) => {
      history.push(`/leads/new?c=${ids}${history.location.search.replace("?", "&")}`);
    },
    openTasksPage: (ids) => {
      history.push(`/tasks`);
    },
    openRemoveUser: (id, uiProps) => {
      setDeleteEntityDialog({
        action: { fn: actions.deleteUserById, props: { context: uiProps.context, id } },
        entityType: uiProps.userType,
      });
    },
    canOpenRemoveUser: (row) => canDeleteUser(groups, session, row),
  };

  return (
    <UsersUIProvider uiEvents={uiEvents} action={action} userType={userType}>
      <LoadingDialog isLoading={isLoading} />
      <UsersCard />
    </UsersUIProvider>
  );
}
