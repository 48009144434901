import * as React from "react";
import { NumberInput } from "../../../_utils/formUtils";
import { MetricCardSubMetric, MetricCardSubValueProps } from "./MetricCardSubMetric";
import { NumberFormatProps } from "react-number-format";
import { isNumber } from "lodash-es";

export interface NumberMetricCardSubValueProps extends MetricCardSubValueProps {
  dataCy?: string;
  secondValue?: number | NumberFormatProps;
}

export const NumberMetricCardSubMetric: React.FC<NumberMetricCardSubValueProps> = ({
  dataCy,
  secondValue,
  label,
  labelValues,
  ...props
}) => {
  return (
    <MetricCardSubMetric
      label={label}
      labelValues={labelValues}
      value={
        <>
          <NumberInput
            data-cy={dataCy}
            displayType="text"
            className="font-weight-bold text-right mr-1"
            {...props}
          />
          {!!secondValue && (
            <>
              {"("}
              <NumberInput
                data-cy={dataCy + "-sub-metric"}
                displayType="text"
                className="font-weight-bold text-right"
                value={isNumber(secondValue) ? secondValue : secondValue.value}
                decimalScale={0}
                {...secondValue}
              />
              {")"}
            </>
          )}
        </>
      }
    />
  );
};
