import * as React from "react";
import { DebouncedFunc, isNumber, isUndefined } from "lodash-es";
import { BudgetLabel } from "./columns/BudgetLabel";
import { BudgetPlanned } from "./columns/BudgetPlanned";
import { NumberInput } from "../../../../_utils/formUtils";
import cn from "clsx";
import {
  BudgetLineColumnsTypes,
  budgetPercentageColumns,
  paymentStatusStyle,
  TInvoiceStatus,
} from "../../../../../data/schemas";
import { SUFFIX_EURO_CURRENCY, SUFFIX_PERCENTAGE } from "../../../../_utils/suffixUtils";
import { CellContext, ColumnDefTemplate } from "@tanstack/react-table";
import { BudgetTableRow } from "../BudgetEditExpenses";
import { FormattedMessage } from "react-intl";

export interface BudgetExpensesCellProps {
  customHandleChange: DebouncedFunc<(event: any) => void>;
  typedKey: BudgetLineColumnsTypes;
  disabled: boolean;
}

export const BudgetExpensesCell: ColumnDefTemplate<
  CellContext<BudgetTableRow, any> & BudgetExpensesCellProps
> = function ({ row, getValue, customHandleChange, typedKey, disabled }) {
  let initialValue = getValue();
  const [value, setValue] = React.useState(initialValue);
  React.useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  // Empty column render
  if (isUndefined(value)) {
    return "-";
  }

  // Specific column render for field inputs
  switch (typedKey) {
    case "label":
      return (
        <BudgetLabel
          row={row}
          value={value}
          disabled={disabled}
          customHandleChange={customHandleChange}
          setValue={setValue}
        />
      );
    case "planned":
      if (row.original.rowType === "BUDGET_LINE") {
        return (
          <BudgetPlanned
            row={row}
            value={value}
            disabled={disabled}
            customHandleChange={customHandleChange}
            setValue={setValue}
          />
        );
      }
      break;
    case "invoiceStatus":
      const invoiceStatus = paymentStatusStyle[value as TInvoiceStatus];
      return (
        <span
          className={cn(
            "label label-lg label-inline text-nowrap",
            `label-light-${invoiceStatus.color}`
          )}
        >
          <FormattedMessage id={invoiceStatus.translationKey} />
        </span>
      );
  }

  // Default column render
  if (isNumber(value)) {
    return (
      <NumberInput
        displayType="text"
        fixedDecimalScale={true}
        className={cn(
          "font-weight-bold text-right",
          ["realDifference", "realDifferencePercentage"].includes(typedKey) &&
            (value < 0 ? "text-success" : "text-danger"),
          typedKey === "remainingToInvoice" && value < 0 && "text-danger"
        )}
        value={value}
        decimalScale={2}
        suffix={
          budgetPercentageColumns.includes(typedKey) ? SUFFIX_PERCENTAGE : SUFFIX_EURO_CURRENCY
        }
      />
    );
  }
};
