import * as React from "react";
import { useIntl } from "react-intl";

import { LoaderSpinner } from "app/_components/LoaderSpinner";

import {
  useRequestSignatureDialogContext,
  RequestSignatureDialogUiState,
} from "./RequestSignatureDialogContext";

import { ViewSignaturesRequest } from "./ViewSignaturesRequest";
import { Signatories } from "./Signatories";
import { CancelSignaturesRequest } from "./CancelSignaturesRequest";

export const RequestSignatureDialogBody: React.FunctionComponent = () => {
  const intl = useIntl();
  const { uiState, currentSignaturesRequest, previousSignaturesRequest } =
    useRequestSignatureDialogContext();

  switch (uiState) {
    case RequestSignatureDialogUiState.VIEW:
    case RequestSignatureDialogUiState.DONE:
      return (
        <ViewSignaturesRequest {...{ uiState, signaturesRequest: currentSignaturesRequest! }} />
      );

    case RequestSignatureDialogUiState.EDIT:
      return (
        <>
          <ViewSignaturesRequest
            className="mb-6"
            {...{ uiState, signaturesRequest: previousSignaturesRequest! }}
          />
          <Signatories />
        </>
      );

    case RequestSignatureDialogUiState.CREATE:
      return <Signatories />;

    case RequestSignatureDialogUiState.CANCELLATION:
      return <CancelSignaturesRequest />;

    case RequestSignatureDialogUiState.LOADING:
      return (
        <div className="d-flex justify-content-center">
          <div className="d-flex flex-column align-items-center">
            <LoaderSpinner />
            <span className="mt-3">{intl.formatMessage({ id: "COMMON.LOADING" })}</span>
          </div>
        </div>
      );

    default:
      return <>{intl.formatMessage({ id: "COMMON.NOT_SPECIFIED" })}</>;
  }
};

export default RequestSignatureDialogBody;
