import { createContext, useState } from "react";
import PropTypes from "prop-types";
import { useIntl } from "react-intl";
import { useFilesContext } from "../modules/File/FilesProvider";
import { orderByLang } from "../_utils/fileUtils";

export interface FileViewerContextValue {
  openFileViewer: Function;
  downloadAndOpenFilesInViewer: (files: any[]) => void;
  closeFileViewer: Function;
  showFileViewer: boolean;
  currentFiles?: any[];
  setCurrentFiles: (files: any[]) => void;
  currentFileShown?: any;
  setCurrentFileShown: (file: any) => void;
  isLoading: boolean;
  setIsLoading: (loadingCondition: boolean) => void;
}

export const FileViewerContext = createContext<FileViewerContextValue>({
  openFileViewer: () => undefined,
  downloadAndOpenFilesInViewer: (files) => undefined,
  closeFileViewer: () => undefined,
  showFileViewer: false,
  currentFiles: [],
  setCurrentFiles: (files) => [],
  currentFileShown: undefined,
  setCurrentFileShown: (file: any) => {},
  isLoading: false,
  setIsLoading: (loadingCondition) => undefined,
});

export const FileViewerProvider = (props: { children: any }): JSX.Element => {
  const { children } = props;
  const intl = useIntl();
  const { loadFile } = useFilesContext();

  const [currentFiles, setCurrentFilesInternal] = useState<any[]>([]);
  const [currentFileShown, setCurrentFileShownInternal] = useState<any>();
  const [showFileViewer, setShowFileViewerInternal] = useState<boolean>(false);
  const [isLoading, setIsLoadingInternal] = useState<boolean>(false);

  const setCurrentFiles = (files?: any[]): void => {
    const orderedFiles = orderByLang(files ?? [], intl);
    setCurrentFileShownInternal(orderedFiles?.[0]);
    setCurrentFilesInternal(orderedFiles);
  };

  const setCurrentFileShown = (file?: any): void => {
    setCurrentFileShownInternal(file);
  };

  const openFileViewer = () => {
    setShowFileViewerInternal(true);
  };
  const closeFileViewer = () => {
    setShowFileViewerInternal(false);
    setCurrentFiles([]);
    setCurrentFileShown(undefined);
    setIsLoadingInternal(false);
  };

  const downloadAndOpenFilesInViewer = async (files: any[]) => {
    setCurrentFiles(files);
    openFileViewer();
    const filesDownloadPromises: any = files.map((file) => loadFile(file));
    const downloadedFiles = await Promise.all(filesDownloadPromises);
    setCurrentFiles(downloadedFiles);
  };

  const setIsLoading = (condition: boolean) => {
    setIsLoadingInternal(condition);
  };

  return (
    <FileViewerContext.Provider
      value={{
        openFileViewer,
        downloadAndOpenFilesInViewer,
        closeFileViewer,
        showFileViewer,
        currentFiles,
        setCurrentFiles,
        currentFileShown,
        setCurrentFileShown,
        isLoading,
        setIsLoading,
      }}
    >
      {children}
    </FileViewerContext.Provider>
  );
};

FileViewerProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export const FileViewerConsumer = FileViewerContext.Consumer;
