export const ProductStatusCssClasses = ["success", "info", ""];
export const defaultSorted = [{ dataField: "updatedAt", order: "desc" }];
export const sizePerPageList = [
  { text: "5", value: 5 },
  { text: "10", value: 10 },
  { text: "25", value: 25 },
  { text: "50", value: 50 },
];
export const initialFilters = {
  filterSelected: [],
  pageNumber: 1,
  pageSize: 25,
};
