import { globalSlice } from "./globalSlice";

const { actions } = globalSlice;

export const save = () => (dispatch) => {
  dispatch(actions.saving({}));
};

export const saved = () => (dispatch) => {
  dispatch(actions.saved({}));
};

export const setFromUrl = (fromUrl) => (dispatch) => {
  dispatch(actions.setFromUrl({ fromUrl }));
};

export const backToUrl =
  (urlsToBack = 1) =>
  (dispatch) => {
    dispatch(actions.backToUrl({ urlsToBack }));
  };

export const resetFromUrl = () => (dispatch) => {
  dispatch(actions.resetFromUrl({}));
};
