import * as React from "react";
import { FormattedMessage } from "react-intl";
import cn from "clsx";

import {
  UserEntityType,
  TUserEntityTypes,
  UserRightType,
  TUserRightTypes,
  IUserRight,
} from "data/schemas";

import {
  CRUD_RIGHTS_LIST,
  ADDITIONAL_RIGHTS_LIST,
  ADDITIONAL_RIGHTS_LIST_I18N_LABEL_KEY,
  RIGHT_USER_I18N_PREFIX_KEY,
} from "../definitions";
import { isAdminOrHasSessionRight } from "../../../../../../../_utils/authUtils";
import { useAppSelector } from "../../../../../../../../redux/hooks";

export type TUpdateRightFn = (selection: IUserRight) => void;

export interface UserRightsSelectionProps {
  label: string;
  entityType: TUserEntityTypes;
  updateRight: TUpdateRightFn;
  disabledFields: TUserRightTypes[];
  userRights: IUserRight[];

  readOnly?: boolean;
  isMaster?: boolean;
}

const filterDifference = (targetList: string[], avoidList: string[]) =>
  targetList.filter((check) => !avoidList.includes(check));

export const UserRightsSelectionInner: React.FunctionComponent<UserRightsSelectionProps> = ({
  label,
  entityType,
  updateRight,
  disabledFields = [],
  userRights,

  readOnly = false,
  isMaster = false,
}) => {
  const [crudList, additionalRightsList] = React.useMemo(() => {
    if (disabledFields.length === 0) return [CRUD_RIGHTS_LIST, ADDITIONAL_RIGHTS_LIST];

    return [
      filterDifference(CRUD_RIGHTS_LIST, disabledFields),
      filterDifference(ADDITIONAL_RIGHTS_LIST, disabledFields),
    ] as [TUserRightTypes[], TUserRightTypes[]];
  }, [disabledFields]);

  const { groups, session } = useAppSelector((state) => ({
    groups: state.auth.groups,
    session: state.auth.session,
  }));

  const isCheckDisabled = ({ rightType, entityType }: IUserRight) =>
    readOnly || !isAdminOrHasSessionRight(groups, session, entityType, rightType);
  const isChecked = (rightType: string) =>
    userRights.length === 0
      ? false
      : userRights.find((userRight) => {
          const { entityType: userEntityType, rightType: userRightType } = userRight;
          return (
            (userEntityType === UserEntityType.ALL && userRightType === UserRightType.ALL) ||
            (userEntityType === entityType && userRightType === rightType)
          );
        }) !== undefined;

  const checkChangeHandler =
    (selection: IUserRight) => (_: React.SyntheticEvent<HTMLInputElement>) => {
      updateRight(selection);
    };

  const mapRightOption = (rightType: TUserRightTypes) => (
    <div key={rightType} className="row mr-2 my-2">
      <label
        htmlFor={`checkbox-${entityType}-${rightType}`}
        style={{ cursor: !isCheckDisabled({ entityType, rightType }) ? "pointer" : "not-allowed" }}
        className={cn(
          "col-7 py-2 pl-7 m-0",
          isCheckDisabled({ entityType, rightType }) && "bg-gray-200 opacity-50"
        )}
      >
        <FormattedMessage id={`${RIGHT_USER_I18N_PREFIX_KEY}.${rightType}`} />
      </label>
      <label
        className={cn(
          "checkbox checkbox-single justify-content-end col-5 justify-content-center",
          isCheckDisabled({ entityType, rightType }) && "bg-gray-200 checkbox-disabled opacity-50"
        )}
      >
        <input
          id={`checkbox-${entityType}-${rightType}`}
          type="checkbox"
          disabled={isCheckDisabled({ entityType, rightType })}
          checked={isChecked(rightType)}
          onChange={checkChangeHandler({ entityType, rightType })}
        />
        <span />
      </label>
    </div>
  );

  return (
    <div className="col-lg-4 border-top mx-10 mt-10 mb-4 m-lg-0">
      <div className="form-group">
        <div className="row">
          <label className="h4 p-3 w-100 font-weight-bold">{label}</label>
        </div>

        {crudList.map(mapRightOption)}

        {additionalRightsList.length > 0 && (
          <>
            <label className="mt-8 mb-2 w-100 h6">
              <FormattedMessage id={ADDITIONAL_RIGHTS_LIST_I18N_LABEL_KEY} />
            </label>

            {additionalRightsList.map(mapRightOption)}
          </>
        )}
      </div>
    </div>
  );
};

export const UserRightsSelection = React.memo(UserRightsSelectionInner);

export default UserRightsSelection;
