import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { Button, Modal } from "react-bootstrap";
import { useTranslation } from "../../../../../hooks/useTranslation";
import { KycChoiceOption, KycConfirmationDialogConfiguration, KycFlow } from "../../../Kyc";
import { KycButton } from "../../steps/KycButton";
import cn from "clsx";
import { useLoading } from "../../../../../hooks/useLoading";
import { KycDropDownSelectRHF } from "./KycDropDownSelectRHF";
import { KYC_V2_OPTIONS_FLOW_TYPE } from "../../../KycTranslationUtils";
import { set } from "lodash-es";
import { useKyc } from "../../../../../hooks/useKyc";
import { mapKycClient } from "../../../KycMappingUtils";
import { useAppSelector } from "../../../../../../redux/hooks";
import { shallowEqual } from "react-redux";
import { FORM_INIT, getLangShort, isFlowUnfinished } from "../../../KycUtils";
import { useSession } from "../../../../../hooks/useSession";
import { v4 as uuid } from "uuid";
import { useIntl } from "react-intl";
import { KycTranslation } from "../../../KycTranslation";
import {
  isKycMultipleFlowTypesAvailable,
  kycAvailableFlowTypes,
} from "../../../../../_utils/configUtils";
import { useKycUIContext } from "../KycUIContext";

type FormData = {
  lead: any;
  flowType: any;
};

export interface KycConfirmationModalProps {
  config?: KycConfirmationDialogConfiguration;
  [rest: string]: any;
}

export const KycCreateDialog = ({
  config,
  size,
  optionsLeads,
}: KycConfirmationModalProps): JSX.Element => {
  const { control, handleSubmit, reset, watch, setValue } = useForm<FormData>();
  const { fm, fDisplayName } = useTranslation();
  const { isCurrentlyLoading } = useLoading();
  const { currentKycFlows } = useKyc();
  const { createKycFlow } = useKyc();
  const { showKycModal, closeKycModal } = useKycUIContext();

  const formValues = watch();

  const intl = useIntl();

  const { originalEntityForEdit } = useAppSelector(
    (state) => ({
      originalEntityForEdit: state?.users?.entityForEdit.saved,
    }),
    shallowEqual
  );

  const formatOptionsFlowType = (options: any) => {
    return options.map((option: KycChoiceOption) => {
      return {
        ...option,
        label: fm(option.label),
        isDisabled: isFlowUnfinished(currentKycFlows, formValues?.lead?.value?.id, option?.value),
      };
    });
  };
  const { session } = useSession();

  const onSubmit = async (data: FormData) => {
    if (!originalEntityForEdit) {
      return;
    }

    const initForm: KycFlow = { ...FORM_INIT(fDisplayName, fm, session) };
    const kycClient = mapKycClient(originalEntityForEdit, fDisplayName, data?.lead?.value);

    initForm.form.client = kycClient;
    initForm.flowType = isKycMultipleFlowTypesAvailable()
      ? data?.flowType?.value
      : kycAvailableFlowTypes()?.[0];

    set(initForm, "id", uuid());
    set(initForm, "form.client", kycClient);
    set(initForm, "form.reference", kycClient?.lead?.name);
    set(initForm, "form.transactionAmount", kycClient?.lead?.sellingPrice);
    set(initForm, "lang", getLangShort(intl.locale) ?? "en");

    createKycFlow(initForm);
  };

  const isLoading = isCurrentlyLoading(config?.isLoading, config?.isLoadingId ?? "");

  const handleCancel = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    reset();
    closeKycModal?.();
  };

  useEffect(() => {
    if (formValues.flowType !== "" && isKycMultipleFlowTypesAvailable()) {
      reset({ ...formValues, flowType: "" });
    }
  }, [formValues?.lead]);

  useEffect(() => {
    if (!isKycMultipleFlowTypesAvailable()) {
      setValue("flowType", kycAvailableFlowTypes()?.[0]);
    }
  }, []);

  return (
    <Modal show={showKycModal} centered backdrop="static" size={size ?? "md"}>
      <Modal.Header closeButton>
        <Modal.Title id="example-modal-sizes-title-lg">
          {config?.labelTitle && fm(config?.labelTitle)}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form>
          <KycDropDownSelectRHF
            label={KycTranslation.SELECT_LEAD}
            control={control}
            name="lead"
            options={optionsLeads}
          />
          {isKycMultipleFlowTypesAvailable() && (
            <KycDropDownSelectRHF
              className="mt-5"
              isDisabled={!formValues.lead}
              label={KycTranslation.SELECT_FLOW_TYPE}
              control={control}
              name="flowType"
              options={formatOptionsFlowType([...KYC_V2_OPTIONS_FLOW_TYPE])}
            />
          )}
        </form>
      </Modal.Body>

      <Modal.Footer>
        {!isLoading && showKycModal ? (
          <div>
            <Button
              onClick={handleCancel}
              className="btn btn-light"
              disabled={isLoading || config?.disabled}
            >
              {fm("COMMON.ACTION.CANCEL")}
            </Button>
            <KycButton
              data-cy="button-kyc-confirm"
              onClick={handleSubmit(onSubmit)}
              className={cn(
                `btn btn-${config?.colorActionButton ?? "light"} ml-2`,
                config?.isLoading && "hidden"
              )}
              disabled={isLoading || !formValues.lead || !formValues.flowType}
              loadingMessage={
                config?.labelLoading ? config?.labelLoading : config?.labelActionButton
              }
              isLoading={isCurrentlyLoading(config?.isLoading)}
              label={config?.labelActionButton ?? ""}
            />
          </div>
        ) : (
          <div className="d-flex align-items-center">
            <div className="mr-2 text-muted">{fm("COMMON.LOADING")}</div>
            <div className="spinner spinner-primary mr-10" />
          </div>
        )}
      </Modal.Footer>
    </Modal>
  );
};
