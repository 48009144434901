/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import { useSelector } from "react-redux";
import { FormattedMessage } from "react-intl";
import { useHistory } from "react-router-dom";

export function LeadsCounterWidget({ className, iconColor = "success", widgetHeight = "150px" }) {
  const { isLoading, counter } = useSelector((state) => ({
    isLoading: state.dashboard.countersLoading,
    counter:
      state.dashboard?.counters?.leadCounters?.find((c) => c.counterType === "TOTAL")?.value || 0,
  }));

  const history = useHistory();
  return (
    <div
      role="button"
      className={`card card-custom ${className}`}
      style={{ height: widgetHeight }}
      onClick={() => history.push("/leads")}
    >
      <div className="card-body">
        <span className={`svg-icon svg-icon-3x svg-icon-${iconColor}`}>
          <SVG src={toAbsoluteUrl("/media/svg/icons/Communication/Chat2.svg")} />
        </span>
        <div className="text-dark font-weight-bolder font-size-h2 mt-3">
          {isLoading ? (
            <span className="spinner spinner-lg spinner-success mr-12" />
          ) : (
            <span>{counter}</span>
          )}
        </div>
        <div className="text-muted text-hover-primary font-weight-bold font-size-lg mt-1">
          <FormattedMessage id="LEAD.TITLE" />
        </div>
      </div>
    </div>
  );
}
