import * as React from "react";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../../../../../_metronic/_helpers";
import { FormattedMessage, useIntl } from "react-intl";
import { DateUtils } from "../../../../../../../_utils/DateUtils";
import { NumberInput } from "../../../../../../../_utils/formUtils";
import { SUFFIX_EURO_CURRENCY } from "../../../../../../../_utils/suffixUtils";
import { ILead, ISettings } from "../../../../../../../../data/schemas";
import { useLeadContext } from "../../../lead-edit/LeadContext";

interface LeadInvoiceReducedVatInfoProps {
  lead: ILead;
  settings: ISettings;
  currentConstructionPriceWithReducedVat?: number;
}
export const LeadInvoiceReducedVatInfo: React.FC<LeadInvoiceReducedVatInfoProps> = ({
  lead,
  settings,
  currentConstructionPriceWithReducedVat,
}) => {
  const intl = useIntl();
  const { usedConstructionPriceWithReducedVat, initialCredit } = useLeadContext();

  const currentRemainingConstructionPriceWithReducedVat =
    (lead.constructionPriceWithReducedVat ?? 0) -
    (currentConstructionPriceWithReducedVat ?? usedConstructionPriceWithReducedVat);

  return (
    <>
      <div className={"mb-4"}>
        <span className="svg-icon svg-icon-lg svg-icon-info mr-2">
          <SVG src={toAbsoluteUrl("/media/svg/icons/Code/Info-circle.svg")} />
        </span>
        <FormattedMessage
          id={"LEAD.CLIENT_ELIGIBLE_REDUCED_VAT"}
          values={{ count: lead.users.length }}
        />
      </div>
      <div className={"d-flex flex-column mb-2"}>
        <div className={"d-flex mr-5"}>
          <div className={"d-flex flex-column"} style={{ width: "50%" }}>
            <span>
              <FormattedMessage id={"LEAD.AUTHORISATION_CODE"} />
            </span>
            <span className={"mb-2"}>
              <FormattedMessage id={"LEAD.AUTHORISATION_DATE"} />
            </span>
            <span>
              <FormattedMessage id={"LEAD.INITIAL_CREDIT"} />
            </span>
            <span className={"mb-2"}>
              <FormattedMessage id={"LEAD.REMAINING_CREDIT_AFTER_INVOICE"} />
            </span>
            <span>
              <FormattedMessage id={"LEAD.CONSTRUCTION_PRICE_WITH_REDUCED_VAT"} />
            </span>
            <span>
              <FormattedMessage id={"LEAD.REMAINING_CONSTRUCTION_PRICE_WITH_REDUCED_VAT"} />
            </span>
          </div>
          <div className={"d-flex flex-column"}>
            <span className={"ml-6 font-weight-bold"}>{lead.authorisationCode}</span>
            <span className={"ml-6 font-weight-bold mb-2"}>
              {DateUtils.format(new Date(lead.authorisationDate!), intl, false)}
            </span>
            <span className={"ml-6 font-weight-bold"}>
              <NumberInput
                data-cy="input-lead-invoice-initial-credit"
                value={initialCredit}
                displayType="text"
                suffix={SUFFIX_EURO_CURRENCY}
                decimalScale={2}
                fixedDecimalScale={true}
              />
            </span>
            <span className={"ml-6 font-weight-bold mb-2"}>
              <NumberInput
                data-cy="input-lead-invoice-remaining-credit-after-invoice"
                value={
                  currentRemainingConstructionPriceWithReducedVat < 0
                    ? 0
                    : currentRemainingConstructionPriceWithReducedVat *
                      ((settings.defaultVat ?? 0) - (settings.reducedVat ?? 0))
                }
                displayType="text"
                suffix={SUFFIX_EURO_CURRENCY}
                decimalScale={2}
                fixedDecimalScale={true}
              />
            </span>
            <span className={"ml-6 font-weight-bold"}>
              <NumberInput
                data-cy="input-lead-invoice-construction-price-with-reduced-vat"
                value={lead.constructionPriceWithReducedVat ?? 0}
                displayType="text"
                suffix={SUFFIX_EURO_CURRENCY}
                decimalScale={2}
                fixedDecimalScale={true}
              />
            </span>
            <span className={"ml-6 font-weight-bold"}>
              <NumberInput
                data-cy="input-lead-invoice-remaining-construction-price-with-reduced-vat"
                value={
                  currentRemainingConstructionPriceWithReducedVat < 0
                    ? 0
                    : currentRemainingConstructionPriceWithReducedVat
                }
                displayType="text"
                suffix={SUFFIX_EURO_CURRENCY}
                decimalScale={2}
                fixedDecimalScale={true}
              />
            </span>
          </div>
        </div>
      </div>
    </>
  );
};
