import React from "react";
import { KycStepPropDevDisclaimer } from "app/modules/kyc-v2/components/steps/KycStepPropDevDisclaimer";
import { KycStepAdminPage1 } from "app/modules/kyc-v2/components/steps/KycStepAdminPage1";
import { KycStepAdminPage2 } from "app/modules/kyc-v2/components/steps/KycStepAdminPage2";
import { KycStepClientPage1 } from "app/modules/kyc-v2/components/steps/KycStepClientPage1";
import { KycStepClientPage3 } from "app/modules/kyc-v2/components/steps/KycStepClientPage3";
import { KycStepClientPage2 } from "app/modules/kyc-v2/components/steps/KycStepClientPage2";

import { v4 as uuid } from "uuid";

import {
  Address,
  Agent,
  BeneficialOwner,
  KycBoType,
  KycChoiceOption,
  KycClientLegal,
  KycClientNatural,
  KycEntityType,
  KycFile,
  KycFlow,
  KycFlowListItem,
  KycFlowStatus,
  KycFlowType,
  KycForm,
  KycGeoRisk,
  KycLegalEntityType,
  KycLegalType,
  KycNotification,
  KycNotificationDestination,
  KycPerson,
  KycRelatedToEntity,
  KycRiskAssessmentStatus,
  KycRiskLevel,
  KycSignatory,
  KycStep,
  KycStepEvent,
  KycWorkflow,
  Representative,
  ShareholderCompany,
} from "app/modules/kyc-v2/Kyc";
import { KycTranslation } from "./KycTranslation";
import { KycStepClientPage4 } from "./components/steps/KycStepClientPage4";
import { KycStepSuccess } from "./components/steps/KycStepSuccess";
import { NotificationTypes } from "../../_utils/notificationTypes";
import { KycStepRiskEvaluation } from "./components/steps/KycStepRiskEvaluation";
import { KycStepStartFlow } from "./components/steps/KycStepStartFlow";
import { KycStepRejectAndRequestFormCompletion } from "./components/steps/KycStepRejectAndRequestFormCompletion";
import { KycStepStartWithNote } from "./components/steps/KycStepStartWithNote";
import {
  allOptions,
  getFlowTypeLabel,
  getTranslationCategory,
  KYC_V2_OPTIONS_CLIENT_ACTIVITIES_RISK,
  KYC_V2_OPTIONS_CLIENT_RISK,
  KYC_V2_OPTIONS_CLIENT_RISK_ADDITIONAL,
  KYC_V2_OPTIONS_GEO_COUNTRY_RISK_HIGHER_RISK,
  KYC_V2_OPTIONS_GEO_COUNTRY_RISK_LOWER_RISK,
} from "./KycTranslationUtils";
import { concat, difference, find, maxBy, remove } from "lodash-es";
import { canEdit } from "../../_utils/authUtils";
import { isEvidenceOptionSet } from "./KycFileUtils";
import { IntlShape } from "react-intl";
import { StepsContent } from "app/contexts/WizardContext";
import { isKycMultipleFlowTypesAvailable, kycAvailableFlowTypes } from "../../_utils/configUtils";

const getChoiceOptionsAsRiskItem = (choiceOptions: KycChoiceOption[]) => {
  return [
    ...choiceOptions?.map((risk: KycChoiceOption) => {
      return {
        option: risk,
        isChecked: false,
        freeText: risk?.value?.option === "other" ? "" : undefined,
      };
    }),
  ];
};

export const flattenObject = (ob: any) => {
  const toReturn: any = {};

  for (const i in ob) {
    if (!ob.hasOwnProperty(i)) continue;

    if (typeof ob[i] == "object") {
      const flatObject: any = flattenObject(ob?.[i]);
      for (const x in flatObject) {
        if (!flatObject.hasOwnProperty(x)) continue;

        toReturn[i + "." + x] = flatObject[x];
      }
    } else {
      toReturn[i] = ob[i];
    }
  }
  return toReturn;
};

export const getStepsForFlowDefinition = (flowDefinition: KycStep[]) => {
  if (flowDefinition) {
    return flowDefinition?.map((stepId) => WIZARD_STEP_DEFINITION[stepId]);
  }
};

export const getContentForStep = (stepId: string) => {
  switch (stepId) {
    case "STEP_START_FLOW":
      return <KycStepStartFlow />;
    case "STEP_DISCLAIMER":
      return <KycStepPropDevDisclaimer />;
    case "STEP_ADMIN_PAGE_1":
      return <KycStepAdminPage1 />;
    case "STEP_ADMIN_PAGE_2":
      return <KycStepAdminPage2 />;
    case "STEP_CLIENT_PAGE_1":
      return <KycStepClientPage1 />;
    case "STEP_CLIENT_PAGE_2":
      return <KycStepClientPage2 />;
    case "STEP_CLIENT_PAGE_3":
      return <KycStepClientPage3 />;
    case "STEP_CLIENT_PAGE_4":
      return <KycStepClientPage4 />;
    case "STEP_SUCCESS":
      return <KycStepSuccess />;
    case "STEP_RISK_EVALUATION":
      return <KycStepRiskEvaluation />;
    case "STEP_REJECT_AND_REQUEST_FORM_COMPLETION":
      return <KycStepRejectAndRequestFormCompletion />;
    case "STEP_START_WITH_NOTE":
      return <KycStepStartWithNote />;
  }
};

export const getTranslationForLegalType = (legalType: string) => {
  switch (legalType) {
    case KycLegalType.LEGAL_TYPE_LU_OR_FOREIGN_COMPANY:
    case KycLegalType.LEGAL_TYPE_LISTED_COMPANY:
      return KycTranslation.EVIDENCE_DOCUMENT_NAME_COMPANY_STATUTES_LUC_FC_LC;
    case KycLegalType.LEGAL_TYPE_INVESTMENT_FUND:
      return KycTranslation.EVIDENCE_DOCUMENT_NAME_COMPANY_STATUTES_INVESTMENT_FUND;
    case KycLegalType.LEGAL_TYPE_TRUST:
      return KycTranslation.EVIDENCE_DOCUMENT_NAME_COMPANY_STATUTES_TRUST;
    default:
      return;
  }
};

export const WIZARD_STEP_DEFINITION: Record<KycStep, StepsContent> = {
  STEP_START_FLOW: {
    id: KycStep.STEP_START_FLOW,
    title: KycTranslation.STEPS_DISCLAIMER_TITLE,
  },
  STEP_DISCLAIMER: {
    id: KycStep.STEP_DISCLAIMER,
    title: KycTranslation.STEPS_DISCLAIMER_TITLE,
  },
  STEP_ADMIN_PAGE_1: {
    id: KycStep.STEP_ADMIN_PAGE_1,
    title: KycTranslation.STEPS_ADMIN_PAGE_1_TITLE,
  },
  STEP_ADMIN_PAGE_2: {
    id: KycStep.STEP_ADMIN_PAGE_2,
    title: KycTranslation.STEPS_ADMIN_PAGE_2_TITLE_COMPLETE_BY_CLIENT,
  },
  STEP_CLIENT_PAGE_1: {
    id: KycStep.STEP_CLIENT_PAGE_1,
    title: KycTranslation.STEPS_CLIENT_PAGE_1_TITLE,
  },
  STEP_CLIENT_PAGE_2: {
    id: KycStep.STEP_CLIENT_PAGE_2,
    title: KycTranslation.STEPS_CLIENT_PAGE_2_TITLE,
  },
  STEP_CLIENT_PAGE_3: {
    id: KycStep.STEP_CLIENT_PAGE_3,
    title: KycTranslation.STEPS_CLIENT_PAGE_3_TITLE,
  },
  STEP_CLIENT_PAGE_4: {
    id: KycStep.STEP_CLIENT_PAGE_4,
    title: KycTranslation.STEPS_CLIENT_PAGE_4_TITLE,
  },
  STEP_SUCCESS: {
    id: KycStep.STEP_SUCCESS,
    title: "BREADCRUMB.SUCCESS",
  },
  STEP_RISK_EVALUATION: {
    id: KycStep.STEP_RISK_EVALUATION,
    title: KycTranslation.STEPS_RISK_EVALUATION_TITLE,
  },
  STEP_BO_SIGNATURE_REQUEST: {
    id: KycStep.STEP_BO_SIGNATURE_REQUEST,
    title: KycTranslation.STEPS_BO_SIGNATURE_REQUEST_TITLE,
  },
  STEP_REJECT_AND_REQUEST_FORM_COMPLETION: {
    id: KycStep.STEP_REJECT_AND_REQUEST_FORM_COMPLETION,
    title: KycTranslation.STEPS_REJECT_AND_REQUEST_FORM_COMPLETION_TITLE,
  },
  STEP_START_WITH_NOTE: {
    id: KycStep.STEP_START_WITH_NOTE,
    title: KycTranslation.STEPS_REJECT_AND_REQUEST_FORM_COMPLETION_TITLE,
  },
};

export const ADDRESS: Address = {
  addressLine1: "",
  addressLine2: "",
  city: "",
  stateOrRegion: "",
  postalCode: "",
  countryCode: "",
  country: "",
};

export const KYC_USER = {
  id: "",
  title: "",
  firstName: "",
  lastName: "",
  displayName: "",
  privateAddress: { ...ADDRESS },
  email: "",
  mobile: "",
};

export const KYC_COMPANY = {
  name: "",
  vatNumber: "",
  registrationNumber: "",
  nationalRegistrationNumber: "",
};

export const KYC_PERSON: KycPerson = {
  ...KYC_USER,
  nationality: "",
  dateOfBirth: "",
  placeOfBirth: "",
  countryOfBirth: "",
  passportOrIdNumber: "",
  registrationNumber: "",
  profession: "",
  idValidityDate: "",
  isPep: "",
  publicMandate: "",
  disclaimerCorrectness: {
    isAccepted: false,
    acceptedDate: "",
  },
  mobile: "",
  email: "",
};

export const KYC_REPRESENTATIVE: Representative = {
  ...KYC_PERSON,
};

export const KYC_AGENT: Agent = {
  ...KYC_PERSON,
  companyName: "",
  // evidenceLetterOfEngagement: [{ ...KYC_ATTACHMENT }],
  isRepresentingClient: false,
};

export const KYC_EXTERNAL_COMPANY: ShareholderCompany = {
  id: uuid(),
  name: "",
  legalForm: "",
  countryCodeEstablishment: "",
  address: { ...ADDRESS },
  nameOfTradeRegister: "",
  numberInTradeRegister: "",
};

export const KYC_BENEFICIAL_OWNER: BeneficialOwner = {
  ...KYC_PERSON,
  id: "",
  profession: "",
  professionalAddress: { ...ADDRESS },
  isBigOwner: "false",
  isShareholderOfClientCompany: true,
  externalCompanies: [],
  numberOfSharesInEntity: 0,
  nominalValue: 0,
  nominalValueCurrency: "EUR",
  percentageOfShareCapital: 0,
  jobTitle: "",
};

export const KYC_CLIENT_NATURAL: KycClientNatural = {
  legalEntityType: KycLegalEntityType.NATURAL,
  ...KYC_PERSON,
  lead: null,
  registrationNumber: "",
  profession: "",
  professionalAddress: { ...ADDRESS },
  isMarried: "",
  isMarriedMatrimonialRegime: "",
  isMarriedAcquisitionMartialHome: "",
  isRepresentedByAgent: "",
  agents: [],
};

export const KYC_CLIENT_LEGAL: KycClientLegal = {
  ...KYC_USER,
  lead: null,
  legalEntityType: null,
  legalType: null,
  name: "",
  legalForm: "",
  company: { ...KYC_COMPANY },
  website: "",
  representatives: [],
  beneficialOwners: [],
  isBigOwner: "false",
  isRepresentedByAgent: "",
  agents: [],
};

export const KYC_FORM = (fm?: any): KycForm => {
  return {
    client: { ...KYC_CLIENT_LEGAL, ...KYC_CLIENT_NATURAL },
    reference: "",
    referenceId: "",
    transactionDate: undefined,
    dateOfAcquisition: undefined,
    transactionAmount: 0,
    purposeAndNature: "",
    clientIs: fm ? fm(KycTranslation.CLIENT_IS_VALUE) : KycTranslation.CLIENT_IS_VALUE,
    counterparty: fm ? fm(KycTranslation.COUNTERPARTY_VALUE) : KycTranslation.COUNTERPARTY_VALUE,
    workflowChoice: KycWorkflow.FLOW_KYC_WORKFLOW_COMPLETE_BY_CLIENT,
    isClientPresent: "false",
    isClientCanUpdate: "false",
    acceptTerms: { isAccepted: false, isNextClicked: false, acceptedAt: "" },
    originOfFunds: "",
    accurateDescriptionOfOriginOfFunds: "",
    sellingPrice: 0,
    notes: "",
  };
};

export const KYC_GEO_RISK: KycGeoRisk = {
  countryCode: "",
  lowerRisk: getChoiceOptionsAsRiskItem(KYC_V2_OPTIONS_GEO_COUNTRY_RISK_LOWER_RISK),
  higherRisk: getChoiceOptionsAsRiskItem(KYC_V2_OPTIONS_GEO_COUNTRY_RISK_HIGHER_RISK),
};

export const KYC_NOTIFICATION: KycNotification = {
  deadline: "",
  methodsUsed: [NotificationTypes.EMAIL],
  isRequestSent: false,
  sentAt: "",
};

export const statusAsArray = [
  KycFlowStatus.INIT,
  KycFlowStatus.CREATED,
  KycFlowStatus.ADMIN_FILLING_IN,
  KycFlowStatus.CLIENT_REVIEW_PENDING,
  KycFlowStatus.CLIENT_REJECT_AND_REVIEW_PENDING,
  KycFlowStatus.CLIENT_REVIEW_DONE,
  KycFlowStatus.ADMIN_RISK_ASSESSMENT_PENDING,
  KycFlowStatus.ADMIN_RISK_ASSESSMENT_DECLINED,
  KycFlowStatus.ADMIN_RISK_ASSESSMENT_VALIDATED,
  KycFlowStatus.ADMIN_RISK_ASSESSMENT_DONE,
  KycFlowStatus.BO_DECLARATIONS_SIGNATURE_PENDING,
  KycFlowStatus.BO_DECLARATIONS_SIGNATURE_DONE,
  KycFlowStatus.KYC_APPROVED,
  KycFlowStatus.KYC_REJECTED,
  KycFlowStatus.CANCELED,
];

export const FORM_INIT = (fDisplayName?: any, fm?: any, session?: any): KycFlow => {
  return {
    eventId: "-1",
    beneficialOwners: [],
    id: "0",
    form: { ...KYC_FORM(fm) },
    fileOwner: {
      id: session?.id ?? "",
      firstName: session?.firstName ?? "",
      lastName: session?.lastName ?? "",
      title: session?.title ?? "",
      displayName: fDisplayName
        ? fDisplayName(session, true)
        : `${session?.firstName} ${session?.lastName}`,
      mobile: session?.mobile ?? "",
      email: session?.email ?? "",
    },
    events: [],
    notification: { ...KYC_NOTIFICATION },
    riskEvaluation: {
      isPep: "",
      negativeWebInfo: "",
      amlKycAdditionalTool: {
        isUsed: "",
        nameOfTool: "",
      },
      clientRisks: getChoiceOptionsAsRiskItem(KYC_V2_OPTIONS_CLIENT_RISK),
      clientRisksAdditional: getChoiceOptionsAsRiskItem(KYC_V2_OPTIONS_CLIENT_RISK_ADDITIONAL),
      clientRiskDetermination: {
        riskLevel: KycRiskLevel.NONE,
      },
      geographicRiskDetermination: {
        registeredOfficeClient: { ...KYC_GEO_RISK },
        registeredOfficeBo: { ...KYC_GEO_RISK },
        placeOfBusinessClient: { ...KYC_GEO_RISK },
        placeOfBusinessBo: { ...KYC_GEO_RISK },
        riskLevel: KycRiskLevel.NONE,
      },
      clientRisksActivity: getChoiceOptionsAsRiskItem(KYC_V2_OPTIONS_CLIENT_ACTIVITIES_RISK),
      clientRisksActivityDomain: "",
      clientActivityRiskDetermination: {
        riskLevel: KycRiskLevel.NONE,
      },
      riskLevel: KycRiskLevel.NONE,
      remarks: "",
    },
    updatedAt: "",
    clientId: "",
    status: KycFlowStatus.INIT,
    taskId: "",
    link: "",
    internalNotes: [],
    clientNotes: [],
    isDirty: false,
    signatures: [],
    signatories: [],
    propDevCompany: {
      id: "MAIN_COMPANY",
    },
    files: [],
    kycDocument: {},
    lang: "en",
    riskAssessmentStatus: KycRiskAssessmentStatus.INIT,
    fieldsUpdateHistory: {},
    kycFlowCompanies: [],
    flowType: undefined,
  };
};

export const getFlowDefinition = (
  values: KycFlow,
  isClientViewer: boolean,
  isFileOwnerViewer: boolean,
  setIsReadOnly: any,
  setActiveStep: any,
  setShowControls: any,
  groups: any,
  session: any
) => {
  const status = values?.status;
  const workflowChoice = values?.form?.workflowChoice;

  const CREATED_COMPLETED_BY_CLIENT = [
    KycStep.STEP_DISCLAIMER,
    KycStep.STEP_ADMIN_PAGE_1,
    KycStep.STEP_ADMIN_PAGE_2,
  ];

  const CREATED_COMPLETED_BY_ADMIN = [
    KycStep.STEP_DISCLAIMER,
    KycStep.STEP_ADMIN_PAGE_1,
    KycStep.STEP_CLIENT_PAGE_1,
    KycStep.STEP_CLIENT_PAGE_2,
    KycStep.STEP_CLIENT_PAGE_3,
    KycStep.STEP_ADMIN_PAGE_2,
  ];

  const READ_ADMIN = [
    KycStep.STEP_ADMIN_PAGE_1,
    KycStep.STEP_CLIENT_PAGE_1,
    KycStep.STEP_CLIENT_PAGE_2,
    KycStep.STEP_CLIENT_PAGE_3,
  ];

  const REVIEW_CLIENT = [
    KycStep.STEP_ADMIN_PAGE_1,
    KycStep.STEP_CLIENT_PAGE_1,
    KycStep.STEP_CLIENT_PAGE_2,
    KycStep.STEP_CLIENT_PAGE_3,
    KycStep.STEP_CLIENT_PAGE_4,
  ];

  const VALIDATION_ADMIN_ACCEPTED = [
    KycStep.STEP_ADMIN_PAGE_1,
    KycStep.STEP_CLIENT_PAGE_1,
    KycStep.STEP_CLIENT_PAGE_2,
    KycStep.STEP_CLIENT_PAGE_3,
  ];

  const VALIDATION_ADMIN_DECLINED = [
    KycStep.STEP_ADMIN_PAGE_1,
    KycStep.STEP_CLIENT_PAGE_1,
    KycStep.STEP_CLIENT_PAGE_2,
    KycStep.STEP_CLIENT_PAGE_3,
    KycStep.STEP_REJECT_AND_REQUEST_FORM_COMPLETION,
  ];

  const SUCCESS = [KycStep.STEP_SUCCESS];

  if (!isClientViewer) {
    if (!canEdit(groups, session, "KYC_FLOW")) {
      setIsReadOnly(true);
      return VALIDATION_ADMIN_ACCEPTED;
    } else {
      switch (status) {
        case KycFlowStatus.INIT:
        case KycFlowStatus.CREATED:
        case KycFlowStatus.ADMIN_FILLING_IN:
          setIsReadOnly(false);
          return workflowChoice === KycWorkflow.FLOW_KYC_WORKFLOW_COMPLETE_BY_ADMIN
            ? CREATED_COMPLETED_BY_ADMIN
            : CREATED_COMPLETED_BY_CLIENT;
        case KycFlowStatus.CLIENT_REVIEW_PENDING:
          setIsReadOnly(true);
          return READ_ADMIN;
        case KycFlowStatus.CLIENT_REJECT_AND_REVIEW_PENDING:
          setIsReadOnly(true);
          setActiveStep(0);
          return READ_ADMIN;
        case KycFlowStatus.CLIENT_REVIEW_DONE:
          setIsReadOnly(false);
          return VALIDATION_ADMIN_ACCEPTED;
        case KycFlowStatus.ADMIN_RISK_ASSESSMENT_PENDING:
          setIsReadOnly(false);
          return VALIDATION_ADMIN_ACCEPTED;
        case KycFlowStatus.ADMIN_RISK_ASSESSMENT_VALIDATED:
          // NOTE: when set to false, case manager will still be able to fill in form until signature
          // requests have been sent out. Might be useful in near future, as it's likely that our customers
          // will ask to further unlock form until signature requests are sent out. This is actually not an issue,
          // but hasn't been done as it was requested to have it deactivated in ticket: https://kodehyve.atlassian.net/browse/IMT-1699
          setIsReadOnly(true);
          return VALIDATION_ADMIN_ACCEPTED;
        case KycFlowStatus.ADMIN_RISK_ASSESSMENT_DECLINED:
          setIsReadOnly(true);
          return VALIDATION_ADMIN_DECLINED;
        case KycFlowStatus.ADMIN_RISK_ASSESSMENT_DONE:
        case KycFlowStatus.BO_DECLARATIONS_SIGNATURE_PENDING:
        case KycFlowStatus.BO_DECLARATIONS_SIGNATURE_DONE:
        case KycFlowStatus.CANCELED:
        case KycFlowStatus.KYC_APPROVED:
        case KycFlowStatus.KYC_REJECTED:
          setIsReadOnly(true);
          setShowControls(true);
          return VALIDATION_ADMIN_ACCEPTED;
        default:
          setIsReadOnly(true);
          setShowControls(true);
          return VALIDATION_ADMIN_ACCEPTED;
      }
    }
  }

  if (isClientViewer) {
    if (status === KycFlowStatus.CLIENT_REVIEW_PENDING) {
      setShowControls(true);
      setIsReadOnly(false);

      return removeStepUploadAdditionalFilesIfRequired(values, REVIEW_CLIENT);
    }

    if (status === KycFlowStatus.CLIENT_REJECT_AND_REVIEW_PENDING) {
      setShowControls(true);
      setIsReadOnly(false);
      return removeStepUploadAdditionalFilesIfRequired(values, [
        KycStep.STEP_START_WITH_NOTE,
        ...REVIEW_CLIENT,
      ]);
    }

    setActiveStep(0);
    return SUCCESS;
  }
  return [];
};

const removeStepUploadAdditionalFilesIfRequired = (values: KycFlow, steps: KycStep[]) => {
  const stepsToReturn = [...steps];
  if (isNaturalClient(values)) {
    remove(stepsToReturn, (step) => step === KycStep.STEP_CLIENT_PAGE_3);
  }
  return stepsToReturn;
};

export const getSignatureByBoId = (kycFlow: any, boId: any) => {
  return kycFlow?.signatures?.find((signature: any) => signature?.beneficialOwnerId === boId);
};

//take locale from intl and return short version (en-EN -> en)
export const getLangShort = (locale: string, doUpperCase = false): string => {
  return doUpperCase
    ? locale?.split("-")?.[0]?.toUpperCase()
    : locale?.split("-")?.[0]?.toLowerCase();
};

export const insertIf = (condition: any, ...elements: any[]) => {
  return condition ? elements : [];
};

export const isStatusBefore = (status: KycFlowStatus, compStatus: KycFlowStatus) => {
  return statusAsArray.indexOf(status) < statusAsArray.indexOf(compStatus);
};

export const isStatusBetweenInclusive = (
  status: KycFlowStatus,
  statusA: KycFlowStatus,
  statusB: KycFlowStatus
) => {
  return (
    statusAsArray.indexOf(status) >= statusAsArray.indexOf(statusA) &&
    statusAsArray.indexOf(status) <= statusAsArray.indexOf(statusB)
  );
};

export const isStatus = (status: KycFlowStatus, compStatus: KycFlowStatus) => {
  return (status as KycFlowStatus) === compStatus;
};

export const isStatusOneOf = (status: KycFlowStatus, statuses: KycFlowStatus[]) => {
  return statuses.includes(status);
};

export const canSendForm = (values: KycFlow) =>
  values?.form?.acceptTerms?.isAccepted &&
  values?.form?.acceptTerms?.isNextClicked &&
  ((isLegalClient(values) && !!values?.form?.client?.legalType) || isNaturalClient(values));

export const isKycFlowInTerminalState = (kyc: KycFlow) => {
  return [
    KycFlowStatus.ADMIN_RISK_ASSESSMENT_DECLINED,
    KycFlowStatus.KYC_REJECTED,
    KycFlowStatus.KYC_APPROVED,
    KycFlowStatus.CANCELED,
  ].includes(kyc?.status);
};

export const KYC_EVENT_TYPES: any = {
  ADMIN_CREATE_KYC: "/media/svg/icons/Navigation/Plus.svg",
  ADMIN_ACCEPT_DISCLAIMER: "/media/svg/icons/Code/Done-circle.svg",
  ADMIN_ACCEPT_SIGNATURES_COMPLETE: "/media/svg/icons/Code/Done-circle.svg",
  ADMIN_UPDATE: "/media/svg/icons/General/Update.svg",
  CLIENT_UPDATE: "/media/svg/icons/General/Update.svg",
  ADMIN_SEND_TO_CLIENT_FOR_COMPLETION: "/media/svg/icons/Communication/Send.svg",
  OPEN_KYC_FORM: "/media/svg/icons/General/Visible.svg",
  CLIENT_SEND_TO_ADMIN: "/media/svg/icons/Communication/Send.svg",
  ADMIN_REFUSE_DATA_ADDED_BY_CLIENT_AND_SEND_BACK_FOR_REVISION:
    "/media/svg/icons/Code/Warning-2.svg",
  ADMIN_ACCEPT_DATA_ADDED_BY_CLIENT: "/media/svg/icons/Code/Done-circle.svg",
  ADMIN_START_RISK_ASSESSMENT: "/media/svg/icons/Navigation/Plus.svg",
  ADMIN_UPDATE_RISK_ASSESSMENT: "/media/svg/icons/General/Update.svg",
  ADMIN_ACCEPT_RISK_ASSESSMENT: "/media/svg/icons/Code/Done-circle.svg",
  ADMIN_REJECT_RISK_ASSESSMENT: "/media/svg/icons/Code/Error-circle.svg",
  ADMIN_SEND_PDF_FOR_BO: "/media/svg/icons/Communication/Send.svg",
  ADMIN_DOWNLOAD_PDF_FOR_BO: "/media/svg/icons/Files/DownloadedFile.svg",
  BO_SIGN_PDF_BO: "/media/svg/icons/General/Shield-check.svg",
  ADMIN_UPLOAD_PDF_BO_SIGNED: "/media/svg/icons/Files/Uploaded-file.svg",
  ADMIN_DOWNLOAD_FILE: "/media/svg/icons/Files/DownloadedFile.svg",
  ADMIN_APPROVE_KYC: "/media/svg/icons/Code/Done-circle.svg",
  ADMIN_CANCEL_KYC: "/media/svg/icons/Code/Stop.svg",
  ADMIN_REJECT_KYC: "/media/svg/icons/Code/Error-circle.svg",
};

export const getColorForKycEvent = (eventName: any) => {
  switch (eventName) {
    case KycStepEvent.ADMIN_ACCEPT_DISCLAIMER:
    case KycStepEvent.ADMIN_ACCEPT_SIGNATURES_COMPLETE:
    case KycStepEvent.ADMIN_ACCEPT_DATA_ADDED_BY_CLIENT:
    case KycStepEvent.ADMIN_ACCEPT_RISK_ASSESSMENT:
    case KycStepEvent.BO_SIGN_PDF_BO:
    case KycStepEvent.ADMIN_APPROVE_KYC:
    case KycStepEvent.ADMIN_UPLOAD_PDF_BO_SIGNED:
      return "svg-icon-success";
    case KycStepEvent.ADMIN_CANCEL_KYC:
    case KycStepEvent.ADMIN_REJECT_KYC:
      return "svg-icon-danger";
    case KycStepEvent.ADMIN_REFUSE_DATA_ADDED_BY_CLIENT_AND_SEND_BACK_FOR_REVISION:
      return "svg-icon-warning";
    default:
      return "svg-icon-primary";
  }
};

export const formatDataCy = (
  prefix: string = "",
  value: string,
  suffix: string | number = ""
): string => {
  if (!value) return "";

  return `${prefix}-${value
    ?.replace(prefix, "")
    ?.replaceAll(".", "-")
    ?.replaceAll(" ", "-")
    ?.replaceAll("[", "-")
    ?.replaceAll("--", "-")
    ?.replaceAll("]", "")
    ?.replaceAll("/", "")
    ?.replaceAll(":", "")
    ?.toLowerCase()}-${suffix}`;
};

export const isRiskAssessmentDone = (values: KycFlow) => {
  return [KycRiskAssessmentStatus.REJECTED, KycRiskAssessmentStatus.VALIDATED].includes(
    values?.riskAssessmentStatus
  );
};

export const getEntityToEntityTypeMap = (kyc: KycFlow): { [key: string]: string } => {
  return {
    [KycEntityType.KYC]: KycTranslation.PLACEHOLDER_NO_VALUE_INDICATOR,
    [KycEntityType.REPRESENTATIVE]: isBigOwner(kyc)
      ? KycTranslation.REPRESENTATIVE
      : KycTranslation.BENEFICIAL_OWNER,
    [KycEntityType.BENEFICIAL_OWNER]: KycTranslation.BENEFICIAL_OWNER,
    [KycEntityType.CLIENT_NATURAL]: KycTranslation.CLIENT_IS,
    [KycEntityType.CLIENT_LEGAL]: KycTranslation.CLIENT_IS,
    [KycEntityType.AGENT]: KycTranslation.AGENT,
    [KycEntityType.ADDITIONAL_DOCUMENTS_LU_OR_FOREIGN_COMPANY]:
      KycTranslation.PLACEHOLDER_NO_VALUE_INDICATOR,
    [KycEntityType.ADDITIONAL_DOCUMENTS_LISTED_COMPANY]:
      KycTranslation.PLACEHOLDER_NO_VALUE_INDICATOR,
    [KycEntityType.ADDITIONAL_DOCUMENTS_INVESTMENT_FUND]:
      KycTranslation.PLACEHOLDER_NO_VALUE_INDICATOR,
    [KycEntityType.ADDITIONAL_DOCUMENTS_TRUST]: KycTranslation.PLACEHOLDER_NO_VALUE_INDICATOR,
    [KycEntityType.ADDITIONAL_DOCUMENTS_NATURAL_PERSON]:
      KycTranslation.PLACEHOLDER_NO_VALUE_INDICATOR,
    [KycEntityType.SHAREHOLDER_COMPANY]: KycTranslation.SHAREHOLDER_COMPANY,
  };
};

export const legalTypeToAdditionalDocumentsTypeMap: Record<KycLegalType, KycEntityType> = {
  [KycLegalType.LEGAL_TYPE_LU_OR_FOREIGN_COMPANY]:
    KycEntityType.ADDITIONAL_DOCUMENTS_LU_OR_FOREIGN_COMPANY,
  [KycLegalType.LEGAL_TYPE_LISTED_COMPANY]: KycEntityType.ADDITIONAL_DOCUMENTS_LISTED_COMPANY,
  [KycLegalType.LEGAL_TYPE_INVESTMENT_FUND]: KycEntityType.ADDITIONAL_DOCUMENTS_INVESTMENT_FUND,
  [KycLegalType.LEGAL_TYPE_TRUST]: KycEntityType.ADDITIONAL_DOCUMENTS_TRUST,
};

export const getAllEvidenceForKycRelatedEntityType = (
  kycRelatedEntityType: KycEntityType,
  kyc: KycFlow
): any[] => {
  const mRequiredEvidence = {
    [KycEntityType.KYC]: [],
    [KycEntityType.REPRESENTATIVE]: ["evidencePrivateAddress", "evidencePassportId"],
    [KycEntityType.BENEFICIAL_OWNER]: ["evidencePrivateAddress", "evidencePassportId"],
    [KycEntityType.CLIENT_LEGAL]: [
      "evidenceLegalForm",
      "evidenceTradeRegisterRCS",
      "evidenceTradeRegisterRBE",
      "evidenceAdditionalDocuments",
      "evidenceBeneficialOwnerOrganisationalChart",
      //only if investment fund
      ...insertIf(
        !isBigOwner(kyc) && isBuyerFlow(kyc) && isInvestmentFund(kyc),
        "evidenceAgentNoBigOwner"
      ),
      ...insertIf(isBuyerFlow(kyc), "evidencePurposeOfAcquisition"),
      ...insertIf(isBuyerFlow(kyc), "evidenceOriginOfAssets"),
      ...insertIf(isSellerFlow(kyc), "evidenceMeansOfAcquisition"),
      ...insertIf(
        isEvidenceOptionSet(kyc?.files, "originOfAssetsBankLoan"),
        "evidenceRepaymentMethod"
      ),
    ],
    [KycEntityType.CLIENT_NATURAL]: [
      "evidencePrivateAddress",
      "evidencePassportId",
      ...insertIf(isBuyerFlow(kyc), "evidencePurposeOfAcquisition"),
      ...insertIf(isBuyerFlow(kyc), "evidenceOriginOfAssets"),
      ...insertIf(isSellerFlow(kyc), "evidenceMeansOfAcquisition"),
      ...insertIf(
        isEvidenceOptionSet(kyc?.files, "originOfAssetsBankLoan"),
        "evidenceRepaymentMethod"
      ),
    ],
    [KycEntityType.AGENT]: [
      "evidencePrivateAddress",
      "evidencePassportId",
      "evidenceLetterOfEngagement",
    ],
    [KycEntityType.ADDITIONAL_DOCUMENTS_LU_OR_FOREIGN_COMPANY]: [
      "evidenceRegisterOfShareHolders",
      "evidenceCertificateIncumbency2",
      "evidenceAccountsYearlyPublic",
      "evidenceCopyAuthorisation",
      "evidenceAgreementCSSF",
      "evidencePrintScreenControlInstit",
      "evidenceSocialDocsShareholders",
      "evidenceLFCLCWorldCheck",
      "evidenceLFCLCInternetCheck",
    ],
    [KycEntityType.ADDITIONAL_DOCUMENTS_INVESTMENT_FUND]: [
      "evidenceProveRegulatedFund",
      "evidenceCopyProspects",
      "evidencePrintScreenCssfMancoAifm",
      "evidenceInvestmentFundWorldCheck",
      "evidenceInvestmentFundInternetCheck",
    ],
    [KycEntityType.ADDITIONAL_DOCUMENTS_LISTED_COMPANY]: [
      "evidenceProveOfContributions",
      "evidenceLFCLCWorldCheck",
      "evidenceLFCLCInternetCheck",
    ],
    [KycEntityType.ADDITIONAL_DOCUMENTS_TRUST]: ["evidencePassportIdUtilityBill2"],
    [KycEntityType.SHAREHOLDER_COMPANY]: [
      "evidenceTradeRegisterRCS",
      "evidenceTradeRegisterRBE",
      "evidenceAdditionalDocuments",
    ],
    [KycEntityType.ADDITIONAL_DOCUMENTS_NATURAL_PERSON]: [
      "evidenceNPWorldCheck",
      "evidenceNPInternetCheck",
    ],
  };

  const p: any = [];

  mRequiredEvidence?.[kycRelatedEntityType]?.forEach((evidenceId, index) => {
    p.push({
      id: "" + index,
      evidenceId,
    });
  });

  return p;
};

export const createTableRowFilePlaceholder: any = (evidenceId: string, fm: Function) => {
  return {
    id: uuid(),
    labels: {
      entity: "-",
      entityName: "-",
      evidenceCategory: getTranslationCategory(evidenceId, fm) ?? "-",
    },
  };
};

export const getFilesMissing = (groupedKycEntity: any) => {
  const evidenceRequired = groupedKycEntity?.evidenceRequired ?? [];
  const evidenceProvided =
    groupedKycEntity?.evidenceProvided?.map((f: any) => f.categoryId?.split(".")?.pop()) ?? [];

  const missingEvidenceIds =
    difference(
      evidenceRequired?.map((e: any) => e.evidenceId),
      evidenceProvided
    ) ?? [];
  return {
    provided: evidenceProvided,
    missing: missingEvidenceIds,
    text: `${evidenceRequired?.length - missingEvidenceIds?.length}/${evidenceRequired?.length}`,
  };
};
export const getFilesMissingPlaceholderTableRows = (groupedKycEntity: any, fm: Function) => {
  return groupedKycEntity?.missing?.map((e: any) => createTableRowFilePlaceholder(e, fm));
};

const getDropdownOption = (file: KycFile, fm: Function) => {
  if (file?.option === "others" || file?.freeText) {
    return file?.freeText;
  }

  if (file?.option === "internalDocument") {
    return fm(KycTranslation.FILE_CATEGORY_INTERNAL_DOCUMENT);
  }

  const label = allOptions.find((option: KycChoiceOption) => option.value === file?.option)?.label;
  return label && fm(label);
};

export const getFileName = (file: any, intl: IntlShape) => {
  //for multi-lang files, always show the filename of the file related to the user lang
  if (file?.files) {
    return find(file?.files, { lang: intl.locale })?.friendlyName || file?.friendlyName;
  }
  return file.friendlyName;
};

export const formatGroupedFiles = (
  groupedFiles: any[],
  kycFlow: KycFlow,
  intl: IntlShape,
  fDisplayName: Function,
  fm: Function
) => {
  return groupedFiles?.map((file) => {
    file.isAvailable = file?.url || file?.files;
    file.labels = {
      filename: getFileName(file, intl) ?? "-",
      //the label for the type of the entity e.g. "Legal representative, Beneficial Owner"
      entityName: getKycRelatedEntityName(file, fm, fDisplayName, kycFlow),
      entity: fm(getKycRelatedEntityNameOfType(file?.kycRelatedToEntity?.type, kycFlow)) ?? "-",
      flowTypeLabel: kycFlow?.flowType && getFlowTypeLabel(kycFlow?.flowType, fm),
      evidenceCategory: getTranslationCategory(file?.categoryId, fm) ?? "-",
      evidenceDocumentType: getDropdownOption(file, fm) ?? "-",
    };
    return file;
  });
};

const getEntityNameForId = (id: string, arrEntities: any[], fDisplayName: Function) => {
  const entity = arrEntities.find((entity: any) => entity.id === id);
  if (entity) {
    return (entity && entity?.name) || fDisplayName(entity, false);
  }
  return "";
};

export const getAdditionalDocumentsEntity = (
  id: string,
  entityType: KycEntityType,
  fm: Function
) => {
  return {
    id,
    entityType,
    name: fm(KycTranslation.EVIDENCE_DOCUMENT_ADDITIONAL),
  };
};

export const getKycRelatedEntityName = (
  file: any,
  fm: Function,
  fDisplayName: Function,
  kyc: KycFlow
) => {
  const client = kyc?.form?.client;
  if (file?.categoryId === "kycDocument") {
    return fm(KycTranslation.FILENAME_KYC_DOCUMENT);
  }

  const kycRelatedToEntity: KycRelatedToEntity = file?.kycRelatedToEntity;

  const entityMap = new Map<KycEntityType, any[]>([
    [KycEntityType.CLIENT_LEGAL, [client]],
    [KycEntityType.CLIENT_NATURAL, [client]],
    [KycEntityType.REPRESENTATIVE, client?.representatives],
    [KycEntityType.AGENT, client?.agents],
    [KycEntityType.BENEFICIAL_OWNER, [...client?.representatives, ...client?.beneficialOwners]],
    [
      KycEntityType.SHAREHOLDER_COMPANY,
      (client?.beneficialOwners ?? []).flatMap(
        (beneficialOwner: BeneficialOwner) => beneficialOwner?.externalCompanies ?? []
      ),
    ],
    [
      KycEntityType.ADDITIONAL_DOCUMENTS_LU_OR_FOREIGN_COMPANY,
      [
        getAdditionalDocumentsEntity(
          kyc?.id,
          KycEntityType.ADDITIONAL_DOCUMENTS_LU_OR_FOREIGN_COMPANY,
          fm
        ),
      ],
    ],
    [
      KycEntityType.ADDITIONAL_DOCUMENTS_LISTED_COMPANY,
      [
        getAdditionalDocumentsEntity(
          kyc?.id,
          KycEntityType.ADDITIONAL_DOCUMENTS_LISTED_COMPANY,
          fm
        ),
      ],
    ],
    [
      KycEntityType.ADDITIONAL_DOCUMENTS_INVESTMENT_FUND,
      [
        getAdditionalDocumentsEntity(
          kyc?.id,
          KycEntityType.ADDITIONAL_DOCUMENTS_INVESTMENT_FUND,
          fm
        ),
      ],
    ],
    [
      KycEntityType.ADDITIONAL_DOCUMENTS_TRUST,
      [getAdditionalDocumentsEntity(kyc?.id, KycEntityType.ADDITIONAL_DOCUMENTS_TRUST, fm)],
    ],
    [
      KycEntityType.ADDITIONAL_DOCUMENTS_NATURAL_PERSON,
      [
        getAdditionalDocumentsEntity(
          kyc?.id,
          KycEntityType.ADDITIONAL_DOCUMENTS_NATURAL_PERSON,
          fm
        ),
      ],
    ],
  ]);

  const entities = entityMap.get(kycRelatedToEntity?.type);
  if (file?.kycRelatedToEntity?.id === "38abcf02-1fc6-458a-8e57-e999d818a2f3")
    console.log("entities", entities);

  return entities
    ? getEntityNameForId(kycRelatedToEntity?.id, entities, fDisplayName)
    : fm(KycTranslation.PLACEHOLDER_NO_VALUE_INDICATOR);
};

export const isLegalClient = (kyc: KycFlow) => {
  return getLegalTypeOfClient(kyc) === KycLegalEntityType.LEGAL;
};
export const isTrust = (kyc: KycFlow) => {
  return kyc?.form?.client?.legalType === KycLegalType.LEGAL_TYPE_TRUST;
};

export const isInvestmentFund = (kyc: KycFlow) => {
  return kyc?.form?.client?.legalType === KycLegalType.LEGAL_TYPE_INVESTMENT_FUND;
};
export const isRepresentedByAgent = (kyc: KycFlow) => {
  return kyc?.form?.client?.isRepresentedByAgent === "true";
};

export const isNaturalClient = (kyc: KycFlow) => {
  return getLegalTypeOfClient(kyc) === KycLegalEntityType.NATURAL;
};

export const getLegalTypeOfClient = (kyc: KycFlow) => {
  return kyc?.form?.client?.legalEntityType === KycLegalEntityType.LEGAL
    ? KycLegalEntityType.LEGAL
    : KycLegalEntityType.NATURAL;
};

export const isBigOwner = (kyc: KycFlow) => {
  return kyc?.form?.client?.isBigOwner === "true";
};

const getAllEntityRequiredEvidenceMap = (kyc: KycFlow) => {
  const m: { [key: string]: any[] } = {};
  Object.values(KycEntityType).map(
    (entityType) => (m[entityType] = getAllEvidenceForKycRelatedEntityType(entityType, kyc))
  );
  return m;
};

export const getEntityWithType = (entity: any, entityType: KycEntityType) => {
  return { ...entity, entityType };
};

export const isSpecialEntity = (entity: any) => {
  return [
    KycEntityType.SHAREHOLDER_COMPANY,
    KycEntityType.ADDITIONAL_DOCUMENTS_LISTED_COMPANY,
    KycEntityType.ADDITIONAL_DOCUMENTS_INVESTMENT_FUND,
    KycEntityType.ADDITIONAL_DOCUMENTS_LU_OR_FOREIGN_COMPANY,
    KycEntityType.ADDITIONAL_DOCUMENTS_TRUST,
    KycEntityType.ADDITIONAL_DOCUMENTS_NATURAL_PERSON,
  ].includes(entity.entityType);
};

export const getEntityName = (entity: any, fDisplayName: Function) => {
  return isSpecialEntity(entity) ? entity?.name : fDisplayName(entity) ?? "-";
};

export const getAdditionalDocumentsEntityType = (kyc: KycFlow): KycEntityType => {
  return isNaturalClient(kyc)
    ? KycEntityType.ADDITIONAL_DOCUMENTS_NATURAL_PERSON
    : legalTypeToAdditionalDocumentsTypeMap?.[kyc?.form?.client?.legalType as KycLegalType];
};

export const getAllEntityIdsAndNames = (
  kyc: KycFlow,
  fDisplayName: Function,
  fm: Function,
  files: any[]
) => {
  const client = kyc?.form?.client;

  const clientEntity = getEntityWithType(
    client,
    isLegalClient(kyc) ? KycEntityType.CLIENT_LEGAL : KycEntityType.CLIENT_NATURAL
  );

  const representativeEntities = client?.representatives?.map((representativeEntity: any) =>
    getEntityWithType(
      representativeEntity,
      isBigOwner(kyc) ? KycEntityType.REPRESENTATIVE : KycEntityType.BENEFICIAL_OWNER
    )
  );

  const agents = client?.agents?.map((e: any) => getEntityWithType(e, KycEntityType.AGENT));
  const beneficialOwners = client?.beneficialOwners?.map((e: any) =>
    getEntityWithType(e, KycEntityType.BENEFICIAL_OWNER)
  );

  const shareholderCompanies = (client?.beneficialOwners ?? []).flatMap(
    (beneficialOwner: BeneficialOwner) =>
      (beneficialOwner?.externalCompanies ?? []).map((company: ShareholderCompany) =>
        getEntityWithType(company, KycEntityType.SHAREHOLDER_COMPANY)
      )
  );

  const additionalDocumentsEntity = {
    id: kyc?.id,
    name: fm(KycTranslation.EVIDENCE_DOCUMENT_ADDITIONAL),
    entityType: getAdditionalDocumentsEntityType(kyc),
  };

  const allEntities = concat(
    clientEntity,
    representativeEntities,
    agents,
    isBigOwner(kyc) ? beneficialOwners : [],
    additionalDocumentsEntity,
    shareholderCompanies
  );

  let mFormattedEntities: { [key: string]: any } = {};

  const entityRequiredEvidence = getAllEntityRequiredEvidenceMap(kyc);
  allEntities.forEach((entity) => {
    if (entity) {
      mFormattedEntities[entity?.id] = {
        id: entity.id,
        name: getEntityName(entity, fDisplayName),
        entityType: entity?.entityType,
        evidenceRequired: entityRequiredEvidence[entity?.entityType],
        evidenceProvided: files?.filter((file) => file?.kycRelatedToEntity?.id === entity.id),
        entityName: fm(getEntityToEntityTypeMap(kyc)?.[entity?.entityType]),
      };
    }
  });

  Object.values(mFormattedEntities).forEach((entity) => {
    mFormattedEntities[entity.id] = { ...entity, ...getFilesMissing(entity) };
  });
  return mFormattedEntities;
};

export const getKycRelatedEntityNameOfType = (type: KycEntityType, kyc: KycFlow): string => {
  return getEntityToEntityTypeMap(kyc)?.[type] || type?.toString();
};

export const getKycAdditionalDocumentsRelatedEntityType = (kyc: KycFlow) => {
  const { legalType } = kyc?.form?.client ?? {};
  const legalTypeToEntityTypeMap: { [key: string]: KycEntityType } = {
    [KycLegalType.LEGAL_TYPE_LU_OR_FOREIGN_COMPANY]:
      KycEntityType.ADDITIONAL_DOCUMENTS_LU_OR_FOREIGN_COMPANY,
    [KycLegalType.LEGAL_TYPE_LISTED_COMPANY]: KycEntityType.ADDITIONAL_DOCUMENTS_LISTED_COMPANY,
    [KycLegalType.LEGAL_TYPE_INVESTMENT_FUND]: KycEntityType.ADDITIONAL_DOCUMENTS_INVESTMENT_FUND,
    [KycLegalType.LEGAL_TYPE_TRUST]: KycEntityType.ADDITIONAL_DOCUMENTS_TRUST,
  };
  const type = isNaturalClient(kyc)
    ? KycEntityType.ADDITIONAL_DOCUMENTS_NATURAL_PERSON
    : legalTypeToEntityTypeMap?.[legalType];
  return type ? { id: kyc?.id, type } : undefined;
};

export const mapNameToKycEntityType = (name: string) => {
  if (!name) return "";
  if (name.includes("externalCompanies")) return KycEntityType.SHAREHOLDER_COMPANY;
  if (name.includes("agents")) return KycEntityType.AGENT;
  if (name.includes("representatives")) return KycEntityType.REPRESENTATIVE;
  if (name.includes("beneficialOwners")) return KycEntityType.BENEFICIAL_OWNER;
  return "";
};

export const getSignatoryIndex = (signatories: KycSignatory[], signatoryId: string) => {
  return signatories?.findIndex((signatory: any) => signatory?.id === signatoryId) ?? -1;
};

export const getNewestObjectsByLang = (objects: KycFile[]): KycFile[] => {
  // Create an object to store the newest objects for each lang
  const newestObjectsByLang: any = {};

  // Loop through the objects and update the newestObjectsByLang object
  objects.forEach((object) => {
    const lang: any = object.lang;
    const createdAt = object.updatedAt;

    // If the lang hasn't been seen yet, or if this object is newer than the previous one for the lang
    if (!newestObjectsByLang?.[lang] || createdAt > newestObjectsByLang?.[lang]?.createdAt) {
      newestObjectsByLang[lang] = { ...object };
    }
  });

  // Return the newest objects as an array
  return Object.values(newestObjectsByLang);
};

export const getBoTypeName = (boType: KycBoType, fm: Function) => {
  switch (boType) {
    case KycBoType.NATURAL_PERSON:
      return fm(KycTranslation.BO_TYPE_NATURAL_PERSON);
    case KycBoType.LEGAL_PERSON:
      return fm(KycTranslation.BO_TYPE_LEGAL_PERSON);
    case KycBoType.LEGAL_PERSON_MORE_25:
      return fm(KycTranslation.BO_TYPE_LEGAL_PERSON_MORE_25);
  }
};

export const formatSignatories = (kyc: KycFlow, signatories: KycSignatory[], files: any[]) => {
  return signatories.map((signatory: KycSignatory) => {
    const signatorySignature = getSignatureByBoId(kyc, signatory?.id);
    const signatoryFiles = files?.filter(
      (file) =>
        file.kycRelatedToEntity?.id === signatory?.id &&
        getLangShort(file?.lang) === signatory?.boDeclaration?.lang
    );

    const signatoryFileSelected = maxBy(signatoryFiles, (file) => new Date(file.updatedAt));

    return {
      id: signatory?.id,
      name: signatory?.fullName,
      email: signatory?.email,
      mobile: signatory?.mobile,
      boDocType: signatory?.type,
      signatureStatus: signatorySignature?.status,
      signature: signatorySignature,
      notificationMethods: signatory?.boDeclaration?.notificationMethods,
      isOfflineSignatory: signatory?.boDeclaration?.notificationMethods?.includes(
        KycNotificationDestination.OFFLINE
      ),
      lang: signatory?.boDeclaration?.lang,
      files: signatoryFiles,
      selectedFile: signatoryFileSelected ?? {},
      selectedFileDownloadedAt: signatory?.boDeclaration?.downloadedAt,
      boDeclaration: signatory?.boDeclaration,
    };
  });
};

export const isTerminalStatus = (status: KycFlowStatus) => {
  return [KycFlowStatus.KYC_REJECTED, KycFlowStatus.KYC_APPROVED, KycFlowStatus.CANCELED]?.includes(
    status
  );
};

export const isFlowUnfinished = (
  currentKycFlows: KycFlowListItem[],
  leadId: string,
  flowType?: KycFlowType
) => {
  const unfinishedFlowsTypes: KycFlowType[] = currentKycFlows
    ?.filter((kyc: KycFlowListItem) => !isTerminalStatus(kyc?.status) && kyc?.lead?.id === leadId)
    .map((unfinishedKycFlow) => unfinishedKycFlow.flowType);

  if (isKycMultipleFlowTypesAvailable()) {
    const typesToCheck = flowType ? [flowType] : Object.values(KycFlowType);
    return typesToCheck.every((type) => unfinishedFlowsTypes.includes(type));
  } else {
    return unfinishedFlowsTypes.includes(kycAvailableFlowTypes()?.[0]);
  }
};

export const isSellerFlow = (kyc: KycFlow) => {
  return kyc.flowType === KycFlowType.SELLER;
};

export const isBuyerFlow = (kyc: KycFlow) => {
  return kyc.flowType === KycFlowType.BUYER;
};
