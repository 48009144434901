import { Field, FieldInputProps } from "formik";
import { FormattedMessage } from "react-intl";
import * as React from "react";
import cn from "clsx";

export interface CheckboxProps {
  name?: string;
  label: string;
  translateLabel?: boolean;
  customClasses?: string;
  checkboxStyle?: string;
  checkboxLarge?: boolean;
  customLabelValues?: any;
}
export const Checkbox: React.FC<
  CheckboxProps & Partial<FieldInputProps<boolean>> & React.InputHTMLAttributes<HTMLInputElement>
> = ({
  name,
  label,
  translateLabel = true,
  customClasses,
  checkboxStyle,
  checkboxLarge = true,
  customLabelValues,
  ...props
}) => {
  return (
    <div className={cn("row", customClasses)}>
      <div className={"col-lg-12 d-flex"}>
        <label
          className={cn(
            "checkbox checkbox-single mr-2",
            checkboxStyle,
            checkboxLarge && "checkbox-lg"
          )}
        >
          {!!name ? (
            <Field type="checkbox" name={name} {...props} />
          ) : (
            <input type="checkbox" {...props} />
          )}
          <span className={"mr-2 flex-shrink-0"} />
          {translateLabel ? <FormattedMessage id={label} values={customLabelValues} /> : label}
        </label>
      </div>
    </div>
  );
};
