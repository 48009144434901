import React, { useMemo } from "react";
import { LeadFilesFilter } from "./LeadFilesFilter";
import { LeadFilesTable } from "./LeadFilesTable";
import { LeadFileEditDialog } from "./lead-file-edit-dialog/LeadFileEditDialog";
import { useLeadFilesUIContext } from "./LeadFilesUIContext";
import { LeadRequestSignature } from "./lead-file-edit-dialog/LeadRequestSignature";
import { SearchProvider } from "../../../../../contexts/SearchContext";

export function LeadFilesFiles() {
  const leadFilesUIContext = useLeadFilesUIContext();
  const leadFilesUIProps = useMemo(() => {
    return { readOnly: leadFilesUIContext.readOnly };
  }, [leadFilesUIContext]);

  return (
    <SearchProvider>
      {!leadFilesUIProps.readOnly && (
        <>
          <LeadFileEditDialog />
          <LeadRequestSignature />
        </>
      )}
      <div className="form margin-b-30">
        <LeadFilesFilter />
      </div>
      <LeadFilesTable />
    </SearchProvider>
  );
}
