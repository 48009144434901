import { IntlShape } from "react-intl";

import { DateUtils } from "app/_utils/DateUtils";
import { ColumnFormatter } from "react-bootstrap-table-next";

interface FormatExtraDataProps {
  intl: IntlShape;
  showTime: boolean;
}

export const DateTimeColumnFormatter: ColumnFormatter<any, FormatExtraDataProps, string> = (
  cell,
  row,
  rowIndex,
  formatExtraData
) => (
  <span>
    {formatExtraData && cell
      ? DateUtils.format(new Date(cell), formatExtraData.intl, formatExtraData.showTime)
      : "-"}
  </span>
);
