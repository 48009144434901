import { KycUploadComponent } from "./controls/kyc-upload/KycUploadComponent";
import { KycTranslation } from "../KycTranslation";
import { useKycFlow } from "../../../hooks/useKycFlow";
import { getKycAdditionalDocumentsRelatedEntityType } from "../KycUtils";

export const KycAdditionalDocumentsTrust = (): JSX.Element => {
  const { values } = useKycFlow();

  return (
    <KycUploadComponent
      name="evidencePassportIdUtilityBill2"
      subLabel={KycTranslation.EVIDENCE_DOCUMENT_NAME_PASSPORT_ID_UTILITY_BILL_2}
      label={KycTranslation.EVIDENCE_DOCUMENT_NAME_PASSPORT_ID_UTILITY_BILL_2_SHORT}
      kycRelatedToEntity={getKycAdditionalDocumentsRelatedEntityType(values)}
      multiple={true}
    />
  );
};
