import type { IntlShape } from "react-intl";

import {
  DefaultUserRoleType,
  IUserRight,
  TUserEntityTypes,
  TUserRightTypes,
  TUserRoleTypes,
  UserEntityType,
  UserRightType,
} from "data/schemas";
import { roles } from "../../../../../../_utils/rolesUtils";

//----------------------------------------------------------------------------//

type TMapKeys = keyof Omit<typeof UserEntityType, "ALL">;

type TEntityDisabledFields = Record<TMapKeys, TUserRightTypes[]>;

type TEntityRights = [TUserEntityTypes, TUserRightTypes[]];

//----------------------------------------------------------------------------//
// @begin: mapping configurations

/** map the disable fields for each entity */
const mapEntityDisabledFields: Omit<TEntityDisabledFields, "*"> = {
  PROJECT: ["HIDDEN_LINE", "INVOICE_MANAGEMENT_DASHBOARD"],

  BUDGET: [
    "SHARE",
    "ANALYSIS",
    "AUDIT_TRAIL",
    "UPLOAD_PHOTO",
    "UPDATE_PHOTO",
    "DELETE_PHOTO",
    "NOTE",
    "CONFIDENTIAL_DOCUMENT",
    "INVOICE_MANAGEMENT_DASHBOARD",
  ],

  PRODUCT: ["SHARE", "ANALYSIS", "HIDDEN_LINE", "INVOICE_MANAGEMENT_DASHBOARD"],

  USER: [
    "SHARE",
    "ANALYSIS",
    "UPLOAD_PHOTO",
    "UPDATE_PHOTO",
    "DELETE_PHOTO",
    "HIDDEN_LINE",
    "NOTE",
    "INVOICE_MANAGEMENT_DASHBOARD",
  ],

  CLIENT: [
    "SHARE",
    "ANALYSIS",
    "UPLOAD_PHOTO",
    "UPDATE_PHOTO",
    "DELETE_PHOTO",
    "HIDDEN_LINE",
    "INVOICE_MANAGEMENT_DASHBOARD",
  ],

  SUBCONTRACTOR: [
    "SHARE",
    "ANALYSIS",
    "UPLOAD_PHOTO",
    "UPDATE_PHOTO",
    "DELETE_PHOTO",
    "HIDDEN_LINE",
    "INVOICE_MANAGEMENT_DASHBOARD",
  ],

  LEAD: [
    "SHARE",
    "ANALYSIS",
    "UPLOAD_PHOTO",
    "UPDATE_PHOTO",
    "DELETE_PHOTO",
    "HIDDEN_LINE",
    "INVOICE_MANAGEMENT_DASHBOARD",
  ],

  TASK: [
    "SHARE",
    "ANALYSIS",
    "AUDIT_TRAIL",
    "UPLOAD_PHOTO",
    "UPDATE_PHOTO",
    "DELETE_PHOTO",
    "HIDDEN_LINE",
    "CONFIDENTIAL_DOCUMENT",
    "INVOICE_MANAGEMENT_DASHBOARD",
  ],

  TAG: [
    "UPDATE",
    "SHARE",
    "ANALYSIS",
    "AUDIT_TRAIL",
    "UPLOAD_PHOTO",
    "UPDATE_PHOTO",
    "DELETE_PHOTO",
    "NOTE",
    "HIDDEN_LINE",
    "CONFIDENTIAL_DOCUMENT",
    "INVOICE_MANAGEMENT_DASHBOARD",
  ],

  CHAT: [
    "READ",
    "UPDATE",
    "DELETE",
    "SHARE",
    "ANALYSIS",
    "AUDIT_TRAIL",
    "UPLOAD_PHOTO",
    "UPDATE_PHOTO",
    "DELETE_PHOTO",
    "NOTE",
    "HIDDEN_LINE",
    "CONFIDENTIAL_DOCUMENT",
    "INVOICE_MANAGEMENT_DASHBOARD",
  ],

  USER_ACTION: [
    "READ",
    "UPDATE",
    "DELETE",
    "SHARE",
    "ANALYSIS",
    "AUDIT_TRAIL",
    "UPLOAD_PHOTO",
    "UPDATE_PHOTO",
    "DELETE_PHOTO",
    "NOTE",
    "HIDDEN_LINE",
    "CONFIDENTIAL_DOCUMENT",
    "INVOICE_MANAGEMENT_DASHBOARD",
  ],

  USER_RIGHTS: [
    "CREATE",
    "DELETE",
    "SHARE",
    "ANALYSIS",
    "AUDIT_TRAIL",
    "UPLOAD_PHOTO",
    "UPDATE_PHOTO",
    "DELETE_PHOTO",
    "NOTE",
    "HIDDEN_LINE",
    "CONFIDENTIAL_DOCUMENT",
    "INVOICE_MANAGEMENT_DASHBOARD",
  ],

  KYC_FLOW: [
    "SHARE",
    "ANALYSIS",
    "UPLOAD_PHOTO",
    "UPDATE_PHOTO",
    "DELETE_PHOTO",
    "NOTE",
    "HIDDEN_LINE",
    "CONFIDENTIAL_DOCUMENT",
    "INVOICE_MANAGEMENT_DASHBOARD",
  ],

  PROPCO: [
    "SHARE",
    "ANALYSIS",
    "UPLOAD_PHOTO",
    "UPDATE_PHOTO",
    "DELETE_PHOTO",
    "NOTE",
    "HIDDEN_LINE",
    "CONFIDENTIAL_DOCUMENT",
    "INVOICE_MANAGEMENT_DASHBOARD",
  ],

  PROJECT_INSIGHTS: [
    "CREATE",
    "READ",
    "UPDATE",
    "DELETE",
    "SHARE",
    "ANALYSIS",
    "AUDIT_TRAIL",
    "UPLOAD_PHOTO",
    "UPDATE_PHOTO",
    "DELETE_PHOTO",
    "NOTE",
    "HIDDEN_LINE",
    "CONFIDENTIAL_DOCUMENT",
  ],
};

// @end: mapping configurations
//----------------------------------------------------------------------------//
//  @begin: RIGHTS LISTS

export const CRUD_RIGHTS_LIST: TUserRightTypes[] = ["READ", "CREATE", "UPDATE", "DELETE"];

export const ADDITIONAL_RIGHTS_LIST: TUserRightTypes[] = [
  "AUDIT_TRAIL",
  "SHARE",
  "ANALYSIS",
  "HIDDEN_LINE",
  "NOTE",
  "UPLOAD_PHOTO",
  "UPDATE_PHOTO",
  "DELETE_PHOTO",
  "CONFIDENTIAL_DOCUMENT",
  "INVOICE_MANAGEMENT_DASHBOARD",
];

//  @end: RIGHTS LISTS
//----------------------------------------------------------------------------//

export const RIGHTS_ATTR = "rights";

export const RIGHT_USER_I18N_PREFIX_KEY = "RIGHT.USER";

export const ADDITIONAL_RIGHTS_LIST_I18N_LABEL_KEY = `${RIGHT_USER_I18N_PREFIX_KEY}.ADDITIONAL.RIGHT`;

//----------------------------------------------------------------------------//

type TEntityType = keyof Omit<TEntityDisabledFields, "*">;

export interface IRightItem {
  label: string;
  entityType: TEntityType;
  disabledFields: TUserRightTypes[];
}

export interface IPreDefinedRole {
  label: string;
  value: string;
  rights: IUserRight[];
}

//---

const getRoleI18NLabelKey = (roleName: TUserRoleTypes) => {
  if (roleName === DefaultUserRoleType.NONE) return "COMMON.NONE";
  return `ROLES.${roleName}`;
};

const buildPredefinedRoleRights = (roleKey: TUserRoleTypes) => {
  const rightsOptions: IUserRight[] = [];
  const entityRights = roles[roleKey] as TEntityRights[];
  for (const [entityType, rights] of entityRights) {
    for (const rightType of rights) {
      rightsOptions.push({ entityType, rightType });
    }
  }
  return rightsOptions;
};

//---
// @begin: build the objects on the expected shape

export const RIGHTS_LIST: IRightItem[] = Object.keys(UserEntityType).reduce((acc, key) => {
  if (key === "ALL") return acc;

  const entityType = key as TEntityType;
  const rightItem: IRightItem = {
    label: `${RIGHT_USER_I18N_PREFIX_KEY}.${entityType}`,
    entityType,
    disabledFields: mapEntityDisabledFields[entityType] || [],
  };

  acc.push(rightItem);

  return acc;
}, [] as IRightItem[]);

export const ALL_RIGHTS_KEYS = Object.keys(UserRightType).reduce((acc, key) => {
  if (key === "ALL") return acc;

  acc.push(key as TUserRightTypes);

  return acc;
}, [] as TUserRightTypes[]);

export const ALL_RIGHTS: IUserRight[] = RIGHTS_LIST.reduce(
  (result, right) =>
    result.concat(
      ALL_RIGHTS_KEYS.filter((r) => !right.disabledFields?.includes(r)).map((rightType) => ({
        entityType: right.entityType,
        rightType,
      }))
    ),
  [] as IUserRight[]
);

export const MASTER_ROLE_RIGHTS = buildPredefinedRoleRights(DefaultUserRoleType.MASTER);

export const PRE_DEFINED_ROLES: IPreDefinedRole[] = Object.keys(roles).reduce((acc, key) => {
  const roleKey = key as TUserRoleTypes;

  const rights =
    key === DefaultUserRoleType.MASTER ? MASTER_ROLE_RIGHTS : buildPredefinedRoleRights(roleKey);

  const role: IPreDefinedRole = {
    label: getRoleI18NLabelKey(roleKey),
    value: key === DefaultUserRoleType.CUSTOM ? "" : key,
    rights,
  };

  acc.push(role);

  return acc;
}, [] as IPreDefinedRole[]);

// @end: build the objects on the expected shape
//---

export const getTranslatedLists = (intl: IntlShape) => {
  const mapFunction = <T extends IRightItem | IPreDefinedRole>({ label: id, ...attrs }: T) =>
    ({
      label: intl.formatMessage({ id }),
      ...attrs,
    } as T);

  const rightsList = RIGHTS_LIST.map<IRightItem>(mapFunction);

  const preDefinedRoles = PRE_DEFINED_ROLES.map<IPreDefinedRole>(mapFunction);

  return { rightsList, preDefinedRoles };
};
