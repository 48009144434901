/*
  https://react-select.com/typescript
*/
import * as React from "react";
import { useIntl } from "react-intl";

import { ETaskStatusTranslationKeyPrefix } from "data/schemas";

import { LANES_DEFINITION } from "app/_utils/listUtils";

import { CustomSelectOption } from "../CustomSelectBase";

import { CustomSelectStatusRender } from "./CustomSelectStatusRender";
import { CustomSelectStatusProps, CustomSelectStatusRenderProps } from "./definitions";

export const InnerCustomSelectStatus: React.FunctionComponent<CustomSelectStatusProps> = ({
  selected,
  ...props
}) => {
  const intl = useIntl();

  const [selectedOption, options] = React.useMemo(() => {
    const { color, faIconName: icon } = LANES_DEFINITION.find((item) => item.id === selected) || {};
    const selectedOption = {
      label: intl.formatMessage({ id: `${ETaskStatusTranslationKeyPrefix.LABEL}${selected}` }),
      value: selected,
      color,
      icon,
    };

    const options: CustomSelectOption[] = LANES_DEFINITION.map((option) => ({
      label: intl.formatMessage({
        id: option.title,
      }),

      value: option.id,
      color: option.color,
      icon: option.faIconName,
    }));

    return [selectedOption, options];
  }, [intl, selected]);

  const selectProps: CustomSelectStatusRenderProps = {
    ...props,
    selected: selectedOption,
    options,
  };

  return <CustomSelectStatusRender {...selectProps} />;
};

export const CustomSelectStatus = React.memo(InnerCustomSelectStatus);

export default CustomSelectStatus;
