import { exportCSV } from "../../../../_utils/fileUtils";
import { IntlShape } from "react-intl";
import { computeCategory } from "../../BudgetsUtils";
import { SubcontractorFinanceType } from "../../../../../data/schemas";
import { cloneDeep, startCase, isNumber } from "lodash-es";
import { SUFFIX_EURO_CURRENCY } from "app/_utils/suffixUtils";

export const downloadCSV = (budget: any, project: any, values: any, intl: IntlShape) => {
  const headers = [
    intl.formatMessage({ id: "COMMON.DESCRIPTION" }),
    intl.formatMessage({ id: "BUDGET.PLANNED" }) +
      " " +
      intl.formatMessage({ id: "COMMON.EXCL_TAX" }),
    intl.formatMessage({ id: "BUDGET.PLANNED" }) +
      " " +
      intl.formatMessage({ id: "COMMON.INCL_TAX" }),
    intl.formatMessage({ id: "FINANCE.QUOTES" }) +
      " " +
      intl.formatMessage({ id: "COMMON.EXCL_TAX" }),
    intl.formatMessage({ id: "FINANCE.QUOTES" }) +
      " " +
      intl.formatMessage({ id: "COMMON.INCL_TAX" }),
    intl.formatMessage({ id: "FINANCE.ORDERS" }) +
      " " +
      intl.formatMessage({ id: "COMMON.EXCL_TAX" }),
    intl.formatMessage({ id: "FINANCE.ORDERS" }) +
      " " +
      intl.formatMessage({ id: "COMMON.INCL_TAX" }),
    intl.formatMessage({ id: "BUDGET.SUPPLEMENTARY_AGREEMENTS" }) +
      " " +
      intl.formatMessage({ id: "COMMON.EXCL_TAX" }),
    intl.formatMessage({ id: "BUDGET.SUPPLEMENTARY_AGREEMENTS" }) +
      " " +
      intl.formatMessage({ id: "COMMON.INCL_TAX" }),
    intl.formatMessage({ id: "BUDGET.ORDERS_AND_SUPPLEMENTARY_AGREEMENTS" }) +
      " " +
      intl.formatMessage({ id: "COMMON.EXCL_TAX" }),
    intl.formatMessage({ id: "BUDGET.ORDERS_AND_SUPPLEMENTARY_AGREEMENTS" }) +
      " " +
      intl.formatMessage({ id: "COMMON.INCL_TAX" }),
    intl.formatMessage({ id: "BUDGET.DIFFERENCE" }) +
      " " +
      intl.formatMessage({ id: "COMMON.EXCL_TAX" }),
    intl.formatMessage({ id: "BUDGET.DIFFERENCE" }) +
      " " +
      intl.formatMessage({ id: "COMMON.INCL_TAX" }),
    intl.formatMessage({ id: "BUDGET.DIFFERENCE_PERCENT" }) +
      " " +
      intl.formatMessage({ id: "COMMON.EXCL_TAX" }),
    intl.formatMessage({ id: "BUDGET.DIFFERENCE_PERCENT" }) +
      " " +
      intl.formatMessage({ id: "COMMON.INCL_TAX" }),
    intl.formatMessage({ id: "BUDGET.INVOICED" }) +
      " " +
      intl.formatMessage({ id: "COMMON.EXCL_TAX" }),
    intl.formatMessage({ id: "BUDGET.INVOICED" }) +
      " " +
      intl.formatMessage({ id: "COMMON.INCL_TAX" }),
    intl.formatMessage({ id: "BUDGET.DISCOUNTS" }) +
      " " +
      intl.formatMessage({ id: "COMMON.EXCL_TAX" }),
    intl.formatMessage({ id: "BUDGET.DISCOUNTS" }) +
      " " +
      intl.formatMessage({ id: "COMMON.INCL_TAX" }),
    intl.formatMessage({ id: "BUDGET.INVOICED_PERCENT" }) +
      " " +
      intl.formatMessage({ id: "COMMON.EXCL_TAX" }),
    intl.formatMessage({ id: "BUDGET.INVOICED_PERCENT" }) +
      " " +
      intl.formatMessage({ id: "COMMON.INCL_TAX" }),

    intl.formatMessage({ id: "BUDGET.REMAINING_TO_INVOICE" }) +
      " " +
      intl.formatMessage({ id: "COMMON.EXCL_TAX" }),
    intl.formatMessage({ id: "BUDGET.REMAINING_TO_INVOICE" }) +
      " " +
      intl.formatMessage({ id: "COMMON.INCL_TAX" }),

    intl.formatMessage({ id: "BUDGET.INVOICED_ORDERS" }) +
      " " +
      intl.formatMessage({ id: "COMMON.EXCL_TAX" }),
    intl.formatMessage({ id: "BUDGET.INVOICED_ORDERS" }) +
      " " +
      intl.formatMessage({ id: "COMMON.INCL_TAX" }),

    intl.formatMessage({ id: "BUDGET.REMAINING_ORDERS_TO_INVOICE" }) +
      " " +
      intl.formatMessage({ id: "COMMON.EXCL_TAX" }),
    intl.formatMessage({ id: "BUDGET.REMAINING_ORDERS_TO_INVOICE" }) +
      " " +
      intl.formatMessage({ id: "COMMON.INCL_TAX" }),

    intl.formatMessage({ id: "BUDGET.INVOICED_ORDERS_PERCENT" }) +
      " " +
      intl.formatMessage({ id: "COMMON.EXCL_TAX" }),
    intl.formatMessage({ id: "BUDGET.INVOICED_ORDERS_PERCENT" }) +
      " " +
      intl.formatMessage({ id: "COMMON.INCL_TAX" }),

    intl.formatMessage({
      id: "BUDGET.INVOICED_SUPPLEMENTARY_AGREEMENT",
    }) +
      " " +
      intl.formatMessage({ id: "COMMON.EXCL_TAX" }),
    intl.formatMessage({
      id: "BUDGET.INVOICED_SUPPLEMENTARY_AGREEMENT",
    }) +
      " " +
      intl.formatMessage({ id: "COMMON.INCL_TAX" }),

    intl.formatMessage({
      id: "BUDGET.REMAINING_SUPPLEMENTARY_AGREEMENT_TO_INVOICE",
    }) +
      " " +
      intl.formatMessage({ id: "COMMON.EXCL_TAX" }),
    intl.formatMessage({
      id: "BUDGET.REMAINING_SUPPLEMENTARY_AGREEMENT_TO_INVOICE",
    }) +
      " " +
      intl.formatMessage({ id: "COMMON.INCL_TAX" }),
    intl.formatMessage({
      id: "BUDGET.INVOICED_SUPPLEMENTARY_AGREEMENT_PERCENT",
    }) +
      " " +
      intl.formatMessage({ id: "COMMON.EXCL_TAX" }),
    intl.formatMessage({
      id: "BUDGET.INVOICED_SUPPLEMENTARY_AGREEMENT_PERCENT",
    }) +
      " " +
      intl.formatMessage({ id: "COMMON.INCL_TAX" }),
  ];

  const content = [headers];
  const contentSubcontractors = [cloneDeep(headers)];
  contentSubcontractors[0].splice(
    0,
    3,
    ...[
      intl.formatMessage({ id: "COMMON.FILE_NAME" }),
      intl.formatMessage({ id: "EXPORT.BUDGET.CATEGORY_NAME" }),
      intl.formatMessage({ id: "EXPORT.BUDGET.BUDGET_LINE" }),
      intl.formatMessage({ id: "EXPORT.BUDGET.SUBCONTRACTOR" }),
    ]
  );
  // Remove Difference VAT (updated IMT-2025)
  const indexSubToRemove = contentSubcontractors[0].findIndex(
    (s) =>
      s ===
      intl.formatMessage({ id: "BUDGET.DIFFERENCE" }) +
        " " +
        intl.formatMessage({ id: "COMMON.EXCL_TAX" })
  );
  contentSubcontractors[0].splice(indexSubToRemove, 4);

  const computedCategories = budget.sortedLines?.map((category: any) =>
    computeCategory(category.id, project, category)
  );

  const hasUnspecifiedCategory = Object.values(SubcontractorFinanceType).some(
    (financeType) => !!project?.subcontractorsFinanceFiles?.[financeType]?.data?.unspecifiedCategory
  );

  const computedUnspecifiedCategory =
    hasUnspecifiedCategory && computeCategory("unspecifiedCategory", project);

  const generateRow = (valueCategories: any) => {
    const plannedIncludingTax = valueCategories.planned
      ? valueCategories.planned * (1 + (project.projectOwner.defaultVat ?? 0))
      : 0;

    const orders_including_tax = valueCategories.orders_including_tax ?? 0;
    const supplementary_agreements_including_tax =
      valueCategories.supplementary_agreements_including_tax ?? 0;

    const ordersAndSupplAgreementsIncludingTax =
      orders_including_tax + supplementary_agreements_including_tax;

    let realDifferenceIncludingTax = 0;
    let realDifferencePercentageIncludingTax = 0;

    if (
      isNumber(plannedIncludingTax) &&
      plannedIncludingTax > 0 &&
      isNumber(ordersAndSupplAgreementsIncludingTax) &&
      ordersAndSupplAgreementsIncludingTax > 0
    ) {
      realDifferenceIncludingTax = ordersAndSupplAgreementsIncludingTax - plannedIncludingTax;

      realDifferencePercentageIncludingTax =
        (realDifferenceIncludingTax * 100) / plannedIncludingTax;
    }

    const ordersAndSAWithoutDiscountsIncludingTax =
      ordersAndSupplAgreementsIncludingTax - (valueCategories.discounts_including_tax ?? 0);

    const ordersWithoutDiscountsIncludingTax =
      orders_including_tax - (valueCategories.discountsOrders_including_tax ?? 0);

    const supplementaryAgreementsWithoutDiscountsIncludingTax =
      supplementary_agreements_including_tax -
      (valueCategories.discountsSupplementaryAgreements_including_tax ?? 0);

    let invoicedPercentageIncludingTax = 0;

    const invoicedPercentage = valueCategories.invoicedPercentage;
    const invoiced_including_tax = valueCategories.invoiced_including_tax ?? 0;

    if (
      invoicedPercentage > 0 &&
      invoiced_including_tax > 0 &&
      ordersAndSAWithoutDiscountsIncludingTax &&
      ordersAndSAWithoutDiscountsIncludingTax !== 0
    ) {
      invoicedPercentageIncludingTax =
        (invoiced_including_tax * 100) / ordersAndSAWithoutDiscountsIncludingTax;
    }

    const remainingToInvoiceIncludingTax =
      ordersAndSAWithoutDiscountsIncludingTax - invoiced_including_tax;

    const invoicedOrders_including_tax = valueCategories.invoicedOrders_including_tax ?? 0;

    const remainingOrdersToInvoiceIncludingTax =
      ordersWithoutDiscountsIncludingTax - invoicedOrders_including_tax;

    const invoicedOrdersPercentageIncludingTax = ordersWithoutDiscountsIncludingTax
      ? (invoicedOrders_including_tax * 100) / ordersWithoutDiscountsIncludingTax
      : 0;

    const invoicedSupplementaryAgreements_including_tax =
      valueCategories.invoicedSupplementaryAgreements_including_tax ?? 0;

    const remainingSupplementaryAgreementsToInvoiceIncludingTax =
      supplementaryAgreementsWithoutDiscountsIncludingTax -
      invoicedSupplementaryAgreements_including_tax;

    const invoicedSupplementaryAgreementsPercentageIncludingTax =
      supplementaryAgreementsWithoutDiscountsIncludingTax
        ? (invoicedSupplementaryAgreements_including_tax * 100) /
          supplementaryAgreementsWithoutDiscountsIncludingTax
        : 0;

    let generatedRowValues = [
      '"' + valueCategories.label + '"',
      valueCategories.planned,
      plannedIncludingTax,
      valueCategories.quotes,
      valueCategories.quotes_including_tax,
      valueCategories.orders,
      orders_including_tax,
      valueCategories.supplementary_agreements,
      supplementary_agreements_including_tax,
      valueCategories.ordersAndSupplAgreements,
      ordersAndSupplAgreementsIncludingTax,
      valueCategories.realDifference,
      realDifferenceIncludingTax,
      valueCategories.realDifferencePercentage,
      realDifferencePercentageIncludingTax,
      valueCategories.invoiced,
      invoiced_including_tax,
      valueCategories.discounts,
      valueCategories.discounts_including_tax,
      invoicedPercentage,
      invoicedPercentageIncludingTax,
      valueCategories.remainingToInvoice,
      remainingToInvoiceIncludingTax,
      valueCategories.invoicedOrders,
      invoicedOrders_including_tax,
      valueCategories.remainingOrdersToInvoice,
      remainingOrdersToInvoiceIncludingTax,
      valueCategories.invoicedOrdersPercentage,
      invoicedOrdersPercentageIncludingTax,
      valueCategories.invoicedSupplementaryAgreements,
      invoicedSupplementaryAgreements_including_tax,
      valueCategories.remainingSupplementaryAgreementsToInvoice,
      remainingSupplementaryAgreementsToInvoiceIncludingTax,
      valueCategories.invoicedSupplementaryAgreementsPercentage,
      invoicedSupplementaryAgreementsPercentageIncludingTax,
    ];

    generatedRowValues = generatedRowValues.map((val) => val ?? 0);

    const percentagesIndexes = [13, 14, 19, 20, 27, 28, 33, 34];

    generatedRowValues.forEach((value: string | number, index: number) => {
      if (typeof value === "number" && value !== 0) {
        if (!percentagesIndexes.includes(index)) {
          const result = Math.round(value * 100) / 100;
          generatedRowValues[index] = `${result}${result > 0 ? SUFFIX_EURO_CURRENCY : ""}`;
        } else {
          generatedRowValues[index] = Math.round(value * 100) / 100;
        }
      }
    });

    return generatedRowValues;
  };

  const fullData = computedCategories;

  if (hasUnspecifiedCategory) {
    computedUnspecifiedCategory.label = intl.formatMessage({ id: "BUDGET.NOT_SPECIFIED" });
    fullData.push(computedUnspecifiedCategory);
  }

  const subcontractorAndFilesLoop = (valuesLines: any, valueCategory: string): any => {
    const returnValues: any[] = [];
    const returnValues2: any[] = [];
    valuesLines.subcontractors.forEach((valueSubcontractors: any) => {
      const rowSubcontractors = generateRow(valueSubcontractors);

      returnValues.push(rowSubcontractors);
      if (valueSubcontractors && valueSubcontractors.files)
        valueSubcontractors.files.forEach((valuesFiles: any) => {
          const row = generateRow(valuesFiles);
          returnValues.push(cloneDeep(row));
          row.splice(
            0,
            3,
            ...[
              '"' + valuesFiles.label + '"',
              '"' + valueCategory + '"',
              '"' + valuesLines.label + '"',
              '"' + valueSubcontractors.label + '"',
            ]
          );
          // Remove Difference VAT (updated IMT-2025)
          row.splice(indexSubToRemove, 4);
          returnValues2.push(row);
        });
    });
    return { default: returnValues, subcontractorLines: returnValues2 };
  };

  fullData.forEach((valueCategories: any) => {
    content.push(generateRow(valueCategories));
    valueCategories.lines?.forEach((valueLines: any) => {
      content.push(generateRow(valueLines));
      const subContractorValues = subcontractorAndFilesLoop(valueLines, valueCategories.label);
      content.push(...subContractorValues.default);
      contentSubcontractors.push(...subContractorValues.subcontractorLines);
    });

    valueCategories.unspecifiedLines?.forEach((valueUnspecifiedLines: any) => {
      content.push(generateRow(valueUnspecifiedLines));
      const subContractorValues = subcontractorAndFilesLoop(
        valueUnspecifiedLines,
        valueCategories.label
      );
      content.push(...subContractorValues.default);
      contentSubcontractors.push(...subContractorValues.subcontractorLines);
    });
  });

  // Flat
  const flatName = startCase(intl.formatMessage({ id: "BUDGET.EXPORT.FILE_FLAT" })).replace(
    /\s+/g,
    "_"
  );
  exportCSV(flatName + "_" + values.name + "_" + values.projectName, content);
  // Structured
  const structuredName = startCase(
    intl.formatMessage({ id: "BUDGET.EXPORT.FILE_STRUCTURED" })
  ).replace(/\s+/g, "_");
  exportCSV(
    structuredName + "_" + values.name + "_" + values.projectName + "_",
    contentSubcontractors
  );
};
