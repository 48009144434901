import React, { useEffect } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { injectIntl } from "react-intl";
import * as auth from "../_redux/authRedux";
import { updateUser } from "../../UsersManagement/_redux/usersActions";
import { fetchSettings } from "../../Settings/_redux/settingsActions";
import { useHistory } from "react-router-dom";
import { GDPR } from "../../../_components/GDPR";
import { useAuth } from "../../../v2/contexts";
import { useGetMe } from "../../../v2/data";

function AcceptGDPR(props) {
  const { tokens } = useAuth();
  const { refetch: refetchMe } = useGetMe(tokens?.idToken?.getJwtToken());

  const dispatch = useDispatch();

  const { session, settings } = useSelector((state) => ({
    session: state.auth.session,
    settings: state.settings.settingsForEdit.saved,
  }));

  const acceptGDPR = () => {
    // TODO: https://kodehyve.atlassian.net/browse/KHOS-2817
    const gdprApprovedAt = new Date();
    dispatch(updateUser({ ...session, gdprApprovedAt })).then((user) => {
      props.acceptGDPR(gdprApprovedAt);
      refetchMe().then();
    });
  };

  const history = useHistory();
  const logout = () => {
    history.push("/logout");
  };

  useEffect(() => {
    if (!settings) {
      fetchSettings(dispatch).catch(() => {
        logout();
      });
    }
  }, [settings]);

  return (
    <>
      {/*

      Note: company-settings must be configured before giving access to customers.
      Missing settings/settings-object causes the UI to remain in loading state.

      Company details/settings can be configured by accessing the UI using an admin account
      (e.g. master@immotool.lu) in settings tab (wheel icon in overlay menu on left side next
      to autosave icon)

      */}
      <>
        <GDPR settings={settings} session={session} acceptGDPR={acceptGDPR} logout={logout} />
      </>
    </>
  );
}

export default injectIntl(connect(null, auth.actions)(AcceptGDPR));
