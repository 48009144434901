import type { Timeline, TimelineGroup, TimelineItem, TimelineOptions } from "vis-timeline/types";
import { DataSet } from "vis-data/esnext";

import moment from "moment";

import { ITask } from "data/schemas";

import { ISelectedSort } from "app/_utils/tasks";

//----------------------------------------------------------------------------//

export enum VisTimelineGroupsCommand {
  EXPAND = "expand",
  COLLAPSE = "collapse",
  TOGGLE_DETAILS = "toggle_details",
}

export const visTimelineGroupsCommands = Object.values(VisTimelineGroupsCommand) as string[];

export enum UpdateVisTimelineWindowCommand {
  FIT = "fit",
  DAY = "day",
  WEEK = "week",
  MONTH = "month",
  QUARTER = "quarter",
  YEAR = "year",
}

export type TUpdateVisTimelineWindowCommand = `${UpdateVisTimelineWindowCommand}`;

export const updateVisTimelineWindowAvailableCommands = Object.values(
  UpdateVisTimelineWindowCommand
) as string[];

const [, ...restUpdateVisTimelineWindowAvailableCommands] =
  updateVisTimelineWindowAvailableCommands;
export const updateVisTimelineWindowFromDateAvailableCommands =
  restUpdateVisTimelineWindowAvailableCommands;

// https://github.com/visjs/vis-timeline/blob/master/lib/timeline/component/ItemSet.js
export interface IVisTimelineItemSet {
  items: Record<string, any>;
  groups: Record<string, any>;

  toggleGroupShowNested: (group: any, force?: boolean) => void;
}

export interface IVisTimeline extends Timeline {
  // https://github.com/visjs/vis-timeline/blob/master/lib/timeline/Timeline.js#L148
  itemSet: IVisTimelineItemSet;
}

export interface ITimelineItem extends TimelineItem {
  progress?: number;
}

//----------------------------------------------------------------------------//

export interface IAnchorPoint {
  x: number;
  y: number;
}

export const initialAnchorPoint: IAnchorPoint = { x: 0, y: 0 };

export interface IDisplayExpandCollapseMenuItems {
  expand: boolean;
  collapse: boolean;
}

export const initialDisplayExpandCollapseMenuItems: IDisplayExpandCollapseMenuItems = {
  expand: true,
  collapse: true,
};

export interface TasksSorterFilter {
  filterText?: string;
  selectedSort: ISelectedSort;
  tasks: ITask[];
}

export type TasksSorterFilterFunction<T extends TasksSorterFilter = TasksSorterFilter> = (
  param: T
) => ITask[];

export interface TasksGanttChartProps extends TasksSorterFilter {
  isTasksLoading?: boolean;
}

export interface TimelineGroupNested extends TimelineGroup {
  treeLevel: number;
}

export interface GanttChartGroupsItemsReturn {
  groups: DataSet<TimelineGroup>;
  items: DataSet<TimelineItem>;
}

export interface GanttChartGroupsItemsMapReturn extends GanttChartGroupsItemsReturn {
  ids2tasks: Map<string, ITask>;
}

export type GanttChartGroupsAndItemsFunction = (tasks: ITask[]) => GanttChartGroupsItemsMapReturn;

export const emptyGanttChartGroupsAndItems: GanttChartGroupsItemsReturn = {
  groups: new DataSet<TimelineGroup>([]),
  items: new DataSet<TimelineItem>([]),
};

export const TASK_BG_ITEM_REGEX = /^task-bg:/;

export const TIME_FORMAT = "YYYY-MM-DD";

export const VIS_TIMELINE_MENU_WIDTH = 185;

export const visTimelineOptions: TimelineOptions = {
  min: "1999-01-01",
  max: moment().add(50, "years").format(TIME_FORMAT),

  zoomMin: 1000 * 60 * 10, // 1 minute
  // zoomMax: 1000 * 60 * 60 * 24 * 31 * 12, // around of a year

  width: "100%",
  height: "100%",

  zoomKey: "ctrlKey",
  zoomFriction: 10,

  stack: false,
  editable: false,
  horizontalScroll: true,
  verticalScroll: true,
  orientation: {
    axis: "both",
    item: "top",
  },

  dataAttributes: ["progress"],
};
