import { toAbsoluteUrl } from "_metronic/_helpers";
import { FormattedMessage } from "react-intl";
import SVG from "react-inlinesvg";
import cn from "clsx";

interface WarningMessageProps {
  className?: string;
  messageId: string;
  messageValues?: any;
}

export default function WarningMessage({
  className,
  messageId,
  messageValues,
}: WarningMessageProps) {
  return (
    <div className={cn("bg-warning-o-95 p-4 font-size-sm font-weight-bold", className)}>
      <span className="svg-icon svg-icon-md svg-icon-dark svg-icon-2x mr-2">
        <SVG src={toAbsoluteUrl("/media/svg/icons/Code/Warning-2.svg")} />
      </span>
      <FormattedMessage id={messageId} values={messageValues} />
    </div>
  );
}
