import * as React from "react";
import { Modal } from "react-bootstrap";
import { useDispatch } from "react-redux";
import * as actions from "../../../../../UsersManagement/_redux/usersActions";
import { CLIENT } from "../../../../../../_utils/userTypes";
import { Menu, MenuItem, Typeahead } from "react-bootstrap-typeahead";
import { formatDisplayNameIntl } from "../../../../../../_utils/userUtils";
import { UserDropdownItem } from "../../../../../../_components/UserDropdownItem";
import { FormattedMessage, useIntl } from "react-intl";
import { useAppSelector } from "../../../../../../../redux/hooks";
import { IUserOption } from "../../../../../../../data/schemas";

interface AddClientDialogProps {
  id: string;
  show: boolean;
  onHide: () => void;
}

export const AddClientDialog: React.FC<AddClientDialogProps> = ({ id, show, onHide }) => {
  const intl = useIntl();

  const { leadClients, clients, isLoadingUser, session, isLoadingLead } = useAppSelector(
    (state) => ({
      isLoadingUser: state.users.actionsLoading,
      isLoadingLead: state.leads.actionsLoading,
      clients: state.users.entities,
      leadClients: state.leadClients.entities,
      session: state.auth.session,
    })
  );

  const [newClients, setNewClients] = React.useState<IUserOption[]>([]);
  const [filteredUsers, setFilteredUsers] = React.useState<IUserOption[]>([]);

  const dispatch = useDispatch();
  const typeaheadRef = React.useRef<any>(null);

  React.useEffect(() => {
    if (leadClients) {
      console.log({ leadClients });
      setFilteredUsers(
        clients.filter(
          (u: IUserOption) =>
            session?.id !== u.id && !leadClients?.find((ps: IUserOption) => ps.id === u.id)
        )
      );
    }
  }, [isLoadingLead, clients, setFilteredUsers, leadClients, session]);

  const updateLeadClients = (leadClients: IUserOption[]) => {
    setNewClients(leadClients);
  };
  const submitLeadClients = () => {
    for (const client of newClients) {
      dispatch(actions.addClientToLead({ leadId: id, userId: client.id }));
    }
    setNewClients([]);
  };

  React.useEffect(() => {
    if (show) {
      dispatch(actions.fetchUsersByUserType({ userTypes: [CLIENT] }));
    }
  }, [show]);

  return (
    <Modal show={show} onHide={onHide} centered size={"lg"} backdrop="static" scrollable>
      <Modal.Header>
        <Modal.Title id="example-modal-sizes-title-lg">
          <FormattedMessage id="COMMON.ADD_CLIENT" />
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="pb-6">
        <div className="d-flex">
          <Typeahead
            id="share-typeahead-input"
            ref={typeaheadRef}
            paginate={false}
            multiple
            positionFixed
            labelKey={"displayName"}
            filterBy={["displayName", "email"]}
            selected={newClients}
            onChange={updateLeadClients}
            options={filteredUsers.map((u) => {
              const user = { ...u };
              user.displayName = formatDisplayNameIntl(intl, user, false);
              return user;
            })}
            placeholder={intl.formatMessage({ id: "COMMON.SELECT_CLIENT" })}
            disabled={isLoadingLead || isLoadingUser}
            className={"flex-grow-1"}
            renderMenu={(users, menuProps) => (
              <Menu {...menuProps}>
                {users.map((user, index) => (
                  <MenuItem option={user} position={index} key={index}>
                    <UserDropdownItem user={user} searchText={typeaheadRef?.current?.state?.text} />
                  </MenuItem>
                ))}
              </Menu>
            )}
          />{" "}
          <button
            type="button"
            onClick={submitLeadClients}
            disabled={isLoadingLead || isLoadingUser}
            className="btn btn-primary btn-elevate ml-4 px-6"
          >
            <FormattedMessage id="COMMON.ACTION.SAVE" />
          </button>
        </div>

        {leadClients?.length > 0 && clients?.length > 0 && (
          <div className="pt-6">
            {leadClients.map((r: IUserOption, index) => {
              const user = clients.find((u: IUserOption) => u.id === r.id);
              if (!user) {
                return <React.Fragment key={index} />;
              }
              return (
                <div
                  key={index}
                  className="d-flex align-items-center justify-content-between"
                  style={{ height: "50px" }}
                >
                  <UserDropdownItem user={user} />
                </div>
              );
            })}
          </div>
        )}
      </Modal.Body>

      <Modal.Footer>
        <div>
          <button
            type="button"
            onClick={onHide}
            disabled={isLoadingUser}
            className={`btn btn-light btn-elevate ml-4 ${
              isLoadingLead && "spinner spinner-dark spinner-right"
            }`}
          >
            <FormattedMessage id="COMMON.ACTION.DONE" />
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};
