import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import * as React from "react";
import { Dropdown, DropdownProps } from "react-bootstrap";
import { DropdownCustomToggler } from "../../../_metronic/_partials/dropdowns";
import SelectableContext from "react-bootstrap/esm/SelectableContext";
import { Portal } from "react-portal";

export interface DropdownMenuProps {
  icon?: string;
  disabled?: boolean;
  toggleClass?: string;
  containerClass?: string;
  buttonRef?: any;
  dropDirection?: "up" | "left" | "right" | "down";
  onClick?: DropdownProps["onToggle"];
  id?: string;
  dataCy?: string;
  alignRight?: boolean;
  withoutPortal?: boolean;
}

export const DropdownMenu: React.FC<DropdownMenuProps> = ({
  icon = "/media/svg/icons/General/Other2.svg",
  disabled,
  toggleClass,
  containerClass,
  children,
  buttonRef,
  dropDirection = "up",
  onClick,
  id,
  dataCy = "",
  alignRight = true,
  withoutPortal = false,
}) => {
  const content = (
    <Dropdown.Menu
      popperConfig={{
        strategy: "fixed",
      }}
      alignRight={alignRight}
      className="dropdown-menu dropdown-menu-left dropdown-menu-anim dropdown-menu-anim-down"
      rootCloseEvent={"mousedown"}
      onClick={(e: React.MouseEvent<HTMLDivElement>) => {
        e.stopPropagation();
      }}
    >
      {children}
    </Dropdown.Menu>
  );

  return (
    <>
      <SelectableContext.Provider value={null}>
        <Dropdown
          drop={dropDirection}
          className={containerClass}
          onToggle={onClick}
          id={id}
          data-cy={dataCy}
        >
          <Dropdown.Toggle
            as={DropdownCustomToggler}
            disabled={disabled}
            customClass={toggleClass}
            ref={buttonRef}
          >
            <span className="svg-icon svg-icon-md cursor-pointer">
              <SVG src={toAbsoluteUrl(icon)} />
            </span>
          </Dropdown.Toggle>

          {withoutPortal ? (
            <>{content}</>
          ) : (
            <Portal node={document && document.getElementById("layout-portal")}>{content}</Portal>
          )}
        </Dropdown>
      </SelectableContext.Provider>
    </>
  );
};
