import * as React from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";

import {
  requestLeadFileSignature,
  cancelLeadFileSignaturesRequest,
} from "app/modules/PropertiesManagement/_redux/leads/leadsActions";
import { fetchUsersByUserType } from "app/modules/UsersManagement/_redux/usersActions";

import { CLIENT, SUBCONTRACTOR, USER } from "app/_utils/userTypes";

import { RequestSignatureDialog } from "app/_components/RequestSignatureDialog";

import { useLeadFilesUIContext } from "../LeadFilesUIContext";

export const LeadRequestSignature: React.FunctionComponent = () => {
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(fetchUsersByUserType({ userTypes: [USER, CLIENT, SUBCONTRACTOR] }));
  }, []);

  const { leadFile } = useSelector(
    (state: any) => ({
      leadFile: state.leads?.leadForEdit?.current?.files,
    }),
    shallowEqual
  );

  return (
    <RequestSignatureDialog
      useFilesUIContext={useLeadFilesUIContext}
      fileList={leadFile}
      requestFileSignature={requestLeadFileSignature}
      requestCancelPrevious={cancelLeadFileSignaturesRequest}
    />
  );
};

export default LeadRequestSignature;
