import * as React from "react";
import {
  NumberMetricCardSubMetric,
  NumberMetricCardSubValueProps,
} from "./NumberMetricCardSubMetric";
import { SUFFIX_EURO_CURRENCY, SUFFIX_PERCENTAGE } from "../../../_utils/suffixUtils";
import { isNumber, isUndefined } from "lodash-es";

export const CurrencyMetricCardSubMetric: React.FC<NumberMetricCardSubValueProps> = ({
  secondValue,
  decimalScale = 2,
  fixedDecimalScale = true,
  suffix = SUFFIX_EURO_CURRENCY,
  ...props
}) => {
  return (
    <NumberMetricCardSubMetric
      {...props}
      suffix={suffix}
      decimalScale={decimalScale}
      fixedDecimalScale={fixedDecimalScale}
      secondValue={
        !isUndefined(secondValue) && {
          value: isNumber(secondValue) ? secondValue : secondValue?.value,
          suffix: SUFFIX_PERCENTAGE,
          decimalScale: 0,
          ...(isNumber(secondValue) ? {} : secondValue),
        }
      }
    />
  );
};
