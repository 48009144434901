import React, { Component } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { LayoutSplashScreen } from "../../../../_metronic/layout";
import * as auth from "../_redux/authRedux";
import { Auth } from "aws-amplify";
import { Intercom } from "../../../_utils/intercomUtils";

class Logout extends Component {
  async componentDidMount() {
    Intercom.shutdown();
    await Auth.signOut().catch((err) => console.log("signOut err:", err));
    this.props.logout();
    localStorage.clear();
    window.location.reload();
  }

  render() {
    const { hasTokens } = this.props;
    return hasTokens ? <LayoutSplashScreen /> : <Redirect to="/auth/login" />;
  }
}

export default connect(({ auth }) => ({ hasTokens: Boolean(auth.tokens) }), auth.actions)(Logout);
