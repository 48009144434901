import * as React from "react";
import { useLeadContext } from "../../../../LeadContext";
import {
  CurrencyMetricCard,
  MetricCard,
  MetricCardProps,
} from "../../../../../../../../../_components/MetricCard";
import { roundByTo100 } from "../../../../../../../../../_utils/mathUtils";

export interface CreditBreakdownProps {}

export const CreditBreakdown: React.FC<CreditBreakdownProps & MetricCardProps> = (props) => {
  const { initialCredit, remainingCredit, settings } = useLeadContext();

  const usedCredit = initialCredit - remainingCredit;
  const remainingReducedVATPrice =
    remainingCredit / ((settings?.defaultVat ?? 0) - (settings?.reducedVat ?? 0));

  const roundedPercents = roundByTo100([usedCredit, remainingCredit], initialCredit);

  return (
    <CurrencyMetricCard
      title="LEAD.INSTALMENTS.CREDIT_BREAKDOWN"
      dataCy="input-lead-instalments-credit"
      value={initialCredit}
      label="LEAD.INSTALMENTS.TOTAL_CREDIT"
      {...props}
    >
      <MetricCard.CurrencySubMetric
        dataCy="input-lead-instalments-used-credit"
        label="LEAD.INSTALMENTS.USED_CREDIT"
        value={usedCredit}
        secondValue={roundedPercents[0]}
      />
      <MetricCard.CurrencySubMetric
        dataCy="input-lead-instalments-remaining-credit"
        label="LEAD.INSTALMENTS.REMAINING_CREDIT"
        value={remainingCredit}
        secondValue={roundedPercents[1]}
      />
      <MetricCard.CurrencySubMetric
        dataCy="input-lead-instalments-remaining-reduced-vat-price"
        label="LEAD.INSTALMENTS.REMAINING_REDUCED_VAT_PRICE"
        value={remainingReducedVATPrice}
      />
    </CurrencyMetricCard>
  );
};
