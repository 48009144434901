import { useIntl } from "react-intl";
import * as Yup from "yup";

export interface UploadFileFormValidationSchemaProps {
  newFile: any;
}

export const UploadFileFormValidationSchema = ({
  newFile,
}: UploadFileFormValidationSchemaProps) => {
  const intl = useIntl();

  return Yup.object().shape({
    file: newFile ? Yup.mixed().required() : Yup.mixed(),
    friendlyName: Yup.string()
      .min(2, `${intl.formatMessage({ id: "AUTH.VALIDATION.MIN_SYMBOLS" })} 2`)
      .max(200, `${intl.formatMessage({ id: "AUTH.VALIDATION.MAX_SYMBOLS" })} 200`)
      .required(intl.formatMessage({ id: "AUTH.VALIDATION.FILE.NAME.REQUIRED" })),
    fileType: Yup.string().notOneOf(
      ["-"],
      intl.formatMessage({ id: "AUTH.VALIDATION.TYPE.REQUIRED" })
    ),
  });
};
