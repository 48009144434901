import React, { lazy, Suspense, useEffect } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { LayoutSplashScreen } from "../_metronic/layout";
import { DashboardPage } from "./modules/Dashboard/DashboardPage";
import { Auth } from "aws-amplify";
import { injectIntl, useIntl } from "react-intl";
import { connect } from "react-redux";
import * as auth from "./modules/Auth/_redux/authRedux";
import * as Yup from "yup";
import { ValidationError } from "yup";
import { isValidBIC, isValidIBANNumber } from "./_utils/formUtils";

import PropertiesManagementPage from "./modules/PropertiesManagement/pages/PropertiesManagementPage";
import { EntityDeleteDialogProvider } from "./modules/PropertiesManagement/pages/entity-delete-dialog/EntityDeleteDialogContext";
import { useAppDispatch } from "../redux/hooks";
import { fetchChats } from "./modules/Chat/_redux/chatActions";
import { FilesProvider } from "./modules/File/FilesProvider";
import { ConfirmationDialogProvider } from "./_components/ConfirmationDialog/ConfirmationDialogContext";
import { AuditTrailProvider } from "./contexts/AuditTrailContext";
import { LeadProvider } from "./contexts/LeadContext";
import { isKycAvailable } from "./_utils/configUtils";
import { WebsocketProvider } from "./modules/WebSocket/WebSocketProvider";
import printValue from "../_metronic/i18n/yup/util/printValue";
import { SessionProvider } from "./contexts/SessionContext";
import { LoadingProvider } from "./contexts/LoadingContext";
import { WizardProvider } from "./contexts/WizardContext";
import { TagProvider } from "./contexts/TagContext";

import { isEnabled as isTableauDashboarsEnabled } from "app/_components/Tableau/configs";
import { InvoiceManagement } from "./modules/Dashboard/InvoiceManagement/InvoiceManagement";
import { DashboardProvider } from "./modules/Dashboard/context/DashboardContext";
import { FileViewer } from "./modules/File/components/file-viewer/FileViewer";
import { FileViewerProvider } from "./contexts/FileViewerContext";

const ChatsPage = lazy(() => import("./modules/Chat/ChatsPage"));

const UsersManagementPage = lazy(() =>
  import("./modules/UsersManagement/pages/UsersManagementPage")
);

const SettingsManagementPage = lazy(() =>
  import("./modules/Settings/pages/SettingsManagementPage")
);

const KycAmlBoPage = lazy(() => import("./modules/kyc-v2/KycPage"));

const SignPage = lazy(() => import("./modules/Sign/pages/SignPage"));

const TableauPage = lazy(() => import("./modules/Tableau/TableauPage"));

function BasePage({ logout, requestUserSession }) {
  const intl = useIntl();
  Yup.addMethod(Yup.mixed, "bic", function () {
    return this.test("test-bic", function (value) {
      const { path, createError } = this;
      const test = isValidBIC(value, intl);
      if (typeof test === "string") {
        return createError({ path, message: test });
      }
      return true;
    });
  });

  Yup.addMethod(Yup.mixed, "iban", function () {
    return this.test("test-iban", function (value) {
      const { path, createError } = this;
      const test = isValidIBANNumber(value);
      if (test === false) {
        return createError({ path, message: "IBAN is too short" });
      } else if (test !== 1) {
        return createError({ path, message: "IBAN is incorrect" });
      }
      return true;
    });
  });
  ValidationError.formatError = (message, params) => {
    // Custom start
    if (typeof params.label === "string") {
      params.label = intl.formatMessage({ id: params.label });
    }
    // Custom end
    const path = params.label || params.path || "this";
    if (path !== params.path) {
      params = { ...params, path };
    }
    if (typeof message === "string") {
      return message.replace(/\$\{\s*(\w+)\s*\}/g, (_, key) => printValue(params[key]));
    }
    if (typeof message === "function") {
      return message(params);
    }
    return message;
  };
  useEffect(() => {
    Auth.currentAuthenticatedUser()
      .then(requestUserSession)
      .catch((err) => {
        console.log("currentAuthenticatedUser err:", err);
        Auth.signOut()
          .then((signOutRes) => console.log("signOutRes:", signOutRes))
          .catch((signOutErr) => console.log("signOutErr:", signOutErr));
        logout();
      });
  }, [logout, requestUserSession]);

  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(fetchChats());
  }, []);

  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <AuditTrailProvider>
        <LeadProvider>
          <ConfirmationDialogProvider>
            <EntityDeleteDialogProvider>
              <WebsocketProvider>
                <TagProvider>
                  <FilesProvider>
                    <FileViewerProvider>
                      <Switch>
                        {
                          /* Redirect from root URL to /dashboard. */
                          <Redirect exact from="/" to="/dashboard" />
                        }

                        <Route
                          path="/dashboard"
                          render={() => (
                            <Switch>
                              <DashboardProvider>
                                <Route path="/dashboard" exact component={DashboardPage} />
                                <Route
                                  path="/dashboard/invoice-management"
                                  exact
                                  component={InvoiceManagement}
                                />
                              </DashboardProvider>
                            </Switch>
                          )}
                        />

                        <Route path="/profile" component={UsersManagementPage} />
                        <Route path="/settings" component={SettingsManagementPage} />
                        <Route path="/sign" component={SignPage} />
                        <Route path="/chats" component={ChatsPage} />

                        {/*
                          // TODO: review - keep in mind this a temporary page to test the tableau embedded analytics
                         */}
                        {isTableauDashboarsEnabled && (
                          <Route path="/tableau" component={TableauPage} />
                        )}

                        {isKycAvailable() && <Route path="/flows" component={KycAmlBoPage} />}
                        {isKycAvailable() ? (
                          <SessionProvider>
                            <LoadingProvider>
                              <WizardProvider>
                                <Route path="/" component={PropertiesManagementPage} />
                              </WizardProvider>
                            </LoadingProvider>
                          </SessionProvider>
                        ) : (
                          <Route path="/" component={PropertiesManagementPage} />
                        )}
                      </Switch>
                      <FileViewer />
                    </FileViewerProvider>
                  </FilesProvider>
                </TagProvider>
              </WebsocketProvider>
            </EntityDeleteDialogProvider>
          </ConfirmationDialogProvider>
        </LeadProvider>
      </AuditTrailProvider>
    </Suspense>
  );
}

export default injectIntl(connect(null, auth.actions)(BasePage));
