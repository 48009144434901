import TitleWithGuideLink from "app/_components/TitleWithGuideLink";
import WarningMessage from "app/_components/WarningMessage";
import WarningModal from "app/_components/WarningModal";
import {
  filterPriceIndicesProperDate,
  getBasePriceIndex,
  getCompanyAvailablePricesIndices,
  getPriceIndexOptionLabel,
} from "app/_utils/priceIndexUtils";
import { ILead, IPriceIndex, ISettings } from "data/schemas";
import { IntlShape } from "react-intl";
import { ChangeEvent, useEffect, useState } from "react";
import { useTranslation } from "app/hooks/useTranslation";
import { Select } from "_metronic/_partials/controls";

interface LeadPriceIndexSelectorProps {
  lead: ILead;
  initialBaseLeadPriceIndex: IPriceIndex | null;
  settings: ISettings | null;
  priceIndices: IPriceIndex[];
  showModal: boolean;
  setShowModal: (show: boolean) => void;
  intl: IntlShape;
  values: any;
  handleChange: (e: React.ChangeEvent<any>) => void;
  customHandleChange: (e: CustomChangeEvent) => (handleChange: any) => void;
}

interface CustomChangeEvent {
  target: {
    name: string;
    value: IPriceIndex | undefined;
  };
}

const LeadPriceIndexSelector = ({
  lead,
  settings,
  showModal,
  setShowModal,
  intl,
  values,
  handleChange,
  customHandleChange,
}: LeadPriceIndexSelectorProps) => {
  const { fm } = useTranslation();

  const [initialBaseLeadPriceIndex, setInitialBaseLeadPriceIndex] = useState<IPriceIndex | null>(
    null
  );
  const [priceIndices, setPriceIndices] = useState<IPriceIndex[]>([]);

  useEffect(() => {
    if (settings?.priceIndices && lead.project) {
      const companyAvailablePriceIndices = getCompanyAvailablePricesIndices(settings);
      const filteredPriceIndices = filterPriceIndicesProperDate(
        companyAvailablePriceIndices,
        lead.project
      );
      setPriceIndices(filteredPriceIndices);

      const basePriceIndex = getBasePriceIndex(lead.project);
      const selectedOption = settings.priceIndices.find((item) => item.id === basePriceIndex?.id);
      if (basePriceIndex && selectedOption) {
        setInitialBaseLeadPriceIndex(selectedOption);
      }
    }
  }, [settings, lead.project]);

  if (!lead.project) return null;

  const handleSelectChange = (e: ChangeEvent<{ name?: string | undefined; value: unknown }>) => {
    if (lead.financialDocuments.length > 0) {
      setShowModal(!showModal);
      return;
    }

    const selectedOption = priceIndices.find((item) => item.id === e.target.value);
    customHandleChange({
      target: {
        name: "baseLeadPriceIndex",
        value: selectedOption,
      },
    })(handleChange);
  };

  const baseLeadPriceIndexId = values.baseLeadPriceIndex
    ? values.baseLeadPriceIndex.id
    : initialBaseLeadPriceIndex
    ? initialBaseLeadPriceIndex.id
    : "";

  return (
    <div className="mb-4 mt-2">
      <TitleWithGuideLink
        descriptionMessageId="PRICE_INDEX.BASE_INDEX.DESCRIPTION"
        guideLink={fm("INVOICE.PRICE_INDEX_INFO_MESSAGE.LINK")}
        titleMessageId="PRICE_INDEX.TITLE.SINGULAR"
        mainTitle={true}
      />
      {initialBaseLeadPriceIndex && settings?.priceIndices && priceIndices.length > 1 ? (
        <>
          <Select
            name="baseLeadPriceIndex"
            label={intl.formatMessage({
              id: "PRICE_INDEX.BASE_INDEX.TITLE",
            })}
            customFeedbackLabel=""
            className="text-wrap mb-2"
            onChange={handleSelectChange}
            value={baseLeadPriceIndexId}
          >
            {priceIndices.map((item) => (
              <option key={item.id} value={item.id}>
                {getPriceIndexOptionLabel(item, priceIndices, initialBaseLeadPriceIndex, intl)}
              </option>
            ))}
          </Select>
        </>
      ) : (
        <WarningMessage messageId="PRICE_INDEX.BASE_INDEX.LEAD.WARNING" />
      )}
      <WarningModal
        displayModal={showModal}
        modalTitle="PRICE_INDEX.BASE_INDEX.LEAD.WARNING_MODAL.TITLE"
        modalDescription="PRICE_INDEX.BASE_INDEX.LEAD.WARNING_MODAL.DESCRIPTION"
        setShowModal={setShowModal}
      />
    </div>
  );
};

export default LeadPriceIndexSelector;
