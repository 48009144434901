import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../../../_metronic/_helpers";
import * as React from "react";
import { useFileViewer } from "../../../../../hooks/useFileViewer";
import { KycFile } from "../../../Kyc";

interface KycFilePreviewButtonProps {
  file: KycFile;
}

export const KycFilePreviewButton = ({ file }: KycFilePreviewButtonProps): JSX.Element => {
  const { setCurrentFiles, openFileViewer } = useFileViewer();

  const openFileInViewer = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    setCurrentFiles([file]);
    openFileViewer();
  };

  return (
    <button
      className="btn btn-icon btn-light btn-hover-info btn-md ml-1 form-control-reduce-space"
      style={{ zIndex: 1 }}
      onClick={openFileInViewer}
    >
      <span className="svg-icon svg-icon-md svg-icon-info">
        <SVG src={toAbsoluteUrl("/media/svg/icons/General/Visible.svg")} />
      </span>
    </button>
  );
};
