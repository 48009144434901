import { KycEntityType, KycFlow, KycLegalType } from "../../Kyc";
import { KycTranslation } from "../../KycTranslation";
import { KycUploadComponent } from "../controls/kyc-upload/KycUploadComponent";
import { useKycFlow } from "../../../../hooks/useKycFlow";
import { KycFormTitle } from "../layout/KycFormTitle";
import { ReactNode } from "react";
import { KycStepKycAdditionalDocumentsLuOrForeignCompany } from "../KycAdditionalDocumentsLuOrForeignCompany";
import { KycAdditionalDocumentsListedCompany } from "../KycAdditionalDocumentsListedCompany";
import { KycAdditionalDocumentsInvestmentFund } from "../KycAdditionalDocumentsInvestmentFund";
import { KycAdditionalDocumentsTrust } from "../KycAdditionalDocumentsTrust";
import { getKycAdditionalDocumentsRelatedEntityType, isNaturalClient } from "../../KycUtils";

export const KycStepClientPage3 = (): JSX.Element => {
  const { values, isClientViewer } = useKycFlow();

  const getContent = (kyc: KycFlow) => {
    const { legalType }: { [key: string]: KycEntityType } = kyc?.form?.client ?? {};
    const components: ReactNode | any = {
      [KycLegalType.LEGAL_TYPE_LU_OR_FOREIGN_COMPANY]: (
        <KycStepKycAdditionalDocumentsLuOrForeignCompany />
      ),
      [KycLegalType.LEGAL_TYPE_LISTED_COMPANY]: <KycAdditionalDocumentsListedCompany />,
      [KycLegalType.LEGAL_TYPE_INVESTMENT_FUND]: <KycAdditionalDocumentsInvestmentFund />,
      [KycLegalType.LEGAL_TYPE_TRUST]: <KycAdditionalDocumentsTrust />,
    };
    return components?.[legalType] ?? <></>;
  };

  return (
    <>
      <KycFormTitle label={KycTranslation.STEPS_CLIENT_PAGE_3_TITLE} />
      {isNaturalClient(values) ? (
        <>
          {!isClientViewer && (
            <>
              <KycUploadComponent
                name="evidenceNPWorldCheck"
                subLabel={KycTranslation.EVIDENCE_DOCUMENT_NP_WORLD_CHECK}
                kycRelatedToEntity={getKycAdditionalDocumentsRelatedEntityType(values)}
                label={KycTranslation.EVIDENCE_DOCUMENT_NP_WORLD_CHECK_SHORT}
                multiple={true}
              />
              <KycUploadComponent
                name="evidenceNPInternetCheck"
                subLabel={KycTranslation.EVIDENCE_DOCUMENT_NP_INTERNET_CHECK}
                kycRelatedToEntity={getKycAdditionalDocumentsRelatedEntityType(values)}
                label={KycTranslation.EVIDENCE_DOCUMENT_NP_INTERNET_CHECK_SHORT}
                multiple={true}
              />
            </>
          )}
        </>
      ) : (
        <>{getContent(values)}</>
      )}
    </>
  );
};
