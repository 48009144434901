import React, { useMemo } from "react";

import { useFormsUIContext } from "../FormsUIProvider";
import { KycAmlFilloutCard } from "../../../../../_components/kyc-aml/KycAmlFilloutCard";

export const AuthRequestKycAmlFilloutCard = ({
  session,
  submitForm,
  saveFormFields,
  disabled,
  title,
}) => {
  const formsUIContext = useFormsUIContext();
  const formsUiProps = useMemo(() => {
    return {
      setFormData: formsUIContext.setFormData,
      formDataFields: formsUIContext.formData?.fields,
    };
  }, [formsUIContext]);

  const { setFormData, formDataFields } = formsUiProps;

  return (
    <KycAmlFilloutCard
      setFormData={setFormData}
      session={session}
      disabled={disabled}
      saveFormFields={saveFormFields}
      formDataFields={formDataFields}
      submitForm={submitForm}
      title={title}
    />
  );
};
