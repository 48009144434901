import * as React from "react";
import { useIntl } from "react-intl";
import { Select } from "../../../_metronic/_partials/controls";
import {
  filterPriceIndicesProperDate,
  getActivePriceIndexIdForDate,
  getPriceIndexById,
  getPriceIndexOptionLabel,
} from "app/_utils/priceIndexUtils";
import { IPriceIndex, IProject } from "../../../data/schemas";
import { useFormikContext } from "formik";
import { cloneDeep } from "lodash-es";
import { ILeadFileInvoiceForm } from "../../modules/PropertiesManagement/pages/leads/lead-files/lead-file-edit-dialog/invoice/LeadFileInvoiceForm";

interface PriceIndexSelectProps {
  priceIndices: IPriceIndex[];
  basePriceIndex?: IPriceIndex;
  project: IProject;
}

export const PriceIndexSelect: React.FunctionComponent<PriceIndexSelectProps> = ({
  priceIndices,
  basePriceIndex,
  project,
}) => {
  const intl = useIntl();

  const [activePriceIndexId, setActivePriceIndexId] = React.useState<string>();
  const { values, setFieldValue, setValues } = useFormikContext<ILeadFileInvoiceForm>();

  const [disabled, setDisabled] = React.useState<boolean>(false);

  const [priceIndexOptions, setPriceIndexOptions] = React.useState<any>([]);

  const [fiteredPriceIndices, setFiteredPriceIndices] = React.useState(priceIndices);

  React.useEffect(() => {
    if (priceIndices && project) {
      setFiteredPriceIndices(filterPriceIndicesProperDate(priceIndices, project));
      setActivePriceIndexId(getActivePriceIndexIdForDate(fiteredPriceIndices));
    }
  }, [priceIndices]);

  React.useEffect(() => {
    if (activePriceIndexId) {
      handleValueChangePriceIndex(getPriceIndexById(priceIndices, activePriceIndexId));
    }
  }, [activePriceIndexId]);

  const handleValueChangePriceIndex = (value: any) => {
    const res: any = cloneDeep(values);
    res.priceIndex = value;
    res.priceIndexId = activePriceIndexId;
    setValues(res);
  };

  React.useEffect(() => {
    setFieldValue("priceIndex", getPriceIndexById(priceIndices, values?.priceIndexId));
  }, [values?.priceIndexId]);

  React.useEffect(() => {
    setPriceIndexOptions(getPriceIndexOptions(fiteredPriceIndices));
  }, [fiteredPriceIndices]);

  const getPriceIndexOptions = (priceIndices: IPriceIndex[]) => {
    const options: any[] = priceIndices?.map(
      (priceIndex: IPriceIndex) =>
        priceIndex && (
          <option key={priceIndex.id} value={priceIndex.id}>
            {getPriceIndexOptionLabel(priceIndex, priceIndices, basePriceIndex, intl)}
          </option>
        )
    );
    setDisabled(options?.length < 2);
    return options;
  };

  return (
    <div>
      <Select
        name="priceIndexId"
        label={""}
        customFeedbackLabel={" "}
        className="text-wrap mt-1"
        disabled={disabled}
      >
        {priceIndexOptions}
      </Select>
    </div>
  );
};
