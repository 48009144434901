export enum SurfaceType {
  CELLARS = "cellars",
  GARDENS = "gardens",
  TERRACES = "terraces",
  BALCONIES = "balconies",
  PARKINGS = "parkings",
  COMBINE_PLOTS = "combinePlots",
}

export enum SurfaceStatus {
  PRIVATE = "PRIVATE",
  COMMON = "COMMON",
}

export enum ParkingType {
  INDOOR = "INDOOR",
  OUTDOOR = "OUTDOOR",
}
