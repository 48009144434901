import { ISettings, LeadFinancialDocumentPriceLine } from "../../../../../../../data/schemas";
import { ILeadInstalment, ILeadSupplement } from "../definitions";
import { accurateFloatOperation } from "../../../../../../_utils/mathUtils";

export interface ProcessFinancialFileLineProps {
  revenue: ILeadInstalment | ILeadSupplement;
  line: LeadFinancialDocumentPriceLine;
  settings?: ISettings;
  invoiceStatus: string;
  priceIndexChangeCoef: number;
}

export const processFinancialFileLine = ({
  revenue,
  line,
  settings,
  invoiceStatus,
  priceIndexChangeCoef,
}: ProcessFinancialFileLineProps) => {
  let priceWithReducedVat = 0;
  if (!revenue.hasOwnProperty("invoiced")) {
    revenue.amountInclPriceIndex = 0;
    revenue.indexedAmount = 0;
    revenue.invoiced = 0;
    revenue.invoicedInclVAT = 0;
    revenue.paymentStatusCount[invoiceStatus] = 0;
    revenue.taxes = 0;
    revenue.amountInclVAT = 0;
    revenue.amountDefaultVAT = 0;
    revenue.paid = 0;
    revenue.paidInclVAT = 0;
    if (line.vat === 0) {
      revenue.amount0VAT = 0;
    }
  }

  const indexedAmount = accurateFloatOperation(line.amount * priceIndexChangeCoef, 10);
  const lineAmountInclPriceIndex = accurateFloatOperation(line.amount + indexedAmount, 10);
  const vatAmount = accurateFloatOperation(lineAmountInclPriceIndex * line.vat, 10);

  revenue.indexedAmount! += indexedAmount;
  revenue.amountInclPriceIndex! += lineAmountInclPriceIndex;
  revenue.amountInclVAT! += lineAmountInclPriceIndex + vatAmount;
  revenue.invoiced! += lineAmountInclPriceIndex;
  revenue.invoicedInclVAT! += lineAmountInclPriceIndex + vatAmount;

  // Have to move this because already calculated for supplement
  revenue.paymentStatusCount[invoiceStatus] += 1;
  //

  revenue.taxes! += vatAmount;
  if (line.vat === settings?.reducedVat) {
    revenue.credit =
      (revenue.credit ?? 0) +
      lineAmountInclPriceIndex * ((settings?.defaultVat ?? 0) - (settings?.reducedVat ?? 0));
    priceWithReducedVat += lineAmountInclPriceIndex;
    revenue.amountReducedVAT = (revenue.amountReducedVAT ?? 0) + lineAmountInclPriceIndex;
  } else if (line.vat === settings?.defaultVat) {
    revenue.amountDefaultVAT! += lineAmountInclPriceIndex;
  } else if (line.vat === 0) {
    revenue.amount0VAT = (revenue.amount0VAT ?? 0) + lineAmountInclPriceIndex;
  }
  if (invoiceStatus === "PAID") {
    revenue.paid! += lineAmountInclPriceIndex;
    revenue.paidInclVAT! += lineAmountInclPriceIndex + vatAmount;
  }
  return priceWithReducedVat;
};
