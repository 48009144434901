import { Card, CardBody } from "_metronic/_partials/controls";
import { LoaderSpinner } from "./LoaderSpinner";

export const CardLoaderSpinner = () => {
  return (
    <Card>
      <CardBody>
        <LoaderSpinner
          style={{ height: "40vh" }}
          className={"justify-content-center align-items-center"}
        />
      </CardBody>
    </Card>
  );
};
