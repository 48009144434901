import { createContext, FC, ReactNode, useState } from "react";
import PropTypes from "prop-types";
import { API } from "aws-amplify";

export interface PublicRequestsContextValue {
  errorMessage: string | undefined;
  isLoading: boolean;
  isSigning: boolean;
  isInvalidOtp: boolean;

  formData: any;
  setFormData: (data: any) => void;

  user: any;
  settings: any;
  action: any;
  file: any;

  getPublicRequestsRelatedFile: (
    requestId: string | undefined,
    fileId: string | undefined
  ) => Promise<void>;

  updateGDPR: (requestId?: string) => Promise<void>;

  getPublicRequestsKycAml: (requestId?: string) => Promise<void>;
  postPublicRequestsKycAml: (
    requestId: string | undefined,
    formDataUploadBody: any
  ) => Promise<void>;

  getPublicRequestSignature: (requestId?: string) => Promise<void>;
  getPublicRequestSignatureOtp: (requestId?: string) => Promise<void>;
  postPublicRequestSignature: (requestId?: string, otpCode?: string) => Promise<any>;

  setIsInvalidOtp: (flag: boolean) => void;
}

interface PublicRequestsProviderProps {
  children: ReactNode;
}

export const PublicRequestsContext = createContext<PublicRequestsContextValue>({
  errorMessage: undefined,
  isLoading: false,
  isSigning: false,
  isInvalidOtp: false,

  formData: {},
  setFormData: Function,

  file: undefined,
  user: undefined,
  action: undefined,
  settings: undefined,

  updateGDPR: (requestId) => Promise.resolve(),

  getPublicRequestsRelatedFile: (requestId, fileId) => Promise.resolve(),

  getPublicRequestsKycAml: (requestId) => Promise.resolve(),
  postPublicRequestsKycAml: (requestId) => Promise.resolve(),

  getPublicRequestSignature: (requestId) => Promise.resolve(),
  getPublicRequestSignatureOtp: (requestId) => Promise.resolve(),
  postPublicRequestSignature: (requestId, otpCode) => Promise.resolve(true),

  setIsInvalidOtp: (flag) => {},
});

export const PublicRequestsProvider: FC<PublicRequestsProviderProps> = (props) => {
  const { children } = props;

  const [isLoading, setIsLoading] = useState(false);
  const [isSigning, setIsSigning] = useState(false);
  const [isInvalidOtp, setInnerIsInvalidOtp] = useState(false);

  const [file, setFile] = useState<undefined | Object>();
  const [user, setUser] = useState<undefined | Object>();
  const [action, setAction] = useState<undefined | Object>();
  const [settings, setSettings] = useState<undefined | Object>();

  const [formData, setFormDataDefault] = useState<undefined | Object>(undefined);

  const [errorMessage, setErrorMessage] = useState<string | undefined>();

  const errorMessageByStatusCode = (statusCode: number) => {
    switch (statusCode) {
      case 401:
        return "UNAUTH_REQUEST.ERROR.EXPIRED_ACCESS";
      case 403: {
        return "UNAUTH_REQUEST.ERROR.NOT_PERMISSION";
      }
      case 404:
      default:
        return "UNAUTH_REQUEST.ERROR.RANDOM";
    }
  };

  const setIsInvalidOtp = (flag = false) => setInnerIsInvalidOtp(flag);

  //--------------------------------------------------------------------------//
  // @begin: signature

  const getPublicRequestSignature = async (requestId?: string) => {
    if (!requestId) {
      setErrorMessage(errorMessageByStatusCode(404));
      return;
    }

    setIsLoading(true);

    return API.get("API", `/requests/${requestId}/signature`, {})
      .then((response) => {
        const { settings, user } = response || {};

        if (!settings || !user) {
          setErrorMessage(errorMessageByStatusCode(404));
        }

        setUser(user);
        setSettings(settings);

        return response;
      })
      .catch((error) => {
        setErrorMessage(errorMessageByStatusCode(error.response.status));

        throw error;
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const getPublicRequestSignatureOtp = async (requestId?: string) => {
    setIsInvalidOtp(false);

    if (!requestId) {
      setErrorMessage(errorMessageByStatusCode(404));
      return;
    }

    setIsLoading(true);

    return API.get("API", `/requests/${requestId}/signature/otp`, {})
      .catch((error) => {
        const statusCode = error.response.status;

        if (statusCode === 425) {
          setErrorMessage("");
        } else {
          setErrorMessage(errorMessageByStatusCode(statusCode));
        }

        throw error;
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const postPublicRequestSignature = async (requestId?: string, otpCode?: string) => {
    if (!requestId || !otpCode) {
      setErrorMessage(errorMessageByStatusCode(404));

      return;
    }

    setIsInvalidOtp(false);
    setIsSigning(true);

    return API.post("API", `/requests/${requestId}/signature/sign`, { body: { code: otpCode } })
      .then((response) => {
        if (!response) {
          setErrorMessage(errorMessageByStatusCode(404));
        } else {
          const { settings } = response || {};

          if (!settings) {
            setErrorMessage(errorMessageByStatusCode(404));
          }

          setSettings(settings);
        }

        return response;
      })
      .catch((error) => {
        const statusCode = error.response.status;

        if (statusCode === 403) {
          setIsInvalidOtp(true);
          setErrorMessage("");
        } else {
          setErrorMessage(errorMessageByStatusCode(statusCode));
        }

        throw error;
      })
      .finally(() => {
        setIsSigning(false);
      });
  };

  // @end: signature
  //--------------------------------------------------------------------------//
  // @begin: kyc-aml

  const getPublicRequestsKycAml = async (requestId: string | undefined) => {
    setIsLoading(true);
    API.get("API", `/requests/${requestId}/kyc-aml`, {})
      .then((response) => {
        setUser(response?.user);
        setAction(response?.action);
        setSettings(response?.settings);
      })
      .catch((error) => {
        setErrorMessage(errorMessageByStatusCode(error.response.status));
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const postPublicRequestsKycAml = async (
    requestId: string | undefined,
    formDataUploadBody: any
  ) => {
    setIsLoading(true);
    API.post("API", `/requests/${requestId}/kyc-aml`, { body: formDataUploadBody })
      .then((response) => {
        setAction(response?.action);
        setFile(response?.file);
      })
      .catch((error) => {
        setErrorMessage(errorMessageByStatusCode(error.response.status));
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  // @end: kyc-aml
  //--------------------------------------------------------------------------//

  const getPublicRequestsRelatedFile = async (
    requestId: string | undefined,
    fileId: string | undefined
  ) => {
    setIsLoading(true);
    API.get("API", `/requests/${requestId}/files/${fileId}`, {})
      .then((response) => {
        setFile(response);
      })
      .catch((error) => {
        setErrorMessage(errorMessageByStatusCode(error.response.status));
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  //--------------------------------------------------------------------------//

  const updateGDPR = async (requestId: string | undefined) => {
    setIsLoading(true);
    API.post("API", `/requests/${requestId}/gdpr`, {})
      .then((response) => {
        setUser(response?.user);
      })
      .catch((error) => {
        setErrorMessage(errorMessageByStatusCode(error.response.status));
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  //--------------------------------------------------------------------------//

  const setFormData = (data: Object) => {
    setFormDataDefault(data);
  };

  //--------------------------------------------------------------------------//

  return (
    <PublicRequestsContext.Provider
      value={{
        errorMessage,
        isLoading,
        isSigning,
        isInvalidOtp,
        file,
        settings,
        formData,
        setFormData,
        user,
        action,
        updateGDPR,
        getPublicRequestsRelatedFile,
        getPublicRequestsKycAml,
        postPublicRequestsKycAml,
        getPublicRequestSignature,
        getPublicRequestSignatureOtp,
        postPublicRequestSignature,
        setIsInvalidOtp,
      }}
    >
      {children}
    </PublicRequestsContext.Provider>
  );
};

PublicRequestsProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export const PublicRequestsConsumer = PublicRequestsContext.Consumer;
