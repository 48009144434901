import * as React from "react";

export interface FormSectionFieldProps {
  className?: string;
  style?: React.CSSProperties;
  label?: React.ReactNode;
}

export const FormSectionField: React.FunctionComponent<FormSectionFieldProps> = ({
  className = "col-sm-6 col-md-3 mt-3",
  style,
  label,
  children,
}) => (
  <div className={className} style={style}>
    {label && <label>{label}</label>}
    {children}
  </div>
);

export default FormSectionField;
