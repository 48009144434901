// React bootstrap table next =>
// DOCS: https://react-bootstrap-table.github.io/react-bootstrap-table2/docs/
// STORYBOOK: https://react-bootstrap-table.github.io/react-bootstrap-table2/storybook/index.html
import { useEffect, useMemo, useState } from "react";
import cn from "clsx";
import SVG from "react-inlinesvg";
import { shallowEqual } from "react-redux";
import { useIntl } from "react-intl";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, { PaginationProvider } from "react-bootstrap-table2-paginator";
import { useDidMount } from "rooks";

import { useAppDispatch, useAppSelector } from "redux/hooks";

import { NoRecordsFoundMessage, sortCaret, toAbsoluteUrl } from "_metronic/_helpers";
import { Pagination } from "_metronic/_partials/controls";

import { canDelete, canReadAll, canSeeHiddenLine, canShare } from "app/_utils/authUtils";
import { sortDateFunc } from "app/_utils/columnUtils";
import {
  ActionsColumnFormatter,
  DateTimeColumnFormatter,
  PhotoColumnFormatter,
} from "app/_components/column-formatters";
import { useSetupAmplifyAxios } from "app/_components/SetupAmplifyAxios";

import { useEntityDeleteDialogContext } from "../../entity-delete-dialog/EntityDeleteDialogContext";
import * as actions from "../../../_redux/projects/projectsActions";

import * as uiHelpers from "../ProjectsUIHelpers";
import { ProjectShareDialog } from "../project-dialogs/ProjectShareDialog";
import { useProjectsUIContext } from "../ProjectsUIContext";

import { ProjectsMacroView } from "./ProjectsMacroView";
import { useSearch } from "../../../../../hooks/useSearch";
import EmptyState from "app/_components/EmptyState/EmptyState";
import { PROJECT } from "../../../../../_utils/dataTypes";
import { columnTags } from "../../../../../_utils/tagsUtils";

export function ProjectsTable() {
  const intl = useIntl();

  const projectsUIContext = useProjectsUIContext();
  const { shouldRedirectOnStatus404 } = useSetupAmplifyAxios();
  const { setEntities, entitiesFiltered } = useSearch();

  const projectsUIProps = useMemo(() => {
    return {
      setIds: projectsUIContext.setIds,
      queryParams: projectsUIContext.queryParams,
      filters: projectsUIContext.filters,
      newProjectButtonClick: projectsUIContext.newProjectButtonClick,
      openProjectPage: projectsUIContext.openProjectPage,
      readOnly: projectsUIContext.readOnly,
      filterList: projectsUIContext.filterList,
    };
  }, [projectsUIContext]);

  const { entities, listLoading, groups, username, session } = useAppSelector(
    (state) => ({
      entities: state.projects.entities,
      listLoading: state.projects.listLoading,
      groups: state.auth.groups,
      session: state.auth.session,
      username: state.auth.session?.id,
    }),
    shallowEqual
  );
  const [isViewList, setIsViewList] = useState(true);
  const dispatch = useAppDispatch();

  useEffect(() => {
    projectsUIProps.setIds([]);
    if (canReadAll(groups, session, "PROJECT")) {
      dispatch(actions.fetchProjects(projectsUIProps.queryParams));
    } else {
      dispatch(actions.fetchProjectsForReseller(username));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectsUIProps.queryParams, dispatch]);

  const { setDeleteEntityDialog } = useEntityDeleteDialogContext();
  const openDeleteProjectDialog = (id) => {
    setDeleteEntityDialog({
      action: { fn: actions.deleteProject, props: id },
      entityType: "PROJECT",
    });
  };

  const [openShareProjectDialog, setOpenShareProjectDialog] = useState(false);

  // Table columns
  const columns = [
    {
      dataField: "id",
      text: "",
      sort: false,
      formatter: PhotoColumnFormatter,
      formatExtraData: {
        icon: "fas fa-layer-group text-info icon-md",
      },
    },
    {
      dataField: "name",
      headerClasses: "text-left text-nowrap",
      classes: "text-left",
      text: intl.formatMessage({
        id: "COMMON.NAME",
      }),
      sort: true,
      sortCaret: sortCaret,
    },
    {
      dataField: "address.city",
      headerClasses: "text-left text-nowrap",
      classes: "text-left",
      text: intl.formatMessage({
        id: "ADDRESS.CITY",
      }),
      sort: true,
      sortCaret: sortCaret,
    },
    {
      dataField: "productsCount",
      headerClasses: "text-left text-nowrap",
      classes: "text-left",
      text: intl.formatMessage({
        id: "PRODUCT.TITLE",
      }),
      sort: true,
      sortCaret: sortCaret,
    },
    {
      dataField: "availability",
      text: intl.formatMessage({
        id: "PROJECT.INFO.AVAILABILITY_DATE",
      }),
      sort: true,
      headerClasses: "text-left text-nowrap",
      classes: "text-left",
      sortCaret: sortCaret,
      formatter: DateTimeColumnFormatter,
      formatExtraData: { intl, showTime: false },
      sortFunc: sortDateFunc,
    },
    columnTags(groups, session, intl, PROJECT),
    {
      dataField: "startDate",
      headerClasses: "text-left text-nowrap",
      classes: "text-left",
      text: intl.formatMessage({
        id: "COMMON.START_DATE",
      }),
      sort: true,
      sortCaret: sortCaret,
      formatter: DateTimeColumnFormatter,
      formatExtraData: { intl, showTime: false },
      sortFunc: sortDateFunc,
    },
    {
      dataField: "action",
      text: intl.formatMessage({
        id: "COMMON.ACTION",
      }),
      formatter: ActionsColumnFormatter,
      formatExtraData: {
        openShareProjectDialog: setOpenShareProjectDialog,
        openDeleteDialog: openDeleteProjectDialog,
        actionList: ["SHARE", "TRASH"],
        canDelete: (row) => {
          if (row.budgetsCount) {
            if (row.hiddenLineExist) {
              return (
                canDelete(groups, session, "PROJECT") &&
                canDelete(groups, session, "BUDGET") &&
                canSeeHiddenLine(groups, session)
              );
            }
            return canDelete(groups, session, "PROJECT") && canDelete(groups, session, "BUDGET");
          }
          return canDelete(groups, session, "PROJECT");
        },
        canShare: (row) => {
          return canShare(groups, session, "PROJECT");
        },
        entityType: "PROJECT",
      },
      headerClasses: "text-right",
      classes: "text-right",
      style: {
        minWidth: "100px",
      },
      hidden: projectsUIProps.readOnly,
    },
  ];

  useEffect(() => {
    setEntities(entities);
  }, [entities]);

  useDidMount(() => {
    shouldRedirectOnStatus404(false);
  });

  // Table pagination properties
  const paginationOptions = {
    custom: true,
    totalSize: entitiesFiltered.length,
    sizePerPageList: uiHelpers.sizePerPageList,
    sizePerPage: projectsUIProps.filters.pageSize,
    page: projectsUIProps.filters.pageNumber,
  };

  return (
    <>
      <ProjectShareDialog
        show={!!openShareProjectDialog}
        id={openShareProjectDialog}
        onHide={() => {
          setOpenShareProjectDialog(false);
        }}
        loadProject={true}
      />
      <div data-cy="table-list">
        <EmptyState
          stateLocation={"PROJECT"}
          entitiesLength={entities.length}
          listLoading={listLoading}
          entityUIContextAction={projectsUIContext.newProjectButtonClick}
        >
          <>
            <div className={"d-flex justify-content-end mb-4"}>
              <div>
                <button
                  type={"button"}
                  className={cn(
                    "btn font-weight-bold rounded-right-0",
                    isViewList ? "btn-primary" : "btn-light-primary btn-hover-primary"
                  )}
                  onClick={() => {
                    setIsViewList(true);
                  }}
                >
                  <span className={"svg-icon svg-icon-md"}>
                    <SVG src={toAbsoluteUrl("/media/svg/icons/Text/Bullet-list.svg")} />
                  </span>
                </button>
              </div>
              <div>
                <button
                  type={"button"}
                  className={cn(
                    "btn font-weight-bold rounded-left-0",
                    isViewList ? "btn-light-primary btn-hover-primary" : "btn-primary"
                  )}
                  onClick={() => {
                    setIsViewList(false);
                  }}
                >
                  <span className={"svg-icon svg-icon-md"}>
                    <SVG src={toAbsoluteUrl("/media/svg/icons/Layout/Layout-grid.svg")} />
                  </span>
                </button>
              </div>
            </div>
            <PaginationProvider pagination={paginationFactory(paginationOptions)}>
              {({ paginationProps, paginationTableProps }) => {
                return (
                  <Pagination isLoading={listLoading} paginationProps={paginationProps}>
                    {isViewList ? (
                      <BootstrapTable
                        wrapperClasses="table-responsive"
                        classes="table table-head-custom table-vertical-center"
                        bootstrap4
                        bordered={false}
                        keyField="id"
                        data={entitiesFiltered || []}
                        columns={columns}
                        defaultSorted={uiHelpers.defaultSorted}
                        noDataIndication={() =>
                          listLoading ? "" : <NoRecordsFoundMessage entities={entitiesFiltered} />
                        }
                        hover
                        rowEvents={{
                          onClick: (e, row, rowIndex) => {
                            projectsUIProps.openProjectPage(row);
                          },
                        }}
                        rowStyle={{ cursor: "pointer" }}
                        {...paginationTableProps}
                      />
                    ) : (
                      <ProjectsMacroView
                        projects={entitiesFiltered || []}
                        shareProjectDialog={[openShareProjectDialog, setOpenShareProjectDialog]}
                        openDeleteProjectDialog={openDeleteProjectDialog}
                      />
                    )}
                  </Pagination>
                );
              }}
            </PaginationProvider>
          </>
        </EmptyState>
      </div>
    </>
  );
}
