import React, { useEffect, useRef, useState } from "react";
import { Modal } from "react-bootstrap";
import { shallowEqual, useDispatch } from "react-redux";
import * as actions from "../../../_redux/projects/projectsActions";
import * as actionsUsers from "../../../../UsersManagement/_redux/usersActions";

import { formatDisplayNameIntl } from "../../../../../_utils/userUtils";
import { Menu, MenuItem, Typeahead } from "react-bootstrap-typeahead";
import { FormattedMessage, useIntl } from "react-intl";
import { UserDropdownItem } from "../../../../../_components/UserDropdownItem";
import { SUBCONTRACTOR } from "../../../../../_utils/userTypes";
import { useAppSelector } from "../../../../../../redux/hooks";
import { canCreate } from "../../../../../_utils/authUtils";
import {
  mergeWithInitUser,
  saveUserFields,
  submitUserUtils,
} from "../../../../../_utils/userFormUtils";
import { UserEditForm } from "../../../../UsersManagement/pages/profile/profile-edit/UserEditForm";
import { cloneDeep } from "lodash";
import { LoaderSpinner } from "../../../../../_components/LoaderSpinner";

export function ProjectSubcontractorsDialog({ id, show, onHide, loadProject = false }) {
  const intl = useIntl();
  const [filteredUsers, setFilteredUsers] = useState([]);
  const dispatch = useDispatch();
  const { isLoadingProject, users, userForEdit, project, session, groups, isLoadingUser } =
    useAppSelector(
      (state) => ({
        isLoadingProject: state.projects.actionsLoading || state.projects.listLoading,
        users: state.users.entities,
        userForEdit: state.users.entityForEdit.current,
        project: state.projects.projectForEdit.saved,
        session: state.auth.session,
        groups: state.auth.groups,
        isLoadingUser: state.users.actionsLoading || state.users.listLoading,
      }),
      shallowEqual
    );

  const [hiddenSubcontractorForm, setHiddenSubcontractorForm] = useState(false);

  useEffect(() => {
    if (!id) {
      onHide();
    } else {
      if (loadProject) {
        dispatch(actions.fetchProject(id));
      }
      dispatch(actionsUsers.fetchUsersByUserType({ userTypes: [SUBCONTRACTOR] }));
    }
  }, [id]);

  useEffect(() => {
    if (!isLoadingProject && project) {
      setFilteredUsers(
        users.filter(
          (u) =>
            session?.id !== u.id && !project.projectSubcontractors?.find((ps) => ps.userId === u.id)
        )
      );
    }
  }, [isLoadingProject, users, setFilteredUsers, project, session]);

  const [newProjectSubcontractors, setNewProjectSubcontractors] = useState([]);
  const [isEmailAlreadyExists, setIsEmailAlreadyExists] = useState(false);

  const userEditFormCallbackRef = useRef(null);

  const updateProjectSubcontractors = (projectSubcontractors) => {
    setNewProjectSubcontractors(projectSubcontractors);
  };

  const submitProjectSubcontractors = () => {
    for (const user of newProjectSubcontractors) {
      dispatch(actions.createProjectSubcontractor({ projectId: project.id, userId: user.id }));
    }
    setNewProjectSubcontractors([]);
    onHide();
  };

  const typeaheadRef = useRef(null);

  const submitUser = () =>
    submitUserUtils({
      intl,
      dispatch,
      user: userForEdit,
      setIsEmailAlreadyExists,
      isEmailAlreadyExists,
      actionFn: (notes) =>
        dispatch(
          actionsUsers.createUser(
            mergeWithInitUser(
              {
                ...userForEdit,
                userTypes: ["SUBCONTRACTOR"],
                notes,
                username: userForEdit.email,
              },
              "SUBCONTRACTOR"
            )
          )
        ).then((res) => {
          if (res?.response?.status && res?.response?.status === 409) {
            setIsEmailAlreadyExists(true);
          } else if (res?.id) {
            const user = cloneDeep(res);
            user.displayName = formatDisplayNameIntl(intl, user, false);
            updateProjectSubcontractors([...newProjectSubcontractors, user]);
            // setNewProjectSubcontractors(newProjectSubcontractors.push(res));
            setHiddenSubcontractorForm(false);
            // history.push(`/${userTypePath}/${res.id}${history.location.search}`);
          }
        }),
    });

  return (
    <Modal show={show} onHide={onHide} centered size={"lg"} backdrop="static" scrollable>
      <Modal.Header closeButton>
        <Modal.Title>
          <FormattedMessage id={"PROJECT.ADD_SUBCONTRACTORS_TO"} values={{ name: project?.name }} />
          {hiddenSubcontractorForm && (
            <>
              {` - `} <FormattedMessage id="PROJECT.CREATE_SUBCONTRACTOR.BUTTON" />
            </>
          )}
        </Modal.Title>
      </Modal.Header>

      {hiddenSubcontractorForm ? (
        <>
          <Modal.Body>
            <UserEditForm
              user={mergeWithInitUser(userForEdit, "SUBCONTRACTOR")}
              submitUser={submitUser}
              disabled={false}
              hideUserAvatar={true}
              isEmailAlreadyExists={isEmailAlreadyExists}
              isSameUser={false}
              onSubmitRef={(callback) => {
                userEditFormCallbackRef.current = callback;
              }}
              originalUser={mergeWithInitUser({}, "SUBCONTRACTOR")}
              saveUserFields={(key, value) => saveUserFields(`${key}`, value)(dispatch)}
            />
          </Modal.Body>
          <Modal.Footer>
            <div>
              {isLoadingUser ? (
                <LoaderSpinner />
              ) : (
                <>
                  <button
                    type="button"
                    onClick={() => setHiddenSubcontractorForm(false)}
                    className={`btn btn-light btn-elevate`}
                  >
                    <FormattedMessage id="COMMON.ACTION.BACK" />
                  </button>
                  <button
                    type="submit"
                    onClick={(e) => {
                      userEditFormCallbackRef?.current(e);
                    }}
                    className={`btn btn-primary btn-elevate ml-4 ${
                      isLoadingProject && "spinner spinner-dark spinner-right"
                    }`}
                  >
                    <FormattedMessage id="PROJECT.CREATE_SUBCONTRACTOR.BUTTON" />
                  </button>
                </>
              )}
            </div>
          </Modal.Footer>
        </>
      ) : (
        <>
          <Modal.Body className="border-bottom pb-6 ">
            <div className="d-flex">
              <Typeahead
                id="share-typeahead-input"
                ref={typeaheadRef}
                multiple
                positionFixed
                labelKey={"displayName"}
                filterBy={["displayName", "email"]}
                selected={newProjectSubcontractors}
                onChange={(selected) => {
                  updateProjectSubcontractors(selected);
                }}
                options={filteredUsers.map((u) => {
                  const user = { ...u };
                  user.displayName = formatDisplayNameIntl(intl, user, false);
                  return user;
                })}
                placeholder={intl.formatMessage({ id: "COMMON.SELECT_USER" })}
                disabled={isLoadingProject || isLoadingUser}
                className={"flex-grow-1"}
                renderMenu={(users, menuProps) => (
                  <Menu {...menuProps}>
                    {users.map((user, index) => (
                      <MenuItem option={user} position={index} key={index}>
                        <UserDropdownItem
                          user={user}
                          searchText={typeaheadRef?.current?.state?.text}
                        />
                      </MenuItem>
                    ))}
                  </Menu>
                )}
              />
            </div>
          </Modal.Body>
          <Modal.Body>
            <div>
              <h4>
                <FormattedMessage id="PROJECT.CREATE_SUBCONTRACTOR.TITLE" />
              </h4>
              <p className="mt-2">
                <FormattedMessage id="PROJECT.CREATE_SUBCONTRACTOR.DESCRIPTION" />
              </p>
            </div>
            <div className="d-flex justify-content-end">
              <button
                type="button"
                onClick={() => {
                  dispatch(actionsUsers.updateUserLocally({}));
                  setHiddenSubcontractorForm(true);
                }}
                disabled={!canCreate(groups, session, "SUBCONTRACTOR")}
                className={`btn btn-primary btn-elevate ml-4 ${
                  isLoadingProject && "spinner spinner-dark spinner-right"
                }`}
              >
                <FormattedMessage id="PROJECT.CREATE_SUBCONTRACTOR.BUTTON" />
              </button>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <div>
              <button type="button" onClick={onHide} className={`btn btn-light btn-elevate`}>
                <FormattedMessage id="COMMON.ACTION.CANCEL" />
              </button>
              <button
                type="button"
                onClick={submitProjectSubcontractors}
                disabled={isLoadingProject || isLoadingUser}
                className={`btn btn-primary btn-elevate ml-4 ${
                  isLoadingProject && "spinner spinner-dark spinner-right"
                }`}
              >
                <FormattedMessage id="COMMON.ACTION.ADD" />
              </button>
            </div>
          </Modal.Footer>
        </>
      )}
    </Modal>
  );
}
