/*
  How to create responsive tables with pure CSS using Grid Layout Module | FreeCodeCamp
  https://www.freecodecamp.org/news/https-medium-com-nakayama-shingo-creating-responsive-tables-with-pure-css-using-the-grid-layout-module-8e0ea8f03e83/

    Responsive tables with pure CSS using Grid Layout Module | CodePen
    https://codepen.io/ShingoNakayama/pen/LMLeRZ
*/

import * as React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Switch } from "@material-ui/core";
import { useDidUpdate } from "rooks";
import cn from "clsx";

import { IRequestSignatory } from "data/schemas";

import { Signatory } from "./Signatory";
import { AddSignatory } from "./AddSignatory";

import { useRequestSignatureDialogContext } from "../RequestSignatureDialogContext";

import "./Signatories.scss";

import SignatoriesGrantAccess from "./SignatoriesGrantAccess/SignatoriesGrantAccess";

export interface SignatoriesProps {
  className?: string;
}

export const Signatories: React.FunctionComponent<SignatoriesProps> = ({ className }) => {
  const intl = useIntl();

  const signatoriesCollectionRef = React.useRef<HTMLDivElement>(null);

  const {
    sendEmail,
    setSendEmail,
    sendSms,
    setSendSms,
    signatories,
    updateSignatory,
    removeSignatory,
  } = useRequestSignatureDialogContext();

  const buildRemoveClickHandler = (signatory: IRequestSignatory) => () =>
    removeSignatory(signatory);

  const signatoriesLength = signatories.length;
  const isEmpty = signatoriesLength === 0;
  const signatoriesLastIndex = isEmpty ? 0 : signatoriesLength - 1;

  //--------------------------------------------------------------------------//

  useDidUpdate(() => {
    const { current: signatoriesCollection } = signatoriesCollectionRef;
    if (!signatoriesCollection || signatories.length === 0) return;
    signatoriesCollection.scrollTo({ top: signatoriesCollection.scrollHeight, behavior: "smooth" });
  }, [signatories]);

  //--------------------------------------------------------------------------//

  const renderGrantAccess: () => JSX.Element | null = () => {
    const disabledUsersSignatory = signatories.filter(
      (user) => user.hasUserPlatformAccess === false
    );

    if (disabledUsersSignatory.length > 0) {
      return <SignatoriesGrantAccess disabledUsersSignatory={disabledUsersSignatory} />;
    }

    return null;
  };

  //--------------------------------------------------------------------------//

  return (
    <div className={cn("signatories", className)}>
      <div className="font-size-lg font-weight-bold">
        <FormattedMessage id="COMMON.SIGNATURES.REQUEST" />
      </div>

      {/* @begin: list container */}
      <div ref={signatoriesCollectionRef} className={cn("collection", { empty: isEmpty })}>
        <div className="item item-header">
          <div className="attribute avatar"></div>
          <div className="attribute name"></div>
          <div className="attribute email"></div>
          <div className="attribute phone"></div>
          <div className="attribute actions"></div>
        </div>

        <div className="gradient gradient-before"></div>

        {isEmpty && (
          <div className="item empty">
            <div className="attribute empty">
              {intl.formatMessage({ id: "COMMON.NOT_SPECIFIED" })}
            </div>
          </div>
        )}

        {signatories.map((signatory, index) => (
          <Signatory
            key={signatory.signatoryHelperId}
            className={cn({ last: index === signatoriesLastIndex })}
            signatory={signatory}
            onRemoveClick={buildRemoveClickHandler(signatory)}
            onUpdate={updateSignatory}
          />
        ))}

        <div className="gradient gradient-after"></div>
      </div>
      {/* @end: list container */}

      <AddSignatory className="mt-2" />

      <div className={"notifications mt-4"}>
        <FormattedMessage id="COMMON.ACTION.NOTIFY_USER_BY" />

        <Switch
          onChange={(e) => setSendEmail(e.target.checked)}
          checked={sendEmail}
          data-cy="switch-send-email"
        />
        <FormattedMessage id="COMMON.EMAIL" />

        <Switch
          onChange={(e) => setSendSms(e.target.checked)}
          checked={sendSms}
          data-cy="switch-send-sms"
        />
        <FormattedMessage id="COMMON.SMS" />
      </div>
      {renderGrantAccess()}
    </div>
  );
};

export default Signatories;
