import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import Cropper from "react-easy-crop";
import { FormattedMessage } from "react-intl";

export function ImageCropper({ isOpen, image, saveCroppedImage, onCancel }) {
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [rotation, setRotation] = useState(0);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);

  const onCropComplete = (croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  };

  const saveImage = async () => {
    const cropperImage = await getCroppedImg(image, croppedAreaPixels, image.type, rotation);
    saveCroppedImage(cropperImage);
  };

  const cancelCrop = () => {
    if (!!onCancel) {
      onCancel();
    }
  };

  return (
    <Modal
      show={isOpen}
      onHide={() => {}}
      aria-labelledby="user-avatar-cropper-modal"
      centered
      size={"lg"}
    >
      <Modal.Body className="overlay" style={{ height: "400px" }}>
        <Cropper
          image={image}
          crop={crop}
          zoom={zoom}
          aspect={1}
          onRotationChange={setRotation}
          onCropChange={setCrop}
          onZoomChange={setZoom}
          onCropComplete={onCropComplete}
        />
      </Modal.Body>

      <Modal.Footer>
        <button type="button" onClick={cancelCrop} className="btn btn-clean btn-elevate">
          <FormattedMessage id="COMMON.ACTION.CANCEL" />
        </button>
        <button type="button" onClick={saveImage} className="btn btn-primary btn-elevate">
          <FormattedMessage id="COMMON.ACTION.SAVE" />
        </button>
        {/*  Copper slider goes here + Save button */}
      </Modal.Footer>
    </Modal>
  );
}

const createImage = (url) =>
  new Promise((resolve, reject) => {
    const image = new Image();
    image.addEventListener("load", () => resolve(image));
    image.addEventListener("error", (error) => reject(error));
    image.setAttribute("crossOrigin", "anonymous"); // needed to avoid cross-origin issues on CodeSandbox
    image.src = url;
  });

const getRadianAngle = (degreeValue) => {
  return (degreeValue * Math.PI) / 180;
};

const getCroppedImg = async (imageSrc, pixelCrop, imageType, rotation = 0) => {
  const image = await createImage(imageSrc);
  const canvas = document.createElement("canvas");
  const ctx = canvas.getContext("2d");

  const maxSize = Math.max(image.width, image.height);
  const safeArea = 2 * ((maxSize / 2) * Math.sqrt(2));

  // set each dimensions to double largest dimension to allow for a safe area for the
  // image to rotate in without being clipped by canvas context
  canvas.width = safeArea;
  canvas.height = safeArea;

  // translate canvas context to a central location on image to allow rotating around the center.
  ctx.translate(safeArea / 2, safeArea / 2);
  ctx.rotate(getRadianAngle(rotation));
  ctx.translate(-safeArea / 2, -safeArea / 2);

  // draw rotated image and store data.
  ctx.drawImage(image, safeArea / 2 - image.width * 0.5, safeArea / 2 - image.height * 0.5);
  const data = ctx.getImageData(0, 0, safeArea, safeArea);

  // set canvas width to final desired crop size - this will clear existing context
  canvas.width = pixelCrop.width;
  canvas.height = pixelCrop.height;

  // paste generated rotate image with correct offsets for x,y crop values.
  ctx.putImageData(
    data,
    Math.round(0 - safeArea / 2 + image.width * 0.5 - pixelCrop.x),
    Math.round(0 - safeArea / 2 + image.height * 0.5 - pixelCrop.y)
  );

  // As Base64 string
  // return canvas.toDataURL('image/jpeg');

  // As a blob
  return new Promise((resolve) => {
    canvas.toBlob((file) => {
      resolve(file);
    }, imageType);
  });
};
