import * as React from "react";
import { useIntl } from "react-intl";
import { cloneDeep } from "lodash-es";

import { ISettings } from "data/schemas";

import { useAppSelector } from "redux/hooks";

import { accurateFloatOperation } from "app/_utils/mathUtils";

import { getTotalInvoiceStatus } from "app/modules/Budget/utils";

import { processFinancialFile } from "./utils/processFinancialFile";
import { ILeadInstalment, ILeadSupplement, LEAD_CALCULATED_ID } from "./definitions";

export interface ILeadContext {
  usedConstructionPriceWithReducedVat: number;
  initialCredit: number;
  remainingCredit: number;
  leadInstalments: ILeadInstalment[];
  leadSupplements: ILeadSupplement[];
  settings?: ISettings;
}

const LeadContext = React.createContext<ILeadContext>({} as ILeadContext);

export function useLeadContext() {
  return React.useContext(LeadContext);
}

export const LeadProvider: React.FC = ({ children }) => {
  const intl = useIntl();
  const { leadForEdit, generalSettings } = useAppSelector((state) => ({
    leadForEdit: state.leads.leadForEdit.current,
    generalSettings: state.settings.settingsForEdit.saved,
  }));

  const { sellingPrice, shareOfLandSellingPrice, architectEngineeringFees } = leadForEdit ?? {};
  const constructionPrice =
    (sellingPrice ?? 0) - ((shareOfLandSellingPrice ?? 0) + (architectEngineeringFees ?? 0));

  const settings = leadForEdit?.project?.projectOwner ?? generalSettings;

  const {
    leadInstalments,
    leadSupplements,
    usedConstructionPriceWithReducedVat,
    initialCredit,
    remainingCredit,
  } = React.useMemo(() => {
    const leadInstalments: ILeadInstalment[] = [];
    const leadSupplements: ILeadSupplement[] = [];
    let usedConstructionPriceWithReducedVat = 0;

    // Populate instalment revenue list with budget instalments and default share of land and architect fees instalment
    leadInstalments.push(
      {
        id: LEAD_CALCULATED_ID.SHARE_OF_LAND,
        label: intl.formatMessage({ id: "LEAD.SHARE_OF_LAND" }),
        amount: shareOfLandSellingPrice ?? 0,
        amountInclPriceIndex: shareOfLandSellingPrice ?? 0,
        amountInclVAT: shareOfLandSellingPrice ?? 0,
        amount0VAT: shareOfLandSellingPrice ?? 0,
        paymentStatusCount: {},
      },
      {
        id: LEAD_CALCULATED_ID.ARCHITECT_ENGINEERING_FEES,
        label: intl.formatMessage({ id: "LEAD.ARCHITECT_ENGINEERING" }),
        amount: architectEngineeringFees ?? 0,
        amountInclPriceIndex: architectEngineeringFees ?? 0,
        amountInclVAT: (architectEngineeringFees ?? 0) * (1 + (settings?.defaultVat ?? 0)),
        amountDefaultVAT: architectEngineeringFees ?? 0,
        taxes: (architectEngineeringFees ?? 0) * (settings?.defaultVat ?? 0),
        paymentStatusCount: {},
      }
    );

    for (const { id, label, instalment } of leadForEdit?.budgetInstalments ?? []) {
      const amount = accurateFloatOperation(instalment * constructionPrice, 2);
      leadInstalments.push({
        id,
        label,
        instalmentPercentage: accurateFloatOperation(instalment * 100, 2),
        amount,
        amountInclPriceIndex: amount,
        paymentStatusCount: {},
      });
    }

    // Sort files so that SA come first and then Invoice, this way less compute to make in the for loop
    const sortedFinancialDocuments = (cloneDeep(leadForEdit?.financialDocuments) ?? []).sort(
      (fileA, fileB) =>
        fileA.fileType === "SUPPLEMENTARY_AGREEMENT"
          ? -1
          : fileB.fileType === "SUPPLEMENTARY_AGREEMENT"
          ? 1
          : 0
    );
    // Loop over every lead financial files of the project to compute leadInstalments and leadSupplements
    for (const leadFinancialDocument of sortedFinancialDocuments) {
      if (!leadFinancialDocument.selectedForFinance) continue;

      usedConstructionPriceWithReducedVat += processFinancialFile({
        file: leadFinancialDocument,
        leadSupplements,
        leadInstalments,
        settings,
      });
    }

    const initialCredit =
      (leadForEdit?.constructionPriceWithReducedVat ?? 0) *
      ((settings?.defaultVat ?? 0) - (settings?.reducedVat ?? 0));

    let remainingCredit =
      initialCredit -
      (usedConstructionPriceWithReducedVat ?? 0) *
        ((settings?.defaultVat ?? 0) - (settings?.reducedVat ?? 0));
    // Case when the user forced the reduced VAT when advised not to
    if (remainingCredit < 0) {
      remainingCredit = 0;
    }

    let remainingConstructionPrice =
      (leadForEdit?.constructionPriceWithReducedVat ?? 0) - usedConstructionPriceWithReducedVat;

    // Set payment status and compute expected instalment values that are not invoiced
    for (const revenue of leadInstalments) {
      revenue.paymentStatus = getTotalInvoiceStatus(revenue.paymentStatusCount!);
      if (
        ![LEAD_CALCULATED_ID.SHARE_OF_LAND, LEAD_CALCULATED_ID.ARCHITECT_ENGINEERING_FEES].includes(
          revenue.id
        ) &&
        !revenue.invoiced
      ) {
        const expectedAmountAtReducedVat = Math.min(
          remainingConstructionPrice,
          revenue.amountInclPriceIndex!
        );
        remainingConstructionPrice -= expectedAmountAtReducedVat;
        revenue.amountReducedVAT = expectedAmountAtReducedVat;
        revenue.amountDefaultVAT = revenue.amountInclPriceIndex! - expectedAmountAtReducedVat;
        revenue.taxes =
          revenue.amountReducedVAT * (settings?.reducedVat ?? 0) +
          revenue.amountDefaultVAT * (settings?.defaultVat ?? 0);
        revenue.amountInclVAT = revenue.amountInclPriceIndex! + revenue.taxes!;
      } else if (revenue.indexedAmount) {
        revenue.priceIndex = (revenue.indexedAmount / revenue.amount!) * 100;
      }
    }
    for (const revenue of leadSupplements) {
      revenue.paymentStatus = getTotalInvoiceStatus(revenue.paymentStatusCount!);
      if (revenue.amountInclPriceIndex) {
        if (revenue.indexedAmount) {
          revenue.priceIndex = (revenue.indexedAmount / revenue.amount!) * 100;
        }
        revenue.supplementMarginPercentage =
          ((revenue.supplementMargin ?? 0) /
            (revenue.amountInclPriceIndex - (revenue.supplementMargin ?? 0))) *
          100;
      }
    }
    return {
      leadInstalments,
      leadSupplements,
      usedConstructionPriceWithReducedVat,
      initialCredit,
      remainingCredit,
    };
  }, [leadForEdit, settings, intl.locale]);

  const value = {
    usedConstructionPriceWithReducedVat,
    initialCredit,
    remainingCredit,
    leadInstalments,
    leadSupplements,
    settings,
  };

  return <LeadContext.Provider value={value}>{children}</LeadContext.Provider>;
};
