import { IntlShape } from "react-intl";
import { ColumnFormatter } from "react-bootstrap-table-next";
import TimeAgo, { Unit } from "react-timeago";
import { getTimeAgoStringForIntl } from "../../_utils/tasks";
import { DateUtils } from "../../_utils/DateUtils";

interface FormatExtraDataProps {
  intl: IntlShape;
  showTime: boolean;
}

function getDifferenceInDays(date1: any, date2: any) {
  const diffInMs = Math.abs(date2 - date1);
  return diffInMs / (1000 * 60 * 60 * 24);
}

export const AuditTrailDateTimeColumnFormatter: ColumnFormatter<any, FormatExtraDataProps, string> =
  (dateTimeValue, row, rowIndex, formatExtraData) => {
    const isOlderThan1Day: boolean = getDifferenceInDays(new Date(), new Date(dateTimeValue)) > 3;
    const intl = formatExtraData?.intl;

    if (intl) {
      return (
        <>
          {isOlderThan1Day ? (
            <div>{DateUtils.format(new Date(dateTimeValue), intl, true)}</div>
          ) : (
            <div>
              {formatExtraData?.intl?.formatMessage(
                { id: `TIME.AGO` },
                {
                  data: (
                    <TimeAgo
                      date={dateTimeValue}
                      formatter={(value: number, unit: Unit) => {
                        return getTimeAgoStringForIntl("TIMEAGO.LEFT", value, unit, intl);
                      }}
                    />
                  ),
                }
              )}
            </div>
          )}
        </>
      );
    }
    return <></>;
  };
