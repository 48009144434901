import { ISettings, ICompany } from "data/schemas";

export interface IForEdit<T = ISettings> {
  saved?: T;
  current?: T;
}

export interface IPropCoState<T = ICompany> {
  entities: T[];
  forEdit: IForEdit<T>;
}

export interface IState {
  getLoading: boolean;
  listLoading: boolean;
  actionLoading: boolean;
  settingsForEdit: IForEdit;
  propCo: IPropCoState;
}

export enum CallType {
  GET = "get",
  LIST = "list",
  ACTION = "action",
}

//---//

export interface IFieldUpdatedLocallyPayload {
  key: string;
  value: any;
}

export interface ICallTypePayload {
  callType: CallType;
}

export interface IsettingsPayload {
  settings: ISettings;
}

export interface IPropCosPayload {
  propCos: ICompany[];
}

export interface IPropCoIdPayload {
  propCoId: string;
}

export interface IPropCoPayload {
  propCo: ICompany;
}

//----------------------------------------------------------------------------//

export const stateName = "settings";

export const initialState: IState = {
  getLoading: false,
  listLoading: false,
  actionLoading: false,
  settingsForEdit: {
    saved: undefined,
    current: undefined,
  },
  propCo: {
    entities: [],
    forEdit: {
      saved: undefined,
      current: undefined,
    },
  },
};

//----------------------------------------------------------------------------//
