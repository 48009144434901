import * as React from "react";
import SVG from "react-inlinesvg";
import { FormattedMessage, useIntl } from "react-intl";
import cn from "clsx";

import { toAbsoluteUrl } from "_metronic/_helpers";
import { Card, CardBody, CardFooter, CardHeader } from "_metronic/_partials/controls";

import { DateUtils } from "app/_utils/DateUtils";

import { PdfViewer } from "app/modules/Common/PdfViewer/PdfViewer";

import { ISignatureInfo, SignatureStatus } from "data/schemas";

interface CardPDFSignedViewerProps {
  signatureInfo: Partial<ISignatureInfo>;
  fileUrl: string;

  setModalState?: Function;
  extraCardClassName?: string;
  disableSignButton?: boolean;
}

export const CardPDFSignedViewer: React.FC<CardPDFSignedViewerProps> = ({
  signatureInfo,
  fileUrl,
  setModalState,
  extraCardClassName,
  disableSignButton = false,
}) => {
  const intl = useIntl();

  const renderFooter = () => {
    const { signedDate, sealedAt, signaturesRequest } = signatureInfo;

    if (signaturesRequest!.status === SignatureStatus.CANCELLED) {
      const { cancelledReason, updatedAt } = signaturesRequest!;

      return (
        <div className="d-flex flex-column">
          <div className="d-flex justify-content-end align-items-center">
            <span className="svg-icon svg-icon-2x svg-icon-danger mr-1">
              <SVG src={toAbsoluteUrl("/media/svg/icons/General/Shield-disabled.svg")} />
            </span>

            <FormattedMessage
              tagName={"div"}
              id="SIGN.CANCELLED_AT"
              values={{
                b: (chunks: string) => <b>{chunks}</b>,
                date: DateUtils.format(new Date(updatedAt), intl, true),
              }}
            />
          </div>

          {cancelledReason && (
            <div className="d-flex justify-content-end align-items-center mt-2">
              <span className="font-weight-bold footer-label mr-2">
                <FormattedMessage id="COMMON.CANCELLATION_REASON" />
              </span>

              <span>{cancelledReason}</span>
            </div>
          )}
        </div>
      );
    }

    if (signedDate) {
      return (
        <>
          {sealedAt ? (
            <span className="svg-icon svg-icon-2x svg-icon-success mr-1">
              <SVG src={toAbsoluteUrl("/media/svg/icons/General/Shield-check.svg")} />
            </span>
          ) : (
            <span className="svg-icon svg-icon-2x svg-icon-success mr-1">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Files/File-done.svg")} />
            </span>
          )}

          <FormattedMessage
            id="SIGN.SIGNED_AT"
            values={{
              b: (chunks: string) => <b>{chunks}</b>,
              date: DateUtils.format(new Date(signedDate), intl, true),
              user: signatureInfo.signedBy,
            }}
          />
        </>
      );
    }

    return !!setModalState ? (
      <button
        data-cy="button-signature-sign"
        type="button"
        className="btn btn-primary"
        onClick={() => setModalState("VALIDATE")}
        disabled={disableSignButton}
      >
        <FormattedMessage id={"SIGN.ACTION"} />
      </button>
    ) : null;
  };

  return (
    <Card className={cn("h-100", extraCardClassName)}>
      <CardHeader title={intl.formatMessage({ id: "SIGN.TITLE" })} />
      <CardBody
        className={"p-0 d-flex align-items-center justify-content-center position-relative"}
        style={{ minHeight: "40vh" }}
      >
        <div className={"w-100 h-100 position-absolute"}>
          <PdfViewer fileUrl={fileUrl} />
        </div>
      </CardBody>
      <CardFooter className={"p-4 p-sm-6"}>
        <div className={"text-right"} data-cy="text-signature-signed-confirmation">
          {renderFooter()}
        </div>
      </CardFooter>
    </Card>
  );
};
