import { FormikProps } from "formik/dist/types";
import { KycFieldLock } from "../KycFieldLock";
import { get } from "lodash-es";
import { FieldFeedbackLabel } from "../../../../../../_metronic/_partials/controls";
import { KycFormElementBaseLayout } from "../KycControlsBaseLayout";
import { KycInfoBox } from "../../KycInfoBox";
import * as React from "react";
import { FormikErrors, FormikTouched, useFormikContext } from "formik";
import PhoneInput from "react-phone-input-2";
import { useKycFlow } from "../../../../../hooks/useKycFlow";

interface KycPhoneProps {
  field: any;
  customHandleChange?: any;
  withFeedbackLabel: boolean;
  inline?: boolean;
  label?: string;
  props?: any;
  disabled?: boolean;
  form: FormikProps<any>;
  customFeedbackLabel?: string;
  type?: string;
  className?: string;
  infoLabel?: string;
  disableLabel?: boolean;
  locked?: boolean;
  renderAsLabel?: boolean;
  dataCy?: string;
  onChange: Function;
  onBlur: Function;
}

const getFieldCSSClasses = (
  touched: undefined | boolean | FormikTouched<any> | FormikTouched<any>[],
  errors: undefined | string | string[] | FormikErrors<any> | FormikErrors<any>[],
  values: FormikErrors<{
    [field: string]: any;
  }>
) => {
  const classes = ["form-control"];
  if (touched && errors) {
    classes.push("is-invalid");
  }
  return classes.join(" ");
};

export const KycPhone: React.FC<KycPhoneProps> = ({
  field,
  form,
  label,
  withFeedbackLabel = true,
  customFeedbackLabel = "",
  inline = true,
  type = "text",
  className = "",
  disabled = false,
  infoLabel,
  disableLabel = false,
  locked = false,
  renderAsLabel = false,
  dataCy = "input-mobile",
  onChange,
  onBlur,
  ...props
}) => {
  const { setFieldValue } = useFormikContext();
  const { saveField } = useKycFlow();
  const content = (
    <>
      <KycFieldLock disabled={disabled} field={field} locked={locked} renderAsLabel={renderAsLabel}>
        <>
          {inline ? (
            <>
              <PhoneInput
                country={"lu"}
                {...props}
                {...field}
                inputProps={{
                  "data-cy": dataCy,
                  name: field.name,
                  className: getFieldCSSClasses(
                    get(form.touched, field.name),
                    get(form.errors, field.name),
                    get(form.values, field.name)
                  ),
                }}
                onChange={(phoneNumber) => {
                  phoneNumber = "+" + phoneNumber;
                  if (!!onChange) {
                    onChange({ target: { name: field.name, value: phoneNumber } });
                  }
                  setFieldValue(field.name, phoneNumber);
                }}
                onBlur={onBlur || saveField}
                specialLabel={""}
              />
              {withFeedbackLabel && !disabled && (
                <FieldFeedbackLabel
                  error={get(form.errors, field.name)}
                  touched={get(form.touched, field.name)}
                  value={field.value}
                  label={label}
                  type={type}
                  customFeedbackLabel={customFeedbackLabel}
                />
              )}
            </>
          ) : (
            <>
              <PhoneInput
                country={"lu"}
                {...props}
                {...field}
                inputProps={{
                  "data-cy": dataCy,
                  name: field.name,
                  className: getFieldCSSClasses(
                    get(form.touched, field.name),
                    get(form.errors, field.name),
                    get(form.values, field.name)
                  ),
                }}
                onChange={(phoneNumber) => {
                  phoneNumber = "+" + phoneNumber;
                  if (!!onChange) {
                    onChange({ target: { name: field.name, value: phoneNumber } });
                  }
                  setFieldValue(field.name, phoneNumber);
                }}
                onBlur={onBlur || saveField}
                specialLabel={""}
              />
              {withFeedbackLabel && !disabled && (
                <FieldFeedbackLabel
                  error={get(form.errors, field.name)}
                  touched={get(form.touched, field.name)}
                  value={field.value}
                  label={label}
                  type={type}
                  customFeedbackLabel={customFeedbackLabel}
                />
              )}
            </>
          )}
        </>
      </KycFieldLock>
    </>
  );
  return (
    <>
      <KycFormElementBaseLayout
        renderAsLabel={renderAsLabel}
        field={field}
        content={content}
        disabled={disabled}
        disableLabel={disableLabel}
        label={label}
        inline={inline}
      />
      {infoLabel && <KycInfoBox label={infoLabel} />}
    </>
  );
};
