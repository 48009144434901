import { FormattedMessage } from "react-intl";

interface TitleWithGuideLinkProps {
  titleMessageId: string;
  descriptionMessageId: string;
  guideLink: string;
  mainTitle?: boolean;
}

const TitleWithGuideLink = ({
  descriptionMessageId,
  guideLink,
  titleMessageId,
  mainTitle = false,
}: TitleWithGuideLinkProps) => {
  const onGuideClick = (guideLink: string) => {
    window.open(guideLink, "_blank", "noopener, noreferrer");
  };

  return (
    <>
      {!mainTitle ? (
        <>
          <div className="separator separator-solid separator-border-2 my-4" />
          <span className="font-size-lg font-weight-bold">
            <FormattedMessage id={titleMessageId} />
          </span>
        </>
      ) : (
        <>
          <h3 className="mt-14">
            <FormattedMessage id={titleMessageId} />
          </h3>
          <div className="separator separator-solid separator-border-2 my-4" />
        </>
      )}
      <div className="my-2">
        <FormattedMessage
          id={descriptionMessageId}
          values={{
            guide: (
              <span
                className="text-primary cursor-pointer"
                style={{ textDecoration: "underline" }}
                onClick={() => onGuideClick(guideLink)}
              >
                <FormattedMessage id="COMMON.GUIDE" />
              </span>
            ),
          }}
        />
      </div>
    </>
  );
};

export default TitleWithGuideLink;
