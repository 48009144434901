import * as React from "react";
import { v4 as uuid } from "uuid";
import { useKycFile } from "../../../../../hooks/useKycFile";
import { useKycFlow } from "../../../../../hooks/useKycFlow";
import { useTranslation } from "../../../../../hooks/useTranslation";

interface KycUploadAddButtonProps {
  name: string;
  kycRelatedToEntity?: any;
}

export const KycUploadAddButton = ({
  name,
  kycRelatedToEntity,
}: KycUploadAddButtonProps): JSX.Element => {
  const { currentFiles, setCurrentFiles } = useKycFile();
  const { values } = useKycFlow();
  const { fm } = useTranslation();

  const handleAddFile = (e: React.MouseEvent) => {
    e.preventDefault();
    setCurrentFiles([
      ...currentFiles,
      { ...initKycFile, id: uuid(), status: "DRAFT", index: currentFiles.length },
    ]);
  };

  const initKycFile = {
    kycId: values?.id,
    categoryId: name,
    fieldPath: name,
    kycRelatedToEntity: kycRelatedToEntity,
    freeText: "",
    option: "",
    index: -1,
    size: 0,
  };

  return (
    <div
      className={
        "d-flex flex-wrap flex-sm-wrap flex-md-wrap flex-lg-nowrap justify-content-center pb-lg-1 pl-lg-4 pr-lg-2 pl-sm-0 pr-sm-0"
      }
    >
      <label className="col-lg-6 col-form-label text-left" />
      <div className="w-100 text-justify">
        <div className="d-flex justify-content-end pl-lg-1">
          <button
            type="button"
            className="btn btn-sm btn-light flex-grow-1 rounded d-flex align-items-center justify-content-center w-100 mx-lg-2"
            onClick={handleAddFile}
          >
            <i className="ki ki-plus icon-nm" />
            {fm("LEAD.ACTION.ADD.LINE")}
          </button>
        </div>
      </div>
    </div>
  );
};
