import cn from "clsx";
import * as React from "react";
import { ReactNode } from "react";
import { useWizard } from "../../../../hooks/useWizard";

type KycCenteredStepBodyLayoutProps = {
  children?: ReactNode;
};

export const KycCenteredStepBodyLayout = ({
  children,
}: KycCenteredStepBodyLayoutProps): JSX.Element => {
  const { isExpanded } = useWizard();

  return (
    <div
      className={cn(
        "d-flex flex-column justify-content-center",
        isExpanded ? "step-content-center-maximized" : "step-content-center"
      )}
    >
      {children}
    </div>
  );
};
