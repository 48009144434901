import { createSlice } from "@reduxjs/toolkit";

const initialSnackBarNotificationState = {
  list: [],
};

export const inAppNotificationsSlice = createSlice({
  name: "inAppNotifications",
  initialState: initialSnackBarNotificationState,
  reducers: {
    addNotification: (state, action) => {
      const newList = [...state.list, action.payload.notification];
      newList.sort((n1, n2) => new Date(n2.createdAt) - new Date(n1.createdAt));
      state.list = newList;
    },
    updateNotifications: (state, action) => {
      let newList = [];
      if (action.payload?.notifications) {
        newList = [...action.payload.notifications];
        newList.sort((n1, n2) => new Date(n2.createdAt) - new Date(n1.createdAt));
      }
      state.list = newList;
    },
    markNotificationAsRead: (state, action) => {
      state.list = [...state.list].map((notification) => {
        if (notification.id === action.payload?.notification?.id) {
          notification.readAt = action.payload?.notification?.readAt;
        }
        return notification;
      });
    },
  },
});
