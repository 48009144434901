import {
  ILeadFinancialDocument,
  ISettings,
  isLeadFinancialDocumentPriceLine,
} from "../../../../../../../data/schemas";
import { ILeadInstalment, ILeadSupplement } from "../definitions";
import { processFinancialFileLine } from "./processFinancialFileLine";
import { accurateFloatOperation } from "../../../../../../_utils/mathUtils";

export interface ProcessFinancialFileProps {
  file: ILeadFinancialDocument;
  leadInstalments: ILeadInstalment[];
  leadSupplements: ILeadSupplement[];
  settings?: ISettings;
}

export const processFinancialFile = ({
  file,
  leadInstalments,
  leadSupplements,
  settings,
}: ProcessFinancialFileProps) => {
  let priceWithReducedVat = 0;
  const { fileType, content, title, id, invoiceStatus, basePriceIndex, priceIndex } = file;
  let priceIndexChangeCoef = 0;
  if (basePriceIndex?.value && priceIndex?.value) {
    priceIndexChangeCoef = (priceIndex.value - basePriceIndex.value) / basePriceIndex.value;
  }

  switch (fileType) {
    case "SUPPLEMENTARY_AGREEMENT":
      const linkedToInstalment = content[0].budgetInstalmentId;

      const supplement: ILeadSupplement = {
        budgetInstalmentId: linkedToInstalment,
        id,
        label: title,
        amount: 0,
        amountInclPriceIndex: 0,
        indexedAmount: 0,
        amountInclVAT: 0,
        amountDefaultVAT: 0,
        taxes: 0,
        supplementMargin: 0,
        paymentStatusCount: {},
      };
      for (const line of content) {
        if (!isLeadFinancialDocumentPriceLine(line)) continue;
        const indexedAmount = accurateFloatOperation(line.amount * priceIndexChangeCoef, 10);
        const lineAmountInclPriceIndex = line.amount + indexedAmount;
        // For SA that are not invoiced, the VAT will be the default VAT
        const vatAmount = lineAmountInclPriceIndex * (settings?.defaultVat ?? 0);
        supplement.indexedAmount! += indexedAmount;
        supplement.amount! += line.amount;
        supplement.amountInclPriceIndex! += lineAmountInclPriceIndex;
        supplement.amountInclVAT! += lineAmountInclPriceIndex + vatAmount;
        supplement.amountDefaultVAT! += lineAmountInclPriceIndex;
        supplement.taxes! += vatAmount;
        supplement.supplementMargin! += line.margin ?? 0;
      }
      leadSupplements.push(supplement);
      break;

    case "INVOICE":
      for (const line of content) {
        if (!isLeadFinancialDocumentPriceLine(line)) continue;

        if (line.budgetInstalmentId) {
          const instalment = leadInstalments.find((i) => i.id === line.budgetInstalmentId);
          if (instalment) {
            priceWithReducedVat += processFinancialFileLine({
              revenue: instalment,
              line,
              settings,
              invoiceStatus,
              priceIndexChangeCoef,
            });
          }
        }

        if (!line.relatedFinancialDocuments && !line.financialDocumentId) continue;

        for (const rfd of line.relatedFinancialDocuments ?? []) {
          const supplement = leadSupplements.find((f) => f.id === rfd.financialDocumentId);
          if (supplement) {
            for (const lineRFD of rfd.content) {
              if (!isLeadFinancialDocumentPriceLine(lineRFD)) continue;
              priceWithReducedVat += processFinancialFileLine({
                revenue: supplement,
                line: lineRFD,
                settings,
                invoiceStatus,
                priceIndexChangeCoef,
              });
            }
            supplement.paymentStatusCount[invoiceStatus] += 1;
          }
        }
      }
      break;
  }
  return priceWithReducedVat;
};
