import { DateUtils } from "./DateUtils";
import { get } from "lodash-es";
import {
  INVOICE_STATUS,
  LEAD_FILE_TYPES,
  LEAD_STATUS,
  PROJECT_PRODUCT_FILE_TYPES,
  USER_FILE_TYPES,
} from "./listUtils";
import { formatDisplayNameColumn } from "./userUtils";
import { selectedLeadStatus } from "./formUtils";

export const selectField = (uiProps) =>
  uiProps?.filters?.filterSelected?.length > 0
    ? uiProps?.filters?.filterSelected
    : uiProps?.filterList ?? [];

export const searchUtils = (freeText, entities, filteringProps, intl) => {
  if (!entities) {
    return [];
  }
  if (!freeText) {
    return entities;
  }

  freeText = freeText.trim();
  return entities.filter(lookupForValueFilterFn(freeText, filteringProps, intl));
};

export const lookupForValueFilterFn = (freeText, filteringProps, intl) => (entity) =>
  Object.values(createEntityToFilter(entity, filteringProps, intl))
    .map((value) =>
      !!value && (typeof value === "string" || value instanceof String) ? value.toLowerCase() : ""
    )
    .findIndex((value) => {
      return value.includes(freeText.toLowerCase());
    }) >= 0;

export const createEntityToFilter = (entity, filteringProps, intl) => {
  let entityToFilter = {};
  for (const option of filteringProps) {
    const content = get(entity, option.value);
    entityToFilter[option.value] = content ? DateUtils.formatDateString(content, intl) : "";
  }

  // Translating values if existing id in translations
  if (entityToFilter.name) {
    if (intl.messages[entityToFilter.name])
      entityToFilter.name = intl.formatMessage({ id: entityToFilter.name });
  }

  if (entityToFilter.budgetStatus) {
    if (entity.id === entityToFilter.budgetStatus) {
      entityToFilter.budgetStatus = intl.formatMessage({ id: "COMMON.ACTION.SELECTED" });
    } else {
      entityToFilter.budgetStatus = intl.formatMessage({ id: "COMMON.ACTION.DRAFT" });
    }
  }

  if (entityToFilter.status) {
    entityToFilter.status = intl.formatMessage({
      id: LEAD_STATUS[entityToFilter.status]?.name,
    });
  }

  if (entityToFilter.leadStatus) {
    entityToFilter.leadStatus = intl.formatMessage({
      id: selectedLeadStatus(entityToFilter.leadStatus).name,
    });
  }

  if (entityToFilter.invoiceStatus) {
    entityToFilter.invoiceStatus = intl.formatMessage({
      id: INVOICE_STATUS[entityToFilter.invoiceStatus],
    });
  }

  if (entityToFilter.assignee) {
    entityToFilter.assignee = formatDisplayNameColumn(entityToFilter.assignee);
  }

  if (entityToFilter?.fileType) {
    const fileTypes = [
      LEAD_FILE_TYPES,
      PROJECT_PRODUCT_FILE_TYPES,
      USER_FILE_TYPES.CLIENT,
      USER_FILE_TYPES.SUBCONTRACTOR,
    ];
    fileTypes.forEach((value) => {
      if (value[entityToFilter.fileType]) {
        entityToFilter.fileType = intl.formatMessage({ id: value[entityToFilter.fileType] });
      }
    });
  }

  if (entityToFilter?.["address.countryCode"]) {
    entityToFilter.countryNameInEnglish = new Intl.DisplayNames(["en-GB"], { type: "region" }).of(
      entityToFilter["address.countryCode"]
    );

    if (intl.locale !== "en-GB") {
      entityToFilter.countryNameInLocaleLanguage = intl.formatDisplayName(
        entityToFilter["address.countryCode"],
        {
          type: "region",
        }
      );
    }
  }

  if (entityToFilter["fullName"] !== undefined) {
    entityToFilter.fullName = formatDisplayNameColumn(entity);
  }

  if (entityToFilter.createdByUserId) {
    entityToFilter.createdByUser = `${entity?.createdByUser?.firstName || ""} ${
      entity?.createdByUser?.lastName || ""
    }`.trim();
    delete entityToFilter.createdByUserId;
  }

  if (entityToFilter?.sellingPrice) {
    entityToFilter.sellingPrice = entityToFilter.sellingPrice.toFixed(2);
  }

  for (const key of ["uploadedAt", "updatedAt", "startDate", "availability", "createdAt"]) {
    if (entityToFilter.hasOwnProperty(key)) {
      entityToFilter[key] = entityToFilter[key]
        ? DateUtils.format(new Date(entity[key]), intl, true)
        : "-";
    }
  }
  return entityToFilter;
};
