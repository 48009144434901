import React from "react";
import SVG from "react-inlinesvg";
import { ColumnFormatter } from "react-bootstrap-table-next";
import { toAbsoluteUrl } from "../../../../../../_metronic/_helpers";
import { getFilesForKycFile } from "../../../KycFileUtils";
import { KycFileItemLang } from "./KycFileItemLang";
import { KycBoTableMode, KycFile, KycLoading } from "../../../Kyc";
import { IntlShape } from "react-intl";
import cn from "clsx";
import { isEmpty } from "lodash-es";
import { Spinner } from "react-bootstrap";
import { handleDownloadFile, orderByLang } from "../../../../../_utils/fileUtils";

interface FormatExtraDataProps {
  intl: IntlShape;
  deleteFile: Function;
  files: KycFile[];
  mode?: KycBoTableMode;
  setCurrentKycFlow: Function;
  isCurrentlyLoading: Function;
}

export const KycFileDownloadColumnFormatter: ColumnFormatter<any, FormatExtraDataProps> = (
  cell,
  file,
  rowIndex,
  formatExtraData
) => {
  let filesToDisplay = getFilesForKycFile(cell || file, formatExtraData?.files);

  const isFileCategoryLoading = (categoryId: string, loadingType: KycLoading) => {
    return (
      filesToDisplay?.[0]?.categoryId === categoryId &&
      formatExtraData?.isCurrentlyLoading(loadingType)
    );
  };

  const isLoading =
    isFileCategoryLoading("kycDocument", KycLoading.GENERATE_KYC_FLOW) ||
    isFileCategoryLoading("boDeclaration", KycLoading.GENERATE_BO);
  return (
    <>
      {isLoading ? (
        <Spinner animation="border" variant="primary" size="sm" />
      ) : (
        <div className="d-flex justify-content-end align-items-right">
          {/*MULTI-LANG FILE DOWNLOAD BUTTONS */}
          <span className={cn(!isEmpty(filesToDisplay) && "d-flex px-2")}>
            {filesToDisplay &&
              formatExtraData?.files &&
              orderByLang(Object.values(filesToDisplay), formatExtraData?.intl)
                ?.filter((f) => f?.url)
                ?.map((file: KycFile, index) => (
                  <KycFileItemLang key={"file-lang" + index} file={file} index={index} />
                ))}
          </span>

          {/*SINGLE FILE DOWNLOAD BUTTON */}
          <div>
            {filesToDisplay?.length === 1 && (
              <button
                className="btn btn-icon btn-light btn-hover-primary btn-sm"
                onClick={(e: any) => handleDownloadFile(e, filesToDisplay?.[0])}
              >
                <span className="svg-icon svg-icon-sm svg-icon-primary">
                  <SVG src={toAbsoluteUrl("/media/svg/icons/Files/Download.svg")} />
                </span>
              </button>
            )}
          </div>
        </div>
      )}
    </>
  );
};
