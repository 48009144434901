import { Cell, flexRender } from "@tanstack/react-table";
import cn from "clsx";
import * as React from "react";
import { BaseCellRender } from "./BaseCellRender";
import { getRowTheme, TRowTheme } from "../CardTableThemes";
import { CardTableItem } from "../context/CardTableContext";

export interface CellRenderProps<T> {
  cell: Cell<T, unknown>;
  rowTheme?: TRowTheme;
  index: number;
}

export const CellRender = <T extends CardTableItem>({
  cell,
  rowTheme,
  index,
}: CellRenderProps<T>) => (
  <BaseCellRender
    index={index}
    cell={cell}
    customClasses={cn(
      "p-2 d-flex align-items-center justify-content-end",
      cell.row.depth === 0 ? "font-weight-bold" : "font-size-sm",
      cell.column.columnDef.meta?.fixed && getRowTheme(rowTheme, cell.row.depth)
    )}
    render={flexRender(cell.column.columnDef.cell, cell.getContext())}
  />
);
