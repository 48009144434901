import React, { useEffect, useState } from "react";
import { useWizard, Wizard } from "react-use-wizard";
import { shallowEqual } from "react-redux";
import { FormEdit } from "../form-edit/FormEdit";
import { PersonalInfoFilloutConfirmModal } from "./PersonalInfoFilloutConfirmModal";
import { useAppSelector } from "../../../../../../redux/hooks";
import { CardSuccess } from "../../../../../_components/CardSuccess";
import { CardLoaderSpinner } from "../../../../../_components/CardLoaderSpinner";
import { FormattedMessage } from "react-intl";

export function PersonalInfoFilloutWizard() {
  const [showModal, setShowModal] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const { action } = useAppSelector(
    (state) => ({
      action: state.forms.action?.current,
    }),
    shallowEqual
  );

  const StepFilloutForm = ({ breadcrumbs }) => {
    const { nextStep } = useWizard();
    return (
      <FormEdit
        nextStep={nextStep}
        title={"FORM.TITLE.PERSONAL_INFORMATION"}
        formType={"FILLOUT_PERSONAL_INFORMATION"}
        breadcrumbs={breadcrumbs}
      />
    );
  };

  const StepConfirmFormUpload = ({ breadcrumbs }) => {
    const { nextStep, previousStep } = useWizard();

    useEffect(() => {
      setShowModal(true);
    }, []);

    return (
      <div>
        <FormEdit
          showOnly={true}
          nextStep={nextStep}
          title={"FORM.TITLE.PERSONAL_INFORMATION"}
          formType={"FILLOUT_PERSONAL_INFORMATION"}
          breadcrumbs={breadcrumbs}
        />
        <PersonalInfoFilloutConfirmModal
          action={action}
          nextStep={nextStep}
          previousStep={previousStep}
          showModal={showModal}
          setShowModal={setShowModal}
          setIsLoading={setIsLoading}
          setIsError={setIsError}
        />
      </div>
    );
  };

  const StepSuccess = ({ breadcrumbs }) => {
    return isLoading ? (
      <CardLoaderSpinner />
    ) : isError ? (
      <div>
        <FormattedMessage id={"UNAUTH_REQUEST.ERROR.RANDOM"} />
      </div>
    ) : (
      <CardSuccess
        breadcrumbs={breadcrumbs}
        successMessageId={"FORMS.FLOW.PERSONAL_INFORMATION.COMPLETED.SUCCESS"}
        successDateTime={action?.updatedAt}
      />
    );
  };

  return (
    <Wizard startIndex={action?.status === "DONE" ? 2 : 0}>
      <StepFilloutForm breadcrumbs={["FORM.TITLE.PERSONAL_INFORMATION"]} />
      <StepConfirmFormUpload
        breadcrumbs={["FORM.TITLE.PERSONAL_INFORMATION", "FORM.UPLOAD.TITLE.CONFIRM"]}
      />
      <StepSuccess
        breadcrumbs={[
          "FORM.TITLE.PERSONAL_INFORMATION",
          "FORM.UPLOAD.TITLE.CONFIRM",
          "BREADCRUMB.SUCCESS",
        ]}
      />
    </Wizard>
  );
}
