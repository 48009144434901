import React, { createContext, FunctionComponent, useContext, useState } from "react";

export interface IKycUIEvents {
  newKycModalButtonClick?: () => void;
  closeKycModal?: () => void;
  showKycModal: boolean;
}

export interface IKycUIContext extends IKycUIEvents {
  optionsLeads: any[];
  setOptionsLeads: (value: any[]) => void;
}

const KycUIContext = createContext<IKycUIContext>({
  showKycModal: false,
  optionsLeads: [],
  setOptionsLeads: () => {},
});

export interface KycUIProviderProps {
  kycUIEvents?: IKycUIEvents;
}

export const KycUIProvider: FunctionComponent<KycUIProviderProps> = ({
  kycUIEvents,
  children,
}) => {
  const [showKycModal, setShowKycModal] = useState<boolean>(false);
  const [optionsLeads, setOptionsLeads] = useState<any[]>([]);

  const closeKycModal = () => {
    setShowKycModal(false);
  };

  const newKycModalButtonClick = () => {
    setShowKycModal(true);
    if (kycUIEvents?.newKycModalButtonClick) {
      kycUIEvents.newKycModalButtonClick();
    }
  };

  const value: IKycUIContext = {
    newKycModalButtonClick,
    closeKycModal,
    showKycModal,
    optionsLeads,
    setOptionsLeads,
  };

  return (
    <KycUIContext.Provider value={value}>
      {children}
    </KycUIContext.Provider>
  );
};

export const useKycUIContext = () => useContext(KycUIContext);

export default KycUIProvider;
