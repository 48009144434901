import { ColumnFormatter } from "react-bootstrap-table-next";

interface FormatExtraDataProps {
  icon: string;
}

interface RowProps {
  avatarPictureSetup: boolean;
  avatarPictureUrl: string;
}

export const PhotoColumnFormatter: ColumnFormatter<RowProps, FormatExtraDataProps> = (
  cell,
  row,
  rowIndex,
  formatExtraData
) => (
  <>
    {formatExtraData && (
      <div className="symbol symbol-35 bg-white-o-30 symbol-light-primary flex-shrink-0">
        {row?.avatarPictureSetup && row?.avatarPictureUrl ? (
          <img src={row.avatarPictureUrl} alt="Profile" />
        ) : (
          <span className="symbol-label font-size-h5 bg-white-o-30 font-weight-bold symbol-label">
            <i className={formatExtraData.icon} />
          </span>
        )}
      </div>
    )}
  </>
);
