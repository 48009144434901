import React from "react";
import { ProductsCard } from "./ProductsCard";
import { ProductsUIProvider } from "./ProductsUIContext";
import { shallowEqual, useSelector } from "react-redux";
import { LoadingDialog } from "../../../../../_metronic/_partials/controls";
import { useLocation } from "react-router-dom";

export function ProductsPage({ history, status }) {
  const location = useLocation();

  const { isLoading } = useSelector(
    (state) => ({ isLoading: state.products.listLoading }),
    shallowEqual
  );
  const productsUIEvents = {
    newProductButtonClick: () => {
      history.push("/products/new");
    },
    openProductPage: (product) => {
      history.push(`/products/${product.id}`);
    },
    openFetchProductsDialog: () => {
      history.push(`/products/fetch`);
    },
    openUpdateProductsStatusDialog: () => {
      history.push("/products/updateStatus");
    },
    openCreateLeadPage: (id) => {
      history.push(`/leads/new?p=${id}${history.location.search.replace("?", "&")}`);
    },
    status,
  };

  return (
    <ProductsUIProvider 
      productsUIEvents={productsUIEvents} 
      queryParamsInit={location.state?.projectId ? { projectId: location.state?.projectId } : undefined}
    >
      <LoadingDialog isLoading={isLoading} />
      <ProductsCard />
    </ProductsUIProvider>
  );
}
