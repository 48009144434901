import React, { useEffect, useState } from "react";
import { KycBoTableMode, KycNotificationDestination } from "../../../Kyc";
import "./KycFileItemLangChoose.scss";
import cn from "clsx";
import { isArray } from "lodash-es";
import { useKycFlow } from "../../../../../hooks/useKycFlow";
import { getSignatoryIndex } from "../../../KycUtils";
import { Button } from "react-bootstrap";

interface KycFileItemLangChooseProps {
  notificationMethods: any;
  signatory: any;
  mode: KycBoTableMode;
}

export const KycNotificationChoice = ({
  notificationMethods,
  signatory,
  mode,
}: KycFileItemLangChooseProps): JSX.Element => {
  const [selectedNotifications, setSelectedNotifications] = useState<string[]>([]);
  const { values, saveField } = useKycFlow();
  const signatories = values?.signatories;

  useEffect(() => {
    setSelectedNotifications(
      signatory?.isOfflineSignatory ? [KycNotificationDestination.OFFLINE] : notificationMethods
    );
  }, [notificationMethods]);

  const handleSelectNotificationChannels = (e: any, notificationMethod: string = "") => {
    e.preventDefault();
    let res = isArray(selectedNotifications) ? [...selectedNotifications] : [];
    if (selectedNotifications?.includes(notificationMethod)) {
      if (selectedNotifications.length > 1) {
        res = [...selectedNotifications?.filter((nm) => nm !== notificationMethod)];
      }
    } else {
      if (notificationMethod === KycNotificationDestination.OFFLINE) {
        res = [KycNotificationDestination.OFFLINE];
      } else {
        res = [...res.filter((n) => n !== KycNotificationDestination.OFFLINE), notificationMethod];
      }
    }
    setSelectedNotifications(res);

    const index = getSignatoryIndex(signatories, signatory?.id);
    if (index > -1) {
      saveField(e, {
        field: {
          name: `signatories[${index}].boDeclaration.notificationMethods`,
          value: res ?? [],
        },
      });
    }
  };

  const hasEmailAndMobile = Boolean(signatory?.email && signatory?.mobile);
  const smsAndEmail = [KycNotificationDestination.SMS, KycNotificationDestination.EMAIL];

  const availableNotificationMethods = [
    KycNotificationDestination.OFFLINE,
    ...(hasEmailAndMobile ? smsAndEmail : []),
  ];

  return (
    <>
      {mode === KycBoTableMode.COMPLIANCE ? (
        <div className="d-flex align-items-center justify-content-center">
          {selectedNotifications?.sort()?.map((nm, index) => (
            <span
              className="d-flex align-items-center"
              key={"kyc-notification-choose-" + signatory?.id + "-" + index}
            >
              {index > 0 && (
                <span className="px-1 text-muted">{index && index > 0 ? "|" : ""}</span>
              )}
              {nm}
            </span>
          ))}
        </div>
      ) : (
        <div className="d-flex justify-content-center">
          {availableNotificationMethods?.map((n: string, index) => (
            <Button
              key={"kyc-notification-choose-" + signatory?.id + "-" + index}
              onClick={(e: any) => handleSelectNotificationChannels(e, n)}
              variant="outline"
              className={cn(
                "kyc-button-group-button",
                selectedNotifications?.includes(n) && "kyc-button-group-button-selected"
              )}
            >
              {n}
            </Button>
          ))}
        </div>
      )}
    </>
  );
};
