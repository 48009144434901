import { KycTranslation } from "../../KycTranslation";
import { KycDropdownSelect } from "../controls/kyc-dropdown-select/KycDropdownSelect";
import {
  KYC_V2_OPTIONS_COUNTRIES,
  KYC_V2_OPTIONS_MEANS_OF_ACQUISITION_OF_PROPERTY,
  KYC_V2_OPTIONS_ORIGIN_OF_ASSETS,
  KYC_V2_OPTIONS_PURPOSE_OF_ACQUISITION,
  KYC_V2_OPTIONS_REPAYMENT_METHOD,
} from "../../KycTranslationUtils";
import { KycUploadComponent } from "../controls/kyc-upload/KycUploadComponent";
import { useKycFlow } from "../../../../hooks/useKycFlow";
import { KycFormTitle } from "../layout/KycFormTitle";
import { Field } from "formik";
import { KycInput } from "../controls/kyc-input/KycInput";
import cn from "clsx";
import { useWizard } from "../../../../hooks/useWizard";
import { useKycFile } from "../../../../hooks/useKycFile";
import { isEvidenceOptionSet } from "../../KycFileUtils";
import { getLegalTypeOfClient } from "../../KycUtils";
import { KycEntityType, KycFlowType, KycLegalEntityType } from "../../Kyc";
import { KycDatePicker } from "../controls/kyc-date-picker/KycDatePicker";

export const KycStepClientPage1 = (): JSX.Element => {
  const { values } = useKycFlow();
  const { isExpanded } = useWizard();
  const { currentFiles } = useKycFile();

  const getKycRelatedEntity = () => {
    return {
      id: values?.form?.client?.id,
      type:
        getLegalTypeOfClient(values) === KycLegalEntityType.LEGAL
          ? KycEntityType.CLIENT_LEGAL
          : KycEntityType.CLIENT_NATURAL,
    };
  };

  const buyer = () => {
    return (
      <>
        <KycFormTitle label={KycTranslation.STEPS_CLIENT_PAGE_1_TITLE} />
        <div
          className={cn(
            "d-flex flex-column justify-content-start",
            isExpanded ? "step-content-center-maximized" : "step-content-center"
          )}
        >
          <KycUploadComponent
            name="evidencePurposeOfAcquisition"
            kycRelatedToEntity={getKycRelatedEntity()}
            label={KycTranslation.PURPOSE_OF_ACQUISITION_TITLE}
            infoLabel={KycTranslation.EVIDENCE_DOCUMENT_NAME_PURPOSE_OF_ACQUISITION}
            options={KYC_V2_OPTIONS_PURPOSE_OF_ACQUISITION}
            multiple={false}
          />

          <KycUploadComponent
            name="evidenceOriginOfAssets"
            kycRelatedToEntity={getKycRelatedEntity()}
            label={KycTranslation.ORIGIN_OF_ASSETS_TITLE}
            infoLabel={KycTranslation.EVIDENCE_DOCUMENT_NAME_ORIGIN_OF_ASSETS}
            options={KYC_V2_OPTIONS_ORIGIN_OF_ASSETS}
            multiple={true}
          />

          {isEvidenceOptionSet(currentFiles, "originOfAssetsBankLoan") && (
            <KycUploadComponent
              name="evidenceRepaymentMethod"
              kycRelatedToEntity={getKycRelatedEntity()}
              label={KycTranslation.REPAYMENT_METHOD_TITLE}
              infoLabel={KycTranslation.EVIDENCE_DOCUMENT_NAME_REPAYMENT_METHOD}
              options={KYC_V2_OPTIONS_REPAYMENT_METHOD}
              multiple={true}
            />
          )}
          <Field
            indent={false}
            component={KycDropdownSelect}
            name="form.originOfFunds"
            options={KYC_V2_OPTIONS_COUNTRIES}
            translateLabels={true}
            translateRegion={true}
            label={KycTranslation.ORIGIN_OF_FUNDS_TITLE}
          />
          <Field
            name="form.accurateDescriptionOfOriginOfFunds"
            component={KycInput}
            label={KycTranslation.ORIGIN_OF_FUNDS_ACCURATE_DESCRIPTION_BUYER}
          />
        </div>
      </>
    );
  };

  const seller = () => {
    return (
      <>
        <KycFormTitle label={KycTranslation.STEPS_CLIENT_PAGE_1_SELLER_TITLE} />
        <div
          className={cn(
            "d-flex flex-column justify-content-start",
            isExpanded ? "step-content-center-maximized" : "step-content-center"
          )}
        >
          <Field
            className="form-control react-datepicker-ignore-onclickoutside no-validation"
            name="form.dateOfAcquisition"
            component={KycDatePicker}
            label={KycTranslation.DATE_OF_ACQUISITION}
            withFeedbackLabel={false}
            showTimeSelect={false}
            dateFormat="P"
            popperPlacement="bottom-start"
            autoComplete="off"
          />
          <KycUploadComponent
            name="evidenceMeansOfAcquisition"
            kycRelatedToEntity={getKycRelatedEntity()}
            label={KycTranslation.MEANS_OF_ACQUISITION_TITLE}
            options={KYC_V2_OPTIONS_MEANS_OF_ACQUISITION_OF_PROPERTY}
            multiple={true}
          />

          <Field
            name="form.accurateDescriptionOfOriginOfFunds"
            component={KycInput}
            label={KycTranslation.ORIGIN_OF_FUNDS_ACCURATE_DESCRIPTION_SELLER}
          />
        </div>
      </>
    );
  };

  return values?.flowType === KycFlowType.BUYER ? buyer() : seller();
};
