import {
  BudgetLineBaseProps,
  BudgetLineIncludingTaxProps,
  IBudgetLine,
  IBudgetSortedCategory,
  IProject,
  ISFFFile,
  SFFRelatedBudgetFinanceIncludingTaxType,
  SFFRelatedBudgetFinanceType,
  SubcontractorFinanceType,
  SubcontractorsFinanceFiles,
} from "../../../data/schemas";
import { sortBy } from "lodash-es";
import { getTotalInvoiceStatus } from "./utils";

export const sumBudgetLineBaseProps = (data: any[]) =>
  data.reduce((acc, dataObj) => {
    for (const financeType of Object.values(BudgetLineBaseProps)) {
      if (dataObj?.[financeType]) {
        acc[financeType] = (acc[financeType] ?? 0) + dataObj[financeType];
      }
    }
    for (const financeType of Object.values(BudgetLineIncludingTaxProps)) {
      if (dataObj?.[financeType]) {
        acc[financeType] = (acc[financeType] ?? 0) + dataObj[financeType];
      }
    }
    if (dataObj.invoiceStatusCount) {
      acc.invoiceStatusCount = Object.keys(dataObj.invoiceStatusCount).reduce(
        (invoiceStatusAcc, key) => ({
          ...invoiceStatusAcc,
          [key]: (acc?.invoiceStatusCount?.[key] ?? 0) + dataObj.invoiceStatusCount[key],
        }),
        acc.invoiceStatusCount
      );
    }
    return acc;
  }, {});

export const computeRelatedProps = ({
  orders,
  supplementary_agreements,
  planned,
  invoiced,
  invoicedOrders,
  invoicedSupplementaryAgreements,
  discounts = 0,
  discountsOrders = 0,
  discountsSupplementaryAgreements = 0,
  invoiceStatusCount,
}: any) => {
  const totalReal =
    orders || supplementary_agreements
      ? (orders ?? 0) + (supplementary_agreements ?? 0)
      : undefined;
  const realDifferenceCategory = totalReal && planned ? totalReal - planned : 0;
  return {
    ordersAndSupplAgreements: totalReal,
    realDifference: totalReal && planned ? realDifferenceCategory : undefined,
    realDifferencePercentage:
      totalReal && planned ? (realDifferenceCategory / planned) * 100 : undefined,
    invoicedPercentage:
      invoiced && totalReal ? (invoiced / (totalReal - discounts)) * 100 : undefined,
    remainingToInvoice:
      totalReal || invoiced ? (totalReal ?? 0) - discounts - (invoiced ?? 0) : undefined,
    remainingOrdersToInvoice: orders ? orders - discountsOrders - (invoicedOrders ?? 0) : undefined,
    invoicedOrdersPercentage:
      orders && invoicedOrders ? (invoicedOrders / (orders - discountsOrders)) * 100 : undefined,
    remainingSupplementaryAgreementsToInvoice: supplementary_agreements
      ? supplementary_agreements -
        discountsSupplementaryAgreements -
        (invoicedSupplementaryAgreements ?? 0)
      : undefined,
    invoicedSupplementaryAgreementsPercentage:
      supplementary_agreements && invoicedSupplementaryAgreements
        ? (invoicedSupplementaryAgreements /
            (supplementary_agreements - discountsSupplementaryAgreements)) *
          100
        : undefined,
    invoiceStatus: !!invoiceStatusCount ? getTotalInvoiceStatus(invoiceStatusCount) : undefined,
  };
};

export const computeUnspecifiedLines = (categoryId: string, project?: Partial<IProject>) =>
  Object.values(SubcontractorFinanceType).reduce<any[]>(
    (acc, sffType) => [
      ...acc,
      ...(Object.entries(
        project?.subcontractorsFinanceFiles?.[sffType]?.data?.[categoryId]?.lines ?? {}
      )
        ?.filter(([, line]) => !line.budgetLineId)
        .map(([id, line]: any) => computeLine({ project, categoryId, line: { ...line, id } })) ??
        []),
    ],
    []
  );

export interface ComputeLineProps {
  project?: Partial<IProject>;
  categoryId: string;
  line: IBudgetLine;
}

export const computeCategory = (
  categoryId: string,
  project?: Partial<IProject>,
  category?: IBudgetSortedCategory
): any => {
  const computedLines =
    category?.lines?.map((line) => computeLine({ project, categoryId, line })) ?? [];
  const unspecifiedLines = computeUnspecifiedLines(categoryId, project);
  const linesTotal = sumBudgetLineBaseProps([...computedLines, ...unspecifiedLines]);
  return {
    id: categoryId,
    label: category?.label,
    ...linesTotal,
    ...computeRelatedProps(linesTotal),
    lines: computedLines,
    unspecifiedLines,
    sequence: category?.sequence,
    visible: category?.visible,
  };
};

export const computeLine = ({ project, categoryId, line }: ComputeLineProps) => {
  const subcontractors = computeSubcontractors(
    project?.subcontractorsFinanceFiles,
    categoryId,
    line.id
  );
  const subcontractorsTotal = sumBudgetLineBaseProps(subcontractors);
  return {
    id: line.id,
    categoryId,
    label: line.label,
    ...subcontractorsTotal,
    ...computeRelatedProps({ ...subcontractorsTotal, planned: line.plannedBudget }),
    planned: line.plannedBudget,
    subcontractors,
    sequence: line?.sequence,
  };
};

export const computeSubcontractors = (
  subcontractorsFinanceFiles: SubcontractorsFinanceFiles | undefined,
  categoryId: string,
  budgetLineId: string
) => {
  let res: any[] = [];
  const invoicesToHide: Record<string, string[]> = {};
  for (const sffType of Object.values(SubcontractorFinanceType)) {
    const sffLine =
      subcontractorsFinanceFiles?.[sffType]?.data?.[categoryId]?.lines?.[budgetLineId];
    if (sffLine) {
      for (const file of sffLine.files) {
        const { userId, userName, id: fileId } = file;
        if (invoicesToHide[userId]?.find((f: string) => f === fileId)) {
          continue;
        }
        let subcontractor = res.find((sub) => sub.userId === userId);
        if (!subcontractor) {
          subcontractor = {
            userId,
            label: userName,
            files: [],
            lineId: budgetLineId,
            categoryId: categoryId,
          };
          res.push(subcontractor);
        }
        const computedFile = computeFile({
          file,
          sffType,
          subcontractorsFinanceFiles,
          categoryId,
          budgetLineId,
          invoicesToHide,
        });
        let updatedSubcontractorValues = sumBudgetLineBaseProps([subcontractor, computedFile]);
        updatedSubcontractorValues = {
          ...updatedSubcontractorValues,
          ...computeRelatedProps(updatedSubcontractorValues),
        };
        for (const prop in updatedSubcontractorValues) {
          subcontractor[prop] = updatedSubcontractorValues[prop];
        }
        subcontractor.files.push(computedFile);
      }
    }
  }
  return sortBy(res, "label");
};

interface ComputeFileProps {
  file: ISFFFile;
  sffType: SubcontractorFinanceType;
  subcontractorsFinanceFiles?: SubcontractorsFinanceFiles;
  categoryId: string;
  budgetLineId: string;
  invoicesToHide: any;
}

const computeFile = ({
  file,
  sffType,
  subcontractorsFinanceFiles,
  categoryId,
  budgetLineId,
  invoicesToHide,
}: ComputeFileProps) => {
  const budgetFinanceType = SFFRelatedBudgetFinanceType[sffType];
  const budgetFinanceIncludingTaxType = SFFRelatedBudgetFinanceIncludingTaxType[sffType];
  let computedFile: any = {
    label: file.friendlyName,
    fileType: file.fileType,
    fileId: file.id,
    userId: file.userId,
    categoryId,
    lineId: budgetLineId,
    [budgetFinanceIncludingTaxType]:
      sffType === SubcontractorFinanceType.INVOICE
        ? file.grossAmount + file.grossAmount * file.vat
        : file.amount + file.amount * file.vat,
    [budgetFinanceType]: file.amount,
  };
  if (sffType === SubcontractorFinanceType.INVOICE) {
    computedFile.discounts = file.grossAmount - file.amount;
    computedFile.discounts_including_tax =
      computedFile.discounts + computedFile.discounts * file.vat;
    computedFile.invoiceStatusCount = file.invoiceStatus ? { [file.invoiceStatus]: 1 } : undefined;
  }
  // Temporary use linked files to link orders/SA to invoices
  if (
    [SubcontractorFinanceType.ORDER, SubcontractorFinanceType.SUPPLEMENTARY_AGREEMENT].includes(
      sffType
    ) &&
    file.linkedFiles
  ) {
    for (const linkedFile of file.linkedFiles) {
      const invoice = subcontractorsFinanceFiles?.invoices?.data?.[categoryId]?.lines?.[
        budgetLineId
      ]?.files?.find((f) => f.id === linkedFile.id);
      if (invoice) {
        if (!invoicesToHide[file.userId]) {
          invoicesToHide[file.userId] = [invoice.id];
        } else {
          invoicesToHide[file.userId].push(invoice.id);
        }
        const discount = invoice.grossAmount - invoice.amount;

        if (invoice.invoiceStatus) {
          computedFile.invoiceStatusCount = computedFile.invoiceStatusCount ?? {};
          computedFile.invoiceStatusCount[invoice.invoiceStatus] =
            (computedFile.invoiceStatusCount[invoice.invoiceStatus] ?? 0) + 1;
        }
        computedFile.invoiced = (computedFile.invoiced ?? 0) + invoice.amount;
        computedFile.invoiced_including_tax = computedFile.invoiced * (1 + file.vat);
        computedFile.discounts = (computedFile.discounts ?? 0) + discount;
        computedFile.discounts_including_tax = computedFile.discounts * (1 + file.vat);
        if (sffType === SubcontractorFinanceType.ORDER) {
          computedFile.invoicedOrders = (computedFile.invoicedOrders ?? 0) + invoice.amount;
          computedFile.discountsOrders = (computedFile.discountsOrders ?? 0) + discount;
          computedFile.invoicedOrders_including_tax = computedFile.invoicedOrders * (1 + file.vat);
          computedFile.discountsOrders_including_tax =
            computedFile.discountsOrders * (1 + file.vat);
        } else {
          computedFile.invoicedSupplementaryAgreements =
            (computedFile.invoicedSupplementaryAgreements ?? 0) + invoice.amount;
          computedFile.discountsSupplementaryAgreements =
            (computedFile.discountsSupplementaryAgreements ?? 0) + discount;
          computedFile.invoicedSupplementaryAgreements_including_tax =
            computedFile.invoicedSupplementaryAgreements * (1 + file.vat);
          computedFile.discountsSupplementaryAgreements_including_tax =
            computedFile.discountsSupplementaryAgreements * (1 + file.vat);
        }
      }
    }
  }
  computedFile = {
    ...computedFile,
    ...computeRelatedProps(computedFile),
  };

  return computedFile;
};
