import { createSlice } from "@reduxjs/toolkit";
import { set } from "lodash-es";

const initialFormsState = {
  listLoading: false,
  actionsLoading: false,
  actionNotFound: false,
  entities: [],
  formForEdit: {
    saved: undefined,
    current: undefined,
  },
  action: {
    saved: undefined,
    current: undefined,
  },
};
export const callTypes = {
  list: "list",
  action: "action",
};

export const formsSlice = createSlice({
  name: "forms",
  initialState: initialFormsState,
  reducers: {
    catchError: (state, action) => {
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    formFetched: (state, action) => {
      state.actionsLoading = false;
      state.formForEdit = {
        saved: action.payload.formForEdit,
        current: action.payload.formForEdit,
      };
      state.error = null;
    },
    formFieldUpdatedLocally: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      if (!state.formForEdit.current) {
        state.formForEdit.current = {};
      }
      set(state.formForEdit.current, action.payload.key, action.payload.value);
    },
    actionFetched: (state, action) => {
      state.action = { ...action.payload.action };
      state.actionNotFound = false;
      state.actionsLoading = false;
      state.formForEdit = {
        saved: action.payload.formForEdit,
        current: action.payload.formForEdit,
      };
      state.action = {
        saved: action.payload.action,
        current: action.payload.action,
      };
      state.error = null;
    },
    actionNotFound: (state, action) => {
      state.actionNotFound = true;
    },
    formUpdated: (state, action) => {
      state.action = { ...action.payload.action };

      state.action = {
        saved: action.payload.action,
        current: action.payload.action,
      };

      state.error = null;
      state.actionsLoading = false;
      state.formForEdit = {
        saved: action.payload.formForEdit,
        current: action.payload.formForEdit,
      };
      state.entities = state.entities.map((entity) => {
        if (entity.id === action.payload.form.id) {
          return action.payload.form;
        }
        return entity;
      });
    },
  },
});
