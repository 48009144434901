import * as React from "react";
import { FormattedMessage } from "react-intl";

import {
  useRequestSignatureDialogContext,
  RequestSignatureDialogUiState,
} from "./RequestSignatureDialogContext";

export const RequestSignatureDialogFooter: React.FunctionComponent = () => {
  const {
    uiState,
    currentSignaturesRequest,
    toggleCancellationUiState,
    isSendButtonDisabled,
    isLoading,
    closeDialog,
    submit,

    cancellationReason,
    cancelPrevious,
  } = useRequestSignatureDialogContext();

  switch (uiState) {
    case RequestSignatureDialogUiState.CANCELLATION:
      return (
        <div>
          <button
            type="button"
            className="btn btn-light mr-4"
            data-cy="button-cancel-action"
            onClick={toggleCancellationUiState}
            disabled={isLoading}
          >
            <FormattedMessage id="COMMON.ACTION.CANCEL" />
          </button>

          <button
            type="button"
            className="btn btn-danger"
            data-cy="button-yes-cancel-all-signatures-action"
            onClick={cancelPrevious(currentSignaturesRequest, cancellationReason)}
            disabled={isLoading}
          >
            <FormattedMessage id="COMMON.ACTION.CANCEL.ALL_REQUESTS.CONFIRM" />
          </button>
        </div>
      );

    case RequestSignatureDialogUiState.VIEW:
      return (
        <div>
          <button
            type="button"
            className="btn btn-outline-danger mr-4"
            data-cy="button-cancel-all-signatures-action"
            onClick={toggleCancellationUiState}
          >
            <FormattedMessage id="COMMON.ACTION.CANCEL.ALL_REQUESTS" />
          </button>

          <button
            type="button"
            className="btn btn-primary"
            data-cy="button-ok-action"
            onClick={closeDialog}
          >
            <FormattedMessage id="COMMON.ACTION.OK" />
          </button>
        </div>
      );

    case RequestSignatureDialogUiState.DONE:
      return (
        <div>
          <button
            type="button"
            className="btn btn-primary"
            data-cy="button-ok-action"
            onClick={closeDialog}
          >
            <FormattedMessage id="COMMON.ACTION.OK" />
          </button>
        </div>
      );

    default:
      return (
        <div>
          <button
            type="button"
            className="btn btn-light mr-4"
            data-cy="button-cancel-signature"
            onClick={closeDialog}
            disabled={isLoading}
          >
            <FormattedMessage id="COMMON.ACTION.CANCEL" />
          </button>

          <button
            type="button"
            className="btn btn-primary"
            data-cy="button-send-signature"
            onClick={submit}
            disabled={isSendButtonDisabled}
          >
            <FormattedMessage id="COMMON.ACTION.SEND" />
          </button>
        </div>
      );
  }
};

export default RequestSignatureDialogFooter;
