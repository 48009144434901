import * as React from "react";
import PropTypes from "prop-types";
import { useAppSelector } from "../../redux/hooks";
import { shallowEqual } from "react-redux";

interface SessionProps {
  children: React.ReactNode;
}

export interface SessionContextValue {
  session: any;
}

export const SessionContext = React.createContext<SessionContextValue>({
  session: undefined,
});

export const SessionProvider: React.FunctionComponent<SessionProps> = (props) => {
  const { children } = props;

  const { session } = useAppSelector(
    (state: any) => ({
      session: state.auth.session,
    }),
    shallowEqual
  );

  return (
    <SessionContext.Provider
      value={{
        session,
      }}
    >
      {children}
    </SessionContext.Provider>
  );
};

SessionProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export const SessionConsumer = SessionContext.Consumer;
