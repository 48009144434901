import React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { EMAIL, NotificationTypes, SMS } from "../_utils/notificationTypes";
import { Switch } from "@material-ui/core";

import { TUserTypes } from "data/schemas";

interface NotificationProps {
  notifyMethods: string[];
  setNotifyMethods: (data: string[]) => void;
  userType: TUserTypes;
}

interface NotificationButtonsProps {
  notifyMethods: string[];
  setNotifyMethods: (data: string[]) => void;
}

export const Notification: React.FunctionComponent<NotificationProps> = ({
  setNotifyMethods,
  notifyMethods,
  userType,
}) => {
  return (
    <div className="d-flex flex-wrap">
      <div className="mr-auto p-2 text-nowrap">
        <FormattedMessage id={`COMMON.ACTION.NOTIFY_${userType}_BY`} />
      </div>
      <div className="d-flex flex-wrap">
        <NotificationButtons notifyMethods={notifyMethods} setNotifyMethods={setNotifyMethods} />
      </div>
    </div>
  );
};

export const NotificationButtons: React.FC<NotificationButtonsProps> = ({
  notifyMethods,
  setNotifyMethods,
}) => {
  React.useEffect(() => {
    setNotifyMethods([options.EMAIL.value]);
  }, []);

  React.useEffect(() => {}, [notifyMethods]);

  const intl = useIntl();

  const options = {
    EMAIL: { label: intl.formatMessage({ id: "COMMON.EMAIL" }), value: EMAIL },
    SMS: { label: intl.formatMessage({ id: "COMMON.SMS" }), value: SMS },
  };

  const updateNotifyMethods = (isChecked: any, notificationType: NotificationTypes) => {
    let updatedMethods: string[] = [...notifyMethods];
    if (isChecked) {
      updatedMethods.push(options[notificationType].value);
    } else {
      updatedMethods = updatedMethods.filter((value) => value !== notificationType);
    }
    setNotifyMethods(updatedMethods);
  };

  return (
    <>
      <div className="text-nowrap">
        <Switch
          defaultChecked={true}
          className="text-primary"
          onChange={(e: any) => updateNotifyMethods(e.target.checked, NotificationTypes.EMAIL)}
          data-cy="switch-send-email"
        />
        <FormattedMessage id="COMMON.EMAIL" />
      </div>

      <div className="text-nowrap">
        <Switch
          onChange={(e: any) => updateNotifyMethods(e.target.checked, NotificationTypes.SMS)}
          data-cy="switch-send-sms"
        />
        <FormattedMessage id="COMMON.SMS" />
      </div>
    </>
  );
};
