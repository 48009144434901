import { useWizard } from "app/hooks/useWizard";
import { KycTranslation } from "../../KycTranslation";
import { KycStep } from "../../Kyc";
import { useTranslation } from "../../../../hooks/useTranslation";
import { useKycFlow } from "../../../../hooks/useKycFlow";

export const KycFooterDisclaimer = (): JSX.Element => {
  const { fm } = useTranslation();
  const { currentStepId } = useWizard();
  const { isClientViewer } = useKycFlow();

  const isShowDisclaimer = (): boolean => {
    const excludedSteps: KycStep[] = [
      KycStep.STEP_START_FLOW,
      KycStep.STEP_DISCLAIMER,
      KycStep.STEP_CLIENT_PAGE_4,
      KycStep.STEP_SUCCESS,
    ];

    return !isClientViewer && !excludedSteps?.includes(currentStepId() as KycStep);
  };

  return (
    <>
      {isShowDisclaimer() && (
        <div className="p-2">
          <div
            className="mx-auto"
            style={{
              marginTop: 12,
              padding: 20,
              border: "1px solid #0033333",
              backgroundColor: "#f5f5f5",
              maxWidth: 1000,
              position: "relative",
              bottom: "0",
              textAlign: "justify",
              textJustify: "inter-word",
              fontSize: "0.8rem",
            }}
          >
            {fm(KycTranslation.DISCLAIMER_CONTENT, {
              br: <br />,
            })}
          </div>
        </div>
      )}
    </>
  );
};
