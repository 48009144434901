/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import SVG from "react-inlinesvg";
import { FormattedMessage } from "react-intl";

export function ActiveFilesWidget({ className }) {
  const backgroundImageUrl = toAbsoluteUrl("/media/stock-600x600/img-12.jpg");

  return (
    <>
      <div
        className={`card card-custom bgi-no-repeat bgi-position-x-center ${className}`}
        style={{
          backgroundImage: `url("${backgroundImageUrl}")`,
        }}
      >
        {/* begin::Body */}
        <div className="card-body d-flex flex-column align-items-start justify-content-start">
          <div className="p-1 flex-grow-1">
            <h3 className="font-weight-bolder line-height-lg mb-5">
              <FormattedMessage id="LEADS.TITLE.FILES.ACTIVE" />
            </h3>
          </div>

          <a href="/leads" className="btn btn-link btn-link-warning font-weight-bold">
            <FormattedMessage id="COMMON.VIEW_ALL" />
            <span className="svg-icon-lg svg-icon-warning">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Navigation/Arrow-right.svg")} />
            </span>
          </a>
        </div>
        {/* end::Body */}
      </div>
    </>
  );
}
