import React, { useEffect, useMemo, useState } from "react";
import { Modal } from "react-bootstrap";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as actions from "../../../../_redux/products/productsActions";
import { ProductFileEditDialogHeader } from "./ProductFileEditDialogHeader";
import { ProductFileEditForm } from "./ProductFileEditForm";
import { useProductFilesUIContext } from "../ProductFilesUIContext";
import { unloadHandler } from "../../../../../../_utils/formUtils";

export function ProductFileEditDialog() {
  const productFilesUIContext = useProductFilesUIContext();
  const productFilesUIProps = useMemo(() => {
    return {
      id: productFilesUIContext.selectedId,
      setIds: productFilesUIContext.setIds,
      queryParams: productFilesUIContext.queryParams,
      showEditFileDialog: productFilesUIContext.showEditFileDialog,
      closeEditFileDialog: productFilesUIContext.closeEditFileDialog,
      initFile: productFilesUIContext.initFile,
      newFile: productFilesUIContext.newFile,
    };
  }, [productFilesUIContext]);

  const dispatch = useDispatch();
  const { actionsLoading, productFileForEdit, session } = useSelector(
    (state) => ({
      actionsLoading: state.products.actionsLoading,
      productFileForEdit: state.products.productForEdit?.current?.files?.find(
        (file) => file.id === productFilesUIProps.id
      ),
      session: state.auth.session,
    }),
    shallowEqual
  );

  useEffect(() => {
    // dispatch(actions.fetchFile(productFilesUIProps.id));
    console.log("productFilesUIProps.id:", productFilesUIProps.id);
  }, [productFilesUIProps.id]);

  const [progress, setProgress] = useState(0);

  const saveFile = (productFile) => {
    window.addEventListener("beforeunload", unloadHandler);
    if (!productFilesUIProps.id) {
      return dispatch(actions.uploadProductFile(productFile, session?.id, setProgress))
        .then(({ file }) => {
          dispatch(actions.createProductFile(file)).then(() => {
            productFilesUIProps.setIds([]);
            productFilesUIProps.closeEditFileDialog();
            window.removeEventListener("beforeunload", unloadHandler);
            setProgress(0);
          });
        })
        .catch((error) => {
          window.removeEventListener("beforeunload", unloadHandler);
          return Promise.reject(error);
        });
    } else {
      dispatch(actions.updateFile(productFile)).then(() => {
        productFilesUIProps.setIds([]);
        productFilesUIProps.closeEditFileDialog();
        window.removeEventListener("beforeunload", unloadHandler);
      });
    }
  };

  return (
    <Modal
      show={productFilesUIProps.showEditFileDialog}
      onHide={productFilesUIProps.closeEditFileDialog}
      centered
      scrollable
      backdrop="static"
      size={"lg"}
      keyboard={!actionsLoading}
    >
      <ProductFileEditDialogHeader
        productFileForEdit={productFileForEdit}
        actionsLoading={actionsLoading}
        showEditFileDialog={productFilesUIProps.showEditFileDialog}
      />
      <ProductFileEditForm
        saveFile={saveFile}
        actionsLoading={actionsLoading}
        file={productFileForEdit || productFilesUIProps.initFile}
        onHide={productFilesUIProps.closeEditFileDialog}
        newFile={productFilesUIProps.newFile}
        progress={progress}
      />
    </Modal>
  );
}
