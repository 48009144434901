import * as React from "react";
import { Table } from "@tanstack/react-table";
import { TRowTheme } from "../CardTableThemes";

export interface ICardTableContext<T> {
  isScrolled: boolean;
  setIsScrolled: React.Dispatch<React.SetStateAction<boolean>>;

  isScrolledEnd: boolean;
  setIsScrolledEnd: React.Dispatch<React.SetStateAction<boolean>>;
  hiddenColumnsStorageKey: string;
  table: Table<T>;
}

export interface CardTableItem {
  id: string;
  defaultExpanded?: boolean;
  children?: this[];
  rowTheme?: TRowTheme;
  footer?: React.ReactNode;
}

export interface EditableCardTableItem extends CardTableItem {
  sequence?: number;
}

export const CardTableContext = React.createContext<ICardTableContext<any>>(
  {} as ICardTableContext<any>
);

export function useCardTableContext<T extends CardTableItem>() {
  return React.useContext<ICardTableContext<T>>(
    CardTableContext as React.Context<ICardTableContext<T>>
  );
}

export interface CardTableProviderProps<T> {
  table: Table<T>;
  hiddenColumnsStorageKey: string;
}

export const CardTableProvider = <T extends CardTableItem>({
  children,
  table,
  hiddenColumnsStorageKey,
}: React.PropsWithChildren<CardTableProviderProps<T>>) => {
  const [isScrolled, setIsScrolled] = React.useState<boolean>(false);
  const [isScrolledEnd, setIsScrolledEnd] = React.useState<boolean>(false);

  const value: ICardTableContext<T> = {
    isScrolled,
    setIsScrolled,
    isScrolledEnd,
    setIsScrolledEnd,
    hiddenColumnsStorageKey,
    table,
  };

  return <CardTableContext.Provider value={value}>{children}</CardTableContext.Provider>;
};
