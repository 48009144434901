import React, { FC, useMemo } from "react";
import { set } from "lodash-es";

import { usePublicRequests } from "../../hooks/usePublicRequests";
import { KycAmlFilloutCard } from "../../../../_components/kyc-aml/KycAmlFilloutCard";

interface UnAuthRequestKycAmlFilloutCardProps {
  submitForm: Function;
  title: string;
}
export const KycAmlFilloutCardPublicWrapper: FC<UnAuthRequestKycAmlFilloutCardProps> = ({
  submitForm,
  title,
}) => {
  const unAuthRequestUIContext = usePublicRequests();
  const { user, action, settings, formData } = unAuthRequestUIContext;
  const { setFormData, formDataFields } = useMemo(
    () => ({
      setFormData: unAuthRequestUIContext.setFormData,
      formDataFields: unAuthRequestUIContext.formData?.fields,
    }),
    [unAuthRequestUIContext]
  );

  const saveFormFields = (key: any, value: any) => {
    if (Array.isArray(key) && key.length === value.length) {
      for (const k of Object.keys(key) as any) {
        set(formData, `fields.${key[k]}`, value[k]);
      }
    } else {
      set(formData, `fields.${key}`, value);
    }
  };

  return (
    <KycAmlFilloutCard
      setFormData={setFormData}
      session={user}
      disabled={action?.status === "DONE"}
      saveFormFields={saveFormFields}
      formDataFields={formDataFields}
      submitForm={submitForm}
      title={title}
      settings={settings}
    />
  );
};
