import React from "react";
import { FieldFeedbackLabel } from "./FieldFeedbackLabel";
import { get } from "lodash-es";

const getFieldCSSClasses = (touched, errors, value) => {
  const classes = ["form-control"];
  if (touched && errors) {
    classes.push("is-invalid");
  }
  return classes.join(" ");
};

export function Input({
  field, // { name, value, onChange, onBlur }
  form: { touched, errors, values }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  label,
  withFeedbackLabel = true,
  customFeedbackLabel,
  inline = false,
  type = "text",
  className,
  ...props
}) {
  return (
    <>
      {inline ? (
        <>
          {label && <label className={"col-lg-3 col-form-label text-lg-right"}>{label}</label>}
          <div className={"col-md-9 col-lg-4"}>
            <input
              type={type}
              className={
                getFieldCSSClasses(
                  get(touched, field.name),
                  get(errors, field.name),
                  get(values, field.name)
                ) +
                " " +
                className
              }
              {...field}
              {...props}
            />
            {withFeedbackLabel && !props.disabled && (
              <FieldFeedbackLabel
                error={get(errors, field.name)}
                touched={get(touched, field.name)}
                value={field.value}
                label={label}
                type={type}
                customFeedbackLabel={customFeedbackLabel}
              />
            )}
          </div>
        </>
      ) : (
        <>
          {label && (
            <label>
              {/*{!props.disabled && "Enter "} {label}*/}
              {/*DISABLE "Enter" in front of Input Label*/}
              {!props.disabled} {label}
            </label>
          )}
          <input
            type={type}
            className={
              getFieldCSSClasses(
                get(touched, field.name),
                get(errors, field.name),
                get(values, field.name)
              ) +
              " " +
              className
            }
            {...field}
            {...props}
          />
          {withFeedbackLabel && !props.disabled && (
            <FieldFeedbackLabel
              error={get(errors, field.name)}
              touched={get(touched, field.name)}
              value={field.value}
              label={label}
              type={type}
              customFeedbackLabel={customFeedbackLabel}
            />
          )}
        </>
      )}
    </>
  );
}
