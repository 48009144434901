import { FormattedMessage } from "react-intl";
import * as React from "react";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import { Link } from "react-router-dom";

export const ForgotPasswordSuccessfullyChanged = () => {
  return (
    <div className="login-form login-forgot" style={{ display: "block" }}>
      <div className="text-center mb-10 mb-lg-20">
        <h3 className="font-size-h1">
          <FormattedMessage id="PROFILE.PASSWORD.NEW" />
        </h3>
        <div className="text-muted font-weight-bold">
          <FormattedMessage id="PROFILE.PASSWORD.NEW.ENTER" />
        </div>
      </div>
      <div className="d-flex flex-column align-items-center">
        <span className="svg-icon svg-icon-9x svg-icon-success mb-4">
          <SVG src={toAbsoluteUrl("/media/svg/icons/Code/Done-circle.svg")} />
        </span>
        <h1>
          <FormattedMessage id="AUTH.FORGOT_PASSWORD.CHANGED" />
        </h1>
        <Link className="btn btn-link-primary font-size-h4" to="/auth">
          <FormattedMessage id="AUTH.FORGOT_PASSWORD.SUCCESS_LINK" />
        </Link>
      </div>
    </div>
  );
};
