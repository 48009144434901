import React from "react";
import { Modal } from "react-bootstrap";
import { FormattedMessage } from "react-intl";

interface WarningModalProps {
  displayModal: boolean;
  modalTitle: string;
  modalDescription: string;
  setShowModal: (show: boolean) => void;
}

export default function WarningModal({
  displayModal,
  modalTitle,
  modalDescription,
  setShowModal,
}: WarningModalProps) {
  return (
    <Modal show={displayModal} size="lg" aria-labelledby="user-avatar-cropper-modal" centered>
      <Modal.Header>
        <Modal.Title>
          <FormattedMessage id={modalTitle} />
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <FormattedMessage id={modalDescription} />
      </Modal.Body>
      <Modal.Footer>
        <button
          type="button"
          onClick={() => {
            setShowModal(false);
          }}
          className="btn btn-primary btn-elevate"
        >
          <FormattedMessage id="COMMON.CLOSE" />
        </button>
      </Modal.Footer>
    </Modal>
  );
}
