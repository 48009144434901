import {
  KycComplianceViewMode,
  KycFlowStatus,
  KycLoading,
  KycRiskAssessmentStatus,
  KycStep,
} from "../../Kyc";
import * as React from "react";
import { useEffect, useState } from "react";
import { KycComplianceDetailsSection } from "./KycComplianceDetailsSection";
import { KycFileExplorer } from "./file-explorer/KycFileExplorer";
import { useKyc } from "../../../../hooks/useKyc";
import { KycComplianceDetailsOverviewSection } from "./overview/KycComplianceDetailsOverviewSection";
import { LoaderSpinner } from "../../../../_components/LoaderSpinner";
import { KycAuditTrailViewer } from "./event/KycAuditTrailViewer";
import { useWebsocket } from "../../../../hooks/useWebsocket";
import { WizardStepper } from "../../../../_components/wizard/WizardStepper";
import { WIZARD_ORIENTATION } from "../../../../_components/wizard/Wizard";
import { useWizard } from "../../../../hooks/useWizard";
import { WizardNavbar } from "../../../../_components/wizard/WizardNavbar";
import {
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  CardHeaderToolbar,
} from "../../../../../_metronic/_partials/controls";
import { Carousel } from "react-bootstrap";
import { KycBeneficialOwnerSection } from "./beneficial-owner/KycBeneficialOwnerSection";
import { KycComplianceItemToolbar } from "./KycComplianceItemToolbar";
import { KycStepRiskEvaluation } from "../steps/KycStepRiskEvaluation";
import { canEdit, canSeeAuditTrail } from "../../../../_utils/authUtils";
import { useAppSelector } from "../../../../../redux/hooks";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { KycComplianceBackButton } from "./KycComplianceBackButton";
import { KycTranslation } from "../../KycTranslation";
import { KycBreadcrumbs } from "../../KycBreadcrumbs";
import { getStatusProperties, KycStatusMode } from "../../KycTranslationUtils";
import { useTranslation } from "../../../../hooks/useTranslation";
import { getAllEntityIdsAndNames, isKycFlowInTerminalState, statusAsArray } from "../../KycUtils";
import { useIntl } from "react-intl";
import { KycFilesUIProvider } from "./KycFilesUIContext";
import { SearchProvider } from "../../../../contexts/SearchContext";
import { KycSummary } from "./docs-summary/KycSummary";
import { TextEditor } from "../../../../_components/TextEditor/TextEditor";
import { useKycFlow } from "../../../../hooks/useKycFlow";
import { Field, Formik } from "formik";
import { basicToolbar, commentToolbar } from "../../../../_components/TextEditor/config";
import TextareaAutosize from "@material-ui/core/TextareaAutosize/TextareaAutosize";
import { findAndDeleteFileById } from "../../KycFileUtils";
import { useKycFile } from "../../../../hooks/useKycFile";
import { useLoading } from "../../../../hooks/useLoading";

export const KycComplianceItem = (): JSX.Element => {
  const history = useHistory();
  const location = useLocation();
  const params: { id: string; kycId: string } = useParams();
  const intl = useIntl();

  const { fDisplayName, fm } = useTranslation();
  const { getKycFlow, currentKycFlow } = useKyc();
  const { customHandleChange, values } = useKycFlow();
  const { currentStepId, steps } = useWizard();
  const { listFilesByKycId, setCurrentFiles, getFileById, currentFiles } = useKycFile();
  const { removeLoading } = useLoading();

  const [activeIndex, setActiveIndex] = useState<number>(0);
  const [sourceEntities, setSourceEntities] = useState<any[]>([]);
  const [selectedEntity, setSelectedEntity] = useState<{ id: string; searchText: string }>({
    id: "",
    searchText: "",
  });
  const [kycEntitiesMap, setKycEntitiesMap] = useState<any>({});
  const [viewMode, setViewMode] = useState<KycComplianceViewMode | undefined>();
  const [modeNotes, setModeNotes] = useState<"read" | "edit">("read");

  const { groups, session } = useAppSelector((state) => ({
    groups: state.auth.groups,
    session: state.auth.session,
  }));

  useEffect(() => {
    switch (viewMode) {
      case KycComplianceViewMode.COMPLIANCE_DETAILS:
        setActiveIndex(0);
        updateQueryParamsViewMode("");
        break;
      case KycComplianceViewMode.FORM:
        setActiveIndex(1);
        updateQueryParamsViewMode(KycComplianceViewMode.FORM);
        break;
      case KycComplianceViewMode.RISK_ASSESSMENT:
        setActiveIndex(2);
        updateQueryParamsViewMode(KycComplianceViewMode.RISK_ASSESSMENT);
        break;
    }
  }, [viewMode]);

  //vm = viewMode query param
  useEffect(() => {
    if (currentKycFlow) {
      const queryParams = new URLSearchParams(location.search);
      if (queryParams.has("vm") && queryParams.get("vm") === KycFlowStatus.CREATED) {
        setViewMode(KycComplianceViewMode.FORM);
      } else if (!queryParams.has("vm") && viewMode !== KycComplianceViewMode.COMPLIANCE_DETAILS) {
        setViewMode(KycComplianceViewMode.COMPLIANCE_DETAILS);
      } else if (queryParams.has("vm")) {
        const vm = queryParams.get("vm");
        const stringEnumValues: string[] = Object.values(KycComplianceViewMode);
        if (vm && stringEnumValues.includes(vm)) {
          if (vm === KycComplianceViewMode.RISK_ASSESSMENT) {
            if (
              currentKycFlow?.riskAssessmentStatus !== KycRiskAssessmentStatus.INIT ||
              (currentKycFlow?.riskAssessmentStatus === KycRiskAssessmentStatus.INIT &&
                statusAsArray.indexOf(currentKycFlow.status) >=
                  statusAsArray.indexOf(KycFlowStatus.CLIENT_REVIEW_DONE))
            ) {
              setViewMode(KycComplianceViewMode.RISK_ASSESSMENT);
            } else {
              setViewMode(KycComplianceViewMode.COMPLIANCE_DETAILS);
            }
          } else {
            setViewMode(vm as KycComplianceViewMode);
          }
        } else {
          setViewMode(KycComplianceViewMode.COMPLIANCE_DETAILS);
        }
      }
    }
  }, [location?.search, currentKycFlow?.status]);

  const { latestMessage } = useWebsocket();

  const refreshComplianceDetails = () => {
    getKycFlow(params?.kycId);
    listFilesByKycId(params.kycId);
  };

  useEffect(() => {
    // fetch latest kyc from backend, when updated from counterparty (e.g. PropDev validated/relaunched kyc form completion flow, client completed form);
    // this only happens if user is currently viewing the kyc (with matching id) which has been updated// useEffect(() => {

    if (params?.kycId !== latestMessage?.parameters?.kycId) {
      return;
    }
    switch (latestMessage?.type) {
      case "REFRESH_GENERATED_FILES":
        removeLoading(KycLoading.GENERATE_KYC_FLOW);
        removeLoading(KycLoading.GENERATE_BO);
        refreshComplianceDetails();
        break;
      case "REFRESH_KYC_FLOW_COMPLIANCE":
        refreshComplianceDetails();
        break;
      case "REFRESH_KYC_FILE_DELETED":
        if (latestMessage?.parameters?.kycId && latestMessage?.parameters?.fileId) {
          setCurrentFiles(findAndDeleteFileById(currentFiles, latestMessage?.parameters?.fileId));
        }
        break;
      case "REFRESH_KYC_FILE_UPLOADED":
      case "REFRESH_KYC_FILE_UPDATED":
        if (latestMessage?.parameters?.kycId && latestMessage?.parameters?.fileId) {
          getFileById(latestMessage?.parameters?.kycId, latestMessage?.parameters?.fileId);
        }
        break;
    }
  }, [latestMessage]);

  useEffect(() => {
    if (params?.kycId) {
      getKycFlow(params.kycId);
      listFilesByKycId(params.kycId);
    }
  }, [params?.kycId]);

  const updateQueryParamsViewMode = (value: string) => {
    const queryParams = new URLSearchParams(location.search);
    queryParams.delete("vm");
    if (value) {
      queryParams.set("vm", value);
    }

    history.replace({
      search: queryParams.toString(),
    });
  };

  const isFooterHidden = () => {
    if (currentStepId() === KycStep.STEP_START_FLOW) {
      return true;
    }
  };

  const [kycEntities, setKycEntities] = useState<any[]>([]);

  const isKycTerminal: boolean =
    (currentKycFlow && isKycFlowInTerminalState(currentKycFlow)) ?? false;

  useEffect(() => {
    if (currentKycFlow && currentFiles) {
      const allEntities = getAllEntityIdsAndNames(
        { ...currentKycFlow, files: currentFiles },
        fDisplayName,
        fm,
        sourceEntities
      );
      setKycEntitiesMap(allEntities);
      setKycEntities(Object.values(allEntities));
    }
  }, [currentKycFlow, currentFiles, intl, sourceEntities]);
  return (
    <div key={"kyc-compliance-item-" + currentKycFlow?.id}>
      <KycBreadcrumbs userId={params?.id} />
      <Card>
        <CardHeader title={currentKycFlow?.form?.reference}>
          <CardHeaderToolbar>
            <KycComplianceItemToolbar viewMode={viewMode} setViewMode={setViewMode} />
          </CardHeaderToolbar>
        </CardHeader>
        <CardBody className="py-4">
          {currentKycFlow?.id !== params?.kycId ? (
            <LoaderSpinner
              style={{ height: "20vh" }}
              className="justify-content-center align-items-center"
            />
          ) : (
            <div>
              {currentKycFlow && (
                <>
                  {viewMode === KycComplianceViewMode.FORM && (
                    <KycComplianceDetailsSection
                      title={KycTranslation.FORM}
                      controls={<KycComplianceBackButton setViewMode={setViewMode} />}
                    />
                  )}
                  {viewMode === KycComplianceViewMode.RISK_ASSESSMENT && (
                    <KycComplianceDetailsSection
                      title={KycTranslation.STEPS_RISK_EVALUATION_TITLE}
                      status={getStatusProperties(
                        currentKycFlow?.riskAssessmentStatus,
                        KycStatusMode.RISK_ASSESSMENT,
                        fm
                      )}
                      controls={<KycComplianceBackButton setViewMode={setViewMode} />}
                    />
                  )}
                  <Carousel
                    indicators={false}
                    controls={false}
                    interval={null}
                    activeIndex={activeIndex}
                    slide={true}
                  >
                    <Carousel.Item key={"carousel-item-compliance-details"}>
                      {viewMode === KycComplianceViewMode.COMPLIANCE_DETAILS && (
                        <>
                          <KycComplianceDetailsOverviewSection kycFlow={currentKycFlow} />
                          <hr className="mt-lg-20 mt-md-4 mb-4 border-1" />
                          <KycBeneficialOwnerSection />
                          <hr className="mb-4" />
                          <KycFilesUIProvider>
                            <SearchProvider>
                              <KycSummary
                                kycEntities={kycEntities}
                                setKycEntities={setKycEntities}
                                selectedEntity={selectedEntity}
                                setSelectedEntity={setSelectedEntity}
                              />
                              <KycFileExplorer
                                kycEntities={kycEntitiesMap}
                                setSourceEntities={setSourceEntities}
                                selectedEntity={selectedEntity}
                              />
                            </SearchProvider>
                          </KycFilesUIProvider>

                          <hr className="mb-4" />
                          <KycComplianceDetailsSection title="NOTE.TITLE">
                            {isKycTerminal ? (
                              <TextEditor
                                disabled={true}
                                data={values?.form?.notes}
                                name="kycFlow.notes"
                                toolbarOption={basicToolbar}
                                data-cy="input-kyc-risk-evaluation-remarks"
                                saveMethod={() => {}}
                              />
                            ) : modeNotes === "edit" ? (
                              <Formik
                                initialValues={{ content: values?.form?.notes }}
                                onSubmit={(values) => {
                                  customHandleChange(
                                    { target: { name: "form.notes", value: values.content } },
                                    true
                                  );
                                  setModeNotes("read");
                                }}
                              >
                                {({ values, handleSubmit, setFieldValue }) => (
                                  <>
                                    <div className="form-group row">
                                      <div className="col-lg-12">
                                        <TextEditor
                                          data={values.content}
                                          name={"content"}
                                          data-cy="input-kyc-notes"
                                          saveMethod={setFieldValue}
                                          toolbarOption={commentToolbar}
                                          size={"100px"}
                                          id={`ckeditor-kyc-notes`}
                                          timeout={false}
                                          disabled={false}
                                        />
                                      </div>
                                    </div>

                                    <div className={"text-right"}>
                                      <button
                                        type="button"
                                        onClick={() => setModeNotes("read")}
                                        className="btn btn-light btn-elevate"
                                      >
                                        {fm("COMMON.ACTION.CANCEL")}
                                      </button>
                                      <> </>
                                      <button
                                        type="button"
                                        onClick={() => handleSubmit()}
                                        className="btn btn-primary btn-elevate"
                                      >
                                        {fm("COMMON.ACTION.SAVE")}
                                      </button>
                                    </div>
                                  </>
                                )}
                              </Formik>
                            ) : (
                              <div>
                                {values?.form?.notes ? (
                                  <div className={"border p-2"}>
                                    <span
                                      className="mt-2"
                                      style={{ whiteSpace: "pre-wrap", overflowWrap: "anywhere" }}
                                      dangerouslySetInnerHTML={{
                                        __html: values?.form?.notes,
                                      }}
                                    />
                                  </div>
                                ) : (
                                  <Field
                                    name="content"
                                    as={TextareaAutosize}
                                    placeholder={fm(KycTranslation.CREATE_NOTES)}
                                    className={`form-control`}
                                    style={{ resize: "none", overflowWrap: "anywhere" }}
                                    onFocus={() => setModeNotes("edit")}
                                  />
                                )}

                                {canEdit(groups, session, "KYC_FLOW") && values?.form?.notes && (
                                  <span>
                                    <div className="text-muted text-left mt-4">
                                      <button
                                        className="btn btn-text p-0 text-primary font-weight-bold"
                                        onClick={() => setModeNotes("edit")}
                                      >
                                        {fm("COMMON.ACTION.EDIT")}
                                      </button>
                                    </div>
                                  </span>
                                )}
                              </div>
                            )}
                          </KycComplianceDetailsSection>

                          {canSeeAuditTrail(groups, session, "KYC_FLOW") && (
                            <>
                              <hr className="mb-4" />
                              <KycComplianceDetailsSection title="COMMON.EVENTS">
                                <KycAuditTrailViewer
                                  showNavbar={false}
                                  enableEventPrefixFormatting={false}
                                />
                              </KycComplianceDetailsSection>
                            </>
                          )}
                        </>
                      )}
                    </Carousel.Item>

                    <Carousel.Item key={"carousel-item-wizard"}>
                      {viewMode === KycComplianceViewMode.FORM && (
                        <>
                          <WizardStepper
                            orientation={WIZARD_ORIENTATION.VERTICAL}
                            hideStepperIndicator={steps.length === 1}
                          />
                          <CardFooter
                            style={{
                              borderTop: !isFooterHidden() ? "1px solid #F5F5F5" : "none",
                              padding: "1rem",
                              minHeight: 66,
                            }}
                          >
                            {!isFooterHidden() && <WizardNavbar />}
                          </CardFooter>
                        </>
                      )}
                    </Carousel.Item>
                    <Carousel.Item key={"carousel-item-risk-assessment"}>
                      {viewMode === KycComplianceViewMode.RISK_ASSESSMENT && (
                        <KycStepRiskEvaluation />
                      )}
                    </Carousel.Item>
                  </Carousel>
                </>
              )}
            </div>
          )}
        </CardBody>
      </Card>
    </div>
  );
};
