import * as React from "react";
import { DropdownItem } from "../../../../../../_components/actions-menu/DropdownItem";
import { Row } from "@tanstack/react-table";
import { BudgetTableRow } from "../../../BudgetEditExpenses";
import { useCustomLocationState } from "../../../../../../_utils/useCustomLocationState";
import { useAppSelector } from "../../../../../../../redux/hooks";

export interface ViewRelatedFilesProps {
  row: Row<BudgetTableRow>;
}

export const ViewRelatedFiles: React.FC<ViewRelatedFilesProps> = ({ row }) => {
  const { setFromUrlTo } = useCustomLocationState();

  const { budget } = useAppSelector((state) => ({ budget: state.budgets.budgetForEdit.current }));

  const viewRelatedFile = () => {
    let url = `/budgets/${budget!.id}/${row.original.categoryId}/${
      row.original.lineId ?? row.original.id
    }`;
    if (row.original.userId) {
      url += `?subcontractorId=${row.original.userId}`;
      if (row.original.fileId) {
        url += `&fileId=${row.original.fileId}`;
      }
    }
    setFromUrlTo({ url: url.toString(), name: budget!.name });
  };

  return (
    <DropdownItem
      onClick={viewRelatedFile}
      icon={"/media/svg/icons/General/Visible.svg"}
      title={"BUDGET.TABLE.ACTIONS.VIEW_RELATED_FILES"}
    />
  );
};
