import { IntlShape } from "react-intl";
import { ColumnFormatter } from "react-bootstrap-table-next";
import { DateUtils } from "../../../../../_utils/DateUtils";

interface FormatExtraDataProps {
  intl: IntlShape;
  showTime: boolean;
}

export const KycDateTimeFormatter: ColumnFormatter<any, FormatExtraDataProps, string> = (
  dateTimeValue,
  row,
  rowIndex,
  formatExtraData
) => {
  const intl = formatExtraData?.intl;

  if (!intl) {
    return <></>;
  }

  return (
    <div>
      {DateUtils.format(new Date(dateTimeValue), intl, false, {
        year: "numeric",
        month: "numeric",
        day: "numeric",
        hour: "numeric",
        minute: "numeric",
        second: "2-digit",
      })}
    </div>
  );
};
