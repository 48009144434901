import { KycTranslation } from "./KycTranslation";
import {
  KycChoiceOption,
  KycFlow,
  KycFlowStatus,
  KycFlowType,
  KycLegalEntityType,
  KycLegalType,
  KycRiskAssessmentStatus,
} from "./Kyc";
import { getLangShort, isStatus, isStatusBefore } from "./KycUtils";
import { IntlShape } from "react-intl";
import { isEmpty } from "lodash";

export const getTranslationCategory = (categoryId: any, fm: Function) => {
  const messageId =
    evidenceCategoryIdToTranslationIdMap[
      categoryId?.split(".")[categoryId?.split(".").length - 1]?.replace("[0]", "")
    ];
  return categoryId && messageId ? fm(messageId) : categoryId;
};

const evidenceCategoryIdToTranslationIdMap: Record<string, KycTranslation> = {
  kycDocument: KycTranslation.PLACEHOLDER_NO_VALUE_INDICATOR,
  boDeclaration: KycTranslation.FILENAME_BO_DECLARATION,
  evidenceRegisterOfShareHolders:
    KycTranslation.EVIDENCE_DOCUMENT_NAME_REGISTER_OF_SHAREHOLDERS_SHORT,
  evidenceCertificateIncumbency2:
    KycTranslation.EVIDENCE_DOCUMENT_NAME_CERTIFICATE_INCUMBENCY_2_SHORT,
  evidenceAccountsYearlyPublic: KycTranslation.EVIDENCE_DOCUMENT_NAME_ACCOUNTS_YEARLY_PUBLIC_SHORT,
  evidenceCopyAuthorisation: KycTranslation.EVIDENCE_DOCUMENT_NAME_COPY_AUTHORISATION_SHORT,
  evidenceAgreementCSSF: KycTranslation.EVIDENCE_DOCUMENT_NAME_AGREEMENT_CSSF_SHORT,
  evidencePrintScreenControlInstit:
    KycTranslation.EVIDENCE_DOCUMENT_NAME_PRINT_SCREEN_CONTROL_INSTIT_SHORT,
  evidenceSocialDocsShareholders:
    KycTranslation.EVIDENCE_DOCUMENT_NAME_SHAREHOLDER_SOCIAL_DOCS_SHORT,
  evidenceLFCLCWorldCheck: KycTranslation.EVIDENCE_DOCUMENT_L_FC_LC_WORLD_CHECK_SHORT,
  evidenceLFCLCInternetCheck: KycTranslation.EVIDENCE_DOCUMENT_L_FC_LC_INTERNET_CHECK_SHORT,
  evidenceProveOfContributions: KycTranslation.EVIDENCE_DOCUMENT_NAME_PROVE_OF_CONTRIBUTIONS_SHORT,
  evidenceProveRegulatedFund: KycTranslation.EVIDENCE_DOCUMENT_NAME_PROVE_REGULATED_FUND_SHORT,
  evidenceCopyProspects: KycTranslation.EVIDENCE_DOCUMENT_NAME_COPY_PROSPECTS_SHORT,
  evidencePrintScreenCssfMancoAifm:
    KycTranslation.EVIDENCE_DOCUMENT_NAME_PRINT_SCREEN_CSSF_MANCO_AIFM_SHORT,
  evidenceInvestmentFundWorldCheck:
    KycTranslation.EVIDENCE_DOCUMENT_INVESTMENT_FUND_WORLD_CHECK_SHORT,
  evidenceInvestmentFundInternetCheck:
    KycTranslation.EVIDENCE_DOCUMENT_INVESTMENT_FUND_INTERNET_CHECK_SHORT,
  evidencePassportIdUtilityBill2:
    KycTranslation.EVIDENCE_DOCUMENT_NAME_PASSPORT_ID_UTILITY_BILL_2_SHORT,
  evidenceNPWorldCheck: KycTranslation.EVIDENCE_DOCUMENT_NP_WORLD_CHECK_SHORT,
  evidenceNPInternetCheck: KycTranslation.EVIDENCE_DOCUMENT_NP_INTERNET_CHECK_SHORT,
  evidencePurposeOfAcquisition: KycTranslation.PURPOSE_OF_ACQUISITION_TITLE,
  evidenceOriginOfAssets: KycTranslation.ORIGIN_OF_ASSETS_TITLE,
  evidenceRepaymentMethod: KycTranslation.REPAYMENT_METHOD_TITLE,
  evidenceMeansOfAcquisition: KycTranslation.MEANS_OF_ACQUISITION_TITLE,
  evidenceLegalForm: KycTranslation.LEGAL_FORM,
  evidenceTradeRegisterRCS: KycTranslation.FILE_CATEGORY_TRADE_REGISTER,
  evidenceTradeRegisterRBE: KycTranslation.EVIDENCE_DOCUMENT_NAME_TRADE_REGISTER_2,
  evidenceBeneficialOwnerOrganisationalChart: KycTranslation.FILE_CATEGORY_ORGANIGRAM,
  evidenceAgentNoBigOwner: KycTranslation.EVIDENCE_DOCUMENT_NAME_NO_BIG_OWNER_SHORT,
  evidencePrivateAddress: KycTranslation.FILE_CATEGORY_PROOF_OF_RESIDENCE,
  evidencePassportId: KycTranslation.FILE_CATEGORY_PASSPORT_IDENTITY,
  evidenceLetterOfEngagement: KycTranslation.EVIDENCE_DOCUMENT_NAME_POWER_OF_ATTORNEY,
  evidenceAdditionalDocuments: KycTranslation.EVIDENCE_DOCUMENT_ADDITIONAL,
};

export const KYC_V2_OPTIONS_ORIGIN_OF_ASSETS: KycChoiceOption[] = [
  {
    label: KycTranslation.ORIGIN_OF_ASSETS_PROFESSIONAL_INCOME,
    value: "originOfAssetsProfessionalIncome",
  },
  { label: KycTranslation.ORIGIN_OF_ASSETS_RENTAL_INCOME, value: "originOfAssetsRentalIncome" },
  { label: KycTranslation.ORIGIN_OF_ASSETS_SAVINGS, value: "originOfAssetsSavings" },
  { label: KycTranslation.ORIGIN_OF_ASSETS_SUCCESSION, value: "originOfAssetsSuccession" },
  {
    label: KycTranslation.ORIGIN_OF_ASSETS_PREVIOUS_PROPERTY_SALE_PROCEEDS,
    value: "originOfAssetsPreviousPropertySaleProceeds",
  },
  { label: KycTranslation.ORIGIN_OF_ASSETS_DONATION, value: "originOfAssetsDonation" },
  { label: KycTranslation.ORIGIN_OF_ASSETS_BANK_LOAN, value: "originOfAssetsBankLoan" },
  { label: KycTranslation.ORIGIN_OF_ASSETS_OTHER, value: "other" },
];

export const KYC_V2_OPTIONS_PURPOSE_OF_ACQUISITION: KycChoiceOption[] = [
  {
    label: KycTranslation.PURPOSE_OF_ACQUISITION_USE_AS_RESIDENCE_ACQUISITION,
    value: "purposeOfAcquisitionUseAsResidenceAcquisition",
  },
  { label: KycTranslation.PURPOSE_OF_ACQUISITION_LEASE, value: "purposeOfAcquisitionLease" },
  { label: KycTranslation.PURPOSE_OF_ACQUISITION_RESALE, value: "purposeOfAcquisitionResale" },
  { label: KycTranslation.PURPOSE_OF_ACQUISITION_OTHER, value: "other" },
];

export const KYC_V2_OPTIONS_MEANS_OF_ACQUISITION_OF_PROPERTY: KycChoiceOption[] = [
  { label: KycTranslation.MEANS_OF_ACQUISITION_BANK_LOAN, value: "meansOfAcquisitionBankLoan" },
  {
    label: KycTranslation.MEANS_OF_ACQUISITION_PROF_ACTIVITY,
    value: "meansOfAcquisitionProfActivity",
  },
  { label: KycTranslation.MEANS_OF_ACQUISITION_SUCCESSION, value: "meansOfAcquisitionSuccession" },
  { label: KycTranslation.MEANS_OF_ACQUISITION_OTHER, value: "other" },
];

export const KYC_V2_OPTIONS_REPAYMENT_METHOD: KycChoiceOption[] = [
  {
    label: KycTranslation.REPAYMENT_METHOD_PROFESSIONAL_INCOME,
    value: "repaymentMethodProfessionalIncome",
  },
  {
    label: KycTranslation.REPAYMENT_METHOD_RENTAL_INCOME,
    value: "repaymentMethodRentalIncome",
  },
  {
    label: KycTranslation.REPAYMENT_METHOD_PREVIOUS_PROPERTY_SALE_PROCEEDS,
    value: "repaymentMethodPreviousPropertySaleProceeds",
  },
  { label: KycTranslation.REPAYMENT_METHOD_OTHER, value: "other" },
];

export const KYC_V2_OPTIONS_LEGAL_TYPES: KycChoiceOption[] = [
  {
    label: KycTranslation.LEGAL_TYPE_LU_OR_FOREIGN_COMPANY,
    value: KycLegalType.LEGAL_TYPE_LU_OR_FOREIGN_COMPANY,
  },
  {
    label: KycTranslation.LEGAL_TYPE_LISTED_COMPANY,
    value: KycLegalType.LEGAL_TYPE_LISTED_COMPANY,
  },
  {
    label: KycTranslation.LEGAL_TYPE_INVESTMENT_FUND,
    value: KycLegalType.LEGAL_TYPE_INVESTMENT_FUND,
  },
  { label: KycTranslation.LEGAL_TYPE_TRUST, value: KycLegalType.LEGAL_TYPE_TRUST },
];

const nationalities = require("i18n-nationality");
nationalities.registerLocale(require("i18n-nationality/langs/en.json"));
nationalities.registerLocale(require("i18n-nationality/langs/de.json"));
nationalities.registerLocale(require("i18n-nationality/langs/fr.json"));

export const getNationalityOptions = (intl: IntlShape) => {
  const options = [];
  for (const [key, value] of Object.entries(nationalities.getNames(getLangShort(intl.locale)))) {
    options.push({ label: value, value: key });
  }
  return options;
};

export const KYC_V2_OPTIONS_LEGAL_ENTITY_TYPES: KycChoiceOption[] = [
  { label: KycTranslation.LEGAL_ENTITY_TYPES_NATURAL, value: KycLegalEntityType.NATURAL },
  { label: KycTranslation.LEGAL_ENTITY_TYPES_LEGAL, value: KycLegalEntityType.LEGAL },
];

export const KYC_V2_OPTIONS_PERSON_TITLE: KycChoiceOption[] = [
  { label: "TITLES.MR", value: "Mr" },
  { label: "TITLES.MRS", value: "Mrs" },
];

export const KYC_V2_OPTIONS_CLIENT_RISK: KycChoiceOption[] = [
  {
    label: KycTranslation.RISK_EVALUATION_CLIENT_RISK_CLIENT_NOT_PRESENT,
    value: "clientRiskClientNotPresent",
  },
  {
    label: KycTranslation.RISK_EVALUATION_CLIENT_RISK_BELOW_MARKET_ADDITIONAL_CASH,
    value: "clientRiskBelowMarketAdditionalCash",
  },
  {
    label: KycTranslation.RISK_EVALUATION_CLIENT_RISK_NO_INSPECTION,
    value: "clientRiskNoInspection",
  },
  {
    label: KycTranslation.RISK_EVALUATION_CLIENT_RISK_QUICK_BUY_SELL_DUDE,
    value: "clientRiskQuickBySellDude",
  },
  {
    label: KycTranslation.RISK_EVALUATION_CLIENT_RISK_PAYMENT_PARTIALLY_IN_CASH,
    value: "clientRiskPaymentPartiallyInCash",
  },
  {
    label: KycTranslation.RISK_EVALUATION_CLIENT_RISK_FREQUENT_CHANGES_SHAREHOLDING,
    value: "clientRiskFrequentChangesShareholding",
  },
  {
    label: KycTranslation.RISK_EVALUATION_CLIENT_RISK_SUSPICION_NOT_TRUSTWORTHY,
    value: "clientRiskSuspicionNotTrustworthy",
  },
  {
    label: KycTranslation.RISK_EVALUATION_CLIENT_RISK_BAD_STRUCTURE,
    value: "clientRiskBadStructure",
  },
  {
    label: KycTranslation.RISK_EVALUATION_CLIENT_RISK_DIFFICULT_TRANSACTION_STRUCTURE,
    value: "clientRiskDifficultTransactionStructure",
  },
  {
    label: KycTranslation.RISK_EVALUATION_CLIENT_RISK_ABNORMAL_CIRCUMSTANCES,
    value: "clientRiskAbnormalCircumstance",
  },
  {
    label: KycTranslation.RISK_EVALUATION_CLIENT_RISK_DETAINED_BY_DESIGNATED_SHAREHOLDERS,
    value: "clientRiskDetainedByDesignatedShareHolders",
  },
  {
    label: KycTranslation.RISK_EVALUATION_CLIENT_RISK_EX_TRUST_FOUNDATION,
    value: "clientRiskExTrustFoundation",
  },
  {
    label: KycTranslation.RISK_EVALUATION_CLIENT_RISK_COUNTRY_NON_EU_ADDITIONAL_REQUESTS,
    value: "clientRiskCountryNonEuAdditionalRequests",
  },
  { label: KycTranslation.RISK_EVALUATION_CLIENT_RISK_OTHER, value: "other" },
];

export const KYC_V2_OPTIONS_CLIENT_RISK_ADDITIONAL: KycChoiceOption[] = [
  {
    label: KycTranslation.RISK_EVALUATION_CLIENT_RISK_ADDITIONAL_LONG_TIME_CUSTOMER,
    value: "clientRiskAdditionalLongTimeCustomer",
  },
  {
    label: KycTranslation.RISK_EVALUATION_CLIENT_RISK_ADDITIONAL_BANK_RIGHTS,
    value: "clientRiskAdditionalBankRights",
  },
  {
    label: KycTranslation.RISK_EVALUATION_CLIENT_RISK_ADDITIONAL_PSF,
    value: "clientRiskAdditionalPsf",
  },
  {
    label: KycTranslation.RISK_EVALUATION_CLIENT_RISK_ADDITIONAL_PUBLICLY_TRADED_COMPANY,
    value: "clientRiskAdditionalPubliclyTradedCompany",
  },
  {
    label: KycTranslation.RISK_EVALUATION_CLIENT_RISK_ADDITIONAL_WEAK_CORRUPTION_ADMINISTRATION,
    value: "clientRiskAdditionalWeakCorruptionAdministration",
  },
  { label: KycTranslation.RISK_EVALUATION_CLIENT_RISK_ADDITIONAL_OTHER, value: "other" },
];

export const KYC_V2_OPTIONS_GEO_COUNTRY_RISK_HIGHER_RISK: KycChoiceOption[] = [
  {
    label: KycTranslation.RISK_EVALUATION_GEO_COUNTRY_GAFI_NOT_MEMBER,
    value: "geoCountryNotGafiMember",
  },
  {
    label: KycTranslation.RISK_EVALUATION_GEO_COUNTRY_GAFI_NOT_COOP,
    value: "geoCountryNotGafiCoop",
  },

  {
    label: KycTranslation.RISK_EVALUATION_GEO_COUNTRY_NOT_EU_NOT_ML_TR,
    value: "geoCountryNotEuNotMlTr",
  },
  {
    label: KycTranslation.RISK_EVALUATION_GEO_COUNTRY_NOT_EU_HIGH_CORRUPTION,
    value: "geoCountryNotEuHighCorruption",
  },
  {
    label: KycTranslation.RISK_EVALUATION_GEO_COUNTRY_NOT_EU_SANCTION,
    value: "geoCountryNotEuSanction",
  },
];
export const KYC_V2_OPTIONS_GEO_COUNTRY_RISK_LOWER_RISK: KycChoiceOption[] = [
  {
    label: KycTranslation.RISK_EVALUATION_GEO_COUNTRY_GAFI_MEMBER,
    value: "geoCountryGafiMember",
  },

  {
    label: KycTranslation.RISK_EVALUATION_GEO_COUNTRY_GAFI_COOP,
    value: "geoCountryGafiCoop",
  },
  {
    label: KycTranslation.RISK_EVALUATION_GEO_COUNTRY_NOT_EU_ML_TR,
    value: "geoCountryNotEuMlTr",
  },

  {
    label: KycTranslation.RISK_EVALUATION_GEO_COUNTRY_NOT_EU_WEAK_CORRUPTION,
    value: "geoCountryNotEuWeakCorruption",
  },
  {
    label: KycTranslation.RISK_EVALUATION_GEO_COUNTRY_NOT_EU_SANCTION_FREE,
    value: "geoCountryNotEuSanctionFree",
  },
];

export const KYC_V2_OPTIONS_CLIENT_ACTIVITIES_RISK: KycChoiceOption[] = [
  {
    label: KycTranslation.RISK_EVALUATION_CLIENT_ACTIVITIES_PRIVATE_BANK,
    value: "clientActivitiesPrivateBank",
  },
  {
    label: KycTranslation.RISK_EVALUATION_CLIENT_ACTIVITIES_FAV_ANONYMOUS,
    value: "clientActivitiesFavAnonymous",
  },
  {
    label: KycTranslation.RISK_EVALUATION_CLIENT_ACTIVITIES_SPECIAL_ACTIVITIES,
    value: "clientActivitiesSpecialActivities",
  },
  {
    label: KycTranslation.RISK_EVALUATION_CLIENT_ACTIVITIES_GASTRO,
    value: "clientActivitiesGastro",
  },
  {
    label: KycTranslation.RISK_EVALUATION_CLIENT_ACTIVITIES_GAMBLING,
    value: "clientActivitiesGambling",
  },
  {
    label: KycTranslation.RISK_EVALUATION_CLIENT_ACTIVITIES_ONG,
    value: "clientActivitiesOng",
  },
  {
    label: KycTranslation.RISK_EVALUATION_CLIENT_ACTIVITIES_INNOVATION,
    value: "clientActivitiesInnovation",
  },
  {
    label: KycTranslation.RISK_EVALUATION_CLIENT_ACTIVITIES_NON_PHYSICAL,
    value: "clientActivitiesNonPhysical",
  },
  { label: KycTranslation.RISK_EVALUATION_CLIENT_ACTIVITIES_OTHER, value: "other" },
];

export const KYC_V2_OPTIONS_FLOW_TYPE: KycChoiceOption[] = [
  {
    label: KycTranslation.FLOW_TYPE_BUYER,
    value: KycFlowType.BUYER,
  },
  {
    label: KycTranslation.FLOW_TYPE_SELLER,
    value: KycFlowType.SELLER,
  },
];
export enum KycStatusMode {
  BO = "BO",
  KYC_FLOW = "KYC_FLOW",
  BO_OVERALL = "BO_OVERALL",
  RISK_ASSESSMENT = "RISK_ASSESSMENT",
}

export const getStatusLabelForKycStatusShort = (
  status: KycFlowStatus | string | undefined,
  mode: KycStatusMode
) => {
  if (mode === KycStatusMode.KYC_FLOW) {
    switch (status) {
      case KycFlowStatus.INIT:
        return KycTranslation.STATUS_LABEL_INIT;
      case KycFlowStatus.CREATED:
        return KycTranslation.STATUS_LABEL_CREATED;
      case KycFlowStatus.ADMIN_FILLING_IN:
      case KycFlowStatus.CLIENT_REVIEW_PENDING:
      case KycFlowStatus.CLIENT_REVIEW_DONE:
      case KycFlowStatus.CLIENT_REJECT_AND_REVIEW_PENDING:
      case KycFlowStatus.ADMIN_RISK_ASSESSMENT_PENDING:
      case KycFlowStatus.ADMIN_RISK_ASSESSMENT_VALIDATED:
      case KycFlowStatus.ADMIN_RISK_ASSESSMENT_DECLINED:
      case KycFlowStatus.ADMIN_RISK_ASSESSMENT_DONE:
      case KycFlowStatus.BO_DECLARATIONS_SIGNATURE_PENDING:
      case KycFlowStatus.BO_DECLARATIONS_SIGNATURE_DONE:
        return KycTranslation.STATUS_LABEL_IN_PROGRESS;
      case KycFlowStatus.KYC_APPROVED:
        return KycTranslation.STATUS_LABEL_KYC_APPROVED;
      case KycFlowStatus.KYC_REJECTED:
        return KycTranslation.STATUS_LABEL_KYC_REJECTED;
      case KycFlowStatus.CANCELED:
        return KycTranslation.STATUS_LABEL_CANCELED;
    }
  }
};
export const getStatusLabelForKycStatus = (
  status: KycFlowStatus | string | undefined,
  mode: KycStatusMode,
  kyc?: KycFlow
) => {
  if (mode === KycStatusMode.KYC_FLOW) {
    switch (status) {
      case KycFlowStatus.INIT:
        return KycTranslation.STATUS_LABEL_INIT;
      case KycFlowStatus.CREATED:
        return KycTranslation.STATUS_LABEL_CREATED;
      case KycFlowStatus.ADMIN_FILLING_IN:
        return KycTranslation.STATUS_LABEL_ADMIN_FILLING_IN;
      case KycFlowStatus.CLIENT_REVIEW_PENDING:
        return KycTranslation.STATUS_LABEL_CLIENT_REVIEW_PENDING;
      case KycFlowStatus.CLIENT_REVIEW_DONE:
        return KycTranslation.STATUS_LABEL_ADMIN_REVIEW;
      case KycFlowStatus.CLIENT_REJECT_AND_REVIEW_PENDING:
        return KycTranslation.STATUS_LABEL_CLIENT_REJECT_AND_REVIEW_PENDING;
      case KycFlowStatus.ADMIN_RISK_ASSESSMENT_PENDING:
        return KycTranslation.STATUS_LABEL_ADMIN_RISK_ASSESSMENT_PENDING;
      case KycFlowStatus.ADMIN_RISK_ASSESSMENT_VALIDATED:
        return KycTranslation.STATUS_LABEL_ADMIN_RISK_ASSESSMENT_VALIDATED;
      case KycFlowStatus.ADMIN_RISK_ASSESSMENT_DECLINED:
        return KycTranslation.STATUS_LABEL_ADMIN_RISK_ASSESSMENT_DECLINED;
      case KycFlowStatus.ADMIN_RISK_ASSESSMENT_DONE:
        return KycTranslation.STATUS_LABEL_ADMIN_RISK_ASSESSMENT_DONE;
      case KycFlowStatus.BO_DECLARATIONS_SIGNATURE_PENDING:
        return KycTranslation.STATUS_LABEL_BO_DECLARATIONS_SIGNATURE_PENDING;
      case KycFlowStatus.BO_DECLARATIONS_SIGNATURE_DONE:
        return KycTranslation.STATUS_LABEL_BO_DECLARATIONS_SIGNATURE_DONE;
      case KycFlowStatus.KYC_APPROVED:
        return KycTranslation.STATUS_LABEL_KYC_APPROVED;
      case KycFlowStatus.KYC_REJECTED:
        return KycTranslation.STATUS_LABEL_KYC_REJECTED;
      case KycFlowStatus.CANCELED:
        return KycTranslation.STATUS_LABEL_CANCELED;
    }
  }

  if (mode === KycStatusMode.BO) {
    switch (status) {
      case "CREATED":
        return "IN_PROGRESS";
      case "SIGNED":
        return KycTranslation.STATUS_SIGNED;
    }
  }

  if (mode === KycStatusMode.RISK_ASSESSMENT) {
    switch (status) {
      case KycRiskAssessmentStatus.INIT:
        return KycTranslation.STATUS_LABEL_INIT_NOT_STARTED;
      case KycRiskAssessmentStatus.VALIDATED:
        return KycTranslation.STATUS_LABEL_VALIDATED;
      case KycRiskAssessmentStatus.IN_PROGRESS:
        return KycTranslation.STATUS_LABEL_PENDING;
      case KycRiskAssessmentStatus.REJECTED:
        return KycTranslation.STATUS_LABEL_KYC_REJECTED;
      default:
        return "";
    }
  }

  if (mode === KycStatusMode.BO_OVERALL) {
    if (isStatusBefore(status as KycFlowStatus, KycFlowStatus.BO_DECLARATIONS_SIGNATURE_PENDING)) {
      return KycTranslation.BO_OVERALL_DRAFT;
    } else if (isStatus(status as KycFlowStatus, KycFlowStatus.BO_DECLARATIONS_SIGNATURE_PENDING)) {
      return KycTranslation.BO_OVERALL_IN_PROGRESS;
    } else if (
      isEmpty(kyc?.signatures?.filter((signature: any) => signature.status !== "SIGNED")) &&
      !isEmpty(kyc?.signatures)
    ) {
      return KycTranslation.BO_OVERALL_DONE;
    }
  }
};

export const getColorForStatus = (
  status: string | undefined,
  mode: KycStatusMode,
  kyc?: KycFlow
) => {
  if (mode === KycStatusMode.KYC_FLOW) {
    switch (status) {
      case "SIGNED":
        return "label-light-success";
      case "CREATED":
      case "ADMIN_FILLING_IN":
      case "CLIENT_REVIEW_PENDING":
      case "CLIENT_REJECT_AND_REVIEW_PENDING":
      case "CLIENT_REVIEW_DONE":
      case "ADMIN_RISK_ASSESSMENT_PENDING":
      case "ADMIN_RISK_ASSESSMENT_VALIDATED":
      case "ADMIN_RISK_ASSESSMENT_REJECTED":
      case "BO_DECLARATIONS_SIGNATURE_PENDING":
      case "BO_DECLARATIONS_SIGNATURE_DONE":
        return "label-light-info";
      case "KYC_APPROVED":
        return "label-light-success";
      case "KYC_REJECTED":
        return "label-light-danger";
      case "CANCELED":
        return "label-light-warning";
    }
  }

  if (mode === KycStatusMode.BO) {
    switch (status) {
      case "CREATED":
        return "label-light-info";
      case "SIGNED":
        return "label-light-success";
    }
  }

  if (mode === KycStatusMode.RISK_ASSESSMENT) {
    switch (status) {
      case KycRiskAssessmentStatus.INIT:
        return "label-light";
      case KycRiskAssessmentStatus.IN_PROGRESS:
        return "label-light-info";
      case KycRiskAssessmentStatus.VALIDATED:
        return "label-light-success";
      case KycRiskAssessmentStatus.REJECTED:
        return "label-light-danger";
      default:
        return "label-light";
    }
  }

  if (mode === KycStatusMode.BO_OVERALL) {
    if (isStatusBefore(status as KycFlowStatus, KycFlowStatus.BO_DECLARATIONS_SIGNATURE_PENDING)) {
      return "label-light";
    } else if (isStatus(status as KycFlowStatus, KycFlowStatus.BO_DECLARATIONS_SIGNATURE_PENDING)) {
      return "label-light-info";
    } else if (
      isEmpty(kyc?.signatures?.filter((signature: any) => signature.status !== "SIGNED")) &&
      !isEmpty(kyc?.signatures)
    ) {
      return "label-light-success";
    }
  }

  return "label-light";
};

export const getStatusProperties = (
  status = "",
  mode: KycStatusMode,
  fm: Function,
  kyc?: KycFlow
) => {
  return {
    label: fm(getStatusLabelForKycStatus(status, mode, kyc)) ?? "",
    color: getColorForStatus(status, mode, kyc) ?? "",
  };
};

export const getStatusPropertiesShort = (
  status = "",
  mode: KycStatusMode,
  fm: Function,
  kyc?: KycFlow
) => {
  return {
    label: fm(getStatusLabelForKycStatusShort(status, mode)) ?? "",
    color: getColorForStatus(status, mode, kyc) ?? "",
  };
};

export const getFlowTypeLabel = (type: KycFlowType, fm: Function) => {
  switch (type) {
    case KycFlowType.BUYER:
      return fm(KycTranslation.FLOW_TYPE_BUYER);
    case KycFlowType.SELLER:
      return fm(KycTranslation.FLOW_TYPE_SELLER);
  }
};

export const allOptions = [
  ...KYC_V2_OPTIONS_ORIGIN_OF_ASSETS,
  ...KYC_V2_OPTIONS_PURPOSE_OF_ACQUISITION,
  ...KYC_V2_OPTIONS_REPAYMENT_METHOD,
  ...KYC_V2_OPTIONS_LEGAL_TYPES,
  ...KYC_V2_OPTIONS_LEGAL_ENTITY_TYPES,
  ...KYC_V2_OPTIONS_PERSON_TITLE,
  ...KYC_V2_OPTIONS_MEANS_OF_ACQUISITION_OF_PROPERTY,
];

export const KYC_V2_OPTIONS_COUNTRIES: KycChoiceOption[] = [
  { label: "LU", value: "LU" },
  { label: "FR", value: "FR" },
  { label: "BE", value: "BE" },
  { label: "DE", value: "DE" },
  { label: "GB", value: "GB" },
  { label: "PT", value: "PT" },
  { label: "IT", value: "IT" },
  { label: "ES", value: "ES" },
  { label: "CH", value: "CH" },
  { label: "LI", value: "LI" },
  { label: "RU", value: "RU" },
  { label: "AF", value: "AF" },
  { label: "AL", value: "AL" },
  { label: "AX", value: "AX" },
  { label: "DZ", value: "DZ" },
  { label: "AS", value: "AS" },
  { label: "AD", value: "AD" },
  { label: "AO", value: "AO" },
  { label: "AI", value: "AI" },
  { label: "AQ", value: "AQ" },
  { label: "AG", value: "AG" },
  { label: "AR", value: "AR" },
  { label: "AM", value: "AM" },
  { label: "AW", value: "AW" },
  { label: "AU", value: "AU" },
  { label: "AT", value: "AT" },
  { label: "AZ", value: "AZ" },
  { label: "BH", value: "BH" },
  { label: "BS", value: "BS" },
  { label: "BD", value: "BD" },
  { label: "BB", value: "BB" },
  { label: "BY", value: "BY" },
  { label: "BZ", value: "BZ" },
  { label: "BJ", value: "BJ" },
  { label: "BM", value: "BM" },
  { label: "BT", value: "BT" },
  { label: "BO", value: "BO" },
  { label: "BQ", value: "BQ" },
  { label: "BA", value: "BA" },
  { label: "BW", value: "BW" },
  { label: "BV", value: "BV" },
  { label: "BR", value: "BR" },
  { label: "IO", value: "IO" },
  { label: "VG", value: "VG" },
  { label: "BN", value: "BN" },
  { label: "BG", value: "BG" },
  { label: "BF", value: "BF" },
  { label: "BI", value: "BI" },
  { label: "CV", value: "CV" },
  { label: "KH", value: "KH" },
  { label: "CM", value: "CM" },
  { label: "CA", value: "CA" },
  { label: "KY", value: "KY" },
  { label: "CF", value: "CF" },
  { label: "TD", value: "TD" },
  { label: "CL", value: "CL" },
  { label: "CN", value: "CN" },
  { label: "HK", value: "HK" },
  { label: "MO", value: "MO" },
  { label: "CX", value: "CX" },
  { label: "CC", value: "CC" },
  { label: "CO", value: "CO" },
  { label: "KM", value: "KM" },
  { label: "CG", value: "CG" },
  { label: "CK", value: "CK" },
  { label: "CR", value: "CR" },
  { label: "HR", value: "HR" },
  { label: "CU", value: "CU" },
  { label: "CW", value: "CW" },
  { label: "CY", value: "CY" },
  { label: "CZ", value: "CZ" },
  { label: "CI", value: "CI" },
  { label: "KP", value: "KP" },
  { label: "CD", value: "CD" },
  { label: "DK", value: "DK" },
  { label: "DJ", value: "DJ" },
  { label: "DM", value: "DM" },
  { label: "DO", value: "DO" },
  { label: "EC", value: "EC" },
  { label: "EG", value: "EG" },
  { label: "SV", value: "SV" },
  { label: "GQ", value: "GQ" },
  { label: "ER", value: "ER" },
  { label: "EE", value: "EE" },
  { label: "SZ", value: "SZ" },
  { label: "ET", value: "ET" },
  { label: "FK", value: "FK" },
  { label: "FO", value: "FO" },
  { label: "FJ", value: "FJ" },
  { label: "FI", value: "FI" },
  { label: "GF", value: "GF" },
  { label: "PF", value: "PF" },
  { label: "TF", value: "TF" },
  { label: "GA", value: "GA" },
  { label: "GM", value: "GM" },
  { label: "GE", value: "GE" },
  { label: "GH", value: "GH" },
  { label: "GI", value: "GI" },
  { label: "GR", value: "GR" },
  { label: "GL", value: "GL" },
  { label: "GD", value: "GD" },
  { label: "GP", value: "GP" },
  { label: "GU", value: "GU" },
  { label: "GT", value: "GT" },
  { label: "GG", value: "GG" },
  { label: "GN", value: "GN" },
  { label: "GW", value: "GW" },
  { label: "GY", value: "GY" },
  { label: "HT", value: "HT" },
  { label: "HM", value: "HM" },
  { label: "VA", value: "VA" },
  { label: "HN", value: "HN" },
  { label: "HU", value: "HU" },
  { label: "IS", value: "IS" },
  { label: "IN", value: "IN" },
  { label: "ID", value: "ID" },
  { label: "IR", value: "IR" },
  { label: "IQ", value: "IQ" },
  { label: "IE", value: "IE" },
  { label: "IM", value: "IM" },
  { label: "IL", value: "IL" },
  { label: "JM", value: "JM" },
  { label: "JP", value: "JP" },
  { label: "JE", value: "JE" },
  { label: "JO", value: "JO" },
  { label: "KZ", value: "KZ" },
  { label: "KE", value: "KE" },
  { label: "KI", value: "KI" },
  { label: "KW", value: "KW" },
  { label: "KG", value: "KG" },
  { label: "LA", value: "LA" },
  { label: "LV", value: "LV" },
  { label: "LB", value: "LB" },
  { label: "LS", value: "LS" },
  { label: "LR", value: "LR" },
  { label: "LY", value: "LY" },
  { label: "LT", value: "LT" },
  { label: "MG", value: "MG" },
  { label: "MW", value: "MW" },
  { label: "MY", value: "MY" },
  { label: "MV", value: "MV" },
  { label: "ML", value: "ML" },
  { label: "MT", value: "MT" },
  { label: "MH", value: "MH" },
  { label: "MQ", value: "MQ" },
  { label: "MR", value: "MR" },
  { label: "MU", value: "MU" },
  { label: "YT", value: "YT" },
  { label: "MX", value: "MX" },
  { label: "FM", value: "FM" },
  { label: "MC", value: "MC" },
  { label: "MN", value: "MN" },
  { label: "ME", value: "ME" },
  { label: "MS", value: "MS" },
  { label: "MA", value: "MA" },
  { label: "MZ", value: "MZ" },
  { label: "MM", value: "MM" },
  { label: "NA", value: "NA" },
  { label: "NR", value: "NR" },
  { label: "NP", value: "NP" },
  { label: "NL", value: "NL" },
  { label: "NC", value: "NC" },
  { label: "NZ", value: "NZ" },
  { label: "NI", value: "NI" },
  { label: "NE", value: "NE" },
  { label: "NG", value: "NG" },
  { label: "NU", value: "NU" },
  { label: "NF", value: "NF" },
  { label: "MP", value: "MP" },
  { label: "NO", value: "NO" },
  { label: "OM", value: "OM" },
  { label: "PK", value: "PK" },
  { label: "PW", value: "PW" },
  { label: "PA", value: "PA" },
  { label: "PG", value: "PG" },
  { label: "PY", value: "PY" },
  { label: "PE", value: "PE" },
  { label: "PH", value: "PH" },
  { label: "PN", value: "PN" },
  { label: "PL", value: "PL" },
  { label: "PR", value: "PR" },
  { label: "QA", value: "QA" },
  { label: "KR", value: "KR" },
  { label: "MD", value: "MD" },
  { label: "RO", value: "RO" },
  { label: "RW", value: "RW" },
  { label: "RE", value: "RE" },
  { label: "BL", value: "BL" },
  { label: "SH", value: "SH" },
  { label: "KN", value: "KN" },
  { label: "LC", value: "LC" },
  { label: "MF", value: "MF" },
  { label: "PM", value: "PM" },
  { label: "VC", value: "VC" },
  { label: "WS", value: "WS" },
  { label: "SM", value: "SM" },
  { label: "ST", value: "ST" },
  { label: "SA", value: "SA" },
  { label: "SN", value: "SN" },
  { label: "RS", value: "RS" },
  { label: "SC", value: "SC" },
  { label: "SL", value: "SL" },
  { label: "SG", value: "SG" },
  { label: "SX", value: "SX" },
  { label: "SK", value: "SK" },
  { label: "SI", value: "SI" },
  { label: "SB", value: "SB" },
  { label: "SO", value: "SO" },
  { label: "ZA", value: "ZA" },
  { label: "GS", value: "GS" },
  { label: "SS", value: "SS" },
  { label: "LK", value: "LK" },
  { label: "PS", value: "PS" },
  { label: "SD", value: "SD" },
  { label: "SR", value: "SR" },
  { label: "SJ", value: "SJ" },
  { label: "SE", value: "SE" },
  { label: "SY", value: "SY" },
  { label: "TJ", value: "TJ" },
  { label: "TH", value: "TH" },
  { label: "MK", value: "MK" },
  { label: "TL", value: "TL" },
  { label: "TG", value: "TG" },
  { label: "TK", value: "TK" },
  { label: "TO", value: "TO" },
  { label: "TT", value: "TT" },
  { label: "TN", value: "TN" },
  { label: "TR", value: "TR" },
  { label: "TM", value: "TM" },
  { label: "TC", value: "TC" },
  { label: "TV", value: "TV" },
  { label: "UG", value: "UG" },
  { label: "UA", value: "UA" },
  { label: "AE", value: "AE" },
  { label: "TZ", value: "TZ" },
  { label: "TW", value: "TW" },
  { label: "UM", value: "UM" },
  { label: "US", value: "US" },
  { label: "UY", value: "UY" },
  { label: "UZ", value: "UZ" },
  { label: "VU", value: "VU" },
  { label: "VE", value: "VE" },
  { label: "VN", value: "VN" },
  { label: "VI", value: "VI" },
  { label: "WF", value: "WF" },
  { label: "EH", value: "EH" },
  { label: "YE", value: "YE" },
  { label: "ZM", value: "ZM" },
  { label: "ZW", value: "ZW" },
];
