import * as React from "react";
import { CreditBreakdown, PaymentBreakdown, PricingBreakdown, TaxesBreakdown } from "./cards";

export const LeadInstalmentsHeader = () => {
  return (
    <div className={"row"}>
      <PricingBreakdown className="gutter-b" />
      <TaxesBreakdown className="gutter-b" />
      <PaymentBreakdown className="gutter-b" />
      <CreditBreakdown className="gutter-b" />
    </div>
  );
};
