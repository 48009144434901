import * as React from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";

import {
  requestProjectFileSignature,
  cancelProjectFileSignaturesRequest,
} from "app/modules/PropertiesManagement/_redux/projects/projectsActions";
import { fetchUsersByUserType } from "app/modules/UsersManagement/_redux/usersActions";

import { CLIENT, SUBCONTRACTOR, USER } from "app/_utils/userTypes";

import { RequestSignatureDialog } from "app/_components/RequestSignatureDialog";

import { useProjectFilesUIContext } from "../ProjectFilesUIContext";

export const ProjectRequestSignature: React.FunctionComponent = () => {
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(fetchUsersByUserType({ userTypes: [USER, CLIENT, SUBCONTRACTOR] }));
  }, []);

  const { projectFile } = useSelector(
    (state: any) => ({
      projectFile: state.projects?.projectForEdit?.current?.files,
    }),
    shallowEqual
  );

  return (
    <RequestSignatureDialog
      useFilesUIContext={useProjectFilesUIContext}
      fileList={projectFile}
      requestFileSignature={requestProjectFileSignature}
      requestCancelPrevious={cancelProjectFileSignaturesRequest}
    />
  );
};

export default ProjectRequestSignature;
