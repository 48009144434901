import "../../../KycWizard.scss";
import * as React from "react";
import { useState } from "react";

import "../../RepresentativeCarousel.scss";
import { Button } from "react-bootstrap";
import { KycHorizontalScrollMenu } from "./KycHorizontalScrollMenu";
import { KycEntityCarousel } from "./KycEntityCarousel";
import { get } from "lodash-es";
import { useTranslation } from "../../../../../hooks/useTranslation";
import { Field, FieldArray } from "formik";
import { useKycFlow } from "../../../../../hooks/useKycFlow";
import { v4 as uuid } from "uuid";
import { useWizard } from "../../../../../hooks/useWizard";
import { KycFormSectionTitle } from "../../KycFormSectionTitle";
import { DataCyCompPrefix, KycItemType, KycLoading } from "../../../Kyc";
import { formatDataCy } from "../../../KycUtils";
import { useLoading } from "../../../../../hooks/useLoading";

type KycMultiEntityProps = {
  field: any;
  title?: string;
  name: string;
  initObj: any;
  defaultHeaderTitle: string;
  labelButtonAdd: string;
  disabled: boolean;
  infoLabel?: string;
  itemType: KycItemType;
  showDivider?: boolean;
};

export const KycMultiEntity = ({
  field,
  initObj,
  labelButtonAdd,
  defaultHeaderTitle,
  title,
  disabled,
  infoLabel,
  itemType,
  showDivider = true,
}: KycMultiEntityProps): JSX.Element => {
  const { fm } = useTranslation();
  const { values, saveField } = useKycFlow();
  const { isReadOnly } = useWizard();
  const { isCurrentlyLoading } = useLoading();

  const [carouselActiveIndex, setCarouselActiveIndex] = useState<number>(0);

  const handlePushEntity = (e: any, push: Function) => {
    e.preventDefault();
    const indexOfEntityCreated = field?.value?.length;
    const entityCreated = {
      ...initObj,
      id: uuid(),
      firstName: `${fm(defaultHeaderTitle)} ${indexOfEntityCreated + 1}`,
    };
    push(entityCreated);
    const entityField = {
      name: `${field.name}[${indexOfEntityCreated}]`,
      value: entityCreated,
    };
    saveField(e, {
      field: entityField,
      preventDefault: false,
      loading: { type: KycLoading.SAVE_KYC_ENTITY },
    });
  };

  return (
    <>
      {field && field?.name && (
        <FieldArray name={field.name}>
          {({ push, remove }) => (
            <>
              <KycFormSectionTitle title={title} infoLabel={infoLabel}>
                {!disabled && !isReadOnly && (
                  <Button
                    data-cy={formatDataCy(DataCyCompPrefix.BUTTON + "-add", field?.name)}
                    onClick={(e: any) => handlePushEntity(e, push)}
                    disabled={isCurrentlyLoading(KycLoading.SAVE_KYC_ENTITY, "", true)}
                    className="btn btn-primary"
                  >
                    {fm(labelButtonAdd)}
                  </Button>
                )}
              </KycFormSectionTitle>

              {field && field?.name && (
                <Field
                  component={KycHorizontalScrollMenu}
                  disabled={disabled}
                  items={get(values, field.name)}
                  defaultTitle={defaultHeaderTitle}
                  name={field.name}
                  remove={remove}
                  setActiveIndex={setCarouselActiveIndex}
                  activeIndex={carouselActiveIndex}
                />
              )}
              {get(values, field.name)?.length > 0 && (
                <KycEntityCarousel
                  activeIndex={carouselActiveIndex}
                  name={field.name}
                  items={get(values, field.name)}
                  itemType={itemType}
                />
              )}

              {showDivider && <hr className={"my-4"} />}
            </>
          )}
        </FieldArray>
      )}
    </>
  );
};
