import * as React from "react";
import { CurrencyMetricCard } from "../../../../_components/MetricCard";

export interface BudgetInstalmentsHeaderProps {
  totalPrice: number;
  expectedConstructionPrice: number;
  architectEngineeringFeesPrice: number;
  shareOfLandPrice: number;
}

export const BudgetInstalmentsHeader: React.FC<BudgetInstalmentsHeaderProps> = ({
  totalPrice,
  expectedConstructionPrice,
  architectEngineeringFeesPrice,
  shareOfLandPrice,
}) => {
  return (
    <div className={"row"}>
      <CurrencyMetricCard
        dataCy="input-budget-total-listing-price"
        value={totalPrice}
        label="COMMON.TOTAL.CONSTRUCTION"
        className={"gutter-b"}
      />
      <CurrencyMetricCard
        dataCy="input-budget-expected-total-construction-price"
        value={expectedConstructionPrice}
        label="COMMON.EXPECTED.TOTAL.CONSTRUCTION"
        className={"gutter-b"}
      />
      <CurrencyMetricCard
        dataCy="input-budget-expected-total-share-of-land"
        value={shareOfLandPrice}
        label="COMMON.EXPECTED.TOTAL.SHARE_OF_LAND"
        className={"gutter-b"}
      />
      <CurrencyMetricCard
        dataCy="input-budget-expected-total-architect-engineering-fees"
        value={architectEngineeringFeesPrice}
        label="COMMON.EXPECTED.TOTAL.ARCHITECT_ENGINEERING"
        className={"gutter-b"}
      />
    </div>
  );
};
