import * as React from "react";
import { FormattedMessage } from "react-intl";

import { ISignatureInfo } from "data/schemas";

import { CardPDFSignedViewer } from "app/_components/CardPDFSignedViewer";
import { usePublicRequests } from "app/modules/Requests/hooks/usePublicRequests";
import { ValidateIdentityModal } from "app/modules/Sign/pages/ValidateIdentityModal";

import { TModalState, ModalState } from "./definitions";
import { useRequestIdUrlParam } from "./useRequestIdUrlParam";
import { SignatureContentInfoCard } from "./SignatureContentInfoCard";

//----------------------------------------------------------------------------//

export const SignatureContentCard: React.FunctionComponent = () => {
  const requestId = useRequestIdUrlParam();
  const [modalState, setModalState] = React.useState<TModalState>(ModalState.NONE);

  const {
    isLoading,
    settings,
    user,
    errorMessage,
    getPublicRequestSignatureOtp,
    postPublicRequestSignature,
    setIsInvalidOtp,
  } = usePublicRequests();

  if (isLoading && modalState !== ModalState.VALIDATE) {
    return (
      <SignatureContentInfoCard>
        <span className="spinner spinner-lg spinner-primary" />
      </SignatureContentInfoCard>
    );
  }

  if (!settings) {
    return (
      <SignatureContentInfoCard>
        <span className="text-danger">
          <FormattedMessage id={"SIGN.NOTHING"} />
        </span>
      </SignatureContentInfoCard>
    );
  }

  //--------------------------------------------------------------------------//

  const requestOtpCode = () => {
    setIsInvalidOtp(false);

    return getPublicRequestSignatureOtp(requestId);
  };

  const requestDocSign = (code: string) => postPublicRequestSignature(requestId, code);

  const triggerModalHide = () => {
    setIsInvalidOtp(false);

    setModalState(ModalState.NONE);
  };

  //--------------------------------------------------------------------------//

  const signatoryInfo = (settings as ISignatureInfo) || {};

  const fileUrl = signatoryInfo.fileUrl;

  const triggerModalStateChange =
    signatoryInfo && !signatoryInfo.signedDate ? setModalState : undefined;

  return (
    <>
      <ValidateIdentityModal
        show={modalState === ModalState.VALIDATE}
        onHide={triggerModalHide}
        triggerModalHide={triggerModalHide}
        requestCode={requestOtpCode}
        requestDocSign={requestDocSign}
      />

      {fileUrl && (
        <CardPDFSignedViewer
          extraCardClassName="w-100"
          signatureInfo={signatoryInfo}
          fileUrl={fileUrl}
          setModalState={triggerModalStateChange}
          disableSignButton={user && !user.gdprApprovedAt && !errorMessage}
        />
      )}
    </>
  );
};

export default SignatureContentCard;
