import * as React from "react";
import { useToggle } from "rooks";
import { FormattedMessage } from "react-intl";

import { SubtasksProgress } from "./SubtasksProgress";
import { SubtasksList, SubtasksListSharedProps } from "./SubtasksList";
import { AddNewSubtask } from "./AddNewSubtask";

import "./Subtasks.scss";
import { useAppSelector } from "redux/hooks";
import { shallowEqual } from "react-redux";
import { canCreate, canReadAll } from "app/_utils/authUtils";

export interface SubtasksProps extends SubtasksListSharedProps {
  disabled?: boolean;
  onCreate: (subtaskTitle: string, toogleAddNew: (action?: any) => any) => void;
}

const InnerSubtasks: React.FunctionComponent<SubtasksProps> = ({
  disabled = false,
  list = [],
  onCreate,
  onUpdate,
  onDelete,
}) => {
  const [addNew, toogleAddNew] = useToggle(false);
  const [isEditing, toogleEditing] = useToggle(false);

  const shouldRenderSubtasksInfo = list.length > 1;
  const shouldRenderSubtasksList = list.length > 0;

  const innerCreateHandler = React.useCallback(
    (subtaskTitle: string) => {
      onCreate(subtaskTitle, toogleAddNew);
    },
    [onCreate]
  );

  const { groups, session } = useAppSelector(
    (state) => ({
      groups: state.auth.groups,
      session: state.auth.session,
    }),
    shallowEqual
  );

  return (
    <div className="subtasks" data-cy="subtasks-container">
      <div className="subtasks__header">
        <div className="title h5">
          <FormattedMessage id="TASK.SUBTASKS.TITLE" />
        </div>
        {canCreate(groups, session, "TASK") && canReadAll(groups, session, "TASK") && (
          <div className="actions">
            <button
              type="button"
              data-cy="button-add-subtask"
              className="btn btn-primary font-weight-bold"
              disabled={disabled || addNew || isEditing}
              onClick={toogleAddNew}
            >
              <FormattedMessage id="COMMON.ACTION.ADD" />
            </button>
          </div>
        )}

        {/* do not display if the the subtasks list is empty */}
        {shouldRenderSubtasksInfo && (
          <SubtasksProgress
            list={list}
            progressClassName="progressbar"
            statusClassName="progress-status"
            numberClassName="number"
          />
        )}
      </div>

      {/* displayed only when the task contains a subtask list */}
      {shouldRenderSubtasksList && (
        <div className="subtasks__body">
          <SubtasksList
            {...{
              disabled: disabled || addNew,
              list,
              onUpdate,
              onDelete,
              toogleEditing,
            }}
          />
        </div>
      )}

      {/* this will be only visible when the user click on the add new subtask */}
      {addNew && (
        <div className="subtasks__add-new">
          <AddNewSubtask
            {...{
              disabled: disabled || isEditing,
              onCreate: innerCreateHandler,
              onCancel: toogleAddNew,
            }}
          />
        </div>
      )}
    </div>
  );
};

// avoid unnecessary re-render
export const Subtasks = React.memo(InnerSubtasks);

export default Subtasks;
