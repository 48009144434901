import { IFile, ILinkedFile } from "../../../data/schemas";

interface LinkedFilesAddedProps {
  entityForEdit: any;
  fileId: string;
  linkedFiles: ILinkedFile[];
}
interface LinkedFileRemovedProps {
  entityForEdit: any;
  fileId: string;
  linkedFileToRemove?: string;
}
interface LinkedFileRenamedProps {
  entityForEdit: any;
  updatedFile: IFile;
}
export class FilesSliceUtils {
  static linkedFilesAdded = (props: LinkedFilesAddedProps) => {
    ["current", "saved"].forEach((stateType) => {
      const files = props.entityForEdit?.[stateType]?.files;
      if (files) {
        const file = files.find((file: IFile) => file.id === props.fileId);
        if (file) {
          if (!file.linkedFiles) file.linkedFiles = [];
          file.linkedFiles.push(...props.linkedFiles);
        }
      }
    });
  };

  static linkedFileRemoved = (props: LinkedFileRemovedProps) => {
    ["current", "saved"].forEach((stateType) => {
      const files = props.entityForEdit?.[stateType]?.files;
      if (files) {
        const file = files.find((file: IFile) => file.id === props.fileId);
        if (file) {
          const linkedFileIndex = file.linkedFiles.findIndex(
            (file: ILinkedFile) => file.id === props.linkedFileToRemove
          );
          file.linkedFiles.splice(linkedFileIndex, 1);
        }
      }
    });
  };

  static linkedFileRenamed = ({ updatedFile, entityForEdit }: LinkedFileRenamedProps) => {
    for (const linkedFile of updatedFile.linkedFiles ?? []) {
      if (linkedFile.relatedEntity.id === updatedFile.relatedEntity.id) {
        ["current", "saved"].forEach((stateType) => {
          const linkedFileState = entityForEdit?.[stateType]?.files?.find(
            (file: IFile) => file.id === linkedFile.id
          );
          const linkToUpdate = linkedFileState?.linkedFiles?.find(
            (lf: ILinkedFile) => lf.id === updatedFile.id
          );
          if (linkToUpdate) linkToUpdate.friendlyName = updatedFile.friendlyName;
        });
      }
    }
  };
}
