import React from "react";
import { ProgressBar } from "react-bootstrap";

export function ModalProgressBar({ variant = "primary" }) {
  return (
    <ProgressBar
      variant={variant}
      animated
      now={100}
      style={{ width: "100%", borderBottomRightRadius: 0, borderBottomLeftRadius: 0,position: "absolute", height: 10 }}
      data-cy={"progress-bar"}
    />
  );
}
