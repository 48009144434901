import * as React from "react";
import { NumberMetricCard, NumberMetricProps } from "./NumberMetricCard";
import { SUFFIX_PERCENTAGE } from "../../_utils/suffixUtils";

export const PercentageMetricCard: React.FC<NumberMetricProps> = ({
  decimalScale = 1,
  children,
  ...props
}) => {
  return (
    <NumberMetricCard {...props} decimalScale={decimalScale} suffix={SUFFIX_PERCENTAGE}>
      {children}
    </NumberMetricCard>
  );
};
