import * as React from "react";
import cn from "clsx";
import type { SingleValueProps } from "react-select";
import { components, GroupBase } from "react-select";

import type { CustomSelectOption } from "../definitions";

import "./CustomSingleValue.scss";

export const CustomSingleValue = <
  Option,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>
>({
  children,
  className,
  ...props
}: SingleValueProps<Option, IsMulti, Group>) => {
  const { icon, color } = props.data as any as CustomSelectOption;
  return (
    <components.SingleValue {...{ ...props, className: cn("custom-single-value", className) }}>
      {icon && <i style={{ color }} className={`fas fa-${icon} icon-md`} />}
      <span data-cy="select-custom-single-value" className="ellipsis">
        {children}
      </span>
    </components.SingleValue>
  );
};

export default CustomSingleValue;
