import React from "react";
import SVG from "react-inlinesvg";
import { getIconFilePath } from "../../_utils/fileUtils";
import { ColumnFormatter } from "react-bootstrap-table-next";
import { TFileStatus } from "../../../data/schemas";

interface RowProps {
  mimeType: string;
  status: TFileStatus;
}

export const DocumentTypeColumnFormatter: ColumnFormatter<RowProps> = (cell, row) => {
  return (
    <span className="svg-icon svg-icon-3x">
      <SVG src={getIconFilePath(!row?.status?.includes("REQUEST") ? row?.mimeType : undefined)} />
    </span>
  );
};
