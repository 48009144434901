import { useField } from "formik";
import cn from "clsx";

import { FieldFeedbackLabel } from "./FieldFeedbackLabel";

export function Select({
  label,
  withFeedbackLabel = true,
  type = "text",
  customFeedbackLabel,
  children,
  inline = false,
  className = "",
  ...props
}) {
  const [field, meta] = useField(props);
  const { touched, error, value } = meta;

  const selectCodeBlock = (
    <>

      <select
        className={cn(
          "form-control custom-select",
          className,
          {
            "is-invalid-select": touched && error
          }
        )}
        {...field}
        {...props}
        style={props.disabled ? { appearance: "none" } : {}}
      >
        {children}
      </select>

      {withFeedbackLabel && !props.disabled && (
        <FieldFeedbackLabel
          error={error}
          touched={touched}
          value={value}
          label={label}
          customFeedbackLabel={customFeedbackLabel}
        />
      )}
    </>
  );

  return (
    <>
      {inline ? (
        <>
          {label && <label className={"col-lg-3 col-form-label text-lg-right"}>{label}</label>}
          <div className={"col-md-9 col-lg-4"}>{selectCodeBlock}</div>
        </>
      ) : (
        <>
          {label && (
            <label>
              {/*TODO temporarily disabled as it is not required to indicate select as prefix on a droplist*/}
              {/*{!props.disabled && "Select "} */}
              {label}
            </label>
          )}
          {selectCodeBlock}
        </>
      )}
    </>
  );
}
