import * as React from "react";
import { DropdownMenu, DropdownMenuProps } from "../../../../actions-menu/DropdownMenu";
import { CardTableDropdownActionsDelete, CardTableDropdownActionsMove } from "./actions";
import { CardTableDropdownActionsProvider } from "./context/CardTableDropdownActionsContext";

type CardTableDropdownActionsComponents = {
  Delete: typeof CardTableDropdownActionsDelete;
  Move: typeof CardTableDropdownActionsMove;
};

export interface CardTableDropdownActionsProps extends DropdownMenuProps {}

export const CardTableDropdownActions: React.FC<CardTableDropdownActionsProps> &
  CardTableDropdownActionsComponents = ({ disabled, children, ...props }) => {
  const buttonRef = React.useRef<HTMLButtonElement>(null);

  return (
    <DropdownMenu
      disabled={disabled}
      alignRight={false}
      onClick={(isOpen, event, metadata) => {
        event.preventDefault();
        event.stopPropagation();
      }}
      buttonRef={buttonRef}
      dropDirection="down"
      {...props}
    >
      <CardTableDropdownActionsProvider buttonRef={buttonRef.current}>
        {children}
      </CardTableDropdownActionsProvider>
    </DropdownMenu>
  );
};

CardTableDropdownActions.Delete = CardTableDropdownActionsDelete;
CardTableDropdownActions.Move = CardTableDropdownActionsMove;
