import React, { useEffect, useRef, useState } from "react";
import TextareaAutosize from "@material-ui/core/TextareaAutosize/TextareaAutosize";
import { Field, Formik } from "formik";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch } from "react-redux";

import * as tasksActions from "../../modules/PropertiesManagement/_redux/tasks/tasksActions";
import { TextEditor } from "../TextEditor/TextEditor";
import { commentToolbar } from "../TextEditor/config";

export const CommentItemCreate = ({ task, session, draft }) => {
  const formRef = useRef();

  const [isFocused, setIsFocused] = useState(false);
  const [commentDraft, setCommentDraft] = useState(draft);
  const intl = useIntl();
  const dispatch = useDispatch();

  const showButtons = () => {
    setIsFocused(true);
  };

  const commentInit = {
    createdAt: undefined,
    createdByUserId: undefined,
    updatedAt: undefined,
    content: "",
    taskId: undefined,
  };

  const saveEntity = (entity) => {
    entity.createdAt = new Date();
    entity.createdByUserId = session?.id;
    entity.taskId = task.id;
    entity.isDraft = false;

    dispatch(tasksActions.createComment(entity));
  };

  const saveDraft = (entity) => {
    if (commentDraft) {
      entity.updatedAt = new Date();
      entity.updatedByUserId = session?.id;
      dispatch(tasksActions.updateComment(entity));
    } else {
      entity.createdAt = new Date();
      entity.createdByUserId = session?.id;
      entity.taskId = task.id;
      entity.isDraft = true;
      dispatch(tasksActions.createComment(entity));
    }
  };

  const resetCommentInput = (values) => {
    values.content = "";
    setIsFocused(false);
    if (commentDraft) {
      setCommentDraft(undefined);
      dispatch(tasksActions.deleteComment(draft));
    }
  };

  useEffect(() => {
    const formInstance = formRef.current;
    return function cleanUp() {
      const formCommentDraft = formInstance.values;
      if (formCommentDraft?.content?.length === 0 && formCommentDraft.isDraft) {
        setCommentDraft();
        dispatch(tasksActions.deleteComment(formCommentDraft));
      }

      if (formCommentDraft?.content.length > 0 && formCommentDraft.content !== draft?.content) {
        saveDraft(formCommentDraft);
      }
    };
  }, []);

  return (
    <>
      <Formik
        innerRef={formRef}
        enableReinitialize={true}
        initialValues={draft || commentInit}
        onSubmit={(values) => {
          saveEntity(values, false);
          resetCommentInput(values);
        }}
      >
        {({ values, handleSubmit, setFieldValue }) => (
          <>
            <div className="form-group row">
              <div className="col-lg-12">
                {isFocused ? (
                  <TextEditor
                    data-cy="input-task-comment-create"
                    data={values.content}
                    name={"content"}
                    saveMethod={setFieldValue}
                    toolbarOption={commentToolbar}
                    size={"100px"}
                    id={"ckeditor-comment-create"}
                    timeout={false}
                  />
                ) : (
                  <Field
                    name="content"
                    data-cy="input-task-comment-create"
                    as={TextareaAutosize}
                    placeholder={intl.formatMessage({ id: "COMMENT.CREATE" })}
                    onChange={(e) => {
                      setFieldValue("content", e.target.value);
                    }}
                    className={`form-control`}
                    style={{ resize: "none", overflowWrap: "anywhere" }}
                    onFocus={() => showButtons()}
                  />
                )}
              </div>
            </div>
            {isFocused && (
              <div className={"text-right"}>
                <button
                  type="button"
                  onClick={() => resetCommentInput(values)}
                  className="btn btn-light btn-elevate"
                >
                  <FormattedMessage id="COMMON.ACTION.CANCEL" />
                </button>
                <> </>
                <button
                  data-cy="button-comment-save"
                  type="button"
                  onClick={() => {
                    handleSubmit();
                  }}
                  disabled={values?.content?.length === 0}
                  className="btn btn-primary btn-elevate"
                >
                  <FormattedMessage id="COMMON.ACTION.SAVE" />
                </button>
              </div>
            )}
          </>
        )}
      </Formik>
    </>
  );
};
