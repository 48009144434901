import { ISupplementRevenue } from "../definitions";

interface findOrInitializeSupplementProps {
  supplementRevenue: ISupplementRevenue[];
  budgetInstalmentId?: string;
  financialDocumentId?: string;
  id: string;
  title: string;
  fileType: "SUPPLEMENTARY_AGREEMENT" | "INVOICE";
}
export const findOrInitializeSupplement = ({
  supplementRevenue,
  budgetInstalmentId,
  financialDocumentId,
  id,
  title,
  fileType,
}: findOrInitializeSupplementProps) => {
  const existingSupplement = supplementRevenue.find((supplement) => {
    switch (fileType) {
      case "SUPPLEMENTARY_AGREEMENT":
        return budgetInstalmentId
          ? supplement.budgetInstalmentId === budgetInstalmentId
          : supplement.id === id;
      case "INVOICE":
        return financialDocumentId
          ? supplement.id === financialDocumentId
          : supplement.budgetInstalmentId === budgetInstalmentId;
      default:
        return false;
    }
  });

  if (existingSupplement) {
    return existingSupplement;
  }

  const newSupplement: ISupplementRevenue = {
    budgetInstalmentId,
    id,
    label: title,
    sold: 0,
    invoiced: 0,
    supplementMargin: 0,
    paymentStatusCount: {},
  };

  supplementRevenue.push(newSupplement);

  return newSupplement;
};
