import * as React from "react";
import { useLeadContext } from "../../../../LeadContext";
import {
  CurrencyMetricCard,
  MetricCard,
  MetricCardProps,
} from "../../../../../../../../../_components/MetricCard";
import { roundByTo100 } from "../../../../../../../../../_utils/mathUtils";

export interface PaymentBreakdownProps {}

export const PaymentBreakdown: React.FC<PaymentBreakdownProps & MetricCardProps> = (props) => {
  const { leadInstalments, leadSupplements } = useLeadContext();

  let totalInclVAT = 0;
  let totalPaidInclVAT = 0;
  let totalInvoicedInclVAT = 0;
  for (const item of [...leadInstalments, ...leadSupplements]) {
    totalInclVAT += item.amountInclVAT ?? 0;
    totalPaidInclVAT += item.paidInclVAT ?? 0;
    totalInvoicedInclVAT += item.invoicedInclVAT ?? 0;
  }
  const invoicedNotPaid = totalInvoicedInclVAT - totalPaidInclVAT;
  const notInvoiced = totalInclVAT - totalInvoicedInclVAT;

  const roundedPercents = roundByTo100(
    [totalPaidInclVAT, invoicedNotPaid, notInvoiced],
    totalInclVAT
  );

  return (
    <CurrencyMetricCard
      title="LEAD.INSTALMENTS.PAYMENT_BREAKDOWN"
      dataCy="input-lead-instalments-expected-price-incl-vat"
      value={totalInclVAT}
      label="LEAD.INSTALMENTS.TOTAL_EXPECTED_PAYMENT_INCL_VAT"
      {...props}
    >
      <MetricCard.CurrencySubMetric
        dataCy="input-lead-instalments-paid"
        label="LEAD.INSTALMENTS.PAID"
        value={totalPaidInclVAT}
        secondValue={roundedPercents[0]}
      />
      <MetricCard.CurrencySubMetric
        dataCy="input-lead-instalments-invoiced"
        label="LEAD.INSTALMENTS.INVOICED"
        value={invoicedNotPaid}
        secondValue={roundedPercents[1]}
      />
      <MetricCard.CurrencySubMetric
        dataCy="input-lead-instalments-not-invoiced"
        label="LEAD.INSTALMENTS.NOT_INVOICED"
        value={notInvoiced}
        secondValue={roundedPercents[2]}
      />
    </CurrencyMetricCard>
  );
};
