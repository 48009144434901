import * as React from "react";
import { useIntl } from "react-intl";
import { sumBy } from "lodash-es";

import {
  IBudget,
  ILead,
  ILeadFinancialDocument,
  IProject,
  isLeadFinancialDocumentPriceLine,
} from "data/schemas";

import { accurateFloatOperation } from "app/_utils/mathUtils";

import { useGetRevenue } from "../hooks/useGetRevenue";
import {
  BUDGET_REVENUE_CALCULATED_ID,
  IInstalmentRevenue,
  ISupplementRevenue,
} from "../definitions";
import { findOrInitializeSupplement } from "../utils";
import { getTotalInvoiceStatus } from "../../utils";

export interface IBudgetRevenueContext {
  projectName: string;
  budgetName: string;

  leadFinancialDocuments: ILeadFinancialDocument[];
  leads: ILead[];
  isLoading: boolean;
  unsoldTotal: number;
  soldTotal: number;
  unsoldConstructionPriceTotal: number;
  soldConstructionPriceTotal: number;
  productsQty: {
    total: number;
    sold: number;
    unsold: number;
  };
  soldInvoicedInstalments: number;
  soldNotInvoicedInstalments: number;
  instalmentRevenue: IInstalmentRevenue[];
  supplementRevenue: ISupplementRevenue[];
}

export const BudgetRevenueContext = React.createContext<IBudgetRevenueContext>(
  {} as IBudgetRevenueContext
);

export function useBudgetRevenueContext() {
  return React.useContext<IBudgetRevenueContext>(
    BudgetRevenueContext as React.Context<IBudgetRevenueContext>
  );
}

export interface BudgetRevenueProviderProps {
  project: Partial<IProject>;
  budget: IBudget;
}

export const BudgetRevenueProvider = ({
  children,

  project,
  budget,
}: React.PropsWithChildren<BudgetRevenueProviderProps>) => {
  const intl = useIntl();

  const projectId = project.id!;
  const projectName = project.name ?? "";
  const budgetName = budget.name ?? "";

  const { isLoading, data } = useGetRevenue({ projectId });

  const { leadFinancialDocuments, leads, products } = data ?? {};

  let unsoldTotal = 0;
  let unsoldShareOfLandTotal = 0;
  let unsoldArchitectFeesTotal = 0;

  let soldTotal = 0;
  let soldShareOfLandTotal = 0;
  let soldArchitectFeesTotal = 0;
  let soldInvoicedInstalments = 0;

  let productsQty = {
    total: products?.length ?? 0,
    sold: 0,
    unsold: 0,
  };

  const instalmentRevenue: IInstalmentRevenue[] = [];
  const supplementRevenue: ISupplementRevenue[] = [];

  // Parsing products to compute unsold related values
  for (const product of products ?? []) {
    if (product.leadStatus.WON) {
      continue;
    }

    const {
      financialDetails: { price, architectEngineeringFees, shareOfLand },
    } = product;
    productsQty.unsold += 1;
    unsoldTotal += price ?? 0;
    unsoldShareOfLandTotal += shareOfLand ?? 0;
    unsoldArchitectFeesTotal += architectEngineeringFees ?? 0;
  }
  let unsoldConstructionPriceTotal =
    unsoldTotal - unsoldShareOfLandTotal - unsoldArchitectFeesTotal;

  // Parsing won leads to compute sold related values
  for (const lead of leads ?? []) {
    productsQty.sold += 1;
    soldTotal += lead.sellingPrice ?? 0;
    soldShareOfLandTotal += lead.shareOfLandSellingPrice ?? 0;
    soldArchitectFeesTotal += lead.architectEngineeringFees ?? 0;
  }
  let soldConstructionPriceTotal = soldTotal - soldShareOfLandTotal - soldArchitectFeesTotal;

  // Populate instalment revenue list with budget instalments and default share of land and architect fees instalment
  instalmentRevenue.push(
    {
      id: BUDGET_REVENUE_CALCULATED_ID.SHARE_OF_LAND,
      label: intl.formatMessage({ id: "COMMON.EXPECTED.TOTAL.SHARE_OF_LAND" }),
      expected: unsoldShareOfLandTotal + soldShareOfLandTotal,
      sold: soldShareOfLandTotal,
      invoiced: 0,
      invoicedLeads: [],
      paymentStatusCount: {},
    },
    {
      id: BUDGET_REVENUE_CALCULATED_ID.ARCHITECT_ENGINEERING_FEES,
      label: intl.formatMessage({ id: "COMMON.EXPECTED.TOTAL.ARCHITECT_ENGINEERING" }),
      expected: unsoldArchitectFeesTotal + soldArchitectFeesTotal,
      sold: soldArchitectFeesTotal,
      invoiced: 0,
      invoicedLeads: [],
      paymentStatusCount: {},
    }
  );

  for (const { id, label, instalment } of budget.budgetInstalments) {
    instalmentRevenue.push({
      id,
      label,
      instalmentPercentage: instalment * 100,
      expected: instalment * (unsoldConstructionPriceTotal + soldConstructionPriceTotal),
      sold: instalment * soldConstructionPriceTotal,
      invoiced: 0,
      invoicedLeads: [],
      paymentStatusCount: {},
    });
  }

  // Loop over every lead financial files of the project to compute instalmentRevenue and supplementRevenue
  for (const leadFinancialDocument of leadFinancialDocuments ?? []) {
    const { fileType, content, title, id, invoiceStatus, basePriceIndex, priceIndex } =
      leadFinancialDocument;
    let priceIndexChangeCoef = 0;
    if (basePriceIndex?.value && priceIndex?.value) {
      priceIndexChangeCoef = (priceIndex.value - basePriceIndex.value) / basePriceIndex.value;
    }
    switch (fileType) {
      case "SUPPLEMENTARY_AGREEMENT":
        const linkedToInstalment = content[0].budgetInstalmentId;

        const supplement = findOrInitializeSupplement({
          supplementRevenue,
          budgetInstalmentId: linkedToInstalment,
          id,
          title,
          fileType,
        });

        let sold = 0;
        let supplementMargin = 0;
        for (const line of content) {
          if (!isLeadFinancialDocumentPriceLine(line)) continue;
          sold += line.amount ?? 0;
          supplementMargin += line.margin ?? 0;
        }

        supplement.sold! += sold;
        supplement.supplementMargin! += supplementMargin;

        break;

      case "INVOICE":
        for (const line of content) {
          if (!isLeadFinancialDocumentPriceLine(line)) continue;

          if (line.budgetInstalmentId) {
            const instalment = instalmentRevenue.find((i) => i.id === line.budgetInstalmentId);
            if (instalment) {
              if (!instalment.invoicedLeads.find((id) => id === leadFinancialDocument.leadId)) {
                // Whenever a lead has an invoice, we substract the expected sold value of the instalment by the invoiced value, so we can handle indexes
                const lead = leads!.find((l) => l.id === leadFinancialDocument.leadId);
                if (lead) {
                  if (line.budgetInstalmentId === BUDGET_REVENUE_CALCULATED_ID.SHARE_OF_LAND) {
                    instalment.sold! -= lead.shareOfLandSellingPrice;
                  } else if (
                    line.budgetInstalmentId ===
                    BUDGET_REVENUE_CALCULATED_ID.ARCHITECT_ENGINEERING_FEES
                  ) {
                    instalment.sold! -= lead.architectEngineeringFees;
                  } else {
                    const leadConstructionPrice =
                      lead.sellingPrice -
                      lead.shareOfLandSellingPrice -
                      lead.architectEngineeringFees;
                    instalment.sold! -=
                      (instalment.instalmentPercentage! / 100) * leadConstructionPrice;
                  }
                }
                instalment.invoicedLeads.push(leadFinancialDocument.leadId);
              }
              const indexedAmount = accurateFloatOperation(line.amount * priceIndexChangeCoef, 2);
              instalment.indexedAmount = (instalment.indexedAmount ?? 0) + indexedAmount;
              instalment.invoiced = (instalment.invoiced ?? 0) + line.amount + indexedAmount;
              instalment.sold! += line.amount + indexedAmount;
              instalment.paymentStatusCount[invoiceStatus] =
                (instalment.paymentStatusCount[invoiceStatus] ?? 0) + 1;
              soldInvoicedInstalments += line.amount + indexedAmount;
            }
          }

          if (!line.relatedFinancialDocuments && !line.financialDocumentId) continue;

          const supplement = findOrInitializeSupplement({
            supplementRevenue,
            budgetInstalmentId: line.budgetInstalmentId,
            financialDocumentId: line.financialDocumentId,
            id,
            title,
            fileType,
          });

          const sumRFD = sumBy(line.relatedFinancialDocuments, (rfd) =>
            sumBy(rfd.content, "amount")
          );
          // Amount of supplement is fixed from SA to invoice so no need to search for the SA amount, just need to add the indexed amount to the sold total
          supplement.sold! += sumRFD * priceIndexChangeCoef;
          supplement.invoiced! += sumRFD * (1 + priceIndexChangeCoef);
          supplement.paymentStatusCount![invoiceStatus] =
            (supplement.paymentStatusCount![invoiceStatus] ?? 0) + 1;
        }
        break;
    }
  }

  let soldNotInvoicedInstalments = soldTotal - soldInvoicedInstalments;

  // Setting the invoice status and the percentages after all the document mapping is done
  for (const revenue of instalmentRevenue) {
    revenue.paymentStatus = getTotalInvoiceStatus(revenue.paymentStatusCount!);

    if (revenue.sold) {
      revenue.invoicedPercentage = ((revenue.invoiced ?? 0) / revenue.sold) * 100;
    }
  }
  for (const revenue of supplementRevenue) {
    revenue.paymentStatus = getTotalInvoiceStatus(revenue.paymentStatusCount!);

    if (revenue.sold) {
      revenue.invoicedPercentage = ((revenue.invoiced ?? 0) / revenue.sold) * 100;
      revenue.supplementMarginPercentage =
        ((revenue.supplementMargin ?? 0) / (revenue.sold - (revenue.supplementMargin ?? 0))) * 100;
    }
  }

  const value: IBudgetRevenueContext = {
    projectName,
    budgetName,

    isLoading,

    leads: leads ?? [],
    leadFinancialDocuments: leadFinancialDocuments ?? [],

    unsoldTotal,
    soldTotal,

    unsoldConstructionPriceTotal,
    soldConstructionPriceTotal,

    productsQty,

    soldInvoicedInstalments,
    soldNotInvoicedInstalments,

    instalmentRevenue,
    supplementRevenue,
  };

  return <BudgetRevenueContext.Provider value={value}>{children}</BudgetRevenueContext.Provider>;
};
