import { createContext, FunctionComponent, useContext } from "react";

import { TasksContentViewMode, TTasksContentViewMode } from "./TasksCard/TasksCardContent";
import { ITask } from "../../../../../data/schemas";
import { SearchTypeValues } from "../../../../contexts/SearchContext";
import { useIntl } from "react-intl";
import { formatDropdownOptions } from "../../../../_utils/formatDataUtils";
import { filterUITag } from "../../../../_utils/search/searchUtils";
import { useTag } from "../../../../hooks/useTag";
import { canReadAll } from "../../../../_utils/authUtils";
import { TAG, TASK } from "../../../../_utils/dataTypes";
import { useAppSelector } from "../../../../../redux/hooks";

export interface ITasksUIEvents {
  newTaskButtonClick?: () => void;

  buildEditTaskUrl?: (id: string) => string;
  openEditTaskPage?: (id: string) => void;

  toggleContentView?: (contentView?: TTasksContentViewMode) => void;
  toggleGanttChartDetails?: (showDetails?: boolean) => void;
}

export interface ITasksUIContext extends ITasksUIEvents {
  ganttShowDetails: boolean;
  contentView: TTasksContentViewMode;
  filtersUI?: any[];
}

const TasksUIContext = createContext<ITasksUIContext>({
  ganttShowDetails: false,
  contentView: TasksContentViewMode.COLUMNS,
});

export interface TasksUIProviderProps {
  ganttShowDetails?: boolean;
  contentView?: TTasksContentViewMode;
  tasksUIEvents?: ITasksUIEvents;
}

interface Option {
  label: string;
  value: any;
}

export const TasksUIProvider: FunctionComponent<TasksUIProviderProps> = ({
  ganttShowDetails = false,
  contentView = TasksContentViewMode.COLUMNS,
  tasksUIEvents,
  children,
}) => {
  const intl = useIntl();
  const { tagLinks } = useTag();
  const { groups, session, relatedToEntityNames } = useAppSelector((state: any) => ({
    session: state.auth.session,
    groups: state.auth.groups,
    relatedToEntityNames: state.tasks.relatedToEntityNames,
  }));

  const value = {
    ganttShowDetails,
    contentView,
    newTaskButtonClick: tasksUIEvents?.newTaskButtonClick,
    buildEditTaskUrl: tasksUIEvents?.buildEditTaskUrl,
    openEditTaskPage: tasksUIEvents?.openEditTaskPage,
    toggleContentView: tasksUIEvents?.toggleContentView,
    toggleGanttChartDetails: tasksUIEvents?.toggleGanttChartDetails,
    filtersUI: [
      {
        name: "COMMON.SEARCH.TITLE",
        value: "text",
        type: SearchTypeValues.SEARCH_TEXT_COLUMNS,
        columns: [
          { value: "name", label: "COMMON.NAME" },
          { value: "description", label: "COMMON.TYPE" },
          { value: "assignee", label: "TASK.LABEL.ASSIGNEE" },
        ],
        checkTag: true,
      },
      {
        name: "TASK.LABEL.RELATED_TO",
        type: SearchTypeValues.SEARCH_DROPDOWN,
        value: "relatedTo",
        values: [],
        groupBy: (options: Option[], entities?: ITask[]) => {
          return formatDropdownOptions(intl, options, relatedToEntityNames, "typeIdTuple");
        },
      },
      {
        name: "TASK.LABEL.ASSIGNEE",
        type: SearchTypeValues.SEARCH_DROPDOWN,
        value: "assigneeId",
        values: [],
        groupBy: (options: Option[]) => {
          return formatDropdownOptions(intl, options, relatedToEntityNames, "id");
        },
      },
      {
        name: "TASK.LABEL.DATE.DUE",
        value: "dueDate",
        type: SearchTypeValues.SEARCH_DATE,
        isAdvanced: true,
        isFuture: true,
      },
      {
        name: "COMMON.CREATED.AT",
        value: "createdAt",
        type: SearchTypeValues.SEARCH_DATE,
        isAdvanced: true,
      },
      {
        name: "COMMON.UPDATED.AT",
        value: "updatedAt",
        type: SearchTypeValues.SEARCH_DATE,
        isAdvanced: true,
      },
      {
        // TODO : add translation "hide subtasks"
        name: "TASK.SUBTASKS.TITLE",
        value: "parentId",
        type: SearchTypeValues.SEARCH_CHECKBOX,
        isAdvanced: true,
        isChecked: true,
        validator: (entity: ITask, value: string) => {
          if (value !== "true") {
            return entity.parentId === undefined;
          } else {
            return true;
          }
        },
      },
    ],
  };
  if (canReadAll(groups, session, TAG)) {
    // @ts-ignore
    value.filtersUI.push(filterUITag(TASK, tagLinks));
  }

  return <TasksUIContext.Provider value={value}>{children}</TasksUIContext.Provider>;
};

export const useTasksUIContext = () => useContext(TasksUIContext);

export default TasksUIProvider;
