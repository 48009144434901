import { createSlice } from "@reduxjs/toolkit";
import { isEqual, set, sumBy } from "lodash-es";

import { FinanceFileTypeToSubcontractorFinance, isFinanceFile } from "data/schemas";

import { sliceSignaturesRequestFileHelper } from "app/_utils/signatureUtils";

import { FilesSliceUtils } from "app/modules/File/filesSliceUtils";

const initialUsersState = {
  listLoading: false,
  actionsLoading: false,
  entities: [],
  me: undefined,
  entityForEdit: {
    saved: undefined,
    current: undefined,
  },
  chat: {},
};

export const callTypes = {
  list: "list",
  action: "action",
};

export const usersSlice = (name) =>
  createSlice({
    name: name,
    initialState: initialUsersState,
    reducers: {
      catchError: (state, action) => {
        // state.error = `${action.type}: ${action.payload.error}`;
        if (action.payload.callType === callTypes.list) {
          state.listLoading = false;
        } else {
          state.actionsLoading = false;
        }
      },
      startCall: (state, action) => {
        state.error = null;
        if (action.payload.callType === callTypes.list) {
          state.listLoading = true;
          if (!isEqual(action.payload?.queryParams, state.queryParams)) {
            state.entities = [];
          }
        } else {
          state.actionsLoading = true;
        }
      },
      userFetched: (state, action) => {
        state.actionsLoading = false;
        state.entityForEdit = {
          saved: action.payload.entityForEdit,
          current: action.payload.entityForEdit,
        };
        state.error = null;
      },
      userMe: (state, action) => {
        state.actionsLoading = false;
        state.me = {
          current: action.payload.me,
        };
        state.error = null;
      },
      usersFetched: (state, action) => {
        const { entities } = action.payload;
        state.listLoading = false;
        state.error = null;
        state.entities = entities;
        // state.entityForEdit = initialUsersState.entityForEdit;
      },
      userCreated: (state, action) => {
        state.actionsLoading = false;
        state.error = null;
        state.entities.push(action.payload.user);
        state.entityForEdit = {
          saved: action.payload.user,
          current: action.payload.user,
        };
      },
      userFieldUpdatedLocally: (state, action) => {
        state.error = null;
        state.actionsLoading = false;
        if (!state.entityForEdit.current) {
          state.entityForEdit.current = {};
        }
        set(state.entityForEdit.current, action.payload.key, action.payload.value);
      },
      userUpdatedLocally: (state, action) => {
        state.error = null;
        state.actionsLoading = false;
        state.entityForEdit.current = action.payload.entityForEdit;
      },
      userUpdated: (state, action) => {
        const { user, updateCurrent = false } = action.payload;
        state.error = null;
        state.actionsLoading = false;
        state.entityForEdit.saved = {
          ...user,
        };
        if (updateCurrent) {
          state.entityForEdit.current = {
            ...user,
          };
        }

        if (state.entityForEdit?.current?.files) {
          state.entityForEdit.saved.files = state.entityForEdit.current.files;
        }
        state.entities = state.entities.map((entity) => {
          if (entity.id === user.id) {
            return user;
          }
          return entity;
        });
      },
      userActionCreated: (state, action) => {
        state.error = null;
        state.actionsLoading = false;
        const { action: newAction } = action.payload;
        [("current", "saved")].forEach((key) => {
          if (
            !state.entityForEdit?.[key]?.actions ||
            !state.entityForEdit?.[key]?.actions?.length === 0
          ) {
            state.entityForEdit[key].actions = [newAction];
            state.entityForEdit[key].actions = [newAction];
          } else {
            const indexAction = state.entityForEdit[key].actions?.findIndex((a) => {
              return a?.id === newAction?.id;
            });
            if (indexAction !== -1) {
              state.entityForEdit[key].actions[indexAction] = newAction;
            } else {
              state.entityForEdit[key].actions.push(newAction);
            }
          }
        });
      },
      userRightsUpdated: (state, action) => {
        state.error = null;
        state.actionsLoading = false;
        state.entityForEdit.current = action.payload.user;
        state.entityForEdit.saved = action.payload.user;
        state.entities = state.entities.map((entity) => {
          if (entity.id === action.payload.user.id) {
            return action.payload.user;
          }
          return entity;
        });
      },
      userDeleted: (state, action) => {
        state.error = null;
        state.actionsLoading = false;
        state.entities = state.entities.filter((el) => el.id !== action.payload.id);
      },

      fileCreated: (state, action) => {
        state.error = null;
        state.actionsLoading = false;

        const { file } = action.payload;
        state.entityForEdit.current.files.push(file);
        state.entityForEdit.saved.files.push(file);

        const project = action.payload.project;
        if (project) {
          const currentUserProject = state.entityForEdit.current.projects?.find(
            (p) => p.projectId === file.projectId
          );
          if (currentUserProject) {
            currentUserProject[project.financeType] =
              (currentUserProject[project.financeType] ?? 0) + project.value;
          }
          const savedUserProject = state.entityForEdit.saved.projects?.find(
            (p) => p.projectId === file.projectId
          );
          if (savedUserProject) {
            savedUserProject[project.financeType] =
              (savedUserProject[project.financeType] ?? 0) + project.value;
          }
        }

        const { id, friendlyName, relatedEntity } = file;
        for (const linkedFile of file.linkedFiles ?? []) {
          FilesSliceUtils.linkedFilesAdded({
            entityForEdit: state.entityForEdit,
            fileId: linkedFile.id,
            linkedFiles: [
              {
                id,
                friendlyName,
                relatedEntity,
              },
            ],
          });
        }
      },
      fileUpdated: (state, action) => {
        const file = action.payload.file;
        state.error = null;
        state.actionsLoading = false;
        const currentFileIndex = state.entityForEdit.current.files.findIndex(
          (f) => f.id === file.id
        );
        const currentFile = state.entityForEdit.current.files[currentFileIndex];
        if (file.friendlyName !== currentFile.friendlyName) {
          FilesSliceUtils.linkedFileRenamed({
            updatedFile: file,
            entityForEdit: state.entityForEdit,
          });
        }

        state.entityForEdit.current.files[currentFileIndex] = file;
        const savedFileIndex = state.entityForEdit.current.files.findIndex((f) => f.id === file.id);
        state.entityForEdit.saved.files[savedFileIndex] = file;

        const project = action.payload.project;
        if (project) {
          const currentUserProject = state.entityForEdit.current.projects?.find(
            (p) => p.projectId === file.projectId
          );
          if (currentUserProject) {
            currentUserProject[project.financeType] =
              (currentUserProject[project.financeType] ?? 0) + project.value;
          }
          const savedUserProject = state.entityForEdit.saved.projects?.find(
            (p) => p.projectId === file.projectId
          );
          if (savedUserProject) {
            savedUserProject[project.financeType] =
              (savedUserProject[project.financeType] ?? 0) + project.value;
          }
        }
      },
      linkedFilesAdded: (state, { payload: { fileId, linkedFiles } }) =>
        FilesSliceUtils.linkedFilesAdded({
          entityForEdit: state.entityForEdit,
          fileId,
          linkedFiles,
        }),
      linkedFileRemoved: (state, { payload: { fileId, linkedFileToRemove } }) =>
        FilesSliceUtils.linkedFileRemoved({
          entityForEdit: state.entityForEdit,
          fileId,
          linkedFileToRemove,
        }),
      fileDeleted: (state, action) => {
        const file = action.payload.file;
        state.error = null;
        state.actionsLoading = false;

        const { id } = file;
        const currentFile = state.entityForEdit.current?.files?.find((file) => file.id === id);
        for (const linkedFile of currentFile?.linkedFiles ?? []) {
          FilesSliceUtils.linkedFileRemoved({
            entityForEdit: state.entityForEdit,
            fileId: linkedFile.id,
            linkedFileToRemove: currentFile.id,
          });
        }

        state.entityForEdit.current.files = state.entityForEdit.current.files.filter(
          (f) => f.id !== file.id
        );
        state.entityForEdit.saved.files = state.entityForEdit.saved.files.filter(
          (f) => f.id !== file.id
        );

        if (file.projectId && isFinanceFile(file.fileType)) {
          let financeType = FinanceFileTypeToSubcontractorFinance[file.fileType];
          const sum = sumBy(file.categories, ({ lines }) => sumBy(lines, "amount"));
          const currentUserProject = state.entityForEdit.current.projects?.find(
            (p) => p.projectId === file.projectId
          );
          if (currentUserProject) {
            currentUserProject[financeType] -= sum;
          }
          const savedUserProject = state.entityForEdit.saved.projects?.find(
            (p) => p.projectId === file.projectId
          );
          if (savedUserProject) {
            savedUserProject[financeType] -= sum;
          }
        }
      },

      avatarCreated: (state, action) => {
        state.error = null;
        state.actionsLoading = false;
      },

      signaturesRequestCreated: (state, action) => {
        state.error = null;
        state.actionsLoading = false;

        const { file } = action.payload;

        const { current, saved } = state.entityForEdit;

        sliceSignaturesRequestFileHelper({ file, current, saved });

        state.entityForEdit.current = current;
        state.entityForEdit.saved = saved;
      },

      signaturesRequestCancelled: (state, action) => {
        state.error = null;
        state.actionsLoading = false;

        const { file } = action.payload;

        const { current, saved } = state.entityForEdit;

        sliceSignaturesRequestFileHelper({ file, current, saved });

        state.entityForEdit.current = current;
        state.entityForEdit.saved = saved;
      },
    },
  });
