import { FC, ChangeEvent } from "react";
import SVG from "react-inlinesvg";
import { Field, FieldArray } from "formik";
import { FormattedMessage } from "react-intl";
import { cloneDeep } from "lodash-es";

import { Input } from "_metronic/_partials/controls";
import { toAbsoluteUrl } from "_metronic/_helpers";

import { URL_LABEL } from "app/_utils/listUtils";

import { useEntityDeleteDialogContext } from "app/modules/PropertiesManagement/pages/entity-delete-dialog/EntityDeleteDialogContext";

//----------------------------------------------------------------------------//

const regExURL = new RegExp(
  "^(?:http(s)?:\\/\\/)?[\\w.-]+(?:\\.[\\w\\.-]+)+[\\w\\-\\._~:/?#[\\]@!\\$&'\\(\\)\\*\\+,;=.]+$"
);

const loadLogo = (url: string): { logo: string } => {
  const defaultResponse = {
    logo: "/media/svg/icons/General/Clip.svg",
  };

  try {
    if (!regExURL.test(url)) {
      return defaultResponse;
    }
    const urlFormatted = new URL(url);
    const key = Object.keys(URL_LABEL).filter((key) => {
      return urlFormatted.origin.includes(key);
    })[0];
    return (URL_LABEL as any)[key] ?? defaultResponse;
  } catch (e) {
    return defaultResponse;
  }
};

//----------------------------------------------------------------------------//

interface IValues {
  urls: string[];
}

export interface UrlsProps {
  disabled: boolean;
  values: IValues;
  saveFields?: Function;
  shouldConfirmRemove?: boolean;
}

export const Urls: FC<UrlsProps> = ({
  disabled,
  values,
  saveFields,
  shouldConfirmRemove = true,
}) => {
  const { setDeleteEntityDialog } = useEntityDeleteDialogContext();

  const saveUrlsField = (urls: string[]) => saveFields && saveFields("urls", urls);

  const urlChangeHandler =
    (values: IValues, index: number) => (e: ChangeEvent<HTMLInputElement>) => {
      if (!saveFields) return;

      const res = cloneDeep(values.urls);
      res[index] = e.target.value;

      saveUrlsField(res);
    };

  const removeUrlLogic = (values: IValues, index: number, remove: Function) => () => {
    remove(index);
    const res = cloneDeep(values?.urls);
    res.splice(index, 1);

    saveUrlsField(res);
  };

  const removeUrlButtonClickHandler =
    (values: IValues, index: number, remove: Function) =>
    (event: React.MouseEvent<HTMLButtonElement>) => {
      event.stopPropagation();

      if (!saveFields) return;

      shouldConfirmRemove
        ? setDeleteEntityDialog({
            entityType: "URL",
            onDelete: removeUrlLogic(values, index, remove),
          })
        : removeUrlLogic(values, index, remove)();
    };

  return (
    <>
      {(values?.urls?.length > 0 || !disabled) && (
        <>
          <div className={"mb-4"}>
            <h3>
              <FormattedMessage id={"COMMON.URL_LINK"} />
            </h3>
          </div>
          <div>
            <FieldArray
              name={"urls"}
              render={({ remove, push }) => (
                <>
                  {values.urls?.map((url: string, index: number) => (
                    <div key={index} className={"d-flex justify-content-between my-4"}>
                      <div
                        className={"d-flex justify-content-center align-items-center w-30px h-30px"}
                      >
                        <span className="symbol symbol-20 py-auto">
                          <img alt={"logo"} src={toAbsoluteUrl(loadLogo(url).logo)} />
                        </span>
                      </div>

                      <div className={"w-100 mx-4"}>
                        <Field
                          component={Input}
                          value={url}
                          onChange={urlChangeHandler(values, index)}
                          disabled={disabled}
                        />
                      </div>

                      <div>
                        {!disabled && (
                          <button
                            className={"btn btn-icon btn-light btn-hover-danger btn-sm h-100"}
                            onClick={removeUrlButtonClickHandler(values, index, remove)}
                          >
                            <span className="svg-icon svg-icon-md svg-icon-danger">
                              <SVG src={toAbsoluteUrl("/media/svg/icons/General/Trash.svg")} />
                            </span>
                          </button>
                        )}
                      </div>
                    </div>
                  ))}

                  {!disabled && (
                    <div className="d-flex justify-content-end">
                      <button
                        type="button"
                        className="btn btn-sm btn-light flex-grow-1 rounded-0 d-flex align-items-center  justify-content-center"
                        onClick={() => push("")}
                      >
                        <i className="ki ki-plus icon-nm" />
                        <FormattedMessage id="LEAD.ACTION.ADD.LINE" />
                      </button>
                    </div>
                  )}
                </>
              )}
            />
          </div>
        </>
      )}
    </>
  );
};
