import { useFileViewer } from "../../../../hooks/useFileViewer";
import React from "react";
import { Button, Spinner } from "react-bootstrap";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../../_metronic/_helpers";
import { formatFileFriendlyNameWithLang, handleDownloadFile } from "../../../../_utils/fileUtils";

export const FileViewerHeader = (): JSX.Element => {
  const { currentFiles, currentFileShown, setCurrentFileShown, isLoading } = useFileViewer();

  const index = currentFiles && currentFileShown && currentFiles.indexOf(currentFileShown);

  const isFirst = currentFiles && currentFileShown && index === 0;
  const isLast = currentFiles && currentFileShown && index === currentFiles?.length - 1;
  const isMultiple = currentFiles && currentFiles?.length > 1;

  const previousDocument = () => {
    if (currentFiles) {
      if (index > 0) {
        setCurrentFileShown(currentFiles?.[index - 1]);
      }
    }
  };

  const nextDocument = () => {
    if (currentFiles) {
      if (index < currentFiles?.length - 1) {
        setCurrentFileShown(currentFiles?.[index + 1]);
      }
    }
  };

  return (
    <div className="row  pb-0 align-items-baseline">
      <div className="col-8 text-left">
        <div className="d-flex">
          <h6 className="align-self-end" data-cy="file-viewer-modal-title">
            {formatFileFriendlyNameWithLang(currentFileShown)}{" "}
          </h6>
          {isLoading && (
            <div className="pl-2">
              <Spinner animation="border" variant="primary" size="sm" />
            </div>
          )}
        </div>
      </div>
      <div className="col-4 text-right">
        {isMultiple && (
          <>
            <Button
              size="sm"
              className="btn btn-icon btn-light-secondary"
              onClick={previousDocument}
              disabled={isFirst}
            >
              <span className="svg-icon svg-icon-md svg-icon-primary p-4">
                <SVG src={toAbsoluteUrl("/media/svg/icons/Navigation/Arrow-left.svg")} />
              </span>
            </Button>
            <Button
              size="sm"
              className="btn btn-icon btn-light-secondary ml-2"
              onClick={nextDocument}
              disabled={isLast}
            >
              <span className="svg-icon svg-icon-md svg-icon-primary p-4">
                <SVG src={toAbsoluteUrl("/media/svg/icons/Navigation/Arrow-right.svg")} />
              </span>
            </Button>
          </>
        )}
        <button
          className="btn btn-icon btn-light btn-hover-primary btn-sm ml-8"
          onClick={(e: any) => handleDownloadFile(e, currentFileShown)}
        >
          <span className="svg-icon svg-icon-sm svg-icon-primary">
            <SVG src={toAbsoluteUrl("/media/svg/icons/Files/Download.svg")} />
          </span>
        </button>
      </div>
    </div>
  );
};
