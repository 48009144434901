import * as React from "react";
import cn from "clsx";
import { FormattedMessage, useIntl } from "react-intl";
import { ILeadFinancialDocument, IPriceIndex, IProject } from "../../../data/schemas";
import { PriceIndexSelect } from "./PriceIndexSelect";
import {
  filterPriceIndicesProperDate,
  getBasePriceIndex,
  getPriceIndexOptionLabel,
} from "../../_utils/priceIndexUtils";
import { Field, useFormikContext } from "formik";
import { cloneDeep } from "lodash-es";
import { Input } from "_metronic/_partials/controls";
import { useTranslation } from "app/hooks/useTranslation";
import WarningMessage from "../WarningMessage";

interface PriceIndexInvoiceDetailsProps {
  baseLeadPriceIndex: IPriceIndex;
  priceIndices: IPriceIndex[];
  project: IProject;
}

export const PriceIndexInvoiceDetails: React.FunctionComponent<PriceIndexInvoiceDetailsProps> = ({
  baseLeadPriceIndex,
  priceIndices,
  project,
}) => {
  const [basePriceIndex, setBasePriceIndex] = React.useState<IPriceIndex>();

  const { fm } = useTranslation();

  const intl = useIntl();

  const { values, setValues } = useFormikContext<ILeadFinancialDocument>();

  const handleValueChangePriceIndex = (value: any) => {
    const res: any = cloneDeep(values);
    res.basePriceIndex = value;
    res.basePriceIndexId = value?.id;
    setValues(res);
  };

  React.useEffect(() => {
    const filteredPriceIndices = filterPriceIndicesProperDate(priceIndices, project);
    const bpi = baseLeadPriceIndex || getBasePriceIndex(project);

    if (filteredPriceIndices.length <= 1) {
      setBasePriceIndex(undefined);
    } else {
      handleValueChangePriceIndex(bpi);
      setBasePriceIndex(bpi);
    }
  }, [baseLeadPriceIndex, priceIndices, project]);

  return (
    <>
      <div className="d-flex flex-column">
        <label className="font-size-lg font-weight-bold">
          <FormattedMessage id="PRICE_INDEX.TITLE.SINGULAR" />
        </label>
        <label>
          <FormattedMessage
            id="INVOICE.PRICE_INDEX_INFO_MESSAGE"
            values={{
              guide: (
                <span
                  className="text-primary cursor-pointer"
                  style={{ textDecoration: "underline" }}
                  onClick={() => {
                    window.open(
                      fm("INVOICE.PRICE_INDEX_INFO_MESSAGE.LINK"),
                      "_blank",
                      "noopener, noreferrer"
                    );
                  }}
                >
                  <FormattedMessage id="COMMON.GUIDE" />
                </span>
              ),
            }}
          />
        </label>
      </div>
      <div className="row pt-1 mb-5 d-flex justify-content-between">
        <div className={cn(!basePriceIndex ? "col-lg-12" : "col-lg-6", "d-flex flex-column")}>
          {!basePriceIndex ? (
            <WarningMessage
              messageId="PRICE_INDEX.BASE_INDEX.WARNING"
              messageValues={{ projectOwner: project.projectOwner?.name }}
            />
          ) : (
            <>
              <FormattedMessage id="PRICE_INDEX.BASE_INDEX.TITLE" />
              <Field
                name="basePriceIndex"
                className="mt-1"
                value={getPriceIndexOptionLabel(basePriceIndex, priceIndices, basePriceIndex, intl)}
                component={Input}
                disabled={true}
              />
            </>
          )}
        </div>
        {basePriceIndex && (
          <div className="col-lg-6 d-flex flex-column">
            <FormattedMessage id="PRICE_INDEX.USED_INDEX.TITLE" />
            <PriceIndexSelect
              priceIndices={priceIndices}
              basePriceIndex={basePriceIndex}
              project={project}
            />
          </div>
        )}
      </div>
    </>
  );
};
