import * as React from "react";
import { MetricCard, MetricCardBase } from "./MetricCard";
import { NumberInput } from "../../_utils/formUtils";
import { NumberFormatProps } from "react-number-format";

export interface NumberMetricProps extends NumberFormatProps, Omit<MetricCardBase, "mainContent"> {
  dataCy?: string;
}

export const NumberMetricCard: React.FC<NumberMetricProps> = ({
  value,
  dataCy,
  fixedDecimalScale,
  decimalScale,
  suffix,
  children,
  ...props
}) => {
  return (
    <MetricCard
      {...props}
      mainContent={
        <NumberInput
          data-cy={dataCy}
          value={value}
          displayType="text"
          suffix={suffix}
          decimalScale={decimalScale}
          fixedDecimalScale={fixedDecimalScale}
          className="ml-auto font-weight-bold"
        />
      }
    >
      {children}
    </MetricCard>
  );
};
