import * as React from "react";
import {
  ExpectedInstalmentRevenues,
  ExpectedTotalRevenue,
  InstalmentInvoiceShare,
  UnitsOverview,
} from "./cards";

export const BudgetRevenueHeader: React.FC = () => {
  return (
    <div className={"row"}>
      <ExpectedTotalRevenue className={"gutter-b"} />
      <ExpectedInstalmentRevenues className={"gutter-b"} />
      <UnitsOverview className={"gutter-b"} />
      <InstalmentInvoiceShare className={"gutter-b"} />
    </div>
  );
};
