// https://github.com/cyntler/react-doc-viewer/blob/main/src/renderers/video/index.tsx#L5

import type { DocRenderer } from "@cyntler/react-doc-viewer";

export const CustomVideoRenderer: DocRenderer = ({ mainState: { currentDocument } }) => {
  if (!currentDocument) return null;

  return (
    <div id="video-renderer" style={{ width: "100%" }}>
      <video
        style={{ width: "100%", height: "100%", border: 0 }}
        controls
        src={currentDocument.uri}
      />
    </div>
  );
};

CustomVideoRenderer.fileTypes = [
  "video/mp4",
  "video/quicktime",
  "video/x-msvideo",
  "video/webm",
  "video/mpeg",
];
CustomVideoRenderer.weight = 1;

export default CustomVideoRenderer;
