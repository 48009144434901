import { FormattedMessage, useIntl } from "react-intl";
import React, { FC, useEffect, useRef, useState } from "react";
import { shallowEqual, useDispatch } from "react-redux";
import { Modal } from "react-bootstrap";
import { Menu, MenuItem, Typeahead } from "react-bootstrap-typeahead";
import { useAppSelector } from "../../redux/hooks";
import * as actionsUsers from "../../app/modules/UsersManagement/_redux/usersActions";
import { formatDisplayNameIntl } from "../_utils/userUtils";
import { UserDropdownItem } from "./UserDropdownItem";
import { toAbsoluteUrl } from "../../_metronic/_helpers";
import SVG from "react-inlinesvg";

interface Props {
  show: boolean;
  onHide: () => void;
  actions: any;
  photo: any;
  isLoading: boolean;
}

export const PhotoShareDialog: FC<Props> = ({ show, onHide, actions, photo, isLoading }) => {
  const intl = useIntl();
  const [filteredUsers, setFilteredUsers] = useState([]);
  const dispatch = useDispatch();
  const { users, session } = useAppSelector(
    (state) => ({
      users: state.users.entities,
      session: state.auth.session,
    }),
    shallowEqual
  );
  useEffect(() => {
    if (!photo?.id) {
      onHide();
    } else {
      dispatch(
        actionsUsers.fetchUsersByUserType({ userTypes: ["USER", "SUBCONTRACTOR", "CLIENT"] })
      );
    }
  }, [photo?.id]);
  useEffect(() => {
    setFilteredUsers(
      users.filter(
        (u: { id: string }) =>
          session?.id !== u.id &&
          !photo?.photoSharings?.find((ps: { userId: string }) => ps.userId === u.id)
      )
    );
  }, [users, setFilteredUsers, photo, isLoading]);

  const [newSharings, setNewSharings] = useState([]);
  const updatePhotoSharings = (photoSharings: any) => {
    setNewSharings(photoSharings);
  };

  const removeUser = (userId: string) => {
    dispatch(
      actions.removePhotoSharing({
        projectId: photo?.projectId,
        productId: photo?.productId,
        photoId: photo.id,
        userId,
      })
    );
  };

  const submitPhotoSharings = () => {
    for (const user of newSharings as any[]) {
      dispatch(
        actions.createPhotoSharing({
          projectId: photo?.projectId,
          productId: photo?.productId,
          photoId: photo?.id,
          userId: user.id,
        })
      );
    }
    setNewSharings([]);
  };

  const typeaheadRef = useRef<any>(null);

  return (
    <Modal show={show} onHide={onHide} centered size={"lg"} backdrop="static" scrollable>
      <Modal.Header closeButton>
        <Modal.Title>
          <FormattedMessage id={"PHOTO.SHARE"} />
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="pb-6">
        <div className="d-flex">
          <Typeahead
            id="share-typeahead-input"
            ref={typeaheadRef}
            paginate={false}
            multiple
            positionFixed
            labelKey={"displayName"}
            filterBy={["displayName", "email"]}
            selected={newSharings}
            onChange={(selected) => {
              updatePhotoSharings(selected);
            }}
            options={filteredUsers.map((u: { displayName: string }) => {
              const user = { ...u };
              user.displayName = formatDisplayNameIntl(intl, user, false);
              return user;
            })}
            placeholder={intl.formatMessage({ id: "COMMON.SELECT_USER" })}
            disabled={isLoading}
            className={"flex-grow-1"}
            renderMenu={(users, menuProps) => (
              <Menu {...menuProps}>
                {users.map((user, index) => (
                  <MenuItem option={user} position={index} key={index}>
                    <UserDropdownItem user={user} searchText={typeaheadRef?.current?.state?.text} />
                  </MenuItem>
                ))}
              </Menu>
            )}
          />
          <button
            type="button"
            onClick={submitPhotoSharings}
            disabled={isLoading}
            className="btn btn-primary btn-elevate ml-4 px-6"
          >
            <FormattedMessage id="COMMON.ACTION.SHARE" />
          </button>
        </div>
        {photo?.photoSharings?.length > 0 && users?.length > 0 && (
          <div className="pt-6">
            {photo?.photoSharings.map((ps: { userId: string }, index: number) => {
              const user = (users as any[]).find((u: { id: string }) => u.id === ps.userId);
              if (!user) {
                return <React.Fragment key={index} />;
              }
              return (
                <div
                  key={index}
                  className="d-flex align-items-center justify-content-between"
                  style={{ height: "50px" }}
                >
                  <UserDropdownItem user={user} />
                  <div className="ml-3">
                    <button
                      type="button"
                      onClick={() => removeUser(user?.id)}
                      className="btn btn-icon btn-light-danger btn-hover-danger btn-sm"
                    >
                      <span className="svg-icon svg-icon-md svg-icon-danger">
                        <SVG src={toAbsoluteUrl("/media/svg/icons/General/Trash.svg")} />
                      </span>
                    </button>
                  </div>
                </div>
              );
            })}
          </div>
        )}
      </Modal.Body>
      <Modal.Footer>
        <div>
          <button
            type="button"
            onClick={onHide}
            disabled={isLoading}
            className={`btn btn-light btn-elevate ml-4 ${
              isLoading && "spinner spinner-dark spinner-right"
            }`}
          >
            <FormattedMessage id="COMMON.ACTION.DONE" />
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};
