import { useTranslation } from "../../../hooks/useTranslation";
import * as React from "react";
import { KycFormElementBaseLayout } from "./controls/KycControlsBaseLayout";
import { Button, ButtonGroup } from "react-bootstrap";
import { KycFormSectionSub } from "./layout/KycFormSectionSub";
import { KycTranslation } from "../KycTranslation";
import cn from "clsx";
import { KycRiskLevel } from "../Kyc";
import { useWizard } from "../../../hooks/useWizard";
import { useKycFlow } from "../../../hooks/useKycFlow";

type KycRiskSelectionProps = {
  field?: any;
  className?: string;
  label: string;
  infoLabel?: string;
  onChange?: any;
  disableLabel?: boolean;
  disabled?: boolean;
};

export const KycRiskSelection = ({
  field,
  label,
  className,
  infoLabel,
  onChange,
  disabled = false,
  disableLabel = false,
}: KycRiskSelectionProps): JSX.Element => {
  const { fm } = useTranslation();
  const { isReadOnly } = useWizard();
  const { isFieldLocked } = useKycFlow();

  const handleRiskValue = (e: any, riskLevel: KycRiskLevel) => {
    e.preventDefault();
    if (!isRiskDisabled()) {
      onChange({
        target: {
          name: field.name,
          value: riskLevel,
        },
      });
    }
  };

  const isRiskDisabled = () => {
    return disabled || isReadOnly || isFieldLocked(field);
  };

  const mRiskLevelTranslation: { [key: string]: KycTranslation } = {
    [KycRiskLevel.WEAK]: KycTranslation.RISK_EVALUATION_RISK_LEVEL_WEAK,
    [KycRiskLevel.MEDIUM]: KycTranslation.RISK_EVALUATION_RISK_LEVEL_MEDIUM,
    [KycRiskLevel.HIGH]: KycTranslation.RISK_EVALUATION_RISK_LEVEL_HIGH,
  };

  const mRiskLevelColor: { [key: string]: string } = {
    [KycRiskLevel.WEAK]: "btn-success",
    [KycRiskLevel.MEDIUM]: "btn-warning",
    [KycRiskLevel.HIGH]: "btn-danger",
  };

  const content = (
    <div>
      <KycFormSectionSub label={label} indent={false} />
      <ButtonGroup className="d-flex mt-2 mb-4 mx-10">
        {[KycRiskLevel.WEAK, KycRiskLevel.MEDIUM, KycRiskLevel.HIGH].map((riskLevel) => (
          <Button
            key={riskLevel}
            type="button"
            disabled={isRiskDisabled()}
            className={cn(
              "btn",
              field.value === riskLevel ? `${mRiskLevelColor[riskLevel]}` : "btn-light"
            )}
            onClick={(e: any) => handleRiskValue(e, riskLevel)}
            style={{
              cursor: isRiskDisabled() ? "default" : "pointer",
              opacity: isRiskDisabled() ? 0.9 : 1,
            }}
          >
            {fm(mRiskLevelTranslation[riskLevel])}
          </Button>
        ))}
      </ButtonGroup>
    </div>
  );

  return (
    <>
      <KycFormElementBaseLayout
        disableLabel={disableLabel}
        field={field}
        content={content}
        disabled={false}
        isSectionChild={false}
      />
    </>
  );
};
