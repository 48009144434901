import { toAbsoluteUrl } from "_metronic/_helpers";

import { countryFlagByCodeMap } from "app/_utils/listUtils";

export const AVAILABLE_LANGUAGES = [
  {
    lang: "en-GB",
    name: "English",
    flag: toAbsoluteUrl(countryFlagByCodeMap.get("GB")),
    shortCode: "EN",
  },
  {
    lang: "fr-FR",
    name: "Français",
    flag: toAbsoluteUrl(countryFlagByCodeMap.get("FR")),
    shortCode: "FR",
  },
  {
    lang: "de-DE",
    name: "Deutsch",
    flag: toAbsoluteUrl(countryFlagByCodeMap.get("DE")),
    shortCode: "DE",
  },
];
