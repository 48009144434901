import * as React from "react";
import { ReactNode, useEffect } from "react";
import { isEmpty } from "lodash-es";
import { useKyc } from "../../../../hooks/useKyc";
import { useLocation } from "react-router";
import { KycComplianceTable } from "./KycComplianceTable";
import { useLoading } from "../../../../hooks/useLoading";
import { KycLoading } from "../../Kyc";
import { LoaderSpinner } from "../../../../_components/LoaderSpinner";
import { KycStartFlowAction } from "./start-kyc-flow/KycStartFlowAction";
import { SearchProvider } from "../../../../contexts/SearchContext";
import { SearchLocal } from "../../../../_components/Search/SearchLocal";
import { useKycFlowUIContext } from "../../../../hooks/useKycFlowUIContext";

interface KycComplianceContainerProps {
  userId: string;
  children?: ReactNode;
}

export const KycComplianceContainer = ({ userId }: KycComplianceContainerProps): JSX.Element => {
  const { getKycByUserId, currentKycFlows } = useKyc();
  const { isCurrentlyLoading } = useLoading();

  useEffect(() => {
    if (userId && isEmpty(currentKycFlows)) {
      getKycByUserId(userId);
    }
  }, [userId]);

  const location = useLocation();

  const getContent = () => {
    const queryParams = new URLSearchParams(location.search);
    const t = queryParams.get("t");

    if (t) {
      return isCurrentlyLoading(KycLoading.LIST_KYC_FOR_USER_ID) ? (
        <LoaderSpinner
          style={{ height: "20vh" }}
          className="justify-content-center align-items-center"
        />
      ) : (
        <SearchProvider>
          <div className={"d-flex mb-4 flex-column-reverse flex-sm-row"}>
            <SearchLocal useEntityUIContext={useKycFlowUIContext} />
            <KycStartFlowAction cId={userId} />
          </div>
          <KycComplianceTable userId={userId} />
        </SearchProvider>
      );
    }
  };

  return (
    <>
      <>{getContent()}</>
    </>
  );
};
