import React from "react";
import { useFormikContext } from "formik";
import DatePicker from "react-datepicker";
import { useIntl } from "react-intl";
import { createPortal } from "react-dom";
import { get } from "lodash-es";
import { FieldFeedbackLabel } from "./FieldFeedbackLabel";

const getFieldCSSClasses = (touched, errors) => {
  const classes = ["form-control"];
  if (touched && errors) {
    classes.push("is-invalid-select");
  }
  return classes.join(" ");
};

export function DatePickerField({ field, customHandleChange, className, withFeedbackLabel = true, label, type = "datepicker", customFeedbackLabel, ...props }) {
  const intl = useIntl();
  if (field.value) {
    field.value = (new Date(field.value)).getTime();
  }
  const { setFieldValue, handleChange, errors, touched } = useFormikContext();
  return (
    <>
      {label && <label className={"mr-2"}>{label}</label>}
      <DatePicker
        className={
          getFieldCSSClasses(
            get(touched, field.name),
            get(errors, field.name)
          ) +
          " " +
          className
        }
        {...field}
        locale={intl.locale}
        showTimeSelect
        popperContainer={({ children }) => createPortal(children, document.body)}
        popperPlacement="top"
        popperClassName="custom-popper-zindex"
        timeIntervals={10}
        dateFormat="Pp"
        selected={(field.value && new Date(field.value)) || undefined}
        onChange={(val) => {
          if (!!customHandleChange) {
            customHandleChange({
              target: { name: field.name, value: val }
            })(handleChange);
          } else {
            setFieldValue(field.name, val);
          }
        }}
        {...props}
      />
      {withFeedbackLabel && !props.disabled && (
        <FieldFeedbackLabel
          error={get(errors, field.name)}
          touched={get(touched, field.name)}
          value={field.value}
          label={label}
          type={type}
          customFeedbackLabel={customFeedbackLabel}
        />
      )}
    </>
  );
}
