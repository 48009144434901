import { useTranslation } from "../../../../hooks/useTranslation";
import cn from "clsx";

type KycFormSectionProps = {
  label: string;
  className?: string;
  additionalText?: string;
};

export const KycFormTitle = ({
  label,
  className,
  additionalText,
}: KycFormSectionProps): JSX.Element => {
  const { fm } = useTranslation();

  return (
    <>
      <h3 className={cn("pb-8 pt-5", className)}>
        {fm(label)} {additionalText}
      </h3>
    </>
  );
};
