import { AsyncThunk, createAction, createReducer } from "@reduxjs/toolkit";

type GenericAsyncThunk = AsyncThunk<unknown, unknown, any>;

type PendingAction = ReturnType<GenericAsyncThunk["pending"]>;
type RejectedAction = ReturnType<GenericAsyncThunk["rejected"]>;
type FulfilledAction = ReturnType<GenericAsyncThunk["fulfilled"]>;

const initialState: Record<string, any> = {
  loaders: {},
};
const resetAction = createAction("reset-tracked-loading-state");

export const uiReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(resetAction, () => initialState)
    .addMatcher<PendingAction>(
      (action) => action.type.endsWith("/pending"),
      (state, action) => {
        state.loaders[action.meta.requestId] = action.type.replace("/pending", "");
      }
    )
    .addMatcher<RejectedAction>(
      (action) => action.type.endsWith("/rejected"),
      (state, action) => {
        delete state.loaders[action.meta.requestId];
      }
    )
    .addMatcher<FulfilledAction>(
      (action) => action.type.endsWith("/fulfilled"),
      (state, action) => {
        delete state.loaders[action.meta.requestId];
      }
    );
});
