import React, { useEffect, useState } from "react";
import { NotificationTypes } from "../../../_utils/notificationTypes";
import { useKycFlow } from "../../../hooks/useKycFlow";
import { KycDatePicker } from "./controls/kyc-date-picker/KycDatePicker";
import { KycTranslation } from "../KycTranslation";
import { Field } from "formik";
import { KycFormElementBaseLayout } from "./controls/KycControlsBaseLayout";
import { KycInfoBox } from "./KycInfoBox";
import { NotificationButtons } from "../../../_components/Notification";

interface KycConfirmationModalProps {
  field: any;
  isDeadline: boolean;
  disabled?: boolean;
  disableLabel?: boolean;
  label?: string;
  infoLabel?: string;
}

export const KycNotificationWidget = ({
  field,
  isDeadline = false,
  disabled = false,
  disableLabel = false,
  label = "COMMON.ACTION.NOTIFY_USER_BY",
  infoLabel,
}: KycConfirmationModalProps): JSX.Element => {
  const [notifyMethods, setNotifyMethods] = useState<Array<NotificationTypes>>([]);
  const { customHandleChange } = useKycFlow();

  useEffect(() => {
    updateNotifyMethods(true, NotificationTypes.EMAIL);
  }, []);

  const updateNotifyMethods = (isChecked: any, type: NotificationTypes) => {
    let updatedMethods: NotificationTypes[] = [...notifyMethods];
    if (isChecked) {
      updatedMethods.push(type);
    } else {
      updatedMethods = updatedMethods.filter((value) => value !== type);
    }

    customHandleChange({ target: { name: `${field.name}.methodsUsed`, value: updatedMethods } });
    setNotifyMethods(updatedMethods);
  };

  const content = (
    <div className="d-flex justify-content-left pb-2">
      <div className="col-md-9 col-lg-7">
        <div className="d-flex flex-nowrap">
          <NotificationButtons
            notifyMethods={field.value.methodsUsed}
            setNotifyMethods={(options) =>
              customHandleChange({ target: { name: `${field.name}.methodsUsed`, value: options } })
            }
          />
        </div>
      </div>
    </div>
  );

  return (
    <>
      <KycFormElementBaseLayout
        disableLabel={disableLabel}
        content={content}
        disabled={disabled}
        label={label}
      />
      {infoLabel && <KycInfoBox label={infoLabel} />}
      {isDeadline && (
        <Field
          className="form-control react-datepicker-ignore-onclickoutside no-validation"
          name={`${field.name}.deadline`}
          component={KycDatePicker}
          label={KycTranslation.DEADLINE}
          minDate={new Date()}
          withFeedbackLabel={false}
          showTimeSelect={true}
          dateFormat="P"
          popperPlacement="bottom-start"
          autoComplete="off"
        />
      )}
    </>
  );
};
