import { createSlice } from "@reduxjs/toolkit";
import { v4 as uuid } from "uuid";

const initialSnackBarNotificationState = {
  list: {},
};

export const snackBarNotificationsSlice = createSlice({
  name: "snackBarNotifications",
  initialState: initialSnackBarNotificationState,
  reducers: {
    addNotification: (state, action) => {
      const id = action.payload.notification.id ?? uuid();
      state.list[id] = {
        id,
        show: false,
        deleted: false,
        ...action.payload.notification,
      };
    },
    removeNotification: (state, action) => {
      delete state.list[action.payload?.notification?.id];
    },
    showNotification: (state, action) => {
      state.list[action.payload?.notification?.id].show = true;
    },
    hideNotification: (state, action) => {
      state.list[action.payload?.notification?.id].show = false;
      state.list[action.payload?.notification?.id].deleted = true;
    },
  },
});
