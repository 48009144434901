import * as React from "react";
import * as Yup from "yup";
import { Field, Form, Formik } from "formik";
import { forgotPasswordSubmit } from "../_redux/authCrud";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import { FormattedMessage, useIntl } from "react-intl";
import { Input } from "../../../../_metronic/_partials/controls";
import { Link, useHistory } from "react-router-dom";
import { useAuth } from "../../../v2/contexts";
import { useState } from "react";

interface InfoAboutPerson {
  AttributeName: string;
  DeliveryMedium: string;
  Destination: string;
  email: string;
}

const initialValuesChangePassword = {
  code: "",
  password: "",
  confirmPassword: "",
};

export const ForgotPasswordConfirmNewPassword = () => {
  const intl = useIntl();
  const history = useHistory();
  const { resetApplicationInitialized } = useAuth();
  const [isConfirming, setIsConfirming] = useState<boolean>(false);
  const [infoAboutPerson, setInfoAboutPerson] = React.useState<InfoAboutPerson>();
  const [errorMessage, setErrorMessage] = React.useState<string | undefined>(undefined);

  React.useEffect(() => {
    const localStorageInfo = localStorage.getItem("forgot-password");
    const infoParse = localStorageInfo ? JSON.parse(localStorageInfo) : {};
    infoParse &&
    infoParse.value &&
    infoParse.expDate &&
    new Date().getTime() < new Date(infoParse.expDate).getTime()
      ? setInfoAboutPerson(infoParse.value)
      : history.push("/auth/forgot-password");
  }, []);

  const ChangePasswordSchema = Yup.object().shape({
    code: Yup.string().required(intl.formatMessage({ id: "AUTH.VALIDATION.REQUIRED_FIELD" })),
    password: Yup.string()
      .required(intl.formatMessage({ id: "AUTH.VALIDATION.REQUIRED_FIELD" }))
      .min(8, `${intl.formatMessage({ id: "AUTH.VALIDATION.MIN_CHARACTER" })} 8`)
      .lowercase()
      .uppercase()
      .matches(
        RegExp(/\d/),
        intl.formatMessage({ id: "AUTH.VALIDATION.MIN_NUMBER" }, { value: "1" })
      ),
    passwordConfirmation: Yup.string().required(
      intl.formatMessage({ id: "AUTH.VALIDATION.REQUIRED_FIELD" })
    ),
  });
  return (
    <div className="login-form login-forgot" style={{ display: "block" }}>
      <div className="text-center mb-10 mb-lg-20">
        <h3 className="font-size-h1">
          <FormattedMessage id="PROFILE.PASSWORD.NEW" />
        </h3>
        <div className="text-muted font-weight-bold">
          <FormattedMessage id="PROFILE.PASSWORD.NEW.ENTER" />
        </div>
      </div>
      <Formik
        enableReinitialize={true}
        initialValues={initialValuesChangePassword}
        validationSchema={ChangePasswordSchema}
        onSubmit={(values) => {
          setErrorMessage(undefined);
          setIsConfirming(true);
          forgotPasswordSubmit(infoAboutPerson?.email, values.code, values.password) //email
            .then(() => {
              history.push("/auth/forgot-password-successfully-changed");
              localStorage.removeItem("forgot-password");
            })
            .catch((error) => {
              let errorMessageKey;
              switch (error.code) {
                case "LimitExceededException":
                  errorMessageKey = "AUTH.FORGOT_PASSWORD.ERROR.LIMIT_EXCEEDED";
                  break;
                case "CodeMismatchException":
                  errorMessageKey = "AUTH.FORGOT_PASSWORD.ERROR.CODE_MISMATCH";
                  break;
                default:
                  errorMessageKey = "AUTH.FORGOT_PASSWORD.ERROR";
                  break;
              }
              setErrorMessage(intl.formatMessage({ id: errorMessageKey }));
            })
            .finally(() => {
              setIsConfirming(false);
              resetApplicationInitialized();
            });
        }}
      >
        {() => (
          <Form className="form">
            {errorMessage && (
              <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
                <div className="alert-text font-weight-bold">
                  <FormattedMessage id={errorMessage} />
                </div>
              </div>
            )}
            <div className="mb-5 font-size-lg">
              <span className="svg-icon svg-icon-lg svg-icon-info mr-1">
                <SVG src={toAbsoluteUrl("/media/svg/icons/Code/info-circle.svg")} />
              </span>
              {infoAboutPerson?.DeliveryMedium === "EMAIL" ? (
                <FormattedMessage
                  id="AUTH.FORGOT_PASSWORD.CHECK_EMAIL"
                  values={{ destination: infoAboutPerson?.Destination }}
                />
              ) : (
                <FormattedMessage
                  id="AUTH.FORGOT_PASSWORD.CHECK_SMS"
                  values={{ destination: infoAboutPerson?.Destination }}
                />
              )}
            </div>
            <div className="form-group fv-plugins-icon-container">
              <div className="form-group">
                <Field
                  name="code"
                  component={Input}
                  className="form-control-lg form-control-solid h-auto py-5 px-6"
                  label={intl.formatMessage({ id: "AUTH.CONFIRMATION_CODE" })}
                />
              </div>
              <div className="form-group">
                <Field
                  name="password"
                  component={Input}
                  type="password"
                  className="form-control-lg form-control-solid h-auto py-5 px-6"
                  label={intl.formatMessage({ id: "AUTH.PASSWORD" })}
                />
              </div>
            </div>
            <div className="form-group">
              <Field
                name="passwordConfirmation"
                component={Input}
                type="password"
                className="form-control-lg form-control-solid h-auto py-5 px-6"
                label={intl.formatMessage({ id: "AUTH.PASSWORD.REPEAT" })}
              />
            </div>
            <div className="form-group d-flex flex-wrap flex-center">
              <Link to="/auth">
                <button
                  type="button"
                  id="kt_login_forgot_cancel"
                  className="btn btn-light-primary font-weight-bold px-9 py-4 my-3 mx-4"
                >
                  <FormattedMessage id="COMMON.ACTION.CANCEL" />
                </button>
              </Link>
              <button
                id="kt_login_forgot_submit"
                type="submit"
                className="btn btn-primary font-weight-bold px-9 py-4 my-3 mx-4"
                disabled={isConfirming}
              >
                <span>
                  <FormattedMessage id="COMMON.ACTION.SUBMIT" />
                </span>
                {isConfirming && <span className="ml-3 spinner spinner-white" />}
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};
