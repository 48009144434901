/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { BRAND_URL } from "../../../../configuration";

export function EmptyWidget({ className }) {
  const backgroundImageUrl = BRAND_URL + "/empty_card.png";

  return (
    <>
      <div
        className={`card card-custom bgi-no-repeat bgi-size-cover ${className}`}
        style={{
          backgroundPosition: "center",
          backgroundImage: `url("${backgroundImageUrl}")`,
        }}
      ></div>
    </>
  );
}
