import React from "react";
import { useLanguage } from "./Metronici18n";
import { IntlProvider } from "react-intl";
import "@formatjs/intl-displaynames/polyfill";
import "@formatjs/intl-displaynames/locale-data/en";
import "@formatjs/intl-displaynames/locale-data/fr";
import "@formatjs/intl-displaynames/locale-data/de";
import "@formatjs/intl-relativetimeformat/polyfill";
import "@formatjs/intl-relativetimeformat/locale-data/en";
import "@formatjs/intl-relativetimeformat/locale-data/fr";
import "@formatjs/intl-relativetimeformat/locale-data/de";

import enMessages from "./messages/en-GB";
import frMessages from "./messages/fr-FR";
import deMessages from "./messages/de-DE";

import * as frYup from "./yup/fr";
import * as deYup from "./yup/de";
import { setLocale } from "yup";


const allMessages = {
  "en-GB": enMessages,
  "fr-FR": frMessages,
  "de-DE": deMessages,
};

const yupMessages = {
  "fr-FR": frYup,
  "de-DE": deYup
}

export function I18nProvider({ children }) {
  const { selectedLanguage:locale } = useLanguage();

  !!yupMessages[locale] && setLocale(yupMessages[locale])

  const messages = allMessages[locale];

  return (
    <IntlProvider locale={locale} messages={messages} onError={(err) => {
      console.warn(err);
    }}>

      {children}
    </IntlProvider>
  );
}
