import { Modal, Spinner } from "react-bootstrap";
import { useTranslation } from "../../../hooks/useTranslation";
import React, { useEffect, useState } from "react";
import { useLoading } from "../../../hooks/useLoading";
import { KycLoading } from "../Kyc";
import { useWebsocket } from "../../../hooks/useWebsocket";

export interface KycPackageDownloadDialogConfigProps {
  showModal: boolean;
  labelTitle: string;
  labelContent: string;
  labelLoading: string;
  progressMessageContent: string;
  isLoading: KycLoading;
  isLoadingData?: KycLoading;
}

export interface KycPackageDownloadDialogProps {
  config: KycPackageDownloadDialogConfigProps;
}

export const KycPackageDownloadDialog = ({
  config,
}: KycPackageDownloadDialogProps): JSX.Element => {
  const { fm } = useTranslation();
  const { isCurrentlyLoading } = useLoading();
  const { latestMessage } = useWebsocket();

  const [progress, setProgress] = useState<number>(0);

  const { showModal, isLoading, labelTitle, progressMessageContent, labelLoading } = config;

  useEffect(() => {
    if (
      latestMessage?.type === "KYC_ZIP_PACKAGE_IN_PROGRESS" &&
      latestMessage?.parameters?.progress
    ) {
      setProgress(latestMessage?.parameters?.progress);
    }
  }, [latestMessage]);

  return (
    <Modal show={showModal || isCurrentlyLoading(isLoading)} centered backdrop="static" size="lg">
      <Modal.Header closeButton>
        <Modal.Title id="example-modal-sizes-title-lg">{labelTitle && fm(labelTitle)}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {isCurrentlyLoading(isLoading) && (
          <div className="d-flex align-items-center">
            {progressMessageContent && progress > 0
              ? fm(progressMessageContent, {
                  progress: progress,
                })
              : labelLoading && <span className="visually-hidden">{fm(labelLoading)}...</span>}
            <Spinner
              as="span"
              animation="border"
              size="sm"
              role="status"
              aria-hidden="true"
              className="ml-4"
              variant="primary"
            />
          </div>
        )}
      </Modal.Body>
    </Modal>
  );
};
