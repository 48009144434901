import { API } from "@aws-amplify/api";

export async function createBudget(budget) {
  const body = { ...budget };
  return await API.post("API", "/budgets", { body });
}

export async function updateBudget(budget) {
  const body = { ...budget };
  return await API.post("API", `/budgets/${budget.id}`, { body });
}

export async function getBudgetById(id) {
  return await API.get("API", `/budgets/${id}`, {});
}

export async function findBudgets(queryParams) {
  const response = {
    data: {
      items: [],
    },
  };
  if (queryParams?.projectId) {
    const apiResponse = await API.get("API", "/budgets", {
      queryStringParameters: {
        projectId: queryParams.projectId,
      },
    });
    console.log("apiResponse:", apiResponse);
    response.data.items = apiResponse;
  } else {
    const apiResponse = await API.get("API", "/budgets", {});
    console.log("apiResponse:", apiResponse);
    response.data.items = apiResponse;
  }
  return response;
}

export async function deleteBudget(id) {
  return await API.del("API", `/budgets/${id}`, {});
}
