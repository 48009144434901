import * as React from "react";
import {
  ColumnDef,
  ExpandedState,
  getCoreRowModel,
  getExpandedRowModel,
  RowData,
  useReactTable,
} from "@tanstack/react-table";
import "./CardTable.scss";
import { TRowTheme } from "./CardTableThemes";
import { CardTableItem, CardTableProvider } from "./context/CardTableContext";
import { CardTableComponent } from "./CardTableComponent";
import { CardTableActionsProps } from "./components/CardTableActions/CardTableActions";
import { diff } from "deep-object-diff";

declare module "@tanstack/table-core" {
  // eslint-disable-next-line
  interface ColumnMeta<TData extends RowData, TValue> {
    customWidth?: string;
    minWidth?: number;
    fixed?: boolean;
    label?: string;
    defaultHidden?: boolean;
  }
}

export interface CardTablePropsBase extends CardTableActionsProps {
  rowTheme?: TRowTheme; // Row theme can either be set globally or specified row by row
  multiCardLayout?: boolean;
  innerRef?: React.Ref<HTMLDivElement>;
}

export interface CardTableProps<T> extends CardTablePropsBase {
  columns: ColumnDef<T, any>[];
  data: T[];
  id: string;
}

export const CardTable = <T extends CardTableItem>({
  data,
  columns,
  id,
  ...props
}: CardTableProps<T>) => {
  const flattenData = React.useCallback((data: T[]) => {
    let stack = [...data];
    let result: T[] = [];
    while (stack.length > 0) {
      const row = stack.pop();
      if (row) {
        result.push(row);
        if (Array.isArray(row.children)) {
          stack = [...stack, ...row.children];
        }
      }
    }
    return result;
  }, []);

  const defaultExpanded = React.useMemo(() => {
    let acc: ExpandedState = {};
    for (const row of flattenData(data)) {
      if (row.defaultExpanded) {
        acc[row.id] = true;
      }
    }
    return acc;
  }, [data]);

  const prevDefaultExpanded = React.useRef<ExpandedState>({});

  const [expanded, setExpanded] = React.useState<ExpandedState>(defaultExpanded);

  React.useEffect(() => {
    if (expanded !== true) {
      // When using the expand all button, the value of the expanded state is "true"
      const newDefaultExpandedItems = diff(prevDefaultExpanded.current as object, defaultExpanded);
      setExpanded({ ...(expanded as object), ...newDefaultExpandedItems });
      prevDefaultExpanded.current = defaultExpanded;
    }
  }, [defaultExpanded]);

  const hiddenColumnsStorageKey = id + "_HIDDEN_COLUMNS";

  const defaultHidden: any = React.useMemo(() => {
    const storedHiddenColumns = JSON.parse(localStorage.getItem(hiddenColumnsStorageKey) ?? "{}");
    for (const column of columns) {
      if (column.meta?.defaultHidden && !storedHiddenColumns.hasOwnProperty(column.id!)) {
        storedHiddenColumns[column.id!] = false;
      }
    }
    return storedHiddenColumns;
  }, [columns]);

  const [columnVisibility, setColumnVisibility] = React.useState(defaultHidden);

  const table = useReactTable<T>({
    data,
    columns,
    state: {
      expanded,
      columnVisibility,
    },
    onExpandedChange: setExpanded,
    onColumnVisibilityChange: setColumnVisibility,
    getSubRows: (row) => row.children,
    getCoreRowModel: getCoreRowModel(),
    getRowId: (row) => row.id,
    getExpandedRowModel: getExpandedRowModel(),
  });
  return (
    <CardTableProvider table={table} hiddenColumnsStorageKey={hiddenColumnsStorageKey}>
      <CardTableComponent {...props} />
    </CardTableProvider>
  );
};
