import React, { createContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { KycStep } from "app/modules/kyc-v2/Kyc";
import { getStepsForFlowDefinition } from "../modules/kyc-v2/KycUtils";
import { WIZARD_ORIENTATION } from "../_components/wizard/Wizard";

interface WizardProps {
  children?: React.ReactNode;
}

export interface StepsContent {
  id: KycStep;
  title: string;
}

export interface WizardContextValue {
  resetWizard: () => void;

  flowDefinition?: any[];
  setFlowDefinition: (flowDefinition: any[]) => void;

  stepperOrientation: WIZARD_ORIENTATION;
  setStepperOrientation: (orientation: WIZARD_ORIENTATION) => void;

  isReadOnly: boolean;
  setIsReadOnly: (value: boolean) => void;

  isExpanded: boolean;
  setIsExpanded: (isExpanded: boolean) => void;

  activeStep: number;
  setActiveStep: (prevActiveStep: number) => void;

  steps: StepsContent[];
  setSteps: (steps?: any[]) => void;

  nextStep: () => void;
  previousStep: () => void;

  disableNext: boolean;
  setDisableNext: (disableNext: boolean) => void;

  currentStepId: () => string;

  showControls: boolean;
  setShowControls: (showControlValue: boolean) => void;
}

export const WizardContext = createContext<WizardContextValue>({
  resetWizard: () => undefined,

  flowDefinition: undefined,
  setFlowDefinition: (flowDefinition) => undefined,

  stepperOrientation: WIZARD_ORIENTATION.HORIZONTAL,
  setStepperOrientation: (orientation) => undefined,

  isReadOnly: false,
  setIsReadOnly: (value: boolean) => undefined,

  isExpanded: false,
  setIsExpanded: (isExpanded) => undefined,

  activeStep: 0,
  setActiveStep: (activeStep) => undefined,

  disableNext: false,
  setDisableNext: (disableNext) => undefined,

  steps: [],
  setSteps: (steps) => undefined,

  nextStep: () => undefined,
  previousStep: () => undefined,

  currentStepId: () => "",

  showControls: false,
  setShowControls: (showFooter) => undefined,
});

export const WizardProvider: React.FunctionComponent<WizardProps> = (props) => {
  const { children } = props;

  const [flowDefinition, setFlowDefinitionInternal] = useState<KycStep[]>();

  const [showControls, setShowControlsInternal] = useState<boolean>(false);
  const [activeStep, setActiveStepInternal] = useState(0);
  const [steps, setStepsInternal] = useState<StepsContent[]>([]);
  const [disableNext, setDisableNextInternal] = useState<boolean>(false);
  const [isExpanded, setIsExpandedInternal] = useState<boolean>(false);

  const [isReadOnly, setIsReadOnlyInternal] = useState<boolean>(false);

  const [stepperOrientation, setStepperOrientationInternal] = useState<WIZARD_ORIENTATION>(
    WIZARD_ORIENTATION.HORIZONTAL
  );

  useEffect(() => {
    if (flowDefinition) {
      setSteps(getStepsForFlowDefinition(flowDefinition));
    }
  }, [flowDefinition]);

  const resetWizard = () => {
    setActiveStep(0);
  };

  const setFlowDefinition = (flowDefinition: any) => {
    setFlowDefinitionInternal(flowDefinition);
  };

  const setActiveStep = (indexStep: any) => {
    setActiveStepInternal(indexStep);
  };

  const setSteps = (steps?: any[]) => {
    if (steps) setStepsInternal(steps);
  };

  const previousStep = () => {
    if (activeStep - 1 >= 0) {
      setActiveStepInternal(activeStep - 1);
    }
  };

  const nextStep = () => {
    if (activeStep + 1 <= steps.length - 1) {
      setActiveStep(activeStep + 1);
    }
  };

  const setDisableNext = (disableNext: boolean) => {
    setDisableNextInternal(disableNext);
  };

  const currentStepId = () => {
    return steps?.[activeStep]?.id;
  };

  const setStepperOrientation = (orientation: WIZARD_ORIENTATION) => {
    setStepperOrientationInternal(orientation);
  };

  const setIsExpanded = (isExpanded: boolean) => {
    setIsExpandedInternal(isExpanded);
  };

  const setShowControls = (showControls: boolean) => {
    setShowControlsInternal(showControls);
  };

  const setIsReadOnly = (value: boolean) => {
    setIsReadOnlyInternal(value);
  };

  return (
    <WizardContext.Provider
      value={{
        resetWizard,
        flowDefinition,
        setFlowDefinition,
        isReadOnly,
        setIsReadOnly,
        activeStep,
        setActiveStep,
        steps,
        setSteps,
        previousStep,
        disableNext,
        nextStep,
        setDisableNext,
        currentStepId,
        stepperOrientation,
        setStepperOrientation,
        isExpanded,
        setIsExpanded,
        showControls,
        setShowControls,
      }}
    >
      {children}
    </WizardContext.Provider>
  );
};

WizardProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export const WizardConsumer = WizardContext.Consumer;
