import * as React from "react";
import {
  NumberMetricCardSubMetric,
  NumberMetricCardSubValueProps,
} from "./NumberMetricCardSubMetric";
import { SUFFIX_PERCENTAGE } from "../../../_utils/suffixUtils";
import { isNumber } from "lodash-es";

export const PercentageMetricCardSubMetric: React.FC<NumberMetricCardSubValueProps> = ({
  secondValue,
  decimalScale = 2,
  fixedDecimalScale = true,
  suffix = SUFFIX_PERCENTAGE,
  ...props
}) => {
  return (
    <NumberMetricCardSubMetric
      {...props}
      suffix={suffix}
      decimalScale={decimalScale}
      fixedDecimalScale={fixedDecimalScale}
      secondValue={
        !!secondValue && {
          value: isNumber(secondValue) ? secondValue : secondValue?.value,
          suffix: SUFFIX_PERCENTAGE,
          decimalScale: 0,
          ...(isNumber(secondValue) ? {} : secondValue),
        }
      }
    />
  );
};
