import * as React from "react";
import { ReactNode, useEffect } from "react";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../../_metronic/_helpers";
import { KycTranslation } from "../../KycTranslation";
import { KycCenteredStepBodyLayout } from "./KycCenteredStepBodyLayout";
import { useTranslation } from "../../../../hooks/useTranslation";
import { useWizard } from "../../../../hooks/useWizard";

export const KycStepSuccess = (): JSX.Element => {
  const { setActiveStep } = useWizard();
  const { fm } = useTranslation();

  type KycStepSuccessLayoutContainerProps = {
    message: string;
    children?: ReactNode;
  };

  useEffect(() => {
    setActiveStep(0);
  }, []);

  const KycStepSuccessLayoutContainer = ({
    message,
    children,
  }: KycStepSuccessLayoutContainerProps): JSX.Element => {
    return (
      <div className="d-flex flex-column align-items-center">
        <span className="svg-icon svg-icon-9x svg-icon-success mb-4">
          <SVG src={toAbsoluteUrl("/media/svg/icons/Code/Done-circle.svg")} />
        </span>
        <h3 className="text-center">{fm(message)}</h3>
        {children}
      </div>
    );
  };

  return (
    <KycCenteredStepBodyLayout>
      <KycStepSuccessLayoutContainer
        message={KycTranslation.SUCCESS_SCREEN_KYC_CLIENT_FORM_COMPLETED}
      />
    </KycCenteredStepBodyLayout>
  );
};
