import * as React from "react";
import { useIntl } from "react-intl";
import { useDidMount } from "rooks";
import cn from "clsx";

// https://ericgio.github.io/react-bootstrap-typeahead/
import { Menu, MenuItem, Typeahead } from "react-bootstrap-typeahead";

import { IUserOption } from "data/schemas";

import { TooltipHandler } from "app/_components/TooltipHandler";
import { UserDropdownItem } from "app/_components/UserDropdownItem";

import "./SelectAvailableUser.scss";

export type TOnConfirmClick = (selectedUsers: IUserOption[]) => void;

export interface SelectAvailableUserProps {
  isLoading?: boolean;
  className?: string;

  users: IUserOption[];

  onConfirmClick: TOnConfirmClick;
  onCancelClick: () => void;
}

export const SelectAvailableUser: React.FunctionComponent<SelectAvailableUserProps> = ({
  isLoading = false,
  className,
  users,
  onConfirmClick,
  onCancelClick,
}) => {
  const intl = useIntl();

  const typeaheadRef = React.useRef<Typeahead<IUserOption>>(null);

  const [selectedUsers, setSelectedUsers] = React.useState<IUserOption[]>([]);

  const hasAnySelectedUser = selectedUsers.length > 0;

  //--------------------------------------------------------------------------//

  const processSelectedUsers = (users: IUserOption[]) => {
    setSelectedUsers(
      users.map((userOption) => {
        if (userOption.customOption) {
          const { displayName } = userOption;

          return {
            displayName,
            firstName: "",
            lastName: "",
            email: "",
            mobile: "",
          } as IUserOption;
        }

        return userOption;
      })
    );
  };

  //--------------------------------------------------------------------------//

  const confirmClickHandler = React.useCallback(() => {
    selectedUsers.length > 0 && onConfirmClick(selectedUsers);
  }, [selectedUsers]);

  useDidMount(() => {
    const { current: typehead } = typeaheadRef;
    typehead && typehead.focus();
  });

  return (
    <div className={cn("select-available-user", className)}>
      <div className="select" data-cy="typeahead-request-signature-target-user">
        <Typeahead
          id="request-signature-typeahead-input"
          dropup
          positionFixed
          multiple
          ref={typeaheadRef}
          paginate={false}
          allowNew
          labelKey="displayName"
          filterBy={["displayName", "email", "mobile"]}
          onChange={processSelectedUsers}
          options={users}
          placeholder={intl.formatMessage({ id: "COMMON.SELECT_USER" })}
          disabled={isLoading}
          renderMenu={(users, menuProps, state) => (
            <Menu {...menuProps}>
              {users.map((user, index) => (
                <MenuItem option={user} position={index} key={index}>
                  <UserDropdownItem
                    user={user}
                    searchText={state.text}
                    showMobile
                    newLabelId="USER.EXTERNAL.TITLE.SINGLE"
                  />
                </MenuItem>
              ))}
            </Menu>
          )}
        />
      </div>
      <div className="actions">
        <TooltipHandler
          disabled={!hasAnySelectedUser}
          id="confirm-add-request-tooltip"
          message={intl.formatMessage({ id: "COMMON.ACTION.ADD" })}
        >
          <button
            data-cy="confirm-add-request"
            className="btn btn-icon btn-light btn-hover-secondary btn-sm"
            type="button"
            onClick={confirmClickHandler}
            disabled={!hasAnySelectedUser}
          >
            <i className="fas fa-check icon-md" />
          </button>
        </TooltipHandler>

        <TooltipHandler
          id="cancel-add-request-tooltip"
          message={intl.formatMessage({ id: "COMMON.ACTION.CANCEL" })}
        >
          <button
            data-cy="cancel-add-request"
            className="btn btn-icon btn-light btn-hover-secondary btn-sm"
            type="button"
            onClick={onCancelClick}
          >
            <i className="fas fa-times icon-md" />
          </button>
        </TooltipHandler>
      </div>
    </div>
  );
};

export default SelectAvailableUser;
