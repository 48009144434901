export enum TasksContentViewMode {
  COLUMNS = "columns",
  GANTT_CHART = "gantt-chart",
}

export type TTasksContentViewMode = `${TasksContentViewMode}`;

export interface TasksSwitchContentViewProps {
  className?: string;
  mode?: TTasksContentViewMode;
  onChange: (mode: TTasksContentViewMode) => void;
}
