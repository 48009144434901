import { ITask } from "data/schemas";

export const tasksWithSubtasksUtils = (tasks: ITask[]) => {
  let { list, taskMap, tasksToProcess } = tasks
    .map((task) => ({ ...task, subtasks: [] }))
    .reduce(
      (acc, task) => {
        const { list, taskMap, tasksToProcess } = acc;

        const { parentId } = task;

        if (!parentId) {
          const newTask = { ...task };
          list.push(newTask);
          taskMap[task.id!] = newTask;
        } else {
          if (taskMap.hasOwnProperty(parentId)) {
            const { subtasks, relatedTo } = taskMap[parentId];
            if (!task.relatedTo) {
              task.relatedTo = relatedTo;
            }
            taskMap[parentId].subtasks = [...subtasks, task];
          } else {
            tasksToProcess.push(task);
          }
        }

        return { list, taskMap, tasksToProcess };
      },
      {
        list: [],
        taskMap: {},
        tasksToProcess: [],
      } as {
        list: ITask[];
        taskMap: Record<string, ITask>;
        tasksToProcess: ITask[];
      }
    );

  // check if any task wasn't processed and process them
  for (const taskToProcess of tasksToProcess) {
    const { parentId } = taskToProcess;
    if (parentId && taskMap.hasOwnProperty(parentId)) {
      const { subtasks, relatedTo } = taskMap[parentId];
      if (!taskToProcess.relatedTo) {
        taskToProcess.relatedTo = relatedTo;
      }
      taskMap[parentId].subtasks = [...subtasks, taskToProcess];
    } else {
      list.push(taskToProcess);
    }
  }

  return list;
};

export default tasksWithSubtasksUtils;
