import React, { useEffect, useState } from "react";
import { KycBoTableMode, KycFlowStatus } from "../../../Kyc";
import { useTranslation } from "../../../../../hooks/useTranslation";
import {
  NoRecordsFoundMessage,
  PleaseWaitMessage,
  sortCaret,
} from "../../../../../../_metronic/_helpers";
import BootstrapTable, { ColumnDescription } from "react-bootstrap-table-next";
import {
  getStatusProperties,
  getTranslationCategory,
  KycStatusMode,
} from "../../../KycTranslationUtils";
import { sortDateFunc } from "../../../../../_utils/columnUtils";
import { useIntl } from "react-intl";
import { KycTranslation } from "../../../KycTranslation";
import {
  formatSignatories,
  getBoTypeName,
  getSignatureByBoId,
  isStatusBefore,
} from "../../../KycUtils";
import { useKyc } from "../../../../../hooks/useKyc";
import { defaultBootstrapTableOptions } from "../definitions";
import { KycBoDeclarationSingleDownloadColumnFormatter } from "../column-formatters/KycBoDeclarationSingleDownloadColumnFormatter";
import { useKycFile } from "../../../../../hooks/useKycFile";
import { useKycFlow } from "../../../../../hooks/useKycFlow";
import { PhoneNumberFormatter } from "../../../../../_components/column-formatters";
import { KycFileItemLangChoose } from "../column-formatters/KycFileItemLangChoose";
import { KycNotificationChoice } from "../column-formatters/KycNotificationChoice";
import cn from "clsx";
import { diff } from "deep-object-diff";
import { isEmpty } from "lodash-es";

interface KycBeneficialOwnerTableProps {
  mode: KycBoTableMode;
}

export const KycBeneficialOwnerTable = ({ mode }: KycBeneficialOwnerTableProps): JSX.Element => {
  const intl = useIntl();
  const { fm } = useTranslation();
  const { currentKycFlow } = useKyc();
  const { currentFiles } = useKycFile();
  const { values } = useKycFlow();

  const [processedEntities, setProcessedEntities] = useState<any[]>([]);

  useEffect(() => {
    currentKycFlow &&
      setProcessedEntities(
        formatSignatories(
          currentKycFlow,
          isStatusBefore(currentKycFlow?.status, KycFlowStatus.ADMIN_RISK_ASSESSMENT_DECLINED) &&
            !isEmpty(diff(values?.signatories, currentKycFlow?.signatories))
            ? currentKycFlow?.signatories
            : values?.signatories,
          currentFiles
        )
      );
  }, [values, currentKycFlow, currentFiles, intl]);

  const basicColumns: ColumnDescription[] = [
    {
      dataField: "name",
      headerClasses: "text-left text-nowrap",
      text: fm(KycTranslation.BO_NAME),
      headerStyle: { minWidth: 200, maxWidth: 200 },
      style: { minWidth: 200, maxWidth: 200 },
      sort: true,
      sortCaret: sortCaret,
    },
    {
      dataField: "email",
      headerClasses: "text-left text-nowrap",
      text: fm("USER.INFO.EMAIL"),
      headerStyle: { minWidth: 250, maxWidth: 200 },
      style: { minWidth: 250, maxWidth: 250 },
      sort: true,
      sortCaret: sortCaret,
      formatter: (cell: any) => <span>{getTranslationCategory(cell, fm)}</span>,
    },
    {
      dataField: "mobile",
      headerClasses: "text-left text-nowrap",
      classes: "text-left",
      text: fm("USER.INFO.MOBILE"),
      headerStyle: { minWidth: 150, maxWidth: 150 },
      style: { minWidth: 150, maxWidth: 150 },
      sort: true,
      sortCaret: sortCaret,
      formatter: PhoneNumberFormatter,
    },
    {
      dataField: "boDocType",
      headerClasses: "text-nowrap",
      headerStyle: { minWidth: 170, maxWidth: 170 },
      style: { minWidth: 170, maxWidth: 170 },
      text: fm("COMMON.TYPE"),
      sort: true,
      sortCaret: sortCaret,
      formatter: (cell: any) => <>{getBoTypeName(cell, fm)}</>,
    },
  ];

  const columns: ColumnDescription[] = [
    ...basicColumns,
    {
      dataField: "signatureStatus",
      headerClasses: "text-nowrap text-center",
      classes: "text-center",
      text: fm("SIGN.TITLE"),
      sort: true,
      sortCaret: sortCaret,
      headerStyle: { minWidth: 130, maxWidth: 130 },
      style: { minWidth: 130, maxWidth: 130 },
      formatter: (cell: any) => {
        const statusProps = getStatusProperties(cell, KycStatusMode.BO, fm);

        return (
          <div
            key={"kyc-beneficial-owner-signature-status-" + { cell }}
            style={{ fontSize: "1.2em", fontWeight: 400 }}
          >
            <span className={cn("label label-lg label-inline", statusProps?.color)}>
              <div style={{ fontSize: "1.0em" }}>
                {statusProps?.label || fm("KYC.V2.STATUS_BO_OVERALL_DRAFT")}
              </div>
            </span>
          </div>
        );
      },
    },
    {
      dataField: "notificationMethods",
      headerClasses: "text-center",
      classes: "align-items-center",
      text: fm(KycTranslation.BO_DECLARATION_NOTIFICATION),
      headerStyle: { minWidth: 150, maxWidth: 150 },
      style: { minWidth: 150, maxWidth: 150 },
      sort: false,
      formatter: (cell: any, row: any) => (
        <KycNotificationChoice notificationMethods={cell} signatory={row} mode={mode} />
      ),
    },
    {
      dataField: "lang",
      headerClasses: "text-center text-nowrap",
      classes: "justify-content-center",
      text: fm("COMMON.LANGUAGE"),
      headerStyle: { minWidth: 100, maxWidth: 100 },
      style: { minWidth: 120, maxWidth: 120 },
      sort: false,
      sortCaret: sortCaret,
      sortFunc: sortDateFunc,
      formatter: (cell: any, row: any) => (
        <KycFileItemLangChoose cell={cell} signatory={row} mode={mode} />
      ),
    },
    {
      dataField: "selectedFile",
      headerClasses: "text-center",
      classes: "text-right",
      text: fm("COMMON.ACTIONS"),
      sort: true,
      sortCaret: sortCaret,
      sortFunc: sortDateFunc,
      style: { minWidth: 150, maxWidth: 150, height: 55 },
      formatter: (cell: any, row: any) => (
        <KycBoDeclarationSingleDownloadColumnFormatter
          selectedBoFile={cell}
          signatory={row}
          mode={mode}
          signature={getSignatureByBoId(values, row?.id)}
        />
      ),
    },
  ];

  return (
    <BootstrapTable
      key={"kyc-bo-table-" + mode + "-" + intl.locale + "-" + currentKycFlow?.status}
      {...defaultBootstrapTableOptions}
      hover={false}
      keyField="id"
      data={processedEntities}
      columns={columns}
      rowStyle={{ cursor: "default" }}
      noDataIndication={() => (
        <NoRecordsFoundMessage
          entities={processedEntities}
          messageId={KycTranslation.NO_SIGNATORIES_AVAILABLE}
        />
      )}
    >
      <PleaseWaitMessage entities={processedEntities} />
    </BootstrapTable>
  );
};
