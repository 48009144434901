import React, { createContext, useCallback, useContext, useState } from "react";
import { isEqual, isFunction } from "lodash-es";
import { initialFilters } from "./ProjectsUIHelpers";
import { SearchTypeValues } from "../../../../contexts/SearchContext";
import { filterUITag } from "../../../../_utils/search/searchUtils";
import { useTag } from "../../../../hooks/useTag";
import { canReadAll } from "../../../../_utils/authUtils";
import { shallowEqual, useSelector } from "react-redux";
import { PROJECT, TAG } from "../../../../_utils/dataTypes";

const ProjectsUIContext = createContext();

export function useProjectsUIContext() {
  return useContext(ProjectsUIContext);
}

export const ProjectsUIConsumer = ProjectsUIContext.Consumer;

export function ProjectsUIProvider({ projectsUIEvents, children, queryParamsInit, readOnly }) {
  const [queryParams, setQueryParams] = useState(queryParamsInit);
  const [filters, setFiltersBase] = useState(initialFilters);
  const filterList = [
    { value: "name", label: "COMMON.NAME" },
    { value: "address.city", label: "ADDRESS.CITY" },
    { value: "createdAt", label: "COMMON.CREATED.AT" },
    { value: "availability", label: "PROJECT.INFO.AVAILABILITY_DATE" },
    { value: "startDate", label: "COMMON.START_DATE" },
  ];
  const [ids, setIds] = useState([]);
  const setFilters = useCallback((nextFilters) => {
    setFiltersBase((prevFilters) => {
      if (isFunction(nextFilters)) {
        nextFilters = nextFilters(prevFilters);
      }

      if (isEqual(prevFilters, nextFilters)) {
        return prevFilters;
      }

      return nextFilters;
    });
  }, []);
  const { tagLinks } = useTag();

  const { groups, session } = useSelector(
    (state) => ({
      groups: state.auth.groups,
      session: state.auth.session,
    }),
    shallowEqual
  );

  const value = {
    queryParams,
    setQueryParams,
    ids,
    setIds,
    filters,
    setFilters,
    newProjectButtonClick: projectsUIEvents?.newProjectButtonClick,
    openProjectPage: projectsUIEvents?.openProjectPage,
    openDeleteProjectDialog: projectsUIEvents?.openDeleteProjectDialog,
    readOnly,
    filterList,
    filtersUI: [
      {
        name: "COMMON.SEARCH.TITLE",
        value: "text",
        type: SearchTypeValues.SEARCH_TEXT_COLUMNS,
        columns: [...filterList],
        checkTag: true,
      },
    ],
  };
  if (canReadAll(groups, session, TAG)) value.filtersUI.push(filterUITag(PROJECT, tagLinks));

  return <ProjectsUIContext.Provider value={value}>{children}</ProjectsUIContext.Provider>;
}
