import "./KycWizard.scss";
import { KycFlowFormBase } from "app/modules/kyc-v2/components/layout/KycFlowFormBase";
import { isKycAvailable } from "../../_utils/configUtils";
import { KycV2Provider } from "../../contexts/KycContext";
import { SessionProvider } from "../../contexts/SessionContext";
import { WizardProvider } from "app/contexts/WizardContext";
import { KycFlowContainerContent } from "./KycFlowContainerContent";
import { LoadingProvider } from "../../contexts/LoadingContext";
import { KycFileProvider } from "../../contexts/KycFileContext";
import { KycConfirmationProvider } from "../../contexts/KycConfirmationContext";
import { KycEventProvider } from "../../contexts/KycEventContext";

export const KycFlowContainer = (): JSX.Element => {
  return (
    <>
      {isKycAvailable() && (
        <SessionProvider>
          <LoadingProvider>
            <WizardProvider>
              <KycEventProvider>
                <KycV2Provider>
                  <KycFileProvider>
                    <KycFlowFormBase>
                      <KycConfirmationProvider>
                        <KycFlowContainerContent />
                      </KycConfirmationProvider>
                    </KycFlowFormBase>
                  </KycFileProvider>
                </KycV2Provider>
              </KycEventProvider>
            </WizardProvider>
          </LoadingProvider>
        </SessionProvider>
      )}
    </>
  );
};
