import * as React from "react";
import { FormSectionField, FormSectionRegistryRow } from "../FormSection";
import { Field } from "formik";
import { FormattedMessage, useIntl } from "react-intl";
import { NumberInput } from "../../../../../../../_utils/formUtils";
import { Input, Select } from "_metronic/_partials/controls";
import SVG from "react-inlinesvg";
import { IPropertyParkingDetails } from "../../../../../../../../data/schemas";
import { SUFFIX_EURO_CURRENCY } from "../../../../../../../_utils/suffixUtils";
import { toAbsoluteUrl } from "../../../../../../../../_metronic/_helpers";
import { PARKING_TYPE } from "../../../../../../../_utils/listUtils";

interface ParkingFieldsProps {
  parentName: string;
  disabled: boolean;
  index: number;
  parking: IPropertyParkingDetails;
  handle: any;
  removeParking: Function;
}

export const ParkingFields: React.FC<ParkingFieldsProps> = ({
  parentName,
  disabled,
  index,
  parking,
  handle,
  removeParking,
}) => {
  const { fieldChangeHandler, handleChange, numberFieldChangeHandler } = handle;
  const intl = useIntl();
  const SUFFIX_UNIT_AREA = intl.formatMessage({ id: "COMMON.AREA.UNIT" });

  return (
    <div className={"d-flex justify-content-berween my-4 bg-gray-100 h-100"}>
      <div className={"w-100 h-100 pb-4"}>
        <div className={"w-100 mx-2 form-row justify-content-between justify-content-lg-start"}>
          <FormSectionRegistryRow
            surface={{
              value: parking?.surface ?? 0,
              onValueChange: numberFieldChangeHandler({
                name: `${parentName}.parkings.${index}.surface`,
                defaultValue: 0,
                handleChange,
              }),
            }}
            thousands={{
              value: parking?.thousands ?? 0,
              onValueChange: numberFieldChangeHandler({
                name: `${parentName}.parkings.${index}.thousands`,
                defaultValue: 0,
                handleChange,
              }),
            }}
            landArchitectNumber={`${parentName}.parkings.${index}.landArchitectNumber`}
            landRegistryNumber={`${parentName}.parkings.${index}.landRegistryNumber`}
            onFieldChange={fieldChangeHandler(handleChange)}
            suffix={{ surface: SUFFIX_UNIT_AREA }}
          />
        </div>
        <div className={"w-100 mx-2 form-row justify-content-between justify-content-lg-start"}>
          <FormSectionField className={"col-sm-6 col-md-5 mt-3"}>
            <Field
              name={`${parentName}.parkings.${index}.numberBlockStaircaseLevelCadastreCellar`}
              onChange={fieldChangeHandler(handleChange)}
              label={intl.formatMessage({
                id: "PRODUCT.INFO.NUMBER_BLOCK_STAIRCASE",
              })}
              component={Input}
              disabled={disabled}
            />
          </FormSectionField>

          <FormSectionField
            label={<FormattedMessage id={"COMMON.PRICE"} />}
            className={"col-sm-6 col-md-2 mt-3"}
          >
            <NumberInput
              className="form-control text-right"
              suffix={SUFFIX_EURO_CURRENCY}
              decimalScale={2}
              value={parking?.price ?? 0.0}
              onValueChange={numberFieldChangeHandler({
                name: `${parentName}.parkings.${index}.price`,
                handleChange,
              })}
            />
          </FormSectionField>

          <FormSectionField>
            <Field
              name={`${parentName}.parkings.${index}.parcel`}
              onChange={fieldChangeHandler(handleChange)}
              label={intl.formatMessage({
                id: "PRODUCT.INFO.LOT",
              })}
              component={Input}
              disabled={disabled}
            />
          </FormSectionField>

          <FormSectionField className={"col-sm-6 col-md-2 mt-3"}>
            <Select
              name={`${parentName}.parkings.${index}.type`}
              value={parking?.type}
              onChange={fieldChangeHandler(handleChange)}
              disabled={disabled}
              customFeedbackLabel={true}
              label={<FormattedMessage id={"COMMON.TYPE"} />}
            >
              {PARKING_TYPE.map((value, index2) => (
                <option key={index2} value={value.key}>
                  {intl.formatMessage({ id: value.label })}
                </option>
              ))}
            </Select>
          </FormSectionField>
        </div>
      </div>

      <div
        className="pb-4 ml-2 d-flex flex-column justify-content-end btn px-0 btn-hover-danger"
        onClick={(e) => removeParking(e)}
      >
        {!disabled && (
          <button style={{ minHeight: "36px" }} className={"btn btn-icon btn btn-sm my-auto"}>
            <span className="svg-icon svg-icon-md svg-icon-danger">
              <SVG src={toAbsoluteUrl("/media/svg/icons/General/Trash.svg")} />
            </span>
          </button>
        )}
      </div>
    </div>
  );
};
