import { FormattedMessage } from "react-intl";
import { NumberInput } from "../../../../../_utils/formUtils";
import React, { FC } from "react";

interface Props {
  data: number;
  icon: string;
  label: string;
  suffix?: string;
}

export const ProjectWidget: FC<Props> = ({ data, icon, label, suffix = "" }) => {
  return (
    <>
      {data !== undefined && (
        <div className="d-flex align-items-start col my-2">
          <span className="mr-4">
            <i className={`${icon} icon-2x text-muted font-weight-bold`} />
          </span>
          <div className="d-flex flex-column flex-lg-fill">
            <span className="text-dark-75 font-weight-bolder font-size-sm">
              <FormattedMessage id={label} values={{ count: data }} />
            </span>
            <NumberInput
              displayType="text"
              value={data}
              decimalScale={2}
              suffix={suffix}
              className="text-muted font-weight-bold"
            />
          </div>
        </div>
      )}
    </>
  );
};
