import React from "react";
import { useIntl } from "react-intl";
import { COUNTRY_CODES } from "../../_utils/listUtils";

export const CountryDropdownOptions = () => {
  const intl = useIntl();

  return (
    <>
      <option key={"EMPTY.STRING"} value={"EMPTY.STRING"}>
        {}
      </option>
      {COUNTRY_CODES?.map(
        (country) =>
          country && (
            <option key={country} value={country}>
              {intl.formatDisplayName(country, { type: "region" })}
            </option>
          )
      )}
    </>
  );
};
