import * as React from "react";
import { SetConfidentialCategory, ViewRelatedFiles } from "./actions";
import { BudgetTableRow } from "../../BudgetEditExpenses";
import { Row } from "@tanstack/react-table";
import { IBudget, IBudgetSortedCategory } from "../../../../../../data/schemas";
import { CardTableDropdownActions } from "../../../../../_components/CardTable/components/buttons/CardTableDropdownActions/CardTableDropdownActions";
import { useBudgetsUIContext } from "../../../BudgetsUIContext";
import { useFormikContext } from "formik";
import { useIntl } from "react-intl";
import { useConfirmationDialogContext } from "../../../../../_components/ConfirmationDialog";

export interface BudgetLineActionsProps {
  row: Row<BudgetTableRow>;
  relatedBudgetCategory?: IBudgetSortedCategory;
  disabled: boolean;
}

export const BudgetLineActions: React.FC<BudgetLineActionsProps> = ({
  row,
  relatedBudgetCategory,
  disabled,
}) => {
  const { rowType, children } = row.original;
  const { setConfirmationDialog } = useConfirmationDialogContext();
  const { saveBudgetFields } = useBudgetsUIContext();
  const { values, handleChange } = useFormikContext<IBudget>();
  const intl = useIntl();

  let saveBudgetFieldsPath: string;
  let budgetArrayToUpdate;

  if (rowType === "BUDGET_LINE") {
    saveBudgetFieldsPath = `sortedLines.${relatedBudgetCategory?.sequence}.lines`;
    budgetArrayToUpdate = relatedBudgetCategory?.lines ?? [];
  } else {
    saveBudgetFieldsPath = "sortedLines";
    budgetArrayToUpdate = values.sortedLines;
  }

  const notRemovableRow =
    (rowType === "BUDGET_LINE" && !!children?.length) ||
    (rowType === "BUDGET_CATEGORY" &&
      children?.some((line) => !line.rowType || line.children?.length));

  const removeBudgetItem = (updatedArray: any[]) => {
    if (!notRemovableRow) {
      saveBudgetFields(saveBudgetFieldsPath!, updatedArray);
    } else {
      let confirmDialogConfig;
      if (rowType === "BUDGET_LINE") {
        confirmDialogConfig = {
          title: "BUDGET.LINE.DELETE",
          body: "BUDGET.REMOVE_LINE_WARNING",
        };
      } else {
        confirmDialogConfig = {
          title: "BUDGET.CATEGORY.DELETE",
          body: "BUDGET.REMOVE_CATEGORY_WARNING",
        };
      }

      const { title, body } = confirmDialogConfig;
      setConfirmationDialog({
        title: intl.formatMessage({ id: title }),
        body: intl.formatMessage({ id: body }),
        hideButtonLabel: intl.formatMessage({ id: "COMMON.ACTION.OK" }),
        hideButtonClassName: "btn-primary",
      });
    }
  };

  const updateBudgetOrder = (updatedArray: any[]) => {
    saveBudgetFields(saveBudgetFieldsPath, updatedArray);
    handleChange({ target: { name: saveBudgetFieldsPath, value: updatedArray } });
  };

  return (
    <>
      <CardTableDropdownActions
        disabled={disabled && rowType === "BUDGET_CATEGORY"}
        toggleClass={"mr-2"}
      >
        {!disabled && rowType === "BUDGET_CATEGORY" && <SetConfidentialCategory row={row} />}
        {rowType !== "BUDGET_CATEGORY" && <ViewRelatedFiles row={row} />}
        {!disabled && ["BUDGET_CATEGORY", "BUDGET_LINE"].includes(rowType ?? "") && (
          <>
            <CardTableDropdownActions.Move
              row={row}
              arrayToUpdate={budgetArrayToUpdate}
              moveCallback={updateBudgetOrder}
            />
            <CardTableDropdownActions.Delete
              confirmDelete={!notRemovableRow}
              deleteTitle={
                rowType === "BUDGET_LINE" ? "BUDGET.LINE.DELETE" : "BUDGET.CATEGORY.DELETE"
              }
              row={row}
              arrayToUpdate={budgetArrayToUpdate}
              removeCallback={removeBudgetItem}
            />
          </>
        )}
      </CardTableDropdownActions>
    </>
  );
};
