import { WizardVerticalStepperIndicator } from "./WizardVerticalStepperIndicator";
import * as React from "react";
import { useEffect } from "react";
import { useWizard } from "../../hooks/useWizard";
import "./WizardStepper.scss";
import cn from "clsx";
import { WIZARD_ORIENTATION } from "./Wizard";
import { getContentForStep } from "../../modules/kyc-v2/KycUtils";
import { KycFooterDisclaimer } from "../../modules/kyc-v2/components/steps/KycFooterDisclaimer";

interface WizardStepperProps {
  orientation?: WIZARD_ORIENTATION;
  hideStepperIndicator?: boolean;
}

export const WizardStepper = ({
  orientation = WIZARD_ORIENTATION.HORIZONTAL,
  hideStepperIndicator = false,
}: WizardStepperProps): JSX.Element => {
  const {
    stepperOrientation,
    setStepperOrientation,
    currentStepId,
    steps,
    activeStep,
    isExpanded,
  } = useWizard();

  useEffect(() => {
    setStepperOrientation(orientation);
  }, [orientation]);

  return (
    <>
      {hideStepperIndicator ? (
        <div className={cn("step-container", isExpanded && "maximized")}>
          <div className={cn("step-content", isExpanded && "maximized")}>
            {getContentForStep(steps[activeStep]?.id)}
          </div>
          <KycFooterDisclaimer />
          {isExpanded && <div style={{ height: "24px" }} />}
        </div>
      ) : (
        <div
          className={cn("d-flex", stepperOrientation === "HORIZONTAL" && "flex-column")}
          style={{ height: "100%" }}
        >
          <div
            className={cn(
              stepperOrientation === WIZARD_ORIENTATION.VERTICAL
                ? "kyc-wizard-vertical"
                : "kyc-wizard-horizontal"
            )}
          >
            <WizardVerticalStepperIndicator />
          </div>
          <div
            style={{
              width: stepperOrientation === WIZARD_ORIENTATION.VERTICAL ? "80%" : "100%",
              height: "100%",
              overflow: "auto",
            }}
          >
            <div className={cn("step-container", isExpanded && "maximized")}>
              <div className={cn("step-content", isExpanded && "maximized")}>
                {currentStepId() === steps[activeStep]?.id &&
                  getContentForStep(steps[activeStep]?.id)}
              </div>
              <KycFooterDisclaimer />
              {isExpanded && <div style={{ height: "24px" }} />}
            </div>
          </div>
        </div>
      )}
    </>
  );
};
