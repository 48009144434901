import { UserAvatarImg } from "../UserAvatarImg";
import { ColumnFormatter } from "react-bootstrap-table-next";

interface RowProps {
  profilePictureSetup: any;
  profilePictureUrl: string;
  firstName: string;
  lastName: string;
  email: string;
}

export const ProfilePictureColumnFormatter: ColumnFormatter<RowProps> = (cell, row) =>
  !row.profilePictureSetup && !row.profilePictureUrl ? (
    <div className="symbol symbol-35 min-w-35px min-h-35px symbol-light-primary flex-shrink-0">
      <span className="symbol-label font-size-h5 font-weight-bold symbol-label">
        {row.firstName || row.lastName ? (
          <>
            {!!row.firstName && row.firstName[0].toUpperCase()}
            {!!row.lastName && row.lastName[0].toUpperCase()}
          </>
        ) : (
          <>{!!row.email ? row.email[0].toUpperCase() : "-"}</>
        )}
      </span>
    </div>
  ) : (
    <div className="symbol symbol-35 min-w-35px min-h-35px bg-white-o-30 flex-shrink-0">
      <UserAvatarImg user={row} />
    </div>
  );
