import * as React from "react";
import { IProject } from "../../../../../../../data/schemas";
import { useBudgetsUIContext } from "../../../../BudgetsUIContext";
import {
  CurrencyMetricCard,
  MetricCard,
  MetricCardProps,
} from "../../../../../../_components/MetricCard";

export interface InvoicedOrdersCardProps {
  canSeeHiddenLine: boolean;
  project: Partial<IProject>;
}

export const InvoicedOrdersCard: React.FC<InvoicedOrdersCardProps & MetricCardProps> = ({
  canSeeHiddenLine,
  project,
  ...props
}) => {
  const {
    budgetTotals: { invoicedOrders = 0, orders, discountsOrders = 0 },
  } = useBudgetsUIContext();
  const totalInvoicedOrdersPercentage =
    invoicedOrders && orders ? (invoicedOrders / (orders - discountsOrders)) * 100 : 0;
  return (
    <CurrencyMetricCard
      dataCy="input-budget-invoiced-orders"
      value={invoicedOrders}
      label="BUDGET.ORDERS_INVOICED"
      {...props}
    >
      <MetricCard.PercentageSubMetric
        dataCy="input-budget-invoiced-orders-percentage"
        value={totalInvoicedOrdersPercentage}
      />
    </CurrencyMetricCard>
  );
};
