import * as React from "react";
import PropTypes from "prop-types";
import { API } from "aws-amplify";
import { PaginationOptions } from "react-bootstrap-table-next";
import * as uiHelpers from "../modules/Budget/BudgetsUIHelpers";
import { IEvent, IEventQueryParams, ILastEvaluatedKey } from "../_components/AuditTrail/models";
import { USER, userTypes } from "../_utils/userTypes";
import { DataObjectTypes } from "../_utils/dataTypes";
import { useLocation } from "react-router";

const defaultPaginationProps: PaginationOptions = {
  custom: true,
  sizePerPageList: uiHelpers.sizePerPageList ?? 0,
  sizePerPage: 100,
};

interface AuditTrailProps {
  children?: React.ReactNode;
}

interface PaginationPage {
  events: IEvent[];
  lastEvaluatedKey: any;
  index: -1;
}

export const LOADING_FETCH_USER_EVENTS = "fetchUserEvents";

export type TFetchDirection = "next" | "previous";

export interface AuditTrailContextValue {
  currentEntityObject: any;
  setCurrentEntityObject: (entityObject: any) => void;
  events: IEvent[];
  loadings: Set<string>;
  fetchAuditTrail: (
    id: string,
    entity: DataObjectTypes,
    fetchDirection?: TFetchDirection
  ) => Promise<void>;
  paginationProps: any;
  getNextUserEvents: () => Promise<void>;
  getPreviousUserEvents: () => Promise<void>;
  currentPageIndex: number;
  lastEvaluatedKey: ILastEvaluatedKey | undefined;
  setLastEvaluatedKey: (lastKey: ILastEvaluatedKey) => void;
  currentId: string;
  currentEntity: string;
  pages: PaginationPage[];
  resetAuditTrail: () => void;
  setCurrentId: (id: string) => void;
  setEventsManually: (events: DataObjectTypes) => void;
}

export const AuditTrailContext = React.createContext<AuditTrailContextValue>({
  currentEntityObject: undefined,
  setCurrentEntityObject: (entityObject) => undefined,
  paginationProps: defaultPaginationProps,
  events: [],
  loadings: new Set(),
  fetchAuditTrail: (id, entity, fetchDirection) => Promise.resolve(),
  getNextUserEvents: () => Promise.resolve(),
  getPreviousUserEvents: () => Promise.resolve(),
  currentPageIndex: 0,
  lastEvaluatedKey: undefined,
  setLastEvaluatedKey: (lastKey) => undefined,
  currentId: "",
  setCurrentId: () => undefined,
  currentEntity: "",
  pages: [],
  resetAuditTrail: () => undefined,
  setEventsManually: (events) => undefined,
});

export const AuditTrailProvider: React.FunctionComponent<AuditTrailProps> = (props) => {
  const { children } = props;
  const [loadings, setLoadings] = React.useState<Set<string>>(new Set());
  const [events, setEvents] = React.useState<IEvent[]>([]);
  const [paginationProps, setPaginationProps] =
    React.useState<PaginationOptions>(defaultPaginationProps);
  const [pages, setPages] = React.useState<any>();
  const [currentPageIndex, setCurrentPageIndex] = React.useState<number>(0);
  const [currentId, setCurrentIdInternal] = React.useState<string>("");
  const [currentEntity, setCurrentEntity] = React.useState<string>("");
  const [currentEntityObject, setCurrentEntityObjectInternal] = React.useState<any>();
  const [lastEvaluatedKey, setLastEvaluatedKeyInternal] = React.useState<ILastEvaluatedKey>();

  const location = useLocation();
  const [lastLocation, setLastLocation] = React.useState<string | undefined>(undefined);
  React.useEffect(() => {
    if (lastLocation !== location.pathname) {
      resetAuditTrail();
      setLastLocation(location.pathname);
    }
  }, [location]);

  const getNextUserEvents = async () => {
    let nextPage = pages?.[currentPageIndex + 1];

    if (!nextPage) {
      if (currentPageIndex === 0) {
        setLastEvaluatedKeyInternal(undefined);
      }
      await fetchAuditTrail(currentId, currentEntity, "next");
      pages?.push({
        events: events,
        lastEvaluatedKey: lastEvaluatedKey,
        index: currentPageIndex + 1,
      });
    } else {
      setEvents(nextPage?.events);
    }
    setCurrentPageIndex(currentPageIndex + 1);
  };

  const getPreviousUserEvents = async () => {
    if (currentPageIndex === 0) {
      console.warn("cannot go previous page, current page index is 0");
      return;
    }

    let previousPage = pages?.[currentPageIndex - 1];

    if (previousPage) {
      setEvents(previousPage?.events);
    }

    if (!previousPage) {
      await fetchAuditTrail(currentId, currentEntity, "previous");
    }
    setCurrentPageIndex(currentPageIndex - 1);
  };

  const setLastEvaluatedKey = (lastKey: any) => {
    setLastEvaluatedKeyInternal(lastKey);
  };

  const fetchAuditTrail = async (
    id: string,
    entity: string,
    fetchDirection: TFetchDirection = "next"
  ) => {
    setCurrentId(id);
    setCurrentEntity(entity);
    if (id) {
      setLoadings(new Set(loadings).add(LOADING_FETCH_USER_EVENTS));

      const queryParams: IEventQueryParams = {
        entity,
        id,
        fetchDirection: fetchDirection ?? "next",
        sizePerPage: paginationProps?.sizePerPage,
      };

      const lastEvalKey: ILastEvaluatedKey = {
        GSI4PK: lastEvaluatedKey?.GSI4PK,
        GSI4SK: lastEvaluatedKey?.GSI4SK,
        PK: lastEvaluatedKey?.PK,
        SK: lastEvaluatedKey?.SK,
      };

      if (userTypes.includes(entity)) {
        queryParams.entity = USER;
        queryParams.userType = USER;
      }

      await API.get("API", `/audit`, {
        queryStringParameters: { ...queryParams, ...lastEvalKey },
      })
        .then((response) => {
          setEvents(response.events);
          setLastEvaluatedKey(response.lastEvaluatedKey);
        })
        .finally(() => {
          const newLoadings = new Set(loadings);
          newLoadings.delete(LOADING_FETCH_USER_EVENTS);
          setLoadings(newLoadings);
        });
    }
  };

  const resetAuditTrail = () => {
    setPaginationProps(defaultPaginationProps);
    setEvents([]);
    setCurrentPageIndex(0);
    setLastEvaluatedKeyInternal(undefined);
    setCurrentId("");
    setCurrentEntity("");
    setPages([]);
  };

  const setCurrentEntityObject = (entityObject: any): void => {
    setCurrentEntityObjectInternal(entityObject);
  };

  const setCurrentId = (id: string) => {
    setCurrentIdInternal(id);
  };

  const setEventsManually = (mEvents: any): void => {
    setEvents(mEvents?.reverse());
  };

  return (
    <AuditTrailContext.Provider
      value={{
        currentEntityObject,
        setCurrentEntityObject,
        resetAuditTrail,
        pages,
        currentId,
        currentEntity,
        getNextUserEvents,
        getPreviousUserEvents,
        currentPageIndex,
        paginationProps,
        events,
        loadings,
        lastEvaluatedKey,
        setLastEvaluatedKey,
        fetchAuditTrail,
        setCurrentId,
        setEventsManually,
      }}
    >
      {children}
    </AuditTrailContext.Provider>
  );
};

AuditTrailProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export const AuditTrailConsumer = AuditTrailContext.Consumer;
