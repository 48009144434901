import * as React from "react";

export interface ICardTableDropdownActionsContext {
  buttonRef: HTMLButtonElement | null;
}

export const CardTableDropdownActionsContext =
  React.createContext<ICardTableDropdownActionsContext>({} as ICardTableDropdownActionsContext);

export function useCardTableDropdownActionsContext() {
  return React.useContext<ICardTableDropdownActionsContext>(
    CardTableDropdownActionsContext as React.Context<ICardTableDropdownActionsContext>
  );
}

export interface CardTableDropdownActionsProps {
  buttonRef: HTMLButtonElement | null;
}

export const CardTableDropdownActionsProvider = ({
  children,
  buttonRef,
}: React.PropsWithChildren<CardTableDropdownActionsProps>) => {
  const value: ICardTableDropdownActionsContext = {
    buttonRef,
  };

  return (
    <CardTableDropdownActionsContext.Provider value={value}>
      {children}
    </CardTableDropdownActionsContext.Provider>
  );
};
