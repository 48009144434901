import * as React from "react";
import SVG from "react-inlinesvg";
import cn from "clsx";

import { toAbsoluteUrl } from "_metronic/_helpers";

export interface ProjectOwnerInfoIconProps {
  className?: string;
}

export const ProjectOwnerInfoIcon: React.FunctionComponent<ProjectOwnerInfoIconProps> = ({
  className,
}) => (
  <span className={cn("projectOwnerInfoIcon svg-icon svg-icon-md svg-icon-info", className)}>
    <SVG src={toAbsoluteUrl("/media/svg/icons/Code/Info-circle.svg")} />
  </span>
);

export default ProjectOwnerInfoIcon;
