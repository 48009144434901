import { createSlice } from "@reduxjs/toolkit";
import { set } from "lodash-es";
import { IBudget } from "../../../../data/schemas";

const initialBudgetsState: {
  listLoading: boolean;
  actionsLoading: boolean;
  entities: IBudget[];
  budgetForEdit: {
    saved: IBudget | undefined;
    current: Partial<IBudget> | undefined;
  };
  error: any;
} = {
  listLoading: false,
  actionsLoading: false,
  entities: [],
  budgetForEdit: {
    saved: undefined,
    current: undefined,
  },
  error: null,
};
export const callTypes = {
  list: "list",
  action: "action",
};

export const budgetsSlice = (name: string) =>
  createSlice({
    name: name,
    initialState: initialBudgetsState,
    reducers: {
      catchError: (state, action) => {
        if (action.payload.callType === callTypes.list) {
          state.listLoading = false;
        } else {
          state.actionsLoading = false;
        }
      },
      startCall: (state, action) => {
        state.error = null;
        if (action.payload.callType === callTypes.list) {
          state.listLoading = true;
        } else {
          state.actionsLoading = true;
        }
      },
      budgetFetched: (state, action) => {
        console.log("budgetFetched: ", action);
        state.actionsLoading = false;
        state.budgetForEdit = {
          saved: action.payload.budgetForEdit,
          current: action.payload.budgetForEdit,
        };
        state.error = null;
      },
      budgetsFetched: (state, action) => {
        console.log("budgetsFetched: ", action);
        const { entities } = action.payload;
        state.listLoading = false;
        state.error = null;
        state.entities = entities;
        state.budgetForEdit = initialBudgetsState.budgetForEdit;
      },
      budgetCreated: (state, action) => {
        console.log("budgetCreated: ", action);
        state.actionsLoading = false;
        state.error = null;
        state.entities.push(action.payload.budget);
        state.budgetForEdit = {
          saved: action.payload.budget,
          current: action.payload.budget,
        };
      },
      budgetFieldUpdatedLocally: (state, action) => {
        console.log("budgetUpdated: ", action);
        state.error = null;
        state.actionsLoading = false;
        if (!state.budgetForEdit.current) {
          state.budgetForEdit.current = {};
        }
        set(state.budgetForEdit.current, action.payload.key, action.payload.value);
      },
      budgetUpdatedLocally: (state, action) => {
        console.log("budgetUpdated: ", action);
        state.error = null;
        state.actionsLoading = false;
        state.budgetForEdit.current = action.payload.budgetForEdit;
      },
      budgetUpdated: (state, action) => {
        console.log("budgetUpdated: ", action);
        state.error = null;
        state.actionsLoading = false;
        state.budgetForEdit.saved = action.payload.budget;
        state.entities = state.entities.map((entity) => {
          if (entity.id === action.payload.budget.id) {
            return action.payload.budget;
          }
          return entity;
        });
      },
      budgetDeleted: (state, action) => {
        console.log("budgetDeleted: ", action);
        state.error = null;
        state.actionsLoading = false;
        state.entities = state.entities.filter((el) => el.id !== action.payload.id);
      },
    },
  });
