import * as React from "react";
import { useHistory, useLocation } from "react-router-dom";
import axiosRetry from "axios-retry";
import axios from "@aws-amplify/api-rest/node_modules/axios";

import { useSetupAmplifyAxios } from "./SetupAmplifyAxiosContext";

// https://github.com/softonic/axios-retry/issues/87
const retryDelay = (retryNumber = 0) => {
  const seconds = Math.pow(2, retryNumber) * 1000;
  const randomMs = 1000 * Math.random();
  return seconds + randomMs;
};

export const SetupAmplifyAxios: React.FunctionComponent = () => {
  const history = useHistory();

  const location = useLocation();
  const isPublicRequests = location.pathname.startsWith("/requests");

  // when the configuration changes it will redefine the onReject response interceptor
  const { shouldRedirectOnError } = useSetupAmplifyAxios();

  React.useEffect(() => {
    // @ts-ignore error cause because we use two different axios packages
    axiosRetry(axios, {
      retries: 2,
      retryDelay,
      // retry on Network Error & 5xx responses
      retryCondition: axiosRetry.isRetryableError,
    });

    const { status401, status404, status5xx, network } = shouldRedirectOnError;

    const onReject = (err: any) => {
      const status = err?.response?.status.toString() ?? "";

      if (status && !isPublicRequests) {
        if (status401 && status === "401") {
          history.push("/logout");
        } else if (status404 && status === "404") {
          history.push("/error/404");
        } else if (status5xx && status.startsWith("5")) {
          history.push("/error/500");
        }
      }

      // err.message === "Network Error" will also trigger for CORS errors as the browser doesn't send full errors to the js
      if (network && !navigator.onLine && history.location.pathname !== "/error/network") {
        localStorage.setItem("retryUrl", history.location.pathname);
        history.push("/error/network");
      }

      return Promise.reject(err);
    };

    // https://axios-http.com/docs/interceptors
    const interptorId = axios.interceptors.response.use((response) => response, onReject);
    return () => {
      axios.interceptors.response.eject(interptorId);
    };
  }, [history, shouldRedirectOnError]);

  return null;
};

export default SetupAmplifyAxios;
