import { flatMap } from "lodash-es";
import {
  LeadFinancialDocumentLine,
  LeadFinancialDocumentPriceLine,
} from "../../../../../../../../data/schemas";

/**
 * Method used only for easier calculation of totals and credit update
 * When flattened, if an SA is added we remove the main line as it's only used as a sum of its lines
 */
export const flattenInvoiceLines = (lines: LeadFinancialDocumentLine[]) =>
  lines.reduce(
    (acc, line) => [
      ...acc,
      ...(line.financialDocumentId ? [] : [line]),
      ...(line.relatedFinancialDocuments ? flatMap(line.relatedFinancialDocuments, "content") : []),
    ],
    [] as LeadFinancialDocumentPriceLine[]
  );
