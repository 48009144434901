import React from "react";
import { FormattedMessage } from "react-intl";

export function BooleanOption({
  dataCy,
  field, // { name, value, onChange, onBlur }
  label,
  className,
  ...props
}) {
  return (
    <>
      {label && <label>{label}</label>}

      <div>
        <div className="form-check form-check-inline">
          <input
            type="radio"
            id={`${field.name}-TRUE`}
            data-cy={`${dataCy}-TRUE`}
            className="form-check-input"
            {...field}
            {...props}
            value="COMMON.ACTION.YES"
            checked={field.value === "COMMON.ACTION.YES"}
          />
          <label htmlFor={`${field.name}-TRUE`} className="form-check-label">
            <FormattedMessage id="COMMON.ACTION.YES"/>
          </label>
        </div>
        <div className="form-check form-check-inline">
          <input
            type="radio"
            id={`${field.name}-FALSE`}
            data-cy={`${dataCy}-FALSE`}
            className="form-check-input"
            {...field}
            {...props}
            value="COMMON.ACTION.NO"
            checked={field.value === "COMMON.ACTION.NO"}
          />
          <label htmlFor={`${field.name}-FALSE`} className="form-check-label">
            <FormattedMessage id="COMMON.ACTION.NO"/>
          </label>
        </div>
      </div>
    </>
  );
}
