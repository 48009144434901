import cn from "clsx";
import { KycFieldLock } from "../KycFieldLock";
import { FormControl } from "react-bootstrap";
import { KycUploadTrashButton } from "./KycUploadTrashButton";
import * as React from "react";
import { useWizard } from "../../../../../hooks/useWizard";
import { findAndReplaceFile } from "../../../KycFileUtils";
import { useKycFile } from "../../../../../hooks/useKycFile";
import { KycFile } from "../../../Kyc";

interface KycUploadFreetextProps {
  othersOnly?: boolean;
  file?: KycFile;
  disabled?: boolean;
}

export const KycUploadFreetext = ({
  file,
  disabled = false,
  othersOnly = false,
}: KycUploadFreetextProps): JSX.Element => {
  const { isReadOnly } = useWizard();
  const { currentFiles, setCurrentFiles, updateFile } = useKycFile();

  const handleInputOnBlur = (e: any, file: any) => {
    e.preventDefault();
    updateFile(file);
  };

  const handleInputOnChange = (e: any, file: any) => {
    e.preventDefault();
    file.freeText = e.target.value;
    setCurrentFiles(findAndReplaceFile(currentFiles, file));
  };

  return (
    <div className={cn("d-flex w-100")}>
      <div className={"w-100"}>
        <KycFieldLock
          field={{
            name: file?.fieldPath,
            value: file?.freeText || "",
          }}
          disabled={disabled}
        >
          <FormControl
            key={"upload-file-freeText-" + file?.id}
            disabled={disabled || isReadOnly}
            aria-label="freeText"
            aria-describedby="basic-addon2"
            value={file?.freeText}
            onBlur={(e: any) => handleInputOnBlur(e, file)}
            onChange={(e) => handleInputOnChange(e, file)}
          />
        </KycFieldLock>
      </div>
      {othersOnly && !disabled && <KycUploadTrashButton file={file} />}
    </div>
  );
};
