import * as React from "react";
import moment from "moment";
import { FormattedMessage, useIntl } from "react-intl";
import { Dropdown } from "react-bootstrap";
import DatePicker from "react-datepicker";
import { DateUtils } from "../../_utils/DateUtils";
import { DropdownSelectToggler } from "./components/DropdownSelectToggler";
import cn from "clsx";

export interface DateRange {
  from: Date;
  to: Date;
  preset: string;
}

const DateRangePresetsKeys: Record<string, string> = {
  customRange: "SEARCH.DATE.CUSTOM_RANGE",
  last3Days: "SEARCH.DATE.LAST_3_DAYS",
  last7Days: "SEARCH.DATE.LAST_7_DAYS",
  lastMonth: "SEARCH.DATE.LAST_MONTH",
  last3Months: "SEARCH.DATE.LAST_3_MONTHS",
  currentYear: "SEARCH.DATE.CURRENT_YEAR",
  last3Years: "SEARCH.DATE.LAST_3_YEARS",
} as const;

export interface DateRangePickerProps {
  onChange: (value: DateRange | Partial<DateRange> | undefined) => void;
  label?: string;
  value?: DateRange | Partial<DateRange>;
}

export const DateRangePicker = ({ onChange, label, value }: DateRangePickerProps) => {
  const intl = useIntl();

  const [show, setShow] = React.useState(false);

  const [dateRange, setDateRange] = React.useState<Partial<DateRange> | undefined>(value);

  const applyChange = () => {
    if (dateRange) {
      onChange(dateRange);
    } else {
      onChange(undefined);
    }
    setShow(false);
  };

  const yesterday = moment().subtract(1, "day").endOf("day").toDate();
  const lastMonth = moment().subtract(1, "month");

  const dateRangePreset: DateRange[] = [
    {
      preset: "last3Days",
      from: moment().subtract(3, "day").startOf("day").toDate(),
      to: yesterday,
    },
    {
      preset: "last7Days",
      from: moment().subtract(7, "day").startOf("day").toDate(),
      to: yesterday,
    },
    {
      preset: "lastMonth",
      from: lastMonth.startOf("month").toDate(),
      to: lastMonth.endOf("month").toDate(),
    },
    {
      preset: "last3Months",
      from: moment().subtract(3, "month").startOf("month").toDate(),
      to: lastMonth.endOf("month").toDate(),
    },
    { preset: "currentYear", from: moment().startOf("year").toDate(), to: yesterday },
    {
      preset: "last3Years",
      from: moment().subtract(3, "year").startOf("year").toDate(),
      to: moment().subtract(1, "year").endOf("year").toDate(),
    },
  ];

  const dateRangePresetOptions = Object.keys(DateRangePresetsKeys).map((preset) => ({
    label: intl.formatMessage({ id: DateRangePresetsKeys[preset] }),
    value: preset,
  }));

  const inputLabel = dateRange ? (
    <div className="d-flex">
      {dateRange?.from ? (
        DateUtils.format(dateRange.from, intl, false)
      ) : (
        <span className="text-muted">
          <FormattedMessage id="COMMON.START_DATE" />
        </span>
      )}
      <span className="mx-2">{"-"}</span>
      {dateRange?.to ? (
        DateUtils.format(dateRange.to, intl, false)
      ) : (
        <span className="text-muted">
          <FormattedMessage id="COMMON.END_DATE" />
        </span>
      )}
    </div>
  ) : (
    <span className="text-muted">
      <FormattedMessage id={label ?? "DATEPICKER.ALL_TIME"} />
    </span>
  );

  return (
    <Dropdown drop="down" show={show} onToggle={(isOpen) => setShow(isOpen)}>
      <Dropdown.Toggle
        as={DropdownSelectToggler}
        showClear={!!dateRange}
        onClear={() => {
          setDateRange(undefined);
          onChange(undefined);
        }}
        style={{ minWidth: "250px" }}
      >
        <div>{inputLabel}</div>
      </Dropdown.Toggle>
      <Dropdown.Menu
        alignRight
        className="dropdown-menu dropdown-menu-left dropdown-menu-anim dropdown-menu-anim-down border border-secondary p-0 overflow-hidden"
        rootCloseEvent="mousedown"
        onClick={(e: React.MouseEvent<HTMLDivElement>) => {
          e.stopPropagation();
        }}
      >
        <div className="d-flex">
          <div className="border-right border-right-secondary">
            {dateRangePresetOptions.map((preset, index) => (
              <div
                key={index}
                className={cn(
                  "px-6 py-2 text-nowrap",
                  dateRange?.preset === preset.value
                    ? "bg-primary text-white"
                    : "bg-hover-light-primary"
                )}
                onClick={() => {
                  setDateRange(dateRangePreset.find((p) => p.preset === preset.value));
                }}
              >
                {preset.label}
              </div>
            ))}
          </div>
          <DatePicker
            className="form-control form-control-sm"
            calendarClassName="custom-datepicker date-range-picker"
            wrapperClassName="custom-datepicker"
            locale={intl.locale}
            showPopperArrow={false}
            showTimeSelect={false}
            isClearable
            dropdownMode="select"
            popperPlacement="bottom"
            popperClassName="custom-popper-zindex"
            dateFormat="P"
            disabledKeyboardNavigation
            monthsShown={2}
            selected={dateRange?.from || undefined}
            onChange={([start, end]: any) => {
              setDateRange({ from: start, to: end, preset: "customRange" });
            }}
            startDate={dateRange?.from}
            endDate={dateRange?.to}
            maxDate={yesterday} // Date is refreshed every night, so we show the user that we don't have any data for the current day
            selectsRange
            inline
          />
        </div>
        <Dropdown.Divider className="mt-0 border-top-secondary" />
        <div className="d-flex justify-content-end px-6 py-2">
          <button className="btn btn-sm btn-primary" onClick={applyChange}>
            <FormattedMessage id="COMMON.ACTION.APPLY" />
          </button>
        </div>
      </Dropdown.Menu>
    </Dropdown>
  );
};
