import * as React from "react";
import { IProject } from "../../../../../../../data/schemas";
import {
  CurrencyMetricCard,
  MetricCard,
  MetricCardProps,
} from "../../../../../../_components/MetricCard";
import { useBudgetsUIContext } from "../../../../BudgetsUIContext";

export interface ActualInvoicedBudgetCardProps {
  canSeeHiddenLine: boolean;
  actualBudget: number;
  project: Partial<IProject>;
}

export const ActualInvoicedBudgetCard: React.FC<ActualInvoicedBudgetCardProps & MetricCardProps> =
  ({ canSeeHiddenLine, project, actualBudget, ...props }) => {
    const {
      budgetTotals: { invoiced = 0, discounts = 0 },
    } = useBudgetsUIContext();

    const totalInvoicedPercentage =
      invoiced && actualBudget ? (invoiced / (actualBudget - discounts)) * 100 : 0;
    return (
      <CurrencyMetricCard
        dataCy="input-budget-invoiced-total"
        value={invoiced}
        label="BUDGET.ACTUAL_BUDGET_INVOICED"
        {...props}
      >
        <MetricCard.PercentageSubMetric
          dataCy="input-budget-invoiced-total-percentage"
          value={totalInvoicedPercentage}
        />
      </CurrencyMetricCard>
    );
  };
