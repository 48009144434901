import { Auth } from "@aws-amplify/auth";

import * as React from "react";
import { useIntl } from "react-intl";

import { useAppSelector } from "redux/hooks";
import { shallowEqual } from "react-redux";

import { WS_ENDPOINT } from "../../../configuration";
import * as actions from "../../modules/Notifications/_redux/inAppNotificationsActions";
import { addNewChat, addNewChatMessage } from "../Chat/_redux/chatActions";
import { useAppDispatch } from "../../../redux/hooks";
import { fetchAction } from "../PropertiesManagement/_redux/forms/formsActions";
import * as userActions from "../UsersManagement/_redux/usersActions";

export interface WebsocketContextValue {
  latestMessage: any;
  setLatestMessage: (message: any) => void;
}

export const WebsocketContext = React.createContext<WebsocketContextValue>({
  latestMessage: undefined,
  setLatestMessage: (_) => undefined,
});

export const WebsocketProvider: React.FunctionComponent = ({ children }) => {
  const intl = useIntl();
  const dispatch = useAppDispatch();

  const [connectionOpen, setConnectionOpen] = React.useState(false);

  const [latestMessage, setLatestMessageInternal] = React.useState<boolean>();

  const { groups, session } = useAppSelector(
    (state) => ({
      groups: state.auth.groups,
      session: state.auth.session,
    }),
    shallowEqual
  );

  const setLatestMessage = (latestMessage: any) => {
    setLatestMessageInternal(latestMessage);
  };

  React.useEffect(() => {
    if (!connectionOpen) {
      Auth.currentUserInfo()
        .then((userInfo) => {
          if (userInfo?.attributes?.sub) {
            const userId = userInfo.attributes?.sub;

            dispatch(actions.fetchLatestNotifications({ intl, userId, groups, session }));

            const websocket = new WebSocket(`${WS_ENDPOINT}?user=${userId}`);

            websocket.onopen = (ev) => {
              console.log("websocket.onopen", new Date().toISOString());
              setConnectionOpen(true);
            };

            websocket.onclose = (ev) => {
              console.log("websocket.onclose", new Date().toISOString());
              setConnectionOpen(false);
            };

            websocket.onmessage = (ev) => {
              console.log("websocket.onmessage");
              console.log(ev);

              const data = JSON.parse(ev.data);

              console.log(data);

              switch (data.type) {
                case "REFRESH_IN_APP_NOTIFICATION":
                  dispatch(actions.fetchLatestNotifications({ intl, userId, groups, session }));
                  break;
                case "ADD_NEW_CHAT":
                  dispatch(addNewChat(data.parameters));
                  break;
                case "ADD_NEW_CHAT_MESSAGE":
                  dispatch(addNewChatMessage(data.parameters));
                  break;
                case "DOCUMENT_GENERATED":
                  if (data?.parameters?.file?.userId && data?.parameters?.action?.id) {
                    dispatch(
                      fetchAction(data?.parameters?.file?.userId, data?.parameters?.action?.id)
                    );
                  }
                  break;
                case "REFRESH_USER_RIGHTS":
                  //When this websocket message has been received by a specific user,
                  //it basically means someone modified his rights(-table).
                  //In this case, we force refresh of the frontend of that user to ensure that
                  //changes will be reflected directly
                  //Backend implementation -> check createUsersRight.ts for additional information
                  dispatch(userActions.fetchUserMe());
                  window.location.reload();
                  break;

                /*
                 alternative mode, set the latest message, which then can be consumed via
                 const { latestMessage } = useWebsocket(); from within a component
                 example can be found in KycContentContainer.tsx
                 */
                case "REFRESH_KYC_FLOW_COMPLIANCE":
                case "REFRESH_GENERATED_FILES":
                case "REFRESH_KYC_FILE":
                case "REFRESH_KYC_FILE_UPDATED":
                case "REFRESH_KYC_FILE_UPLOADED":
                case "REFRESH_KYC_FILE_DELETED":
                case "GENERATING_KYC_DOCUMENTS":
                case "GENERATING_BO_DECLARATIONS":
                case "KYC_ZIP_PACKAGE_CREATED":
                case "KYC_ZIP_PACKAGE_IN_PROGRESS":
                case "ADD_TAG":
                case "UPDATE_TAG":
                case "REMOVE_TAG":
                case "ADD_TAG_LINK":
                case "REMOVE_TAG_LINK":
                  setLatestMessage(data);
                  break;

                default:
                  break;
              }
            };

            websocket.onerror = (ev) => {
              console.log("websocket.onerror");
              console.log(ev);
            };
          }
        })
        .catch();
    }
  }, [connectionOpen]);

  return (
    <WebsocketContext.Provider
      value={{
        latestMessage,
        setLatestMessage,
      }}
    >
      {children}
    </WebsocketContext.Provider>
  );
};

export const WebsocketConsumer = WebsocketContext.Consumer;
