import React from "react";
import cn from "clsx";
import { accurateFloatOperation } from "../../_utils/mathUtils";
import { ColumnFormatter } from "react-bootstrap-table-next";

interface FormatExtraDataProps {
  numerator: number;
  denominator: number;
}

export const ProgressBarFormatter: ColumnFormatter<any, FormatExtraDataProps> = (
  cell,
  row,
  rowIndex,
  formatExtraData
) => {
  if (formatExtraData) {
    const { numerator, denominator } = formatExtraData;
    const progress = accurateFloatOperation(((numerator ?? 0) / denominator) * 100, 0) ?? 0;
    return denominator ? (
      <div className="d-flex flex-column w-100 mr-2">
        <div className="d-flex align-items-center justify-content-between mb-2">
          <span className="text-muted mr-2 font-size-sm font-weight-bold">{progress}%</span>
        </div>
        <div className="progress progress-xs w-100">
          <div
            className={cn(
              "progress-bar",
              progress < 30 ? "bg-danger" : progress < 60 ? "bg-warning" : "bg-success"
            )}
            role="progressbar"
            style={{ width: `${progress}%` }}
          />
        </div>
      </div>
    ) : (
      <>-</>
    );
  }
};

interface ActionsColumnFormatterComponentProps {
  rowIndex?: number;
  formatExtraData: FormatExtraDataProps;
}

export const ProgressBarFormatterComponent: React.FC<ActionsColumnFormatterComponentProps> = ({
  rowIndex,
  formatExtraData,
}) => <>{ProgressBarFormatter(undefined, undefined, rowIndex ?? -1, formatExtraData)}</>;
