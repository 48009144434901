import { Pagination } from "../../../../../../_metronic/_partials/controls";
import {
  NoRecordsFoundMessage,
  sortCaret,
  toAbsoluteUrl,
} from "../../../../../../_metronic/_helpers";
import React from "react";
import { canEdit } from "../../../../../_utils/authUtils";
import { useAppSelector } from "../../../../../../redux/hooks";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, { PaginationProvider } from "react-bootstrap-table2-paginator";
import { FormattedMessage, useIntl } from "react-intl";
import { useEntityDeleteDialogContext } from "../../../../PropertiesManagement/pages/entity-delete-dialog/EntityDeleteDialogContext";
import * as actions from "../../../../PropertiesManagement/_redux/projects/projectsActions";
import { NumberInput } from "../../../../../_utils/formUtils";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import SVG from "react-inlinesvg";
import { useCustomLocationState } from "../../../../../_utils/useCustomLocationState";
import { useSubheader } from "../../../../../../_metronic/layout";
import { accurateFloatOperation } from "../../../../../_utils/mathUtils";
import { useUsersUIContext } from "../../../../PropertiesManagement/pages/users/UsersUIContext";
import { formatDisplayNameIntl } from "../../../../../_utils/userUtils";
import * as uiHelpers from "../../../../PropertiesManagement/pages/users/UsersUIHelpers";
import { ProgressBarFormatter } from "../../../../../_components/column-formatters";
import { SUFFIX_EURO_CURRENCY } from "../../../../../_utils/suffixUtils";

export function SubcontractorProjectsTable() {
  const intl = useIntl();
  const { readOnly, filters } = useUsersUIContext();
  const { user, groups, session } = useAppSelector((state: any) => ({
    user: state.users.entityForEdit?.current,
    groups: state.auth.groups,
    session: state.auth.session,
  }));

  const { setDeleteEntityDialog } = useEntityDeleteDialogContext();
  const { setFromUrlTo } = useCustomLocationState();
  const subheader = useSubheader();

  const openProjectPage = (row: any) => {
    if (
      (typeof session.config?.hasProjectAccess === "boolean" && session.config?.hasProjectAccess) ||
      session.config?.hasProjectAccess?.find((projectId: string) => projectId === row.projectId)
    ) {
      subheader.setTitle(row.name);
      setFromUrlTo({ url: `/projects/${row.projectId}`, name: formatDisplayNameIntl(intl, user) });
    }
  };

  const openDeleteProjectSubcontractor = React.useCallback(
    (id: string) => (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      e.preventDefault();
      e.stopPropagation();

      setDeleteEntityDialog({
        action: {
          fn: actions.removeProjectSubcontractor,
          props: { projectId: id, userId: (user as any).id },
        },
        entityType: "PROJECT_SUBCONTRACTOR",
        customSubmitLabel: "COMMON.ACTION.REMOVE",
      });
    },
    [user]
  );

  // Table columns
  const columns = [
    {
      dataField: "projectId",
      text: "",
      sort: false,
      hidden: true,
    },
    {
      dataField: "name",
      text: intl.formatMessage({
        id: "COMMON.NAME",
      }),
      sort: true,
      sortCaret: sortCaret,
    },
    {
      dataField: "quotes",
      text: intl.formatMessage({
        id: "FINANCE.QUOTES",
      }),
      sort: true,
      sortCaret: sortCaret,
      sortValue: (cell: number | undefined) => cell ?? "-",
      formatter: (cell: number | undefined) => {
        return typeof cell !== "undefined" ? (
          <NumberInput
            value={cell}
            displayType="text"
            decimalScale={2}
            suffix={SUFFIX_EURO_CURRENCY}
          />
        ) : (
          <>-</>
        );
      },
    },
    {
      dataField: "orders",
      text: intl.formatMessage({
        id: "FINANCE.ORDERS",
      }),
      sort: true,
      sortCaret: sortCaret,
      sortValue: (cell: number | undefined) => cell ?? "-",
      formatter: (cell: number | undefined) => {
        return typeof cell !== "undefined" ? (
          <NumberInput
            value={cell}
            displayType="text"
            decimalScale={2}
            suffix={SUFFIX_EURO_CURRENCY}
          />
        ) : (
          <>-</>
        );
      },
    },
    {
      dataField: "supplementary_agreements",
      text: intl.formatMessage({
        id: "BUDGET.SUPPLEMENTARY_AGREEMENTS",
      }),
      sort: true,
      sortCaret: sortCaret,
      sortValue: (cell: number | undefined) => cell ?? "-",
      formatter: (cell: number | undefined) => {
        return typeof cell !== "undefined" ? (
          <NumberInput
            value={cell}
            displayType="text"
            decimalScale={2}
            suffix={SUFFIX_EURO_CURRENCY}
          />
        ) : (
          <>-</>
        );
      },
    },
    {
      dataField: "invoices",
      text: intl.formatMessage({
        id: "FINANCE.INVOICES",
      }),
      sort: true,
      sortCaret: sortCaret,
      sortValue: (cell: number | undefined) => cell ?? "-",
      formatter: (cell: number | undefined) => {
        return typeof cell !== "undefined" ? (
          <NumberInput
            value={cell}
            displayType="text"
            decimalScale={2}
            suffix={SUFFIX_EURO_CURRENCY}
          />
        ) : (
          <>-</>
        );
      },
    },
    {
      dataField: "progress",
      text: intl.formatMessage({
        id: "BUDGET.INVOICED_PERCENT",
      }),
      sort: true,
      sortValue: (cell: any, row: any) =>
        row.orders || row.supplementary_agreements
          ? accurateFloatOperation(
              ((row.invoices ?? 0) / ((row.orders ?? 0) + (row.supplementary_agreements ?? 0))) *
                100,
              0
            )
          : -1,
      sortCaret: sortCaret,
      formatter: (cell: any, row: any, index: number) =>
        ProgressBarFormatter(cell, row, index, {
          numerator: row.invoices,
          denominator:
            (row.orders || row.supplementary_agreements) &&
            (row.orders ?? 0) + (row.supplementary_agreements ?? 0),
        }),
    },
    {
      dataField: "action",
      text: intl.formatMessage({
        id: "COMMON.ACTION",
      }),
      formatter: (_: any, row: any) => (
        <OverlayTrigger
          overlay={
            <Tooltip id="project-delete-tooltip">
              <FormattedMessage id="COMMON.ACTION.DELETE" />
            </Tooltip>
          }
        >
          <button
            className="btn btn-icon btn-light btn-hover-danger btn-sm"
            data-cy="button-project-subcontractors-delete-row"
            onClick={openDeleteProjectSubcontractor(row.projectId)}
          >
            <span className="svg-icon svg-icon-md svg-icon-danger">
              <SVG src={toAbsoluteUrl("/media/svg/icons/General/Trash.svg")} />
            </span>
          </button>
        </OverlayTrigger>
      ),
      classes: "text-right",
      headerClasses: "text-right",
      style: {
        minWidth: "100px",
      },
      hidden: readOnly || !canEdit(groups, session, "PROJECT"),
    },
  ];

  // Table pagination properties
  const paginationOptions = {
    custom: true,
    totalSize: user?.projects?.length,
    sizePerPageList: uiHelpers.sizePerPageList,
    sizePerPage: filters.pageSize,
    page: filters.pageNumber,
  };

  return (
    <>
      <PaginationProvider pagination={paginationFactory(paginationOptions)}>
        {({ paginationProps, paginationTableProps }) => {
          return (
            <Pagination paginationProps={paginationProps}>
              <BootstrapTable
                {...paginationTableProps}
                wrapperClasses="table-responsive"
                classes="table table-head-custom table-vertical-center"
                bootstrap4
                bordered={false}
                keyField="projectId"
                data={user?.projects || []}
                columns={columns}
                defaultSorted={[{ dataField: "name", order: "asc" }]}
                noDataIndication={() => <NoRecordsFoundMessage entities={user?.projects?.length} />}
                hover
                rowEvents={{
                  onClick: (e, row, rowIndex) => {
                    openProjectPage(row);
                  },
                }}
                rowStyle={{ cursor: "pointer" }}
              />
            </Pagination>
          );
        }}
      </PaginationProvider>
    </>
  );
}
