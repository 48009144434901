import * as React from "react";

import { ISignatureInfo } from "data/schemas";

import { CardPDFSignedViewer } from "app/_components/CardPDFSignedViewer";

import { usePublicRequests } from "app/modules/Requests/hooks/usePublicRequests";

interface UnAuthPdfViewerProps {
  signature: Partial<ISignatureInfo>;
}

export const CardPDFSignedViewerPublicWrapper: React.FC<UnAuthPdfViewerProps> = ({ signature }) => {
  const { file } = usePublicRequests();

  return <CardPDFSignedViewer fileUrl={file?.url} signatureInfo={signature} />;
};
