import { IntlShape } from "react-intl";
import { kebabCase, endsWith } from "lodash-es";

import { TInvoiceStatus, paymentStatusStyle } from "data/schemas";

import { exportCSV } from "app/_utils/fileUtils";
import { SUFFIX_EURO_CURRENCY, SUFFIX_PERCENTAGE } from "app/_utils/suffixUtils";

import { percentageColumns, currencyColumns, LEAD_CALCULATED_ID } from "../definitions";
import { LeadInstalmentsTableRow } from "./definitions";

//----------------------------------------------------------------------------//

type CSVValue = string | number;

const outputString = (value: string) => `"${value}"`;

//----------------------------------------------------------------------------//

interface GenerateCSVOptions {
  VATColumnValue: Record<string, number>;
  headers: Record<string, string>;
  tableData: LeadInstalmentsTableRow[];
  intl: IntlShape;
}

export const generateCSV = ({ VATColumnValue, headers, tableData, intl }: GenerateCSVOptions) => {
  const filename = kebabCase(intl.formatMessage({ id: "LEAD.FINANCIAL_OVERVIEW" }));

  const locale = intl.locale;

  const entries = Object.entries(headers);

  const overviewHeaderLabels = entries.map(([attr, id]) =>
    outputString(intl.formatMessage({ id }, { vat: VATColumnValue[attr] }))
  );

  const detailsHeaderLabels = [
    outputString(intl.formatMessage({ id: "COMMON.TYPE" })),
    overviewHeaderLabels[0],
    outputString(intl.formatMessage({ id: "COMMON.NAME" })),
    ...overviewHeaderLabels.slice(1),
  ];

  const instalmentLabel = intl.formatMessage({ id: "BUDGET.INSTALMENT.SINGLE" });
  const supplementLabel = intl.formatMessage({ id: "BUDGET.REVENUE.SUPPLEMENT" });

  const overviewName = `${kebabCase(intl.formatMessage({ id: "COMMON.OVERVIEW" }))}_${filename}`;

  const detailsName = `${kebabCase(intl.formatMessage({ id: "COMMON.DETAILS" }))}_${filename}`;

  //---===---//

  const NumberFormat = new Intl.NumberFormat(locale, {
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
  });

  const processOverviewRow = (row: LeadInstalmentsTableRow, attributes = Object.keys(headers)) => {
    const csvRow: CSVValue[] = [];

    for (const attr of attributes) {
      let value = (row as any)[attr];
      if (attr === "paymentStatus") {
        value = paymentStatusStyle[value as TInvoiceStatus];
        value = value ? intl.formatMessage({ id: value.translationKey }) : "";
        value = outputString(value);
      } else if (percentageColumns.includes(attr)) {
        value = outputString(
          value !== undefined ? `${NumberFormat.format(value)}${SUFFIX_PERCENTAGE}` : ""
        );
      } else if (currencyColumns.includes(attr)) {
        value = outputString(
          value !== undefined ? `${NumberFormat.format(value)}${SUFFIX_EURO_CURRENCY}` : ""
        );
      } else {
        value = outputString(value || "");
      }

      csvRow.push(value);
    }

    return csvRow;
  };

  const transformOverviewRowToDetailsRow = (
    ovewviewRow: CSVValue[],
    type: string = instalmentLabel,
    description: CSVValue = ovewviewRow[0]
  ) => [type, description, ...ovewviewRow];

  //---===---//

  const totalRow = tableData[0];

  let csvOverviewRows: CSVValue[][] = [overviewHeaderLabels, processOverviewRow(totalRow)];
  let csvDetailsRows: CSVValue[][] = [detailsHeaderLabels];

  const CALCULATED_IDS = [
    LEAD_CALCULATED_ID.SHARE_OF_LAND,
    LEAD_CALCULATED_ID.ARCHITECT_ENGINEERING_FEES,
  ];

  if (totalRow.children && totalRow.children.length > 0) {
    let overviewRow: CSVValue[];
    for (const row of totalRow.children) {
      overviewRow = processOverviewRow(row);
      csvOverviewRows.push(overviewRow);

      const { id, label: description } = row;

      if (CALCULATED_IDS.includes(id)) {
        csvDetailsRows.push(transformOverviewRowToDetailsRow(overviewRow));
        continue;
      }

      if (!row.children) {
        continue;
      }

      const isTotalSupplement = id === LEAD_CALCULATED_ID.TOTAL_SUPPLEMENT;

      for (const child of row.children) {
        const type = isTotalSupplement
          ? supplementLabel
          : endsWith(child.id, "instalment")
          ? instalmentLabel
          : supplementLabel;
        overviewRow = processOverviewRow(child);
        csvDetailsRows.push(transformOverviewRowToDetailsRow(overviewRow, type, description));
      }
    }
  }

  //---===---//

  exportCSV(overviewName, csvOverviewRows);
  exportCSV(detailsName, csvDetailsRows);
};
