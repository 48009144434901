import * as React from "react";
import { useBudgetRevenueContext } from "../../../context/BudgetRevenueContext";
import {
  MetricCard,
  MetricCardProps,
  NumberMetricCard,
} from "../../../../../../_components/MetricCard";

export const UnitsOverview: React.FC<MetricCardProps> = (props) => {
  const {
    productsQty: { total, sold, unsold },
  } = useBudgetRevenueContext();
  return (
    <NumberMetricCard
      dataCy="input-budget-revenues-units-overview"
      value={total}
      label="BUDGET.REVENUE.UNITS_OVERVIEW"
      {...props}
    >
      <MetricCard.NumberSubMetric
        dataCy="input-budget-revenues-units-overview-sold"
        label="BUDGET.REVENUE.SOLD"
        value={sold}
      />
      <MetricCard.NumberSubMetric
        dataCy="input-budget-revenues-units-overview-unsold"
        label="BUDGET.REVENUE.UNSOLD"
        value={unsold}
      />
    </NumberMetricCard>
  );
};
