import React from "react";
import { KycFlow } from "../Kyc";
import { getFlowTypeLabel, getStatusPropertiesShort, KycStatusMode } from "../KycTranslationUtils";
import { useTranslation } from "../../../hooks/useTranslation";
import cn from "clsx";
import { useHistory } from "react-router-dom";

interface KycLeadColumnFormatterProps {
  kycFlow?: KycFlow;
}

export const KycLeadColumnFormatter = ({ kycFlow }: KycLeadColumnFormatterProps): JSX.Element => {
  const { fm } = useTranslation();
  const history = useHistory();

  const openKycFlow = (e: any) => {
    e.stopPropagation();
    //redirects to to client's compliance tab
    kycFlow?.clientId && kycFlow?.id && history.push(`/clients/${kycFlow.clientId}?t=compliance`);
    //Note: In case business changes its mind and requests to redirect to kyc flow directly instead of compliance tab, use:
    //history.push(`/clients/${kycFlow.clientId}/kyc/${kycFlow.id}`);
  };

  const status = kycFlow && getStatusPropertiesShort(kycFlow?.status, KycStatusMode.KYC_FLOW, fm);
  return (
    <>
      {kycFlow && status ? (
        <div className="d-flex justify-content-left" onClick={openKycFlow}>
          {kycFlow.flowType && (
            <div className="align-self-end">{getFlowTypeLabel(kycFlow.flowType, fm)}</div>
          )}
          <span className={cn("label label-lg label-inline ml-4", status.color)}>
            {status.label}
          </span>
        </div>
      ) : (
        "-"
      )}
    </>
  );
};
