import { Row } from "@tanstack/react-table";

export interface CardTableCollapseIndicatorProps {
  row: Row<any>;
}

export const CardTableCollapseIndicator = ({ row }: CardTableCollapseIndicatorProps) => (
  <button
    type="button"
    className="btn btn-icon btn-link-primary btn-xs flex-shrink-0"
    tabIndex={-1}
  >
    <i
      className="ki ki-arrow-down icon-nm"
      style={{
        transform: !row.getIsExpanded() ? "rotate(-90deg)" : "",
        transition: "transform .2s ease",
      }}
    />
  </button>
);
