import * as React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { Error404 } from "./Error404";
import { Error500 } from "./Error500";
import { NetworkError } from "./NetworkError";

export const ErrorsPage = () => (
  <Switch>
    <Redirect from="/error" exact={true} to="/error/500" />
    <Route path="/error/404" component={Error404} />
    <Route path="/error/500" component={Error500} />
    <Route path="/error/network" component={NetworkError} />
    <Redirect to={"/error/404"} />
  </Switch>
);
