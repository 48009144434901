import { ColumnFormatter } from "react-bootstrap-table-next";
import cn from "clsx";

import { IAvatarPublicProps } from "data/schemas";

import { errorImageHandler } from "app/_components/AvatarImage";

interface FormatExtraDataProps {
  icon: string;
}

export const AvatarColumnFormatter: ColumnFormatter<IAvatarPublicProps, FormatExtraDataProps> = (
  cell,
  row,
  rowIndex,
  formatExtraData
) => {
  const hasPictureUrl = row?.avatarPictureSetup && row?.avatarPictureUrl;

  return (
    <>
      {formatExtraData && (
        <div
          className={cn("flex-shrink-0 symbol symbol-35 min-w-35px min-h-35px", {
            "bg-white-o-30": hasPictureUrl,
            "symbol-light-primary": !hasPictureUrl,
          })}
        >
          {hasPictureUrl ? (
            <img src={row.avatarPictureUrl} alt="Avatar" onError={errorImageHandler()} />
          ) : (
            <span className="symbol-label font-size-h5 font-weight-bold symbol-label">
              <i className={cn("fas text-info icon-md", formatExtraData.icon)} />
            </span>
          )}
        </div>
      )}
    </>
  );
};
