import * as React from "react";
import { shallowEqual, useDispatch } from "react-redux";
import { FormattedMessage, useIntl } from "react-intl";
import { cloneDeep } from "lodash-es";
import * as actions from "../../../../PropertiesManagement/_redux/projects/projectsActions";
import { useAppSelector } from "redux/hooks";
import { Card } from "_metronic/_partials/controls";
import { DateUtils } from "app/_utils/DateUtils";
import { canReadAll } from "app/_utils/authUtils";
import { SUFFIX_EURO_CURRENCY } from "app/_utils/suffixUtils";
import { ProgressBarFormatterComponent } from "app/_components/column-formatters";
import {
  AvatarPlaceholderClassName,
  EditAvatarImage,
  TRemoveAvatarFn,
  TSaveAvatarFn,
} from "app/_components/AvatarImage";
import * as projectsActions from "app/modules/PropertiesManagement/_redux/projects/projectsActions";
import * as tasksActions from "app/modules/PropertiesManagement/_redux/tasks/tasksActions";
import { ProjectWidget } from "../project-widget/ProjectWidget";
import ProjectOwnerSwitch from "./ProjectOwnerSwitch";
import { TagManager } from "../../../../../_components/TagManager";
import { PROJECT, TAG } from "../../../../../_utils/dataTypes";

export interface ProjectDashboardProps {
  readOnly?: boolean;
}

export const ProjectDashboard: React.FunctionComponent<ProjectDashboardProps> = ({
  readOnly = false,
}) => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const { projectForEdit, isLoading, groups, session, budget } = useAppSelector((state) => {
    const { projects, auth } = state;
    const { actionsLoading, projectForEdit } = projects;

    const currentProject = projectForEdit?.current;
    const selectedBudget = currentProject?.selectedBudget;

    const { groups, session } = auth;

    return {
      isLoading: actionsLoading,
      projectForEdit: currentProject,
      groups,
      session,
      budget: currentProject?.budgets?.find((budget) => budget.id === selectedBudget),
    };
  }, shallowEqual);

  const totalTasks = projectForEdit?.tasksCount ?? 0;

  // Set empty state to avoid confusion with tasks from other pages
  React.useEffect(() => {
    dispatch(tasksActions.taskFetched([]));
  }, []);

  const saveAvatarImage: TSaveAvatarFn = React.useCallback(
    (fileName, image, onAfterSave) => {
      if (!projectForEdit) return;

      const avatar = {
        projectId: projectForEdit.id,
        fileName,
        image,
      };

      projectsActions
        .setProjectAvatar(avatar)(dispatch)
        .then((response: { key: string }) => {
          onAfterSave();

          projectsActions.updateProject({
            ...projectForEdit,
            avatarPictureSetup: true,
            avatarPictureKey: response.key,
          })(dispatch);
        })
        .catch(console.error);
    },
    [projectForEdit]
  );

  const removeAvatarImage: TRemoveAvatarFn = (onAfterSave) => {
    dispatch(actions.removeProjectAvatar(cloneDeep(projectForEdit)));
    onAfterSave();
  };

  const badgeStyle =
    "btn btn-sm btn-text text-uppercase font-weight-bold d-flex justify-content-center";

  return (
    <Card>
      <div className="card-body">
        <div className="d-flex">
          <EditAvatarImage
            containerClassName="mr-7 mt-lg-0 mt-3"
            imageAltText="project avatar"
            isLoading={isLoading}
            isEditable={!readOnly}
            placeholderClassName={AvatarPlaceholderClassName.PROJECT}
            url={projectForEdit?.avatarPictureUrl}
            onSaveImage={saveAvatarImage}
            showAllowedFileTypesLabel={false}
            onRemoveImage={removeAvatarImage}
          />

          <div className="flex-grow-1">
            <div className="d-flex align-items-center justify-content-between flex-wrap">
              <div className="mr-3">
                <div className="d-flex align-items-center text-dark font-size-h5 font-weight-bold mr-3">
                  {!!projectForEdit?.name ? projectForEdit?.name : "-"}
                </div>

                {projectForEdit?.address?.city && (
                  <div className="d-flex flex-wrap my-2">
                    <div className="text-muted font-weight-bold">
                      <span className="svg-icon svg-icon-md svg-icon-gray-500 mr-1">
                        <i className={"fas fa-map-marker-alt icon-md"} />
                      </span>
                      {projectForEdit?.address?.city}
                    </div>
                  </div>
                )}
              </div>
            </div>

            <div className="d-sm-flex flex-wrap py-2 align-items-start justify-content-between">
              <div className="d-sm-flex flex-wrap align-items-center">
                <div className="d-sm-flex align-items-center justify-content-between mr-8 mb-4">
                  <div className="m-2">
                    <div className="font-weight-bold mb-4">
                      <FormattedMessage id={"COMMON.START_DATE"} />
                    </div>
                    <span className={`${badgeStyle} btn-light-primary`}>
                      {!!projectForEdit?.startDate
                        ? DateUtils.format(new Date(projectForEdit?.startDate), intl, false)
                        : "-"}
                    </span>
                  </div>

                  <div className="m-2">
                    <div className="font-weight-bold mb-4">
                      <FormattedMessage id={"PROJECT.INFO.AVAILABILITY_DATE"} />
                    </div>
                    <span className={`${badgeStyle} btn-light-facebook`}>
                      {!!projectForEdit?.availability
                        ? DateUtils.format(projectForEdit?.availability, intl, false)
                        : "-"}
                    </span>
                  </div>

                  {!!projectForEdit?.startDate && !!projectForEdit?.availability && (
                    <div className="m-2">
                      <div className="font-weight-bold mb-4">
                        <FormattedMessage id={"PROJECT.INFO.DURATION"} />
                      </div>
                      <span className={`${badgeStyle} btn-light-primary`}>
                        {DateUtils.diffBetweenDates(
                          new Date(projectForEdit?.startDate),
                          new Date(projectForEdit?.availability),
                          intl
                        )}
                      </span>
                    </div>
                  )}

                  {!!projectForEdit?.availability &&
                    new Date(projectForEdit?.availability) > new Date(Date.now()) && (
                      <div className="m-2">
                        <div className="font-weight-bold mb-4">
                          <FormattedMessage id={"PROJECT.INFO.TIME_LEFT"} />
                        </div>
                        <span className={`${badgeStyle} btn-light-primary`}>
                          {DateUtils.diffBetweenDates(
                            new Date(new Date().setHours(0, 0, 0, 0)),
                            new Date(projectForEdit?.availability),
                            intl
                          )}
                        </span>
                      </div>
                    )}
                </div>
              </div>

              {!!projectForEdit && (
                <div className="flex-grow-1 flex-shrink-0 min-w-200px max-w-250px mb-4">
                  <div className="font-weight-bold m-2 mb-4">
                    <FormattedMessage id="COMMON.PROGRESS" />
                  </div>
                  <div className="m-2" style={{ width: "80%" }}>
                    <ProgressBarFormatterComponent
                      formatExtraData={{
                        numerator: projectForEdit?.tasksDoneCount ?? 0, //totalTasks.filter((task) => task.status === "DONE").length,
                        denominator: totalTasks, //totalTasks.length,
                      }}
                    />
                  </div>
                </div>
              )}

              {!!projectForEdit && (
                <div className="flex-grow-1 flex-shrink-0 min-w-200px max-w-250px">
                  <ProjectOwnerSwitch readonly={readOnly} />
                </div>
              )}
            </div>
          </div>
        </div>

        <div className={"separator separator-solid my-7"} />

        <div className="d-flex align-items-start flex-wrap my-1">
          {budget?.plannedTotal !== undefined &&
            canReadAll(groups, session, "BUDGET") &&
            ProjectWidget({
              data: budget?.plannedTotal,
              icon: "flaticon-piggy-bank",
              label: "COMMON.PLANNED.EXPENSES",
              suffix: SUFFIX_EURO_CURRENCY,
            })}

          {projectForEdit?.subcontractorsFinanceFiles &&
            canReadAll(groups, session, "BUDGET") &&
            ProjectWidget({
              data:
                (projectForEdit?.subcontractorsFinanceFiles?.orders?.total?.general ?? 0) +
                (projectForEdit?.subcontractorsFinanceFiles?.supplementary_agreements?.total
                  ?.general ?? 0),
              icon: "flaticon-statistics",
              label: "COMMON.REAL.EXPENSES",
              suffix: SUFFIX_EURO_CURRENCY,
            })}

          {!!projectForEdit !== undefined &&
            ProjectWidget({
              data: totalTasks, //totalTasks.length,
              icon: "flaticon-file-2",
              label: totalTasks > 1 ? "TASKS.COUNT" : "TASK.COUNT",
            })}

          {projectForEdit?.products &&
            ProjectWidget({
              data: projectForEdit?.products?.length,
              icon: "flaticon-home-2",
              label: "COMMON.PROPERTIES",
            })}

          {!!projectForEdit && projectForEdit.id && canReadAll(groups, session, TAG) && (
            <div className="d-flex align-items-start my-2 flex-grow-1 col">
              <TagManager
                displayLogo={true}
                relatedTo={`${PROJECT}#${projectForEdit.id}`}
                readOnly={readOnly}
                className={"d-flex flex-direction-row"}
              />
            </div>
          )}
        </div>
      </div>
    </Card>
  );
};

export default ProjectDashboard;
