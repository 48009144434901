import * as React from "react";
import cn from "clsx";
import { FormattedMessage } from "react-intl";
import { useToggle } from "rooks";

import "./SubtasksProgress.scss";

// Percentage = ( value * 100 ) / total
const calculatePercentage = (total: number, value: number) => Math.ceil((value * 100) / total);

export interface SubtasksProgressProps {
  list?: any;
  progressClassName: string;
  statusClassName: string;
  numberClassName: string;
}

const InnerSubtasksProgress: React.FunctionComponent<SubtasksProgressProps> = ({
  list = [],
  progressClassName,
  statusClassName,
  numberClassName,
}) => {
  const [showNumbers, toggleShowNumbers] = useToggle(false);

  let doneProgress = 0;
  let doneProgressAmount = 0;

  const length = list.length;

  const progressAmounts = list.reduce(
    (map: any, subtask: any) => {
      const { status = "" } = subtask;

      if (status && map.hasOwnProperty(status)) {
        map[status] += 1;
      }
      return map;
    },
    {
      DONE: 0,
      STUCK: 0,
      IN_PROGRESS: 0,
      PLANNED: 0,
    }
  );

  const progressPercentages =
    length > 0
      ? Object.entries<number>(progressAmounts).map((entry) => {
          const [name, value] = entry;
          const percentage = calculatePercentage(length, value);
          if (name === "DONE") {
            doneProgress = percentage;
            doneProgressAmount = value;
          }
          return [name, percentage, value];
        })
      : [];

  const buildBar = (className: string, progress = 0, progressAmount = 0) => {
    className = className.toUpperCase();
    const props: Record<string, unknown> = { key: className, className };
    if (className !== "PLANNED" && progress > 0) {
      const progressStr = `${progress}%`;
      props[`data-progress`] = progressStr;
      props[`data-progress-amount`] = `${progressAmount}`;
      props["style"] = {
        [`--progress`]: progressStr,
      };
    } else {
      props[`data-progress`] = "";
      props[`data-progress-amount`] = "";
    }
    return progress > 0 ? <div {...props} /> : null;
  };

  return (
    <>
      <div className={progressClassName}>
        <div
          className={cn("stackedbars", {
            numbers: showNumbers,
            percentages: !showNumbers,
          })}
          onClick={toggleShowNumbers}
        >
          {progressPercentages.map(([className, progress, progressAmount]) =>
            buildBar(className as string, progress as number, progressAmount as number)
          )}
        </div>
      </div>

      {length > 0 && (
        <div className={statusClassName}>
          <div className={cn(numberClassName, { percentage: !showNumbers })}>
            {!showNumbers ? doneProgress : `${doneProgressAmount}/${length}`}
          </div>
          <div>
            <FormattedMessage id="COMMON.ACTION.DONE" />
          </div>
        </div>
      )}
    </>
  );
};

// avoid unnecessary re-render
export const SubtasksProgress = React.memo(InnerSubtasksProgress);

export default SubtasksProgress;
