import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as budgetActions from "../../../../Budget/_redux/budgetsActions";
import * as leadsActions from "../../../_redux/leads/leadsActions";
import { TilesWidget11 } from "../../../../../../_metronic/_partials/widgets";
import { NumberInput } from "../../../../../_utils/formUtils";
import { sumBy } from "lodash-es";
import { useHtmlClassService } from "../../../../../../_metronic/layout";
import objectPath from "object-path";
import { accurateFloatOperation, formatCurrency } from "../../../../../_utils/mathUtils";
import { RadialBarChart } from "./RadialBarChart";
import { FormattedMessage, useIntl } from "react-intl";
import { isBudgetServiceAvailable } from "../../../../../_utils/configUtils";
import { SUFFIX_EURO_CURRENCY, SUFFIX_PERCENTAGE } from "../../../../../_utils/suffixUtils";
import { FinanceFileType } from "../../../../../../data/schemas";

export function ProjectAnalysis({ project }) {
  const intl = useIntl();
  const isBudgetAvailable = React.useMemo(() => isBudgetServiceAvailable(), []);
  const uiService = useHtmlClassService();
  const layoutProps = useMemo(() => {
    return {
      colorsGrayGray500: objectPath.get(uiService.config, "js.colors.gray.gray500"),
      colorsGrayGray200: objectPath.get(uiService.config, "js.colors.gray.gray200"),
      colorsGrayGray300: objectPath.get(uiService.config, "js.colors.gray.gray300"),
      colorsThemeBasePrimary: objectPath.get(uiService.config, "js.colors.theme.base.primary"),
      colorsThemeBaseWarning: objectPath.get(uiService.config, "js.colors.theme.base.warning"),
      colorsThemeBaseSuccess: objectPath.get(uiService.config, "js.colors.theme.base.success"),
      colorsThemeBaseDanger: objectPath.get(uiService.config, "js.colors.theme.base.danger"),
      colorsThemeBaseInfo: objectPath.get(uiService.config, "js.colors.theme.base.info"),
      colorsThemeInversePrimary: objectPath.get(
        uiService.config,
        "js.colors.theme.inverse.primary"
      ),
      colorsThemeLightPrimary: objectPath.get(uiService.config, "js.colors.theme.light.primary"),
      fontFamily: objectPath.get(uiService.config, "js.fontFamily"),
    };
  }, [uiService]);

  const { budget, leads } = useSelector((state) => ({
    budget: state.budgets?.budgetForEdit?.saved,
    leads: state.leads.entities,
  }));

  const [plannedTotal, setPlannedTotal] = useState();
  const [loading, setLoading] = useState(false);
  const [budgetLoaded, setBudgetLoaded] = useState(false);
  const [leadsLoaded, setLeadsLoaded] = useState(false);
  //TODO: this part has been commented because we need to fix the chart with this task: IMT-778

  // const lastMonths = ["Apr", "May", "Jun", "Jul", "Aug", "Sep"];
  const [analysisValues, setAnalysisValues] = useState();
  const dispatch = useDispatch();
  useEffect(() => {
    setLoading(true);
    setAnalysisValues(undefined);

    if (isBudgetAvailable) {
      Promise.resolve(dispatch(budgetActions.fetchBudget(project.selectedBudget))).then(() =>
        setBudgetLoaded(true)
      );
    } else {
      setBudgetLoaded(true);
    }

    Promise.resolve(dispatch(leadsActions.fetchLeads({ projectId: project.id }))).then(() =>
      setLeadsLoaded(true)
    );
  }, [project]);

  useEffect(() => {
    if (project && budgetLoaded && leadsLoaded) {
      const plannedTotal = budget
        ? sumBy(budget?.sortedLines, ({ lines }) => sumBy(lines, "plannedBudget"))
        : 0;
      setPlannedTotal(plannedTotal);
      // Project part
      let expenses = project.subcontractorsFinanceFiles?.invoices?.total?.general || 0;
      let real =
        (project.subcontractorsFinanceFiles?.orders?.total?.general || 0) +
        (project.subcontractorsFinanceFiles?.supplementary_agreements?.total?.general || 0);
      let invoicesOut = 0;
      const leadsInvoices = project.leadsFinancialDocuments.filter(
        (financialDocument) => financialDocument.fileType === FinanceFileType.INVOICE
      );
      for (const leadInvoice of leadsInvoices) {
        invoicesOut += sumBy(leadInvoice.content, "amount");
      }

      // Lead part
      let currentProjectValue = 0;
      let wonLeads = 0;
      let qualifiedLeads = 0;
      let openLeads = 0;
      let lostLeads = 0;
      for (const lead of leads) {
        if (lead.status === "WON") {
          currentProjectValue += lead.sellingPrice;
          wonLeads++;
        } else if (lead.status === "QUALIFIED") {
          qualifiedLeads++;
        } else if (lead.status === "OPEN") {
          openLeads++;
        } else if (lead.status === "LOST") {
          lostLeads++;
        }
      }
      const leadStatusSeries = [
        {
          name: intl.formatMessage({ id: "FINANCE.STATUS.WON" }),
          data: [0, 0, 0, 0, 0, wonLeads],
        },
        {
          name: intl.formatMessage({ id: "FINANCE.STATUS.QUALIFIED" }),
          data: [0, 0, 0, 0, 0, qualifiedLeads],
        },
        {
          name: intl.formatMessage({ id: "FINANCE.STATUS.OPEN" }),
          data: [0, 0, 0, 0, 0, openLeads],
        },
        {
          name: intl.formatMessage({ id: "FINANCE.STATUS.LOST" }),
          data: [0, 0, 0, 0, 0, lostLeads],
        },
      ];

      const invoicesInToDate = accurateFloatOperation((expenses / real) * 100, 2) || 0;
      const invoicesOutToDate =
        accurateFloatOperation((invoicesOut / currentProjectValue) * 100, 2) || 0;

      // Profit
      const profit = currentProjectValue - expenses;
      const margin = currentProjectValue
        ? accurateFloatOperation((profit / currentProjectValue) * 100, 2)
        : "-";

      const listingPrice = project.products.length
        ? sumBy(project.products, "financialDetails.price")
        : "-";
      let plannedProfit;
      let plannedMargin;
      if (budget && listingPrice !== "-") {
        plannedProfit = listingPrice - plannedTotal;
        plannedMargin = listingPrice
          ? accurateFloatOperation((plannedProfit / listingPrice) * 100, 2)
          : "-";
      } else {
        plannedProfit = "-";
        plannedMargin = "-";
      }

      const profitChartSeries = [
        {
          name: intl.formatMessage({ id: "FINANCE.VALUE.PROJECT" }),
          data: [0, 0, 0, 0, 0, currentProjectValue],
        },
      ];

      if (isBudgetAvailable) {
        profitChartSeries.push({
          name: intl.formatMessage({ id: "FINANCE.BUDGET.REAL" }),
          data: [0, 0, 0, 0, 0, real],
        });
      }

      setAnalysisValues({
        ...analysisValues,
        expenses,
        real,
        invoicesOut,
        currentProjectValue,
        leads: {
          wonLeads,
          qualifiedLeads,
          lostLeads,
          openLeads,
          leadStatusSeries,
        },
        invoicesInToDate,
        invoicesOutToDate,
        profit,
        margin,
        listingPrice,
        plannedProfit,
        plannedMargin,
        profitChartSeries,
      });
      setLoading(false);
    }
  }, [project, budget, leads, budgetLoaded, leadsLoaded]);

  return (
    <div className="mt-5">
      {loading && (
        <div className="d-flex flex-column align-items-center">
          <span className={"spinner spinner-lg spinner-primary h-30px w-30px mb-2"} />
          <span>
            <FormattedMessage id="COMMON.LOADING" />
          </span>
        </div>
      )}
      {analysisValues && (
        <>
          <h3 className="mb-4">
            <FormattedMessage id="FINANCE.FIGURES.REAL" />
          </h3>
          <div className="row mb-8">
            <div className="col-lg-4 gutter-b">
              <TilesWidget11
                title={
                  <NumberInput
                    value={analysisValues.currentProjectValue}
                    displayType="text"
                    suffix={SUFFIX_EURO_CURRENCY}
                    decimalScale={2}
                    className="ml-auto font-weight-bold"
                  />
                }
                subtitle={intl.formatMessage({ id: "FINANCE.VALUE.PROJECT" })}
              />
            </div>
            {isBudgetAvailable && (
              <div className="col-lg-4 gutter-b">
                <TilesWidget11
                  title={
                    <NumberInput
                      value={analysisValues.real}
                      displayType="text"
                      suffix={SUFFIX_EURO_CURRENCY}
                      decimalScale={2}
                      className="ml-auto font-weight-bold"
                    />
                  }
                  subtitle={intl.formatMessage({ id: "FINANCE.BUDGET.REAL" })}
                  baseColor={"light"}
                />
              </div>
            )}
            {isBudgetAvailable && (
              <div className="col-lg-4 gutter-b">
                <TilesWidget11
                  baseColor={
                    analysisValues.profit < 0
                      ? "danger"
                      : analysisValues.profit === 0
                      ? "light"
                      : "success"
                  }
                  title={
                    <>
                      <NumberInput
                        value={analysisValues.profit}
                        displayType="text"
                        suffix={SUFFIX_EURO_CURRENCY}
                        decimalScale={2}
                        className="ml-auto font-weight-bold"
                      />
                      {analysisValues.profit !== 0 && (
                        <div style={{ fontSize: "13px" }}>
                          (
                          <NumberInput
                            value={analysisValues.margin}
                            displayType="text"
                            suffix={SUFFIX_PERCENTAGE}
                            decimalScale={2}
                            className="ml-auto font-weight-bold"
                          />
                          )
                        </div>
                      )}
                    </>
                  }
                  subtitle={intl.formatMessage({ id: "FINANCE.PROFIT.CURRENT" })}
                />
              </div>
            )}

            {/*//TODO: this part has been commented because we need to fix the chart with this task: IMT-778*/}
            {/*<div className="col-lg-9">*/}
            {/*  {layoutProps && (*/}
            {/*    <BarChart*/}
            {/*      layoutProps={layoutProps}*/}
            {/*      series={analysisValues.profitChartSeries}*/}
            {/*      xaxis={lastMonths}*/}
            {/*      id="current_profit_chart"*/}
            {/*      useCurrency={true}*/}
            {/*      seriesColors={[layoutProps.colorsThemeBasePrimary, layoutProps.colorsGrayGray300]}*/}
            {/*    />*/}
            {/*  )}*/}
            {/*</div>*/}
          </div>
          <h3 className="mb-4">
            <FormattedMessage id="FINANCE.PROJECTION" />
          </h3>
          <div className="row mb-8">
            <div className="col-md-4 gutter-b">
              <TilesWidget11
                title={
                  <NumberInput
                    value={analysisValues.listingPrice}
                    displayType="text"
                    suffix={SUFFIX_EURO_CURRENCY}
                    decimalScale={2}
                    className="ml-auto font-weight-bold"
                  />
                }
                subtitle={intl.formatMessage({ id: "FINANCE.PROJECT.LISTING_PRICE" })}
                baseColor={"light"}
              />
            </div>
            {isBudgetAvailable && (
              <>
                <div className="col-md-4 gutter-b">
                  <TilesWidget11
                    title={
                      <NumberInput
                        value={plannedTotal || "-"}
                        displayType="text"
                        suffix={SUFFIX_EURO_CURRENCY}
                        decimalScale={2}
                        className="ml-auto font-weight-bold"
                      />
                    }
                    subtitle={intl.formatMessage({ id: "FINANCE.BUDGET.PLANNED" })}
                    baseColor={"light"}
                  />
                </div>
                <div className="col-md-4 gutter-b">
                  <TilesWidget11
                    baseColor={
                      analysisValues.plannedProfit < 0
                        ? "danger"
                        : analysisValues.plannedProfit === "-"
                        ? "light"
                        : "success"
                    }
                    title={
                      <>
                        <NumberInput
                          value={analysisValues.plannedProfit}
                          displayType="text"
                          suffix={SUFFIX_EURO_CURRENCY}
                          decimalScale={2}
                          className="ml-auto font-weight-bold"
                        />
                        {analysisValues.plannedProfit !== "-" && (
                          <div style={{ fontSize: "13px" }}>
                            (
                            <NumberInput
                              value={analysisValues.plannedMargin}
                              displayType="text"
                              suffix={SUFFIX_PERCENTAGE}
                              decimalScale={2}
                              className="ml-auto font-weight-bold"
                            />
                            )
                          </div>
                        )}
                      </>
                    }
                    subtitle={intl.formatMessage({ id: "FINANCE.PROFIT.MARGIN.PROJECTED" })}
                  />
                </div>
              </>
            )}
          </div>
          <div className="row mb-4">
            {/*TODO chart for "invoices in to date" are currently disabled if budget is not set. This is mainly because expenses and quotes calculations are currently tied to budget.
            However, this could potentially be fixed/adapted by gathering quotes and invoices and doing the calculation on the fly. TBD*/}
            {isBudgetAvailable && (
              <div className="col-sm-6 d-flex align-items-center flex-column">
                <h4 className="mb-n2">
                  <FormattedMessage id="FINANCE.INVOICES.IN_TO_DATE" />
                </h4>
                <RadialBarChart
                  series={[analysisValues.invoicesInToDate]}
                  valueLabel={analysisValues.real === 0 ? "-" : undefined}
                  id="current-invoices-in"
                  layoutProps={layoutProps}
                  insideLabel={[
                    formatCurrency(analysisValues.expenses, 0, intl),
                    intl.formatMessage({ id: "FINANCE.OUT.OF" }),
                    formatCurrency(analysisValues.real, 0, intl),
                  ]}
                />
              </div>
            )}
            <div className="col-sm-6 d-flex align-items-center flex-column">
              <h4 className="mb-n2">
                <FormattedMessage id="FINANCE.INVOICES.OUT_TO_DATE" />
              </h4>
              <RadialBarChart
                series={[analysisValues.invoicesOutToDate]}
                valueLabel={analysisValues.currentProjectValue === 0 ? "-" : undefined}
                id="current-invoices-out"
                layoutProps={layoutProps}
                insideLabel={[
                  formatCurrency(analysisValues.invoicesOut, 0, intl),
                  intl.formatMessage({ id: "FINANCE.OUT.OF" }),
                  formatCurrency(analysisValues.currentProjectValue, 0, intl),
                ]}
                color={(() => {
                  const value = analysisValues.invoicesOut / analysisValues.currentProjectValue;
                  if (value < 0.33) {
                    return layoutProps.colorsThemeBaseDanger;
                  } else if (value < 0.66) {
                    return layoutProps.colorsThemeBaseWarning;
                  } else {
                    return layoutProps.colorsThemeBaseSuccess;
                  }
                })()}
              />
            </div>
          </div>
          <h3 className="mb-4">
            <FormattedMessage id="FINANCE.COMMERCIALISATION_TO_DATE" />
          </h3>
          <div className="row mb-8">
            <div className="col-lg-6 d-md-flex p-0">
              <div className="col-md-6 gutter-b">
                <TilesWidget11
                  title={analysisValues.leads.wonLeads}
                  subtitle={intl.formatMessage({ id: "FINANCE.STATUS.WON" })}
                  baseColor={"success"}
                />
              </div>
              <div className="col-md-6 gutter-b">
                <TilesWidget11
                  title={
                    <NumberInput
                      value={
                        analysisValues.currentProjectValue / analysisValues.leads.wonLeads || 0
                      }
                      displayType="text"
                      suffix={SUFFIX_EURO_CURRENCY}
                      decimalScale={2}
                      className="ml-auto font-weight-bold"
                    />
                  }
                  subtitle={intl.formatMessage({ id: "FINANCE.STATUS.AVERAGE_PER_WON_LEAD" })}
                />
              </div>
            </div>
            <div className="col-lg-6 d-md-flex p-0">
              <div className="col-md-4 gutter-b">
                <TilesWidget11
                  title={analysisValues.leads.qualifiedLeads}
                  subtitle={intl.formatMessage({ id: "FINANCE.STATUS.QUALIFIED" })}
                  baseColor={"info"}
                />
              </div>
              <div className="col-md-4 gutter-b">
                <TilesWidget11
                  title={analysisValues.leads.openLeads}
                  subtitle={intl.formatMessage({ id: "FINANCE.STATUS.OPEN" })}
                  baseColor={"light"}
                />
              </div>
              <div className="col-md-4 gutter-b">
                <TilesWidget11
                  title={analysisValues.leads.lostLeads}
                  subtitle={intl.formatMessage({ id: "FINANCE.STATUS.LOST" })}
                  baseColor={"danger"}
                />
              </div>
            </div>
            {/*//TODO: this part has been commented because we need to fix the chart with this task: IMT-778*/}
            {/*</div>*/}
            {/*<div className="col-lg-6">*/}
            {/*  {layoutProps && (*/}
            {/*    <BarChart*/}
            {/*      layoutProps={layoutProps}*/}
            {/*      series={analysisValues.leads.leadStatusSeries}*/}
            {/*      xaxis={lastMonths}*/}
            {/*      height={225}*/}
            {/*      id="lead_status_chart"*/}
            {/*      seriesColors={[*/}
            {/*        layoutProps.colorsThemeBaseSuccess,*/}
            {/*        layoutProps.colorsThemeBaseInfo,*/}
            {/*        layoutProps.colorsGrayGray300,*/}
            {/*        layoutProps.colorsThemeBaseDanger,*/}
            {/*      ]}*/}
            {/*    />*/}
            {/*  )}*/}
            {/*</div>*/}
          </div>
        </>
      )}
    </div>
  );
}
