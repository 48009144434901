import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import React, { FC, ReactNode, useEffect, useRef, useState } from "react";
import KTCard from "../../../_metronic/_assets/js/components/card";
import { v4 as uuid } from "uuid";
import "./CustomCard.scss";
import cn from "clsx";
import { DeletePopover } from "./DeletePopover";
import { useDidMount } from "rooks";

export enum CustomCardCollapsePosition {
  LEFT = "LEFT",
  RIGHT = "RIGHT",
}

export interface CustomCardProps {
  header?: ReactNode;
  expand?: boolean;
  collapse?: boolean;
  collapsable?: boolean;
  collapsePosition?: CustomCardCollapsePosition;
  remove?: Function;
  confirmRemove?: boolean;
  draggable?: boolean;
  headerClassName?: string;
  headerStyle?: React.CSSProperties;
  parentClassName?: string;
  isByDefaultCollapse?: boolean;
  isDeleteEnabled?: boolean;
}

export const CustomCard: FC<CustomCardProps> = ({
  header,
  children,
  expand,
  collapse,
  collapsable,
  collapsePosition = CustomCardCollapsePosition.RIGHT,
  remove,
  confirmRemove = true,
  draggable,
  headerClassName,
  headerStyle,
  parentClassName,
  isByDefaultCollapse,
  isDeleteEnabled = true,
}) => {
  const [id] = useState<string>(uuid());
  const [card, setCard] = useState<InstanceType<typeof KTCard>>();
  const [showRemove, setShowRemove] = useState<boolean>(false);
  const targetRemove = useRef<HTMLButtonElement>(null);

  useDidMount(() => {
    if (!card) {
      setCard(new KTCard(id, { toggleSpeed: 200 }));
    }
  });

  useEffect(() => {
    if (card !== undefined && isByDefaultCollapse) {
      setIsExpanded(isByDefaultCollapse);
      isByDefaultCollapse ? card.collapse() : card.expand();
    }
  }, [card]);

  const [isExpanded, setIsExpanded] = useState<boolean>(true);

  useEffect(() => {
    if (card) {
      setIsExpanded(true);
      card.expand();
    }
  }, [expand]);

  useEffect(() => {
    if (card) {
      setIsExpanded(false);
      card.collapse();
    }
  }, [collapse]);

  const toggleCard = (e: React.MouseEvent<HTMLDivElement>) => {
    if (collapsable && card) {
      e.preventDefault();
      setIsExpanded(!isExpanded);
      card.toggle();
    }
  };

  return (
    <div
      className={cn(
        "card card-custom position-relative",
        !!children && "card-border",
        remove && "removable-card",
        parentClassName
      )}
      data-card="true"
      id={id}
    >
      {typeof remove !== "undefined" && isDeleteEnabled && (
        <>
          <button
            type="button"
            className={`remove-card ${
              showRemove && "remove-card--active"
            } btn btn-icon btn-xs btn-light-danger btn-circle position-absolute top-0 right-0 invisible`}
            style={{ transform: "translate(50%,-50%)" }}
            tabIndex={-1}
            onClick={(e) => {
              if (confirmRemove) {
                setShowRemove(!showRemove);
              } else {
                remove();
              }
            }}
            ref={targetRemove}
          >
            <span className="svg-icon line-height-0">
              <SVG src={toAbsoluteUrl("/media/svg/icons/General/Trash.svg")} />
            </span>
          </button>
          <DeletePopover
            {...{
              showRemove,
              targetRemove: targetRemove.current!,
              remove,
              setShowRemove,
              title: "BUDGET.CATEGORY.DELETE",
            }}
          />
        </>
      )}
      {!!header && (
        <div
          className={cn(
            "card-header bg-primary-o-40 px-2 mt-0 align-items-center",
            headerClassName
          )}
          onClick={toggleCard}
          style={{
            cursor: collapsable ? "pointer" : "default",
            minHeight: "50px",
            transition: "all .3s ease",
            ...headerStyle,
          }}
        >
          {draggable && (
            <button
              type="button"
              className="btn btn-icon btn-xs mr-2 card-handle w-15px position-relative"
              onClick={(e) => e.stopPropagation()}
              tabIndex={-1}
            >
              <i className="ki ki-more-ver position-absolute" style={{ left: "-1px" }} />
              <i className="ki ki-more-ver position-absolute" style={{ left: "3px" }} />
            </button>
          )}
          {collapsable && (
            <button
              type="button"
              className={cn(
                "btn btn-icon btn-link-primary btn-xs",
                collapsePosition === CustomCardCollapsePosition.RIGHT && "order-2"
              )}
              tabIndex={-1}
            >
              <i
                className="ki ki-arrow-down icon-nm"
                style={{
                  transform: !isExpanded ? "rotate(-90deg)" : "",
                  transition: "transform .2s ease",
                }}
              />
            </button>
          )}
          {header}
        </div>
      )}
      {!!children && <div className="card-body p-0">{children}</div>}
    </div>
  );
};
