import { Spinner } from "react-bootstrap";
import * as React from "react";
import cn from "clsx";

interface LoaderSpinnerProps {
  className?: string;
  style?: Object;
}
export const LoaderSpinner: React.FC<LoaderSpinnerProps> = ({ className = "", style = {} }) => (
  <div className={cn("d-flex", className)} style={style}>
    <Spinner animation="border" variant="primary" style={{ top: "30px", left: "20px" }} />
  </div>
);
