import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../../_metronic/_helpers";
import { FormattedMessage } from "react-intl";
import * as React from "react";

interface NumberMetricProps {
  icon: string;
  value: number;
  label: string;
  color: string;
}
export const NumberMetric = ({ icon, value, label, color }: NumberMetricProps) => (
  <div className="col-6 col-md-3 col-xl-6">
    <div className={"card card-custom gutter-b"} style={{ height: "185px" }}>
      <div className="card-body">
        <span className={`svg-icon svg-icon-3x svg-icon-${color}`}>
          <SVG src={toAbsoluteUrl(icon)} />
        </span>
        <div className="text-dark font-weight-bolder font-size-h2 mt-3">
          <span>{value}</span>
        </div>
        <div className="text-muted font-weight-bold font-size-lg mt-1">
          <FormattedMessage id={label} />
        </div>
      </div>
    </div>
  </div>
);
