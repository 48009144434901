import * as React from "react";
import { NumberMetricCard, NumberMetricProps } from "./NumberMetricCard";
import { SUFFIX_EURO_CURRENCY } from "../../_utils/suffixUtils";

export const CurrencyMetricCard: React.FC<NumberMetricProps> = ({
  fixedDecimalScale = true,
  decimalScale = 2,
  children,
  ...props
}) => {
  return (
    <NumberMetricCard
      {...props}
      decimalScale={decimalScale}
      fixedDecimalScale={fixedDecimalScale}
      suffix={SUFFIX_EURO_CURRENCY}
    >
      {children}
    </NumberMetricCard>
  );
};
