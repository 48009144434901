/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { useSelector } from "react-redux";
import { formatDisplayNameIntl } from "../../../_utils/userUtils";
import { Link } from "react-router-dom";
import { FormattedMessage, useIntl } from "react-intl";
import { DateUtils } from "../../../_utils/DateUtils";

export function ProfileWidget({ className, widgetHeight = "175px" }) {
  const intl = useIntl();
  const { session } = useSelector((state) => ({
    session: state.auth.session,
  }));

  return (
    <>
      <div className={`card card-custom ${className}`} style={{ height: widgetHeight }}>
        {/* begin::Body */}
        <div className="card-body d-flex align-items-center justify-content-between flex-wrap">
          <div className="mr-2">
            <h3 className="font-weight-bolder">
              <FormattedMessage id="USER.PROFILE.WELCOME" />
              {!!session ? `, ${formatDisplayNameIntl(intl, session, true)}` : ""}
            </h3>
            <i>
              <div className="text-dark-50 font-size-lg mt-2">
                <FormattedMessage id="USER.PROFILE.LATEST_CONNECTION" />{" "}
                {!!session?.lastSeenAt ? DateUtils.format(new Date(session.lastSeenAt), intl) : "-"}
              </div>
            </i>
          </div>
          <Link to="/profile/me" className="btn btn-primary font-weight-bold py-3 px-6">
            <FormattedMessage id="USER.PROFILE.VIEW" />
          </Link>
        </div>
        {/* end::Body */}
      </div>
    </>
  );
}
