import * as actionsUser from "../modules/UsersManagement/_redux/usersActions";
import { AppDispatch } from "../../redux/store";
import { mergeWith, cloneDeep } from "lodash-es";
import { formatDisplayNameIntl } from "./userUtils";
import { IUser } from "../../data/schemas";
import { IntlShape } from "@formatjs/intl";

export const saveUserFields = (key: Array<any> | string, value: any) => (dispatch: AppDispatch) => {
  if (Array.isArray(key) && key.length === value.length) {
    for (const k of Object.keys(key)) {
      dispatch(actionsUser.updateUserFieldLocally(key[parseInt(k)], value[k]));
    }
  } else {
    dispatch(actionsUser.updateUserFieldLocally(key, value));
  }
};

const initUser = {
  id: undefined,
  legalEntityType: "NATURAL",
  title: "",
  firstName: "",
  lastName: "",
  mobile: "",
  email: "",
  address: {
    addressLine1: "",
    addressLine2: "",
    city: "",
    stateOrRegion: "",
    postalCode: "",
    countryCode: "",
  },
  referrer: "",
  company: {
    name: "",
    vatNumber: "",
    registrationNumber: "",
    nationalRegistrationNumber: "",
  },
  language: "en-GB",
  userTypes: [],
  actions: [],
};

export const mergeWithInitUser = (obj: any, userType: string) =>
  mergeWith(
    userType ? { ...cloneDeep(initUser), userTypes: [userType] } : cloneDeep(initUser),
    obj,
    (dest, src) => (src === null ? dest : undefined)
  );

interface SubmitUserUtilsParams {
  isEmailAlreadyExists: any;
  setIsEmailAlreadyExists: any;
  user: IUser;
  actionFn: (notes: string) => void;
  intl: IntlShape;
}

export const submitUserUtils = ({
  isEmailAlreadyExists,
  setIsEmailAlreadyExists,
  user,
  intl,
  actionFn,
}: SubmitUserUtilsParams) => {
  if (isEmailAlreadyExists) {
    setIsEmailAlreadyExists(false);
  }
  if (!user.id) {
    const notes = `<h2>${formatDisplayNameIntl(intl, user)} - Notes</h2><p>&nbsp;</p><p>&nbsp;</p>`;
    actionFn(notes);
  }
};
