// https://github.com/cyntler/react-doc-viewer/tree/main#overriding-no-renderer-error
import { FormattedMessage } from "react-intl";
import SVG from "react-inlinesvg";

import { getIconFilePath } from "app/_utils/fileUtils";

import { OverrideComponent } from "./definitions";

export const NoRenderer: OverrideComponent = ({ document }) => (
  <div className="d-flex flex-column justify-content-center align-items-center w-100">
    <span className="svg-icon svg-icon-4x">
      <SVG src={getIconFilePath(document?.fileType)} />
    </span>
    <span className="mt-3">
      <FormattedMessage id="COMMON.PREVIEW_NOT_AVAILABLE" />
    </span>
  </div>
);

export default NoRenderer;
