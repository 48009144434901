import { Button } from "react-bootstrap";
import cn from "clsx";
import React from "react";

export const enum KycButtonGroupMode {
  VIEW = "VIEW",
  EDIT = "EDIT",
}

interface KycButtonGroupProps {
  mode: KycButtonGroupMode;
  items: any[];
  selectedValue: any;
  onClick: any;
  showSelectedOnly?: boolean;
}

export const KycButtonGroup = ({
  items,
  selectedValue,
  mode,
  onClick,
}: KycButtonGroupProps): JSX.Element => {
  return (
    <>
      {mode === KycButtonGroupMode.EDIT ? (
        items.map((value, index) => {
          return (
            <div className="d-flex align-items-center" key={`signature-request-${index}`}>
              <Button
                className={cn(
                  "text-uppercase",
                  selectedValue === value &&
                    mode === KycButtonGroupMode.EDIT &&
                    "kyc-button-group-button-selected"
                )}
                size="sm"
                variant="outline"
                onClick={(e) => onClick({ ...e, target: { ...e.target, value: value } })}
              >
                {value?.toUpperCase()}
              </Button>
            </div>
          );
        })
      ) : (
        <>{selectedValue?.toUpperCase()}</>
      )}
    </>
  );
};
