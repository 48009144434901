import type { DocRenderer } from "@cyntler/react-doc-viewer";

import { renderToolbarBasic } from "app/modules/Common/PdfViewer/CustomToolbarBasic";
import { FileViewerPdfViewer } from "../FileViewerPdfViewer";

// @ts-ignore
export const CustomPdfRenderer: DocRenderer = ({ mainState: { currentDocument } }) => {
  if (!currentDocument) return null;

  return <FileViewerPdfViewer toolbar={renderToolbarBasic} />;
};

CustomPdfRenderer.fileTypes = ["pdf", "application/pdf"];
CustomPdfRenderer.weight = 1;

export default CustomPdfRenderer;
