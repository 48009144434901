/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import * as React from "react";
import "./TasksWidget.scss";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { formatDisplayNameMeIntl } from "../../../../_utils/userUtils";
import TimeAgo from "react-timeago";
import PerfectScrollbar from "react-perfect-scrollbar";
import * as tasksActions from "../../../PropertiesManagement/_redux/tasks/tasksActions";
import { TaskEditDialog } from "../../../PropertiesManagement/pages/tasks/task-edit-dialog/TaskEditDialog";
import { FormattedMessage, useIntl } from "react-intl";
import {
  defaultSelectedSort,
  getColorForTimeAgo,
  getColorForTimeAgoIcon,
  getDesiredDate,
  getTaskText,
  getTimeAgoCaption,
  sortedTasksUtils,
} from "app/_utils/tasks";
import { useEntityDeleteDialogContext } from "../../../PropertiesManagement/pages/entity-delete-dialog/EntityDeleteDialogContext";
import { useHtmlClassService } from "../../../../../_metronic/layout";
import objectPath from "object-path";
import { DropdownCustomToggler } from "../../../../../_metronic/_partials/dropdowns";
import { Dropdown } from "react-bootstrap";
import { TASK_SORTER_OPTION } from "../../../../_utils/listUtils";
import { Sorter } from "app/_components/Sorter";
import { canDelete, canEdit } from "../../../../_utils/authUtils";
import { useAppSelector } from "../../../../../redux/hooks";
import { ETaskStatus, ITask, TaskTypes } from "../../../../../data/schemas";

const perfectScrollbarOptions = {
  wheelSpeed: 2,
  wheelPropagation: false,
};

interface TasksWidgetProps {
  className: string;
}

export const TasksWidget: React.FC<TasksWidgetProps> = ({ className }) => {
  const intl = useIntl();

  const [selectedSort, setSelectedSort] = React.useState(defaultSelectedSort);

  const [showTaskEditModal, setShowTaskEditModal] = React.useState(false);
  const [taskId, setTaskId] = React.useState("");
  const history = useHistory();

  const { isLoading, tasks, listLoadingTask, actionsLoadingTask, session, groups } = useAppSelector(
    (state) => ({
      isLoading: state.dashboard.tasksLoading,
      listLoadingTask: state.tasks.listLoading,
      actionsLoadingTask: state.tasks.actionsLoading,
      tasks: state.tasks.entities,
      session: state.auth.session,
      groups: state.auth.groups,
    })
  );

  const uiService = useHtmlClassService();

  const layoutProps = React.useMemo(() => {
    return {
      colorsGrayGray700: objectPath.get(uiService.config, "js.colors.gray.gray700"),
      colorsThemeBasePrimary: objectPath.get(uiService.config, "js.colors.theme.base.primary"),
      colorsThemeBaseWarning: objectPath.get(uiService.config, "js.colors.theme.base.warning"),
      colorsThemeBaseSuccess: objectPath.get(uiService.config, "js.colors.theme.base.success"),
      colorsThemeBaseDanger: objectPath.get(uiService.config, "js.colors.theme.base.danger"),
      colorsThemeBaseInfo: objectPath.get(uiService.config, "js.colors.theme.base.info"),
    };
  }, [uiService]);
  let tasksToUse =
    isLoading && tasks.length === 0
      ? []
      : tasks
          // .filter((t) => (relatedTo ? t.assignee.id === relatedTo.split("#")[1] : true))
          .filter(
            (t) =>
              ([ETaskStatus.IN_PROGRESS, ETaskStatus.PLANNED, ETaskStatus.STUCK].includes(
                t.status
              ) &&
                session &&
                session.id &&
                t.assignee?.id === session.id) ||
              (t.taskType !== TaskTypes.CUSTOM &&
                t.status === ETaskStatus.IN_PROGRESS &&
                t.createdByUserId === session?.id)
          );
  tasksToUse = sortedTasksUtils(tasksToUse, selectedSort);

  const dispatch = useDispatch();

  const markTaskAsDone = (e: React.MouseEvent, task: ITask) => {
    e.stopPropagation();
    dispatch(
      tasksActions.updateTask({
        intl,
        session,
        groups,
        relatedTo: undefined,
        task: {
          ...task,
          status: ETaskStatus.DONE,
          doneDate: new Date().toISOString(),
        },
      })
    );
  };

  const { setDeleteEntityDialog } = useEntityDeleteDialogContext();

  const deleteTask = (e: React.MouseEvent, task: ITask) => {
    e.preventDefault();
    e.stopPropagation();
    setDeleteEntityDialog({
      action: { fn: tasksActions.deleteTask, props: task.id },
      entityType: "TASK",
    });
  };

  const openTaskDetails = (task: ITask) => {
    if (task.id) {
      setTaskId(task.id);
      setShowTaskEditModal(true);
      history.push({
        search: [`mode="edit"`, `id=${task.id}`].join("&"),
      });
    }
  };

  const completeAction = (e: React.MouseEvent, item: ITask) => {
    e.stopPropagation();
    history.push(item.pathLinkedToAction);
  };

  const resetQueryParams = () => {
    history.push({
      search: "",
    });
  };

  // TODO : the reactvirtualisedList should be used here as the size of fetched tasks can be long
  return (
    <>
      <TaskEditDialog
        show={showTaskEditModal}
        mode="edit"
        id={taskId}
        relatedTo={null}
        onHide={() => {
          setShowTaskEditModal(false);
          resetQueryParams();
        }}
        relatedToName={undefined}
      />
      <div className={`card card-custom ${className}`}>
        <div className="card-header border-0 align-items-center">
          <h3 className="card-title font-weight-bolder text-dark mt-6 mb-4">
            <FormattedMessage id="TASK.TODO" />
          </h3>
          {tasksToUse.length > 1 && (
            <Sorter
              selectedSort={selectedSort}
              setSelectedSort={setSelectedSort}
              option={TASK_SORTER_OPTION}
            />
          )}
          {/*<div className="card-toolbar">*/}
          {/*  <Dropdown className="dropdown-inline" alignRight>*/}
          {/*    <Dropdown.Toggle*/}
          {/*        id="dropdown-toggle-top"*/}
          {/*        as={DropdownCustomToggler}*/}
          {/*    >*/}
          {/*      <i className="ki ki-bold-more-ver"/>*/}
          {/*    </Dropdown.Toggle>*/}
          {/*    <Dropdown.Menu className="dropdown-menu dropdown-menu-sm dropdown-menu-right">*/}
          {/*      <DropdownMenu3/>*/}
          {/*    </Dropdown.Menu>*/}
          {/*  </Dropdown>*/}
          {/*</div>*/}
        </div>
        <div className="card-body pt-0 px-0 mt-4">
          {tasksToUse.length === 0 && !listLoadingTask && (
            <div className="mb-6 pl-8 pr-4">
              <i>
                <FormattedMessage id="TASK.NOTHING_TO_DO" />
              </i>
            </div>
          )}
          <PerfectScrollbar
            options={perfectScrollbarOptions}
            style={{ minHeight: "400px", maxHeight: "600px", position: "relative" }}
          >
            <div>
              {tasksToUse.map((item, index) => (
                <div
                  className="m-6 m-lg-3 pl-8 pr-4 bg-hover-gray-200"
                  key={index}
                  onClick={() => openTaskDetails(item)}
                >
                  <div className="d-flex align-items-center flex-grow-1">
                    <div className="d-flex flex-wrap align-items-center justify-content-between w-100">
                      <div className="d-flex flex-column align-items-left py-2 w-100">
                        {isLoading && Number.isInteger(item) ? (
                          <>
                            <div className="mb-1 task-loading">
                              <span className="task-loading-anim" />
                            </div>

                            <span style={{ minHeight: "19px" }} />
                          </>
                        ) : (
                          <div className="d-sm-flex justify-content-between flex-wrap">
                            <div>
                              <div className="text-dark-75 font-weight-bold text-hover-primary font-size-lg mb-1">
                                {getTaskText(intl, item?.name, item?.attachments)}
                              </div>
                              {!!item.assignee && (
                                <span
                                  className="text-muted font-weight-bold"
                                  style={{ minHeight: "19px" }}
                                >
                                  <FormattedMessage id="TASK.ASSIGNEE" />:{" "}
                                  {formatDisplayNameMeIntl(intl, item.assignee, session?.id)}
                                </span>
                              )}
                            </div>
                          </div>
                        )}
                        {item.dueDate && (
                          <div className="text-left">
                            <span
                              className="label label-lg label-inline font-weight-bold mt-2"
                              style={getColorForTimeAgo(item, layoutProps)}
                            >
                              <i
                                style={{
                                  color: getColorForTimeAgoIcon(item, layoutProps),
                                  marginRight: 5,
                                }}
                                className={` fas fa-clock icon-md`}
                              />
                              <TimeAgo
                                className="ml-1"
                                date={getDesiredDate(item)}
                                formatter={(value, unit) => {
                                  return getTimeAgoCaption(item, value, unit, intl);
                                }}
                              />
                            </span>
                          </div>
                        )}
                      </div>
                    </div>
                    {((canEdit(groups, session, "TASK") && !item.pathLinkedToAction) ||
                      canDelete(groups, session, "TASK") ||
                      item.pathLinkedToAction) && (
                      <div className="checkbox checkbox-lg checkbox-lg checkbox-single flex-shrink-0 ml-4">
                        <Dropdown className="dropdown-inline" alignRight>
                          <Dropdown.Toggle id="dropdown-toggle-top" as={DropdownCustomToggler}>
                            <i className="ki ki-bold-more-ver" />
                          </Dropdown.Toggle>
                          <Dropdown.Menu className="dropdown-menu dropdown-menu-sm dropdown-menu-right">
                            <ul className="navi">
                              {canEdit(groups, session, "TASK") && !item.pathLinkedToAction && (
                                <li className="navi-item">
                                  <button
                                    className="text-left btn btn-block navi-link"
                                    onClick={(e) => markTaskAsDone(e, item)}
                                  >
                                    <span>
                                      <i className="text-success flaticon2-checkmark mr-1" />
                                    </span>
                                    <span>
                                      <FormattedMessage id="TASK.MARK_AS_DONE" />
                                    </span>
                                  </button>
                                </li>
                              )}

                              {item.pathLinkedToAction && (
                                <li className="navi-item">
                                  <button
                                    className="text-left btn btn-block navi-link"
                                    onClick={(e) => completeAction(e, item)}
                                  >
                                    <span>
                                      <i className="text-info flaticon2-sheet mr-1" />
                                    </span>
                                    <span>
                                      <FormattedMessage id="COMMON.COMPLETE" />
                                    </span>
                                  </button>
                                </li>
                              )}
                              {canDelete(groups, session, "TASK") && (
                                <li className="navi-item">
                                  <button
                                    className="text-left btn btn-block navi-link"
                                    onClick={(e) => deleteTask(e, item)}
                                  >
                                    <span>
                                      <i className="text-danger flaticon2-trash mr-1" />
                                    </span>
                                    <span>
                                      <FormattedMessage id="COMMON.DELETE" />
                                    </span>
                                  </button>
                                </li>
                              )}
                            </ul>
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>
                    )}
                  </div>
                </div>
              ))}
            </div>
          </PerfectScrollbar>
          {(listLoadingTask || actionsLoadingTask) && (
            <div className="d-flex justify-content-end mr-4 ml-2">
              <div className="mr-2 text-muted">
                <FormattedMessage id="COMMON.LOADING" />
              </div>
              <div className="spinner spinner-primary mr-10" />
            </div>
          )}
        </div>
      </div>
    </>
  );
};
