import { shouldPolyfill as shouldPolyfillCanonicalLocales } from "@formatjs/intl-getcanonicallocales/should-polyfill";
import { shouldPolyfill as shouldPolyfillLocale } from "@formatjs/intl-locale/should-polyfill";

export async function intlPolyfills() {
  const polyfillsToImport = [];
  if (shouldPolyfillCanonicalLocales()) {
    polyfillsToImport.push("getcanonicallocales");
  }
  if (shouldPolyfillLocale()) {
    polyfillsToImport.push("locale");
  }
  if (!polyfillsToImport.length) {
    return;
  }
  console.log("import polyfills: ", polyfillsToImport);
  for (const polyfillToImport of polyfillsToImport) {
    await import(`@formatjs/intl-${polyfillToImport}/polyfill`);
  }
}
