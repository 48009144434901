import * as React from "react";
import { useIntl, FormattedMessage } from "react-intl";
import { Highlighter } from "react-bootstrap-typeahead";

import { formatDisplayNameIntl } from "../_utils/userUtils";

import { ProfilePictureThumbnail } from "./ProfilePictureThumbnail";

interface UserDropdownItemProps {
  user: any;
  searchText?: string;
  shape?: string;

  showMobile?: boolean;

  newLabelId?: string;
}
export const UserDropdownItem: React.FunctionComponent<UserDropdownItemProps> = ({
  user,
  searchText = "",
  shape,

  showMobile = false,

  newLabelId = "MENU.NEW",
}) => {
  const intl = useIntl();

  // user.customOption === true
  const isUserDefined = !!user?.email;

  return (
    <div className="d-flex align-items-center w-100">
      <ProfilePictureThumbnail user={user} shape={shape} />

      <div className="d-flex justify-content-center flex-column ml-3 w-100">
        {isUserDefined ? (
          <div className="font-weight-bold">
            <Highlighter
              search={searchText}
              highlightClassName={"bg-light-primary p-0"}
              children={formatDisplayNameIntl(intl, user, false)}
            />
          </div>
        ) : (
          <div className="d-flex">
            <div className="font-weight-bold pr-2">
              <FormattedMessage id={newLabelId} />:
            </div>
            {searchText}
          </div>
        )}

        {(!!user?.firstName || !!user?.lastName || user?.company?.name) && (
          <div className="d-flex justify-content-between w-100">
            <div className="text-muted">
              <Highlighter
                search={searchText}
                highlightClassName={"bg-light-primary p-0"}
                children={user?.email}
              />
            </div>

            {showMobile ? (
              <div className="text-muted">
                <Highlighter
                  search={searchText}
                  highlightClassName={"bg-light-primary p-0"}
                  children={user?.mobile}
                />
              </div>
            ) : null}
          </div>
        )}
      </div>
    </div>
  );
};
