import React, { useEffect, useMemo } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../../../_metronic/_partials/controls";
import { ProductsTable } from "./products-table/ProductsTable";
import { useProductsUIContext } from "./ProductsUIContext";
import { useSubheader } from "../../../../../_metronic/layout";
import { canCreate } from "../../../../_utils/authUtils";
import { shallowEqual, useSelector } from "react-redux";
import { useIntl } from "react-intl";
import { Filter } from "../../../../_components/Filter";
import ActionNewButton from "app/_components/ActionNewButton";

export function ProductsCard() {
  const intl = useIntl();

  const { groups, session } = useSelector(
    (state) => ({
      groups: state.auth.groups,
      session: state.auth.session,
    }),
    shallowEqual
  );

  const productsUIContext = useProductsUIContext();
  const productsUIProps = useMemo(() => {
    return {
      ids: productsUIContext.ids,
      queryParams: productsUIContext.queryParams,
      setQueryParams: productsUIContext.setQueryParams,
      newProductButtonClick: productsUIContext.newProductButtonClick,
      openUpdateProductsStatusDialog: productsUIContext.openUpdateProductsStatusDialog,
      openFetchProductsDialog: productsUIContext.openFetchProductsDialog,
      status: productsUIContext.status,
    };
  }, [productsUIContext]);

  useEffect(() => {
    if (productsUIProps.status !== "LEAD_CREATION") {
      productsUIProps.setQueryParams(undefined);
    }
  }, [productsUIProps.status]);

  const subheader = useSubheader();

  useEffect(() => {
    subheader.setTitle(
      intl.formatMessage({
        id: productsUIProps.status === "LEAD_CREATION" ? "LEAD.TITLE.NEW" : "PRODUCT.TITLE",
      })
    );
  }, [subheader]);

  return (
    <Card>
      <CardHeader
        title={intl.formatMessage({
          id: productsUIProps.status === "LEAD_CREATION" ? "PRODUCT.SELECT" : "PRODUCT.TITLE.LIST",
        })}
      >
        <CardHeaderToolbar>
          {!productsUIProps.status && canCreate(groups, session, "PRODUCT") && (
            <ActionNewButton
              dataCy="button-products-new"
              onClick={productsUIProps.newProductButtonClick}
              messageId="PRODUCT.ACTION.NEW"
            />
          )}
        </CardHeaderToolbar>
      </CardHeader>
      <CardBody>
        <Filter useEntityUIContext={useProductsUIContext} />
        <ProductsTable />
      </CardBody>
    </Card>
  );
}
