import * as React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { DonutChart } from "./DonutChart";
import { SUFFIX_EURO_CURRENCY } from "../../../../_utils/suffixUtils";
import { NumberMetric } from "./NumberMetric";
import { useHtmlClassService } from "../../../../../_metronic/layout";
import objectPath from "object-path";
import { InvoiceDashboardMetrics } from "../../../../../data/schemas/Dashboard";

interface InvoiceManagementMetricsProps {
  data: InvoiceDashboardMetrics;
}

export const InvoiceManagementMetrics = ({ data }: InvoiceManagementMetricsProps) => {
  const intl = useIntl();
  const uiService = useHtmlClassService();
  const layoutProps = {
    colorsGrayGray300: objectPath.get(uiService.config, "js.colors.gray.gray300"),
    colorsThemeBaseInfo: objectPath.get(uiService.config, "js.colors.theme.base.info"),
    colorsThemeLightInfo: objectPath.get(uiService.config, "js.colors.theme.light.info"),
    fontFamily: objectPath.get(uiService.config, "js.fontFamily"),
  };

  return (
    <div className="row">
      <div className="col-12 col-xl-8">
        <div className="row">
          <div className="col-12 col-sm-6">
            <div className={"card card-custom gutter-b"} style={{ height: "395px" }}>
              <div className="card-body d-flex flex-column">
                <h3>
                  <FormattedMessage id="DASHBOARD.INVOICE_MANAGEMENT.ACCOUNTS_RECEIVABLE" />
                </h3>
                <div>
                  <FormattedMessage id="DASHBOARD.INVOICE_MANAGEMENT.ACCOUNTS_RECEIVABLE_SUBTITLE" />
                </div>
                <div className="mt-auto">
                  <DonutChart
                    id="accounts-receivable"
                    series={[data.lead.invoiced, data.lead.open]}
                    totalLabel={intl.formatMessage({ id: "COMMON.TOTAL" })}
                    totalValue={
                      intl.formatNumber(data.lead.expectedRevenue, {
                        minimumFractionDigits: data.lead.expectedRevenue >= 1000000 ? 3 : 0,
                        maximumFractionDigits: data.lead.expectedRevenue >= 1000000 ? 3 : 0,
                        notation: data.lead.expectedRevenue >= 1000000 ? "compact" : "standard",
                      }) + SUFFIX_EURO_CURRENCY
                    }
                    labels={[
                      intl.formatMessage({ id: "DASHBOARD.INVOICE_MANAGEMENT.INVOICED" }),
                      intl.formatMessage({ id: "DASHBOARD.INVOICE_MANAGEMENT.OPEN" }),
                    ]}
                    layoutProps={layoutProps}
                    intl={intl}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 col-sm-6">
            <div className={"card card-custom gutter-b"} style={{ height: "395px" }}>
              <div className="card-body d-flex flex-column">
                <h3>
                  <FormattedMessage id="DASHBOARD.INVOICE_MANAGEMENT.ACCOUNTS_PAYABLE" />
                </h3>
                <div>
                  <FormattedMessage id="DASHBOARD.INVOICE_MANAGEMENT.ACCOUNTS_PAYABLE_SUBTITLE" />
                </div>
                <div className="mt-auto">
                  <DonutChart
                    id="accounts-payable"
                    series={[data.subcontractors.invoiced, data.subcontractors.open]}
                    totalLabel={intl.formatMessage({ id: "COMMON.TOTAL" })}
                    totalValue={
                      intl.formatNumber(data.subcontractors.realBudget, {
                        minimumFractionDigits: data.subcontractors.realBudget >= 1000000 ? 3 : 0,
                        maximumFractionDigits: data.subcontractors.realBudget >= 1000000 ? 3 : 0,
                        notation:
                          data.subcontractors.realBudget >= 1000000 ? "compact" : "standard",
                      }) + SUFFIX_EURO_CURRENCY
                    }
                    labels={[
                      intl.formatMessage({ id: "DASHBOARD.INVOICE_MANAGEMENT.INVOICED" }),
                      intl.formatMessage({ id: "DASHBOARD.INVOICE_MANAGEMENT.OPEN" }),
                    ]}
                    layoutProps={layoutProps}
                    intl={intl}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="col-12 col-xl-4">
        <div className="row">
          <NumberMetric
            value={data.invoicesCount}
            label="DASHBOARD.INVOICE_MANAGEMENT.TOTAL_INVOICES"
            icon="/media/svg/icons/Files/Selected-file.svg"
            color="tertiary"
          />
          <NumberMetric
            value={data.invoicesPaidCount}
            label="DASHBOARD.INVOICE_MANAGEMENT.PAID_INVOICES"
            icon="/media/svg/icons/Files/File-done.svg"
            color="quaternary"
          />
          <NumberMetric
            value={data.subcontractorsCount}
            label="DASHBOARD.INVOICE_MANAGEMENT.SUBCONTRACTORS_INVOLVED"
            icon="/media/svg/icons/Tools/Tools.svg"
            color="secondary"
          />
          <NumberMetric
            value={data.clientsCount}
            label="DASHBOARD.INVOICE_MANAGEMENT.CLIENTS_INVOLVED"
            icon="/media/svg/icons/General/User.svg"
            color="quinary"
          />
        </div>
      </div>
    </div>
  );
};
