import { ITask } from "data/schemas";

import { defaultSelectedSort } from "./definitions";

export const sortedByDateFn = (key: string, isAscending: boolean) => (a: any, b: any) => {
  const res = Number(new Date(a[key])) - Number(new Date(b[key]));
  return isAscending ? res : res * -1;
};

export const sortedTasksUtils = (tasks: ITask[] = [], selectedSort = defaultSelectedSort) => {
  if (tasks.length === 0) return tasks;

  if (selectedSort?.selected && selectedSort.selected.type && selectedSort.selected.label) {
    if (selectedSort.selected.type === "DUE_DATE") {
      let tasksWithDueDate = tasks.filter((task) => !!task.dueDate);
      let tasksWithoutDueDate = tasks.filter((task) => !task.dueDate);

      tasksWithDueDate = tasksWithDueDate.sort(sortedByDateFn("dueDate", selectedSort.isAscending));
      tasksWithoutDueDate = tasksWithoutDueDate.sort(
        sortedByDateFn("createdAt", selectedSort.isAscending)
      );
      return tasksWithDueDate.concat(tasksWithoutDueDate);
    } else if (selectedSort.selected.type === "UPDATED_AT") {
      return tasks.sort(sortedByDateFn("updatedAt", selectedSort.isAscending));
    }
  } else {
    return tasks.sort(sortedByDateFn("createdAt", selectedSort.isAscending));
  }

  return [];
};
