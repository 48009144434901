/* eslint-disable no-restricted-imports */
import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { ModalProgressBar } from "../../../../../../../_metronic/_partials/controls";
import { useIntl } from "react-intl";

export function ProductFileEditDialogHeader({
  productFileForEdit,
  showEditFileDialog,
  actionsLoading,
}) {
  const [title, setTitle] = useState("");
  const intl = useIntl();
  useEffect(() => {
    setTitle(
      productFileForEdit?.id
        ? `${intl.formatMessage({ id: "COMMON.ACTION.EDIT" })}  ${
            productFileForEdit?.friendlyName ?? ""
          }`
        : intl.formatMessage({ id: "FILE.ACTIONS.UPLOAD.NEW" })
    );
  }, [productFileForEdit, actionsLoading]);

  return (
    <>
      {actionsLoading && <ModalProgressBar variant="query" />}
      <Modal.Header closeButton>
        <Modal.Title id="example-modal-sizes-title-lg">{showEditFileDialog && title}</Modal.Title>
      </Modal.Header>
    </>
  );
}
