import { FormattedMessage } from "react-intl";
import { CellContext, ColumnDefTemplate } from "@tanstack/react-table";
import { isNumber, isUndefined } from "lodash-es";
import cn from "clsx";

import { paymentStatusStyle, TInvoiceStatus } from "data/schemas";

import { NumberInput } from "app/_utils/formUtils";
import { SUFFIX_EURO_CURRENCY, SUFFIX_PERCENTAGE } from "app/_utils/suffixUtils";

import { ILeadInstalmentsColumns, percentageColumns } from "../../definitions";
import { LeadInstalmentsTableRow } from "../definitions";

export interface LeadInstalmentsCellProps {
  typedKey: keyof ILeadInstalmentsColumns;
}

export const LeadInstalmentsCell: ColumnDefTemplate<
  CellContext<LeadInstalmentsTableRow, any> & LeadInstalmentsCellProps
> = function ({ row, getValue, typedKey }) {
  let value = getValue();
  // Empty column render
  if (isUndefined(value)) {
    return "-";
  }

  // Specific column render
  switch (typedKey) {
    case "label":
      return (
        <div
          className="d-flex align-items-center flex-grow-1"
          style={{
            paddingLeft: `${row.depth > 1 ? row.depth : 0}rem`,
          }}
        >
          {value}
        </div>
      );
    case "paymentStatus":
      const paymentStatus = paymentStatusStyle[value as TInvoiceStatus];
      return (
        <span
          className={cn(
            "label label-lg label-inline text-nowrap",
            `label-light-${paymentStatus.color}`
          )}
        >
          <FormattedMessage id={paymentStatus.translationKey} />
        </span>
      );
  }

  // Default column render
  if (isNumber(value)) {
    return (
      <NumberInput
        displayType="text"
        fixedDecimalScale={true}
        className="font-weight-bold text-right"
        value={value}
        decimalScale={2}
        suffix={percentageColumns.includes(typedKey) ? SUFFIX_PERCENTAGE : SUFFIX_EURO_CURRENCY}
      />
    );
  }
};
