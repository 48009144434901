import React from "react";
import { FieldFeedbackLabel } from "./FieldFeedbackLabel";
import { get } from "lodash-es";
import PhoneInput from "react-phone-input-2";
import "./PhoneFormInput.css";

const getFieldCSSClasses = (touched, errors, value) => {
  const classes = ["form-control"];
  if (touched && errors) {
    classes.push("is-invalid");
  }
  return classes.join(" ");
};

export function PhoneFormInput({
  field, // { name, value, onChange, onBlur }
  form: { touched, errors, values, setFieldValue }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  label,
  withFeedbackLabel = true,
  customFeedbackLabel,
  type = "text",
  inline = false,
  dataCy="input-mobile",
  ...props
}) {
  return (
    <>
      {inline ? (
        <>
          {label && <label className={"col-lg-3 col-form-label text-lg-right"}>{label}</label>}
          <div className={"col-md-9 col-lg-4"}>
            <PhoneInput
              country={"lu"}
              {...props}
              {...field}
              inputProps={{
                "data-cy": dataCy,
                name: field.name,
                className: getFieldCSSClasses(
                  get(touched, field.name),
                  get(errors, field.name),
                  get(values, field.name)
                )
              }}
              onChange={(phoneNumber) => {
                phoneNumber = "+" + phoneNumber;
                if (!!props.onChange) {
                  props.onChange({ target: { name: field.name, value: phoneNumber } });
                }
                setFieldValue(field.name, phoneNumber);
              }}
              specialLabel={""}
            />
            {withFeedbackLabel && !props.disabled && (
              <FieldFeedbackLabel
                error={get(errors, field.name)}
                touched={get(touched, field.name)}
                value={field.value}
                label={label}
                type={type}
                customFeedbackLabel={customFeedbackLabel}
              />
            )}
          </div>
        </>
      ) : (
        <>
          {label && (
            <label>
              {label}
            </label>
          )}
          <PhoneInput
            country={"lu"}
            {...props}
            {...field}
            inputProps={{
              "data-cy": dataCy,
              name: field.name,
              className: getFieldCSSClasses(
                get(touched, field.name),
                get(errors, field.name),
                get(values, field.name)
              )
            }}
            onChange={(phoneNumber) => {
              phoneNumber = "+" + phoneNumber;
              if (!!props.onChange) {
                props.onChange({ target: { name: field.name, value: phoneNumber } });
              }
              setFieldValue(field.name, phoneNumber);
            }}
            onBlur={props.onBlur}
            specialLabel={""}
          />
          {withFeedbackLabel && !props.disabled && (
            <FieldFeedbackLabel
              error={get(errors, field.name)}
              touched={get(touched, field.name)}
              value={field.value}
              label={label}
              type={type}
              customFeedbackLabel={customFeedbackLabel}
            />
          )}
        </>
      )}
    </>
  );
}
