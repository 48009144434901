import React from "react";
import "../../../_metronic/_assets/sass/pages/error/error-5.scss";

export function ResourceUnavailablePage() {
  return (
    <div className="d-flex flex-column flex-root">
      <div
        className="error error-5 d-flex flex-row-fluid bgi-size-cover bgi-position-center"
        style={
          {
            //  TODO add custom background images (illustrations) for error / exception-pages
            // backgroundImage: `url(${toAbsoluteUrl("/media/error/bg5.jpg")})`,
          }
        }
      >
        <div className="container d-flex flex-row-fluid flex-column justify-content-md-center p-12">
          <h1 className="error-title font-weight-boldest text-info mt-10 mt-md-0 mb-12">Oops!</h1>
          <p className="font-weight-boldest display-4">
            The requested resource isn't available (anymore)
          </p>
          {/*    TODO probably add a possibility to send feedback or send a support mail (to be determined) */}
        </div>
      </div>
    </div>
  );
}
