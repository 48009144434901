import React from "react";
import cn from "clsx";

export const ProfilePictureThumbnail = ({ user, shape = "square" }) => {
  if (!user) {
    return getDefaultAvatar(shape);
  }
  const { profilePictureUrl, firstName, lastName, email } = user;

  return !profilePictureUrl && !user?.firstName && !user?.lastName ? (
    getDefaultAvatar(shape)
  ) : profilePictureUrl ? (
    <div
      className={cn(
        "symbol symbol-35 bg-white-o-30 flex-shrink-0",
        shape === "circle" && "symbol-circle"
      )}
    >
      <img src={profilePictureUrl} alt="Avatar" />
    </div>
  ) : (
    <div
      className={cn(
        "symbol symbol-35 symbol-light-primary flex-shrink-0",
        shape === "circle" && "symbol-circle"
      )}
    >
      <span className="symbol-label font-size-h5 font-weight-bold symbol-label">
        {firstName || lastName ? (
          <>
            {!!firstName && firstName[0].toUpperCase()}
            {!!lastName && lastName[0].toUpperCase()}
          </>
        ) : (
          <>{!!email ? email[0].toUpperCase() : "-"}</>
        )}
      </span>
    </div>
  );
};

//used in case where no user info is available -> display avatar box with the letter "U" for user
const getDefaultAvatar = (shape = "square") => {
  return (
    <div
      className={cn(
        "symbol symbol-35 symbol-light-primary flex-shrink-0",
        shape === "circle" && "symbol-circle"
      )}
    >
      <span className="symbol-label font-size-h5 font-weight-bold symbol-label">U</span>
    </div>
  );
};
