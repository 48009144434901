import * as React from "react";
import { OverlayTriggerProps, OverlayTrigger, Tooltip } from "react-bootstrap";
import { useDidUpdate } from "rooks";
import { useOnClickOutside } from "usehooks-ts";

//----------------------------------------------------------------------------//

interface CustomOverlayTriggerProps extends Omit<OverlayTriggerProps, "overlay"> {
  tooltipClassName?: string;
  keepOpen?: boolean;
  id: string;
  message: React.ReactNode;
}

const CustomOverlayTrigger: React.FunctionComponent<CustomOverlayTriggerProps> = ({
  tooltipClassName,
  keepOpen = false,
  id,
  message,
  children,
  show,
  ...overlayTriggerProps
}) => {
  const divWrapperRef = React.useRef(null);
  const [innerShow, setShow] = React.useState<boolean | undefined>(show);

  const mouseEnterHandler = () => {
    setShow(true);
  };

  const mouseLeavehandler = () => {
    setShow(false);
  };

  useOnClickOutside(divWrapperRef, mouseLeavehandler);

  const wrapperFn = (children: React.ReactElement) => {
    return keepOpen ? (
      <div ref={divWrapperRef} onMouseEnter={mouseEnterHandler}>
        {children}
      </div>
    ) : (
      children
    );
  };

  useDidUpdate(() => {
    setShow(show);
  }, [show]);

  useDidUpdate(() => {
    setShow(undefined);
  }, [keepOpen]);

  return wrapperFn(
    <OverlayTrigger
      {...overlayTriggerProps}
      show={innerShow}
      overlay={
        <Tooltip id={id} className={tooltipClassName}>
          {message}
        </Tooltip>
      }
    >
      {children}
    </OverlayTrigger>
  );
};

//----------------------------------------------------------------------------//

export interface TooltipProps extends CustomOverlayTriggerProps {
  disabled?: boolean;
}

export const TooltipHandler: React.FunctionComponent<TooltipProps> = ({
  disabled = false,
  children,
  ...overlayTriggerProps
}) => {
  return disabled ? (
    <>{children}</>
  ) : (
    <CustomOverlayTrigger {...overlayTriggerProps}>{children}</CustomOverlayTrigger>
  );
};

export default TooltipHandler;
