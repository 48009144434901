import React, { ChangeEvent, FC, useMemo, useRef, useState } from "react";
import { Field, Formik } from "formik";
import { cloneDeep, findIndex, isEqual } from "lodash";
import { FormattedMessage, useIntl } from "react-intl";
import { Input } from "../../_metronic/_partials/controls";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../_metronic/_helpers";
import { Overlay, Popover } from "react-bootstrap";
import { IOption } from "../_utils/interfacesUtils";
import { isFunction } from "lodash-es";

interface FilterProps {
  useEntityUIContext: Function;
}

const prepareFilter = (filters: any, searchText: string, filterSelected: Object[]) => {
  const newFilters = { ...filters };
  if (searchText) {
    newFilters.freeText = searchText.trim();
  } else {
    newFilters.freeText = "";
  }
  newFilters.filterSelected = filterSelected;
  return newFilters;
};

export const Filter: FC<FilterProps> = ({ useEntityUIContext }) => {
  const intl = useIntl();
  // Products UI Context
  const ctx = useEntityUIContext();

  const [show, setShow] = useState(false);
  const target = useRef(null);

  const uiProps = useMemo(() => {
    return {
      setFilters: ctx.setFilters,
      filters: ctx.filters,
      filterList: ctx.filterList,
    };
  }, [ctx]);

  const applyFilter = (values: { searchText: string; filterList: Object[] }) => {
    const { searchText, filterList } = cloneDeep(values);
    let newFilters = prepareFilter(uiProps.filters, searchText, filterList ?? []);
    if (!isEqual(newFilters, uiProps.filters)) {
      newFilters.pageNumber = 1;
      uiProps.setFilters((prevQueryParams: any) => {
        if (isFunction(newFilters)) {
          newFilters = newFilters(prevQueryParams);
        }
        if (isEqual(prevQueryParams, newFilters)) {
          return prevQueryParams;
        }
        return newFilters;
      });
    }
  };
  return (
    <Formik
      initialValues={
        {
          searchText: "",
          filterList: [],
        } as { searchText: string; filterList: IOption[] }
      }
      onSubmit={(values) => applyFilter(values)}
    >
      {({ values, handleSubmit, handleBlur, handleChange, setFieldValue }) => (
        <form onSubmit={handleSubmit} className="form form-label-right mb-4">
          <div className={"d-flex mb-4 flex-column-reverse flex-sm-row"}>
            {/*FILTER*/}
            <div>
              <button
                className={
                  "btn btn-primary d-flex mr-sm-2 w-100 w-sm-auto mt-4 mt-sm-0 d-flex justify-content-center"
                }
                ref={target}
                onClick={() => setShow(!show)}
                type="button"
              >
                <span className={"svg-icon svg-icon-md mr-2"}>
                  <SVG src={toAbsoluteUrl("/media/svg/icons/Shopping/Settings.svg")} />
                </span>
                <FormattedMessage id={"COMMON.FILTERS"} />
              </button>
              <Overlay
                placement={"bottom-start"}
                target={target.current}
                show={show}
                rootClose
                onHide={(e) => {
                  e.stopPropagation();
                  setShow(false);
                }}
              >
                <Popover id="popover-contained" className={"p-0 m-0 bg-gray-100"}>
                  <Popover.Content className={"p-0 m-0 bg-gray-100"}>
                    <div className={"bg-gray-100 border-0 mt-2"}>
                      {(uiProps.filterList || []).map((filter: IOption) => {
                        const isChecked = !!values.filterList.find((filter2) =>
                          isEqual(filter2, filter)
                        );
                        return (
                          <div
                            key={filter.value}
                            className={"btn btn-light-primary px-2 py-1 d-flex w-100"}
                            onClick={(e) => {
                              if (!isChecked) {
                                values.filterList.push(filter);
                              } else {
                                const index = findIndex(
                                  values.filterList,
                                  (data) => data.value === filter.value
                                );
                                if (index > -1) values.filterList.splice(index, 1);
                              }
                              setFieldValue("filterList", values.filterList);
                              handleSubmit();
                            }}
                          >
                            <span className="svg-icon svg-icon-xl svg-icon-success h-30px w-35px m-auto ">
                              {isChecked && (
                                <SVG src={toAbsoluteUrl("/media/svg/icons/Navigation/Check.svg")} />
                              )}
                            </span>
                            <p className={"my-auto px-2 w-100 text-left"}>
                              <FormattedMessage id={filter.label} />
                            </p>
                          </div>
                        );
                      })}
                    </div>
                  </Popover.Content>
                </Popover>
              </Overlay>
            </div>

            {/*SEARCHBAR*/}
            <div className={"w-100 mr-2"}>
              <Field
                data-cy="input-search"
                name="searchText"
                component={Input}
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  setFieldValue("searchText", e.target.value);
                  handleSubmit();
                }}
                onBlur={handleBlur}
                placeholder={intl.formatMessage({
                  id: "COMMON.SEARCH.TITLE",
                })}
                className={"h-100"}
              />
            </div>
          </div>
        </form>
      )}
    </Formik>
  );
};
