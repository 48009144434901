import * as React from "react";
import cn from "clsx";

import { CardBaseProps } from "./definitions";

export interface CardHeaderTitleProps extends CardBaseProps<HTMLHeadingElement> {}

export const CardHeaderTitle = React.forwardRef<HTMLHeadingElement, CardHeaderTitleProps>(
  ({ className, children, ...props }, ref) => (
    <h2
      {...{
        ref,
        className: cn("card-label", className),
        ...props,
      }}
    >
      {children}
    </h2>
  )
);

export default CardHeaderTitle;

// Set display name for debugging.
if (process.env.NODE_ENV !== "production") {
  CardHeaderTitle.displayName = "CardHeaderTitle";
}
