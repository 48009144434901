import React, { FC, useEffect } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useSubheader } from "../../_metronic/layout";
import { toAbsoluteUrl } from "../../_metronic/_helpers";
import { DateUtils } from "../_utils/DateUtils";
import SVG from "react-inlinesvg";
import { translateBreadcrumbs } from "../_utils/routeUtils";

type Props = {
  breadcrumbs: any;
  successMessageId?: string;
  successDateTime?: Date;
  title?: string;
};
export const CardSuccess: FC<Props> = ({
  breadcrumbs,
  successMessageId,
  successDateTime,
  title,
}) => {
  const intl = useIntl();
  const subheader = useSubheader();

  useEffect(() => {
    subheader.setBreadcrumbs(translateBreadcrumbs(intl, breadcrumbs));
  }, []);

  return (
    <div className="card">
      <div className="card-header">
        <div className="card-body">
          <div className="d-flex flex-column align-items-center">
            <span className="svg-icon svg-icon-9x svg-icon-success mb-4">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Code/Done-circle.svg")} />
            </span>
            {successMessageId && (
              <h3 className="text-center">
                <FormattedMessage id={successMessageId} />
              </h3>
            )}
            {successDateTime && (
              <strong data-cy="text-form-submitted" className="text-center text-success">
                ({DateUtils.format(new Date(successDateTime), intl, true)})
              </strong>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
