import * as React from "react";
import { FormattedMessage } from "react-intl";
import cn from "clsx";

import {
  useRequestSignatureDialogContext,
  RequestSignatureDialogUiState,
} from "../RequestSignatureDialogContext";

import "./RequestSignatureDialogTitle.scss";

export const RequestSignatureDialogTitle: React.FunctionComponent = () => {
  const { uiState } = useRequestSignatureDialogContext();

  if (uiState === RequestSignatureDialogUiState.CANCELLATION) {
    return <FormattedMessage id="COMMON.ACTION.CANCEL.ARE_YOU_SURE.TITLE.SIGNATURES_REQUEST" />;
  }

  const renderStatus = () => {
    let statusClassName = "";
    let statusLabel = "";

    switch (uiState) {
      case RequestSignatureDialogUiState.DONE:
        statusClassName = "status-done";
        statusLabel = "COMMON.ACTION.SIGNATURES_REQUEST.STATUS.DONE";
        break;
      case RequestSignatureDialogUiState.VIEW:
        statusClassName = "status-in-progress";
        statusLabel = "COMMON.ACTION.SIGNATURES_REQUEST.STATUS.IN_PROGRESS";
        break;
      case RequestSignatureDialogUiState.CREATE:
      case RequestSignatureDialogUiState.EDIT:
        statusClassName = "status-needed";
        statusLabel = "COMMON.ACTION.SIGNATURES_REQUEST.STATUS.NEEDED";
        break;
      default:
        statusClassName = "invisible";
        statusLabel = "";
        break;
    }

    return (
      <div className={cn("ml-6 small status", statusClassName)}>
        {statusLabel && <FormattedMessage id={statusLabel} />}
      </div>
    );
  };

  return (
    <div className="d-flex align-items-center">
      <FormattedMessage id="COMMON.ACTION.SIGNATURES_REQUEST_DETAILS" /> {renderStatus()}
    </div>
  );
};

export default RequestSignatureDialogTitle;
