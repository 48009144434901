import React from "react";
import { FormattedMessage } from "react-intl";
import { Checkbox } from "../../../Checkbox";
import WarningMessage from "app/_components/WarningMessage";
import { IRequestSignatory } from "data/schemas";
import { useRequestSignatureDialogContext } from "../../RequestSignatureDialogContext";

interface SignatoriesGrantAccessProps {
  disabledUsersSignatory: IRequestSignatory[];
}

const SignatoriesGrantAccess = ({ disabledUsersSignatory }: SignatoriesGrantAccessProps) => {
  const { toggleGrantAccess } = useRequestSignatureDialogContext();

  const disabledUsersLength = disabledUsersSignatory.length;

  const namesLabel =
    disabledUsersLength > 1 ? (
      <div className="text-lowercase ml-1 font-weight-bold">
        <span className="mr-1">{disabledUsersLength}</span>
        <FormattedMessage id="USER.TITLE" />
      </div>
    ) : (
      <p className="font-weight-bold mb-0 ml-1">{disabledUsersSignatory[0].fullName}</p>
    );

  return (
    <div>
      <label className="font-size-lg font-weight-bold mb-4">
        <FormattedMessage id="SIGNATURE.GRANT_ACCESS.TITLE" />
      </label>
      <WarningMessage messageId="SIGNATURE.GRANT_ACCESS.WARNING_MESSAGE" />
      <div className="my-4">
        <FormattedMessage id="SIGNATURE.GRANT_ACCESS.DESCRIPTION" />
      </div>
      <Checkbox
        label="SIGNATURE.GRANT_ACCESS.CHECKBOX"
        customClasses="mb-4"
        onChange={toggleGrantAccess}
        customLabelValues={{
          names: namesLabel,
        }}
      />
    </div>
  );
};

export default React.memo(SignatoriesGrantAccess);
