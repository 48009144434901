import * as React from "react";
import { DebouncedFunc } from "lodash-es";
import { IInstalmentsColumns, InstalmentsTableRow } from "../BudgetInstalments";
import { CellContext, ColumnDefTemplate } from "@tanstack/react-table";
import { isUndefined } from "lodash";
import { NumberInput } from "../../../../_utils/formUtils";
import { SUFFIX_EURO_CURRENCY, SUFFIX_PERCENTAGE } from "../../../../_utils/suffixUtils";
import { NumberFormatValues } from "react-number-format";
import { Field } from "formik";
import { accurateFloatOperation } from "../../../../_utils/mathUtils";
import { Input } from "../../../../../_metronic/_partials/controls";
import cn from "clsx";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../../_metronic/_helpers";

export interface BudgetInstalmentsCellProps {
  customHandleChange: DebouncedFunc<(event: any) => void>;
  typedKey: keyof IInstalmentsColumns;
  disabled: boolean;
}

export const BudgetInstalmentsCell: ColumnDefTemplate<
  CellContext<InstalmentsTableRow, any> & BudgetInstalmentsCellProps
> = function ({ row, getValue, customHandleChange, typedKey, disabled }) {
  const { sequence, id } = row.original;

  const editableRow = ![
    "TOTAL_INSTALMENT_ROW",
    "SHARE_OF_LAND",
    "ARCHITECT_ENGINEERING_FEES",
  ].includes(id);

  let initialValue = getValue();
  const [value, setValue] = React.useState(initialValue);
  React.useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  const handlePlannedChange = (event: NumberFormatValues) => {
    const value = accurateFloatOperation((event.floatValue ?? 0) / 100, 4) || 0;
    setValue(value);
    customHandleChange({
      target: {
        name: `budgetInstalments.${sequence}.instalment`,
        value: value,
      },
    });
  };

  const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value);
    customHandleChange(event);
  };

  // Empty column render
  if (isUndefined(value)) {
    return "-";
  }

  switch (typedKey) {
    case "label":
      return (
        <div className="d-flex align-items-center flex-grow-1">
          {!editableRow ? (
            value
          ) : (
            <>
              <Field name={`budgetInstalments.${sequence}.id`} type="hidden" />
              <Field
                name={`budgetInstalments.${sequence}.label`}
                value={value}
                className="form-control"
                component={Input}
                withFeedbackLabel={false}
                onChange={handleNameChange}
                disabled={disabled}
              />
            </>
          )}
        </div>
      );
    case "instalment":
      return id === "TOTAL_INSTALMENT_ROW" ? (
        <>
          <NumberInput
            displayType="text"
            fixedDecimalScale={true}
            className={cn(
              "font-weight-bold text-right",
              value < 1 ? "text-warning" : value > 1 ? "text-danger" : "text-success"
            )}
            value={value * 100}
            decimalScale={2}
            suffix={SUFFIX_PERCENTAGE}
          />
          <OverlayTrigger
            placement="top"
            overlay={
              <Tooltip id="percentage-construction-price" className={"tooltip-auto-width"}>
                <FormattedMessage id="COMMON.PERCENTAGE.CONSTRUCT.PRICE" />
              </Tooltip>
            }
          >
            <span className="svg-icon svg-icon-md svg-icon-info ml-2">
              <SVG
                className={"pb-1"}
                src={toAbsoluteUrl("/media/svg/icons/Code/Info-circle.svg")}
              />
            </span>
          </OverlayTrigger>
        </>
      ) : (
        <NumberInput
          className={"form-control text-right"}
          value={value * 100}
          suffix={SUFFIX_PERCENTAGE}
          decimalScale={2}
          onValueChange={handlePlannedChange}
          disabled={disabled}
          onClick={(e: React.MouseEvent<HTMLInputElement>) => e.stopPropagation()}
          displayType={editableRow ? "input" : "text"}
        />
      );
    case "amountPreview":
      return (
        <>
          <NumberInput
            displayType="text"
            fixedDecimalScale={true}
            className="font-weight-bold text-right"
            value={value}
            decimalScale={2}
            suffix={SUFFIX_EURO_CURRENCY}
          />
          {id === "TOTAL_INSTALMENT_ROW" && (
            <OverlayTrigger
              placement="top"
              overlay={
                <Tooltip id="layout-tooltip" className={"tooltip-auto-width"}>
                  <FormattedMessage id="BUDGET.INSTALMENTS.EXPECTED_TOTAL" />
                </Tooltip>
              }
            >
              <span className="svg-icon svg-icon-md svg-icon-info ml-2">
                <SVG
                  className={"pb-1"}
                  src={toAbsoluteUrl("/media/svg/icons/Code/Info-circle.svg")}
                />
              </span>
            </OverlayTrigger>
          )}
        </>
      );
  }
};
