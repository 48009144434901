import { API } from "@aws-amplify/api";

import {
  ISignaturesRequestBody,
  ISignaturesRequest,
  ICancelSignaturesResponseBody,
  ICreateSignaturesResponseBody,
} from "data/schemas/signatures";

//----------------------------------------------------------------------------//

const ENDPOINT = "/signatures";

/**
 *  Create a new Signatures Request
 *
 * @param {ISignaturesRequestBody} signaturesRequest
 * @returns
 */
export const createSignaturesRequest = (
  signaturesRequest: ISignaturesRequestBody
): Promise<ICreateSignaturesResponseBody> =>
  API.post("API", `/signatures`, { body: signaturesRequest });

/**
 * Get a Signatures Request by its id
 *
 * @param {string} id - of the signatures request object
 * @returns {ISignaturesRequest} object - the Signatures Request one
 */
export const getSignaturesRequest = (id: string): Promise<ISignaturesRequest> =>
  API.get("API", `${ENDPOINT}/${id}/requests`, {});

/**
 *
 * @param signaturesRequest
 * @returns {IFile} object - the updated file object
 */
export const cancelSignaturesRequest = (
  signaturesRequest: ISignaturesRequest
): Promise<ICancelSignaturesResponseBody> => {
  const { id } = signaturesRequest;
  return API.post("API", `${ENDPOINT}/${id}/cancel`, { body: signaturesRequest });
};

//----------------------------------------------------------------------------//

/**
 * Request the generation of an OTP code
 *
 * @param id it's the url param id
 * @returns empty
 */
export const requestSignatureOtp = (id: string) => API.post("API", `${ENDPOINT}/${id}/otp`, {});

/**
 * Get the information of a single signature request
 *
 * @param id - it's the url param id
 * @returns object - needed information to display and process a signature request
 */
export const getSignature = (id: string) => API.get("API", `${ENDPOINT}/${id}`, {});

/**
 * Call the endpoint to process a signature
 *
 * @param id - it's the url param id
 * @param code - OTP code to validate the signature
 * @returns object - the signature request updated
 */
export const postSignature = (id: string, code: string) =>
  API.post("API", `${ENDPOINT}/${id}`, { body: { code } });
