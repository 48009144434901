import { useSearch } from "../../hooks/useSearch";
import * as React from "react";
import { IFilterSearchType, SearchTypeValues } from "../../contexts/SearchContext";
import { SearchText } from "./SearchText";
import { SearchDropdown } from "./SearchDropdown";
import { SearchDate } from "./SearchDate";
import { FormattedMessage } from "react-intl";
import { SearchCheckBox } from "./SearchCheckBox";
import { cloneDeep } from "lodash-es";
import { SearchDateRange } from "./SearchDateRange";
import { merge } from "lodash";

interface SearchLocalProps {
  useEntityUIContext?: any;
  filters?: IFilterSearchType[];
}

export const SearchLocal: React.FC<SearchLocalProps> = ({
  useEntityUIContext,
  filters,
}): JSX.Element => {
  const { filters: searchFilters, setFilters } = useSearch();
  const ctx = useEntityUIContext?.();

  const [isShowAdvanced, setIsShowAdvanced] = React.useState<boolean>(false);
  const isAdvancedDisplayed = searchFilters.some((f) => f.isAdvanced);

  React.useEffect(() => {
    if (!searchFilters?.length) {
      const filtersProps = cloneDeep(ctx?.filtersUI ?? filters ?? []);
      setFilters(filtersProps.filter((x: any) => x !== false) ?? []);
    }
  }, []);

  React.useEffect(() => {
    const filtersProps = cloneDeep(ctx?.filtersUI ?? filters ?? []);
    setFilters(merge(cloneDeep(searchFilters), filtersProps));
  }, [ctx?.filtersUI, filters]);

  const displayFilters = (filters: IFilterSearchType[]) => {
    const values: JSX.Element[] = [];
    filters.forEach((filter: IFilterSearchType) => {
      switch (filter.type) {
        case SearchTypeValues.SEARCH_TEXT_COLUMNS:
          values.push(
            <SearchText
              name={filter.name}
              value={filter.selected}
              key={"filter_Text_" + filter.name}
            />
          );
          break;

        case SearchTypeValues.SEARCH_DROPDOWN:
          values.push(<SearchDropdown filter={filter} key={"filter_Dropdown_" + filter.name} />);
          break;

        case SearchTypeValues.SEARCH_DATE:
          values.push(
            <SearchDate
              name={filter.value}
              label={filter.name}
              isFuture={filter.isFuture}
              isFlexGrow={filter.isAdvanced}
              key={"filter_Date_" + filter.name}
            />
          );
          break;

        case SearchTypeValues.SEARCH_CHECKBOX:
          values.push(
            <SearchCheckBox
              name={filter.value}
              label={filter.name}
              isChecked={filter.isChecked}
              isFlexGrow={filter.isAdvanced}
              key={"filter_CB_" + filter.name}
            />
          );
          break;
        case SearchTypeValues.SEARCH_DATE_RANGE:
          values.push(<SearchDateRange filter={filter} key={"filter_DateRange_" + filter.name} />);
      }
    });
    return values;
  };

  return (
    <div className="form form-label-right w-100">
      <div className="d-flex flex-column flex-sm-row flex-wrap mr-n2">
        {displayFilters(searchFilters?.filter((f: any) => !f.isAdvanced))}
        {isShowAdvanced && displayFilters(searchFilters?.filter((f: any) => f.isAdvanced))}
        {isAdvancedDisplayed && !isShowAdvanced && (
          <div className="mr-md-2 mr-0 mt-2 w-100 w-md-auto">
            <button
              type="button"
              className="btn btn-primary w-sm-100 flex-grow-1 min-h-40px"
              onClick={() => setIsShowAdvanced(!isShowAdvanced)}
            >
              <FormattedMessage id="COMMON.SEARCH.ADVANCED_FILTERS" />
            </button>
          </div>
        )}
      </div>
    </div>
  );
};
