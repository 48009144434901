import * as React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useFormikContext } from "formik";
import {
  PriceDetails,
  PriceDetailsLineCurrency,
} from "../../../../../../../_components/PriceDetails/PriceDetails";
import {
  isReInvoiceDetailPriceLine,
  TSubcontractorFile,
} from "../../../../../../../../data/schemas";

export interface UserFileFinanceTotalsProps {}

export const UserFileFinanceTotals: React.FC<UserFileFinanceTotalsProps> = () => {
  const { values } = useFormikContext<TSubcontractorFile>();
  const intl = useIntl();

  let subcontractorValues = {
    subtotal: 0,
    discounts: 0,
    netTotal: 0,
    totalVat: 0,
    total: 0,
  };
  for (const cat of values.categories ?? []) {
    for (const line of cat.lines) {
      subcontractorValues.subtotal += line.grossAmount;
      subcontractorValues.discounts += line.grossAmount - line.amount;
      subcontractorValues.netTotal += line.amount;
      subcontractorValues.totalVat += line.amount * line.vat;
      subcontractorValues.total += line.amount + line.amount * line.vat;
    }
  }

  const reInvoiceValues = { subtotal: 0, margin: 0, totalNet: 0, totalVat: 0, total: 0 };
  if (values.enableReInvoicing) {
    for (const detail of values.reInvoiceFile?.details ?? []) {
      if (isReInvoiceDetailPriceLine(detail)) {
        const amount =
          (detail.unitPrice ?? 0) * (values.reInvoiceFile?.useQuantities ? detail.quantity : 1);
        const margin =
          detail.marginType === "absolute"
            ? detail.margin ?? 0
            : (amount * (detail.margin ?? 0)) / 100;
        const netAmount = amount + margin;
        reInvoiceValues.subtotal += amount;
        reInvoiceValues.margin += margin;
        reInvoiceValues.totalNet += netAmount;
        reInvoiceValues.totalVat += netAmount * detail.vat;
        reInvoiceValues.total += netAmount + netAmount * detail.vat;
      }
    }
  }

  return (
    <div className="d-flex flex-wrap">
      <div className="d-flex flex-column justify-content-between">
        {values.enableReInvoicing && (
          <div className="mb-2 font-weight-bold font-size-h4">
            <FormattedMessage id={`FILE.SUBCONTRACTOR_${values.fileType}`} />
          </div>
        )}
        <PriceDetails>
          <PriceDetailsLineCurrency
            label={intl.formatMessage({ id: "COMMON.SUBTOTAL" })}
            value={subcontractorValues.subtotal}
          />
          {!!subcontractorValues.discounts && (
            <PriceDetailsLineCurrency
              label={intl.formatMessage({ id: "COMMON.DISCOUNT" })}
              value={subcontractorValues.discounts}
            />
          )}
          {!!subcontractorValues.discounts && (
            <PriceDetailsLineCurrency
              label={intl.formatMessage({ id: "COMMON.NET_TOTAL" })}
              value={subcontractorValues.netTotal}
              style={{ fontWeight: "600" }}
            />
          )}
          <PriceDetailsLineCurrency
            label={intl.formatMessage({ id: "COMMON.VAT" })}
            value={subcontractorValues.totalVat}
          />
          <PriceDetailsLineCurrency
            label={intl.formatMessage({ id: "COMMON.TOTAL" })}
            style={{ fontWeight: "bold", fontSize: "1.2em" }}
            value={subcontractorValues.total}
          />
        </PriceDetails>
      </div>
      {values.enableReInvoicing && (
        <div className="ml-20 d-flex flex-column justify-content-between">
          <div className={"mb-2 font-weight-bold font-size-h4"}>
            <FormattedMessage id={"BUDGET.CLIENT_SUPPLEMENTARY_AGREEMENT"} />
          </div>
          <PriceDetails>
            <PriceDetailsLineCurrency
              label={intl.formatMessage({ id: "COMMON.SUBTOTAL" })}
              value={reInvoiceValues.subtotal}
            />
            <PriceDetailsLineCurrency
              label={intl.formatMessage({ id: "COMMON.MARGIN" })}
              value={reInvoiceValues.margin}
            />
            <PriceDetailsLineCurrency
              label={intl.formatMessage({ id: "COMMON.NET_TOTAL" })}
              value={reInvoiceValues.totalNet}
              style={{ fontWeight: "600" }}
            />
            <PriceDetailsLineCurrency
              label={intl.formatMessage({ id: "COMMON.VAT" })}
              value={reInvoiceValues.totalVat}
            />
            <PriceDetailsLineCurrency
              label={intl.formatMessage({ id: "COMMON.TOTAL" })}
              style={{ fontWeight: "bold", fontSize: "1.2em" }}
              value={reInvoiceValues.total}
            />
          </PriceDetails>
        </div>
      )}
    </div>
  );
};
