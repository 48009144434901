import * as React from "react";
import { TabNavHeader } from "../../../../_components/TabNavHeader";
import { InvoiceDashboardProjectsData } from "../../../../../data/schemas/Dashboard";
import { AccountsReceivableInvoicesTable } from "./AccountsReceivableInvoicesTable";
import { SearchProvider } from "../../../../contexts/SearchContext";

export interface InvoiceManagementInvoice {
  id: string;
  createdAt: string;
  projectName: string;
  name: string;
  amount: number;
  assignee: string;
  userIds: string[];
  invoiceStatus?: string;
}

interface InvoiceManagementInvoicesProps {
  data: InvoiceDashboardProjectsData;
  selectedProjects: string[];
}

export const InvoiceManagementInvoices = ({
  data,
  selectedProjects,
}: InvoiceManagementInvoicesProps) => {
  const [tab, setTab] = React.useState("accounts-receivable");

  const { receivableInvoices, filteredClients } = React.useMemo(() => {
    const receivableInvoices: InvoiceManagementInvoice[] = [];
    const filteredClients: any = {};
    for (const invoice of data.leadsInvoices) {
      let isFiltered = false;
      isFiltered = !!selectedProjects.length && !selectedProjects.includes(invoice.projectId);

      if (isFiltered) {
        continue;
      }

      const invoiceClients = [];
      const invoiceClientsIds = [];
      for (const client of Object.values(data.clients)) {
        if (client.leads.includes(invoice.leadId)) {
          const userName = `${client.firstName} ${client.lastName}`;
          filteredClients[client.id] = { id: client.id, userName };
          invoiceClients.push(userName);
          invoiceClientsIds.push(client.id);
        }
      }
      const formattedInvoice = {
        ...invoice,
        assignee: invoiceClients.join(", "),
        projectName: data.projects[invoice.projectId].name,
        userIds: invoiceClientsIds,
      };
      receivableInvoices.push(formattedInvoice);
    }
    return { receivableInvoices, filteredClients };
  }, [data, selectedProjects]);

  const { payableInvoices, filteredSubcontractors } = React.useMemo(() => {
    const payableInvoices: InvoiceManagementInvoice[] = [];
    const filteredSubcontractors: any = {};
    for (const invoice of data.subcontractorsInvoices) {
      let isFiltered = false;
      isFiltered = !!selectedProjects.length && !selectedProjects.includes(invoice.projectId);

      if (isFiltered) {
        continue;
      }

      const user = data.subcontractors[invoice.userId];
      const userName = `${user.companyName ? user.companyName + " - " : ""}${user.firstName} ${
        user.lastName
      }`;
      filteredSubcontractors[user.id] = { id: user.id, userName };
      const formattedInvoice = {
        ...invoice,
        assignee: userName,
        projectName: data.projects[invoice.projectId].name,
        userIds: [user.id],
      };
      payableInvoices.push(formattedInvoice);
    }
    return { payableInvoices, filteredSubcontractors };
  }, [data, selectedProjects]);

  return (
    <div className="row">
      <div className="col-12">
        <div className={"card card-custom gutter-b"}>
          <div className="card-body">
            <ul className="nav nav-tabs nav-tabs-line " role="tablist">
              <TabNavHeader
                name="accounts-receivable"
                tab={tab}
                setTab={setTab}
                translationId="DASHBOARD.INVOICE_MANAGEMENT.ACCOUNTS_RECEIVABLE"
              />
              <TabNavHeader
                name="accounts-payable"
                tab={tab}
                setTab={setTab}
                translationId="DASHBOARD.INVOICE_MANAGEMENT.ACCOUNTS_PAYABLE"
              />
            </ul>
            <div>
              <SearchProvider entities={receivableInvoices}>
                {tab === "accounts-receivable" && (
                  <AccountsReceivableInvoicesTable users={filteredClients} />
                )}
              </SearchProvider>
              <SearchProvider entities={payableInvoices}>
                {tab === "accounts-payable" && (
                  <AccountsReceivableInvoicesTable users={filteredSubcontractors} />
                )}
              </SearchProvider>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
