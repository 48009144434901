import { Field } from "formik";
import * as React from "react";
import {
  KYC_V2_OPTIONS_COUNTRIES,
  KYC_V2_OPTIONS_GEO_COUNTRY_RISK_HIGHER_RISK,
  KYC_V2_OPTIONS_GEO_COUNTRY_RISK_LOWER_RISK,
} from "../KycTranslationUtils";
import { KycTranslation } from "../KycTranslation";
import { KycDropdownSelect } from "./controls/kyc-dropdown-select/KycDropdownSelect";
import { KycCheckboxList } from "./controls/KycCheckboxList";

type KycAddressFormProps = {
  field: any;
  label?: string;
  disabled?: boolean;
};

export const KycRiskAssessmentLowerHigherRisk = ({
  field,
  label,
  disabled,
}: KycAddressFormProps): JSX.Element => {
  const assembleFieldPath = (fieldName: string) => {
    return `${field.name}.${fieldName}`;
  };

  return (
    <>
      <Field
        indent={false}
        clearable={true}
        disabled={disabled}
        component={KycDropdownSelect}
        name={assembleFieldPath("countryCode")}
        options={KYC_V2_OPTIONS_COUNTRIES}
        translateLabels={true}
        translateRegion={true}
        label={label}
      />
      {field?.value?.countryCode && (
        <div className="row mt-2 mb-4 mx-4">
          {/*lower risk*/}
          <div className="col-md-0 col-lg-0" />
          <div className="col-md-12 col-lg-6">
            <Field
              name={assembleFieldPath("lowerRisk")}
              title={KycTranslation.RISK_EVALUATION_GEO_COUNTRY_LOWER_RISK_TITLE}
              options={KYC_V2_OPTIONS_GEO_COUNTRY_RISK_LOWER_RISK}
              component={KycCheckboxList}
            />
          </div>
          {/*higher risk*/}
          <div className="col-md-12 col-lg-6">
            <Field
              name={assembleFieldPath("higherRisk")}
              title={KycTranslation.RISK_EVALUATION_GEO_COUNTRY_HIGHER_RISK_TITLE}
              options={KYC_V2_OPTIONS_GEO_COUNTRY_RISK_HIGHER_RISK}
              component={KycCheckboxList}
            />
          </div>
        </div>
      )}
    </>
  );
};
