import { useSearch } from "../../hooks/useSearch";
import { DateRangePicker, DateRangePickerProps } from "../DateRangePicker/DateRangePicker";
import * as React from "react";
import { IFilterSearchTypeDateRange, SearchTypeValues } from "../../contexts/SearchContext";

interface SearchDateProps {
  filter: IFilterSearchTypeDateRange;
}

export const SearchDateRange = ({
  filter: { name, value: filterValue, selected },
}: SearchDateProps) => {
  const { setSearchValues } = useSearch();
  const onChange: DateRangePickerProps["onChange"] = (value) => {
    setSearchValues(value, SearchTypeValues.SEARCH_DATE_RANGE, filterValue);
  };
  return (
    <div className={"mr-sm-2 mr-0 mt-2"} data-cy={"filter-date-range-" + filterValue.toLowerCase()}>
      <DateRangePicker onChange={onChange} label={name} value={selected} />
    </div>
  );
};
