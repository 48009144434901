/* eslint-disable no-restricted-imports */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useMemo } from "react";
import { Link } from "react-router-dom";
import Dropdown from "react-bootstrap/Dropdown";
import { useSelector } from "react-redux";
import objectPath from "object-path";
import { toAbsoluteUrl } from "../../../../_helpers";
import { DropdownTopbarItemToggler } from "../../../../_partials/dropdowns";
import { useHtmlClassService } from "../../..";
import { UserAvatarImg } from "../../../../../app/_components/UserAvatarImg";
import { FormattedMessage } from "react-intl";
import { canSeeSettings } from "../../../../../app/_utils/authUtils";

export function UserProfileDropdown() {
  const { tokens, session, groups } = useSelector((state) => state.auth);

  const uiService = useHtmlClassService();
  const layoutProps = useMemo(() => {
    return {
      light: objectPath.get(uiService.config, "extras.user.dropdown.style") === "light",
    };
  }, [uiService]);

  const userAvatarImage =
    session?.profilePictureSetup && session?.profilePictureUrl ? (
      <span className="symbol symbol-35 min-w-35px min-h-35px bg-white-o-30 flex-shrink-0">
        <UserAvatarImg user={session} />
      </span>
    ) : (
      <span className="symbol symbol-35 min-w-35px min-h-35px">
        <span className="symbol-label text-white font-size-h5 font-weight-bold bg-white-o-30">
          {session?.firstName || session?.lastName ? (
            <>
              {session?.firstName[0]?.toUpperCase()}
              {session?.lastName[0]?.toUpperCase()}
            </>
          ) : (
            <>{tokens?.idToken?.payload?.email[0].toUpperCase()}</>
          )}
        </span>
      </span>
    );

  return (
    <Dropdown drop="down" alignRight>
      <Dropdown.Toggle as={DropdownTopbarItemToggler} id="dropdown-toggle-user-profile">
        <div
          data-cy="link-parent-user-profile-dropdown"
          className={
            "btn btn-icon btn-hover-transparent-white d-flex align-items-center btn-lg px-md-2 w-md-auto"
          }
        >
          <span className="text-white opacity-90 font-weight-bolder font-size-base d-none d-md-inline mr-4">
            {session?.firstName || session?.lastName ? (
              <>
                {session?.firstName} {session?.lastName}
              </>
            ) : (
              <>{tokens?.idToken?.payload?.email}</>
            )}
          </span>

          {userAvatarImage}
        </div>
      </Dropdown.Toggle>
      <Dropdown.Menu className="dropdown-menu p-0 m-0 dropdown-menu-right dropdown-menu-anim-up dropdown-menu-lg p-0">
        <>
          {/** ClassName should be 'dropdown-menu p-0 m-0 dropdown-menu-right dropdown-menu-anim dropdown-menu-top-unround dropdown-menu-xl' */}
          {layoutProps.light && (
            <>
              <div className="d-flex align-items-center p-8 rounded-top">
                {userAvatarImage}

                <div className="ml-4 text-dark m-0 flex-grow-1 mr-3 font-size-h5">
                  {session?.firstName || session?.lastName ? (
                    <>
                      {session?.firstName} {session?.lastName}
                    </>
                  ) : (
                    <>{tokens?.idToken?.payload?.email.split("@")[0]}</>
                  )}
                </div>
                {/*<span className="label label-light-success label-lg font-weight-bold label-inline">3 messages</span>*/}
              </div>
              <div className="separator separator-solid" />
            </>
          )}

          {!layoutProps.light && (
            <div
              className="d-flex align-items-center justify-content-between flex-wrap p-8 bgi-size-cover bgi-no-repeat rounded-top"
              style={{
                backgroundImage: `url(${toAbsoluteUrl("/media/misc/bg-1.jpg")})`,
              }}
            >
              <div className="symbol bg-white-o-15 mr-3">
                <span className="symbol-label text-success font-weight-bold font-size-h4">S</span>
                {/*<img alt="Pic" className="hidden" src={user.pic} />*/}
              </div>
              <div className="text-white m-0 flex-grow-1 mr-3 font-size-h5">Sean Stone</div>
              <span className="label label-success label-lg font-weight-bold label-inline">
                3 messages
              </span>
            </div>
          )}
        </>

        <div className="navi navi-spacer-x-0 pt-5">
          <Link to="/profile/me" className="navi-item px-8" data-cy="link-user-profile-me">
            <div className="navi-link">
              <div className="navi-icon mr-2">
                <i className="flaticon2-calendar-3 text-success" />
              </div>
              <div className="navi-text">
                <div className="font-weight-bold">
                  <FormattedMessage id="PROFILE.MY_PROFILE" />
                </div>
                <div className="text-muted">
                  <FormattedMessage id="PROFILE.ACCOUNT_SETTINGS_AND_MORE" />
                </div>
              </div>
            </div>
          </Link>

          {canSeeSettings(groups, session) && (
            <Link to="/settings" className="navi-item px-8" data-cy="link-settings">
              <div className="navi-link">
                <div className="navi-icon mr-2">
                  <i className="flaticon2-gear text-body" />
                </div>
                <div className="navi-text">
                  <div className="font-weight-bold">
                    <FormattedMessage id="SETTING.TITLE" />
                  </div>
                </div>
              </div>
            </Link>
          )}

          {/*<a className="navi-item px-8">*/}
          {/*  <div className="navi-link">*/}
          {/*    <div className="navi-icon mr-2">*/}
          {/*      <i className="flaticon2-mail text-warning"></i>*/}
          {/*    </div>*/}
          {/*    <div className="navi-text">*/}
          {/*      <div className="font-weight-bold">*/}
          {/*        My Messages*/}
          {/*      </div>*/}
          {/*      <div className="text-muted">*/}
          {/*        Inbox and tasks*/}
          {/*      </div>*/}
          {/*    </div>*/}
          {/*  </div>*/}
          {/*</a>*/}

          {/*<a className="navi-item px-8">*/}
          {/*  <div className="navi-link">*/}
          {/*    <div className="navi-icon mr-2">*/}
          {/*      <i className="flaticon2-rocket-1 text-danger"></i>*/}
          {/*    </div>*/}
          {/*    <div className="navi-text">*/}
          {/*      <div className="font-weight-bold">*/}
          {/*        My Activities*/}
          {/*      </div>*/}
          {/*      <div className="text-muted">*/}
          {/*        Logs and notifications*/}
          {/*      </div>*/}
          {/*    </div>*/}
          {/*  </div>*/}
          {/*</a>*/}

          {/*<a className="navi-item px-8">*/}
          {/*  <div className="navi-link">*/}
          {/*    <div className="navi-icon mr-2">*/}
          {/*      <i className="flaticon2-hourglass text-primary"></i>*/}
          {/*    </div>*/}
          {/*    <div className="navi-text">*/}
          {/*      <div className="font-weight-bold">*/}
          {/*        My Tasks*/}
          {/*      </div>*/}
          {/*      <div className="text-muted">*/}
          {/*        latest tasks and projects*/}
          {/*      </div>*/}
          {/*    </div>*/}
          {/*  </div>*/}
          {/*</a>*/}
          <div className="navi-separator mt-3" />

          <div className="navi-footer  px-8 py-5">
            <Link
              to="/logout"
              className="btn btn-light-primary font-weight-bold"
              data-cy="button-logout"
            >
              <FormattedMessage id="PROFILE.SIGN.OUT" />
            </Link>
            {/*<a href="#" className="btn btn-clean font-weight-bold">*/}
            {/*  Upgrade Plan*/}
            {/*</a>*/}
          </div>
        </div>
      </Dropdown.Menu>
    </Dropdown>
  );
}
