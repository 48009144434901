import * as React from "react";
import { NumberInput } from "../../../../../../_utils/formUtils";
import { SUFFIX_PERCENTAGE } from "../../../../../../_utils/suffixUtils";
import { FormattedMessage } from "react-intl";
import { IProject } from "../../../../../../../data/schemas";
import {
  CurrencyMetricCard,
  MetricCard,
  MetricCardProps,
} from "../../../../../../_components/MetricCard";

export interface OrdersCardProps {
  canSeeHiddenLine: boolean;
  actualBudget: number;
  project: Partial<IProject>;
}

export const OrdersCard: React.FC<OrdersCardProps & MetricCardProps> = ({
  canSeeHiddenLine,
  project,
  actualBudget,
  ...props
}) => {
  const totalKey = canSeeHiddenLine ? "general" : "public";
  const totalOrders = project.subcontractorsFinanceFiles
    ? project.subcontractorsFinanceFiles.orders.total[totalKey]
    : 0;

  const totalOrdersPercentage =
    totalOrders && actualBudget ? (totalOrders / actualBudget) * 100 : 0;
  return (
    <CurrencyMetricCard
      dataCy="input-budget-orders-total"
      value={totalOrders}
      label="FINANCE.ORDERS"
      {...props}
    >
      <MetricCard.SubMetric
        value={
          <FormattedMessage
            id={"BUDGET.PERCENTAGE_OF_ACTUAL_BUDGET"}
            values={{
              amount: (
                <NumberInput
                  data-cy="input-budget-orders-total-percentage"
                  displayType="text"
                  value={totalOrdersPercentage}
                  decimalScale={2}
                  fixedDecimalScale={true}
                  suffix={SUFFIX_PERCENTAGE}
                  className="font-weight-bold text-right"
                />
              ),
            }}
          />
        }
      />
    </CurrencyMetricCard>
  );
};
