import * as React from "react";
import { ChangeEvent } from "react";
import { KycTranslation } from "../../KycTranslation";
import { useTranslation } from "../../../../hooks/useTranslation";
import { KycFormSectionSub } from "../layout/KycFormSectionSub";
import { Field } from "formik";
import { KycRadioGroup } from "../controls/kyc-radio-group/KycRadioGroup";
import { useKycFlow } from "../../../../hooks/useKycFlow";
import { KycInput } from "../controls/kyc-input/KycInput";
import {
  KYC_V2_OPTIONS_CLIENT_ACTIVITIES_RISK,
  KYC_V2_OPTIONS_CLIENT_RISK,
  KYC_V2_OPTIONS_CLIENT_RISK_ADDITIONAL,
  KYC_V2_OPTIONS_COUNTRIES,
} from "../../KycTranslationUtils";
import { KycRiskSelection } from "../KycRiskSelection";
import { KycRiskAssessmentLowerHigherRisk } from "../KycRiskAssessmentLowerHigherRisk";
import { KycCheckboxList } from "../controls/KycCheckboxList";
import { KycCenteredStepBodyLayout } from "./KycCenteredStepBodyLayout";
import { KycInfoBoxBig } from "../KycInfoBoxBig";
import { KycFormSectionTitle } from "../KycFormSectionTitle";
import { isRiskAssessmentDone } from "../../KycUtils";
import { TextEditor } from "../../../../_components/TextEditor/TextEditor";
import { useKyc } from "../../../../hooks/useKyc";
import { basicToolbar } from "../../../../_components/TextEditor/config";

export const KycStepRiskEvaluation = (): JSX.Element => {
  const { fm } = useTranslation();
  const { values, customHandleChange, saveFieldDelayed } = useKycFlow();
  const { currentKycFlow } = useKyc();

  return (
    <>
      <KycCenteredStepBodyLayout>
        {/* Client risk evaluation */}
        <KycFormSectionTitle
          title={KycTranslation.RISK_EVALUATION_CLIENT_RISK_IS_PEP_TITLE}
          infoLabel={KycTranslation.RISK_EVALUATION_CLIENT_RISK_IS_PEP_INFO}
        />

        <Field
          disabled={isRiskAssessmentDone(values)}
          component={KycRadioGroup}
          name="riskEvaluation.isPep"
          label={KycTranslation.RISK_EVALUATION_CLIENT_RISK_IS_PEP}
          onChange={(e: ChangeEvent<HTMLInputElement>) => customHandleChange(e, true, true)}
        />
        <Field
          disabled={isRiskAssessmentDone(values)}
          component={KycRadioGroup}
          name="riskEvaluation.negativeWebInfo"
          label={KycTranslation.RISK_EVALUATION_CLIENT_RISK_NEGATIVE_INFO_INTERNET}
          onChange={(e: ChangeEvent<HTMLInputElement>) => customHandleChange(e, true, true)}
          infoLabel={KycTranslation.RISK_EVALUATION_CLIENT_RISK_NEGATIVE_INFO_INTERNET_INFO}
        />
        <Field
          disabled={isRiskAssessmentDone(values)}
          component={KycRadioGroup}
          name="riskEvaluation.amlKycAdditionalTool.isUsed"
          label={KycTranslation.RISK_EVALUATION_CLIENT_RISK_KYC_DONE_BY_OTHER_TOOL}
          onChange={(e: ChangeEvent<HTMLInputElement>) => customHandleChange(e, true, true)}
        />
        {values?.riskEvaluation?.amlKycAdditionalTool?.isUsed === "true" && (
          <Field
            disabled={isRiskAssessmentDone(values)}
            name="riskEvaluation.amlKycAdditionalTool.nameOfTool"
            component={KycInput}
            onChange={(e: ChangeEvent<HTMLInputElement>) => customHandleChange(e)}
            placeholder=""
            label={KycTranslation.RISK_EVALUATION_CLIENT_RISK_KYC_DONE_BY_OTHER_TOOL_NAME_OF_TOOL}
          />
        )}

        {/* client risks of natural persons or all BOs*/}
        <h6>{fm(KycTranslation.RISK_EVALUATION_CLIENT_RISK_TITLE)}</h6>
        <KycFormSectionSub
          label={KycTranslation.RISK_EVALUATION_CLIENT_RISK_CLIENT_POTENTIAL_HIGHER_RISK_TITLE}
          indent={false}
        />
        <div className="row">
          <div className="col-md-12 col-lg-1" />
          <div className="col-md-12 col-lg-11">
            <Field
              disabled={isRiskAssessmentDone(values)}
              name="riskEvaluation.clientRisks"
              options={KYC_V2_OPTIONS_CLIENT_RISK}
              component={KycCheckboxList}
            />
          </div>
        </div>
        <KycFormSectionSub
          label={KycTranslation.RISK_EVALUATION_CLIENT_RISK_CLIENT_POTENTIAL_LOWER_RISK_TITLE}
          indent={false}
        />

        <div className="row">
          <div className="col-md-12 col-lg-1" />
          <div className="col-md-12 col-lg-11">
            <Field
              disabled={isRiskAssessmentDone(values)}
              name="riskEvaluation.clientRisksAdditional"
              options={KYC_V2_OPTIONS_CLIENT_RISK_ADDITIONAL}
              component={KycCheckboxList}
            />
          </div>
        </div>
        <Field
          disabled={isRiskAssessmentDone(values)}
          component={KycRiskSelection}
          name="riskEvaluation.clientRiskDetermination.riskLevel"
          label={KycTranslation.RISK_EVALUATION_RISK_LEVEL_CLIENT}
          options={KYC_V2_OPTIONS_COUNTRIES}
          onChange={(e: ChangeEvent<HTMLInputElement>) => customHandleChange(e, true, true)}
        />

        {/*Geographic risk evaluation*/}
        <h6>{fm(KycTranslation.RISK_EVALUATION_GEO_TITLE)}</h6>
        <KycInfoBoxBig
          label={KycTranslation.RISK_EVALUATION_GEO_COUNTRY_INFO_FATF}
          link={fm(KycTranslation.RISK_EVALUATION_GEO_COUNTRY_INFO_FATF_LINK)}
        />

        <Field
          disabled={isRiskAssessmentDone(values)}
          indent={false}
          component={KycRiskAssessmentLowerHigherRisk}
          name="riskEvaluation.geographicRiskDetermination.registeredOfficeClient"
          onChange={(e: ChangeEvent<HTMLInputElement>) => customHandleChange(e)}
          label={KycTranslation.RISK_EVALUATION_GEO_LOC_CLIENT_RO}
        />

        <Field
          disabled={isRiskAssessmentDone(values)}
          indent={false}
          component={KycRiskAssessmentLowerHigherRisk}
          name="riskEvaluation.geographicRiskDetermination.registeredOfficeBo"
          onChange={(e: ChangeEvent<HTMLInputElement>) => customHandleChange(e)}
          label={KycTranslation.RISK_EVALUATION_GEO_LOC_BO_RO}
        />

        <Field
          disabled={isRiskAssessmentDone(values)}
          indent={false}
          component={KycRiskAssessmentLowerHigherRisk}
          name="riskEvaluation.geographicRiskDetermination.placeOfBusinessClient"
          onChange={(e: ChangeEvent<HTMLInputElement>) => customHandleChange(e)}
          label={KycTranslation.RISK_EVALUATION_GEO_LOC_CLIENT_POB}
        />

        <Field
          disabled={isRiskAssessmentDone(values)}
          indent={false}
          component={KycRiskAssessmentLowerHigherRisk}
          name="riskEvaluation.geographicRiskDetermination.placeOfBusinessBo"
          onChange={(e: ChangeEvent<HTMLInputElement>) => customHandleChange(e)}
          label={KycTranslation.RISK_EVALUATION_GEO_LOC_BO_POB}
        />

        <Field
          disabled={isRiskAssessmentDone(values)}
          indent={true}
          component={KycRiskSelection}
          name="riskEvaluation.geographicRiskDetermination.riskLevel"
          label={KycTranslation.RISK_EVALUATION_RISK_LEVEL_GEO}
          options={KYC_V2_OPTIONS_COUNTRIES}
          translateLabels={true}
          translateRegion={true}
          onChange={(e: ChangeEvent<HTMLInputElement>) => customHandleChange(e, true, true)}
        />

        {/* Client's activity*/}
        <h6>{fm(KycTranslation.RISK_EVALUATION_CLIENT_ACTIVITY_TITLE)}</h6>

        <Field
          disabled={isRiskAssessmentDone(values)}
          name="riskEvaluation.clientRisksActivityDomain"
          component={KycInput}
          placeholder="Bank"
          label={KycTranslation.RISK_EVALUATION_CLIENT_ACTIVITY_FIELD_OF_ACTIVITY}
          onChange={(e: ChangeEvent<HTMLInputElement>) => customHandleChange(e)}
        />
        <KycFormSectionSub
          label={KycTranslation.RISK_EVALUATION_CLIENT_RISK_CLIENT_POTENTIAL_HIGHER_RISK_TITLE}
          indent={false}
        />
        <div className="row">
          <div className="col-md-12 col-lg-1" />
          <div className="col-md-12 col-lg-11">
            <Field
              disabled={isRiskAssessmentDone(values)}
              name="riskEvaluation.clientRisksActivity"
              options={KYC_V2_OPTIONS_CLIENT_ACTIVITIES_RISK}
              component={KycCheckboxList}
            />
          </div>
        </div>
        <Field
          disabled={isRiskAssessmentDone(values)}
          component={KycRiskSelection}
          name="riskEvaluation.clientActivityRiskDetermination.riskLevel"
          label={KycTranslation.RISK_EVALUATION_RISK_LEVEL_CLIENT_ACTIVITY}
          options={KYC_V2_OPTIONS_COUNTRIES}
          onChange={(e: ChangeEvent<HTMLInputElement>) => customHandleChange(e, true, true)}
        />

        {/*Overall risk evaluation*/}
        <h6>{fm(KycTranslation.RISK_EVALUATION_CLIENT_ACTIVITY_CLIENT_RISK)}</h6>
        <Field
          disabled={isRiskAssessmentDone(values)}
          component={KycRiskSelection}
          name="riskEvaluation.riskLevel"
          label={KycTranslation.RISK_EVALUATION_CLIENT_ACTIVITY_SEVERITY_ML_FR}
          options={KYC_V2_OPTIONS_COUNTRIES}
          onChange={(e: ChangeEvent<HTMLInputElement>) => customHandleChange(e, true, true)}
        />
        <KycFormSectionSub label={KycTranslation.RISK_EVALUATION_REMARKS} indent={false} />
        <div className="mx-10">
          <TextEditor
            disabled={isRiskAssessmentDone(values)}
            data={currentKycFlow?.riskEvaluation?.remarks || ""}
            name="riskEvaluation.remarks"
            saveMethod={(name: string, value: string) =>
              saveFieldDelayed({ kycId: currentKycFlow?.id, field: { name, value } })
            }
            toolbarOption={basicToolbar}
            data-cy="input-kyc-risk-evaluation-remarks"
          />
        </div>
      </KycCenteredStepBodyLayout>
    </>
  );
};
