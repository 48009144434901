export interface IHistoryParams {
  requestId: string;
}

export const ModalState = {
  NONE: "NONE",
  VALIDATE: "VALIDATE",
} as const;

export type TModalState = keyof typeof ModalState;

export const getModalStateValue = (state: TModalState) => ModalState[state] ?? ModalState.NONE;
