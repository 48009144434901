import React, { useRef, useState } from "react";
import { shallowEqual, useDispatch } from "react-redux";
import SVG from "react-inlinesvg";
import { cloneDeep } from "lodash-es";
import { Overlay, Popover } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { useAppSelector } from "../../redux/hooks";
import { canDeletePhoto, canUpdatePhoto } from "../_utils/authUtils";
import { toAbsoluteUrl } from "../../_metronic/_helpers";
import { PhotoShareDialog } from "./PhotoShareDialog";
import { IEntityPhoto } from "../../data/schemas";

const photoUrl = process.env.REACT_APP_PHOTOSURL;
type Props = {
  photo: IEntityPhoto;
  removePhoto: Function;
  parentType: string;
  actions: any;
  isLoading: boolean;
  setLocalPhotos?: Function;
};
export const Photo: React.FC<Props> = ({
  photo,
  removePhoto,
  parentType,
  actions,
  isLoading,
  setLocalPhotos,
}) => {
  const openPhoto = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    window.open(`${photoUrl}/${photo.photo}`, "_blank");
  };

  const [showRemove, setShowRemove] = useState(false);
  const [openSharePhotoDialog, setOpenSharePhotoDialog] = useState(false);
  const [loading, setLoading] = useState(false);
  const targetRemove = useRef<HTMLButtonElement>(null);

  const dispatch = useDispatch();

  const { session, groups } = useAppSelector(
    (state) => ({
      session: state.auth.session,
      groups: state.auth.groups,
    }),
    shallowEqual
  );

  return (
    <>
      {openSharePhotoDialog && (
        <PhotoShareDialog
          actions={actions}
          photo={photo}
          onHide={() => {
            setOpenSharePhotoDialog(false);
          }}
          show={openSharePhotoDialog}
          isLoading={isLoading}
        />
      )}
      <div className={"image-input image-input-outline m-4 mb-6"} onClick={openPhoto}>
        <div
          className="image-input-wrapper p-0 border-0"
          style={{ width: "150px", height: "150px", overflow: "hidden" }}
        >
          <div className="upload-file-preview h-100">
            <div className="d-flex justify-content-center align-items-center h-100">
              <img
                src={photo.localPhoto ?? `${photoUrl}/${photo.thumbnail || photo.photo}`}
                alt={parentType}
                className="h-100 w-100"
                style={{ objectFit: "cover" }}
              />
            </div>
            <>
              {photo.isPrivate && canUpdatePhoto(groups, session, parentType.toUpperCase()) && (
                <button
                  className="svg-icon svg-icon-xl mr-20 btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow"
                  data-action="change"
                  data-toggle="tooltip"
                  disabled={loading}
                  onClick={(e) => {
                    e.stopPropagation();
                    setOpenSharePhotoDialog(!openSharePhotoDialog);
                  }}
                >
                  <span className="svg-icon svg-icon-xl svg-icon-info">
                    <SVG src={toAbsoluteUrl("/media/svg/icons/Communication/Share.svg")} />
                  </span>
                </button>
              )}
              {canUpdatePhoto(groups, session, parentType.toUpperCase()) && (
                <button
                  className="svg-icon svg-icon-xl mr-10 btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow"
                  data-action="change"
                  data-toggle="tooltip"
                  disabled={loading}
                  onClick={(e) => {
                    e.stopPropagation();
                    const photoUpdate = cloneDeep(photo);
                    setLoading(true);
                    photoUpdate.isPrivate = !photoUpdate.isPrivate;
                    dispatch(actions.updatePhoto(photoUpdate)).then((res: IEntityPhoto) => {
                      if (setLocalPhotos) {
                        setLocalPhotos(res);
                      }
                      setLoading(false);
                    });
                  }}
                >
                  <span className="svg-icon svg-icon-xl svg-icon-primary">
                    {!photo.isPrivate ? (
                      <i className="ki ki-eye icon-md text-primary" />
                    ) : (
                      <i className="ki ki-hide icon-md text-primary" />
                    )}
                  </span>
                </button>
              )}

              {canDeletePhoto(groups, session, parentType.toUpperCase()) && (
                <>
                  <button
                    className="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow"
                    data-action="change"
                    data-toggle="tooltip"
                    disabled={loading}
                    onClick={(e) => {
                      e.stopPropagation();
                      setShowRemove(true);
                    }}
                    ref={targetRemove}
                  >
                    <i className="ki ki-bold-close icon-xs text-danger" />
                  </button>
                  <Overlay
                    show={showRemove}
                    target={targetRemove}
                    placement="top"
                    rootClose
                    onHide={(e) => {
                      e.stopPropagation();
                      setShowRemove(false);
                    }}
                  >
                    <Popover id="popover-contained">
                      <Popover.Title as="h3">
                        <FormattedMessage id={"PHOTO.DELETE"} />
                      </Popover.Title>
                      <Popover.Content>
                        <div className={"d-flex justify-content-end"}>
                          <button
                            type="button"
                            onClick={(e) => {
                              e.stopPropagation();
                            }}
                            className="btn btn-light btn-elevate"
                          >
                            <FormattedMessage id="COMMON.ACTION.CANCEL" />
                          </button>
                          <button
                            type="button"
                            onClick={(e) => {
                              setShowRemove(true);
                              e.stopPropagation();
                              removePhoto(e, photo?.id);
                              setShowRemove(false);
                            }}
                            className="btn btn-delete btn-danger btn-elevate ml-4"
                          >
                            <FormattedMessage id="COMMON.ACTION.DELETE" />
                          </button>
                        </div>
                      </Popover.Content>
                    </Popover>
                  </Overlay>
                </>
              )}
            </>
          </div>
        </div>
      </div>
    </>
  );
};
