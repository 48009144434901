import * as React from "react";
import { FormattedMessage } from "react-intl";

import TooltipHandler from "app/_components/TooltipHandler";

import { useProjectOwnerSwitchContext } from "app/modules/PropertiesManagement/pages/projects/ProjectDashboard/ProjectOwnerSwitch/ProjectOwnerSwitchContext";

import DisplayCountry from "./ProjectOwnerDropdownItem/DisplayCountry";
import DisplayPercentageValue from "./ProjectOwnerDropdownItem/DisplayPercentageValue";

export interface ProjectOwnerDisplaySelectedTooltipProps {
  children: JSX.Element;
}

export const ProjectOwnerDisplaySelectedTooltip: React.FunctionComponent<ProjectOwnerDisplaySelectedTooltipProps> =
  ({ children }) => {
    const { assigneeDefaultVat, assigneeReducedVat, assigneeCountryCode } =
      useProjectOwnerSwitchContext();

    return (
      <TooltipHandler
        placement="bottom"
        id="project-owner-info"
        message={
          <div className="d-flex flex-column">
            {assigneeDefaultVat ? (
              <>
                <div className="mb-2 d-flex align-items-center">
                  <span className="font-weight-bold">
                    <FormattedMessage id="COMPANY.DEFAULT_VAT" />
                  </span>
                  <DisplayPercentageValue className="ml-2" value={assigneeDefaultVat} />
                </div>
                {assigneeReducedVat && (
                  <div className="mb-2 d-flex align-items-center">
                    <span className="font-weight-bold">
                      <FormattedMessage id="COMPANY.REDUCED_VAT" />
                    </span>

                    <DisplayPercentageValue className="ml-2" value={assigneeReducedVat} />
                  </div>
                )}
              </>
            ) : (
              <div className="mb-2 d-flex align-items-center">
                <span className="font-weight-bold">
                  <FormattedMessage id="COMMON.VAT" />
                </span>

                <span className="ml-2">
                  <FormattedMessage id="COMMON.NOT_SPECIFIED" />
                </span>
              </div>
            )}

            {assigneeCountryCode && (
              <div className="d-flex align-items-center">
                <span className="font-weight-bold">
                  <FormattedMessage id="ADDRESS.COUNTRY" />
                </span>

                <DisplayCountry className="ml-2" countryCode={assigneeCountryCode} />
              </div>
            )}
          </div>
        }
      >
        {children}
      </TooltipHandler>
    );
  };

export default ProjectOwnerDisplaySelectedTooltip;
