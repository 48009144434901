import { shallowEqual, useDispatch, useSelector } from "react-redux";
import React, { useEffect } from "react";
import "./SnackBarNotificationsDialog.scss";
import { Toast } from "react-bootstrap";
import * as notificationsActions from "../_redux/snackBarNotificationsActions";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import { FormattedMessage } from "react-intl";
import cn from "clsx";

const notificationTypeIcon = {
  success: "/media/svg/icons/Navigation/Check.svg",
  danger: "/media/svg/icons/Code/Warning-2.svg",
  info: "/media/svg/icons/Code/Info-circle.svg",
};

export function SnackBarNotificationsDialog() {
  const { notifications } = useSelector(
    (state) => ({ notifications: state.snackBarNotifications?.list }),
    shallowEqual
  );

  const dispatch = useDispatch();
  useEffect(() => {
    if (notifications && notifications !== {}) {
      Object.keys(notifications)
        .filter(
          (notificationKey) =>
            !notifications[notificationKey].show && !notifications[notificationKey].deleted
        )
        .forEach((notificationKey) =>
          setTimeout(() => {
            dispatch(notificationsActions.displayNotification(notificationKey));
          }, 100)
        );

      Object.keys(notifications)
        .filter((notificationKey) => !!notifications[notificationKey].deleted)
        .forEach((notificationKey) =>
          setTimeout(() => {
            dispatch(notificationsActions.removeNotification(notificationKey));
          }, 800)
        );
    }
  }, [notifications]);

  const closeToast = (id) => {
    dispatch(notificationsActions.hideNotification(id));
  };

  return (
    <div id={"notifications"}>
      <>
        {notifications &&
          notifications !== {} &&
          Object.values(notifications).map((notification) => (
            <Toast
              key={notification.id}
              className={"mb-4"}
              show={!!notification.show}
              delay={notification.delay ?? 4000}
              autohide={notification.autoHide ?? true}
              onClose={() => closeToast(notification.id)}
            >
              <Toast.Header
                className="px-4 py-2 border-bottom-0"
                closeButton={notification.removable ?? true}
              >
                <span className={cn("svg-icon svg-icon-xl mr-2", `svg-icon-${notification.type}`)}>
                  <SVG src={toAbsoluteUrl(notificationTypeIcon[notification.type])} />
                </span>
                <div className={cn("font-weight-bold mr-auto", `text-${notification.type}`)}>
                  <FormattedMessage id={notification.title} />
                </div>
              </Toast.Header>
              {notification.body && <Toast.Body className="pl-14">{notification.body}</Toast.Body>}
            </Toast>
          ))}
      </>
    </div>
  );
}
