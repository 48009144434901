/*
  https://react-select.com/typescript
*/
import Select, { GroupBase } from "react-select";

import { CustomSelectBaseProps } from "./definitions";
import { useHtmlClassService } from "../../../../_metronic/layout";
import { useMemo } from "react";
import objectPath from "object-path";

export const CustomSelectBase = <
  Option,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>
>({
  disabled,
  clearable,
  selected,
  menuPosition = "fixed",
  captureMenuScroll = true,
  menuShouldBlockScroll = true,
  ...props
}: CustomSelectBaseProps<Option, IsMulti, Group>) => {
  const uiService = useHtmlClassService();
  const layoutProps = useMemo(() => {
    return {
      colorsThemeLightPrimary: objectPath.get(uiService.config, "js.colors.theme.light.primary"),
      colorsThemeBasePrimary: objectPath.get(uiService.config, "js.colors.theme.base.primary"),
    };
  }, [uiService]);

  return (
    <Select
      theme={(theme) => ({
        ...theme,
        colors: {
          ...theme.colors,
          primary25: layoutProps.colorsThemeLightPrimary,
          primary: layoutProps.colorsThemeBasePrimary,
        },
      })}
      {...{
        ...props,
        menuPosition,
        captureMenuScroll,
        menuShouldBlockScroll,
        value: selected,
        isDisabled: disabled,
        isClearable: clearable,
      }}
    />
  );
};

export default CustomSelectBase;
