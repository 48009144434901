import React from "react";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../../../_metronic/_helpers";
import { isPreviewPossible } from "../../../KycFileUtils";
import { KycBoTableMode, KycFlowStatus, KycLoading, KycStepEvent } from "../../../Kyc";
import { OverlayTrigger, Spinner, Tooltip } from "react-bootstrap";
import { KycTranslation } from "../../../KycTranslation";
import { getSignatoryIndex, isStatus } from "../../../KycUtils";
import { KycUploadInputSimple } from "../../controls/kyc-upload-simple/KycUploadInputSimple";
import { useKycFlow } from "../../../../../hooks/useKycFlow";
import { useFileViewer } from "../../../../../hooks/useFileViewer";
import { useKyc } from "../../../../../hooks/useKyc";
import { useKycEvent } from "../../../../../hooks/useKycEvent";
import { useLoading } from "../../../../../hooks/useLoading";
import { useTranslation } from "../../../../../hooks/useTranslation";
import { handleDownloadFile } from "../../../../../_utils/fileUtils";

export const KycBoDeclarationSingleDownloadColumnFormatter = ({
  selectedBoFile,
  signatory,
  mode,
}: any): JSX.Element => {
  const { values, saveField } = useKycFlow();
  const { setCurrentFiles, openFileViewer } = useFileViewer();
  const { currentKycFlow } = useKyc();
  const { isCurrentlyLoading } = useLoading();
  const { createKycEventMessage } = useKycEvent();
  const { fm } = useTranslation();

  const openFileInViewer = (e: any) => {
    e.preventDefault();
    setCurrentFiles([selectedBoFile]);
    openFileViewer();
  };

  const signatories = values?.signatories ?? [];
  const index = getSignatoryIndex(signatories, signatory.id);

  const isBoDeclarationUploadDisabled = () => {
    if (values) {
      return !isStatus(values?.status, KycFlowStatus.BO_DECLARATIONS_SIGNATURE_PENDING);
    }
    return true;
  };

  const handleDownloadFileBo = (e: any) => {
    e.preventDefault();
    //only mark if status of kyc is ok
    handleDownloadFile(e, selectedBoFile);
    if (
      signatory?.isOfflineSignatory &&
      values?.status === KycFlowStatus.BO_DECLARATIONS_SIGNATURE_PENDING
    ) {
      currentKycFlow &&
        createKycEventMessage(currentKycFlow?.id, KycStepEvent.ADMIN_DOWNLOAD_PDF_FOR_BO, {
          boName: signatory?.name,
          boEmail: signatory?.email,
        });
    }

    if (
      index > -1 &&
      currentKycFlow &&
      isStatus(currentKycFlow?.status, KycFlowStatus.BO_DECLARATIONS_SIGNATURE_PENDING)
    ) {
      saveField(e, {
        field: {
          name: `signatories[${index}].boDeclaration.downloadedAt`,
          value: new Date().toISOString(),
        },
      });
    } else {
      console.warn("no index for signatory");
    }
  };

  const isFileCategoryLoading = (categoryId: string, loadingType: KycLoading) => {
    return selectedBoFile?.categoryId === categoryId && isCurrentlyLoading(loadingType);
  };

  const isLoading = isFileCategoryLoading("boDeclaration", KycLoading.GENERATE_BO);

  return (
    <div className="d-flex justify-content-end align-items-right">
      <>
        {isLoading ? (
          <div className="pr-5">
            <Spinner animation="border" variant="primary" size="sm" />
          </div>
        ) : (
          <div className="d-flex align-items-center">
            <KycUploadInputSimple
              disabled={isBoDeclarationUploadDisabled()}
              signatory={signatory}
              show={
                signatory?.isOfflineSignatory &&
                signatory?.selectedFileDownloadedAt &&
                values?.status === KycFlowStatus.BO_DECLARATIONS_SIGNATURE_PENDING
              }
            />
            {signatory?.isOfflineSignatory &&
              !signatory?.selectedFileDownloadedAt &&
              currentKycFlow?.status === KycFlowStatus.BO_DECLARATIONS_SIGNATURE_PENDING && (
                <OverlayTrigger
                  placement="top"
                  overlay={
                    <Tooltip id={"tooltip-"} className="kyc-tooltip">
                      {fm(KycTranslation.BO_DECLARATION_DOWNLOAD_REQUIRED_INFO)}
                    </Tooltip>
                  }
                >
                  <div className="ml-2">
                    {mode === KycBoTableMode.COMPLIANCE && (
                      <span className="svg-icon svg-icon-2x svg-icon-warning mr-2">
                        <SVG src={toAbsoluteUrl("/media/svg/icons/Code/Warning-2.svg")} />
                      </span>
                    )}
                  </div>
                </OverlayTrigger>
              )}
            {/*SINGLE FILE DOWNLOAD BUTTON */}
            <button
              className="btn btn-icon btn-light btn-hover-primary btn-sm ml-1"
              style={{
                cursor: !selectedBoFile?.id ? "default" : "pointer",
              }}
              disabled={!selectedBoFile?.id}
              onClick={handleDownloadFileBo}
            >
              <span className="svg-icon svg-icon-sm svg-icon-primary">
                <SVG src={toAbsoluteUrl("/media/svg/icons/Files/Download.svg")} />
              </span>
            </button>

            {/*VIEW FILE BUTTON */}

            <button
              className="btn btn-icon btn-light btn-hover-secondary btn-sm mx-1 my-auto"
              style={{
                cursor: !isPreviewPossible(selectedBoFile) ? "default" : "pointer",
              }}
              type="button"
              onClick={openFileInViewer}
              disabled={
                !isPreviewPossible(selectedBoFile) ||
                isCurrentlyLoading(KycLoading.SAVE_FILE, selectedBoFile?.id)
              }
            >
              <span className="svg-icon svg-icon-lg svg-icon-info">
                <SVG src={toAbsoluteUrl("/media/svg/icons/General/Visible.svg")} />
              </span>
            </button>
          </div>
        )}
      </>
    </div>
  );
};
