import { createSlice } from "@reduxjs/toolkit";
import { ITask } from "../../../../data/schemas";
import { Counter } from "../../../../data/schemas/Counter";

interface DashboardState {
  countersLoading: boolean;
  tasksLoading: boolean;
  counters: {
    leadCounters?: Counter[];
    clientCounters?: Counter[];
    propertyCounters?: Counter[];
    projectCounters?: Counter[];
  };
  tasks: ITask[];
}

const initialSettingsState: DashboardState = {
  countersLoading: false,
  tasksLoading: false,
  counters: {},
  tasks: [],
};

export const callTypes = {
  counters: "counters",
  tasks: "tasks",
};

export const dashboardSlice = (name: string) =>
  createSlice({
    name: name,
    initialState: initialSettingsState,
    reducers: {
      catchError: (state, action) => {
        if (action.payload.callType === callTypes.counters) {
          state.countersLoading = false;
        } else {
          state.tasksLoading = false;
        }
      },
      startCall: (state, action) => {
        if (action.payload.callType === callTypes.counters) {
          state.countersLoading = true;
        } else {
          state.tasksLoading = true;
        }
      },
      countersFetched: (state, action) => {
        state.countersLoading = false;
        state.counters = action.payload.counters;
      },
      tasksFetched: (state, action) => {
        state.tasksLoading = false;
        state.tasks = action.payload.tasks;
      },
      taskCreated: (state, action) => {
        state.tasks.push(action.payload.task);
      },
      taskUpdated: (state, action) => {
        const task = action.payload.task;
        const taskId = task.id;
        state.tasks = state.tasks.map((entity) => {
          if (entity.id === taskId) {
            return task;
          }
          return entity;
        });
      },
      taskDeleted: (state, action) => {
        const taskId = action.payload.taskId;
        state.tasks = state.tasks.filter((el) => el.id !== taskId);
      },
    },
  });
