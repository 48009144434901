import * as React from "react";
import { useFormikContext } from "formik";
import { SUFFIX_PERCENTAGE } from "../../../../../../../_utils/suffixUtils";
import { useIntl } from "react-intl";
import { cloneDeep, sum } from "lodash-es";
import { ILeadFileInvoiceForm } from "./LeadFileInvoiceForm";
import { accurateFloatOperation, formatCurrency } from "../../../../../../../_utils/mathUtils";
import {
  PriceDetails,
  PriceDetailsLine,
  PriceDetailsLineCurrency,
} from "../../../../../../../_components/PriceDetails/PriceDetails";
import {
  isLeadFinancialDocumentPriceLine,
  LeadFinancialDocumentLine,
} from "../../../../../../../../data/schemas";
import { flattenInvoiceLines } from "./LeadInvoiceUtils";

interface IVatTotals {
  [key: string]: number;
}

interface IInvoicePriceDetails {
  basePriceIndex?: any;
  priceIndex?: any;
  subtotal: number;
  vatTotals?: IVatTotals;
  totalIndex: number;
  priceIndexChangePercentage: number;
  total: number;
  totalVat: number;
}

interface InvoicePriceDetailsProps {
  handleMixVatLine: (content: LeadFinancialDocumentLine[]) => LeadFinancialDocumentLine[];
}

export const InvoicePriceDetails: React.FunctionComponent<InvoicePriceDetailsProps> = ({
  handleMixVatLine,
}) => {
  const { values } = useFormikContext<ILeadFileInvoiceForm>();
  const intl = useIntl();

  const [details, setDetails] = React.useState<IInvoicePriceDetails>({
    basePriceIndex: undefined,
    priceIndex: undefined,
    subtotal: 0,
    vatTotals: {},
    totalIndex: 0,
    priceIndexChangePercentage: 0,
    total: 0,
    totalVat: 0,
  });

  React.useEffect(() => {
    calcContent();
  }, [values]);

  const calcContent = (): void => {
    const invoiceDetailData = values?.content;

    if (!values || !invoiceDetailData) return;
    let subtotal = 0;
    let vatTotals: IVatTotals = {};

    const basePriceIndex = values?.basePriceIndex?.value;
    const priceIndex = values?.priceIndex?.value;

    let totalIndex = 0;
    let priceIndexChangePercentage = 0;
    if (basePriceIndex && priceIndex) {
      priceIndexChangePercentage = ((priceIndex - basePriceIndex) / basePriceIndex) * 100;
    }
    const flattenContent = flattenInvoiceLines(handleMixVatLine(cloneDeep(invoiceDetailData)));
    for (const invoiceLine of flattenContent) {
      if (isLeadFinancialDocumentPriceLine(invoiceLine)) {
        if (invoiceLine.amount) {
          subtotal += invoiceLine.amount;
          const invoiceLinePriceIndexAmount = invoiceLine.indexedAmount - invoiceLine.amount;
          totalIndex += invoiceLinePriceIndexAmount;

          const vat = invoiceLine.vat;
          const vatKey = accurateFloatOperation(vat * 100, 2);
          if (vat) {
            vatTotals[vatKey] =
              (vatTotals[vatKey] ?? 0) + invoiceLine.indexedAmount * invoiceLine.vat;
          }
        }
      }
    }

    let totalVat = sum(Object.values(vatTotals)) ?? 0;

    const total = subtotal + totalIndex + totalVat;

    setDetails({
      basePriceIndex,
      priceIndex,
      subtotal,
      vatTotals,
      totalIndex,
      priceIndexChangePercentage,
      totalVat,
      total,
    });
  };

  const getPriceIndexLabel = () => {
    let label = `${intl.formatMessage({
      id: "PRICE_INDEX.TITLE.APPLIED",
    })}: ${details?.priceIndex?.toFixed(2)}`;

    const sign = details.priceIndexChangePercentage > 0 ? "+" : "";
    const changePercentage = ` | ${sign}${
      intl.formatNumber(details?.priceIndexChangePercentage, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }) + SUFFIX_PERCENTAGE
    }`;
    label += changePercentage;

    return label;
  };

  return (
    <PriceDetails>
      <PriceDetailsLineCurrency
        label={intl.formatMessage({ id: "COMMON.SUBTOTAL" })}
        value={details?.subtotal}
      />
      {details.priceIndex && (
        <PriceDetailsLine
          label={getPriceIndexLabel()}
          value={formatCurrency(details?.totalIndex, 2, intl)}
          valueColor={
            details?.totalIndex < 0 ? "text-danger" : details.totalIndex > 0 ? "text-success" : ""
          }
        />
      )}
      {details.vatTotals &&
        Object.entries(details.vatTotals).map(([vat, value]) => (
          <PriceDetailsLineCurrency
            label={`${intl.formatMessage({ id: "COMMON.VAT" })} ${vat}%`}
            value={value}
            key={vat}
          />
        ))}
      <PriceDetailsLineCurrency
        label={intl.formatMessage({ id: "COMMON.TOTAL" })}
        style={{ fontWeight: "bold", fontSize: "1.2em" }}
        value={details?.total}
      />
    </PriceDetails>
  );
};
