import { useEffect, useState } from "react";

import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";

import { CardPDFSignedViewer } from "app/_components/CardPDFSignedViewer";

import { downloadFileBase64 } from "app/modules/UsersManagement/_redux/usersCrud";

import { getSignature, requestSignatureOtp, postSignature } from "data/api/signatures";

import { ValidateIdentityModal } from "./ValidateIdentityModal";

export function PdfViewerPageSignature({ fileUrl, signature, setSignature, setFileBase64 }) {
  const [modalState, setModalState] = useState("");
  const [signatureInfo, setSignatureInfo] = useState(signature);

  //--------------------------------------------------------------------------//

  const loadDocInfo = () => {
    if (!signatureInfo?.id || signatureInfo?.signedDate) return;

    getSignature(signatureInfo.id).then((response) => {
      setSignatureInfo({ ...signature, ...response });

      downloadFileBase64(response?.fileUrl).then((res) => setFileBase64(res));
    });
  };

  const requestOtpCode = () => requestSignatureOtp(signatureInfo.id);

  const requestDocSign = (code) =>
    postSignature(signatureInfo.id, code).then((response) => {
      downloadFileBase64(response.fileUrlSigned).then((res) => setFileBase64(res));
      setSignature(response);
    });

  const triggerModalHide = (reloadDocInfo = false) => {
    setModalState("");

    if (reloadDocInfo) {
      loadDocInfo();
    }
  };

  useEffect(() => {
    loadDocInfo();
  }, [signature]);

  //--------------------------------------------------------------------------//

  return (
    <>
      {signature.id && (
        <ValidateIdentityModal
          show={modalState === "VALIDATE"}
          onHide={triggerModalHide}
          triggerModalHide={triggerModalHide}
          requestCode={requestOtpCode}
          requestDocSign={requestDocSign}
        />
      )}

      <CardPDFSignedViewer fileUrl={fileUrl} signatureInfo={signatureInfo} />
    </>
  );
}
