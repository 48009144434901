import { FormattedMessage } from "react-intl";

type ActionNewButtonProps = {
  dataCy?: string;
  className?: string;
  onClick: (() => void) | undefined;
  messageId: string;
  disabled?: boolean;
};

const ActionNewButton = ({
  dataCy,
  className,
  onClick,
  messageId,
  disabled = false,
}: ActionNewButtonProps) => {
  const buttonClass = `btn btn-primary ${className}`;

  return (
    <button
      type="button"
      data-cy={dataCy}
      className={buttonClass}
      onClick={onClick}
      disabled={disabled}
    >
      <i className="flaticon2-plus icon-nm" />
      <FormattedMessage id={messageId} />
    </button>
  );
};

export default ActionNewButton;
