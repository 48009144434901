import React from "react";
import { formatDisplayNameColumn } from "../../_utils/userUtils";
import { ColumnFormatter } from "react-bootstrap-table-next";

interface RowProps {
  firstName?: string;
  lastName?: string;
  company?: { name?: string };
}

export const FullNameFormatter: ColumnFormatter<RowProps> = (cell, row) => (
  <span>{formatDisplayNameColumn(row)}</span>
);
