import * as React from "react";
import { FormattedMessage } from "react-intl";
import { NumberFormatProps } from "react-number-format";

export interface MetricCardSubValueProps extends Omit<NumberFormatProps, "value"> {
  label?: string;
  labelValues?: Record<string, React.ReactNode>;
  value: React.ReactNode;
}

export const MetricCardSubMetric: React.FC<MetricCardSubValueProps> = ({
  label,
  labelValues,
  ...props
}) => {
  return (
    <div className="row font-size-xs text-inverse-light">
      {!!label && (
        <div className={"col"}>
          <FormattedMessage id={label} values={labelValues} />
        </div>
      )}
      <div className={"col-auto"}>{props.value}</div>
    </div>
  );
};
