/* eslint-disable no-restricted-imports */
import React, { useEffect } from "react";
import { Modal } from "react-bootstrap";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as actions from "../../../../../UsersManagement/_redux/usersActions";
import { ModalProgressBar } from "../../../../../../../_metronic/_partials/controls";
import { FormattedMessage } from "react-intl";

export function RemoveClientDialog({ id, leadId, show, onHide }) {
  const dispatch = useDispatch();
  const { isLoading } = useSelector(
    (state) => ({ isLoading: state.leadClients.actionsLoading }),
    shallowEqual
  );

  useEffect(() => {
    if (!id || !leadId) {
      onHide();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, leadId]);

  useEffect(() => {}, [isLoading]);

  const deleteClient = () => {
    dispatch(actions.removeClientFromLead({ id, leadId })).then(() => {
      onHide();
    });
  };

  return (
    <Modal show={show} onHide={onHide} centered size={"lg"} backdrop="static">
      {isLoading && <ModalProgressBar variant="query" />}
      <Modal.Header closeButton>
        <Modal.Title id="example-modal-sizes-title-lg">
          <FormattedMessage id="CLIENT.LEAD.REMOVE" />
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {!isLoading && (
          <span>
            {" "}
            <FormattedMessage id="COMMON.ACTION.DELETE.ARE_YOU_SURE.CLIENT" />
          </span>
        )}
        {isLoading && (
          <span>
            <FormattedMessage id="COMMON.ACTION.REMOVING" />
          </span>
        )}
      </Modal.Body>
      <Modal.Footer>
        <div>
          <button type="button" onClick={onHide} className="btn btn-light btn-elevate">
            <FormattedMessage id="COMMON.ACTION.CANCEL" />
          </button>
          <> </>
          <button
            type="button"
            onClick={deleteClient}
            className="btn btn-delete btn-danger btn-elevate"
          >
            <FormattedMessage id="COMMON.ACTION.REMOVE" />
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
}
