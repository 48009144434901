import * as React from "react";
import { ReactNode } from "react";
import { useTranslation } from "../../../../hooks/useTranslation";
import cn from "clsx";

type KycDetailsSectionProps = {
  title?: string;
  children?: ReactNode;
  controls?: ReactNode;
  status?: { label: string; color: string };
  statusDataCy?: string;
};

export const KycComplianceDetailsSection = ({
  title,
  children,
  controls,
  status,
  statusDataCy,
}: KycDetailsSectionProps): JSX.Element => {
  const { fm } = useTranslation();

  return (
    <>
      <div
        className="d-flex justify-content-md-between align-items-center"
        style={{ minHeight: title ? 45 : 0 }}
      >
        <div className="d-flex align-items-center ">
          <h4 className="my-auto">{title && fm(title)}</h4>
          {status?.label && (
            <span
              data-cy={statusDataCy}
              className={cn("label label-lg label-inline ml-2 my-auto", status?.color)}
            >
              {status?.label}
            </span>
          )}
        </div>
        {controls}
      </div>
      {children}
    </>
  );
};
