import React, { FC, useEffect, useState } from "react";
import { shallowEqual, useDispatch } from "react-redux";
import { useDropzone } from "react-dropzone";
import { FormattedMessage } from "react-intl";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import SVG from "react-inlinesvg";
import { useAppSelector } from "../../redux/hooks";
import { canUploadPhoto } from "../_utils/authUtils";
import { toAbsoluteUrl } from "../../_metronic/_helpers";
import { Photo } from "./Photo";
import { cloneDeep } from "lodash-es";
import { ListManager } from "react-beautiful-dnd-grid";
import { IEntityPhoto } from "../../data/schemas";

type Props = {
  item: {
    id: string;
    photos: IEntityPhoto[];
    photosOrder: string[];
  };
  actions: any;
  parentType: string;
  displayLabel: boolean;
  isLoading: boolean;
};
export const Photos: FC<Props> = ({
  item,
  actions,
  parentType,
  displayLabel = false,
  isLoading,
}) => {
  const sortPhotos = (photosList: IEntityPhoto[], photosOrder: string[]) => {
    const newPhotosList = cloneDeep(photosList);
    const localphotosOrder = cloneDeep(photosOrder);
    newPhotosList.sort(
      (a, b) => localphotosOrder?.indexOf(a.id ?? "") - localphotosOrder?.indexOf(b.id ?? "")
    );
    return newPhotosList;
  };

  const dispatch = useDispatch();
  const { getRootProps, getInputProps } = useDropzone();
  const [loading, setLoading] = useState(false);
  const [localPhotos, setLocalPhotos] = useState<IEntityPhoto[]>([]);

  const { session, groups } = useAppSelector(
    (state) => ({
      session: state.auth.session,
      groups: state.auth.groups,
    }),
    shallowEqual
  );

  useEffect(() => {
    if (item?.id) {
      dispatch(actions.fetchPhotos(item?.id)).then((res: IEntityPhoto[]) => {
        setLocalPhotos(sortPhotos(res, item?.photosOrder));
      });
    }
  }, [item?.id]);

  const upload = (e: any) => {
    const files: File[] | null = e.dataTransfer?.files || e.target?.files || null;
    if (files) {
      for (const file of files) {
        const { name } = file;
        const [, , , extension] = /([^.]+)(\.(\w+))?$/.exec(name) ?? [];
        setLoading(true);
        dispatch(
          actions.createPhoto({
            parentId: item?.id,
            typePhoto: extension,
            file,
          })
        ).then((res: IEntityPhoto) => {
          localPhotos?.push(res);
          setLoading(false);
        });
      }
    }
  };

  const removePhoto = (e: any, photoId: string) => {
    e.stopPropagation();
    dispatch(actions.deletePhoto({ parentId: item?.id, photoId })).then(() => {
      setLocalPhotos(localPhotos?.filter((photo) => photo && photo.id !== photoId));
    });
  };

  const onDragEnd = async (sourceIndex: number, destinationIndex: number) => {
    if (destinationIndex !== sourceIndex) {
      const newOrderPhotos = cloneDeep(item?.photosOrder);
      const [idPhoto] = newOrderPhotos?.splice(sourceIndex, 1);
      newOrderPhotos?.splice(destinationIndex, 0, idPhoto);
      setLocalPhotos(sortPhotos(localPhotos, newOrderPhotos));
      await dispatch(actions.photosOrderUpdate(item?.id, newOrderPhotos));
    }
  };

  return (
    <>
      {localPhotos &&
        (localPhotos?.length > 0 || canUploadPhoto(groups, session, parentType.toUpperCase())) &&
        displayLabel && (
          <label>
            <FormattedMessage id={`COMMON.${parentType?.toUpperCase()}.PHOTOS`} />
          </label>
        )}
      <div className="d-flex flex-wrap">
        {localPhotos && (
          <ListManager
            items={localPhotos}
            direction="horizontal"
            maxItems={1}
            onDragEnd={onDragEnd}
            render={(photo) => {
              const res = item?.photos?.find((ph) => photo && photo.id === ph.id);
              return res ? (
                <Photo
                  photo={res}
                  setLocalPhotos={(photo: IEntityPhoto) => {
                    setLocalPhotos(
                      localPhotos?.map((p) => {
                        return p?.id === photo?.id ? photo : p;
                      })
                    );
                  }}
                  removePhoto={removePhoto}
                  parentType={parentType}
                  actions={actions}
                  isLoading={isLoading}
                />
              ) : (
                <></>
              );
            }}
          />
        )}
        {localPhotos &&
          localPhotos?.length < 20 &&
          canUploadPhoto(groups, session, parentType.toUpperCase()) && (
            <div className={"image-input image-input-outline m-4 mb-6"}>
              <div
                {...getRootProps({ onDrop: (e) => upload(e) })}
                className="image-input-wrapper"
                style={{ width: "150px", height: "150px", overflow: "hidden" }}
              >
                <div className="upload-file-container" style={{ cursor: "pointer" }}>
                  <input
                    name="file"
                    {...getInputProps({ onChange: (e) => upload(e) })}
                    accept=".png, .jpg, .jpeg"
                    disabled={loading}
                  />
                  <div
                    className="d-flex justify-content-center align-items-center flex-column"
                    style={{ opacity: "0.4" }}
                  >
                    {!loading ? (
                      <>
                        <span className="svg-icon svg-icon-6x svg-icon-secondary">
                          <SVG src={toAbsoluteUrl("/media/svg/icons/Devices/Camera.svg")} />
                        </span>
                        <div className="text-center">
                          <FormattedMessage id="PHOTO.ACTION.CHOOSE_OR_DRAG" />
                        </div>
                      </>
                    ) : (
                      <svg className="splash-spinner ml-0" viewBox="0 0 50 50">
                        <circle
                          className="path"
                          cx="25"
                          cy="25"
                          r="20"
                          fill="none"
                          strokeWidth="5"
                        />
                      </svg>
                    )}
                  </div>
                </div>
                <OverlayTrigger
                  placement="top"
                  overlay={
                    <Tooltip id={"change-photos"}>
                      <FormattedMessage id="PHOTO.ACTION.UPLOAD" />
                    </Tooltip>
                  }
                >
                  <span
                    className="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow"
                    data-action="change"
                    data-toggle="tooltip"
                  >
                    <i className="fa fa-upload icon-sm text-muted" />
                  </span>
                </OverlayTrigger>
              </div>
            </div>
          )}
      </div>
    </>
  );
};
