/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import { useLocation } from "react-router";
import { NavLink } from "react-router-dom";
import { checkIsActive } from "../../../../_helpers";
import {FormattedMessage} from "react-intl";

export function HeaderMenuSubcontractor({ layoutProps }) {
  const location = useLocation();
  const getMenuItemActive = (url) => {
    return checkIsActive(location, url) ? "menu-item-active" : "";
  };

  return (
    <div
      id="kt_header_menu"
      className={`header-menu header-menu-left header-menu-mobile ${layoutProps.ktMenuClasses}`}
      {...layoutProps.headerMenuAttributes}
    >
      {/*begin::Header Nav*/}
      <ul className={`menu-nav ${layoutProps.ulClasses}`}>
        <li className={`menu-item menu-item-rel ${getMenuItemActive("/dashboard")}`}>
          <NavLink className="menu-link" to="/dashboard" data-cy="link-dashboard">
            <span className="menu-text"><FormattedMessage id="DASHBOARD.TITLE" /></span>
            {/*{layoutProps.rootArrowEnabled && <i className="menu-arrow" />}*/}
          </NavLink>
        </li>

        <li className={`menu-item ${getMenuItemActive("/projects")}`}>
          <NavLink className="menu-link" to="/projects" data-cy="link-projects">
            <span className="menu-text"><FormattedMessage id="PROJECT.TITLE" /></span>
            {/*{layoutProps.rootArrowEnabled && <i className="menu-arrow" />}*/}
          </NavLink>
        </li>

        <li className={`menu-item menu-item-rel ${getMenuItemActive("/tasks")}`}>
          <NavLink className="menu-link" to="/tasks" data-cy="link-tasks">
            <span className="menu-text"><FormattedMessage id="TASK.TITLE" /></span>
          </NavLink>
        </li>
      </ul>
      {/*end::Header Nav*/}
    </div>
  );
}
