import { useIntl } from "react-intl";
import * as React from "react";
import { useEffect } from "react";
import BootstrapTable, { ColumnDescription, ExpandRowProps } from "react-bootstrap-table-next";
import {
  NoRecordsFoundMessage,
  PleaseWaitMessage,
  sortCaret,
} from "../../../../../../_metronic/_helpers";
import { Button } from "react-bootstrap";
import { getEntries } from "../../../../../_components/AuditTrail/utilsAuditTrail";
import "../../../../../_components/AuditTrail/AuditTrail.scss";
import cn from "clsx";
import { useAuditTrail } from "../../../../../hooks/useAuditTrail";
import { LOADING_FETCH_USER_EVENTS } from "../../../../../contexts/AuditTrailContext";
import { IDiffEntry } from "../../../../../_components/AuditTrail/models";
import { LoaderSpinner } from "../../../../../_components/LoaderSpinner";
import { IGNORE_FIELDS } from "../../../../../_components/AuditTrail/utilsAuditTrailTranslations";
import { isEmpty } from "lodash-es";
import { KycDateTimeFormatter } from "../column-formatters/KycDateTimeFormatter";
import { Pagination } from "_metronic/_partials/controls/pagination/Pagination";
import paginationFactory, { PaginationProvider } from "react-bootstrap-table2-paginator";
import { KycAuditTrailDetailColumnFormatter } from "../column-formatters/KycAuditTrailDetailColumnFormatter";
import { defaultBootstrapTableOptions, defaultPaginationOptions } from "../definitions";
import { useTranslation } from "../../../../../hooks/useTranslation";
import { KycTranslation } from "../../../KycTranslation";
import { useKyc } from "../../../../../hooks/useKyc";

interface AuditTrailViewerProps {
  showNavbar?: boolean;
  enableEventPrefixFormatting?: boolean;
  mode?: "default" | "kyc";
}

export const KycAuditTrailViewer = ({
  enableEventPrefixFormatting = true,
}: AuditTrailViewerProps): JSX.Element => {
  const intl = useIntl();
  const { events, loadings, setEventsManually } = useAuditTrail();
  const { fm } = useTranslation();
  const { currentKycFlow } = useKyc();

  useEffect(() => {
    setEventsManually(currentKycFlow?.events?.items ?? []);
  }, [currentKycFlow]);

  const columns: ColumnDescription[] = [
    {
      dataField: "createdAt",
      text: fm("COMMON.UPDATED.AT"),
      style: { width: 150 },
      classes: "text-left",
      sort: true,
      formatter: KycDateTimeFormatter,
      formatExtraData: { intl, showTime: true },
      headerStyle: { width: "auto" },
    },
    {
      dataField: "id",
      classes: "text-left",
      text: "Action",
      sort: false,
      formatter: KycAuditTrailDetailColumnFormatter,
      formatExtraData: {
        intl: intl,
        enableEventPrefixFormatting: enableEventPrefixFormatting,
      },
    },
  ];

  const expandRow: ExpandRowProps<any, any> = {
    renderer: (row: any) => {
      return (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {getEntries(intl, row)}
        </div>
      );
    },
    showExpandColumn: true,
    expandByColumnOnly: true,
    expandColumnPosition: "right",
    onlyOneExpanding: true,
    expandHeaderColumnRenderer: () => <></>,
    expandColumnRenderer: ({ expanded, rowKey }) => {
      const event = events?.find((event) => event.id === rowKey);

      if (!event) return <></>;

      let showDetailsButton = false;

      let entryDetails: any = [];

      if (["MODIFY", "INSERT", "SIGNATURE_REQUEST_CREATED"].includes(event.eventName)) {
        event?.diff?.forEach((entry: IDiffEntry, index) => {
          const propertyKey = entry.path[entry.path.length - 1];
          if (propertyKey && !IGNORE_FIELDS.includes(propertyKey)) {
            entryDetails.push(propertyKey);
          }
        });
      }

      if (!isEmpty(entryDetails)) {
        showDetailsButton = true;
      }

      return (
        <>
          {showDetailsButton && (
            <div className="text-right">
              <Button variant="none" size="sm" className="p-0">
                {fm(expanded ? "COMMON.ACTION.HIDE_DETAILS" : "COMMON.ACTION.SHOW_DETAILS")}
                <i className={cn("fas icon-md", expanded ? "fa-angle-up" : "fa-angle-down")} />
              </Button>
            </div>
          )}
        </>
      );
    },
  };

  const getPreprocessedEvents = (events: any[]) => {
    if (isEmpty(events)) {
      return [];
    }

    const processedEvents = [];
    for (const ev of events) {
      if (
        ev?.eventName === "ADMIN_ACCEPT_DISCLAIMER" &&
        currentKycFlow?.form?.acceptTerms?.acceptedAt
      ) {
        // show exact time, when user ticked the checkbox to accept the terms
        // this is required because creation of the event is not exactly the same moment
        // the user ticked the box
        ev.createdAt = currentKycFlow?.form?.acceptTerms?.acceptedAt;
      }

      processedEvents.push(ev);
    }
    return processedEvents;
  };

  return (
    <>
      {loadings.has(LOADING_FETCH_USER_EVENTS) ? (
        <LoaderSpinner
          style={{ height: "20vh" }}
          className="justify-content-center align-items-center"
        />
      ) : (
        <div data-cy="table-kyc-events">
          <PaginationProvider
            pagination={paginationFactory({
              ...defaultPaginationOptions,
              totalSize: events?.length,
            })}
          >
            {({ paginationProps, paginationTableProps }) => {
              return (
                <Pagination paginationProps={paginationProps}>
                  <BootstrapTable
                    {...paginationTableProps}
                    {...defaultBootstrapTableOptions}
                    keyField="id"
                    data={getPreprocessedEvents(events)}
                    columns={columns}
                    expandRow={expandRow}
                    sort={{
                      dataField: "createdAt",
                      order: "desc",
                      sortCaret: sortCaret,
                    }}
                    noDataIndication={() => (
                      <NoRecordsFoundMessage
                        entities={events}
                        messageId={KycTranslation.NO_EVENTS_AVAILABLE}
                      />
                    )}
                  >
                    <PleaseWaitMessage entities={events} />
                  </BootstrapTable>
                </Pagination>
              );
            }}
          </PaginationProvider>
        </div>
      )}
    </>
  );
};
