import * as React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { NumberFormatValues, SourceInfo } from "react-number-format";
import { Field } from "formik";

import { Input } from "_metronic/_partials/controls";

import { NumberInput } from "app/_utils/formUtils";

import { FormSectionRow, FormSectionRowProps } from "../FormSectionRow";
import { FormSectionField } from "../FormSectionField";

//----------------------------------------------------------------------------//

const NUMBER_INPUT_CLASSNAME = "form-control text-right";

interface IInputField extends HTMLElement {
  name: string;
  value: any;
}

export interface NumberInputProps {
  label?: string;
  value?: number | string | null;
  onValueChange: (values: NumberFormatValues, sourceInfo: SourceInfo) => void;
}

export interface NumberInputSuffix {
  surface?: string;
  thousands?: string;
}

export interface FormSectionRegistryRowProps extends FormSectionRowProps {
  disabled?: boolean;

  /** landArchitectNumber field name */
  landArchitectNumber: string;

  /** landRegistryNumber field name */
  landRegistryNumber: string;

  /** landArchitectNumber and landRegistryNumber change handler */
  onFieldChange: <T extends IInputField>(e: React.ChangeEvent<T>) => void;

  surface: NumberInputProps;
  thousands: NumberInputProps;
  suffix?: NumberInputSuffix;
}

export const FormSectionRegistryRow: React.FunctionComponent<FormSectionRegistryRowProps> = ({
  disabled = false,

  surface,
  landArchitectNumber,
  onFieldChange,

  landRegistryNumber,
  thousands,

  suffix = {
    surface: undefined,
  },
}) => {
  const intl = useIntl();

  return (
    <FormSectionRow>
      <FormSectionField label={<FormattedMessage id={surface.label ?? "PRODUCT.INFO.SURFACE"} />}>
        <NumberInput
          {...surface}
          className={NUMBER_INPUT_CLASSNAME}
          suffix={suffix.surface}
          decimalScale={2}
          disabled={disabled}
        />
      </FormSectionField>

      <FormSectionField
        label={<FormattedMessage id={thousands.label ?? "PRODUCT.INFO.THOUSANDS"} />}
      >
        <NumberInput
          {...thousands}
          className={NUMBER_INPUT_CLASSNAME}
          suffix={suffix.thousands}
          disabled={disabled}
        />
      </FormSectionField>

      <FormSectionField>
        <Field
          name={landArchitectNumber}
          onChange={onFieldChange}
          label={intl.formatMessage({
            id: "PRODUCT.INFO.LAND_ARCHITECT_NUMBER",
          })}
          component={Input}
          disabled={disabled}
        />
      </FormSectionField>

      <FormSectionField>
        <Field
          name={landRegistryNumber}
          onChange={onFieldChange}
          label={intl.formatMessage({
            id: "PRODUCT.INFO.LAND_REGISTRY_NUMBER",
          })}
          component={Input}
          disabled={disabled}
        />
      </FormSectionField>
    </FormSectionRow>
  );
};

export default FormSectionRegistryRow;
