/*
  based on:
  https://github.com/cyntler/react-doc-viewer/blob/main/src/renderers/html/index.tsx#L6
*/
import { useEffect } from "react";

import type { DocRenderer } from "@cyntler/react-doc-viewer";

import { dataURLFileLoader } from "@cyntler/react-doc-viewer/dist/esm/utils/fileLoaders";

const SVG_MIME_TYPE = "image/svg+xml";

export const CustomSvgRenderer: DocRenderer = ({ mainState: { currentDocument } }) => {
  useEffect(() => {
    const b64String = currentDocument?.fileData as string;

    const bodyBase64 = b64String?.replace(`data:application/octet-stream;base64,`, "") || "";
    const body: string = window.atob(bodyBase64);

    let iframeCont = document.getElementById("svg-body") as HTMLIFrameElement | null;
    let iframe = iframeCont?.contentWindow && iframeCont.contentWindow;
    if (!iframe) return;

    const iframeDoc = iframe.document;
    iframeDoc.open();
    iframeDoc.write(`${body}`);
    iframeDoc.close();
  }, [currentDocument]);

  return (
    <div className="d-flex flex-column w-100 h-100" id="svg-renderer">
      <iframe
        title="svg-body-frame"
        id="svg-body"
        sandbox="allow-same-origin"
        className="w-100 h-100 border-0 p-1"
      />
    </div>
  );
};

CustomSvgRenderer.fileTypes = ["svg", SVG_MIME_TYPE];
CustomSvgRenderer.weight = 1;
CustomSvgRenderer.fileLoader = dataURLFileLoader;

export default CustomSvgRenderer;
