/* eslint-disable no-restricted-imports */
import React, { useEffect, useRef, useState } from "react";
import { Modal } from "react-bootstrap";
import { shallowEqual, useDispatch } from "react-redux";
import * as actions from "../../../_redux/projects/projectsActions";
import * as actionsUsers from "../../../../UsersManagement/_redux/usersActions";

import { formatDisplayNameIntl } from "../../../../../_utils/userUtils";
import { Menu, MenuItem, Typeahead } from "react-bootstrap-typeahead";
import { FormattedMessage, useIntl } from "react-intl";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../../../_metronic/_helpers";
import { UserDropdownItem } from "../../../../../_components/UserDropdownItem";
import { useAppSelector } from "../../../../../../redux/hooks";

export function ProjectShareDialog({ id, show, onHide, loadProject = false }) {
  const intl = useIntl();
  const [filteredUsers, setFilteredUsers] = useState([]);
  const dispatch = useDispatch();
  const { isLoadingProject, users, project, session, isLoadingUser } = useAppSelector(
    (state) => ({
      isLoadingProject: state.projects.actionsLoading || state.projects.listLoading,
      users: state.users.entities,
      project: state.projects.projectForEdit.saved,
      session: state.auth.session,
      isLoadingUser: state.users.actionsLoading || state.users.listLoading,
    }),
    shallowEqual
  );

  useEffect(() => {
    if (!id) {
      onHide();
    } else {
      if (loadProject) {
        dispatch(actions.fetchProject(id));
      }
      dispatch(actionsUsers.fetchUsersByUserType({ userTypes: ["USER", "SUBCONTRACTOR"] }));
    }
  }, [id]);

  useEffect(() => {
    if (!isLoadingProject && project) {
      setFilteredUsers(
        users.filter(
          (u) => session?.id !== u.id && !project.projectSharings?.find((ps) => ps.userId === u.id)
        )
      );
    }
  }, [isLoadingProject, users, setFilteredUsers, project, session]);

  const [newSharings, setNewSharings] = useState([]);
  const updateProjectSharings = (projectSharings) => {
    setNewSharings(projectSharings);
  };

  const removeUser = (userId) => {
    dispatch(actions.removeProjectSharing({ projectId: project.id, userId }));
  };

  const submitProjectSharings = () => {
    for (const user of newSharings) {
      dispatch(actions.createProjectSharing({ projectId: project.id, userId: user.id }));
    }
    setNewSharings([]);
  };

  const typeaheadRef = useRef(null);

  return (
    <Modal show={show} onHide={onHide} centered size={"lg"} backdrop="static" scrollable>
      <Modal.Header closeButton>
        <Modal.Title>
          {project?.name && (
            <FormattedMessage id={"PROJECT.SHARE.WITH"} values={{ name: project?.name }} />
          )}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="pb-6">
        <div className="d-flex">
          <Typeahead
            id="share-typeahead-input"
            ref={typeaheadRef}
            paginate={false}
            multiple
            positionFixed
            labelKey={"displayName"}
            filterBy={["displayName", "email"]}
            selected={newSharings}
            onChange={(selected) => {
              updateProjectSharings(selected);
            }}
            options={filteredUsers.map((u) => {
              const user = { ...u };
              user.displayName = formatDisplayNameIntl(intl, user, false);
              return user;
            })}
            placeholder={intl.formatMessage({ id: "COMMON.SELECT_USER" })}
            disabled={isLoadingProject || isLoadingUser}
            className={"flex-grow-1"}
            renderMenu={(users, menuProps) => (
              <Menu {...menuProps}>
                {users.map((user, index) => (
                  <MenuItem option={user} position={index} key={index}>
                    <UserDropdownItem user={user} searchText={typeaheadRef?.current?.state?.text} />
                  </MenuItem>
                ))}
              </Menu>
            )}
          />
          <button
            type="button"
            onClick={submitProjectSharings}
            disabled={isLoadingProject || isLoadingUser}
            className="btn btn-primary btn-elevate ml-4 px-6"
          >
            <FormattedMessage id="COMMON.ACTION.SHARE" />
          </button>
        </div>
        {project?.projectSharings?.length > 0 && users?.length > 0 && (
          <div className="pt-6">
            {project?.projectSharings.map((r, index) => {
              const user = users.find((u) => u.id === r.userId);
              if (!user) {
                return <React.Fragment key={index} />;
              }
              return (
                <div
                  key={index}
                  className="d-flex align-items-center justify-content-between"
                  style={{ height: "50px" }}
                >
                  <UserDropdownItem user={user} />
                  <div className="ml-3">
                    <button
                      type="button"
                      onClick={() => removeUser(user.id)}
                      className="btn btn-icon btn-light-danger btn-hover-danger btn-sm"
                    >
                      <span className="svg-icon svg-icon-md svg-icon-danger">
                        <SVG src={toAbsoluteUrl("/media/svg/icons/General/Trash.svg")}></SVG>
                      </span>
                    </button>
                  </div>
                </div>
              );
            })}
          </div>
        )}
      </Modal.Body>
      <Modal.Footer>
        <div>
          <button
            type="button"
            onClick={onHide}
            disabled={isLoadingProject || isLoadingUser}
            className={`btn btn-light btn-elevate ml-4 ${
              isLoadingProject && "spinner spinner-dark spinner-right"
            }`}
          >
            <FormattedMessage id="COMMON.ACTION.DONE" />
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
}
