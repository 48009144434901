import * as React from "react";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";

export enum IconType {
  COLUMNS = "columns",
  GANTT_CHART = "gantt-chart",
  AUDIT_TRAIL_VIEWED = "audit-trail-viewed",
  AUDIT_TRAIL_CREATED = "audit-trail-created",
  AUDIT_TRAIL_UPDATED = "audit-trail-updated",
  AUDIT_TRAIL_DELETED = "audit-trail-deleted",
  AUDIT_TRAIL_MODIFIED = "audit-trail-modified",
  AUDIT_TRAIL_OPEN_FILE_DOCUMENT = "audit-trail-open-file-document",
  AUDIT_TRAIL_FILE_UPLOADED = "audit-trail-file-uploaded",
  AUDIT_TRAIL_SIGNATURE = "audit-trail-signature",
}

export type TIconType = `${IconType}`;

type TStringOrNumber = string | number;

export interface IconProps {
  name: TIconType;
  className?: string;
  width?: TStringOrNumber;
  height?: TStringOrNumber;
}

const svgMap: {
  [key in TIconType]: (props: React.SVGProps<SVGSVGElement>) => JSX.Element;
} = {
  columns: ({ className, width, height }) => (
    <svg
      {...{ className, width, height }}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      fill="currentColor"
      stroke="currentColor"
    >
      <path d="M19.893 3.001H4c-1.103 0-2 .897-2 2v14c0 1.103.897 2 2 2h15.893c1.103 0 2-.897 2-2V5a2.003 2.003 0 0 0-2-1.999zM8 19.001H4V8h4v11.001zm6 0h-4V8h4v11.001zm2 0V8h3.893l.001 11.001H16z" />
    </svg>
  ),

  "gantt-chart": ({ className, width, height }) => (
    <svg
      {...{ className, width, height }}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 512 512"
      fill="currentColor"
      stroke="currentColor"
    >
      <path d="M352 300.8v38.5C352 345.6 358.4 352 364.8 352h102.5C473.6 352 480 345.6 480 339.3v-38.5C480 294.4 473.6 288 467.3 288h-102.5C358.4 288 352 294.4 352 300.8zM192 204.8v38.5C192 249.6 198.4 256 204.8 256h166.5C377.6 256 384 249.6 384 243.3v-38.5C384 198.4 377.6 192 371.3 192h-166.5C198.4 192 192 198.4 192 204.8zM480 416H64V63.1C64 46.33 49.67 32 32 32S0 46.33 0 63.1V448c0 17.6 14.4 32 32 32h448C497.7 480 512 465.7 512 448S497.7 416 480 416zM140.8 160h134.5C281.6 160 288 153.6 288 147.3v-38.5C288 102.4 281.6 96 275.3 96h-134.5C134.4 96 128 102.4 128 108.8v38.5C128 153.6 134.4 160 140.8 160z" />
    </svg>
  ),
  "audit-trail-created": ({
    className = "svg-icon svg-icon-primary svg-icon-2x",
    width,
    height,
  }) => (
    <span className={className}>
      <svg
        {...{ width, height }}
        xmlns="http://www.w3.org/2000/svg"
        width="24px"
        height="24px"
        viewBox="0 0 24 24"
        version="1.1"
      >
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <rect fill="#000000" x="4" y="11" width="16" height="2" rx="1" />
          <rect
            fill="#000000"
            opacity="0.3"
            transform="translate(12.000000, 12.000000) rotate(-270.000000) translate(-12.000000, -12.000000) "
            x="4"
            y="11"
            width="16"
            height="2"
            rx="1"
          />
        </g>
      </svg>
    </span>
  ),
  "audit-trail-viewed": ({
    className = "svg-icon svg-icon-primary svg-icon-2x",
    width,
    height,
  }) => (
    <span className={className}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24px"
        height="24px"
        viewBox="0 0 24 24"
        version="1.1"
      >
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <rect x="0" y="0" width="24" height="24" />
          <path
            d="M3,12 C3,12 5.45454545,6 12,6 C16.9090909,6 21,12 21,12 C21,12 16.9090909,18 12,18 C5.45454545,18 3,12 3,12 Z"
            fill="#000000"
            fillRule="nonzero"
            opacity="0.3"
          />
          <path
            d="M12,15 C10.3431458,15 9,13.6568542 9,12 C9,10.3431458 10.3431458,9 12,9 C13.6568542,9 15,10.3431458 15,12 C15,13.6568542 13.6568542,15 12,15 Z"
            fill="#000000"
            opacity="0.3"
          />
        </g>
      </svg>
    </span>
  ),
  "audit-trail-updated": ({
    className = "svg-icon svg-icon-primary svg-icon-2x",
    width,
    height,
  }) => (
    <span className={className}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24px"
        height="24px"
        viewBox="0 0 24 24"
        version="1.1"
      >
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <rect x="0" y="0" width="24" height="24" />
          <path
            d="M8,17.9148182 L8,5.96685884 C8,5.56391781 8.16211443,5.17792052 8.44982609,4.89581508 L10.965708,2.42895648 C11.5426798,1.86322723 12.4640974,1.85620921 13.0496196,2.41308426 L15.5337377,4.77566479 C15.8314604,5.0588212 16,5.45170806 16,5.86258077 L16,17.9148182 C16,18.7432453 15.3284271,19.4148182 14.5,19.4148182 L9.5,19.4148182 C8.67157288,19.4148182 8,18.7432453 8,17.9148182 Z"
            fill="#000000"
            fillRule="nonzero"
            transform="translate(12.000000, 10.707409) rotate(-135.000000) translate(-12.000000, -10.707409) "
          />
          <rect fill="#000000" opacity="0.3" x="5" y="20" width="15" height="2" rx="1" />
        </g>
      </svg>
    </span>
  ),

  "audit-trail-deleted": ({
    className = "svg-icon svg-icon-primary svg-icon-2x",
    width,
    height,
  }) => (
    <span className={className}>
      {" "}
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24px"
        height="24px"
        viewBox="0 0 24 24"
        version="1.1"
      >
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <rect x="0" y="0" width="24" height="24" />
          <path
            d="M6,8 L18,8 L17.106535,19.6150447 C17.04642,20.3965405 16.3947578,21 15.6109533,21 L8.38904671,21 C7.60524225,21 6.95358004,20.3965405 6.89346498,19.6150447 L6,8 Z M8,10 L8.45438229,14.0894406 L15.5517885,14.0339036 L16,10 L8,10 Z"
            fill="#000000"
            fillRule="nonzero"
          />
          <path
            d="M14,4.5 L14,3.5 C14,3.22385763 13.7761424,3 13.5,3 L10.5,3 C10.2238576,3 10,3.22385763 10,3.5 L10,4.5 L5.5,4.5 C5.22385763,4.5 5,4.72385763 5,5 L5,5.5 C5,5.77614237 5.22385763,6 5.5,6 L18.5,6 C18.7761424,6 19,5.77614237 19,5.5 L19,5 C19,4.72385763 18.7761424,4.5 18.5,4.5 L14,4.5 Z"
            fill="#000000"
            opacity="0.3"
          />
        </g>
      </svg>
    </span>
  ),
  "audit-trail-modified": ({
    className = "svg-icon svg-icon-primary svg-icon-2x",
    width,
    height,
  }) => (
    <span className={className}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24px"
        height="24px"
        viewBox="0 0 24 24"
        version="1.1"
      >
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <polygon points="0 0 24 0 24 24 0 24" />
          <rect
            fill="#000000"
            opacity="0.3"
            transform="translate(12.000000, 12.000000) rotate(-90.000000) translate(-12.000000, -12.000000) "
            x="11"
            y="5"
            width="2"
            height="14"
            rx="1"
          />
          <path
            d="M9.70710318,15.7071045 C9.31657888,16.0976288 8.68341391,16.0976288 8.29288961,15.7071045 C7.90236532,15.3165802 7.90236532,14.6834152 8.29288961,14.2928909 L14.2928896,8.29289093 C14.6714686,7.914312 15.281055,7.90106637 15.675721,8.26284357 L21.675721,13.7628436 C22.08284,14.136036 22.1103429,14.7686034 21.7371505,15.1757223 C21.3639581,15.5828413 20.7313908,15.6103443 20.3242718,15.2371519 L15.0300721,10.3841355 L9.70710318,15.7071045 Z"
            fill="#000000"
            fillRule="nonzero"
            transform="translate(14.999999, 11.999997) scale(1, -1) rotate(90.000000) translate(-14.999999, -11.999997) "
          />
        </g>
      </svg>
    </span>
  ),
  "audit-trail-open-file-document": ({
    className = "svg-icon svg-icon-primary svg-icon-2x",
    width,
    height,
  }) => (
    <span className={className}>
      <SVG src={toAbsoluteUrl("/media/svg/icons/Files/Selected-file.svg")} />
    </span>
  ),
  "audit-trail-file-uploaded": ({
    className = "svg-icon svg-icon-primary svg-icon-2x",
    width,
    height,
  }) => (
    <span className={className}>
      <SVG src={toAbsoluteUrl("/media/svg/icons/Files/Uploaded-file.svg")} />
    </span>
  ),
  "audit-trail-signature": ({
    className = "svg-icon svg-icon-primary svg-icon-2x",
    width,
    height,
  }) => (
    <span className={className}>
      <SVG src={toAbsoluteUrl("/media/svg/icons/Design/Pen-tool-vector.svg")} />
    </span>
  ),
};

export const Icon: React.FunctionComponent<IconProps> = ({ name, ...svgProps }) => {
  const IconFn = svgMap[name];

  if (!IconFn) return null;

  return IconFn(svgProps);
};

export default Icon;
