import { Field } from "formik";
import { KycInput } from "./controls/kyc-input/KycInput";
import * as React from "react";
import { ChangeEvent } from "react";
import { KycDropdownSelect } from "./controls/kyc-dropdown-select/KycDropdownSelect";
import { KYC_V2_OPTIONS_COUNTRIES } from "../KycTranslationUtils";
import { useKycFlow } from "../../../hooks/useKycFlow";

type KycAddressFormProps = {
  name: string;
  label?: string;
};

export const KycAddressForm = ({ name, label }: KycAddressFormProps): JSX.Element => {
  const { customHandleChange } = useKycFlow();

  const assembleFieldPath = (fieldName: string) => {
    return `${name}.${fieldName}`;
  };

  return (
    <>
      <Field
        component={KycInput}
        name={assembleFieldPath("addressLine1")}
        label="ADDRESS.LINE.ONE"
        onChange={(e: ChangeEvent<HTMLInputElement>) => customHandleChange(e)}
        placeholder="rue de l'église, 5"
      />
      <Field
        name={assembleFieldPath("addressLine2")}
        component={KycInput}
        label="ADDRESS.LINE.TWO_ALTERNATIVE"
        onChange={(e: ChangeEvent<HTMLInputElement>) => customHandleChange(e)}
        placeholder="Appartement 02"
      />
      <Field
        label="ADDRESS.POSTAL_CODE_BASIC"
        labelInfoLabel="ADDRESS.POSTAL_CODE_INFO"
        name={assembleFieldPath("postalCode")}
        component={KycInput}
        onChange={(e: ChangeEvent<HTMLInputElement>) => customHandleChange(e)}
        placeholder="L-3332"
      />
      <Field
        name={assembleFieldPath("city")}
        component={KycInput}
        label="ADDRESS.CITY"
        onChange={(e: ChangeEvent<HTMLInputElement>) => customHandleChange(e)}
        placeholder="Fennange"
      />
      <Field
        name={assembleFieldPath("stateOrRegion")}
        component={KycInput}
        label="ADDRESS.STATE_OR_REGION"
        onChange={(e: ChangeEvent<HTMLInputElement>) => customHandleChange(e)}
        placeholder="Luxembourg"
      />

      <Field
        name={assembleFieldPath("countryCode")}
        component={KycDropdownSelect}
        placeholder="Luxembourg"
        options={KYC_V2_OPTIONS_COUNTRIES}
        translateLabels={true}
        translateRegion={true}
        label="ADDRESS.COUNTRY"
      />
    </>
  );
};
