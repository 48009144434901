import type * as React from "react";
import type { SortOrder } from "react-bootstrap-table-next";

export interface IFilters {
  pageNumber: number;
  pageSize: number;
  filterSelected: Object[];
  freeText?: string;
}

export interface IFilterListItem {
  value: string;
  label: string;
}

export interface IPropCosUIContext {
  filters: IFilters;
  setFilters: React.Dispatch<React.SetStateAction<IFilters>>;
  filterList: IFilterListItem[];
}

export const initialFilters: IFilters = {
  filterSelected: [],
  pageNumber: 1,
  pageSize: 25,
};

export const sizePerPageList = [
  { text: "5", value: 5 },
  { text: "10", value: 10 },
  { text: "25", value: 25 },
  { text: "50", value: 50 },
];

export const filterList: IFilterListItem[] = [
  { value: "name", label: "COMMON.NAME" },
  { value: "updatedAt", label: "COMMON.UPDATED.AT" },
];

export const defaultSorted: [{ dataField: any; order: SortOrder }] = [
  { dataField: "name", order: "desc" },
];
