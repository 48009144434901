import React, { createContext, useContext, useState } from "react";

const formsUIContext = createContext();

export function useFormsUIContext() {
  return useContext(formsUIContext);
}

export function FormsUIProvider({ children, readOnly, pTitle, pBreadcrumbs, pFormType, pFormId }) {
  const [title, setTitle] = useState(pTitle);
  const [breadcrumbs, setBreadcrumbs] = useState(pBreadcrumbs);
  const [formType, setFormType] = useState(pFormType);
  const [formData, setFormData] = useState({});
  const [signature, setSignature] = useState({});
  const [formId, setFormId] = useState(pFormId);
  const [companyName, setCompanyName] = useState("");
  const [showModal, setShowModal] = useState(false);

  const value = {
    readOnly,
    title,
    setTitle,
    breadcrumbs,
    setBreadcrumbs,
    formType,
    setFormType,
    formData,
    setFormData,
    showModal,
    setShowModal,
    signature,
    setSignature,
    formId,
    setFormId,
    companyName,
    setCompanyName,
  };

  return <formsUIContext.Provider value={value}>{children}</formsUIContext.Provider>;
}
