import * as React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import SVG from "react-inlinesvg";
import cn from "clsx";
import { isUndefined, sumBy } from "lodash-es";
import { createColumnHelper } from "@tanstack/react-table";

import { toAbsoluteUrl } from "_metronic/_helpers";

import { useAppSelector } from "redux/hooks";

import { canEdit } from "app/_utils/authUtils";
import { CardTable } from "app/_components/CardTable/CardTable";
import { CardTableCollapseIndicator } from "app/_components/CardTable/components/buttons/CardTableCollapseIndicator";

import { getTotalInvoiceStatus } from "app/modules/Budget/utils";

import {
  currencyColumns,
  ILeadInstalment,
  ILeadInstalmentsColumns,
  ILeadSupplement,
  TCurrencyColumns,
  LEAD_CALCULATED_ID,
} from "../definitions";
import { useLeadContext } from "../LeadContext";

import { LeadInstalmentsHeader } from "./components/BudgetInstalmentsHeader/LeadInstalmentsHeader";
import { LeadInstalmentsCell } from "./components/LeadInstalmentsCell";

import { generateCSV } from "./generateCSV";

import { LeadInstalmentsTableRow } from "./definitions";

export const LeadInstalments: React.FC = () => {
  const intl = useIntl();
  const { leadInstalments, leadSupplements } = useLeadContext();
  const { leadForEdit, generalSettings, groups, session } = useAppSelector((state) => ({
    leadForEdit: state.leads.leadForEdit.current,
    generalSettings: state.settings.settingsForEdit.saved,
    groups: state.auth.groups,
    session: state.auth.session,
  }));

  const settings = leadForEdit?.project?.projectOwner ?? generalSettings;
  const VATColumnValue: Record<string, number> = {
    amount0VAT: 0,
    amountReducedVAT: (settings?.reducedVat ?? 0) * 100,
    amountDefaultVAT: (settings?.defaultVat ?? 0) * 100,
  };

  const headers = {
    label: "LEAD.INSTALMENTS.TABLE_COLUMN.DESCRIPTION",
    instalmentPercentage: "LEAD.INSTALMENTS.TABLE_COLUMN.INSTALMENT",
    amount: "LEAD.INSTALMENTS.TABLE_COLUMN.BASE_AMOUNT",
    amountInclPriceIndex: "LEAD.INSTALMENTS.TABLE_COLUMN.TOTAL_AMOUNT_INCL_PRICE_INDEX",
    priceIndex: "LEAD.INSTALMENTS.TABLE_COLUMN.APPLIED_INDEX",
    amount0VAT: "LEAD.INSTALMENTS.TABLE_COLUMN.AMOUNT_SUBJECT_TO_VAT",
    amountReducedVAT: "LEAD.INSTALMENTS.TABLE_COLUMN.AMOUNT_SUBJECT_TO_VAT",
    amountDefaultVAT: "LEAD.INSTALMENTS.TABLE_COLUMN.AMOUNT_SUBJECT_TO_VAT",
    taxes: "LEAD.INSTALMENTS.TABLE_COLUMN.TOTAL_TAXES",
    amountInclVAT: "LEAD.INSTALMENTS.TABLE_COLUMN.AMOUNT_INCL_VAT",
    credit: "LEAD.INSTALMENTS.TABLE_COLUMN.CREDIT",
    invoiced: "LEAD.INSTALMENTS.TABLE_COLUMN.INVOICED",
    invoicedInclVAT: "LEAD.INSTALMENTS.TABLE_COLUMN.INVOICED_INCL_VAT",
    ...(canEdit(groups, session, "LEAD")
      ? {
          supplementMargin: "LEAD.INSTALMENTS.TABLE_COLUMN.SUPPLEMENT_MARGIN",
          supplementMarginPercentage: "LEAD.INSTALMENTS.TABLE_COLUMN.SUPPLEMENT_MARGIN_PERCENTAGE",
        }
      : {}),
    paymentStatus: "LEAD.INSTALMENTS.TABLE_COLUMN.PAYMENT_STATUS",
  };

  const calcRevenueTotal = (instalments: ILeadInstalment[], supplements: ILeadSupplement[]) => {
    const totalLine: Partial<ILeadInstalmentsColumns> = {};
    const paymentStatusCount: Record<string, number> = {};
    let totalIndexedAmount = 0;
    for (const revenueLine of [...instalments, ...supplements]) {
      if (revenueLine.paymentStatus) {
        paymentStatusCount[revenueLine.paymentStatus] =
          (paymentStatusCount[revenueLine.paymentStatus] ?? 0) + 1;
      }
      for (const property of currencyColumns as TCurrencyColumns[]) {
        if (!isUndefined(revenueLine[property])) {
          totalLine[property] = (totalLine[property] ?? 0) + revenueLine[property]!;
        }
      }
      if (revenueLine.instalmentPercentage) {
        totalLine.instalmentPercentage =
          (totalLine.instalmentPercentage ?? 0) + revenueLine.instalmentPercentage;
      }
      totalIndexedAmount += revenueLine.indexedAmount ?? 0;
    }

    totalLine.paymentStatus = getTotalInvoiceStatus(paymentStatusCount);
    if (!isUndefined(totalLine.supplementMargin)) {
      const amountWithoutMargin =
        sumBy(supplements, "amountInclPriceIndex") - totalLine.supplementMargin;
      totalLine.supplementMarginPercentage =
        (totalLine.supplementMargin / amountWithoutMargin) * 100;
    }
    if (totalIndexedAmount) {
      totalLine.priceIndex = (totalIndexedAmount / totalLine.amount!) * 100;
    }
    return totalLine;
  };

  const extraSupplements = leadSupplements?.filter((supplement) => !supplement.budgetInstalmentId);

  const tableData: LeadInstalmentsTableRow[] | undefined = React.useMemo(
    () => [
      {
        id: "ID",
        label: intl.formatMessage({ id: "COMMON.TOTAL" }),
        defaultExpanded: true,
        ...calcRevenueTotal(leadInstalments, leadSupplements),
        children: [
          ...leadInstalments.map((instalment): LeadInstalmentsTableRow => {
            const supplements =
              leadSupplements?.filter((s) => s.budgetInstalmentId === instalment.id) ?? [];
            return {
              ...instalment,
              defaultExpanded: true,
              ...calcRevenueTotal([instalment], supplements),
              ...(![
                LEAD_CALCULATED_ID.SHARE_OF_LAND,
                LEAD_CALCULATED_ID.ARCHITECT_ENGINEERING_FEES,
              ].includes(instalment.id)
                ? {
                    children: [
                      {
                        ...instalment,
                        id: instalment.id + "instalment",
                        label: intl.formatMessage({ id: "BUDGET.INSTALMENT.SINGLE" }),
                      },
                      ...supplements,
                    ],
                  }
                : {}),
            };
          }),
          {
            id: LEAD_CALCULATED_ID.TOTAL_SUPPLEMENT,
            label: intl.formatMessage({ id: "BUDGET.REVENUE.TOTAL_SUPPLEMENTS" }),
            ...calcRevenueTotal([], extraSupplements),
            children: extraSupplements,
            rowTheme: "info",
          },
        ],
      },
    ],
    []
  );

  const columnHelper = createColumnHelper<LeadInstalmentsTableRow>();

  const columns = React.useMemo(
    () => [
      columnHelper.display({
        id: "COLLAPSE",
        cell: ({ row }) => (
          <>{row.depth > 0 && row.getCanExpand() && <CardTableCollapseIndicator row={row} />}</>
        ),
        meta: {
          minWidth: 0,
          fixed: true,
          customWidth: "w-40px",
        },
      }),
      ...Object.entries(headers).map(([key, translationKey]) => {
        const typedKey = key as keyof ILeadInstalmentsColumns;
        const column = columnHelper.accessor(typedKey, {
          header: () => (
            <span className={cn(typedKey !== "label" && "ml-auto text-right")}>
              <FormattedMessage id={translationKey} values={{ vat: VATColumnValue[typedKey] }} />
            </span>
          ),
          cell: (props) => <LeadInstalmentsCell {...props} typedKey={typedKey} />,
          meta: {
            minWidth: 110,
            label: intl.formatMessage({ id: translationKey }, { vat: VATColumnValue[typedKey] }),
          },
          enableHiding: true,
        });
        switch (typedKey) {
          case "label":
            column.meta!.customWidth = "flex-shrink-0 w-200px w-sm-250px w-md-300px w-lg-350px";
            column.meta!.fixed = true;
            column.enableHiding = false;
            break;
          case "paymentStatus":
            column.meta!.minWidth = 135;
        }
        return column;
      }),
    ],
    [intl.locale]
  );

  return (
    <>
      <LeadInstalmentsHeader />
      <div style={{ marginLeft: "-21px", marginRight: "-21px" }}>
        <CardTable
          data={tableData}
          columns={columns}
          multiCardLayout={false}
          id="LEAD_INSTALMENTS"
          customActions={
            <OverlayTrigger
              overlay={
                <Tooltip id="budgets-download-tooltip">
                  <FormattedMessage id="TOOLTIP.EXPORT_CSV" />
                </Tooltip>
              }
            >
              <button
                type="button"
                className="btn btn-icon btn-light-secondary"
                onClick={() => {
                  generateCSV({ VATColumnValue, headers, tableData, intl });
                }}
              >
                <span className="svg-icon svg-icon-md svg-icon-primary">
                  <SVG src={toAbsoluteUrl("/media/svg/icons/Files/Download.svg")} />
                </span>
              </button>
            </OverlayTrigger>
          }
        />
      </div>
    </>
  );
};
