import * as React from "react";
import { FormattedMessage } from "react-intl";

import { LoaderSpinner } from "app/_components/LoaderSpinner";

import { usePublicRequests } from "app/modules/Requests/hooks/usePublicRequests";
import { PublicAcceptGDPR } from "app/modules/Requests/components/PublicAcceptGDPR";

import { useRequestIdUrlParam } from "./useRequestIdUrlParam";
import { SignatureContentCard } from "./SignatureContentCard";

//----------------------------------------------------------------------------//

export const SignaturePage: React.FunctionComponent = () => {
  const requestId = useRequestIdUrlParam();

  const {
    isLoading,
    errorMessage,

    user,
    settings,

    getPublicRequestSignature,
  } = usePublicRequests();

  React.useEffect(() => {
    getPublicRequestSignature(requestId);
  }, [requestId]);

  if (isLoading && !user && !settings && !errorMessage) return <LoaderSpinner />;

  if (!isLoading && errorMessage) {
    return (
      <div>
        <FormattedMessage id={errorMessage} />
      </div>
    );
  }

  return (
    <>
      {user && !user.gdprApprovedAt && !errorMessage && <PublicAcceptGDPR />}

      <SignatureContentCard />
    </>
  );
};

export default SignaturePage;
