import { NumberInput } from "../../../../../_utils/formUtils";
import { Select } from "../../../../../../_metronic/_partials/controls";
import { TimeUnit, TimeUnitTypes } from "../../../../../_utils/timeUnitTypes";
import { EMAIL, SMS } from "../../../../../_utils/notificationTypes";
import React from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { FormattedMessage, useIntl } from "react-intl";
import { DateUtils } from "../../../../../_utils/DateUtils";

const handleChangeData = (value, index, reminders, targetField) => {
  const item = reminders[index];
  item[targetField] = value;
  return reminders;
};

const getTriggerAtTimestamp = (reminder, dueDate) => {
  const { amount, timeUnit } = reminder;
  if (amount && timeUnit && dueDate) {
    const secondsUntilTrigger = TimeUnitTypes[timeUnit].value * amount;
    return new Date(new Date(dueDate).getTime() - secondsUntilTrigger);
  }
};

export const Reminder = ({
  values,
  remove,
  handleChange,
  customHandleChange,
  reminder,
  index,
  dueDate,
}) => {
  const intl = useIntl();
  const placement = "left";
  return (
    reminder && (
      <OverlayTrigger
        key={placement}
        placement={placement}
        overlay={
          <Tooltip id={"task-reminder-tooltip"}>
            <FormattedMessage id="TASK.ACTION.REMINDER.NOTIFICATION_TIME" />
            <br />
            {DateUtils.format(getTriggerAtTimestamp(reminder, dueDate), intl)}
          </Tooltip>
        }
      >
        <div style={{ marginBottom: 8 }} className="form-group row" key={index}>
          <div className="col-lg-4">
            <Select
              name={`reminders.${index}.notifyMethodOptions`}
              className="form-control form-control-sm text-right"
              value={reminder.notifyMethodOptions}
              onBlur={(e) =>
                customHandleChange({
                  target: {
                    name: "reminders",
                    value: handleChangeData(
                      e.target.value,
                      index,
                      values?.reminders,
                      "notifyMethodOptions"
                    ),
                  },
                })
              }
            >
              <option value={SMS}>{intl.formatMessage({ id: "COMMON.SMS" })}</option>
              <option value={EMAIL}>{intl.formatMessage({ id: "COMMON.EMAIL" })}</option>
            </Select>
          </div>

          <div className="col-lg-2">
            <NumberInput
              style={{ marginTop: 3 }}
              name={`reminders.${index}.amount`}
              allowNegative={false}
              allowEmptyFormatting={false}
              allowLeadingZeros={false}
              defaultValue="1"
              className="form-control form-control-sm text-right"
              value={reminder?.amount}
              onBlur={(e) =>
                customHandleChange({
                  target: {
                    name: "reminders",
                    value: handleChangeData(
                      e.target.value > 0 ? e.target.value : 1,
                      index,
                      values?.reminders,
                      "amount"
                    ),
                  },
                })(handleChange)
              }
              onValueChange={(e) =>
                customHandleChange({
                  target: {
                    name: "reminders",
                    value: handleChangeData(e.floatValue, index, values?.reminders, "amount"),
                  },
                })(handleChange)
              }
            />
          </div>
          <div className="col-lg-5">
            <Select
              name={`reminders.${index}.timeUnit`}
              value={reminder?.timeUnit || undefined}
              onChange={(selected) =>
                customHandleChange({
                  target: {
                    name: "reminders",
                    value: handleChangeData(
                      selected.target.value,
                      index,
                      values?.reminders,
                      "timeUnit"
                    ),
                  },
                })(handleChange)
              }
            >
              <option value={TimeUnit.MINUTE}>
                {intl.formatMessage(
                  { id: "TIMEAGO.REMINDER.BEFORE.MINUTE" },
                  { time: reminder.amount }
                )}
              </option>
              <option value={TimeUnit.HOUR}>
                {intl.formatMessage(
                  { id: "TIMEAGO.REMINDER.BEFORE.HOUR" },
                  { time: reminder.amount }
                )}
              </option>
              <option value={TimeUnit.DAY}>
                {intl.formatMessage(
                  { id: "TIMEAGO.REMINDER.BEFORE.DAY" },
                  { time: reminder.amount }
                )}
              </option>
            </Select>
          </div>
          <div className="col-lg-1">
            <span style={{ paddingTop: 10 }} type="button" onClick={() => remove(index)}>
              <i className="fas fa-times" />
            </span>
          </div>
        </div>
      </OverlayTrigger>
    )
  );
};
