import { KycFlow, KycFlowType } from "../../../Kyc";
import { useTranslation } from "../../../../../hooks/useTranslation";
import * as React from "react";
import { KycTranslation } from "../../../KycTranslation";
import { useKyc } from "../../../../../hooks/useKyc";
import { KycInput } from "../../controls/kyc-input/KycInput";
import { Field } from "formik";
import { KycComplianceDetailsSection } from "../KycComplianceDetailsSection";
import { IUser } from "../../../../../../data/schemas";
import { getStatusProperties, KycStatusMode } from "../../../KycTranslationUtils";
import { KycPropDevCompanyChooser } from "../beneficial-owner/KycPropDevCompanyChooser";
import { useKycFlow } from "../../../../../hooks/useKycFlow";

interface KycCaseManagerSectionProps {
  kycFlow: KycFlow;
}

export const KycComplianceDetailsOverviewSection = ({
  kycFlow,
}: KycCaseManagerSectionProps): JSX.Element => {
  const { fm, fDisplayName, fDate } = useTranslation();
  const { currentKycFlow } = useKyc();
  const { values } = useKycFlow();
  return (
    <>
      <KycComplianceDetailsSection
        title={KycTranslation.OVERVIEW}
        status={getStatusProperties(currentKycFlow?.status, KycStatusMode.KYC_FLOW, fm)}
        statusDataCy="label-kyc-status-flow"
      />
      <div className="row">
        <div className="col-lg-5 col-mb-12">
          <KycComplianceDetailsSection>
            <Field
              name="form.client.displayName"
              component={KycInput}
              label={KycTranslation.CLIENT_NAME}
              renderAsLabel={true}
            />
            <Field
              name="form.client.lead.name"
              component={KycInput}
              label="LEAD.TITLE.NAME"
              renderAsLabel={true}
            />
            <Field
              inline={false}
              name="form.reference"
              component={KycInput}
              label={KycTranslation.REFERENCE_NAME}
              renderAsLabel={true}
            />
            <Field
              inline={false}
              name="form.client.lead.product.name"
              component={KycInput}
              label={KycTranslation.PROPERTY_NAME}
              renderAsLabel={true}
            />
          </KycComplianceDetailsSection>
        </div>
        <div className="col-lg-5 col-mb-12">
          <KycComplianceDetailsSection>
            <Field
              inline={false}
              name=""
              field={{
                name: "",
                value: fm(
                  kycFlow?.flowType === KycFlowType.SELLER
                    ? KycTranslation.FLOW_TYPE_SELLER
                    : KycTranslation.FLOW_TYPE_BUYER
                ),
              }}
              component={KycInput}
              label={KycTranslation.FLOW_TYPE}
              renderAsLabel={true}
            />
            <Field
              inline={true}
              field={{
                name: "",
                value: `${
                  (kycFlow?.fileOwner &&
                    fDisplayName(values?.fileOwner || (kycFlow?.fileOwner as IUser), false)) ||
                  ""
                }`,
              }}
              readOnly={true}
              component={KycInput}
              label={KycTranslation.FILE_OWNER}
              renderAsLabel={true}
            />
            <Field
              inline={true}
              field={{
                name: "",
                value: `${kycFlow?.createdAt && fDate(new Date(kycFlow?.createdAt), true)} by ${
                  fDisplayName(values?.createdByUser || kycFlow?.createdByUser, true) || ""
                }`,
              }}
              readOnly={true}
              component={KycInput}
              label={KycTranslation.AUDIT_STARTED_ON}
              renderAsLabel={true}
            />
            <KycPropDevCompanyChooser />
          </KycComplianceDetailsSection>
        </div>

        <div className="col-lg-2 col-mb-12">
          <KycComplianceDetailsSection />
        </div>
      </div>
    </>
  );
};
