import React from "react";
import { LeadsCard } from "./LeadsCard";
import { LeadsUIProvider } from "./LeadsUIContext";
import { shallowEqual, useSelector } from "react-redux";
import { LoadingDialog } from "../../../../../_metronic/_partials/controls";

export function LeadsPage({ history }) {
  const leadsUIEvents = {
    newLeadButtonClick: () => {
      history.push("/leads/new");
    },
    openLeadPage: (lead) => {
      history.push(`/leads/${lead.id}`);
    },
    openFetchLeadsDialog: () => {
      history.push(`/leads/fetch`);
    },
    openUpdateLeadsStatusDialog: () => {
      history.push("/leads/updateStatus");
    },
  };

  const { isLoading } = useSelector(
    (state) => ({
      isLoading: state.leads.listLoading,
    }),
    shallowEqual
  );

  return (
    <LeadsUIProvider leadsUIEvents={leadsUIEvents}>
      <LoadingDialog isLoading={isLoading} />
      <LeadsCard />
    </LeadsUIProvider>
  );
}
