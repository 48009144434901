import { usePublicRequests } from "../hooks/usePublicRequests";
import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useWizard, Wizard } from "react-use-wizard";
import { FormattedMessage, useIntl } from "react-intl";
import { useSubheader } from "../../../../_metronic/layout";
import { translateBreadcrumbs } from "../../../_utils/routeUtils";
import { KycAmlFilloutCardPublicWrapper } from "../components/kyc-aml/KycAmlFilloutCardPublicWrapper";
import { CardPDFSignedViewerPublicWrapper } from "../components/CardPDFSignedViewerPublicWrapper";
import { KycAmlFilloutAcceptTermsPublicWrapper } from "../components/kyc-aml/KycAmlFilloutAcceptTermsPublicWrapper";
import { PublicAcceptGDPR } from "../components/PublicAcceptGDPR";
import { LoaderSpinner } from "../../../_components/LoaderSpinner";

type HistoryParams = {
  requestId: string;
};

export const KycAmlPage = () => {
  const { requestId } = useParams<HistoryParams>();
  const {
    getPublicRequestsKycAml,
    user,
    action,
    getPublicRequestsRelatedFile,
    file,
    errorMessage,
    isLoading,
  } = usePublicRequests();

  useEffect(() => {
    getPublicRequestsKycAml(requestId).then();
  }, [requestId]);

  useEffect(() => {
    if (
      !file &&
      action &&
      (action.status === "DOCUMENT_GENERATED" || action.status === "DONE") &&
      user
    ) {
      getPublicRequestsRelatedFile(requestId, action?.userFileId).then();
    }
  }, [action]);

  const StepFilloutForm = () => {
    const { nextStep } = useWizard();
    return isLoading ? (
      <LoaderSpinner />
    ) : (
      <KycAmlFilloutCardPublicWrapper title={"KYC.TITLE"} submitForm={nextStep} />
    );
  };

  const StepAcceptTerms = ({ breadcrumbs }: { breadcrumbs: string[] }) => {
    const { previousStep, nextStep } = useWizard();
    return (
      <KycAmlFilloutAcceptTermsPublicWrapper
        previousStep={previousStep}
        nextStep={nextStep}
        breadcrumbs={breadcrumbs}
      />
    );
  };

  const StepSignDocument = ({ breadcrumbs }: { breadcrumbs: string[] }) => {
    const intl = useIntl();
    const subheader = useSubheader();
    useEffect(() => {
      subheader.setBreadcrumbs(translateBreadcrumbs(intl, breadcrumbs));
    }, [action]);

    const { firstName, lastName, email } = user;
    return file ? (
      <div className={"container h-100"}>
        <CardPDFSignedViewerPublicWrapper
          signature={{
            signedDate: file?.uploadedAt,
            signedBy: firstName || lastName ? `${firstName + " " + lastName}` : email,
          }}
        />
      </div>
    ) : (
      <LoaderSpinner />
    );
  };
  return (
    <>
      {!user && isLoading && !errorMessage && <LoaderSpinner />}
      {user && !user.gdprApprovedAt && !errorMessage && <PublicAcceptGDPR />}

      {user && action && !errorMessage && user.gdprApprovedAt && (
        <Wizard
          startIndex={action?.status === "DOCUMENT_GENERATED" || action?.status === "DONE" ? 2 : 0}
        >
          <StepFilloutForm />
          <StepAcceptTerms breadcrumbs={["KYC.TITLE", "KYC.TERMS.TITLE"]} />
          <StepSignDocument breadcrumbs={["KYC.TITLE", "KYC.TERMS.TITLE", "SIGN.TITLE"]} />
        </Wizard>
      )}
      {errorMessage && (
        <div>
          <FormattedMessage id={errorMessage} />
        </div>
      )}
    </>
  );
};
