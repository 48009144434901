import { API } from "@aws-amplify/api";
import { Chat, ChatUser } from "./models";

export async function fetchChats() {
  return await API.get("API", "/chats", {});
}

export async function fetchChatById(id: string) {
  return await API.get("API", `/chats/${id}`, {});
}

export async function updateChat(chat: Chat) {
  return await API.post("API", `/chats/${chat.id}`, {
    body: chat,
  });
}

export async function createChat(chat: Chat) {
  return await API.post("API", `/chats`, {
    body: chat,
  });
}

export async function sendMessage(message: { message: string; chatId: string }) {
  return await API.post("API", `/chats/${message.chatId}/messages`, {
    body: message,
  });
}

export async function saveDraftMessage(chatUser: ChatUser) {
  return await API.post("API", `/chats/${chatUser.chatId}/users/${chatUser.userId}`, {
    body: chatUser,
  });
}

export async function readMessages(chatId: string, messageIds: string[]) {
  return await API.post("API", `/chats/${chatId}/read`, {
    body: messageIds,
  });
}
