import React, { useEffect, useState } from "react";
import { CardLoaderSpinner } from "../CardLoaderSpinner";
import { Card, CardBody, CardFooter, CardHeader } from "../../../_metronic/_partials/controls";
import { FormattedMessage, useIntl } from "react-intl";
import { translateBreadcrumbs } from "../../_utils/routeUtils";
import { useSubheader } from "../../../_metronic/layout";

const companyLogoProps = {
  filename: "logo_kyc_aml.png",
  coordinates: {
    x: 255,
    y: 660,
    width: 280,
    height: 80,
  },
};

interface KycAmlFilloutAcceptTermsProps {
  submit: Function;
  formData: any;
  companyName: string;
  breadcrumbs: string[];
  action: any;
  previousStep: Function;
  actionsLoading: boolean;
}

export const KycAmlFilloutAcceptTerms: React.FC<KycAmlFilloutAcceptTermsProps> = ({
  submit,
  companyName,
  formData,
  breadcrumbs,
  action,
  previousStep,
  actionsLoading,
}) => {
  const intl = useIntl();
  const [location, setLocation] = useState<string>("");

  // const signaturePlaceholder = {
  //   x: 235,
  //   y: 380, //pixels starting from bottom to top
  //   width: 300,
  //   height: 130,
  //   //0-index-based page 2 -> page 0,1,2 ... the 3rd page of the pdf
  //   page: 2,
  //   color: "#F2ECE5",
  //   borderWidth: 1,
  //   borderColor: "#333333",
  //   acroformId: "Signature1",
  //   //optionally: adds layout with company logo to seal
  //   companySealImage: "logo_seal.png",
  // };

  const [agreeTermsChanges, setAgreeTermsChanges] = useState(false);
  const [agreeTermsForwardData, setAgreeTermsForwardData] = useState(false);

  const uploadEntityConsentOnly = () => {
    const formDataUploadBody = {
      flow: "FLOW_KYC_CONSENT_ONLY",
      fields: {
        ...formData.fields,
        companyName: companyName,
        signaturePlace: location,
        signatureAdditionalText: "KYC.FLOW.CONSENT.ADDITIONAL_TEXT",
        signatureContent: formData?.fields?.firstName + " " + formData?.fields?.lastName,
      },
      companyLogoProps,
    };
    submit(formDataUploadBody);
  };

  // const uploadEntitySignatureFlow = () => {
  //   const formDataUploadBody = {
  //     flow: "FLOW_KYC_SIGNATURE",
  //     fields: { ...formData.fields, signaturePlace: location, companyName: companyName },
  //     signaturePlaceholder,
  //     companyLogoProps,
  //   };
  //
  //   dispatch(actions.uploadForm(formDataUploadBody, action.id, action.userId));
  //   nextStep();
  // };
  const subheader = useSubheader();

  useEffect(() => {
    subheader.setBreadcrumbs(translateBreadcrumbs(intl, breadcrumbs));
  }, [action]);

  return (
    <>
      {!companyName ? (
        <CardLoaderSpinner />
      ) : (
        <Card>
          <CardHeader
            title={intl.formatMessage({
              id: "KYC.TERMS.TITLE",
            })}
          />
          <CardBody>
            <div className="mt-5">
              <span
                data-cy="modal-consent-boy"
                dangerouslySetInnerHTML={{
                  __html: intl.formatMessage({
                    id: "FORM.UPLOAD.SUBMITTING.KYC_AML.CONSENT.BODY",
                  }),
                }}
              />
              <hr />

              <div className="d-flex align-items-center flex-grow-1">
                <label
                  className="checkbox align-items-start"
                  data-cy="checkbox-kycaml-agree-terms-changes"
                >
                  <input
                    type="checkbox"
                    checked={agreeTermsChanges}
                    onChange={() => setAgreeTermsChanges(!agreeTermsChanges)}
                  />
                  <span style={{ marginLeft: 20 }} className="mr-4 flex-shrink-0" />
                  <div>
                    <FormattedMessage
                      id={"KYC.TERMS.CHANGES"}
                      values={{
                        b: (...chunks: any) => <b>{chunks}</b>,
                        companyName: companyName,
                      }}
                    />
                  </div>
                </label>
                <hr className="mt-8" />
              </div>
              <div className="d-flex align-items-center flex-grow-1 mt-2">
                <label
                  className="checkbox align-items-start"
                  data-cy="checkbox-kycaml-agree-terms-forward"
                >
                  <input
                    type="checkbox"
                    checked={agreeTermsForwardData}
                    onChange={() => setAgreeTermsForwardData(!agreeTermsForwardData)}
                  />

                  <span
                    style={{ marginLeft: 20 }}
                    className="mr-4 flex-shrink-0"
                    id="checkbox-phone-mobile"
                  />
                  <div>
                    <FormattedMessage
                      id={"KYC.TERMS.FORWARD_DATA"}
                      values={{
                        b: (...chunks: any) => <b>{chunks}</b>,
                        companyName: companyName,
                      }}
                    />
                  </div>
                </label>
              </div>

              <div className="mt-3 ml-17 d-flex justify-content-start align-items-center ">
                <label className="mr-4 mt-2">
                  <FormattedMessage id={"KYC.FLOW.CONSENT.CURRENT.LOCATION"} />
                </label>
                <input
                  className={"form-control w-50"}
                  data-cy="input-signature-place"
                  type="text"
                  inputMode="text"
                  onChange={(e) => setLocation(e.target.value)}
                />
              </div>
            </div>
          </CardBody>
          <CardFooter>
            <div className="d-flex justify-content-end">
              <button type="button" onClick={() => previousStep()} className="btn btn-light">
                <i className="fa fa-arrow-left" />
              </button>
              <button
                data-cy="button-form-flow-consent-only-submit"
                type="button"
                onClick={uploadEntityConsentOnly}
                className="btn btn-primary font-weight-bold ml-5"
                disabled={
                  actionsLoading ||
                  !(agreeTermsForwardData && agreeTermsChanges && location?.length > 2)
                }
              >
                <FormattedMessage id="KYC.FLOW.CHOICE.CONSENT.ACTION.LABEL" />
              </button>

              {/*TODO disable/enable until/when luxtrust is ready for prod (certificates and prod api available)*/}
              {/*<button*/}
              {/*  data-cy="button-form-flow-signature-submit"*/}
              {/*  type="button"*/}
              {/*  onClick={uploadEntitySignatureFlow}*/}
              {/*  className="btn btn-primary font-weight-bold ml-5"*/}
              {/*  disabled={*/}
              {/*    actionsLoading ||*/}
              {/*    !(agreeTermsForwardData && agreeTermsChanges && location?.length > 2)*/}
              {/*  }*/}
              {/*>*/}
              {/*  <FormattedMessage id="KYC.FLOW.CHOICE.SIGNATURE.ACTION.LABEL" />*/}
              {/*</button>*/}
            </div>
          </CardFooter>
        </Card>
      )}
    </>
  );
};
