import * as requestFromServer from "./budgetsCrud";
import { budgetsSlice, callTypes } from "./budgetsSlice";
import * as globalActions from "../../../../redux/global/globalActions";
import * as projectActions from "../../PropertiesManagement/_redux/projects/projectsActions";

import {
  processSnackbarNotification,
  SNACKBAR_MESSAGE,
} from "../../Common/SnackbarNotificationsHandler";

const contextualActions = {
  default: budgetsSlice("budgets").actions,
};

const actions = contextualActions.default;

export const fetchBudget = (id) => (dispatch) => {
  if (!id) {
    dispatch(actions.budgetFetched({ budgetForEdit: undefined }));
    return Promise.resolve(undefined);
  }

  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .getBudgetById(id)
    .then((response) => {
      const budgetForEdit = response;
      console.log("fetchBudget budgetForEdit: ", budgetForEdit);
      dispatch(actions.budgetFetched({ budgetForEdit }));
      return budgetForEdit;
    })
    .catch((error) => {
      // error.displayMessage = "Can't find budget";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
      processSnackbarNotification(SNACKBAR_MESSAGE.DANGER.FIND_BUDGET, dispatch);
    });
};

export const deleteBudget =
  ({ queryParams, id }) =>
  (dispatch) => {
    dispatch(actions.startCall({ callType: callTypes.action }));
    return requestFromServer
      .deleteBudget(id)
      .then((response) => {
        processSnackbarNotification(SNACKBAR_MESSAGE.SUCCESS.DELETE_BUDGET, dispatch);

        dispatch(actions.budgetDeleted({ id }));
        if (queryParams?.projectId) {
          dispatch(projectActions.deleteBudget(id));
        }
      })
      .catch((error) => {
        // error.displayMessage = "Can't delete budget";
        dispatch(actions.catchError({ error, callType: callTypes.action }));
        processSnackbarNotification(SNACKBAR_MESSAGE.DANGER.DELETE_BUDGET, dispatch);
      });
  };

export const createBudget = (budgetForCreation) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .createBudget(budgetForCreation)
    .then((response) => {
      console.log("createBudget response: ", response);
      const budget = { ...budgetForCreation, ...response };
      processSnackbarNotification(SNACKBAR_MESSAGE.SUCCESS.CREATE_BUDGET, dispatch);

      dispatch(actions.budgetCreated({ budget }));
      return budget;
    })
    .catch((error) => {
      // error.displayMessage = "Can't create budget";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
      processSnackbarNotification(SNACKBAR_MESSAGE.DANGER.CREATE_BUDGET, dispatch);
    });
};

export const updateBudgetLocally = (budgetForEdit) => (dispatch) => {
  dispatch(actions.budgetUpdatedLocally({ budgetForEdit }));
};

export const updateBudgetFieldLocally = (key, value) => (dispatch) => {
  dispatch(actions.budgetFieldUpdatedLocally({ key, value }));
};

export const updateBudget = (budgetForEdit) => (dispatch) => {
  dispatch(globalActions.save());
  return requestFromServer
    .updateBudget(budgetForEdit)
    .then((response) => {
      console.log("updateBudget response: ", response);
      const budget = { ...budgetForEdit, ...response };
      dispatch(actions.budgetUpdated({ budget }));
      dispatch(globalActions.saved());
    })
    .catch((error) => {
      // error.displayMessage = "Can't update budget";
      dispatch(globalActions.saved());
      dispatch(actions.catchError({ error, callType: callTypes.action }));
      processSnackbarNotification(SNACKBAR_MESSAGE.DANGER.UPDATE_BUDGET, dispatch);
    });
};
