import React, { ChangeEvent, useEffect } from "react";
import { Field } from "formik";
import { KycTranslation } from "../../KycTranslation";
import { get } from "lodash-es";
import { KycRadioGroup } from "../controls/kyc-radio-group/KycRadioGroup";
import { KycInput } from "../controls/kyc-input/KycInput";
import { KycUploadComponent } from "../controls/kyc-upload/KycUploadComponent";
import { KycAddressForm } from "../KycAddressForm";
import {
  getNationalityOptions,
  KYC_V2_OPTIONS_COUNTRIES,
  KYC_V2_OPTIONS_PERSON_TITLE,
} from "../../KycTranslationUtils";
import { useKycFlow } from "../../../../hooks/useKycFlow";
import { KycDatePicker } from "../controls/kyc-date-picker/KycDatePicker";
import { KycFormSection } from "../layout/KycFormSection";
import { useTranslation } from "../../../../hooks/useTranslation";
import { KycDropdownSelect } from "../controls/kyc-dropdown-select/KycDropdownSelect";
import { KycCheckbox } from "../controls/kyc-checkbox/KycCheckbox";
import { KycMultiEntity } from "../controls/kyc-multi-entity-form/KycMultiEntity";
import { KycItemType } from "../../Kyc";
import { isBigOwner, KYC_EXTERNAL_COMPANY, mapNameToKycEntityType } from "../../KycUtils";
import { DateUtils } from "../../../../_utils/DateUtils";
import { useIntl } from "react-intl";
import { Suffix } from "../../../../_utils/suffixUtils";
import { NumberFormatValues } from "react-number-format";
import { KycNumberInput } from "../controls/kyc-number-input/KycNumberInput";
import { KycPhone } from "../controls/kyc-phone/KycPhone";

type KycClientPage2LegalEntityPersonalInfoProps = {
  name: string;
  index: number;
};

export const KycClientPage2LegalEntityPersonalInfo = ({
  name,
  index,
}: KycClientPage2LegalEntityPersonalInfoProps): JSX.Element => {
  const { values, customHandleChange, setFlowValue } = useKycFlow();
  const { fm, fDisplayName } = useTranslation();
  const intl = useIntl();

  const assembleFieldPath = (fieldName: string) => {
    return `${name}[${index}].${fieldName}`;
  };

  useEffect(() => {
    if (
      fDisplayName(get(values, `${name}[${index}]`), true) !==
      get(values, assembleFieldPath("displayName"))
    ) {
      setFlowValue(assembleFieldPath("displayName"), fDisplayName(get(values, name), true));
    }
  }, [
    get(values, assembleFieldPath("firstName")),
    get(values, assembleFieldPath("lastName"), get(values, assembleFieldPath("title"))),
  ]);

  const isExistingUser = () => {
    return get(values, `${name}[${index}]`)?.isExistingUser;
  };

  const getKycRelatedEntity = () => {
    return {
      id: get(values, assembleFieldPath("id")),
      type: mapNameToKycEntityType(name),
    };
  };

  return (
    <div className="my-2">
      {/*<KycSelectBo name={`${name}[${index}]`} />*/}
      <Field
        component={KycDropdownSelect}
        name={assembleFieldPath("title")}
        placeholder={fm("TITLES.MR")}
        locked={isExistingUser()}
        options={KYC_V2_OPTIONS_PERSON_TITLE}
        translateLabels={true}
        label="USER.INFO.TITLE"
      />
      <Field
        name={assembleFieldPath("firstName")}
        locked={isExistingUser()}
        component={KycInput}
        placeholder="John"
        label={KycTranslation.FIRST_NAME}
        onChange={(e: ChangeEvent<HTMLInputElement>) => customHandleChange(e)}
      />
      <Field
        name={assembleFieldPath("lastName")}
        locked={isExistingUser()}
        component={KycInput}
        placeholder="Smith"
        label={KycTranslation.FAMILY_NAME}
        onChange={(e: ChangeEvent<HTMLInputElement>) => customHandleChange(e)}
      />
      {(name?.includes("beneficialOwners") || name?.includes("representatives")) && (
        <>
          <Field
            name={assembleFieldPath("email")}
            locked={isExistingUser()}
            component={KycInput}
            onChange={(e: ChangeEvent<HTMLInputElement>) => customHandleChange(e)}
            label="USER.INFO.EMAIL"
          />
          <Field
            name={assembleFieldPath("mobile")}
            locked={isExistingUser()}
            component={KycPhone}
            onChange={(e: ChangeEvent<HTMLInputElement>) => customHandleChange(e)}
            label="USER.INFO.MOBILE"
          />
        </>
      )}
      {name?.includes("agents") && (
        <Field
          name={assembleFieldPath("companyName")}
          component={KycInput}
          label={KycTranslation.COMPANY_NAME}
          onChange={(e: ChangeEvent<HTMLInputElement>) => customHandleChange(e)}
        />
      )}
      <Field
        component={KycDropdownSelect}
        name={assembleFieldPath("nationality")}
        options={getNationalityOptions(intl)}
        translateLabels={false}
        label={KycTranslation.NATIONALITY}
      />

      <Field
        className="form-control react-datepicker-ignore-onclickoutside no-validation"
        name={assembleFieldPath("dateOfBirth")}
        component={KycDatePicker}
        label={KycTranslation.DATE_OF_BIRTH}
        withFeedbackLabel={false}
        minDate={DateUtils.subtractYears(new Date(), 100)}
        maxDate={new Date()}
        showTimeSelect={false}
        dateFormat="P"
        popperPlacement="bottom-start"
        autoComplete="off"
      />
      <Field
        name={assembleFieldPath("placeOfBirth")}
        component={KycInput}
        placeholder="Dudelange"
        label={KycTranslation.PLACE_OF_BIRTH}
        onChange={(e: ChangeEvent<HTMLInputElement>) => customHandleChange(e)}
      />
      <Field
        name={assembleFieldPath("countryOfBirth")}
        component={KycDropdownSelect}
        placeholder="Luxembourg"
        options={KYC_V2_OPTIONS_COUNTRIES}
        translateLabels={true}
        translateRegion={true}
        label={KycTranslation.COUNTRY_OF_BIRTH}
      />

      <KycFormSection label={KycTranslation.PRIVATE_ADDRESS}>
        <KycAddressForm name={assembleFieldPath("privateAddress")} />
      </KycFormSection>

      <KycUploadComponent
        name={assembleFieldPath("evidencePrivateAddress")}
        infoLabel={KycTranslation.EVIDENCE_DOCUMENT_NAME_UTILITY_BILL}
        kycRelatedToEntity={getKycRelatedEntity()}
      />
      {(name?.includes("beneficialOwners") || name?.includes("representatives")) && (
        <>
          <Field
            name={assembleFieldPath("profession")}
            component={KycInput}
            placeholder="Software Engineer"
            label={KycTranslation.PROFESSION}
            onChange={(e: ChangeEvent<HTMLInputElement>) => customHandleChange(e)}
          />
          {((!isBigOwner(values) && name?.includes("representatives")) ||
            name?.includes("beneficialOwners")) && (
            <KycFormSection label={KycTranslation.PROFESSIONAL_ADDRESS}>
              <KycAddressForm name={assembleFieldPath("professionalAddress")} />
            </KycFormSection>
          )}
        </>
      )}
      <Field
        name={assembleFieldPath("passportOrIdNumber")}
        component={KycInput}
        label={KycTranslation.PASSPORT_ID_NUMBER}
        placeholder="VMQ17WQEY"
        onChange={(e: ChangeEvent<HTMLInputElement>) => customHandleChange(e)}
      />
      <KycUploadComponent
        name={assembleFieldPath("evidencePassportId")}
        kycRelatedToEntity={getKycRelatedEntity()}
        infoLabel={
          name?.includes("beneficialOwners")
            ? KycTranslation.EVIDENCE_DOCUMENT_NAME_PASSPORT_ID_COPY
            : KycTranslation.EVIDENCE_DOCUMENT_NAME_PASSPORT_ID_COPY_LEGAL
        }
      />
      <Field
        className="form-control react-datepicker-ignore-onclickoutside no-validation"
        name={assembleFieldPath("idValidityDate")}
        component={KycDatePicker}
        label={KycTranslation.ID_VALIDITY_DATE}
        withFeedbackLabel={false}
        showTimeSelect={false}
        dateFormat="P"
        popperPlacement="bottom-start"
        autoComplete="off"
      />
      {!name?.includes("agents") && (
        <Field
          name={assembleFieldPath("registrationNumber")}
          component={KycInput}
          label={KycTranslation.REGISTRATION_NUMBER}
          onChange={(e: ChangeEvent<HTMLInputElement>) => customHandleChange(e)}
        />
      )}

      <Field
        component={KycRadioGroup}
        name={assembleFieldPath("isPep")}
        label={KycTranslation.IS_PEP}
        onChange={(e: ChangeEvent<HTMLInputElement>) => customHandleChange(e, true, true)}
      />
      {get(values, assembleFieldPath("isPep")) === "true" && (
        <Field
          name={assembleFieldPath("publicMandate")}
          component={KycInput}
          placeholder=""
          label={KycTranslation.PUBLIC_MANDATE}
          onChange={(e: ChangeEvent<HTMLInputElement>) => customHandleChange(e)}
        />
      )}
      {name?.includes("agents") && (
        <>
          <KycUploadComponent
            name={assembleFieldPath("evidenceLetterOfEngagement")}
            kycRelatedToEntity={getKycRelatedEntity()}
            infoLabel={KycTranslation.EVIDENCE_DOCUMENT_NAME_POWER_OF_ATTORNEY}
          />

          <Field
            name={assembleFieldPath("letterOfEngagement")}
            component={KycInput}
            label={KycTranslation.MISSION_LETTER}
            onChange={(e: ChangeEvent<HTMLInputElement>) => customHandleChange(e)}
          />
        </>
      )}

      {get(values, "form.client.isBigOwner") === "true" && name?.includes("beneficialOwners") && (
        <>
          <Field
            name={assembleFieldPath("numberOfSharesInEntity")}
            component={KycNumberInput}
            decimalScale={0}
            label={KycTranslation.BENEFICIAL_OWNER_NUMBER_OF_SHARES}
            onChange={(e: any) => {
              customHandleChange({
                target: {
                  name: assembleFieldPath("numberOfSharesInEntity"),
                  value: e.floatValue,
                },
              });
            }}
          />
          <Field
            name={assembleFieldPath("nominalValue")}
            component={KycNumberInput}
            onChange={(e: any) => {
              customHandleChange({
                target: {
                  name: assembleFieldPath("nominalValue"),
                  value: e.floatValue,
                },
              });
            }}
            label={KycTranslation.BENEFICIAL_OWNER_NOMINAL_VALUE}
          />

          <Field
            name={assembleFieldPath("nominalValueCurrency")}
            component={KycInput}
            label={KycTranslation.BENEFICIAL_OWNER_NOMINAL_VALUE_CURRENCY}
            onChange={(e: ChangeEvent<HTMLInputElement>) => customHandleChange(e)}
          />

          <Field
            name={assembleFieldPath("percentageOfShareCapital")}
            component={KycNumberInput}
            suffix={Suffix.PERCENTAGE}
            onChange={(e: NumberFormatValues) => {
              customHandleChange({
                target: {
                  name: assembleFieldPath("percentageOfShareCapital"),
                  value: (e.floatValue ?? 0) / 100 || 0,
                },
              });
            }}
            label={KycTranslation.BENEFICIAL_OWNER_PERCENTAGE_SHARE_CAPITAL}
          />
          <Field
            component={KycCheckbox}
            translateText={false}
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              customHandleChange({
                target: {
                  name: e.target.name,
                  value: e.target.checked,
                },
              })
            }
            name={assembleFieldPath("isShareholderOfClientCompany")}
            isChecked={get(values, assembleFieldPath("isShareholderOfClientCompany"))}
            text={fm(
              get(values, assembleFieldPath("isShareholderOfClientCompany")) === true
                ? KycTranslation.CLIENT_BO_IS_DIRECT_SHAREHOLDER_OF_COMPANY
                : KycTranslation.CLIENT_BO_IS_INDIRECT_SHAREHOLDER_OF_COMPANY,
              {
                companyName: values?.form?.client?.company?.name,
              }
            )}
          />
          {!get(values, assembleFieldPath("isShareholderOfClientCompany")) && (
            <>
              <Field
                title={KycTranslation.SHAREHOLDER_COMPANIES}
                infoLabel={KycTranslation.CLIENT_BO_ADD_SHAREHOLDER_COMPANY}
                component={KycMultiEntity}
                itemType={KycItemType.SHAREHOLDER_COMPANY}
                name={assembleFieldPath("externalCompanies")}
                initObj={{ ...KYC_EXTERNAL_COMPANY }}
                defaultHeaderTitle={KycTranslation.SHAREHOLDER_COMPANY}
                labelButtonAdd="COMMON.ACTION.ADD"
                showDivider={false}
              />
            </>
          )}
        </>
      )}
    </div>
  );
};
