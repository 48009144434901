import * as React from "react";
import { useEffect, useState } from "react";
import { API } from "@aws-amplify/api";
import PropTypes from "prop-types";
import { KycFile, KycLoading } from "../modules/kyc-v2/Kyc";
import {
  findAndDeleteFile,
  findAndReplaceFile,
  isFileUploaded,
} from "../modules/kyc-v2/KycFileUtils";
import { useLoading } from "../hooks/useLoading";
import { useKyc } from "../hooks/useKyc";
import axios, { AxiosRequestConfig } from "axios";
import { useSession } from "../hooks/useSession";

type KycFileProps = {
  children: React.ReactNode;
};

export interface KycFileContextValue {
  currentFiles: any[];
  currentFilesIdToFileMap: Record<string, any>;
  setCurrentFiles: (file: any[]) => void;
  getFileByFieldPath: (fieldPath: string) => any;
  getFileById: (kycId: string, fileId: string) => Promise<void>;
  saveFile: (file: KycFile, setProgress?: any) => Promise<void>;
  replaceFile: (file: KycFile) => Promise<void>;
  updateFile: (file: KycFile) => Promise<void>;
  deleteFile: (file: any) => Promise<void>;
  listFilesByKycId: (kycId: string) => Promise<void>;
}

export const KycFileContext = React.createContext<KycFileContextValue>({
  currentFiles: [],
  currentFilesIdToFileMap: {},
  setCurrentFiles: (files) => undefined,
  getFileByFieldPath: (fieldPath) => undefined,
  getFileById: (kycId, fileId) => Promise.resolve(),
  saveFile: (file, setProgress) => Promise.resolve(),
  replaceFile: (file) => Promise.resolve(),
  updateFile: (file) => Promise.resolve(),
  deleteFile: (file) => Promise.resolve(),
  listFilesByKycId: (kycId) => Promise.resolve(),
});

export const KycFileProvider: React.FunctionComponent<KycFileProps> = (props) => {
  const { addLoading, removeLoading } = useLoading();
  const { currentKycFlow, updateKycFlow } = useKyc();

  const { children } = props;
  const [currentFiles, setCurrentFilesInternal] = useState<any[]>([]);
  const [currentFilesIdToFileMap, setCurrentFilesIdToFileMap] = useState<Record<string, any>>({});
  const { session } = useSession();

  useEffect(() => {
    return () => {
      setCurrentFilesInternal([]);
    };
  }, []);

  useEffect(() => {
    setCurrentFilesIdToFileMap(
      currentFiles.reduce((acc, obj) => {
        acc[obj.id] = obj;
        return acc;
      }, {})
    );
  }, [currentFiles]);

  const setCurrentFiles = (files: any[]) => {
    setCurrentFilesInternal(files);
  };

  const saveFile = async (file: KycFile, setProgress?: any) => {
    if (!currentKycFlow) {
      return;
    }

    if (!file?.name) {
      console.error("bad filename", file);
      return;
    }

    const body = { ...file };
    addLoading(KycLoading.SAVE_FILE, file.id);
    await API.post("API", `/kyc/${currentKycFlow.id}/files`, { body }).then((response) => {
      if (file.body) {
        try {
          const config: AxiosRequestConfig = {
            onUploadProgress: (progressEvent) => {
              if (setProgress) {
                setProgress(Math.round((progressEvent.loaded / progressEvent.total) * 100));
              }
              if (progressEvent.loaded === progressEvent.total) {
                removeLoading(KycLoading.SAVE_FILE, file.id);
              }
            },
          };

          axios.put(response.putUrl, file.body, config);
          setCurrentFilesInternal(
            findAndReplaceFile(currentFiles, { ...response, status: "UPLOADED_TO_S3" })
          );
        } catch (e) {
          console.error("axios error:", e);
        }
      }
    });
  };

  const listFilesByKycId = async (kycId: string) => {
    if (!kycId) {
      return;
    }
    addLoading(KycLoading.LIST_FILES, kycId);
    await API.get("API", `/kyc/${kycId}/files/`, {})
      .then((response) => {
        setCurrentFilesInternal(response);
      })
      .finally(() => {
        removeLoading(KycLoading.LIST_FILES, kycId);
      });
  };

  const getFileById = async (kycId: string, fileId: string) => {
    addLoading(KycLoading.GET_FILE, fileId);
    await API.get("API", `/kyc/${kycId}/files/${fileId}`, {})
      .then((response) => {
        // console.log("received file:", response);
        setCurrentFilesInternal((cFiles) => findAndReplaceFile(cFiles, response));
      })
      .finally(() => {
        removeLoading(KycLoading.GET_FILE, fileId);
      });
  };

  const updateFile = async (file: KycFile) => {
    if (!currentKycFlow) {
      return;
    }
    addLoading(KycLoading.UPDATE_FILE, file.id);
    setCurrentFilesInternal((cFiles) => findAndReplaceFile(cFiles, file));
    const body = { ...file };
    await API.post("API", `/kyc/${currentKycFlow.id}/files/${file?.id}`, { body })
      .then((response) => {
        setCurrentFilesInternal((cFiles) =>
          findAndReplaceFile(cFiles, { ...response, url: file?.url })
        );
      })
      .finally(() => {
        removeLoading(KycLoading.UPDATE_FILE, file.id);
      });
  };

  const replaceFile = async (file: KycFile) => {
    if (!currentKycFlow) {
      return;
    }
    addLoading(KycLoading.SAVE_FILE, file.id);

    if (!file?.name) {
      console.error("bad filename", file);
      return;
    }

    const body = { ...file };
    setCurrentFilesInternal((cFiles) => findAndReplaceFile(cFiles, { ...file, status: "LOCAL" }));

    await API.post("API", `/kyc/${currentKycFlow.id}/files`, { body })
      .then((response) => {
        try {
          const config = {
            onUploadProgress: (progressEvent: { loaded: any }) => {
              if (progressEvent.loaded / file?.body?.size === 1) {
                removeLoading(KycLoading.SAVE_FILE, file.id);
              }
            },
          };
          axios.put(response.putUrl, file.body, config);
          setCurrentFilesInternal((cFiles) =>
            findAndReplaceFile(cFiles, { ...response, status: "UPLOADED_TO_S3" })
          );

          const indexSignatureOfFile = currentKycFlow.signatures?.findIndex(
            (signature: any) => signature?.fileId === file.id
          );
          if (indexSignatureOfFile > -1) {
            const updatedSignatures: any = [...currentKycFlow.signatures];
            const updatedSignature: any = updatedSignatures[indexSignatureOfFile];
            updatedSignatures[indexSignatureOfFile] = { ...updatedSignature, status: "SIGNED" };
            updateKycFlow({ ...currentKycFlow, signatures: updatedSignatures }, true);
          }
        } catch (e) {
          console.error("axios error:", e);
        }
      })
      .finally(() => {
        removeLoading(KycLoading.SAVE_FILE, file.id);
      });
  };

  const deleteFile = async (file: any) => {
    if (!currentKycFlow) {
      return;
    }
    if (!file?.createdAt) {
      setCurrentFilesInternal((cFiles) => findAndDeleteFile(cFiles, file));
      return;
    }
    addLoading(KycLoading.DELETE_FILE, file.id);
    //
    if (file?.createdByUserId === session?.id || file?.status === "DRAFT") {
      setCurrentFilesInternal((cFiles) => findAndDeleteFile(cFiles, file));
    }

    if (isFileUploaded(file)) {
      await API.del("API", `/kyc/${currentKycFlow.id}/files/${file?.id}`, {})
        .finally(() => {
          if (file?.createdByUserId !== session?.id) {
            setCurrentFilesInternal((cFiles) => findAndDeleteFile(cFiles, file));
          }
          removeLoading(KycLoading.DELETE_FILE, file.id);
        })
        .catch(() => {});
    } else {
      removeLoading(KycLoading.DELETE_FILE, file.id);
    }
  };

  const getFileByFieldPath = (fieldPath: string) => {
    return currentFiles?.find((file) => file?.fieldPath === fieldPath);
  };

  return (
    <KycFileContext.Provider
      value={{
        currentFiles,
        currentFilesIdToFileMap,
        setCurrentFiles,
        getFileByFieldPath,
        getFileById,
        saveFile,
        replaceFile,
        updateFile,
        deleteFile,
        listFilesByKycId,
      }}
    >
      {children}
    </KycFileContext.Provider>
  );
};

KycFileProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export const KycFileConsumer = KycFileContext.Consumer;
