import * as React from "react";
import cn from "clsx";

import { AvatarImageProps, AvatarPlaceholderClassName, errorImageHandler } from "./definitions";

export enum SymbolSizeClassName {
  SMALL = "symbol-25 min-h-25px min-w-25px",
  MEDIUM = "symbol-35 min-h-35px min-w-35px",
  LARGE = "symbol-45 min-h-45px min-w-45px",
}

export interface SmallAvatarImageProps extends AvatarImageProps {
  className?: string;
  symbolSizeClassName?: string;
  isCircleShape?: boolean;
}

export const SmallAvatarImageInner: React.FunctionComponent<SmallAvatarImageProps> = ({
  className,
  symbolSizeClassName = SymbolSizeClassName.SMALL,
  isCircleShape = false,
  isLoading = false,
  url,
  placeholderClassName = AvatarPlaceholderClassName.LOADING,
}) => (
  <div
    className={cn(
      "flex-shrink-0 symbol",
      symbolSizeClassName,
      url ? "bg-white-o-30" : "symbol-light-primary",
      {
        "symbol-circle": isCircleShape,
      },
      className
    )}
  >
    {url ? (
      <img src={url} alt="Avatar" onError={errorImageHandler()} />
    ) : (
      <span className="symbol symbol-label font-weight-bold">
        <i
          className={cn(
            "fas text-info icon-md",
            isLoading ? AvatarPlaceholderClassName.LOADING : placeholderClassName
          )}
        />
      </span>
    )}
  </div>
);

export const SmallAvatarImage = React.memo(SmallAvatarImageInner);

export default SmallAvatarImage;
