import * as React from "react";
import cn from "clsx";
import { NumberInput } from "../../_utils/formUtils";
import { SUFFIX_EURO_CURRENCY } from "../../_utils/suffixUtils";

export const PriceDetails: React.FC = ({ children }) => {
  return (
    <table>
      <tbody>{children}</tbody>
    </table>
  );
};

interface PriceDetailsLineProps {
  label: string;
  value: React.ReactNode;
  suffix?: string;
  style?: React.CSSProperties;
  valueColor?: string;
}
export const PriceDetailsLine: React.FC<PriceDetailsLineProps> = ({
  label,
  value,
  style,
  valueColor = "",
}) => {
  return (
    <tr>
      <td className="pr-20" style={style}>
        {label}
      </td>
      <td className={cn("text-right", valueColor)} style={style}>
        {value}
      </td>
    </tr>
  );
};

export const PriceDetailsLineCurrency: React.FC<PriceDetailsLineProps> = (props) => {
  return (
    <PriceDetailsLine
      {...props}
      value={
        <NumberInput
          displayType={"text"}
          value={props.value}
          suffix={SUFFIX_EURO_CURRENCY}
          className={"ml-auto"}
          decimalScale={2}
          fixedDecimalScale={true}
        />
      }
    />
  );
};
