import { Field } from "formik";
import cn from "clsx";
import "./KycRadioGroup.scss";
import { DataCyCompPrefix, KycChoiceOption } from "../../../Kyc";
import { KycFormElementBaseLayout } from "../KycControlsBaseLayout";
import { KycFieldLock } from "../KycFieldLock";
import { ReactNode } from "react";
import { KycInfoBox } from "../../KycInfoBox";
import { useTranslation } from "../../../../../hooks/useTranslation";
import { formatDataCy } from "../../../KycUtils";

type KycRadioGroupProps = {
  field: any;
  label: string;
  classNameLabel: string;
  inline: boolean;
  name: string;
  children?: ReactNode;
  choices: KycChoiceOption[];
  onChange: Function;
  disabled: boolean;
  infoLabel: string;
  disableLabel?: boolean;
  props: any;
};

export const KycRadioGroup = ({
  field,
  label,
  classNameLabel,
  inline,
  children,
  choices = [
    { label: "COMMON.ACTION.YES", value: "true" },
    { label: "COMMON.ACTION.NO", value: "false" },
  ],
  name,
  onChange,
  disabled,
  infoLabel,
  disableLabel = false,
  ...props
}: KycRadioGroupProps): JSX.Element => {
  const { fm } = useTranslation();
  const content = (
    <KycFieldLock field={field} translations={choices} disabled={disabled}>
      <div className="d-lg-flex d-md-flex flex-md-wrap flex-sm-wrap flex-md-row flex-sm-column-reverse flex-lg-row pt-1">
        {choices?.map((choice, index) => (
          <div
            key={field.name + "-" + choice.value}
            className={cn(
              choices?.length === 2 && "col-sm-6 pl-0 pr-0 pb-1",
              index === 0 && "pr-1"
            )}
            data-cy={formatDataCy(DataCyCompPrefix.RADIO, field?.name, choice.value)}
          >
            <div className=" border border-secondary p-2 rounded">
              <label
                className="control control-radio text-left"
                style={{ cursor: disabled ? "not-allowed" : "pointer" }}
              >
                <Field
                  id={field.name + "-" + choice.value}
                  type="radio"
                  name={field.name}
                  value={choice.value}
                  onChange={onChange}
                  disabled={disabled}
                  className={cn("control control-radio")}
                />
                <div className={cn("control_indicator")} />
                {fm(choice.label)}
              </label>
            </div>
          </div>
        ))}
      </div>
    </KycFieldLock>
  );

  return (
    <>
      <KycFormElementBaseLayout
        className={classNameLabel}
        disableLabel={disableLabel}
        field={field}
        content={content}
        disabled={disabled}
        label={label}
        inline={inline}
      />
      {infoLabel && <KycInfoBox label={infoLabel} />}
    </>
  );
};
