import * as React from "react";
import cn from "clsx";
import { useDidMount } from "rooks";

import { CardBaseProps } from "./definitions";

import { CardHeaderTitle } from "./CardHeaderTitle";

export interface CardHeaderProps extends Omit<CardBaseProps, "title"> {
  cardHeaderClassName?: string;
  cardHeaderTitleClassName?: string;
  title?: React.ReactNode;
  sticky?: boolean;
  icon?: any;
  toolbar?: React.ReactNode;
  labelRef?: React.LegacyRef<HTMLDivElement>;
}

export const CardHeader = React.forwardRef<HTMLDivElement, CardHeaderProps>(
  (
    {
      className,
      cardHeaderClassName,
      cardHeaderTitleClassName,
      children,
      icon,
      title,
      toolbar,
      sticky = false,
      labelRef,
      ...props
    },
    ref
  ) => {
    const [top, setTop] = React.useState(0);
    const [windowHeight, setWindowHeight] = React.useState(0);

    useDidMount(() => {
      handleResize();

      function handleResize() {
        setWindowHeight(window.innerWidth);
      }

      window.addEventListener("resize", handleResize);

      return () => {
        window.removeEventListener("resize", handleResize);
      };
    });

    React.useEffect(() => {
      const headerElement = document.querySelector<HTMLElement>(".header");

      // Skip if sticky is disabled or on initial render when we don't know about window height.
      if (!sticky || windowHeight === 0 || !headerElement) {
        return;
      }

      const subheaderElement = document.querySelector<HTMLElement>(".subheader");
      const headerMobileElement = document.querySelector<HTMLElement>(".header-mobile");

      let nextMarginTop = 0;

      // mobile header
      if (headerMobileElement && window.getComputedStyle(headerElement).height === "0px") {
        nextMarginTop = headerMobileElement.offsetHeight;
      } else {
        const classList = document.body.classList;

        // desktop header
        if (classList.contains("header-minimize-topbar")) {
          // hardcoded minimized header height
          nextMarginTop = 60;
        } else {
          // normal fixed header
          if (classList.contains("header-fixed")) {
            nextMarginTop += headerElement.offsetHeight;
          }

          if (subheaderElement && classList.contains("subheader-fixed")) {
            nextMarginTop += subheaderElement.offsetHeight;
          }
        }
      }

      setTop(nextMarginTop);
    }, [sticky, windowHeight]);

    return (
      <div
        {...{
          ref,
          className: cn("card-header", cardHeaderClassName),
          style: sticky ? { top, position: "sticky", backgroundColor: "#fff" } : undefined,
          ...props,
        }}
      >
        {title && (
          <div ref={labelRef} className={cn("card-title", cardHeaderTitleClassName, className)}>
            {icon}
            <CardHeaderTitle>{title}</CardHeaderTitle>
          </div>
        )}

        {toolbar}
        {children}
      </div>
    );
  }
);

export default CardHeader;

// Set display name for debugging.
if (process.env.NODE_ENV !== "production") {
  CardHeader.displayName = "CardHeader";
}
