import { KycInfoBox, KycInfoLevel } from "./KycInfoBox";
import * as React from "react";

interface KycValidationMessageProps {
  field: any;
  isRequired?: boolean;
  level: KycInfoLevel;
  message: string;
}

/* TODO: Yup/Validation not currently not fully integrated/supported.
   - To fit requirement of https://kodehyve.atlassian.net/browse/IMT-1901,
     a prop has been added (isRequired={true/false}) which will show a warning label by default
   - This can be adapted further to make use of Yup, where instead of showing a warning message by default,
      an error message is shown when validation is triggered (e.g. in kyc context, when "next" button is clicked in form
   - To switch color/icon, KycInfoLevel.ERROR can be set achieve "error"-style message
   - Due to the fact that most controls have been refactored and use a common pattern,
     the logic related to validation for controls can be managed with yup + this component.
   - The component is currently only used in KycDropdownSelect (but can be improved to be used across all controls tbd.)
 */
export const KycValidationMessage = ({
  field,
  isRequired = false,
  level = KycInfoLevel.ERROR,
  message,
}: KycValidationMessageProps): JSX.Element => {
  return (
    <>
      {message && isRequired && !field.value && (
        <KycInfoBox level={level} label={message} className="mb-1" textAlign={"left"} />
      )}
    </>
  );
};
