import "./ParentLabel.scss";
import { CustomSelectStatus } from "app/_components/CustomSelect";

interface ParentLabelProps {
    parentId: string;
    parentName: string;
    parentStatus: string;
    actionOnClick: (id: string) => void;
}

const ParentLabel = ({parentId, parentName, parentStatus, actionOnClick}: ParentLabelProps) => {
  return (
    <div className="form-group row">
      <div className="col-lg-12 parentField">
        <h5>Parent Task</h5>
          <div className="parentField__container">
            <div className={"parentField__name"}
                onClick={() => actionOnClick(parentId)}
            >
              {parentName}
            </div>
            <div className="parentField__status">
              <CustomSelectStatus
                name="parentTaskStatus"
                selected={parentStatus}
                small
                disabled={true}
              />
            </div>
          </div>
        </div>
    </div>
  )
}

export default ParentLabel;
