import * as React from "react";
import { useIntl } from "react-intl";
import cn from "clsx";

import { toAbsoluteUrl } from "_metronic/_helpers";

import { countryFlagByCodeMap } from "app/_utils/listUtils";

export interface DisplayCountryProps {
  className?: string;
  countryCode?: string;
}

export const DisplayCountry: React.FunctionComponent<DisplayCountryProps> = ({
  className,
  countryCode,
}) => {
  const intl = useIntl();

  if (!countryCode) return null;

  const countryName = intl.formatDisplayName(countryCode, { type: "region" });
  const countryFlag = countryFlagByCodeMap.get(countryCode);

  return (
    <div className={cn("d-flex align-items-center", className)}>
      <span>{countryName}</span>

      {countryFlag && (
        <span className="symbol symbol-20 ml-2">
          <img src={toAbsoluteUrl(countryFlag)} alt={countryName} />
        </span>
      )}
    </div>
  );
};

export default DisplayCountry;
