import * as React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Modal, OverlayTrigger, Tooltip } from "react-bootstrap";
import { MenuItem } from "react-bootstrap-typeahead";
import { take } from "lodash";

import { UserDropdownItem } from "./UserDropdownItem";
import { ProfilePictureThumbnail } from "./ProfilePictureThumbnail";
import { IUser } from "../../data/schemas";

const numberOfUsersToDisplay = 5;

export interface WidgetUserListAccessProps {
  users: IUser[];
  modalTitle: string;
  displayLogo?: boolean;
}

export const WidgetUserList: React.FunctionComponent<WidgetUserListAccessProps> = ({
  users,
  modalTitle,
  displayLogo = true,
}) => {
  const intl = useIntl();

  const [displayModal, setDisplayModal] = React.useState<boolean>(false);
  const [searchText, setSearchText] = React.useState("");

  return (
    <>
      <Modal show={displayModal} size="lg" aria-labelledby="user-avatar-cropper-modal" centered>
        <Modal.Header>
          <Modal.Title>{modalTitle}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <input
              type="text"
              data-cy="input-search"
              className="form-control"
              name="searchText"
              placeholder={intl.formatMessage({
                id: "COMMON.SEARCH.TITLE",
              })}
              value={searchText}
              onChange={(e) => {
                setSearchText(e.target.value);
              }}
            />
            <small className="form-text text-muted">
              <b>
                <FormattedMessage id="COMMON.SEARCH.TITLE" />
              </b>
              <FormattedMessage id="COMMON.SEARCH.IN_ALL_FIELDS" />
            </small>
            <div className={"overflow-auto max-h-275px"}>
              {users
                .filter((user) => {
                  return (
                    user.email.toLowerCase().includes(searchText.trim()) ||
                    user?.firstName.toLowerCase().includes(searchText.trim()) ||
                    user?.lastName.toLowerCase().includes(searchText.trim())
                  );
                })
                .map((user, index) => (
                  <MenuItem option={user} position={index} key={index}>
                    <UserDropdownItem user={user} />
                  </MenuItem>
                ))}
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div>
            <button
              type="button"
              onClick={() => {
                setSearchText("");
                setDisplayModal(false);
              }}
              className={`btn btn-light btn-elevate`}
            >
              <FormattedMessage id="COMMON.CLOSE" />
            </button>
          </div>
        </Modal.Footer>
      </Modal>
      <div className="d-flex align-items-center flex-lg-fill my-1">
        {displayLogo && (
          <span className="mr-4">
            <i className="flaticon-network icon-2x text-muted font-weight-bold" />
          </span>
        )}

        <div className="symbol-group symbol-hover">
          {take(users, numberOfUsersToDisplay).map((user, index) => (
            <OverlayTrigger
              key={index}
              placement="top"
              overlay={
                <Tooltip id="budgets-expand-tooltip">
                  {user?.firstName || user?.lastName
                    ? `${user?.firstName} ${user?.lastName}`.trim()
                    : user?.email}
                </Tooltip>
              }
            >
              {ProfilePictureThumbnail({ shape: "circle", user })}
            </OverlayTrigger>
          ))}

          {users.length > numberOfUsersToDisplay && (
            <div className="symbol symbol-35 symbol-circle symbol-light symbol-light-primary flex-shrink-0">
              <span
                className="symbol-label font-weight-bold bg-hover-primary text-hover-white text-primary"
                onClick={() => setDisplayModal(true)}
              >
                +{users.length - numberOfUsersToDisplay}
              </span>
            </div>
          )}
        </div>
      </div>
    </>
  );
};
