import * as React from "react";
import cn from "clsx";

export interface VisTimelineSkeletonProps {
  className?: string;
}

export const VisTimelineSkeleton: React.FunctionComponent<VisTimelineSkeletonProps> = ({
  className,
}) => (
  <div className={cn("vis-timeline-skeleton", className)}>
    <div className="skeleton">
      <div className="header-left"></div>
      <div className="header-panel"></div>
      <div className="center-labels"></div>
      <div className="center-panel">
        <span className="spinner spinner-lg spinner-primary"></span>
      </div>
      <div className="footer-left"></div>
      <div className="footer-panel"></div>
    </div>
  </div>
);

export default VisTimelineSkeleton;
