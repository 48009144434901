import { API } from "@aws-amplify/api";

export async function updateForm(formData, actionId, userId) {
  return await API.post("API", `/users/${userId}/actions/${actionId}`, { body: { ...formData } });
}

export async function updateAction(form) {
  const input = { ...form };
  delete input.user;

  return await API.post("API", `/users/${form.userId}/actions/${input.id}`, { body: form });
}

export async function getActionById(userId, actionId) {
  return await API.get("API", `/users/${userId}/actions/${actionId}`, {});
}
