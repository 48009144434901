/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { Link } from "react-router-dom";
import { useCustomLocationState } from "../../../../../app/_utils/useCustomLocationState";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../_helpers";

export function BreadCrumbs({ subheader }) {
  const { goBack } = useCustomLocationState();

  if (!subheader.breadcrumbs?.length) {
    return "";
  }

  const backToUrl = (item, index) => {
    goBack(item.pathname, subheader.breadcrumbs.length - 1 - index);
  };

  return (
    <div className="d-flex align-items-center font-weight-bold my-2 flex-wrap">
      <Link className="opacity-75 hover-opacity-100" to="/dashboard">
        <span className="svg-icon svg-icon-white svg-icon-md">
          <SVG src={toAbsoluteUrl("/media/svg/icons/Layout/Layout-4-blocks.svg")} />
        </span>
      </Link>
      {subheader.breadcrumbs.map((item, index) => (
        <React.Fragment key={index}>
          <span className="svg-icon svg-icon-white svg-icon-md opacity-75 mx-3">
            <SVG src={toAbsoluteUrl("/media/svg/icons/Navigation/Angle-right.svg")} />
          </span>
          {item.pathname ? (
            <button
              className="btn btn-text p-0 text-white font-size-lg text-hover-primary"
              onClick={() => backToUrl(item, index)}
            >
              {item.title}
            </button>
          ) : (
            <span className="text-white font-size-lg">{item.title}</span>
          )}
        </React.Fragment>
      ))}
    </div>
  );
}
