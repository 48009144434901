import React from "react";
import "./DropdownSelectToggler.scss";
import cn from "clsx";

export interface DropdownSelectTogglerProps {
  customClass?: string;
  onClick: any;
  children: any;
  disabled?: boolean;
  onClear?: () => void;
  showClear?: boolean;
}

export const DropdownSelectToggler = React.forwardRef<
  HTMLDivElement,
  DropdownSelectTogglerProps & React.HTMLAttributes<HTMLDivElement>
>(({ onClear, showClear, ...props }, ref) => {
  return (
    <div ref={ref} {...props} className={cn("dropdown-select-toggler__control", props.className)}>
      <div className="dropdown-select-toggler__value-container">{props.children}</div>
      <div className="dropdown-select-toggler__indicators-container">
        {showClear && !!onClear && (
          <div
            className="dropdown-select-toggler__indicator-container"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              onClear();
            }}
          >
            <span className="fa fa-times w-20px h-20px d-flex align-items-center justify-content-center" />
          </div>
        )}
        <div className="dropdown-select-toggler__indicator-separator"></div>
        <div className="dropdown-select-toggler__indicator-container">
          <span className="fa fa-chevron-down w-20px h-20px d-flex align-items-center justify-content-center" />
        </div>
      </div>
    </div>
  );
});
