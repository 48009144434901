import { Nav } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import React from "react";

export const UserNotificationsDropdownTabHeaderItem = ({
  tabPaneKeyActive,
  tabKey,
  titleLabelId,
  unreadNotificationsCount,
  ...otherProps
}) => {
  return (
    <Nav.Item className="nav-item" as="li" {...otherProps}>
      <Nav.Link
        eventKey={tabKey}
        className={`nav-link show ${tabPaneKeyActive === tabKey ? "active" : ""}`}
      >
        <FormattedMessage id={titleLabelId} />
        {unreadNotificationsCount > 0 && (
          <span
            className="badge badge-danger rounded-circle ml-1"
            style={{ fontSize: "70%", bottom: "4px", right: "4px", top: "auto" }}
          >
            {unreadNotificationsCount}
          </span>
        )}
      </Nav.Link>
    </Nav.Item>
  );
};
