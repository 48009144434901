export const TABLEAU_CLOUD_URL_PREFIX = `${process.env.REACT_APP_TABLEAU_CLOUD_URL_PREFIX}`;

const TABLEAU_STR_CONFIG_B64 = `${process.env.REACT_APP_TABLEAU_DASHBOARDS_CONFIG}`;

export const hasTableauConfigString =
  TABLEAU_STR_CONFIG_B64.length > 0 && TABLEAU_STR_CONFIG_B64 !== "undefined";

export const isEnabled =
  hasTableauConfigString && `${process.env.REACT_APP_TABLEAU_DASHBOARDS_ENABLED}` === "true";

export const TABLEAU_DEFAULT_USER = `${process.env.REACT_APP_TABLEAU_DASHBOARDS_USER_EMAIL}`;

export const TABLEAU_JWT_CONFIG = isEnabled
  ? JSON.parse(window.atob(TABLEAU_STR_CONFIG_B64))
  : ["", "", ""];
