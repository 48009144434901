import * as React from "react";
import { IFilterSearchTypeDropdown, SearchTypeValues } from "../../contexts/SearchContext";
import { CustomSelect } from "../CustomSelect";
import { useIntl } from "react-intl";
import { useSearch } from "../../hooks/useSearch";
import cn from "clsx";
import { isEmpty } from "lodash";
import { isEqual } from "lodash-es";

interface SearchDropdownProps {
  filter: IFilterSearchTypeDropdown;
}

export const SearchDropdown: React.FC<SearchDropdownProps> = ({
  filter: {
    name,
    value: filterValue,
    labelKey,
    values,
    filteredValues,
    groupBy,
    formatter,
    isMultiple,
    isAdvanced,
    selected,
  },
}): JSX.Element => {
  const { setSearchValues, entities } = useSearch();
  const shouldGroupBy = (values: any) => {
    return groupBy ? groupBy(values, entities) : values;
  };
  const intl = useIntl();
  const options = shouldGroupBy(
    (filteredValues ?? values).map((option: any) => ({
      value: option,
      label: labelKey
        ? intl.formatMessage({ id: labelKey + "." + option })
        : formatter
        ? formatter(option)
        : option.toString(),
    }))
  );

  return (
    <div
      className={cn(`mr-sm-2 mr-0 mt-2`, { "flex-grow-1": isAdvanced })}
      data-cy={"filter-dropdown-" + filterValue.toLowerCase()}
    >
      <CustomSelect
        placeholder={intl.formatMessage({ id: name })}
        styles={{
          control: { width: "100%", maxWidth: "100%", minHeight: "40px", minWidth: "240px" },
          multiValueLabel: {
            backgroundColor: "#d7dbf1",
            color: "black",
            fontSize: "13px",
            fontWeight: "400",
            minWidth: "8.164px",
            padding: "0.3em 0.5em",
            paddingLeft: "6.5px",
            borderRadius: "3px",
            maxWidth: options?.length > 1 ? "130px" : "175px",
          },
          multiValueRemove: {
            backgroundColor: "#d7dbf1 !important",
            paddingLeft: "0px",
            ">svg": {
              fill: "black",
            },
            borderRadius: "3px",
          },
          multiValue: {
            borderRadius: "3px",
          },
        }}
        noOptionsMessage={() => null}
        options={options}
        selected={selected ? options.find((option: any) => isEqual(option.value, selected)) : null}
        clearable={true}
        openMenuOnClick={true}
        onChange={(selected: any) => {
          if (selected === null) {
            setSearchValues("*", SearchTypeValues.SEARCH_DROPDOWN, filterValue);
            return;
          }
          const value = isMultiple ? selected : selected.value;
          setSearchValues(value, SearchTypeValues.SEARCH_DROPDOWN, filterValue);
        }}
        getOptionValue={(o: any) => o.value}
        disabled={isEmpty(options)}
        isMulti={isMultiple}
      />
    </div>
  );
};
