import { API } from "@aws-amplify/api";
import { IFile, ILinkedFile } from "../../../data/schemas";
import { ENTITY_TYPE_PATH } from "../../_utils/listUtils";

export const getFile = async (file: ILinkedFile) => {
  return await API.get(
    "API",
    `/${ENTITY_TYPE_PATH[file.relatedEntity.type]}/${file.relatedEntity.id}/files/${file.id}`,
    {}
  );
};

export const updateLinkedFiles = async (file: IFile) => {
  return await API.post("API", `/files/${file.id}/linkedFiles`, {
    body: { file },
  });
};

export const searchFiles = async (fileToSearch: string) => {
  return await API.get("API", "/files", { queryStringParameters: { fileToSearch } });
};
