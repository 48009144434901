import { KycFormElementBaseLayout } from "../KycControlsBaseLayout";
import { KycInfoBox } from "../../KycInfoBox";
import { NumberInput } from "../../../../../_utils/formUtils";
import { KycFieldLock } from "../KycFieldLock";
import { Suffix } from "../../../../../_utils/suffixUtils";
import { useKycFlow } from "../../../../../hooks/useKycFlow";

type KycNumberInputProps = {
  field: any;
  onChange: any;
  onBlur: any;
  // onChange: FormEventHandler<HTMLInputElement>;
  withFeedbackLabel: boolean;
  inline: boolean;
  label: string;
  props: any;
  disabled: boolean;
  form: { touched: any; errors: any; values: any }; // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  customFeedbackLabel: string;
  type: string;
  className: string;
  infoLabel?: string;
  suffix?: Suffix | string;
  decimalScale?: number;
  disableLabel?: boolean;
};

export const KycNumberInput = ({
  field, // { name, value, onChange, onBlur }
  form: { touched, errors, values }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  label,
  withFeedbackLabel = true,
  onChange,
  onBlur,
  customFeedbackLabel,
  inline = true,
  type = "text",
  className,
  disabled,
  infoLabel,
  suffix = "",
  decimalScale = 2,
  disableLabel = false,
  ...props
}: KycNumberInputProps): JSX.Element => {
  const { saveField } = useKycFlow();

  const content = (
    <>
      <KycFieldLock field={field} disabled={disabled} type="number" suffix={suffix}>
        <NumberInput
          className="form-control text-right form-control-reduce-space"
          allowNegative={false}
          allowEmptyFormatting={false}
          allowLeadingZeros={false}
          value={field.value ? field.value * (suffix === Suffix.PERCENTAGE ? 100 : 1) : 0}
          suffix={suffix}
          fixedDecimalScale={true}
          decimalScale={decimalScale}
          onValueChange={onChange}
          onBlur={(e: any) => saveField(e, { field: { name: field?.name, value: field?.value } })}
          {...props}
        />
      </KycFieldLock>
      {infoLabel && <KycInfoBox label={infoLabel} />}
    </>
  );
  return (
    <KycFormElementBaseLayout
      disableLabel={disableLabel}
      field={field}
      content={content}
      disabled={disabled}
      label={label}
      inline={inline}
    />
  );
};
